<template>
  <div>
    <!-- top-bar -->
    <div class="navbar-custom">
      <h4 class="content-title">Hydraulic Monitoring</h4>
        <li class="setting-btn" style="float:right; margin: -40px 55px 0 0;">
                                <!--b-button size="sm" class="float-right" @click="show_setting">
                                    <i class="fas fa-cog"></i>
                                </b-button-->
                 <b-button  class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="show_group">Group</b-button>
                 <b-button  class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="goRouter('/hydraulic-point-reg-map?w_type=111')">New Point</b-button>
        </li>
      <ul class="lst-unstyled topnav-menu topnav-menu-left">
        <!-- <li>
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                          <li class="breadcrumb-item active">{{$t('pressure_monitoring.mpage_title')}}</li>
                        </ol>
                    </li> -->
        <!--
                    <li class="setting-btn">
                                <b-button size="sm" class="float-right" @click="show_setting">
                                    <i class="fas fa-cog"></i>
                                </b-button>
                    </li>
                    -->
           
                            
      </ul>
    </div>
    <!-- tom-bar-end -->
    <div class="monitoring_box">
      <!-- 2022 new -->
      <!--      Headers start-->
      <div class="content-header">
        <div class="d-flex align-items-center">
          <div class="item-total">
            <span> {{ $t("pressure_monitoring.block_cnt") }}</span>
            <strong> {{ tot_block_length }}</strong>
          </div>
          <div class="status d-flex align-items-center ml-4" style="position: relative; top: 0px">
            <div class="mr-1">
              <input
                  type="radio"
                  id="radio_01"
                  name="radio01"
                  checked
                  v-model="sort"
                  value=""
                />
                <label for="radio_01" style="font-size: 15px">Point Name</label>
            </div>
            <div class="mr-1">
              <input
                  type="radio"
                  id="radio_02"
                  name="radio01"
                  v-model="sort"
                  value="id"
                />
                <label for="radio_02" style="font-size: 15px"
                  >Installation Data</label
                >
            </div>
            <div>
              <input
                type="checkbox"
                style="display: none"
                id="check_01"
                name="check_01"
                v-model="sort_strength"
              />
              <label
                    for=""
                    style="font-size: 15px; margin-right: 10px"
                    >Status
                  </label>
            </div>
            <div>
               
              <b-select
                    size="sm"
                    v-model="type"
                    :options="f_status_option"
                    style="width: 100px; padding: 0 10px"
                  ></b-select>
            </div>
           <div style="margin-left:20px; ">
               <label
                    for=""
                    style="font-size: 15px; margin-right: 10px"
                    >Group
                  </label>
              <b-select
                    size="sm"
                    v-model="group"
                    :options="f_group_option"
                    style="width: 100px; padding: 0 10px"
                  ></b-select>
           </div>
          </div>
        </div>

        <div class="item-right">
           
          <div class="ns-search-box">
            
            <input
              type="text"
              class="ns-search-input"
              v-model="value"
              placeholder="Search..."
              @keyup.enter="Enter_Check"
            />
            <button type="button" class="ns-search-button" @click="go_search" />
          </div>
        </div>
      </div>
      <!-- Headers end -->
      <!-- <div class="monitoring_wrap">
        <div class="input_search_box">
          <input
            type="text"
            class="input_search"
            v-model="value"
            placeholder="Search..."
            @keyup.enter="Enter_Check"
          />
          <input type="submit" class="input_submit" @click="go_search" />
        </div>
        <div style="float: right">
          <ul class="list">
            <li style="background: none">
              <span
                class="status m1-map"
                style="position: relative; right: 20%; top: 30px"
              >
                <input
                  type="radio"
                  id="radio_01"
                  name="radio01"
                  checked
                  v-model="sort"
                  value=""
                />
                <label for="radio_01" style="font-size: 15px">Point Name</label>
                <input
                  type="radio"
                  id="radio_02"
                  name="radio01"
                  v-model="sort"
                  value="id"
                />
                <label for="radio_02" style="font-size: 15px"
                  >Installation Data</label
                >
                <input type="checkbox" id="check_01" name="check_01"  v-model="sort_strength" >

                <span style="padding: 10px 20px">
                  <label
                    for="radio_02"
                    style="font-size: 15px; margin-right: 10px"
                    >Status
                  </label>
                  <b-select
                    size="sm"
                    v-model="type"
                    :options="f_status_option"
                    style="width: 100px; padding: 0 10px"
                  ></b-select>
                </span>
              </span>
              <span style="float: right; right: 20px; margin-top: 30px">
                {{ $t("pressure_monitoring.block_cnt") }} :
                {{ tot_block_length }}
              </span>
            </li>
          </ul>
        </div>
      </div> -->
      <div class="table_wrap press-monitoring  " :key="listKey">
        <ul class="monitoring-list row row-cols-3">
        <fragment v-for="item in ts_monitoring_list" :key="item.id">
          <li class="col">
          <hydraCard
            :dma_name="item.root_id"
            :tag="item.id"
            :box="item" 
            @go_hydraulic_info="go_hydraulic_info"
          >
          </hydraCard>
        </li>
        </fragment>
      </ul>
      </div>
    </div>
    <DlgHydraulicPointInfo ref="hydraulic_info"    />
    <DlgGroupList ref="dlg_group_list"   />

  </div>
</template>

<script>
import hydraCard from "@/components/card/hydraCard";
import { Fragment } from "vue-fragment";
import DlgHydraulicPointInfo from '@/components/popup/DlgHydraulicPointInfo'
import DlgGroupList from '@/components/popup/DlgGroupList';

export default {
  name: "HydraulicMonitoring",
  props: [],
  watch: {
    sort() {
      this.go_search();
    },
    type() {
      this.go_search();
    },
    group(){
       this.go_search();
    }
  },
  data() {
    return {
      columns: [
        { label: "Tag", field: "tag" },
        { label: "Name", field: "name" },
      ],
      ts_monitoring_list: [],
      sort: "",
      type: "",
      sort_strength: "",
      temp_ts_monitoring_list: [],
      value: "",
      listKey: 0,
      tot_block_length: 0,
      f_status_option: [
        { value: "", text: "All" },
        { value: "A", text: "Active" },
        { value: "I", text: "Inactive" },
      ],
        group : '',
        f_group_option: [
        { value: "", text: "All_Group" },

      ],
    };
  },
  methods: {
    goRouter(path) {
        var last_str = this.last_mea_date.substr(0,10)
        this.$router.push({path: path, query: { id: this.tag, mea_date:last_str  }})
    },
    show_setting() {
      this.temp_ts_monitoring_list = [...this.ts_monitoring_list];
      this.$refs.modal.show();
    },
    show_group(){
            this.$refs.dlg_group_list.show();
    },     
    updateKey: function () {
      this.listKey += 1;
    },
    go_hydraulic_info(id){
         this.$refs.hydraulic_info.show(id)
    },

    handleOk() {
      this.ts_monitoring_list = [...this.temp_ts_monitoring_list];
      let param = [];

      for (let i = 0; i < this.ts_monitoring_list.length; i++)
        param.push(this.ts_monitoring_list[i].tag);
      this.$http.get(this.URL + "api/set_monitoring_list?list=" + param);
    },

    go_search() {
      if (this.value === undefined) return;
      this.updateKey();
      this.ts_monitoring_list = [];
      var customer_id = sessionStorage.select_customer_id;
      if (sessionStorage.select_customer_id === "27") {
        customer_id = 17; //파주
      }
      //this.$http.get(this.URL+'api/get_monitoring_list_left?search='+this.value).then((res) => {
      this.$http.get( this.URL + "api/API21/hydraulic/list?customer_id=" + customer_id +
            "&search=" + this.value + "&sort="+this.sort + "&type=" + this.type+ "&group_id=" + this.group
        ).then((res) => {
          this.ts_monitoring_list = res.data.results[0];
          this.tot_block_length = this.ts_monitoring_list.length;
        });
    },
    Enter_Check() {
      //  if(event.keyCode == 13){
      this.go_search(); // 실행할 이벤트
    },
  },
  mounted() {
    // this.$http.get(this.URL+'api/get_monitoring_list').then((res) => {
    var customer_id = sessionStorage.select_customer_id;
    if (sessionStorage.select_customer_id === "27") {
      customer_id = 17; //파주
    }
    // group data를 가져온다.
    this.$http.get(this.URL+'api/waterpressure/groups?customer_id='+sessionStorage.select_customer_id).then((rows) => {
      if(rows.data.groups.length <= 0) return;
      for(var i = 0 ; i < rows.data.groups.length ;++i){
        this.f_group_option.push(  { value: rows.data.groups[i].groupId, text: rows.data.groups[i].groupName})
      }
    
    
    })

    this.$http
      .get(
        this.URL +
          "api/API21/hydraulic/list?customer_id=" +
          customer_id +
          "&sort=" +
          this.sort
      )
      .then((res) => {
            this.ts_monitoring_list = res.data.results[0]
            this.tot_block_length =  this.ts_monitoring_list.length ;    
            if(res.data.results[0].length >0){   
              this.last_mea_date = res.data.results[0][0].last_measure_datetime;
            }else{
              var today = new Date();
              var year = today.getFullYear();
              var month = ('0' + (today.getMonth() + 1)).slice(-2);
              var day = ('0' + today.getDate()).slice(-2);

              var todayFormat = year + '-' + month  + '-' + day;
              this.last_mea_date = todayFormat;
            }
      });
  },
  beforeDestroy() {},
  components: {
    hydraCard,
    Fragment,
    DlgGroupList:DlgGroupList,
    DlgHydraulicPointInfo : DlgHydraulicPointInfo,
  },
};
</script>

<style scoped lang="scss">
// 2023
.row {
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.col {
  padding-right: 8px;
  padding-left: 8px;
}
.map-button.icon_btn.icon_btn_text.blue {
  background-color: #28AF81;
}
</style>



<template>
  <highcharts id= "LeakCountChart1"  :options="chartOptions" :class="size"></highcharts>
</template>

<script>
export default {
  name: 'barHchart',
   props:{

      chartData: {
        
      },
       chartData1: {
        type: Number
      },
      series_name : {},
      y_title : {},
      size : { type:String},
      /*
      interval : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
       sdate : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
      */
      //y_title : { type:String},
      //chart_type : { type:String}
  },
  data () {
    return {
     //  x_label : this.size === "gisBarChart" ? "" : "%" ,
       chartOptions: {
      
         colorAxis:[{lineColor:"#ffffff"}],
       //  colors : ["#2e75b6","#ff0000"],
          credits: {
          text: '',
          href: ''
        },
        series:[ 
            {
             //name: "material",
             colorByPoint:  this.y_title==='Strength'  ? false: true,     
             data : this.chartData
            }
         ]
        ,
          tooltip: {
            pointFormat: (this.y_title===undefined ? "" :this.y_title+":")  +" <b>{point.y}</b>" +(this.y_title==="Pressure(bar)" ? "%" :"")
        },
         title: {        text: '', },
         chart: {  // zoomType: 'x' ,
                    type: this.y_title==='Strength'  ? 'column': 'bar',
                    inverted: this.y_title==='Strength'  ? false: true,               
                },
      
        xAxis: {
            categories: this.series_name,
            title: { text: this.y_title==='Strength'  ? '': this.y_title },  
            lineWidth: 1,
            labels: {
              // rotation: -45,
                enabled: true,
                
            }
        },
        yAxis: {
            min: 0,
          //  max:this.series_name === "Leak" ? null : Math.ceil(this.chartData,0),
            title: {
                text: this.y_title==='Strength'  ? this.y_title: ''
            },
            lineWidth:1,
            labels: {
                enabled: true,
                format: '{value:.,0f}'+ (this.y_title==="Pressure(bar)" ? "%" : "")
            }
        },
         exporting: {
              buttons: { contextButton: { enabled: false} }
         },    
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
              //  stacking: 'normal',       
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.y}</b>'+(this.y_title==="Pressure(bar)" ? "%" :""),
                }
            },
            column: {
             // pointWidth: 70
            }
        },
      },

    
       
    }
  }
}
</script>
<style  lang="scss"  >
.gisBarChart .highcharts-container .highcharts-root {
  width: 100%; 
 /* height:  calc(25vh - 20px); */
  overflow-y: auto;
  overflow-x: hidden;
}
.gisBarChart {
  min-width:300px;
  max-width: 1600px;
 /* height: calc(25vh - 20px); */
  width:100%; 
  margin: 0 auto;
  
}

#LeakCountChart1 .highcharts-container .highcharts-root {
  width: 100%; 
 /* height: calc(40vh - 20px);*/
   margin: 0 auto;
}
 .highcharts-grid-line {
    stroke-width: 0px;
    stroke: #ffffff;
}

</style>
<template>
    <div>
        <b-modal id="my-modal"  size="lg"
            ref="modal"
            :title="$t('inp_file_info.title')"
            >
          <b-tabs  v-if="properties!==null" align="center" >
            <b-tab :title="$t('inp_file_info.tab_title1')" active  style="height:600px;">  
                <b-row>
                    <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.model_no')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="properties['id']" disabled></b-input>
                    </b-col>
                </b-row>
    
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.name')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="properties['name']" ></b-input>
                      
                    </b-col>
                </b-row>
              
                <b-row>
                    <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.register')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="properties['m_id']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.register_time')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="properties['insert_datetime']" disabled></b-input>
                    </b-col>
                </b-row>
              
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.save_time')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="properties['update_datetime']" disabled></b-input>
                    </b-col>
                </b-row> 
                  <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.f_name')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="properties['file_name']" disabled></b-input>
                        <b-button size="sm" @click="importTextFile()"   style="margin: 10px 0 ;">
                          <i class="mdi mdi-upload"/>File Upload                         
                        </b-button>
                        <b-button size="sm" @click="InpfileDownload()"   style="margin: 10px 20px;">
                          <i class="mdi mdi-download"/>File Download                            
                        </b-button>
                        
                    </b-col>
                </b-row>   
                
                
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.contents')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="properties['contents']" ></b-input>
                    </b-col>
                </b-row>  
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>Flow Unit</label>
                    </b-col>
                    <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties['flow_unit']" :options="flow_unit_option"  ></b-select>
                    </b-col>
                </b-row> 
                  <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>Head Loss Formula</label>
                    </b-col>
                    <b-col sm="7">
                        <b-select class="facility__input" size="sm" v-model="properties['head_loss']" :options="head_loss_option"  ></b-select>
                    </b-col>
                </b-row> 
                  <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>Demand Model</label>
                    </b-col>
                    <b-col sm="7">
                          <b-select class="facility__input" size="sm" v-model="properties['demand_model']" :options="demand_model_option"  ></b-select>
                    </b-col>
                </b-row> 
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label >{{$t('inp_file_info.status')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-select  size="sm" v-model="properties['status']" :options="f_status_option" ></b-select>
                    </b-col>
                </b-row>          
            </b-tab>

            <b-tab :title="$t('inp_file_info.tab_title2')"  style="height:600px;">
              <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.node')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="setting.node_tot['Junctions']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.reservior')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="setting.node_tot['Reservoirs']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.tank')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="setting.node_tot['Tanks']" disabled></b-input>
                    </b-col>
                </b-row>                                     
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.link')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="setting.link_tot['Pipes']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.link_length')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="setting.link_tot['Pipes_length']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.pump')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="setting.link_tot['Pumps']" disabled></b-input>
                    </b-col>
                </b-row>
            
                <b-row>
                      <b-col sm=1 ></b-col>
                    <b-col sm="3">
                        <label>{{$t('inp_file_info.valve')}}</label>
                    </b-col>
                    <b-col sm="7">
                        <b-input size="sm" v-model="setting.link_tot['Valves']" disabled ></b-input>
                    </b-col>
                </b-row>
              
            </b-tab>
         
            <b-tab :title="$t('inp_file_info.tab_title3')"  >
   
              <div class="info_box gis_master">
                <div class="status m1-map d-flex" style="position: relative; left: 18%;top:-20px;">
                  <div>
                    <input type="radio" id="radio_01" name="radio01" checked v-model="facility_type" value="1">
                    <label for="radio_01">Junction</label>
                  </div>
                  <div>
                    <input type="radio" id="radio_02" name="radio01"   v-model="facility_type" value="2">
                    <label for="radio_02">Reservoir</label>
                  </div>
                  <div>
                    <input type="radio" id="radio_03" name="radio01"  v-model="facility_type" value="3">
                    <label for="radio_03">Tank</label>
                  </div>
                  <div>
                    <input type="radio" id="radio_04" name="radio01"   v-model="facility_type" value="4">
                    <label for="radio_04">Link</label>
                  </div>
                  <div>
                    <input type="radio" id="radio_05" name="radio01"   v-model="facility_type" value="5">
                    <label for="radio_05">Pump</label>
                  </div>
                  <div>
                    <input type="radio" id="radio_06" name="radio01"   v-model="facility_type" value="6">
                    <label for="radio_06">Valve</label>
                  </div>   
                </div> 
              </div> 
            
                  <b-col sm=12 style="height:630px;" >                 
                        <ServerSideTable1  ref="junction_control" v-if="facility_type==='1'"
                                    :columns="jun_columns"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :totalRecords = "totalRecords1"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                    @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"                                
                                    @on-row-click="onRowClick"   
                        />   
                     
                        
                      <ServerSideTable1  ref="Reservoirs_control" v-if="facility_type==='2'"
                                    :columns="reser_columns"   
                                    :totalRecords = "totalRecords2"                                 
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                   @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"                                
                                    @on-row-click="onRowClick"   
                    />   
                     
                      <ServerSideTable1  ref="Tanks_control"  v-if="facility_type==='3'" 
                                    :columns="tank_columns"
                                    :totalRecords = "totalRecords3"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                   @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"                                
                                    @on-row-click="onRowClick"   
                                 
                    /> 
                     <ServerSideTable1  ref="Pipes_control"  v-if="facility_type==='4'" 
                                    :columns="pipe_columns"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                    :totalRecords = "totalRecords4"
                                    @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"                                
                                    @on-row-click="onRowClick"   
                                 
                    />
                   
                      <ServerSideTable1  ref="Pumps_control"  v-if="facility_type==='5'" 
                                    :columns="pump_columns"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                    :totalRecords = "totalRecords5"
                                   @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"                                
                                    @on-row-click="onRowClick"   
                                  
                              
                    />   
                      <ServerSideTable1 ref="Valves_control"  v-if="facility_type==='6'" 
                                    :columns="valve_columns"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                       
                                    :max_height="table_height1+'px'"
                                    :totalRecords = "totalRecords6"
                                    @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"                                
                                    @on-row-click="onRowClick"   
                                 
                   />   
                     </b-col>      
            </b-tab>
            <b-tab  :title="$t('inp_file_info.tab_title4')">
                 <b-row >
                   
                      <b-col cols="1">
                              Reinbow 
                       </b-col>
                            <b-col class="text-center layers-table__item" cols="5" >
                              <input v-model="color_arry[0][0]" type="color" size="sm" style="background: transparent;" disabled />                           
                              <input v-model="color_arry[0][1]" size="sm" type="color" style="background: transparent;" disabled />
                              <input v-model="color_arry[0][2]" type="color" size="sm" style="background: transparent;" disabled/>                           
                              <input v-model="color_arry[0][3]" size="sm" type="color" style="background: transparent;" disabled/>
                              <input v-model="color_arry[0][4]" type="color" size="sm" style="background: transparent;" disabled/>                           
                             
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                           
              
                      <b-col cols="1">
                              Blue 
                       </b-col>
                            <b-col class="text-center layers-table__item" cols="5" >
                              <input v-model="color_arry[1][0]" type="color"  style="background: transparent;" disabled />                           
                              <input v-model="color_arry[1][1]" type="color" style="background: transparent;" disabled />
                              <input v-model="color_arry[1][2]" type="color" style="background: transparent;" disabled />                           
                              <input v-model="color_arry[1][3]" size="sm" type="color" style="background: transparent;" disabled />
                              <input v-model="color_arry[1][4]" type="color" style="background: transparent;" disabled />                           
                             
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                           
                </b-row>
                 <b-row >
                     
                      <b-col cols="1">
                              Orange 
                       </b-col>
                            <b-col class="text-center layers-table__item" cols="5" >
                              <input v-model="color_arry[2][0]" type="color" size="sm" style="background: transparent;" disabled />                           
                              <input v-model="color_arry[2][1]" size="sm" type="color" style="background: transparent;" disabled />
                              <input v-model="color_arry[2][2]" type="color" size="sm" style="background: transparent;" disabled />                           
                              <input v-model="color_arry[2][3]" size="sm" type="color" style="background: transparent;" disabled/>
                              <input v-model="color_arry[2][4]" type="color" size="sm" style="background: transparent;" disabled/>                           
                            </b-col>
                      <b-col cols="1">
                              Purple 
                       </b-col>
                       <b-col class="text-center layers-table__item" cols="5" >
                              <input v-model="color_arry[3][0]" type="color" size="sm" style="background: transparent;" disabled />                           
                              <input v-model="color_arry[3][1]" size="sm" type="color" style="background: transparent;" disabled />
                              <input v-model="color_arry[3][2]" type="color" size="sm" style="background: transparent;" disabled />                           
                              <input v-model="color_arry[3][3]" size="sm" type="color"  style="background: transparent;" disabled/>
                              <input v-model="color_arry[3][4]" type="color" size="sm" style="background: transparent;" disabled />                           
                             
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                      </b-col>
                           
                </b-row>
               <b-col sm=12 :key="listKey1">  
                    <div class="layers-table mt-3">
                        <b-row class="mb-3 ml-0 mr-0 layers-table-head">
                          <b-col class="form-title mb-0 font-weight-medium" cols="3">
                            Legend
                          </b-col>
                          <b-col class="form-title mb-0 layers-table__item text-center font-weight-medium" cols="1">
                            Auto
                          </b-col>
                          <b-col class="form-title mb-0 layers-table__item text-center font-weight-medium" cols="3">
                            Value
                          </b-col>
                          <b-col class="form-title mb-0 layers-table__item text-center font-weight-medium" cols="2">
                            Unit
                          </b-col>
                          <b-col class="form-title mb-0 layers-table__item text-center font-weight-medium" cols="3">
                            Color
                          </b-col>
                      
                    </b-row> 
                    </div>
                    <div class="layers-table-row" >
                          <b-row >
                            <b-col cols="3">
                              elevation 
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.elevation.auto" size="sm" class="layers-table__item"/>
                            </b-col>

                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.elevation.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.elevation.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                             
                                <b-select  size="sm" v-model="setting.setting_info.elevation.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row>
                            <b-row >
                            <b-col cols="3">
                              base_demand 
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.base_demand.auto" size="sm" class="layers-table__item"/>
                            </b-col>

                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.base_demand.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.base_demand.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.base_demand.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row>
                          <b-row >
                            <b-col cols="3">
                              init_quality 
                            </b-col>
                           <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.init_quality.auto" size="sm" class="layers-table__item"/>
                            </b-col>                            
                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.init_quality.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.init_quality.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.init_quality.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row>
                          <b-row >
                            <b-col cols="3">
                              demand 
                            </b-col>
                             <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.demand.auto" size="sm" class="layers-table__item"/>
                            </b-col>                              
                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.demand.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.demand.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.demand.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row>
                            <b-row >
                            <b-col cols="3">
                              head 
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.head.auto" size="sm" class="layers-table__item"/>
                            </b-col>                              
                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.head.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.head.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.head.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row>
                           <b-row >
                            <b-col cols="3">
                              pressure 
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.pressure.auto" size="sm" class="layers-table__item"/>
                            </b-col>                              
                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.pressure.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.pressure.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.pressure.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row> 
                          <b-row >
                            <b-col cols="3">
                              quality 
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.quality.auto" size="sm" class="layers-table__item"/>
                            </b-col>                                 
                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.quality.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.quality.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.quality.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row>  
                          <b-row >
                            <b-col cols="3">
                              flow 
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.flow.auto" size="sm" class="layers-table__item"/>
                            </b-col>                                
                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.flow.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.flow.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.flow.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row>   
                           <b-row >
                            <b-col cols="3">
                              velocity 
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.velocity.auto" size="sm" class="layers-table__item"/>
                            </b-col>                              
                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.velocity.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.velocity.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.velocity.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row>    
                           <b-row >
                            <b-col cols="3">
                              Pipe_quality 
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.link_quality.auto" size="sm" class="layers-table__item"/>
                            </b-col>                              

                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.link_quality.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.link_quality.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.link_quality.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row> 
                           <b-row >
                            <b-col cols="3">
                              unit_headloss 
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="1" >
                               <b-checkbox v-model="setting.setting_info.unit_headloss.auto" size="sm" class="layers-table__item"/>
                            </b-col>                                
                            <b-col class="text-center layers-table__item" cols="3" >
                               <b-input v-model="setting.setting_info.unit_headloss.set" size="sm"/>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2">
                              <b-input v-model="setting.setting_info.unit_headloss.unit" size="sm"/>
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="3" >
                              <b-select  size="sm" v-model="setting.setting_info.unit_headloss.color_set" :options="f_color_option" ></b-select>
                            </b-col>
                          </b-row>                                                          
                    </div>
               </b-col>               
            </b-tab>            
            <b-tab title="Setting(View)"  style="height:600px;">
              <b-row>
                    <b-col sm=3 ></b-col>
                    <b-col sm="3">
                        <label>Node size</label>
                    </b-col>
                    <b-col sm="3">
                        <b-input size="sm" v-model="setting.setting_info.view.node_size" ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                      <b-col sm=3 ></b-col>
                    <b-col sm="3">
                        <label>Proportional to node value</label>
                    </b-col>
                    <b-col sm="3" style="margin-top: -10px;">
                        <b-checkbox v-model="setting.setting_info.view.node_size_propor" class="layers-table__item"></b-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                      <b-col sm=3 ></b-col>
                    <b-col sm="3">
                        <label>Display node value</label>
                    </b-col>
                    <b-col sm="3" style="margin-top: -10px;">
                       <b-checkbox v-model="setting.setting_info.view.node_value" class="layers-table__item"></b-checkbox>
                    </b-col>
                </b-row>                                     
                <b-row>
                      <b-col sm=3 ></b-col>
                    <b-col sm="3">
                        <label>Link Size</label>
                    </b-col>
                    <b-col sm="3">
                        <b-input size="sm" v-model="setting.setting_info.view.link_size" ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                      <b-col sm=3 ></b-col>
                    <b-col sm="3">
                        <label>Proportional to link value</label>
                    </b-col>
                    <b-col sm="3" style="margin-top: -10px;">
                        <b-checkbox v-model="setting.setting_info.view.link_size_propor" class="layers-table__item"></b-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                      <b-col sm=3 ></b-col>
                    <b-col sm="3">
                        <label>Display link value </label>
                    </b-col>
                    <b-col sm="3" style="margin-top: -10px;">
                        <b-checkbox v-model="setting.setting_info.view.link_value" class="layers-table__item"></b-checkbox>
                    </b-col>                    
                </b-row>
                 <b-row>
                      <b-col sm=3 ></b-col>
                    <b-col sm="3">
                        <label>Display link arrow</label>
                    </b-col>
                    <b-col sm="3" style="margin-top: -10px;">
                        <b-checkbox v-model="setting.setting_info.view.link_arrow" class="layers-table__item"></b-checkbox>
                    </b-col>                    
                </b-row>
           
                <!--b-row>
                    <b-col sm=3 ></b-col>
                    <b-col sm="3">
                        <label>Display link value</label>
                    </b-col>
                    <b-col sm="3">
                        <b-checkbox v-model="item.visible" class="layers-table__item">{{item.name}}</b-checkbox>
                    </b-col>
                    
                </b-row-->
            
 
              
            </b-tab>
         
           </b-tabs>
<!--
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="apply()">Apply</b-button>
                
                <b-button @click="cancel1()">Close</b-button>
            </template>
-->
           <template v-slot:modal-footer="{  }">

              <b-row class="modal-footer-row m-0 leak_foot">
               <b-col sm="6">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">Close</b-button>
               </b-col>
               <b-col sm="6">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">Apply</b-button>
               </b-col>              

             </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;
import ServerSideTable1 from '@/components/table/ServerSideTable_simulation';

import { Project, Workspace,NodeProperty,CountType,Option, NodeType, LinkType, LinkProperty} from 'epanet-js';
//import { Project, Workspace} from 'epanet-js';
export default {
    name: 'DlgM2MonitoringSetting',
    props: {
    },
    watch:{
      facility_type(){
        setTimeout(()=>{  
          this.go_table_view();
        }, 100);
      }

    },
    data() {
        return {
            listKey: 0 ,
            listKey1: 0 ,
            facility_type : "1",
            view_type : "node",
            properties: null,
            properties1: null,            
            table_height1 : "570",
            table_height : "250",
            totalRecords1:0,
            totalRecords2:0,
            totalRecords3:0,
            totalRecords4:0,
            totalRecords5:0,
            totalRecords6:0,
            properties2 : {
                  "junctions" : "m,bar,kgf,psi",
                  "reservoirs" : "Level(m),E_Level(m)" ,
                  "tanks" : "Level(m),E_Level(m)",
                  "pipes" : "Flow(m3/hr)",
                  "pumps" : "Flow(m3/hr)", 
                  "valves" : "Flow(m3/hr)"
            },
            properties_bak: null,
            set1: true,
            set2: true,
            set3: true,
            
            p_mode : '',
            recount: 0,
            myWorkItem : false,
            ele_data : 0,
            measure_mode:1,
 
            columns: [
                {label: 'Color type', field: 'color_type',sortable: false},
                {label: 'Color1', field: 'color1',sortable: false},
                {label: 'Color2', field: 'color2',sortable: false},
                {label: 'Color3', field: 'color3',sortable: false},
                {label: 'Color4', field: 'color4',sortable: false},
                {label: 'Color5', field: 'color5',sortable: false},
            ],
            table: [],
            flow_unit_option : [
                { value: 4, text: 'AFD' },
                { value: 0, text: 'CFS' },
                { value: 9, text: 'CMD' },
                { value: 8, text: 'CMH' },
                { value: 1, text: 'GPM' },
                { value: 3, text: 'IMG' },
                { value: 6, text: 'LPM' },
                { value: 5, text: 'LPS' },
                { value: 2, text: 'MGD' },
                { value: 7, text: 'MLD' }
            ],
            demand_model_option : [
                { value: 0, text: 'DDA' },
                { value: 1, text: 'PDA' },
            ],
            head_loss_option : [
                { value: 0, text: 'H-W' },
                { value: 1, text: 'D-W' },
                { value: 2, text: 'C-M' },
            ],
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            f_color_option : [
                { value: '0', text: 'Rainbow' },
                { value: '1', text: 'Blue' },
                { value: '2', text: 'Orange' },
                { value: '3', text: 'Purple' },
            ],
            file_model : null, 
            g_ws : null,
            reportFile : null,              
            Junctions : [], //node-junction을 넣어준다.
            Reservoirs : [],
            Tanks : [],
            Pipes :[],
            Pumps:[],
            Valves : [],
            setting : {},
            model_json : {},
                jun_columns: [
                  //  {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Elev.', field: 'elevation', sortable: false,  width: '20%',},
                    {label: 'Demand', field: 'demand',width: '20%',},
                    {label: 'Pattern', field: 'pattern',width: '10%',},  
                  //  {label: 'Control', field: 'control_yn',width: '10%',}, 
                     {label: 'Value', field: 'value_yn',width: '10%',},
                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
                  //  {label: 'Value', field: 'set1',width: '10%',},
                  
                ], 
            reser_columns : [
          //  {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Head', field: 'head', sortable: false,  width: '20%',},
                    {label: 'Pattern', field: 'pattern',width: '10%',},  
               //     {label: 'Control', field: 'control_yn',width: '10%',}, 
                    {label: 'Value', field: 'value_yn',width: '10%',},
                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ],     
           tank_columns : [
          //  {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Elev.', field: 'elevation', sortable: false,  width: '10%',},
                    {label: 'InitLevel', field: 'InitLevel',width: '10%',},
                    {label: 'MinLevel', field: 'MinLevel',width: '10%',},  
                    {label: 'MaxLevel', field: 'MaxLevel',width: '10%',},  
                    {label: 'Diameter', field: 'diameter',width: '10%',},  
                    {label: 'MinVol', field: 'MinVol',width: '10%',},  
                    {label: 'VolCurve', field: 'VolCurve',width: '10%',},  
                    {label: 'Overflow', field: 'overflow',width: '10%',}, 
              //      {label: 'Cont.', field: 'control_yn',width: '5%',}, 
                    {label: 'Value', field: 'value_yn',width: '5%',},
                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ], 
            pipe_columns : [
           // {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Node1', field: 'node1', sortable: false,  width: '10%',},
                    {label: 'Node2', field: 'node2',width: '10%',},
                    {label: 'Len.', field: 'length',width: '10%',},                  
                    {label: 'Dia.', field: 'diameter',width: '10%',},  
                    {label: 'Rough.', field: 'roughness',width: '10%',},  
                    {label: 'Minor.', field: 'minorLoss',width: '10%',},  
                    {label: 'Status', field: 'status',width: '5%',}, 
                //    {label: 'Con.', field: 'control_yn',width: '5%',}, 
                    {label: 'Val.', field: 'value_yn',width: '5%',},
                    
                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ],    
            pump_columns : [
                //    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Node1', field: 'node1', sortable: false,  width: '10%',},
                    {label: 'Node2', field: 'node2',width: '10%',},  
                    {label: 'Parameters', field: 'parameters',width: '10%',}, 
               //     {label: 'Cont.', field: 'control_yn',width: '5%',}, 
                    {label: 'Val.', field: 'value_yn',width: '5%',},

                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ],                                             
 
            valve_columns : [
                //    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Node1', field: 'node1', sortable: false,  width: '20%',},
                    {label: 'Node2', field: 'node2',width: '10%',},  
                    {label: 'Diameter', field: 'diameter',width: '10%',}, 
                    {label: 'Type', field: 'type',width: '10%',},
                    {label: 'Setting', field: 'setting',width: '10%',},
                    {label: 'MinorLoss', field: 'minorLoss',width: '10%',},
                 //   {label: 'Cont.', field: 'control_yn',width: '5%',}, 
                    {label: 'Val.', field: 'value_yn',width: '5%',},

                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ],                                             
            columns2: [
                //    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '10%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '10%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'E.L', field: 'el', sortable: false,  width: '20%',},
                    {label: 'Value', field: 'value',width: '20%',},        
                   {label: 'code', field: 'code', hidden: true, },
                ],     
            columns3: [
                 //   {label: this.$t('gis.table_column.map'), field: 'go_map', width: '10%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '10%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Status', field: 'status', sortable: false,  width: '20%',},
                    {label: 'Flow', field: 'value',width: '20%',},        
                   {label: 'code', field: 'code', hidden: true, },
             ],                      
            columns1: [
                    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '10%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '10%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Set 1', field: 'set1', sortable: false,  width: '20%',},
                    {label: 'Set 2', field: 'set2',width: '20%',},        
                     {label: 'code', field: 'code', hidden: true, },
                ], 
             //rainbow, blue, orange,  purple    
            color_arry : [["#0000ff","#00ffff","#00ff00","#ffff00","#ff0000"],
            ["#eaeaea","#9999ff","#3333ff","#0000cc","#000080"],
            ["#fffbf0","#ffcc99","#ff9933","#cc6600","#993300"],
            ['#ffccff','#ff99ff','#cc00ff','#9900cc','#660066']]    
        }
    },

    mounted () {
        this.default_model_setting();
    },
    methods: {
      onRowClick(params) {
        //선택된 색상 보여지게
    //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
        if(this.clicked_b_row != null){
            this.clicked_b_row.bgColor = '';
        //  //  this.clicked_b_row.style.backgroundColor = '';
        }
        
        if(params.event.target.nodeName==='TD'){
            params.event.target.parentElement.bgColor  = '125744';          
            this.clicked_b_row = params.event.target.parentElement ;
        }else  if(params.event.target.nodeName==='IMG'){
            params.event.target.parentElement.parentElement.parentElement.bgColor = '125744';      
            this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
        }else{
            params.event.target.parentElement.parentElement.bgColor = '125744';      
            this.clicked_b_row = params.event.target.parentElement.parentElement ;
        }  
      },  

      on_facility_info(row) {
       this.$emit('show_model_f_info',row) ;
       //this.$refs.map.show_model_info(row.id)
      }, 
      //type => 0:cntrol, 1: value, check 0 => false, 1 : true
      set_node_links(array_links,idx, type,check){ 
        for(var i = 0 ; i <this.array_links.length ; ++i){
          if(array_links[i].idx === idx){
            if(type === 0){ //control
              if(check === 1){  //true
                array_links[i].control_yn = true;
              }else{ //false
                array_links[i].control_yn = false;
              }
            }else{ //value
              if(check === 1){  //true
                array_links[i].value_yn = true;
              }else{ //false
                array_links[i].value_yn = false;
              }
            }
          }
        }
      },         
      set_node_links_return_idx(array_links, idx){ 
        for(var i = 0 ; i <array_links.length ; ++i){
          if(array_links[i].idx === idx){
             return i
          }
        }
      },         

      on_changeCell(row,col) {
            var changeData = row[col]
            //var column =  col+"11";
            var row_idx =  row.idx
            var del_index = 0
            var arr_idx = -1
            if(col === "control_yn"){
              
                if(changeData){  
                   if(row.code1=="junction") {                   
                      this.setting.set_contrl.Junctions.push(row_idx)
                      this.Junctions[row_idx-1].control_yn = true;
                   }else if(row.code1=="reservoir"){
                      this.setting.set_contrl.Reservoirs.push(row_idx)  
                      arr_idx = this.set_node_links_return_idx(this.Reservoirs,row_idx)
                      this.Reservoirs[arr_idx].control_yn = true;   
                   }else if(row.code1=="tank"){
                      this.setting.set_contrl.Tanks.push(row_idx)   
                      arr_idx = this.set_node_links_return_idx(this.Tanks,row_idx)
                      this.Tanks[arr_idx].control_yn = true;   
                   }else if(row.code1=="pipe"){
                      this.setting.set_contrl.Pipes.push(row_idx)   
                      this.Pipes[row_idx-1].control_yn = true;
                      //this.set_node_links( this.Tanks,row_idx,0,1)          
                   }else if(row.code1=="valve"){
                      this.setting.set_contrl.Valves.push(row_idx)   
                     // this.set_node_links(this.Valves,row_idx,0,1) 
                      arr_idx = this.set_node_links_return_idx(this.Valves,row_idx)
                      this.Valves[arr_idx].control_yn = true;   

                   }else if(row.code1=="pump"){
                      this.setting.set_contrl.Pumps.push(row_idx)    
                     // this.set_node_links(this.Pumps,row_idx,0,1)       
                      arr_idx = this.set_node_links_return_idx(this.Pumps,row_idx)
                      this.Pumps[arr_idx].control_yn = true;   
                   }

                }else{
                   if(row.code1=="reservoir") {   
                       del_index = this.setting.set_contrl.Reservoirs.indexOf(row_idx) 
                      if (del_index > -1) {
                         this.setting.set_contrl.Reservoirs.splice(del_index, 1);
                          //type => 0:cntrol, 1: value, check 0 => false, 1 : true
                         //this.set_node_links(this.Reservoirs,row_idx,0,0) 
                         arr_idx = this.set_node_links_return_idx(this.Reservoirs,row_idx)
                         this.Reservoirs[arr_idx].control_yn = false;  
                      }
                   }else  if(row.code1=="junction") {   
                       del_index = this.setting.set_contrl.Junctions.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Junctions.splice(del_index, 1);
                           this.Junctions[row_idx-1].control_yn = false;
                        }
                   }else  if(row.code1=="tank") {   
                       del_index = this.setting.set_contrl.Tanks.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Tanks.splice(del_index, 1);
                           //this.set_node_links(this.Tanks,row_idx,0,0) 
                           arr_idx = this.set_node_links_return_idx(this.Tanks,row_idx)
                           this.Tanks[arr_idx].control_yn = false;  
                        }
                   }else  if(row.code1=="pipe") {   
                       del_index = this.setting.set_contrl.Pipes.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Pipes.splice(del_index, 1);
                           this.Pipes[row_idx-1].control_yn = false;
                        }
                  }else  if(row.code1=="valve") {   
                       del_index = this.setting.set_contrl.Valves.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Valves.splice(del_index, 1);
                          // this.set_node_links(this.Valves,row_idx,0,0)
                            arr_idx = this.set_node_links_return_idx(this.Valves,row_idx)
                            this.Valves[arr_idx].control_yn = false;   
                        }
                  }else  if(row.code1=="pump") {   
                       del_index = this.setting.set_contrl.Pumps.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Pumps.splice(del_index, 1);
                          // this.set_node_links(this.Pumps,row_idx,0,0)
                            arr_idx = this.set_node_links_return_idx(this.Pumps,row_idx)
                            this.Pumps[arr_idx].control_yn = false;    
                        }
                    }

                }
                /*
                if(row.code==1100)
                 this.control_setting(1);
                else
                  this.control_setting(2);
                */  
               // alert(this.setting.set_contrl.Junctions)
            }else if(col === "value_yn"){
                
                if(changeData){  
                   if(row.code1=="junction"){                    
                        this.setting.set_value.Junctions.push(row_idx)
                        this.Junctions[row_idx-1].value_yn = true;
                   }else if(row.code1=="reservoir"){
                        this.setting.set_value.Reservoirs.push(row_idx)  
                        //this.set_node_links( this.Reservoirs,row_idx,1,1)  
                        arr_idx = this.set_node_links_return_idx(this.Reservoirs,row_idx)
                        this.Reservoirs[arr_idx].value_yn = true;        
                   }else if(row.code1=="tank"){
                        this.setting.set_value.Tanks.push(row_idx)     
                        this.set_node_links( this.Tanks,row_idx,1,1)  
                        arr_idx = this.set_node_links_return_idx(this.Tanks,row_idx)
                        this.Tanks[arr_idx].value_yn = true;            
                   }else if(row.code1=="pipe"){
                        this.setting.set_value.Pipes.push(row_idx)   
                        this.Pipes[row_idx-1].value_yn = true;        
                   }else if(row.code1=="valve"){
                        this.setting.set_value.Valves.push(row_idx)          
                       // this.set_node_links( this.Valves,row_idx,1,1) 
                        arr_idx = this.set_node_links_return_idx(this.Valves,row_idx)
                        this.Valves[arr_idx].value_yn = true; 
                   }else if(row.code1=="pump"){
                        this.setting.set_value.Pumps.push(row_idx) 
                        //this.set_node_links( this.Pumps,row_idx,1,1)  
                        arr_idx = this.set_node_links_return_idx(this.Pumps,row_idx)
                        this.Pumps[arr_idx].value_yn = true;          
                   }

                }else{
                   if(row.code1=="reservoir") {   
                       del_index = this.setting.set_value.Reservoirs.indexOf(row_idx) 
                      if (del_index > -1) {
                         this.setting.set_value.Reservoirs.splice(del_index, 1);
                         //this.set_node_links(this.Reservoirs,row_idx,1,0) 
                         arr_idx = this.set_node_links_return_idx(this.Reservoirs,row_idx)
                         this.Reservoirs[arr_idx].value_yn = false;
                      }
                   }else  if(row.code1=="junction") {   
                       del_index = this.setting.set_value.Junctions.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_value.Junctions.splice(del_index, 1);
                           this.Junctions[row_idx-1].value_yn = false;  
                        }
                   }else  if(row.code1=="tank") {   
                       del_index = this.setting.set_value.Tanks.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_value.Tanks.splice(del_index, 1);
                          // this.set_node_links(this.Tanks,row_idx,1,0) 
                           arr_idx = this.set_node_links_return_idx(this.Tanks,row_idx)
                           this.Tanks[arr_idx].value_yn = false;
                        }
                   }else  if(row.code1=="pipe") {   
                       del_index = this.setting.set_value.Pipes.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_value.Pipes.splice(del_index, 1);
                           this.Pipes[row_idx-1].value_yn = false;        
                        }
                  }else  if(row.code1=="valve") {   
                       del_index = this.setting.set_value.Valves.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Valves.splice(del_index, 1);
                         //  this.set_node_links(this.Valves,row_idx,1,0) 
                           arr_idx = this.set_node_links_return_idx(this.Valves,row_idx)
                           this.Valves[arr_idx].value_yn = false;
                        }
                  }else  if(row.code1=="pump") {   
                       del_index = this.setting.set_contrl.Pumps.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_value.Pumps.splice(del_index, 1);
                           //this.set_node_links(this.Pumps,row_idx,1,0) 
                           arr_idx = this.set_node_links_return_idx(this.Pumps,row_idx)
                           this.Pumps[arr_idx].value_yn = false;
                        }
                    }

                }
                /*
                if(row.code==1100)
                 this.value_set(1);
                else
                  this.value_set(2);
                */  
            }
            //this.mediaPlanData[row][column] = changedData
        },    
      on_goto_map_click(row) {
        this.$emit('on_goto_map_click',row) ;
      },           
      go_table_view(){
        if(this.facility_type === '1'){
            this.$refs.junction_control.setUrl(this.Junctions) ; 
        }else if(this.facility_type === '2'){
            this.$refs.Reservoirs_control.setUrl(this.Reservoirs) ;  
        }else if(this.facility_type === '3'){        
          this.$refs.Tanks_control.setUrl(this.Tanks) ;  
        }else if(this.facility_type === '4'){
             this.$refs.Pipes_control.setUrl(this.Pipes) ;  
        }else if(this.facility_type === '5'){
             this.$refs.Pumps_control.setUrl(this.Pumps) ;   
        }else if(this.facility_type === '6'){  
            this.$refs.Valves_control.setUrl(this.Valves) ;  
        }
      },
      default_model_setting(){
        //
       // var valve_coff = {0 : 0, 5:0.05, 10 : 0.1, 15:0.15, 20 : 0.2,
       //                30:0.3,40:0.4,50:0.5,60:0.6,70:0.7,80:0.8,90:0.9,100:1 };
        /*               
        var valve_type = {'Junctions':"m,bar,psi,kgf", 
                       'Reservoirs':  "Level(m),E_Level(m)",
                       'Tanks' : "Level(m),E_Level(m)",
                       'Pipes' : "Flow(㎥/hr)",
                       'Pumps':   "Flow(㎥/hr)",
                       'Valves':  "Flow(㎥/hr)",                                             
                        } ;              
        */
         var node_tot = {'Junctions': 0, 
                       'Reservoirs':  0,
                       'Tanks' : 0 };
         var link_tot = {'Pipes' : 0,
                         'Pipes_length' : 0,
                         'Pumps':   0,
                         'Valves':  0,                                             
                        } ;                
        var setting_info = {'elevation': { "set" :  "25,50,75,100","color_set" : 0,"unit" : 'm'}, 
                       'base_demand':  { "set" :  "25,50,75,100","color_set" : 0,"unit" : "LPS"},
                       'init_quality' : { "set" : "0.25,0.50,0.75,1.00","color_set" : 0,"unit":""},
                       'demand' : { "set" : "25,50,75,100","color_set" : 0,"unit" : "LPS"},
                       'head':  { "set" :  "25,50,75,100","color_set" : 0 ,"unit" : 'm' },
                       'pressure': {"set" : "25,50,75,100","color_set" : 0,"unit" : 'm'},     
                       'quality': {"set" : "0.25,0.50,0.75,1.00","color_set" : 0 ,"unit":""}, 
                       'length': {"set" : "100,500,1000,5000","color_set" : 0 ,"unit":"m"} ,
                       'diameter': {"set" : "6,12,24,36","color_set" : 0 ,"unit":"mm"} ,
                       'roughness': {"set" : "50,75,100,125","color_set" : 0 ,"unit":"mm"} ,
                       'flow': {"set" : "25,50,75,100","color_set" : 0 ,"unit":"LPS"} ,
                       'velocity': {"set" : "0.01,0.10,1.00,2.00","color_set" : 0 ,"unit":"m/s"} , 
                       'link_quality': {"set" : "0.25,0.50,0.75,1.00","color_set" : 0 ,"unit":""},  
                       'unit_headloss': {"set" : "0.03,0.05,0.08,0.10","color_set" : 0 ,"unit":"m/km"},                                        
                       'view' : {"node_size": 5, "node_size_propor": true, "node_value" : true,
                        "link_size": 2, "link_size_propor": true, "link_value" : true , "link_arrow" : false }
                        } ; 
        //각각 값들의 간격을 저장한다.      
        /*          
        var setting_value_term = {'Flow': { "set1" : "Demand"}, 
                       'Reservoirs':  { "set1" : "Elevation"},
                       'Tanks' : { "set1" : "Elevation", "set2" : "Level"},
                       'Pipes' : { "set1" : "OPEN/CLOSE", "set2" : "COFFICIENT"},
                       'Pumps':  { "set1" : "OPEN/CLOSE" },
                       'Valves': { "set1" : "OPEN/CLOSE", "set2" : "Open rate(%)"},                                             
                        } ;                 
        */                        
        var set_contrl = {'Junctions':[1], //값 셋팅을 위해서 index값만 저장한다.
                       'Reservoirs':  [],
                       'Tanks' : [],
                       'Pipes' : [],
                       'Pumps':   [],
                       'Valves':  []}  
        var set_value = {'Junctions':[1], 
                       'Reservoirs':  [],
                       'Tanks' : [],
                       'Pipes' : [],
                       'Pumps':   [],
                       'Valves':  []}  
        this.setting = {"set_value" : set_value, //값을 보여줄 index
                        "set_contrl" : set_contrl,//값을 제어할 index
                        "setting_info" : setting_info, 
                        "node_tot" : node_tot,
                        "link_tot"  : link_tot}                       
    },   
    importTextFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'text/plain'; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"
      // this 접근을 위해 선언 필요
      const self = this;
      input.onchange = function () {
          const file = new FileReader();
          file.onload = () => {
          //    document.getElementById('text-data').textContent = file.result;
            //  self.$data.textData = file.result;
              self.file_text = file.result
              self.draw_model(file.result); 
            
          };
          self.read_file_name = this.files[0].name ;
          self.properties['file_name'] = this.files[0].name ;
          file.readAsText(this.files[0]);
      };
      input.click();
      // const fs = require("fs");
      // const net1 = fs.readFileSync("net1.inp");
      // Runs toolkit methods: EN_open, EN_solveH & EN_close
    },           
     InpfileDownload() {
       var model_id = this.properties['id']
      // var f_name =  model_id+"_"+this.box[model_id].file_name;
       var f_name =  model_id+"_"+this.properties['file_name'];
       this.file_model.saveInpFile(f_name) ;
          //console.log(save_text)
            const ws = new Workspace()
            const inpFile = ws.readFile(f_name);
            //console.log(inpFile)
            this.inpfile_dwnload(f_name, inpFile)
            //nodecnt : 압력값을 가져온다.
            
            //lint : 유량값을 가져온다.
            
           // document.getElementById("epanet-report").innerHTML = reportFile + "<br/>" +nodeIds + "<br/>"+linkIds;
           //  ++this.listKey;
           //this.file_model.close();
    },
    inpfile_dwnload(f_name,text){            
            
        var blob = new Blob([text], {type:'text/plain'});
        var link = document.createElement("a");
        link.download = f_name;
        link.innerHTML = "Download File";
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        }, 100);
    },
    draw_model(file_text){       
        if(this.file_model !== null){
            this.file_model.close();
        }  
        const ws = new Workspace()
        this.file_model = new Project(ws)
        ws.writeFile("net2.inp", file_text);

        this.g_ws = ws
        this.file_model.open("net2.inp", "report.rpt", "out.bin");

        //this.file_model.runProject("net2.inp", "report.rpt", "out.bin");
       
    
        

        this.file_model.solveH();
       
        this.properties['flow_unit'] = this.file_model.getFlowUnits();
        this.properties['head_loss'] = this.file_model.getOption(Option.HeadlossForm);
        this.properties['demand_model'] = this.file_model.getDemandModel().type;

        this.reportFile = ws.readFile("report.rpt");
        this.getNodes_return();
        this.getLinks_return();
        //junction 데이터를 만들어준다.
         
        this.model_json = {'Junctions':this.Junctions, 
                       'Reservoirs':  this.Reservoirs,
                       'Tanks' : this.Tanks,
                       'Pipes' : this.Pipes,
                       'Pumps':  this.Pumps, 
                       'Valves': this.Valves,
                       'setting' : this.setting                                             
                        } ;
      
         
       // this.on_facility_info(this.tag) ;                       
     },
     loading_model(){
      this.draw_model(this.file_text);
       /*
        const ws = new Workspace()
        const model = new Project(ws)
        ws.writeFile("net2.inp", this.file_text);

        model.open("net2.inp", "report.rpt", "out.bin");
        model.solveH();
        //   const nodeCount = model.getCount(CountType.NodeCount);  
        //   const linkCount = model.getCount(CountType.NodeCount);                  
        //const nodeIds = this.getNodes_return(model);
        //const linkIds = this.getLinks_return(model);
        
        // model.close();
        this.file_model = model;
        this.reportFile = ws.readFile("report.rpt");
        this.getNodes_return();
        this.getLinks_return();
        */
        //nodecnt : 압력값을 가져온다.
        
        //lint : 유량값을 가져온다.
         


        //document.getElementById("epanet-report").innerHTML = reportFile + "<br/>" +nodeIds + "<br/>"+linkIds;
        //  ++this.listKey;
        //document.getElementById("epanet-report").innerHTML = "model.node count : " + cnt;
   
    },
        getLinks_return() {
            this.Pipes = [] ;
            this.Pumps = [] ;
            this.Valves = [] ;
            const model = this.file_model;
            var abox =null;
            const linkount = model.getCount(CountType.LinkCount);      
            let total_length = 0 ;
            for (let i = 1; i <= linkount; i++) {                
                //const linkId = model.getLinkId(i);
             //   var control_yn = false ;
             //   var value_yn = false ;
                if(model.getLinkType(i) === LinkType.Pipe) {
                    const reserId = model.getLinkId(i);                   
                    abox = {};
                    //if (this.setting.set_contrl.Pipes.indexOf(i) > -1)  control_yn = true ; 
                    //if (this.setting.set_value.Pipes.indexOf(i) > -1) value_yn = true;
                    abox = {'idx':  i, 
                       // 'id': model.getLinkId(i),
                        'id': reserId,
                        'node1' : model.getLinkNodes(i).node1, // 고도값을 가져온다.
                        'node2' : model.getLinkNodes(i).node2, //               
                        'length' : Math.round(model.getLinkValue(i,LinkProperty.Length)*100)/100, //
                        'diameter' : Math.round(model.getLinkValue(i,LinkProperty.Diameter)*100)/100, //
                        'roughness' :Math.round( model.getLinkValue(i,LinkProperty.Roughness)*100)/100, //
                        'minorLoss' : Math.round(model.getLinkValue(i,LinkProperty.MinorLoss)*100)/100, //  
                        'value': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량
                       // 'value_yn' : value_yn,
                       // 'control_yn' : control_yn,
                       'value_yn' : this.setting.set_value.Pipes.indexOf(i) > -1 ? true : false,
                       'control_yn' : this.setting.set_contrl.Pipes.indexOf(i) > -1 ? true : false,
                        'status' : model.getLinkValue(i,LinkProperty.Status) == 1 ? "Open" : "Close", //
                        'code' : 1101 , //map layer_id 라인스트링 layer
                        'code1' : 'pipe',        
                    } ; 
                    total_length +=  model.getLinkValue(i,LinkProperty.Length) ;
                    this.Pipes.push(abox)
                }else if(model.getLinkType(i) === LinkType.Pump) {
                    const reserId = model.getLinkId(i);                   
                    abox = {};
                    abox = {'idx':  i, 
                       // 'id': model.getLinkId(i),
                        'id': reserId,
                        'node1' : model.getLinkNodes(i).node1, // 고도값을 가져온다.
                        'node2' : model.getLinkNodes(i).node2, //               
                        'parameters' : model.getLinkValue(i,LinkProperty.PumpPower), //   
                        'status': model.getLinkValue(i,LinkProperty.Status),
                        'value': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량                        
                        'value_yn' : this.setting.set_value.Pumps.indexOf(i) > -1 ? true : false,
                        'control_yn' : this.setting.set_contrl.Pumps.indexOf(i) > -1 ? true : false,
                        'code' : 1101 , //map layer_id 라인스트링 layer   
                        'code1' : 'pump',                
                    } ; 
                    this.Pumps.push(abox)
                }else{ //valves
                    abox = {};
                    abox = {'idx':  i, 
                        'id': model.getLinkId(i),
                        'node1' : model.getLinkNodes(i).node1, // 고도값을 가져온다.
                        'node2' : model.getLinkNodes(i).node2, //               
                        'type' : model.getLinkType(i), //valve_type종류
                        'diameter' : model.getLinkValue(i,LinkProperty.Diameter), //
                        'setting' : model.getLinkValue(i,LinkProperty.Setting), //
                        'minorLoss' : model.getLinkValue(i,LinkProperty.MinorLoss), //               
                        'value': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량
                        'code' : 1101 , //map layer_id 라인스트링 layer
                        'code1' : 'valve',   
                        'value_yn' : this.setting.set_value.Valves.indexOf(i) > -1 ? true : false,
                        'control_yn' : this.setting.set_contrl.Valves.indexOf(i) > -1 ? true : false,             
                    } ; 
                    this.Valves.push(abox)           
                }                
            }//for문 끝
           

         
            this.setting.link_tot = {'Pipes' : this.Pipes.length,
                         'Pipes_length' : Math.round(total_length*100)/100,
                         'Pumps':   this.Pumps.length,
                         'Valves':  this.Valves.length,                                             
                        } ;  
          //   ++this.listKey1;
          
      },
 
     getNodes_return() {
        //지도에 map
       // var ext = [];

         const model = this.file_model;
         const nodeCount = model.getCount(CountType.NodeCount);            
         this.Junctions = [] ; //node-junction을 넣어준다.
         this.Reservoirs = [] ;
         this.Tanks = [] ;
         //  this.nodeIds = [];
         var abox =null;
      
         //  let str = 'Node Results:<br/>';
         for (let i = 1; i <= nodeCount; i++) {
            var control_yn = false ;
            var value_yn = false ;
            if (model.getNodeType(i) === NodeType.Junction) {
               // const nodeId = model.getNodeId(i);
               
               if (this.setting.set_contrl.Junctions.indexOf(i) > -1)  control_yn = true ; 
               if (this.setting.set_value.Junctions.indexOf(i) > -1) value_yn = true;
               abox = {};
            
               abox = {'idx':  i, 
                       'id': model.getNodeId(i),
                       'elevation' : model.getNodeValue(i,NodeProperty.Elevation) > 0 ? Math.round(model.getNodeValue(i,NodeProperty.Elevation)*10)/10 :model.getNodeValue(i,NodeProperty.Elevation) , // 고도값을 가져온다.
                       'demand' : model.getNodeValue(i,NodeProperty.Demand)> 0 ? Math.round(model.getNodeValue(i,NodeProperty.Demand)*1000)/1000 :model.getNodeValue(i,NodeProperty.Demand),  // 고도값을 가져온다.
                       'pattern':  model.getNodeValue(i,NodeProperty.Pattern), //패턴을 가져온다.       
                       'value': Math.round(model.getNodeValue(i, NodeProperty.Pressure)*100)/100 ,    // 압력계산값을 가져온다.                                
                       'value_yn' : value_yn,
                       'control_yn' : control_yn,
                       'code' : 1100 , //map point layer      
                       'code1' : 'junction',  
               } ; 
               this.Junctions.push(abox) ; 
            }else if(model.getNodeType(i) === NodeType.Tank) {
            //  const tankId = model.getNodeId(i);
               
              abox = {};
            
               abox = {'idx':  i, 
                       'id': model.getNodeId(i),
                       'elevation' : model.getNodeValue(i,NodeProperty.Elevation), // 고도값을 가져온다.
                       'InitLevel' : model.getNodeValue(i,NodeProperty.InitVolume), //
                       'MinLevel' : model.getNodeValue(i,NodeProperty.MinLevel), //
                       'MaxLevel' : model.getNodeValue(i,NodeProperty.MaxLevel), //
                       'diameter' : model.getNodeValue(i,NodeProperty.TankDiam), // 고도값을 가져온다.
                       'MinVol':  model.getNodeValue(i,NodeProperty.MinVolume), //패턴을 가져온다.                                            
                       'VolCurve' : model.getNodeValue(i,NodeProperty.VolCurve), //   
                       'value': Math.round(model.getNodeValue(i, NodeProperty.TankLevel)*100)/100 ,    // Level을 가져온다.   
                       'code' : 1100 , //map point layer       
                       'code1' : 'tank',       
                        'value_yn' : this.setting.set_value.Tanks.indexOf(i) > -1 ? true : false,
                       'control_yn' : this.setting.set_contrl.Tanks.indexOf(i) > -1 ? true : false,     
               } ; 
               this.Tanks.push(abox) ;
            }else if(model.getNodeType(i) === NodeType.Reservoir) {
             //   const reserId = model.getNodeId(i);
               
                 abox = {};
            
               abox = {'idx':  i, 
                       'id': model.getNodeId(i),
                       'head' : model.getNodeValue(i,NodeProperty.Head), // 고도값을 가져온다.
                       'pattern' : model.getNodeValue(i,NodeProperty.Pattern), //       
                       'value': Math.round(model.getNodeValue(i, NodeProperty.Elevation)*100)/100 ,    // Level을 가져온다.   
                       'code' : 1100 , //map point layer        
                       'code1' : 'reservoir',
                       'value_yn' : this.setting.set_value.Reservoirs.indexOf(i) > -1 ? true : false,
                       'control_yn' : this.setting.set_contrl.Reservoirs.indexOf(i) > -1 ? true : false,                  
               } ; 
               this.Reservoirs.push(abox) ;
            }
          }//for문 끝
          this.$refs.junction_control.setUrl(this.Junctions) ; 
          //this.$refs.Reservoirs_control.setUrl(this.Reservoirs) ;  
          //this.$refs.Tanks_control.setUrl(this.Tanks) ;  
          this.setting.node_tot =   {'Junctions':this.Junctions.length, 
                                     'Reservoirs': this.Reservoirs.length,
                                     'Tanks' : this.Tanks.length,};
          //++this.listKey;
         //
     }, 
       checkValue(type,event) {
          var objTarget = event.srcElement || event.target;
          var value = objTarget.value;
          if(type === 1 && (value < 1 || value > 288)) {             
             alert(this.$t('m2_point_setting.alt_1_288'));
             this.properties['press_measure_count'] = this.properties_bak['press_measure_count'] ;
          }else if(type === 2 && (value < 0 || value > 24)) {
             alert(this.$t('m2_point_setting.alt_0_24'));
             this.properties['leak_sound_measure_interval'] = this.properties_bak['leak_sound_measure_interval'];
          }else if(type === 3 && (value < 60 || value > 1440)) {
             alert(this.$t('m2_point_setting.alt_60_1440'));
             this.properties['water_flow_measure_interval'] = this.properties_bak['water_flow_measure_interval'];
          }
        },
        show(id) {
             this.properties = [] ;
             this.g_ws = null
             this.file_model = null   
             //this.setting = null          
           //  this.default_model_setting();
            // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
             this.properties1 =[];
             this.table = [{"Rainbow":"0", "coefficient" : ""},
             {"Blue":"5%", "coefficient" : ""},
             {"Orange":"10%", "coefficient" : ""},
             {"Purple":"15%", "coefficient" : ""},          
             
             ]     
             /*
             this.properties1 = 
                 {"junctions" : "Demand",
                  "reservoirs" : "Elevation" ,
                  "tanks" : "Elevation,Level",
                  "pipes" : "OPEN(1) /CLOSE(0),COEFFICIENT",
                  "pumps" : "OPEN(1) /CLOSE(0)", 
                  "valves" : "OPEN(1) /CLOSE(0), Opening rate(%)"
                 }
             ;
             */
            // this.$http.get(this.URL+'api/get_press_info?idx='+idx).then((res) => {
             //setting값을 먼저가져와서, 그 후에 데이터를 앉힌다.     
             
             this.$http.get(this.URL+'api/get_model_info_setting?id='+id).then((res) => {
                this.setting = res.data ;

                if(this.setting === null || this.setting === ""){
                    this.default_model_setting();
                }else{
                    this.setting = Object.assign({},  res.data) ;
                }

             
                this.$http.get(this.URL+'api/get_model_info?id='+id).then((res) => {

                    if(res.data.length<=0) return           
                    //setting 값을 가져온다.
             
                    this.properties = res.data.list[0]; 
                    var inp_text = res.data.list[0].inp_file;
                    this.properties_bak = Object.assign({}, this.properties)
                    this.draw_model(inp_text)
                //  if(sessionStorage.m_id === this.properties.worker )
                //       this.myWorkItem = true
                
                    
                //    let p_type = this.properties['point_type'] ;
                    // 
                //    let ps_type = ['ALL',' Water Leak',' Water Pressure' ,' Water Flow'  ]
                //   this.properties['point_type']  = ps_type[p_type] 
    
                 
               });
             }) 
        
            
             this.$refs.modal.show();          
    

           },
         insert_show() {
           this.properties = [] ;
           // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
            this.properties1 =[];
            this.table = [{"Rainbow":"0", "coefficient" : ""},
            {"Blue":"5%", "coefficient" : ""},
            {"Orange":"10%", "coefficient" : ""},
            {"Purple":"15%", "coefficient" : ""},          
            
            ]     
             
             this.default_model_setting();       
             this.$refs.modal.show();          
    

         },
         del(){
           var con_test1 = confirm("Are you sure you want to delete?");
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test1){
              this.$http.get(this.URL+'api/delete_ex?p_idx_ex='+this.properties['id']).then((res) => {  
                 console.log(res.data);
                 this.$emit('leaktable_refresh') ;
                 this.$refs.modal.hide();
              });

           }
        },
        apply() {
          //  if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === undefined){
              //  alert("Can not be modified.")
                return
         //   }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 1){
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
            let params = {} ;
            let model_info = {};  
            
            this.file_model.close();        
            const reportFile = this.g_ws.readFile("report.rpt");
            if( this.properties['id'] === null ||  this.properties['id'] === undefined ){
               
                params["customer_id"] = sessionStorage.select_customer_id;
                params["customer_member_id"] = sessionStorage.m_idx;
                params["name"] = this.properties['name'];
                params["file_name"] = this.read_file_name;
                params["inp_file"] = this.file_text;
                params["rpt_file"] = reportFile;
                params["inp_setting"] =  encodeURIComponent(JSON.stringify(this.setting));
                this.$http.post(this.URL+'api/set_modeling_upload' ,params).then((res) => {
                console.log(res.data);
                if(res.data.check ===1){
                    alert("fail : "+ res.data.msg) ;
                }else{
                    alert("success !! : "+ res.data.new_id) ;
                }
                //left_list를 refresh한다.
                })
            }else{
              let keys = Object.keys(this.properties_bak)
              for(let i=0; i<keys.length; i++) {
                if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                model_info[keys[i]] = this.properties[keys[i]]
              }
            //  if(Object.keys(model_info).length<=0 ) return
                params["id"] = this.properties['id'] ;
                model_info["inp_setting"] =  encodeURIComponent(JSON.stringify(this.setting));
                params["fields"] = encodeURIComponent(JSON.stringify(model_info))
               if(Object.keys(params).length>0){
                  this.$http.post(this.URL+'api/set_modeling_upload' ,params).then((res) => {
                     console.log(res.data);
                     if(res.data.check ===1){
                        alert("fail : "+ res.data.msg) ;
                     }else{
                        alert("Update success!!")
                        this.$emit('search'); 
                     }
                  })   
               }       
            }
             
            this.$refs.modal.hide();    
          
        
           
        }    
    },
    components: {
         //   FFTChart1:FFTChart1
        //  PressBigChartData:PressBigChartData,
         ServerSideTable1: ServerSideTable1,        
    }
}
</script>

<style  lang="scss">


.facility__label-radio {
  font-size: 12px;
  color: #616161;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}
.tab-content {
    max-height: 720px;    
    overflow-y: auto;    
    overflow-x: hidden; 
}
.my-modal {
  .modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    margin: 70px auto 0;
    background: #F2F2F2;
    width: 550px;
    height: calc(100% - 70px);
    .modal-header {
      padding-bottom: 22px;
      .modal-title {
        font-size: 16px;
        margin-top: 12px;
      }
    }
    .modal-info-content {
      border: 0;
      border-radius: 0;
 
      &.reversal {
        background: #F2F2F2;
        .nav-tabs {
          background: #F2F2F2;
        }
        .tab-content {
          background: #fff;
          &.is--scroll {
            height: 73vh;
            overflow-y: auto;
          }
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
          background: #fff;
        }
        .facility__label {
          color: #616161;
          font-size: 12px;
          display: inline-block;
          letter-spacing: 0.5px;
          //width: 35%;
        }
        .facility__input {
          //width: 60%;
          height: 32px;
          font-size: 12px;
          border: 1px solid #D9D9D9;
          box-sizing: border-box;
          border-radius: 0;
          margin-top: 0;
          margin-bottom: 14px;
          &.height--auto {
            height: auto;
          }
        }
        .modal-footer {
          background: #fff;
          padding-bottom: 18px;
          padding-top: 3px;
        }
        .modal-footer-row {
          width: 100%;
          margin: 0;
          padding-bottom: 0;
        }
        .btn.modal-footer__button {
          height: 52px;
          font-size: 14px;
          border: 0;
          &.modal-footer__button-ok {
            background: #28B7CB;
          }
          &.modal-footer__button-cancel {
            background: #9D9D9D;
          }
        }
        .photo-card {
          border-radius: 0;
          border: 0;
          background: none;
          .img-thumbnail {
            margin-bottom: 10px;
          }
          .card-header {
            height: 150px;
            background: #F2F2F2;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .card-header__text {
              font-size: 16px;
              color: rgba(3,3,3,0.4);
            }
          }
        }
      }
    }
  }
}
</style>
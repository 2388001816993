
<template>
    <div  :style="{height: height+'px'}">
        <line-chart ref="chart" v-bind:chartData="chartData" v-bind:yopt="yopt" v-bind:xdata="xdata" v-if="loaded"
          v-bind:chart_type ="chart_type" v-bind:size="size" class="wavchart"></line-chart>
    </div>
  
</template>

<script>
//import * as d3 from 'd3'BlockLineChart
//import LineChart from '@/components/LineChart1' 
import LineChart from '@/components/chart/BlockLineChart'
//import {Line} from 'vue-chartjs'
import * as d3 from 'd3'
export default {
    name: 'BlockMonRealTime',
 //   extends: Line,
    components: {LineChart },    
    props: [
        'height',
        'url'
    ],
    watch: {
        bidx: function() {
          this.redraw();
        },
        url() {
            if(this.url==='') return

            this.loaded=false;
            this.xdata = [];
            this.chartData = [];
            var s_url = this.url.split("#")
            //itype 1 : m2 유량 상세페이지 실시간,
            //2 :  m2 유량 chart페이지 실시간,
            //3 :  m1 유량fhrj chart페이지 실시간,

            //&retType= data_cnt_time_interval
            this.total_size = 0;
            this.interval = 0 ;
            var url_a = s_url[0].split("&retType=")
            if(url_a.length >1){
              var vchart = url_a[1].split("_") ; //vchart[0]:               
              this.total_size = Number(vchart[0])
              this.interval = Number(vchart[1])
            }

            this.itype = Number(s_url[1]) ;
             this.chart_type = null;
             
            this.size = (this.itype === 0 || this.itype === 3)  ? 'firstClass' : (this.itype === 1  ? 'firstClass1' : 'firstClass2');
            
            this.loaded=false;
            var parseTime1 = d3.timeParse("%Y-%m-%d %H:%M:%S");
            this.chart_type = "area" ;
            if(this.itype ===3)
               parseTime1 = d3.timeParse("%Y-%m-%d %H:%M");
            else  if(this.itype ===2){
               parseTime1 = d3.timeParse("%Y-%m-%d");
               this.chart_type = 'column'
            }

            this.$http.get(s_url[0]).then((res) => {
               if( res.data !=="" ){
                  
                  let ts_flow = null;
                  if(this.itype ===3){
                  // this.chart_type = "" ;
                   ts_flow = res.data
                  }else{
                    ts_flow = res.data.results[0]
                  }
               // var parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S.000Z");
               // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
                for(let i=0; i<ts_flow.length; i++) {
                 // var dd = new Date(ts_flow[i]['block_date_time']);
                  var ssdate1 = parseTime1(ts_flow[i]['measure_datetime']);
                 // this.xdata.push( dd)
                   
                   var sdate1 = Date.UTC(ssdate1.getFullYear(), ssdate1.getMonth(),  ssdate1.getDate(),ssdate1.getHours(),ssdate1.getMinutes(),ssdate1.getSeconds());
                   var value = ts_flow[i].water_flow
                   if(this.url.indexOf("api/API21/flow/day/") !== -1){
                       value = ts_flow[i].night_min_flow
                   }
                   this.chartData.push([sdate1,value])
                }
                for(let j = 0 ; j < this.total_size ; ++j){
                  let c_date =  this.chartData[this.chartData.length-1][0]
                          //c_date = c_date.setSeconds(c_date.getSeconds() +c_intrl);
                  c_date = c_date +this.interval*1000;
                   this.chartData.push([c_date, null])
                }
               }else {   
                  this.xdata = []
                  this.chartData =[] ;
               }
                ++this.bidx;

            });
        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        datacollection: null,
        chartData: [],
        size: 'firstClass',
        xdata : [],
        ymax : 1,
        xmax : 10,
        loaded: false,
        yopt : null,
        vchart : 0,
        bidx : 0,
        itype:0,
        total_size : 0,
        interval : 60, //60초
        //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
       //dataset: [65, 59, 80, 81, 56, 55, 40]
      // dataset :  this.fft_data ,
      }
    },
    methods: {
  
       redraw () {
           //  this.ymax = parseInt(this.$store.setting.wave.frequency.y) ;
           //  if(this.vchart === '0'){
            //    this.ymax = parseInt(this.$store.setting.wave.frequency.y) ;
                 this.yopt =  {
                      beginAtZero: true,       
                 //     max: this.ymax,
                  //    max: 10,
                      min: 0,     
                //      stepSize  : this.ymax/5
                    } ;
           //  }else{
          //      this.yopt = {  beginAtZero: true  } ;
           //  }
            

        //     this.xmax = parseInt(this.$store.setting.wave.frequency.x) ;
        //     this.xdata=[];  
      //         for (var i = 0;  i < this.chartData.length ; ++i) {
    //             this.xdata.push(i);
     //          }
              this.loaded = true;
            //  this.update();
           //   this.$data._chart.update()
           // this.chartData =  cData ;
            //this.$refs.chart.update();

      }
      

    
    },
  mounted () {
        window.addEventListener('resize', this.resize);
      //  if(this.url==='') return
      //  this.$http.get(this.url).then((res) => {
      //      this.chartData = res.data.slice(0, this.$store.setting.wave.frequency.x);
     //   });
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    }
    
}
</script>

<style lang="scss" scoped>
.firstClass{   height: calc(40vh - 20px) ;   width: 98%;  padding-top: 10px;  }
.firstClass2{   height: calc(30vh - 40px) ;   width: 100%;  padding-top: 10px;  }
.firstClass1{   height: calc(70vh - 40px) ; max-width: 1800px;  width: 100%;  padding-top: 10px;  }

.wavchart .highcharts-container{
    width: 100%;    
} 


</style>

<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
<!--                        <li class="breadcrumb-title">Map Frequency Comparison</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/leak-monitoring')" href="#">{{$t('leak_monitoring.m_title')}}</a></li>                       
                        <li class="breadcrumb-item active">{{$t('leak_monitoring.map_title')}}</li>
                    </ol>
                </li>
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div>
             <b-row style="margin: 0;">
                <b-col :cols="msize" style="padding: 0 10px 0 0;">
                    <div class="search_div m2_map">

                        <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="value"  @keyup.enter ="Enter_Check"/></span>
                        <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="go_search">{{$t('button.search')}}</button></span>
                        <!--span style="float:right;width:150px;padding: 0 10px 0 10px;">
                            <b-input size="sm" type="text" placeholder="datetime" v-model="flow_date"   />
                             <span style="position:absolute;top:0px;right:-155px;">
                                <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-10)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                                <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-1)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                                <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(1)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                                <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(10)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>
                             </span>
                        </span-->
                        <span style="float:right;width:250px;padding: 0 10px 0 10px;">
                           <span >
                                <b-input size="sm" type="date" placeholder="datetime" v-model="flow_date_min1"  style="width: 120px;" @change="change_flow_date_min1($event)"  />
                                <span style="position:absolute;top:0px;right:-50px;">
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-30*48*10)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-30*48)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(30*48)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(30*48*10)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>
                                    
                                </span>
                           </span>  
                           
                            <span > 
                                <b-input size="sm" type="time" placeholder="time" v-model="flow_date_min2" @change="change_flow_date_min2($event)" style="margin-top:2px;width: 120px;" />
                                <span style="position:absolute;top:34px;right:-50px;">
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-10*6)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-10)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(10)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(10*6)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style=" margin : 0px; padding-right: -5px; " >
                    <div style="overflow-y: scroll;" :style="{height: contents_height+'px'}">

                     <Map
                        ref="map"
                        :widx="$route.query.widx"
                        w_type="102"
                        :visible_gisload_button="true"
                        :visible_size_side_view_button="true"
                        :leak_l ="false"
                        :press_icon1 ="true"
                        @set_map_gisload="set_map_gisload"
                        @set_info="set_info"
                        @toggle_visible_all_layer = "toggle_visible_all_layer"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @toggle_visible_leak_type = "toggle_visible_leak_type"
                        @size_view="size_view"
                        @onLeakMapClick="onLeakMapClick()"
                     />
                      </div>
                     </div>
                 </b-col>
                 <b-col  v-if="v_chart" :cols="tsize" style=" padding: 0px 2px 0 0; ">
                   <b-row style=" padding-left: 0px;background-color:#172a46; margin: 0px 10px 0 -5px; ">
                       <b-col style="padding-top: 5px; padding-bottom: 5px;">

                           <div  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                           <div style=" position:absolute; top : 5px; left:-5px;z-index:102; ">
                                <img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg" style="width: 80%;"  @click="on_leakage_info(lidx1)"  />
                                <div> <img src="@/assets/images/new/ic_table_map.svg" alt="map"  style="width: 70%; margin: 10px 0;" @click="on_goto_map_click(p_idx1)" /> </div>    </div>
         
                            <div style=" position: relative;left:32%;width:53%;text-align:center;">
                                <div style="float:left;">
                                
                                    <span style="float:left;">  </span>
                                    <span style="float: right;padding-top:15px;">
                                    <b-input v-model="szoom1"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                    type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                    v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                                    
                                    </span> 
                                </div>
                                <span  style="float:left;margin-left: -40px;" >                            
                                    <audio id="slice_audio_id1" controls preload="auto" :src="slice_url1" style="transform:scale(0.7);height: 50px;">
                                    </audio>
                                </span>
                          </div>     
           
                        <div class="status leak" style="right:-10px;">             
                                        
                            <input type="radio" v-model="vchart1" value="0" id="fixed"> <label for="fixed">{{$t('leakage_info.fixed')}}</label>
                            <input type="radio" v-model="vchart1" value="1" id="proportional"><label for="proportional">{{$t('leakage_info.proportional')}}</label>
                        </div>
                        <div style='height:50px'>
                            <div id="hidden-container1">
                              <div id="waveform_1" ref="waveform_1" style="float : left; margin-left: 35px; width:95%;"/>
                              <div id="wave-timeline1" ref="wave-timeline1" style="float : left; margin-left: 35px; width:95%;"/>
                            </div>
                        </div>
                         <div  :style="{height: fftchart_height+ 'px'}" style='margin-left:-20px;'>
                            <pressChartData_fft :url="fft_url1" ref="fftchart1" :height="fftchart_height-30" style="width:100%; overflow: auto;" @onZoomClickEvent="onZoomClickEvent(1)"  />
                         </div>   
                        </div> 
                       </b-col>
                   </b-row>

                   <b-row style=" padding-left: 0px; background-color:#172a46; margin: 7px 10px 0 -5px;" >
                       <b-col style="padding-top: 10px; padding-bottom: 5px;">
 
                        <div  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                           <div style=" position:absolute; top : 0px; left:-5px;z-index:102; "> 
                                <img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg" style="width: 80%;"  @click="on_leakage_info(lidx2)"  />
                                <div> <img src="@/assets/images/new/ic_table_map.svg" alt="map"  style="width: 70%; margin: 10px 0;" @click="on_goto_map_click(p_idx2)" /> </div>  
                           </div>
                        
                           <div style=" position: relative;left:32%;width:53%;text-align:center;">
                            <div style="float:left;">
                            
                                <span style="float:left;">  </span>
                                <span style="float: right;padding-top:15px;">
                                <b-input v-model="szoom2"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                                
                                </span> 
                            </div>
                            <span  style="float:left;margin-left: -40px;" >                            
                                <audio id="slice_audio_id2" controls preload="auto" :src="slice_url2" style="transform:scale(0.7);height: 50px;">
                                </audio>
                            </span>
                          </div>     
            
                        <div class="status leak" style="right:-10px;">             
                                        
                            <input type="radio" v-model="vchart2" value="0" id="fixed"> <label for="fixed">{{$t('leakage_info.fixed')}}</label>
                            <input type="radio" v-model="vchart2" value="1" id="proportional"><label for="proportional">{{$t('leakage_info.proportional')}}</label>
                        </div>
                        <div style='height:50px'>
                            <div id="hidden-container2" >
                            <div id="waveform_2" ref="waveform_2" style="float : left; margin-left: 35px; width:95%;"/>
                            <div id="wave-timeline2" ref="wave-timeline2" style="float : left; margin-left: 35px; width:95%;"/>
                        </div>
                        </div>
                         <div   :style="{height: fftchart_height+ 'px'}" style='margin-left:-20px;'>
                            <pressChartData_fft :url="fft_url2" ref="fftchart2" :height="fftchart_height-30" style="width:100%; overflow: auto; 
                              " @onZoomClickEvent="onZoomClickEvent(2)"  />
                         </div>   
                       </div> 
                   </b-col>
                   </b-row>
                   <b-row style="  padding-left: 0px;background-color:#172a46; margin: 7px 10px 0 -5px;">
                       <b-col style="padding-top: 10px; padding-bottom: 5px;">
  
                           <div  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                           <div style=" position:absolute; top : 0px; left:-5px;z-index:102; ">
                                <img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg" style="width: 80%;"  @click="on_leakage_info(lidx3)"  />
                                <div> <img src="@/assets/images/new/ic_table_map.svg" alt="map"  style="width: 70%; margin: 10px 0;" @click="on_goto_map_click(p_idx3)" /> </div>  
                           </div>
                           <!--
                             <audio controls preload="auto" :src="wav3" style="width:60%;transform:scale(0.7);"/>
                              <div style="position:relative; left:30px;width: 95%;" id="waveform_3" ref="waveform_3"/>
                             <FFTChart1_c :url="fft_url3" ref="fftchart3" :height="fftchart_height"/>
                             -->
                           <div style=" position: relative;left:32%;width:53%;text-align:center;">
                            <div style="float:left;">
                            
                                <span style="float:left;">  </span>
                                <span style="float: right;padding-top:15px;">
                                <b-input v-model="szoom3"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                                
                                </span> 
                            </div>
                            <span  style="float:left;margin-left: -40px;" >                            
                                <audio id="slice_audio_id3" controls preload="auto" :src="slice_url3" style="transform:scale(0.7);height: 50px;">
                                </audio>
                            </span>
                          </div>     
                        
                        <div class="status leak" style="right:-10px;">             
                                        
                            <input type="radio" v-model="vchart3" value="0" id="fixed"> <label for="fixed">{{$t('leakage_info.fixed')}}</label>
                            <input type="radio" v-model="vchart3" value="1" id="proportional"><label for="proportional">{{$t('leakage_info.proportional')}}</label>
                        </div>
                        <div style='height:50px'>
                            <div id="hidden-container3" >
                            <div id="waveform_3" ref="waveform_3" style="float : left; margin-left: 35px; width:95%;"/>
                            <div id="wave-timeline3" ref="wave-timeline3" style="float : left; margin-left: 35px; width:95%;"/>
                        </div>
                        </div>
                         <div  :style="{height: fftchart_height+ 'px'}" style='margin-left:-20px;'>
                            <pressChartData_fft :url="fft_url3" ref="fftchart3" :height="fftchart_height-30" style="width:100%; overflow: auto; 
                              " @onZoomClickEvent="onZoomClickEvent(3)"  />
                         </div>   
                           </div>  

                       </b-col>
                   </b-row>
                 </b-col>
            </b-row>
         </div>

    </div>
</template>

<script>
import pressChartData_fft from '@/components/chart/pressChartData_fft';
import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';
import WaveSurfer from 'wavesurfer.js';
//import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions';

export default {
    name: 'LeakAnalysis1_c',
    props: {
    },
    data() {
        return {
            v_chart : true,
            contents_height: 600,
            waveform_height: 42,
            fftchart_height: 140,
            wavesurfer: null,
            layer_all_visible: true,
            chart_i : 0 ,
            fft_url1: '',
            fft_url2: '',
            fft_url3: '',
            lidx1 :'',
            lidx2 :'',
            lidx3 :'',
            p_idx1 :'', //지점 id
            p_idx2 :'',
            p_idx3 :'',
            wav1 : '',
            wav2 :'',
            wav3 : '' ,
            options: {
            },
            file: ' ' ,
            msize:'7',
            tsize:'5',
            value:'',
            sdata:0,
            slice_url1:'',
            szoom1:50,
            vchart1:1,
            slice_url2:'',
            szoom2:50,
            vchart2:1,
            slice_url3:'',
            szoom3:50,
            vchart3:1,
            roof:0,
            flow_date : '0001-01-01 00:00',
            flow_date_min1 : '0001-01-01'  ,
            flow_date_min2 :  "03:00",            
            start_idx:0,
            max_idx:2, //(0,1,2 까지 볼수 있다.)
        }
    },
    methods: {
         toggle_visible_all_layer(a) {

           if(a=== 1){
              // alert(a)
                this.$refs.map.leak_cal = 1;
           }else{
              //  alert(a)
                this.$refs.map.leak_cal = 0;
           }
           this.$refs.map.init_reload_leak_layer() ;
        },
        change_date_flow(a){
          let default_date = new Date(this.flow_date.toLocaleString());
          default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
          this.chang_press_mapvalue_type();
          //this.get_time_series_flow()
        },
       change_date_flow_min(a){
       
          let default_date = new Date(this.flow_date.toLocaleString());
          default_date.setMinutes(default_date.getMinutes()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date,1)
          this.flow_date_min1 = this.date_to_str(default_date,0)
          this.flow_date_min2 = this.date_to_str(default_date,2)
          this.chang_press_mapvalue_type();
          //this.get_time_series_flow()
        },
        change_flow_date_min1(e) {
            this.flow_date_min1 = e
            this.flow_date = this.flow_date_min1 + " "+ this.flow_date_min2
            this.chang_press_mapvalue_type();
        },
        change_flow_date_min2(e) {
            this.flow_date_min2 = e
            this.flow_date = this.flow_date_min1 + " "+ this.flow_date_min2
            this.chang_press_mapvalue_type();
        },        
        chang_press_mapvalue_type() {

          this.$refs.map.time_30m = this.flow_date; //시간
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;

        },  
        date_to_str1(format){
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 
          var hour = format.getHours();
           if(hour<10) hour = '0' + hour; 
          var min = format.getMinutes();
          if(min<10) min = '0' + min; 
     
          return year + "-" + month + "-" + date +" "+hour+":"+min;
        },        
        date_to_str(format,a){
          //a = 0 ,day, a=1 ㅡㅑㅜ
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 

          if(a > 0){
            var hour = format.getHours();
            if(hour<10) hour = '0' + hour; 
            var min = format.getMinutes();
            if(min<10) min = '0' + min; 
            if(a === 1)
                return year + "-" + month + "-" + date +" "+hour+":"+min;
            else
                return hour+":"+min;    
          }else{
            return year + "-" + month + "-" + date ;
          }
        },  
        toggle_visible_leak_type() {
            // 처음엔 leak type all 데이터를 가져와 지도에 보여준다.
            // All or LEAK 값을 가져와 지도에 보여준다.
            this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
            if(this.$refs.map.layer_leak_visible)
                this.$refs.map.leak_type = 'LEAK';
            else
                this.$refs.map.leak_type = null;
            
            //누수 레이어를 다시 그린다.
            this.$refs.map.init_reload_leak_layer() ;      
        },
        go_search(){
            this.$refs.map.go_search(this.value);
        },
        fn_press_han(event)
        {
            var objTarget = event.srcElement || event.target;
        // if(objTarget.type == 'text') {
            
            var value = objTarget.value;
            //alert(value);
            if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');
            // alert('Please enter only numbers');
            // event.preventDefault();          
            }        
        },  
        //20210427 
        onZoomClickEvent(a){
        var s_min = ""; //09-03 이렇게 들어온다
        var s_max = ""; //09-03 이렇게 들어온다
        if(a===1){
            s_min = this.$refs.fftchart1.select_min; //09-03 이렇게 들어온다
            s_max = this.$refs.fftchart1.select_max; //09-03 이렇게 들어온다
            this.slice_url1 = this.URL+'api/get_wave_slice?w_type=102&idx='+this.lidx1+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom1 ;
        }else if(a===2){
            s_min = this.$refs.fftchart2.select_min; //09-03 이렇게 들어온다
            s_max = this.$refs.fftchart2.select_max; //09-03 이렇게 들어온다
            this.slice_url2 = this.URL+'api/get_wave_slice?w_type=102&idx='+this.lidx2+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom2 ;
        }else if(a===3){
            s_min = this.$refs.fftchart3.select_min; //09-03 이렇게 들어온다
            s_max = this.$refs.fftchart3.select_max; //09-03 이렇게 들어온다
            this.slice_url3 = this.URL+'api/get_wave_slice?w_type=102&idx='+this.lidx3+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom3;
        }
        //  this.min_value = s_min;
        //  this.max_value = s_max;
        
        // this.slice_url = 'http://dev.neverlosewater.com/node/api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
        },                

        Enter_Check(){
         //  if(event.keyCode == 13){
          this.go_search();  // 실행할 이벤트
        },
        on_leakage_info(idx) {
          this.$refs.map.show_leakage_info_pop(idx,2)
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        on_goto_map_click(idx) {
            this.$refs.map.get_extent('w_type=102&p_idx='+idx).then((data)=>{
                this.$refs.map.set_fit_extent(data)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
             this.$refs.map.select_facility_feature(102,'',idx) 
        },
      
   
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
               alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                     if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    this.$refs.map.layer_list.push(newlayer) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);

                } //for문 끝

           } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },

        onLeakMapClick(){
            let leak_idx = this.$refs.map.leak_idx;
            let p_idx = this.$refs.map.leak_recov_idx;

            //leak_info를 쿼리해서 그 데이터를 wav,fft에 앉힌다.
            this.chart_i = this.chart_i+1 ;
            if(this.chart_i%3 === 1){
                this.lidx1 = leak_idx;    
                this.p_idx1 = p_idx;
                document.getElementById("hidden-container1").style.display = 'none';
                this.wavesurfer_1.load(this.URL+'api/get_wav/m2_water_leak/'+leak_idx);
                this.fft_url1 = this.URL+"api/get_fft/m2_water_leak/"+leak_idx+'#'+this.vchart1;
                var audio1 = document.getElementById('slice_audio_id1')
                audio1.load(); 
             
            }else if(this.chart_i%3 === 2){
                this.lidx2 = leak_idx;
                this.p_idx2 = p_idx;
                document.getElementById("hidden-container2").style.display = 'none';
                this.wavesurfer_2.load(this.URL+'api/get_wav/m2_water_leak/'+leak_idx);
                this.fft_url2 = this.URL+"api/get_fft/m2_water_leak/"+leak_idx+'#'+this.vchart2;
                var audio2 = document.getElementById('slice_audio_id2')
                audio2.load(); 
      
             
            }else{
                this.lidx3 = leak_idx;               
                this.p_idx3 = p_idx;
                document.getElementById("hidden-container3").style.display = 'none';
                this.wavesurfer_3.load(this.URL+'api/get_wav/m2_water_leak/'+this.lidx3);
                this.fft_url3 = this.URL+"api/get_fft/m2_water_leak/"+this.lidx3+'#'+this.vchart3;
                var audio3 = document.getElementById('slice_audio_id3') //초기화
                audio3.load(); 
             }

        },
        size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
        },
        create_wavesurfer(){
            this.wavesurfer_1 = WaveSurfer.create({
                container: '#waveform_1',
                waveColor: 'blue',
                progressColor: 'black',
                mediaControls: true,
            // autoCenter: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [
                ]
            });
            this.wavesurfer_1.on('finish', () => {
                this.is_playing1 = false
            });
            
            this.wavesurfer_1.on("loading", function () {
                document.getElementById("hidden-container1").style.display = '';
                var mediaElt = document.getElementById("waveform_1");
                mediaElt = mediaElt.querySelector('audio');
                mediaElt.style =  "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-10px;border:0; outline:0;" ;
            //  this.wavesurfer.params.mediaControls = true;
            });
            
            //
            this.wavesurfer_2 = WaveSurfer.create({
                container: '#waveform_2',
                waveColor: 'blue',
               // progressColor: 'black',
               // autoCenter: true,
                mediaControls: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [      
                //    TimelinePlugin.create({
                //        container: '#wave-timeline2',    
                 //   }),
                Regions.create({
                        regionsMinLength: 1,
                        regions: [
                            {
                                start: this.region_start,
                                end: this.region_end,
                                loop: false,
                                color: 'hsla(400, 100%, 30%, 0.5)',
                                drag: false,
                                resize: false
                            }, 
                        ],
                    })                
                ]
            });


             this.wavesurfer_2.on("loading", function () {
                document.getElementById("hidden-container2").style.display = 'block';
                var mediaElt1 = document.getElementById('waveform_2');
                var mediaElt = mediaElt1.querySelector('audio');
                 mediaElt.style = "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-10px;border:0; outline:0;" ;
            });

           //this.wavesurfer_2.load(this.URL+'api/get_wav/-1');
            //
            this.wavesurfer_3 = WaveSurfer.create({
                container: '#waveform_3',
                waveColor: 'blue',
                progressColor: 'black',
        //     autoCenter: true,
                mediaControls: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [
                ]
            });
      
            this.wavesurfer_3.on("loading", function () {
                document.getElementById("hidden-container3").style.display = '';
                var mediaElt = document.getElementById("waveform_3");
                mediaElt = mediaElt.querySelector('audio');
                mediaElt.style =  "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-10px;border:0; outline:0;" ;

            });

        }

    },
    mounted () {
        this.contents_height = window.innerHeight - 120;
        this.fftchart_height =  this.contents_height/3 - 60;
        setTimeout(()=>{
           var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
            if( elem !== undefined ){
                if(elem.length > 0){
                    document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();            
                }
            }
       }, 500)  
        var date = this.$route.query.mea_date
        this.flow_date = date    
        this.$refs.map.time_30m = this.flow_date; //시간   
        
        if(!(this.flow_date === undefined || this.flow_date === null)&& this.flow_date !== '0001-01-01 00:00'){
          this.flow_date_min1 =  date.substr(0,10);
          this.flow_date_min2 =  date.substr(11);
          setTimeout(()=>{         
            this.chang_press_mapvalue_type();
          }, 1000)
        }
        
        document.getElementById("hidden-container1").style.display = 'none';
        document.getElementById("hidden-container2").style.display = 'none';
        document.getElementById("hidden-container3").style.display = 'none';
        this.create_wavesurfer();       
        this.fft_url1 = this.URL+"api/get_fft/-1";
        this.fft_url2 = this.URL+"api/get_fft/-1";
        this.fft_url3 = this.URL+"api/get_fft/-1";
       
    },
    watch: {
      v_chart: function() {
          if(this.v_chart){
  
               setTimeout(()=>{
                   this.create_wavesurfer() ;
                   this.wavesurfer_1.load(this.URL+'api/get_wav/m2_water_leak/'+this.lidx1);
                   this.fft_url1 = this.URL+"api/get_fft/m2_water_leak/"+this.lidx1+"&roof="+(++this.roof)+'#'+this.vchart1;
                   this.wavesurfer_2.load(this.URL+'api/get_wav/m2_water_leak/'+this.lidx2);
                   this.fft_url2 = this.URL+"api/get_fft/m2_water_leak/"+this.lidx2+"&roof="+(++this.roof)+'#'+this.vchart2;
                   this.wavesurfer_3.load(this.URL+'api/get_wav/m2_water_leak/'+this.lidx3);
                   this.fft_url3 = this.URL+"api/get_fft/m2_water_leak/"+this.lidx3+"&roof="+(++this.roof)+'#'+this.vchart3;
                    var audio1 = document.getElementById('slice_audio_id1')
                    audio1.load(); 
                    var audio2 = document.getElementById('slice_audio_id2')
                    audio2.load(); 
                    var audio3 = document.getElementById('slice_audio_id3')
                    audio3.load();                 
            }, 500)

          }
      },
      vchart1() {
        var c_url = this.fft_url1.split('#') ;
        this.fft_url1 = c_url[0]+'#'+this.vchart1 ;
      },
       vchart2() {
        var c_url = this.fft_url2.split('#') ;
        this.fft_url2 = c_url[0]+'#'+this.vchart2 ;
      },
      vchart3() {
        var c_url = this.fft_url3.split('#') ;
        this.fft_url3 = c_url[0]+'#'+this.vchart3 ;
      },

    },
    components: {
        Map: Map,
    //    FFTChart1_c: FFTChart1_c,
        pressChartData_fft: pressChartData_fft,
    }
}
</script>

<style lang="scss" >
.infoimg{
    width: 15px;
}
.search_div.m2_map{
    position: absolute;
    left:180px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}
</style>

<template>
 
  <div :key="listKey">
   <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733"  />
   <pressChart_fft ref="fft_hchart" class="hchart" v-bind:size="size" v-bind:chartData="chartData"  v-bind:sdate="sdate" v-bind:interval="interval"  v-bind:ymax ="ymax"  v-if="loaded" @onZoomClickEvent="onZoomClickEvent"  ></pressChart_fft>
  </div>  
 
</template>

<script>
import pressChart_fft from '@/components/chart/pressChart_fft' ;
//import * as d3 from 'd3'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'pressChartData_fft',
    props: [
        'height',
        'url'
    ],
  watch: {
        chartData: function() {

              //   deep: true,
                   this.redraw();
                // handler(){this.renderChart(this.chartData, this.options); }
        },
        url() {
            if(this.url==='') return
           // datacollection: null,
          //  this.chartData= [];
     
          //this.chartData =  [];
       
         
          this.loaded=false;
           this.loaded_ts_flow=false;
      //    this.url = "http://localhost:3000/api/get_leak_bubble?w_idx=768" ;

      //   var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S.%L");
      //   var parseTime1 = d3.timeParse("%Y-%m-%d %H:%M");
   
           // datacollection: null,
           // this.chartData= [];
            this.xdata = [];
          //  this.datacollection= null;
          //  this.loaded=false;
            var url_a =this.url.split('#');
            if(url_a.length >1){
             this.vchart = url_a[1] ;
              if(this.vchart === '0'){
                this.ymax = parseInt(this.$store.setting.wave.frequency.y) ; 
              }else{
                this.ymax = null;
              }
            }
            this.$http.get(url_a[0]).then((res) => {
               if( res.data !==""  ){
                  this.chartData = res.data.slice(0, this.$store.setting.wave.frequency.x); 
                  //
                  var arr = this.chartData.slice(0,799)
                  var arr1 = this.chartData.slice(800,this.$store.setting.wave.frequency.x)
                  var low_max = Math.max(...arr);
                  var high_max = Math.max(...arr1);
                  this.low_max_hz = arr.indexOf(low_max)
                  this.high_max_hz = arr1.indexOf(high_max)+800
                 // alert(low_max+":"+this.low_max_hz+"/"+high_max+":"+this.high_max_hz)
               }else{    
                  this.chartData =[] ;
                  this.loaded_ts_flow=true;
               }
         //   });
         /*
         this.$http.get(this.url).then((res) => {
            //데이터 셋팅
              var b_data = res.data;
              this.chart_type = null;
             if( b_data !=="" && b_data != null) {
                //var b_data = res.data;
                //this.chartData = res.data.data    
                //this.chartData = res.data           
                
                 var ccdata = [];
                 var size = b_data.length 
               //  if(b_data.length > 400000){
                //    size = 400000 
                  //  start = 200000
                   
                 
                  for(let i=0; i < size ; ++i){
                    //this.xdata.push(b_data[i].idx+":"+b_data[i].w_title);
                   
                   // if(i === 0){
                    if(this.url.indexOf("api/API21/m2_press/minute/") !== -1){  
                       var ssdate1 = parseTime1(b_data[i].date_time);
                       var sdate1 = Date.UTC(ssdate1.getFullYear(), ssdate1.getMonth(),  ssdate1.getDate(),ssdate1.getHours(),ssdate1.getMinutes(),ssdate1.getSeconds());
                       ccdata.push([sdate1,b_data[i].tag_value]);

                    }else{
                       var ssdate = parseTime(b_data[i].date);
                       var sdate = Date.UTC(ssdate.getFullYear(), ssdate.getMonth(),  ssdate.getDate(),ssdate.getHours(),ssdate.getMinutes(),ssdate.getSeconds(),ssdate.getMilliseconds());
                       ccdata.push([sdate,b_data[i].press_data]);
                    }
                    //  console.log(ssdate )
                   // }
                  }
                  this.chartData = ccdata
               
                 
                
              
             }else{

                  this.xdata = [];
                 this.chartData = [] ;
                 
             }
             */
          })
          .catch((error) => {
           // this.loaded = true;
            this.xdata = [];
            this.chartData = [] ;           
            this.loaded_ts_flow = true
            console.log(error)
          });


        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        chart_type : null ,
        loaded_ts_flow: true,
        chartData:  [0,1,2,3,4,5],
        interval : 100,
        sdate: Date.UTC(2020, 0, 1,0,0,0,0),
        loaded: false,
        select_min : null,
        select_max : null,
        vchart : 0,
        ymax : null,
        listKey: 0,
        size : 'fftchart',
        low_max_hz: 0,
        high_max_hz: 0,
      }
    },
   methods: {
      updateKey: function () {
          this.listKey+= 1
       },
       redraw () {
           //   this.updateKey();
              this.loaded = true;
              this.loaded_ts_flow = true
            //  this.update();
           //   this.$data._chart.update()
           // this.chartData =  cData ;
            //this.$refs.chart.update();

      },
       onZoomClickEvent(){
         this.select_min = this.$refs.fft_hchart.select_min;
         this.select_max = this.$refs.fft_hchart.select_max;
         this.$emit('onZoomClickEvent') ;
      }
  
  },
  mounted () {
        window.addEventListener('resize', this.resize);
        let cur_page = ""+document.location.href ;
        if(cur_page.indexOf("leak-analysis1") !== -1 || cur_page.indexOf("-detail-map") !== -1 ||cur_page.indexOf("leak-logger-map") !== -1) {
          // this.control = 'float : left; margin-left: 0px;'
           this.size= 'fftchart1'
        }else{
          this.size = 'fftchart'
        }
        if(this.url==='') return

         //this.$http.get(this.url).then((res) => {
            //데이터 셋팅
         //    if( res.data !=="" && res.data != null) {
         //       this.chartData = res.data.data;
                /*
                for(let i=0; i < b_data.length ; ++i){
                  this.xdata.push(b_data[i].w_title);
                  //this.chartData1.push(b_data[i].leak_count) ;
                  //this.chartData2.push(b_data[i].leak_inside) ;
                  this.chartData1.push(10) ;
                  this.chartData2.push(20) ;
                  this.chartData3.push(b_data[i].other_count) ;

                }
                */
      //       }else{
      //            this.xdata = [];
                 
      //       }
      //    });


    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    },

    components: {
        PulseLoader: PulseLoader,
        pressChart_fft:pressChart_fft,
    }
}
</script>
<style lang="scss" scoped>
/*
.wavchart .hchart {
  width:90%;

}
.highcharts-container .highcharts-root {
    width: 750px;
    margin: 0 auto;
}
.wavchart svg.highcharts-root{
   max-width: 440px;

}
*/
.wavchart.firstClass .highcharts-container .highcharts-root {
    width: 98%;    
    margin: 0 auto;
}
.firstClass{   height: calc(40vh - 20px) ; margin: 0 auto; padding-top: 20px;  }
.firstClass2{   height: calc(30vh - 40px) ; margin: 0 auto;  padding-top: 10px;  }
.firstClass1{   height: calc(70vh - 40px) ; margin: 0 auto;max-width: 1800px;  padding-top: 10px;  }
</style>
<template>
    <div>
        <b-modal
            ref="modal"
            title="Model Facility Info"
            hide-backdrop          
            content-class="modal-info-content reversal"
            >
            <b-tabs content-class="facility-info-content is--scroll" align="center" v-if="properties!==null">
                <b-tab title="Properties"  >
                  <div v-if="junction" > 
                    <b-row>
                        <b-col sm="6">
                            <label>Node Type</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['code1']" disabled></b-input>
                        </b-col>
                    </b-row>     
                     <b-row>
                        <b-col sm="6">
                            <label>Index</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['idx']" disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>ID</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['id']" ></b-input>
                        </b-col>
                    </b-row>                                                         
                    <b-row>
                        <b-col sm="6">
                            <label>Elevation (m)</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['elevation']"  type="number" min=0 ></b-input>
                        </b-col>
                    </b-row>              
                    <b-row>
                        <b-col sm="6">
                            <label>Demand (㎥/hr)</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['demand']"  type="number" min=0 ></b-input>
                        </b-col>
                    </b-row>  
                     <b-row>
                        <b-col sm="6">
                            <label>Pattern</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['pattern']"  disabled></b-input>
                        </b-col>
                    </b-row>                     
                    <b-row>
                        <b-col sm="6">
                            <label>Head (m)</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['head']" disabled></b-input>
                        </b-col>
                    </b-row>        
                    <b-row>
                        <b-col sm="6">
                            <label>Initial Quality</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Initqual']"  type="number" min=0 ></b-input>
                        </b-col>
                    </b-row>    
                    <b-row>
                        <b-col sm="6">
                            <label>Source Quality</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Quality']" disabled></b-input>
                        </b-col>
                    </b-row>  
                    
                     <b-row>
                        <b-col sm="6">
                            <label>Pressure (m)</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['pressure']" disabled></b-input>
                        </b-col>
                    </b-row> 
                    <!--b-row>
                        <b-col sm="6">
                            <label>Control Setting</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['control_yn']" :options="yn_option" ></b-select>
                              
                        </b-col>
                    </b-row>
                       <b-row>
                        <b-col sm="12"> &nbsp;                           
                        </b-col>
                    </b-row-->
                    <b-row>
                        <b-col sm="6">
                            <label>Value Check</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['value_yn']" :options="yn_option" ></b-select>
                              
                        </b-col>
                    </b-row>                        
                  </div>
                  <div v-if="tank" > 
                    <b-row>
                        <b-col sm="6">
                            <label>Node Type</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['code1']" disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>Index</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['idx']" disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>ID</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['id']" ></b-input>
                        </b-col>
                    </b-row>                                                         
                    <b-row>
                        <b-col sm="6">
                            <label>Elevation (m)</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['elevation']"  type="number" min=0 ></b-input>
                        </b-col>
                    </b-row>              
                    <b-row>
                        <b-col sm="6">
                            <label>Initial Level</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['InitLevel']" disabled></b-input>
                        </b-col>
                    </b-row>  
                     <b-row>
                        <b-col sm="6">
                            <label>MinLevel</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['MinLevel']"  ></b-input>
                        </b-col>
                    </b-row>                    
                    <b-row>
                        <b-col sm="6">
                            <label>MaxLevel</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['MaxLevel']" ></b-input>
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col sm="6">
                            <label>TankDiam</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['TankDiam']" ></b-input>
                        </b-col>
                    </b-row>   
                    <b-row>
                        <b-col sm="6">
                            <label>MinVol</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['MinVol']" disabled></b-input>
                        </b-col>
                    </b-row>    
                    <b-row>
                        <b-col sm="6">
                            <label>Pressure (m)</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Pressure']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>Initial Quality</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Initqual']" ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>Quality</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Quality']" disabled></b-input>
                        </b-col>
                    </b-row>                                                                           
                    <!--b-row>
                        <b-col sm="6">
                            <label>Control Setting</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['control_yn']" :options="yn_option" disabled=""></b-select>
                              
                        </b-col>
                    </b-row>
                       <b-row>
                        <b-col sm="12"> &nbsp;                           
                        </b-col>
                    </b-row-->
                    <b-row>
                        <b-col sm="6">
                            <label>Value Check</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['value_yn']" :options="yn_option" ></b-select>
                              
                        </b-col>
                    </b-row>    
                  </div>
                  <div v-if="reservoirs" > 
                     <b-row>
                        <b-col sm="6">
                            <label>Node Type</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['code1']" disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>Index</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['idx']" disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>ID</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['id']" ></b-input>
                        </b-col>
                    </b-row>                                                         
                    <b-row>
                        <b-col sm="6">
                            <label>Total Head</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['head']"  disabled></b-input>
                        </b-col>
                    </b-row>              
                    <b-row>
                        <b-col sm="6">
                            <label>Pattern</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['pattern']" disabled></b-input>
                        </b-col>
                    </b-row>  
                     <b-row>
                        <b-col sm="6">
                            <label>Pressure</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['pressure']"  disabled></b-input>
                        </b-col>
                    </b-row>                    
                               <b-row>
                        <b-col sm="6">
                            <label>Initial Quality</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Initqual']" ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>Quality</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Quality']" disabled></b-input>
                        </b-col>
                    </b-row>                  
                    <!--b-row>
                        <b-col sm="6">
                            <label>Control Setting</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['control_yn']" :options="yn_option" disabled=""></b-select>
                              
                        </b-col>
                    </b-row>
                       <b-row>
                        <b-col sm="12"> &nbsp;                           
                        </b-col>
                    </b-row-->
                    <b-row>
                        <b-col sm="6">
                            <label>Value Check</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['value_yn']" :options="yn_option" ></b-select>
                              
                        </b-col>
                    </b-row>    
                  </div>
                  <div v-if="pipe" > 
                     <b-row>
                        <b-col sm="6">
                            <label>Link Type</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['code1']" disabled></b-input>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col sm="6">
                            <label>Index</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['idx']" disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>ID</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['id']" ></b-input>
                        </b-col>
                    </b-row>                                                         
                    <b-row>
                        <b-col sm="6">
                            <label>Node1</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['node1']"  disabled></b-input>
                        </b-col>
                    </b-row>              
                    <b-row>
                        <b-col sm="6">
                            <label>Node2</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['node2']" disabled ></b-input>
                        </b-col>
                    </b-row>  
                     <b-row>
                        <b-col sm="6">
                            <label>Length</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['length']"  type="number" min=0 ></b-input>
                        </b-col>
                    </b-row>                    
                    <b-row>
                        <b-col sm="6">
                            <label>Diameter</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['diameter']"  type="number" min=0 ></b-input>
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col sm="6">
                            <label>Roughness</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['roughness']"  type="number" min=0 ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>Loss Coeff</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['minorLoss']"  disabled></b-input>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col sm="6">
                            <label>Initial Status</label>
                        </b-col>
                        <b-col sm="6">                            
                              <b-select class="facility__input" size="sm" v-model="properties['status']" :options="oc_option" ></b-select>
                        </b-col>
                    </b-row>   
                    <b-row>
                        <b-col sm="6">
                            <label>Quality</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['LinkQual']" disabled ></b-input>
                        </b-col>
                    </b-row>    
                    <b-row>
                        <b-col sm="6">
                            <label>Velocity</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Velocity']" disabled></b-input>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col sm="6">
                            <label>Unit Headloss</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Headloss']" disabled></b-input>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col sm="6">
                            <label>Flow</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['flow']"  disabled></b-input>
                        </b-col>
                    </b-row>                                                                              
                    <!--b-row>
                        <b-col sm="6">
                            <label>Control Setting</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['control_yn']" :options="yn_option" disabled=""></b-select>
                              
                        </b-col>
                    </b-row>
                       <b-row>
                        <b-col sm="12"> &nbsp;                           
                        </b-col>
                    </b-row-->
                    <b-row>
                        <b-col sm="6">
                            <label>Value Check</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['value_yn']" :options="yn_option" ></b-select>
                              
                        </b-col>
                    </b-row>    
                  </div>      
                  <div v-if="pump" > 
                     <b-row>
                        <b-col sm="6">
                            <label>Link Type</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['code1']" disabled></b-input>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col sm="6">
                            <label>Index</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['idx']" disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>ID</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>                                                         
                    <b-row>
                        <b-col sm="6">
                            <label>Node1</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['node1']"  disabled></b-input>
                        </b-col>
                    </b-row>              
                    <b-row>
                        <b-col sm="6">
                            <label>Node2</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['node2']" disabled></b-input>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col sm="6">
                            <label>Pump Curve</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['PumpHCurve']"  ></b-input>
                        </b-col>
                    </b-row>
                   <b-row>
                        <b-col sm="6">
                            <label>Power</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['PumpPower']"  ></b-input>
                        </b-col>
                    </b-row>
               
                    <b-row>
                        <b-col sm="6">
                            <label>Pattern</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['LinkPattern']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>Effic. Curve</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['PumpECurve']"  ></b-input>
                        </b-col>
                    </b-row>                    
                    <b-row>
                        <b-col sm="6">
                            <label>Initial Status</label>
                        </b-col>
                        <b-col sm="6">                            
                              <b-select class="facility__input" size="sm" v-model="properties['status']" :options="oc_option" ></b-select>
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col sm="6">
                            <label>Energy Price</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['PumpECost']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                    <b-col sm="6">
                            <label>Price Pattern</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['PumpEPat']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>Headloss</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Headloss']"  disabled></b-input>
                        </b-col>
                    </b-row>                                        
      
                    <b-row>
                        <b-col sm="6">
                            <label>Flow</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['flow']"  disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>Quality</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['LinkQual']" ></b-input>
                        </b-col>
                    </b-row>                          
                    <!--b-row>
                        <b-col sm="6">
                            <label>Control Setting</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['control_yn']" :options="yn_option" disabled=""></b-select>
                              
                        </b-col>
                    </b-row>
                       <b-row>
                        <b-col sm="12"> &nbsp;                           
                        </b-col>
                    </b-row-->
                    <b-row>
                        <b-col sm="6">
                            <label>Value Check</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['value_yn']" :options="yn_option" ></b-select>
                              
                        </b-col>
                    </b-row>    
                  </div>
                  <div v-if="valve" > 
                    <b-row>
                        <b-col sm="6">
                            <label>Link Type</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['code1']" disabled></b-input>
                        </b-col>
                    </b-row>                        
                    <b-row>
                        <b-col sm="6">
                            <label>Index</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm"  v-model="properties['idx']" disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>ID</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>                                                         
                    <b-row>
                        <b-col sm="6">
                            <label>Node1</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['node1']"  disabled></b-input>
                        </b-col>
                    </b-row>              
                    <b-row>
                        <b-col sm="6">
                            <label>Node2</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['node2']" disabled></b-input>
                        </b-col>
                    </b-row>  
                     <b-row>
                        <b-col sm="6">
                            <label>Valve Type</label>
                        </b-col>
                        <b-col sm="6">
                            <!--b-input size="sm" v-model="properties['type']"  disabled></b-input-->
                            <b-select class="facility__input" size="sm" v-model="properties['type']" :options="valve_type_option" disabled="true"></b-select>
                        </b-col>
                    </b-row>                    
                    

                     <b-row>
                        <b-col sm="6">
                            <label>Diameter</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['diameter']"  ></b-input>
                        </b-col>
                    </b-row>                    
                    <b-row>
                        <b-col sm="6">
                            <label>Setting</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['setting']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>Loss Coeff.</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['minorLoss']"  ></b-input>
                        </b-col>
                    </b-row> 
                     <b-row>
                        <b-col sm="6">
                            <label>Status</label>
                        </b-col>
                        <b-col sm="6">
                             <b-select class="facility__input" size="sm" v-model="properties['status']" :options="status_option" ></b-select>
                        </b-col>
                    </b-row>                                       
                    <b-row>
                        <b-col sm="6">
                            <label>Flow</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['flow']"  disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="6">
                            <label>Velocity</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Velocity']" disabled></b-input>
                        </b-col>
                    </b-row>                     
                    <b-row>
                        <b-col sm="6">
                            <label>Headloss</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['Headloss']" disabled></b-input>
                        </b-col>
                    </b-row>                     
                    <b-row>
                        <b-col sm="6">
                            <label>Quality</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" v-model="properties['LinkQual']" ></b-input>
                        </b-col>
                    </b-row>    
                   
                                 
                    <!--b-row>
                        <b-col sm="6">
                            <label>Control Setting</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['control_yn']" :options="yn_option"></b-select>
                              
                        </b-col>
                    </b-row>
                       <b-row>
                        <b-col sm="12"> &nbsp;                           
                        </b-col>
                    </b-row-->
                    <b-row>
                        <b-col sm="6">
                            <label>Value Check</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['value_yn']" :options="yn_option" ></b-select>
                              
                        </b-col>
                    </b-row>    
                  </div>                                                            
                </b-tab>               
            </b-tabs>

            <template v-slot:modal-footer="{  }">
              
                <b-button @click="apply()">apply</b-button> 
                <b-button @click="cancel1()">Close</b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>
//import {getLength} from 'ol/sphere';
//import {Sphere} from 'ol/sphere';
import {transform} from 'ol/proj';
import {LineString } from 'ol/geom';
export default {
    name: 'DlgModelFacilityInfo',
    props: {
    },
    watch:{
       

    },
    data() {
        return {
            properties: null,
            properties_bak: null,
            junction: true,
            reservoirs: false,
            tank : false,
            pipe : false,
            pump : false,
            valve : false,

            info_u_YN : true,
            geo_u_YN : false,
            ditem :[],
            oc_option: [
            //    { value: null, text: 'Please select yes/no' },
               { value: 0, text: 'Close' },
               { value: 1, text: 'Open' },
            ],
            valve_type_option : [
               { value: 3, text: 'PRV' },
               { value: 4, text: 'PSV' },
               { value: 5, text: 'PBV' },
               { value: 6, text: 'FCV' },               
               { value: 7, text: 'TCV' },
               { value: 8, text: 'GPV' },
                
            ],
            yn_option: [
            //    { value: null, text: 'Please select yes/no' },
               { value: true, text: 'Yes' },
               { value: false, text: 'No' },
            ],
            columns: [
                {label: 'No', field: 'idx'},
                {label: 'Layer', field: 'layer'},
                {label: 'Title', field: 'w_title'},
                {label: 'Start Time', field: 'w_start_time'},
            ],
            //block안에 레이어 갯수 계산
            columns1: [
                {label: 'Layer', field: 'layer'},
                {label: 'Count', field: 'count'},
                {label: 'length', field: 'length'},
            ],        
            table1: [],    
            table: [],
            layer_type_option: [
                {value: 0, text: 'Pipe'},
                {value: 1, text: 'Valve'},
                {value: 2, text: 'Water Meter'},
                {value: 3, text: 'Pump'},
                {value: 4, text: 'Hydrant'},
                {value: 7, text: 'Block'},
                {value: 8, text: 'Water Treatment Plant'},
                {value: 9, text: 'Pumping Station'},
                {value: 10, text: 'Water Basin'},
                {value: 11, text: 'Chamber'},
                {value: 12, text: 'Gauge'},
                {value: 13, text: 'Other'},
            ],
            f_type_option: [],
            status_option: [
                { value: '', text: 'None' },
                { value: 1, text: 'Open' },
                { value: 0, text: 'Closed' },
            ],
            material_option: [],
            img1:'',
            img2:'',
            img3:'',
            b_show : false,
            vcount : 0,
            wcount : 0,
            vlength : 0,
            plength :0,
        }
    },

    mounted () {
    },
    methods: {
        show(model) {
            this.junction = false;
            this.reservoirs= false;
            this.tank = false;
            this.pipe = false;
            this.pump = false;
            this.valve = false;
            this.properties = null;
 
            if(model.code1 === 'junction'){
                this.junction = true
            }else if(model.code1 === 'tank'){
                 this.tank = true
            }else if(model.code1 === 'reservoir'){
                 this.reservoirs = true
            }else if(model.code1 === 'pipe'){
                 this.pipe = true
            }else if(model.code1 === 'pump'){
                 this.pump = true
            }else if(model.code1 === 'valve'){
                 this.valve = true
            }
            this.properties = model;
            this.properties_bak = Object.assign({}, this.properties);
            this.$refs.modal.show();

        },
        distanceBetweenPoints(latlng1, latlng2){
            //var line = new LineString([latlng1, latlng2]);
            var line = new LineString([transform(latlng1, "EPSG:900913",'EPSG:4326'), transform(latlng2, "EPSG:900913",'EPSG:4326')]);
            return Math.round(line.getLength() * 100) / 100;
        },
        converter_number(strNumber){

          let a = Number(strNumber) 

          if(isNaN(a)){
              return strNumber
          }else {
             return a
          }
            
        },
        apply() {

            
            this.$refs.modal.hide()
          
            let params = {} ;

            
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
               if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                  params[keys[i]] = this.converter_number(this.properties[keys[i]])
            }

            if(params !== null){
                params["idx"] = this.properties["idx"]
                params["code1"] = this.properties["code1"]
                this.$emit('unit_model_change',params) ;
            }

/*
            let cur_page = ""+document.location.href ;         
            if(cur_page.indexOf("gis-master") !== -1) { 
                this.$emit('facility_refresh',1) ;
            }
*/            
            //this.$refs.modal.hide(); 
       },
       cancel1(){
        //home =>gis_reload만
            if(this.geo_u_YN) { 
                this.$emit('facility_refresh',2) ; //수정분 날려보낸다.                
            }
            this.$refs.modal.hide(); 
       }
    },
    components: {
    }
}
</script>

<style scoped>
</style>

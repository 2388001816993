<template>
  <div>
    <!-- top-bar -->
    <div ref="navbar" class="navbar-custom">
      <ul class="list-unstyled topnav-menu topnav-menu-left">
        <li>
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-title">Leak Detail</li>-->
            <!-- <li class="breadcrumb-bar">|</li>-->
            <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
            <li class="breadcrumb-item"><router-link to="/maintenance">{{$t('menu.maintenance')}}</router-link></li>
            <li class="breadcrumb-item active">{{$t('leak_recovery.specification')}}</li>
          </ol>
        </li>

      </ul>
    </div>
    <!-- tom-bar-end -->
    <div class="content-area row justify-content-around" style="padding : 0 15px 0 15px;"> 
      <div class="left leak-recovery"  style="padding-right:10px;">
   
     
  
        <div class="list_box list_box2">
          <div class="page_list">
            <b-row class="list_box list_box2 recov" >
              <b-col class="page_list">
                <h3>{{$t('leak_recovery.table_title')}}</h3>
                   <div class="input_search_box leak" style="width:60%">
                    <input type="text" class="input_search" v-model="value" :placeholder="$t('water_leak.left_menu.search')"  @keyup.enter ="Enter_Check" >
                    <input type="submit" class="input_submit" @click="go_search" >
                  </div>
                <div style="float:right;margin:0px 0px 0px">
                  <b-button  class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text"  style="width: 100px; background-color: #2e75b6;" @click="go_work_move" >{{$t('leak_detail.btn_work_move')}}</b-button>                                      
                </div>       
                <div class="table-box" >
                 <!-- <div style="position:absolute; top: 0; right:0;  ">
                      <download-excel
                        class   = "btn btn-default"
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        name    = "filename.xls">
                      <button  @click="go_download" class="btn btn-third ml-2"  > <i class="mdi mdi-download" v-b-tooltip.hover title='Excel Download'></i></button>
                    </download-excel> </div>-->
                  <!--<label for="textarea-small" class="table-box-text mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label>-->
                  <ServerSideTable ref="table_info" 
                                   :columns="columns"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"                                   
                                   :select="true"
                                   :add_custom_column="true"
                                   :adv_search="adv_search"
                                   :max_height="table_height+'px'"
                                   @on-row-click="onRowClick"
                                   @on_goto_map_click="on_goto_map_click"
                                   @on_info_click="on_maintenance_info"
                  />

                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="right" style="margin-top:-55px;">

        <b-row style=" padding: 10px 5px 0px 0px ; margin-bottom:5px;">
           <b-tabs content-class="press_detail"     style="margin: 0px;width:100%;padding-right:0;">
            <b-tab :title="$t('work_info.proper')" style="padding:0;" active> 
              <b-row style="margin: 0;"> 
               <b-col class="leak-detail-content"  style="height: calc(45vh - 40px );">
            <!--b-row >
              <b-col style=" padding-left: 0px;padding-right: 0px;">

                <div  style='height:400px' :key="chartKey">
                   <BubbleChart v-bind:chartData0="chartdata0" v-bind:chartData1="chartdata1" v-bind:chartData2="chartdata2" v-bind:chartData3="chartdata3" 
                   v-bind:chartData4="chartdata4" v-bind:chartData5="chartdata5" 
                   ref="fftchart" :height="fftchart_height" style="width:100%; overflow: auto;"   />
                </div>   
              </b-col>
               </b-row-->
                   <b-row style=" padding-left: 0px; background-color:#172a46; margin: 10px 10px 0 -5px;" >
                       <b-col style="padding-top: 20px; padding-bottom: 20px;">
 
                        <div class="facility-info1" >
                            <b-row>
                              <b-col sm="3">
                                <label>{{$t('work_info.no')}}</label>
                             </b-col>
                             <b-col sm="3">
                                 <b-input size="sm" v-model="properties['id']" disabled></b-input>
                             </b-col>
                                <b-col sm="2">
                                    <label class="facility__label">{{$t('leak_recovery.columns.date')}}</label>
                                </b-col>
                                <b-col sm="4">                                  

                                    <b-input  class="facility__input" size="sm" v-model="properties['register_datetime']" disabled></b-input>

                                </b-col>

                            </b-row>
                            <b-row>
                                 <b-col sm="3">
                                    <label class="facility__label">Work {{$t('work_info.title')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['w_title']" disabled ></b-input>
                                </b-col>
                                <b-col sm="2">
                                    <label class="facility__label">Work {{$t('work_info.contents')}}</label>
                                </b-col>
                                <b-col sm="4">

                                    <b-input  class="facility__input" size="sm" v-model="properties['w_contents']" disabled ></b-input>

                                </b-col>                           
                            </b-row>                            
                            <b-row>
                                 <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.columns.title')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['title']" disabled ></b-input>
                                </b-col>
                                <b-col sm="2">
                                    <label class="facility__label">{{$t('leak_recovery.columns.contents')}}</label>
                                </b-col>
                                <b-col sm="4">

                                    <b-input  class="facility__input" size="sm" v-model="properties['contents']" disabled ></b-input>

                                </b-col>                           
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('facility_info.type')}}</label>
                                </b-col>
                                <b-col sm="3">
                                   <b-select class="facility__input detail" size="sm" 
                                    v-model="properties['type']" :options="f_type_option" ></b-select>

                                </b-col>

                            
                                <b-col sm="2">
                                    <label class="facility__label">{{$t('leak_recovery.f_name')}}</label>
                                </b-col>
                                <b-col sm="4">

                                    <b-input  class="facility__input" size="sm" v-model="properties['layer_type']" disabled ></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.f_no')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['f_idx']" disabled ></b-input>
                                </b-col>
                                <b-col sm="2">
                                    <label class="facility__label">{{$t('leak_recovery.columns.worker')}}</label>
                                </b-col>
                                <b-col sm="4">

                                    <b-input  class="facility__input" size="sm" v-model="properties['worker']" disabled ></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('facility_info.remarks')}}</label>
                                </b-col>
                                <b-col sm="9">

                                    <b-input  class="facility__input" size="sm" v-model="properties['remarks']" disabled ></b-input>

                                </b-col>                                
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('facility_info.status')}}</label>
                                </b-col>
                                <b-col sm="3">

                                     <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" ></b-select>

                                </b-col>                                
                            </b-row>
                        </div>  
 
                       </b-col>
                   </b-row>
           
              </b-col>
              </b-row> 
             </b-tab>
             <b-tab :title="$t('pressure.photo')" >
               <b-col style="height: calc(45vh - 40px );width:100%;overflow-y:auto;">
                <span v-for="(item,i) in img_list" :key="i">
                                    <b-card
                                        no-body
                                        img-top
                                        class="photo-card" style=" width: 33%;float: left;padding:5px;"
                                    >
                                        <b-img thumbnail fluid :src="item" alt="image" bottom style="height: 300px;" ></b-img>
                                        <!--<b-img thumbnail fluid src="http://localhost:3000/api/get_img/leakage/6/1" alt="Image" bottom></b-img>-->
                                    </b-card>      
                 </span> 
               </b-col>
             </b-tab> 
           </b-tabs>  
        </b-row>
        <b-row  style=" padding: 10px 5px 0px 0px ; ">
          <b-col class="leak-detail-content-map"  style='padding: 0;'>
            <div class="search_div_rec">
              <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
              <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
           </div>
            <div :style="{height: map_height+'px'}" >
              <Map
                  ref="map"
                  :widx="$route.query.widx"
                  :visible_full_extent="true"
                  :visible_gisload_button="true"    
                  :recovery_icon = "true"               
                  w_type="140"
                  @go_home="go_home()"           
                  @set_map_gisload="set_map_gisload"
                  @toggle_visible_all_layer = "toggle_visible_all_layer"
                  @facility_invisible_layer = "facility_invisible_layer"
                  @set_info="set_info"
                  @leaktable_refresh="leaktable_refresh"
                  @get_gisinfo="get_gisinfo"   
                  @gis_reload = "gis_reload"                   
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
     <DlgWorkList ref="dlg_work_list"  @leaktable_refresh="leaktable_refresh" />
  </div>
</template>

<script>

//import FFTChart1 from '@/components/FFTChart1';
//import BubbleChart from '@/components/BubbleChart';
import ServerSideTable from '@/components/table/ServerSideTable';
import Map from '@/components/Map_model';
import DlgWorkList from '@/components/popup/DlgWorkList';
import {CreateVectorLayer_f} from '@/gis.js';
//import DlgWavSliceChart from '@/components/DlgWavSliceChart';


export default {
  name: 'MaintenanceDetail',
  props: {
    // url
  },
  watch: {
    vchart() {
      //     if(this.vchart==='0') {
      //        alert("radio button : " + this.vchart) ;
      //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
      //     }
      var c_url = this.fft_url.split('#') ;
      this.fft_url = c_url[0]+'#'+this.vchart ;
    }
  },
  data() {
    return {
      properties: [],      
      layer_all_visible: false,
      addr_value:'',
      chartKey : 0,
      img_list: [],   
      checkedNames: [],
      unchecked : true,
      contents_height: 800,
      waveform_height: 50,
      fftchart_height: 400,
      map_height: 400,
      table_height: 580,
      chartdata0: [],
      chartdata1: [],
      chartdata2: [],
      chartdata3: [],
      chartdata4: [],
      chartdata5: [],
      f_status_option: [
          { value: 'A', text: 'Active' },
          { value: 'I', text: 'Inactive' },
          { value: 'U', text: 'Unable' },
      ],
      f_type_option: [
            { value: 0, text: 'Other' },
            { value: 1, text: 'Water Quality' },
            { value: 2, text: 'Pressure' },
            { value: 3, text: 'Stop' },//단수
            { value: 4, text: 'Price' },//단수
            { value: 5, text: 'Leak' },//단수
        ],
      vchart : 0,
      is_playing: false,
      value:'',
      levelA : false,
      columns: [
        {label: this.$t('leak_recovery.columns.map'), field: 'go_map',sortable: false},
        {label: this.$t('leak_recovery.columns.info'), field: 'info', sortable: false},
        {label: this.$t('leak_recovery.columns.date'), field: 'register_datetime'},
        {label: this.$t('leak_recovery.columns.title'), field: 'title'},
        {label: this.$t('leak_recovery.columns.worker'), field: 'worker'},
        {label: this.$t('leak_recovery.columns.facility'), field: 'layer_type'},   
        {label: 'Type', field: 'type_'},                 
        {label: this.$t('leak_recovery.columns.image'), field: 'image'},
        {label: this.$t('leak_recovery.columns.contents'), field: 'contents'},      
        {label: 'Remarks', field: 'remarks'}
      ],
     selected_ts_mnf: '',
      json_data :[],
      json_fields:{
        'Date': 'register_datetime',
        'Sub Title': 'title',
        'Worker': 'worker',
        'Facility': 'layer_type',       
        'X' : 'X',
        'Y' : 'Y',
        'Type' : 'type_',
        'Image': 'image',
        'contents': 'contents',
        'remarks': 'remarks'
      },

         adv_search: [
                {
                    field: 'leak_result_',
                    control: 'select',
                    value: null,
                    //'Leak','Leak Inside','Usage','Other'
                    options: [
                        { value: null, text: 'Leak Result' },
                        { value: 'RT_LEAK', text: 'Leak' },
                        { value: 'RT_LEAK_INSIDE', text: 'Leak Inside' },
                        { value: 'RT_USAGE', text: 'Usage' },
                        { value: 'RT_OTHER', text: 'Other'},
                    ]
                },
            ],
      filename : 'filename.xls',
      wavesurfer: null,
      fft_url: '',
      aurl:'',
      clicked_l_idx: -1,
      clicked_b_row:null,
      searchTerm: '',
      slice_url:'',
      szoom:50,
    }
  },
  methods: {
    addr_search(){
      this.$refs.map.go_search(this.addr_value);
    },
    Enter_Check1(){
        this.addr_search();
    },
    toggle_visible_all_layer() {
     
      //누수 레이어를 다시 그린다.
      this.$refs.map.init_reload_layer() ;

      
    },
    fn_press_han(event)
    {
        var objTarget = event.srcElement || event.target;
    // if(objTarget.type == 'text') {
      
      var value = objTarget.value;
      //alert(value);
      if(isNaN(value)){
        objTarget.value =value.replace(/[^0-9]/g,'');
        // alert('Please enter only numbers');
        // event.preventDefault();          
      }        
    },    
    permission_check(){
       if(sessionStorage === null || typeof sessionStorage === undefined){
            alert(this.$t('leak_detail.alt_no_update'))
            return
   
        }else if(sessionStorage.level > 2){
            alert(this.$t('water_leak.alert_no_permission'))
            return;
        }
    },      
    go_work_move() {
        this.permission_check();
        let rows = this.$refs.table_info.getSelectRows()
        if(rows.length<1) {
          alert(this.$t('leak_detail.alt_select'));
          return;
        }

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        this.$refs.dlg_work_list.show(140,list,this.$route.query.widx);
    
    },    
     uncheckedAll (a) {
      
       // this.isChecked = true;        
             if (this.unchecked && a === "1") {
                this.checkedNames=[]
             }else{
               if(this.checkedNames.length > 0)
                  this.unchecked = false ;
                else
                  this.unchecked = true ;   
             }                    
    },
     change_selected_ts_mnf(e) {
          this.selected_ts_mnf = e
          this.go_search();
           /*
          this.$refs.table_info.setUrl(this.URL+"api/get_leak_list_ex?w_idx="+this.$route.query.widx+'&search='+this.value+'&leak_result='+this.selected_ts_mnf, (rows) => {
            console.log(rows)
            if((!rows)||(rows.length<=0)) return;
            this.clicked_l_idx = rows[0].idx
            this.aurl = this.URL+'api/get_wav/'+rows[0].idx
            //  this.avwaveform.load(aurl)
            this.wavesurfer.load(this.aurl);
            this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
        })
        */
        //    this.get_time_series_mnf()
    },
    facility_invisible_layer() {

      this.layer_all_visible = false;
      let map = this.$refs.map.map
      for(let i=1; i<map.getLayers().getLength()-1; i++) {
        let layer = map.getLayers().item(i)
        if(layer.get('code')>50) continue;
        layer.setVisible(this.layer_all_visible)
      }
    },
    go_download() {
  //   this.filename = 
      this.filename = this.filename.replace(",","_")
      let rows = this.$refs.table_info.rows ;
      if(rows.length<=0) {
        alert(this.$t('leak_detail.alt_no_data'));
        return;
      }
      this.json_data = rows ;
      // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
      // this.$router.push({path: '/leak-detail', query: { widx: list }})
    },
       go_delete() {
            let rows = this.$refs.table_info.getSelectRows()
            if(rows.length<0) return;

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].idx)
       }
            var con_test = confirm("Are you sure you want to delete?");
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test){
              this.$http.get(this.URL+'api/delete?l_idx_ex='+list).then((res) => {  
                console.log(res.data);
                this.leaktable_refresh();
              });

           }
        },
    go_home() {
      this.$router.push({path: '/maintenance-detail-map', query: { widx: this.$route.query.widx, w_type: '140' }})
    },
    download(idx, name) {
      this.$http({
        url: this.URL+'api/download_wav/'+idx,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);
        fileLink.click();
      })

    },
    downloads() {
      let rows = this.$refs.table_info.getSelectRows()
      if(rows.length<=0){
        alert(this.$t('gis.gis_check_alert'));   
        return;  
      } 
      //'Leak','Leak Inside','Usage','Other'
      let fst = {'Leak':'L','Leak inside':'I','Usage':'U','Other':'O'}
      for(let i=0; i<rows.length; i++){
        let fs = '';
        if(rows[i].leak_result_ !== null){
           fs =  fst[rows[i].leak_result_];
        }
        let fname = rows[i].file_name.split('.')[0]+'_'+fs+".wav"
       // this.download(rows[i].idx, rows[i].file_name)
        setTimeout(()=>{
          this.download(rows[i].idx, fname)
        }, 100*i)
      }
    },
 
    goRouter(path) {
      let query = {}
      if(path==='/') query['widx'] = this.$route.query.widx
      else if('/leak-analysis'===path) {
        let rows = this.$refs.table_info.getSelectRows()
        if(rows.length<=0) return;
        let lidx = []
        for(let i=0; i<rows.length; i++) lidx.push(rows[i].idx)
        query['lidx'] = lidx
        query['widx'] = this.$route.query.widx
      }
      this.$router.push({path: path, query: query})
    },

    leaktable_refresh(){
      this.$refs.table_info.setUrl(this.URL+"api/get_maintenance_list_ex?w_idx="+this.$route.query.widx, (rows) => {
        if((!rows)||(rows.length<=0)) return;
        this.clicked_l_idx = rows[0].id       
        this.fft_url = this.URL+'api/get_fft/'+rows[0].id+'#'+this.vchart;

      })
    },
    onRowClick(params) {
      //선택된 색상 보여지게
   //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
      if(this.clicked_b_row != null){
        this.clicked_b_row.bgColor = '';
    //  //  this.clicked_b_row.style.backgroundColor = '';
     }
    
      if(params.event.target.nodeName==='TD'){
          params.event.target.parentElement.bgColor  = '125744';          
          this.clicked_b_row = params.event.target.parentElement ;
      }else  if(params.event.target.nodeName==='IMG'){
           params.event.target.parentElement.parentElement.parentElement.bgColor = '125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
      }else{
          params.event.target.parentElement.parentElement.bgColor = '125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement ;
      }
      
/*
     if(this.clicked_b_row != null){
       this.rowStyleClassFn(params.row, 1);
     }
      this.rowStyleClassFn(params.row, 0);
 */  

      this.clicked_l_idx = params.row.id
      this.row_detail(params.row.id)    
      //this.fft_url = this.URL+'api/get_fft/'+params.row.water_leak_id+'#'+this.vchart;  

    },
     onLeakMapClick(){
            let leak_idx = this.$refs.map.leak_idx;
         
            //leak_info를 쿼리해서 그 데이터를 wav,fft에 앉힌다.
            this.lidx1 = leak_idx;
            //이미지 가져오기
            //facility info정보가져오기
            this.row_detail(leak_idx)
    },
    row_detail(id){
      this.properties = {} ;
    // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
      this.img_list =[];
      
    // this.$http.get(this.URL+'api/get_press_info?idx='+idx).then((res) => {
      this.$http.get(this.URL+'api/get_maintenance_info?idx='+id).then((res) => {
          
        if(res.data.length<=0) return           

        this.properties = res.data.list[0]; 
      
      if(res.data.list[0].image_list === null || res.data.list[0].image_list === undefined ){

          this.img_list.push(require('@/assets/noimage1.png')) 
          // this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
          this.img_list.push(require('@/assets/noimage1.png'));
          this.img_list.push(require('@/assets/noimage1.png'));
          
        }else{
          var img = res.data.list[0].image_list.split(',')
          for(var i = 0 ; i < img.length ;++i) {   
            this.img_list.push( this.URL+'api/'+img[i])
          }
      
        
        }
        this.properties_bak = Object.assign({}, this.properties)

      })
    },
    on_goto_map_click(row) {
      this.$refs.map.get_extent('w_type=140&p_idx='+row.id).then((data)=>{
        this.$refs.map.set_fit_extent(data)     
        if(this.$store.setting.map_name==='Daum')
          this.$refs.map.map.getView().setZoom(13);
        else
          this.$refs.map.map.getView().setZoom(18);   
      })
      this.$refs.map.select_facility_feature(140,'',row.id) 
    },
    play() {
      if(!this.is_playing) {
        this.wavesurfer.play();
        this.is_playing = true
      }
      else {
        this.is_playing = false
        this.wavesurfer.pause();
      }

    },
    stop() {
      this.is_playing = false
      this.wavesurfer.stop();
    },
    window_resiz() {
      this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 50

      this.map_height = this.contents_height/2
      //this.fftchart_height = this.map_height /2
     //this.waveform_height = this.fftchart_height - 100

      this.$refs.map.update_size()
    },
    set_map_gisload() {
      this.set_info();
      let map = this.$refs.map.map;
      var size1 = map.getSize();
      var size = [size1[0]*1, size1[1]*1] ;
      var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
      let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
      var extent = map.getView().calculateExtent(size);

      if(this.$refs.map.map.getView().getZoom() < cz){
         alert(this.$t('home.alert_zoom_in')) ;
      }else{
        // 추가 되었던 소스 가져와서 삭제하기
        // heat layer 삭제

        var h_layer = this.$refs.map.heat_layer ;
        if(h_layer != null) {
          var source_h = h_layer.getSource();
          var hfeatures = source_h.getFeatures();
          for(var k=0; k< hfeatures.length ; k++) {
            source_h.removeFeature(hfeatures[k]);
          }
          map.removeLayer(h_layer) ;
          h_layer = null;
        }
        // remove_layer(map);
        for(let i=0; i<this.$refs.map.layer_list.length; i++) {
          var layer = this.$refs.map.layer_list[i];
          if(layer=== null || layer.values_ === null) continue
          var source1 = layer.getSource();
          var features = source1.getFeatures();
          for(var j=0; j< features.length ; j++) {
            source1.removeFeature(features[j]);
          }
          map.removeLayer(layer) ;
        }
        this.$refs.map.layer_list = [] ;
        for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

          var params = this.$refs.map.layer_setting_stlye_list[i];
          // var nstyle = params.style;
          var _url=params.surl;
          // if(params.code >50 || layer.get('code')=== undefined ) continue

          var lftaye = params.f_type;
          _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

          var newlayer = CreateVectorLayer_f(params,extent) ;
          var vis = params.visible ;
          newlayer.setVisible(vis);
          this.$refs.map.layer_list.push(newlayer) ;
          map.addLayer(newlayer);


        } //for문 끝
        this.$refs.map.init_draw_modify();
      } // zoom크기에 대한 if


    },
    gis_reload(){
        this.set_info();
       // this.$refs.map.init_draw();
        if(this.$store.setting.map_name==='Daum')
          this.$refs.map.map.getView().setZoom(9);
        else
          this.$refs.map.map.getView().setZoom(15);   
        this.set_map_gisload() ;    
    },
    get_gisinfo(){
        this.$refs.map.block_info = 1 ;
        this.set_toggle_visible_all_layer();
        // this.$refs.map.init_draw();
        this.$refs.map.edit_change('add', 'Block')
        // this.$refs.map.edit_start('add', 'Block') ;
    },
    set_toggle_visible_all_layer() {
          
            this.layer_all_visible = !this.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue
                layer.setVisible(this.layer_all_visible)
            }
        },
    set_info(){
      this.$refs.map.edit_cancel();
      this.$refs.map.select_info = true;
    },
    on_maintenance_info(row) {
      //var purl = "/leakRecove_popup_info?idx="+row.id ;
      //window.open(purl, "PopupWin", "width=580,height=750,top=10, left=50,status=no, menubar=no, toolbar=no,resizable=yes, location=no");
       this.$refs.map.show_maintenance_info(row.id)
    },
   
     go_search() {
          if(this.value===undefined) return;

          this.box = [] ;       
      //    this.$http.get(this.URL+'api/get_work_list_ex?w_type=100&search_term='+value+'&per_page=100&page=1').then((rows) => {
          this.$refs.table_info.setUrl(this.URL+"api/get_maintenance_list_ex?w_idx="+this.$route.query.widx+'&search='+this.value+'&leak_result='+this.checkedNames, (rows) => {
          console.log(rows)
          if((!rows)||(rows.length<=0)) return;
          this.clicked_l_idx = +rows[0].id         
         this.fft_url = this.URL+'api/get_fft/'+rows[0].water_leak_id+'#'+this.vchart;

        })
     },
      Enter_Check(){
       //  if(event.keyCode == 13){
         this.go_search();  // 실행할 이벤트
     },
  

  },
  created () {
 
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.window_resiz)
  },
  mounted () {
    window.addEventListener('resize', this.window_resiz)
    this.window_resiz()
       setTimeout(()=>{
            this.toggle_visible_all_layer();   
       }, 1000)  
//삭제버튼 활성화 여부
   // if(localStorage !== null && typeof (localStorage) !== undefined && localStorage.level === 'A'){
     if(sessionStorage !== null && typeof (sessionStorage) !== undefined && sessionStorage.level === 'A'){
       this.levelA = true       
     }else{
       this.levelA = false
    }


    this.$refs.table_info.setUrl(this.URL+"api/get_maintenance_list_ex?w_idx="+this.$route.query.widx, (rows) => {
        console.log(rows)
       
        if((!rows)||(rows.length<=0)) return;
        this.clicked_l_idx = rows[0].id
        this.row_detail(this.clicked_l_idx )
       // this.filename = "widx-"+this.$route.query.widx+"-lidx-"+rows[0].id+"~.xls"     
        //this.fft_url = this.URL+'api/get_fft/'+rows[0].water_leak_id+'#'+this.vchart;
      
    })
    // this.chartdata1 = [];
     /*
    this.$http.get(this.URL+'api/get_maintenance_list_ex?type=0&w_idx='+this.$route.query.widx).then((rows) => {
       console.log(rows)
       for(let i=0; i<rows.data.rows.length; i++) {
             var abox = { x:rows.data.rows[i].X,
                       y: rows.data.rows[i].Y,
                       r : 10
                       } ; 
             if(rows.data.rows[i].type == 1)          
               this.chartdata1.push(abox) ;
             else if(rows.data.rows[i].type == 2)          
               this.chartdata2.push(abox) ;
             else if(rows.data.rows[i].type == 3)          
               this.chartdata3.push(abox) ;
             else if(rows.data.rows[i].type == 4)          
               this.chartdata4.push(abox) ;
             else if(rows.data.rows[i].type == 5)          
               this.chartdata5.push(abox) ;
             else
                this.chartdata0.push(abox) ;                  
       }
       ++this.chartKey
    })
    */

  },
  components: {
   // FFTChart1: FFTChart1,
   // BubbleChart: BubbleChart,
    ServerSideTable: ServerSideTable,
    Map: Map,    
    DlgWorkList:DlgWorkList,
  //  DlgWavSliceChart:DlgWavSliceChart,

  }
}

</script>

<style lang="scss" scoped>
/*
    #waveform{
          background: rgba(0,0,0,0.8);
    }
*/
#waveform .audio {
  all: none;
  width: 300px;
  position :absolute;
  z-index:1;
}

.inner-bg {
  float : left;
  /* 공통 속성값 지정 */

  display: inline-block;
  /* 인라인요소를 인라인블록 요소로 */
  height: 30px;
  /* 이미지 공간을 확보하기 위해 높이값 지정 */

  background: #c0c0c0;
  padding-left:10px;
  padding-right: 10px;

}

.content-area {
  padding: 0 30px;
}
.leak-detail-content {
  /*background: #fff;*/
  padding: 10px 30px 0;
}
.leak-detail-content-map {
  padding: 0;
}
.left .list_box.list_box2.recov {
  min-height: 780px;
  height: calc(100vh - 120px);
}
.table-button-group {
  margin-bottom: 15px;
}
.table-button-group .btn-lk-middle {
  width: 100%;
}
.left.leak-detail {
  height: auto;
}
table.type01 th {
  padding: 12px 0;
  background-color: aqua;
}

.btn-default {
  color: #fff;
}
.btn-default :hover{
  color: #000;
}
i {
    font-style: normal;
}
.input_search_box.leak{
  margin-top:0px;
}
.custom-radio.b-custom-control-sm{
     margin-left: 20px;
}

.search_div_rec{
    position: absolute;
    left:250px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}
.facility__input.custom-select{
  background: #0A192F;
  color:  #707070;
  border: 1px solid #707070;
}
</style>

<template>
    <div>
        <b-modal
              ref="modal"
              title="Upload CsvFile"
              size="sm"
            >

            <div style ="padding:20px;">
                <b-row> <b-col sm="4"><label>CUSTOMER  </label>  </b-col>
           
                        <b-col sm="8">
                        <b-select  size="sm" v-model="s_customer_id" :options="customer_name" style="width:100%;" ></b-select>
                         </b-col>
                
                </b-row>
                <b-row style="padding-top:5px;"> <b-col sm="6"><label>Sample File(.csv)</label>  </b-col>
                        <b-col sm="6">  <b-button v-on:click="csv_file_download()"> Download</b-button> </b-col>
                
                </b-row>
                 <!--b-row> <b-col sm="4"><label>Layer Type</label>  </b-col>
                  <b-col sm="8">    <b-select v-model="selected_layer" :options="layers"></b-select></b-col>
                
                </b-row-->                 
               
                <div>
                    <label>File
                         <input size="sm"  type="file" id="files" ref="files" accept=".csv" v-on:change="handleFilesUpload()"/>
                    </label>
                </div>
                <div>
                    <ul>
                        <li v-for="(file, index) in files" :key="index">{{ file.name }} </li>
                    </ul>
                </div>
                <br>
                <div>
                    <b-button v-on:click="addFiles()">Add Files</b-button>
                </div>
                <br>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="submitFiles()">OK</b-button>
                <b-button @click="$refs.modal.hide()">Cancel</b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>

export default {
    data() {
        return {
            files: [],
            encoding: 'euc-kr',
            tag_name: null,
            selected_layer: 0,
            customer_name : [],
            s_customer_id:-1,
        }
    },


    methods: {
        show(arr_customer_name, s_customer_no) {
            if(s_customer_no === "")
               s_customer_no = '082-042-000'
            this.customer_name = []
            for(var ii = 0 ; ii < arr_customer_name.length ; ++ii ){
              var c_no1 =arr_customer_name[ii].value ;
              this.customer_name.push({value:arr_customer_name[ii].id, text :arr_customer_name[ii].text}) ; 
              if(c_no1 === s_customer_no){
                 this.s_customer_id = arr_customer_name[ii].id
              }
            }
           
            this.files = []
            this.$refs.modal.show();
        },
        csv_file_download(){
            const csvContent =
             '시리얼 번호,장비타입,장비 설명,펌웨어 버전,보드 버전\r\n' +
             'SM2-V3A-1234,SM2,설명1,펌웨어1,보드1\r\n' +
             'SM2-V3A-1235,SM2,설명2,펌웨어2,보드2\r\n' +
             'SM2-V3A-1236,SM2,설명3,펌웨어3,보드3\r\n' +
             'SM2-V3A-1237,SM2,설명4,펌웨어4,보드4\r\n' ;
           // const encodedUri = encodeURI('data:text/csv;charset=euc-kr,' + csvContent);
            var fileURL = window.URL.createObjectURL(new Blob(["\ufeff"+csvContent]));
            const link = document.createElement('a');
            link.setAttribute('href', fileURL);
            //link.href = fileURL;
            link.setAttribute('download', 'Sample_file.csv'); // 다운로드될 파일 이름 설정
            document.body.appendChild(link);
            link.click();
        },        
        addFiles() {
            this.$refs.files.click();
        },
        submitFiles(){
            let formData = new FormData();
            let a = 0
            let b = 0
            var c_id = this.s_customer_id
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                let fileName = file.name;
                let fileExtension = fileName.split('.').pop();

                if( fileExtension.toLowerCase() ==="csv" ){
                    a=a+1;
                }

                //formData.append('files[' + i + ']', file);
                formData.append('csv', file);
            }
            if(a !== 1 && b !== 1  ){
                alert("csv파일을 업로드하여야 합니다.")
                return
            }
          //  this.$http.post(this.URL+'api/upload_shape?layer='+this.selected_layer+'&encoding='+this.encoding+'&tag_name='+this.tag_name, formData, {
              this.$http.post(this.URL+'api/device/multi/'+c_id, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                console.log('SUCCESS!!');
                if(res.data.check ===1){
                    alert("FAILURE!! ") ;
                }else{
                    alert("SUCCESS!! Insert CNT : "+ res.data.count) ;
                }
                this.$refs.modal.hide();
            })
            .catch(() => {
                console.log('FAILURE!!');
                this.$refs.modal.hide();
            });
        },
        handleFilesUpload(){
            let uploadedFiles = this.$refs.files.files;
            for( var i = 0; i < uploadedFiles.length; i++ ){
                this.files.push( uploadedFiles[i] );
            }
        },
        removeFile( key ){
            this.files.splice( key, 1 );
        }
    }
}
</script>

<style  lang="scss">
  input[type="file"]{
    position: absolute;
    top: -500px;
  }
  div.file-listing{
    width: 200px;
  }
  span.remove-file{
    color: red;
    cursor: pointer;
    float: right;
  }
</style>

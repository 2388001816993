import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//import './assets/css/bootstrap.min.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

import './assets/css/table_widget_v2.css';
import './assets/css/icons.min.css';
//chart 사용추가 2020.07.24
import 'chart.js'
import 'hchs-vue-charts'
import Chartkick from 'chartkick'
import VueChartkick from 'vue-chartkick'
//highcharts
// 
import VueCryptojs from 'vue-cryptojs'
//2021-01-21 
import WaveSurferVue from "wavesurfer.js-vue";
//import {waveform} from 'vue-waveform'
//import AudioVisual from 'vue-audio-visual'
//20200827 엑셀파일 다운로드
import JsonExcel from 'vue-json-excel'
//2021.01.08 highchart 
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
//import stockInit from 'highcharts/modules/stock'
import boost from "highcharts/modules/boost";
import highcharts3d from 'highcharts/highcharts-3d'
import More from 'highcharts/highcharts-more'
// 2022.10.18 new style
import './assets/scss/style.scss';

import vuescroll from 'vuescroll';
//import Pagination from 'vue-pagination-2';
import exportingInit from "highcharts/modules/exporting";
import exportdata from "highcharts/modules/export-data";
exportingInit(Highcharts);
exportdata(Highcharts);

Vue.prototype.$http = axios;
Vue.prototype.$EventBus = new Vue();

//npm run serve
//Vue.prototype.URL = 'http://localhost:3000/'
//Vue.prototype.URL = 'http://id.neverlosewater.com/node/'
//운영서버
//Vue.prototype.URL = 'https://kr.neverlosewater.com/node/'
//김제서버
//Vue.prototype.URL = 'http://krgj.neverlosewater.com/node/'
//Vue.prototype.URL = 'http://neverlosewater.com/node/'
//Vue.prototype.URL = 'http://docker.neverlosewater.com/'
//Vue.prototype.URL = 'http://wiplat.com/node/'
//Vue.prototype.URL = 'http://map.wiplat.com:3000/'
//Vue.prototype.URL = 'http://map.wiplat.com:3232/'
//네이버 서버
//Vue.prototype.URL = 'http://tr.neverlosewater.com/node/'

//Vue.prototype.URL = 'https://id.neverlosewater.com/'
//Vue.prototype.URL = 'https://dev.neverlosewater.com/node/'

//npm run build
Vue.prototype.URL = '';

Vue.use(VueGoodTablePlugin);
Vue.use(BootstrapVue)

//chart 사용추가 2020.07.24
Vue.use(window.VueCharts)
Vue.use(VueChartkick, { Chartkick })
//암호화
Vue.use(VueCryptojs)
//2021.01.08 highchart 
Vue.use(HighchartsVue)

highcharts3d(Highcharts);
More(Highcharts) ;

boost(Highcharts);
//stockInit(Highcharts);

Vue.component('downloadExcel', JsonExcel) ;
//Vue.component('pagination', Pagination);
//audio 컨포넌트 추가 2020.08.12
//Vue.use(AudioVisual)
//Vue.use(waveform)
//2021-01-21
Vue.use(WaveSurferVue)
Vue.use(vuescroll);
Vue.config.productionTip = false

new Vue({
  i18n, // i18n 
  router,
  store,
  render: h => h(App)
}).$mount('#app')


<template>
    <div  :style="{height: height+'px'}">
        <line-chart ref="chart" v-bind:chartData="chartData" v-bind:yopt="yopt" v-bind:xdata="xdata" v-if="loaded" v-bind:class="size"></line-chart>
    </div>
  
</template>

<script>
//import * as d3 from 'd3'
import LineChart from '@/components/chart/LineChart1'
//import {Line} from 'vue-chartjs'

export default {
    name: 'pumpRealTime',
 //   extends: Line,
    components: {LineChart },    
    props: [
        'height',
        'url'
    ],
    watch: {
        bidx: function() {
          this.redraw();
        },
        url() {
            if(this.url==='') return

            this.datacollection= null; 
            this.xdata = [];
            this.chartData = [];
            var s_url = this.url.split("#")
            this.item = Number(s_url[1]) ;
            this.loaded=false;
            this.$http.get(this.url).then((res) => {
               if( res.data !=="" ){
                   let ts_flow = res.data.results[0]
               // var parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S.000Z");
               // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
                for(let i=0; i<ts_flow.length; i++) {
                //  var dd = new Date(ts_flow[i]['date_time']);
                  this.xdata.push( ts_flow[i]['date_time'])
                  if(this.item === 0){ 
                     this.chartData.push(ts_flow[i].instant_flow_rate)
                  }else{
                     this.chartData.push(ts_flow[i].out_flow_rate)
                  }
                }
         
               }else {   
                  this.xdata = []
                  this.chartData =[] ;
               }
                ++this.bidx;

            });
        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        datacollection: null,
        chartData: [],
        size: 'firstClass',
        xdata : [],
        ymax : 1,
        xmax : 10,
        loaded: false,
        yopt : null,
        vchart : 0,
        bidx : 0,
        //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
       //dataset: [65, 59, 80, 81, 56, 55, 40]
      // dataset :  this.fft_data ,
      }
    },
    methods: {
  
       redraw () {
           //  this.ymax = parseInt(this.$store.setting.wave.frequency.y) ;
           //  if(this.vchart === '0'){
            //    this.ymax = parseInt(this.$store.setting.wave.frequency.y) ;
                 this.yopt =  {
                      beginAtZero: true,       
                 //     max: this.ymax,
                  //    max: 10,
                      min: 0,     
                //      stepSize  : this.ymax/5
                    } ;
           //  }else{
          //      this.yopt = {  beginAtZero: true  } ;
           //  }
            

        //     this.xmax = parseInt(this.$store.setting.wave.frequency.x) ;
        //     this.xdata=[];  
      //         for (var i = 0;  i < this.chartData.length ; ++i) {
    //             this.xdata.push(i);
     //          }
              this.loaded = true;
            //  this.update();
           //   this.$data._chart.update()
           // this.chartData =  cData ;
            //this.$refs.chart.update();

      }
      

    
    },
  mounted () {
        window.addEventListener('resize', this.resize);
        if(this.url==='') return
      //  this.$http.get(this.url).then((res) => {
      //      this.chartData = res.data.slice(0, this.$store.setting.wave.frequency.x);
     //   });
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    }
    
}
</script>

<style scoped>
.firstClass{
   height: 350px;
 
    padding-top: 10px;
 

 /*  
    width: 700px;
    */
  }

</style>

import {Map} from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';
import {get as getProjection} from 'ol/proj';
import TileGrid from 'ol/tilegrid/TileGrid';
//import Point from 'ol/geom/Point';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
//import {Circle as CircleStyle, Fill, Stroke, Style, Icon} from 'ol/style.js';
//import {Circle as Circle, Fill, Style, Stroke, Text} from 'ol/style.js';
import {bbox} from 'ol/loadingstrategy';
//import {defaults as defaultControls, Control, OverviewMap} from 'ol/control.js';
import {defaults as defaultControls} from 'ol/control';
import BingMaps from 'ol/source/BingMaps';
import {Heatmap as HeatmapLayer} from 'ol/layer';
let oboe = require('oboe');

let epsg5181 = 'EPSG:5181';
proj4.defs(epsg5181, '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs');
register(proj4);
getProjection(epsg5181).setExtent([-30000,-60000,-30000+Math.pow(2,18)*2, -60000+Math.pow(2, 18)*4]);
let gisload = false ;

function LinearInterpolation(x, x0, y0, x1, y1) {
	return y0 + (y1-y0) * ((x-x0) / (x1-x0));
}

function ConvertCoordinatePoint(fepsg, tepsg, p) {
	return proj4(fepsg, tepsg, p);
}

function ConvertCoordinateExtent(fepsg, tepsg, ext) {
	let p1 = proj4(fepsg, tepsg, [ext[0], ext[1]]);
	let p2 = proj4(fepsg, tepsg, [ext[2], ext[3]]);
	return [p1[0], p1[1], p2[0], p2[1]];
}

function CreateDaumMap(name, bstandard) {
	return new TileLayer({
		name: name,
		source: new XYZ({
			projection: epsg5181,
			tileGrid: new TileGrid({
				origin: [-30000, -60000],
				resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
			}),
			tileUrlFunction: function (c) {
				var z = 14 - c[0];
				if(typeof(this.url_num)=='undefined') this.url_num = 0;
				if((++this.url_num)>3) this.url_num = 0;
				if(bstandard)
					return 'http://map'+this.url_num+'.daumcdn.net/map_2d/1807hsm/L' + z + '/' + ((-c[2])-1) + '/' + c[1] + '.png';
				else
					return 'http://map'+this.url_num+'.daumcdn.net/map_skyview/L' + z + '/' + ((-c[2])-1) + '/' + c[1] + '.jpg';
			}
		})
	});
}
function CreateVWorldMap(name ) {
	return new TileLayer({
		name: name,
		source: new XYZ({
			url: 'http://xdworld.vworld.kr:8080/2d/Base/202002/{z}/{x}/{y}.png'
		})
	});
}
function CreateBingMap(key, name, bstandard) {
	return new TileLayer({
		name: name,
		visible: true,
		preload: Infinity,
		source: new BingMaps({
			key: key,
			imagerySet: (bstandard)?'RoadOnDemand':'Aerial'
      // use maxZoom 19 to see stretched tiles instead of the BingMaps
      // "no photos at this zoom level" tiles
      // maxZoom: 19
		})
	});
}

function get_gisload(){
	return gisload;
}

function set_gisload(){
   this.gisload = true ;
   gisload = get_gisload();
}
function CreateVectorLayer(params, url) {
	params['source'] = new VectorSource({
		format: new GeoJSON(),
		strategy: bbox,	
		loader: function(extent, resolution, projection) {		
				let _url = url+(url.indexOf('?')>-1?'&':'?')+'bbox=' + extent.join(',') + '&srid='+projection.getCode()+'&f_type='+params.f_type;
				oboe(_url).node('features.*', (f)=> {
					this.addFeature(this.getFormat().readFeature(f))
				});	
		}

	});
	return new VectorLayer(params);
}


function CreateVectorLayer_f(params,extent) {
	var source1 = new VectorSource();
    if(extent != null){

		var url = params['surl'];
		var epsg = params['epsg'];
		//var f_type = params['f_type'];
		let _url = url+(url.indexOf('?')>-1?'&':'?')+'bbox=' + extent.join(',') + '&srid='+epsg+'&f_type='+params.f_type;
        if(params.f_type===''){
			_url = url+(url.indexOf('?')>-1?'&':'?')+'bbox=' + extent.join(',') + '&srid='+epsg ;
		}
		var	format = new GeoJSON() ;

		oboe(_url).node('features.*', (f)=> {
			source1.addFeature(format.readFeature(f))
		});
	}
	params['source'] = source1; 

	return new VectorLayer(params);
}

function CreateVectorLayer_fc(params,extent) {
	var source1 = new VectorSource();

    if(extent != null){

		var url = params['surl'];
		var epsg = params['epsg'];
		//var f_type = params['f_type'];
		let _url = url+(url.indexOf('?')>-1?'&':'?')+'bbox=' + extent.join(',') + '&srid='+epsg+'&f_type='+params.f_type;
        if(params.f_type===''){
			_url = url+(url.indexOf('?')>-1?'&':'?')+'bbox=' + extent.join(',') + '&srid='+epsg ;
		}
		var	format = new GeoJSON() ;

		oboe(_url).node('features.*', (f)=> {
			source1.addFeature(format.readFeature(f))
		});
	}
	
	var clustersource = new Cluster({distance:10,source:source1})
	params['source'] = clustersource; 
	return new VectorLayer(params);
	
}
function CreateVectorLayer_ff(params,extent,dma_id) {
	var source1 = new VectorSource();
    if(extent != null){

		var url = params['surl'];
		var epsg = params['epsg'];
		//var f_type = params['f_type'];
		let _url = url+(url.indexOf('?')>-1?'&':'?')+'bbox=' + extent.join(',') + '&srid='+epsg+'&f_type='+params.f_type+'&dma_id='+dma_id;
        if(params.f_type===''){
			_url = url+(url.indexOf('?')>-1?'&':'?')+'bbox=' + extent.join(',') + '&srid='+epsg +'&dma_id='+dma_id;
		}
		var	format = new GeoJSON() ;

		oboe(_url).node('features.*', (f)=> {
			source1.addFeature(format.readFeature(f))
		});
	}
	params['source'] = source1; 

	return new VectorLayer(params);
}


function CreateHeatmapLayer(params, url, fn_weight) {
	params['source'] = new VectorSource({url: url, format: new GeoJSON()});
	if(typeof(fn_weight)!=='undefined') params['weight'] = fn_weight;
	else params['weight'] = function() { return 1 };
	return new HeatmapLayer(params);
}

function CreateMap(_id) {
	
	var newmap= new Map({
		target: _id,
		
		controls: defaultControls({
			zoom: false,
			attribution: false,
			rotate: false
		}).extend([]),
		layers: []
	});
	newmap.on('moveend', function(){
		var level = newmap.getView().getZoom().toFixed(0);
		var message= 'zoom= ' + level;
		var resultDiv = document.getElementById('c_zoom');  
		resultDiv.innerHTML = message;                
   });        
	return newmap ;
}
/*
function SetView(map, epsg) {
	map.setView(new View({
		minZoom: 0,
		maxZoom: 30,
		projection: epsg,
		zoom: 6,
		autoUpdateSize: true
	}));
}
*/

function CenterPoint(extent) {
	return [((extent[2] - extent[0]) / 2) + extent[0], ((extent[3] - extent[1]) / 2) + extent[1]];
}

function ScaleExtent(extent, scale) {
	let pt = CenterPoint(extent);
    let w = ((extent[2] - extent[0])*scale)/2;
    let h = ((extent[3] - extent[1])*scale)/2;
    return [pt[0]-w, pt[1]-h, pt[0]+w, pt[1]+h];
}
// 3857			center: [185642.86278, 184815.18245],
//189169.68539, 186234.04669
//X:

export {
	CreateMap,
	//SetView,
	set_gisload,
	LinearInterpolation,
	CreateDaumMap,
	CreateVWorldMap,
	CreateBingMap,
	CreateVectorLayer,
	CreateVectorLayer_f,
	CreateVectorLayer_fc,	
	CreateVectorLayer_ff,
	CreateHeatmapLayer,
	ScaleExtent,
	ConvertCoordinatePoint,
	ConvertCoordinateExtent
};

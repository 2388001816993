<template>
  <highcharts id= "fftchart"  :options="chartOptions" :class="size"></highcharts>
</template>

<script>
export default {
  name: 'pressChart1',
   props:{

      chartData: {
        type: Array
      },
      interval : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
       sdate : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
      ymax : { type:Number },
      chart_type : { type:String},
      size : { type:String},
   
  },
  watch: {
      select_min : function() {
        //alert("select_min  watch:" +this.select_min+", max :"+ this.select_max  );         
        this.onZoomClickEvent( ) ;

      }
  },
  data () {
    return {
       select_min : -1,
       select_max : -1,
      
       chartOptions: {
    //  2022 new
        //  colorAxis:[{lineColor:"#434348"}],
        //  colors : ["#5580b4"],
         colorAxis:[{lineColor:"#638cfb"}],
         colors : ["#fff"],
        series: [{
          type:this.chart_type,
          showInLegend: false,
          name: 'Amplitude',
          followPointer: true,
         
         // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
           data: this.chartData,
           lineWidth: 1.0,
         // pointStart: Date.UTC(2018, 1, 1,0,0,0,0),
         // pointStart: this.sdate,
         // pointInterval: 100,
         // pointInterval: this.interval, //
         // pointInterval: 4000/20480, //
         
        }],
         credits: {
            text: '',
            href: ''
          },
          exporting: {
              buttons: { contextButton: { enabled: false} }
         },    
          tooltip: {
            //valueDecimals: 2,
            shared: true,
            crosshairs: true
          },
           boost: { enabled: true },
          //dateTimeLabelFormats:{millisecond : {main:"%S.%L"}},
          
          plotOptions: {
                  area: {
                      fillColor: {
                        linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1
                        },
                        stops: [
                          // 2022 new
                          // [0,'#d6e8f9'],
                          // [1,'#b5d6f5']
                          [0,'#172A46;'],
                          [1,'#172A46;']
                        ]
                      },
                 },
            
        
        
          },
            
       // }],      
        title: {        text: '', },
         chart: {   zoomType: 'x' ,
                 
                   events : { //selection:this.handle
                    
                     /*selection: function(event) {
                          // log the min and max of the primary, datetime x-axis
                          console.log(' min :' +   event.xAxis[0].min , 'max ' +        event.xAxis[0].max   ) ;
                          //this.onZoomClickEvent()

                        
                      }
                      */
                  }
                },
         /*
         tooltip: {
                formatter: function() {
                        return '<b>'+ this.series.name +'</b><br/>'+
                        this.dateFormat('%Y-%m-%d %H:%M:%S', this.x) +'<br/>'+
                        this.numberFormat(this.y, 2);
                }
            },
            */
        yAxis : { 
                 title:{text:'Amplitude'},
                 max: this.ymax ,
                 gridLineColor: '#2a4369',
                 className: 'highcharts-color-0',
                },   
        xAxis: { //type: 'datetime',
                title:{text:'Frequency(Hz)'},
            /*
                labels: {
                formatter: function() {
                  return Highcharts.dateFormat('Year:%y <br/> Month:%e<br /> %H:%M:%S:%L', this.value);

               
                },
              }  
              */
               max: parseInt(this.$store.setting.wave.frequency.x) ,
               events: {
                 afterSetExtremes:this.handle
                 /*
                  afterSetExtremes: function(e) {
                     
                      this.select_min = Math.round(e.min)
                      this.select_max = Math.round(e.max)   
                       alert("min :" +this.select_min+", max :"+ this.select_max  );             
                  },
                 */
               }
              },
   
    
           

    },

    
       
    }
  },
  methods: {
        onZoomClickEvent(){
            //this.bar_idx = idx ;
           this.$emit('onZoomClickEvent') ;
        },  
        handle (point) {
          if(point.min < 0)
            this.select_min = 0;
          else
            this.select_min = Math.round(point.min);
          this.select_max = Math.round(point.max)   
          //alert(point)
        }
    },
     mounted () {
      // this.height = 200;
     // this.renderChart(this.datacollection, this.options)
    }
}
</script>
<style lang="scss" scoped>
.fftchart {
  min-width: 450px;
  //max-width: 900px;
  width:98%;
  height: 200px;
  margin: 0 auto;
}
.fftchart1 {
  min-width: 450px;
  
  max-width: 900px;
  width:98%;  
  height: 17vh;  
  margin: 0 auto;
}
.highcharts-axis.highcharts-color-0 text {
    fill: #7cb5ec;
}

</style>
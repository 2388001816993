<template>
    <div ref="modal" class="facility-info model" v-if="properties!==null">
      <h4 class="facility-info__title">{{$t('inp_file_info.title')}}</h4>
      <b-tabs  align="center"  v-model="tabIndex"  content-class="facility-info-content is--scroll" >
          <b-tab  :title="$t('inp_file_info.tab_title1')" active>
              <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('inp_file_info.model_no')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-input class="facility__input" size="sm" v-model="properties['id']" disabled></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('inp_file_info.name')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-input class="facility__input" size="sm" v-model="properties['name']"></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('model_info.contents')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-input class="facility__input" size="sm" v-model="properties['contents']"></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('inp_file_info.register')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-input class="facility__input" size="sm" v-model="properties['m_id']" disabled ></b-input>
                  </b-col>
              </b-row>                  
              <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('inp_file_info.register_time')}}</label>
                  </b-col>
                  <b-col sm="8">
                     <b-input class="facility__input" size="sm" v-model="properties['insert_datetime']" disabled ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('inp_file_info.save_time')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-input class="facility__input" size="sm" v-model="properties['update_datetime']" disabled ></b-input>
                  </b-col>
              </b-row>
                  
              <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('model_info.file_name')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-input class="facility__input" size="sm" v-model="properties['file_name']" disabled></b-input>
                  </b-col>
              </b-row>

               <b-row>
                        <b-col sm="4">
                            <label>Flow Unit</label>
                        </b-col>
                        <b-col sm="8">
                          <b-select class="facility__input" size="sm" v-model="properties['flow_unit']" :options="flow_unit_option" disabled ></b-select>
                        </b-col>
                    </b-row> 
                      <b-row>
                        <b-col sm="4">
                            <label>Head Loss Formula</label>
                        </b-col>
                        <b-col sm="8">
                           <b-select class="facility__input" size="sm" v-model="properties['head_loss']" :options="head_loss_option" disabled ></b-select>
                        </b-col>
                    </b-row> 
                      <b-row>
                        <b-col sm="4">
                            <label>Demand Model</label>
                        </b-col>
                        <b-col sm="8">
                              <b-select class="facility__input" size="sm" v-model="properties['demand_model']" :options="demand_model_option" disabled ></b-select>
                        </b-col>
                    </b-row>               
              <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('facility_info.status')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" :disabled="f_satus_level"></b-select>
                  </b-col>
              </b-row>          
          </b-tab>
           <b-tab :title="$t('model_info.setting')" v-if="properties!==null">
              <b-row>
                  <b-col sm="12">
                      <!--b-select class="facility__input" size="sm" v-model="type" :options="f_control_option" @change="switchSelect($event,1)" ></b-select-->
           <div class="info_box gis_master">
            <div class="status m1-map d-flex" style="position: relative; left: 0%;top:-20px;">
              <div>
                <input type="radio" id="cradio_01" name="cradio01" checked v-model="facility_type" value="junction">
                <label for="cradio_01">Junction</label>
              </div>
              <div>
                <input type="radio" id="cradio_02" name="cradio01"   v-model="facility_type" value="reservoir">
                <label for="cradio_02">Reservoir</label>
              </div>
              <div>
                <input type="radio" id="cradio_03" name="cradio01"  v-model="facility_type" value="tank">
                <label for="cradio_03">Tank</label>
              </div>
              <div>
                <input type="radio" id="cradio_04" name="radio01"   v-model="facility_type" value="pipe">
                <label for="cradio_04">Link</label>
              </div>
              <div>
                <input type="radio" id="cradio_05" name="radio01"   v-model="facility_type" value="pump">
                <label for="cradio_05">Pump</label>
              </div>
              <div>
                <input type="radio" id="cradio_06" name="radio01"   v-model="facility_type" value="valve">
                <label for="cradio_06">Valve</label>
              </div>   
            </div> 
           </div>
                  </b-col>     
              </b-row>
              <b-row>
                  <b-col sm="12" >

                    <ServerSideTable1 v-if="facility_type==='junction'" ref="junction_control"
                                    :columns="jun_columns"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :totalRecords = "totalRecords1"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                    @on-row-click="onRowClick"                                
                                    @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"
                                    @on_goto_map_click="on_goto_map_click"
                    />   
                      <ServerSideTable1 v-if="facility_type==='reservoir'" ref="Reservoirs_control"
                                    :columns="reser_columns"
                                    :totalRecords = "totalRecords2"  
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                    @on-row-click="onRowClick"                                
                                    @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"
                                    @on_goto_map_click="on_goto_map_click"
                    />   
               
                      <ServerSideTable1 v-if="facility_type==='tank'" ref="Tanks_control"
                                    :columns="tank_columns"
                                     :totalRecords = "totalRecords3"  
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                    @on-row-click="onRowClick"                                
                                    @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"
                                    @on_goto_map_click="on_goto_map_click"
                    />   
                      <ServerSideTable1 v-if="facility_type==='pipe'" ref="Pipes_control"
                                    :columns="pipe_columns"
                                     :totalRecords = "totalRecords4"  
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                    @on-row-click="onRowClick"                                
                                    @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"
                                    @on_goto_map_click="on_goto_map_click"
                    />   
                      <ServerSideTable1 v-if="facility_type==='pump'" ref="Pumps_control"
                                    :columns="pump_columns"
                                    :totalRecords = "totalRecords5"  
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                    @on-row-click="onRowClick"                                
                                    @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"
                                    @on_goto_map_click="on_goto_map_click"
                    />   
                      <ServerSideTable1 v-if="facility_type==='valve'" ref="Valves_control"
                                    :columns="valve_columns"
                                    :totalRecords = "totalRecords6"  
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                   
                                    :max_height="table_height1+'px'"
                                    @on-row-click="onRowClick"                                
                                    @on_info_click="on_facility_info"
                                    @changeCell = "on_changeCell"
                                    @on_goto_map_click="on_goto_map_click"
                    />   
                  </b-col>
              </b-row>
            

          </b-tab>
          <b-tab :title="$t('model_info.simulation')">
           <b-row style="position:absolute; margin-top:-50px;width:100%;z-index:101;">
            <b-col sm="7">
                   <!--b-select class="facility__input" size="sm" v-model="view_type" :options="f_control_option1" @change="switchSelect($event,2)" ></b-select-->
                <div class="info_box gis_master">
                    <div class="status m1-map d-flex" style="position: relative; left: 0%;top:0px;z-index:1000;">
                    <div>
                        <input type="radio" id="vradio_01" name="vradio01" checked v-model="view_type" value="node">
                        <label for="vradio_01">Node</label>
                    </div>
                    <div>
                        <input type="radio" id="vradio_02" name="vradio01"   v-model="view_type" value="link">
                        <label for="vradio_02">Link</label>
                    </div>
        
                    </div> 

                </div>
               </b-col> 
               <b-col sm="2">
                 <b-button size="sm" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" 
                  style="margin : 0px 0 10px 0;background:#6c757d;" @click="saveInpFile">saveInpFile</b-button>

                  </b-col>
                  <b-col sm="3">    
                      <b-button size="sm" class="btn map-button icon_btn icon_btn_text blue"   style="margin : 0px 0 10px"
                      @click="simulation">Simulation</b-button>
                </b-col>     
              </b-row>
            
              <b-row>
                  <b-col sm="12">
                     <ServerSideTable1  v-if="view_type==='node'" ref="control_list"
                                    :columns="columns1"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                               
                                    :max_height="table_height+'px'"
                                    @on-row-click="onRowClick"                            
                                    @change_set = "change_set"
                                    @on_info_click="on_facility_info1"
                                    @on_goto_map_click="on_goto_map_click"
                    />    
                     <ServerSideTable1  v-if="view_type==='link'" ref="control_list1"
                                    :columns="columns1_1"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                               
                                    :max_height="table_height+'px'"
                                    @on-row-click="onRowClick"  
                                    @change_set = "change_set"                          
                                    @on_info_click="on_facility_info1"
                                    @on_goto_map_click="on_goto_map_click"
                    />                                
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="12" style="margin-top :20px;">
                   Simulation Result

                  </b-col>     
              </b-row>              
              <b-row >
                  <b-col sm="12">
                    <div>  
                    <ServerSideTable1 v-if="view_type==='node'" ref="value_list"
                                    :columns="columns2"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                 
                                    :max_height="table_height+'px'"
                                    @on-row-click="onRowClick"                              
                                    @on_info_click="on_facility_info1"
                                    @on_goto_map_click="on_goto_map_click"
                    />
                        <ServerSideTable1 v-if="view_type==='link'" ref="value_list1"
                                    :columns="columns3"
                                    :search="false"
                                    :sort="false"
                                    :select="false"
                                    :add_custom_column="true"                                 
                                    :max_height="table_height+'px'"
                                    @on-row-click="onRowClick"                              
                                    @on_info_click="on_facility_info1"
                                    @on_goto_map_click="on_goto_map_click"
                    />
                    </div>                     
                  </b-col>
              </b-row>
          </b-tab>       
      
      </b-tabs>

      <b-row class="facility-info-footer-row m-0 mb-3">
        <b-col sm="6">
          <!--<b-button @click="$refs.modal.hide()">Close</b-button> -->
          <b-button @click="cancel1()" block class="facility-info-footer__button facility-info-footer__button-cancel">Close</b-button>
        </b-col>
        <b-col sm="6">
          <b-button @click="apply()" block class="facility-info-footer__button facility-info-footer__button-ok">Apply</b-button>
        </b-col>
      </b-row>
      <div>
      
      </div>
    </div>
</template>

<script>
//import Compressor from 'compressorjs';
import ServerSideTable1 from '@/components/table/ServerSideTable_simulation';
//import { Project, Workspace,NodeProperty,CountType,  NodeType, LinkType, LinkProperty} from 'epanet-js';
import {Workspace,Project, NodeProperty,CountType, Option, NodeType, LinkType, LinkProperty} from 'epanet-js';
//import { NodeProperty } from 'epanet-js';
export default {
    name: 'FacilityInfoType',
    props: {
    },
    watch:{
      facility_type(){
        setTimeout(()=>{  
          this.go_table_view();
        }, 100);
      },
      view_type(){
        setTimeout(()=>{  
          this.switchSelect(this.view_type,2);
        }, 100);
      },
      tabIndex (){
          if(this.tabIndex == 1){
             this.type = 'junction'
             setTimeout(()=>{
                this.$refs.junction_control.setUrl(this.Junctions) ;                
             }
            , 500)
                
              //  this.$refs.junction_control.setUrl(this.Junctions) ;
            
          }else if(this.tabIndex == 2){
              this.view_type = 'node'
              this.switchSelect('node',2)
             // this.control_setting(1);
             // this.value_set(1);
            //  this.control_setting(2);
            //  this.value_set(2);
          }
      },  
    //  Junctions(){
    //      this.reset();
    //  }   
    },
    data() {
        return {
            totalRecords1:0,
            totalRecords2:0,
            totalRecords3:0,
            totalRecords4:0,
            totalRecords5:0,
            totalRecords6:0,
            tabIndex : 0,
            table_height1 : "570",
            table_height : "250",
            properties: null,
            properties1: [], //관리대장속성들 
            properties_bak: null,
            properties1_bak: [],//관리대장속성들 back
            is_prv : false,  
            loading : false, 
            loading1 : false,
            loading2 : false,        
            is_control_valve : false,
            is_air_valve : false,
            is_water_meter:false,            
            info_u_YN : true,
            first_pipe : true,
            geo_u_YN : false,
            facility_type : "junction",
            view_type : "node",
            ditem :[],
            columns: [
                {label: this.$t('facility_info.table_column.no'), field: 'id'},
                {label: this.$t('facility_info.table_column.layer'), field: 'layer'},
                {label: this.$t('facility_info.table_column.title'), field: 'title'},
                {label: this.$t('facility_info.table_column.s_time'), field: 'create_datetime'},
            ],
            table: [],
            layer_type_option : {},
            layer_type_option1: [
                {value: 0, text: 'Pipe'},
                {value: 1, text: 'Valve'},
                {value: 2, text: 'Water Meter'},
                {value: 3, text: 'Pump'},
                {value: 4, text: 'Hydrant'},
                {value: 5, text: 'LAYER_JUNCTION'},
                {value: 6, text: 'Other'},
                {value: 7, text: 'Block'},
                {value: 8, text: 'Water Treatment Plant'},
                {value: 9, text: 'Pumping Station'},
                {value: 10, text: 'Water Basin'},
                {value: 11, text: 'Chamber'},
                {value: 12, text: 'Gauge'},
                {value: 13, text: 'Other'},
            
            ],
            f_type_option: [],
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            f_control_option: [
                { value: 'junction', text: 'Junctions' },
                { value: 'reservoir', text: 'Reservoirs' },
                { value: 'tank', text: 'Tanks' },
                { value: 'pipe', text: 'Pipes' },
                { value: 'pump', text: 'Pumps' },
                { value: 'valve', text: 'Valves' },
            ], 
               f_control_option1: [
                { value: 'node', text: 'Node(Junctions,Reservoirs,Tanks)' },
                { value: 'link', text: 'Line(Pipes,Valves,Pumps)' },
            ],  
            flow_unit_option : [
                { value: 4, text: 'AFD' },
                { value: 0, text: 'CFS' },
                { value: 9, text: 'CMD' },
                { value: 8, text: 'CMH' },
                { value: 1, text: 'GPM' },
                { value: 3, text: 'IMG' },
                { value: 6, text: 'LPM' },
                { value: 5, text: 'LPS' },
                { value: 2, text: 'MGD' },
                { value: 7, text: 'MLD' }
            ],
            demand_model_option : [
                { value: 0, text: 'DDA' },
                { value: 1, text: 'PDA' },
            ],
            head_loss_option : [
                { value: 0, text: 'H-W' },
                { value: 1, text: 'D-W' },
                { value: 2, text: 'C-M' },
            ],            
            Junctions : [],
            Cont_Junctions : [],
            Val_Junctions : [], 
            Cont_node : [],
            Value_node : [], 
            Cont_link : [],
            Value_link : [], 

            Reservoirs : [],
            Tanks : [],
            Pipes : [],
            Pumps : [],
            Valves : [],
            setting : {},     
            f_satus_level : true,
            material_option: [],
            img1:'',
            img2:'',
            img3:'',
            file1: null,
            b_show : false,
            vcount : 0,
            wcount : 0,
            vlength : 0,
            plength :0,
            img_list: [],            
            preview: null,
            image: null,
            exist_image : false,
            preview_list: [],
            image_list: [],
            listKey: 0,
            del_image_list : [],
            imgUrl:null,
            tag : null,
           jun_columns: [
                    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Elev.', field: 'elevation', sortable: false,  width: '20%',},
                    {label: 'Demand', field: 'demand',width: '20%',},
                    {label: 'Pattern', field: 'pattern',width: '10%',},  
                //    {label: 'Control', field: 'control_yn',width: '10%',}, 
                     {label: 'Value', field: 'value_yn',width: '10%',},
                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
                  //  {label: 'Value', field: 'set1',width: '10%',},
                  
                ], 
            reser_columns : [
            {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Head', field: 'head', sortable: false,  width: '20%',},
                    {label: 'Pattern', field: 'pattern',width: '10%',},  
            //        {label: 'Control', field: 'control_yn',width: '10%',}, 
                    {label: 'Value', field: 'value_yn',width: '10%',},
                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ],     
           tank_columns : [
            {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Elev.', field: 'elevation', sortable: false,  width: '10%',},
                    {label: 'InitLevel', field: 'InitLevel',width: '10%',},
                    {label: 'MinLevel', field: 'MinLevel',width: '10%',},  
                    {label: 'MaxLevel', field: 'MaxLevel',width: '10%',},  
                    {label: 'Diameter', field: 'diameter',width: '10%',},  
                    {label: 'MinVol', field: 'MinVol',width: '10%',},  
                    {label: 'VolCurve', field: 'VolCurve',width: '10%',},  
                    {label: 'Overflow', field: 'overflow',width: '10%',}, 
                //    {label: 'Cont.', field: 'control_yn',width: '5%',}, 
                    {label: 'Value', field: 'value_yn',width: '5%',},
                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ], 
            pipe_columns : [
            {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Node1', field: 'node1', sortable: false,  width: '100%',},
                    {label: 'Node2', field: 'node2',width: '10%',},
                    {label: 'Len.', field: 'length',width: '10%',},                  
                    {label: 'Dia.', field: 'diameter',width: '10%',},  
                    {label: 'Rough.', field: 'roughness',width: '10%',},  
                    {label: 'Minor.', field: 'minorLoss',width: '10%',},  
                    {label: 'Status', field: 'status',width: '5%',}, 
               //     {label: 'Con.', field: 'control_yn',width: '5%',}, 
                    {label: 'Val.', field: 'value_yn',width: '5%',},                    
                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ],    
            pump_columns : [
                    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Node1', field: 'node1', sortable: false,  width: '20%',},
                    {label: 'Node2', field: 'node2',width: '10%',},  
                    {label: 'Parameters', field: 'parameters',width: '10%',}, 
                //    {label: 'Cont.', field: 'control_yn',width: '5%',}, 
                    {label: 'Val.', field: 'value_yn',width: '5%',},

                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ],                                             
 
            valve_columns : [
                    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Node1', field: 'node1', sortable: false,  width: '20%',},
                    {label: 'Node2', field: 'node2',width: '10%',},  
                    {label: 'Diameter', field: 'diameter',width: '10%',}, 
                    {label: 'Type', field: 'type',width: '10%',},
                    {label: 'Setting', field: 'setting',width: '10%',},
                    {label: 'MinorLoss', field: 'minorLoss',width: '10%',},
                    {label: 'Status', field: 'status',width: '5%',}, 
                //    {label: 'Cont.', field: 'control_yn',width: '5%',}, 
                    {label: 'Val.', field: 'value_yn',width: '5%',},

                    {label: 'code', field: 'code', hidden: true, },
                    {label: 'code1', field: 'code1', hidden: true, },
            ],                                             
            columns2: [
                    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '10%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '10%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'E.L', field: 'el', sortable: false,  width: '20%',},
                    {label: 'Value', field: 'value',width: '20%',},        
                   {label: 'code', field: 'code', hidden: true, },
                ],     
            columns3: [
                    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '10%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '10%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'Status', field: 'status', sortable: false,  width: '20%',},
                    {label: 'Flow', field: 'value',width: '20%',},        
                   {label: 'code', field: 'code', hidden: true, },
             ],                      
            columns1: [
                    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '10%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '10%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'type', field: 'type', width: '10%',},
                    {label: 'Set1(J:demand,O:eleva)', field: 'set1', sortable: false,  width: '20%',},
                    {label: 'Set2(T:level)', field: 'set2',width: '20%',},        
                     {label: 'code', field: 'code', hidden: true, },
                ], 
              columns1_1: [
                    {label: this.$t('gis.table_column.map'), field: 'go_map', width: '10%', sortable: false},
                    {label: this.$t('gis.table_column.info'), field: 'info', width: '10%', sortable: false},
                    {label: 'index', field: 'idx', width: '10%',},
                    {label: 'ID', field: 'id', width: '10%',},
                    {label: 'type', field: 'type', width: '10%',},
                    {label: 'Set1\n(1:On/0:Off)', field: 'set1', sortable: false,  width: '20%',},
                    {label: 'Set2(pipe:C/Valve:setting)', field: 'set2',width: '20%',},        
                    {label: 'code', field: 'code', hidden: true, },
                ], 
              file_model : null,                               
        }
    },

    mounted () {
               this.layer_type_option = {};
               this.layer_type_option[0] = 'Pipe' ;
               this.layer_type_option[1] = 'Valve' ;                
               this.layer_type_option[2] =  'Water Meter' ;
               this.layer_type_option[3] = 'Pump' ;
               this.layer_type_option[4] =  'Hydrant';
               this.layer_type_option[5] =  'Junction';
               this.layer_type_option[6] =  'Other';
               this.layer_type_option[7] =  'Block';
               this.layer_type_option[8] =  'Water Treatment Plant';
               this.layer_type_option[9] =  'Pumping Station';
               this.layer_type_option[10] =  'Water Reservoir';
               this.layer_type_option[11] =  'Chamber';
               this.layer_type_option[12] =  'Gauge';
               this.layer_type_option[13] =  'Other';
    },
    methods: {
      loading_model(){
        const ws = new Workspace()
        const model = new Project(ws)
        ws.writeFile("net2.inp", this.file_text);

        model.open("net2.inp", "report.rpt", "out.bin");
        model.solveH();
     //   const nodeCount = model.getCount(CountType.NodeCount);  
     //   const linkCount = model.getCount(CountType.NodeCount);                  
        //const nodeIds = this.getNodes_return(model);
        //const linkIds = this.getLinks_return(model);
        
       // model.close();
        this.file_model = model;
        this.reportFile = ws.readFile("report.rpt");
        this.getNodes_return();
        this.getLinks_return();
        //nodecnt : 압력값을 가져온다.
        
        //lint : 유량값을 가져온다.
         


        //document.getElementById("epanet-report").innerHTML = reportFile + "<br/>" +nodeIds + "<br/>"+linkIds;
        //  ++this.listKey;
        //document.getElementById("epanet-report").innerHTML = "model.node count : " + cnt;
   
    },
    importTextFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'text/plain'; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"
      // this 접근을 위해 선언 필요
      const self = this;
      input.onchange = function () {
          const file = new FileReader();
          file.onload = () => {
          //    document.getElementById('text-data').textContent = file.result;
            //  self.$data.textData = file.result;
              self.$data.file_text = file.result
              self.loading_model();
            
          };
          self.$data.read_file_name = this.files[0].name ;
          file.readAsText(this.files[0]);
      };
      input.click();
      // const fs = require("fs");
      // const net1 = fs.readFileSync("net1.inp");
      // Runs toolkit methods: EN_open, EN_solveH & EN_close
    },
      InpfileDownload() {
       var model_id = this.properties['id']
       var f_name =  model_id+"_"+this.box[model_id].file_name;
       this.file_model.saveInpFile(f_name) ;
          //console.log(save_text)
            const ws = new Workspace()
            const inpFile = ws.readFile(f_name);
            //console.log(inpFile)
            this.inpfile_dwnload(f_name, inpFile)
            //nodecnt : 압력값을 가져온다.
            
            //lint : 유량값을 가져온다.
            
           // document.getElementById("epanet-report").innerHTML = reportFile + "<br/>" +nodeIds + "<br/>"+linkIds;
           //  ++this.listKey;
           //this.file_model.close();
    },
    inpfile_dwnload(f_name,text){            
            
        var blob = new Blob([text], {type:'text/plain'});
        var link = document.createElement("a");
        link.download = f_name;
        link.innerHTML = "Download File";
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        }, 100);
    },
        onCheckChange(i){
            alert(i)
        },
        saveInpFile(){
           var today = new Date();

           var year = today.getFullYear();
           var month = ('0' + (today.getMonth() + 1)).slice(-2);
           var day = ('0' + today.getDate()).slice(-2);
           var hours = ('0' + today.getHours()).slice(-2); 
           var minutes = ('0' + today.getMinutes()).slice(-2);
           var seconds = ('0' + today.getSeconds()).slice(-2); 

           var timeString = hours + minutes  + seconds;
           var dateString = year +  month + day;
     
           //console.log(dateString);
           var f_name =  dateString+timeString+"_saveInpFile.inp"
           this.file_model.saveInpFile(f_name) ;
           //console.log(save_text)
            const ws = new Workspace()
            const inpFile = ws.readFile(f_name);
            //console.log(inpFile)
            this.inpfile_dwnload(f_name, inpFile)
            //nodecnt : 압력값을 가져온다.
            
            //lint : 유량값을 가져온다.
            
           // document.getElementById("epanet-report").innerHTML = reportFile + "<br/>" +nodeIds + "<br/>"+linkIds;
           //  ++this.listKey;
           //this.file_model.close();
        },

        change_set( changedData, column, row) {
            //var row_1 = row
           // alert('changedData, column, row ==>' +changedData +":"+ column+":"+ row )
           //1 : node, 2:link 
           
                  
            var type = row.type; //junction,
            var r_column = column; //set1,set2
            var id = row.id; //id값
           

            if(type == 'junction'){
                for(var j = 0 ; j < this.Junctions.length ;++j){
                    if (id===this.Junctions[j].id)  {
                        if(r_column === "set1")         
                            this.Junctions[j].demand = changedData                      
                    }
                }
            }else if(type =='reservoir' ){
                for( j = 0 ; j < this.Reservoirs.length ;++j){
                    if (id === this.Reservoirs[j].id )  {
                        if(r_column === "set1")    
                            this.Reservoirs[j].elevation = changedData
                    }
                }
            }else if(type =='tank' ){   
              for( j = 0 ; j < this.Tanks.length ;++j){
                
                if (id === this.Tanks[j].id )  {
                     if(r_column === "set1")    
                       this.Tanks[j].elevation = changedData
                     else if(r_column === "set2")   
                       this.Tanks[j].tanklevel = changedData
                    }
               }
            }else if(type =='pipe' ){   
              for( j = 0 ; j < this.Pipes.length ;++j){
                
                if (id===this.Pipes[j].id)  {
                     if(r_column === "set1")    
                       this.Pipes[j].status = changedData                       
                     else if(r_column === "set2")   
                       this.Pipes[j].roughness = changedData
                    }
               }
            }else if(type =='valve' ){   
              for( j = 0 ; j < this.Valves.length ;++j){
                
                if (id===this.Valves[j].id)  {
                     if(r_column === "set1")    
                       this.Valves[j].status = changedData
                     else if(r_column === "set2")   
                       this.Valves[j].setting = changedData
                }
               }          
            }else if(type =='pump' ){   
                for( j = 0 ; j < this.Pumps.length ;++j){
                    
                    if (id===this.Pumps[j].id)  {
                        if(r_column === "set1")    
                        this.Pumps[j].status = changedData
                    
                    }
                }  
            }   
      
       
                           
        
        
        
       },
       simulation(){
          
          this.control_setting(1); //
          
          this.control_setting(2);
          //this.file_model.initH();
          //this.Cont_node,this.Cont_link
          for(var i = 0 ; i <  this.Cont_node.length ;++i){  
            var idx = this.Cont_node[i].idx
            var type = this.Cont_node[i].type
            var set1 = Number(this.Cont_node[i].set1)
            var set2 = Number(this.Cont_node[i].set2)
            if(type === "junction")                
                this.file_model.setNodeValue(idx,NodeProperty.BaseDemand,set1  ) 
            else if(type === "tank" ){
                this.file_model.setNodeValue(idx,NodeProperty.Elevation,set1  ) 
                this.file_model.setNodeValue(idx,NodeProperty.TankLevel,set2 ) 
            }
            else if( type === "reservoir")
                this.file_model.setNodeValue(idx,NodeProperty.Elevation,set1  )     
          }
          //link setting  
          for(i = 0 ; i <  this.Cont_link.length ;++i){  
            idx = this.Cont_link[i].idx
            type = this.Cont_link[i].type
            set1 = Number(this.Cont_link[i].set1) //0:close,1 : open
            set2 = Number(this.Cont_link[i].set2)
            if(type === "pipe"){                
                this.file_model.setLinkValue(idx,LinkProperty.InitStatus,set1  ) 
                this.file_model.setLinkValue(idx,LinkProperty.Status,set1  )                 
                this.file_model.setLinkValue(idx,LinkProperty.Roughness,set2  ) //거칠기 계수
            }else if(type === "pump" ){
                this.file_model.setLinkValue(idx,LinkProperty.InitStatus,set1  )  
                this.file_model.setLinkValue(idx,LinkProperty.Status,set1  )                 
            }
            else if( type === "valve"){
                
                this.file_model.setLinkValue(idx,LinkProperty.InitStatus,set1  )  
                this.file_model.setLinkValue(idx,LinkProperty.Status,set1  )     
                if(typeof(set2)==='number'){ 
                    this.file_model.setLinkValue(idx,LinkProperty.InitSetting,set2  )  //  Pipe/valve minor loss coefficient. 
                    this.file_model.setLinkValue(idx,LinkProperty.Setting,set2  )
                }
            }
          }

        //  this.file_model.saveInpFile("net3.inp") ;  
        //  this.file_model.init("report1.rpt", "out.bin",1,0)
        //  this.file_model.runProject("net2.inp", "report1.rpt", "out.bin");
          this.file_model.solveH();
          
          //this.$refs.value_list.setUrl([]); 
          /*
          this.Val_Junctions = [];
          alert(set1)  
          for(var j = 0 ; j <  this.Val_Junctions.length ;++j){  
            var idx1 = this.Val_Junctions[j].idx
            //var set1 = this.$refs.value_list.rows[i].set1
            this.Val_Junctions[j].value = Math.round(this.file_model.getNodeValue(idx1, NodeProperty.Pressure)*100)/100 
          }
          */
           //this.$refs.value_list.setUrl(this.Val_Junctions);
            this.getNodes_return();
            this.getLinks_return();
            //this.value_set(1);
            //this.value_set(2);
            this.view_type = 'node'
            this.switchSelect('node',2)
          //  this.control_setting(1);
          //  this.value_set(1);
           this.$emit('simulation_model_map_view',this.file_model )
        },
      getNodes_return() {
        //지도에 map
       // var ext = [];

        const model = this.file_model;
         const nodeCount = model.getCount(CountType.NodeCount);            
         this.Junctions = [] ; //node-junction을 넣어준다.
         this.Reservoirs = [] ;
         this.Tanks = [] ;
       //  this.nodeIds = [];
         var abox =null;
      
       //  let str = 'Node Results:<br/>';
         for (let i = 1; i <= nodeCount; i++) {
            var control_yn = false ;
            var value_yn = false ;
            if (model.getNodeType(i) === NodeType.Junction) {
               // const nodeId = model.getNodeId(i);
               
               if (this.setting.set_contrl.Junctions.indexOf(i) > -1)  control_yn = true ; 
               if (this.setting.set_value.Junctions.indexOf(i) > -1) value_yn = true;
               abox = {};
            
               abox = {'idx':  i, 
                       'id': model.getNodeId(i),
                       'elevation' : model.getNodeValue(i,NodeProperty.Elevation) > 0 ? Math.round(model.getNodeValue(i,NodeProperty.Elevation)*10)/10 :model.getNodeValue(i,NodeProperty.Elevation) , // 고도값을 가져온다.
                       'demand' : model.getNodeValue(i,NodeProperty.Demand)> 0 ? Math.round(model.getNodeValue(i,NodeProperty.Demand)*1000)/1000 :model.getNodeValue(i,NodeProperty.Demand),  // 고도값을 가져온다.
                       'pattern':  model.getNodeValue(i,NodeProperty.Pattern), //패턴을 가져온다.       
                       'value': Math.round(model.getNodeValue(i, NodeProperty.Pressure)*100)/100 ,    // 압력계산값을 가져온다.                                
                       'value_yn' : value_yn,
                       'control_yn' : control_yn,
                       'code' : 1100 , //map point layer      
                       'code1' : 'junction',  
               } ; 
               this.Junctions.push(abox) ; 
            }else if(model.getNodeType(i) === NodeType.Tank) {
            //  const tankId = model.getNodeId(i);
               
              abox = {};
            
               abox = {'idx':  i, 
                       'id': model.getNodeId(i),
                       'elevation' : model.getNodeValue(i,NodeProperty.Elevation), // 고도값을 가져온다.
                       'InitLevel' : model.getNodeValue(i,NodeProperty.TankLevel), //
                       'MinLevel' : model.getNodeValue(i,NodeProperty.MinLevel), //
                       'MaxLevel' : model.getNodeValue(i,NodeProperty.MaxLevel), //
                       'diameter' : model.getNodeValue(i,NodeProperty.TankDiam), // 고도값을 가져온다.
                       'MinVol':  model.getNodeValue(i,NodeProperty.MinVolume), //패턴을 가져온다.                                            
                       'VolCurve' : model.getNodeValue(i,NodeProperty.VolCurve), // 
                       'tanklevel' : Math.round(model.getNodeValue(i, NodeProperty.TankLevel)*100)/100 ,    // Level을 가져온다.      
                       'value': Math.round(model.getNodeValue(i, NodeProperty.TankLevel)*100)/100 ,    // Level을 가져온다.   
                       'code' : 1100 , //map point layer       
                       'code1' : 'tank',        
                       'value_yn' : this.setting.set_value.Tanks.indexOf(i) > -1 ? true : false,
                       'control_yn' : this.setting.set_contrl.Tanks.indexOf(i) > -1 ? true : false,   
               } ; 
               this.Tanks.push(abox) ;
            }else if(model.getNodeType(i) === NodeType.Reservoir) {
             //   const reserId = model.getNodeId(i);
               
                 abox = {};
            
               abox = {'idx':  i, 
                       'id': model.getNodeId(i),
                       'head' : model.getNodeValue(i,NodeProperty.Head), // 고도값을 가져온다.
                       'pattern' : model.getNodeValue(i,NodeProperty.Pattern), //       
                       'elevation' :  Math.round(model.getNodeValue(i, NodeProperty.Elevation)*100)/100 , 
                       'value': Math.round(model.getNodeValue(i, NodeProperty.Elevation)*100)/100 ,    // Level을 가져온다.   
                       'code' : 1100 , //map point layer        
                       'code1' : 'reservoir',      
                       'value_yn' : this.setting.set_value.Reservoirs.indexOf(i) > -1 ? true : false,
                       'control_yn' : this.setting.set_contrl.Reservoirs.indexOf(i) > -1 ? true : false,      
               } ; 
               this.Reservoirs.push(abox) ;
            }
          }
         //
        },
        getLinks_return() {
            this.Pipes = [] ;
            this.Pumps = [] ;
            this.Valves = [] ;
            const model = this.file_model;
            var abox =null;
            const linkount = model.getCount(CountType.LinkCount);      
            for (let i = 1; i <= linkount; i++) {                
                //const linkId = model.getLinkId(i);
                var control_yn = false ;
                var value_yn = false ;
                if(model.getLinkType(i) === LinkType.Pipe) {
                    const reserId = model.getLinkId(i);                   
                    abox = {};
                    if (this.setting.set_contrl.Pipes.indexOf(i) > -1)  control_yn = true ; 
                    if (this.setting.set_value.Pipes.indexOf(i) > -1) value_yn = true;
                    abox = {'idx':  i, 
                       // 'id': model.getLinkId(i),
                        'id': reserId,
                        'node1' : model.getLinkNodes(i).node1, // 고도값을 가져온다.
                        'node2' : model.getLinkNodes(i).node2, //               
                        'length' : Math.round(model.getLinkValue(i,LinkProperty.Length)*100)/100, //
                        'diameter' : Math.round(model.getLinkValue(i,LinkProperty.Diameter)*100)/100, //
                        'roughness' :Math.round( model.getLinkValue(i,LinkProperty.Roughness)*100)/100, //
                        'minorLoss' : Math.round(model.getLinkValue(i,LinkProperty.MinorLoss)*100)/100, //  
                        'value': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량
                        'value_yn' : value_yn,
                        'control_yn' : control_yn,
                        'status' : model.getLinkValue(i,LinkProperty.Status), //
                        'code' : 1101 , //map layer_id 라인스트링 layer
                        'code1' : 'pipe',        
                    } ; 
                    this.Pipes.push(abox)
                }else if(model.getLinkType(i) === LinkType.Pump) {
                    const reserId = model.getLinkId(i);                   
                    abox = {};
                    abox = {'idx':  i, 
                       // 'id': model.getLinkId(i),
                        'id': reserId,
                        'node1' : model.getLinkNodes(i).node1, // 고도값을 가져온다.
                        'node2' : model.getLinkNodes(i).node2, //               
                        'parameters' : model.getLinkValue(i,LinkProperty.PumpPower), //   
                        'status': model.getLinkValue(i,LinkProperty.Status),
                        'value': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량                        
                        'code' : 1101 , //map layer_id 라인스트링 layer   
                        'code1' : 'pump',  
                        'value_yn' : this.setting.set_value.Pumps.indexOf(i) > -1 ? true : false,
                        'control_yn' : this.setting.set_contrl.Pumps.indexOf(i) > -1 ? true : false,             
                    } ; 
                    this.Pumps.push(abox)
                }else{ //valves
                    abox = {};
                    abox = {'idx':  i, 
                        'id': model.getLinkId(i),
                        'node1' : model.getLinkNodes(i).node1, // 고도값을 가져온다.
                        'node2' : model.getLinkNodes(i).node2, //               
                        'type' : model.getLinkType(i), //valve_type종류
                        'diameter' : model.getLinkValue(i,LinkProperty.Diameter), //
                        'status': model.getLinkValue(i,LinkProperty.Status), //
                        'setting' : model.getLinkValue(i,LinkProperty.Setting), //
                        'minorLoss' : model.getLinkValue(i,LinkProperty.MinorLoss), //               
                        'value': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량
                        'code' : 1101 , //map layer_id 라인스트링 layer
                        'code1' : 'valve',    
                        'value_yn' : this.setting.set_value.Valves.indexOf(i) > -1 ? true : false,
                        'control_yn' : this.setting.set_contrl.Valves.indexOf(i) > -1 ? true : false,           
                    } ; 
                    this.Valves.push(abox)           
                }
            }
        },  

        control_setting(type){ //1 : node, 2:link 
          if(type == 1) {
            this.Cont_node = [];   
                  
            var j_index = this.setting.set_contrl.Junctions;
            var r_index = this.setting.set_contrl.Reservoirs;
            var t_index = this.setting.set_contrl.Tanks;
      
          //  for(var i = 0 ; i < j_index.length ;++i){
             var abox = {}
             var j = 0;
            for( j = 0 ; j < this.Junctions.length ;++j){
                abox = {}
                if (j_index.indexOf(this.Junctions[j].idx) > -1)  {
                //if(j_index[i] === this.Junctions[j].idx){
                    this.Junctions[j].control_yn = true;
                    abox = {'idx':  this.Junctions[j].idx, 
                        'id': this.Junctions[j].id,
                        'type' : 'junction',
                        'set1' : this.Junctions[j].demand,
                        'code' : 1100,
                        'properties' :  this.Junctions[j]
                    }
                  
                    this.Cont_node.push(abox)
                }else{
                    this.Junctions[j].control_yn = false;
                }
            }
            for( j = 0 ; j < this.Reservoirs.length ;++j){
                abox = {}
                if (r_index.indexOf(this.Reservoirs[j].idx) > -1)  {
                  this.Reservoirs[j].control_yn = true ;
                  abox = {'idx':  this.Reservoirs[j].idx, 
                       'id': this.Reservoirs[j].id,
                       'type' : 'reservoir',
                       'set1' : this.Reservoirs[j].elevation,
                       'code' : 1100,
                       'properties' : this.Reservoirs[j]
                  }
                
                  this.Cont_node.push(abox)
                }else{
                    this.Reservoirs[j].control_yn = false ;
                }                
            }   
            for( j = 0 ; j < this.Tanks.length ;++j){
                abox = {}
                if (t_index.indexOf(this.Tanks[j].idx) > -1)  {
                 this.Tanks[j].control_yn = true
                 abox = {'idx':  this.Tanks[j].idx, 
                       'id': this.Tanks[j].id,
                       'type' : 'tank',
                       'set1' : this.Tanks[j].elevation,
                       'set2' : this.Tanks[j].tanklevel,
                       'code' : 1100,
                       'properties' : this.Tanks[j]
                  }
                  
                  this.Cont_node.push(abox)
                }else{
                    this.Tanks[j].control_yn = false
                }                
            }
            setTimeout(()=>{
                this.$refs.control_list.setUrl( this.Cont_node)
            }  , 100);
           }else{ //line
            this.Cont_link = [];   
                  
            var p_index = this.setting.set_contrl.Pipes;
            var v_index = this.setting.set_contrl.Valves;
            var u_index = this.setting.set_contrl.Pumps;
            abox = {}
          //  for(var i = 0 ; i < j_index.length ;++i){
            var i = 0
            for( i = 0 ; i < this.Pipes.length ;++i){
                abox = {}
                if (p_index.indexOf(this.Pipes[i].idx) > -1)  {
                //if(j_index[i] === this.Junctions[j].idx){
                    this.Pipes[i].control_yn = true
                    abox = {'idx':  this.Pipes[i].idx, 
                        'id': this.Pipes[i].id,
                        'type' : 'pipe', 
                        'set1' : this.Pipes[i].status, //open,close
                        'set2' : this.Pipes[i].roughness, //coefficient
                        'code' : 1101,
                        'properties' : this.Pipes[i]
                    }
                 
                    this.Cont_link.push(abox)
                }else{
                    this.Pipes[i].control_yn = false
                }
            }
            for( i = 0 ; i < this.Valves.length ;++i){
                abox = {}
                if (v_index.indexOf(this.Valves[i].idx) > -1)  {
                 this.Valves[i].control_yn = true   
                 abox = {'idx':  this.Valves[i].idx, 
                       'id': this.Valves[i].id,
                       'type' : 'valve',
                       'set1' : this.Valves[i].status, //open/close
                       'set2' : this.Valves[i].setting, //Opening rat(%)
                       'code' : 1101,
                       'properties' :  this.Valves[i]
                  }
                  
                  this.Cont_link.push(abox)
                }else{
                    this.Valves[i].control_yn = false
                }                
            }   
            for( i = 0 ; i < this.Pumps.length ;++i){
                abox = {}
                if (u_index.indexOf(this.Pumps[i].idx) > -1)  {
                 this.Pumps[i].control_yn = true
                 abox = {'idx':  this.Pumps[i].idx, 
                       'id': this.Pumps[i].id,
                       'type' : 'pump',
                       'set1' : this.Pumps[i].status, //open/close
                       'code' : 1101,
                       'properties' : this.Pumps[i]
                  }
                 
                  this.Cont_link.push(abox)
                }else{
                    this.Pumps[i].control_yn = false
                }                
            }
             if(this.$refs.control_list1 !== undefined)
                this.$refs.control_list1.setUrl( this.Cont_link)
           }
        },
        value_set(i_code){
          
            
        
           // for(var i = 0 ; i < j_index.length ;++i){

            var abox = {}
            if(i_code==1){ 
                this.Value_node = [];
                var jv_index = this.setting.set_value.Junctions;
                var rv_index = this.setting.set_value.Reservoirs;
                var tv_index = this.setting.set_value.Tanks;
                //junction
                for(var j = 0 ; j < this.Junctions.length ;++j){
                // if(j_index[i] === this.Junctions[j].idx){
                    this.Junctions[j].value_yn = true
                    if (jv_index.indexOf(this.Junctions[j].idx) > -1)  {   
                        abox = {'idx':  this.Junctions[j].idx, 
                            'id': this.Junctions[j].id,
                            'type' : 'junction',
                            'value' :  this.Junctions[j].value,
                            'code' : 1100,
                            'properties' : this.Junctions[j]
                        }                        
                        this.Value_node.push(abox)
                    }else{
                        this.Junctions[j].value_yn = false
                    }
                }
                // reservoir
                 for(j = 0 ; j < this.Reservoirs.length ;++j){
                // if(j_index[i] === this.Junctions[j].idx){
                    if (rv_index.indexOf(this.Reservoirs[j].idx) > -1)  {   
                        this.Reservoirs[j].value_yn = true
                        abox = {'idx':  this.Reservoirs[j].idx, 
                            'id': this.Reservoirs[j].id,
                            'type' : 'reservoir',
                            'value' :  this.Reservoirs[j].value,
                            'code' : 1100,
                            'properties' : this.Reservoirs[j]
                        }
                      
                        this.Value_node.push(abox)
                    }else{
                        this.Reservoirs[j].value_yn = false
                    }
                }
                //
                for(j = 0 ; j < this.Tanks.length ;++j){
                // if(j_index[i] === this.Junctions[j].idx){
                    if (tv_index.indexOf(this.Tanks[j].idx) > -1)  {  
                        this.Tanks[j].value_yn = true 
                        abox = {'idx':  this.Tanks[j].idx, 
                            'id': this.Tanks[j].id,
                            'type' : 'tank',
                            'value' : this.Tanks[j].value,
                            'code' : 1100,
                            'properties' : this.Tanks[j]
                        }
                        this.Value_node.push(abox)
                    }else{
                        this.Tanks[j].value_yn = false
                    }
                }
                setTimeout(()=>{
                  this.$refs.value_list.setUrl(this.Value_node); 
                }  , 100);
              
            }else{
                this.Value_link = [] ;
                var p_index = this.setting.set_value.Pipes;
                var v_index = this.setting.set_value.Valves;
                var u_index = this.setting.set_value.Pumps;
                for(var i = 0 ; i < this.Pipes.length ;++i){
                // if(j_index[i] === this.Junctions[j].idx){
                    if (p_index.indexOf(this.Pipes[i].idx) > -1)  { 
                        this.Pipes[i].value_yn = true  
                        abox = {'idx':  this.Pipes[i].idx, 
                            'id': this.Pipes[i].id,
                            'type' : 'pipe',
                            'status' :  this.Pipes[i].status,
                            'value' :this.Pipes[i].value,
                            'code' : 1101,
                            'properties' : this.Pipes[i]
                        }
                      
                        this.Value_link.push(abox)
                    }else{
                        this.Pipes[i].value_yn = false
                    }
                }
                // reservoir
                 for(i = 0 ; i < this.Valves.length ;++i){
                // if(j_index[i] === this.Junctions[j].idx){
                    this.Valves[i].value_yn = true
                    if (v_index.indexOf(this.Valves[i].idx) > -1)  {   
                        abox = {'idx':  this.Valves[i].idx, 
                            'id': this.Valves[i].id,
                            'type' : 'valve',
                            'status' :  this.Valves[i].status,
                            'value' : this.Valves[i].value,
                            'code' : 1101,
                            'properties' : this.Valves[i]
                        }
                       
                        this.Value_link.push(abox)
                    }else{
                        this.Valves[i].value_yn = false
                    }
                }
                //
                for(i = 0 ; i < this.Pumps.length ;++i){
                // if(j_index[i] === this.Junctions[j].idx){
                    if (u_index.indexOf(this.Pumps[i].idx) > -1)  {   
                        this.Pumps[i].value_yn = true
                        abox = {'idx':  this.Pumps[i].idx, 
                            'id': this.Pumps[i].id,
                            'type' : 'pump',
                            'status' :  this.Pumps[i].status,
                            'value' : this.Pumps[i].value,
                            'code' : 1101,
                            'properties' : this.Pumps[i]
                        }
                       
                        this.Value_link.push(abox)
                    }else{
                        this.Pumps[i].value_yn = false
                    }
                }



                this.$refs.value_list1.setUrl(this.Value_link);
            }
              
              
                
           // }

           
        },
        set_node_links_return_idx(array_links, idx){ 
            for(var i = 0 ; i <array_links.length ; ++i){
              if(array_links[i].idx === idx){
                  return i
              }
            }
        },         
        on_changeCell(row,col) {
            var changeData = row[col]
            //var column =  col+"11";
            var row_idx =  row.idx
            var del_index = 0
            var arr_idx = -1
            if(col === "control_yn"){
                if(changeData){  
                   if(row.code1=="junction") {                   
                        this.setting.set_contrl.Junctions.push(row_idx)
                        this.Junctions[row_idx-1].control_yn = true;
                   }else if(row.code1=="reservoir"){
                        this.setting.set_contrl.Reservoirs.push(row_idx)
                        arr_idx = this.set_node_links_return_idx(this.Reservoirs,row_idx)
                        this.Reservoirs[arr_idx].control_yn = true;        
                   }else if(row.code1=="tank"){
                      this.setting.set_contrl.Tanks.push(row_idx)     
                      arr_idx = this.set_node_links_return_idx(this.Tanks,row_idx)
                      this.Tanks[arr_idx].control_yn = true;   
                   }else if(row.code1=="pipe"){
                        this.setting.set_contrl.Pipes.push(row_idx)          
                        this.Pipes[row_idx-1].control_yn = true;
                   }else if(row.code1=="valve"){
                        this.setting.set_contrl.Valves.push(row_idx) 
                        arr_idx = this.set_node_links_return_idx(this.Valves,row_idx)
                        this.Valves[arr_idx].control_yn = true;   
                   }else if(row.code1=="pump"){
                      this.setting.set_contrl.Pumps.push(row_idx)          
                      arr_idx = this.set_node_links_return_idx(this.Pumps,row_idx)
                      this.Pumps[arr_idx].control_yn = true;                
                   }

                }else{
                   if(row.code1=="reservoir") {   
                       del_index = this.setting.set_contrl.Reservoirs.indexOf(row_idx) 
                      if (del_index > -1) {
                         this.setting.set_contrl.Reservoirs.splice(del_index, 1);
                         arr_idx = this.set_node_links_return_idx(this.Reservoirs,row_idx)
                         this.Reservoirs[arr_idx].control_yn = false;  
                      }
                   }else  if(row.code1=="junction") {   
                       del_index = this.setting.set_contrl.Junctions.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Junctions.splice(del_index, 1);
                           this.Junctions[row_idx-1].control_yn = false;
                        }
                   }else  if(row.code1=="tank") {   
                       del_index = this.setting.set_contrl.Tanks.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Tanks.splice(del_index, 1);
                           arr_idx = this.set_node_links_return_idx(this.Tanks,row_idx)
                           this.Tanks[arr_idx].control_yn = false;  
                        }
                   }else  if(row.code1=="pipe") {   
                       del_index = this.setting.set_contrl.Pipes.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Pipes.splice(del_index, 1);
                           this.Pipes[row_idx-1].control_yn = false;
                        }
                  }else  if(row.code1=="valve") {   
                       del_index = this.setting.set_contrl.Valves.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Valves.splice(del_index, 1);
                           arr_idx = this.set_node_links_return_idx(this.Valves,row_idx)
                           this.Valves[arr_idx].control_yn = false;   
                        }
                  }else  if(row.code1=="pump") {   
                       del_index = this.setting.set_contrl.Pumps.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Pumps.splice(del_index, 1);
                           arr_idx = this.set_node_links_return_idx(this.Pumps,row_idx)
                           this.Pumps[arr_idx].control_yn = false;                               
                        }
                    }

                }
                /*
                if(row.code==1100)
                 this.control_setting(1);
                else
                  this.control_setting(2);
                */  
               // alert(this.setting.set_contrl.Junctions)
            }else if(col === "value_yn"){
                
                       if(changeData){  
                   if(row.code1=="junction"){                    
                        this.setting.set_value.Junctions.push(row_idx)
                        this.Junctions[row_idx-1].value_yn = true;
                   }else if(row.code1=="reservoir"){
                        this.setting.set_value.Reservoirs.push(row_idx)  
                        //this.set_node_links( this.Reservoirs,row_idx,1,1)  
                        arr_idx = this.set_node_links_return_idx(this.Reservoirs,row_idx)
                        this.Reservoirs[arr_idx].value_yn = true;        
                   }else if(row.code1=="tank"){
                        this.setting.set_value.Tanks.push(row_idx)     
                        this.set_node_links( this.Tanks,row_idx,1,1)  
                        arr_idx = this.set_node_links_return_idx(this.Tanks,row_idx)
                        this.Tanks[arr_idx].value_yn = true;            
                   }else if(row.code1=="pipe"){
                        this.setting.set_value.Pipes.push(row_idx)   
                        this.Pipes[row_idx-1].value_yn = true;        
                   }else if(row.code1=="valve"){
                        this.setting.set_value.Valves.push(row_idx)          
                       // this.set_node_links( this.Valves,row_idx,1,1) 
                        arr_idx = this.set_node_links_return_idx(this.Valves,row_idx)
                        this.Valves[arr_idx].value_yn = true; 
                   }else if(row.code1=="pump"){
                        this.setting.set_value.Pumps.push(row_idx) 
                        //this.set_node_links( this.Pumps,row_idx,1,1)  
                        arr_idx = this.set_node_links_return_idx(this.Pumps,row_idx)
                        this.Pumps[arr_idx].value_yn = true;          
                   }

                }else{
                   if(row.code1=="reservoir") {   
                       del_index = this.setting.set_value.Reservoirs.indexOf(row_idx) 
                      if (del_index > -1) {
                         this.setting.set_value.Reservoirs.splice(del_index, 1);
                         //this.set_node_links(this.Reservoirs,row_idx,1,0) 
                         arr_idx = this.set_node_links_return_idx(this.Reservoirs,row_idx)
                         this.Reservoirs[arr_idx].value_yn = false;
                      }
                   }else  if(row.code1=="junction") {   
                       del_index = this.setting.set_value.Junctions.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_value.Junctions.splice(del_index, 1);
                           this.Junctions[row_idx-1].value_yn = false;  
                        }
                   }else  if(row.code1=="tank") {   
                       del_index = this.setting.set_value.Tanks.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_value.Tanks.splice(del_index, 1);
                          // this.set_node_links(this.Tanks,row_idx,1,0) 
                           arr_idx = this.set_node_links_return_idx(this.Tanks,row_idx)
                           this.Tanks[arr_idx].value_yn = false;
                        }
                   }else  if(row.code1=="pipe") {   
                       del_index = this.setting.set_value.Pipes.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_value.Pipes.splice(del_index, 1);
                           this.Pipes[row_idx-1].value_yn = false;        
                        }
                  }else  if(row.code1=="valve") {   
                       del_index = this.setting.set_value.Valves.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_contrl.Valves.splice(del_index, 1);
                         //  this.set_node_links(this.Valves,row_idx,1,0) 
                           arr_idx = this.set_node_links_return_idx(this.Valves,row_idx)
                           this.Valves[arr_idx].value_yn = false;
                        }
                  }else  if(row.code1=="pump") {   
                       del_index = this.setting.set_contrl.Pumps.indexOf(row_idx) 
                        if (del_index > -1) {
                           this.setting.set_value.Pumps.splice(del_index, 1);
                           //this.set_node_links(this.Pumps,row_idx,1,0) 
                           arr_idx = this.set_node_links_return_idx(this.Pumps,row_idx)
                           this.Pumps[arr_idx].value_yn = false;
                        }
                    }
                }

                /*
                if(row.code==1100)
                 this.value_set(1);
                else
                  this.value_set(2);
                */  
            }
            //this.mediaPlanData[row][column] = changedData
        },
    on_facility_info(row) {
       this.$emit('show_model_f_info',row) ;
       //this.$refs.map.show_model_info(row.id)
    },  
    on_facility_info1(row) {
       this.$emit('show_model_f_info',row.properties) ;
       //this.$refs.map.show_model_info(row.id)
    },        
    on_goto_map_click(row) {
    
      //this.$refs.map.get_extent('f_idx='+row.id).then((data)=>{
       // this.$refs.map.set_fit_extent(data, ()=>{
      //    this.$refs.map.select_facility_feature(row.gis_layer_id, row.type, row.id)
      //  })
     // })        
        this.$emit('on_goto_map_click',row) ;
    },            
        onRowClick(params) {
        //선택된 색상 보여지게
    //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
        if(this.clicked_b_row != null){
            this.clicked_b_row.bgColor = '';
        //  //  this.clicked_b_row.style.backgroundColor = '';
        }
        
        if(params.event.target.nodeName==='TD'){
            params.event.target.parentElement.bgColor  = '125744';          
            this.clicked_b_row = params.event.target.parentElement ;
        }else  if(params.event.target.nodeName==='IMG'){
            params.event.target.parentElement.parentElement.parentElement.bgColor = '125744';      
            this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
        }else{
            params.event.target.parentElement.parentElement.bgColor = '125744';      
            this.clicked_b_row = params.event.target.parentElement.parentElement ;
        }  
        },           
  
        show_modeling(){
            this.$refs.Modeling_info.show(this.tag,this.ditem );  
           // this.$refs.Modeling_info.show(567208); 
        },
        go_table_view(){
                if(this.facility_type === 'reservoir'){                    
                    this.$refs.Reservoirs_control.setUrl(this.Reservoirs) ;            
                }else if(this.facility_type === 'junction'){   
                    //if(this.$refs.Pipes_control !== undefined)
                    //    this.$refs.Pipes_control.setUrl([]) ;
                    this.$refs.junction_control.setUrl(this.Junctions) ;
                }else if(this.facility_type === 'tank'){   
                    this.$refs.Tanks_control.setUrl(this.Tanks) ;
                }else if(this.facility_type === 'pipe'){ 
                    //if(this.$refs.junction_control !== undefined)                   
                    //   this.$refs.junction_control.setUrl([]) ;
                    this.$refs.Pipes_control.setUrl(this.Pipes) ;
                }else if(this.facility_type === 'pump'){
                    this.$refs.Pumps_control.setUrl(this.Pumps) ;
                }else if(this.facility_type === 'valve'){
                    this.$refs.Valves_control.setUrl(this.Valves) ;
                }
      },
        switchSelect(event,type){
            /*
                f_control_option: [
                { value: 'Junctions', text: 'Junctions' },
                { value: 'Reservoirs', text: 'Reservoirs' },
                { value: 'Tanks', text: 'Tanks' },
                { value: 'Pipes', text: 'Pipes' },
                { value: 'Pumps', text: 'Pumps' },
                { value: 'Valves', text: 'Valves' },
            ],
            */            
            var selected = event;
            this.type = event ;
            if(type ==1){
                if(selected === 'reservoir'){                    
                    this.$refs.Reservoirs_control.setUrl(this.Reservoirs) ;            
                }else if(selected === 'junction'){   
                    if(this.$refs.Pipes_control !== undefined)
                        this.$refs.Pipes_control.setUrl([]) ;
                    this.$refs.junction_control.setUrl(this.Junctions) ;
                }else if(selected === 'tank'){   
                    this.$refs.Tanks_control.setUrl(this.Tanks) ;
                }else if(selected === 'pipe'){ 
                    if(this.$refs.junction_control !== undefined)                   
                        this.$refs.junction_control.setUrl([]) ;
                    this.$refs.Pipes_control.setUrl(this.Pipes) ;
                }else if(selected === 'pump'){
                    this.$refs.Pumps_control.setUrl(this.Pumps) ;
                }else if(selected === 'valve'){
                    this.$refs.Valves_control.setUrl(this.Valves) ;
                }
            }else{ //simulation
              //this.view_type = selected ;
              if(selected === 'node'){
                  //  if( this.Value_node === undefined || this.Value_node === null){
                       this.value_set(1);
                       this.control_setting(1) //simulation
                 //   }
                 
                   // this.$refs.control_list.setUrl(this.Cont_node) ;   
                   // this.$refs.value_list.setUrl(this.Value_node) ;            
              }else if(selected === 'link'){   
                   // if( this.Value_link === undefined || this.Value_link === null){
                        this.value_set(2);
                        this.control_setting(2)
                   // }

                // this.$refs.control_list1.setUrl(this.Cont_link) ;
                 //this.$refs.value_list1.setUrl(this.Value_link) ;     
              }
            }
                 
        },
   
        reset() {    
            ++this.listKey;
        },
        show(idx, info,model_json,_f_model) { //idx, model_file_info, model_json, setting
            // if(localStorage === null || typeof localStorage === undefined){
            this.properties_bak = [];
            this.file_model = _f_model ;
            this.properties = info;
           
            this.properties['flow_unit'] = this.file_model.getFlowUnits();
            this.properties['head_loss'] = this.file_model.getOption(Option.HeadlossForm);
            this.properties['demand_model'] = this.file_model.getDemandModel().type;
           

            this.properties_bak = Object.assign({}, this.properties)
            this.setting = model_json.setting;
            this.type='Junctions' 

            
            if(sessionStorage === null || typeof sessionStorage === undefined){
                return
        //   }else if(localStorage.level === 'A'){
            }else if(sessionStorage.level < 2){
                this.f_satus_level = false;
            }
            this.tag = idx;
            this.Junctions =model_json.Junctions ;
            
            this.Reservoirs =model_json.Reservoirs ;
            this.Tanks =model_json.Tanks ;
            this.Pipes =model_json.Pipes ;
            this.Pumps =model_json.Pumps ;
            this.Valves =model_json.Valves ;
            this.tabIndex = 0;
            /*
             setTimeout(()=>{
                this.switchSelect('Junctions',1)
                this.$refs.junction_control.setUrl(this.Junctions) ;
                this.control_setting(1);
                this.value_set(1);
             }
            , 1000)
            */
        },
      
        apply() {
            //this.$refs.modal.hide()
        //   if(localStorage === null || typeof localStorage === undefined){
             if(sessionStorage === null || typeof sessionStorage === undefined){
                alert("Can not be modified.")
                return
        //    }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 1){
              //  alert("You do not have permission to edit.")
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
            let params = {} ;              
            let model_info = {} ;
        
        
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
                if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                model_info[keys[i]] = this.properties[keys[i]]
            }
            

            params["id"] = this.properties["id"]
            model_info["customer_member_id"] = sessionStorage.m_idx;
                var f_name =  "temp_saveInpFile.inp"
            this.file_model.saveInpFile(f_name) ;
            //console.log(save_text)
            const ws = new Workspace()
            const inpFile = ws.readFile(f_name);                
            const reportFile = ws.readFile("report.rpt"); 
            model_info["inp_file"] = inpFile;
            model_info["inp_setting"] =  encodeURIComponent(JSON.stringify(this.setting));
            model_info["rpt_file"] = reportFile;
            params["fields"] = encodeURIComponent(JSON.stringify(model_info))

            if(Object.keys(model_info).length<=0) return
            // this.$http.get(this.URL+'api/set_setting?m_id='+sessionStorage.m_id+'&json='+encodeURIComponent(JSON.stringify(this.setting)))
            this.$http.post(this.URL+'api/set_modeling_upload' ,params).then((res) => {
                console.log(res.data);
                if(res.data.check ===1){
                    alert("fail : "+ res.data.msg) ;
                }
                //left_list를 refresh한다.
            })            
            this.properties = null;
            //this.$emit('search')
       },

       cancel1(){
        //home =>gis_reload만
            if(this.geo_u_YN) {
                this.$emit('facility_refresh',2) ; //수정분 날려보낸다.
            }
            console.log(this.$refs.modal)
           // this.$refs.modal.hide();
            this.properties = null;
       }
    },
    components: {
        //DlgModeling:DlgModeling,
         ServerSideTable1: ServerSideTable1,
    }
}
</script>

<style lang="scss" scoped>
 .facility-info.model {
     width: 550px;
 }               
/*
  .facility-info {
    position: fixed;
    right: 0;
    top: 0;
    margin: 70px auto 0;
    background: #fff;
    width: 455px;
    height: calc(100% - 70px);
    z-index: 111;
    display: flex;
    flex-direction: column;
    &__title {
      margin: 0;
      font-size: 18px;
      color: #333;
      text-align: center;
      display: block;
      width: 100%;
      padding: 28px 0 22px 0;
      background: #f2f2f2; 
      font-family: 'Roboto', 'Noto Sans Korean', sans-serif;
    }
    .tabs {
      height: 85%;
    }
    .nav-tabs {
      background: #f2f2f2; 
    }
    .nav-tabs .nav-link {
      border: 0;
      padding: 8px;
      float: left;
      font-size: 14px;
      color: #080F15;
      cursor: pointer;
      letter-spacing: 0.5px;
      border-radius: 0;
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
      background: #fff;
      color: #28B7CB;
    }
  .facility-info-content {
      &.tab-content {
        background: #fff;
        &.is--scroll {
          height: 90%;
          padding: 36px 28px 0 28px;
          overflow-y: auto;
          @media screen and (max-height: 930px){
            height: 68vh;
          }
        }
      }
      .facility__label {
        color: #616161;
        font-size: 14px;
        display: inline-block;
        letter-spacing: 0.5px;
        //width: 35%;
      }
      .facility__input {
        //width: 60%;
        height: 32px;
        font-size: 14px;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 0;
        margin-top: 0;
        margin-bottom: 14px;

        &.height--auto {
          height: auto;
        }
      }
    }
    .facility-info-footer {
      background: #fff;
      padding-bottom: 18px;
      padding-top: 3px;
    }
    .facility-info-footer-row {
      width: 100%;
      margin: 0;
      padding-bottom: 0;
    }
    .btn.facility-info-footer__button {
      height: 52px;
      font-size: 14px;
      border: 0;
      &.facility-info-footer__button-ok {
        background: #28B7CB;
      }
      &.facility-info-footer__button-cancel {
        background: #9D9D9D;
      }
    }
    .photo-card {
      border-radius: 0;
      border: 0;
      .img-thumbnail {
        margin-bottom: 10px;
      }
      .card-header {
        height: 150px;
        background: #F2F2F2;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .card-header__text {
          font-size: 16px;
          color: rgba(3,3,3,0.4);
        }
      }

    }
  }
  */
  
</style>

<template>
    <div>
        <b-modal
            ref="modal"
            title="Block Info"
            >
            <b-tabs content-class="mt-3" align="center" >
                <b-tab title="Properties"   >
                     <b-row>
                        <b-col sm="6">
                            <label class="facility__label">No</label>
                        </b-col>
                        <b-col sm="6">
                            <b-input class="facility__input" size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="6">
                            <label class="facility__label">Name</label>
                        </b-col>
                        <b-col sm="6">
                            <b-input class="facility__input" size="sm" v-model="properties['name']" ></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="6">
                            <label class="facility__label">Remarks</label>
                        </b-col>
                        <b-col sm="6">
                            <b-input class="facility__input" size="sm" v-model="properties['remarks']" ></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="6">
                            <label>Service Pipe</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" :value=vlength disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>Supply Pipe</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" :value=plength disabled></b-input>
                        </b-col>
                    </b-row>                                                         
                    <b-row>
                        <b-col sm="6">
                            <label>Valve Count</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" :value=vcount disabled></b-input>
                        </b-col>
                    </b-row>              
                    <b-row>
                        <b-col sm="6">
                            <label>Water Meter Count</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" :value=wcount disabled></b-input>
                        </b-col>
                    </b-row>              
                    <b-row>
                        <b-col sm="6">
                            <label class="facility__label">Status</label>
                        </b-col>
                        <b-col sm="6">
                            <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option"></b-select>
                        </b-col>
                    </b-row>
                </b-tab>               
            </b-tabs>

            <template v-slot:modal-footer="{ ok, cancel, hide }">
              
                <!--<b-button @click="$refs.modal.hide()">Close</b-button> -->
                <b-button @click="cancel1()">Close</b-button>
                <b-button v-if="save_btn" @click="Apply()">Apply</b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>
//import {getLength} from 'ol/sphere';
//import {Sphere} from 'ol/sphere';
import {transform} from 'ol/proj';
import {LineString } from 'ol/geom';
export default {
    name: 'DlgBlockInfo',
    props: {
    },
    watch:{
       

    },
    data() {
        return {
            properties: {},
            properties_bak: null,
            info_u_YN : true,
            geo_u_YN : false,
            ditem :[],
            save_btn : false,
            columns: [
                {label: 'No', field: 'idx'},
                {label: 'Layer', field: 'layer'},
                {label: 'Title', field: 'w_title'},
                {label: 'Start Time', field: 'w_start_time'},
            ],
            //block안에 레이어 갯수 계산
            columns1: [
                {label: 'Layer', field: 'layer'},
                {label: 'Count', field: 'count'},
                {label: 'length', field: 'length'},
            ],        
            table1: [],    
            table: [],
            layer_type_option: [
                {value: 0, text: 'Pipe'},
                {value: 1, text: 'Valve'},
                {value: 2, text: 'Water Meter'},
                {value: 3, text: 'Pump'},
                {value: 4, text: 'Hydrant'},
                {value: 7, text: 'Block'},
                {value: 8, text: 'Water Treatment Plant'},
                {value: 9, text: 'Pumping Station'},
                {value: 10, text: 'Water Basin'},
                {value: 11, text: 'Chamber'},
                {value: 12, text: 'Gauge'},
                {value: 13, text: 'Other'},
            ],
            f_type_option: [],
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            material_option: [],
            img1:'',
            img2:'',
            img3:'',
            b_show : false,
            vcount : 0,
            wcount : 0,
            vlength : 0,
            plength :0,
            work_block_type : 100,
        }
    },

    mounted () {
    },
    methods: {
        show(idx,block_type) {
          //  this.$http.get(this.URL+'api/get_geometry?f_idx='+idx).then((res) => {
              this.work_block_type = block_type
              var surl = this.URL+'api/work_block/detail/'+idx
              if(block_type === '110'){
                  surl = this.URL+'api/waterpressure/groups/'+idx+'?customer_id='+ sessionStorage.select_customer_id
              }
              this.$http.get(surl).then((res) => {
                //if(res.data.check ==1) return
                if(res.data.msg !== undefined) return
                
                this.properties = res.data.result 
                
                
                
              //  let geo = res.data.features[0].geometry.coordinates[0]
              
                this.vlength = 0 ; this.plength = 0 ;this.vcount =0;this.wcount=0;



                  //   this.$http.get(this.URL+'api/get_geometry?layer=1&geo='+geo).then((res) => {
                    //geo  
                    if(res.data.result.location !== null){
                        var geo = res.data.result.location.coordinates[0]
                        this.$http.get(this.URL+'api/get_geometry_count?geo='+geo+"&customer_id="+sessionStorage.select_customer_id).then((res) => {
                            this.plength  = res.data.rows[0].sp_length; 
                            this.vlength  = res.data.rows[0].sv_length;         
                            this.vcount  = res.data.rows[0].v_count;     
                            this.wcount  = res.data.rows[0].w_count;    
                                                                    
                        })
                    }

               /*
                    this.$http.get(this.URL+'api/get_geometry?layer=0&f_type=Service&geo='+geo).then((res) => {
                        let ct = res.data.features.length ;     
                        if(ct<=0) return
                        //var wgs84Sphere = new Sphere(6371008.8);
                        
                        for(let i=0; i<ct; i++){
                            // const length = getLength(new LineString([transform(res.data.features[i].geometry.coordinates[0], "EPSG:900913",'EPSG:4326'),transform(res.data.features[i].geometry.coordinates[1], "EPSG:900913",'EPSG:4326')]));
                            const length = getLength(new LineString([res.data.features[i].geometry.coordinates[0],res.data.features[i].geometry.coordinates[1]])); 
                            // var distance = wgs84Sphere.haversineDistance(transform(res.data.features[i].geometry.coordinates[0], "EPSG:900913",'EPSG:4326'),ol.proj.transform(res.data.features[i].geometry.coordinates[1], "EPSG:900913",'EPSG:4326'))
                            // var length = wgs84Sphere.haversineDistance(res.data.features[i].geometry.coordinates[0],res.data.features[i].geometry.coordinates[1])
                            this.vlength += length    
                        }
                      
                         this.vlength = ct 
                        
                    })
                  */
                 let cur_page = ""+document.location.href ;
                //alert(cur_page)
                if(cur_page.indexOf("Work-block") !== -1 ) { 
                    this.save_btn=true;                           
                    if(cur_page.indexOf("block_type=110") !== -1 ){
                        this.work_block_type = '110'
                    }
                }
                this.properties_bak = Object.assign({}, this.properties)
                this.$refs.modal.show();
            })
        },
        Apply() {        
            let params = {} ;
            //update
            if(this.work_block_type === '110'){
                 if(this.properties['id'] !== undefined && this.properties['id'] !== null){
                    let keys = Object.keys(this.properties_bak)
                    for(let j=0; j<keys.length; j++) {
                    if(this.properties_bak[keys[j]]===this.properties[keys[j]]) continue
                        if(keys[j] === 'name')
                            params['groupName'] = this.properties[keys[j]]
                        if(keys[j] === 'location'){
                            params[keys[j]] = this.properties[keys[j]]
                        }    
                    }
                    if(Object.keys(params).length<=0) return
                    params['customerId'] = sessionStorage.select_customer_id ;
                    this.$http.patch(this.URL+'api/waterpressure/groups/'+this.properties['id'],params).then((res) => {
                    console.log(res.data);
                    if(res.data.check ===1){
                        alert("fail : "+ res.data.msg) ;
                    }else{
                        this.$refs.modal.hide();
                    }
                    })
                //insert   
                }else{
                    let keys = Object.keys(this.properties);
                    for(let ki=0 ; ki < keys.length ; ++ki) {
                        if(  this.properties[keys[ki]]  !== null && this.properties[keys[ki]].value !== '') {
                           if(keys[ki] === 'name'){
                                params['groupName'] = this.properties[keys[ki]]
                                params['customerId'] = sessionStorage.select_customer_id ;
                           }
                           if(keys[ki] === 'location'){
                                 params[keys[ki]] = this.properties[keys[ki]]
                           }
                        }
                    }
                // this.ditem['params'] = params ;
                    this.$http.post(this.URL+'api/waterpressure/groups/',params).then((res) => {
                    console.log(res.data);
                    if(res.data.check ===1){
                        alert("fail : "+ res.data.msg) ;
                    }else{
                        this.$refs.modal.hide();
                    }
                    })
                    //this.$emit('facility_refresh',0) ;
                }
            }else{
                if(this.properties['id'] !== undefined && this.properties['id'] !== null){
                    let keys = Object.keys(this.properties_bak)
                    for(let i=0; i<keys.length; i++) {
                    if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                        params[keys[i]] = this.properties[keys[i]]
                    }
                    if(Object.keys(params).length<=0) return
                    this.$http.patch(this.URL+'api/work_block/'+this.properties['id'],params).then((res) => {
                    console.log(res.data);
                    if(res.data.check ===1){
                        alert("fail : "+ res.data.msg) ;
                    }else{
                        this.$refs.modal.hide();
                    }
                    })
                //update   
                }else{
                    let keys = Object.keys(this.properties);
                    for(let k=0 ; k < keys.length ; ++k) {
                        if(  this.properties[keys[k]]  !== null && this.properties[keys[k]].value !== '')  params[keys[k]] = this.properties[keys[k]] ;
                    }
                // this.ditem['params'] = params ;
                    this.$http.post(this.URL+'api/work_block?customer_id='+sessionStorage.select_customer_id,params).then((res) => {
                    console.log(res.data);
                    if(res.data.check ===1){
                        alert("fail : "+ res.data.msg) ;
                    }else{
                        this.$refs.modal.hide();
                    }
                    })
                    //this.$emit('facility_refresh',0) ;
                }
            }
        },
        distanceBetweenPoints(latlng1, latlng2){
            //var line = new LineString([latlng1, latlng2]);
            var line = new LineString([transform(latlng1, "EPSG:900913",'EPSG:4326'), transform(latlng2, "EPSG:900913",'EPSG:4326')]);
            return Math.round(line.getLength() * 100) / 100;
        },
        insert_show(item) {
            this.properties = {} ;
            let ltype = item.code; 
            this.geo_u_YN = true;
            this.ditem = null;
            if(item.features=== null) return

            if(ltype===0) {
                this.f_type_option = ['Service','Supply','Drain','Other']
                this.material_option = ['DCIP', 'Steel', 'PVC', 'PE', 'HI-3P', 'Other']
            }
            if(ltype===1) this.f_type_option = ['PRV','Control','Drain','Boundary','Relief','Other']
            if(ltype===2) this.f_type_option = ['Mechanical','Digital','Other']
            if(ltype===7) this.f_type_option = ['Big','Middle','Small','Sub']
             let geo = item.feature.geometry.coordinates[0]
             this.properties["location"] =  item.feature.geometry
            if(ltype === 7) {
                 this.b_show = true;
                   
                 //   this.$http.get(this.URL+'api/get_geometry?layer=1&geo='+geo).then((res) => {
                  //   var data = {layer: '1', geo: item.feature.geometry.coordinates[0]} ;
                 
                    this.$http.get(this.URL+'api/get_geometry_count?geo='+geo+"&customer_id="+sessionStorage.select_customer_id).then((res) => {
                        this.plength  = res.data.rows[0].sp_length; 
                        this.vlength  = res.data.rows[0].sv_length;         
                        this.vcount  = res.data.rows[0].v_count;     
                        this.wcount  = res.data.rows[0].w_count;    
                                                                  
                    })
            }

           
            if(item.type==="add"){ //추가
            /*
                this.info_u_YN = false ;
                
                this.properties['f_type'] = item.f_type;
                if(ltype===1 ) 
                    this.properties['f_type']  = 'Other' ;
                if(ltype===2 ) 
                    this.properties['f_type']  = 'Other' ;

                this.properties['layer_type'] = ltype ;
              
                let date = new Date();
                let yy = date.getFullYear() ;
                this.properties['f_register_date'] = yy +"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0"+date.getDate()).slice(-2) ;
                           //시설정보 테이블에 데이터    
             */                           
                this.properties['status'] = 'A' ;
                                                
            }


            //데이터 변환한다.
            //this.change_xy(item);
            this.ditem = item;

            let cur_page = ""+document.location.href ;
            if(cur_page.indexOf("Work-block") !== -1 ) { 
                this.save_btn=true;      
                if(cur_page.indexOf("block_type=110") !== -1 ){
                    this.work_block_type = '110'
                }                     
             }

            this.$refs.modal.show();           
        },        

       cancel1(){
        //home =>gis_reload만
            if(this.geo_u_YN) { 
                this.$emit('facility_refresh',2) ; //수정분 날려보낸다.                
            }
            this.$refs.modal.hide(); 
       }
    },
    components: {
    }
}
</script>

<style scoped>
</style>

<template>
    <div>
        <b-modal
            ref="modal"
            title="AI_Model_info"
            >
            <b-tabs  v-if="properties!==null" align="center">
              <b-tab  :title="$t('work_info.proper')" active>  
                     <b-row>
                        <b-col sm="4">
                           
                        </b-col>
                        <b-col sm="8">
                           <span style="color:red;float:right;">(*)required</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <!-- <b-input size="sm" v-model="properties['id']" disabled></b-input> -->
                            <b-input size="sm" v-model="properties['id']" disabled ></b-input>
                        </b-col>
                    </b-row>
                   
                    <b-row>
                        <b-col sm="4">
                            <label>Model Name</label><span style="color:red;">(*)</span>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" maxlength="50" v-model="properties['model_name']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.contents')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" maxlength="75" v-model="properties['contents']" ></b-input>
                        </b-col>
                    </b-row>
                  
                  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.worker')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['creator']" disabled></b-input>
                        </b-col>
                    </b-row>
                  <b-row>
                        <b-col sm="4">
                            <label>DATASET ID</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['ds_id']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>DATASET CNT</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['ds_cnt']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.r_date')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['create_datetime']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col sm="4">
                            <label>File Create Datetime</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['file_create_datetime']" disabled  ></b-input>
                        </b-col>
                    </b-row> 
                       <b-row>

                        <b-col sm="4">
                            <label>File Path</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['file_path']" disabled  ></b-input>
                        </b-col>
                    </b-row>         
                 <!--b-row v-if = "new_model">
                  <b-col sm="4">
                      <label class="facility__label">{{$t('facility_info.status')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" ></b-select>
                  </b-col>
                </b-row-->              
              </b-tab>
                
           

            </b-tabs>
<!--
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="apply()">Apply</b-button>
                
                <b-button @click="cancel1()">Close</b-button>
            </template>
-->
           <template v-slot:modal-footer="{ ok, cancel, hide }">

              <b-row class="modal-footer-row m-0 leak_foot">
               <b-col sm="6">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">{{$t('work_info.close')}}</b-button>
               </b-col>
               <b-col sm="6">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('work_info.apply')}}</b-button>
               </b-col>              

             </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;
//import datetime from 'vuejs-datetimepicker';
export default {
    name: 'DlgWorkInfo',
    props: {
    },
    watch:{
     

    },
    data() {
        return {
            properties: null,
            properties_bak: null,
            work_id : 0,
             p_mode : '',
             new_model : true,
             recount: 0,
             myWorkItem : false,
             ele_data : 0,
             work_type : 100, 
             is_show : true,
             isActiveSetting : true, //로거인 경우엔 false
             change_yn : false,
             isWorkBlock:false,
             f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
             ],
             columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Remarks', field: 'remarks' }
             ],
             columns1: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'color', field: 'rgb' }
             ],
            ts_monitoring_list: [], //선택블럭
            ts_flow_tag_list: [], //선택추가할 블럭 
            temp_ts_monitoring_list: [], // 변경값
            change_work : 0,
        }
    },

    mounted () {
    },
    methods: {
        color_change(id,e){
            ++this.change_work
            alert(id+e)
            
            let rows = this.temp_ts_monitoring_list
           
            if (rows.length <= 0) return
            for (let i = 0; i < rows.length; i++) {
                if(rows[i].id === id ){
                  this.temp_ts_monitoring_list[i]= { id: rows[i].id, name: rows[i].name,rgb : e }
                }
            }
            
        },
        fn_press_han(event)
        {
           var objTarget = event.srcElement || event.target;
       // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //if(Number(value))
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');

          }        
        },
        fn_press_han1(event)
        {
           var objTarget = event.srcElement || event.target;
       // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //if(Number(value))
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9.]/g,'');
            alert('Please enter only numbers');
            if(isNaN(objTarget.value))
               objTarget.value = ""
           // return
          
          }else{
             if(Number(value) < 0.1)
               objTarget.value = 0.1
          }        
        },
        goRouter (path) {
           this.$router.push({ path: path, query: { tag: this.work_id  } })
        },
        show_setting () {
            this.change_work = 0  
            this.temp_ts_monitoring_list = [...this.ts_monitoring_list]
        
        },
        handleOk () {
            this.ts_monitoring_list = [...this.temp_ts_monitoring_list]
            let param = []
            for (let i = 0; i < this.ts_monitoring_list.length; i++) param.push(this.ts_monitoring_list[i].tag)
                this.$http.get(this.URL + 'api/set_monitoring_list?list=' + param)
        },
        insert_show(w_type,idx, cnt) { //1:일반압력, 2:수충압
            this.properties = {} ;
        // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
            this.img_list =[];              
            this.work_type = w_type ;              
            this.properties["type_code"] = w_type              
            this.properties['customer_member_id'] = sessionStorage.m_idx ;
            //this.work_id = id;
            //로거인 경우
            this.properties["ds_id"] = idx
            this.properties["ds_cnt"] = cnt
            this.properties["status"] = 'I'  
            this.new_model = false
            this.$refs.modal.show();
        },
        show(id,w_type) {
            this.properties = {} ;
        // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
            this.img_list =[];
            this.is_show = true
            this.work_type = w_type ;
            this.work_id = id;
            var surl = this.URL+'api/dataset/get_ai_model?id='+id
  
        // this.$http.get(this.URL+'api/get_press_info?idx='+idx).then((res) => {
            this.$http.get(surl).then((res) => {
                
            if(res.data.length<=0) return           

            this.properties = res.data.list[0]; 
            
            if(sessionStorage.m_id === this.properties.worker )
                    this.myWorkItem = true
            
            if(this.properties.water_work_id !== null  &&  (typeof this.properties.water_work_id !== undefined) && this.work_type !== 300 )                  
                this.isActiveSetting = false;
            else
                this.isActiveSetting = true;
                
            this.properties_bak = Object.assign({}, this.properties)



            })
   
            this.$refs.modal.show();          


        },
         del(){
           var con_test1 = confirm("Are you sure you want to delete?");
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test1){
              this.$http.get(this.URL+'api/delete_ex?p_idx_ex='+this.properties['id']).then((res) => {  
                 console.log(res.data);
                 this.$emit('leaktable_refresh') ;
                 this.$refs.modal.hide();
              });

           }
        },
        add_monitoring () {
            let rows = this.$refs.flow_tag_table.selectedRows
            if (rows.length <= 0) return
            for (let i = 0; i < rows.length; i++) {
                this.temp_ts_monitoring_list.push({ id: rows[i].id, name: rows[i].name ,rgb:"#B0A8F0"})
            }
            this.del_dup_list(this.temp_ts_monitoring_list, this.ts_flow_tag_list)
         
        },
        del_monitoring () {
            let rows = this.$refs.ts_monitoring_table.selectedRows
            if (rows.length <= 0) return
            for (let i = 0; i < rows.length; i++) {
                this.ts_flow_tag_list.push({ id: rows[i].id, name: rows[i].name })
            }
            this.del_dup_list(this.ts_flow_tag_list, this.temp_ts_monitoring_list)
        },
        del_dup_list (a, b) {
            for (let i = 0; i < a.length; i++) {
                let idx = b.findIndex(obj => obj.id == a[i].id)
                if (idx <= -1) continue
                b.splice(idx, 1)
            }
            ++this.change_work;
        }, 
        work_block_setting(){
          //work_block work_id를 전부 삭제후 insert한다..
           let params = {} ;
           let work_block = []
           if(this.temp_ts_monitoring_list.length > 0){
              params["ww_idx"] = this.work_id
              for(var i = 0 ; i <this.temp_ts_monitoring_list.length ; ++i ) {
                 work_block.push({ wb_idx:this.temp_ts_monitoring_list[i].id, 
                              name:this.temp_ts_monitoring_list[i].name, rgb :this.temp_ts_monitoring_list[i].rgb  }) 
              } 

              params["data"] = JSON.stringify(work_block);
              //
               this.$http.post(this.URL+'api/work_block/setting',params).then((res) => {
                  console.log(res.data);
                  if(res.data.check ===1){
                     alert("fail : "+ res.data.msg) ;
                  }else{
                    this.$refs.modal.hide();
                  }
                })
           }
          
        },       
        apply() {
          //  if(localStorage === null || typeof localStorage === undefined){
              if(sessionStorage === null || typeof sessionStorage === undefined){
                alert("Can not be modified.")
                return
         //   }else if(localStorage.level === 'C'){
              }else if(sessionStorage.level > 1){
                alert("You do not have permission to edit.")
                return;
            }
          
            let params = {} ;
            let param = {} ;
            //update인경우
            if(this.properties['id'] !== null && this.properties['id'] !== undefined){
                let keys = Object.keys(this.properties_bak)
                for(let i=0; i<keys.length; i++) {
                  if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                  param[keys[i]] = this.properties[keys[i]]
                }
                params["fields"] = JSON.stringify(param);
                var update_url = this.URL+'api/dataset/set_model'
               
                if(Object.keys(param).length<=0) {
                     if(this.change_work == 0){ 
                      alert("변경된 내용이 없습니다.")
                      return
                     }else{
                        this.$refs.modal.hide();     
                     }
                }else{
                      params['id']  = this.properties['id']
                }          
                this.$http.post(update_url,params).then((res) => {
                    console.log(res.data);
                })   
            }else{ //insert인경우
                let title = this.properties['model_name']
                if(title.length < 1 ) {
                    alert("Please enter title. ")
                    return
                }
                //insert_url
                var insert_url = null
               
                
                //var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");

               // var today = new Date();
               // params["w_start_time"]= today.getTime()
                //동수두가 아닌경우
                    //setting값이 없으면 return한다.

                    //water_work
                param["ds_id"] = this.properties["ds_id"]
                param["ds_cnt"] = this.properties["ds_cnt"]
                param["customer_member_id"] = this.properties["customer_member_id"]
                param["model_name"] = this.properties["model_name"]
                param["contents"] = this.properties["contents"]
                //setting
                param['status'] = 'I';
                params["fields"] = JSON.stringify(param);
                //  insert_url = this.URL+'api/set_work?'+(JSON.stringify(params))
                insert_url = this.URL+'api/dataset/set_model'
                this.$http.post(insert_url,params).then((res) => {
                   console.log(res.data);
                   if(res.data.check === 0){
                       alert("모델파일 신규생성은 5시간이상 소요됩니다.\n 모델파일 생성 =>  모델변경 작업 진행 =>  신규 모델 적용.")
                   }else{
                       alert("Fail :" +res.msg )
                   }
                   
                })   

               
          
            }
            this.$emit('worklist_refresh') ;
            this.$refs.modal.hide();     
        }       
    },
    components: {
         //   FFTChart1:FFTChart1
        //  PressBigChartData:PressBigChartData,
       //datetime 
    }
}
</script>

<style  lang="scss">

.pressure_chart  .highcharts-container {
  width:440px;
}
.pressure_chart svg.highcharts-root {
    max-width: 440px;
}
.facility__label-radio {
  font-size: 12px;
  color: #616161;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}
.tab-content {
    max-height: 720px;    
    overflow-y: auto;    
    overflow-x: hidden; 
}
// .modal-info {
//   .modal-dialog {
//     position: absolute;
//     right: 0;
//     top: 0;
//     margin: 70px auto 0;
//     // 2022 new
//     // background: #F2F2F2;
//     background: #172A46;
//     width: 350px;
//     // width: 350px;
//     height: calc(100% - 70px);
//     .modal-header {
//       padding-bottom: 22px;
//       .modal-title {
//         font-size: 18px;
//         // margin-top: 12px;
//       }
//     }
//     .modal-info-content {
//       border: 0;
//       border-radius: 0;
 
//       &.reversal {
//         background: #172A46;
//         .nav-tabs {
//           background: #172A46;
//         }
//         .tab-content {
//           background: #172A46;
//           &.is--scroll {
//             height: 73vh;
//             overflow-y: auto;
//           }
//         }
//         .nav-tabs .nav-item.show .nav-link,
//         .nav-tabs .nav-link.active {
//           // 2022 new
//           // background: #fff;
//           border-color: 1px solid #707070;
//         }
//         .facility__label {
//           color: #fff;
//           font-size: 12px;
//           display: inline-block;
//           letter-spacing: 0.5px;
//           //width: 35%;
//         }
//         .facility__input {
//           //width: 60%;
//           height: 32px;
//           font-size: 12px;
//           border: 1px solid #707070;
//           box-sizing: border-box;
//           border-radius: 0;
//           margin-top: 0;
//           margin-bottom: 14px;
//           background: #0A192F;
//           &.height--auto {
//             height: auto;
//           }
//         }
//         .modal-footer {
//           background: #172A46;
//           padding-bottom: 18px;
//           padding-top: 3px;
//         }
//         .modal-footer-row {
//           width: 100%;
//           margin: 0;
//           padding-bottom: 0;
//         }
//         .btn.modal-footer__button {
//           height: 52px;
//           font-size: 14px;
//           border: 0;
//           &.modal-footer__button-ok {
//             // 2022 new
//             // background: #28B7CB;
//             background: #28AF81;
//           }
//           &.modal-footer__button-cancel {
//             background: #9D9D9D;
//           }
//         }
//         .photo-card {
//           border-radius: 0;
//           border: 0;
//           background: none;
//           .img-thumbnail {
//             margin-bottom: 10px;
//           }
//           .card-header {
//             height: 150px;
//             background: #172A46;
//             text-align: center;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             .card-header__text {
//               font-size: 16px;
//               color: #fff;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
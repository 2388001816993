<template>
    <div>
       <b-modal
            ref="modal"
            :title="$t('leakage_info.logger_title')"
            >
     
            <b-tabs  v-if="properties!==null" align="center">
              <b-tab :title="$t('facility_info.properties')" active>
                <b-row >
                    <b-col style="height: 280px">
                        <b-row >
                            <audio controls preload="auto" :src="aurl" style = "margin: 0px 0 0 -35px; width:70%;transform:scale(0.7);"/>
                             <div class="status "  style="float:right;padding-right: 0px; margin: 10px 0 0 -20px;" >
                                <input type="radio" v-model="vchart" value="0" id="fixed__" checked>
                                <label for="fixed__">{{$t('leakage_info.fixed')}}</label>
                                <input type="radio" v-model="vchart" value="1" id="proportional__">
                                <label for="proportional__" >{{$t('leakage_info.proportional')}}</label>
                            </div>
                        </b-row>
                        <FFTChart1 :url="fft_url"  ref="fftchart" :height="fftchart_height1"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.no')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['id']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.m2_point_id')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['logger_id']"  disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.m2_point_name')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['point_name']"  disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.mea_time')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['measure_datetime']" disabled></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.table_column.strength')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['wave_energy']" disabled ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.table_column.max_fre')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['wave_max_frequency']" disabled ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.ai')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['ai_leak_result']" disabled ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.leak')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-select class="facility__input"  style="margin-bottom:5px;" size="sm" v-model="properties['leak_result']" :options="leak_result_option"  ></b-select>
                    </b-col>
                </b-row>
                <!--
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">device_temperature</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['device_temperature']" disabled  ></b-input>
                    </b-col>
                </b-row>
                -->
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.wave_f_id')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['wave_file_id']" disabled  ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <label class="facility__label">{{$t('leak_monitoring.info.fft_f_id')}}</label>
                    </b-col>
                    <b-col sm="8">
                        <b-input class="facility__input" size="sm" v-model="properties['fft_file_id']" disabled   ></b-input>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('facility_info.status')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" :disabled="f_satus_level"></b-select>
                  </b-col>
              </b-row>                
              </b-tab>     
            </b-tabs>
            <!--</div> -->
         <template v-slot:modal-footer="{ ok, cancel, hide }">   
            <b-row class="modal-footer-row m-0 leak_foot">
                <b-col sm="6">
                    <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">{{$t('button.close')}}</b-button>
                </b-col>
                <b-col sm="6">
                    <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('button.apply')}}</b-button>
                </b-col>
            </b-row>
         </template>
        </b-modal>
    </div>
    
</template>

<script>
//import WaveSurfer from 'wavesurfer.js';
import FFTChart1 from '@/components/chart/FFTChart1';


export default {
    name: 'DlgLeakLoggerInfo',
    props: {
    },
    data() {
        return {
            properties: null,
       //     waveform_height1: 10,
            fftchart_height1: 180,
            is_playing: false,
       //     wavesurfer: null,
            loading:false,
            fft_url: '',
            aurl : '' ,
             img1:'',
             img2:'',
             img3:'',
             vchart : '0',
             is_dataset_option: [
                { value: '1', text : 'Dataset' },
                { value: '0', text: 'No Dataset' },
                //{ value: 'RT_LEAK_INSIDE', text: 'Leak inside' },
                //{ value: 'RT_USAGE', text: 'Usage' },
                //{ value: 'RT_OTHER', text: 'Other' },
             
            ],
            leak_result_option: [
                { value: 'L', text: 'Leak' },
                //{ value: 'RT_LEAK_INSIDE', text: 'Leak inside' },
                //{ value: 'RT_USAGE', text: 'Usage' },
                //{ value: 'RT_OTHER', text: 'Other' },
                { value: 'N', text : 'No Leak' },
            ],
             img_list: [],
             ai_result:'',
            f_type_option: [],
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            f_satus_level:true
        }
    },
 
     watch: {
        vchart() {
               //     if(this.vchart==='0') {
                //        alert("radio button : " + this.vchart) ;
                 //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
               //     }
               var c_url = this.fft_url.split('#') ;
               this.fft_url = c_url[0]+'#'+this.vchart ;
        }
    },
    methods: {
        handleOk() {

        },
       get_ai_result(){
            this.loading = true     
            this.$http.get(this.URL+'api/set_leak_AI_data?idx='+this.properties['id']).then((res) => {
                 if(res.data.check ===1){ 
                    this.loading = false
                    return;
                 }
                 this.ai_result = res.data.msg ;
                 this.loading = false 
            });
            setTimeout(()=>{
                this.loading = false      
             }, 10000)    
        },
        close(){
             this.properties = null;
             window.close();
        },

        show(idx) {
            if(sessionStorage === null || typeof sessionStorage === undefined){
                return      
            }else if(sessionStorage.level < 2){
                this.f_satus_level = false;
            }
             this.properties = {} ;
             this.img_list =[];
             this.$http.get(this.URL+'api/API21/water_leak_logger/logger_detail/'+idx).then((res) => {
                if(res.data.length<=0) return
                this.properties = res.data.results[0][0];
                this.ai_result = this.properties['ai_leak_result'] ;
                this.properties_bak = Object.assign({}, this.properties)

             })


            this.$refs.modal.show();
            this.aurl = this.URL+'api/get_wav/water_leak_logger_detail/'+idx ;
            this.fft_url = this.URL+"api/get_fft/water_leak_logger_detail/"+idx+'#'+this.vchart;
             
        },
        apply() {
            
             //   if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === "undefined"){
                return
           // }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 1 && sessionStorage.m_id !== this.properties.worker){
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
            
            let params = {} ;
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
            if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                params[keys[i]] = this.properties[keys[i]]
            }
            if(Object.keys(params).length<=0) return
                this.$http.get(this.URL+'api/update_water_leak?type=logger&idx='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                console.log(res.data);
            })
          //  window.close();
            this.$emit('refresh_table') ;
            this.$refs.modal.hide();
        }

    },
       mounted () {
             this.properties = {} ;
          //   var s_aurl = "";
            
              this.ai_result ="";
       


                
            //   this.aurl = s_aurl;
            //   this.fft_url = this.URL+"api/"+s_ffturl+'#'+this.vchart;
            //this.$refs.modal.show();
            
          //  this.fft_url = this.URL+"api/get_fft/"+this.$route.query.idx+'?'+this.vchart;
      
      
    },
    components: {
        FFTChart1: FFTChart1
        //FFTChart1_c:FFTChart1_c
    }
}
</script>

<style scoped lang="scss">
.leak_foot {
    text-align: center;
}
.facility__label-radio {
  font-size: 12px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}
.tab-content {
    max-height: 720px;    
    overflow-y: auto;    
    overflow-x: hidden; 
}
.modal-info {
  .modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    margin: 70px auto 0;
    background: #172A46;
    width: 350px;
    height: calc(100% - 70px);
    .modal-header {
      padding-bottom: 22px;
      .modal-title {
        font-size: 18px;
        // margin-top: 12px;
      }
    }
    .modal-info-content {
      border: 0;
      border-radius: 0;
 
      &.reversal {
        background: #172A46;
        .nav-tabs {
          background: #172A46;
        }
        .tab-content {
          background: #172A46;
          &.is--scroll {
            height: 73vh;
            overflow-y: auto;
          }
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            // 2022 new
        //   background: #fff;
        }
        .facility__label {
          color: #fff;
          font-size: 12px;
          display: inline-block;
          letter-spacing: 0.5px;
          //width: 35%;
        }
        .facility__input {
          //width: 60%;
          height: 32px;
          font-size: 12px;
          border: 1px solid #707070;
          box-sizing: border-box;
          border-radius: 0;
          margin-top: 0;
          margin-bottom: 14px;
          &.height--auto {
            height: auto;
          }
        }
        .modal-footer {
          background: #172A46;
          padding-bottom: 18px;
          padding-top: 3px;
        }
        .modal-footer-row {
          width: 100%;
          margin: 0;
          padding-bottom: 0;
        }
        .btn.modal-footer__button {
          height: 52px;
          font-size: 14px;
          border: 0;
          &.modal-footer__button-ok {
            background: #28AF81;
          }
          &.modal-footer__button-cancel {
            background: #9D9D9D;
          }
        }
        .photo-card {
          border-radius: 0;
          border: 0;
          background: none;
          .img-thumbnail {
            margin-bottom: 10px;
          }
          .card-header {
            height: 150px;
            background: #172A46;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .card-header__text {
              font-size: 16px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.leak_foot {
    text-align: center;
}
</style>

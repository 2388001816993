<template>
  <div id="app" class="app">
     <vue-scroll>
    <router-view v-if="!is_logined()" />
    <div id="page-wrapper"
      v-else 
      class="page-wrapper chiller-theme"
      :class="{ toggled: is_toggled }"
    >
      <div id="Header">
        <div class="logo" >
          <router-link to="/"><img  v-if="!is_samjin()" src="@/assets/images/Header/logo.png" alt="로고"/>
            <img  v-if="is_samjin()" src="@/assets/images/Header/logo_samjin.png" alt="로고"/></router-link>
        </div>
     
        <!--  
            <h1 class="logo"><a href="#none" @click="goRouter('/')"><img src="@/assets/images/Header/logo.png" alt="로고"></a></h1>
    -->

        <div class="header-navbar">
          <!-- 좌측메뉴 열림:닫힘 -->
          <div id="menu2" class="un-toggle-menu-off" @click="toggle_menu()">
            <div
              :class="
                is_toggled
                  ? 'un-toggle-menu-btn-tri-off'
                  : 'un-toggle-menu-btn-tri-on'
              "
            ></div>
          </div>

          <!-- top right 메뉴 -->
          <ul class="header-navbar--nav">
            <!--            <li style="width: 276px;padding-top: 2px; padding-right: 10px;">-->
            <!--              &lt;!&ndash; img src="@/assets/mark_top.jpg"  alt="로고" /&ndash;&gt;-->
            <!--              &lt;!&ndash; <img :src="icons[$i18n.locale]" alt="" /> &ndash;&gt;-->
            <!--            </li>-->
            <li v-if="is_admin()">
              <!--b-select
                class="item-select"
                v-model="customer_id"
                :options="customer_name"
                :disabled="ischange"
              ></b-select-->
               <select v-model="customer_id" class="item-select" :disabled="ischange">
                 <option v-for="(option, index) in customer_name" v-bind:value="option.value" :key="index" 
                  v-bind:class="['option_list', { 'is--new': option.isNew === 1 }]">
                
                  {{ option.text }}
                   <span v-if="option.isNew === 1" >--New
                     <!--img src="@/assets/images/Header/new.png" width="50%"  -->
                   </span>
                 </option>
               </select>  
            </li>
            <!-- 2022new 홍길동 -->
            <li>
              <a class="nav-link nav-user" href="#none" @click="show_pw_settings()">
                <img
                  src="@/assets/images/new/user.svg"
                  alt="user-image"
                  width="42px"
                />
                <span>{{user_name}}</span>
              </a>
            </li>
            <li v-if="!is_admin()"><a href="#none"></a></li> 
            <li v-if="is_admin()" class="icon_t" ref="menuMonitoring_t">
              <a href="#none" @click="toggleSubmenu" class='nav-link'>{{$t('menu.wiplat')}}</a> 
              <ul class="submenu">
                <li class="submenuLink">
                  <router-link to="/customer" style="height: 35px">고객 관리
                  </router-link>
                </li>
                <li class="submenuLink">
                  <router-link to="/worker-log" style="height: 35px">{{
                    $t("menu.worker_log")
                  }}</router-link>
                </li>
                <!--li class="submenuLink"><router-link to="/service-log"  style="height:35px;">{{$t('menu.worker_log1')}}</router-link></li-->
                <li class="submenuLink">
                  <router-link to="/iot-device" style="height: 35px">{{
                    $t("menu.device")
                  }}</router-link>
                </li>
                <li class="submenuLink"><router-link to="/event-log">{{$t("menu.event_log")}}</router-link></li> 
              </ul>
            </li>
            <li class="setting">
              <!-- <a href="#none" @click="show_settings()">{{$t('menu.setting')}}</a> -->
              <a href="#none" @click="show_settings()">
                <!--img src="./assets/images/Header/ic_setting_white.svg" /-->
                <img  @click="show_settings()" src="./assets/images/Header/cog-outline.png" />
              </a>
            </li>
               <li >
              <a href="nelow_web.pdf" target="_blank"  class="sidebar-admin">
                  <!--i class="mdi mdi-book-open-outline logout"></i-->  
                <img src="./assets/images/Header/book.png" />               
              </a>
            </li> 
            <!-- 2022 숨김 srart -->
 

            <li >
              <a @click="logout()" href="#" class="sidebar-admin">
                <!--i class="mdi mdi-logout logout"></i-->
                 <img src="./assets/images/Header/logout.png" />
              </a>
            </li>
         
            <li >            
              <router-link to="/about">               
                 <!--i class="mdi mdi-information-outline logout"></i-->      
                 <img src="./assets/images/Header/info.png" />                       
              </router-link>              
            </li>
            <li  v-if="is_admin()" >            
              <router-link to="/web-accounts">               
                <!--i class="mdi mdi-information-outline logout"></i-->      
                 <img src="./assets/images/Header/web.png" />          
              </router-link>              
            </li>
          </ul>
        </div>
      </div>

      <!--
            <a id="show-sidebar" class="btn btn-sm btn-dark" href="#" @click="showSidebar()">
                <i class="fas fa-bars"></i>
            </a>
            -->
      <!-- 2022 new sidebar -->
      <nav id="sidebar" class="sidebar-wrapper">
        <div class="sidebar-content">
          <div class="sidebar-menu font-roboto">
            <div class="sidebar-menu font-roboto">
              <ul class="menu_list">
                <!--li class="icon_01" @click="clickMenu"><router-link to="/gis-master">GIS Master</router-link></li-->
                <li class="icon_01" ref="menuMonitoring0">
                  <a href="#none" @click="toggleSubmenu">{{
                    $t("menu.gis_master")
                  }}</a>
                  <ul>
                    <li>
                      <router-link to="/gis-master">{{
                        $t("menu.gis")
                      }}</router-link>
                      <span class="menu-arrow"></span>
                    </li>
                    <li>
                      <router-link to="/gis-dma">{{
                        $t("menu.dma")
                      }}</router-link>
                    </li>
                      <li><router-link  to="/simulation">Simulation</router-link></li>
                  </ul>
                </li>
                <!--li class="icon_02" @click="clickMenu"><router-link to="/leak-master">Water Leak</router-link></li-->
                <li class="icon_02" ref="menuMonitoring1">
                  <a href="#none" @click="toggleSubmenu">{{
                    $t("menu.leak_menu")
                  }}</a>
                  <ul>
                    <li>
                      <router-link to="/leak-master">{{
                        $t("menu.leak_master")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/water-leak-logger">{{
                        $t("menu.leak_logger")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/leak-monitoring">{{
                        $t("menu.leak_monitoring")
                      }}</router-link>
                    </li>
                  </ul>
                </li>
                <!--li class="icon_03" @click="clickMenu"><router-link to="/water-pressure">Water Pressure</router-link></li-->
                <li class="icon_03" ref="menuMonitoring2">
                  <a href="#none" @click="toggleSubmenu">{{
                    $t("menu.pressure_menu")
                  }}</a>
                  <ul>
                    <li>
                      <router-link to="/water-pressure">{{
                        $t("menu.pressure")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/pressure-monitoring">{{
                        $t("menu.pressure_monitoring")
                      }}</router-link>
                    </li>
                    <li><router-link to="/hydraulic-monitoring">Hydraulic</router-link></li>  
                  </ul>
                </li>

                <li class="icon_04" ref="menuMonitoring">
                  <a href="#none" @click="toggleSubmenu">{{
                    $t("menu.monitoring")
                  }}</a>
                  <ul>
                    <li>
                      <router-link to="/water-flow-logger">{{
                        $t("menu.pressure")
                      }}</router-link>
                    </li>
                    <li>
                      <router-link to="/block-monitoring">{{
                        $t("menu.block_monitoring")
                      }}</router-link>
                    </li>
                    <!--li><router-link to="/pumping">{{$t('menu.pumping')}}</router-link></li>
                              <li><router-link to="/water-tank">{{$t('menu.water_tank')}}</router-link></li-->
                  </ul>
                </li>
                <li class="icon_05" ref="menuMonitoring3">
                  <a href="#none" @click="toggleSubmenu">{{
                    $t("menu.maintenance")
                  }}</a>
                  <ul>
                    <li>
                      <router-link to="/leak-recovery">{{
                        $t("menu.leak_recovery")
                      }}</router-link>
                    </li>
                    <li><router-link to="/maintenance">{{$t('menu.maintenance')}}</router-link></li> 
                   
                  </ul>
                </li>
                <li class="icon_06"  v-if="model_admin()" ref="menuMonitoring4">
                  <a href="#none" @click="toggleSubmenu">Dataset</a>
                  <ul>
                    <li>
                      <router-link to="/dataset">Dataset</router-link>
                    </li>
                    <li><router-link to="/aI-modeling">AI MODEL</router-link></li> 
                   
                  </ul>
                </li>               
                <li class="icon_07" @click="clickMenu" v-if="is_admin()"><router-link to="/scadadata-chart">SCADA</router-link></li>
                <!--
                          <li class="icon_05" @click="clickMenu"><router-link to="/customer-voice">Customer Voice</router-link></li>
                          <li class="icon_06" @click="clickMenu"><router-link to="/water-meter-reading">Water Metering</router-link></li>
                          
                          <li class="icon_06" @click="clickMenu"><router-link to="/water-accounts">Water Metering</router-link>
                          <li class="icon_07" @click="clickMenu"><router-link to="/maintenance">Maintenance</router-link></li>
                          <li class="icon_08" @click="clickMenu"><router-link to="/water-quality">Water Quality</router-link></li>
                         -->
              </ul>
            </div>
          </div>

          <!-- sidebar-menu  -->
        </div>
        <!--
                <div class="sidebar-footer">
                   <sidebar-content
                    <a href="#">
                        <i class="fa fa-bell"></i>
                        <span class="badge badge-pill badge-warning notification">3</span>
                    </a>
                    <a href="#">
                        <i class="fa fa-envelope"></i>
                        <span class="badge badge-pill badge-success notification">7</span>
                    </a>
                    <a href="#">
                        <i class="fa fa-cog"></i>
                        <span class="badge-sonar"></span>
                    </a>
                    <a href="#">
                        <i class="fa fa-power-off"></i>
                    </a>
                     <div style="width: 276px;padding: 5px 10px;"> <img src="@/assets/mark.jpg"  alt="로고" />
                    </div>
                </div> 
                -->
      </nav>
      <!-- sidebar-wrapper  -->
      <!-- 4/16일 추가 23일 추가수정 -->

      <!-- 좌측메뉴 열림:닫힘 -->

      <!--      <div id="menu2" class="un-toggle-menu-off" @click="toggle_menu()">-->
      <!--        <div :class="is_toggled ? 'un-toggle-menu-btn-tri-off': 'un-toggle-menu-btn-tri-on'"></div>-->
      <!--      </div>-->

      <!-- 4/16일 추가 -->
      <main class="page-content" id="main">
        <div class="content-wrap">
          <router-view />
        </div>
      </main>
      <DlgSettings ref="dlg_setting" />
      <DlgPwSettings ref="dlg_pw_setting" />
    </div>
     </vue-scroll>
  </div>
</template>

<script>
import DlgPwSettings from "@/components/popup/DlgPwSettings";
import DlgSettings from "@/components/popup/DlgSettings";
import top_img_kr from "@/assets/mark_top.jpg";
import top_img_id from "@/assets/mark_top_id.jpg";
//import new_img from "@/assets/mark_top_id.jpg";
export default {
  beforeCreate() {
    // this.$http.get(this.URL+'api/get_setting?m_id='+localStorage.m_id).then((res) => {
    if (this.$store.setting === null)
      this.$http
        .get(this.URL + "api/get_setting?m_id=" + sessionStorage.m_id)
        .then((res) => {
          this.$store.setting = res.data;
        });

    // const {accessToken} = localStorage
    const { accessToken } = sessionStorage;
    if (accessToken) {
      this.$http
        .get(this.URL + "api/auth_check?token=" + accessToken)
        .then((res) => {
          if (res.data.msg === "success") {
            sessionStorage.accessToken = accessToken;

            //    localStorage.accessToken = accessToken
          } else delete sessionStorage.accessToken;
          //  delete localStorage.accessToken
        });
    }
  },
  created() {
    let locale = navigator.language || navigator.userLanguage;
    locale = locale.substring(0, 2);
    //  if (!(locale === 'ko' || locale === 'vi')) locale = 'en' // 한국어가 아닌 경우 무조건 영어로 설정
    if (!(locale === "ko")) locale = "en"; // 한국어가 아닌 경우 무조건 영어로 설정
    this.$i18n.locale = locale;
  },
  data() {
    return {
      user_name : sessionStorage.member_name === undefined||sessionStorage.member_name === 'undefined' ? "" :  sessionStorage.member_name,
      icons: { ko: top_img_kr, en: null },
      icons1: { ko: top_img_id, en: top_img_id },
      hide_sidebar_menu: false,
      is_toggled: true,
      sub_menu: false,
      admin: true,
      logo_imgName : sessionStorage.select_customer_id !== "32"? "logo" : "logo_samjin" ,
      
       customer_id:
        sessionStorage.select_customer_id === undefined
          ? "1"
          : sessionStorage.select_customer_id,
      customer_name: [],
      customer_no_list: {},
      ischange: false,
      isnew : true,

      //   gis_master: '/'+localStorage.level+'/gis-master/',
      //   gis_master: '/gis-master',
    };
  },
  methods: {
    show_settings() {
      this.$refs.dlg_setting.show();
    },
    show_pw_settings() {
      this.$refs.dlg_pw_setting.show();
    },
    toggle_menu() {
      this.is_toggled = !this.is_toggled;
      this.hide_sidebar_menu = !this.hide_sidebar_menu;
      this.$EventBus.$emit("sidebar_resize");
    },
    is_logined() {
      return sessionStorage.accessToken;
      //  return localStorage.accessToken
    },
    is_admin() {
      if (sessionStorage.customer_id === "1") return true;
      else return false;
    },
    is_samjin() {
      if (sessionStorage.customer_id === "32") return true;
      else return false;
    },
    model_admin() {
      if (sessionStorage.customer_id === "1" || sessionStorage.customer_id === "32") return true;
      else return false;
    },
    logout() {
      //  delete localStorage.accessToken
      delete sessionStorage.accessToken;
      sessionStorage.clear();
      this.customer_id = 1;
      this.$router.push("/login");
    },
    goRouter(path) {
      this.$router.push(path).catch((err) => {
        console.log(err);
      });
    },
    clickSidebarDropdown(e) {
      e.target.parentElement.classList.toggle("active");
      if (e.target.parentElement.classList.contains("active"))
        e.currentTarget.nextSibling.style.display = "block";
      else e.currentTarget.nextSibling.style.display = "none";
    },
    toggleSubmenu(e) {
      this.sub_menu = true;

      if (
        e.target.parentElement &&
        e.target.parentElement.classList.contains("on")
      ) {
        e.target.parentElement.classList.remove("on");
      } else {
        e.target.parentElement.classList.add("on");
      }
      if (
        this.$refs.menuMonitoring0 !== undefined &&
        e.target.parentElement.className !== "icon_01 on"
      )
        this.$refs.menuMonitoring0.classList.remove("on");
      if (
        this.$refs.menuMonitoring1 !== undefined &&
        e.target.parentElement.className !== "icon_02 on"
      )
        this.$refs.menuMonitoring1.classList.remove("on");
      if (
        this.$refs.menuMonitoring2 !== undefined &&
        e.target.parentElement.className !== "icon_03 on"
      )
        this.$refs.menuMonitoring2.classList.remove("on");
      if (
        this.$refs.menuMonitoring3 !== undefined &&
        e.target.parentElement.className !== "icon_05 on"
      )
        this.$refs.menuMonitoring3.classList.remove("on");
      if (
        this.$refs.menuMonitoring !== undefined &&
        e.target.parentElement.className !== "icon_04 on"
      )
        this.$refs.menuMonitoring.classList.remove("on");
      if (
        this.$refs.menuMonitoring_t !== undefined &&
        e.target.parentElement.className !== "icon_t on"
      )
        this.$refs.menuMonitoring_t.classList.remove("on");
       if (this.$refs.menuMonitoring4 !== undefined && e.target.parentElement.className !== "icon_06 on")  
        this.$refs.menuMonitoring4.classList.remove("on");
    },
    clickMenu() {
      this.sub_menu = false;
      if (this.$refs.menuMonitoring0 !== undefined)
        this.$refs.menuMonitoring0.classList.remove("on");
      if (this.$refs.menuMonitoring1 !== undefined)
        this.$refs.menuMonitoring1.classList.remove("on");
      if (this.$refs.menuMonitoring2 !== undefined)
        this.$refs.menuMonitoring2.classList.remove("on");
      if (this.$refs.menuMonitoring3 !== undefined)
        this.$refs.menuMonitoring3.classList.remove("on");
      if (this.$refs.menuMonitoring4 !== undefined)
        this.$refs.menuMonitoring4.classList.remove("on");  
      if (this.$refs.menuMonitoring !== undefined)
        this.$refs.menuMonitoring.classList.remove("on");
      if (this.$refs.menuMonitoring_t !== undefined)
        this.$refs.menuMonitoring_t.classList.remove("on");
    },
  },
  mounted() {
    this.customer_name = [];
    this.customer_no_list = {};
    
    this.logo_imgName = sessionStorage.select_customer_id !== "32"? "logo" : "logo_samjin" ;
    // this.customer_id = sessionStorage.select_customer_id=== undefined ? "1" : sessionStorage.select_customer_id
    this.$http.get(this.URL + "api/get_all_customer_list?sort=name").then((rows) => {
      if (!rows || rows.data.list <= 0) return;
      for (let i = 0; i < rows.data.list.length; i++) {
        this.customer_name.push({
          isNew : rows.data.list[i].isNew  , 
          value: rows.data.list[i].id,
          text: rows.data.list[i].name + "",
        });
        this.customer_no_list[rows.data.list[i].id] =
          rows.data.list[i].customer_no;
      }
    });
    let cur_page = "" + document.location.href;

    if (cur_page.indexOf("id.neverlosewater.com") !== -1) {
      // if(cur_page.indexOf("localhost") !== -1){
      this.icons = this.icons1;
    }
  },
  watch: {
    $route(to) {
      const toDepth = to.path;

      // if((toDepth.indexOf("detail") !== -1 || toDepth.indexOf("analysis") !== -1)&& !this.sub_menu ) {
      if (
        toDepth.indexOf("detail") !== -1 ||
        toDepth.indexOf("analysis") !== -1 ||
        toDepth.indexOf("-map") !== -1
      ) {
        this.hide_sidebar_menu = false;
        this.ischange = true;
        this.is_toggled = true;
        setTimeout(() => {
          this.toggle_menu();
        }, 50);
      } else {
        this.hide_sidebar_menu = true;
        this.is_toggled = false;
        this.ischange = false;
        this.toggle_menu();
      }
      if (
        toDepth.indexOf("monitoring-detail") !== -1 ||
        toDepth.indexOf("tank-detail") !== -1 ||
        toDepth.indexOf("station-detail") !== -1
      ) {
        this.hide_sidebar_menu = false;
        this.is_toggled = true;
        this.toggle_menu();
      }
    },
    customer_id() {
      sessionStorage.select_customer_id = this.customer_id;
      sessionStorage.select_customer_no = this.customer_no_list[this.customer_id];
      //console.log(sessionStorage.select_customer_no );
      this.$router.go(this.$router.currentRoute);
    },
  },
  components: {
    DlgSettings: DlgSettings,
    DlgPwSettings: DlgPwSettings,
  },
};
</script>
<style>


/*
  body {

    margin: 0;
   

  }
  #app {
    
  
    width : 100vw;
    height : 100vh;
   
  
  }
  */
  .page-wrapper{
    min-width: 1700px;
    overflow-x: scroll;
   /* white-space:nowrap*/
  }
</style>
<style scoped lang="scss">
@import "assets/css/base.css";
@import "assets/scss/base/variables";

.app {
  height: 100%;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  30% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(5deg);
  }

  70% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

html body {
  font-size: 0.9rem;
  font-family: "Roboto", "Noto Sans Korean", sans-serif;
  background-color: $color-bg;
}

#Header {
  width: 100%;
  height: 70px;
  background: $color-bg;
  z-index: 100;
  position: fixed;
  top: 0;
  display: flex;

  .logo {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    box-sizing: border-box;
  }

  .header_btn {
    flex: auto;
    box-sizing: border-box;

    ul {
      height: 70px;
      line-height: 70px;
      margin: 0;

      li {
        float: left;
        height: 100%;
        text-align: center;
        letter-spacing: 0.2px;

        select {
          width: 200px;
          height: 48px;
          display: table;
          vertical-align: middle;
          padding: 8px;
          margin: 10px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-color: $color-bg;
          color: $color-white;
          border-width: 1px;
          border-style: solid;
          border-color: $color-gray;
          border-image: initial;
          border-radius: 0;

          &:focus-visible {
            border-color: $color-white;
            border-radius: 5px;
          }
        }

        &.list-inline-item {
          width: auto;
          padding: 0 15px;
          color: rgba(255, 255, 255, 0.6);
          min-width: 32px;
          display: block;
          line-height: 70px;
          text-align: center;
          max-height: 70px;
          margin-left: 7px;

          .logout {
            font-size: 14px;
            text-decoration: none;
            color: rgba(255, 255, 255, 0.6);
          }
        }

        &.setting {
          padding: 0 15px;
          color: rgba(255, 255, 255, 0.6);
          min-width: 32px;
          display: block;
          line-height: 70px;
          text-align: center;
          max-height: 70px;
          // background: url('./assets/images/Header/ic_setting_white.svg') 23px center no-repeat;
          // background-size: 20px;
          a {
            padding-left: 10px;
          }
        }

        a {
          font-size: 14px;
          font-weight: 400;
          color: $color-white;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 240px;
  }
}

/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 10px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
  z-index: 99999;
}

.page-wrapper.toggled #show-sidebar {
  left: -40px;
}

/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  width: 240px;
  height: calc(100% - 70px);
  max-height: 100%;
  position: fixed;
  top: 70px;
  left: -240px;
  z-index: 999;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.log-status {
  color: #25be50;
  font-size: 150px;
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}

/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 60px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
  font-size: 11px;
  margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
  font-size: 8px;
  margin-right: 4px;
  color: #5cb85c;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
  // margin-top: 70px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
}

.sidebar-footer > a {
  flex-grow: 1;
  text-align: center;
  height: 30px;
  line-height: 30px;
  position: relative;
}

.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}

.badge-sonar {
  display: inline-block;
  background: #980303;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: absolute;
  top: 0;
}

.badge-sonar:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #980303;
  opacity: 0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: sonar 1.5s infinite;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  padding-top: 70px;
}

.page-wrapper .page-content > div {
  padding: 0 0;
}

/*
.page-wrapper .page-content > div {
  padding: 20px 40px;
}
*/
.page-wrapper .page-content {
  overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #525965;
}

::-webkit-scrollbar-thumb:active {
  background: #525965;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/*-----------------------------chiller-theme (0419 수정)-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
  /*background: rgb(198 204 216 / 55%);*/
  background: #28af81;
}

/*.chiller-theme .sidebar-wrapper .sidebar-header,*/
/*.chiller-theme .sidebar-wrapper .sidebar-search,*/
/*.chiller-theme .sidebar-wrapper .sidebar-menu {*/
/*    border-top: 1px solid #467495;*/
/*}*/

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand > a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer > a {
  /*color: #333;*/
  color: white;
}

.sidebar-wrapper .menu_list li a {
  color: $color-white;
  font-size: 16px;
  display: block;
  width: 100%;
  height: 100%;
  font-weight: 500;
  line-height: 46px;
}

.sidebar-wrapper .menu_list li .router-link-active {
  // background-color: #ccc;
  background-color: $color-green;
}

.sidebar-wrapper .menu_list li a {
  background-position: 33px center;
  background-repeat: no-repeat;
  background-size: 24px;
}

.sidebar-wrapper .menu_list li {
  width: 100%;
  /*height: 52px;*/
  line-height: 52px;
  text-indent: 54px;
}

.sidebar-wrapper .menu_list .icon_01 > a {
  // background-image: url('assets/images/LeftMenu/icon_01.png');
  background-image: url("./assets/images/new/ic_map.svg");
}

.sidebar-wrapper .menu_list .icon_02 > a {
  // background-image: url('assets/images/LeftMenu/icon_02.png');
  background-image: url("assets/images/new/ic_leak.svg");
}

.sidebar-wrapper .menu_list .icon_03 > a {
  // background-image: url('assets/images/LeftMenu/icon_03.png');
  background-image: url("assets/images/new/ic_pressure.svg");
}

.sidebar-wrapper .menu_list .icon_04 > a {
  // background-image: url('assets/images/LeftMenu/icon_04.png');
  background-image: url("assets/images/new/ic_flow.svg");
}

.sidebar-wrapper .menu_list .icon_05 > a {
  // background-image: url('assets/images/LeftMenu/icon_07.png');
  background-image: url("assets/images/new/ic_maintenance.svg");
}

.sidebar-wrapper .menu_list .icon_06 > a {
  background-image: url("assets/images/LeftMenu/file-tree-outline.svg");
}

.sidebar-wrapper .menu_list .icon_07 a {
  background-image: url("assets/images/LeftMenu/icon_04_w.png");
}

.sidebar-wrapper .menu_list .icon_08 a {
  background-image: url("assets/images/LeftMenu/icon_08.png");
}

.sidebar-wrapper .menu_list li ul {
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

.sidebar-wrapper .menu_list .icon_01.on ul {
  max-height: 200px;
  transition: max-height 0.3s;
}

.sidebar-wrapper .menu_list .icon_02.on ul {
  max-height: 200px;
  transition: max-height 0.3s;
}

.sidebar-wrapper .menu_list .icon_03.on ul {
  max-height: 200px;
  transition: max-height 0.3s;
}

.sidebar-wrapper .menu_list .icon_05.on ul {
  max-height: 200px;
  transition: max-height 0.3s;
}
.sidebar-wrapper .menu_list .icon_06.on ul {
  max-height: 200px;
  transition: max-height 0.3s;
}
.sidebar-wrapper .menu_list .icon_04.on ul {
  max-height: 300px;
  transition: max-height 0.3s;
}

.sidebar-wrapper .menu_list li ul li {
  padding-left: 0;
  margin-bottom: 0;
}

.sidebar-wrapper .menu_list li ul li a:hover {
  // 2022 new
  // color: #28B7CB;
  color: #28af81;
}

.chiller-theme .sidebar-wrapper .menu_list ul li .router-link-active {
  color: #28b7cb;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:focus,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li:active,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-footer > a:hover i {
  // background-color: #ccc;
  background-color: $color-content-bg;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a:hover {
  /*background-color: #f2f2f2;*/
  // background-color: #172A46;
  background-color: $color-green;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a:focus,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a:active {
  // background-color: #ccc;
  background-color: $color-content-bg;
}

/*.chiller-theme .sidebar-wrapper .sidebar-menu ul li.icon_04 a:focus,*/
/*.chiller-theme .sidebar-wrapper .sidebar-menu ul li.icon_04 a:active {*/
/*  background-color: #fff;*/
/*}*/
.chiller-theme .sidebar-wrapper .sidebar-brand > a:hover {
  color: #fff;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:active > a {
  // background-color: #ddd
  background-color: $color-content-bg;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
  color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
  color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus + span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
  color: #16c7ff;
  text-shadow: 0px 0px 10px rgba(22, 199, 255, 0.5);
}

/*
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before {
}
*/

/*
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
}
*/

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
  color: #6c7b88;
}

/*
.chiller-theme .sidebar-footer {
   background: #273f55; 
    box-shadow: 0px -1px 5px #282c33;
    border-top: 1px solid #464a52; 
}
*/
.chiller-theme .sidebar-footer > a:first-child {
  border-left: none;
}

.chiller-theme .sidebar-footer > a:last-child {
  border-right: none;
}

/*1차 메뉴아이템*/
.header-navbar .icon_t.on ul {
  position: right;
  width: 150px;
  right: 180px;
  top: 60px;
  max-height: 200px;
  height: auto;
  background-color: #0e5340;
}

.header-navbar .icon_t ul {
  width: 150px;
  right: 20px;
  height: 150px;
  max-height: 0;
  overflow: hidden;
}
.header-navbar .icon_t a:hover, .header-navbar .icon_t .submenuLink a:hover {
  // 2022 new
  // color: #28B7CB;
  color: #28af81;
  text-decoration: none;
}
.header-navbar .icon_t .submenuLink {
  position: relative;
  color: #fff;
  background-color: #0e5340;
}

.header-navbar .icon_t .submenuLink a {
  /* 하위 메뉴의 a 태그 스타일 설정 */
  color: #fff;
  background-color: #0e5340;
  
  /*border: solid 1px #cccccc;*/
  
  /* [변경] 위 칸의 하단 테두리와 아래칸의 상단 테두리가 겹쳐지도록 설덩 */
}

.submenu {
  /* 하위 메뉴 스타일 설정 */
  position: absolute;
  /*top:50px;*/
  right: -200px;
  height: 0px;
  overflow: hidden;
  transition: height 0.2s;
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  -o-transition: height 0.2s;
  width: 574px;
  /* [변경] 가로 드랍다운 메뉴의 넓이 */
}

.submenu li {
  display: inline-block;
  /* [변경] 가로로 펼쳐지도록 설정 */
}

.header-navbar .icon_t .submenu {
  /* 상위 메뉴에 마우스 모버한 경우 그 안의 하위 메뉴 스타일 설정 */
  line-height: 32px;
  width: 150px;
  height: 32px;
  /* [변경] 높이를 32px로 설정    */
}

.submenuLink:hover {
  /* 하위 메뉴의 a 태그의 마우스 오버 스타일 설정 */
  color: red;
  // background-color: #dddddd;
  background-color: #dddddd;
}

// -------------------------------------------------

// new style css
.nav-link {
  padding: 0 15px;

  min-width: 32px;
  display: block;
  line-height: 70px;
  text-align: center;
  max-height: 70px;
  border: none;

  img {
    vertical-align: middle;
    width: 42px;
  }

  span {
    color: white;
    width: 100%;
    margin-left: 8px;
    font-size: 14px;
  }
 
}
</style>

<template>
  <div class="content-body">
    <li class="setting-btn" style="float:right; margin: -50px 10px 0 0;">
      <b-button  class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="add_work">Add Work</b-button>
    </li>
    <!-- <h4 class="content-title">{{ $t('leak_recovery.page_title') }}</h4> -->
    <div class="content-header">
      <div>
        <label>
          <input class="ns-checkbox" type="checkbox" @click="SelectAllBox" v-model="allSelected">
        </label>
        <b-button class="ml-4" @click="go_detail">
          {{ $t('button.go_detail') }}
        </b-button>

      </div>
      <div class="ns-search-box">
        <input type="text" class="ns-search-input" v-model="value" :placeholder="$t('water_leak.left_menu.search')"
               @keyup.enter="Enter_Check">
        <button type="button" class="ns-search-button" @click="search"/>
      </div>
    </div>
    <!----------------- 2023 start -------------->

    <ul class="ns-list ns-list-head" style="width:100%">
      <li>
        <div class="ns-items " style="background: #3b4d7c; ">
          <div>
            <label><input class="ns-checkbox" type="checkbox" ></label>
          </div>
          <div class="title " style="width:20%;">
            <span>Title </span>
          </div>
          <div class="" style="width:15%;">
            <span>Work Creation Time</span>
          </div>
          <div class="" style="width:15%;">
            <span>Last Sound Time</span>
          </div>
          <div class="" style="width:13%;text-align: center;">
            <span>Total</span>
          </div>
          <div class=" " style="width:20%;text-align: center;" v-if="!isPressicon">
            <span>Leak Amount</span>
          </div>
          <div class=" " style="width:20%;text-align: center;" v-if="isPressicon">
            <span>Other / Quality / Pressure / Stop / Price / LEAK</span>
          </div>

          <div></div>
          <div></div>
        </div>
      </li>
    </ul>

    
    <ul class="ns-list" style="width:100%">
      <!--      <div class="input_search_box with&#45;&#45;all-button">-->
      <!--        <input type="text" class="input_search" v-model="value" :placeholder="$t('water_leak.left_menu.search')"-->
      <!--               @keyup.enter="Enter_Check">-->
      <!--        &lt;!&ndash;        <b-input v-model="value"  class="modal__input input_search"/>&ndash;&gt;-->
      <!--        <input type="submit" class="input_submit" @click="search">-->
      <!--        &lt;!&ndash;b-button v-if="levelA" class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text"   @click="$emit('go_delete',  leak_check)" >{{$t('button.delete')}}</b-button&ndash;&gt;-->
      <!--        &lt;!&ndash;span class="status m1-map" style="position: relative; left: 50px;top:0px;">-->

      <!--           <input type="radio" id="radio_01" name="radio01"  v-model="sort" value="create_datetime">-->
      <!--           <label for="radio_01">{{$t('water_leak.work_sort')}}</label>-->
      <!--           <input type="radio" id="radio_02" name="radio01"  checked v-model="sort" value="last_work_datetime">-->
      <!--           <label for="radio_02">{{$t('water_leak.leak_sort')}}</label>-->

      <!--        </span&ndash;&gt;-->

      <!--&lt;!&ndash;        <div style="float:right;margin: 15px 35px 0 ;">&ndash;&gt;-->
      <!--&lt;!&ndash;          <b-button class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text" @click="go_detail">&ndash;&gt;-->
      <!--&lt;!&ndash;            {{ $t('button.go_detail') }}&ndash;&gt;-->
      <!--&lt;!&ndash;          </b-button>&ndash;&gt;-->
      <!--&lt;!&ndash;          <input type="checkbox" @click="SelectAllBox" v-model="allSelected">&ndash;&gt;-->
      <!--&lt;!&ndash;          <span class="title"> {{ $t('water_leak.left_menu.select_all') }}</span>&ndash;&gt;-->
      <!--&lt;!&ndash;        </div>&ndash;&gt;-->
      <!--      </div>-->


      <li
          :class="[
           { 'is--active': activeCheckIndex === index },
           { 'with--bottom-index' : isBottomIndex},
           { 'press_icon' : isPressicon},
           ]" class="d-flex"
          v-for="(item, index) in box" :key="index">
        <div class="ns-items " @click="selectBox(index)" >
          <div>
            <label><input class="ns-checkbox" type="checkbox" :id="item.idx" :value="item.idx"
                          v-model=" leak_check"></label>
          </div>
          <div class="title " style="width:20%;">
            <span>
              <b-img :src="item.icon_img " alt="image" v-if="item.isMode"
                     style=" width: 15px;  position: absolute;  left: 55px; top: 25px; "></b-img>
            <span class="num bottom" v-if="isBottomIndex">{{ item.idx }}</span>
            </span>
            <p>
              <!-- work 별 이미지 추가 -->
              <span class="num" v-if="!isBottomIndex">{{ item.idx }}</span> {{ item.title }}
            </p>
          </div>
          <div class="f" style="width:15%;">
            <!--span>Work Creation Time</span-->
            <p>{{ item.time }}</p>
          </div>
          <div class="" style="width:15%;">
            <!--span>Last Sound Time</span-->
            <p>{{ item.close_time }}</p>
          </div>
          <div class="" style="width:13%;font-size: 16pt; font-weight: 600;text-align:center;">
            <!--span>Total Collected Sound</span-->
            <p>{{ item.number }}</p>
          </div>
          <!-- <div class="auto" style="width :310px; height: 40px;" > -->
          <div class=" " style=" width :20%; height: 50px;" v-if="!isPressicon">
            <BarHChart v-bind:chartData="item.leak" v-bind:chartData1="item.no_leak" v-bind:series_name="series_name"
            ></BarHChart>
          </div>
          <div class=" " style=" width :20%;font-size: 16pt; font-weight: 600;text-align:center;" v-if="isPressicon">
            <p>{{ item.other_cnt }} / {{item.quality_cnt}} / {{item.press_cnt}} / {{ item.cut_cnt }} /
              {{ item.price_cnt }} / {{ item.leak_cnt }}
            </p>
          </div>
          <div style="float:right;margin-left:5em;">
          <!--div style="float:right;right:6em"-->
            <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="work_edit(item.idx)"
                 style="width: 24px;height: 24px; margin-right: 3em; cursor: pointer;">
          <!--/div>
          <div style="float:right;right:3em"-->
            <img src="@/assets/images/new/ic_menu_chevron.svg" alt="" @click="$emit('go_detail',  item.idx)"
                 style="width: 8px; cursor: pointer">
          <!--/div-->
          </div>
        </div>
        
      </li>
      <!--div class="submit_box">
        <input type="submit" :value="$t('water_leak.left_menu.detail')" class="submit_btn" @click="go_detail" >
      </div-->
    </ul>
    <!-- <ul class="ns-list">
    
      <li
          :class="[
           { 'is--active': activeCheckIndex === index },
           { 'with--bottom-index' : isBottomIndex},
           { 'press_icon' : isPressicon},
           ]" class="d-flex"
          v-for="(item, index) in box" :key="index">
        <div class="ns-items " @click="selectBox(index)">
          <div>
            <label><input class="ns-checkbox" type="checkbox" :id="item.idx" :value="item.idx"
                          v-model=" leak_check"></label>
          </div>
          <div class="title flex-fill">
            <span>
              <b-img :src="item.icon_img " alt="image" v-if="item.isMode"
                     style=" width: 15px;  position: absolute;  left: 55px; top: 25px; "></b-img>
            <span class="num bottom" v-if="isBottomIndex">{{ item.idx }}</span>
            </span>
            <p>
              <span class="num" v-if="!isBottomIndex">{{ item.idx }}</span> {{ item.title }}
            </p>
          </div>
          <div class="flex-fill">
            <span>Work Creation Time</span>
            <p>{{ item.time }}</p>
          </div>
          <div class="flex-fill">
            <span>Last Sound Time</span>
            <p>{{ item.close_time }}</p>
          </div>
          <div class="flex-fill">
            <span>Total Collected Sound</span>
            <p>{{ item.number }}</p>
          </div>
          <div class="flex-fill " style="width :300px; height: 50px;">
            <BarHChart v-bind:chartData="item.leak" v-bind:chartData1="item.no_leak" v-bind:series_name="series_name"
            ></BarHChart>
          </div>
          <div>
            <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="work_edit(item.idx)"
                 style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
          </div>
          <div>
            <img src="@/assets/images/new/ic_menu_chevron.svg" alt="" @click="$emit('go_detail',  item.idx)"
                 style="width: 8px; cursor: pointer">
          </div>
        </div>
      </li>
    </ul> -->
    <!----------------- 2023 end -------------->
    <DlgWorkInfo ref="work_info" @worklist_refresh="worklist_refresh"/>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" force-use-infinite-wrapper="true">
      <div slot="no-more" style="color: rgb(102, 102, 102); font-size: 14px; padding: 10px 0px;">
        {{ $t('water_leak.left_menu.no_data') }}
      </div>
    </infinite-loading>
  </div>
</template>

<script>
//import { BIconCheckSquare } from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'
import DlgWorkInfo from '@/components/popup/DlgWorkInfo'
//import BarChart from '@/components/BarChart_st_line';
import BarHChart from '@/components/chart/BarHChart_leak'

export default {
  name: 'SearchLeftType1',
  watch: {
    sort () {
      this.search()

    },

  },
  data () {
    return {
      leak_check: [],
      value: '',
      allSelected: false,
      api_url: '',
      box: [],
      page: 1,
      infiniteId: +new Date(),
      levelA: false,
      icon_imag: '',
      isPressicon: false,
      leak_width: '20',
      other_width: '20',
      sort: 'create_datetime',
      series_name: 'Leak Amount(m³/h)'
      //    w_type : 100,
    }
  },
  components: {
    //BIconCheckSquare
    DlgWorkInfo: DlgWorkInfo,
    InfiniteLoading,
    // BarChart,
    BarHChart
  },
  props: {
    activeCheckIndex: {
      type: null
    },
    w_type: {
      type: Number
    },
    //  box: {
    //    type: Array
    //  },
    isBottomIndex: {
      type: Boolean,
      default: false
    },

  },
  methods: {
    add_work(){
      this.$refs.work_info.insert_show(this.w_type,this.measure_mode)
    },
    selectBox (index) {
      this.$emit('updateCheckIndex', index, this.box[index])
    },
    go_detail () {
      var leak_arry = this.leak_check
      if (leak_arry.length > 0) {
        this.$emit('go_detail', leak_arry)
      } else {
        alert(this.$t('water_leak.left_menu.alert_select_list'))
      }
    },
    work_edit (idx) {
      this.$refs.work_info.show(idx, this.w_type)
    },
    worklist_refresh () {
      this.search()
    },
    infiniteHandler ($state) {
      //sort_field=this.sort&sort_type=desc
      var customer_id = sessionStorage.select_customer_id
      //  if(sessionStorage.select_customer_id === "27"){
      //     customer_id = 22
      //   }
      // this.$http.get(this.URL+'api/get_work_list_ex?w_type='+this.w_type+'&search_term='+this.value+'&per_page=10&page='+this.page+'&sort_field='+this.sort+'&sort_type=desc&customer_id='+sessionStorage.select_customer_id).then((rows) => {
      this.$http.get(this.URL + 'api/get_work_list_ex?w_type=' + this.w_type + '&search_term=' + this.value + '&per_page=10&page=' + this.page + '&sort_field=' + this.sort + '&sort_type=desc&customer_id=' + customer_id).then((rows) => {
        if (rows.data.rows.length > 0) {
          this.page += 1
          //this.list.push(...data.hits);
          for (let i = 0; i < rows.data.rows.length; i++) {
            if (i == 0 || this.page === 2) {
              this.t_title = rows.data.rows[i].title
              this.t_worker = rows.data.rows[i].worker
              this.t_start_time = rows.data.rows[i].create_datetime
              this.t_close_time = rows.data.rows[i].last_work_datetime
            }
            var abox = {}
            if (this.w_type === 100 || this.w_type === 101) {
              abox = {
                'title': rows.data.rows[i].title,
                'idx': rows.data.rows[i].id,
                'time': rows.data.rows[i].create_datetime,
                'number': rows.data.rows[i].leak_count,
                'close_time': rows.data.rows[i].last_work_datetime,
                'worker': rows.data.rows[i].worker,
                'w_title': rows.data.rows[i].title,
                'leak': rows.data.rows[i].leak_amount
              }

            }else{
              this.isPressicon = true;
                abox = {'title': rows.data.rows[i].title, 
                       'idx':  rows.data.rows[i].id,
                       'time':  rows.data.rows[i].create_datetime,
                       'number':  rows.data.rows[i].cnt, 
                       'close_time': rows.data.rows[i].last_work_datetime, 
                       'worker': rows.data.rows[i].worker,
                       'w_title':rows.data.rows[i].title,
                       'other_cnt':rows.data.rows[i].other_cnt,
                       'quality_cnt':rows.data.rows[i].quality_cnt,
                       'press_cnt':rows.data.rows[i].press_cnt,
                       'cut_cnt':rows.data.rows[i].cut_cnt,
                       'price_cnt':rows.data.rows[i].price_cnt,
                       'leak_cnt':rows.data.rows[i].leak_cnt,
                       } ;
            }
            this.box.push(abox)
          }
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    SelectDelete () {

    },
    SelectAllBox () {

      // this.isChecked = true;
      var selected = []

      if (!this.allSelected) {
        //   this.box.forEach(function (item) {
        for (let i = 0; i < this.box.length; i++) {
          selected.push(this.box[i].idx)
          if (i === 19) {
            alert(this.$t('water_leak.left_menu.alt_more_20'))
            break
          }
          //   });
        }
      }
      this.leak_check = selected

    },
    Enter_Check () {
      //  if(event.keyCode == 13){
      //  this.$emit('go_search',  this.value) ;  // 실행할 이벤트
      this.search()
    },
    search () {
      this.page = 1
      this.box = []
      this.infiniteId += 1
    }

  },
  mounted () {
    setTimeout(() => {
      if (this.box.length > 0)
        this.$emit('updateCheckIndex', 0, this.box[0])
    }, 500)

    //삭제버튼 활성화 여부
    // if(localStorage !== null && typeof (localStorage) !== undefined && localStorage.level === 'A'){
    if (sessionStorage !== null && typeof (sessionStorage) !== undefined && sessionStorage.level < 2) {
      this.levelA = true
    } else {
      this.levelA = false
    }

  }
}

</script>

<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
<!--                        <li class="breadcrumb-title">Water Quality</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                        <li class="breadcrumb-item active">Water Quality</li>
                    </ol>
                </li>
            </ul>
        </div>
        <!-- tom-bar-end -->

        <b-row style="margin: 0;">
            <b-col style="padding: 0;">
                <div :style="{height: map_height+'px'}">
                    <Map ref="map"
                        :visible_full_extent="false"
                        :visible_leak_detail_button="true"
                        :visible_size_view_button="true"
                        :visible_gisload_button="true"
                        w_type="130"
                        @go_detail="go_detail"
                        @size_view="size_view"
                        @set_map_gisload="set_map_gisload"
                        @set_info="set_info"
                        @toggle_visible_all_layer = "toggle_visible_all_layer"
                        @facility_invisible_layer = "facility_invisible_layer"
                    />
                </div>
            </b-col>
        </b-row>
        <b-row>
<!--            <b-col>-->
                <div class="table-box table-box-resize ml-4 mr-4 gis-master-table-box">
                <!-- <h5 class="card-box-title">Water Quality Data table</h5>
                <label for="textarea-small" class="table-box-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label> -->
                <ServerSideTable ref="table_workinfo"
                    :columns="columns"
                    :search="true"
                    :sort="false"
                    :select="true"
                    :adv_search="adv_search"
                    :max_height="table_height+'px'"
                />
                </div>
<!--            </b-col>-->
        </b-row>
    </div>
</template>

<script>
import Map from '@/components/Map'
import ServerSideTable from '@/components/table/ServerSideTable';
import {CreateVectorLayer_f} from '@/gis.js';


export default {
    name: 'WaterQuality',
    props: {
    },
    data() {
        return {
            contents_height: 600,
            map_height: 300,
            table_height: 300,

            columns: [
                {label: 'No', field: 'idx'},
                {label: 'Title', field: 'w_title'},
                {label: 'Contents', field: 'w_contents'},
                {label: 'Worker', field: 'worker'},
                {label: 'Start Time', field: 'w_start_time'},
                {label: 'End Time', field: 'w_end_time'},
                {label: 'Count', field: 'water_quality_count'}
            ],
            adv_search: [
                {
                    field: 'lf_type',
                    control: 'select',
                    value: null,
                    options: [
                        { value: null, text: 'LF type' },
                        { value: '0', text: 'Pipe' },
                        { value: '1', text: 'Valve' },
                        { value: '2', text: 'Water Meter' },
                        { value: '4', text: 'Hydrant'},
                        { value: '13', text: 'Other'},
                    ]
                },
                {field: 'start_time', control: 'date', value: null, value2: null, placeholder: 'From date', placeholder2: 'To date'},
                {field: 'o_max_frequency', control: 'range', value: null, value2: null, placeholder: 'From frequency', placeholder2: 'To frequency'},
                {field: 'f_block_idx', control: 'select', value: null, options: [{ value: null, text: 'Block name' }]},
            ]

        }
    },
    methods: {
        window_resiz() {
            this.contents_height = window.innerHeight - 210
            //this.table_height = this.contents_height/2
            //this.map_height = this.table_height

            this.map_height = this.contents_height/1.3
            this.table_height = this.contents_height/4

            this.$refs.map.update_size()
        },
       toggle_visible_all_layer() {

            this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')===100)
                    layer.setVisible(this.$refs.map.layer_all_visible)
            }
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        size_view(e) {
            if(e==='up') {
                this.table_height += 100
                this.map_height -= 100
            }
            else if(e==='down') {
                this.table_height -= 100
                this.map_height += 100
            }
            this.$refs.map.update_size()
        },
        go_detail() {
            let rows = this.$refs.table_workinfo.getSelectRows()
            if(rows.length<=0) return;
            let list = []
            for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
            //this.$router.push({path: '/leak-detail', query: { widx: list }})
        },
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*2, size1[1]*2] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert("지도를 확대해 주세요!!!") ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length-1; i++) {
                    var layer = this.$refs.map.layer_list[i];
                    if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length-1; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);


                    } //for문 끝

                } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },
    },
    created () {
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.window_resiz)
    },
    mounted () {
        window.addEventListener('resize', this.window_resiz)
        this.window_resiz()

        this.$refs.table_workinfo.setUrl(this.URL+"api/get_work_list_ex?w_type=130")
    },
    components: {
        ServerSideTable: ServerSideTable,
        Map: Map
    }
}

</script>

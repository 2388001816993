<template>
  <div>
    <!-- top-bar -->
    <div class="navbar-custom">
          <h4 class="content-title">Simulation - Model Master</h4>
           <li class="setting-btn" style="float:right; margin: -40px 55px 0 0;">
          <b-button  class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="new_model">New</b-button>
          <!--b-button  class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="delete_modeling">Delete</b-button-->
           </li>  
           <ul class="list-unstyled topnav-menu topnav-menu-left">
    
           </ul>
    </div>
    <!-- tom-bar-end -->
    <div class="monitoring_box">
      <!-- 2022 new -->
      <!--      Headers start-->
      <div class="content-header">
        <div class="d-flex align-items-center">
          <div class="item-total">
            <span>Simulation Model</span>
            <strong>{{ tot_block_length }}</strong>
          </div>
      
        </div>

        <div class="item-right">
          <div class="ns-search-box" style="right: 16px;">
            <input
              type="text"
              class="ns-search-input"
              v-model="value"
              placeholder="Search..."
              @keyup.enter="Enter_Check"
            />
            <button type="button" class="ns-search-button" @click="go_search" />
          </div>
        </div>
      </div>
  
      <!-- 2023 start-------------------- -->
      <div class="monitoring-content mt-3" :key="listKey">
        <ul class="monitoring-list row row-cols-3">
          <!--          <li class="col" v-for="item in ts_monitoring_list" :key="item.block_id">-->
          <!--            <MonitoringCard :dma_name="item.point_name"-->
          <!--                            :last_date_time="item.last_measure_datetime" :tag="item.id"-->
          <!--                            :now_flow="item.water_flow">-->
          <!--            </MonitoringCard>-->
          <!--          </li>-->
          <div  v-for="(item,id) in box" :key="id" style="margin : 0 0 30px;">
          <fragment >
            <li class="col">
              <ModelingCard @on_inp_file_info="on_inp_file_info"
                :dma_name="item.name"
                :tag="item.id"              
              >
              </ModelingCard>
            </li>
          </fragment>
          </div>
        </ul>
      </div>
    <!-- 2023 end------------------- -->    
    </div>
    <DlgInpFileInfo ref="inp_file_info" @search="go_search" />
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"  force-use-infinite-wrapper="true">
       <div slot="no-more" style="color: rgb(102, 102, 102); font-size: 14px; padding: 10px 0px;">{{$t('water_leak.left_menu.no_data')}}</div>
    </infinite-loading>
  </div>
</template>

<script>
import ModelingCard from "@/components/card/ModelingCard";
import { Fragment } from "vue-fragment";

import DlgInpFileInfo from '@/components/popup/DlgInpFileInfo' 
import InfiniteLoading from 'vue-infinite-loading';
//import { Project, Workspace} from 'epanet-js';
export default {
  name: "LeakMonitoring",
  props: [],
  watch: {
    leak_type() {
      this.go_search(); // 실행할 이벤트
    },
    sort_strength() {
      if (this.sort_strength) this.strength_sort_yn = "strength";
      else this.strength_sort_yn = "";
      this.go_search(); // 실행할 이벤트
    },
  },
  data() {
    return {
      columns: [
        { label: "Tag", field: "tag" },
        { label: "Name", field: "name" },
      ],
      box: [],
      sort_:'',
      infiniteId : +new Date(),
      page:1,
      ts_flow_tag_list: [],
      temp_ts_monitoring_list: [],
      value: "",
      listKey: 0,
      tot_block_length: 0,
      s_block_length: 0,
      leak_type: "",
      sort_strength: "",
      strength_sort_yn: "",
    };
  },
  methods: {
    on_inp_file_info(id){
      this.$refs.inp_file_info.show(id)
    },
    new_model(){
      this.$refs.inp_file_info.insert_show()
    },
    importTextFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'text/plain'; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"
      // this 접근을 위해 선언 필요
      const self = this;
      input.onchange = function () {
          const file = new FileReader();
          file.onload = () => {
          //    document.getElementById('text-data').textContent = file.result;
            //  self.$data.textData = file.result;
              self.$data.file_text = file.result
              self.loading_model();
            
          };
          self.$data.read_file_name = this.files[0].name ;
          file.readAsText(this.files[0]);
      };
      input.click();
      // const fs = require("fs");
      // const net1 = fs.readFileSync("net1.inp");
      // Runs toolkit methods: EN_open, EN_solveH & EN_close
    },
    
    delete_modeling(){
        alert("Do you want to delete?")
        if(sessionStorage === null || typeof sessionStorage === undefined){
                alert("Can not be modified.")
                return
    //    }else if(localStorage.level === 'C'){
        }else if(sessionStorage.level > 1){
          //  alert("You do not have permission to edit.")
            alert(this.$t('water_leak.left_menu.alert_no_permission'));
            return;
        }
        if(this.select_id < 0) return;
        let params = {} ;
        let model_info = {};
        params["id"] = this.select_id ;
        model_info["status"] = 'U';
        params["fields"] = encodeURIComponent(JSON.stringify(model_info))
        this.$http.post(this.URL+'api/set_modeling_upload' ,params).then((res) => {
          console.log(res.data);
          if(res.data.check ===1){
              alert("fail : "+ res.data.msg) ;
          }else{
             alert("Delete success!!")
             this.search();
          }
          //left_list를 refresh한다.
        })
    }, 
    updateKey: function () {
      this.listKey += 1;
    },
    go_m2_info(m2_point_id) {
      this.$refs.m2_setting.show(m2_point_id);
    },
    handleOk() {
      this.ts_monitoring_list = [...this.temp_ts_monitoring_list];
      let param = [];
      for (let i = 0; i < this.ts_monitoring_list.length; i++)
        param.push(this.ts_monitoring_list[i].tag);
      this.$http.get(this.URL + "api/set_monitoring_list?list=" + param);
    },
     search(){      
      this.page = 1;
      this.box = [];
     // this.listKey += 1;
      this.infiniteId += 1;
    },
    go_search() {
     // if (this.value === undefined) return;
      this.page = 1
      this.box = []
      this.infiniteId += 1
    },
    Enter_Check() {
      //  if(event.keyCode == 13){
      this.go_search(); // 실행할 이벤트
    },
     infiniteHandler($state) {
       //sort_field=this.sort&sort_type=desc
       var customer_id = sessionStorage.select_customer_id ;     
      
      this.$http.get(this.URL+'api/get_model_list_ex?search=' + this.value + '&page='+this.page+'&sort=&customer_id='+customer_id).then((results) => { 
        if (results.data.rows.length > 0) {
         
          this.tot_block_length = (this.page-1) *10+results.data.rows.length
           this.page += 1;
          for(let i=0; i<results.data.rows.length; i++) {    
            
             var abox = {};
            
               abox = {'name': results.data.rows[i].name, 
                       'id':  results.data.rows[i].id,
                    //   'name' : results.data.rows[i].name,
                       'contents' : results.data.rows[i].contents,
                       'insert_datetime':  results.data.rows[i].insert_datetime,                                             
               
               } ; 

            this.box.push(abox) ;
          }
          if(this.page === 2){
            //this.selectBox(0)
          
          }
          $state.loaded();
          //$state.complete();
        } else {
          $state.complete();
        }
      });
    },  
  },
  mounted() {
    //  this.$http.get(this.URL+'api/get_monitoring_list').then((res) => {
  
   

    // this.$http.get(this.URL+'api/API21/m2_leak/list?customer_id='+sessionStorage.select_customer_id).then((res) => {

  },
  beforeDestroy() {},
  components: {
    ModelingCard,
    Fragment,
    DlgInpFileInfo : DlgInpFileInfo,
    InfiniteLoading,
  },
};
</script>

<style scoped lang="scss">
// 2022 new 추가
.monitoring-content {
  background-color: none;
  padding: 0 0 30px;
  margin-top: 0px;
}
// .item-total{
//    height: 4px;
//     width: 200px;
//     position: relative;
//     margin: 10px;
// }

.row {
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.col {
  padding-right: 8px;
  padding-left: 8px;
}
</style>

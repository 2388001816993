<template>
    <div ref="modal" class="facility-info" v-if="properties!==null">
      <h4 class="facility-info__title">{{$t('facility_info.title')}}</h4>
      <b-tabs  align="center" content-class="facility-info-content is--scroll" >
          <b-tab  :title="$t('facility_info.properties')" active>
            
              <b-row v-show="id_list_view">
                  <b-col sm="5">
                      <label class="facility__label">Duplicate ID(pos) </label>
                  </b-col>
                  <b-col sm="7">
                       <b-select class="facility__input" size="sm" v-model="properties['id']" :options="duplicate_id_list" :disabled="id_disable" @change="switchSelect1($event)"></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.no')}}</label>
                  </b-col>
                  <b-col sm="7">
                     <b-input  class="facility__input" size="sm" v-model="properties['id']" disabled ></b-input>
                  </b-col>
              </b-row>              
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.type')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties['gis_layer_id']" :options="layer_type_option" disabled ></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.f_name')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['name']"></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.f_type')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties['type']" :options="f_type_option" @change="switchSelect($event)"></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.material')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties['material']" :options="material_option"></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.size')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['size']" type="number" min=0 ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.capacity')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['capacity']"  type="number" min=0  ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.length')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['length']" type="number" min=1 ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.ins_date')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['installation_date']" type="date"></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.serial_no')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['serial_no']"></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.remarks')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['remarks']"></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.block_name')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['block_id']"  type="number" min=1 onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();" ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.r_date')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['register_datetime']" type="date"></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.status')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" :disabled="f_satus_level"></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.elevation')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['elevation']"  type="number" min=0  ></b-input>
                  </b-col>
              </b-row>              
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">{{$t('facility_info.worker')}}</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties['email']" disabled></b-input>
                  </b-col>
              </b-row>
          </b-tab>
           <b-tab  title="PRV Valve"  v-if="is_prv">
           
                 <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Valve Type</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties1['valve_type']" :options="valve_type_option"></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Reducing method</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties1['decompression_type']" :options="prv_type_option" ></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Remote monitoring</label>
                  </b-col>
                  <b-col sm="7">
                      
                      <b-select class="facility__input" size="sm" v-model="properties1['check_type']" :options="check_type_option" ></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Pressure gauge Pos.</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties1['press_gauge_pos']"  :options="gauge_pos_option"></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Press_setting_value</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['press_setting_value']" type="number" min=0 ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">water pressure(front)</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['front_press_value']" type="number" min=0 ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">water pressure(rear)</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['back_press_value']"  type="number" min=0  ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Reducing status</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties1['decompression_yn']" :options="yn_option" ></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">malfunction status</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties1['broken_yn']" :options="yn_option" ></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">valve room size</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['chamber_size']"></b-input>
                  </b-col>
              </b-row>
               <b-row>
                  <b-col sm="5">
                      <label class="facility__label">valve room depth</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['chamber_depth']"  ></b-input>
                  </b-col>
              </b-row>              
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">valve room remark</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['room_remark']"></b-input>
                  </b-col>
              </b-row> 
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Address</label>
                  </b-col>
              </b-row>
              <b-row>    
                  <b-col sm="12">
                      <b-input class="facility__input" size="sm" v-model="properties1['address']" ></b-input>
                  </b-col>
              </b-row>           
          </b-tab>  
          <b-tab  title="Control Valve"  v-if="is_control_valve">
           
                 <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Valve Type</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties1['valve_type']" :options="valve_type_option1"></b-select>
                  </b-col>
              </b-row>
    
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Operation Type </label>
                  </b-col>
                  <b-col sm="7">
                      
                      <b-select class="facility__input" size="sm" v-model="properties1['move_type']" :options="move_type_option" ></b-select>
                  </b-col>
              </b-row>
         
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">valve room size</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['chamber_size']"></b-input>
                  </b-col>
              </b-row>
               <b-row>
                  <b-col sm="5">
                      <label class="facility__label">valve room depth</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['chamber_depth']" ></b-input>
                  </b-col>
              </b-row>              
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">valve room remark</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['room_remark']"></b-input>
                  </b-col>
              </b-row> 
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Address</label>
                  </b-col>
              </b-row>
              <b-row>    
                  <b-col sm="12">
                      <b-input class="facility__input" size="sm" v-model="properties1['address']" ></b-input>
                  </b-col>
              </b-row>           
          </b-tab>
           <b-tab  title="Air Valve"  v-if="is_air_valve">
           
                 <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Valve Type</label>
                  </b-col>
                  <b-col sm="7">
                      <b-select class="facility__input" size="sm" v-model="properties1['valve_type']" :options="valve_type_option2"></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">valve room size</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['chamber_size']"></b-input>
                  </b-col>
              </b-row>
               <b-row>
                  <b-col sm="5">
                      <label class="facility__label">valve room depth</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['chamber_depth']" ></b-input>
                  </b-col>
              </b-row>              
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">valve room remark</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['room_remark']"></b-input>
                  </b-col>
              </b-row> 
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Address</label>
                  </b-col>
              </b-row>
              <b-row>    
                  <b-col sm="12">
                      <b-input class="facility__input" size="sm" v-model="properties1['address']" ></b-input>
                  </b-col>
              </b-row>           
          </b-tab>  
           <b-tab  title="Water Meter"  v-if="is_water_meter">
                 <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Customer Number</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['bill_customer_no']" ></b-input>
                  </b-col>
              </b-row>
                 <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Meter Type</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['meter_type']" ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Installtioned_size(mm)</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['installed_size']" type="number" min=0 ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">installed_year(yyyy)</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['installed_year']" type="number" min=0 disable></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">water pressure (bar)</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['press_value']" type="number" min=0 ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Reading value</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['meter_value']" type="number" min=0 ></b-input>
                  </b-col>
              </b-row>   
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">sealing_yn</label>
                  </b-col>
                  <b-col sm="7">                    
                     <b-select class="facility__input" size="sm" v-model="properties1['packed_yn']" :options="yn_option" ></b-select>
                  </b-col>
              </b-row>   
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Device Number</label>
                  </b-col>
                  <b-col sm="7">                    
                     <b-input class="facility__input" size="sm" v-model="properties1['meter_no']"  ></b-input>
                  </b-col>
              </b-row>   
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Water Meter Status</label>
                  </b-col>
                  <b-col sm="7">
                       <b-select class="facility__input" size="sm" v-model="properties1['meter_status']" :options="meter_status_option" ></b-select>
                  </b-col>
              </b-row>                             
            
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Replacement required</label>
                  </b-col>
                  <b-col sm="7">
                      
                      <b-select class="facility__input" size="sm" v-model="properties1['replace_require_yn']" :options="yn_option" ></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Replacement reason</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['replace_require_etc']" ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">protection_box_place</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['protection_box_pos']"  ></b-input>
                  </b-col>
              </b-row>
               <b-row>
                  <b-col sm="5">
                      <label class="facility__label">water meter box</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['protection_box_status']"  ></b-input>
                  </b-col>
              </b-row>  
               <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Relocation required</label>
                  </b-col>
                  <b-col sm="7">
                      
                      <b-select class="facility__input" size="sm" v-model="properties1['box_move_yn']" :options="yn_option" ></b-select>
                  </b-col>
              </b-row>            
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Relocation reason</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['box_move_etc']"  ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">water supply type</label>
                  </b-col>
                  <b-col sm="7">
                      <!--b-input class="facility__input" size="sm" v-model="properties1['water_supply_etc']"  ></b-input-->
                      <b-select class="facility__input" size="sm" v-model="properties1['water_supply_etc']" :options="water_supply_option" ></b-select>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Tank Capacity(L)</label>
                  </b-col>
                  <b-col sm="7">
                      <!--b-input class="facility__input" size="sm" v-model="properties1['water_supply_etc']"  ></b-input-->
                     <b-input class="facility__input" size="sm" v-model="properties1['tank_capacity']"  ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">People Qty</label>
                  </b-col>
                  <b-col sm="7">
                      <!--b-input class="facility__input" size="sm" v-model="properties1['water_supply_etc']"  ></b-input-->
                     <b-input class="facility__input" size="sm" v-model="properties1['people_qty']" type="number" min=0 ></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Building Height</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['building_high']" type="number" min=0 ></b-input>
                  </b-col>
              </b-row>   
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">floor height</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['building_floor']" type="number" min=0 ></b-input>
                  </b-col>
              </b-row>  
              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">building_etc</label>
                  </b-col>
                  <b-col sm="7">
                      <b-input class="facility__input" size="sm" v-model="properties1['building_etc']"></b-input>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col sm="5">
                      <label class="facility__label">Address</label>
                  </b-col>
              </b-row>
              <b-row>    
                  <b-col sm="12">
                      <b-input class="facility__input" size="sm" v-model="properties1['address']" ></b-input>
                  </b-col>
              </b-row>           
          </b-tab>            
          <b-tab :title="$t('facility_info.specification')" v-if="properties!==null">
              <b-row>
                  <b-col sm="12">
                      <label class="facility__label">{{$t('facility_info.ext_01')}}</label>
                  </b-col>
                  <b-col sm="12">
                      <b-input size="sm" class="facility__input" v-model="properties['ext_field1']"></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="12">
                      <label class="facility__label">{{$t('facility_info.ext_02')}}</label>
                  </b-col>
                  <b-col sm="12">
                      <b-input size="sm" class="facility__input" v-model="properties['ext_field2']"></b-input>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col sm="12">
                      <label class="facility__label">{{$t('facility_info.ext_03')}}</label>
                  </b-col>
                  <b-col sm="12">
                      <b-input size="sm" class="facility__input" v-model="properties['ext_field3']"></b-input>
                  </b-col>
              </b-row>

          </b-tab>
          <b-tab :title="$t('facility_info.work')">
              <vue-good-table ref="table"
                  :columns="columns"
                  :rows="table"
              />
          </b-tab>
          <b-tab :title="$t('facility_info.photo')">
                  <p v-for="(item,i) in img_list" :key="i">
                                    <b-card
                                        no-body
                                        img-top
                                        class="photo-card"
                                    >
                                    <b-row :id="'row_img'+i">
                                    <b-col sm="11">
                                        <b-img thumbnail fluid :src="item" alt="image" bottom ></b-img>
                                    </b-col>
                                    <b-col sm="1" v-if = "exist_image">    
                                        <span @click="del_image(i)" ><i class='fas fa-minus-circle fa-lg' style='color:#de7171'></i></span>
                                    </b-col>    
                                      </b-row>
                                        <!--<b-img thumbnail fluid src="http://localhost:3000/api/get_img/leakage/6/1" alt="Image" bottom></b-img>-->
                                    </b-card>      
                    </p>  
                     <b-row>
                        <b-col sm="4">
                          
                        </b-col>
                        <b-col sm="2" >  
                             
                             <Label for="ex_filename">                      
                              <i class='fas fa-folder-plus fa-2x' style='color:#28af81'></i>
                             </Label>
                             <input type="file" id="ex_filename" multiple @change="previewMultiImage($event)" style="display: none;"/> 
                        </b-col>
                        <b-col sm="2" @click="reset">                                
                            <i class='fas fa-folder-minus fa-2x' style='color:#de7171'></i>
                        </b-col>                           
                    </b-row>    
                    <b-row >
                       <template v-if="preview_list.length" >
                        <div v-for="(item, index) in preview_list" :key="index">
                            <img :src="item" class="img-fluid" />
                            <p class="mb-0">file name: {{ image_list[index].name }}</p>
                            <p>size: {{ image_list[index].size/1024 }}KB</p>
                        </div>
                      </template>      
                    </b-row>  
              <!--b-card
                  no-body
                  img-top
                  class="photo-card"
              >
                 
                  <b-img thumbnail fluid :src="img1" alt="Image" bottom></b-img>
              </b-card>
              <b-card
                  no-body
                  img-top
                  class="photo-card"
              >
                   <b-img thumbnail fluid :src="img2" alt="Image" bottom></b-img>
                 
              </b-card>
              <b-card
                  no-body
                  img-top
                  class="photo-card"
              >

                   <b-img thumbnail fluid :src="img3" alt="Image" bottom></b-img>
                 
              </b-card-->
          </b-tab>
                     <b-tab title="Total" v-if="b_show"  >
                     <b-row>
                        <b-col sm="6">
                            <label>Service Pipe</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" :value=vlength disabled></b-input>
                        </b-col>
                    </b-row>     
                    <b-row>
                        <b-col sm="6">
                            <label>Supply Pipe</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" :value=plength disabled></b-input>
                        </b-col>
                    </b-row>                                                         
                    <b-row>
                        <b-col sm="6">
                            <label>Valve Count</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" :value=vcount disabled></b-input>
                        </b-col>
                    </b-row>              
                    <b-row>
                        <b-col sm="6">
                            <label>Water Meter Count</label>
                        </b-col>
                        <b-col sm="6">
                              <b-input size="sm" :value=wcount disabled></b-input>
                        </b-col>
                    </b-row>       
                    <!-- .inp 파일 다운로드 기능 -->       
                    <!--b-row>
                        <b-col sm="8">
                           <b-row>
                            <b-col sm="3">
                            
                            <b-button v-if="!loading" size="sm" class="mr-2 modal-content-button" @click="make_modeling(0)">Pipe_J</b-button>
                             <span v-if="loading"><img src="@/assets/images/loading.gif" style="width:25px;"/>1. junction of pipe </span>
                              </b-col>
                            <b-col sm="3">
                            <b-button v-if="!loading1" size="sm" class="mr-2 modal-content-button" @click="make_modeling(1)" :disabled="first_pipe">Point_J</b-button>
                              <span v-if="loading1"><img src="@/assets/images/loading.gif" style="width:25px;"/>2. junction of point</span>
                            </b-col>
                            <b-col sm="6">     
                            <b-button v-if="!loading2" size="sm" class="mr-2 modal-content-button" @click="make_modeling(2)">Make Model</b-button>
                              <span v-if="loading2"><img src="@/assets/images/loading.gif" style="width:25px;"/>3. junction of point</span>
                            </b-col>
                           </b-row>
                        </b-col>
                        <b-col sm="4">      
                            <b-button size="sm" class="mr-2 modal-content-button" @click="show_modeling()">Show Model</b-button>
                             
                        </b-col>
                    </b-row-->
                </b-tab>               
      </b-tabs>

      <b-row class="facility-info-footer-row m-0 mb-3">
        <b-col sm="6">
          <!--<b-button @click="$refs.modal.hide()">Close</b-button> -->
          <b-button @click="cancel1()" block class="facility-info-footer__button facility-info-footer__button-cancel">Close</b-button>
        </b-col>
        <b-col sm="6">
          <b-button @click="apply()" block class="facility-info-footer__button facility-info-footer__button-ok">Apply</b-button>
        </b-col>
      </b-row>
       <div>
       <DlgModeling ref="Modeling_info" />
      </div>
    </div>
</template>

<script>
import Compressor from 'compressorjs';
import DlgModeling from '@/components/popup/DlgModeling'
export default {
    name: 'FacilityInfoType',
    props: {
    },
    watch:{
    },
    data() {
        return {
            properties: null,
            properties1: [], //관리대장속성들 
            properties_bak: null,
            properties1_bak: [],//관리대장속성들 back
            loading : false, 
            loading1 : false,
            loading2 : false,        
            first_pipe : true,
            is_prv : false,           
            is_control_valve : false,
            is_air_valve : false,
            is_water_meter:false,            
            info_u_YN : true,
            geo_u_YN : false,
            ditem :[],
            columns: [
                {label: this.$t('facility_info.table_column.no'), field: 'id'},
                {label: this.$t('facility_info.table_column.layer'), field: 'layer'},
                {label: this.$t('facility_info.table_column.title'), field: 'title'},
                {label: this.$t('facility_info.table_column.s_time'), field: 'create_datetime'},
            ],
            table: [],
            layer_type_option : {},
            duplicate_id_list : [],
            layer_type_option1: [
                {value: 0, text: 'Pipe'},
                {value: 1, text: 'Valve'},
                {value: 2, text: 'Water Meter'},
                {value: 3, text: 'Pump'},
                {value: 4, text: 'Hydrant'},
                {value: 5, text: 'LAYER_JUNCTION'},
                {value: 6, text: 'Other'},
                {value: 7, text: 'Block'},
                {value: 8, text: 'Water Treatment Plant'},
                {value: 9, text: 'Pumping Station'},
                {value: 10, text: 'Water Basin'},
                {value: 11, text: 'Chamber'},
                {value: 12, text: 'Gauge'},
                {value: 13, text: 'Other'},
                /*
                const LAYER_BLOCK    = 7
                const LAYER_PIPE     = 0
                const LAYER_VALVE    = 1
                const LAYER_META     = 2
                const LAYER_PUMP     = 3
                const LAYER_HYDRANT  = 4
                const LAYER_JUNCTION = 5
                const LAYER_WATER_TREATMENT_PLANT = 8
                const LAYER_PUMPING_STATION = 9
                const LAYER_WATER_RESERVOIR = 10
                const LAYER_CHAMBER = 11
                const LAYER_GAUGE = 12
                const LAYER_OTHER = 13
                */
            ],
            f_type_option: [],
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            yn_option: [
            //    { value: null, text: 'Please select yes/no' },
                { value: 'Y', text: 'Yes' },
                { value: 'N', text: 'No' },
            ],
            valve_type_option: [ //감압밸브
             //   { value: null, text: 'Please select some valve_type' },
                { value: 'DIRECT', text: 'DIRECT' }, 
                { value: 'PILOT', text: 'PILOT' },
            ],   
            valve_type_option1: [     //이토밸브
           //     { value: null, text: 'Please select some valve_type' },                       
              { value: 'BV', text: 'butterfly' },
              { value: 'GV', text: 'gate' },
            ],   
             valve_type_option2: [   //공기밸브
           //     { value: null, text: 'Please select some valve_type' },             
                { value: 'Single', text: 'Single' }, 
                { value: 'Pair', text: 'Pair' },
            ],            
            check_type_option: [
          //      { value: null, text: 'select site/remote' },    
                { value: 'site', text: 'site' },
                { value: 'Remote', text: 'remote' },
            ], 
            move_type_option: [
              //  { value: null, text: 'select auto/manual' }, 
                { value: 'auto', text: 'automatic' },
                { value: 'manual', text: 'manual' },
            ],                        
            prv_type_option: [
            //    { value: null, text: 'select prv_type' }, 
                { value: 'SIMPLE', text: 'SIMPLE' },
                { value: 'TIME_CONTROL', text: 'TIME_CONTROL' },
                { value: 'FLOW_RATE', text: 'flow rate proportional control' }, 
                { value: 'PRESSURE', text: 'hydraulic linkage type' },
                { value: 'Other', text: 'Other'},  
            ],
            water_supply_option : [
              { value: 'direct', text: 'Direct' },   
              { value: 'pump', text: 'Pump' },
              { value: 'tank', text: 'Tank' },
              { value: 'other', text: 'etc' },             
            ],
            meter_status_option : [
              { value: 'malfuntion', text: 'Malfuntion' },   
            //  { value: 'no_rotation', text: 'needle not lotate' },
              { value: 'normal', text: 'normal' },
              { value: 'no_rotation', text: 'needle not lotate' },
              { value: 'broken', text: 'glass break' },
              { value: 'flooding', text: 'submerged' }, 
              { value: 'buried', text: 'buried' },
              { value: 'other', text: 'Other'},    
            ],   
             gauge_pos_option:[
            //  { value: null, text: 'select location' }, 
              { value: 'none', text: 'N/A' },
              { value: 'front', text: 'front' },
              { value: 'back', text: 'rear' },        
            ],         
            f_satus_level : true,
            material_option: [],
            img1:'',
            img2:'',
            img3:'',
            file1: null,
            b_show : false,
            vcount : 0,
            wcount : 0,
            vlength : 0,
            plength :0,
            img_list: [],
            id_disable : true,
            id_list_view:false,
            preview: null,
            image: null,
            exist_image : false,
            preview_list: [],
            image_list: [],
            listKey: 0,
            del_image_list : [],
            imgUrl:null,
        }
    },

    mounted () {
               this.layer_type_option = {};
               this.layer_type_option[0] = 'Pipe' ;
               this.layer_type_option[1] = 'Valve' ;                
               this.layer_type_option[2] =  'Water Meter' ;
               this.layer_type_option[3] = 'Pump' ;
               this.layer_type_option[4] =  'Hydrant';
               this.layer_type_option[5] =  'Junction';
               this.layer_type_option[6] =  'Other';
               this.layer_type_option[7] =  'Block';
               this.layer_type_option[8] =  'Water Treatment Plant';
               this.layer_type_option[9] =  'Pumping Station';
               this.layer_type_option[10] =  'Water Reservoir';
               this.layer_type_option[11] =  'Chamber';
               this.layer_type_option[12] =  'Gauge';
               this.layer_type_option[13] =  'Other';
    },
    methods: {
        make_modeling(seq){
             //block idx , idx 없시 insert 일시 geometry값을 가지고 그 사이에 있는 값을 junction, vertices, pipe 에 데이터를 만들어준다.
           let api_url = this.URL+'api/set_make_modeling?f_idx='+this.tag+"&seq="+seq +"&customer_id="+sessionStorage.select_customer_id;
           if(seq === 0) this.loading =true ;
           else if(seq === 1) this.loading1 =true ;
           else if(seq === 2) this.loading2 =true ;
           if(this.ditem.type==="add"){
               let geo = this.ditem.feature.geometry.coordinates[0]
               api_url = this.URL+'api/set_make_modeling?geo='+geo +"&seq="+seq +"&customer_id="+sessionStorage.select_customer_id;
           }
           this.$http.get(api_url).then((res) => {
                alert(res.data.meg)
                 if(seq === 0) {
                     this.loading =false ;
                     this.first_pipe = false;
                 }
                 else if(seq === 1) this.loading1 =false ;
                 else if(seq === 2) this.loading2 =false ;
            });
        },
        show_modeling(){
            this.$refs.Modeling_info.show(this.tag,this.ditem );  
           // this.$refs.Modeling_info.show(567208); 
        },
        switchSelect(event){
            var selected = event;
            this.is_prv = false;
            this.is_control_valve = false;
            this.is_air_valve = false;
            this.is_water_meter = false;
            if(selected === 'PRV'){
                this.is_prv = true;                     
            }else if(selected === 'Control'){   
                this.is_control_valve = true;  
            }else if(selected === 'Air'){
                this.is_air_valve = true;
            }           
        },
        switchSelect1(event){
            var selected = event;
            this.show(selected) ;
            
        },        
        del_image(i){
            document.getElementById("row_img"+i).style.display = "none";
            var file_id = this.img_list[i].split("/API21/FILE/")
            if(file_id.length > 0 ){
                this.del_image_list.push(file_id[1])
            }
        },
        addFiles(id){
            let formData = new FormData();
            formData.append("f_idx", id );
           // formData.append("customer_no", this.properties_bak["customer_no"] );
            formData.append("customer_no", sessionStorage.select_customer_no );
            formData.append("m_idx", sessionStorage.m_idx  );
            
            for( var i = 0; i < this.image_list.length; i++ ){
                let file = this.image_list[i];
               // let file = this.call_compress(this.image_list[i])
                formData.append('files[' + i + ']', file);
        
            }

             
            //customer_no, f_idx,m_idx, img_files
          //  this.$http.post(this.URL+'api/upload_shape?layer='+this.selected_layer+'&encoding='+this.encoding+'&tag_name='+this.tag_name, formData, {
              this.$http.post(this.URL+'api/API21/set_image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                console.log("success")
               if(res.data.check ===1){
                   console.log("")
                    alert("fail : "+ res.data.msg) ;
               }
                
            })
            .catch(() => {
                console.log('FAILURE!!');
               
            });
        },
        changeImage(){
            var params = {} ;
            var params1 = {} ;
            ////customer_no, f_idx,m_idx, img_files
            
            params["f_idx"] = this.properties_bak["id"] ;
            params["customer_no"] = this.properties_bak["customer_no"] ;
            params["m_idx"] = sessionStorage.m_idx ;
            params["del_image_list"] = this.del_image_list ;
            params1["json"] =  encodeURIComponent(JSON.stringify(params)) ;
            if(this.del_image_list.length > 0){
                //delete image api 호출 .
               
                this.$http.post(this.URL+'api/delete_image',params1 ).then((res) => {
                    console.log(res.data);
                    if(res.data.check ===1){
                       alert("fail : "+ res.data.msg) ;
                    }else{
                     //  alert("success : "+ res.data.msg) ; 
                      this.addFiles(this.properties_bak["id"] ) ;
                    }
                });
               
              /*
              this.$http.post(this.URL+'api/delete_image?json='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                    console.log(res.data);
                    if(res.data.check ===1){
                     alert("fail : "+ res.data.msg) ;
                    }else{

                      this.addFiles() ;
                    }
                }) 
                */
            }else{
                 this.addFiles(this.properties_bak["id"] ) ;
            }
           
        },
        imageSizeChange( image ) {
            this.imgUrl = null;
            let canvas = document.createElement("canvas"),
                max_size = 512,
                // 최대 기준을 1280으로 잡음.
                width = image.width,
                height = image.height;
            if (width > height) {
                // 가로가 길 경우
                if (width > max_size) {
                height *= max_size / width;
                width = max_size;
                }
            } else {
                // 세로가 길 경우
                if (height > max_size) {
                width *= max_size / height;
                height = max_size;
                }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            this.imgUrl = canvas.toDataURL("image/jpeg", 0.5);
           // const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
          //  return dataURItoBlob(dataUrl);
        },        
        previewMultiImage(event) {
            var input = event.target;
            var count = input.files.length;
            var index = 0;
            var self = this;
            if (input.files) {
                while(count --) {
                    if (!/^image\//.test(input.files[index].type)) {
                        alert('Only image files can be registered.')
                        return false
                    }
                    var reader = new FileReader();
                    reader.onload = (e) => {
                      //  this.preview_list.push(e.target.result);
                      
                       const image = new Image();
                       image.src = e.target.result;
                       image.onload = function () {
                            // 이미지가 로드가 되면! 리사이즈 함수가 실행되도록 합니다.
                            self.imageSizeChange(image);
                           /*
                            const $canvas = document.createElement(`canvas`);
                            const ctx = $canvas.getContext(`2d`);
                        
                            $canvas.width = e.target.width;
                            $canvas.height = e.target.height;
                            
                            ctx.drawImage(e.target, 0, 0);

                            // 용량이 줄어든 base64 이미지
                            console.log($canvas.toDataURL(`image/jpeg`, 0.5));
                            */
                           self.preview_list.push(self.imgUrl)                           
                           //this.image_list.push();
                        };
                       
                        //this.preview_list.push(image.src)
                    }
                   // this.image_list.push(input.files[index]);
                   // this.image_list.push( this.call_compress(input.files[index]));
                    this.call_compress(input.files[index]) ;
                    reader.readAsDataURL(input.files[index]);
                   
                   // reader.readAsDataURL(input.files[index]);
                    index ++;
                }
            }
             ++this.listKey;
        },
        async call_compress(files){
           // var new_file = null
             var self = this;
            const options = {
                    maxWidth: 700,
                    maxHeight: 700,
                    success: function (result) {
                        if (result.size > 5*1024*1024) { // 리사이징 했는데도 용량이 큰 경우
                            alert('파일 용량이 초과되어 업로드가 불가 합니다.');
                            return;
                        }
                         console.log('Output: ', result);
                        // return new File([result], result.name, { type: result.type });
                        self.image_list.push( new File([result], result.name, { type: result.type }));
                        //++self.listKey;
                      //  return result;
                      //  var newImage = new Image(); 
                     //   const _URL = window.URL || window.webkitURL;
                      //  if (_URL) {
                      //      newImage.src = _URL.createObjectURL(result);
                      //  }
                    },
                    error: function (err) {
                        console.log(err);
                    }
                };
               await new Compressor(files, options);
               //return new_file;
        },
        reset() {
            this.image = null;
            this.preview = null;
            this.image_list = [];
            this.preview_list = [];
            ++this.listKey;
        },
        show(idx) {
            // if(localStorage === null || typeof localStorage === undefined){
            let cur_page = ""+document.location.href ;                
           // if(cur_page.indexOf("id.neverlosewater.com") !== -1 || cur_page.indexOf("localhost:8080") !== -1){
                if(cur_page.indexOf("id.neverlosewater.com") !== -1 ){
            // if(cur_page.indexOf("localhost") !== -1){         
                this.duplicate_id_list = [];
                this.$http.get(this.URL+'api/duplicate_geometry?c_idx=3&f_idx='+idx).then((res) => {     
                    if(res.data.list.length<=0) return
                    for(let i=0; i<res.data.list.length; i++) {
                        var abox = { value:res.data.list[i].id, text: res.data.list[i].id +":"+ res.data.list[i].name } ;
                        this.duplicate_id_list.push(abox) ;
                    }
                    if(res.data.list.length > 1){
                        this.id_disable = false;
                        
                    }else{
                        this.id_disable = true; 
                    }
                  //  this.id_list_view = true;
                    
                });
            
            }              
            
            
            this.properties_bak = []
            if(sessionStorage === null || typeof sessionStorage === undefined){
                return
        //   }else if(localStorage.level === 'A'){
            }else if(sessionStorage.level < 2){
                this.f_satus_level = false;
            }
            this.tag = idx;
            this.img_list =[];
            this.del_image_list = [];
            this.preview_list = [];
            this.image_list = [];
            //
            this.info_u_YN =  true ;            
            this.geo_u_YN = false;
            this.listKey =0 ;
            var layer_type = "" ;
            this.is_prv = false;
            this.is_control_valve = false;
            this.is_air_valve = false;
            this.is_water_meter = false;
            this.$http.get(this.URL+'api/get_geometry?f_idx='+idx).then((res) => {
                if(res.data.features.length<=0) return
                let ltype = res.data.features[0].properties['gis_layer_id']
                if(ltype===0) {
                   // this.f_type_option = ['Service','Supply','Drain','Other']
                   // this.material_option = ['DCIP', 'Steel', 'PVC', 'PE', 'HI-3P', 'Other']
                    this.f_type_option = ['Service','Supply','Distribution','Other','Conveyance']
                    this.material_option = ['DCIP','DI','CI', 'Steel', 'PVC', 'HDPE','PE', 'HI-3P', 'AC','Other']
                }
                if(ltype===1){
                    this.f_type_option = ['Control','PRV','Drain','Boundary','Relief','Air','Interwall','Other']     
                    var f_type = res.data.features[0].properties['type']
                  
                    layer_type = 'valve'
                    if(sessionStorage.collector==='true'){
                        if(f_type === 'PRV'){
                            this.is_prv = true;                     
                        }else if(f_type === 'Control'){   
                        this.is_control_valve = true;  
                        }else if(f_type === 'Air'){
                            this.is_air_valve = true;
                        }
                    }

                }
                if((cur_page.indexOf("id.neverlosewater.com") !== -1 || cur_page.indexOf("localhost:8080") !== -1)& ltype===2){
                    this.id_list_view = true; 
                }else{
                   this.id_list_view = false;  
                }
                if(ltype===2){
                    this.f_type_option = ['Mechanical','Digital','Other']                  
                    if(sessionStorage.collector==='true'){
                        this.is_water_meter = true;
                    }
                    layer_type = 'meter'
                }
                if(ltype===7) this.f_type_option = ['Big','Middle','Small','Sub']

                let date = new Date(res.data.features[0].properties['register_datetime'])
                res.data.features[0].properties['register_datetime'] = date.toISOString().slice(0,10)
                this.properties = res.data.features[0].properties
               /* this.img1 = this.URL+'api/'+this.properties['img_file1'];
                this.img2 = this.URL+'api/'+this.properties['img_file2'];
                this.img3 = this.URL+'api/'+this.properties['img_file3'];*/
                
                 if(this.properties.image_list === null || this.properties.image_list  === undefined){
                    this.exist_image = false; 
                    this.img_list.push(require('@/assets/noimage1.png')) 
                    //this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
                    //this.img_list.push(require('@/assets/noimage1.png'));
                    //this.img_list.push(require('@/assets/noimage1.png'));
                  
                }else{
                  this.exist_image = true;
                  var img = this.properties.image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
                }
                this.properties_bak = Object.assign({}, this.properties)
                this.$http.get(this.URL+'api/get_facility_work_list?f_idx='+idx).then((res) => {
                    this.table = res.data.list
                })
               //관리대장 데이터를 가져온다.
                if(sessionStorage.collector==='true' && (ltype == 1 || ltype == 2 ) ){               
                    this.$http.get(this.URL+'api/den/facility?facility_id='+idx+'&type='+layer_type).then((res) => {
                        if(res.data.result.length >0){
                            this.properties1 = res.data.result[0] ;
                            this.properties1_bak =  Object.assign({}, this.properties1) ;

                        }else{
                            this.properties1_bak = [];
                            this.properties1 = []
                        }  
                    })
                }
                this.b_show = false;
                if(ltype === 7){
                    this.b_show = true;
                    this.vlength = 0 ; this.plength = 0 ;this.vcount =0;this.wcount=0;               
                    this.$http.get(this.URL+'api/get_geometry_count?f_idx='+idx+"&customer_id="+sessionStorage.select_customer_id ).then((res) => {
                        this.plength  = res.data.rows[0].sp_length; 
                        this.vlength  = res.data.rows[0].sv_length;         
                        this.vcount  = res.data.rows[0].v_count;     
                        this.wcount  = res.data.rows[0].w_count;    
                                                                  
                    })
             

                }
               // this.$refs.modal.show();
            })
        },
        insert_show(item) {
            this.img_list =[];
            this.del_image_list = [];
            this.preview_list = [];
             this.image_list = [];
            this.f_satus_level = true;
            this.properties = {} ;
            let ltype = item.code;
            this.geo_u_YN = true;
            this.ditem = null;
            let c_url = null; // geometry_count url
            this.is_prv = false;
            this.is_control_valve = false;
            this.is_air_valve = false;
            this.is_water_meter = false;
            if(item.features=== null) return

            if(ltype===0) {
                this.f_type_option = ['Service','Supply','Distribution','Other','Conveyance']
                this.material_option = ['DCIP','DI','CI', 'Steel', 'PVC', 'HDPE','PE', 'HI-3P', 'AC','Other']
                this.properties['length'] = item.length;
            }
            if(ltype===1){
                this.f_type_option = ['Control','PRV','Drain','Boundary','Relief','Air','Interwall','Other']              
            }
            if(ltype===2){
                this.f_type_option = ['Mechanical','Digital','Other']
                if(sessionStorage.collector==='true'){
                            this.is_water_meter = true;
                }
            }
            if(ltype===7) this.f_type_option = ['Big','Middle','Small','Sub']


            if(item.type==="add"){ //추가
                this.info_u_YN = false ;
                
                this.properties['type'] = item.f_type;
                //this.properties['name'] = this.layer_type_option[ltype].text+"_"
                this.properties['name'] = this.layer_type_option[ltype] ;
                this.properties['customer_id'] = sessionStorage.select_customer_id;

                
                this.properties['customer_member_id'] = sessionStorage.m_idx;
                if(ltype===1 ){
                    this.properties['type']  = 'Other' ;
                    if(sessionStorage.collector==='true'){
                         this.properties['type'] = 'Control'
                         this.is_control_valve = true;
                    }
                }
                if(ltype===2 )
                    this.properties['type']  = 'Other' ;

                this.properties['gis_layer_id'] = ltype ;

                let date = new Date();
                let yy = date.getFullYear() ;
                this.properties['register_datetime'] = yy +"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0"+date.getDate()).slice(-2) ;
                           //시설정보 테이블에 데이터
                let geo = item.feature.geometry.coordinates[0]
                c_url = this.URL+'api/get_geometry_count?geo='+geo+"&customer_id="+sessionStorage.select_customer_id;
                this.properties1 =[];
                this.properties1_bak =[];

            }else{ //수정

                var idx = item.feature.properties.idx ;
                c_url = this.URL+'api/get_geometry_count?f_idx='+idx+"&customer_id="+sessionStorage.select_customer_id ;
                this.$http.get(this.URL+'api/get_geometry?f_idx='+idx).then((res) => {
                    if(res.data.features.length<=0) return

                    let date = new Date(res.data.features[0].properties['register_datetime'])
                    res.data.features[0].properties['register_datetime'] = date.toISOString().slice(0,10)
                    this.properties = res.data.features[0].properties
                    this.properties_bak = Object.assign({}, this.properties)
                    this.$http.get(this.URL+'api/get_facility_work_list?f_idx='+idx).then((res) => {
                        this.table = res.data.list
                    })
                })
            }
              this.b_show = false;
                if(ltype === 7){
                    this.b_show = true;
                    this.vlength = 0 ; this.plength = 0 ;this.vcount =0;this.wcount=0;               
                    this.$http.get(c_url).then((res) => {
                        this.plength  = res.data.rows[0].sp_length; 
                        this.vlength  = res.data.rows[0].sv_length;         
                        this.vcount  = res.data.rows[0].v_count;     
                        this.wcount  = res.data.rows[0].w_count;   
                    })
             

                }

            //데이터 변환한다.
            //this.change_xy(item);
            this.ditem = item;
           // this.$refs.modal.show();
        },
        apply() {
            //this.$refs.modal.hide()
        //   if(localStorage === null || typeof localStorage === undefined){
             if(sessionStorage === null || typeof sessionStorage === undefined){
                alert("Can not be modified.")
                return
        //    }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 1){
              //  alert("You do not have permission to edit.")
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
            let params = {} ;              
            let layer_type = '';
            if(this.properties['gis_layer_id'] === 1){ //valve
               layer_type = 'valve'
            }else if(this.properties['gis_layer_id'] === 2){ 
               layer_type = 'meter'     
            }
            
            if(this.info_u_YN){ //upate
                let keys = Object.keys(this.properties_bak)
                for(let i=0; i<keys.length; i++) {
                  if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                  params[keys[i]] = this.properties[keys[i]]

                }
                
                this.ditem['params'] = params ;

                if(this.geo_u_YN){//지도수정
                   this.$http.get(this.URL+'api/add_modify_feature?json='+encodeURIComponent(JSON.stringify(this.ditem))).then((res) => {
                        console.log(res.data);
                   })
                   this.$emit('facility_refresh',0) ;
                 }else{
                     if(Object.keys(params).length>0 || this.del_image_list.length > 0 || this.image_list.length > 0){
                       params['customer_member_id'] = sessionStorage.m_idx;
                       if(this.properties.image_list !== null ){
                            var img = this.properties.image_list.split(',')
                            if(this.del_image_list.length === img.length){
                                params["image_file_id"] = null;
                            }
                        }
                        if(Object.keys(params).length>0  ) {
                            this.$http.get(this.URL+'api/update_geometry?idx='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                                console.log(res.data);
                          
                            })
                        }

                        if(this.del_image_list.length > 0 || this.image_list.length > 0){
                           this.changeImage();
                        
                        }
                        //관리대장 수정
                       
                        this.den_facility_add(this.properties['id'],layer_type);
                        this.properties = null;
                        this.$emit('facility_refresh',1) ;
   
                     }else{ //바뀐것이 없으면 return
                        this.den_facility_add(this.properties['id'],layer_type);                      
                       //  return;
                     }
                 }

            }else{ //신규추가사항
                let keys = Object.keys(this.properties);
                for(let k=0 ; k < keys.length ; ++k) {
                    if(  this.properties[keys[k]]  !== null && this.properties[keys[k]].value !== '')  params[keys[k]] = this.properties[keys[k]] ;
                }

                this.ditem['params'] = params ;
                 this.$http.get(this.URL+'api/add_modify_feature?json='+encodeURIComponent(JSON.stringify(this.ditem))).then((res) => {
                    console.log(res.data);
                    //이미지 insert
                    var f_idx = res.data.idx 
                    if(this.image_list.length > 0){
                        this.addFiles(f_idx);
                    }
                   
                    //관리대장 등록
                    this.den_facility_add(f_idx,layer_type);
                })
            
          
                this.$emit('facility_refresh',0) ;
                this.properties = null;
            }
           

/*
            let cur_page = ""+document.location.href ;
            if(cur_page.indexOf("gis-master") !== -1) {
                this.$emit('facility_refresh',1) ;
            }
*/
           
           // this.$refs.modal.hide();
       },
       //관리대장 insert
       den_facility_add(facility_id, layer_type){
            //관리대장 insert기능
            let params = {} ;
            let keys = [] ;
          
            if(this.properties1_bak.length === 0){ //신규인 경우
                keys = Object.keys(this.properties1);
                for(let k=0 ; k < keys.length ; ++k) {
                    if(this.properties1[keys[k]]  !== null && this.properties1[keys[k]].value !== '')  params[keys[k]] = this.properties1[keys[k]] ;
                } 
                if(Object.keys(params).length>0  ) {            
                    params["facility_id"] = facility_id             
                    //  data = {type: 'add', params:params} ;
                    this.$http.post(this.URL+'api/den/'+ layer_type ,params).then((res) => {
                        console.log(res.data);
                        if(res.data.check ===1){
                            alert("fail : "+ res.data.msg) ;
                        }
                    })
                    this.properties = null;                        
                }                   
            }else{ //update인경우
                keys = Object.keys(this.properties1);                
                for(let i=0; i<keys.length; i++) {
                  if(this.properties1_bak[keys[i]]===this.properties1[keys[i]]) continue
                  params[keys[i]] = this.properties1[keys[i]]
                }
                     //관리대장 수정
                if(Object.keys(params).length>0  ) {            
                    params["facility_id"] = facility_id             
                    //  data = {type: 'add', params:params} ;
                    this.$http.patch(this.URL+'api/den/'+ layer_type ,params).then((res) => {
                        console.log(res.data);
                        if(res.data.check ===1){
                            alert("fail : "+ res.data.msg) ;
                        }
                    }) 
                    this.properties = null;                        
                }              
            }    
       
       },
       set_image(){
           alert("setting image")
       },
       cancel1(){
        //home =>gis_reload만
            if(this.geo_u_YN) {
                this.$emit('facility_refresh',2) ; //수정분 날려보낸다.
            }
            console.log(this.$refs.modal)
           // this.$refs.modal.hide();
            this.properties = null;
       }
    },
    components: {
      DlgModeling:DlgModeling,
    }
}
</script>

<style lang="scss">
  .facility-info {
    position: fixed;
    right: 0;
    top: 0;
    margin: 70px auto 0;
    background: #172A46;
    width: 455px;
    height: calc(100% - 70px);
    z-index: 111;
    display: flex;
    flex-direction: column;
    border: 1px solid #707070;
    &__title {
      margin: 0;
      font-size: 18px;
      color: #fff;
      text-align: center;
      display: block;
      width: 100%;
      padding: 28px 0 22px 0;
      background: #172A46;
      font-family: 'Roboto', 'Noto Sans Korean', sans-serif;
    }
    .tabs {
      height: 85%;
    }
    .nav-tabs {
      background: #172A46;
      height: 70px;
    }
    .nav-tabs .nav-link {
      border: 0;
      padding: 8px;
      float: left;
      font-size: 14px;
    //   color: #080F15;
      cursor: pointer;
      letter-spacing: 0.5px;
      border-radius: 0;
    //   2022 new
      color: #fff;
      border: 1px solid #707070;
      transition: all 0.3s ease 0.4s;
    }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        // 2022 new
    //   background: #fff;
    //   color: #28B7CB;
    color: #fff;
    background: #28AF81;
    border: 1px solid #707070;
    }
  .facility-info-content {
      &.tab-content {
        background: #172A46;
        &.is--scroll {
          height: 90%;
          padding: 36px 28px 0 28px;
          overflow-y: auto;
          @media screen and (max-height: 930px){
            height: 68vh;
          }
        }
      }
      .facility__label {
        color: #fff;
        font-size: 14px;
        display: inline-block;
        letter-spacing: 0.5px;
        //width: 35%;
      }
      .facility__input {
        //width: 60%;
        height: 32px;
        font-size: 14px;
        border: 1px solid #707070;
        box-sizing: border-box;
        border-radius: 0;
        margin-top: 0;
        margin-bottom: 14px;
        background: #0A192F;
        &.height--auto {
          height: auto;
        }
      }
    }
    .facility-info-footer {
      background: #172A46;
      padding-bottom: 18px;
      padding-top: 3px;
    }
    .facility-info-footer-row {
      width: 100%;
      margin: 0;
      padding-bottom: 0;
    }
    .btn.facility-info-footer__button {
      height: 52px;
      font-size: 14px;
      border: 0;
      &.facility-info-footer__button-ok {
        // 2022 new
        // background: #28B7CB;
        background: #28AF81;
      }
      &.facility-info-footer__button-cancel {
        background: #9D9D9D;
      }
    }
    .photo-card {
      border-radius: 0;
      border: 0;
      .img-thumbnail {
        margin-bottom: 10px;
      }
      .card-header {
        height: 150px;
        background: #172A46;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .card-header__text {
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
  .shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(0,0,0,.1)!important;
    box-shadow: 0 1rem 3rem rgba(0,0,0,1)!important;
}
</style>

<template>
  <div class="content-body">
    <!-- <h4 class="content-title">{{ $t('leak_recovery.page_title') }}</h4> -->
     <li class="setting-btn" style="float:right; margin: -50px 10px 0 0;">
                                <!--b-button size="sm" class="float-right" @click="show_setting">
                                    <i class="fas fa-cog"></i>
                                </b-button-->          
                 <b-button  class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="add_work" v-if="!(measure_mode === '' && w_type === 110)">Add Work</b-button>
             </li>
    <div class="content-header" style="line-height: 40px;">
      <div class="d-flex">
        <label style=" line-height: 40px;">
          <input class="ns-checkbox" type="checkbox" @click="SelectAllBox" v-model="allSelected" :disabled="(measure_mode === '' && w_type === 110) ">
          <!--          <span class="title"> {{ $t('water_leak.left_menu.select_all') }}</span>-->
        </label>
        <div class="d-flex">
          <div>
            <b-button @click="go_detail" class="btn-grayLine">
              {{ $t('button.go_detail') }}
            </b-button>
          </div>
          <div v-if = "isPressicon">
            <input type="radio" id="radio_00" name="radio00" v-model="measure_mode" value="">
            <label for="radio_00" class="btn-grayLine">All_Pressure</label>
          </div>
          <div v-if = "isPressicon">
            <input type="radio" id="radio_01" name="radio01" v-model="measure_mode" value="1">
            <label for="radio_01" class="btn-grayLine">{{ $t('pressure.title') }}</label>
          </div>
          <div v-if = "isPressicon">
            <input type="radio" id="radio_02" name="radio01" checked v-model="measure_mode" value="2">
            <label for="radio_02" class="btn-grayLine">{{ $t('pressure.hammer') }}</label>
          </div>
        <div class="item-total">
         <span>  Total :</span>
         <strong> {{ tot_block_length }}</strong>
        </div> 
        </div>
      </div>
       
      <div class="ns-search-box">
     
        <input type="text" class="ns-search-input" v-model="value" :placeholder="$t('water_leak.left_menu.search')"
               @keyup.enter="Enter_Check">
        <button type="button" class="ns-search-button" @click="search"/>
      </div>
    </div>
<!----------------- 2023 start -------------->
<ul class="ns-list ns-list-head" >
      <li>
        <div class="ns-items " style="background: #3b4d7c; ">
          <div>
            <label><input class="ns-checkbox" type="checkbox" v-if="!(measure_mode === '' && w_type === 110)" ></label>
          </div>
          <div class="title " style="width:20%;">
            <span>Title</span>
          </div>
          <div class="" style="width:15%;text-align: center;">
            <span>Start Time</span>
          </div>
          <div class="" style="width:15%;text-align: center;">
            <span>End Time</span>
          </div>
          <div class="" style="width:8%;text-align: center;">
            <span>Saved Time(hour)</span>
          </div>
          <div class="" style="width:8%;text-align: center;">
            <span>Time Interval(sec)</span>
          </div>

          <div class="center " style="width:8%;text-align: center;">
            <span>Total</span>
          </div>

          <div class="center " style="width:60px;padding: 0 0px 0 8px;text-align: center;">
            <span>Max</span>
          </div>
          <div class="center " style="width:60px;padding: 0;text-align: center;">
            <span> Min</span>
          </div>
          <div class="center " style="width:60px;padding: 0 16px 0 0px;text-align: center;">
            <span> Avg</span>
          </div>

          <div>
            <span> </span>
          </div>
        </div>
      </li>
    </ul> 

    <ul class="ns-list" style="width:100%;">
      <li :class="['box_list', { 'is--active': activeCheckIndex === index }, { 'with--bottom-index' : isBottomIndex},, { 'press_icon' : isPressicon},{ 'flow_icon' : isFlowicon}]"
          v-for="(item, index) in box" :key="index" style="width:100%; position: relative;">
        <div class="ns-items d-flex" @click="selectBox(index)">
          <div style="width:3%;">
            <label><input class="ns-checkbox" type="checkbox" :id="item.idx" :value="item.idx"  v-if="!(measure_mode === '' && w_type === 110) "
                          v-model=" leak_check"></label>
          </div>
          <div class="title " style="width:20%;">
            <span>
              <b-img :src="item.icon_img " alt="image" v-if="item.isMode"
                     style=" width: 15px;  position: absolute;  left: 55px; top: 25px; "></b-img>
            <span class="num bottom" v-if="isBottomIndex">{{ item.idx }}</span>
            </span>
            <p>
              <!-- work 별 이미지 추가 -->
              <span class="num" v-if="!isBottomIndex">{{ item.idx }}</span> {{ item.title }}
            </p>
          </div>
          <div class="" style="width:15%;text-align: center;">
            <p>{{ item.time }}</p>
          </div>
          <div class="" style="width:15%;text-align: center;">
            <p>{{ item.close_time }}</p>
          </div>
          <div class="" style="width:8%;text-align: center;">
            <p>{{ item.measure_time }}</p>
          </div>
          <div class="" style="width:8%;text-align: center;">
            <p>{{ item.mea_interval }}</p>
          </div>

          <div class="center " style="width:8%;text-align: center;">
            <p style="font-size: 16pt; font-weight: 600;">{{ item.number }}</p>
          </div>

          <div class="center " style="padding: 0 0px 0 8px;width:60px;">
            <p class="value bg-color-max">{{ item.max }}</p>
          </div>
          <div class="center " style="padding: 0;width:60px;">
            <p class="value bg-color-min">{{ item.min }}</p>
          </div>
          <div class="center " style="padding: 0 16px 0 0px;width:60px;">
            <p class="value bg-color-avg">{{ item.avg }}</p>
          </div>

          <div>
            <span> </span>
          </div>
          <div style="position: absolute;right:6em">
            <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="work_edit(item.idx)"
                 style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
          </div>
          <div style="position: absolute;right:3em">
            <img src="@/assets/images/new/ic_menu_chevron.svg" alt="" @click="$emit('go_detail',  item.idx)"
                 style="width: 8px; cursor: pointer">
          </div>
        </div>


        <!--div style="position:absolute; right : 5px; bottom: 0px; padding:5px; z-index:202;" -->

        <!--/div -->
      </li>

      <!--div class="submit_box">
        <input type="submit" :value="$t('water_leak.left_menu.detail')" class="submit_btn" @click="go_detail" >
      </div-->
    </ul>

    <!-- <ul class="ns-list" style="width:100%;">
      <li :class="['box_list', { 'is--active': activeCheckIndex === index }, { 'with--bottom-index' : isBottomIndex},, { 'press_icon' : isPressicon},{ 'flow_icon' : isFlowicon}]"
          v-for="(item, index) in box" :key="index" style="width:100%; position: relative;">
        <div class="ns-items d-flex" @click="selectBox(index)">
          <div>
            <label><input class="ns-checkbox" type="checkbox" :id="item.idx" :value="item.idx"
                          v-model=" leak_check"></label>
          </div>
          <div class="title " style="width:18%;">
            <span>
              <b-img :src="item.icon_img " alt="image" v-if="item.isMode"
                     style=" width: 15px;  position: absolute;  left: 55px; top: 25px; "></b-img>
            <span class="num bottom" v-if="isBottomIndex">{{ item.idx }}</span>
            </span>
            <p>
              <span class="num" v-if="!isBottomIndex">{{ item.idx }}</span> {{ item.title }}
            </p>
          </div>
          <div class="" style="width:10%;text-align: center;">
            <span>Start Time</span>
            <p>{{ item.time }}</p>
          </div>
          <div class="" style="width:120%;text-align: center;">
            <span>End Time</span>
            <p>{{ item.close_time }}</p>
          </div>
          <div class="" style="width:10%;text-align: center;">
            <span>Saved Time(hour)</span>
            <p>{{ item.measure_time }}</p>
          </div>
          <div class="" style="width:10%;text-align: center;">
            <span>Time Interval(sec)</span>
            <p>{{ item.mea_interval }}</p>
          </div>

          <div class="center " style="width:12%;text-align: center;">
            <span>Total</span>
            <p style="font-size: 18px; font-weight: 600;">{{ item.number }}</p>
          </div>

          <div class="center " style="padding: 0 0px 0 8px;">
            <span>Max</span>
            <p class="value bg-color-max">{{ item.max }}</p>
          </div>
          <div class="center " style="padding: 0">
            <span>Min</span>
            <p class="value bg-color-min">{{ item.min }}</p>
          </div>
          <div class="center " style="padding: 0 16px 0 0px;">
            <span>Avg</span>
            <p class="value bg-color-avg">{{ item.avg }}</p>
          </div>

          <div>
            <span> </span>
            <p class="value">{{ unit }}</p>
          </div>
          <div style="position: absolute;right:6em">
            <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="work_edit(item.idx)"
                 style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
          </div>
          <div style="position: absolute;right:3em">
            <img src="@/assets/images/new/ic_menu_chevron.svg" alt="" @click="$emit('go_detail',  item.idx)"
                 style="width: 8px; cursor: pointer">
          </div>
        </div>
      </li>
    </ul> -->
<!----------------- 2023 end -------------->
    <DlgWorkInfo ref="work_info" @worklist_refresh="worklist_refresh"/>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" force-use-infinite-wrapper="true">
      <div slot="no-more" style="color: rgb(102, 102, 102); font-size: 14px; padding: 10px 0px;">
        {{ $t('water_leak.left_menu.no_data') }}
      </div>
    </infinite-loading>
  </div>
</template>

<script>
//import { BIconCheckSquare } from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'
import DlgWorkInfo from '@/components/popup/DlgWorkInfo'

export default {
  name: 'SearchLeftType1',
  watch: {
    measure_mode () {
      this.search()

    },

  },
  data () {
    return {
      leak_check: [],
      value: '',
      allSelected: false,
      api_url: '',
      box: [],
      page: 1,
      infiniteId: +new Date(),
      levelA: false,
      icon_imag: '',
      isPressicon: false,
      isFlowicon: false,
      measure_mode: '', //일반압력 2:수충압
      tot_block_length : 0,
      type_110: false,
      unit: 'bar'
      //    w_type : 100,
    }
  },
  components: {
    //BIconCheckSquare
    DlgWorkInfo: DlgWorkInfo,
    InfiniteLoading,
  },
  props: {
    activeCheckIndex: {
      type: null
    },
    w_type: {
      type: Number
    },
    //  box: {
    //    type: Array
    //  },
    isBottomIndex: {
      type: Boolean,
      default: false
    },

  },
  methods: {
    selectBox (index) {
      this.$emit('updateCheckIndex', index, this.box[index])
    },
    go_detail () {
      var leak_arry = this.leak_check
      //  if(leak_arry.length > 0 && this.w_type === 110){
      if (leak_arry.length > 0) {
        //alert(this.$t('water_leak.left_menu.alt_110')) ;
        this.$emit('go_detail', leak_arry)
      } else {
        alert(this.$t('water_leak.left_menu.alert_select_list'))
      }
    },
    add_work(){
      this.$refs.work_info.insert_show(this.w_type,this.measure_mode)
    },
    work_edit (idx) {
      this.$refs.work_info.show(idx, this.w_type)
    },
    worklist_refresh () {
      this.search()
    },
    infiniteHandler ($state) {
      var customer_id = sessionStorage.select_customer_id
      if (sessionStorage.select_customer_id === '27') {
        if (this.measure_mode === '1') //일반압력
          customer_id = 20 //양양
        else if (this.measure_mode === '2')
          customer_id = 10 // 수충압, 김제
      }
       if (this.w_type === 110) {
           this.isPressicon = true
       }

      // this.$http.get(this.URL+'api/get_work_list_ex?w_type='+this.w_type+'&search_term='+this.value+'&per_page=10&page='+this.page+'&customer_id='+sessionStorage.select_customer_id+'&measure_mode='+this.measure_mode).then((rows) => {        
      this.$http.get(this.URL + 'api/get_work_list_ex?w_type=' + this.w_type + '&search_term=' + this.value + '&per_page=10&page=' + this.page + '&customer_id=' + customer_id + '&measure_mode=' + this.measure_mode).then((rows) => {
        if (rows.data.check === 1) $state.complete()
        if (rows.data.rows.length > 0) {
          this.page += 1
          //this.list.push(...data.hits);
          for (let i = 0; i < rows.data.rows.length; i++) {
            if (i == 0 || this.page === 2) {
              this.t_title = rows.data.rows[i].title
              this.t_worker = rows.data.rows[i].worker
              this.t_start_time = rows.data.rows[i].create_datetime
              this.t_close_time = rows.data.rows[i].last_work_datetime
            }
            var abox = {}
            this.type_110 = false
            if (this.w_type === 100 || this.w_type === 101) {
              abox = {
                'title': rows.data.rows[i].title,
                'idx': rows.data.rows[i].id,
                'time': rows.data.rows[i].create_datetime,
                'number': rows.data.rows[i].leak_count,
                'close_time': rows.data.rows[i].last_work_datetime,
                'worker': rows.data.rows[i].worker,
                'w_title': rows.data.rows[i].title,
                'max': rows.data.rows[i].max,
                'avg': rows.data.rows[i].avg,
                'min': rows.data.rows[i].min
              }
            } else if (this.w_type === 110) {
              this.unit = 'bar'
              var icon_img = ''
              this.isPressicon = true
              this.isFlowicon = false
              var isMode = false
              this.type_110 = true
              if (rows.data.rows[i].measure_mode === 1) { //일반압력
                icon_img = require('@/assets/images/sub/web_4/pressure.png')
                isMode = true
              } else if (rows.data.rows[i].measure_mode === 2) { //수충압
                icon_img = require('@/assets/images/sub/web_4/hammer.png')
                isMode = true
              }
              abox = {
                'title': rows.data.rows[i].title,
                'idx': rows.data.rows[i].id,
                'time': rows.data.rows[i].start_datetime,
                'number': rows.data.rows[i].press_count,
                'close_time': rows.data.rows[i].end_datetime,
                'worker': rows.data.rows[i].worker,
                'w_title': rows.data.rows[i].title,
                'measure_mode': rows.data.rows[i].measure_mode,
                'max': rows.data.rows[i].max,
                'avg': rows.data.rows[i].avg,
                'min': rows.data.rows[i].min,
                'mea_interval': rows.data.rows[i].measure_interval,
                'measure_time': rows.data.rows[i].measure_time,
                'isMode': isMode,
                'icon_img': icon_img
              }

            } else if (this.w_type === 160 || this.w_type === 104) {
              var icon_img1 = require('@/assets/images/sub/web_4/icon_flow.jpg')
              if (this.w_type === 104) {
                icon_img1 = require('@/assets/images/sub/web_4/leak_io.png')
                this.unit = 'Strength'
              } else {
                this.isFlowicon = true
                this.unit = 'm³/h'
              }
              this.isPressicon = false
              this.isFlowicon = true
              var isMode1 = false

              abox = {
                'title': rows.data.rows[i].title,
                'idx': rows.data.rows[i].id,
                'time': rows.data.rows[i].start_datetime,
                'number': rows.data.rows[i].cnt,
                'close_time': rows.data.rows[i].end_datetime,
                'worker': rows.data.rows[i].worker,
                'w_title': rows.data.rows[i].title,
                'measure_mode': '3',
                'max': rows.data.rows[i].max,
                'avg': rows.data.rows[i].avg,
                'min': rows.data.rows[i].min,
                'mea_interval': rows.data.rows[i].measure_interval,
                'measure_time': rows.data.rows[i].measure_time,
                'isMode': isMode1,
                'icon_img': icon_img1
              }

            }
            this.box.push(abox)
          }
          $state.loaded()
        } else {
          $state.complete()
          this.tot_block_length =  this.box.length
        }
      })
    },
    SelectDelete () {

    },
    SelectAllBox () {

      // this.isChecked = true;
      var selected = []

      if (!this.allSelected) {
        //   this.box.forEach(function (item) {
        for (let i = 0; i < this.box.length; i++) {
          selected.push(this.box[i].idx)
          if (i === 19) {
            alert(this.$t('water_leak.left_menu.alt_more_20'))
            break
          }
          //   });
        }
      }
      this.leak_check = selected

    },
    Enter_Check () {
      //  if(event.keyCode == 13){
      //  this.$emit('go_search',  this.value) ;  // 실행할 이벤트
      this.search()
    },
    search () {
      this.page = 1
      this.box = []
      this.infiniteId += 1
    }

  },
  mounted () {
    setTimeout(() => {
      if (this.box.length > 0)
        this.$emit('updateCheckIndex', 0, this.box[0])
    }, 500)

    //삭제버튼 활성화 여부
    // if(localStorage !== null && typeof (localStorage) !== undefined && localStorage.level === 'A'){
    if (sessionStorage !== null && typeof (sessionStorage) !== undefined && sessionStorage.level < 2) {
      this.levelA = true
    } else {
      this.levelA = false
    }

  }
}

</script>

<style>
.left_area .left_list_box .input_search_box.with--all-button .btn {
  left: 0px;
}

.left_area.w_press .left_list_box .input_search_box .input_submit {
  left: 31%;
}

.left_area.w_press .left_list_box .input_search_box .input_search {
  width: 33%;
}

.left_area.w_press {
  width: 100%;
  display: inline-block;
  margin-top: 10px;
  margin-left: 2.5%;
  float: left;
}

.left_area.w_press .left_list_box .input_search_box.with--all-button {
  width: 95%;
}
/*  */
/* .workbtn {
  background-color: #cccccc;
  border: none;
  color: #000;
  padding: 4px 4px;
  font-size: 12px;
  cursor: pointer;
}

/* Darker background on mouse-over */
.workbtn:hover {
  background-color: rgb(102, 102, 102);
  color: #fff;
} */

.left_area.w_press .list_box_wrap .box_list.with--bottom-index .con_box.worklist {
  width: 17%;
  padding-left: 20px;

}

.left_area.w_press .list_box_wrap .box_list.with--bottom-index .con_box.worklist2 {
  width: 35%;
  padding: 0 1.5%;
  text-align: center;
  border-left: solid 1px #c5c5c5;
  border-right: solid 1px #c5c5c5;
}

.left_area.w_press .list_box_wrap .box_list .num_box.worklist {
  width: 8%;
  text-align: center;
  margin: 7px 20px -20px 20px;

}

.left_area.w_press .list_box_wrap .box_list .num_box.worklist1 {
  width: 28%;
  text-align: center;
  margin: 7px 0px 0px 20px;

}

.left_area.w_press .list_box_wrap {
  margin-top: 0px;
}

.left_area.w_press .list_box_wrap .box_list {
  padding: 16px 0 16px 5%;
}

.left_area.w_press .list_box_wrap .box_list .num.bottom {

  left: 22px;
  bottom: 10px;
  width: 41px;
  text-align: center;
}

.left_area.w_press .list_box_wrap .box_list .num_box .num {
  font-size: 16px;
}

.left_area.w_press .left_list_box .input_search_box.with--all-button .btn {
  position: relative;
  top: 0px;
  width: 100px;
  margin-right: 30px;
  /* 2022 new */
  /* background-color:#2e75b6 ; */
  /* background-color:#3d3d3d ;  */
  border: 1px solid #707070;
  border-radius: 4px;
  padding: 6px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  cursor: pointer;
  transition: 0.5s;
}

.left_area.w_press .list_box_wrap {

  height: calc(100vh - 75px - 37px - 28px - 66px - 37px);
  overflow-y: scroll;
}

.left_area.w_press .list_box_wrap .box_list .con_box .time {
  /* 2022 new */
  /* color: #1b1a1a; */
  font-size: 14px;
  letter-spacing: 0.01rem;
}

/* 2022 new */
table th {
  background-color: none;
  border-top: none;
  border-bottom: none;
}

table tr {
  background-color: none;
  border-top: none;
  border-bottom: none;
}
</style>

<template>
    <div id="map_" ref="map"  style="padding: 0; margin: 0; width: 100%; height: 100%;">

                      <div v-if="visible_gisload_button" style="position: absolute; left: 5px; top: 5px; z-index: 20;">
                            <b-button-toolbar aria-label="Toolbar with button groups">

                                   <b-button  class="mr-2 map-button icon_btn" type="button" @click="$emit('set_info')" v-b-tooltip.hover :title="$t('home.icon_info_title')" >
                                      <i class="fas fa-info"></i>
                                   </b-button>

                                   <b-button v-if="leak_P" class="mr-2 map-button icon_btn" size="sm" @click="$emit('toggle_visible_all_layer')"  v-b-tooltip.hover :title="layer_all_visible ? $t('leak_detail.table_column.strength') : $t('leak_detail.s_cal') ">
                                        <span v-show="layer_all_visible" ><i  class="fas fa-toggle-on"/></span>
                                    <!--/b-button>
                                    <b-button v-if="leak_P" class="mr-2 map-button icon_btn" size="sm" @click="$emit('toggle_visible_all_layer')" v-show="!layer_all_visible" v-b-tooltip.hover :title="!layer_all_visible? $t('leak_detail.s_cal'):''"-->
                                        <span v-show="!layer_all_visible" > <i  class="fas fa-toggle-off"/></span>
                                    </b-button>

                                   <b-button v-if="leak_P || leak_l" class="mr-2 map-button icon_btn" size="sm" @click="$emit('toggle_visible_leak_type')"   v-b-tooltip.hover :title="!layer_leak_visible ? $t('leak_detail.table_column.leak'):$t('map.all')">
                                         <span v-show="!layer_leak_visible" ><i class="mdi mdi-water"/></span>
                                    <!--/b-button>
                                    <b-button v-if="leak_P || leak_l " class="mr-2 map-button icon_btn" size="sm" @click="$emit('toggle_visible_leak_type')" v-show="layer_leak_visible" v-b-tooltip.hover :title="$t('map.all')"-->
                                         <span v-show="layer_leak_visible" ><i class="mdi mdi-water-off"/></span>
                                    </b-button>

                                   <!--button  v-if="leak_l" class="btn mr-2 map-button icon_btn btn-secondary btn-sm" type="button" @click="$emit('toggle_visible_all_layer',0)" v-b-tooltip.hover :title="$t('leak_detail.table_column.strength')">{{$t('map.btn_s')}}</button-->
                                   <!--button  v-if="leak_l" class="btn mr-2 map-button icon_btn btn-secondary btn-sm" type="button" @click="$emit('toggle_visible_all_layer',1)" v-b-tooltip.hover :title="$t('leak_detail.s_cal')">{{$t('map.btn_sc')}}</button-->
                                   
                                  <b-button class="mr-2 map-button icon_btn" size="sm" @click="$emit('facility_invisible_layer')" v-b-tooltip.hover :title="$t('map.title_f_v')" >
                                    <i class="mdi mdi-eye-off-outline"></i>
                                  </b-button>
                                  <!-- 압력 detail쪽 동수도로 압력값 비교, 기본값 비교     -->
                                  <b-button v-if="press_icon" class="mr-2 map-button icon_btn" size="sm" @click="$emit('toggle_visible_Hydraulic_type')"   v-b-tooltip.hover :title="!layer_leak_visible ? $t('map.title_hydra') :$t('map.all')">
                                      <span v-show="!layer_leak_visible" ><i  class='fas fa-ruler fa-rotate-90' ></i></span>
                                    <!--/b-button>
                                    <b-button v-if="press_icon" class="mr-2 map-button icon_btn" size="sm" @click="$emit('toggle_visible_Hydraulic_type')" v-show="layer_leak_visible" v-b-tooltip.hover :title="$t('map.all')"-->
                                       <span v-show="layer_leak_visible" ><i class='fas fa-ruler-horizontal'></i></span>
                                    </b-button>
                                    <b-button v-if="press_icon1" class="mr-2 map-button icon_btn" size="sm" @click="marker_value"  v-b-tooltip.hover :title="$t('map.point_name')">
                                      <!--span v-show= "this.marker_show %2 === 0" > <i class="fas fa-tag"></i> </span-->
                                       <span  v-show= "this.marker_show %2 === 0"  class="mdi mdi-label"></span>
                                       <span  v-show= "this.marker_show %2 === 1"  class="mdi mdi-label-off"></span>
                                    </b-button>

                                   <button  class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text" type="button" @click="$emit('set_map_gisload')" v-b-tooltip.hover>{{$t('button.gisload')}}</button>

                                   <button  v-if="leak_P" class="btn btn-map-view ml-2 map-button icon_btn icon_btn_text" type="button" @click="$emit('get_gisinfo')" v-b-tooltip.hover >{{$t('map.gis_info')}}</button>
                                   <button  v-if="leak_P" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" type="button" @click="marker_value" v-b-tooltip.hover >{{$t('map.value')}}</button>
                                   <button  v-if="leak_l" class="btn btn-map-view ml-2 map-button icon_btn icon_btn_text" type="button" @click="$emit('get_gisinfo')" v-b-tooltip.hover >{{$t('map.gis_info')}}</button>
                                   <button  v-if="leak_l" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" type="button" @click="marker_value" v-b-tooltip.hover >{{$t('map.value')}}</button>
                                   <button  v-if="leak_l||recovery_icon" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" type="button" @click="leak_fix_size" v-b-tooltip.hover>{{b_str_circle}}</button>
                                
                                   

                            </b-button-toolbar>

                            <p id="c_zoom"></p>

                           
                      </div>
                   
        <!-- Gis Master 버튼 -->


        <div class="gis-btn" style="z-index: 10; ">

            <button class="btn map-button icon_btn btn-map-view ml-1" @click="$emit('go_home')" v-if="visible_full_extent"><i class="mdi mdi-arrow-all"></i></button>
            <button v-if="visible_active_button" style="margin-left:10px;"
              class="map-button btn icon_btn icon_btn_text btn-map-view" type="button" @click="$emit('go_gischeck')">GIS Check</button>
            <!--
            <button v-if="visible_active_button" class="btn btn-map-view ml-1" type="button" @click="$emit('set_status', 'G')">active</button>
            <button v-if="visible_active_button" class="btn btn-map-view ml-1" type="button" @click="$emit('set_status', 'I')">Inactive</button>
            <button v-if="visible_active_button" class="btn btn-map-view ml-1" type="button" @click="$emit('set_status', 'U')">Unable</button>
            -->
        </div>
        <!-- leak_anaiysis 버튼 end -->
        <!-- Gis Master 버튼 end -->
        <!-- Water Leak 버튼    -->
         <div v-if="visible_detail_button" class="leak-btn">
             <button class="map-button btn icon_btn icon_btn_text btn-map-view" @click="$emit('go_detail')">Detail</button>
        </div>
        <!-- Water Leak 버튼 end -->
        <!-- 맵 사이즈 변환 버튼 -->
        <div v-if="visible_size_view_button" class="map-size-trans">
           <button class="btn-map-l btn-size-view" @click="$emit('size_view', 'up')"></button>
           <button class="btn-map-r btn-size-view" @click="$emit('size_view', 'down')"></button>
        </div>
        <div v-if="visible_size_side_view_button" class="map-size-r-trans">
           <button class="btn-map-l btn-size-view" @click="$emit('size_view', 'right')"><i class="mdi mdi-menu-right mdi-24px align-right"></i></button>
           <button class="btn-map-r btn-size-view" @click="$emit('size_view', 'left')"><i class="mdi mdi-menu-left mdi-24px"></i></button>
        </div>
        <!-- 맵 사이즈 변환 버튼 end -->
         <div id="scaleline"></div>
        <FacilityInfoType ref="facility_info" @facility_refresh="facility_refresh"/>
        <DlgLeakageInfo ref="leakage_info" @leaktable_refresh="leaktable_refresh"/>
        <DlgBlockInfo ref="Block_info" @facility_refresh="facility_refresh"/>        
        <DlgAddressList ref="addr_list"  @set_marker_="set_marker_" />
        <!--DlgWaterFlowInfo ref="flow_info"  @leaktable_refresh="leaktable_refresh" /-->
        
    </div>
</template>

<script>

import DlgLeakageInfo from '@/components/popup/DlgLeakageInfo'
import DlgAddressList from '@/components/popup/DlgAddressList'
//import DlgWaterFlowInfo from '@/components/popup/DlgWaterFlowInfo'
import DlgBlockInfo from '@/components/popup/DlgBlockInfo'

import Circle from 'ol/geom/Circle';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import Geolocation from 'ol/Geolocation.js';
//import {CreateMap, CreateHeatmapLayer,CreateBingMap,CreateVWorldMap,  CreateVectorLayer, CreateVectorLayer_f, ScaleExtent, ConvertCoordinateExtent, LinearInterpolation} from '@/gis.js'
import {CreateMap, CreateHeatmapLayer,CreateBingMap,CreateDaumMap,  CreateVectorLayer, CreateVectorLayer_f, ScaleExtent, ConvertCoordinateExtent, LinearInterpolation} from '@/gis.js'
import {Circle as CircleStyle, Fill, Style, Stroke, Text,Icon} from 'ol/style.js';
import {Select, Draw, Modify, Snap} from 'ol/interaction';
//import {pointerMove} from 'ol/events/condition';
import Collection from 'ol/Collection';
import {ScaleLine} from 'ol/control';
import {View} from 'ol';
import proj4 from 'proj4';
//import Projection from 'ol/proj/Projection';
//import {getPointResolution} from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import FacilityInfoType from '@/components/FacilityInfoType';
// import ol_color from 'ol/color';

const LAYER_BLOCK    = 7
const LAYER_PIPE     = 0
const LAYER_VALVE    = 1
const LAYER_META     = 2
const LAYER_PUMP     = 3
const LAYER_HYDRANT  = 4
const LAYER_JUNCTION = 5
const LAYER_WATER_TREATMENT_PLANT = 8
const LAYER_PUMPING_STATION = 9
const LAYER_WATER_RESERVOIR = 10
const LAYER_CHAMBER = 11
const LAYER_GAUGE = 12
const LAYER_OTHER = 13

const LAYER_LEAK     = 100
//const LAYER_PRESSURE = 110
const LAYER_HEAT     = 1000


export default {
    name: 'Map',
    props: [
        'widx', //work idx 파라미터로 넘어오면 처리
        'visible_full_extent',  // 전체화면(home) 버튼 지도위에 표시
        'visible_active_button', // 지도위에 GISmaster Active, Inactive, Unable 버튼 표시
        'visible_gisload_button',
        'visible_leakchart_button',
        'visible_detail_button', // 지도위에 Detail 버튼 표시
        'visible_size_view_button', // 지도위에 Size 버튼 표시
        'visible_size_side_view_button', // 지도위에 side Size 버튼 표시
        'w_type', //w_type 파라미터로 넘어오면 처리
        'leak_limit_count', //leak feature 갯수만큼만 가져온 변수
        'leak_P',
        'leak_l',
        'recovery_icon',
        'press_icon',
        'press_icon1', //m1로거 이름표
    ],
    watch: {
        map() {
            this.$nextTick(() => {
                if(this.map===null) return
                this.map.updateSize()
            })
        },
        select_block_id(){
           this.select_facility_feature('112', '', this.select_block_id) 
        }
    },
    data() {
        return {
            map: null,
            layer_all_visible:true,
            layer_leak_visible:false,
            leak_style: null, //누수 스타일 변수             
            leak_style_h: null, //누수 스타일 변수    
            block_style : null,
            water_layer_color : null,
            value_layer_color : null,
            press_style_h : null, //압력이름표 레이어
            hydraulic_line_style : null,
            facility_el_style : null,            
            leak_recov_style: null, //누수 스타일 변수
            press_style: null, //압력 스타일 변수
            accounts_style: null, //계량기 사용량 스타일 변수
            map_mode: 'select',
            select_features: null,
            b_str_circle : this.$t('map.circle_fix'),
            fix_size: 0,
            is_edit_mode: false,
            interactions_modify: null, //도형수정 인터렉션
            interactions_draws: [],    //도형 추가 인터렉션
            snap_features: null,       //스냅 도형들
            add_modify_features: [],   //편집 또는 수정된 도형들 확인 버튼을 누르면 서버에 저장.
            layer_list:[],
            layer_setting_stlye_list : [],
            layer_setting_stlye_el_list : [],
            leak_layer : null, //누수음 layer
            heat_layer : null, //
            corre_layer : null, //상관식때 누수음 그려주기
            leak_value_layer : null, //누수음에 value값, 지점에 이름표시
            select_info: false,
            leak_idx : -1,
            leak_recov_idx:-1,
            leak_cal : 0,  //0 : strengh값을 가지고 오고 1이면 strength_cal값을 가져온다.
            leak_type: null, // NULL:ALL, LEAK : LEAKAGE, 압력쪽://leak_cal (avg, min, max)  leak_type ( null: 원래의 값, HYDRAULIC :동수도)
            reset : false,
            rroof : 0,  
            p_value : 0, //동수두에서 rroof : 0 지점압력 동수두값 보기 1: 숨기기
            epsg1 : 'EPSG:5181',            
            leak_val:0, //압력쪽에서 bar,psi,kgf값을 가져온다. 
            marker_show: 0, 
            time_30m:null,
            block_info : 0 ,
            value:'',
            search_div1:'search_div1',
            home_search:'home_search',
            init_fidx : 0,
            move_idx : 0,//두개이상 일때 조금이동해서 value값 보여주기
            p_name : '', //압력로거 지점이름을 표시한다.
            coord_map : [],
            coord2 : [],
            add_scale:null,
            select_block_id : -1,
        }
    },
    methods: {
        //지도 화면의 사이즈가 바뀌면 update_size() 함수를 호출해 지도를 다시 그려야 한다.
        update_size() {
            this.$nextTick(() => {
                if(this.map===null) return
                this.map.updateSize()
            })
        },
        change_map_mode(){
            this.$emit('change_map_mode') ;
        },
        go_search(q){
         //   var q = this.value; //검색 내용

            if(this.value===undefined || q==='' || q.length <2) {
                alert(this.$t('map.alt_addr')) ;
                return
            }
            if(this.$store.setting.map_name==='Daum'){
                //주소검색
                let surl='https://dapi.kakao.com/v2/local/search/address.json?page=1&size=10&query='+encodeURIComponent(q)
                this.search(surl);
                setTimeout(()=>{
                    if(this.s_data === 0){
                        //키워드 검색
                        let surl1='https://dapi.kakao.com/v2/local/search/keyword.json?page=1&size=10&query='+encodeURIComponent(q)
                        this.search(surl1); 
                    }
                }, 100)
            }else{
                this.search('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDodBJ8zI4EvoX8DOWRjvIjp-v1ymDadNs&address='+encodeURIComponent(q))                                                                       
            }
        },
        search(aurl){
          if(this.$store.setting.map_name==='Daum'){
            this.$http.get(aurl, {
                  headers: {
                       'Authorization': 'KakaoAK 48629232e10c82b9b9f5f348070f5b73'
                    }
            }).then(response => {
                console.log('response', response)
                if(response.data.meta.total_count < 1 ){
                    if(aurl.indexOf("https://dapi.kakao.com/v2/local/search/keyword.json") !== -1)
                    alert(this.$t('map.alt_no_data'))
                    this.s_data = 0;
                     return
                }else if(response.data.meta.total_count === 1){
                    var posx = Number(response.data.documents[0].x);
                    var posy = Number(response.data.documents[0].y);
                    this.set_marker_(posx,posy)
                }else{
                    this.$refs.addr_list.show(response.data.documents,'Daum')
                }
                this.s_data = 1;


                /*
                var posx = Number(response.data.documents[0].x);
                var posy = Number(response.data.documents[0].y);
                let data = [posx ,posy, posx, posy]

                this.set_fit_extent(data)     
               
                this.map.getView().setZoom(13);
               
               
                var coor = proj4( 'EPSG:4326',this.map.getView().getProjection().getCode(), [posx, posy]) 
                this.marker_point(coor)      
                */
              })
           }else{
             this.$http.get(aurl).then((res) => {

                if(res.data.results.length === 1 ){ 
                    var py = res.data.results[0].geometry.location.lat    //y값
                    var px = res.data.results[0].geometry.location.lng    //x값
                    this.set_marker_(px,py) 
                }else if(res.data.results.length === 0){
                    return;
                }else{
                    //2개 이상일때 리스트를 보여준다.
                    this.$refs.addr_list.show(res.data.results,'Bing')
                }
                /*    
                let data1 = [px ,py, px, py]

                this.set_fit_extent(data1)  

                this.map.getView().setZoom(18); 
                var coor1 = proj4('EPSG:4326',this.map.getView().getProjection().getCode(), [px, py]) 
                this.marker_point(coor1) 
                */
                

             });
           }
               
        },
        //popup에서 검색데이터 한개 추출시 표시
        set_marker_(posx,posy) {
          if(this.$store.setting.map_name==='Daum'){
        
                let data = [posx ,posy, posx, posy]

                this.set_fit_extent(data)     
               
                this.map.getView().setZoom(13);
                this.s_data = 1;
               
                var coor = proj4( 'EPSG:4326',this.map.getView().getProjection().getCode(), [posx, posy]) 
                this.marker_point(coor)      
            
           }else{
         
       
                let data1 = [posx ,posy, posx, posy]

                this.set_fit_extent(data1)  

                this.map.getView().setZoom(18); 
                var coor1 = proj4('EPSG:4326',this.map.getView().getProjection().getCode(), [posx ,posy]) 
                this.marker_point(coor1)         
         
           }
               
        },
        //자기 위치 표시
        show_geolocation(){
            var view = this.map.getView()
            var geolocation = new Geolocation({
                trackingOptions: {
                    enableHighAccuracy: true
                },
                projection: view.getProjection()
            });
            geolocation.setTracking(true);
            var coordinates = geolocation.getPosition();
            var py = coordinates.lat    //y값
            var px = coordinates.lng    //x값
            this.set_marker_(px,py) 
            /*
            geolocation.on('change', function() {
            console.log('accuracy = ' + geolocation.getAccuracy() + 'm ' +
                'altitude = ' + geolocation.getAltitude() + 'm ' +
                'altitudeAccuracy = ' +  geolocation.getAltitudeAccuracy() + 'm ' +
                'heading = ' + geolocation.getHeading() + 'rad ' +
                'speed = ' + geolocation.getSpeed() + 'm/s');
            });
            geolocation.on('error', function(error) {
                console.log('geolocation error: ' + error.message);
            });
            geolocation.on('change:position', function() {
                var coordinates = geolocation.getPosition();
                positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
            });
           var positionFeature = new Feature();
                positionFeature.setStyle(new Style({
                    image: new CircleStyle({
                        radius: 6,
                        fill: new Fill({
                            color: '#3399CC'
                        }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2
                        })
                    })
            })); 
            new VectorLayer({
                map: map,
                source: new VectorSource({
                    features: [positionFeature]
                })
            });
            */
        },
        //extent = minx, miny, maxx, maxy
        //화면의 work의 extent 또는 setting에 설정된 exent를 가져오는 함수
        async get_extent(params) {
            if((typeof(params)!=='undefined')&&(params!=='')) {
                let url = this.URL+'api/get_extent'
                 url += '?'+params
                 const {data} = await this.$http.get(url)
                 console.log('url : ' + url)
                 console.log('data: ' + data)
                 if(data[0]===-0.0003 && data[1]===-0.0001 )
                    return this.$store.setting.extent
                 else
                    return data
             }
             else {
                 return this.$store.setting.extent
             }
        },
        // 현재 맵의 extent를 가져오는 함수
        get_view_extent() {
            if(this.map === null ) return;
            var code = this.map.getView().getProjection().getCode();
            var ext = this.map.getView().calculateExtent() ;
            return ConvertCoordinateExtent(code, 'EPSG:4326', ext)
           // return ConvertCoordinateExtent(this.map.getView().getProjection().getCode(), 'EPSG:4326', this.map.getView().calculateExtent())
        },
        //map에 extent 값을 설정
        set_fit_extent(ext, cb) {
            let extent = ScaleExtent(ConvertCoordinateExtent('EPSG:4326', this.map.getView().getProjection().getCode(), ext), 1.5);
            this.map.getView().fit(extent, {padding: [0, 0, 0, 0], constrainResolution: false, callback: cb});
        },
        //서버에서 geojson을 가져오는 함수
        async get_geometry(params) {
            let url = this.URL+'api/get_geometry'
            if((typeof(params)!=='undefined')&&(params!==''))  url += '?'+params
            const {data} = await this.$http.get(url)
            return data
        },
        //layer code와 시설물 타입으로 레이어를 반환하는 함수
        get_layer(_layer, f_type) {
            for(let i=1; i<this.map.getLayers().getLength(); i++) {
                let layers = this.map.getLayers()
                let layer = layers.item(i)
                if(layer.get('code')!==_layer) continue
                if((typeof(f_type)==='undefined')||(layer.get('f_type')==='')) return layer
                else if(layer.get('f_type')===f_type) return layer
            }
            return null
        },
        //layer code, 시설물 타입, idx로 feature를 선택하는 함수
        select_facility_feature(_layer, f_type, idx) {
            this.select_features.getFeatures().clear()
            let layer = this.get_layer(_layer, f_type);
            for(let f of layer.getSource().getFeatures()) {
                //if(_layer==='100'||_layer===101){
                 if(_layer==='100'){   
                   if(f.get('l_idx')===idx ) {
                       this.select_features.getFeatures().push(f)                       
                       this.marker_point(f.getGeometry().flatCoordinates)
                       //setTimeout(()=>{ this.select_features.getFeatures().clear() }, 500) 
                      
                        return;
                    }  
                  }else if(_layer===102 ){   
                   if(Number(f.get('m2_point_id'))===Number(idx) ) {
                       this.select_features.getFeatures().push(f)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 1000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 1500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 2000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 2500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 3000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 3500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 4000)
                  
                        return;
                    }  
                }else if(_layer===1004){   
                   if(Number(f.get('id'))===Number(idx) ) {
                       this.select_features.getFeatures().push(f)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 1000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 1500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 2000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 2500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 3000)                  
                        return;
                    }  
                   
               
                }else if(_layer===1100 ||_layer===1101  ){   
                   if(Number(f.get('idx'))===Number(idx) ) {
                       this.select_features.getFeatures().push(f)
                      // this.set_fit_extent(f.values_.geometry.extent_);
                      this.map.getView().fit(f.values_.geometry.extent_, {padding: [0, 0, 0, 0], constrainResolution: false});
                      var cz = (this.$store.setting.map_name === 'Daum')? 12 : 18 ;
                      this.map.getView().setZoom(cz)
                       //this.$refs.map
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 1000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 1500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 2000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 2500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 3000)                  
                        return;
                    }  
                   
               
                }else{
                    if(f.get('id')===idx) {
                       this.select_features.getFeatures().push(f)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 1000)
                      // setTimeout(()=>{ this.select_features.getFeatures().clear() }, 1500)

                    }
                    else if(f.get('p_idx')===idx ||f.get('tag_id')===idx) {
                       this.select_features.getFeatures().push(f)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 1000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 1500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 2000)
                     //  setTimeout(()=>{ this.select_features.getFeatures().clear() }, 2500)
                   } else if(f.get('idx')===idx ) {
                       this.select_features.getFeatures().push(f)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 1000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 1500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 2000)
                    //   setTimeout(()=>{ this.select_features.getFeatures().clear() }, 2500)
                       this.select_block_id = -1 
                  
                    }else if(f.get('block_id')===idx) {
                       this.select_features.getFeatures().push(f)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 1000)
                       setTimeout(()=>{ this.select_features.getFeatures().clear() }, 1500)
                       setTimeout(()=>{ this.select_features.getFeatures().push(f) }, 2000)
                      // setTimeout(()=>{ this.select_features.getFeatures().clear() }, 2500)

                    }                      
                }
            }
        },
        //서설물 정보를 보여주는 함수
        show_facility_info(idx) {
            this.$refs.facility_info.show(idx)
        },
        show_block_info(idx,block_type) {
          //  this.$refs.facility_info.show(idx)
          this.$refs.Block_info.show(idx,block_type);   
        },
         
        hide_facility_info(idx) {
          this.$refs.facility_info.hide(idx)
        },
        //누수음 정보를 보여준다.
        show_leakage_info(idx) {
            this.$refs.leakage_info.show(idx)
        },
                //누수음 정보를 보여준다.
        show_leakage_info_pop(idx,a) {
            if(idx ==='' || idx === undefined) {
                alert(this.$t('map.alt_no_leak_info'));
                return
            }
               var purl = "/leak_popup_info?idx="+idx ;
               if(a=== 2){
                  purl = "/m_leak_popup_info?idx="+idx ;  
               }
               window.open(purl, "PopupWin", "width=580,height=750,top=10, left=50,status=no, menubar=no, toolbar=no,resizable=yes, location=no");
        },
        //압력정보 보여준다.
        /*
        show_press_info(idx) {
            this.$refs.pressure_info.show(idx)
        },
        
        show_flow_info(idx) {
            this.$refs.flow_info.show(idx)           
        },
        */
        //gis_master시설정보 add, modify
        facility_refresh(a){
            if(a === 0 ){ //지도 수정 및 신규등록
                this.$emit('facility_refresh') ;
              //  this.$emit('init_mode') ;
                //this.$emit('gis_reload') ;
                 setTimeout(()=>{
                     this.$emit('gis_reload') ;
                      this.$emit('init_mode') ;
                 }, 500)
            }else if(a === 1 ){ //데이터만  update한경우
                this.$emit('facility_refresh') ;
            }else if(a === 2 ){ //지도수정하다가 cancel
          
               if(this.block_info === 1) {     
                    this.edit_cancel();
                    this.block_info = 0;     
                    setTimeout(()=>{
                     this.$emit('gis_reload') ;
                    }, 500)              
               }else{  
                setTimeout(()=>{
                    this.$emit('gis_reload') ;
                }, 500)
               }               
            }
        },
        leaktable_refresh(){
           this.$emit('leaktable_refresh') ;
        },

        //레이어를 배열로 반환하는 함수
        get_layers() {
            if(this.map===null) return [];
            return this.map.getLayers().getArray()
        },
        layer_visible(name, v) {
            for(let i=(this.map.getLayers().getLength() - 1); i>=0; i--) {
                if(this.map.getLayers().item(i).get('name')!==name) continue;
                this.map.getLayers().item(i).setVisible(v)
            }
        },
        //편집 시작함수
        edit_start(mode, layr_name) {
            this.is_edit_mode = true
            this.add_modify_features = null;
            if(mode==='add') {
                for(let i=0; i<this.interactions_draws.length; i++)
                    this.interactions_draws[i].draw.setActive(false)
                this.interactions_modify.setActive(false)
                let item = this.interactions_draws.find((item) => { if(item.name === layr_name) return item })
                item.draw.setActive(true)
            }
            else if(mode==='modify') {
                for(let i=0; i<this.interactions_draws.length; i++)
                    this.interactions_draws[i].draw.setActive(false)
                this.interactions_modify.setActive(true)
            }
        },
        edit_change(mode, layr_name) {
            this.edit_start(mode, layr_name)
        },
        //편집 종료 함수
        edit_end() {
            this.is_edit_mode = false
            this.map_mode = 'select'
            for(let i=0; i<this.interactions_draws.length; i++)
                this.interactions_draws[i].draw.setActive(false)
            this.interactions_modify.setActive(false)
            this.select_features.getFeatures().clear()
            this.add_modify_features = []
        },
        // 피처를 삭제하는 함수
        delete_feature() {
            //if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === undefined){
                return
          //  }else if(localStorage.level !== 'A'){
            }else if(sessionStorage.level > 1){  
               alert(this.$t('water_leak.alert_no_permission')) ;
               return
            }
            this.select_features.getFeatures().forEach((f) => {
                let layer = this.select_features.getLayer(f)
                if(f.get('id')!==undefined) {
                    var con_test = confirm("Are you sure you want to delete?");
                    if(con_test){
                      this.$http.get(this.URL+'api/delete_facility?idx='+f.get('id'));
                       layer.getSource().removeFeature(f);
                    }
                }

            })
        },
        init_draw_modify(){
          this.map.removeInteraction(this.select_features) ;
          this.map.removeInteraction(this.interactions_modify) ;
          this.init_draw();
          this.select_features = new Select({hitTolerance: 4})
          this.select_features.setActive(true)
          this.map.addInteraction(this.select_features)

          /*
          this.interactions_draws = []
            for(let i=1; i<(this.map.getLayers().getLength()-1); i++) {
                let layer = this.map.getLayers().item(i)
                let type = ''
                let code = layer.get('code')
                switch ( code ) {
                    case LAYER_PIPE:
                        type = 'LineString'
                        break
                    case LAYER_VALVE:
                        type = 'Point'
                        break
                    case LAYER_META:
                        type = 'Point'
                        break
                    case LAYER_PUMP:
                         type = 'Point'
                        break
                    case LAYER_HYDRANT:
                    case LAYER_JUNCTION:
                    case LAYER_WATER_TREATMENT_PLANT:
                    case LAYER_WATER_RESERVOIR:
                    case LAYER_GAUGE:
                    case LAYER_OTHER:
                        type = 'Point'
                        break;
                    case LAYER_BLOCK:
                        type = 'Polygon'
                        break
                    case LAYER_PUMPING_STATION:
                    case LAYER_CHAMBER:
                        type = 'Polygon'
                        break
                    default:
                        continue
                }

                let draw = new Draw({source: layer.getSource(),  type: type})
                draw.layer = layer
                this.interactions_draws.push({draw: draw, code: layer.get('code'), name: layer.get('name')})
                this.map.addInteraction(draw)
                draw.setActive(false)
                draw.on('drawend', (e) => {
                     var feat = e.feature;
                     var len = 100;
                     //linestring 생성시 길이가 2m 이하로 생성하는 pipe는 제거 기존것을 수정
                     if(e.target.layer.get('code')===0 )
                          len = feat.getGeometry().getLength();
                     if(len >= 1){
                        var data = {type: 'add', feature: e.feature, code: e.target.layer.get('code'), f_type: e.target.layer.get('f_type')} ;
                        data = this.change_xy(data) ;
                        this.$refs.facility_info.insert_show(data);
                        this.edit_cancel();
                     }
                        //this.add_modify_features.push({type: 'add', feature: e.feature, code: e.target.layer.get('code'), f_type: e.target.layer.get('f_type')})

                })
            }
            */
            this.interactions_modify = new Modify({features: this.select_features.getFeatures()})
            this.map.addInteraction(this.interactions_modify)
            this.interactions_modify.setActive(false)
            this.interactions_modify.on('modifyend', (e) => {
                let fs = e.features.getArray()
                for(let i=0; i<fs.length; i++) {
                    let layer = this.select_features.getLayer(fs[i])
                  //  let item = this.add_modify_features.find((item) => { if(item.feature === fs[i]) return item })
                    //if(layer.get('code') < 100 || layer.get('code') === 1000){

                    //100번 이하만 수정해 준다.
                    //다른 layer추가시     
                    if(layer.get('code') < 100 ){    
                        if(layer !== null) {
                            let param = {} ;
                            param["customer_member_id"] = sessionStorage.m_idx;
                            if(layer.get('code')===0 ){
                                var len =  fs[i].getGeometry().getLength();                          
                                len = Math.round(len * 100) / 100
                                param["length"] = len ;
                            }
                            let data = {type: 'modify', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type'),params:param} ;
                        //  this.add_modify_features.push({type: 'modify', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type')})
                            data = this.change_xy(data) ;

                            let con_test = confirm("Do you want to modify?");
                            if(con_test){
                                //this.$http.get(this.URL+'api/delete_facility?idx='+f.get('idx'));
                                this.$http.get(this.URL+'api/add_modify_feature?json='+encodeURIComponent(JSON.stringify(data))).then((res) => {
                                    console.log(res.data);
                                    this.facility_refresh(0);
                                })

                            }else{
                                this.facility_refresh(2);
                            }

                        }
                    //2022.11.21 추가   
                    //동수두 분석   
                    }else if(layer.get('code') === "112" ){  
                        let param = {} ;
                        param["customer_member_id"] = sessionStorage.m_idx;
                     
                        let data = {type: 'modify',type1: 'location', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type'),params:param} ;
                    //  this.add_modify_features.push({type: 'modify', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type')})
                        data = this.change_xy(data) ;

                        let con_test = confirm("Do you want to modify?");
                        if(con_test){
                            //this.$http.get(this.URL+'api/delete_facility?idx='+f.get('idx'));
                            this.$http.get(this.URL+'api/add_hydraulic_feature?json='+encodeURIComponent(JSON.stringify(data))).then((res) => {
                                console.log(res.data);
                         
                            })

                        }
                    }else if(layer.get('code') === "1007" ){  
                        let param = {} ;
                        let data = {type: 'modify',type1: 'location', feature: fs[i], code: 7, f_type: layer.get('f_type'),params:param} ;

                        data = this.change_xy(data) ;
                         let i_idx =  fs[i].get('idx')
                         param["id"] = i_idx
                         param["location"]  = data.feature.geometry
                        let con_test = confirm("Do you want to modify?");
                        if(con_test){
                            //this.$http.get(this.URL+'api/delete_facility?idx='+f.get('idx'));
                            this.$http.patch(this.URL+'api/work_block/'+i_idx,param).then((res) => {
                                console.log(res.data);
                         
                            })

                        }
                    }else if(layer.get('code') === "1008" ){  
                        let param = {} ; 
                        let data = {type: 'modify',type1: 'location', feature: fs[i], code: 7, f_type: layer.get('f_type'),params:param} ;
                        //this.add_modify_features.push({type: 'modify', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type')})
                        data = this.change_xy(data) ;
                         let i_idx =  fs[i].get('idx')
                         param["id"] = i_idx
                         param["location"]  = data.feature.geometry
                        let con_test = confirm("Do you want to modify?");
                        if(con_test){
                            //this.$http.get(this.URL+'api/delete_facility?idx='+f.get('idx'));
                            this.$http.patch(this.URL+'api/waterpressure/groups/location/'+i_idx,param).then((res) => {
                                console.log(res.data);
                         
                            })

                        }
                    }
                }
            })
           //자석기능
            this.snap_features = new Collection([], {unique: true});
            let snap = new Snap({features: this.snap_features});
            this.map.addInteraction(snap)
            for(let i=1; i<(this.map.getLayers().getLength()-1); i++) {
                this.map.getLayers().item(i).getSource().on('addfeature', (e) => {
                    this.snap_features.push(e.feature)
                })
                this.map.getLayers().item(i).getSource().on('removefeature', (e) => {
                    this.snap_features.remove(e.feature)
                })
            }
        //feature 선택시 popup창 뜨워지는 부분
            this.select_features.on('select', ((e)=> {
                let cur_page = ""+document.location.href ;
                if(!this.select_info) return
                if(e.selected.length<=0) return
                if(this.is_edit_mode) return
                let f = e.selected[0];

                    // var ele = document.getElementById('leakchart');
                    // if(ele !== null)
                    //   ele.click();
                    //100:leakage
                    //110:pressure
                    //120:water_meter
                    //130:water quality
                    //140:maintenance
                    //150:customer_voice_info                   
                //누수음 원클릭시
                if(f.get('layer_type') === 100 && f.get('l_idx') !== undefined ){
                   //alert("현재페이지"+document.location.href);

                    this.leak_recov_idx = f.get('w_idx')    
                    this.leak_idx = f.get('l_idx');
                    
                    if(cur_page.indexOf("leak-analysis1") !== -1 || cur_page.indexOf("leak-detail") !== -1) { 
                        this.$emit('onLeakMapClick') ;   
                        this.marker_point([null,null])
                        return;
                    }else{
                       if(cur_page.indexOf("leak-analysis") !== -1) { 
                        //this.show_leakage_info(f.get('l_idx'));  
                       // var win1 = window.open("", "PopupWin", "width=580,height=750,top=10, left=50,resizable=yes, location=no");
                       // win1.document.write("<p>새창에 표시될 내용 입니다.</p>"); 
                           this.show_leakage_info(f.get('l_idx'));    
                        }else{
                            this.show_leakage_info(f.get('l_idx'));
                            this.$emit('onLeakMapClick') ;
                        }
                    }
                  
                } else if(f.get('layer_type') === 101 && f.get('l_idx') !== undefined ){
                   //alert("현재페이지"+document.location.href);

                   
                    this.leak_idx = f.get('l_idx');
                    this.leak_recov_idx = f.get('id');
                    if(cur_page.indexOf("leak-recovery-detail") !== -1 || cur_page.indexOf("leak-recovery-map")) { 
                        this.$emit('onLeakMapClick') ;   
                        return;
                    }else{
                       if(cur_page.indexOf("leak-recovery-detail-map1") !== -1) { 
                        //this.show_leakage_info(f.get('l_idx'));  
                            var win = window.open("", "PopupWin", "width=580,height=750,top=10, left=50,resizable=yes, location=no");
                            win.document.write("<p>새창에 표시될 내용 입니다.</p>"); 
                        }else{
                            this.show_leakage_info(f.get('l_idx'));    
                            this.$emit('onLeakMapClick') ;     
                        }
                    }       
                } else if(f.get('layer_type') === 102 && f.get('m2_id') !== undefined ){
                  
                    this.leak_idx = f.get('m2_id');
                    this.leak_recov_idx = f.get('m2_point_id');
                    if(cur_page.indexOf("leak-monitoring-detail-map1") !== -1) {                      
                          this.show_leakage_info_pop(f.get('m2_id'),2);    
                    }else{
                     
                        this.$emit('onLeakMapClick') ;    
                         return; 
                    }
                
                 } else if(f.get('layer_type') === 111 && f.get('tag_id') !== undefined ){
                   //alert("현재페이지"+document.location.href);                    
                    this.leak_idx = f.get('tag_id');
                    this.leak_recov_idx = f.get('name');                   
                    this.$emit('onLeakMapClick') ;   
                
                }else if(f.get('layer_type') === 110 || f.get('layer_type') === 160 || f.get('layer_type') === 104){
                  //  alert("pressure 2")
                   if(f.get('layer_type') === 110 ){
                    if(cur_page.indexOf("pressure-detail-map") !== -1) { 
                         this.leak_idx = f.get('p_idx') ;
                         this.leak_recov_idx = f.get('psr_file_path');  
                         this.move_idx =   f.get('group_id');   
                         this.p_name =   f.get('p_name');       
                         this.$emit('onLeakMapClick') ;      
                    }else{
                       // this.$refs.pressure_info.show(f.get('p_idx'))
                         this.leak_idx = f.get('p_idx') ;
                         this.leak_recov_idx = f.get('psr_file_path'); 
                         this.$emit('onLeakMapClick') ;    
                    }
                   }else{
                       this.leak_idx = f.get('p_idx') ;
                       if(f.get('layer_type') === 160)
                         this.leak_recov_idx = f.get('flw_file_path');  
                       else{
                        this.leak_recov_idx = f.get('ld_idx'); //logger_detail_id   
                        this.move_idx =   f.get('avg');      
                       }
                       this.$emit('onLeakMapClick') ;    
                   }                    
               
                }else if(f.get('layer_type') === 161){
                   // alert("pressure 1 ")    
                    this.leak_idx = f.get('tag_id');
                    this.leak_recov_idx = f.get('name');                   
                    this.$emit('onLeakMapClick') ; 
                }else if(f.get('layer_type') === 1004){
                   // alert("pressure 1 ")    
                    this.leak_idx = f.get('id');
                   // this.leak_recov_idx = f.get('name');                   
                    this.$emit('on_correlation_info',f) ; 
                }else if(f.get('layer_type') === 7){ //work_block
                   // alert("pressure 1 ")    
                    this.leak_idx = f.get('idx');
                   // this.leak_recov_idx = f.get('name');                   
                   // this.$emit('on_correlation_info',f) ;
                   this.show_block_info(f.get('idx'));                   
                }else{
                    this.show_facility_info(f.get('id'))
                }
            }))
        },
        init_draw(){
            if(this.select_features !== null)
                this.map.removeInteraction(this.select_features) ;    
            for(let i=0; i<(this.interactions_draws.length); i++) {
                this.map.removeInteraction(this.interactions_draws[i]) ;
            }
            this.interactions_draws = []
            for(let i=1; i<(this.map.getLayers().getLength()-1); i++) {
                let layer = this.map.getLayers().item(i)
                let type = 'Point'
                let code = layer.get('code')
                switch ( code ) {
                    case LAYER_PIPE:
                        type = 'LineString'
                        break
                    case LAYER_VALVE:
                        type = 'Point'
                        break
                    case LAYER_META:
                        type = 'Point'
                        break
                    case LAYER_PUMP:
                         type = 'Point'
                        break
                    case LAYER_HYDRANT:
                    case LAYER_JUNCTION:
                    case LAYER_WATER_TREATMENT_PLANT:
                    case LAYER_WATER_RESERVOIR:
                    case LAYER_GAUGE:
                    case LAYER_OTHER:
                        type = 'Point'
                        break;
                    case LAYER_BLOCK:
                        type = 'Polygon'
                        break
                    case LAYER_PUMPING_STATION:
                    case LAYER_CHAMBER:
                        type = 'Polygon'
                        break
                    default:
                        continue
                }

                let draw = new Draw({source: layer.getSource(),  type: type})
                draw.layer = layer
                this.interactions_draws.push({draw: draw, code: layer.get('code'), name: layer.get('name')})
                this.map.addInteraction(draw)
                draw.setActive(false)
                draw.on('drawend', (e) => { // init_draw
                     draw.setActive(false)
                     var feat = e.feature;
                     var len = 100;
                     //linestring 생성시 길이가 1m 이하로 생성하는 pipe는 제거 기존것을 수정
                     if(e.target.layer.get('code')===0 ){
                          len = feat.getGeometry().getLength();
                          len = Math.round(len * 100) / 100
                     }
                     if(len >= 1){
                        var data = {type: 'add', feature: e.feature, code: e.target.layer.get('code'), f_type: e.target.layer.get('f_type'),length:len} ;
                        data = this.change_xy(data) ;
                        //2020-12-01 주석
                        //this.$refs.facility_info.insert_show(data);
                        //block_info = 0 :블럭 info 에 대한 정보
                        if(this.block_info === 0)//
                            this.$refs.facility_info.insert_show(data);
                        else if(this.block_info === 1){
                            this.draw_feat = feat ;
                            this.$refs.Block_info.insert_show(data);                           
                        }
                        /*
                        else if(this.block_info === 2){ //동수두 등록시
                            //this.draw_feat = feat ;
                            this.$refs.Hydraulic_info.insert_show(data);                           
                        }
                        */
                        this.change_map_mode()
                     }
                        //this.add_modify_features.push({type: 'add', feature: e.feature, code: e.target.layer.get('code'), f_type: e.target.layer.get('f_type')})

                })
            }
        },
     //누수음 type =1 인경우 guide: 선택된 방의 누수음을 뿌려준다. 
      //2024.06.15
        init_guide_leak_layer(leak_work_list, type){
   
            //leak_layer 삭제
            var l_layer = this.heat_layer ;
            if(l_layer != null) {
                var source_h = l_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.map.removeLayer(l_layer) ;
                l_layer = null;
            }

            if(type === "1"){
                var extent = this.map.getView().calculateExtent(this.map.getSize());
                let p = ''
                if(Number(this.w_type)===100 ) p='&w_idx='+leak_work_list
                    //strngth 값을 가져와 뿌려준다.
                // var layer = CreateVectorLayer({name:'leakage', code: '100', f_type: '', opacity: 0.6, style: this.leak_style}, this.URL+'api/get_geojson?layer=location_info'+p+'&leak_limit_count='+this.leak_limit_count+'&leak_cal='+this.leak_cal)
                let surl = '';
                var layer = null;
                if(Number(this.w_type)===100 ){
                surl =this.URL+'api/get_geojson?layer=location_info'+p+'&leak_limit_count='+this.leak_limit_count+'&leak_cal='+this.leak_cal+'&leak_type=LEAK';
                layer = CreateVectorLayer_f({name:'leakage_1', code: '101', f_type: '', opacity: 0.6, style: this.quality_style,surl:surl,epsg : this.epsg1},extent) 
                }
                this.heat_layer = layer;        
                this.map.addLayer(layer);       
                layer.setVisible(true) ;
            }
       
        },        
        //누수음 레이어를 상세보기로 들어갈때 호출한다. 지도의 일정크기의 데이터를 가져와 뿌려준다.
        init_reload_leak_layer(){
           //value값 말풍선 삭제
            if(this.marker_show !== 0){
                this.marker_show = 1;
                this.marker_value();
            }
            //leak_layer 삭제
            var l_layer = this.leak_layer ;
            if(l_layer != null) {
                var source_h = l_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.map.removeLayer(l_layer) ;
                l_layer = null;
            }

       
            var extent = this.map.getView().calculateExtent(this.map.getSize());
            let p = ''
            if((Number(this.w_type)===100 || Number(this.w_type)===101)&&(this.widx!==undefined)) p='&w_idx='+this.widx
                //strngth 값을 가져와 뿌려준다.
            // var layer = CreateVectorLayer({name:'leakage', code: '100', f_type: '', opacity: 0.6, style: this.leak_style}, this.URL+'api/get_geojson?layer=location_info'+p+'&leak_limit_count='+this.leak_limit_count+'&leak_cal='+this.leak_cal)
            let surl = '';
            var layer = null;
            if(Number(this.w_type)===100 ){
               surl =this.URL+'api/get_geojson?layer=location_info'+p+'&leak_limit_count='+this.leak_limit_count+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type;
               layer = CreateVectorLayer_f({name:'leakage', code: '100', f_type: '', opacity: 0.6, style: this.leak_style,surl:surl,epsg : this.epsg1},extent) 
            }else if(Number(this.w_type)===101 ){
               //  layer = CreateVectorLayer({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6,style: this.leak_recov_style }, this.URL+'api/get_geojson?layer=leak_recovery'+p+'&limit_count='+this.leak_limit_count)
               surl =  this.URL+'api/get_geojson?layer=leak_recovery'+p+'&limit_count='+this.leak_limit_count ;
               layer = CreateVectorLayer_f({name:'recovery', code: '101', f_type: '', opacity: 0.6, style: this.leak_recov_style,surl:surl,epsg : this.epsg1},extent) 
            }
            this.leak_layer = layer;        
            this.map.addLayer(layer);       
            layer.setVisible(true) ;
       
        },
        //로거,모니터링 상세보기로 들어갈때 지도에 원레이어로 값을 뿌려준다.
        // 지도의 일정크기의 데이터를 가져와 뿌려준다.
        init_reload_pressure_layer(){
            //leak_layer 삭제
            var l_layer = this.leak_layer ;
            if(l_layer != null) {
                var source_h = l_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.map.removeLayer(l_layer) ;
                l_layer = null;
            }
            l_layer = this.leak_value_layer ;
            if(l_layer != null) {
                var source_h1 = l_layer.getSource();
                var hfeatures1 = source_h1.getFeatures();
                for(var kk=0; kk< hfeatures1.length ; kk++) {
                    source_h1.removeFeature(hfeatures1[kk]);
                }
                this.map.removeLayer(l_layer) ;
                l_layer = null;
            }
          //102 m2 모니터링누수음 시연용고객사 : 거제
            var customer_id = sessionStorage.select_customer_id ; 
            if(sessionStorage.select_customer_id === "27" ){
                if(this.w_type === '102')  
                    customer_id = 6
           // else
           //     customer_id = 17    
                else if(this.w_type === '111')  
                    customer_id = 17    
                else if(this.w_type === '160' || this.w_type === '161'|| this.w_type === '104')  
                    customer_id = 1  
            }
            var extent = this.map.getView().calculateExtent(this.map.getSize());
            let p = ''
            let param =  {} ; 
            if(Number(this.w_type)===110){ 
               if((Number(this.w_type)===110)&&(this.widx!==undefined)) p='&w_idx='+this.widx                //strngth 값을 가져와 뿌려준다.
             
            //leak_cal (avg, min, max)  leak_type ( null: 원래의 값, HYDRAULIC :동수도)
               if(this.init_fidx === 0)
                 this.init_fidx = '';
               let surl =this.URL+'api/get_geojson?layer=press_info'+p+'&limit_count='+this.leak_limit_count+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_value='+this.leak_val+'&press_date='+this.time_30m+"&group_id="+this.init_fidx;
            //if(this.leak_limit_count !== undefined)
            //var l_extend = this.get_view_extent() 
               param =  {name:'pressure', code: '110', f_type: '', opacity: 0.6, style: this.press_style,surl:surl,epsg : this.epsg1}
            }else if(Number(this.w_type)===102){ //누수음 모니터링 값을 같이 뿌려준다.
                let surl =this.URL+'api/get_geojson?layer=m2_leak&customer_id='+customer_id+'&leak_type='+this.leak_type+'&leak_date='+this.time_30m;
                var param1 =  {name:'m2_leak', code: '102', f_type: '', opacity: 0.6, style: this.leak_style_h,surl:surl,epsg : this.epsg1}
                this.leak_value_layer = CreateVectorLayer_f(param1,extent)                   
                this.map.addLayer( this.leak_value_layer);       
                this.leak_value_layer.setVisible(true) ;
                param =  {name:'m2_leak', code: '102', f_type: '', opacity: 0.6, style: this.leak_style,surl:surl,epsg : this.epsg1}
            }else  if(Number(this.w_type)===111){ //압력모니터링 값을 가져온다.
               if((Number(this.w_type)===111)&&(this.widx!==undefined)) p='&w_idx='+this.widx                //strngth 값을 가져와 뿌려준다.
          
               let surl = this.URL+'api/get_geojson?layer=m2_press_info'+p+'&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_value='+this.leak_val+'&press_date='+this.time_30m;         
               param =  {name:'m2_press', code: '111', f_type: '', opacity: 0.6, style: this.press_style,surl:surl,epsg : this.epsg1}
            }else  if(Number(this.w_type)===160 || Number(this.w_type)===161  ){ // 유량
               p = 'flow'
               if(this.widx!==undefined && Number(this.w_type)===160 ) p='flow_logger&w_idx=' +this.widx                 //strngth 값을 가져와 뿌려준다.
               // leak_cal : mnf, flow
               let surl = this.URL+'api/get_geojson?layer='+p+'&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_date='+this.time_30m;         
               param =  {name:'flow', code: this.w_type, f_type: '', opacity: 0.6, style: this.press_style,surl:surl,epsg : this.epsg1}
            }else  if(Number(this.w_type)===104   ){ // 누수음로거 값을 가져온다.
               p = 'leak_logger'
               if(this.widx!==undefined && Number(this.w_type)===104 ) p='leak_logger&w_idx=' +this.widx                 //strngth 값을 가져와 뿌려준다.
               // leak_cal : mnf, flow
               let surl = this.URL+'api/get_geojson?layer='+p+'&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_date='+this.time_30m;         
               param =  {name:'leak_logger', code: this.w_type, f_type: '', opacity: 0.6, style: this.press_style,surl:surl,epsg : this.epsg1}
            }
            var layer = CreateVectorLayer_f(param,extent) 
            this.leak_layer = layer;        
            this.map.addLayer(layer);       
            layer.setVisible(true) ;
        },
        //레이어를 삭제하고 새롭게 그린다.
        init_clear_layer(remove_layer){
            var l_layer = remove_layer; //동수두 LAYER
            if(l_layer != null) {
                var source_h = l_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.map.removeLayer(l_layer) ;
                l_layer = null;
            }
        },
        //m2압력이랑, m2 동수두를 보여준다.         
         init_reload_hydrolic_layer(){
            //leak_value_layer,heat_layer  삭제
           // var l_layer = this.heat_layer ; //동수두 LAYER
            this.init_clear_layer(this.heat_layer)//동수두 LAYER clear
            this.init_clear_layer(this.corre_layer)//동수두 LAYER clear
            let p = ''
            let param =  {} ; 
            var extent = this.map.getView().calculateExtent(this.map.getSize());
            var customer_id = sessionStorage.select_customer_id ; 
            //동수두 point 표시
            let surl = this.URL+'api/get_geojson?layer=hydraulic_point'+p+'&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_value='+this.leak_val+'&press_date='+this.time_30m;         
            param =  {name:'hydraulic_point', code: '112', f_type: '', opacity: 0.6, style: this.press_style,surl:surl,epsg : this.epsg1}
            var layer = CreateVectorLayer_f(param,extent) 
            this.heat_layer =  layer;        
            this.map.addLayer(layer);       
            layer.setVisible(true) ;

                     //block show, block_style            
            surl = this.URL+'api/get_geojson?layer=hydraulic_point&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_value=2&press_date='+this.time_30m;         
            param =  {name:'hydraulic_point', code: '112', f_type: '', opacity: 0.4, style: this.block_style,surl:surl,epsg : this.epsg1}
            var layer2 = CreateVectorLayer_f(param,extent) 
            this.corre_layer = layer2;        
            this.map.addLayer(layer2);       
            layer2.setVisible(true) ; 

         },
         init_reload_hydraulic_layer1(){
            
            this.init_clear_layer(this.leak_layer)//동수두 LAYER clear
            this.init_clear_layer(this.leak_value_layer)//동수두 LAYER clear
            //동수두 라인표시
            var extent = this.map.getView().calculateExtent(this.map.getSize());
            var customer_id = sessionStorage.select_customer_id ; 
            //라인을 가져온다.
            let surl = this.URL+'api/get_geojson?layer=hydraulic_point&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_value=1&press_date='+this.time_30m;         
            let param =  {name:'hydraulic_point', code: '112', f_type: '', opacity: 0.6, style: this.hydraulic_line_style,surl:surl,epsg : this.epsg1}
            var layer1 = CreateVectorLayer_f(param,extent) 
            this.leak_layer = layer1;        
            this.map.addLayer(layer1);       
            layer1.setVisible(true) ;

   

            //m2 압력표시
            surl = this.URL+'api/get_geojson?layer=m2_press_info&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type=HYDRAULIC&press_value='+this.leak_val+'&press_date='+this.time_30m;         
            param =  {name:'m2_press', code: '111', f_type: '', opacity: 0.6, style: this.press_style,surl:surl,epsg : this.epsg1}
            var layer2 = CreateVectorLayer_f(param,extent) 
            this.leak_value_layer = layer2;        
            this.map.addLayer(layer2);       
            layer2.setVisible(true) ;            
        }, 
        get_edit_layers_m() {
            let lyrs = this.get_layers()
            let ll = []
            for(let i=1; i<lyrs.length; i++) {
                if(parseInt(lyrs[i].get('code'))>=100) continue
                ll.push(lyrs[i])
            }
            return ll
        },
        edit_cancel() {
            this.map_mode = 'select'
            this.visible_edit = false
            this.edit_end()

            /*
            let lyr = this.get_edit_layers_m()
            for(let i=0; i<lyr.length; i++) {
                lyr[i].getSource().clear()
                lyr[i].getSource().refresh()
            }
            */

        },
        change_xy(item){
              let writer = new GeoJSON();
              item.feature = JSON.parse(writer.writeFeature(item.feature))
         
                if(item.code===0 ){ //line일 경우
                    for(let j=0; j<item.feature.geometry.coordinates.length; j++) {
                        var cod = item.feature.geometry.coordinates[j] ;
                        item.feature.geometry.coordinates[j] = proj4(this.map.getView().getProjection().getCode(), 'EPSG:4326', cod)
                    }
                }else if(item.code===7 || item.code===9 || item.code===11){
                     var cod_block = item.feature.geometry.coordinates ;

                     for(let k=0; k<cod_block.length; k++) {//블럭갯수
                        var chang_block_d=cod_block[k] ;
                        for(let l=0; l<chang_block_d.length; l++) { //블럭안에 point갯수
                             chang_block_d[l] = proj4(this.map.getView().getProjection().getCode(), 'EPSG:4326', chang_block_d[l])
                        }
                        cod_block[k] = chang_block_d ;
                     }
                }else{
                    var cod_point = item.feature.geometry.coordinates ;
                    item.feature.geometry.coordinates = proj4(this.map.getView().getProjection().getCode(), 'EPSG:4326', cod_point)                   
                }

            return item ;
        },

        //맵 초기화 함수
        async init_map() {
            let epsg = 'EPSG:5181';
            let page = ""+document.location.href ;
            //Bing map = EPSG:5181
            //Daum map = EPSG:3857
            this.layer_setting_stlye_list=[];          
            this.layer_setting_stlye_el_list =[];
            let setting = this.$store.setting
            if(!setting) {
           //     let res = await this.$http.get(this.URL+'api/get_setting?m_id='+localStorage.m_id)
                let res = await this.$http.get(this.URL+'api/get_setting?m_id='+sessionStorage.m_id)
                setting = res.data
                this.$store.setting = setting
            }

            if(setting.map_name!=='Daum')  epsg = 'EPSG:3857';
            if(this.map===null) {
                //console.log('Map 호출!!');
                this.map =  CreateMap('map_');
            }
            this.$store.map = this.map;
            this.epsg1 = epsg;
            /*
            if(this.reset){
                this.$refs.map = this.map ;
                this.reset = false ;
            }
            */
            this.map.setView(new View({ minZoom: 0, maxZoom: 30, projection: epsg, zoom: 6, autoUpdateSize: true }));
            // for(let i=(this.map.getLayers().getLength() - 1); i>=0; i--) {
            //     this.map.removeLayer(this.map.getLayers().item(i));
            // }

            this.map.getView().setConstrainResolution(true) //정수형 Level로 설정

            if(setting.map_name==='Daum' )
               // this.map.addLayer(CreateVWorldMap('vworld'))
                this.map.addLayer(CreateDaumMap('Daum', setting.map_type==='Standard'))
            else
               // this.map.addLayer(CreateBingMap('AnjxN9iboQHO1FlneioeNego_amBwaRDjrJnxj-ftnc2BLTNOA72ORqEOgl0vSIa', 'Bing', setting.map_type==='Standard'));
                this.map.addLayer(CreateBingMap('AunJ5mIjFLbHY7rKboyASGQFmcCyuWZweHUx8t3cqnG8Ydro5Se6eJXxtIqru8Yc', 'Bing', setting.map_type==='Standard'));
            for(let i=0; i<setting.layers.length; i++) {
                let minZoom = (setting.map_name==='Daum') ? setting.layers[i].daum_minzoom : setting.layers[i].bing_minzoom
                let layer = null
                if(setting.layers[i].code===LAYER_LEAK) { //누수음 지도 표시
                    let p = ''
                    if((Number(this.w_type)===setting.layers[i].code)&&(this.widx!==undefined)) p='&w_idx='+this.widx
                    //strngth 값을 가져와 뿌려준다.
                 //2020-11-03 수정
                    if(page.indexOf("leak-master") !== -1) { 
                        layer = CreateVectorLayer_f({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6, style: this.leak_style}, this.URL+'api/get_geojson?layer=location_info'+p+'&leak_limit_count='+this.leak_limit_count)
                    }else{
                        layer = CreateVectorLayer_f({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6, style: this.leak_style}, null)
                    }
                   // layer = CreateVectorLayer({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6, style: this.leak_style}, this.URL+'api/get_geojson?layer=location_info'+p+'&leak_limit_count='+this.leak_limit_count)
                    //우선 url 주소를 데이터 없는 것을 호출
                    this.leak_layer = layer;
                }
               
                else if(setting.layers[i].code===101)  {
                  // 누수복구 2021-03-04
                   // let p = ''
                  //  if((Number(this.w_type)===setting.layers[i].code)&&(this.widx!==undefined)) p='&w_idx='+this.widx
                   //
                   // layer = CreateVectorLayer({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6,style: this.leak_recov_style }, this.URL+'api/get_geojson?layer=leak_recovery'+p+'&limit_count='+this.leak_limit_count)
                     layer = CreateVectorLayer_f({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6, style: this.leak_recov_style}, null)
                     this.leak_layer = layer;
                }
                else if(setting.layers[i].code===102)  {
                 
                    let p = ''
                    if((Number(this.w_type)===setting.layers[i].code)&&(this.widx!==undefined)) p='&w_idx='+this.widx
                    //시연용 고객사 등록
                     var customer_id_leak = sessionStorage.select_customer_id ; 
                     if(sessionStorage.select_customer_id === "27"){
                       customer_id_leak = 6
                     }
                    layer = CreateVectorLayer({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6,style: this.leak_style }, this.URL+'api/get_geojson?layer=m2_leak'+p+'&customer_id='+customer_id_leak+'&limit_count='+this.leak_limit_count)
                    this.leak_layer = layer;
                    this.leak_value_layer = CreateVectorLayer({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6,style: this.leak_style_h }, this.URL+'api/get_geojson?layer=m2_leak'+p+'&customer_id='+customer_id_leak+'&limit_count='+this.leak_limit_count)
                  //  this.leak_value_layer = layer;

                   
                }               
                else if(setting.layers[i].code===110)  {
                  // 20200708 주석처리 쿼리문에러
                    //let p = ''
                    //if((Number(this.w_type)===setting.layers[i].code)&&(this.widx!==undefined)) p='&w_idx='+this.widx
                    //layer = CreateVectorLayer({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6,style: this.press_style }, this.URL+'api/get_geojson?layer=press_info'+p+'&limit_count='+this.leak_limit_count)
                      layer = CreateVectorLayer_f({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6, style: this.leak_style}, null)

                }
                else if(setting.layers[i].code===111)  {
                  // M2 지도 압력데이터
                    //let p = ''
                    //if((Number(this.w_type)===setting.layers[i].code)&&(this.widx!==undefined)) p='&w_idx='+this.widx
                    //layer = CreateVectorLayer({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6,style: this.press_style }, this.URL+'api/get_geojson?layer=m2_press_info'+p+'&customer_id='+sessionStorage.select_customer_id+'&limit_count='+this.leak_limit_count)
                    layer = CreateVectorLayer_f({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6,style: this.press_style }, null)
                }   
                //water_flow 추가
                else if(setting.layers[i].code===160 || setting.layers[i].code===161 || setting.layers[i].code===104)  {
                    layer = CreateVectorLayer_f({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6,style: this.press_style }, null)
                }                
                else if(setting.layers[i].code===120) {
                  // 20200708 주석처리 쿼리문에러
                  //  let p = ''
                  //  if((Number(this.w_type)===setting.layers[i].code)&&(this.widx!==undefined)) p='&w_idx='+this.widx
                   // layer = CreateVectorLayer({name: setting.layers[i].name, code: setting.layers[i].code, f_type: '', opacity: 0.6, style: this.accounts_style},  this.URL+'api/get_geometry?layer='+setting.layers[i].code+p)
                }
                else if(setting.layers[i].code===130) {
                    //console.log('');
                }
                else if(setting.layers[i].code===140) {
                    //console.log('');
                }
                else if(setting.layers[i].code===LAYER_HEAT) {
                    //console.log('');

                    if(page.indexOf("leak") !== -1) {
                       continue;
                    }
                    layer = CreateHeatmapLayer({name: 'Heat', maxZoom: 14, opacity: 0.5, gradient: ['#f00', '#ff0', '#0f0', '#0ff', '#00f'], blur: 15, radius: 5}, this.URL+'api/get_geometry?layer=17') ;
                    this.heat_layer = layer ;
                }
                else {
                    let f_type = ''
                    if(setting.layers[i].f_type!==undefined) f_type = setting.layers[i].f_type
                    let color = setting.layers[i].color
                    let fill = new Fill({color: color });
                    let stroke = new Stroke({ color: color,  width: 1.25 });
                  
                    if(setting.layers[i].code===2){
                        this.water_layer_color = color
                    }else if(setting.layers[i].code===1){
                        this.value_layer_color = color
                    }
                    let style = new Style({image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 5  }), fill: fill,  stroke: stroke  });
                    let surl = this.URL+'api/get_geometry?call_type=1&layer='+setting.layers[i].code +"&customer_id="+sessionStorage.customer_id;
                    if(sessionStorage.customer_id === "1")
                        surl = this.URL+'api/get_geometry?call_type=1&layer='+setting.layers[i].code +"&customer_id="+sessionStorage.select_customer_id;

                  
                    if(setting.layers[i].code===7){
                        stroke = new Stroke({ color: '#000000',  width: 3.0 });                   
                        style = new Style({image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 5  }), fill: fill,  stroke: stroke  });
                          //인도네시아 수용가 조사여부,블럭 색깔표시
                         if(page.indexOf("id.neverlosewater.com") !== -1 || page.indexOf("localhost") !== -1) { 
                             this.layer_setting_stlye_list.push({name: setting.layers[i].name, code: setting.layers[i].code, f_type: f_type, minZoom: minZoom, opacity: 0.4,style: this.block_style,surl:surl,epsg : epsg, visible:setting.layers[i].visible})
                         }else{
                            this.layer_setting_stlye_list.push({name: setting.layers[i].name, code: setting.layers[i].code, f_type: f_type, minZoom: minZoom, opacity: 0.4,style: style,surl:surl,epsg : epsg, visible:setting.layers[i].visible})
                         }
                    }else if(setting.layers[i].code===1 || setting.layers[i].code===2) {
                          //인도네시아 수용가 조사여부,블럭 색깔표시
                        if(page.indexOf("id.neverlosewater.com") !== -1 || page.indexOf("localhost") !== -1) {                         
                          //  if(sessionStorage.select_customer_id  === "7" && setting.layers[i].code===2){ //2023-07-25 추가
                            if( setting.layers[i].code===2){ //2023-07-25 추가
                                //minZoom: 18, 그전 갯수 많을때
                                this.layer_setting_stlye_list.push({name: setting.layers[i].name, code: setting.layers[i].code, f_type: f_type, minZoom: 16,  style: style,surl:surl,epsg : epsg, visible:setting.layers[i].visible})
                            }else{
                                this.layer_setting_stlye_list.push({name: setting.layers[i].name, code: setting.layers[i].code, f_type: f_type, minZoom: minZoom,style: this.block_style,surl:surl,epsg : epsg, visible:setting.layers[i].visible})     
                            }
                        }else{
                            this.layer_setting_stlye_list.push({name: setting.layers[i].name, code: setting.layers[i].code, f_type: f_type, minZoom: minZoom,  style: style,surl:surl,epsg : epsg, visible:setting.layers[i].visible})
                        }
                        if (setting.layers[i].code===1){
                            surl = this.URL+'api/get_geojson?layer=facility&customer_id='+sessionStorage.select_customer_id;
                            this.layer_setting_stlye_el_list.push({name: setting.layers[i].name, code: setting.layers[i].code, f_type: f_type, minZoom: minZoom, style: this.facility_el_style,surl:surl,epsg : epsg, visible:true})            
                        }
                    }else{
                        this.layer_setting_stlye_list.push({name: setting.layers[i].name, code: setting.layers[i].code, f_type: f_type, minZoom: minZoom,  style: style,surl:surl,epsg : epsg, visible:setting.layers[i].visible})
                     
                    }
                    
                   
                    layer = CreateVectorLayer_f({name: setting.layers[i].name, code: setting.layers[i].code, f_type: f_type, minZoom: minZoom, style: style, visible:setting.layers[i].visible},null) ;
                    
                    this.layer_list.push(layer) ;
                }

                if(layer===null) continue;

                if( layer.values_.name === 'Heat') {
                    //console.log(layer);
                    //layer.dispose();
                    //continue;
                }

                this.map.addLayer(layer);

                if([100, 101,102,104, 110,111,112, 120, 130, 140, 150,160,161].indexOf(setting.layers[i].code)>-1) setting.layers[i].visible = false

                layer.setVisible(setting.layers[i].visible)
              
                if(this.w_type!==undefined) {
                    //100:leakage
                    //110:pressure
                    //120:water_meter
                    //130:water quality
                    //140:maintenance
                    //150:customer_voice_info
                    if(Number(this.w_type)===setting.layers[i].code) {
                        setting.layers[i].visible = true
                        layer.setVisible(setting.layers[i].visible)
                    }
                }
            }

            let param = '';
  
            if(typeof(this.widx)!=='undefined') param = 'w_idx='+this.widx
            if(typeof(this.w_type)!=='undefined') {
                if(this.w_type === '-1000'){
                    param += 'w_type=-1000&customer_id='+sessionStorage.select_customer_id 
                }else{
                //if(param==='') param = 'w_type='+this.w_type+'&customer_id='+sessionStorage.select_customer_id 
                //else param += '&w_type='+this.w_type+'&customer_id='+sessionStorage.select_customer_id 
                    if(param==='') param = 'w_type='+this.w_type
                    else param += '&w_type='+this.w_type
                }
            }

            if(typeof(this.$route.query.extent)!== 'undefined') {
                this.set_fit_extent(this.$route.query.extent.map(Number))
            }else if(this.w_type === '111' || this.w_type === '102' || this.w_type === '161'|| this.w_type === '160'|| this.w_type === '112') { //m2 압력데이터
                 //102 m2 모니터링누수음 시연용고객사 : 거제
                 var customer_id = sessionStorage.select_customer_id ; 
                 if(sessionStorage.select_customer_id === "27" ){
                   if(this.w_type === '102') //누수음 모니터링  
                      customer_id = 6
                   else if(this.w_type === '111') //압력모니터링 
                      customer_id = 17    
                   else if(this.w_type === '161') //유량모니터링 
                      customer_id = 1    
   
                 }
                 param += '&customer_id='+customer_id
                 this.set_fit_extent(await this.get_extent(param))
                 if(this.w_type === '102' && this.leak_value_layer !== null){
                    this.map.addLayer(this.leak_value_layer);
                 }
            }else {
                // 2020-10-28 
                //param = ''; // 임시로 기존에 설정된 위치로 설정하도록 처리
               // if(!(page.indexOf("leak-detail") !== -1 || page.indexOf("leak-analysis") !== -1 || ((typeof(this.widx)!=='undefined')&& (typeof(this.w_type)!=='undefined')) )) { 
               //        param = ''; // 임시로 기존에 설정된 위치로 설정하도록 처리
               // }
                this.set_fit_extent(await this.get_extent(param))
            }
            if(this.add_scale === null){
                var v_scale = new ScaleLine({units:'metric',className:'ol-scale-line', target: document.getElementById("scaleline")})
                this.add_scale = v_scale;
                this.map.addControl(this.add_scale);
            }

        }, //init_map 끝
        //누수음쪽 value값 별도로 표시
        leak_fix_size(){
            
            ++this.fix_size 
            if(this.fix_size%2 === 1 ){
               this.b_str_circle = this.$t('map.circle_rate') ;
            }else{
               this.b_str_circle = this.$t('map.circle_fix') ;
            }
            this.init_reload_leak_layer()
        },
        marker_value(){
            ++this.marker_show
            var l_v_layer = this.leak_value_layer ;
            // //102 m2 모니터링누수음 시연용고객사 : 거제
            var customer_id = sessionStorage.select_customer_id ; 
            if(sessionStorage.select_customer_id === "27" ){
              if(this.w_type === '102')  
                 customer_id = 6
           // else 
           //     customer_id = 17    
              else if(this.w_type === '111')  
                 customer_id = 17    
              else if(this.w_type === '161')  
                 customer_id = 1  
            } 

            if(l_v_layer != null) {
                var source_h = l_v_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.map.removeLayer(l_v_layer) ;
                l_v_layer = null;
            } 
            if(this.marker_show %2 === 0) return  
            var extent = this.map.getView().calculateExtent(this.map.getSize());
            let p = ''
            let surl=''
            var layer = null;
            if(Number(this.w_type)===100){
                if(this.widx!==undefined) p='&w_idx='+this.widx
                surl =this.URL+'api/get_geojson?layer=location_info'+p+'&leak_limit_count='+this.leak_limit_count+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type;             
                layer = CreateVectorLayer_f({name:'leakage', code: '100', f_type: '', opacity: 0.6, style: this.leak_style_h,surl:surl,epsg : this.epsg1},extent);
               
            }else if (Number(this.w_type)===110){
                if(this.widx!==undefined) p='&w_idx='+this.widx;
                surl =this.URL+'api/get_geojson?layer=press_info'+p+'&limit_count='+this.leak_limit_count+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_value='+this.leak_val+'&press_date='+this.time_30m;
                //if(this.leak_limit_count !== undefined)
                //var l_extend = this.get_view_extent() 
                layer = CreateVectorLayer_f({name:'press', code: '110', f_type: '', opacity: 0.6, style: this.press_style_h,surl:surl,epsg : this.epsg1},extent)
               
            }else if (Number(this.w_type)===111){
               if(this.widx!==undefined) p='&w_idx='+this.widx                //strngth 값을 가져와 뿌려준다.          
               surl = this.URL+'api/get_geojson?layer=m2_press_info'+p+'&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_value='+this.leak_val+'&press_date='+this.time_30m;         
               //param =  {name:'m2_press', code: '111', f_type: '', opacity: 0.6, style: this.press_style,surl:surl,epsg : this.epsg1}                
               layer = CreateVectorLayer_f({name:'m2_press', code: '111', f_type: '', opacity: 0.6, style: this.press_style_h,surl:surl , epsg : this.epsg1},extent)
               
            }else if (Number(this.w_type)===112){
               if(this.widx!==undefined) p='&w_idx='+this.widx                //strngth 값을 가져와 뿌려준다.          
               surl = this.URL+'api/get_geojson?layer=hydraulic_point'+p+'&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_value='+this.leak_val+'&press_date='+this.time_30m;                      
               layer = CreateVectorLayer_f({name:'hydraulic_point', code: '112', f_type: '', opacity: 0.6, style: this.press_style_h,surl:surl , epsg : this.epsg1},extent)              
            
            }else if (Number(this.w_type)===160 || Number(this.w_type)===161 ){
                 p="flow"  
               if(this.widx!==undefined && Number(this.w_type)===160){
                 p='flow_logger&w_idx='+this.widx                //strngth 값을 가져와 뿌려준다.                
               }
               surl = this.URL+'api/get_geojson?layer='+p+'&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_date='+this.time_30m;                        
               layer = CreateVectorLayer_f({name:'water_flow', code: this.w_type, f_type: '', opacity: 0.6, style: this.press_style_h,surl:surl , epsg : this.epsg1},extent)
            } else if(this.widx!==undefined && Number(this.w_type)===104){ //누수음로거
               surl = this.URL+'api/get_geojson?layer=leak_logger&w_idx='+this.widx+'&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&press_date='+this.time_30m;                        
               layer = CreateVectorLayer_f({name:'leak_logger', code: this.w_type, f_type: '', opacity: 0.6, style: this.press_style_h,surl:surl , epsg : this.epsg1},extent)
            } else if(Number(this.w_type)===102){ // m2 모니터링
               surl = this.URL+'api/get_geojson?layer=m2_leak&customer_id='+customer_id+'&leak_cal='+this.leak_cal+'&leak_type='+this.leak_type+'&leak_date='+this.time_30m;                        
               layer = CreateVectorLayer_f({name:'m2_leak', code: this.w_type, f_type: '', opacity: 0.6, style: this.press_style_h,surl:surl , epsg : this.epsg1},extent)

            }
             this.leak_value_layer = layer;
            this.map.addLayer(this.leak_value_layer);
            this.leak_value_layer.setVisible(true) ; 
        },
        correlation_layer(){
            //로거랑, 상관식계산값 둘다를 가져와서 삭제한후 다시 넣어준다.

            var l_v_layer = this.heat_layer ;          
            var l_v_layer1 = this.leak_value_layer
            if(l_v_layer != null) {
                var source_h = l_v_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.map.removeLayer(l_v_layer) ;
                l_v_layer = null;
            } 
            if(l_v_layer1 != null) {
                var source_h1 = l_v_layer1.getSource();
                var hfeatures1 = source_h1.getFeatures();
                for(var i=0; i< hfeatures1.length ; i++) {
                    source_h1.removeFeature(hfeatures1[i]);
                }
                this.map.removeLayer(l_v_layer1) ;
                l_v_layer1 = null;
            } 
           // if(this.marker_show %2 === 0) return  
            var extent = this.map.getView().calculateExtent(this.map.getSize());
            let p = ''
            if(Number(this.w_type)===104){
                 let fill = new Fill({color:'#0000ff' }); //푸른색으로 셋팅
                let stroke = new Stroke({ color: '#0000ff',  width: 4.0 });
                let image = new Icon( ({
                    anchor: [0.5, 0.5],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: require("@/assets/images/sub/epanet/leak_1.png"),
                    scale: 1,
                    offset: [0,0],
                    })) ;
                let style =  [new Style({image: image, fill: fill,  stroke: stroke  })
                             ,new Style({image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 3  }), fill: fill,  stroke: stroke  })]
                if(this.widx!==undefined) p='&w_idx='+this.widx
                //상관식에 계산된 포인트 값을 가져온다.
                let surl =this.URL+'api/get_geojson?layer=correlation'+p+'&customer_id='+sessionStorage.select_customer_id;
                //if(this.leak_limit_count !== undefined)
                //var l_extend = this.get_view_extent() 
                var layer = CreateVectorLayer_f({name:'correlation', code: 1004, f_type: '', opacity: 0.6, style: style,surl:surl,epsg : this.epsg1},extent)
                this.heat_layer = layer;
                //상관식에 사용된 라인 위치값을 가져와서 뿌려준다.
                let surl1 =this.URL+'api/get_geojson?layer=correlation'+p+'&customer_id='+sessionStorage.select_customer_id+'&leak_cal=1';
                //if(this.leak_limit_count !== undefined)
                //var l_extend = this.get_view_extent() 
                var layer1 = CreateVectorLayer_f({name:'correlation', code: 1004, f_type: '', opacity: 0.6, style: style,surl:surl1,epsg : this.epsg1},extent)
                this.leak_value_layer = layer1;
            }  
            this.map.addLayer(this.heat_layer);
            this.heat_layer.setVisible(true) ;
            this.map.addLayer(this.leak_value_layer);
            this.leak_value_layer.setVisible(true) ;  
        },        
        //누수로거 상관식 누수추정위치와 라인 그려주기        
        draw_point(line_geometry, length){
            var l_v_layer = this.corre_layer ;
           alert("calculated length : " +length);
            if(l_v_layer != null) {
                var source_h = l_v_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.map.removeLayer(l_v_layer) ;
                l_v_layer = null;
            } 
         
           var featureLine = new Feature({
                // geometry: new LineString([icoord1,icoord2])
                 geometry: line_geometry
           });          
            var cod_arr = []
            
            var cal_leng= 0; 
            var circleFeature = null;
            var i = 0 ;
            for(let j=0; j<line_geometry.flatCoordinates.length; j+=2) {
                var cod1 = line_geometry.flatCoordinates[j] ;
                var cod2 = line_geometry.flatCoordinates[j+1] ;
                cod_arr.push([cod1,cod2])
                
                if(j>1){
                    var featureLine1 = new Feature({
                        geometry: new LineString([cod_arr[i],cod_arr[i+1]])
                    // geometry: line_geometry
                    });
                    i = i+1 ;
                    cal_leng = featureLine1.getGeometry().getLength();
                    length -= cal_leng ; // 남은길이가 5cm, 최종 라인이 7cm
                    if(length <= 0){
                        //최종라인에 두점만 가져와서 계산한다.
                        var icoord1 =  featureLine1.getGeometry().flatCoordinates //[x,y,x1,y1]
                        
                        var cor_x = icoord1[0] - (icoord1[0]-icoord1[2])*(length+cal_leng)/(cal_leng)
                        var cor_y = icoord1[1] - (icoord1[1]-icoord1[3])*(length+cal_leng)/(cal_leng)
                        circleFeature = new Feature({
                            geometry: new Point([cor_x,cor_y]),
                        });
                        break;
                    }
                }
            }

        
       
            var vectorLine = new VectorSource({});
            vectorLine.addFeature(featureLine);
            vectorLine.addFeature(circleFeature);
            //vectorLine.addFeature(featurePoint);
            let fill = new Fill({color:'#00ff00' });
            let stroke = new Stroke({ color: '#00ff00',  width: 1.25 });
            this.corre_layer = new VectorLayer({
                source: vectorLine,
                style: new Style({
                    image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 10  }),                    
                    fill: fill,
                    stroke: stroke
                })
            });
        
            this.map.addLayer(this.corre_layer);
            this.corre_layer.setVisible(true) ; 
        },
        //두지점사이 라인 그리기
        draw_linestring(icoord1,icoord2){

            var l_v_layer = this.corre_layer ;
            // //102 m2 모니터링누수음 시연용고객사 : 거제
           //alert(icoord1,icoord2)
            if(l_v_layer != null) {
                var source_h = l_v_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.map.removeLayer(l_v_layer) ;
                l_v_layer = null;
            } 
           if(icoord1 === null) return;
           var featureLine = new Feature({
                 geometry: new LineString([icoord1,icoord2])
           });          
          /*  
          var featurePoint = new Feature( new Point(icoord1));
          
          var cor_x = icoord1[0] - (icoord1[0]-icoord2[0])/2
          var cor_y = icoord1[1] - (icoord1[1]-icoord2[1])/2
          const circleFeature = new Feature({
                geometry: new Circle([cor_x,cor_y], 50),
           });
           */
            //var featurePoint = new Feature({
            //     geometry: new Point(icoord1)
           // });
            //featurePoint.transform('EPSG:4326', this.epsg1);
           // featureLine.transform('EPSG:4326', this.epsg1);            
            var vectorLine = new VectorSource({});
            vectorLine.addFeature(featureLine);
            //vectorLine.addFeature(circleFeature);
            //vectorLine.addFeature(featurePoint);
            let fill = new Fill({color:'#00ff00' });
            let stroke = new Stroke({ color: '#00ff00',  width: 1.25 });
            //{name:'leakage', code: '100', f_type: '', opacity: 0.6, style: this.leak_style,surl:surl,epsg : this.epsg1},extent) 
            this.corre_layer = new VectorLayer({name:'draw_pipe', code: '1000', 
                source: vectorLine,
                style: new Style({
                    image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 5  }),                    
                    fill: fill,
                    stroke: stroke
                })
            });
        
            this.map.addLayer(this.corre_layer);
            this.corre_layer.setVisible(true) ; 
        },
        marker_point(cor){
           var l_v_layer = this.corre_layer ;
           let fill = new Fill({color:'#00ff00' });
           let stroke = new Stroke({ color: '#00ff00',  width: 1.25 });
           let image = new Icon( ({
                anchor: [.5, 1],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: require("@/assets/images/Common/table/map_icon1.png"),
                scale: 0.5,
                offset: [0,0],
            }))           
            // //102 m2 모니터링누수음 시연용고객사 : 거제
           //alert(icoord1,icoord2)
            if(l_v_layer != null) {
                var source_h = l_v_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.map.removeLayer(l_v_layer) ;
                l_v_layer = null;
            } 
           if(cor === null) return;            
             var circleFeature = new Feature({
                  geometry: new Point(cor),
             });

            var vectorLine = new VectorSource({});
            vectorLine.addFeature(circleFeature);
             this.corre_layer = new VectorLayer({
                source: vectorLine,
                style: new Style({
                   // image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 5  }),                    
                    image: image,
                    fill: fill,
                    stroke: stroke
                })
            });
            this.map.addLayer(this.corre_layer);
            this.corre_layer.setVisible(true) ; 
        }

      
    },


   
    created() {
        this.$EventBus.$off('reload_map');
        this.$EventBus.$off('sidebar_resize');
        this.$EventBus.$on('reload_map', () => {
          //  this.$route.query.extent = this.get_view_extent()

            if (this.map === null) {
                console.log('Map beforeDestroy : map is null!!')
            }
            else{
                //console.log('layers count : '+ this.map.getLayers().getLength());
                this.reset = true ;
                let item = null;
                for(let i=(this.map.getLayers().getLength() - 1); i>=0; i--) {
                    item = this.map.getLayers().item(i);

                 //   if( item.values_.name === 'Daum' || item.values_.name === 'Bing') {
                        //console.log('dispose call');
                        //console.log(item.getSource().format);
                        // console.log(item.values_.source);
                //         console.log(item.getSource());

               //     }else{


                        this.map.removeLayer(item);
                        item.getSource().clear();
                        item.getSource().dispose();
                        item.dispose();
                        item.values_ = null;
                        item = null;
                 //   }

                    //console.log(item);
                    //this.map.removeLayer(this.map.getLayers().item(i));
                }
                //this.$store.map = null;
               // this.$refs.map = null;
               // this.map = null;
            }
          //  this.reset = false ;

            this.init_map()
        })
        this.$EventBus.$on('sidebar_resize', () => {
            setTimeout(()=>{
                this.update_size()
            }, 500)
        })

    },
    beforeDestroy() {

     //   this.$EventBus.$off('sidebar_resize');
        ++ this.rroof ;
        console.log("beforeDestroy call" +  this.rroof );
        if (this.map === null) {
            console.log('Map beforeDestroy : map is null!!')
        }
        else{
            //console.log('layers count : '+ this.map.getLayers().getLength());

            let item = null;
            for(let i=(this.map.getLayers().getLength() - 1); i>=0; i--) {
                 item = this.map.getLayers().item(i);

                 if( item.values_.name === 'Heat') {
                    //console.log('dispose call');
                    //console.log(item.getSource().format);
                    // console.log(item.values_.source);
                    // console.log(item.getSource());

                }
                this.map.removeLayer(item);
                item.getSource().clear();
                item.getSource().dispose();
                item.dispose();
                item.values_ = null;
                item = null;
                //console.log(item);
                //this.map.removeLayer(this.map.getLayers().item(i));
            }

            this.$store.map = null;
            this.$refs.map = null;
            this.map = null;       
            this.leak_style =  null; //누수만 스타일 변수
            this.leak_style_h =  null; //누수 말풍선 스타일 추가
            this.hydraulic_line_style = null;
            this.press_style_h = null;
            this.block_style = null;
            this.leak_recov_style = null; //누수복구 스타일
            this.press_style = null; //압력 스타일 변수
            this.accounts_style = null; //계량기 사용량 스타일 변수
            this.select_features = null;
            this.is_edit_mode = null;
            this.interactions_modify = null; //도형수정 인터렉션
            this.interactions_draw = null;    //도형 추가 인터렉션
            this.snap_features = null;       //스냅 도형들
            this.add_modify_features = null;   //편집 또는 수정된 도형들 확인 버튼을 누르면 서버에 저장.
            this.select_info = null;
            this.layer_setting_stlye_list=null;
            this.heat_layer = null;
            this.leak_value_layer = null;
        }
    },
    mounted () {
        let textFill = new Fill({color: '#000'});
        let textStroke = new Stroke({color: 'rgb(250, 250, 250)', width: 2});
        let textFill1 = new Fill({color: '#fff'});
        let textStroke1 = new Stroke({color: 'rgb(0, 0, 0)', width: 3});    
        this.facility_el_style = (f) => {
         
            let v =  Number(f.get('elevation'));
            let vmax = Number(f.get('max')) ;
            let vmin =  Number(f.get('min')) ;
            let radius = 15;
            let opercity = 0.5;
            let circleFill = new Fill({color: [83,107,57,opercity]})
			let txt = '';
	
            //
            if(f.get('layer_type') === 1007 || f.get('layer_type') === 1008){
                v = f.get('idx');  
                var block_color = f.get('color');  

                //let block_color = [[253, 238, 0,0.4],[127, 255, 212,0.4],[0, 127, 255,0.4],[255, 32, 82,0.4],[244, 194, 194,0.4],[200, 200, 255,0.4],[100,255,204,0.4]]
                if(block_color !== null && block_color !==undefined)
                   circleFill  = new Fill({color:block_color})
                txt = f.get('block_name') ;
                
            }else{
                if(typeof(v)==='number') {

                    let cha = vmax - vmin ; //
                    // 원 크키가 최소크기보다 작으면 최소크기로 설정
                    if( vmax === v){
                            opercity = 1.0
                    }else if(vmin === v){
                        opercity = 0.1
                    }else{
                        opercity = ((v-vmin)/cha).toFixed(1) 
                    }
                        

                    }
                    
                    //text문자 숨기기
                    if (typeof(v)==='number') {
                        txt = v.toFixed(0);
                    }
            }
			return new Style({ image: new CircleStyle({ radius: radius, fill: circleFill }),  fill: circleFill, text: new Text({ text: txt, font:'Bold 12px Verdana',fill: textFill, stroke: textStroke }), stroke: new Stroke({ color: 'rgb(255, 204, 0)', width: 1 })	});
        }    

        this.leak_style = (f) => {
            let legend = this.$store.setting.wave
            let circleFill = new Fill({color: legend.color})
            let v = f.get('strength');
            let vmax = f.get('streng_max') ;
            let gstroke = new Stroke({ color: legend.color, width: 0 })
            if(f.get('leak_type') === 'L' ){
                circleFill = new Fill({color: legend.color1})
                gstroke = new Stroke({ color: legend.color1, width: 0 })
            }else if(f.get('leak_type') === 'M' || f.get('leak_type') === 'LS'  ){
                circleFill = new Fill({color: "#FF7E00"})
                gstroke = new Stroke({ color: "#FF7E00", width: 0 })
            }
            if(f.get('is_ground') === 1 ){
                gstroke = new Stroke({ color: '#000', width: 1 })
            }
         
			let radius = 1;
            let txt = '';
         
			if(typeof(v)==='number' ) {
               let minSize = Number(legend.wave_energy.start_size);
               let maxSize = Number(legend.wave_energy.end_size);

               radius = minSize + maxSize / vmax * v ;
               // 원 크키가 최소크기보다 작으면 최소크기로 설정
               if( radius < minSize)
                    radius = minSize;
            }

            if(this.fix_size%2 === 1){ //원 사이즈 고정 사이즈
                radius = Number(legend.wave_energy.fix_size);
                if(f.getGeometry().getType() === 'Point'){
                    var center = f.getGeometry().getCoordinates()
                    var circle = new Circle(center, radius/2);
                    f.setGeometry(circle)
                }
             
                return new Style({image: new CircleStyle({ fill: circleFill,  stroke: gstroke,   radius: 5  }), fill: circleFill,  stroke: gstroke  });
            }

            
         
            return new Style({ image: new CircleStyle({ radius: radius, fill: circleFill,stroke:gstroke, }),
             text: new Text({ text: txt,font:'Bold 11px Verdana', fill: textFill, stroke: textStroke}),	});
        }
        this.press_style_h = (f) => {
           // let legend = this.$store.setting.wave
            //let circleFill = new Fill({color: legend.color})
            //let circleFill = new Fill({color: '#FF9100'})
            let image = new Icon( ({
                anchor: [.5, 1],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: require("@/assets/images/ballon1.png"),
                scale: 0.5,
                offset: [0,0],
            }))
            let v = f.get('max');
            //let vmax = f.get('streng_max') ;
           // let radius = 1;
            let txt = '';         
            if( (typeof(v)==='number')) {
                //txt = f.get('l_idx')+"=>"+v.toFixed(1) ;
               txt = f.get('p_name') === undefined ? f.get('name') : f.get('p_name') ;
              //txt = 1
			}		
			return new Style({ image: image, text: new Text({ text: txt, font:'Bold 12px Verdana',fill: textFill1, stroke: textStroke1, offsetY: -17, offsetX: 0}), stroke: new Stroke({ color: 'rgb(255, 204, 0)', width: 1 })	});
        } 
        this.hydraulic_line_style = (f) => {
             let circleFill = new Fill({color: '#FF9100'})
             var styles = [                 
                 new Style ({ image: new CircleStyle({ radius: 5, fill: circleFill }), text: new Text({ text: "", font:'12px Verdana', fill: textFill1, stroke: textStroke1 }),
                  stroke: new Stroke({ color: 'rgb(0, 0, 255)', width: 4 })	})
                // new Style({ image: image, text: new Text({ text: txt, font:'12px Verdana', fill: circleFill,
                // stroke: textStroke,offsetY: -10, offsetX: 0 }), stroke:  new Stroke({ color: 'rgb(0, 0, 255)', width: 3 })		})
              ];
              var geometry = f.getGeometry();
              geometry.forEachSegment(function(start, end) {
                var dx = start[0] - end[0];
                var dy = start[1] - end[1];
                var rotation = Math.atan2(dy, dx);
                
                var dx1 = (end[0] + start[0])/2;
                var dy1 = (end[1] + start[1])/2;
                var dx2 = (end[0] + dx1)/2;
                var dy2= (end[1] + dy1)/2;
              
                
                // arrows
                // arrows
                styles.push(new Style({
                 // geometry: new Point(end),
                  geometry: new Point([dx2,dy2]),
                  image: new Icon({
                  // src: 'http://openlayers.org/en/v3.14.2/examples/data/arrow.png',
                   // src:  require("@/assets/images/sub/epanet/arrow.png"),
                    src:  require("@/assets/images/sub/epanet/arrow.png"),
                    anchor: [0.75, 0.5],
                    rotateWithView: false,
                    rotation: -rotation,
                     scale: 1.0,
                  })
                }));
              
                dx1 = (start[0] + dx1)/2;
                dy1 = (start[1] + dy1)/2;
                styles.push(new Style({
                 // geometry: new Point(end),
                  geometry: new Point([dx1,dy1]),
                  image: new Icon({
                  // src: 'http://openlayers.org/en/v3.14.2/examples/data/arrow.png',
                    src:  require("@/assets/images/sub/epanet/arrow.png"),
                    anchor: [0.75, 0.5],
                    rotateWithView: false,
                    rotation: -rotation,
                     scale: 1.0,
                  })
                }));
              });
              return styles
        } 
        this.leak_style_h = (f) => {
            let legend = this.$store.setting.wave
            //let circleFill = new Fill({color: legend.color})
            //let circleFill = new Fill({color: '#FF9100'})
            let image = new Icon( ({
                anchor: [.5, 1],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                src: require("@/assets/images/ballon1.png"),
                scale: 0.5,
                offset: [0,0],
            }))
            
            let v = f.get('strength');
            //let vmax = f.get('streng_max') ;
           // let radius = 1;
            let txt = '';
            if(typeof(v)==='number') {
                image = new Icon( ({
                    anchor: [.5, 1],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: require("@/assets/images/ballon.png"),
                    scale: 0.5,
                    offset: [0,0],
                    })) ;
        
			}
            if(legend.visible_wave_energy && (typeof(v)==='number')) {
           
                txt = v.toFixed(1) ;
            }	
            
            var off_x = 0;
            var off_y = 0;
            if(f.get('layer_type') === 100 && f.get('f_idx') !== null && this.init_fidx ===f.get('f_idx') && Number(f.get('l_idx')) > this.move_idx){ //같은장소, 위치에 연속 2개이상 누수음 수집시 표시
         //   if(f.get('layer_type') === 100 && f.get('f_idx') !== null && this.init_fidx ===f.get('f_idx') ){
               // txt = this.init_fidx + ":"+f.get('strength')
                  image = new Icon( ({
                    anchor: [0.5, 0],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: require("@/assets/images/ballon2.png"),
                    scale: 0.5,
                    offset: [0,0],
                    })) ;
                off_y = 15;
                this.init_fidx = f.get('f_idx') 
                this.move_idx = Number(f.get('l_idx'))    
                return new Style({ image: image,
                text: new Text({ text: txt,font:'Bold 12px Verdana', fill: textFill, stroke: textStroke, offsetY: off_y, offsetX: off_x ,}),	});
            }else if(f.get('layer_type') === 100 &&  Number(f.get('w_idx')) === 1781 ){
                this.init_fidx = f.get('f_idx') 
                this.move_idx = Number(f.get('l_idx'))
                var device_sn = f.get('device_sn')
                var styles_1 = [
                    new Style({ image: image, text: new Text({ text: txt, font:'Bold 12px Verdana',fill: textFill, stroke: textStroke, offsetY: -15, offsetX: 0}), stroke: new Stroke({ color: 'rgb(255, 204, 0)', width: 1 })	})

                ]
                var image1 = new Icon( ({
                    anchor: [0.5, 0],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: require("@/assets/images/ballon1.png"),
                    scale: 0.5,
                    offset: [0,0],
                    })) ;
                    
                styles_1.push(new Style({ image: image1, text: new Text({ text: device_sn, font:'Bold 12px Verdana',fill: textFill, stroke: textStroke, offsetY: 15, offsetX: 0}), stroke: new Stroke({ color: 'rgb(255, 204, 0)', width: 1 })	}))    
                return  styles_1
            }else{ //첫번째 시설정보에 누수음 수집
                this.init_fidx = f.get('f_idx') 
                this.move_idx = Number(f.get('l_idx'))
                return new Style({ image: image, text: new Text({ text: txt, font:'Bold 12px Verdana',fill: textFill, stroke: textStroke, offsetY: -15, offsetX: 0}), stroke: new Stroke({ color: 'rgb(255, 204, 0)', width: 1 })	});
            }
        }
         this.leak_recov_style = (f) => {
            let legend = this.$store.setting.wave
            let circleFill = new Fill({color: legend.color1})
            let v = f.get('leak_a_value');
            let vmax = f.get('streng_max') ;
		
			let radius = 1;
			let txt = '';
			if(typeof(v)==='number') {
             //   radius = LinearInterpolation(v, legend.start_value, legend.start_size, legend.end_value, legend.end_size)
               let minSize = Number(legend.wave_energy.start_size);
               let maxSize = Number(legend.wave_energy.end_size);

               radius = minSize + maxSize / vmax * v ;
               // 원 크키가 최소크기보다 작으면 최소크기로 설정
               if( radius < minSize)
                    radius = minSize;
			}
            if(legend.visible_wave_energy && (typeof(v)==='number')) {
				txt = v.toFixed(1);
            }
            if(this.fix_size%2 === 1){ //원 사이즈 고정 사이즈
                radius = Number(legend.wave_energy.fix_size);
             //   if(f.getGeometry().getType() === 'Point'){
             //       var center = f.getGeometry().getCoordinates()
             //       var circle = new Circle(center, 100/2);
             //       f.setGeometry(circle)
             //   }
         
                return new Style({image: new CircleStyle({ fill: circleFill,  radius: 20  }), fill: circleFill,  text: new Text({ text: txt,font:'12px Verdana', fill: textFill1, stroke: textStroke1 }),stroke: new Stroke({ color: 'rgb(255, 204, 0)', width: 1 })  });
            }            
			return new Style({ image: new CircleStyle({ radius: radius, fill: circleFill }), text: new Text({ text: txt,font:'12px Verdana', fill: textFill1, stroke: textStroke1 }), stroke: new Stroke({ color: 'rgb(255, 204, 0)', width: 1 })	});
        }
        this.press_style = (f) => {
            let pressure = this.$store.setting.pressure
            let legend = pressure.legend
            let circleFill = new Fill({color: pressure.color})
            let opt = legend.value
            //leak_cal (avg, min, max) 
            if(this.leak_cal != 0)
               opt = this.leak_cal 
            let v = f.get(opt);
            let vmax = f.get('streng_max') ;
			let radius = 1;
            let txt = '';
            
           // if(typeof(f.get('mnp_boundary'))==='number' && f.get('mnp_boundary') > f.get('mnp') ){
           // }
            if(typeof(f.get('e_content')) !== "undefined" && f.get('e_content') !== null ){   
                circleFill = new Fill({color: 'red'})
               // gstroke = new Stroke({ color: legend.color1, width: 0 })
            }
            let cur_page = ""+document.location.href ;
            var circle_image = null
			if(typeof(v)==='number' && (cur_page.indexOf("hydraulic") === -1 ||f.get('layer_type') ===112 ||f.get('layer_type') ===104) ) {
             //   radius = LinearInterpolation(v, legend.start_value, legend.start_size, legend.end_value, legend.end_size)
               let minSize = Number(legend.start_size);
               let maxSize = Number(legend.end_size);
               if(f.get('layer_type') ===112){ //hydraulic
                   minSize = 10
                   maxSize = 30
               }else if(f.get('layer_type') ===104){
                   minSize = 5
                   maxSize = 30
                  circleFill = new Fill({color: 'green'})  
                  if(f.get('leak_result') === 'L' ){
                      circleFill = new Fill({color: 'red'})                      
                  }else if(f.get('leak_result') === 'M' || f.get('leak_type') === 'LS'  ){
                      circleFill = new Fill({color: "#FF7E00"})                      
                  }
               }
               radius = minSize + maxSize / Math.abs(vmax) * Math.abs(v) ;
               // 원 크키가 최소크기보다 작으면 최소크기로 설정
               if( radius < minSize)
                    radius = minSize;
               if(radius > maxSize)    
                    radius = maxSize;
			}else{
                if(cur_page.indexOf("hydraulic") !== -1 ){
                    radius = 10
                   // circleFill = new Fill({color: 'blue'})
                }else{
                    circle_image = new Icon( ({
                    anchor: [.5, 1],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: require("@/assets/images/Common/table/map_icon1.png"),
                    scale: 0.5,
                    offset: [0,0],
                    })) 
                    return
                }
            }
            if(f.get('layer_type') ===112){
                 circleFill = new Fill({color: '#000000'})
                // radius = 10 ;
                if(typeof(f.get('mnp_boundary'))==='number' && (f.get('mnp_boundary') < f.get('mnp') || f.get('mnp_boundary') < v ) ){
                     circleFill = new Fill({color: 'red'})
                }
            }
            if( typeof(v)==='number') {
               if( this.p_value !== 0 && f.get('layer_type') ===111){
                 txt = ''
               }else{ 
                 // if(v < 10) 
                    txt = v.toFixed(1);
               }
			}
			return new Style({ image: circle_image === null ? new CircleStyle({ radius: radius, fill: circleFill }) : circle_image, text: new Text({ text: txt, font:'12px Verdana', fill: textFill1, stroke: textStroke1 }), stroke: new Stroke({ color: 'rgb(0, 0, 255)', width: 3 })	});
        }
        this.block_style = (f) => {
            let stroke = new Stroke({ color: '#000000',  width: 2.0 });
            let v = f.get('id');        
            let fill =  new Fill({color: '#000000'});
             let txt = '';
            //let legend = pressure.legend
                             // 노랑, 연초록, 파랑, 선홍색,인디핑크, 00ff80

            let block_color = ['#fdee00','#7fffd4','#007fff','#ff2052','#f4c2c2','#C8C8FF','#FFAA28']
            if(f.get('gis_layer_id') === 2){
               fill = new Fill({color: this.water_layer_color})
               stroke = new Stroke({ color:  this.water_layer_color,  width: 2.0 });
               if(f.get('isDen') === "true" ) 
                 stroke = new Stroke({ color: '#000000',  width: 3.0 });   
               let v1 =Number(f.get('ext_field3'));  
               if(v1> 1) {
                  txt = v1.toFixed(0);
               }
            }else if(f.get('gis_layer_id') === 1){
               fill = new Fill({color: this.value_layer_color})
               stroke = new Stroke({ color:  this.value_layer_color,  width: 2.0 });
               if(f.get('ext_field1') === "1" ){ 
                  fill = new Fill({color: '#a78806'})  
                  stroke = new Stroke({ color:   '#a78806',  width: 2.0 });
                 // fill = new Fill({color:  this.value_layer_color, opacity : 0.2})  
               }
               if(f.get('isDenDate') >"2022-09-19" ) 
                 stroke = new Stroke({ color: '#000000',  width: 3.0 });   
             
            }else if(f.get('layer_type') === 112){
                fill = new Fill({color: block_color[Number(f.get('idx')) % 7]})
                if(typeof(f.get('mnp_boundary'))==='number' && (f.get('mnp_boundary') < f.get('mnp') || f.get('mnp_boundary') < f.get('10min')) ){
                     fill = new Fill({color: 'red'})
                     this.select_block_id = f.get('idx')
                }
                
                stroke = new Stroke({ color: '#000000',  width: 2.0 });
                txt = f.get('group_name')
                return new Style({image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 5  }), fill: fill,  stroke: stroke ,text: new Text({ text: txt, font:'20px Verdana', fill: textStroke1, stroke: textStroke1,offsetY: -10, offsetX: 0  }) });
            }else{
                fill = new Fill({color: block_color[Number(v) % 7]})
                stroke = new Stroke({ color: '#000000',  width: 2.0 });
                txt = f.get('name')
                return new Style({image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 5  }), fill: fill,  stroke: stroke ,text: new Text({ text: txt, font:'20px Verdana', fill: textStroke1, stroke: textStroke1,offsetY: -10, offsetX: 0  }) });
            }

      
            //if(Number(v) % )
           
			return new Style({image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 5  }), fill: fill,  stroke: stroke ,text: new Text({ text: txt, font:'12px Verdana', fill: textFill1, stroke: textStroke1,offsetY: -10, offsetX: 0  }) });
        }   
        this.accounts_style = (f) => {
            let accounts = this.$store.setting.accounts
            let legend = accounts.legend
            let circleFill = new Fill({color: accounts.color})
			let v = f.get('usage');
			let radius = 1;
			let txt = '';
			if(typeof(v)==='number') {
				radius = LinearInterpolation(v, legend.start_value, legend.start_size, legend.end_value, legend.end_size)
			}
            if(accounts.visible_value && (typeof(v)==='number')) {
				txt = v.toFixed(1);
			}
			return new Style({ image: new CircleStyle({ radius: radius, fill: circleFill }), text: new Text({ text: txt, fill: textFill, stroke: textStroke }), stroke: new Stroke({ color: 'rgb(255, 204, 0)', width: 1 })	});
        }

        this.quality_style = (f) => {
            /*
            let quality = this.$store.setting.quality
            let legend = quality.legend
            let circleFill = new Fill({color: quality.color})
			let v = f.get(legend.value);
			let radius = 1;
			let txt = '';
			if(typeof(v)==='number') {
				radius = LinearInterpolation(v, legend.start_value, legend.start_size, legend.end_value, legend.end_size)
			}
            if(quality.visible_value && (typeof(v)==='number')) {
				txt = v.toFixed(1);
            }
            */
            let legend = this.$store.setting.wave
            let circleFill = new Fill({color: legend.color})
           // let v = f.get('strength');
           // let vmax = f.get('streng_max') ;
            let gstroke = new Stroke({ color: legend.color, width: 0 })
            if(f.get('leak_type') === 'L' ){
                circleFill = new Fill({color: '#6464FA'})
                gstroke = new Stroke({ color: '#6464FA', width: 0 })
            }
         
			let radius = 10;
           
         
            /*
            radius = Number(legend.wave_energy.fix_size);
            if(f.getGeometry().getType() === 'Point'){
                var center = f.getGeometry().getCoordinates()
                var circle = new Circle(center, radius/2);
                f.setGeometry(circle)
            }
             */
            return new Style({image: new CircleStyle({ fill: circleFill,  stroke: gstroke,   radius: radius  }), fill: circleFill,  stroke: gstroke  });
         
		}

        //110:pressure
        //120:water_meter
        //130:water quality
        //140:maintenance
        //150:customer_voice_info


        this.init_map().then(()=>{
            this.select_features = new Select({hitTolerance: 4})
            this.select_features.setActive(true)
            this.map.addInteraction(this.select_features)

        // 마우스 오버시 스타일 지정
            let cur_page = ""+document.location.href ;
			/*
            var selectPointerMove = null;
            if(cur_page.indexOf("leak") !== -1) {
                selectPointerMove = new Select(
                {
                    condition: pointerMove,
                    style : this.leak_style_h
                });
                selectPointerMove.setActive(true) ;
                this.map.addInteraction(selectPointerMove);

            }
			*/
            // interaction 추가
            /*
            var selectPointerMove = new Select();
            selectPointerMove.setActive(true) ;
            this.map.addInteraction(selectPointerMove);
            */
            this.interactions_modify = new Modify({features: this.select_features.getFeatures()})
            this.map.addInteraction(this.interactions_modify)
            this.interactions_modify.setActive(false)


            this.interactions_modify.on('modifyend', (e) => {
                let fs = e.features.getArray()               
                for(let i=0; i<fs.length; i++) {
                    let layer = this.select_features.getLayer(fs[i])
                  //  let item = this.add_modify_features.find((item) => { if(item.feature === fs[i]) return item })
                    //if(layer.get('code') < 100 || layer.get('code') === 1000){

                    //100번 이하만 수정해 준다.
                    //다른 layer추가시     
                    if(layer.get('code') < 100 ){    
                        if(layer !== null) {
                            let param = {} ;
                            param["customer_member_id"] = sessionStorage.m_idx;
                            if(layer.get('code')===0 ){
                                var len =  fs[i].getGeometry().getLength();                          
                                len = Math.round(len * 100) / 100
                                param["length"] = len ;
                            }
                            let data = {type: 'modify', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type'),params:param} ;
                        //  this.add_modify_features.push({type: 'modify', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type')})
                            data = this.change_xy(data) ;

                            let con_test = confirm("Do you want to modify?");
                            if(con_test){
                                //this.$http.get(this.URL+'api/delete_facility?idx='+f.get('idx'));
                                this.$http.get(this.URL+'api/add_modify_feature?json='+encodeURIComponent(JSON.stringify(data))).then((res) => {
                                    console.log(res.data);
                                    this.facility_refresh(0);
                                })

                            }else{
                                this.facility_refresh(2);
                            }

                        }
                    //2022.11.21 추가   
                    //동수두 분석   
                    }else if(layer.get('code') === "112" ){  
                        let param = {} ;
                        param["customer_member_id"] = sessionStorage.m_idx;
                     
                        let data = {type: 'modify',type1: 'location', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type'),params:param} ;
                    //  this.add_modify_features.push({type: 'modify', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type')})
                        data = this.change_xy(data) ;

                        let con_test = confirm("Do you want to modify?");
                        if(con_test){
                            //this.$http.get(this.URL+'api/delete_facility?idx='+f.get('idx'));
                            this.$http.get(this.URL+'api/add_hydraulic_feature?json='+encodeURIComponent(JSON.stringify(data))).then((res) => {
                                console.log(res.data);
                         
                            })

                        }
                    }else if(layer.get('code') === "1007" ){  
                        let param = {} ; 
                        let data = {type: 'modify',type1: 'location', feature: fs[i], code: 7, f_type: layer.get('f_type'),params:param} ;
                        //this.add_modify_features.push({type: 'modify', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type')})
                        data = this.change_xy(data) ;
                         let i_idx =  fs[i].get('idx')
                         param["id"] = i_idx
                         param["location"]  = data.feature.geometry
                        let con_test = confirm("Do you want to modify?");
                        if(con_test){
                            //this.$http.get(this.URL+'api/delete_facility?idx='+f.get('idx'));
                            this.$http.patch(this.URL+'api/work_block/'+i_idx,param).then((res) => {
                                console.log(res.data);
                         
                            })

                        }
                    }else if(layer.get('code') === "1008" ){  
                        let param = {} ; 
                        let data = {type: 'modify',type1: 'location', feature: fs[i], code: 7, f_type: layer.get('f_type'),params:param} ;
                        //this.add_modify_features.push({type: 'modify', feature: fs[i], code: layer.get('code'), f_type: layer.get('f_type')})
                        data = this.change_xy(data) ;
                         let i_idx =  fs[i].get('idx')
                         param["id"] = i_idx
                         param["location"]  = data.feature.geometry
                        let con_test = confirm("Do you want to modify?");
                        if(con_test){
                            //this.$http.get(this.URL+'api/delete_facility?idx='+f.get('idx'));
                            this.$http.patch(this.URL+'api/waterpressure/groups/location/'+i_idx,param).then((res) => {
                                console.log(res.data);
                         
                            })

                        }
                    }
                }
            })





            this.interactions_draws = []
            for(let i=1; i<(this.map.getLayers().getLength()-1); i++) {
                let layer = this.map.getLayers().item(i)
                let type = ''
                let code = layer.get('code')
                switch ( code ) {
                    case LAYER_PIPE:
                        type = 'LineString'
                        break
                    case LAYER_VALVE:
                        type = 'Point'
                        break
                    case LAYER_META:
                        type = 'Point'
                        break
                    case LAYER_PUMP:
                         type = 'Point'
                        break
                    case LAYER_HYDRANT:
                    case LAYER_JUNCTION:
                    case LAYER_WATER_TREATMENT_PLANT:
                    case LAYER_WATER_RESERVOIR:
                    case LAYER_GAUGE:
                    case LAYER_OTHER:
                        type = 'Point'
                        break;
                    case LAYER_BLOCK:
                        type = 'Polygon'
                        break
                    case LAYER_PUMPING_STATION:
                    case LAYER_CHAMBER:
                        type = 'Polygon'
                        break
                    default:
                        continue
                }

                let draw = new Draw({source: layer.getSource(),  type: type})
                draw.layer = layer
                this.interactions_draws.push({draw: draw, code: layer.get('code'), name: layer.get('name')})
                this.map.addInteraction(draw)
                draw.setActive(false)
                draw.on('drawend', (e) => { //this.init_map().then
                     draw.setActive(false)
                     var feat = e.feature;
                     var len = 100;
                     //linestring 생성시 길이가 2m 이하로 생성하는 pipe는 제거 기존것을 수정
                     if(e.target.layer.get('code')===0 ){
                          len = feat.getGeometry().getLength();
                          len = Math.round(len * 100) / 100
                     }
                     if(len >= 1){
                        var data = {type: 'add', feature: e.feature, code: e.target.layer.get('code'), f_type: e.target.layer.get('f_type'),length:len} ;
                        data = this.change_xy(data) ;
                      //  this.$refs.facility_info.insert_show(data);
                        if(this.block_info === 0)
                            this.$refs.facility_info.insert_show(data);
                        //else if(this.block_info === 2) //code ==> 동수두 등록일 시
                        //    this.$refs.hydraulic_info.insert_show(data);
    
                        else{
                            this.draw_feat = feat ;
                            this.$refs.Block_info.insert_show(data);                           
                        }
                      
                        this.change_map_mode()
                     }
                        //this.add_modify_features.push({type: 'add', feature: e.feature, code: e.target.layer.get('code'), f_type: e.target.layer.get('f_type')})

                })
            }


            //feature 선택시 popup창 뜨워지는 부분
            this.select_features.on('select', ((e)=> {
                ////init_map.then
                if(!this.select_info) return
                if(e.selected.length<=0) return
                if(this.is_edit_mode) return
                let f = e.selected[0];

                //leakage 레이어 클릭시
                if(f.get('layer_type') === 100 && f.get('l_idx') !== undefined ){
                   //alert("현재페이지"+document.location.href);
                    this.leak_recov_idx = f.get('w_idx')    
                    this.leak_idx = f.get('l_idx');

                    if(cur_page.indexOf("leak-analysis1") !== -1 || cur_page.indexOf("leak-detail") !== -1  || cur_page.indexOf("leak-recovery") !== -1) { 
                        
                        this.$emit('onLeakMapClick') ;
                        this.marker_point([null,null])
                        return;
                    }else{
                       if(cur_page.indexOf("leak-analysis") !== -1) {
                        //this.show_leakage_info(f.get('l_idx'));
                        //var win = window.open("/leak_popup_info", "PopupWin", "width=500,height=600, status=no, menubar=no, toolbar=no");
                        //win.document.write("<p>새창에 표시될 내용 입니다.</p>");
                        var purl = "/leak_popup_info?idx="+f.get('l_idx') ;
                        window.open(purl, "PopupWin", "width=580,height=750,top=10, left=50,status=no, menubar=no, toolbar=no,resizable=yes, location=no");
                        }else{
                            this.show_leakage_info(f.get('l_idx'));
                            this.$emit('onLeakMapClick') ;
                        }
                    }
      
             
                } else if(f.get('layer_type') === 101 && f.get('l_idx') !== undefined ){
                   //alert("현재페이지"+document.location.href);

                   // let cur_page = ""+document.location.href ;
                    this.leak_idx = f.get('l_idx');
                    this.leak_recov_idx = f.get('id');
                    if(cur_page.indexOf("leak-recovery-detail") !== -1 || cur_page.indexOf("leak-recovery-map")) { 
                        this.$emit('onLeakMapClick') ;   
                        return;
                    }else{
                       if(cur_page.indexOf("leak-recovery-detail-map1") !== -1) { 
                        //this.show_leakage_info(f.get('l_idx'));  
                        var win = window.open("", "PopupWin", "width=580,height=750,top=10, left=50,resizable=yes, location=no");
                        win.document.write("<p>새창에 표시될 내용 입니다.</p>"); 
                        }else{
                            this.show_leakage_info(f.get('l_idx'));    
                            this.$emit('onLeakMapClick') ;     
                        }
                    }
                } else if(f.get('layer_type') === 102 && f.get('m2_id') !== undefined ){
                    this.leak_idx = f.get('m2_id');

                   this.leak_recov_idx = f.get('m2_point_id');
                  //  this.leak_recov_idx = f.get('idx');
                    if(cur_page.indexOf("leak-monitoring-detail-map1") !== -1) {                      
                          this.show_leakage_info_pop(f.get('m2_id'),2);    
                    }else{
                     
                        this.$emit('onLeakMapClick') ;    
                         return; 
                    }
                       
                //압력쪽 레이어 클릭시
                } else if((f.get('layer_type') === 111||f.get('layer_type') === 161 ) && f.get('tag_id') !== undefined ){
                    //alert("현재페이지"+document.location.href);                 
                    this.leak_idx = f.get('tag_id');
                    //this.leak_recov_idx = f.get('idx');                   
                    this.leak_recov_idx = f.get('name');
                    this.coord_map = f.get('geometry').flatCoordinates;  
                    if(cur_page.indexOf("hydraulic-monitoring") !== -1) {     
                        //팝업창으로 m2정보를 뿌려준다.
                       // alert("m2 point:"+this.leak_recov_idx) ;
                        this.$emit('onLeakMapClick1') ; 
                    }else{    
                        this.$emit('onLeakMapClick',f.get('mnp_boundary') ) ;                  
                    }
                } else if((f.get('layer_type') === 112 ) && f.get('root_id') !== undefined ){
                    //alert("현재페이지"+document.location.href);                 
                    this.leak_idx = f.get('idx');
                    //this.leak_recov_idx = f.get('idx');                   
                    this.leak_recov_idx = f.get('name');
                    this.coord_map = f.get('geometry').flatCoordinates;   
                                 
                    if(cur_page.indexOf("hydraulic-point-reg-map") !== -1) {                      
                         this.$emit( 'hydraulic_info_popup', this.leak_idx);
                    }else{
                     
                        this.$emit('onLeakMapClick') ;    
                         return; 
                    }

                } else if(f.get('layer_type') === 110 ||f.get('layer_type') === 160 || f.get('layer_type') === 104){
                  //  alert("pressure 2")
                   if(f.get('layer_type') === 110 ){
                    if(cur_page.indexOf("pressure-detail-map") !== -1) { 
                         this.leak_idx = f.get('p_idx') ;
                         this.leak_recov_idx = f.get('psr_file_path');  
                         this.move_idx =   f.get('group_id');     
                         this.p_name =   f.get('p_name');    
                         this.$emit('onLeakMapClick') ;      
                    }else{
                       // this.$refs.pressure_info.show(f.get('p_idx'))
                         this.leak_idx = f.get('p_idx') ;
                         this.leak_recov_idx = f.get('psr_file_path'); 
                         this.$emit('onLeakMapClick') ;  
                    }
                   }else{
                       this.leak_idx = f.get('p_idx') ;
                        if(f.get('layer_type') === 160)
                         this.leak_recov_idx = f.get('flw_file_path');  
                       else{
                        this.leak_recov_idx = f.get('ld_idx'); //logger_detail_id   
                        this.move_idx =   f.get('avg'); 
                       }
                       this.coord_map = f.get('geometry').flatCoordinates;   
                       this.$emit('onLeakMapClick') ;    
                   }
                }else if(f.get('layer_type') === 1004){
                    // alert("pressure 1 ")    
                    this.leak_idx = f.get('id');
                    // this.leak_recov_idx = f.get('name');                   
                    this.$emit('on_correlation_info_map',f) ;                 
                }else if(f.get('layer_type') === 1007){
                    // alert("pressure 1 ")    
                    this.leak_idx = f.get('idx');
                    // this.leak_recov_idx = f.get('name');                   
                    this.show_block_info(this.leak_idx);                 
                    
                 
                }else{
                    this.show_facility_info(f.get('id'))
                }
            }))

            this.map.on('pointermove', (e) => {
                this.$refs.map.style.cursor = this.map.hasFeatureAtPixel(this.map.getEventPixel(e.originalEvent), {hitTolerance: 4}) ? 'crosshair' : '';

            }); // this.map.on end

        }); // this.init_map().then 끝


    },
    components: {
      FacilityInfoType,
      DlgLeakageInfo: DlgLeakageInfo,
      DlgAddressList:DlgAddressList,
      //  DlgPressureInfo : DlgPressureInfo,
      //  DlgWaterFlowInfo:DlgWaterFlowInfo,
      DlgBlockInfo : DlgBlockInfo
    }
}
</script>

<style scoped lang="scss">
.search_div1{
    position: absolute; 
    right:10px;
    top: 5px;
    z-index: 200;   
}
.home_search{
    position: absolute; 
    left:470px;
    top: 7px;
    z-index: 200;    
}

.spn1 {
      float: left;
}
.map-size-trans {
  width: 100px;
  height: 30px;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  background: #f2f2f2;
  position: absolute;
  bottom: -0px;
  left: 50%;
  transform: translate(-50%, 0);
  .btn-size-view {
    width: 50%;
    height: 100%;
  }
  .btn-map-l {
    background: url('../assets/images/Common/table/up_btn.png') center no-repeat #f2f2f2;
    background-size: 11.5px 7.3px;
  }
  .btn-map-r {
    background: url('../assets/images/Common/table/down_btn.png') center no-repeat #f2f2f2;
    background-size: 11.5px 7.3px;
  }
}

/*
.ol-mouse-position{background:#9eeeff;background:rgba(158,238,255,.6);top:8px;right:8px;font-size:13px;position:absolute}
.ol-scale-line{position: absolute;left: 12px; bottom: 12px; z-index: 100;background:#9eeeff;background:rgba(158,238,255,.6);border-radius:4px;bottom:8px;right:40px;padding:2px;z-index:100;}
.ol-scale-line-inner{position: absolute;left: 12px; bottom: 12px; z-index: 100;border:1px solid #000;border-top:none;color:#000;font-size:10px;text-align:center;margin:1px;will-change:contents,width}
.ol-overviewmap{position:absolute;left:8px;bottom:8px} */
#scaleline {position: absolute;right: 20px; bottom: 12px; z-index: 100;border:1px solid #000;border-top:none;color:#000;font-size:10px;text-align:center;margin:1px;will-change:contents,width}
</style>

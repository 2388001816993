<template>
 
    <div class="table_block" @click="goRouter('/pumping-station-detail')" style='width:100%;'>
 
                     <div class="title_box">
                      <h3 class="fln" style="margin-bottom:10px;">{{ dma_name }}</h3>
                      <span class="date_01">{{ pump_date }}</span>
                      <div class="status_circle">
                        <!--
                        <div class="circle color_01">#1</div>
                        <div class="circle color_01">#2</div>
                        <div class="circle color_01">#3</div>
                        <div class="circle color_02">#4</div>
                        <div class="circle color_03">#5</div>
                        -->
                        <div :class=color1>#1</div>
                        <div :class=color2>#2</div>
                        <div :class=color3>#3</div>
                        <div :class=color4>#4</div>
                        <div :class=color5>#5</div>
                      </div>
                      <div class="water_div">
                        <div class="water_li bb0">
                          <div class="title1">Water flow(㎥/hr)</div>
                          <div class="con1">
                            <span class="num">{{flow_in}}</span>
                            <span class="unit">㎥/hr</span>
                          </div>
                          <div class="title1">Pressure in(kgf)</div>
                          <div class="con1">
                            <span class="num">{{press_in}}</span>
                            <span class="unit">kgf</span>
                          </div>
                        </div>
                        <div class="water_li">
                          <div class="title1">Total head(m)</div>
                          <div class="con1">
                            <span class="num">{{total_head}}</span>
                            <span class="unit">m</span>
                          </div>
                          <div class="title1">Pressure out(kgf)</div>
                          <div class="con1">
                            <span class="num">{{press_out}}</span>
                            <span class="unit">kgf</span>
                          </div>
                        </div>
                      </div>
                    </div>
      <div class="con_box">
        <table>
          <colgroup>
            <col style="width:16.666%">
          </colgroup>
          <thead>
          <tr>
            <th></th>
            <th class="bg">1</th>
            <th class="bg">2</th>
            <th class="bg">3</th>
            <th class="bg">4</th>
            <th class="bg">5</th>
            <th class="bg">6</th>
            <th class="bg">7</th>            
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="bg"><p class="unit"><span>Out_flow_day (㎥/day)</span></p></td>
            <td>{{flow[0]}}</td>
            <td>{{flow[1]}}</td>
            <td>{{flow[2]}}</td>
            <td>{{flow[3]}}</td>
            <td>{{flow[4]}}</td>
            <td>{{flow[5]}}</td>
            <td>{{flow[6]}}</td>            
          </tr>
          <tr>
            <td class="bg"><p class="unit"><span>Max_total_head (m)</span></p></td>
            <td>{{mnf[0]}}</td>
            <td>{{mnf[1]}}</td>
            <td>{{mnf[2]}}</td>
            <td>{{mnf[3]}}</td>
            <td>{{mnf[4]}}</td>
            <td>{{mnf[5]}}</td>
            <td>{{mnf[6]}}</td>            
          </tr>
          <tr>
            <td class="bg"><p class="unit"><span>Min_total_head (m)</span></p></td>
            <td>{{rate[0]}}</td>
            <td>{{rate[1]}}</td>
            <td>{{rate[2]}}</td>
            <td>{{rate[3]}}</td>
            <td>{{rate[4]}}</td>
            <td>{{rate[5]}}</td>
            <td>{{rate[6]}}</td>            
          </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_next"></div>
    </div>

 
</template>

<script>

export default {
    name: 'pumpCard',
    props: [
        'dma_name',
        'tag'
    ],
    data() {
        return {
            now_flow: null,
            now_mnf: null,
            pump_date:'',
            flow: ['0','0','0','0'],
            mnf: ['0','0','0','0'],
            rate:['0','0','0','0'],
            title_color: 'red',
            flow_in: '',
            total_head: '' ,
            press_in:'',
            press_out:'',
            color1: '' ,color2: '' ,color3: '' ,color4: '' ,color5: '' ,
            title_m : [
             {'name': '고남 가압장', 'flow_in': 17.0, 'total_head': 8.0,'press_in': 5.8 ,'press_out': 6.6 , 
               'color1': 'circle color_01' , 'color2': 'circle color_02' , 'color3': 'circle color_03' , 'color4': 'circle color_03', 'color5': 'circle color_03'},
             {'name': '소원 가압장(근흥 라인)', 'flow_in': 187.2, 'total_head': 50.0,'press_in': 5.7 ,'press_out': 10.7 , 
               'color1': 'circle color_01' , 'color2': 'circle color_01' , 'color3': 'circle color_01' , 'color4': 'circle color_02', 'color5': 'circle color_03'},
             {'name': '소원 가압장(소원라인)', 'flow_in': 180.5, 'total_head': 56.0,'press_in':4.0 ,'press_out': 9.6 , 
             'color1': 'circle color_01' , 'color2': 'circle color_02' , 'color3': 'circle color_01' , 'color4': 'circle color_02', 'color5': 'circle color_03'},
           ]            
        }
    },
    methods: {
        goRouter(path) {
            this.$router.push({path: path, query: { tag: this.tag , last_date:this.pump_date.split(' ')[0] }})
        },
        status(char) {
          if(char === "on" )
              return "circle color_01" ;
          else if(char === "off" )
              return "circle color_02" ;   
          else
              return "circle color_03" ;   

        }
    },
    mounted () {
      // this.chartData2.push( ts_mnf[i]['max_total_head'].toFixed(0));
      // this.chartData3.push(ts_mnf[i]['min_total_head'].toFixed(0));
        this.title_color = 'white'
        this.$http.get(this.URL+'api/API21/pump/list/'+this.tag).then((res) => {
              if(res.data.results[0].length >0) {
                  this.pump_date = res.data.results[0][0].date_time
                  this.flow_in = res.data.results[0][0].instant_flow_rate; 
                  this.total_head =  res.data.results[0][0].total_head;
                  this.press_in =  res.data.results[0][0].in_pressure; 
                  this.press_out = res.data.results[0][0].out_pressure;   
                  this.color1 = this.status(res.data.results[0][0].status_1);
                  this.color2 = this.status(res.data.results[0][0].status_2);
                  this.color3 = this.status(res.data.results[0][0].status_3);
                  this.color4 = this.status(res.data.results[0][0].status_4);
                  this.color5 = this.status(res.data.results[0][0].status_5);
              }
             
        });
        //title 데이터 셋팅 
        // for(var i= 0 ; i < this.title_m.length ;++i){
        //     if(this.dma_name ===this.title_m[i].name ){
        //       this.flow_in = this.title_m[i].flow_in; this.total_head = this.title_m[i].total_head;
        //       this.press_in = this.title_m[i].press_in; this.press_out = this.title_m[i].press_out;     
        //       this.color1 = this.title_m[i].color1; this.color2 = this.title_m[i].color2;
        //       this.color3 = this.title_m[i].color3; this.color4 = this.title_m[i].color4;this.color5 = this.title_m[i].color5;
             
        //       break;
        //     }
        // }


        //this.$http.get(this.URL+'api/get_pump_series?type=day&item=0&tag='+encodeURI(this.tag)+'&start_date=2020-09-01&limit=7').then((res) => {
          this.$http.get(this.URL+'api/API21/pump_day/'+this.tag+'?limit=7').then((res) => {
            this.flow = []
            this.mnf = []
            this.rate = []
            for(let i=0; i<res.data.results[0].length; i++) {
                if(i===0) {
                    this.now_flow = (res.data.results[0][i].flow_rate/24).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  //  this.now_mnf = res.data.rows[i].mnf.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                

                }
                this.flow.push( res.data.results[0][i].flow_rate.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") )
                this.mnf.push( res.data.results[0][i].max_total_head.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") )
                this.rate.push( res.data.results[0][i].min_total_head.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") )
            }
        });

    },
    beforeDestroy () {
    },
    components: {
    }
}


</script>

<style scoped lang="scss">
.table_block {
  &:nth-child(even) {
    margin-right: 0;
  }
 // background-color:#dde9f3 ;
  background-color:#94999d ;
}
.table_wrap .table_block .con_box {
    margin-top: 1%;
    margin-bottom: 1%;
}
.table_wrap .table_block .con_box table td {
    padding: 12px 0;
}
</style>

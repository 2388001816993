<template>
    <div>
        <b-modal
            ref="modal"
            title="Dataset Save"
            >
              <div style="margin: 0 30px 20px;">
                <b-row style="margin-top:20px;margin-bottom:30px;">
                       <b-col cols="4">
                         <span>Dataset Title</span>
                       </b-col>
                       <b-col cols="8">
                           <b-select v-model="dataset_id" :options='dataset_title' />
                       </b-col>
                </b-row>
                <div v-for="(item, index) in adv_search" :key="index">
                    <div v-if="item.control==='select'">
                        <b-select v-model="item.value" :options='item.options' />
                    </div>
                    <div v-else-if="item.control==='range'">
                        <b-row>
                            <b-col cols="5">
                                <b-input v-model="item.value" :placeholder="item.placeholder" class="modal__input"/>
                            </b-col>
                            <b-col cols="2" class="text-center pt-1">
                                <span>~</span>
                            </b-col>
                            <b-col cols="5">
                                <b-input v-model="item.value2" :placeholder="item.placeholder2"  class="modal__input"/>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-else-if="item.control==='date'">
                        <b-row>
                            <b-col cols="5">
                                <b-input v-model="item.value" type="date" :placeholder="item.placeholder"/>
                            </b-col>
                            <b-col cols="2" class="text-center pt-1">
                                <span>~</span>
                            </b-col>
                            <b-col cols="5">
                                <b-input v-model="item.value2" type="date" :placeholder="item.placeholder2"/>
                            </b-col>
                        </b-row>
                    </div>
                  
                </div>
               
          
              </div>
<!--
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="apply()">Apply</b-button>
                
                <b-button @click="cancel1()">Close</b-button>
            </template>
-->
           <template v-slot:modal-footer="{ ok, cancel, hide }">

              <b-row class="modal-footer-row m-0 leak_foot">
               <b-col sm="6">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">{{$t('work_info.close')}}</b-button>
               </b-col>
               <b-col sm="6">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('work_info.apply')}}</b-button>
               </b-col>              

             </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;
//import datetime from 'vuejs-datetimepicker';
export default {
    name: 'DlgWorkInfo',
    props: {
    },
    watch:{
     

    },
    data() {
          return {
          w_type : 100,
          w_idx : null,
          dataset_title : [{value:-1, text :"select dataset title"},
                            ],   
          dataset_id:-1,
          adv_search: [
             {
                field: "measure_datetime",
                control: "date",
                value: null,
                value2: null,
                placeholder: "From measure_date",
                placeholder2: "To measure_datet",
            },
                   
            {
                field: "wave_energy",
                control: "range",
                value: null,
                value2: null,
                placeholder: "From Strength",
                placeholder2: "To Strength",
            },
            /*
            {
                field: "leak_result",
                control: "select",
                value: null,
                //'Leak','Leak Inside','Usage','Other'
                options: [
                    { value: null, text: "Leak Result" },
                    { value: "L", text: "Leak" },
                    { value: "N", text: "No LEAK" },
                    { value: "M", text: "Meter is Running" },
                    { value: "U", text: "Unidentified" },
                ],
            },
            
            {
                field: "current_ai_result",
                control: "select",
                value: null,
                //'Leak','Leak Inside','Usage','Other'
                options: [
                    { value: null, text: "ai_leak_result" },
                    { value: "Leak", text: "Leak" },
                    { value: "No LEAK", text: "No LEAK" },
                ],
            },
            {
                field: "is_ground",
                control: "select",
                value: null,
                //'Leak','Leak Inside','Usage','Other'
                options: [
                    { value: null, text: "Ground Y/N" },
                    { value: "0", text: "No Ground" },
                    { value: "1", text: "Ground" },
                ],
            },
            {
                field: "is_dataset",
                control: "select",
                value: null,
                //'Leak','Leak Inside','Usage','Other'
                options: [
                    { value: null, text: "Check ON/OFF" },
                    { value: "0", text: "Check OFF" },
                    { value: "1", text: "Check ON" },
                ],
            },
            */
          ],
        }
    },

    mounted () {
    },
    methods: {
        color_change(id,e){
            ++this.change_work
            alert(id+e)
            
            let rows = this.temp_ts_monitoring_list
           
            if (rows.length <= 0) return
            for (let i = 0; i < rows.length; i++) {
                if(rows[i].id === id ){
                  this.temp_ts_monitoring_list[i]= { id: rows[i].id, name: rows[i].name,rgb : e }
                }
            }
            
        },
        fn_press_han(event)
        {
           var objTarget = event.srcElement || event.target;
       // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //if(Number(value))
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');

          }        
        },
        fn_press_han1(event)
        {
           var objTarget = event.srcElement || event.target;
       // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //if(Number(value))
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9.]/g,'');
            alert('Please enter only numbers');
            if(isNaN(objTarget.value))
               objTarget.value = ""
           // return
          
          }else{
             if(Number(value) < 0.1)
               objTarget.value = 0.1
          }        
        },
        goRouter (path) {
           this.$router.push({ path: path, query: { tag: this.work_id  } })
        },

        show(w_type, list) {
            this.w_type = w_type
            this.w_idx = list

            this.dataset_title = []       

            this.$http.get(this.URL+'api/dataset/get_work_ds_list?w_type=300&customer_id='+sessionStorage.select_customer_id+"&search_term=&per_page=100&page=1").then((rows) => {
                if(rows.data.rows.length <= 0) return;
               
                for(let i=0; i<rows.data.rows.length; i++) {
                    if(i=== 0){
                        this.dataset_title.push({value:-1, text :'Select dataset title'}) ; 
                    }
                    this.dataset_title.push({value:rows.data.rows[i].id, text :rows.data.rows[i].title}) ; 
                }
            })            
            this.$refs.modal.show();
        },
        change(e, index) {
            // alert(index)
            this.$emit('change', e, index)
        },
    
  
        del_dup_list (a, b) {
            for (let i = 0; i < a.length; i++) {
                let idx = b.findIndex(obj => obj.id == a[i].id)
                if (idx <= -1) continue
                b.splice(idx, 1)
            }
            ++this.change_work;
        }, 
 
        apply() {
           var url=this.URL+'api/dataset/set_dataset'
           
           let params = {} ;
           if(this.dataset_id === -1) {
               alert("저장될 데이터셋을 선택해 주세요.")
               return;
           }else{
               params["ds_id"] = this.dataset_id 
           }
          
           for(let i=0; i<this.adv_search.length; i++) {
                if(this.adv_search[i].control==='range' || this.adv_search[i].control==='date') {
                    if((this.adv_search[i].value!==null)&&(this.adv_search[i].value2!==null))
                       params[this.adv_search[i].field] = this.adv_search[i].value+','+this.adv_search[i].value2
                       // url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                    else if(this.adv_search[i].value !==null)
                       params[this.adv_search[i].field] = this.adv_search[i].value +','
                      //  url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','
                    else if(this.adv_search[i].value2 !==null)
                       params[this.adv_search[i].field] = ','+this.adv_search[i].value2
                      //  url += '&'+this.adv_search[i].field+'=,'+this.adv_search[i].value2
                }
                else {
                    if(this.adv_search[i].value!==null)
                       params[this.adv_search[i].field] = this.adv_search[i].value
                       //url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value
                }
           }
          
          if(params.length > 0 || params !== undefined ){
               params["customer_id"] = sessionStorage.select_customer_id
               params["w_idx"] = this.w_idx               
          }
           this.$http.post(url,params).then((res) => {
                console.log(res.data);
           })   

           this.$refs.modal.hide()
        },    
        apply1() {
          //  if(localStorage === null || typeof localStorage === undefined){
              if(sessionStorage === null || typeof sessionStorage === undefined){
                alert("Can not be modified.")
                return
         //   }else if(localStorage.level === 'C'){
              }else if(sessionStorage.level > 1){
                alert("You do not have permission to edit.")
                return;
            }
            if(this.change_work > 0) {
                this.work_block_setting()
            }
            let params = {} ;
            //update인경우
            if(this.properties['id'] !== null && this.properties['id'] !== undefined){
                let keys = Object.keys(this.properties_bak)
                for(let i=0; i<keys.length; i++) {
                if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                    params[keys[i]] = this.properties[keys[i]]
                    if('status' === keys[i] && this.properties[keys[i]] === 'U'  && this.work_type !== 300){
                    var del_url = this.URL+'api/delete_ex?w_idx_ex='+this.properties['id']
                    if(this.work_type === 110){
                        del_url = this.URL+'api/delete_ex?p_idx_ex='+this.properties['id']
                    } 
                    this.$http.get(del_url).then((res) => {
                            console.log(res.data);
                        })  
                    }
                }
                var update_url = this.URL+'api/update_work_info?id='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))
                if(this.work_type === 300){
                    update_url = this.URL+'api/dataset/update_work_ds_info?id='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))
                }
                if(Object.keys(params).length<=0) {
                     if(this.change_work == 0){ 
                      alert("변경된 내용이 없습니다.")
                      return
                     }else{
                        this.$refs.modal.hide();     
                     }
                }          
                this.$http.get(update_url).then((res) => {
                    console.log(res.data);
                })   
            }else{ //insert인경우
                let title = this.properties['title']
                if(title.length < 1 ) {
                      alert("Please enter title. ")
                    return
                }
                //insert_url
                var insert_url = null
                if(this.work_type == 110 || this.work_type == 104 || this.work_type == 160){
                    if(this.properties['start_datetime'] ==null || this.properties['start_datetime']=== undefined){
                        alert("Please enter start_datetime. ")
                        return;
                    }else if(this.properties['end_datetime'] ==null || this.properties['end_datetime']=== undefined){
                        alert("Please enter end_datetime. ")
                        return;
                    }else if(this.properties['measure_interval'] ==null || this.properties['measure_interval']=== undefined){
                        alert("Please enter measure_interval. ")
                        return;
                    }else if(this.properties['measure_time'] ==null || this.properties['measure_time']=== undefined){
                        alert("Please enter measure_time. ")
                        return;
                    } 
                    if(this.properties['start_datetime']  >= this.properties['end_datetime'] ){
                        alert("Start time and end time are invalid.")
                        return;
                    }
                }
                
                //var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");

               // var today = new Date();
               // params["w_start_time"]= today.getTime()
                if(this.work_type !== 300){ //동수두가 아닌경우
                    //setting값이 없으면 return한다.

                    //water_work
                    params["customer_id"] = sessionStorage.select_customer_id
                    params["customer_member_id"] = this.properties["customer_member_id"]
                    params["w_type"] = this.properties["type_code"]
                    params["w_title"] = this.properties["title"]
                    params["w_contents"] = this.properties["contents"]
                    params["m_idx"] = this.properties["customer_member_id"] 
                    //setting
                     params['measure_mode'] = this.properties['measure_mode'];
                     params['start_time'] = this.properties['start_datetime'];
                     params['end_time'] = this.properties['end_datetime'];
                     params['interval'] =this.properties['measure_interval'];
                     params['time'] = this.properties['measure_time'];
                  //  insert_url = this.URL+'api/set_work?'+(JSON.stringify(params))
                    insert_url = this.URL+'api/set_work'
                    this.$http.post(insert_url,params).then((res) => {
                     console.log(res.data);
                   })   

                }else{
                   params["customer_member_id"] = this.properties["customer_member_id"]
                   params["type_code"] = this.properties["type_code"]
                   params["title"] = this.properties["title"]
                   params["contents"] = this.properties["contents"]
                   params["customer_member_id"] = this.properties["customer_member_id"]
                   insert_url = this.URL+'api/dataset/update_work_ds_info?id=&fields='+encodeURIComponent(JSON.stringify(params))
                   this.$http.get(insert_url).then((res) => {
                     console.log(res.data);
                   })   
                }
          
            }
            this.$emit('worklist_refresh') ;
            this.$refs.modal.hide();     
        }       
    },
    components: {
         //   FFTChart1:FFTChart1
        //  PressBigChartData:PressBigChartData,
    //   datetime 
    }
}
</script>

<style  lang="scss">

.pressure_chart  .highcharts-container {
  width:440px;
}
.pressure_chart svg.highcharts-root {
    max-width: 440px;
}
.facility__label-radio {
  font-size: 12px;
  color: #616161;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}
.tab-content {
    max-height: 720px;    
    overflow-y: auto;    
    overflow-x: hidden; 
}
// .modal-info {
//   .modal-dialog {
//     position: absolute;
//     right: 0;
//     top: 0;
//     margin: 70px auto 0;
//     // 2022 new
//     // background: #F2F2F2;
//     background: #172A46;
//     width: 350px;
//     // width: 350px;
//     height: calc(100% - 70px);
//     .modal-header {
//       padding-bottom: 22px;
//       .modal-title {
//         font-size: 18px;
//         // margin-top: 12px;
//       }
//     }
//     .modal-info-content {
//       border: 0;
//       border-radius: 0;
 
//       &.reversal {
//         background: #172A46;
//         .nav-tabs {
//           background: #172A46;
//         }
//         .tab-content {
//           background: #172A46;
//           &.is--scroll {
//             height: 73vh;
//             overflow-y: auto;
//           }
//         }
//         .nav-tabs .nav-item.show .nav-link,
//         .nav-tabs .nav-link.active {
//           // 2022 new
//           // background: #fff;
//           border-color: 1px solid #707070;
//         }
//         .facility__label {
//           color: #fff;
//           font-size: 12px;
//           display: inline-block;
//           letter-spacing: 0.5px;
//           //width: 35%;
//         }
//         .facility__input {
//           //width: 60%;
//           height: 32px;
//           font-size: 12px;
//           border: 1px solid #707070;
//           box-sizing: border-box;
//           border-radius: 0;
//           margin-top: 0;
//           margin-bottom: 14px;
//           background: #0A192F;
//           &.height--auto {
//             height: auto;
//           }
//         }
//         .modal-footer {
//           background: #172A46;
//           padding-bottom: 18px;
//           padding-top: 3px;
//         }
//         .modal-footer-row {
//           width: 100%;
//           margin: 0;
//           padding-bottom: 0;
//         }
//         .btn.modal-footer__button {
//           height: 52px;
//           font-size: 14px;
//           border: 0;
//           &.modal-footer__button-ok {
//             // 2022 new
//             // background: #28B7CB;
//             background: #28AF81;
//           }
//           &.modal-footer__button-cancel {
//             background: #9D9D9D;
//           }
//         }
//         .photo-card {
//           border-radius: 0;
//           border: 0;
//           background: none;
//           .img-thumbnail {
//             margin-bottom: 10px;
//           }
//           .card-header {
//             height: 150px;
//             background: #172A46;
//             text-align: center;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             .card-header__text {
//               font-size: 16px;
//               color: #fff;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
<template>
    <div>
        <b-modal
            ref="modal"
            :title="$t('work_info.page_title')"
            >
            <b-tabs  v-if="properties!==null" align="center">
              <b-tab  :title="$t('work_info.proper')" active>  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <!-- <b-input size="sm" v-model="properties['id']" disabled></b-input> -->
                            <b-input size="sm" v-model="properties['id']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                   
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.title')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" maxlength="15" v-model="properties['title']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.contents')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" maxlength="125" v-model="properties['contents']" ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.customer')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['customer_name']" disabled></b-input>
                        </b-col>
                    </b-row>
                  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.worker')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['worker']" disabled></b-input>
                        </b-col>
                    </b-row>
               
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.r_date')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['create_datetime']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col sm="4">
                            <label>{{$t('work_info.l_time')}}</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['last_work_datetime']" disabled  ></b-input>
                        </b-col>
                    </b-row>     
                     <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('facility_info.status')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" ></b-select>
                  </b-col>
                </b-row>              
              </b-tab>
                <b-tab :title="$t('work_info.setting')" v-if="!isActiveSetting">
                  <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.mea_mode')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="p_mode" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.mea_inter')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <!--b-input size="sm" v-model="properties['measure_interval']" :disabled='is_show'  min=0.1 
                                onkeyPress="if (!((event.keyCode>=48 && event.keyCode<=57) || event.keyCode === 46) ){ event.preventDefault();}"  v-on:keydown="fn_press_han1" v-on:keyup="fn_press_han1"
                             ></b-input-->
                             <b-input size="sm" v-model="properties['measure_interval']" :disabled='is_show || change_yn'  min=0.1 
                               onkeyPress="if  ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"  v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                             
                        </b-col>
                    </b-row>
                  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.mea_time')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['measure_time']" :disabled='is_show '  min=1  
                                onkeyPress="if  ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"  v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                        </b-col>
                    </b-row>
               
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.s_datetime')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input v-if="is_show" size="sm" v-model="properties['start_datetime']" disabled ></b-input>
                            <datetime v-if="!is_show" format="YYYY-MM-DD H:i:s" v-model="properties['start_datetime']"   ></datetime>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col sm="4">
                            <label>{{$t('pressure.end_time')}}</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input v-if="is_show" size="sm" v-model="properties['end_datetime']" disabled  ></b-input>
                            <datetime v-if="!is_show" format="YYYY-MM-DD H:i:s" v-model="properties['end_datetime']"   ></datetime>
                        </b-col>
                    </b-row>           
                
                </b-tab>              
              <b-tab title="work_block" v-if="isWorkBlock">
         
                   <span> Work Block List </span>
                   <b-button style="float:right; padding: 0 5px; margin: 0px;" @click="goRouter('/Work-block')" >Show Block</b-button>
                   <p style="margin: 15px 0 10px ;">
                   <vue-good-table ref="flow_tag_table"
                      :columns="columns"
                      :rows="ts_flow_tag_list"
                      max-height="200px"
                      :select-options="{ enabled: true, selectOnCheckboxOnly: true, disableSelectInfo: true }"
                    />
                     <b-button    style=" padding: 0 5px; margin:10px 0;" @click="add_monitoring" >Select(+)</b-button>
                   </p>
                  
                    <p >
                   <span> Selected Work Block List </span>
                    <vue-good-table ref="ts_monitoring_table"
                                    :columns="columns1"
                                    :rows="temp_ts_monitoring_list"
                                    max-height="200px"
                                    :select-options="{ enabled: true, selectOnCheckboxOnly: true, disableSelectInfo: true }"
                    >
                       <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'rgb'">
                            <span style="font-weight: bold; color: blue;">
                                  <b-input v-model='props.row.rgb' size="sm" type="color" number required @change="color_change(props.row.id,props.row.rgb)"></b-input></span> 
                            </span>
                            <span v-else>
                            {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>
                    </vue-good-table>                    
                     <b-button  style=" padding: 0 5px; margin: 10px 0;" @click="del_monitoring">Delete(-)</b-button>
                    </p>
                   
                </b-tab>

            </b-tabs>
<!--
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="apply()">Apply</b-button>
                
                <b-button @click="cancel1()">Close</b-button>
            </template>
-->
           <template v-slot:modal-footer="{ ok, cancel, hide }">

              <b-row class="modal-footer-row m-0 leak_foot">
               <b-col sm="6">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">{{$t('work_info.close')}}</b-button>
               </b-col>
               <b-col sm="6">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('work_info.apply')}}</b-button>
               </b-col>              

             </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;
import datetime from 'vuejs-datetimepicker';
export default {
    name: 'DlgWorkInfo',
    props: {
    },
    watch:{
     

    },
    data() {
        return {
            properties: null,
            properties_bak: null,
            work_id : 0,
             p_mode : '',
             recount: 0,
             myWorkItem : false,
             ele_data : 0,
             work_type : 100, 
             is_show : true,
             isActiveSetting : true, //로거인 경우엔 false
             change_yn : false,
             isWorkBlock:false,
             f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
             ],
             columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Remarks', field: 'remarks' }
             ],
             columns1: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'color', field: 'rgb' }
             ],
            ts_monitoring_list: [], //선택블럭
            ts_flow_tag_list: [], //선택추가할 블럭 
            temp_ts_monitoring_list: [], // 변경값
            change_work : 0,
        }
    },

    mounted () {
    },
    methods: {
        color_change(id,e){
            ++this.change_work
            alert(id+e)
            
            let rows = this.temp_ts_monitoring_list
           
            if (rows.length <= 0) return
            for (let i = 0; i < rows.length; i++) {
                if(rows[i].id === id ){
                  this.temp_ts_monitoring_list[i]= { id: rows[i].id, name: rows[i].name,rgb : e }
                }
            }
            
        },
        fn_press_han(event)
        {
           var objTarget = event.srcElement || event.target;
       // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //if(Number(value))
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');

          }        
        },
        fn_press_han1(event)
        {
           var objTarget = event.srcElement || event.target;
       // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //if(Number(value))
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9.]/g,'');
            alert('Please enter only numbers');
            if(isNaN(objTarget.value))
               objTarget.value = ""
           // return
          
          }else{
             if(Number(value) < 0.1)
               objTarget.value = 0.1
          }        
        },
        goRouter (path) {
           this.$router.push({ path: path, query: { tag: this.work_id , block_type:100 } })
        },
           show_setting () {
             this.change_work = 0  
             this.temp_ts_monitoring_list = [...this.ts_monitoring_list]
           
           },
            handleOk () {
                this.ts_monitoring_list = [...this.temp_ts_monitoring_list]
                let param = []
                for (let i = 0; i < this.ts_monitoring_list.length; i++) param.push(this.ts_monitoring_list[i].tag)
                    this.$http.get(this.URL + 'api/set_monitoring_list?list=' + param)
            },
            insert_show(w_type,press_mode) { //1:일반압력, 2:수충압
              this.properties = {} ;
            // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
              this.img_list =[];              
              this.work_type = w_type ;              
              this.properties["type_code"] = w_type              
              this.properties['customer_member_id'] = sessionStorage.m_idx ;
              //this.work_id = id;
              //로거인 경우
              if(w_type == 110 || w_type == 104 || w_type == 160){
                  this.is_show = false
                  this.isActiveSetting = false
                  this.change_yn = false;
                  if(w_type == 110){
                   
                    this.properties["measure_mode"] = press_mode
                    if( press_mode ==='1' ){  
                       this.p_mode = "water pressure" ;
                       this.properties["measure_interval"] = 60 
                       this.properties["measure_time"] = 24 
                    }else if(press_mode ==='2' ){  
                       this.p_mode = "water hammer" ;     
                       this.properties["measure_interval"] = 0.1 
                       this.change_yn = true;
                       this.properties["measure_time"] = 1 

                    }
                  }else if (w_type == 104){ //누수음 로거
                    this.properties["measure_mode"] = 4 
                    this.p_mode = "Water Leak"
                    this.properties["measure_interval"] = 600                     
                    this.properties["measure_time"] = 1 
                  }else{  
                    this.properties["measure_mode"] = 3 
                    this.p_mode = "Water Flow"
                    this.properties["measure_interval"] = 60 
                    this.properties["measure_time"] = 24 
                  }
              }              
              if(w_type !== 300){
                 this.properties['customer_id'] = sessionStorage.select_customer_id ;
              }
               this.$refs.modal.show();
            },
            show(id,w_type) {
              this.properties = {} ;
            // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
              this.img_list =[];
              this.is_show = true
              this.work_type = w_type ;
              this.work_id = id;
              var surl = this.URL+'api/get_work_info_ex?id='+id+"&w_type="+w_type
              if(w_type === 300){
                  //alert("데이터셋")
                  surl = this.URL+'api/dataset/get_work_ds_info?id='+id+"&w_type="+w_type
              }
            // this.$http.get(this.URL+'api/get_press_info?idx='+idx).then((res) => {
              this.$http.get(surl).then((res) => {
                 
                if(res.data.length<=0) return           

                this.properties = res.data.list[0]; 
             
                if(sessionStorage.m_id === this.properties.worker )
                     this.myWorkItem = true
                
                if(this.properties.water_work_id !== null  &&  (typeof this.properties.water_work_id !== undefined) && this.work_type !== 300 )                  
                  this.isActiveSetting = false;
                else
                   this.isActiveSetting = true;
              
                if(this.properties.measure_mode === 1) 
                    this.p_mode = "water pressure" ;
                else if(this.properties.measure_mode === 2) 
                    this.p_mode = "water hammer" ;
                else
                    this.p_mode = " " ;
                    
                this.properties_bak = Object.assign({}, this.properties)



             })
               if(Number(w_type) === 100)   {
                    this.isWorkBlock = true;
                    this.ts_flow_tag_list = []
                    this.ts_monitoring_list  = []
                    // this.$http.get(this.URL+'api/work_block/customer/list/'+ sessionStorage.select_customer_id+"?block_name="+this.value+'&page='+this.page+'&perPage=10').then((rows) => { 
                    this.$http.get(this.URL + 'api/work_block/customer/list/' + sessionStorage.select_customer_id +'?page=1&perPage=100').then((res1) => {
                        //this.ts_monitoring_list = res.data.results[0]
                        this.ts_flow_tag_list= res1.data.result
                        //this.tot_block_length = this.ts_monitoring_list.length
                        this.$http.get(this.URL + 'api/work_block/water_work/list/' + id ).then((res2) => {
                           if(res2.data.check === 0){
                               this.ts_monitoring_list = res2.data.result
                               this.del_dup_list(this.ts_monitoring_list, this.ts_flow_tag_list)
                               this.show_setting () 
                           }else{
                               this.del_dup_list(this.ts_monitoring_list, this.ts_flow_tag_list)
                               this.show_setting () 
                           }
                             
                        })   
                    })
                }else{
                    this.isWorkBlock = false;
                }
            this.$refs.modal.show();          
    

           },
         del(){
           var con_test1 = confirm("Are you sure you want to delete?");
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test1){
              this.$http.get(this.URL+'api/delete_ex?p_idx_ex='+this.properties['id']).then((res) => {  
                 console.log(res.data);
                 this.$emit('leaktable_refresh') ;
                 this.$refs.modal.hide();
              });

           }
        },
        add_monitoring () {
            let rows = this.$refs.flow_tag_table.selectedRows
            if (rows.length <= 0) return
            for (let i = 0; i < rows.length; i++) {
                this.temp_ts_monitoring_list.push({ id: rows[i].id, name: rows[i].name ,rgb:"#B0A8F0"})
            }
            this.del_dup_list(this.temp_ts_monitoring_list, this.ts_flow_tag_list)
         
        },
        del_monitoring () {
            let rows = this.$refs.ts_monitoring_table.selectedRows
            if (rows.length <= 0) return
            for (let i = 0; i < rows.length; i++) {
                this.ts_flow_tag_list.push({ id: rows[i].id, name: rows[i].name })
            }
            this.del_dup_list(this.ts_flow_tag_list, this.temp_ts_monitoring_list)
        },
        del_dup_list (a, b) {
            for (let i = 0; i < a.length; i++) {
                let idx = b.findIndex(obj => obj.id == a[i].id)
                if (idx <= -1) continue
                b.splice(idx, 1)
            }
            ++this.change_work;
        }, 
        work_block_setting(){
          //work_block work_id를 전부 삭제후 insert한다..
           let params = {} ;
           let work_block = []
           if(this.temp_ts_monitoring_list.length > 0){
              params["ww_idx"] = this.work_id
              for(var i = 0 ; i <this.temp_ts_monitoring_list.length ; ++i ) {
                 work_block.push({ wb_idx:this.temp_ts_monitoring_list[i].id, 
                              name:this.temp_ts_monitoring_list[i].name, rgb :this.temp_ts_monitoring_list[i].rgb  }) 
              } 

              params["data"] = JSON.stringify(work_block);
              //
               this.$http.post(this.URL+'api/work_block/setting',params).then((res) => {
                  console.log(res.data);
                  if(res.data.check ===1){
                     alert("fail : "+ res.data.msg) ;
                  }else{
                    this.$refs.modal.hide();
                  }
                })
           }
          
        },       
        apply() {
          //  if(localStorage === null || typeof localStorage === undefined){
              if(sessionStorage === null || typeof sessionStorage === undefined){
                alert("Can not be modified.")
                return
         //   }else if(localStorage.level === 'C'){
              }else if(sessionStorage.level > 1){
                alert("You do not have permission to edit.")
                return;
            }
            if(this.change_work > 0) {
                this.work_block_setting()
            }
            let params = {} ;
            //update인경우
            if(this.properties['id'] !== null && this.properties['id'] !== undefined){
                let keys = Object.keys(this.properties_bak)
                for(let i=0; i<keys.length; i++) {
                if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                    params[keys[i]] = this.properties[keys[i]]
                    if('status' === keys[i] && this.properties[keys[i]] === 'U'  && this.work_type !== 300){
                    var del_url = this.URL+'api/delete_ex?w_idx_ex='+this.properties['id']
                    if(this.work_type === 110){
                        del_url = this.URL+'api/delete_ex?p_idx_ex='+this.properties['id']
                    } 
                    this.$http.get(del_url).then((res) => {
                            console.log(res.data);
                        })  
                    }
                }
                var update_url = this.URL+'api/update_work_info?id='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))
                if(this.work_type === 300){
                    update_url = this.URL+'api/dataset/update_work_ds_info?id='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))
                }
                if(Object.keys(params).length<=0) {
                     if(this.change_work == 0){ 
                      alert("변경된 내용이 없습니다.")
                      return
                     }else{
                        this.$refs.modal.hide();     
                     }
                }          
                this.$http.get(update_url).then((res) => {
                    console.log(res.data);
                })   
            }else{ //insert인경우
                let title = this.properties['title']
                if(title.length < 1 ) {
                      alert("Please enter title. ")
                    return
                }
                //insert_url
                var insert_url = null
                if(this.work_type == 110 || this.work_type == 104 || this.work_type == 160){
                    if(this.properties['start_datetime'] ==null || this.properties['start_datetime']=== undefined){
                        alert("Please enter start_datetime. ")
                        return;
                    }else if(this.properties['end_datetime'] ==null || this.properties['end_datetime']=== undefined){
                        alert("Please enter end_datetime. ")
                        return;
                    }else if(this.properties['measure_interval'] ==null || this.properties['measure_interval']=== undefined){
                        alert("Please enter measure_interval. ")
                        return;
                    }else if(this.properties['measure_time'] ==null || this.properties['measure_time']=== undefined){
                        alert("Please enter measure_time. ")
                        return;
                    } 
                    if(this.properties['start_datetime']  >= this.properties['end_datetime'] ){
                        alert("Start time and end time are invalid.")
                        return;
                    }
                }
                
                //var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");

               // var today = new Date();
               // params["w_start_time"]= today.getTime()
                if(this.work_type !== 300){ //동수두가 아닌경우
                    //setting값이 없으면 return한다.

                    //water_work
                    params["customer_id"] = sessionStorage.select_customer_id
                    params["customer_member_id"] = this.properties["customer_member_id"]
                    params["w_type"] = this.properties["type_code"]
                    params["w_title"] = this.properties["title"]
                    params["w_contents"] = this.properties["contents"]
                    params["m_idx"] = this.properties["customer_member_id"] 
                    //setting
                     params['measure_mode'] = this.properties['measure_mode'];
                     params['start_time'] = this.properties['start_datetime'];
                     params['end_time'] = this.properties['end_datetime'];
                     params['interval'] =this.properties['measure_interval'];
                     params['time'] = this.properties['measure_time'];
                  //  insert_url = this.URL+'api/set_work?'+(JSON.stringify(params))
                    insert_url = this.URL+'api/set_work'
                    this.$http.post(insert_url,params).then((res) => {
                     console.log(res.data);
                   })   

                }else{
                   params["customer_member_id"] = this.properties["customer_member_id"]
                   params["type_code"] = this.properties["type_code"]
                   params["title"] = this.properties["title"]
                   params["contents"] = this.properties["contents"]
                   params["customer_member_id"] = this.properties["customer_member_id"]
                   insert_url = this.URL+'api/dataset/update_work_ds_info?id=&fields='+encodeURIComponent(JSON.stringify(params))
                   this.$http.get(insert_url).then((res) => {
                     console.log(res.data);
                   })   
                }
          
            }
            this.$emit('worklist_refresh') ;
            this.$refs.modal.hide();     
        }       
    },
    components: {
         //   FFTChart1:FFTChart1
        //  PressBigChartData:PressBigChartData,
       datetime 
    }
}
</script>

<style  lang="scss">
.tab-pane .vgt-inner-wrap {
  height:100%;
}
.pressure_chart  .highcharts-container {
  width:440px;
}
.pressure_chart svg.highcharts-root {
    max-width: 440px;
}
.facility__label-radio {
  font-size: 12px;
  color: #616161;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}
.tab-content {
    max-height: 720px;    
    overflow-y: auto;    
    overflow-x: hidden; 
}
// .modal-info {
//   .modal-dialog {
//     position: absolute;
//     right: 0;
//     top: 0;
//     margin: 70px auto 0;
//     // 2022 new
//     // background: #F2F2F2;
//     background: #172A46;
//     width: 350px;
//     // width: 350px;
//     height: calc(100% - 70px);
//     .modal-header {
//       padding-bottom: 22px;
//       .modal-title {
//         font-size: 18px;
//         // margin-top: 12px;
//       }
//     }
//     .modal-info-content {
//       border: 0;
//       border-radius: 0;
 
//       &.reversal {
//         background: #172A46;
//         .nav-tabs {
//           background: #172A46;
//         }
//         .tab-content {
//           background: #172A46;
//           &.is--scroll {
//             height: 73vh;
//             overflow-y: auto;
//           }
//         }
//         .nav-tabs .nav-item.show .nav-link,
//         .nav-tabs .nav-link.active {
//           // 2022 new
//           // background: #fff;
//           border-color: 1px solid #707070;
//         }
//         .facility__label {
//           color: #fff;
//           font-size: 12px;
//           display: inline-block;
//           letter-spacing: 0.5px;
//           //width: 35%;
//         }
//         .facility__input {
//           //width: 60%;
//           height: 32px;
//           font-size: 12px;
//           border: 1px solid #707070;
//           box-sizing: border-box;
//           border-radius: 0;
//           margin-top: 0;
//           margin-bottom: 14px;
//           background: #0A192F;
//           &.height--auto {
//             height: auto;
//           }
//         }
//         .modal-footer {
//           background: #172A46;
//           padding-bottom: 18px;
//           padding-top: 3px;
//         }
//         .modal-footer-row {
//           width: 100%;
//           margin: 0;
//           padding-bottom: 0;
//         }
//         .btn.modal-footer__button {
//           height: 52px;
//           font-size: 14px;
//           border: 0;
//           &.modal-footer__button-ok {
//             // 2022 new
//             // background: #28B7CB;
//             background: #28AF81;
//           }
//           &.modal-footer__button-cancel {
//             background: #9D9D9D;
//           }
//         }
//         .photo-card {
//           border-radius: 0;
//           border: 0;
//           background: none;
//           .img-thumbnail {
//             margin-bottom: 10px;
//           }
//           .card-header {
//             height: 150px;
//             background: #172A46;
//             text-align: center;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             .card-header__text {
//               font-size: 16px;
//               color: #fff;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
<template>
  <highcharts class="wavchart" :options="chartOptions"></highcharts>
</template>

<script>
export default {
  name: 'wavChart',
   props:{

      chartData: {
        type: Array
      },
      interval : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
     
   
  },
  data () {
    return {
  
       chartOptions: {
         /*
        rangeSelector: {
         // selected: 1,
          buttons: [{
                
              type: 'millisecond',
              count: 1,
              text: '1ms',
              events: {
                  click: function() {
                      alert('Clicked button');
                  }
              }
          },{
              type: 'millisecond',
              count: 10,
              text: '10ms',
              events: {
                  click: function() {
                      alert('Clicked button');
                  }
              }
          },{
              type: 'millisecond',
              count: 100,
              text: '100ms',
              events: {
                  click: function() {
                      alert('Clicked button');
                  }
              }
          }, {
              type: 'second',
              count: 1,
              text: '1Sec'
          }, 
           {
              type: 'second',
              count: 2,
              text: '2Sec'
          }, {
              type: 'all',
              text: 'All'
          }],
        },
        */
        title: {
          text: ''
        },
      colorAxis:[{lineColor:"#434348"}],
      colors : ["#5580b4"],
        series: [{
          name: 'wav',
         // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
          data: this.chartData,
          pointStart: Date.UTC(2018, 1, 1),
          pointInterval: this.interval, //
         //  pointInterval: 4000/20480, //
       
          dateTimeLabelFormats:{millisecond : {main:"%S.%L"}},
          tooltip: {
            valueDecimals: 4
          }
        }],             
         chart: {   zoomType: 'xy' ,
                    width:800,
         },

        xAxis: { type: 'datetime'   }
    },

    
       
    }
  }
}
</script>
<style lang="scss">
.highcharts {
  width: 98%;
  margin: 0 auto
}
/*
.highcharts-container .highcharts-root {
    width: 800px;
}
*/
</style>
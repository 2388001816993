<template>
    <div>
        <div v-if="search==true" class="table-option">
            <!-- 2023 -->
            <!-- <b-input-group class="mt-1 search_box"> -->
            <b-input-group class="search_box">
                <b-input type="text" v-model="serverParams.searchTerm" class="form-control gis-master__input1" placeholder="" disabled ></b-input>
                <!-- <b-input type="text" v-model="serverParams.searchTerm" class="form-control gis-master__input1" placeholder="" disabled ></b-input> -->
                <div class="search_btn_area" >
                  <a href="#none" @click="$refs.modal.show();" title="Filter option" class="search_icon"><img src="@/assets/images/new/filter-outline.svg" alt="option" style="width:80%;"></a>
                  <a href="#none" @click="clear()"  class="search_icon" title="Filter clear"><img src="@/assets/images/new/filter-off-outline.svg" alt="option clear" style="width:80%;"></a>
                </div>
                
             </b-input-group>
        </div>

                <vue-good-table ref="table"
                    mode="remote"
                    @on-row-click="onRowClick"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"                   
                    styleClass="vgt-table without--head-right-line type01 scroll_table"
                    :globalSearch="true"
                    :line-numbers="false"
                    :isLoading.sync="isLoading"
                    :select-options="{ enabled: select, selectOnCheckboxOnly: true, disableSelectInfo: true }"
                    :sort-options="{enabled: sort}"
                    :search-options="{enabled: true, externalQuery: serverParams.searchTerm}"
                    :pagination-options="{enabled: true, position: 'top', perPageDropdown: [15, 30, 50, 100, 200,500,1000], dropdownAllowAll: false,}"
                    :totalRows="totalRecords"
                    :rows="rows"
                    :columns="columns"
                    :adv_search="adv_search"
                    :fixed-header="true"
                    :max-height="max_height"
                
                    >
                    <template slot="table-row" slot-scope="props" v-if="add_custom_column">
                   
                      <span v-if="props.column.field == 'go_map'" class="table_icon" @click="$emit('on_goto_map_click', props.row)">
                        <!-- 2022 new -->
                        <!-- <img src="@/assets/images/Common/table/ic_table_map.svg" alt="map"> -->
                        <img src="@/assets/images/new/ic_table_map.svg" alt="map">
                         
                    </span>
                       <span v-else-if="props.column.field == 'm_id'" >
                          {{to_split(props.row.m_id)}}
                      </span>
                      <span v-else-if="props.column.field == 'info'" class="table_icon" @click="$emit('on_info_click', props.row.id)">
                        <!-- 2022 new -->
                        <!-- <img src="@/assets/images/Common/ic_list_info.svg" alt="map"> -->
                        <img src="@/assets/images/new/ic_list_info.svg" alt="map" style="width: 70%;">
                    </span>
                    <span v-else-if="props.column.field == 'ai_btn'" class="table_icon">
                        <!-- 2022 new -->
                        <!-- <img src="@/assets/images/Common/ic_list_info.svg" alt="map"> -->
                         <b-button class="mr-2"  size="sm"  @click="$emit('get_ai_result', props.row)" >
                                <i class="fas fa-sync-alt"></i>
                          </b-button>
                          <!--b-button v-if="!loading" class="mr-2"  size="sm" @click="$emit('get_ai_result', props.row)">
                                <i class="fas fa-sync-alt"></i>
                          </b-button>
                          <span v-if="loading"><img src="@/assets/images/loading.gif" style="width:25px;"/></span-->
                    </span>
                      <span v-else-if="props.column.field == 'move'" class="table_icon" @click="$emit('on_move_click', props.row)"><img src="@/assets/images/Common/table/folder_b.png" alt="map"></span>
<!--                        <b-button size="ss" v-if="props.column.field == 'go_map'" @click="$emit('on_goto_map_click', props.row)" class="table_icon">-->
<!--                          <img src="@/assets/images/Common/table/map_icon.png" alt="map"></b-button>-->
<!--                        <b-button size="ss" variant="outline-info" v-else-if="props.column.field == 'info'" @click="$emit('on_info_click', props.row.idx)" ><i class="fas fa-info"></i></b-button>-->
                    </template>
                </vue-good-table>
          <DlgSearch ref="modal" :adv_search="adv_search" @change="change" @handleOk="handleOk"/>
        </div>
</template>

<script>
import DlgSearch from '@/components/popup/DlgSearch';

import _ from 'lodash'

export default {
    name: 'ServerSideTable',
    props: [
        'columns',
        'select',
        'search',
        'sort',
        'adv_search',
        'add_custom_column',
        'max_height'
    ],
    watch: {
    },
    data() {
        return {
            
            isLoading: false,
            rows: [],
            totalRecords:0,
            serverParams: {
                searchTerm: '',
                columnFilters: {
                },
                sort: {
                    field: '',
                    type: '',
                },
                page: 1,
                perPage: 15
            },
            url: '',
            is_search_dlg_ok: false,
       
        }
    },
    methods: {
      to_split(email){
      
         let email_sp = email.split("@") 

         return email_sp[0]
      },  
      dateRangeFilter(data, filterString) {
        let dateRange = filterString.split("to");
        let startDate = Date.parse(dateRange[0]);
        let endDate = Date.parse(dateRange[1]);
        return (data =
            Date.parse(data) >= startDate && Date.parse(data) <= endDate);
      },
        onRowClick(params) {
            this.$emit('on-row-click', params);
          //  this.rowStyleClassFn(this.getSelectRows())
        },
     
        getSelectRows() {
            return this.$refs.table.selectedRows;
        },
        onSearch: _.debounce(function (params) {
            this.updateParams(params);
            this.serverParams.page = 1;
            this.loadItems();
            return false;
        }, 500),
        updateParams(newProps) {
              this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.loadItems();
        },
        onPerPageChange(params) {
           this.updateParams({perPage: params.currentPerPage});
           this.loadItems();
           /*
           if(params.currentPerPage > 400) {
                alert('Searching data is limited to under 1,000')

               this.updateParams({perPage: 10, page: 1}); 
              //  this.loadItems();
               // this.onSearch();
               return false;
            }else{
                this.updateParams({perPage: params.currentPerPage});            
                this.loadItems();
            }
            */
        },
        onSortChange(params) {
            this.updateParams({  
                page: 1,              
                sort: {
                 //  type: params.sortType,
                 //   field: this.columns[params.columnIndex].field,
                   type: params[0].type === 'none' ? "" : params[0].type,
                   field: params[0].type === 'none' ? "" : params[0].field,
                },
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        loadItems(cb) {
            let s = (this.url.indexOf("?")>0)?'&':'?';
            let url = this.url+s+'page='+this.serverParams.page+'&per_page='+this.serverParams.perPage+'&search_term='+this.serverParams.searchTerm+'&sort_field='+this.serverParams.sort.field+'&sort_type='+this.serverParams.sort.type


            if(this.is_search_dlg_ok) {
                for(let i=0; i<this.adv_search.length; i++) {
                    if(this.adv_search[i].control==='range') {
                        if((this.adv_search[i].value!==null)&&(this.adv_search[i].value2!==null))
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                       // else if(this.adv_search[i].value!==null)
                       //     url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','
                        else if(this.adv_search[i].value2!==null)
                            url += '&'+this.adv_search[i].field+'=,'+this.adv_search[i].value2
                     }else  if(this.adv_search[i].control==='date') {
                        if((this.adv_search[i].value!==null)&&(this.adv_search[i].value2!==null))
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                      //  else if(this.adv_search[i].value2!==null)
                      //      url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','
                        else if(this.adv_search[i].value2!==null)
                            url += '&'+this.adv_search[i].field+'=,'+this.adv_search[i].value2
                    }else {
                        if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value
                    }
                }
            }

            this.isLoading = true;
            this.$http.get(url).then((res) => {
                this.rows = res.data.rows;
                this.totalRecords = res.data.cnt;
                this.isLoading = false;
                cb && cb(this.rows);
			});
        },
        setUrl(_url, cb) {
            this.url = _url;
            this.loadItems(cb)
        },
        change(e, index) {
            this.$emit('search_item_change', e, index)
        },
        handleOk() {
            this.is_search_dlg_ok = true
            this.loadItems()
        },
        clear() {
            for(let i=0; i<this.adv_search.length; i++) {
                this.adv_search[i].value = null
                if(this.adv_search[i].control==='range') this.adv_search[i].value2 = null;
            }
            this.serverParams.searchTerm = ''
            this.is_search_dlg_ok = false;
            this.loadItems()
        },
        get_option_highe() {
            return this.$refs.optionHeight.clientWidth
        }
    },
    mounted () {
    },
    beforeDestroy () {
    },
    components: {
        DlgSearch: DlgSearch
    }
}
</script>
<style lang="scss" scoped>
// 2022 new 추가
@import '@/assets/scss/base/variables';

.table-option {
  margin-top: 0;
  margin-bottom: 0;
}

td {
  color: white !important;
}


.btn-size-view {
  background: #f2f2f2;
}

.input-group > {
  .form-control {
    &.gis-master__input1 {
      width: calc(100% - 200px);
      height: 30px;
      text-indent: 40px;
      border: none;
      background: none;
      background-size: 28px;
      color: #171725;
      font-size: 18px;
    }
  }
}
// 2022 new 추가
.vgt-table th.line-numbers, .vgt-table th.vgt-checkbox-col{
    background: #1a4552;
}
// 2023
.search_box {
    width: 97%;
    margin: 0 auto 0 auto;
}

</style>
<style>
.list_box.list_box2.leak .vgt-responsive {
    overflow-y: auto;    
}
.list_box.list_box2.leak .vgt-responsive::-webkit-scrollbar {
    display:block ;
}

.list_box.list_box2.leak .vgt-responsive{
  display: inline-block;
  overflow-y: scroll;
  border: 1px solid black;
  box-sizing: border-box;
  color: white;
  font-family: 'Nanum Gothic';
  background-color:transparent;
}

/* 스크롤바 설정*/
.list_box.list_box2.leak .vgt-responsive::-webkit-scrollbar{
  width: 10px;
}

/* 스크롤바 막대 설정*/
.list_box.list_box2.leak .vgt-responsive::-webkit-scrollbar-thumb{
 /* background-color: rgba(255,255,255,0.7); */
 background-color: #28af8286 ;

 /* 스크롤바 둥글게 설정    */
  border-radius: 5px; 
  border: 7px solid transparent;

}

/* 스크롤바 뒷 배경 설정*/
.list_box.list_box2.leak .vgt-responsive::-webkit-scrollbar-track{
  background-color: transparent;
}
</style>

import { render, staticRenderFns } from "./BlockMonitoring.vue?vue&type=template&id=52ccc088&scoped=true"
import script from "./BlockMonitoring.vue?vue&type=script&lang=js"
export * from "./BlockMonitoring.vue?vue&type=script&lang=js"
import style0 from "./BlockMonitoring.vue?vue&type=style&index=0&id=52ccc088&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ccc088",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./Pie2DHChart.vue?vue&type=template&id=04544abb&scoped=true"
import script from "./Pie2DHChart.vue?vue&type=script&lang=js"
export * from "./Pie2DHChart.vue?vue&type=script&lang=js"
import style0 from "./Pie2DHChart.vue?vue&type=style&index=0&id=04544abb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04544abb",
  null
  
)

export default component.exports
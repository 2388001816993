<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
                      <!--  <li class="breadcrumb-title">Frequency Comparison</li>
                        <li class="breadcrumb-bar">|</li> -->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/leak-monitoring')" href="#">{{$t('leak_monitoring.m_title')}}</a></li>
                        <li class="breadcrumb-item active">{{$t('leak_monitoring.map_title')}}</li>
                    </ol>
                </li>
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div >
             <b-row style="padding: 2px;" >
                 <b-col cols="6">
                     <div id="wrap_scroll" style="overflow-y: scroll;overflow-x: hidden;" :style="{height: contents_height+'px'}">
                       <!--  <b-row v-for="(idx) in $route.query.lidx" :key="idx"> -->
                          <b-row v-for="(item, index) in box" :key="index" >
                             <b-col style=" margin: 0px 10px 10px 10px ;transform: translateZ(0);-webkit-transform: translateZ(0); ">
                             
                                 <!--div  style=" box-shadow: 1px 1px 1px #bcbcbc;  border: 1px solid #bcbcbc; padding: 0px;   background-color: #fff;    "-->
                                 <div  style=" padding: 0px;   background-color: #fff;    ">    
                               
                                 <div style=" position:absolute; top : 10px; right:30px; z-index:102;"> No:{{item.point_id}} &nbsp;&nbsp;
                                      <button variant="outline-danger" @click="on_goto_map_click(item.point_id)" style="border:0;"><i class="mdi mdi-map-marker-radius"></i></button>
                                  </div>
                                  <audio controls :src="URL+'api/get_wav/m2_water_leak/'+item.idx" style = "float:left;width:30%;transform:scale(0.8);"/>
                                  <div style="padding-top:15px;">
                                  <span > {{item.last_measure_datetime}}</span>
                                  </div>
                                 <!--
                                 <button type="button" @click="download(i)" v-bind:id="'d_btn'+i" ><i class="mdi mdi-download mr-2"></i></button>
                                 <button type="button" @click="play(i)" v-bind:id="'p_btn'+i" disabled ><i class="mdi" :class="{'mdi-play': true}"></i></button>
                                 <button type="button" @click="stop(i)"><i class="mdi mdi-stop" ></i></button>
                                 <button variant="outline-danger" @click="on_goto_map_click(idx)"><i class="fas fa-arrow-left"></i></button> -->


                                 <FFTChart1_c :url="URL+'api/get_fft/m2_water_leak/'+item.idx" height="200"/>
                                 </div>
                              
                             </b-col>
                         </b-row>

                     </div>

                 </b-col>
                 <b-col cols="6" style="left:-5px; padding: 0px 20px 0 0;  " >
                    <div class="search_div">
                            <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                            <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
                    </div>
                     <Map
                         ref="map"
                         :widx="$route.query.widx"
                         w_type="102"
                         :visible_gisload_button="true"                         
                         :leak_l ="false"                        
                        @set_map_gisload="set_map_gisload"
                        @set_info="set_info"
                    
                        @toggle_visible_leak_type = "toggle_visible_leak_type"
                        @toggle_visible_all_layer = "toggle_visible_all_layer"
                        @facility_invisible_layer = "facility_invisible_layer"
                     />

                 </b-col>
            </b-row>
         </div>


    </div>
</template>

<script>
import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';

export default {
    name: 'LeakAnalysis',
    props: {
    },
    data() {
        return {
            contents_height: 600,
            addr_value:'', 
            box:[],
            endOfTheScreen: false,
            isLoading: false,
            pageno: 1,
            limit: 4,
            posts: [],
            showbtn: false,
            autoLoading: true,  
        }
    },
  created() {
      
   },   
    methods: {
        scrollCheck() {
            return window.scrollY + window.innerHeight === document.documentElement.offsetHeight;
        },
        onScroll() {
        /*
        let wrap = document.querySelector('.wrapper')
        let contentHeight = wrap.offsetHeight
        let yOffset = window.pageYOffset
        let currentPosition = yOffset*300 + window.innerHeight
        */
            //if (!this.scrollCheck() && !this.isLoading) {
              if ( !this.isLoading) {  
                this.pageno += 1;
                this.loadPosts(this.pageno, this.limit);
            }
        },
        loadPosts(page = this.pageno, limit = this.limit) {
            this.showbtn = false;
            this.isLoading = true;
            const _self = this;
           setTimeout(()=>{
            /*
                axios.get("https://jsonplaceholder.typicode.com/posts?_page=" + page + "&_limit=" + limit).then(response => {
                    for (let i in response.data) {
                        _self.posts.push(response.data[i]);
                    }
                    _self.isLoading = false;
                    _self.showbtn = true;
                });
                */
            
            //this.$http.get(this.URL+'api/get_work_list_ex?w_type='+this.w_type+'&search_term='+this.value+'&per_page='+limit+'&page='+page).then((rows) => {        
                //배열 10, page =3 
                if (this.posts.length/this.limit > (page-1)  ) {
                    var si = (page-1)* limit
                    var ei = this.posts.length > page*limit ? page*limit : this.posts.length ;
                    for(let i=si; i<ei; i++) {
                        var abox = {'idx': this.posts[i].id,'point_id':this.posts[i].point_id,'last_measure_datetime':this.posts[i].last_measure_datetime } ; 
                        this.box.push(abox) ;
                    }
                    this.page += 1;
                    _self.isLoading = false;
                    _self.showbtn = true;
                }
        //    });            
        
            }, 200);
        },
        resetData() {
            this.autoLoading = true;
            this.showbtn = false;
            this.limit = 4;
            this.pageno = 1;
            this.box = [];
            this.loadPosts();
        },          
        addr_search(){
          this.$refs.map.go_search(this.addr_value);
        },
        Enter_Check1(){
            this.addr_search();
        },
        toggle_visible_all_layer() {
              // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
           this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
           if(this.$refs.map.layer_all_visible)
             this.$refs.map.leak_cal = 1;
           else
               this.$refs.map.leak_cal = 0;
           this.$refs.map.init_reload_leak_layer() ;

          /*
            this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')===100)
                    layer.setVisible(this.$refs.map.layer_all_visible)
            }
            */
        },
         toggle_visible_leak_type() {
            // 처음엔 leak type all 데이터를 가져와 지도에 보여준다.
            // All or LEAK 값을 가져와 지도에 보여준다.
            this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
            if(this.$refs.map.layer_leak_visible)
                this.$refs.map.leak_type = 'LEAK';
            else
                this.$refs.map.leak_type = null;
            
            //누수 레이어를 다시 그린다.
            this.$refs.map.init_reload_leak_layer() ;      
        },    
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        on_goto_map_click(idx) {
         
            this.$refs.map.get_extent('w_type=102&p_idx='+idx+"&w_idx="+this.$route.query.widx).then((data)=>{
                this.$refs.map.set_fit_extent(data)     
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
                this.$refs.map.select_facility_feature(102,'',idx)   //point_id로 위치값을 얻는다.  
            })
      
        },
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
               alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                      if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;

                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);


                    } //for문 끝

                } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },

    },
    mounted () {
        this.contents_height = window.innerHeight - 120;
        //this.posts = this.$route.query.lidx ;
        this.$http.get(this.URL+'api/API21/m2_leak/list?customer_id='+sessionStorage.select_customer_id).then((res) => {
            if(res.data.results[0].length <1) return
                
            for(let i=0; i<res.data.results[0].length; i++) {                
                var abox = {};
            //  abox = {'name': rows.data.rows[i].name, 'mnf': Math.round(Number(rows.data.rows[i].mnf)) , 'flow':  Math.round(Number(rows.data.rows[i].flow)) ,  'status': 'blue' } ; 
            //    abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id ,  'device_sn':res.data.results[0][i].device_sn ,
             //   'flow':  res.data.results[0][i].last_measure_datetime+"/ strength :  "+res.data.results[0][i].wave_energy ,  'date':  res.data.results[0][i].last_measure_datetime } ; 
              //  this.box.push(abox) ; 
                abox = {'id': res.data.results[0][i].l_idx, 'point_id': res.data.results[0][i].id,'last_measure_datetime':res.data.results[0][i].last_measure_datetime}
                this.posts.push(abox)
            }  
        })            
        this.resetData() 
         setTimeout(()=>{
            document.getElementById('wrap_scroll').addEventListener('scroll', () => {
               this.onScroll()
            }) 
            var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
            if( elem !== undefined ){
                if(elem.length > 0){
                    document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();            
                }
            }
        }, 200)  

    },
    components: {
        Map: Map,
        FFTChart1_c: () => import('@/components/chart/FFTChart1_c')
    }
}
</script>

<style scoped>
.search_div{
    position: absolute;
    left:180px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}
</style>

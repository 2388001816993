<template>
  <div class="left_area">
    <div class="left_list_box" >
      <div class="input_search_box" >
        <input type="text" class="input_search" v-model="value"   placeholder="Search..."  @keyup.enter ="Enter_Check">
        <input type="submit" class="input_submit" @click="go_search()" >
      </div>

      <div v-if="visible_sort_box_press" style="margin: 1em 0;">
            <span class="status m1-map" style="position: relative;top:0px;">
                         
                <input type="radio" id="radio_01" name="radio01"  checked v-model="sort" value="">
                <label for="radio_01">Name</label>
                <input type="radio" id="radio_02" name="radio01"  v-model="sort" value="id">
                <label for="radio_02">Installation Data</label>      
                <span style="float: right;   margin-top: 3px;"  >
                  <!--label for="radio_02"  style="font-size: 15px;margin-right:10px;">Status </label-->
                  <b-select  size="sm" v-model="status_type" :options="f_status_option" style="padding: 0 10px;" ></b-select>
                </span>        
            </span>   
      </div>      
      <div class="list_box_wrap press" style="transform: translateZ(0);-webkit-transform: translateZ(0);">
        <div :class="['box_list box_list02', { 'is--active': activeCheckIndex === index },{'is--leak' : visible_search_box} , { 'is--status': 'I' === item.point_status }]" v-for="(item, index) in box" :key="index" @click="selectBox(index)">
          <div class="con_box press">
            <p class="title ">{{ item.name }}</p>
            <p class="title ">Avg : {{ item.avg}} (bar)   </p>
            <span class="unit">{{ item.measure_start_datetime.substr(0,19) }}~{{ item.device_sn.substr(11) }}</span>
              <div style="    position: absolute;    top: 23%;    right: 5%; width:28px; z-index:1000;" >
                <!-- 2022 new -->
                <!-- <img src="@/assets/images/Common/icon/setting.png" alt="" @click="go_m2_info(item.id)" style="margin-bottom:10px;"> -->
                <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="go_press_info(item.id)" style="margin-bottom:10px; width: 70%;">
                <!--img src="@/assets/images/Common/icon/map_1.png" alt="" @click="goRouter('/pressure-monitoring-map?w_type=111',item.id,item.flow )"-->
                <!-- 2022 new -->
                <!-- <img src="@/assets/images/Common/icon/map_1.png" alt="" @click="$emit('on_goto_map_click',  item)"> -->
                <!--img src="@/assets/images/new/ic_list_map.svg" alt="" @click="goRouter(item )" style="margin-bottom:10px; width: 70%;"-->
              </div>
          </div>
        </div>
        
     
      </div>
       <div style=" margin: -3.5em 0.5em; bottom:-0.5em;text-align:center; ">
          <!--vue-scroll-->
         <pagination v-model="page" :options=pageOpt  :records="cnt" :per-page="10" @paginate="clickCallback" style=" display: table; margin: auto;"/>
         <!--/vue-scroll-->
       </div>
    </div>
    <DlgPressureInfo ref="pressure_info" />
   
  </div>
</template>

<script>
//pressure-monitoring,leak-monitoring 호출
import pagination from 'vue-pagination-2';
import DlgPressureInfo from '@/components/popup/DlgPressureInfo'
//import DlgHydraulicPointInfo from '@/components/DlgHydraulicPointInfo' 

export default {
  name: 'SearchLeftType3',
  watch: {
    leak_type() {
       this.$emit('go_search', this.value,  this.leak_type,this.strength_sort_yn) ;  // 실행할 이벤트
      
    },
    sort_strength(){
       if(this.sort_strength)
          this.strength_sort_yn ="strength" 
       else 
          this.strength_sort_yn ="" 
        this.$emit('go_search', this.value,  this.leak_type,this.strength_sort_yn ) ;  // 실행할 이벤트
      
    },
    sort() {
      this.go_search();
    },
    status_type() {
      this.go_search();
    },
  },
  data() {
    return {
      box1:[],
      page : 1,
      value: '',
      status_type : 'A',
      leak_type: '',
      sort:'',
      sort_strength:'',
      strength_sort_yn : '',
      // 2022 new
      // img_com_sense : require('@/assets/images/sub/m2/signal5.png'),
      img_com_sense : require('@/assets/images/new/ic_battery.svg'),
      f_status_option: [
                { value: '', text: 'All' },
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
      ],  
      pageOpt : { "chunk" : 3 }    }
  },
  props: {
    activeCheckIndex: {
      type: null
    },
    cnt : { type: Number},
    box: {
      default: () => [],
      type: Array
    },
    w_type : { type: null},
    visible_search_box:{type:null},
    visible_sort_box_press:{type:null},
  },
  methods: {
    go_press_info(idx) {
        this.$refs.pressure_info.show(idx)
    },
    clickCallback (pageNum) {
        this.box = [] ;
        //alert(pageNum)
        //this.page = pageNum
        this.$emit('go_search',  this.value, this.sort, this.status_type,pageNum)
    },
    statusColor(item) {
      return item.status === 'red' ? 'status_02': 'status_01'
    },
    go_m2_info(m2_point_id){
       let cur_page = ""+document.location.href ;

      if(cur_page.indexOf("hydraulic") !== -1 ) { 
        //this.$refs.hydraulic_info.show(m2_point_id)
      }else{
        this.$refs.m2_setting.show(m2_point_id)
      }
    },
    selectBox (index) {
      this.$emit('updateCheckIndex', index)

    },
    Enter_Check(){
       this.page=0
       if(event.keyCode == 13){         
             this.$emit('go_search',  this.value, this.sort, this.status_type)
       }
    },
    go_search(){
         this.page=1
         this.$emit('go_search',  this.value, this.sort, this.status_type,this.page)
    },
    goRouter(item) {
      var path = '';
      if(item.measure_mode === 1 ){
        path= 'pressure-detail-map1?w_type=110&widx='+this.$route.query.widx        
      }else{
        path= 'pressure-detail-map?w_type=110&widx='+this.$route.query.widx
      }
      this.$router.push({path: path, query: { widx: this.$route.query.widx }})
    }
  },
  components: {      
      DlgPressureInfo : DlgPressureInfo,   
      pagination
     // DlgHydraulicPointInfo: DlgHydraulicPointInfo,
  }
};
</script>
<style >
.VuePagination .page-item.disabled .page-link {
   background-color: transparent;
}

</style>

<style lang="scss" scoped>
.detail__work-title {
  font-size: 13px;
  margin-top: 1%;
  margin-bottom: 2%;
  &:before {
    content: '●';
    font-size: 10px;
    padding-right: 5px;
  }
}
.left_area .list_box_wrap .box_list .con_box.press{
      width: 70%;
}
.left_area .list_box_wrap.press{
  /*min-height: 710px;*/
  height: calc(95vh - 80px - 85px - 37px - 28px - 10px );
  margin-bottom: 5em;
}
.left_area .list_box_wrap .box_list.box_list02 {
  border: 1px solid #707070;
}
.status {
    /* float: right; */
    // margin-top: 16px;
    float: none; 
}
.left_area .list_box_wrap .box_list.box_list02{
    background: url(../../assets/images/new/ic_03_w.png) left 7% center no-repeat ;
}

.left_area .list_box_wrap .box_list.box_list02.is--leak{
    background: url(../../assets/images/new/ic_05_w.png) left 7% center no-repeat ;
}

</style>


<template>
    <div  :style="{height: height+'px'}">
        <bar-chart ref="chart" v-bind:chartData1="chartData1"  v-bind:chartData2="chartData2"  v-bind:chartData3="chartData3" v-bind:xdata="xdata" 
        v-bind:xdata_name="xdata_name" v-bind:label_name="label_name" v-if="loaded"  v-bind:class="size" @onBarClick="onBarClick"></bar-chart>
    </div>

</template>

<script>
//import * as d3 from 'd3'
import BarChart from '@/components/chart/BarChart_st'
//import {Line} from 'vue-chartjs'

export default {
    name: 'LeakTypeBarChart',
 //   extends: Line,
    components: {BarChart },
    props: [
        'height',
        'url'
    ],
    watch: {
        chartData3: function() {

              //   deep: true,
                   this.redraw();
                // handler(){this.renderChart(this.chartData, this.options); }
        },
        url() {
            if(this.url==='') return
           // datacollection: null,
           // this.chartData= [];
          this.datacollection= null;
          this.chartData1 = [];
          this.chartData2 = [];
          this.chartData3 = [];
          this.xdata = [];
          this.xdata_name = [];
          this.label_name = ['','',''];          
          this.loaded=false;
      //    this.url = "http://localhost:3000/api/get_leak_bubble?w_idx=768" ;
         var w_type=this.url.split('w_type=')
         if(w_type.length >1){
            if(w_type[1] === '100')
              this.label_name = ['Leak','Inner leak','None']
            else  
              this.label_name = ['Cnt','','']
         }
         this.$http.get(this.url).then((res) => {
            //데이터 셋팅
             if( res.data !=="" && res.data != null) {
                var b_data = res.data.rows;
                 for(let i=0; i < b_data.length; ++i){
                  //this.xdata.push(b_data[i].idx+":"+b_data[i].w_title);
                  this.xdata.push(b_data[i].id);
                  this.xdata_name.push(b_data[i].title);
                  this.chartData1.push(b_data[i].leak_count) ;
                  this.chartData2.push(b_data[i].leak_inside) ;
                 // this.chartData1.push(10) ;
                 // this.chartData2.push(20) ;
                  this.chartData3.push(b_data[i].other_count) ;

                }
                for(let j=b_data.length; j < 5; ++j){
                   this.xdata.push("test"+j);
                  this.xdata_name.push("test"+j);
                  this.chartData1.push(0) ;
                  this.chartData2.push(0) ;
                  this.chartData3.push(0) ;
                }
             }else{

                  this.xdata = [];
                  this.chartData1 = [] ;
                  this.chartData2 = [] ;
                  this.chartData3 = [] ;
                  this.xdata_name = [];
             }
          });


        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        datacollection: null,
        chartData1: [],
        chartData2: [],
        chartData3: [],
        xdata_name :[],
        label_name :[],
        size: 'firstClass',
        loaded: false,
        bar_idx:-1
      }
    },
    methods: {

       redraw () {

              this.loaded = true;
            //  this.update();
           //   this.$data._chart.update()
           // this.chartData =  cData ;
            //this.$refs.chart.update();

      },
      onBarClick(){
         this.bar_idx = this.$refs.chart.bar_idx;
         this.$emit('onBarClick') ;
      }



    },
  mounted () {
        window.addEventListener('resize', this.resize);

        if(this.url==='') return
/*
         this.$http.get(this.url).then((res) => {
            //데이터 셋팅
             if( res.data !=="" && res.data != null) {
                var b_data = res.data.rows;
                for(let i=0; i < b_data.length ; ++i){
                  this.xdata.push(b_data[i].w_title);
                  //this.chartData1.push(b_data[i].leak_count) ;
                  //this.chartData2.push(b_data[i].leak_inside) ;
                  this.chartData1.push(10) ;
                  this.chartData2.push(20) ;
                  this.chartData3.push(b_data[i].other_count) ;

                }
             }else{
                  this.xdata = [];
                  this.chartData1 = [] ;
                  this.chartData2 = [] ;
                  this.chartData3 = [] ;
             }
          });
*/

    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    }

}
</script>

<style scoped>
.firstClass{
   height: 630px;

 /*
    padding-top: 10px;
    width: 700px;
    */
  }

</style>

<template>
    <div>
       <b-modal
            ref="modal"
            modal-class="modal-work"
            title="Address List"
            >
   
            <b-row>
            <b-col class="table-box table-box-resize ml-4 mr-4 gis-master-table-box">
          <!-- <h5 class="card-box-title">GIS Data table</h5>
            <label for="textarea-small" class="table-box-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label> -->
             <div  style="width:500px; height:500px;margin:20px 10px;">
                    <vue-good-table ref="addr_table"
                        :columns="columns"
                        :rows="address_list"
                        :line-numbers="true"
                         max-height='430px'
                        @on-row-click="onRowClick"
                        :fixed-header="true"                       
                        styleClass="vgt-table without--head-right-line type01"
                   />            
             </div>     
   
             </b-col>
            </b-row>
          <template v-slot:modal-footer="{ ok, cancel, hide }">

              <b-row  class="modal-footer-row m-0 leak_foot">
                <b-col sm="6">
                 
               </b-col>
               <b-col sm="6">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">Close</b-button>                  
               </b-col>
             
             </b-row>
            </template>
      </b-modal>
<!--
         
-->
  
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;
//import ServerSideTable from '@/components/table/ServerSideTable_simulation';

export default {
    name: 'DlgWorkList', //작업방 옮기기 위한 리스트
    props: {
    },
    watch:{
     

    },
    data() {
        return {
            columns: [
             //   {label: 'Select', field: 'Select', sortable: false},                
                {label: 'address', field: 'address'},             
                {label: 'x', field: 'x', },
                {label: 'y', field: 'y', },

            ],
            map_type : 'Daum',
            move_list: [],
            work_type:100,
            work_id : '',
            address_list:[],
            dlg_type : 1,
            adv_search: [
              {
              
              },
              
          ],
        }
    },

    mounted () {
    },
    methods: {
 
 
      guide_line_work(){
        let rows = this.$refs.work_table.getSelectRows()
        if(rows.length<1) {
          alert(this.$t('leak_detail.alt_select'));
          return;
        }

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        
        this.$emit('show_leak_map',list,"1") ;
        this.$refs.modal.hide();
      } ,
      show(addrlist,map_type ) {  //리스트, 지도 타입(daum, bing)
          this.map_type = map_type
          var arr_list = []
         
          for(var i=0 ; i <addrlist.length ;++i ){
            var item = {}
            if(map_type==='Daum'){
              item = {'address' : addrlist[i].address_name, 'x':Number( addrlist[i].x) ,'y':Number( addrlist[i].y) }
            }else{
              item = {'address' : addrlist[i].formatted_address, 'x': addrlist[i].geometry.location.lng ,'y': addrlist[i].geometry.location.lat  }
            }
            arr_list.push(item)
          }
          this.address_list = arr_list
          this.$refs.modal.show();   
          //this.$refs.addr_table.setUrl(arr_list) 
          //this.$refs.addr_table.setUrl( this.address_list) 
           
    
        },
      onRowClick(params){
        
        this.$emit('set_marker_',params.row.x,params.row.y) ;
        this.$refs.modal.hide();     
        /*
         var con_test1 = confirm("Are you sure you want to Move?");
        
         if(con_test1){
            
            this.$http.get(this.URL+'api/move_location_leakage?w_type='+ this.work_type+'&w_id='+row.id+'&lidx='+this.move_list).then((res) => {  
                console.log(res.data);
                if(res.data.check === 0){
                  this.$http.get(this.URL+'api/move_work_leakage?w_type='+ this.work_type+'&w_id='+row.id+'&lidx='+this.move_list).then((res1) => {  
                     if(res1.data.check === 0){  
                       if(this.work_type === "100")
                         this.$emit('goRouter',"",row.id) ;
                       else  
                         this.$emit('leaktable_refresh') ;
                       this.$refs.modal.hide();
                     }else{
                        alert("The move failed..")
                     }

                  }); 
                }else{
                  alert("The move failed.")
                }
            });
          }
          */
        },
    
    },
    components: {
         //   FFTChart1:FFTChart1
        //  PressBigChartData:PressBigChartData,
        //ServerSideTable: ServerSideTable,
      
    }
}
</script>

<style  lang="scss">

.modal.show.modal-work .modal-dialog {
      max-width: 575px;
      background: #F2F2F2;
   }

.modal-work {
  
  .modal-dialog { 
    background: #172A46;
    width:575px;
    /*height: calc(100% - 70px);*/
    .modal-header {
      padding-bottom: 22px;
      background: #172A46;
      .modal-title {
        font-size: 18px;
        // margin-top: 20px;
      }
    }
    
    .modal-info-content {
      border: 0;
      border-radius: 0;
 
      &.reversal {
        background: #172A46;
        .nav-tabs {
          background: #172A46;
        }
        .tab-content {
          background: #172A46;
          &.is--scroll {
            height: 73vh;
            overflow-y: auto;
          }
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
          // 2022 new
          // background: #fff;
          border-color: 1px solid #707070;
        }
        .facility__label {
          color: #fff;
          font-size: 12px;
          display: inline-block;
          letter-spacing: 0.5px;
          //width: 35%;
        }
        .facility__input {
          //width: 60%;
          height: 32px;
          font-size: 12px;
          border: 1px solid #707070;
          box-sizing: border-box;
          border-radius: 0;
          margin-top: 0;
          margin-bottom: 14px;
          background: #0A192F;
          &.height--auto {
            height: auto;
          }
        }
        .modal-footer {
          background: #172A46;
          padding-bottom: 18px;
          padding-top: 3px;
        }
        .modal-footer-row {
          width: 100%;
          margin: 0;
          padding-bottom: 0;
        }
        .btn.modal-footer__button {
          height: 52px;
          font-size: 14px;
          border: 0;
          &.modal-footer__button-ok {
          // 2022 new
            // background: #28B7CB;
            background: #28AF81;
          
          }
          &.modal-footer__button-cancel {
            background: #9D9D9D;
          }
        }
        .photo-card {
          border-radius: 0;
          border: 0;
          background: none;
          .img-thumbnail {
            margin-bottom: 10px;
          }
          .card-header {
            height: 150px;
            background: #172A46;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .card-header__text {
              font-size: 16px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div>
    <!-- top-bar -->
    <div  ref="navbar" class="navbar-custom">
      <h4 class="content-title">GIS MASTER</h4>
      <ul class="list-unstyled topnav-menu-gm topnav-menu-left">
        <!-- 추가변경 0419 -->
        <!-- <li class="toggle-menu">
           <a href="#"><i class="mdi mdi-menu toggle-menu-btn"></i></a>
         </li> -->
        <!-- 추가변경 0419 -->
        <!-- <li>
          <ol class="breadcrumb with--map"> -->
            <!--                        <li class="breadcrumb-title">GIS Master</li>-->
            <!--                        <li class="breadcrumb-bar">|</li>-->
            <!-- <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
            <li class="breadcrumb-item active">GIS MASTER</li>
          </ol>
        </li> -->
      </ul>
    </div>
    <!-- tom-bar-end -->

    <div>
      <b-row style="margin: 0;">
        <b-col :cols="msize" style="padding: 0 15px 0 0;">
          <div :style="{height: map_height+'px'}">
            <div class="search_div">
                <span class="spn1"><b-input size="sm" type="text" placeholder="Please enter your address" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">search</button></span>
            </div>
            <Map ref="map"
                 :visible_full_extent="false"
                 :visible_active_button="false"
                 :visible_gisload_button="false"
                 :visible_size_view_button="false"
                 :visible_size_side_view_button="true"      
                 layer_hide_leakage="true"
                 @go_home="go_home"

                 @set_status="set_status"
                 @size_view="size_view"
                 @facility_refresh="facility_refresh"
                 @gis_reload = "gis_reload"
                 @change_map_mode="change_map_mode"
            />

            <div style="position: absolute; left: 5px; top: 5px; z-index: 10;">
              <b-button-toolbar aria-label="Toolbar with button groups">
                <b-button-group class="mr-2 icon-button-group">
                  <b-radio :button='true' size="sm" v-model="map_mode" value='select' v-b-tooltip.hover title='select'>
                    <span class="fas fa-mouse-pointer"></span>
                  </b-radio>
                  <b-radio :button='true' size="sm" v-model="map_mode" value='info' v-b-tooltip.hover title='info' >
                    <i class="fas fa-info"></i>
                  </b-radio>
                  <b-radio :button='true' size="sm" v-model="map_mode" value='edit' v-b-tooltip.hover title='edit'>
                    <span class="fas fa-pencil-alt"></span>
                  </b-radio>
                </b-button-group>
                <b-button class="map-button icon_btn"  size="sm" @click="zoom('+')" v-b-tooltip.hover title='zoom(+)'>
                  <span class="fas fa-search-plus"></span>
                </b-button>
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="zoom('-')" v-b-tooltip.hover title='zoom(-)'>
                  <span class="fas fa-search-minus"></span>
                </b-button>

                <b-button class="mr-2 map-button icon_btn" size="sm" @click="delete_feature()" v-b-tooltip.hover title='delete'>
                  <span class="fas fa-window-close"></span>
                </b-button>
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="zoom_fit()" v-b-tooltip.hover title='zoom_fit'>
                  <span class="fas fa-expand"></span>
                </b-button>
              
                <b-button class="mr-2" size="sm"  @click="$refs.upload_shape.show();" v-b-tooltip.hover title='upload_shape'>
                    <i class="fas fa-cloud-upload-alt"></i>
                </b-button>
               
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="toggle_visible_all_layer()" v-show="layer_all_visible" v-b-tooltip.hover title='visible' >
                  <i class="fas fa-toggle-on"/>
                </b-button>
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="toggle_visible_all_layer()" v-show="!layer_all_visible" v-b-tooltip.hover title='invisible'>
                  <i class="fas fa-toggle-off"/>
                </b-button>

                <button  class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text" type="button" @click="set_map_gisload()"  v-b-tooltip.hover title='GIS Load' id="gis_btn_mas" >GIS_Load</button>
              </b-button-toolbar>
              <p id="c_zoom">zoom=</p>

              <div style="width: 300px; ">
                <b-card id="edit" v-if="$refs.map && visible_edit">
                  <template v-slot:header>
                    <b-button-close @click="edit_cancel()"/>
                    <h6 class="mb-0">Edit</h6>
                  </template>
                  <b-row>
                    <b-col>
                      <b-radio v-model="edit_mode" value="add" >Add</b-radio>
                    </b-col>
                    <b-col>
                      <b-radio v-model="edit_mode" value="modify" >Modify</b-radio>
                    </b-col>
                  </b-row>
                  <div v-if="edit_mode==='add'">
                    <b-row v-for="(item, index) in get_edit_layers()" :key="index">
                      <b-col>
                        <b-radio size="sm" v-model="add_layer_name" :value="item.get('name')"> {{item.get('name')}} </b-radio>
                      </b-col>
                    </b-row>
                  </div>
                  <template v-slot:footer>
                    <!-- <b-button @click="edit_ok()" class="mr-2">OK</b-button>
                     <b-button @click="edit_cancel()" >Cancel</b-button>-->
                  </template>

                </b-card>
              </div>

            </div>



          </div>
        </b-col>
        <b-col  v-if="v_chart" :cols="tsize" style="padding: 0 10px 5px 0;margin-left:-5px; ">
                 <div class="info_box leak">
                   
                  <ul class="status list" style="padding:20px;">
                      <div style="padding-bottom:10px;"> <input type="radio" id="radio_01" name="radio01"  v-model="gis_type" value="1" >
                           <label for="radio_01" style=" padding-left: 15px; font-size: 20px;" > Ponit not Connected with line</label>
                      </div>
                      <div> <input type="radio" id="radio_02" name="radio02"  v-model="gis_type" value="0" >
                           <label for="radio_02" style=" padding-left: 15px; font-size: 20px;"> End of line not connected with other line or point</label> </div>
                      <!--div>  <input type="checkbox" id="chk_02" value='-1' v-model="checkedNames"> All </div-->
                       <div style="float:right;margin-top:-40px;margin-right:20px;">
                          <!--b-button v-if="!loading" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text"  size="sm" @click="get_gis_check_list">
                                Search
                            </b-button-->
                            <span v-if="loading"><img src="@/assets/images/loading.gif" style="width:25px;"/></span>
                        </div>
                  </ul>
                
                </div>
          <div class="list_box list_box2 gis_check">
          <h5 class="card-box-title gis_check">TopologyCheck</h5>
          <div class="table-box" >   
          <ServerSideTable ref="facility_list"
                           :columns="columns"
                           :search="false"
                           :sort="true"
                           :select="true"
                           :add_custom_column="true"
                           :adv_search="adv_search"
                           :max_height="table_height+'px'"
                           @search_item_change="search_item_change"
                           @on_info_click="on_facility_info"
                           @on_goto_map_click="on_goto_map_click"
          />
          </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <!--UploadShape ref="upload_shape"/-->
  </div>
</template>

<script>

import ServerSideTable from '@/components/table/ServerSideTable';
import Map from '@/components/Map';
import proj4 from 'proj4';
import GeoJSON from 'ol/format/GeoJSON';
import {CreateVectorLayer_f} from '@/gis.js';
//import {getLength} from 'ol/sphere';
//import {LineString} from 'ol/geom';
export default {
  name: 'GISTopologyCheck',
  props: {
  },
  data() {
    return {
      map_mode: 'select',
      addr_value :'',
      layer_all_visible: false,
      visible_edit: false,
      edit_mode: 'add',
      add_layer_name: 'Service Pipe',
      add_fe: [],
      contents_height: 600,
      map_height: 300,
      table_height: 300,
      gis_type : 1,
      loading:false,
      //    new_add_fa : 0 ,
      msize:'7',
      tsize:'5',
      v_chart : true,
      columns: [
        {label: 'Map', field: 'go_map', sortable: false},
        {label: 'Info', field: 'info', sortable: false},
        {label: 'No', field: 'id'},
        {label: 'Name', field: 'name'},
        {label: 'layer_type', field: 'layer_type', sortable: false, hidden: true,},
        {label: 'Layer Type', field: 'type_', sortable: false},
        {label: 'F_Type', field: 'type', sortable: false},
       // {label: 'X', field: 'X'},
       // {label: 'Y', field: 'Y'},
    //  {label: 'Material', field: 'material'},
    //  {label: 'Block', field: 'block_id'},
    //  {label: 'Size', field: 'size'},
    //  {label: 'Unit', field: 'unit_'},
    //  {label: 'Capacity', field: 'capacity'},
    //  {label: 'Worker', field: 'member_id'},
    //  {label: 'Date Install', field: 'installation_date'},
        {label: 'Status', field: 'f_status_', sortable: false}
      ],
      adv_search: [
        {
          field: 'layer_type',
          control: 'select',
          value: null,
          options: [
            { value: null, text: 'Type' },
            { value: '0', text: 'Pipe' },
            { value: '1', text: 'Valve' },
            { value: '2', text: 'Water Meter' },
            { value: '3', text: 'Pump'},
            { value: '4', text: 'Hydrant'},
            { value: '7', text: 'Block'},
            { value: '8', text: 'Water Treatment Plant'},
            { value: '9', text: 'Pumping station'},
            { value: '10', text: 'Water_basin'},
            { value: '11', text: 'Chamber'},
            { value: '12', text: 'Gauge'},
            { value: '13', text: 'Other'},
          ]
        },
        {field: 'type', control: 'select', value: null, options: [{ value: null, text: 'Facility type' }]},
        {field: 'size', control: 'range', value: null, value2: null, placeholder: 'From size', placeholder2: 'To size'},
        {field: 'status', control: 'select', value: null,
          options: [
            { value: null, text: 'Status Type' },
            { value: 'A', text: 'Active' },
            { value: 'I', text: 'Inactive' },
            { value: 'U', text: 'Unable' },
          ]
        },
        {field: 'block_id', control: 'select', value: null, options: [{ value: null, text: 'Block name' }]},
      ]
    }
  },
  watch: {
   
    gis_type(){
       this.get_gis_check_list();      
      /*
       var cz = (this.$store.setting.map_name === 'Daum')? 13 : 19 ;
        if(this.$refs.map.map.getView().getZoom() < cz){
          alert(" Please zoom(+) the map => zoom > 12") ;
          return;
        }
        let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
        var extent1 = this.$refs.map.map.getView().calculateExtent(this.$refs.map.map.getSize());  
        if(sessionStorage.customer_id === "1")
          this.$refs.facility_list.setUrl(this.URL+"api/get_facility_check_list_ex?gis_check_id="+this.gis_type+"&bbox="+extent1+"&srid="+epsg+"&customer_id="+sessionStorage.select_customer_id);
        else   
          this.$refs.facility_list.setUrl(this.URL+"api/get_facility_check_list_ex?gis_check_id="+this.gis_type+"&bbox="+extent1+"&srid="+epsg+"&customer_id="+sessionStorage.customer_id);  
*/
    },
    v_chart: function() {
          if(this.v_chart){
  
            setTimeout(()=>{
              this.get_gis_check_list();      
            }, 500)

          }
      },    
    add_layer_name() {
      this.$refs.map.edit_change(this.edit_mode, this.add_layer_name)
    },
    edit_mode() {
      this.$refs.map.edit_change(this.edit_mode, this.add_layer_name)
    },
    map_mode() {
      if(this.map_mode==='edit') {
        if(this.layer_all_visible){
          this.visible_edit = true
          this.$refs.map.edit_start(this.edit_mode, this.add_layer_name)
        }else{
          alert('GIS_Load button 클릭후 현재 관망도를 확인 후 진행해주세요.') ;
          this.$refs.map.select_info = this.map_mode==='info'
        }
      }
      else {

        this.$refs.map.select_info = this.map_mode==='info'
        this.visible_edit = false
        this.$bvToast.hide('edit');
        this.$refs.map.edit_cancel();
      }
    }
  },
  methods: {
    addr_search(){
      this.$refs.map.go_search(this.addr_value);
    },
    get_gis_check_list(){
      let loading1 = this.$refs.facility_list.isLoading  //true : 실행중, false : 완료시

      if(loading1){
         alert("")
         return 
      }else{
        var cz = (this.$store.setting.map_name === 'Daum')? 13 : 19 ;
        if(this.$refs.map.map.getView().getZoom() < cz){
          alert(" Please zoom(+) the map => zoom > "+cz) ;
          return;
        }
        this.loading = true;

        let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
        var extent1 = this.$refs.map.map.getView().calculateExtent(this.$refs.map.map.getSize());  
        if(sessionStorage.customer_id === "1")
          this.$refs.facility_list.setUrl(this.URL+"api/get_facility_check_list_ex?gis_check_id="+this.gis_type+"&bbox="+extent1+"&srid="+epsg+"&customer_id="+sessionStorage.select_customer_id);
        else   
          this.$refs.facility_list.setUrl(this.URL+"api/get_facility_check_list_ex?gis_check_id="+this.gis_type+"&bbox="+extent1+"&srid="+epsg+"&customer_id="+sessionStorage.customer_id);  
       
           
         
        var timmer =  setInterval(()=>{ // 타이머로 반복할 코드
            //alert(this.$refs.facility_list)
            if(this.$refs.facility_list !== undefined){
              if(!this.$refs.facility_list.isLoading){
                this.loading = false
                clearInterval(timmer);
              }
            }
          }, 1000);
           setTimeout(()=>{
              this.loading = false;  
               clearInterval(timmer);              
           }, 10000)    
      }
    },
     size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
        },
    Enter_Check1(){
      this.addr_search();
    },
    toggle_visible_all_layer() {

      this.layer_all_visible = !this.layer_all_visible
      let map = this.$refs.map.map
      for(let i=1; i<map.getLayers().getLength(); i++) {
        let layer = map.getLayers().item(i)
        if(layer.get('code')>50) continue
        layer.setVisible(this.layer_all_visible)
      }
    },
    change_map_mode(){
      this.map_mode = 'select'
      this.visible_edit = false
    },
    set_map_gisload() {

      let map = this.$refs.map.map;
      map.layer_all_visible = true ;
      this.layer_all_visible = true;
      var size1 = map.getSize();
      var size = [size1[0]*2, size1[1]*2] ;
      //   if( (this.new_add_fa%2) === 1 ){
      //     size =  [size1[0]*3, size1[1]*3] ;
      //   }
      //    map.updateSize();
      var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
      let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
      var extent = map.getView().calculateExtent(size);

      if(this.$refs.map.map.getView().getZoom() < cz){
        alert("지도를 확대해 주세요!!!") ;
      }else{
        // 추가 되었던 소스 가져와서 삭제하기
        // heat layer 삭제

        var h_layer = this.$refs.map.heat_layer ;
        if(h_layer != null) {
          var source_h = h_layer.getSource();
          var hfeatures = source_h.getFeatures();
          for(var k=0; k< hfeatures.length ; k++) {
            source_h.removeFeature(hfeatures[k]);
          }
          map.removeLayer(h_layer) ;
          h_layer = null;
        }
        // remove_layer(map);
        for(let i=0; i<this.$refs.map.layer_list.length; i++) {
          var layer = this.$refs.map.layer_list[i];
          if(layer=== null || layer.values_ === null) continue
          var source1 = layer.getSource();
          var features = source1.getFeatures();
          for(var j=0; j< features.length ; j++) {
            source1.removeFeature(features[j]);
          }
          map.removeLayer(layer) ;
        }
        this.$refs.map.layer_list = [] ;
        for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

          var params = this.$refs.map.layer_setting_stlye_list[i];
          // var nstyle = params.style;
          var _url=params.surl;
          // if(params.code >50 || layer.get('code')=== undefined ) continue

          var lftaye = params.f_type;

          _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

          var newlayer = CreateVectorLayer_f(params,extent) ;

          var vis = params.visible ;
          newlayer.setVisible(vis);
          this.$refs.map.layer_list.push(newlayer) ;
          map.addLayer(newlayer);


        } //for문 끝
        this.$refs.map.init_draw_modify();
      } // zoom크기에 대한 if


    },
    edit_ok() {
      let writer = new GeoJSON();
      //좌표값 변환
      for(let i=0; i<this.$refs.map.add_modify_features.length; i++) {
        let item = this.$refs.map.add_modify_features[i]
        item.feature = JSON.parse(writer.writeFeature(item.feature))

        if(item.code===1 || item.code ===2 ||  item.code ===3 ){ //point일 경우
          var cod_point = item.feature.geometry.coordinates ;
          item.feature.geometry.coordinates = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', cod_point)

        }else if(item.code===7){
          var cod_block = item.feature.geometry.coordinates ;

          for(let k=0; k<cod_block.length; k++) {//블럭갯수
            var chang_block_d=cod_block[k] ;
            for(let l=0; l<chang_block_d.length; l++) { //블럭안에 point갯수
              chang_block_d[l] = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', chang_block_d[l])
            }
            cod_block[k] = chang_block_d ;
          }
        }else{
          for(let j=0; j<item.feature.geometry.coordinates.length; j++) {
            var cod = item.feature.geometry.coordinates[j] ;
            item.feature.geometry.coordinates[j] = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', cod)
          }
        }

      }
      //db에 저장
      if(this.$refs.map.add_modify_features.length>0) {
        this.$http.get(this.URL+'api/add_modify_features?json='+encodeURIComponent(JSON.stringify(this.$refs.map.add_modify_features))).then(() => {
          let lyr = this.get_edit_layers()
          for(let i=0; i<lyr.length; i++) {
            lyr[i].getSource().clear()
            lyr[i].getSource().refresh()
          }
        })
      }

      this.map_mode = 'select'
      this.$bvToast.hide('edit')
      this.visible_edit = false
      this.$refs.map.edit_end()
    },
    edit_cancel() {
      this.map_mode = 'select'
      this.visible_edit = false
      this.$refs.map.edit_end();
      /*
      let lyr = this.get_edit_layers()
      for(let i=0; i<lyr.length; i++) {
          lyr[i].getSource().clear()
          lyr[i].getSource().refresh()
      }
      */
    },

    zoom(e) {
      var z = this.$refs.map.map.getView().getZoom() ;
      if(e==='+'){
        this.$refs.map.map.getView().setZoom(z+1);
      }else{
        this.$refs.map.map.getView().setZoom(z-1);
      }

    },
    zoom_fit() {
      this.$refs.map.set_fit_extent(this.$store.setting.extent)
    },
    delete_feature() {
      this.$refs.map.delete_feature()
    },
    get_edit_layers() {
      let lyrs = this.$refs.map.get_layers()
      let ll = []
      for(let i=1; i<lyrs.length; i++) {
        if(parseInt(lyrs[i].get('code'))>=100) continue
        ll.push(lyrs[i])
      }
      return ll
    },
    go_home() {
      this.$router.push({path: '/', query: { extent: this.$refs.map.get_view_extent() }})
    },
    on_goto_map_click(row) {

      this.$refs.map.get_extent('f_idx='+row.id).then((data)=>{
        this.$refs.map.set_fit_extent(data, ()=>{
          this.$refs.map.select_facility_feature(row.gis_layer_id, row.type, row.id)
        })
      })

    },
    on_facility_info(row) {

      this.$refs.map.show_facility_info(row.id)
    },
    set_status(e) {
      let rows = this.$refs.facility_list.getSelectRows()
      if(rows.length<=0) return;
      let list = []
      for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
      this.$http.get(this.URL+'api/set_facility_status?f_status='+e+'&f_idx='+list).then(() => {
        this.$refs.facility_list.loadItems()
      })
    },
    facility_refresh(){
      //   this.$bvToast.hide('edit') ;
      //this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id="+sessionStorage.customer_id);
      if(sessionStorage.customer_id === "1")
        this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id="+sessionStorage.select_customer_id);
      else   
        this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id="+sessionStorage.customer_id);


      this.init_mode();
      /*
      if(a===0){
        ++this.new_add_fa;
        this.set_map_gisload()
      }
      */
      //  this.gis_reload();
    },
    gis_reload(){
      this.edit_cancel() ;
      this.$bvToast.hide('edit') ;
      this.set_map_gisload() ;
    },
    init_mode(){
      this.edit_cancel() ;
      this.$bvToast.hide('edit') ;
    },
  
    search_item_change(e, index) {
      if(index==0) {
        if(this.adv_search[0].value===null) {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [{ value: null, text: 'Facility Type' }];
        }
        else if(this.adv_search[0].value==='0') {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [
            { value: null, text: 'Pipe Type' },
            { value: 'Service', text: 'Service' },
            { value: 'Supply', text: 'Supply' },
            { value: 'Drain', text: 'Drain' },
            { value: 'Other', text: 'Other' }
          ]
        }
        else if(this.adv_search[0].value==='1') {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [
            { value: null, text: 'Valve Type' },
            { value: 'PRV', text: 'PRV' },
            { value: 'Control', text: 'Control' },
            { value: 'Drain', text: 'Drain' },
            { value: 'Boundary', text: 'Boundary' },
            { value: 'Relief', text: 'Relief' },
            { value: 'Other', text: 'Other' }
          ]
        }
        else if(this.adv_search[0].value==='2') {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [
            { value: null, text: 'Water Meter Type' },
            { value: 'Mechanical', text: 'Mechanical' },
            { value: 'Digital', text: 'Digital' },
            { value: 'Other', text: 'Other' }
          ]
        }
        else if(this.adv_search[0].value==='7') {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [
            { value: null, text: 'Block Type' },
            { value: 'Big', text: 'Big' },
            { value: 'Middle', text: 'Middle' },
            { value: 'Small', text: 'Small' },
            { value: 'Sub', text: 'Sub' }
          ]
        }
        else {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [ { value: null, text: '' } ]
        }
      }
    }
  },
  created () {
  },
  mounted () {
    this.contents_height = window.innerHeight - 120 
    this.map_height = this.contents_height
    this.table_height = 620;
    this.$refs.map.get_extent('f_idx='+this.$route.query.f_idx).then((data)=>{
        this.$refs.map.set_fit_extent(data)     
       
        if(this.$store.setting.map_name==='Daum')
          this.$refs.map.map.getView().setZoom(13);
        else
          this.$refs.map.map.getView().setZoom(19);   

    
    })
   
    this.$refs.map.update_size()
    setTimeout(()=>{
    
        var extent1 = this.$refs.map.map.getView().calculateExtent(this.$refs.map.map.getSize());  
        let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
        if(sessionStorage.customer_id === "1")
          this.$refs.facility_list.setUrl(this.URL+"api/get_facility_check_list_ex?gis_check_id=1&bbox="+extent1+"&srid="+epsg+"&customer_id="+sessionStorage.select_customer_id);
        else   
          this.$refs.facility_list.setUrl(this.URL+"api/get_facility_check_list_ex?gis_check_id=1&bbox="+extent1+"&srid="+epsg+"&customer_id="+sessionStorage.customer_id);  
              
     }, 500)
  },
  beforeDestroy () {
    //console.log('GITSMaster destroyed!!')
    //console.log(this.$refs.map);
  },
  
  components: {
    ServerSideTable: ServerSideTable,
    
    Map: Map,
   // UploadShape: () => import('@/components/UploadShape'),
  }
}


</script>
<style  lang="scss" scoped>
.map-button.icon_btn {
  font-size: 14px;
  line-height: 1;
  margin-right: 6px;
}
.map-button.icon_btn.icon_btn_text {
  font-size: 12px;
}
.icon-button-group ::v-deep .btn-secondary {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  float: left;
  margin-right: 6px;
  font-size: 14px;
  line-height: 23px;
  background: rgba(35, 41, 47, 0.8) !important;
  border-color: transparent !important;
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.icon_btn.btn-secondary {
  background: rgba(35, 41, 47, 0.8);
  border-color: transparent;
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.btn-map-view {
  background: rgba(35, 41, 47, 0.8);
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.map-zoom {
  font-size: 14px;
  float: left;
  margin-right: 10px;
  margin-bottom: 0;
  margin-top: 5px;
}
.btn-toolbar {
  margin-right: 0;
}
.gis-master-table-box {
  background: #172a46;
  padding-bottom: 0;
}
.search_div{
    position: absolute;
    left:460px;
    top: 10px;
    z-index: 110;
}
.spn1 {
      float: left;
}
.list_box.list_box2.gis_check{
  padding: 10px;
  height:calc(100vh - 37px - 35px - 70px - 21px - 1rem - 90px  );
  background: #172a46;
}
.card-box-title.gis_check{
  padding-left: 20px;
}
</style>

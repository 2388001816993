<template>
  <div class="left_area" style="width: 100%;">
    <div class="left_list_box">
      <div v-if="visible_search_box" >
            <span class="status m1-map model" style="position: relative;top:0px;text-align:center;margin-bottom:10px;">
              <b-button size="sm" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue" @click="delete_modeling" >Delete</b-button>
              <b-button size="sm" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue" @click="importTextFile">New</b-button>
              <!--b-button size="sm" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="make_modeling(0)">View</b-button>
              <b-button size="sm" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="make_modeling(0)">Inp</b-button-->                 
            </span>   
      </div>
      <div class="input_search_box" style=" margin-top: 10px;" >
        <input type="text" class="input_search" v-model="value"   placeholder="Search..."  @keyup.enter ="Enter_Check">
        <input type="submit" class="input_submit" @click="search" >
      </div>

      <div class="list_box_wrap dma scroll_box" style="transform: translateZ(0);-webkit-transform: translateZ(0);">
        <div :class="['box_list box_list_dma', { 'is--active':activeCheckIndex === index  } ]" v-for="(item, index) in box" :key="index" >
          <div class="con_box dma" @click="selectBox( index)">
            <span class="num bottom" style="font-size:12px;left: 15px;">{{ item.idx }}</span>
            <p class="title ">{{ item.title }}</p>
            <p class="title ">{{ item.contents }}</p>
            <span class="unit ">{{ item.insert_datetime }}</span>           
            
          </div>
            <div style="    position: absolute;    top: 30%;    right: 3%; z-index:1000;" >
                
                <!--img v-if="activeCheckIndex === index" src="@/assets/images/Common/table/icon_option.png" alt="" @click="$emit('on_facility_info',item.idx)" style="margin:10px; width:25px;"-->
                <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="on_inp_file_info(item.idx)" style="margin:10px; width:25px;">
                <!--img src="@/assets/images/Common/icon/map_1.png" alt="" @click="goRouter('/pressure-monitoring-map?w_type=111',item.id,item.flow )"-->
                <!--b-button size="sm" class="btn map-button icon_btn icon_btn_text blue" @click="InpfileDownload(index)">.inp Download</b-button-->
                <!--button :class="['btn btn-map-view ml-1  map-button icon_btn icon_btn_text ', {'red': 'red' === item.color},{'blue': 'blue' === item.color},{'gray': 'gray' === item.color}]"  style="float:right;" alt="" @click="$emit('on_goto_map_click',  item)">{{item.type}}</button-->
            </div>
        </div>
      </div>
    </div>
  
    <DlgInpFileInfo ref="inp_file_info" @search="search" />
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"  force-use-infinite-wrapper="true">
       <div slot="no-more" style="color: rgb(102, 102, 102); font-size: 14px; padding: 10px 0px;">{{$t('water_leak.left_menu.no_data')}}</div>
    </infinite-loading>
  </div>
  
</template>

<script>
//pressure-monitoring,leak-monitoring 호출
import DlgInpFileInfo from '@/components/popup/DlgInpFileInfo' 
import InfiniteLoading from 'vue-infinite-loading';

//import { Project, Workspace,NodeProperty,CountType,  NodeType,LinkProperty} from 'epanet-js';
import { Project, Workspace} from 'epanet-js';

export default {
  name: 'SearchLeftType3',
  watch: {
    block_type() {
      this.search();   // 실행할 이벤트
      
    },
    sort_(){
      this.search();       
    }
  },
  data() {
    return {
      value: '',
      file_text:'',
      read_file_name:'',
      block_type: '',
      sort_:'',
      strength_sort_yn : '',
      infiniteId : +new Date(),
      page:1,
      box:[],
      color: '' ,
      sort:'',
      img_com_sense : require('@/assets/images/sub/m2/signal5.png'),
     // select_id : null,
     active_id : 0,
    }
  },
  props: {
      activeCheckIndex: {
      type: null
    },
    select_id :  {
      type: null
    },
 //   box: {
 //     type: Array
 //   },
   isBottomIndex: {
      type: Boolean,
      default:true
    },
    w_type : { type: null},
    visible_search_box:{type:null},
    
  },
  methods: {
    importTextFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'text/plain'; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"
      // this 접근을 위해 선언 필요
      const self = this;
      input.onchange = function () {
          const file = new FileReader();
          file.onload = () => {
          //    document.getElementById('text-data').textContent = file.result;
            //  self.$data.textData = file.result;
              self.$data.file_text = file.result
              self.loading_model();
            
          };
          self.$data.read_file_name = this.files[0].name ;
          file.readAsText(this.files[0]);
      };
      input.click();
      // const fs = require("fs");
      // const net1 = fs.readFileSync("net1.inp");
      // Runs toolkit methods: EN_open, EN_solveH & EN_close
    },
    loading_model(){
        const ws = new Workspace()
        const model = new Project(ws)
        ws.writeFile("net2.inp", this.file_text);

        model.open("net2.inp", "report.rpt", "out.bin");
        model.solveH();
                        
       // const nodeIds = this.getNodes_return(model);
       // const linkIds = this.getLinks_return(model);
        
        model.close();
        
        const reportFile = ws.readFile("report.rpt");
        
        //nodecnt : 압력값을 가져온다.
        
        //lint : 유량값을 가져온다.
         


        //document.getElementById("epanet-report").innerHTML = reportFile + "<br/>" +nodeIds + "<br/>"+linkIds;
        //  ++this.listKey;
        //document.getElementById("epanet-report").innerHTML = "model.node count : " + cnt;
        let params = {} ;
        params["customer_id"] = sessionStorage.select_customer_id;
        params["customer_member_id"] = sessionStorage.m_idx;
        params["name"] = this.read_file_name;
        params["file_name"] = this.read_file_name;
        params["inp_file"] = this.file_text;
        params["rpt_file"] = reportFile;

        this.$http.post(this.URL+'api/set_modeling_upload' ,params).then((res) => {
          console.log(res.data);
          if(res.data.check ===1){
              alert("fail : "+ res.data.msg) ;
          }else{
              alert("success !! : "+ res.data.new_id) ;
          }
          //left_list를 refresh한다.
        })
    }, 
    delete_modeling(){
        alert("Do you want to delete?")
        if(sessionStorage === null || typeof sessionStorage === undefined){
                alert("Can not be modified.")
                return
    //    }else if(localStorage.level === 'C'){
        }else if(sessionStorage.level > 1){
          //  alert("You do not have permission to edit.")
            alert(this.$t('water_leak.left_menu.alert_no_permission'));
            return;
        }
        if(this.select_id < 0) return;
        let params = {} ;
        let model_info = {};
        params["id"] = this.select_id ;
        model_info["status"] = 'U';
        params["fields"] = encodeURIComponent(JSON.stringify(model_info))
        this.$http.post(this.URL+'api/set_modeling_upload' ,params).then((res) => {
          console.log(res.data);
          if(res.data.check ===1){
              alert("fail : "+ res.data.msg) ;
          }else{
             alert("Delete success!!")
             this.search();
          }
          //left_list를 refresh한다.
        })
    },   
    //geojson 파일 만들기       
    
    infiniteHandler($state) {
       //sort_field=this.sort&sort_type=desc
       var customer_id = sessionStorage.select_customer_id ; 
       if(sessionStorage.select_customer_id === "27"){
          customer_id = 22
        }

      //var active_id = 0 
      this.$http.get(this.URL+'api/get_model_list_ex?search='+this.value+'&page='+this.page+'&sort='+this.sort_+'&customer_id='+customer_id).then((results) => { 
        if (results.data.rows.length > 0) {
          this.page += 1;
          for(let i=0; i<results.data.rows.length; i++) {    
            
             var abox = {};
            
               abox = {'title': results.data.rows[i].name, 
                       'idx':  results.data.rows[i].id,
                       'name' : results.data.rows[i].name,
                       'contents' : results.data.rows[i].contents,
                       'insert_datetime':  results.data.rows[i].insert_datetime,                                             
               
               } ; 
           if(this.select_id ===  results.data.rows[i].id)
               this.active_id=i
            this.box.push(abox) ;
          }

          //if(this.page === 2){
             
            
         // }
          $state.loaded();
        } else {
         this.selectBox(this.active_id)
          $state.complete();
        }
      });
    },   
    
    statusColor(item) {
      return item.status === 'red' ? 'status_02': 'status_01'
    },
    on_inp_file_info(id){
      this.$refs.inp_file_info.show(id)
    },
    InpfileDownload (index) {
      this.$emit('InpfileDownload', this.box[index].idx, this.box[index].title)
    },
    selectBox (index) {
      this.select_id = this.box[index].idx ;
      this.$emit('updateCheckIndex', index,this.box[index].idx)
     
    },
    search(){      
      this.page = 1;
      this.box = [];
      this.infiniteId += 1;
    },
    Enter_Check(){
      
       if(event.keyCode == 13){
          this.search();
         /*
         if(this.visible_search_box)
           this.$emit('go_search',  this.value, this.leak_type,this.strength_sort_yn) ;  // 실행할 이벤트
          else
             this.$emit('go_search',  this.value)
         */    
       }
    },
    goRouter(path,tag,last_date) {

        if(last_date.length < 10 ) return;
        var last_str = last_date.substr(0,10)
        if(this.w_type === '102' ){
          path= 'leak-monitoring-detail-map?w_type=102'
          last_str = last_date.substr(0,16)
        }
        this.$router.push({path: path, query: { tag: tag, mea_date : last_str  }})
    }
  },
   mounted () {
   },
  components: {      
      DlgInpFileInfo : DlgInpFileInfo,
      InfiniteLoading,
  }
};
</script>

<style lang="scss" scoped>
.detail__work-title {
  font-size: 13px;
  margin-top: 1%;
  margin-bottom: 2%;
  &:before {
    content: '●';
    font-size: 10px;
    padding-right: 5px;
  }
}
.left_area .list_box_wrap .box_list.box_list_dma {
 background: url('../../assets/images/sub/epanet/memory_2.png') 80% 15px no-repeat ;
 opacity : 0.6;
}
.left_area .list_box_wrap .box_list .con_box.press{
      width: 70%;
 
}
.left_area .list_box_wrap.dma{  
  height:calc(100vh - 70px - 1rem - 70px - 140px );
}
.map-button.icon_btn.icon_btn_text.blue {
  background-color: #28AF81;
}
.map-button.icon_btn.icon_btn_text.red {
  background-color: #c85b6d;
}
.model .map-button.icon_btn.icon_btn_text {
  margin : 0 10px 0 10px;  
}
.list_box_wrap.dma .box_list.box_list_dma.is--active,
.list_box_wrap.dma .box_list.box_list_dma:hover {
      /* 2022 new*/
      /* border-color: #6fcdda; */
      border: 1px solid #28AF81;                    
      background-color: #0E5340;
      cursor: pointer;
  }
</style>

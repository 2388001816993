<template>
  <highcharts id= "wavchart"  class="wavchart" :options="chartOptions" ref="scada_"
  :key="rerenderKey" ></highcharts>
</template>

<script >
//import 'highcharts/css/highcharts.css';
//import Highcharts from 'highcharts'

export default {
  name: 'scadaChart1',
   props:{
      chartData_1: {  type: Array  },//주측 series1
      chartData_2: {  type: Array  },//주측 series2
      chartData_3: {  type: Array    },//주측 series3
      chartData_4: {  type: Array    }, //주측 series4
      chartData1_1: {  type: Array    }, //보조측 series1
      chartData1_2: {  type: Array    }, //보조측 series1
      chartData1_3: {  type: Array    }, //보조측 series1
      chartData1_4: {  type: Array    }, //보조측 series1
      interval : { type : Number,  },
      sdate : {  type : Number,  },
      y_min1 : {  }, //ymin,ymax 주측 보조측 최소값
      y_max1 : {  },
      y_min2 : {  },
      y_max2 : {  },
      vchart : {}, //1비율, 0 고정 
      y_label_1: {   type: String    }, //주측 series 1 name
      y_label_2: {   type: String    }, //주측 series 2
      y_label_3: {   type: String    }, //주측 series 3
      y_label_4: {   type: String    }, //주측 series 4
      y_label1_1: {   type: String    }, //보조측 series1 1
      y_label1_2: {   type: String    }, //보조측 series1 2
      y_label1_3: {   type: String    }, //보조측 series1 3
      y_label1_4: {   type: String    }, //보조측 series1 4

      y_title : { type:String},
      y_title1 : { type:String},
      x_title : { type:String},
      chart_type : { type:String}
  },
  data () {
    return {
       rerenderKey: true,
       chartOptions: {
         background : "#172a46",
         colorAxis:[{lineColor:["#2caffe", "#544fc5", "#00e272", "#fe6a35", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"]}],
         colors : ["#2caffe", "#c4c885", "#00e272", "#f3a73d", "#6b8abc", "#d568fb", "#2ee0ca", "#fa4b42", "#feb56a", "#91e8e1"],
         credits: {
          text: '',
          href: ''
        },

        series: [{
          name:  this.y_label_1,
          showInLegend: true,
          followPointer: true,
          //data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
          data: this.chartData_1,
          type:this.chart_type,
           //lineWidth: 1.0,
           yAxis: 0,     
        },
       
        {
          name:  this.y_label_2,
          showInLegend: true,
          followPointer: true,
         // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
          data: this.chartData_2,
           type:this.chart_type,
         //  lineWidth: 2.0,
           yAxis: 0,     
        },
        {
          name:  this.y_label_3,
          showInLegend: true,
          followPointer: true,
         // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
          data: this.chartData_3,
           type:this.chart_type,
         //  lineWidth: 1.0,
           yAxis: 0,     
        },
        {
          name:  this.y_label_4,
          showInLegend: true,
          followPointer: true,
         // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
          data: this.chartData_4,
           type:this.chart_type,
         //  lineWidth: 1.0,
           yAxis: 0,     
        },
        {
           name:  this.y_label1_1,
           showInLegend: true,
           data: this.chartData1_1,
           type:this.chart_type,
         //  lineWidth: 1.0,
           yAxis: 1,
        },
        {
           name:  this.y_label1_2,
           showInLegend: true,
           data: this.chartData1_2,
           type:this.chart_type,
        //   lineWidth: 1.0,
           yAxis: 1,
        },
        {
           name:  this.y_label1_3,
           showInLegend: true,
           data: this.chartData1_3,
           type:this.chart_type,
         //  lineWidth: 1.0,
           yAxis: 1,
        },
        {
           name: this.y_label1_4,
           showInLegend: true,
           data: this.chartData1_4,
           type:this.chart_type,
         //  lineWidth: 1.0,
           yAxis: 1,
        }
      
        ],
         
          tooltip: {
            valueDecimals: 2,
            shared: true,
            crosshairs: true,
            xDateFormat: '%Y-%m-%d %H:%M',
            //pointFormat : this.x_title==="Time" || this.x_title===null  || this.x_title==="" ? "x: {point.x:%Y-%m-%d %H:%M} <br> y: {point.y}":"x: {point.x} <br> y: {point.y}"
          },
           boost: { enabled: true },
          //dateTimeLabelFormats:{second : {main:"%Y-%m-%d %H:%M"}},
            plotOptions: {
             /*
              scatter: {
                marker: {
                    radius: 2.5,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                           // lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                jitter: {
                    x: 0.005
                }
            
          },
            
        */
        
          },
       // }],      
         title: {        text: '', },
         chart: {   zoomType: 'xy' , 
               //height:'inherit',
              //   styledMode: true,
                
                },
         /*
         tooltip: {
                formatter: function() {
                        return '<b>'+ this.series.name +'</b><br/>'+
                        this.dateFormat('%Y-%m-%d %H:%M:%S', this.x) +'<br/>'+
                        this.numberFormat(this.y, 2);
                }
            },
            */
        //yAxis : {title:{text:''}},   
        
          yAxis: [{ // left y axis
              //min : this.ymin,
              gridLineColor: '#2a4369',
              title: {
                  text: this.y_title     
                //text:'Pressure'         
              },
              min: this.vchart === "1"  ? null : this.y_min1 ,
              max: this.vchart === "1" ? null :this.y_max1 ,
              labels: {
                  align: 'left',
                   x: -10,
                   y: 16,
                  format: '{value:.,0f}'
              },
              showFirstLabel: false
          }, { // right y axis
            //  linkedTo: 1,
              gridLineWidth: 0,
              min: this.vchart === "1" || this.y_label1_1 === null ? null :this.y_min2 ,
              max: this.vchart === "1" ||  this.y_label1_1 === null ? null :this.y_max2 ,
              //min: this.vchart === "1" || this.chart_type === 'scatter' ? null :this.y_min2 ,
              //max: this.vchart === "1" || this.chart_type === 'scatter' ? null :this.y_max2 ,
              alignTicks: false,
              opposite: true,
              title: {
                  text: this.y_title1    
              },
              labels: {
                align: 'right',
                x: 10,
                y: 16,
                format: '{value:.,2f}',
                style: { color: '#FF0000' }  
              },
              showFirstLabel: false
          }],
        xAxis: { type: this.x_title === "Time" || this.x_title === undefined ? 'datetime'  : '',
                 title: {
                 // text: this.y_title     
                  text: this.x_title        
                 },
     
            
                labels: { 
                 // format :  this.x_title === "Time" || this.x_title === undefined ?  '{value:%Y-%m-%d %H:%M} ' : '{value:.,2f}'
                }  
             },
       legend: {
                align: 'left',
                verticalAlign: 'top',
                borderWidth: 0,
                itemStyle : {"color" : '#c0c0c0'},
                itemHoverStyle: {"color" : '#fff'},
            },       
    },

    
       
    }
  },
  methods: {
    fullScreenChanged: function () {
        if (!document.fullscreenElement) {
            this.rerenderKey = !this.rerenderKey;
        }
    }
},
mounted: function () {
    this.$el.addEventListener('fullscreenchange', this.fullScreenChanged)
},
beforeDestroy: function () {
    this.$el.removeEventListener('fullscreenchange', this.fullScreenChanged)
}
}
</script>


<style lang="scss" scoped >

.wavchart {
  min-width: 450px;
  max-width: 900px;
  width:100%;
  margin: 0 auto;
}

</style>
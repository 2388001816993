<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
          <!-- <h4 class="content-title">{{$t('pressure_monitoring.dpage_title')}}</h4> -->
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                   <li>
                     <ol class="breadcrumb with--map">
                       <!--                              <li class="breadcrumb-title">Monitoring Detail</li>-->
                       <!--                              <li class="breadcrumb-bar">|</li>-->
                       <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                       <li class="breadcrumb-item"><router-link to="/pressure-monitoring">{{$t('pressure_monitoring.mpage_title')}}</router-link></li>
                       <li class="breadcrumb-item active">{{$t('pressure_monitoring.dpage_title')}}</li>
                     </ol>
                   </li>

                 </ul>
         </div>
     <!-- tom-bar-end -->
        <search-left-type3 :box="box" :activeCheckIndex="activeCheckIndex" @updateCheckIndex="updateCheckIndex" 
        :visible_sort_box_press="true" @go_search="go_search" @on_goto_map_click = "on_goto_map_click"/>
        <div class="right_area right_area_01 right_area_block-detail">
          <b-row>
              <b-col cols="8" style="padding: 0 10px">
               <!-- 2023 -->
                <!-- <b-tabs content-class="press_detail"     style="margin-top: -20px;"> -->
                <b-tabs content-class="press_detail"     style="">
                 <b-tab :title="$t('pressure_monitoring.tab_title1')" style="padding:0;" active> 
                  <b-row style="margin: 0;">
                      <b-col style="padding: 0;">
                        <div class="specification_area" style=" padding: 20px 20px 10px 20px;height: calc(50vh - 60px);">
                          <div class="specification_box" ref="button_group_mnf">
                              <b-form inline>
                                  <b-button-group class="mr-2 specification_btn">
                                      <b-radio :button="true"  size="sm" v-model="selected_ts_mnf" value='1' @change="change_selected_ts_mnf($event)">
                                          <span>1M</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='2' @change="change_selected_ts_mnf($event)">
                                          <span>2M</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='3' @change="change_selected_ts_mnf($event)">
                                          <span>3M</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='6' @change="change_selected_ts_mnf($event)">
                                          <span>6M</span>
                                      </b-radio>
                                  </b-button-group>
                                  <b-input size="sm" v-model="mnf_date" type="date" @change="change_mnf_date($event)" class="input_date  m-0 btn-gray-date"/>
                                  <!--span class="title_value">{{$t('pressure_monitoring.day_chart')}}</span-->
                                  

                              </b-form>
                                
                                  <span style="float:left;margin: -25px 0px 0 380px;">
                                   <span style="float:left;"> 
                                   <button class="" @click="change_date_mnf(-1)" style="width: 20px;height: 20px;line-height: 6px;margin: 0;text-indent: -4px;    border: none;"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                                   <button class="" @click="change_date_mnf(1)" style="width: 20px;height: 20px;line-height: 6px;margin: 0 1em 0 .5em;text-indent: -4px;    border: none;"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                                   </span>
                                   <span class="title_value" style="margin: 0px;color:#cfcfcf;">Name : {{tag_name}}</span>
                                  </span>
                            <div class="btn-primary btn-g" style="float: right;margin: -35px; border-radius: 4px; width: 60px;" @click="goRouter('/pressure-monitoring-detailT')">{{$t('pressure_monitoring.btn_chart')}}</div>
                            <div class="status" style=" right: 200px;" >                                            
                              <!--input type="radio" v-model="vchart" value="0" id="proportional"> <label for="proportional">{{$t('leakage_info.proportional')}}</label-->   
                              <span style="float: right;margin-right: 0px; margin-top: -6px;" @click="change_min">
                                <!--b-input v-model="min" style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                type="text" maxlength=4  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" /-->
                               <b-input v-model="min" style="width:60px;height:20px;margin:-24px 30px 0 10px;padding:10px 0  ;text-align:center;"
                                 type="number" step="0.25" class="input_spin" />
                             </span> 
                              <!--input type="radio" v-model="vchart" value="1" id="fixed"><label for="fixed" style="margin-right:10px;">Min Fixed : </label-->
                            </div>                          
                          </div>
 <!--                         
                          <div ref="mnf_chart" :style="{height: mnfchart_height+'px'}">
                              <svg/>
                          </div>
 -->
                            <div >
                              <PressMonitoringDayChart :url="bar_url" ref="barchart" id="barchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:-5px;"  @onBarClick="onBarClick"   />  
                           </div>                          
                          </div>
                      </b-col>
                  </b-row>
                  </b-tab>
                  <!-- 2023 -->
                  <b-tab :title="$t('pressure_monitoring.tab_title2')" >
                  <b-row style="margin: 0px 0 0px 0;">
                      <b-col style="padding: 0;">
                           <!-- <div class="specification_area" style=" background-color:white; padding: 20px 20px 10px 20px ;height:430px;"> -->
                           <div class="specification_area" style="  padding: 20px 20px 10px 20px;height: calc(50vh - 60px);" >
                          <div class="specification_box" ref="button_group_realtime">
                              <b-form inline>
                                  <b-button-group class="mr-2 specification_btn">
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='30' @change="change_selected_ts_flow($event)">
                                          <span>30D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='14' @change="change_selected_ts_flow($event)">
                                          <span>14D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='7' @change="change_selected_ts_flow($event)">
                                          <span>7D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='3' @change="change_selected_ts_flow($event)">
                                          <span>3D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='1' @change="change_selected_ts_flow($event)">
                                          <span>1D</span>
                                      </b-radio>
                                  </b-button-group>
                                  <b-input size="sm" v-model="flow_date" type="date" @change="change_flow_date($event)" class="input_date m-0 btn-gray-date"/>
                                  
                              </b-form>
                              <span style="float:left;margin: -25px 0px 0 430px;">
                               <span  style="margin: 5px 0px 0;">
                                 <button class="" @click="change_date_flow(-1)" style="width: 20px;height: 20px;line-height: 6px;margin: 0;text-indent: -4px;    border: none;"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                                 <button class="" @click="change_date_flow(1)" style="width: 20px;height: 20px;line-height: 6px;margin: 0 1em 0 .5em;text-indent: -4px;    border: none;"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                               </span>
   
                                  <!--b-input v-model="m_min" style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                  type="text" maxlength=4  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" /-->
                                  <div class="status" style="margin: -30px 0px 0 50px;" >                                            
                                    <input type="radio" v-model="vchart1" value="0" id="proportional"> <label for="proportional">{{$t('leakage_info.proportional')}}</label>   
                                    <input type="radio" v-model="vchart1" value="1" id="fixed"><label for="fixed" style="margin-right:10px;">Min Fixed : </label>
                                   
                                  <b-input v-model="m_min" style="float:right;width:60px;height:20px;margin:5px 0px 0 0px;padding:10px 0  ;text-align:center;" type="number" step="0.25" class="input_spin" />
                                  </div> 
                               </span>                            
                             
                            <div class="btn-primary btn-g" style="float: right;margin: -30px -45px ; border-radius: 4px; width: 60px;" @click="goRouter('/pressure-monitoring-detailT')">{{$t('pressure_monitoring.btn_chart')}}</div>
                            <!-- <div class="status" style="top:30px;right:200px;" >                                             -->
                           
                          </div>
<!--
                          <div ref="flow_chart" :style="{height: realtimechart_height+'px'}">
                              <div>
                                  <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                              </div>
                              <svg/>
                          </div>
-->
                            <!-- <div style="background-color:white;margin-top:30px; "> -->
                            <div style="margin-top:-20px; ">
                              <p class="title_value" style="  position:absolute;color:#cfcfcf; right:30px;  margin: 10px 0 0 0px;">Name : {{tag_name}}</p>   
                              <pressChartData1 class="presschart_m2_d1" :url="real_url" ref="realchart"  id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;"   />
                              <!--div>
                                <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                            </div>
                              <PressMinChart :url="real_url" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:5px;"   /-->  
                          </div> 
                          </div>

                      </b-col>
                  </b-row>
                  </b-tab>
                </b-tabs>
                
                <b-row>
                 <b-col class="pressure-detail-content-map" style="padding-left: 0px; margin-left: 15px;margin-top:10px;">
               <div class="search_div_press">
                <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
                <span style="float:left; margin-left:10px;"> <b-select size="sm" v-model="pressure_value_type" :options="pressure_value_opt" change></b-select> </span>
              </div>
                <div :style="{height: map_height+'px'}">
                   <Map
                         ref="map"
                        :visible_gisload_button="true"      
                        :visible_full_extent="true"                  
                        :widx="$route.query.widx"          
                        :press_icon ="true"  
                        :press_icon1 ="true"           
                        w_type="111"
                        @toggle_visible_Hydraulic_type = "toggle_visible_Hydraulic_type"
                        @set_map_gisload="set_map_gisload"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @set_info="set_info"
                        @go_home="go_home()"                      
                        @onLeakMapClick="onLeakMapClick()"
                     />
              </div>
                 </b-col>
                </b-row>  
              </b-col>
              <!-- <b-col cols="4" style="background-color:white;margin-bottom:5px;"> -->
              <b-col cols="4" style="background-color:#172A46; padding: 1em 1em 0; margin: 3.3em 0 0 0.5em; height: calc(100vh - 120px);">
                <div style="height:20px;" >
                  <span style=" position: absolute; top: 20px; right: 20px;">
                      {{$t('pressure_monitoring.data_table_title')}}
                    <!--b-button size="sm" @click="updateDayData"  v-b-tooltip.hover title="Update Data" style="margin: -5px 10px -5px;">
                        <i class="mdi mdi-refresh"/>                             
                     </b-button-->
                 </span>
                </div>
                <!-- <div style="position:relative;background-color:white; padding: 10px 5px;"> -->
                <div style="position:relative;padding: 20px 5px;">

                  <vue-good-table ref="table"
                      :columns="columns"
                      :rows="table_mnf"
                      :line-numbers="true"
                      :max-height="contents_height+ 40 +'px'"
                      :fixed-header="true"
                        styleClass="vgt-table condensed"
                  >
                     <template slot="table-row" slot-scope="props" >
                       <span v-if="props.column.field == 'mnp' && props.row.mnp < mnp_boundary ">
                            <div style=" color: #ff8282;">{{props.row.mnp}}</div> 
                        </span>
                        <span v-else>
                        {{props.formattedRow[props.column.field]}}
                        </span>
                   
                    </template>
                    </vue-good-table>
                 </div>
              </b-col>
          </b-row>
        </div>
    </div>
</template>

<script>
import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';
import SearchLeftType3 from '@/components/left/SearchLeftType3_p';
import * as d3 from 'd3'
import d3tip from "d3-tip";
import PressMonitoringDayChart from '@/components/chart/PressMonitoringDayChart' ;
//import PressMinChart from '@/components/PressMinChart' ;

 d3.tip = d3tip;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import pressChartData1 from '@/components/chart/pressChartData2_temp' ;

export default {
    name: 'PressureMonitoringDetail',
    props: [
    ],
    watch: {
       pressure_value_type(){
        this.chang_press_mapvalue_type() ;
      },
      value_type(){
          this.chang_press_mapvalue_type() ;
      },      
      min() {
        if(this.vchart===0) {
          return;
        //        alert("radio button : " + this.vchart) ;
        //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
        }
        
         this.chang_bar_url();
      }, 
      m_min() {
        if(this.vchart1 === 1 ){
          
          this.$refs.realchart.fixed = 1 ;
          this.$refs.realchart.min = this.m_min ;
          var c_url = this.real_url.split('&fixed=') ;
        // this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#1";
  
          this.real_url = c_url[0]+"&fixed=1&"+this.m_min+'#1' ;
        }else{
          if(this.init_min !== 0){
             this.vchart1 =  1 ;
          }else{
            ++this.init_min;
          }
          
        }
      },           
      vchart1() {
 
        this.$refs.realchart.fixed = this.vchart1 ;
        this.$refs.realchart.min = this.m_min ;
        var c_url = this.real_url.split('#') ;
       // this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#1";
 
        this.real_url = c_url[0]+"&fixed="+this.vchart1+'#0' ;
      }           
    },
    data() {
        return {
            bar_url : '',
            bar_url1 : '',
            real_url : '',
            vchart : 1,
            min : 3,
            vchart1 : 0,
            m_min : 2,
            init_min : 1,
            contents_height: 600,
            mnfchart_height: 300,
            map_height: 250,
            realtimechart_height: 300,
            tag : this.$route.query.id,
            addr_value :'',
            tag_name : '',
            mnp_boundary : -1,
            layer_all_visible: true,
            pressure_value_type : '',
            pressure_value_opt: ['avg' , 'min', 'max','mnp' ],               
            tabs: [
              { 'name': this.$t('pressure_monitoring.tab_title2') },
              { 'name': this.$t('pressure_monitoring.tab_title1') },          
            ],
            selected_ts_flow: '30',
            //flow_date: '2020-11-01',
            flow_date: this.$route.query.mea_date,
            loaded_ts_flow: false,

            selected_ts_mnf: '1',
           // mnf_date: '2020-11-01',
            mnf_date:  this.$route.query.mea_date,
            columns: [
                {label: 'Date', field: 'date_str'},
                {label: 'Max', field: 'flow'},
                {label: 'Avg', field: 'mnf'},
                {label: 'Min', field: 'rate'},
                {label: 'Mnp', field: 'mnp'},
            ],

          
            table_mnf: [],
            ts_mnf: [],
            activeCheckIndex: null,
            box: [
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'comm_sense': '5'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'comm_sense': '5'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'comm_sense': '5'
              }
            ],
           
        }
    },
    methods: {
        updateDayData(){
          //기준날짜에 한달 건 계산
          var tag = encodeURI(this.tag) ;
          var param = "m2_point_id="+tag+"&current_datetime="+this.mnf_date
          this.$http.get(this.URL+'api/refresh?type=111&'+param).then((res) => { 
          // this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id).then((res) => {
            if(res.data.check === 0) alert("Success..")
            else  alert("Fail.. ")
          });

        },
        change_min(){
          if(this.vchart === 0){
             this.vchart =1 ;
             this.chang_bar_url();  
          }
        },
        chang_bar_url(){
             this.$refs.barchart.fixed = 1 ; // 1 :fixed
             this.$refs.barchart.min = this.min ;
             var c_url = this.bar_url1.split('#') ;
             this.bar_url = c_url[0]+"&fixed="+this.vchart+"&"+ this.min+'#0' ;
        },
        show_press_info(idx) {
          this.$refs.map.show_press_info(idx)
        }, 
        fn_press_han(event)
        {
           var objTarget = event.srcElement || event.target;
           var value = objTarget.value;
           if(isNaN(value)){
             objTarget.value =value.replace(/[^0-9]/g,'');
           }        
         },          
         toggle_visible_all_layer(a) {

                // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          /*
           this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
           if(this.$refs.map.layer_all_visible)
             this.$refs.map.leak_cal = 1;
           else
               this.$refs.map.leak_cal = 0;
            */
           if(a=== 1){
              // alert(a)
                this.$refs.map.leak_cal = 1;
           }else{
              //  alert(a)
                this.$refs.map.leak_cal = 0;
           }
           this.$refs.map.init_reload_leak_layer() ;

          /*
            this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')===100)
                    layer.setVisible(this.$refs.map.layer_all_visible)
            }
            */
        },
        chang_press_mapvalue_type() {
          // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          
          this.$refs.map.leak_cal = this.pressure_value_type;    //avg,min/max
          this.$refs.map.leak_val =  this.value_type;     //bar,psi,kgf
          //this.$refs.map.time_30m = this.flow_date; //지도에서 일데이터 (최종시간 그전날 데이터)
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;

        },  
       toggle_visible_Hydraulic_type() {
          // 처음엔 동수도 적용안한다. Hydraulic_type 데이터를 가져와 지도에 보여준다. 동수도 적용안한다.
          // All or Hydraulic 적용값 가져와 지도에 보여준다.
          this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
          if(this.$refs.map.layer_leak_visible)
            this.$refs.map.leak_type = 'HYDRAULIC';
          else
            this.$refs.map.leak_type = null;            
          
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;      
        },               
        addr_search(){
           this.$refs.map.go_search(this.addr_value);
        },
        Enter_Check1(){
            this.addr_search();
        },  
         set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length-1; i++) {
                    var layer = this.$refs.map.layer_list[i];
                    if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length-1; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);


                    } //for문 끝

                } // zoom크기에 대한 if


        },
        facility_invisible_layer() {

          this.layer_all_visible = false;
          let map = this.$refs.map.map
          for(let i=1; i<map.getLayers().getLength(); i++) {
            let layer = map.getLayers().item(i)
            if(layer.get('code')>50) continue;
            layer.setVisible(this.layer_all_visible)
          }
        },
        onLeakMapClick(){
            let tag_id = this.$refs.map.leak_idx;
            this.tag_name = this.$refs.map.leak_recov_idx
           // this.tag_name1 = tag_name ;    
            this.tag = tag_id ;
            //this.flow_date1 = this.flow_date ;
            //this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            this.get_time_series_mnf();
            this.get_time_series_flow()

        },               
        set_info(){
            this.$refs.map.select_info = true;
        },            
         goRouter(path) {
            this.$router.push({path: path, query: { tag: this.tag }})
        },
        change_tab(e) {
            this.$store.setting.tab_idx = e
            this.setting.tab_idx = e
        },
        change_date_mnf(a){
          let default_date = new Date(this.mnf_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.mnf_date = this.date_to_str(default_date)
          this.get_time_series_mnf()
        },
        change_date_flow(a){
          let default_date = new Date(this.flow_date.toLocaleString());
          default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
         
          this.get_time_series_flow()
        },
    
      date_to_str(format){
         var year = format.getFullYear();
         var month = format.getMonth() + 1;
         if(month<10) month = '0' + month;
         var date = format.getDate();
         if(date<10) date = '0' + date; 
     
         return year + "-" + month + "-" + date ;
      },

      on_goto_map_click(row) {
        
        this.$refs.map.get_extent('w_type=111&p_idx='+row.id).then((data)=>{
          this.$refs.map.set_fit_extent(data)     
          if(this.$store.setting.map_name==='Daum')
            this.$refs.map.map.getView().setZoom(13);
          else
            this.$refs.map.map.getView().setZoom(18);   
        })
         this.$refs.map.select_facility_feature('111','',row.id)  
        //  var last_str = row.flow.substr(0,10)
        //  this.$router.push({path: '/pressure-monitoring-map?w_type=111', query: { tag: this.tag, mea_date:last_str  }})
        
      },
      go_home() {
         //  this.$router.push({path: '/', query: { widx: this.$route.query.widx, w_type: '110' }})
          var last_str = this.flow_date
          this.$router.push({path: '/pressure-monitoring-map?w_type=111', query: { tag: this.tag, mea_date:last_str  }})
        },

        go_search(value,sort,status_type) {

            if(value===undefined) return;

                //left메뉴 데이터를 뿌려준다.
            this.box = [] ;
            var customer_id = sessionStorage.select_customer_id ; 
            if(sessionStorage.select_customer_id === "27"){
              customer_id = 17 //파주
            }
           // this.$http.get(this.URL+'api/get_monitoring_list_left?search='+value).then((rows) => {
            this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id+'&search='+value+"&sort="+sort+"&type="+status_type).then((res) => {
              if(res.data.results[0].length <1) return
                /*
                var sdate =   res.data.results[0][0].last_measure_datetime       
                if(sdate.length > 10){
                  this.flow_date = sdate.substring(0,10)
                  this.mnf_date = sdate.substring(0,10)
                }
                */
               
              for(let i=0; i<res.data.results[0].length; i++) {                
                  var abox = {};
                  var check = res.data.results[0][i]._5days ;
                  if(check !== null && check !== undefined)
                      check =  check.split(",") ;
                  else
                      check = null   
                  // abox = {'name': res.data.results[0][i].jijum_name, 'mnf': Math.round(Number(res.data.results[0][i].jijum_id)) , 'flow':  Math.round(Number(res.data.results[0][i].tag_value)) ,  'status': 'blue' } ; 
                  var press_data = res.data.results[0][i].water_pressure === null ? "" :  res.data.results[0][i].water_pressure.toFixed(2)
                  var last_date = res.data.results[0][i].last_measure_datetime === null ? "" :  res.data.results[0][i].last_measure_datetime
              //    var comm_sense = res.data.results[0][i].modem_signal_sensitivity === null || res.data.results[0][i].modem_signal_sensitivity > 5 || res.data.results[0][i].modem_signal_sensitivity < 0 ? 0 :  res.data.results[0][i].modem_signal_sensitivity 
                  var status = (res.data.results[0][i].content == null || res.data.results[0][i].content== undefined || res.data.results[0][i].content== 0) ? "" : "red"              
                  abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id ,  'device_sn':res.data.results[0][i].device_sn ,
                     //             'battery':res.data.results[0][i].battery_level,'comm_sense':comm_sense,  "mnp_boundary" : res.data.results[0][i].mnp_boundary,
                          "day_0" :  check !== null && check.length>0 ?  "circle color_0"+check[0] : "circle color_03",
                          "day_1" :  check !== null && check.length>1 ?  "circle color_0"+check[1] : "circle color_03",
                          "day_2" :  check !== null && check.length>2 ?  "circle color_0"+check[2] : "circle color_03",
                          "day_3" :  check !== null && check.length>3 ?  "circle color_0"+check[3] : "circle color_03",
                          "day_4" :  check !== null && check.length>4 ?  "circle color_0"+check[4] :"circle color_03",
                          'point_status' :res.data.results[0][i].point_status, 'flow':  last_date+"/ p_data :  "+press_data ,  'date':  last_date ,'status':status} ; 
                      this.box.push(abox) ; 
              }
              this.updateCheckIndex(0)
            })

     
        },
        updateCheckIndex(payload) {
          this.activeCheckIndex = payload          
          this.tag=this.box[payload].id
          this.tag_name = this.box[payload].name
          var sdate = this.box[payload].date
          var boundary = this.box[payload].mnp_boundary
          this.vchart = 0;
          if(sdate !== null) {  
            this.flow_date = sdate.substring(0,10)
            this.mnf_date = sdate.substring(0,10)
      
            this.get_time_series_mnf() //테이블값 셋팅
            //this.get_time_series_flow()
            if(this.vchart1 === 1){
              this.vchart1 = 0;
              this.init_min = 0;
              this.m_min = 2 ;
             
            }else{
               this.get_time_series_flow()
            }
            if(boundary !== null)
              this.mnp_boundary = boundary
            else
              this.mnp_boundary = -100  
          }
    
        },
        change_flow_date(e) {
            this.flow_date = e
            this.get_time_series_flow()
        },
        change_selected_ts_flow(e) {
            this.selected_ts_flow = e
            this.get_time_series_flow()
        },
        get_time_series_flow() {
            this.loaded_ts_flow =false
            this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#0";
            /*
            this.$http.get(this.URL+'api/get_time_series?type=flow&tag='+encodeURI(this.tag)+'&start_date='+this.flow_date+'&limit='+this.selected_ts_flow).then((res) => {
                let ts_flow = res.data.rows
               // var parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S.000Z");
                var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
                for(let i=0; i<ts_flow.length; i++) {
                    ts_flow[i]['date_time'] = parseTime(ts_flow[i]['date_time'])
                }
                this.loaded_ts_flow = true
              //  this.draw_flow_chart(ts_flow)
            });
            */
           this.loaded_ts_flow = true 
        },
        change_mnf_date(e) {
            this.mnf_date = e
            this.get_time_series_mnf()
        },
        change_selected_ts_mnf(e) {
            this.selected_ts_mnf = e
            this.get_time_series_mnf()
        },
        get_time_series_mnf() {
            this.table_mnf = []
            this.bar_url1 = this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+'#0'
            var chart_avg = 0
            this.$http.get(this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf).then((res) => {
               if(res.data.results[0].length <1){      
                  this.bar_url = this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+'#0'           
                 return;
               } 
                this.ts_mnf = res.data.results[0]
                var parseTime = d3.timeParse("%Y-%m-%d");
               
                for(let i=0; i<this.ts_mnf.length; i++) {

                    this.ts_mnf[i]['date_str'] = this.ts_mnf[i]['measure_datetime']
                    this.ts_mnf[i]['date'] = parseTime(this.ts_mnf[i]['measure_datetime'])
                  //  this.ts_mnf[i]['rate'] = this.ts_mnf[i]['mnf'] / (this.ts_mnf[i]['flow']/24) * 100

                    this.table_mnf.push({
                        date_str: this.ts_mnf[i]['date_str'],
                        flow: this.ts_mnf[i]['max_water_pressure'],
                        mnf: this.ts_mnf[i]['avg_water_pressure'],
                        rate: this.ts_mnf[i]['min_water_pressure'],
                        mnp: this.ts_mnf[i]['maximum_night_pressure']
                    })
                    chart_avg += this.ts_mnf[i]['maximum_night_pressure']


                }
                chart_avg = chart_avg/this.ts_mnf.length - 0.5
                if(chart_avg < 0 ){
                  chart_avg = 0
                }
                this.min = Math.round(chart_avg*10)/10
                //20230120 추가
                if(this.mnp_boundary> -1 && this.mnp_boundary !== null ){
                  if(this.min > this.mnp_boundary -0.5)
                     this.min = Math.round((this.mnp_boundary -0.5)*10)/10
                }
                this.chang_bar_url();
             //   this.draw_mnf_chart(this.ts_mnf)
            });
            //this.bar_url = this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+'#0';
        },
        onBarClick(){
          var bar_idx = this.$refs.barchart.bar_idx; //09-03 이렇게 들어온다
         // let default_date = this.flow_date.split('-') ;
         // let sta =  default_date[0]+'-'+bar_idx
          this.flow_date = bar_idx[0]
           //   this.fft_url = this.URL+"api/get_leak_bubble?w_idx="+bar_idx[0] ;    
              this.get_time_series_flow()
         },
         window_resiz() {
          this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175
          let left_height = this.contents_height - (this.$refs.button_group_mnf.clientHeight*2)        
          this.map_height = (this.contents_height+90)/2
          this.mnfchart_height = left_height / 2 -50
          this.realtimechart_height = this.mnfchart_height
          this.$refs.map.update_size();
        },
   
    },
    mounted () {
      /*
        this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175
        let left_height = this.contents_height - (this.$refs.button_group_mnf.clientHeight*2)        
        this.map_height = (this.contents_height+90)/2
        this.mnfchart_height = left_height / 2
        */
       window.addEventListener("resize", this.window_resiz);
       this.window_resiz()
        //this.map_height = this.mnfchart_height+this.$refs.navbar.clientHeight*2
        this.realtimechart_height = this.mnfchart_height
        setTimeout(()=>{
          let setting = this.$store.setting
          if(setting) {
                this.pressure_value_type = 'mnp'
          }
        }, 1000)
       //left메뉴 데이터를 뿌려준다.
       this.box = [] ;
       var select_id= Number(this.$route.query.id) ;
       var customer_id = sessionStorage.select_customer_id ; 
       if(sessionStorage.select_customer_id === "27"){
         customer_id = 17 //파주
       }
       this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id+"&type=A").then((res) => { 
      // this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id).then((res) => {
          if(res.data.results[0].length <1) return
        
          for(let i=0; i<res.data.results[0].length; i++) {
          
            var abox = {};
            var check =  res.data.results[0][i]._5days
            if(check !== null && check !== undefined)
               check =  check.split(",") ;
            else
               check = null    
            
          //  abox = {'name': rows.data.rows[i].name, 'mnf': Math.round(Number(rows.data.rows[i].mnf)) , 'flow':  Math.round(Number(rows.data.rows[i].flow)) ,  'status': 'blue' } ; 
            var press_data = res.data.results[0][i].water_pressure === null ? "" :  res.data.results[0][i].water_pressure.toFixed(2)
            var last_date = res.data.results[0][i].last_measure_datetime === null ? "" :  res.data.results[0][i].last_measure_datetime
           // var comm_sense = res.data.results[0][i].modem_signal_sensitivity === null ? "5" :  res.data.results[0][i].modem_signal_sensitivity
            var status = (res.data.results[0][i].content == null || res.data.results[0][i].content== undefined || res.data.results[0][i].content== 0) ? "" : "red"
            abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id ,  'device_sn':res.data.results[0][i].device_sn ,
           //  'battery':res.data.results[0][i].battery_level,'comm_sense':comm_sense,
                   "day_0" : check !== null && check.length>0 ?  "circle color_0"+check[0] : "circle color_03",
                   "day_1" : check !== null && check.length>1 ?  "circle color_0"+check[1] : "circle color_03",
                   "day_2" : check !== null && check.length>2 ?  "circle color_0"+check[2] : "circle color_03",
                   "day_3" : check !== null && check.length>3 ?  "circle color_0"+check[3] : "circle color_03",
                   "day_4" : check !== null && check.length>4 ?  "circle color_0"+check[4] :"circle color_03",
                   "mnp_boundary" : res.data.results[0][i].mnp_boundary,
                  'point_status' :res.data.results[0][i].point_status,'flow':  last_date+"/ p_data :  "+press_data ,  'date':  last_date ,'status': status} ; 
            this.box.push(abox) ; 
            /*
             if(this.$route.query.tag === undefined && i === 0){
                setTimeout(()=>{
                    this.updateCheckIndex(i)
                  }, 500)
             }else{
                if(this.$route.query.tag === res.data.results[0][i].id){
                  setTimeout(()=>{
                    this.updateCheckIndex(i)
                  }, 500)

                }
             }
            */

             if(select_id === res.data.results[0][i].id){
                // select_id = i; 
                  setTimeout(()=>{
                    this.updateCheckIndex(i)
                  }, 500)               
             }
          }
      
        //  this.get_time_series_mnf()
        //  this.get_time_series_flow()
       })
     setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();         
           }
         }
     }, 200)
     /*
     setTimeout(()=>{
       if(select_id>-1){
         this.updateCheckIndex(select_id)
       }
     }, 500)
     */
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.window_resiz)
    },
    components: {
      //  PulseLoader: PulseLoader,
        Map: Map, 
        SearchLeftType3,
        PressMonitoringDayChart:PressMonitoringDayChart,
     //   PressMinChart:PressMinChart,
        pressChartData1 : pressChartData1,
    }
}
</script>

<style lang="scss" >
.right_area.right_area_block-detail .vgt-inner-wrap {
  height: 100%;
}
.right_area.right_area_block-detail .vgt-responsive::-webkit-scrollbar {
      display: block;
      width: 10px;
}
/* 스크롤바 막대 설정*/
.right_area.right_area_block-detail .vgt-responsive::-webkit-scrollbar-thumb{
  background-color:#3f874f78;
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px; 
  border: 5px solid transparent;
}

/* 스크롤바 뒷 배경 설정*/
.right_area.right_area_block-detail .vgt-responsive::-webkit-scrollbar-track{
  background-color: #41684a8c;
}

.presschart_m2_d1 .wavchart  {
  /*min-height: 350px;*/
  height : calc(40vh - 50px);
  width: 98%;
}
.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // background: #fff;
  margin-bottom: 10px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}
/*
.specification_btn .btn-group-toggle + .btn-group-toggle {
  //margin-left: 5px;
}
*/
.specification_btn .btn-secondary{
  float: left;
  width: 42px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  background: rgba(35,41,47,0.4);
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
  border: 0;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background: rgba(35,41,47,0.8);
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}


.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  // background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 735px;
  overflow-y: scroll;
}
table.vgt-table.condensed td {
      padding: .188rem .15rem;
}
.btn_pre{
 position: absolute;
    top: 0;
    //right: 110px;
    left: 50%;
   // background: #4C5055;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
 }
 .map-button.icon_btn.btn.btn-sm{
   padding-top: 4px;
 }
.presschart_m2_d1 .wavchart.hchart {
    width: 100%;    
    margin: 0 auto;
}
.tab-content.press_detail {
  overflow: hidden;
  padding:0px;
}

/* input type number 에서 화살표 제거 */
input[type=number].input_spin::-webkit-inner-spin-button,
input[type=number].input_spin::-webkit-outer-spin-button {  
   opacity: 1;
}
</style>

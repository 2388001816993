<template>
<div >
       <div  ref="navbar" class="navbar-custom">

      <ul class="list-unstyled topnav-menu-gm topnav-menu-left">
    
        <li>
          <ol class="breadcrumb with--map">
            <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">{{$t('menu.home')}}</router-link></li>
            <li class="breadcrumb-item "><router-link to="/simulation">{{$t('menu.simulation')}}</router-link></li>
            <li class="breadcrumb-item active">{{$t('leak_detail.page_title')}} </li>
          </ol>
        </li>
      </ul>
    </div>
    <div>
      <b-row style="margin: 0;">
       <b-col  v-if="v_chart" :cols="tsize" style="margin-left: -1em ;height: 100% ;margin-right: 1em;">
       <search-left-type3 :select_id="tag" :activeCheckIndex="activeCheckIndex" :visible_search_box="false" w_type = "102"  @InpfileDownload="InpfileDownload" 
          @updateCheckIndex="updateCheckIndex" @on_facility_info="on_facility_info" @go_search="go_search_leftlist" @on_goto_map_click = "on_goto_map_click"/>
       </b-col>
        <b-col :cols="msize" style="padding: 0 0px 0 0;">
          <div :style="{height: map_height+'px'}">        

        <!--div style="width: 100%; height: calc(100% - 70px); position:fixed; top: 70px;right:0px;"-->
            <div class="search_div">
                <span class="spn1"><b-input size="sm" type="text"  :placeholder="$t('home.input_search_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
           </div>
            <Map ref="map" :visible_full_extent="true"
                     :visible_gisload_button="true"
                     :visible_size_side_view_button_l = "true"
                     :widx="$route.query.widx"
                     :model_icon ="true"    
                     @size_view="size_view"               
                     w_type="110"
                     @set_map_gisload="set_map_gisload"
                     @toggle_visible_el_type = "view_map_elevation"
                     @facility_invisible_layer = "facility_invisible_layer"                   
                     @set_info="set_info"
                     @go_home="go_home()"
                     @unit_model_change = "unit_model_change"
                />
                  <div style="width: 230px;position: absolute;left:10px;top:65px;">
                   <div  style= "background-color: rgba( 23, 42, 70, 0.7 ); padding: 10px;">
                      <b-row>
                        <b-col sm="2">
                            <label class="facility__label">Node</label>
                        </b-col>
                        <b-col sm="5" style="padding:0 5px 0;">
                            <!--b-input class="facility__input" size="sm" v-model="properties1['water_supply_etc']"  ></b-input-->
                            <b-select class="facility__input" size="sm" v-model="map_node" :options="map_node_option" ></b-select>
                        </b-col>
                        <b-col sm="5"  style="padding-left:0;">                              
                              <b-select class="facility__input" size="sm" v-model="node_color" :options="node_color_option" ></b-select>
                        </b-col>
                      </b-row> 
                      <b-row>
                        <b-col sm="2">
                            <label class="facility__label"></label>
                        </b-col>
                        <b-col sm="3" style="padding:0 5px 0;">
                            <!--b-input class="facility__input" size="sm" v-model="properties1['water_supply_etc']"  ></b-input-->
                            <input type='checkbox' v-model="node_unit_set_auto" size="sm"/> Auto
                        </b-col>
                        <b-col sm="7" style="padding-left:0;">    
                           <b-input v-model="node_unit_set" size="sm"/>
                        </b-col>
                      </b-row>   
                       <b-col class="tab-btm-line"></b-col>                    
                      <b-row>
                        <b-col sm="2">
                            <label class="facility__label">Link</label>
                        </b-col>
                        <b-col sm="5" style="padding:0 5px 0;">
                            <!--b-input class="facility__input" size="sm" v-model="properties1['water_supply_etc']"  ></b-input-->
                            <b-select class="facility__input" size="sm" v-model="map_link" :options="map_link_option" ></b-select>
                        </b-col>
                        <b-col sm="5" style="padding-left:0;">    
                            <b-select class="facility__input" size="sm" v-model="link_color" :options="link_color_option" ></b-select>
                        </b-col>
                      </b-row> 
                       <b-row>
                        <b-col sm="2">
                            <label class="facility__label"></label>
                        </b-col>
                        <b-col sm="3" style="padding:0 5px 0;">
                            <!--b-input class="facility__input" size="sm" v-model="properties1['water_supply_etc']"  ></b-input-->
                            <input type='checkbox' v-model="link_unit_set_auto" size="sm"/> Auto
                        </b-col>
                        <b-col sm="7" style="padding-left:0;">    
                           <b-input v-model="link_unit_set" size="sm"/>
                        </b-col>
                      </b-row> 
                      <b-row>                    
                          <b-col sm="6" >
                            <b-button block @click="map_setting_save()" class="facility-info-footer__button facility-info-footer__button-ok" style="background: #28AF81; ">{{$t('button.apply')}}</b-button>
                          </b-col>
                          <b-col sm="6" style="padding-left:0;">
                            <b-button block @click="map_change_show()" class="facility-info-footer__button facility-info-footer__button-ok" style="background: #c55a11; ">Simulation</b-button>
                          </b-col>
                      </b-row>
                   </div>
                    <!--b-card id="edit" style="background-color: transparent;border:0px; padding: 16px 0;"  -->                  
                        <div v-if="map_node_v !==''" style="margin : 5px 0;background-color : rgb(23, 42, 70, 0.7);">
                           <b-col>Node : {{legend_node_title}}</b-col> 
                           <b-row>
                             <b-col sm=2 v-for="(item, index) in legend_node" :key="index">
                              <input v-model="item.color" type="color" size="sm" style="background: transparent;padding:0;" disabled />
                             </b-col>  
                           </b-row>
                           <b-row>
                           
                             <b-col sm=2 v-for="(item, index) in legend_node" :key="index">
                              <label style="padding-left:5px;">{{item.value}}</label>
                             </b-col>
                             <b-col sm=2></b-col>   
                           </b-row>
                        
                            <!--b-row><b-col>{{map_node}}</b-col></b-row>
                            <b-row v-for="(item, index) in legend_node" :key="index">
                                <b-col sm=4>
                                 <input v-model="item.color" type="color" size="sm" style="background: transparent;" disabled />     
                                </b-col>
                                <b-col sm=6>
                                  <label>{{item.value}}</label>
                                </b-col>
                            </b-row>
                            <b-row><b-col></b-col></b-row-->
                        </div>

                        <div v-if="map_link_v !==''" style="margin : 5px 0;background-color :rgb(23, 42, 70, 0.7);">
                           <b-col>Link : {{legend_link_title}}</b-col> 
                           <b-row>
                             <b-col sm=2 v-for="(item, index) in legend_link" :key="index">
                              <input v-model="item.color" type="color" size="sm" style="background: transparent;padding:0;" disabled />
                             </b-col>  
                           </b-row>
                           <b-row>
                            
                             <b-col sm=2 v-for="(item, index) in legend_link" :key="index">
                              <label  style="padding-left:5px;">{{item.value}}</label>
                             </b-col>  
                             <b-col sm=2></b-col> 
                           </b-row>
                            <!--b-row><b-col sm=4></b-col><b-col>{{map_link}}</b-col></b-row>
                            <b-row v-for="(item, index) in legend_link" :key="index">
                                <b-col sm=4>
                                 <input v-model="item.color" type="color" size="sm" style="background: transparent;margin-top:-20px;" disabled />     
                                </b-col>
                                <b-col sm=6 >
                                  <label style="margin-top:10px;">{{item.value}}</label>
                                </b-col>
                            </b-row>
                            <b-row><b-col></b-col></b-row-->
                        </div>
                        

                    <!--/b-card-->
                </div>
         
         </div>
         
        </b-col>
      </b-row>  
       <ModelingInfoType ref="modeling_info" @on_goto_map_click = "on_goto_map_click" 
         @show_model_f_info = "show_model_f_info" @simulation_model_map_view="simulation_model_map_view" />
    </div>
</div> 
</template>

<script>
import proj4 from 'proj4';
import Map from '@/components/Map_model';
//import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import LineString from 'ol/geom/LineString';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
//20200708추가
import {CreateVectorLayer_f} from '@/gis.js';
import SearchLeftType3 from '@/components/left/SearchLeftType_model';
//import shpwrite from 'shp-write-chinese';
//import b64toBlob from 'b64-to-blob' ;
import { Project, Workspace,NodeProperty,CountType,  NodeType, LinkType, LinkProperty} from 'epanet-js';
//import ModelingInfoType from '@/components/ModelingSetting'; //제어&value, simulation화면, 
import ModelingInfoType from '@/components/ModelingInfoType'; //제어&value, simulation화면, 
import {Circle as CircleStyle, Icon, Fill, Style, Text, Stroke} from 'ol/style.js';
import Overlay from 'ol/Overlay.js';

export default {
    name: 'Simulation',
    props: [
        'w_type'
    ],
    data() {
        return {
            properties: [],
            loading:false,
            map_mode: 'select',           
            addr_value :'',
            msize:'9',
            tsize:'3',
            v_chart : true,
            visible_edit: false,
            edit_mode: 'add',
            add_layer_name: 'Service Pipe',
            layer_leak_visible: true, //고도값
            router_query: null,
            activeCheckIndex: null,
            interactions_modify: null,
            layer_all_visible: false,
            elevation_layer:null,
            box: [],          
            listKey: 0,
            tag : Number(this.$route.query.id), 
            map_height: 800,
            file_model : null,
            junctions : [], //node-junction을 넣어준다.
            reservoirs : [],
            tanks : [],
            pipes :[],
            pumps:[],
            valves : [],
            setting : {},
            model_json : {},
            model_line_style : null,
            model_point_style : null,
            map_node_option: [
                { value: '', text: 'No View' },
                { value: 'elevation', text: 'Elevation' },
                { value: 'base_demand', text: 'Base_demand' },
                { value: 'demand', text: 'Demand' },
                { value: 'head', text: 'Head' },
                { value: 'pressure', text: 'Pressure' },
            ],
            map_link_option: [
                { value: '', text: 'No View' },
                { value: 'length', text: 'Length' },
                { value: 'diameter', text: 'Diameter' },
                { value: 'roughness', text: 'Roughness' },
                { value: 'flow', text: 'Flow' },
                { value: 'velocity', text: 'Velocity' },
                { value: 'unit_headloss', text: 'Unit_headloss' },
            ],
            link_value_check :'',
            link_value_check_option:[
                { value: '', text: 'All' },
                { value: 'check', text: 'Check' },
            ], 
            //사이즈 조절
            link_unit_set_auto : true,
            link_unit_set : "",
            node_unit_set_auto : true,
            node_unit_set : "",
             link_color : -1,
             link_color_option : [
                { value: -1, text: '' },
                { value: 0, text: 'Rainbow' },
                { value: 1, text: 'Blue' },
                { value: 2,text: 'Orange' },
                { value: 3, text: 'Purple' },
            ],
             node_color : -1,
             node_color_option : [
                { value: -1, text: '' },
                { value: 0, text: 'Rainbow' },
                { value: 1, text: 'Blue' },
                { value: 2, text: 'Orange' },
                { value: 3, text: 'Purple' },
            ],
            node_value_check:'',
            node_value_check_option:[
                { value: '', text: 'All' },
                { value: 'check', text: 'Check' },
            ],            
            map_node : '',
            map_node_v : '',
            map_link : '', 
            map_link_v : '', 
            node_max_v : {"elevation" :1, "base_demand" : 1,"demand" : 1,
                          "head" : 1, "pressure" : 1}, 
            link_max_v : {"length" :1, "diameter" : 1,"roughness" : 1,
                              "flow" : 1, "velocity" : 1, "unit_headloss" : 1},
            legend_node_title:'',
            legend_link_title:'',
            legend_node:[],
            legend_link:[],
            color_arry : [["#0000ff","#00ffff","#00ff00","#ffff00","#ff0000"],
            ["#eaeaea","#9999ff","#3333ff","#0000cc","#000080"],
            ["#fffbf0","#ffcc99","#ff9933","#cc6600","#993300"],
            ['#ffccff','#ff99ff','#cc00ff','#9900cc','#660066']]
        }
    },
    watch: { //Service Pipe 만 수로 수정 가능함
        map_node(){
          if(this.map_node !== ""){
           this.node_color =  this.setting.setting_info[this.map_node].color_set
           this.node_unit_set =  this.setting.setting_info[this.map_node].set 
          }else{
            this.node_color = -1
            this.node_unit_set = ""
          }
        },
        map_link(){
          if(this.map_link !== ""){
             this.link_color =  this.setting.setting_info[this.map_link].color_set  
             this.link_unit_set =  this.setting.setting_info[this.map_link].set  
          }else{
            this.link_color = -1
            this.link_unit_set = ""
          }
        },

        add_layer_name() {
            this.$refs.map.edit_change(this.edit_mode, this.add_layer_name)
        },
        edit_mode() {
            this.$refs.map.edit_change(this.edit_mode, this.add_layer_name)
        },
        map_mode1() {
            if(this.map_mode==='edit') {
               if(this.layer_all_visible){
                this.visible_edit = true
                this.$refs.map.edit_start(this.edit_mode, this.add_layer_name)
               }else{
                   alert(this.$t('home.alert_text_no_gisload')) ;
                   this.map_mode='info'
                   this.$refs.map.select_info ='info'                   
               }
            }
            else {
                this.visible_edit = false
                this.$refs.map.select_info ='info'
                this.map_mode='info'
                this.$bvToast.hide('edit')
                this.$refs.map.edit_cancel();
            }
        }
    },
    methods: {

       map_setting_save(){
         if(this.map_node !== ""){
            this.setting.setting_info[this.map_node].color_set = this.node_color
            this.setting.setting_info[this.map_node].set = this.node_unit_set
         }
         if(this.map_link !== ""){
            this.setting.setting_info[this.map_link].color_set = this.link_color
            this.setting.setting_info[this.map_link].set = this.link_unit_set
         }
         let params = {} ;
         let model_info = {};  

         model_info["inp_setting"] =  encodeURIComponent(JSON.stringify(this.setting));
         params["id"] = this.tag ;
         params["fields"] = encodeURIComponent(JSON.stringify(model_info))
          if(Object.keys(params).length>0){
            this.$http.post(this.URL+'api/set_modeling_upload' ,params).then((res) => {
                console.log(res.data);
                if(res.data.check ===1){
                  alert("fail : "+ res.data.msg) ;
                }else{
                  alert("Update success!!")
                  this.$emit('search'); 
                }
            })   
          }       
       },
        //view select에 따라 지도에 view, legend를 새롭게 가져온다,
        map_change_show(){
          this.legend_node = [] //지도에 뿌리는 legend
          this.legend_link = []
          this.legend_node_title = ''
          this.legend_link_title = ''
       //   this.node_max_v = {"elevation" :1, "base_demand" : 1,"demand" : 1,
      //                    "head" : 1, "pressure" : 1} 
      //    this.link_max_v = {"length" :1, "diameter" : 1,"roughness" : 1,
        //                      "flow" : 1, "velocity" : 1, "unit_headloss" : 1}
          this.map_node_v = this.map_node
          this.map_link_v = this.map_link
          if(this.map_node !== ""){
             var uint = this.setting.setting_info[this.map_node].unit === '' ? '':"("+this.setting.setting_info[this.map_node].unit+")"
             this.legend_node_title = this.map_node_v + uint
             //legend setting 데이터 셋
            var value_set = this.setting.setting_info[this.map_node].set+","
            var value_set_arr = value_set.split(",")
            this.node_color = this.setting.setting_info[this.map_node].color_set
             var color_set = this.color_arry[this.setting.setting_info[this.map_node].color_set]
             for (let i = 0; i < 5; i++) {
              //  
              //auto check되어 있으면 최대값을 나눠서 분배
               if(this.node_unit_set_auto){
                let legend_v = Math.round(this.node_max_v[this.map_node_v]/4*(i)*10)/10
                var legend = {"value" :legend_v ,"color" : color_set[i]}     
                this.legend_node.push(legend)           
               }else{
                 var legend_1 = {"value" :value_set_arr[i] ,"color" : color_set[i]}
                 this.legend_node.push(legend_1)
               }
             
             }
          }
          
          if(this.map_link !== ""){
            var uint1 = this.setting.setting_info[this.map_link].unit === '' ? '':"("+this.setting.setting_info[this.map_link].unit+")"
            this.legend_link_title = this.map_link_v+ uint1

             var value_set1 = this.setting.setting_info[this.map_link].set+","
             var value_set_arr1 = value_set1.split(",")
             this.link_color = this.setting.setting_info[this.map_link].color_set
             var color_set1 = this.color_arry[this.setting.setting_info[this.map_link].color_set]
             for (let j = 0; j < 5; j++) {
              //  var legend1 = {"value" :value_set_arr1[j] ,"color" : color_set1[j]}
                if(this.link_unit_set_auto){
                   let legend_v = Math.round(this.link_max_v[this.map_link_v]/4*(j)*10)/10
                   var legend1 = {"value" : legend_v,"color" : color_set1[j]}
                   this.legend_link.push(legend1)
                }else{
                   var legend1_1 = {"value" :value_set_arr1[j] ,"color" : color_set1[j]}
                   this.legend_link.push(legend1_1)
                }
             }
          }
          
          this.set_map_zoom();
        },
         size_view(e) {
            if(e==='left') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='right') {
                this.v_chart= true ;
                this.msize = '9';
                this.tsize='3';

            }
            this.$refs.map.update_size()
        },  
        facility_invisible_layer() {

          this.layer_all_visible = false;
          let map = this.$refs.map.map
          for(let i=1; i<map.getLayers().getLength(); i++) {
            let layer = map.getLayers().item(i)
            if(layer.get('code')>50) continue;
            layer.setVisible(this.layer_all_visible)
          }
        },
        get_dma_result(){
            this.loading = true     
            this.$http.get(this.URL+'api/API21/dma/insert/'+this.tag).then((res) => {
                 if(res.data.check ===1){ 
                    this.loading = false
                    return;
                 }
                 this.updateCheckIndex(this.tag)
                 this.loading = false 
            });
            setTimeout(()=>{
                this.loading = false      
             }, 2000)    
      },
      set_info(){
        this.$refs.map.select_info = true;
      },
      go_search_leftlist(value,type,strength) {

        if(value===undefined) return;

            //left메뉴 데이터를 뿌려준다.
        this.box = [] ;
          var customer_id = sessionStorage.select_customer_id ; 
        if(sessionStorage.select_customer_id === "27"){
            customer_id = 6
        }
        // this.$http.get(this.URL+'api/get_monitoring_list_left?search='+value).then((rows) => {
          this.$http.get(this.URL+'api/API21/m2_leak/list?customer_id='+customer_id+'&search='+value+'&type='+type+'&sort='+strength).then((res) => {
            if(res.data.results[0].length <1) return
        
            
            for(let i=0; i<res.data.results[0].length; i++) {                
              var abox = {};
              // abox = {'name': res.data.results[0][i].jijum_name, 'mnf': Math.round(Number(res.data.results[0][i].jijum_id)) , 'flow':  Math.round(Number(res.data.results[0][i].tag_value)) ,  'status': 'blue' } ; 
              var strength = res.data.results[0][i].wave_energy === null ? "" :  res.data.results[0][i].wave_energy.toFixed(2)
              var last_date = res.data.results[0][i].last_measure_datetime === null ? "" :  res.data.results[0][i].last_measure_datetime
              var comm_sense = res.data.results[0][i].modem_signal_sensitivity === null ? "5" :  res.data.results[0][i].modem_signal_sensitivity
              abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id ,  'device_sn':res.data.results[0][i].device_sn ,
                'battery':res.data.results[0][i].battery_level,'comm_sense':comm_sense, 
               'flow':  last_date+"/ strength :  "+strength ,  'date':  last_date } ; 
               this.box.push(abox) ; 
            }
            this.updateCheckIndex(0)
        })

  
    },
    updateKey: function () {
      this.listKey+= 1
    },
    InpfileDownload(index,i_tag) {
      
       var f_name =  index+"_"+i_tag+"_saveInpFile.inp"
       this.file_model.saveInpFile(f_name) ;
          //console.log(save_text)
            const ws = new Workspace()
            const inpFile = ws.readFile(f_name);
            //console.log(inpFile)
            this.inpfile_dwnload(f_name, inpFile)
            //nodecnt : 압력값을 가져온다.
            
            //lint : 유량값을 가져온다.
            
           // document.getElementById("epanet-report").innerHTML = reportFile + "<br/>" +nodeIds + "<br/>"+linkIds;
           //  ++this.listKey;
           //this.file_model.close();
    },
    inpfile_dwnload(f_name,text){            
            
        var blob = new Blob([text], {type:'text/plain'});
        var link = document.createElement("a");
        link.download = f_name;
        link.innerHTML = "Download File";
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
        }, 100);
    },
    updateCheckIndex(index,i_tag) {
      this.activeCheckIndex = index
      this.properties = [];
      this.tag=i_tag;
      this.map_link = ''
      this.map_link_v = '' 
      this.map_node =''
      this.map_node_v =''
      this.node_max_v = {"elevation" :1, "base_demand" : 1,"demand" : 1,
                          "head" : 1, "pressure" : 1} 
      this.link_max_v = {"length" :1, "diameter" : 1,"roughness" : 1,
                              "flow" : 1, "velocity" : 1, "unit_headloss" : 1}
      this.setting =null ;
      //inp파일관리 테이블에서 데이터를 가져온다.
      //this.file_model.close();
     
      this.$http.get(this.URL+'api/get_model_info_setting?id='+this.tag).then((res) => {
        this.setting = res.data ;

        if(this.setting === null || this.setting === ""){
            this.default_model_setting();
        }else{
            this.setting = Object.assign({},  res.data) ;
        }
        this.$http.get(this.URL+'api/get_model_info?id='+this.tag).then((res) => {
            if(res.data.list.length > 0){
                var inp_text = res.data.list[0].inp_file;
                this.model_info_data = res.data.list[0] ;
       
                // var t_text =  JSON.stringify(inp_text)
                this.draw_model(inp_text);
                //  this.on_facility_info(id)
                // this.file_text(inp_text) ; 
                /*
                    this.properties['name']  = res.data.list.name; 
                    this.properties['w_count']  = res.data.list.water_meter; 
                    this.properties['v_count'] = res.data.list.valve;         
                    this.properties['sp_length']  = res.data.results[0][0].supply_pipe_meter;     
                    this.properties['sv_length']  = res.data.results[0][0].service_pipe_meter;    
                    this.properties['area']  = res.data.results[0][0].area; 
                    this.properties['elevation_h']  = res.data.results[0][0].elevation_high;   
                    this.properties['elevation_a']  = res.data.results[0][0].elevation_avg;   
                    this.properties['elevation_l']  = res.data.results[0][0].elevation_low;                                                     
                 */
                    
            }
           })  
           
       }) 
      
      // this.on_goto_map_click(2347672)
      // this.set_map_gisload(0);
       /*
       setTimeout(()=>{
            this.on_goto_map_click(2347672)
       }, 2000)       
      */
    },
    default_model_setting(){
        //
         var node_tot = {'Junctions': 0, 
                       'Reservoirs':  0,
                       'Tanks' : 0 };
         var link_tot = {'Pipes' : 0,
                         'Pipes_length' : 0,
                         'Pumps':   0,
                         'Valves':  0,                                             
                        } ;                
        var setting_info = {'elevation': { "set" :  "0,25,50,75,100","color_set" : 0,"unit" : 'm'}, 
                       'base_demand':  { "set" :  "0,25,50,75,100","color_set" : 0,"unit" : "LPS"},
                       'init_quality' : { "set" : "0,0.25,0.50,0.75,1.00","color_set" : 0,"unit":""},
                       'demand' : { "set" : "0,25,50,75,100","color_set" : 0,"unit" : "LPS"},
                       'head':  { "set" :  "0,25,50,75,100","color_set" : 0 ,"unit" : 'm' },
                       'pressure': {"set" : "0,25,50,75,100","color_set" : 0,"unit" : 'm'},     
                       'quality': {"set" : ",00.25,0.50,0.75,1.00","color_set" : 0 ,"unit":""}, 
                       'length': {"set" : "0,100,500,1000,5000","color_set" : 0 ,"unit":"m"} ,
                       'diameter': {"set" : "0,6,12,24,36","color_set" : 0 ,"unit":"mm"} ,
                       'roughness': {"set" : "0,50,75,100,125","color_set" : 0 ,"unit":"mm"} ,
                       'flow': {"set" : "0,25,50,75,100","color_set" : 0 ,"unit":"LPS"} ,
                       'velocity': {"set" : "0,0.01,0.10,1.00,2.00","color_set" : 0 ,"unit":"m/s"} , 
                       'link_quality': {"set" : "0,0.25,0.50,0.75,1.00","color_set" : 0 ,"unit":""},  
                       'unit_headloss': {"set" : "0,0.03,0.05,0.08,0.10","color_set" : 0 ,"unit":"m/km"},                                        
                        'view' : {"node_size": 5, "node_size_propor": true, "node_value" : true,"link_size": 2, 
                          "link_size_propor": true, "link_value" : true , "link_arrow" : false }
                       } ;  
        var set_contrl = {'Junctions':[1], //값 셋팅을 위해서 index값만 저장한다.
                       'Reservoirs':  [],
                       'Tanks' : [],
                       'Pipes' : [],
                       'Pumps':   [],
                       'Valves':  []}  
        var set_value = {'Junctions':[1], 
                       'Reservoirs':  [],
                       'Tanks' : [],
                       'Pipes' : [],
                       'Pumps':   [],
                       'Valves':  []}  
        this.setting = {"set_value" : set_value, //값을 보여줄 index
                        "set_contrl" : set_contrl,//값을 제어할 index
                        "setting_info" : setting_info, 
                        "node_tot" : node_tot,
                        "link_tot"  : link_tot}                    
    },              
    file_text(blob_text){
      const reader = new FileReader();
      var self = this;
      reader.addEventListener('loadend', () => {
          // reader.result에 블롭의 내용이 형식화 배열로 들어있음
         const t_text = reader.result;
         self.draw_model(t_text);
      });
      //reader.readAsArrayBuffer(file_text);
      
       reader.readAsArrayBuffer(new Blob(new Uint8Array(blob_text),{ type: 'text/plan' }))
    },
     draw_model(file_text){       
        if(this.file_model !== null){
            this.file_model.close();
        }  
        const ws = new Workspace()
        this.file_model = new Project(ws)
        ws.writeFile("net2.inp", file_text);

        this.file_model.open("net2.inp", "report.rpt", "out.bin");
        this.file_model.solveH();      

        this.getNodes_return();
        this.getLinks_return();
        //junction 데이터를 만들어준다.
         
        this.model_json = {'Junctions':this.junctions, 
                       'Reservoirs':  this.reservoirs,
                       'Tanks' : this.tanks,
                       'Pipes' : this.pipes,
                       'Pumps':  this.pumps, 
                       'Valves': this.valves,
                       'setting' : this.setting                                             
                        } ;
        //this.on_facility_info(this.tag) ; 
         this.$refs.modeling_info.properties = null ;                      
     },

     //jucntion datasetting 한다.
     unit_set_junction(properties_change,keys){

       for(let i=0; i<keys.length; i++) {       
          if(keys[i] === "elevation")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.Elevation,properties_change["elevation"])  
          else if(keys[i] === "demand")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.BaseDemand,properties_change["demand"])  
          else if(keys[i] === "pattern")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.Pattern,properties_change["pattern"])   
        
          //value값만 필요
          else if(keys[i] === "control_yn"){
            if(properties_change.control_yn){             //             
              if (this.setting.set_contrl.Junctions.indexOf(properties_change.idx) === -1) {
                this.setting.set_contrl.Junctions.push(properties_change.idx)
                //this.Junctions[row_idx-1].control_yn = true;
              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_contrl.Junctions.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_contrl.Junctions.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
          else if(keys[i] === "value_yn"){
            if(properties_change.value_yn){             //             
              if (this.setting.set_value.Junctions.indexOf(properties_change.idx) === -1) {
                this.setting.set_value.Junctions.push(properties_change.idx)                //this.Junctions[row_idx-1].control_yn = true;

              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_value.Junctions.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_value.Junctions.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
       }
     },
     unit_set_tank(properties_change,keys){ //배수지

       for(let i=0; i<keys.length; i++) {       
          if(keys[i] === "elevation")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.Elevation,properties_change["elevation"])  
          else if(keys[i] === "MinLevel")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.MinLevel,properties_change["MinLevel"])  
          else if(keys[i] === "MaxLevel")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.MaxLevel,properties_change["MaxLevel"])   
         else if(keys[i] === "TankDiam")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.TankDiam,properties_change["TankDiam"]) 
          //value값만 필요
          /*
          else if(keys[i] === "control_yn"){
            if(properties_change.control_yn){             //             
              if (this.setting.set_contrl.Tanks.indexOf(properties_change.idx) === -1) {
                this.setting.set_contrl.Tanks.push(properties_change.idx)
                //this.Junctions[row_idx-1].control_yn = true;
              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  var del_index = this.setting.set_contrl.Tanks.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_contrl.Tanks.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
          */
          else if(keys[i] === "value_yn"){
            if(properties_change.value_yn){             //             
              if (this.setting.set_value.Tanks.indexOf(properties_change.idx) === -1) {
                this.setting.set_value.Tanks.push(properties_change.idx)                //this.Junctions[row_idx-1].control_yn = true;

              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_value.Tanks.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_value.Tanks.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
       }
     },
     unit_set_reservoir(properties_change,keys){

       for(let i=0; i<keys.length; i++) {       
          if(keys[i] === "elevation")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.Elevation,properties_change["elevation"])  
          else if(keys[i] === "demand")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.BaseDemand,properties_change["demand"])  
          else if(keys[i] === "pattern")
            this.file_model.setNodeValue(properties_change.idx,NodeProperty.Pattern,properties_change["pattern"])   
        
          //value값만 필요
          else if(keys[i] === "control_yn"){
            if(properties_change.control_yn){             //             
              if (this.setting.set_contrl.Reservoirs.indexOf(properties_change.idx) === -1) {
                this.setting.set_contrl.Reservoirs.push(properties_change.idx)
                //this.Junctions[row_idx-1].control_yn = true;
              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_contrl.Reservoirs.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_contrl.Reservoirs.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
          else if(keys[i] === "value_yn"){
            if(properties_change.value_yn){             //             
              if (this.setting.set_value.Reservoirs.indexOf(properties_change.idx) === -1) {
                this.setting.set_value.Reservoirs.push(properties_change.idx)                //this.Junctions[row_idx-1].control_yn = true;

              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_value.Reservoirs.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_value.Reservoirs.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
       }
     },
     unit_set_link(properties_change,keys){

       for(let i=0; i<keys.length; i++) {       
          if(keys[i] === "diameter")
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.Diameter,properties_change["elevation"])  
          else if(keys[i] === "length")
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.Length,properties_change["length"])  
          else if(keys[i] === "minorLoss") //Pipe/valve minor loss coefficient.
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.MinorLoss,properties_change["MinorLoss"])   
          else if(keys[i] === "status") //open/close
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.InitStatus,properties_change["status"])   
          else if(keys[i] === "roughness") //open/close
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.Roughness,properties_change["roughness"])   
        
          //value값만 필요
          else if(keys[i] === "control_yn"){
            if(properties_change.control_yn){             //             
              if (this.setting.set_contrl.Pipes.indexOf(properties_change.idx) === -1) {
                this.setting.set_contrl.Pipes.push(properties_change.idx)
                //this.Junctions[row_idx-1].control_yn = true;
              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_contrl.Pipes.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_contrl.Pipes.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
          else if(keys[i] === "value_yn"){
            if(properties_change.value_yn){             //             
              if (this.setting.set_value.Pipes.indexOf(properties_change.idx) === -1) {
                this.setting.set_value.Pipes.push(properties_change.idx)                //this.Junctions[row_idx-1].control_yn = true;

              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_value.Pipes.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_value.Pipes.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
       }
     },     
     unit_set_pump(properties_change,keys){

       for(let i=0; i<keys.length; i++) {       
          if(keys[i] === "PumpHCurve")
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.PumpHCurve,properties_change["PumpHCurve"])  
          else if(keys[i] === "PumpPower")
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.PumpPower,properties_change["PumpPower"])  
          else if(keys[i] === "LinkPattern") //Pipe/valve minor loss coefficient.
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.LinkPattern,properties_change["LinkPattern"])   
          else if(keys[i] === "status") //open/close
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.InitStatus,properties_change["status"])   
          else if(keys[i] === "PumpECurve") //open/close
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.PumpECurve,properties_change["PumpECurve"])   
          else if(keys[i] === "PumpECost") //open/close
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.PumpECost,properties_change["PumpECost"])   
          else if(keys[i] === "PumpEPat") //open/close
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.PumpEPat,properties_change["PumpEPat"])   
      
          //value값만 필요
          else if(keys[i] === "control_yn"){
            if(properties_change.control_yn){             //             
              if (this.setting.set_contrl.Pumps.indexOf(properties_change.idx) === -1) {
                this.setting.set_contrl.Pumps.push(properties_change.idx)
                //this.Junctions[row_idx-1].control_yn = true;
              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_contrl.Pumps.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_contrl.Pumps.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
          else if(keys[i] === "value_yn"){
            if(properties_change.value_yn){             //             
              if (this.setting.set_value.Pumps.indexOf(properties_change.idx) === -1) {
                this.setting.set_value.Pumps.push(properties_change.idx)                //this.Junctions[row_idx-1].control_yn = true;

              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_value.Pumps.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_value.Pumps.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
       }
     },     
     unit_set_valve(properties_change,keys){

       for(let i=0; i<keys.length; i++) {       
           if(keys[i] === "diameter")
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.Diameter,properties_change["elevation"])  
          else if(keys[i] === "setting")
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.Setting,properties_change["setting"])  
          else if(keys[i] === "minorLoss") //Pipe/valve minor loss coefficient.
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.MinorLoss,properties_change["minorLoss"])   
          else if(keys[i] === "status") //open/close
            this.file_model.setLinkValue(properties_change.idx,LinkProperty.InitStatus,properties_change["status"])   
        
          //value값만 필요
          /*
          else if(keys[i] === "control_yn"){
            if(properties_change.control_yn){             //             
              if (this.setting.set_contrl.Junctions.indexOf(properties_change.idx) === -1) {
                this.setting.set_contrl.Junctions.push(properties_change.idx)
                //this.Junctions[row_idx-1].control_yn = true;
              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_contrl.Junctions.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_contrl.Junctions.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
          */
          else if(keys[i] === "value_yn"){
            if(properties_change.value_yn){             //             
              if (this.setting.set_value.Valves.indexOf(properties_change.idx) === -1) {
                this.setting.set_value.Valves.push(properties_change.idx)                //this.Junctions[row_idx-1].control_yn = true;

              }
            }else{
            // if (this.setting.set_contrl.Reservoirs.indexOf(i) !== -1){
                  let del_index = this.setting.set_value.Valves.indexOf(properties_change.idx) 
                  if (del_index > -1) {
                      this.setting.set_value.Valves.splice(del_index, 1);
                    //  this.Junctions[row_idx-1].control_yn = false;
                  }
            } 
          }
       }
     },     
     //2023-08-22 
     //팝업창에서 데이터를 수정후 model을 바꿔준다.
     //밸브,저수조, 펌프, 등 데이터 저장후 모델을 변경해 준다.
      unit_model_change(properties_change){
        //
        let keys = Object.keys(properties_change)
        if(properties_change.code1 === 'junction'){
            //junction의 값을 셋팅 한다.
          this.unit_set_junction(properties_change,keys)
        }else if( properties_change.code1 === 'tank' ){         
          this.unit_set_tank(properties_change,keys)
        }else if( properties_change.code1 === 'reservoir' ){
          this.unit_set_reservoir(properties_change,keys)
        }else if( properties_change.code1 === 'pipe' ){
          this.unit_set_link(properties_change,keys)
        }else if( properties_change.code1 === 'pump' ){
          this.unit_set_pump(properties_change,keys)
        }else if( properties_change.code1 === 'valve' ){
          this.unit_set_valve(properties_change,keys)
        }
        this.file_model.solveH();      

        this.getNodes_return();
        this.getLinks_return();
      },
      //model 데이터를 지도에 뿌린다.
      simulation_model_map_view(simulation_model){
        //  this.file_model.close();
          this.file_model = simulation_model
          this.getNodes_return();
          this.getLinks_return();
      },
      getNodes_return() {
        //지도에 map
        // var ext = [];
        var min_x = 0;
        var min_y = 0;
        var max_x = 0;
        var max_y = 0;

        const model = this.file_model;
        var l_v_layer = this.$refs.map.corre_layer ;      
        if(l_v_layer != null) {
            var source_h = l_v_layer.getSource();
            var hfeatures = source_h.getFeatures();
            for(var k=0; k< hfeatures.length ; k++) {
                source_h.removeFeature(hfeatures[k]);
            }
            this.$refs.map.map.removeLayer(l_v_layer) ;
            l_v_layer = null;
        }   
         var vectorLine = new VectorSource({});
         const nodeCount = model.getCount(CountType.NodeCount);            
         this.junctions = [] ; //node-junction을 넣어준다.
         this.reservoirs = [] ;
         this.tanks = [] ;
       //  this.nodeIds = [];
         var abox =null;
       //  let str = 'Node Results:<br/>';
         var ele_max = 1
         var base_max = 1
         var de_max = 1
         var head_max = 1
         var press_max = 1
         /*
         var ele_min = 0
         var base_min = 0
         var de_min = 0
         var head_min = 0
         var press_min = 0
         */
         for (let i = 1; i <= nodeCount; i++) {
            var control_yn = false ;
            var value_yn = false ;
         
            if (model.getNodeType(i) === NodeType.Junction) {
                const nodeId = model.getNodeId(i);
                this.properties["Junction_idx"+i] = nodeId
                this.properties["Junction_demand"+i] = model.getNodeValue(model.getNodeIndex(nodeId), NodeProperty.Demand)
           //     nodeIds.push(nodeId);
           //     str += nodeId +" : pressure => " + Math.round(model.getNodeValue(model.getNodeIndex(nodeId), NodeProperty.Pressure)*100)/100+" : Head => " 
           //                 + Math.round(model.getNodeValue(model.getNodeIndex(nodeId), NodeProperty.Head)*100)/100+"<br/>"
               
                if (this.setting.set_contrl.Junctions.indexOf(i) > -1)  control_yn = true ; 
                if (this.setting.set_value.Junctions.indexOf(i) > -1) value_yn = true;
                abox = {};
              
               abox = {'idx':  i, 
                       'id': model.getNodeId(i),
                       'type' : "junction",
                       'elevation' : model.getNodeValue(i,NodeProperty.Elevation) > 0 ? Math.round(model.getNodeValue(i,NodeProperty.Elevation)*100)/100 :model.getNodeValue(i,NodeProperty.Elevation) , // 고도값을 가져온다.
                       'demand' : model.getNodeValue(i,NodeProperty.Demand)> 0 ? Math.round(model.getNodeValue(i,NodeProperty.Demand)*1000)/1000 :model.getNodeValue(i,NodeProperty.Demand),  // 고도값을 가져온다.
                       'head' : model.getNodeValue(i,NodeProperty.Head) > 0 ? Math.round(model.getNodeValue(i,NodeProperty.Head)*100)/100 :model.getNodeValue(i,NodeProperty.Head) , // 고도값을 가져온다.
                       'pressure': Math.round(model.getNodeValue(i, NodeProperty.Pressure)*100)/100 ,    // 압력계산값을 가져온다.                                
                       'pattern':  model.getNodeValue(i,NodeProperty.Pattern), //패턴을 가져온다.       
                       'value_yn' : value_yn,
                       'control_yn' : control_yn,
                       'code' : 1100 , //map point layer      
                       'code1' : 'junction',  
               } ; 
               this.junctions.push(abox) ; 

            }else if(model.getNodeType(i) === NodeType.Tank) {
                const tankId = model.getNodeId(i);
                this.properties["tank_idx"] = tankId
                this.properties["tank_value"] = model.getNodeValue(model.getNodeIndex(tankId), NodeProperty.MaxLevel)
              abox = {};
                if (this.setting.set_contrl.Tanks.indexOf(i) > -1)  control_yn = true ; 
                if (this.setting.set_value.Tanks.indexOf(i) > -1) value_yn = true;
               abox = {'idx':  i, 
                       'id': model.getNodeId(i),
                       'type' : "tank",    
                       'InitLevel' : Math.round(model.getNodeValue(i,NodeProperty.TankLevel)*100)/100, //
                       'MinLevel' : Math.round(model.getNodeValue(i,NodeProperty.MinLevel)*100)/100, //
                       'MaxLevel' : Math.round(model.getNodeValue(i,NodeProperty.MaxLevel)*100)/100, //
                       'diameter' : Math.round(model.getNodeValue(i,NodeProperty.TankDiam)*100)/100, // 고도값을 가져온다.
                       'MinVol':  Math.round(model.getNodeValue(i,NodeProperty.MinVolume)*100)/100, //패턴을 가져온다.                                            
                       'VolCurve' : Math.round(model.getNodeValue(i,NodeProperty.VolCurve)*100)/100, //   
                       'tanklevel': Math.round(model.getNodeValue(i, NodeProperty.TankLevel)*100)/100 ,    // Level을 가져온다.   
                       'elevation' : model.getNodeValue(i,NodeProperty.Elevation) > 0 ? Math.round(model.getNodeValue(i,NodeProperty.Elevation)*100)/100 :model.getNodeValue(i,NodeProperty.Elevation) , // 고도값을 가져온다.
                       'demand' : model.getNodeValue(i,NodeProperty.Demand)> 0 ? Math.round(model.getNodeValue(i,NodeProperty.Demand)*1000)/1000 :model.getNodeValue(i,NodeProperty.Demand),  // 고도값을 가져온다.
                       'head' : model.getNodeValue(i,NodeProperty.Head) > 0 ? Math.round(model.getNodeValue(i,NodeProperty.Head)*100)/100 :model.getNodeValue(i,NodeProperty.Head) , // 고도값을 가져온다.
                       'pressure': Math.round(model.getNodeValue(i, NodeProperty.Pressure)*100)/100 ,    // 압력계산값을 가져온다.                       
                       'code' : 1100 , //map point layer       
                       'code1' : 'tank',   
                       'value_yn' : value_yn,
                       'control_yn' : control_yn,         
               } ; 
               this.tanks.push(abox) ;
            }else if(model.getNodeType(i) === NodeType.Reservoir) {
                const reserId = model.getNodeId(i);
                this.properties["reservoir_idx"] = reserId
                this.properties["reservoir_value"] = model.getNodeValue(model.getNodeIndex(reserId), NodeProperty.Elevation)
                 abox = {};
               if (this.setting.set_contrl.Reservoirs.indexOf(i) > -1)  control_yn = true ; 
               if (this.setting.set_value.Reservoirs.indexOf(i) > -1) value_yn = true;
               abox = {'idx':  i, 
                       'id': model.getNodeId(i),
                       'type' : "reservoir",
                       'pattern' : model.getNodeValue(i,NodeProperty.Pattern), //       
                    //   'value': Math.round(model.getNodeValue(i, NodeProperty.Elevation)*100)/100 ,    // Level을 가져온다.   
                       'elevation' : model.getNodeValue(i,NodeProperty.Elevation) > 0 ? Math.round(model.getNodeValue(i,NodeProperty.Elevation)*100)/100 :model.getNodeValue(i,NodeProperty.Elevation) , // 고도값을 가져온다.
                       'demand' : model.getNodeValue(i,NodeProperty.Demand)> 0 ? Math.round(model.getNodeValue(i,NodeProperty.Demand)*1000)/1000 :model.getNodeValue(i,NodeProperty.Demand),  // 고도값을 가져온다.
                       'head' : model.getNodeValue(i,NodeProperty.Head) > 0 ? Math.round(model.getNodeValue(i,NodeProperty.Head)*100)/100 :model.getNodeValue(i,NodeProperty.Head) , // 고도값을 가져온다.
                       'pressure': Math.round(model.getNodeValue(i, NodeProperty.Pressure)*100)/100 ,    // 압력계산값을 가져온다.                    
                       'value_yn' : value_yn,
                       'control_yn' : control_yn, 
                       'code' : 1100 , //map point layer        
                       'code1' : 'reservoir',            
               } ; 
               this.reservoirs.push(abox) ;
            }
            //지도에 max값에 따른 비율로 원사이즈를 그린다.
            /*
             map_node_option: [
                { value: '', text: 'No View' },
                { value: 'elevation', text: 'Elevation' },
                { value: 'base_demand', text: 'Base_demand' },
                { value: 'demand', text: 'Demand' },
                { value: 'head', text: 'Head' },
                { value: 'pressure', text: 'Pressure' },
            ],
            */
          
            var ele_val = abox['elevation']
           
            if(typeof(ele_val)==='number'){
              ele_max = ele_max < ele_val ? ele_val : ele_max ;
             
            }
            var base_val = abox['base_demand']
            
            if(typeof(base_val)==='number'){
              base_max = base_max < base_val ? base_val : base_max ;
            }
            var de_val = abox['demand']
           
            if(typeof(de_val)==='number'){
              de_max = de_max < de_val ? de_val : de_max ;
            }       
            var head_val = abox['head']
           
            if(typeof(head_val)==='number'){
              head_max = head_max < head_val ? head_val : head_max ;
            } 
            var press_val = abox['pressure']
           
            if(typeof(press_val)==='number'){
              press_max = press_max < press_val ? press_val : press_max ;
            } 

            var cor_x = model.getCoordinates(i).x ;
            var cor_y = model.getCoordinates(i).y ;
            //최대값
            if(min_x === 0) min_x = cor_x
            if(min_x > cor_x) min_x =  cor_x    
            //최소값
            if(min_y === 0) min_y = cor_y
            if(min_y > cor_y) min_y =  cor_y    

            if(max_x === 0) max_x = cor_x
            if(max_x < cor_x) max_x =  cor_x    
            //최소값
            if(max_y === 0) max_y = cor_y
            if(max_y < cor_y) max_y =  cor_y    

            if(max_x - min_x < 0.0001 ) max_x = min_x - 0.0001 ;
            if(max_y - min_y < 0.0001 ) max_y = min_y - 0.0001 ;


            var coord = proj4('EPSG:4326',this.$refs.map.map.getView().getProjection().getCode(),  [cor_x,cor_y ])  
            //junction geometry값을 만들어준다.
            var circleFeature = new Feature({
                properties: abox,
                    "idx" : i,
                    "id" : model.getNodeId(i),
                    "layer" : 1100,
                    "type" : model.getNodeType(i),
                    "name" : "junction"+i,
             //   },
                geometry: new Point(coord),
            });
            vectorLine.addFeature(circleFeature);
            if(model.getNodeType(i) === NodeType.Reservoir) {
             // this.multi_popup(circleFeature) 
            }
          }
        this.node_max_v = {"elevation" :ele_max, "base_demand" : base_max,"demand" : de_max,
                          "head" : head_max, "pressure" : press_max} 
         //
         this.$refs.map.set_fit_extent([min_x,min_y,max_x,max_y]);
        // let fill = new Fill({color:'#00ff00' });
         //let stroke = new Stroke({ color: '#00ff00',  width: 1.25 });
         this.$refs.map.corre_layer = new VectorLayer({
                name : "node",
                code : 1100,
                f_type:'',
                source: vectorLine,
                style : this.model_point_style
                /*
                style: new Style({
                    image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 5  }),                    
                    fill: fill,
                    stroke: stroke
                })
                */
            });
        
         this.$refs.map.map.addLayer(this.$refs.map.corre_layer);
         this.$refs.map.corre_layer.setVisible(true) ; 
         //this.updateKey();              
         // return str;
        },
        getLinks_return() {
            this.pipes = [] ;
            this.pumps = [] ;
            this.valves = [] ;
            const model = this.file_model;
            var abox =null;
            const linkount = model.getCount(CountType.LinkCount);      
            var l_v_layer = this.$refs.map.leak_layer ;      
            if(l_v_layer != null) {
                var source_h = l_v_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.$refs.map.map.removeLayer(l_v_layer) ;
                l_v_layer = null;
            }   
           var vectorLine = new VectorSource({});      
          //  let linkIds = [];
          //  let str = 'Link Results:<br/>';
            var len_max = 1
            var dia_max = 1
            var rough_max = 1
            var flow_max = 1
            var velo_max = 1
            var unit_max = 1

            for (let i = 1; i <= linkount; i++) {                
                //const linkId = model.getLinkId(i);
                var control_yn = false ;
                var value_yn = false ;
                if(model.getLinkType(i) === LinkType.Pipe) {
                    const reserId = model.getLinkId(i);  
                    
                    var head1 =  model.getNodeValue(model.getLinkNodes(i).node1,NodeProperty.Head)
                    var head2 =  model.getNodeValue( model.getLinkNodes(i).node2,NodeProperty.Head)  
                    var head_cha = head1 - head2               
                    abox = {};
                    if (this.setting.set_contrl.Pipes.indexOf(i) > -1)  control_yn = true ; 
                    if (this.setting.set_value.Pipes.indexOf(i) > -1) value_yn = true;
                    abox = {'idx':  i, 
                       // 'id': model.getLinkId(i),
                        'id': reserId,
                        'node1' : model.getLinkNodes(i).node1, // 고도값을 가져온다.
                        'node2' : model.getLinkNodes(i).node2, //               
                        'length' : Math.round(model.getLinkValue(i,LinkProperty.Length)*100)/100, //
                      
                        'roughness' : Math.round(model.getLinkValue(i,LinkProperty.Roughness)*100)/100, //
                        'minorLoss' : Math.round(model.getLinkValue(i,LinkProperty.MinorLoss)*100)/100, //  
                      //  'value': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량
                        'diameter' : Math.round(model.getLinkValue(i,LinkProperty.Diameter)*100)/100, //
                        'flow': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량                      
                        'velocity': Math.round(model.getLinkValue(i, LinkProperty.Velocity)*100)/100 ,    // 유량                      
                        'value_yn' : value_yn,
                        'control_yn' : control_yn,
                        'status' : model.getLinkValue(i,LinkProperty.Status), //
                        'code' : 1101 , //map layer_id 라인스트링 layer
                        'code1' : 'pipe',  
                        'head_cha' : head_cha      
                    } ; 
                    this.pipes.push(abox)
                }else if(model.getLinkType(i) === LinkType.Pump) {
                    const reserId = model.getLinkId(i);                   
                    abox = {};
                    if (this.setting.set_contrl.Pumps.indexOf(i) > -1)  control_yn = true ; 
                    if (this.setting.set_value.Pumps.indexOf(i) > -1) value_yn = true;
                    abox = {'idx':  i, 
                       // 'id': model.getLinkId(i),
                        'id': reserId,
                        'node1' : model.getLinkNodes(i).node1, // 고도값을 가져온다.
                        'node2' : model.getLinkNodes(i).node2, //               
                        'parameters' : model.getLinkValue(i,LinkProperty.PumpPower), //   
                        'status': model.getLinkValue(i,LinkProperty.Status),
                       // 'value': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량 
                        'diameter' : Math.round(model.getLinkValue(i,LinkProperty.Diameter)*100)/100, //
                        'flow': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량                      
                        'velocity': Math.round(model.getLinkValue(i, LinkProperty.Velocity)*100)/100 , 
                        'PumpHCurve': model.getLinkValue(i, LinkProperty.PumpHCurve) < 1 ? '' : model.getCurveId(model.getLinkValue(i, LinkProperty.PumpHCurve)),
                        'PumpECurve': model.getLinkValue(i, LinkProperty.PumpECurve) < 1 ? '' : model.getCurveId(model.getLinkValue(i, LinkProperty.PumpECurve)),
                        'value_yn' : value_yn,
                        'control_yn' : control_yn,
                        'code' : 1101 , //map layer_id 라인스트링 layer   
                        'code1' : 'pump',

                    } ; 
                    this.pumps.push(abox)
                }else{ //valves
                    abox = {};
                  if (this.setting.set_contrl.Valves.indexOf(i) > -1)  control_yn = true ; 
                  if (this.setting.set_value.Valves.indexOf(i) > -1) value_yn = true;
                       
                    abox = {'idx':  i, 
                        'id': model.getLinkId(i),
                        'node1' : model.getLinkNodes(i).node1, // 고도값을 가져온다.
                        'node2' : model.getLinkNodes(i).node2, //               
                        'type' : model.getLinkType(i), //valve_type종류    
                        'status': model.getLinkValue(i,LinkProperty.Status),   
                        'setting' : Math.round(model.getLinkValue(i,LinkProperty.Setting)*100)/100, //
                        'minorLoss' :Math.round( model.getLinkValue(i,LinkProperty.MinorLoss)*100)/100, //               
                        //'value': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량
                        'diameter' : Math.round(model.getLinkValue(i,LinkProperty.Diameter)*100)/100, //
                        'flow': Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100 ,    // 유량                      
                        'velocity': Math.round(model.getLinkValue(i, LinkProperty.Velocity)*100)/100 ,  
                        'value_yn' : value_yn,
                        'control_yn' : control_yn,
                        'code' : 1101 , //map layer_id 라인스트링 layer
                        'code1' : 'valve',                
                    } ; 
                    this.valves.push(abox)           
                }

               // linkIds.push(linkId);
               // str += linkId +" : flow => " + Math.round(model.getLinkValue(i, LinkProperty.Flow)*100)/100+"<br/>"                
                //link_max값을 구한다.
             
              
                var len_val = abox['length']
                if(typeof(len_val)==='number'){
                  len_max = len_max < len_val ? len_val : len_max ;
                }
                var dia_val = abox['diameter']
                
                if(typeof(dia_val)==='number'){
                  dia_max = dia_max < dia_val ? dia_val : dia_max ;
                }
                var rough_val = abox['roughness']
               
                if(typeof(rough_val)==='number'){
                  rough_max = rough_max < rough_val ? rough_val : rough_max ;
                }       
                var flow_val = abox['flow']
                
                if(typeof(flow_val)==='number'){
                  flow_max = flow_max < flow_val ? flow_val : flow_max ;
                } 
                var velo_val = abox['velocity']
               
                if(typeof(velo_val)==='number'){
                  velo_max = velo_max < velo_val ? velo_val : velo_max ;
                } 
                var unit_val = abox['unit_headloss']
               
                if(typeof(unit_val)==='number'){
                  unit_max = unit_max < unit_val ? unit_val : unit_max ;
                } 
            
                var cor_x1 = model.getCoordinates(model.getLinkNodes(i).node1).x ;
                var cor_y1 = model.getCoordinates(model.getLinkNodes(i).node1).y ;
                var coord1 = proj4('EPSG:4326',this.$refs.map.map.getView().getProjection().getCode(),  [cor_x1,cor_y1 ])  
                var cor_x2 = model.getCoordinates(model.getLinkNodes(i).node2).x ;
                var cor_y2 = model.getCoordinates(model.getLinkNodes(i).node2).y ;
                var coord2 = proj4('EPSG:4326',this.$refs.map.map.getView().getProjection().getCode(),  [cor_x2,cor_y2 ])  
                //
                var v_count = model.getVertexCount(i);
                var link_arr = [];
                link_arr.push(coord1) ;
                for (let j = 1; j <= v_count; j++) {  
                   var cor_v1 = model.getVertex(i,j).x ;
                   var cor_v2 = model.getVertex(i,j).y ;
                   var coord_vertex = proj4('EPSG:4326',this.$refs.map.map.getView().getProjection().getCode(),  [cor_v1,cor_v2 ])  
                   link_arr.push(coord_vertex) ;
                }
                link_arr.push(coord2) ;
                var featureLine1 = new Feature({
                       geometry: new LineString(link_arr),
                    // geometry: line_geometry
                      properties: abox,
                    "idx" : i,
                    "id" : model.getLinkId(i),
                    "layer" : 1101,
                    "type" : model.getLinkType(i),
                    "name" : "pipe"+i,
                 //   },
                });
                 vectorLine.addFeature(featureLine1);
            }
            this.link_max_v = {"length" :len_max, "diameter" : dia_max,"roughness" : rough_max,
                              "flow" : flow_max, "velocity" : velo_max, "unit_headloss" : unit_max}
           // let fill = new Fill({color:'#00ff00' });
           // let stroke = new Stroke({ color: '#0000ff',  width: 2.25 });
            this.$refs.map.leak_layer = new VectorLayer({
                    name : "pipe",
                    code : 1101,
                    f_type:'',
                    source: vectorLine,
                    style : this.model_line_style
                    /*style: new Style({
                        image: new CircleStyle({ fill: fill,  stroke: stroke,   radius: 5  }),                    
                        fill: fill,
                        stroke: stroke
                    })
                    */
                });
            
            this.$refs.map.map.addLayer(this.$refs.map.leak_layer);
            this.$refs.map.leak_layer.setVisible(true) ; 
            //return str;
        },  
      show_model_f_info(row) {
         this.$refs.map.show_model_f_info(row )
      },
      on_goto_map_click(row) {
         this.$refs.map.select_facility_feature(row.code,'',row.idx )
      },

 
        addr_search(){
          this.$refs.map.go_search(this.addr_value);
        },
        Enter_Check1(){
          this.addr_search();
        },
        toggle_visible_all_layer() {
            this.layer_all_visible = !this.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue
                layer.setVisible(this.layer_all_visible)
            }
        },
        get_gisinfo(){
                this.$refs.map.block_info = 1 ;
               // this.set_toggle_visible_all_layer();
                // this.$refs.map.init_draw();
                this.$refs.map.edit_change('add', 'Block')
                // this.$refs.map.edit_start('add', 'Block') ;
        },
        
        on_facility_info(id) {
          //  var model_info_data_bak = Object.assign({}, this.model_info_data)
          //  var model_json_bak = Object.assign({}, this.model_json)
          //  var file_model_bak = Object.assign({}, this.file_model)
          //  this.$refs.modeling_info.show(id,model_info_data_bak,model_json_bak,file_model_bak)
           this.$refs.modeling_info.show(id, this.model_info_data,this.model_json,this.file_model)
        },        
        change_map_mode(){
            //this.map_mode = 'select'
          //  this.visible_edit = false
          //  this.$refs.map.select_info ='info'
         
          //  this.$bvToast.hide('edit')
          //  this.$refs.map.edit_cancel();            
          
        },
        set_map_zoom() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
           // var size = [size1[0]*0.5, size1[1]*0.5] ;
            var size = [size1[0], size1[1]] ;
            var extent = map.getView().calculateExtent(size);
            //this.$refs.map.set_fit_extent(extent)
            var ext = [extent[0]+0.0003,extent[1]+0.0011,extent[2]-0.0003,extent[3]-0.001]
            map.getView().fit(ext, {padding: [0, 0, 0, 0], constrainResolution: false}); 
           // let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            /*
            //zoom 조절
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            if(this.$refs.map.map.getView().getZoom() < cz){
              this.zoom('+')
            }else{
              this.zoom('-')
            }
            */
        },
       // 20200708 추가
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
           // var size = [size1[0]*2, size1[1]*2] ;
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length-1; i++) {
                    var layer = this.$refs.map.layer_list[i];
                    if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length-1; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);


                    } //for문 끝

                } // zoom크기에 대한 if


        },
       view_map_elevation(a) {
            if(a === 1)
                this.$refs.map.layer_leak_visible =false
            else if(a === 2)  
                this.$refs.map.layer_leak_visible =true
         

            let map = this.$refs.map.map;
      
            var size1 = map.getSize();
            var size = [size1[0]*2, size1[1]*2] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                if(this.elevation_layer !== null){
                    map.removeLayer(this.elevation_layer) ;
                }
                let layer_style = null     
                if(a > 1) return;
                      
                layer_style = this.$refs.map.layer_setting_stlye_el_list
          
                for(let i=0; i<layer_style.length; i++) {

                    var params = layer_style[i];                 
                 
                    var _url=params.surl;
                  
                    var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;
                   // params.surl = _url 
                    this.elevation_layer = CreateVectorLayer_f(params,extent) ;

                    var vis = params.visible ;
                    this.elevation_layer.setVisible(vis);                    
                    map.addLayer(this.elevation_layer);

                } //for문 끝
           
            } // zoom크기에 대한 if


        },

        facility_refresh(){
           // this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex");
            this.init_mode() ;
        },
        gis_reload(){
          //  this.edit_cancel() ;
          //  this.$bvToast.hide('edit') ;
            this.set_map_gisload(0) ;
            if(this.visible_edit ){              
            this.$refs.map.edit_start(this.edit_mode, this.add_layer_name)
            }
        },
        init_mode(){
           // this.edit_cancel() ;
           // this.$bvToast.hide('edit') ;
        //   if(this.visible_edit ){              
         //       this.$refs.map.edit_start(this.edit_mode, this.add_layer_name)
         //  }
        },
        zoom(e) {
            var z = this.$refs.map.map.getView().getZoom() ;
            if(e==='+'){
                this.$refs.map.map.getView().setZoom(z+1);
            }else{
                this.$refs.map.map.getView().setZoom(z-1);
            }

        },
        zoom_fit() {
            this.$refs.map.set_fit_extent(this.$store.setting.extent)
        },
     
        get_edit_layers() {
            let lyrs = this.$refs.map.get_layers()
            let ll = []
            for(let i=1; i<lyrs.length; i++) {
                if(parseInt(lyrs[i].get('code'))>=100) continue
                ll.push(lyrs[i])
            }
            return ll
        },
        get_layers() {
            return this.$refs.map.get_layers()
        },
        layer_visible(e, name) {
            for(let i=0; i<this.get_layers().length; i++) {
                if(this.get_layers()[i].get('name')!==name) continue;
                this.get_layers()[i].setVisible(e)
            }
        },
        fn_press_han(event)
        {
           var objTarget = event.srcElement || event.target;
          // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');
           // alert('Please enter only numbers');
           // event.preventDefault();          
          }
                 
        },
        //다중 팝업 레이터 만들기
      //  map.on('singleclick', function(evt) {
        multi_popup(f){
            var coordinate = f.values_.geometry.flatCoordinates;
           // var hdms = ol.coordinate.toStringHDMS(ol.proj.transform(coordinate, 'EPSG:3857', 'EPSG:4326'));
            let popup_map = this.$refs.map.map
            // 툴팁 DIV 생성
            let element = document.createElement("div");
            element.classList.add('ol-popup');
            //element.innerHTML = `<a id="popup-closer" class="ol-popup-closer"></a> <div><p>You clicked here:</p><code>${1234}</code></div>`;
            element.innerHTML = `<a id="popup-closer" class="ol-popup-closer"></a> <div><p>You clicked here:</p><code>안녕하세요.</code></div>`;
            element.style.display = 'block';

          // OverLay 생성
            let overlay = new Overlay({
                element: element, // 생성한 DIV
                autoPan: true,
                className: "multiPopup",
                autoPanMargin: 100,
                autoPanAnimation: {
                    duration: 400
                }
            });
          //오버레이의 위치 저장
            overlay.setPosition(coordinate);
            //지도에 추가
            this.$refs.map.map.addOverlay(overlay);

          // 해당 DIV 다켓방법
            let oElem = overlay.getElement();
            oElem.addEventListener('click', function(e) {
                var target = e.target;
                if (target.className == "ol-popup-closer") {
                    //선택한 OverLayer 삭제
                    popup_map.removeOverlay(overlay);

                }
            });
       }

    },
    mounted () {
   // this.contents_height = window.innerHeight - 120
      this.map_height = window.innerHeight - 120
      this.box = [] ;
      this.tag =  Number(this.$route.query.id) ;
     // let textFill = new Fill({color: '#FF0000'});
     // api/get_facility_list_ex?customer_id=1&layer_type=7&page=1&per_page=15&search_term=&sort_field=&sort_type=
       this.model_line_style = (f) => {
       //const _model_line_style = (f) => {
            let stroke = new Stroke({ color: '#0000ff',  width: 2.0 });  //파란색
            let textStroke = new Stroke({ color: '#000000',  width: 1.0 }); //화이트    
            let circleFill = new Fill({color: '#00ff00'}) //연두색
          //  let opt = 'value'
            
            

            let v_obj = f.get('properties');
            let v = ''
            var color1 =  null
            let line_width = Number(this.setting.setting_info.view.link_size); //기본 1, 가장큰것이 6

             line_width = typeof(line_width) ==='number'? line_width : 2
          //   line_width = 2; //기본 1, 가장큰것이 6
            if(this.map_link_v !== '' ){             
              v = v_obj[this.map_link_v]
              if(typeof(v)==='number'){
               if(this.link_unit_set_auto ){  
                 if( Number(v) <this.link_max_v[this.map_link_v]/4){
                   color1 = this.legend_link[0].color
                  }else if( Number(v) <this.link_max_v[this.map_link_v]/2){
                    color1 = this.legend_link[1].color
                  }else if( Number(v) <this.link_max_v[this.map_link_v]*3/4){
                    color1 = this.legend_link[2].color
                  }else if( Number(v) <this.link_max_v[this.map_link_v]){
                    color1 = this.legend_link[3].color
                  }else{
                    color1 = this.legend_link[4].color
                  }

                  //선굵기 셋팅값에 따라 달라진다.
                  //'view' : {"node_size": 10, "node_size_propor": true, 
                  // "node_value" : true,"link_size": 10, "link_size_propor": true, 
                  // "link_value" : true  }
                //this.setting.setting_info.view.link_size
                
               // if(typeof(v)==='number' && this.setting.setting_info.view.link_size_propor ) {
               //   line_width = line_width + 6 / this.link_max_v[this.map_link_v] * v ;
                  // 원 크키가 최소크기보다 작으면 최소크기로 설정
              //  }
 
              }else{
                  //var legend = {"value" :value_set_arr[i] ,"color" : color_set[i]}
                 //this.legend_node.push(legend)
               
              
                if( Number(v) <Number(this.legend_link[0].value)){
                  color1 = this.legend_link[0].color
                }else if( Number(v) <Number(this.legend_link[1].value)){
                  color1 = this.legend_link[1].color
                }else if( Number(v) <Number(this.legend_link[2].value)){
                  color1 = this.legend_link[2].color
                }else if( Number(v) <Number(this.legend_link[3].value)){
                  color1 = this.legend_link[3].color
                }else{
                   color1 = this.legend_link[4].color
                }
               
               }
               if(typeof(v)==='number' && this.setting.setting_info.view.link_size_propor ) {
                  line_width = line_width + 3 / this.link_max_v[this.map_link_v] * v ;
                  // 원 크키가 최소크기보다 작으면 최소크기로 설정
                }
 
               circleFill = new Fill({color: color1})
               stroke = new Stroke({ color: color1,  width: line_width });
             //  textStroke = new Stroke({ color: color1,  width: 1 });   
             }
           }
          
            let radius = 5;

           

            let txt = '';
            
            let show_yn = f.get('properties').value_yn
            
            //1. 링크별 밸브값 표시먼저 체크
            //2. 
            if(this.setting.setting_info.view.link_value ){
                if(this.link_value_check !== '' ){
                  if(show_yn && (typeof(v)==='number')) {
                    txt = v.toFixed(1);
                  }
                }else{
                  if (typeof(v)==='number') {
                    txt = v.toFixed(1);
                  }       
                }
            }
            let image = new CircleStyle({ radius: radius, fill: circleFill })

            
            if(f.get('properties').code1 === 'valve'){
        
              var styles = [
                 // linestring
                 new Style({ image: image, text: new Text({ text: txt, font:'12px Verdana', fill: circleFill,
                 stroke: textStroke,offsetY: -10, offsetX: 0 }), stroke: stroke	})
              ];
              var geometry = f.getGeometry();
              geometry.forEachSegment(function(start, end) {
                var dx = end[0] - start[0];
                var dy = end[1] - start[1];
                var rotation = Math.atan2(dy, dx);

                var dx1 = (end[0] + start[0])/2;
                var dy1 = (end[1] + start[1])/2;

                // arrows
                styles.push(new Style({
                  geometry: new Point([dx1,dy1]),
                  image: new Icon({
                  // src: 'http://openlayers.org/en/v3.14.2/examples/data/arrow.png',
                    src:  require("@/assets/images/sub/epanet/valve.png"),
                    anchor: [0.75, 0.5],
                    rotateWithView: false,
                    rotation: -rotation,
                     scale: 1.0,
                  })
                }));
              });
              return styles
            }else if(f.get('properties').code1 === 'pump'){
              var styles1 = [
                 // linestring
                 new Style({ image: image, text: new Text({ text: txt, font:'12px Verdana', fill: circleFill,
                 stroke: textStroke,offsetY: -10, offsetX: 0 }), stroke: stroke	})
              ];
              var geometry1 = f.getGeometry();
              geometry1.forEachSegment(function(start, end) {
                var dx = end[0] - start[0];
                var dy = end[1] - start[1];
                var rotation = Math.atan2(dy, dx);

                var dx1 = (end[0] + start[0])/2;
                var dy1 = (end[1] + start[1])/2;

                // arrows
                styles1.push(new Style({
                  geometry: new Point([dx1,dy1]),
                  image: new Icon({
                  // src: 'http://openlayers.org/en/v3.14.2/examples/data/arrow.png',
                    src:  require("@/assets/images/sub/epanet/pump.png"),
                    anchor: [0.75, 0.5],
                    rotateWithView: false,
                    rotation: -rotation,
                     scale: 1.0,
                  })
                }));
              });
              return styles1
            }else{
              var styles1_ = [
                 // linestring
                 new Style({ image: image, text: new Text({ text: txt, font:'12px Verdana',
                  fill: circleFill, stroke: textStroke,offsetY: -10, offsetX: 0 }), stroke: stroke	})
              ];
              var geometry1_ = f.getGeometry();
              var node_id1 = f.get('properties').node1
              var node_id2 = f.get('properties').node2
              var flow = f.get('properties').flow

              var head1 =  this.file_model.getNodeValue(node_id1,NodeProperty.Head)
              var head2 =  this.file_model.getNodeValue(node_id2,NodeProperty.Head)
              //if(f.get('idx') % 10 === 0 ){
              var g_cnt = Math.floor(geometry1_.flatCoordinates.length/2) -1 
              g_cnt = g_cnt < 2 ? 1 : g_cnt
              var head_cha = head1 - head2
              //console.log(head_cha+g_cnt)
              var i = 0
              var arrow_show = this.setting.setting_info.view.link_arrow
              geometry1_.forEachSegment(function(start1, end1) {
                //++i
                 var end = end1
                 var start = start1 
               // if(i ===  Math.round(g_cnt/2)){
                 if(head_cha < 0){
                   end = start1
                   start  = end1
                 } 
                  var dx = end[0] - start[0];
                  var dy = end[1] - start[1];
                  var rotation = Math.atan2(dy, dx);
                  
                  var dx1 = (end[0] + start[0])/2;
                  var dy1 = (end[1] + start[1])/2;

                  // arrows
                  if(arrow_show  ){
                    ++i;
                    if( flow != 0 && (i === g_cnt-1 || g_cnt < 2 ) && head_cha !== 0 ){
                    // if( flow != 0  ){
                      styles1_.push(new Style({
                      // 
                        geometry: new Point([dx1,dy1]),
                        image: new Icon({
                      // src: 'http://openlayers.org/en/v3.14.2/examples/data/arrow.png',
                          src:  require("@/assets/images/sub/epanet/arrow.png"),
                          anchor: [0.75, 0.5],
                          rotateWithView: false,                    
                          rotation: -rotation ,
                          opacity : 0.5,
                          scale: 1.0,
                        })
                      }));
                    }
                  }
              });
             
              //}
              return styles1_ 
            }
           // return new Style({ image: image, text: new Text({ text: txt, font:'12px Verdana', fill: textFill, stroke: textStroke,offsetY: -10, offsetX: 0 }), stroke: stroke	});
            
        //    return new Style({ image: new CircleStyle({ radius: radius, fill: circleFill }), text: new Text({ text: txt, font:'12px Verdana', fill: textFill, stroke: textStroke,offsetY: -10, offsetX: 0 }), stroke: stroke});
        
        }
         this.model_point_style = (f) => {
            let circleFill = new Fill({color: '#00ff00'}) //연두색
            let stroke = new Stroke({ color: '#00ff00',  width: 1 });
            let textStroke = new Stroke({ color: '#000000',  width: 1 });   
            
          //  let opt = 'value'
  
            let v_obj = f.get('properties');
            let v = ''
            var color1 = null 
                     
            v = v_obj[this.map_node_v]
            if(typeof(v)==='number'){
              if(this.node_unit_set_auto  ){      
                if( Number(v) <this.node_max_v[this.map_node_v]/4){
                    color1 = this.legend_node[0].color
                  }else if( Number(v) <this.node_max_v[this.map_node_v]/2){
                    color1 = this.legend_node[1].color
                  }else if( Number(v) <this.node_max_v[this.map_node_v]*3/4){
                    color1 = this.legend_node[2].color
                  }else if( Number(v) <this.node_max_v[this.map_node_v]){
                    color1 = this.legend_node[3].color
                  }else{
                    color1 = this.legend_node[4].color
                  }
               }else{
                // var legend = {"value" :value_set_arr[i] ,"color" : color_set[i]}
                //this.legend_node.push(legend)
      
                  if( Number(v) <Number(this.legend_node[0].value)){
                    color1 = this.legend_node[0].color
                  }else if( Number(v) <Number(this.legend_node[1].value)){
                    color1 = this.legend_node[1].color
                  }else if( Number(v) <Number(this.legend_node[2].value)){
                    color1 = this.legend_node[2].color
                  }else if( Number(v) <Number(this.legend_node[3].value)){
                    color1 = this.legend_node[3].color
                  }else{
                    color1 = this.legend_node[4].color
                  }
                
              }
              circleFill = new Fill({color: color1})
              stroke = new Stroke({ color: color1,  width: 1 });
            }
        
           
               //textStroke = new Stroke({ color: color1,  width: 1 });   
            let radius = Number(this.setting.setting_info.view.node_size); //기본 5, 가장큰것이 12
            radius = typeof(radius) ==='number'? radius : 5
            if(typeof(v)==='number'  && this.setting.setting_info.view.node_size_propor ) {
              //alert(this.node_max_v[this.map_node_v]+"/"+v)
               radius = radius + 7 / this.node_max_v[this.map_node_v] * v ;
               // 원 크키가 최소크기보다 작으면 최소크기로 설정
            }
            let txt = '';
            
            let show_yn =f.get('properties').value_yn
            if(this.node_value_check !== ''){
              if(show_yn && (typeof(v)==='number')) {
                txt = v.toFixed(1);
              }
            }else{
              if (typeof(v)==='number') {
                 txt = v.toFixed(1);
              }       
            }
            let image = new CircleStyle({ radius: radius, fill: circleFill })

            if(f.get('properties').code1 === 'reservoir'){
               image = new Icon( ({
                  anchor: [0.5, 1],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'fraction',
                  src: require("@/assets/images/sub/epanet/reservior.png"),
                  scale: 2.0,
               //   color: '#0000ff',
                  offset: [0,-2],
              }))
             
            }else if(f.get('properties').code1 === 'tank'){

               image = new Icon( ({
                  anchor: [0.5, 1],
                  anchorXUnits: 'fraction',
                  anchorYUnits: 'fraction',
                  src: require("@/assets/images/sub/epanet/tank.png"),
                  scale: 1.5,
             //     color: '#0000ff',
                  offset: [0,-2],
              }))
            }
            return new Style({ image: image, text: new Text({ text: txt, font:'12px Verdana', fill: circleFill, stroke: textStroke,offsetY: -10, offsetX: 0 }), stroke: stroke	});
            //return new Style({ image: new CircleStyle({ radius: radius, fill: circleFill }),  stroke: stroke	});
        }

       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
           
           }
         }
        }, 1000)
    },
    beforeUpdate () {
    },
    beforeDestroy (){
        //this.$refs.map = null;
    },
    components: {
      //  UploadShape: () => import('@/components/UploadShape'),
        ModelingInfoType:ModelingInfoType,
        Map: Map,
        SearchLeftType3,
    }
}

</script>
  <style>
      .map {
        width: 100%;
        height: 400px;
      }
      .ol-popup {
        position: absolute;
        background-color: white;
        box-shadow: 0 1px 4px rgba(0,0,0,0.2);
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #cccccc;
        bottom: 12px;
        left: -50px;
        min-width: 280px;
      }
      .ol-popup:after, .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      .ol-popup:after {
        border-top-color: white;
        border-width: 10px;
        left: 48px;
        margin-left: -10px;
      }
      .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
      }
      .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 2px;
        right: 8px;
      }
      .ol-popup-closer:after {
        content: "✖";
      }
    </style>
<style scoped lang="scss">
.table_icon img {
  width:16px;

}
.map-button.icon_btn {
  font-size: 14px;
  line-height: 1;
  margin-right: 6px;
}
.map-button.icon_btn.icon_btn_text {
  font-size: 12px;
}
.icon-button-group ::v-deep .btn-secondary {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  float: left;
  margin-right: 6px;
  font-size: 14px;
  line-height: 23px;
  background: rgba(35, 41, 47, 0.8) !important;
  border-color: transparent !important;
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.icon_btn.btn-secondary {
  background: rgba(35, 41, 47, 0.8);
  border-color: transparent;
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.btn-map-view {
  background: rgba(35, 41, 47, 0.8);
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.map-zoom {
  font-size: 14px;
  float: left;
  margin-right: 10px;
  margin-bottom: 0;
  margin-top: 5px;
}
.btn-toolbar {
  margin-right: 0;
}
.search_div{
    position: absolute;
    left:220px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}

</style>

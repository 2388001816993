<template>
  <!--    <div class="a" :style="{'background-image': 'url(' + require('@/assets/images/login_bg.png') + ')'}">-->
  <form @submit.prevent="onSubmit(m_id, m_pw)" class="login-form">
    <div class="login-area">
      <div class="login">
        <div class="login_logo"><img src="@/assets/images/Common/logo-login.png" alt="logo"></div>
        <p class="wi_plat">WI.Plat</p>
        <p class="title"><span class="bold">NELOW</span><span class="bar">|</span>NEVER LOSE WATER</p>

        <input type="text" v-model="m_id" :placeholder="$t('login.email')" class="login_input">
        <input type="password" v-model="m_pw" :placeholder="$t('login.password')" class="login_input">
        <input type="submit" :value="$t('login.sing')" class="login_btn">
        <div style="width:308px; height:46px;margin: 10px 0 0 0 ; color:#fff;">{{msg}}</div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      m_id: '',
      m_pw: '',
      msg: ''
    }
  },
  methods: {
    onSubmit(m_id, m_pw) {
     // this.$http.get(this.URL + 'api/login_ex?m_id=' + m_id + '&m_pw=' + m_pw).then(({ data }) => {
       let params = {} ;
       const encript_m_id = this.$CryptoJS.AES.encrypt(m_id, "wiplat!123!123").toString()
       const encript_m_pw = this.$CryptoJS.AES.encrypt(m_pw, "wiplat!123!123").toString()
       params['m_id'] = encript_m_id;
       params['m_pw'] = encript_m_pw;
       this.$http.post(this.URL + 'api/login_ex', params ).then(({ data }) => { 
        if (data.status === 'success') {
          /*
                localStorage.m_id = m_id
                localStorage.m_idx = data.m_idx
                localStorage.level = data.level
                localStorage.accessToken = data.accessToken
              */
          //
          sessionStorage.m_id = m_id
          sessionStorage.m_idx = data.m_idx
          sessionStorage.level = data.level
          sessionStorage.customer_id = data.customer_id
          sessionStorage.select_customer_id = data.customer_id
          sessionStorage.select_customer_no = data.customer_no
          //2022.06.29 추가
          sessionStorage.collector = data.collector
          //인도네시아 덴파사르
          let cur_page = "" + document.location.href;
          sessionStorage.member_name = data.name;

          if (cur_page.indexOf("id.neverlosewater.com") !== -1 || cur_page.indexOf("localhost") !== -1) {
            if (data.customer_no === '082-042-000')
              sessionStorage.collector = true;
          }
          sessionStorage.accessToken = data.accessToken
          this.$router.push('/')
        } else if (data.status === 'Inactived_ID') {
          alert(this.$t('login.msg_inactived'))
        }
        else {
          alert(this.$t('login.msg'))
        }
      })


    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/base/variables';

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  padding: 6px;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
  // text-align: center;
  text-decoration: none;
  display: inline-block;
  width: auto;
  border: none;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  transition: 0.5s;

  &:active,
  &:hover,
  &:focus {
    color: $color-white;
    background: $color-primary;
    border: 1px solid $color-primary;
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
  }

}

.login-form {
  width: 100%;
  background-color: $color-bg;
}

.login-area {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  width: 400px;
  height: auto;
}

.login .login_logo {
  width: 143px;
  height: 55px;
  margin: 0 auto 16px;
}

.login .wi_plat {
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
}

.login .title {
  text-align: center;
  width: 100%;
  font-size: 18px;
  color: #fff;
  margin-bottom: 56px;
  letter-spacing: 0.5px;
}

.login .title span {
  color: #fff;
  letter-spacing: 0.5px;
}

.login .title .bold {
  font-weight: 500;
}

.login .title .bar {
  margin: 0 10px;
}

.login .login_input {
  // width: 100%;
  // height: 56px;
  // padding: 0.45rem 0.9rem;
  // border: 1px solid rgba(58,63,69,0.2);
  // box-sizing: border-box;
  // text-indent: 32px;
  // margin-bottom: 24px;
  // font-size: .875rem;
  // font-weight: 400;
  //   line-height: 1.5;
  // color: #080F16;
  // letter-spacing: 0.5px;
  display: block;
  width: 100%;
  height: 56px;
  padding: 0.45rem 0.9rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-white;
  background-color: $color-bg !important;
  margin-bottom: 16px;
  background-clip: padding-box;
  box-sizing: border-box;
  border: 1px solid $color-gray;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;          
  -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.login .login_input::placeholder {
  color: rgb(118, 118, 118);
  font-weight: 400;
  text-align: left;
}

.login .login_input:focus {
  // outline: none;
  border-color: $color-white !important;
}

.login .login_btn {
  /* margin-top: 8px;
  width: 100%;
  height: 56px;
  line-height: 54px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  background: #080F16;
  border: none;
  cursor: pointer;
  letter-spacing: 0.5px; */
  width: 100%;
  height: 56px;
  font-size: 18px;
  background-color: $color-primary;
  color: $color-white;
  border-radius: 0;

  &:hover {
    background-color: darken($color-primary, 20%);
  }
}

.login .login_btn:focus {
  outline: none;
}

.login .login_btn:disabled {
  opacity: 0.5
}
</style>

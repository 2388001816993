<template>
    <div>
          <!-- top-bar -->
        <div class="navbar-custom">

                <ul class="list-unstyled topnav-menu topnav-menu-left">
                    <li>
                        <ol class="breadcrumb">
                             <li class="breadcrumb-title">Accounts Detail</li>
                             <li class="breadcrumb-bar">|</li>
                             <li class="breadcrumb-item"><a href="#">HOME</a></li>
                             <li class="breadcrumb-item"><a href="#">WATER ACCOUNTS</a></li>
                             <li class="breadcrumb-item active">ACCOUNTS DETAIL</li>
                        </ol>
                    </li>
                </ul>
        </div>
    <!-- tom-bar-end -->

        <b-row>
            <b-col>
                <b-row>
                    <b-col>
                        <b-input size="sm" v-model="date" type="date" @change="change_mnf_date($event)"/>
                        <div ref="chart" :style="{height: chart_height+'px'}">
                            <svg/>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <div :style="{height: map_height+'px'}">
                            <Map ref="map"
                                :visible_full_extent="true"
                                w_type="120"
                               :visible_gisload_button="true"     
                            @go_home="go_home()"                                
                            @set_map_gisload="set_map_gisload"                            
                            @toggle_visible_all_layer = "toggle_visible_all_layer" 
                            @facility_invisible_layer = "facility_invisible_layer"                                         
                            @set_info="set_info"   
                            />
                        </div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col>
                <vue-good-table ref="table"
                    :columns="columns"
                    :rows="table"
                    :line-numbers="true"
                    :max-height="table_height+'px'"
                    :select="true"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Map from '@/components/Map';
import * as d3 from 'd3'
import {CreateVectorLayer_f} from '@/gis.js';

export default {
    name: 'AccountsDetail',
    props: [
    ],
    data() {
        return {
            table_height: 600,
            map_height: 500,
            chart_height: 300,
            date: null,
            columns: [
                {label: 'Facility No', field: 'f_idx'},
                {label: 'A', field: 'm0'},
                {label: 'B', field: 'm1'},
                {label: '±(%)', field: 'rate'}
            ],
            f_idx: null,
            table: []
        }
    },
    methods: {
        toggle_visible_all_layer() {
          
            this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')===100) 
                    layer.setVisible(this.$refs.map.layer_all_visible)
            }
        },
        facility_invisible_layer() {
          
            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },

        go_home() {
            this.$router.push({path: '/leak-analysis1', query: { widx: this.$route.query.widx, w_type: '100' }})
        },
        set_map_gisload() {      
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*2, size1[1]*2] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert("지도를 확대해 주세요!!!") ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제
                
                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length-1; i++) {
                    var layer = this.$refs.map.layer_list[i];
                    if(layer=== null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;           
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length-1; i++) {
                    
                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;
                    
                    var newlayer = CreateVectorLayer_f(params,extent) ;
                        this.$refs.map.layer_list.push(newlayer) ;
                        map.addLayer(newlayer);
                        newlayer.setVisible(true);

               
                    } //for문 끝

                } // zoom크기에 대한 if 

           
        },
        set_info(){
            this.$refs.map.select_info = true; 
        },                
        draw_chart(data) {
            d3.select(this.$refs.chart).select("svg").remove();
            var svg = d3.select(this.$refs.chart).append("svg");
            var chartDiv = this.$refs.chart;
            var margin = {top: 20, right: 25, bottom: 20, left: 45};
            var width = chartDiv.clientWidth - margin.left - margin.right;
            var height = chartDiv.clientHeight - margin.top - margin.bottom;
            svg.attr("width", chartDiv.clientWidth).attr("height", chartDiv.clientHeight)
            var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            var x = d3.scaleBand().rangeRound([0, width]).padding(0.5)
            var y0 = d3.scaleLinear().rangeRound([height, 0]);
           

            x.domain(data.map((itm) => { return itm.start_time }));
         //  const shortFormat = d3.timeFormat("%Y-%m-%d");
           let xaxis = d3.axisBottom(x)
            xaxis.tickFormat((d) => {
                return d
            })
               // g.append("g")
               // .attr("transform", "translate(-90," + height + ")")
               // .call(xaxis) 

             g.append("g")
                .attr("transform", "translate(-90," + height + ")")
                .call(d3.axisBottom(x).tickSize([]));     
                
           // x.domain(0,d3.max(data, function(d) { return d.m; }))
           //  x = d3.scaleTime()
           //  x.domain(data.map((itm) => { return itm.m }))
             //x.range([ 0, width ])

            let mx_usage = d3.max(data, d => d.usage)
           

            y0.domain([0, mx_usage+(mx_usage*0.1)]);
            //y1.domain([0, mx_flow+(mx_flow*0.1)]);
            /*
            var tip = d3.tip().attr('class', 'd3-tip').direction('e').offset([-100,-60])
                .html(function(d) {
                    var content = `
                        <table style="margin-top: 2.5px;">
                                <tr><td>Flow : </td><td style="text-align: right">` + d.flow.toFixed(0) + `</td></tr>
                                <tr><td>MNF : </td><td style="text-align: right">` + d.mnf.toFixed(0)  + `</td></tr>
                                <tr><td></td><td style="text-align: right">` + d.rate.toFixed(0)  + `%</td></tr>
                        </table>
                        `;
                    return content;
                });

            svg.call(tip);
            */
            g.append("g")
                .call(d3.axisLeft(y0))
                .append("text")
                .attr("fill", "#000")
                .attr("transform", "rotate(-90)")
                .attr("y", 6)
                .attr("dy", "0.71em")
                .attr("text-anchor", "end")
                .text("Usage");

            g.selectAll(".bar")
                .data(data)
                .enter().append("rect")
                    .attr("class", "bar")
                    .attr("x", function(d) { return x(d.m) })
                    .attr("y", function(d) { return y0(d.usage)  })
                    .attr("width", x.bandwidth())
                    .attr("height", function(d) { return height - y0(d.usage)  })
                //    .on('mouseover', tip.show)
                //    .on('mouseout', tip.hide);
            g.selectAll("text")
                .data(data)
                .enter().append("text")
                .text(function(d) {return d})
             //   .attr("class", "text")
                .style(" font-weight", "bold")
                .style("fill", "white")

            // g.selectAll(".bar").on('click', (e)=>{
            //
            // })
            /*
            g.append("path")
                .data([data])
                .attr("class", "line")
                .style("stroke", "red")
                .style("fill", "none")
                .style("stroke-width", "2px")
                .attr("d", d3.line()
                    .x((d, i) =>  x((i+1)) )
                    .y((d) => y1(d.flow ))
                )
            */

            // Add the X Axis
            //2020.07.08 주석
            /*
            g.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x).tickSize([]));
            */
            // Add the Y1 Axis
            // g.append("g")
            //     .attr("transform", "translate( " + width + ", 0 )")
            //     .call(d3.axisRight(y1))
            //     .append("text")
            //     .attr("fill", "#000")
            //     .attr("transform", "rotate(-90)")
            //     .attr("y", -10)
            //     .attr("dy", "0.71em")
            //     .attr("text-anchor", "end")
                //.text("Flow");

        }
    },
    mounted () {
        this.$http.get(this.URL+"api/get_water_meter_usage?w_idx="+this.$route.query.widx).then((res) => {
            let table = []
            for(let i=0; i<res.data.list.length-1; i++) {
                if(res.data.list[i].usage===null) res.data.list[i].usage = 0
            }
            console.log(res.data.list);            
            for(let i=0; i<res.data.list.length-1; i++) {
                if(res.data.list[i].m==='m0') {
                    if(table.length<=0) {
                        this.f_idx = res.data.list[i].f_idx
                        this.date = res.data.list[i].start_time
                    }
                    table.push({m0: res.data.list[i].usage, f_idx: res.data.list[i].f_idx})
                }
                else if(res.data.list[i].m==='m1') {
                    let item = table[table.length-1]
                    item['m1'] = res.data.list[i].usage
                    item['date'] = res.data.list[i].start_time
                }
            }
            for(let i=0; i<table.length-1; i++) {
                table[i]['rate'] = (((table[i].m1 - table[i].m0) / table[i].m0) * 100).toFixed(0)
            }
            this.table = table
            if(this.f_idx!==null) {
             //   this.$http.get(this.URL+"api/get_water_meter_usage_ex?f_idx="+this.f_idx+"&start_time="+this.date).then((res) => {
                 this.$http.get(this.URL+"api/get_water_meter_usage?w_idx="+this.$route.query.widx).then((res) => { 
                    this.draw_chart(res.data.list)
                })
            }
        })

    },
    beforeDestroy () {
    },
    components: {
        Map: Map
    }
}
</script>

<style scoped>
</style>

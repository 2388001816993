<template>
  <div class="left_area" style="width: 100%;">
    <div class="left_list_box" style="height: calc(100vh - 200px);">
      <div class="input_search_box" >
        <input type="text" class="input_search" v-model="value"   placeholder="Search..."  @keyup.enter ="Enter_Check">
        <input type="submit" class="input_submit" @click="search" >
      </div>
      <div v-if="visible_search_box" >
            <div class="status m1-map d-flex" style="position: relative;margin-top:10px;">
              <div>
                <input type="radio" id="radio_01" name="radio01"  v-model="block_type" value="Big">
                <label for="radio_01">Big</label>
              </div>
              <div>
                <input type="radio" id="radio_02" name="radio01"  v-model="block_type" value="Middle">
                <label for="radio_02">Middle</label>
              </div>
              <div>
                <input type="radio" id="radio_03" name="radio01"  v-model="block_type" value="Small">
                <label for="radio_03">Small</label>
              </div>
              <div>
                <input type="radio" id="radio_04" name="radio01"  checked v-model="block_type" value="">
                <label for="radio_04">All</label>
              </div>     
              <div data-v-e6c3badc="" style="padding:0px 0 0 16px; display: inline-block;width: 100px;white-space: nowrap;">
                <input data-v-e6c3badc="" type="checkbox" id="check_01" name="check_01" value="sork_key">
                <label data-v-e6c3badc="" for="check_01" class="btn-grayLine" style="border: none; padding: 4px 2px 0px; background: none;">Sort key
                </label>
              </div> 
              <!-- <div style="padding-left: 10px;border-radius: 10px;  ;margin-left: 5px;">
                <input type="checkbox" id="check_01" name="check_01"  v-model="sort_" value="sork_key" style=""/>
              <label for="check_01" class="btn-grayLine" style="border:none;padding:4px 2px 0;background: none;">Sort key</label></div>            -->
            </div>
               <!-- 2023 -->
            
      </div>
      <div :class="['list_box_wrap dma scroll_box', , { 'is--small':!visible_search_box}]"  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
        <div :class="['box_list box_list_dma', { 'is--active':activeCheckIndex === index } ]" v-for="(item, index) in box" :key="index" @click="selectBox( index)">
          <div class="con_box dma">
            <span class="num bottom" style="font-size:12px;left: 16px;">{{ item.idx }}
            </span>
            <p class="title ">{{ item.title }}</p>
            <span v-if="visible_search_box" class="unit ">
              <label>Meter : </label>{{ item.meter }} /   
              <label>Valve : </label> {{item.valve}}
            </span>    
            <span v-if="!visible_search_box" class="unit ">
              <label></label>{{ item.type }} /   
              
            </span>         
            <div style="    position: absolute;    top: 20%;    right: 16px; z-index:1000;" >
                <!-- 2022 new  start-->
                <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="go_block_info(item.idx)" style="margin-bottom:10px; width:24px; margin-right: 8px;margin-top: 9%;">
                <!--img src="@/assets/images/Common/icon/map_1.png" alt="" @click="goRouter('/pressure-monitoring-map?w_type=111',item.id,item.flow )"-->
                
                <!-- <button :class="['btn btn-map-view ml-1  map-button icon_btn icon_btn_text  ', {'red': 'red' === item.color},{'blue': 'blue' === item.color},{'gray': 'gray' === item.color}]"  style="float:right;" alt="" @click="$emit('on_goto_map_click',  item)">{{item.type}}</button> -->
                <button v-if="visible_search_box" :class="[' btn-map-view btn-round-square medium ml-1  map-button icon_btn icon_btn_text  ', {'red': 'red' === item.color},{'blue': 'blue' === item.color},{'gray': 'gray' === item.color}]" 
                 style="float:right;" alt="" @click="$emit('on_goto_map_click',  item)">{{item.type}}</button>
                
                <img v-if="!visible_search_box" src="@/assets/images/new/ic_list_map.svg" alt="" @click="$emit('on_goto_map_click',  item.idx)"
             style="width: 24px;height: 24px; margin: 0  8px; cursor: pointer">
            </div>
          </div>
        </div>
      </div>
    </div>
    <DlgM2MoniSetting ref="m2_setting" />
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"  force-use-infinite-wrapper="true">
       <div slot="no-more" style="color: rgb(102, 102, 102); font-size: 14px; padding: 10px 0px;">{{$t('water_leak.left_menu.no_data')}}</div>
    </infinite-loading>
  </div>
</template>

<script>
//pressure-monitoring,leak-monitoring 호출
import DlgM2MoniSetting from '@/components/popup/DlgM2MoniSetting' 
import InfiniteLoading from 'vue-infinite-loading';
export default {
  name: 'SearchLeftType3',
  watch: {
    block_type() {
      this.search();   // 실행할 이벤트
      
    },
    sort_(){
      this.search();       
    }
  },
  data() {
    return {
      value: '',
      block_type: '',
      sort_:'',
      strength_sort_yn : '',
      infiniteId : +new Date(),
      page:1,
      box:[],
      color: '' ,
      sort:'',
      // 2022 new
      // img_com_sense : require('@/assets/images/sub/m2/signal5.png')
      img_com_sense : require('@/assets/images/new/ic_battery.svg')
    }
  },
  props: {
    activeCheckIndex: {
      type: null
    },
 //   box: {
 //     type: Array
 //   },
   isBottomIndex: {
      type: Boolean,
      default:true
    },
    w_type : { type: null},
    visible_search_box:{type:null},
    block_work_type : {type:null}
  },
  methods: {
    infiniteHandler($state) {
       //sort_field=this.sort&sort_type=desc
       var customer_id = sessionStorage.select_customer_id ; 
       if(sessionStorage.select_customer_id === "27"){
          customer_id = 22
        }

      if(this.visible_search_box){ 
        this.$http.get(this.URL+'api/API21/dma/list?search='+this.value+'&limit='+this.page+'&sort='+this.sort_+'&type='+this.block_type+'&customer_id='+customer_id).then((rows) => { 
          if (rows.data.results[0].length > 0) {
            this.page += 1;
            for(let i=0; i<rows.data.results[0].length; i++) {    
              
              var abox = {};
              var color = 'gray'
              if(rows.data.results[0][i].type === "Big"){
                color = 'blue'
              }else if(rows.data.results[0][i].type === "Small"){
                color = 'red'
              }else{
                color = 'gray'
              }
                abox = {'title': rows.data.results[0][i].name, 
                        'idx':  rows.data.results[0][i].id,
                        'type' : rows.data.results[0][i].type,
                        'time':  rows.data.results[0][i].register_datetime,                                             
                        'pipe':rows.data.results[0][i].pipe,
                        'valve':rows.data.results[0][i].valve,
                        'meter':rows.data.results[0][i].water_meter,
                        'color' :color
                        
                } ; 

              this.box.push(abox) ;
            }
            if(this.page === 2){
              this.selectBox(0)
            
            }
            $state.loaded();
          } else {
            $state.complete();
          }
        });
      }else{ //work_block
        var s_url = this.URL+'api/work_block/customer/list/'+ sessionStorage.select_customer_id+"?block_name="+this.value+'&page='+this.page+'&perPage=10'
        if(this.block_work_type === '110'){
          s_url = this.URL+'api/waterpressure/groups?customer_id='+ sessionStorage.select_customer_id+"&search_term="+this.value+'&page='+this.page+'&perPage=10'
          this.$http.get(s_url).then((rows) => { 
            if (rows.data.check ===0 && rows.data.groups.length > 0) {
                this.page += 1;
                for(let i=0; i<rows.data.groups.length; i++) {    
                  
                  var abox = {};
                  abox = {'title': rows.data.groups[i].groupName, 
                          'idx':  rows.data.groups[i].groupId,
                          'type' : '',
                  } ; 
                
               

                  this.box.push(abox) ;
                }
                if(this.page === 2){
                  this.selectBox(0)
                
                }
                // $state.loaded();
                $state.complete();
            } else {
              $state.complete();
            }
          });

        }else{
          this.$http.get(s_url).then((rows) => { 
         
            if (rows.data.check ===0 && rows.data.result.length > 0) {
              this.page += 1;
              for(let i=0; i<rows.data.result.length; i++) {    
                
                var abox = {};
                abox = {'title': rows.data.result[i].name, 
                            'idx':  rows.data.result[i].id,
                            'type' : rows.data.result[i].remark,
                    } ; 
              
                
                this.box.push(abox) ;
              }
              if(this.page === 2){
                this.selectBox(0)
              
              }
              $state.loaded();
            } else {
              $state.complete();
            }
          });

        }

      }
    },   
    statusColor(item) {
      return item.status === 'red' ? 'status_02': 'status_01'
    },
    go_block_info(id){
      this.$emit('on_facility_info', id)
    },
    selectBox (index) {
      if(this.visible_search_box){
        this.$emit('updateCheckIndex', index,this.box[index].idx)
      }else{
        this.$emit('updateCheckIndex', index,this.box[index])
      }
   
    },
    search(){      
      this.page = 1;
      this.box = [];
      this.infiniteId += 1;
    },
    Enter_Check(){
      
       if(event.keyCode == 13){
          this.search();
         /*
         if(this.visible_search_box)
           this.$emit('go_search',  this.value, this.leak_type,this.strength_sort_yn) ;  // 실행할 이벤트
          else
             this.$emit('go_search',  this.value)
         */    
       }
    },
 
  },
   mounted () {
   },
  components: {      
      DlgM2MoniSetting : DlgM2MoniSetting,
      InfiniteLoading,
  }
};
</script>

<style lang="scss" scoped>
// 2022 new 추가
@import '@/assets/scss/base/variables';
// 2022 new 추가


.status.m1-map.d-flex > div:not(:last-child) {
  // 2023
  // margin-right: 6px;
}
.detail__work-title {
  font-size: 13px;
  margin-top: 1%;
  margin-bottom: 2%;
  &:before {
    content: '●';
    font-size: 10px;
    padding-right: 5px;
  }
}
.left_area .list_box_wrap .box_list .con_box.press{
      width: 70%;
}
.left_area .list_box_wrap.dma{  
  height:calc(100vh - 130px - 1rem -  140px );
}
.left_area .list_box_wrap.dma.is--small{  
  height:calc(100vh - 130px - 1rem -  100px  );
}
.map-button.icon_btn.icon_btn_text.blue {
  background-color: #28b7cb;
}
.map-button.icon_btn.icon_btn_text.red {
  background-color: #c85b6d;
}
.left_area .left_list_box {

input[type=radio]{
  position: absolute;
  visibility: hidden;
  display: none;  
  &:checked + label {
    color: $color-white;
    background: $color-primary;
  }        
}
}
// 2023
.left_area .left_list_box .input_search_box{
     width: 100%;
 }
 .left_area .list_box_wrap .box_list{
    // padding: 16px 0 16px 20%;
    // border-radius: 4px;
    // margin-bottom: 16px;
    margin-right: 0%;
    // position: relative;
    // border: 1px solid transparent;
    // cursor: pointer;
    // background-color: #172A46;
}
// 2023
.left_area .list_box_wrap .box_list .num.bottom {
    padding: 10px 0;
}
// 2023
.left_area{
    margin-left: .5em;
}
.status {
     float: none; 
    // margin-top: 1em 0;
}
.list_box_wrap.dma .box_list.box_list_dma.is--active,
.list_box_wrap.dma .box_list.box_list_dma:hover {
      /* 2022 new*/
      /* border-color: #6fcdda; */
      border: 1px solid #28AF81;                    
      background-color: #0E5340;
      cursor: pointer;
  }
</style>

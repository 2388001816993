<template>
    <div class="setting-modal-area">
        <b-modal
            ref="modal"
            title="Settings"
            centered
        >
            <div v-if="setting!==null">
                <b-tabs content-class="" v-model="setting.tab_idx" align="center">
                    <b-tab :title="$t('setting.map')" @click="change_tab(0)">
                        <b-row>
                          <b-col>
                            <b-form-group class="form-title font-weight-semibold">
                              <label class="form-title">{{$t('setting.bg_map')}}</label>
                              <b-row>
                                <b-col :cols="3">
                                  <b-radio class="font-roboto font-weight-lighter" v-model="setting.map_name" name="map_name" value="Daum">
                                    Daum
                                  </b-radio>
                                </b-col>
                                <b-col>
                                  <b-radio class="font-roboto font-weight-lighter" v-model="setting.map_name" name="map_name" value="Bing">Bing</b-radio>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group class="form-title font-weight-medium">
                              <label class="form-title">{{$t('setting.map_type')}}</label>
                              <b-row>
                                <b-col :cols="3">
                                <b-radio class="font-roboto font-weight-lighter" v-model="setting.map_type" name="map_type" value="Standard">Standard</b-radio>
                                </b-col>
                                <b-col>
                                <b-radio class="font-roboto font-weight-lighter" v-model="setting.map_type" name="map_type" value="Satellite">Satellite</b-radio>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-button class="modal-content-button mt-2 mb-1" @click="get_location()">{{$t('setting.btn_extend')}}<i class="fas fa-map-marker-alt ml-1"></i></b-button>
                              <hr class="mt-4">
                                <div class="table-box">
                                  <b-row>
                                    <b-col sm="2" class="pl-0 pr-0">
                                      <label class="modal__input-label">MinX</label>
                                    </b-col>
                                    <b-col sm="10">
                                      <b-input v-model="setting.extent[0]" class="modal__input"/>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col sm="2" class="pl-0 pr-0">
                                      <label class="modal__input-label">MinY</label>
                                    </b-col>
                                    <b-col sm="10">
                                      <b-input v-model="setting.extent[1]" class="modal__input"/>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col sm="2" class="pl-0 pr-0">
                                      <label class="modal__input-label">MaxX</label>
                                    </b-col>
                                    <b-col sm="10">
                                      <b-input v-model="setting.extent[2]" class="modal__input" />
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col sm="2" class="pl-0 pr-0">
                                      <label class="modal__input-label">MaxY</label>
                                    </b-col>
                                    <b-col sm="10">
                                      <b-input v-model="setting.extent[3]" class="modal__input"/>
                                    </b-col>
                                  </b-row>
                                </div>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab  :title="$t('setting.layers')"  @click="change_tab(1)">
                        <b-row>
                            <b-col>
                                <b-button size="sm" class="mr-2 modal-content-button" @click="get_zoom()">{{curr_zoom_text}}</b-button>
                                <b-button size="sm" class="mr-2 modal-content-button" @click="set_zoom('in')"><span class="fas fa-search-plus"></span></b-button>
                                <b-button size="sm" class="mr-2 modal-content-button" @click="set_zoom('out')"><span class="fas fa-search-minus"></span></b-button>
                            </b-col>
                        </b-row>
                      <div class="layers-table mt-3">
                        <b-row class="mb-3 ml-0 mr-0 layers-table-head">
                          <b-col class="form-title mb-0 font-weight-medium" cols="4">
                            <b-checkbox v-model="all_check" @change="check_all()" class="layers-table__item">Layer</b-checkbox>
                          </b-col>
                          <b-col class="form-title mb-0 layers-table__item text-center font-weight-medium" cols="2">
                            Daum
                          </b-col>
                          <b-col class="form-title mb-0 layers-table__item text-center font-weight-medium" cols="2">
                            Bing
                          </b-col>
                          <b-col class="form-title mb-0 layers-table__item text-center font-weight-medium" cols="4">
                            Color
                          </b-col>
                        </b-row>
                        <div class="layers-table-row" v-for="item in setting.layers" :key="item.name">
                          <b-row v-if="Number(item.code)<100 && Number(item.code)>-1">
                            <b-col cols="4">
                              <b-checkbox v-model="item.visible" class="layers-table__item">{{item.name}}</b-checkbox>
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2" v-if="typeof(item.daum_minzoom)!=='undefined'">
                              {{ item.daum_minzoom }}
                              <!--                                    <b-input v-model="item.daum_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item" cols="2" v-if="typeof(item.bing_minzoom)!=='undefined'">
                              {{ item.bing_minzoom }}
                              <!--                                    <b-input v-model="item.bing_minzoom" size="sm"/>-->
                            </b-col>
                            <b-col class="text-center layers-table__item layers-table__item--color" cols="4" v-if="typeof(item.color)!=='undefined'">
                              <b-input v-model="item.color" size="sm" type="color"/>
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab :title="$t('setting.wave')"  @click="change_tab(2)">
                        <div class="">

                            <label class="form-title">Frequency Chart</label>

                            <b-row>
                                <b-col sm="1" class=" pr-0">
                                    <label class="modal__input-label">X</label>
                                </b-col>
                                <b-col sm="5">
                                    <b-input class="modal__input" size="sm" v-model="setting.wave.frequency.x" type="text" min=0  
                                    onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();" v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                                </b-col>
                                <b-col sm="1" class=" pr-0">
                                    <label class="modal__input-label">Y</label>
                                </b-col>
                                <b-col sm="5">
                                    <b-input class="modal__input" size="sm" v-model="setting.wave.frequency.y" type="text" min=0  
                                    onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"  v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                                </b-col>
                            </b-row>
                            <b-col class="tab-btm-line"></b-col>
                            <label class="form-title">Wave Energy</label>
                            <b-row>
                                <b-col sm="6">
                                    <label class="modal__input-label">Min Size</label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="setting.wave.wave_energy.start_size" type="text" min=1  
                                    onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();" v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                                </b-col>
                                <!--
                                <b-col sm="3">
                                    <label class="modal__input-label">Start Value</label>
                                </b-col>
                                <b-col sm="3">
                                    <b-input class="modal__input" size="sm" v-model="setting.wave.wave_energy.start_value"></b-input>
                                </b-col>
                                -->
                            </b-row>

                            <b-row>
                                <b-col sm="6">
                                    <label class="modal__input-label">Max Size</label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="setting.wave.wave_energy.end_size" type="text" min=1  
                                    onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"  v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                                </b-col>
                            </b-row>
                            <b-row>   
                                <b-col sm="6">
                                    <label class="modal__input-label">Circle Size(m)</label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="setting.wave.wave_energy.fix_size" type="text" min=1  
                                    onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"  v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                                </b-col>
                               
                            </b-row>
                            <b-col class="tab-btm-line"></b-col>
                            <b-row>
                                <b-col>
                                    <b-checkbox v-model="setting.wave.visible_wave_energy" class="with--form-title">
                                        <span  class="form-title">Wave Energy Value</span></b-checkbox>
                                </b-col>
                            </b-row>
                           <!-- <b-row>
                                <b-col class="mt-2" sm="5">
                                    <label class="modal__input-label">Range for Calculation</label>
                                </b-col>
                                <b-col class="mt-2" sm="3">
                                    <b-input class="modal__input" size="sm" v-model="setting.wave.range[0]" type="text" min=1  
                                    onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();" v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                                </b-col>
                                <b-col class="mt-2 pt-1" sm="1">
                                    ~
                                </b-col>
                                <b-col class="mt-2" sm="3">
                                    <b-input class="modal__input" size="sm" v-model="setting.wave.range[1]" type="text" min=1  
                                    onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();" v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                                </b-col>
                            </b-row>
                            -->
                            <b-row>
                                <b-col sm="5">
                                    <label class="modal__input-label">Other Color</label>
                                </b-col>
                                <b-col sm="7">
                                    <b-input v-model="setting.wave.color" size="sm" type="color" number required></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="5">
                                    <label class="modal__input-label">Leak Color</label>
                                </b-col>
                                <b-col sm="7">
                                    <b-input v-model="setting.wave.color1" size="sm" type="color" number required></b-input>
                                </b-col>
                            </b-row>

                        </div>
                    </b-tab>
                    <b-tab :title="$t('setting.pressure')"   @click="change_tab(3)">
                      <label class="form-title">Pressure</label>
                        <b-row class="mb-2">
                            <b-col sm="6">
                                <label class="form-title">Value</label>
                            </b-col>
                            <b-col sm="6">
                                <b-select size="sm" v-model="setting.pressure.legend.value" :options="pressure_value" ></b-select>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6">
                                <label class="modal__input-label">Min Size</label>
                            </b-col>
                            <b-col sm="6">
                                <b-input size="sm" class="modal__input" v-model="setting.pressure.legend.start_size" type="text" min=1 
                                 onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();" v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                            </b-col>
                            <!--
                            <b-col sm="3">
                                <label class="modal__input-label">Start Value</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input size="sm" class="modal__input" v-model="setting.pressure.legend.start_value"></b-input>
                            </b-col>
                            -->
                        </b-row>
                        <b-row>
                            <b-col sm="6">
                                <label class="modal__input-label">Max Size</label>
                            </b-col>
                            <b-col sm="6">
                                <b-input size="sm" class="modal__input" v-model="setting.pressure.legend.end_size" type="text" min=1  
                                onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"  v-on:keydown="fn_press_han" v-on:keyup="fn_press_han"></b-input>
                            </b-col>
                            <!--
                            <b-col sm="3">
                                <label class="modal__input-label">End Value</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input size="sm" class="modal__input" v-model="setting.pressure.legend.end_value"></b-input>
                            </b-col>
                            -->
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-checkbox class="form-title mb-1" v-model="setting.pressure.visible_value">Pressure Value</b-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="5">
                                <label class="form-title">Color</label>
                            </b-col>
                            <b-col sm="7">
                                <b-input v-model="setting.pressure.color" size="sm" type="color" number required></b-input>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab :title="$t('setting.browser')"  @click="change_tab(4)">
                        <b-row>
                            <b-col> <label class="form-title">※ Chrome setting</label>
                            </b-col>
                        </b-row>    
                          <b-row>
                            <b-col>
                              
                              <label class="modal__input-label" style="padding:0 15px;">Chrome-Setting-Advanced-System</label>                             
                              <!--b-button size="sm" class="mr-2 modal-content-button" @click="browser_setting_open(0)">  <i class="mdi mdi-share"/></b-button-->
                               
                            </b-col>
                        </b-row>
                        <b-row>
                            <!--img thumbnail fluid src="@/assets/images/sub/web_4/chromesetting.png" alt="Image" bottom/-->
                          <b-img thumbnail fluid :src="set_img[$i18n.locale]"  alt="image" bottom ></b-img>
                        </b-row>
                        <b-row> <b-col> <label class="form-title"> </label></b-col></b-row>
                        <b-row>
                            <b-col> <label class="form-title">※ MS Edge setting</label>
                            </b-col>
                        </b-row>    
                          <b-row>
                            <b-col>
                              
                              <label class="modal__input-label" style="padding:0 15px;">Microsoft Edge-Setting-System</label>                             
                              <!--button size="sm" class="mr-2 modal-content-button" @click="browser_setting_open(1)">  <i class="mdi mdi-share"/></b-button-->
                               
                            </b-col>
                        </b-row>
                        <b-row>
                            <!--img thumbnail fluid src="@/assets/images/sub/web_4/edgesetting.png" alt="Image" bottom/-->
                             <b-img thumbnail fluid :src="set_img1[$i18n.locale]" alt="image" bottom ></b-img>
                        </b-row>                       
                    </b-tab>
                    <!--
                    <b-tab title="Accounts" @click="change_tab(4)">
                      <label class="form-title">Accounts</label>
                      <b-row>
                            <b-col sm="3">
                                <label class="modal__input-label">Start Size</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input size="sm" class="modal__input" v-model="setting.accounts.legend.start_size"></b-input>
                            </b-col>
                            <b-col sm="3">
                                <label class="modal__input-label">Start Value</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input size="sm" class="modal__input"  v-model="setting.accounts.legend.start_value"></b-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label class="modal__input-label">End Size</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input class="modal__input" size="sm" v-model="setting.accounts.legend.end_size"></b-input>
                            </b-col>
                            <b-col sm="3">
                                <label class="modal__input-label">End Value</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input class="modal__input" size="sm" v-model="setting.accounts.legend.end_value"></b-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-checkbox class="form-title mb-1" v-model="setting.accounts.visible_value">Visible Value</b-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="5">
                                <label class="form-title">Color</label>
                            </b-col>
                            <b-col sm="7">
                                <b-input v-model="setting.accounts.color" size="sm" type="color" number required></b-input>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Quality" @click="change_tab(5)">
                      <label class="form-title">Quality</label>
                      <b-row>
                            <b-col sm="6">
                                <label class="form-title">Value</label>
                            </b-col>
                            <b-col sm="6">
                                <b-select class="modal__input" size="sm" v-model="setting.quality.legend.value" :options="quality_value"></b-select>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col sm="3">
                                <label class="modal__input-label">Start Size</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input class="modal__input" size="sm" v-model="setting.quality.legend.start_size"></b-input>
                            </b-col>
                            <b-col sm="3">
                                <label class="modal__input-label">Start Value</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input class="modal__input" size="sm" v-model="setting.quality.legend.start_value"></b-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="3">
                                <label class="modal__input-label">End Size</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input class="modal__input" size="sm" v-model="setting.quality.legend.end_size"></b-input>
                            </b-col>
                            <b-col sm="3">
                                <label class="modal__input-label">End Value</label>
                            </b-col>
                            <b-col sm="3">
                                <b-input class="modal__input" size="sm" v-model="setting.quality.legend.end_value"></b-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-checkbox class="form-title mb-1" v-model="setting.quality.visible_value">Visible Value</b-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="5">
                                <label class="form-title">Color</label>
                            </b-col>
                            <b-col sm="7">
                                <b-input v-model="setting.quality.color" size="sm" type="color" number required></b-input>
                            </b-col>
                        </b-row>
                    </b-tab>
                    -->
                </b-tabs>
            </div>

            <template v-slot:modal-footer="{ ok, cancel, hide }">
              <b-row class="modal-footer-row m-0">
                <b-col sm="6">
                  <b-button block @click="$refs.modal.hide()"  class="modal-footer__button modal-footer__button-cancel">{{$t('button.cancel')}}</b-button>
                </b-col>
                <b-col sm="6">
                  <b-button block @click="handleOk()" class="modal-footer__button modal-footer__button-ok">{{$t('button.ok')}}</b-button>
                </b-col>
              </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {ConvertCoordinateExtent} from '@/gis.js'
import imageitem1 from '@/assets/images/sub/web_4/chromesetting.png'
import imageitem1_en from '@/assets/images/sub/web_4/chromesetting_eng.png'
import imageitem2 from '@/assets/images/sub/web_4/edgesetting.png'
import imageitem2_en from '@/assets/images/sub/web_4/edgesetting_eng.png'

export default {
    name: 'DlgSettings',
    props: {
    },
    data() {
        return {
            all_check: true,
            setting: null,
            curr_zoom: null,
            pressure_value: ['min', 'max', 'avg'],
            quality_value: ['turbidity', 'r_chlorine', 'fe', 'mg'],
            set_img: {ko: imageitem1,en: imageitem1_en,},
            set_img1: {ko: imageitem2,en: imageitem2_en,},
        }
    },
    computed: {
        curr_zoom_text() {
            if(this.curr_zoom===null) return 'Current Zoom'
            else return 'Current Zoom : '+this.curr_zoom
        }
    },
    created() {
    },
    mounted () {
    },
    methods: {
        check_all() {
            this.all_check = !this.all_check
            let map = this.$store.map
            for(let i=0; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                let ll = this.setting.layers.find(e => e.name === layer.get('name'))
                if(typeof(ll)==='undefined') continue
                ll.visible = this.all_check
            }
        },
        fn_press_han(event)
        {
           var objTarget = event.srcElement || event.target;
       // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');
           // alert('Please enter only numbers');
           // event.preventDefault();          
          }        
        },
        handleOk() {
            this.$refs.modal.hide();
            var f_x = Number(this.setting.wave.frequency.x)
            if(f_x >3000){
              alert("frequency x값을 3000보다 작게 입력해 주세요.")
              return

            }
            var s_size = Number(this.setting.wave.wave_energy.start_size)
            var e_size = Number(this.setting.wave.wave_energy.end_size)
            if(s_size >e_size ){
              alert("누수음 최소, 최대 사이즈를 잘못입력하였습니다.")
              return
            }
            var ps_size = Number(this.setting.pressure.legend.start_size)
            var pe_size = Number(this.setting.pressure.legend.end_size)
            if(ps_size >pe_size ){
              alert("압력 최소, 최대 사이즈를 잘못입력하였습니다.")
              return
            }
          
            //let is_reload = (this.setting.map_name!==this.$store.setting.map_name)||(this.setting.map_type!==this.$store.setting.map_type)
           //깊은복제
           //this.$store.setting = Object.assign({}, this.setting)
            this.$store.setting =  JSON.parse(JSON.stringify(this.setting));
            //this.$http.get(this.URL+'api/set_setting?m_id='+localStorage.m_id+'&json='+encodeURIComponent(JSON.stringify(this.setting)))
            //var params = {} ;
            //params["json"] =  encodeURIComponent(JSON.stringify(this.setting)) ;            

            this.$http.get(this.URL+'api/set_setting?m_id='+sessionStorage.m_id+'&json='+encodeURIComponent(JSON.stringify(this.setting)))
            //this.$http.post(this.URL+'api/set_setting?m_id='+sessionStorage.m_id,params)
            this.$EventBus.$emit('reload_map')       
        },
        show() {
            this.setting = {}
            //this.setting = Object.assign({}, this.$store.setting)
            this.setting = JSON.parse(JSON.stringify(this.$store.setting));
            if(this.setting.map_name ==="Bing"){
              this. imageitem1 = require('@/assets/images/sub/web_4/chromesetting_eng.png')
              this. imageitem2 = require('@/assets/images/sub/web_4/edgesetting_eng.png')
            }
            this.$refs.modal.show();
        },
        get_location() {
            let map = this.$store.map
            this.setting.extent = ConvertCoordinateExtent(map.getView().getProjection().getCode(), 'EPSG:4326', map.getView().calculateExtent())
        },
        get_zoom() {
            let map = this.$store.map
            this.curr_zoom = map.getView().getZoom()
        },
        set_zoom(e) {
            let map = this.$store.map
            if(e==='in') {
                map.getView().setZoom(map.getView().getZoom()+1);
            }
            else {
                map.getView().setZoom(map.getView().getZoom()-1);
            }
        },
        browser_setting_open(a){

          if(a===1)
            window.open("edge://settings/system", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
          else
            window.open("chrome://settings/system", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");    
        },
        change_tab(e) {
            this.$store.setting.tab_idx = e
            this.setting.tab_idx = e
        }
    },
    components: {
    }
}
</script>

<style lang="scss" >
.modal-dialog {
  //width: 450px !important;
  //height: 656px;
  //margin: calc(50vh - 328px) auto !important;
  .modal-header {
    position: relative;
    border-bottom: 0;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      font-size: 22px;
    }
    .modal-title {
      // 2022 new
      // text-align: center;
      font-size: 18px;
      // margin-top: 20px;
      font-weight: 500;
      font-family: 'Roboto', 'Noto Sans Korean', sans-serif;
    }
  }
  .modal-body {
    // 2022 new
    background: #172A46;
    padding: 0;
  }
  .modal-content {
    border-radius: 0;
    height: 100%;
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
      // 2022 new
      // color: #28B7CB;
      // background: #F2F2F2;
      // border-radius: 2px 2px 0 0;
      // color: #28AF81;
      color: #fff;
      background: #28AF81;
      border: 1px solid #28AF81;
      // 2023
      // border-color: transparent;
      border-color: #707070;
    }
  }
  .table-box {
    background-color: transparent;
    margin: 0;
    padding-top: 0;
  }
  .modal-footer {
    border-top: 0;
    background: #f2f2f2;
  }
  .btn-secondary.modal-content-button {
    //padding: 8px 25px 8px 10px;
    color: #fff;
    font-size: 13px;
    padding: 4px 7px;
    // background: rgba(35,41,48,0.8);
    border-color: transparent;
  }
  .modal__input-label {
    font-size: 14px;
    line-height: 32px;
    color: #616161;
    margin-bottom: 0;
  }
  .form-control.modal__input,
  .custom-select {
    border: 0;
    height: 32px;
    font-size: 14px;
    border-radius: 0;
  }
  .modal-footer-row {
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
  }
}

.btn.modal-footer__button {
  height: 52px;
  font-size: 14px;
  border: 0;
  &.modal-footer__button-ok {
    //2022 new
    // background: #28B7CB;
    background: #28AF81;
  }
  &.modal-footer__button-cancel {
    background: #9D9D9D;
  }
}
.layers-table {
  background: #fff;
  max-height: 385px;
  overflow: hidden;
  overflow-y: auto;
  .layers-table-row {
    padding: 0 15px;
  }
  .layers-table__item {
    font-size: 12px;
    color: #575757;
    font-weight: 400;
  }
  .layers-table-head {
    border-top: 1px solid #BCBCBC;
    border-bottom: 1px solid #BCBCBC;
  }
  .layers-table__item--color {
    input[type="color"].form-control {
      margin: 0 0 7px;
      padding: 0;
      height: 25px;
    }
  }
}
// 2022 new
.modal-dialog {
  // width: 450px !important;
  //height: 656px;
  //margin: calc(50vh - 328px) auto !important;
  .modal-header {
    position: relative;
    border-bottom: 0;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      font-size: 22px;
    }
    .modal-title {
      // 2022 new
      // text-align: center;
      font-size: 18px;
      // margin-top: 20px;
      font-weight: 500;
      font-family: 'Roboto', 'Noto Sans Korean', sans-serif;
    }
  }
  .modal-body {
    // 2022 new
    background: #172A46;
    padding: 0;
  }
  .modal-content {
    border-radius: 0;
    height: 100%;
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
      // 2022 new
      // color: #28B7CB;
      // background: #F2F2F2;
      // border-radius: 2px 2px 0 0;
      // color: #28AF81;
      color: #fff;
      background: #28AF81;
      border: 1px solid #28AF81;
      border-color: transparent;
    }
  }
  .table-box {
    background-color: transparent;
    margin: 0;
    padding-top: 0;
  }
  .modal-footer {
    border-top: 0;
    background: #0A192F;
  }
  .btn-secondary.modal-content-button {
    //padding: 8px 25px 8px 10px;
    color: #fff;
    font-size: 13px;
    padding: 4px 7px;
    // background: rgba(35,41,48,0.8);
    border-color: transparent;
  }
  .modal__input-label {
    font-size: 14px;
    line-height: 32px;
    color: #fff;
    margin-bottom: 0;
  }
  .form-control.modal__input,
  .custom-select {
    border: 0;
    height: 32px;
    font-size: 14px;
    border-radius: 0;
    background: #0A192F;
    color: #fff;
    border: 1px solid #707070;
  }
  .modal-footer-row {
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
  }
}

.btn.modal-footer__button {
  height: 52px;
  font-size: 14px;
  border: 0;
  &.modal-footer__button-ok {
    //2022 new
    // background: #28B7CB;
    background: #28AF81;
  }
  &.modal-footer__button-cancel {
    background: #9D9D9D;
  }
}
.layers-table {
  background: #172A46;
  max-height: 385px;
  overflow: hidden;
  overflow-y: auto;
  .layers-table-row {
    padding: 0 15px;
  }
  .layers-table__item {
    font-size: 12px;
    color: #fff;
    font-weight: 400;
  }
  .layers-table-head {
    background: #28AF81;
    // border-top: 1px solid #BCBCBC;
    // border-bottom: 1px solid #BCBCBC;
  }
  .layers-table__item--color {
    input[type="color"].form-control {
      margin: 0 0 7px;
      padding: 0;
      height: 25px;
    }
  }
}
.layers-table-row{
  border-bottom: 1px solid #707070;
  line-height: 30px;
}
.custom-control-label {
    font-size: 14px;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
    margin-bottom: 0;
}
</style>

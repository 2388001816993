import { render, staticRenderFns } from "./LeakDataSetDetail.vue?vue&type=template&id=3679cec4&scoped=true"
import script from "./LeakDataSetDetail.vue?vue&type=script&lang=js"
export * from "./LeakDataSetDetail.vue?vue&type=script&lang=js"
import style0 from "./LeakDataSetDetail.vue?vue&type=style&index=0&id=3679cec4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3679cec4",
  null
  
)

export default component.exports
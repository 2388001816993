<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                   <li>
                     <ol class="breadcrumb with--map">
                       <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>                      
                       <li class="breadcrumb-item active">Event Log</li>
                     </ol>
                   </li>
                 
           
              </ul>
         </div>
     <!-- tom-bar-end -->
    

        <div class="right_area_01 right_area_block-detail">
          <b-row style="margin-left: 10px;">
            <!--
              <b-col >
                  <b-row style="margin: 0;">
                      <b-col style="padding: 0;">
                        <div class="specification_area" style=" background-color:white; padding: 20px 30px 10px 10px;height:810px;">
                              <div class="log_box" >
                                <span class="title_value">CUSTOMER</span>
                              </div>
                          
                                
                              <div style="background-color:white; padding: 20px 5px;">
                                <vue-good-table ref="table_customer"
                                    :columns="columns1"
                                    :rows="table_customer"
                                    :line-numbers="true"
                                     :max-height="contents_height+ 50 +'px'"   
                                    :fixed-header="true"
                                    styleClass="vgt-table condensed"
                                    @on-row-click="onRowClick"
                                      
                                 />
                              </div>
                                                  
                          </div>
                      </b-col>
                  </b-row>

              </b-col>
             --> 
              <b-col cols="12" style="margin: 0 10px 10px 0;background-color: #172A46;height:calc(90vh - 20px);" >
                <div style="position:absolute; top: -50px; right: 0px; width:350px;padding:10px;">
                    <!--span  >SERVER NAME : <b-select  size="sm" v-model="server_id" :options="server_name" style="width:200px;"></b-select>
                    </span-->
                </div>
                <div class="log_box" style="padding: 15px 5px 10px 20px; ">
                  <h5 style="color:#fff;" >Event Log</h5>
                </div>
                <div style="position:absolute; top: 20px; right: 30px; padding:10px;background:#f2f2f;">
                    <span style="padding: 10px;"  >Server : <b-select  size="sm" v-model="server_host" :options="server_name" style="width:150px;" ></b-select>
                    </span>

                    <!--span style="padding: 10px;"  >DEVICE TYPE : <b-select  size="sm" v-model="device_type" :options="device_type_name" style="width:120px;"></b-select>
                    </span-->
                 
                </div>
                <!-- <div style="background-color:white; padding: 20px 10px;"> -->
                <div style="padding: 0px 10px;">
                
                    <!--ServerSideTable ref="table_event" 
                                    :line_num ="false"
                                   :fixed-header="true"                                   
                                   :columns="columns"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"   
                                   :add_custom_column="true"   
                                   :adv_search="adv_search"                             
                                   :select="true"  
                                   :search="true"                                
                                   :max_height="contents_height-40 +'px'"  
                                    @on-row-click="onRowClick"  
                                    @on_info_click="on_info_click"                            
                              
                  /-->
     <span>Total: {{totalRecords}}</span>             
     <vue-good-table ref="table_event"
                    mode="remote"
                    @on-row-click="onRowClick"                
                    styleClass="vgt-table without--head-right-line type01"
                    :globalSearch="true"
                    :line-numbers="false"                   
                    :select-options="{ enabled: false, selectOnCheckboxOnly: true, disableSelectInfo: true }"
                    :rows="table_rows"
                
                    :columns="columns"
                    :totalRows="totalRecords"
                    :adv_search="adv_search"
                    :fixed-header="true"
                    :max-height="contents_height-40 +'px'"
                
                    >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.row[props.column.field] == 0  ">
                        <!-- 2022 new -->
                        <!-- <img src="../assets/images/Common/table/map_icon.png" alt="map"> -->
                         <div ></div> 
                     </span>
                    
                     <span v-else-if="props.column.field == 'iconNew'  && props.row.isNew === 1" class="table_icon" @click="$emit('on_goto_map_click', props.row)">
                        <!-- 2022 new -->
                        <!-- <img src="../assets/images/Common/table/map_icon.png" alt="map"> -->
                        <img src="@/assets/images/new/N.png" style="height:25px;" alt="map">
                    </span>
                    <span v-else-if="props.column.field == 'info'" class="table_icon" @click="on_info_click(props.row)">
                        <!-- 2022 new -->
                        <!-- <img src="../assets/images/Common/table/info_icon.png" alt="map"> -->
                        <img src="@/assets/images/new/ic_list_info.svg" style="height:20px;" alt="info" >
                    </span>
                    <!--span v-else-if="props.column.field == 'sound_lasttime'  && props.row.sound_lasttime > base_date ">
                            <div style=" color: #ff8282;">{{props.row.sound_lasttime}}</div> 
                    </span>
                    <span v-else-if="props.column.field == 'leak_logger_lasttime'  && props.row.leak_logger_lasttime > base_date ">
                            <div style=" color: #ff8282;">{{props.row.leak_logger_lasttime}}</div> 
                    </span>                    
                    <span v-else-if="props.column.field == 'pressure_lasttime'  && props.row.pressure_lasttime > base_date ">
                            <div style=" color: #ff8282;">{{props.row.pressure_lasttime}}</div> 
                    </span>
                    <span v-else-if="props.column.field == 'flow_lasttime'  && props.row.flow_lasttime > base_date ">
                            <div style=" color: #ff8282;">{{props.row.flow_lasttime}}</div> 
                    </span-->
                    <span v-else-if="props.column.field.indexOf('lasttime') > 0  && props.formattedRow[props.column.field]> base_date ">
                            <div style=" color: #ff8282;">{{props.formattedRow[props.column.field]}}</div> 
                    </span>
                    <span v-else-if="props.column.field.indexOf('event') > 0 ">
                            <div style=" color: #ff8282;"> {{props.formattedRow[props.column.field]}}</div> 
                    </span>
                     <span v-else  >
                        <div > {{props.formattedRow[props.column.field]}}</div> 
                     </span>
                    </template>
                </vue-good-table>                  
                 </div>
              </b-col>
            

          </b-row>
        </div>
         <DlgCustomerInfo ref="dlg_customer_info"/>
    </div>
</template>

<script>



//import PressMinChart from '@/components/PressMinChart' ;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import DlgCustomerInfo from '@/components/popup/DlgCustomerInfo';

export default {
    name: 'LogHistory',
    props: [
    ],
    watch: {
        server_host() {      
            this.go_search();             
        },
        device_type(){
          this.go_search();
        }
    },
    data() {
        return {
            base_date : null,
            properties: [],
            properties_bak: [],
            table_rows :[],
            new_show : true,
            searchTerm: '',
            i_device_type :"",
            device_sn1:"",
            device_sn2:"",
            contents_height: 600,
            mnfchart_height: 300,
            realtimechart_height: 300,
            tag : this.$route.query.tag,
            tag_name : '',
            selected_ts_flow: '1',
            flow_date: '2020-11-01',
            loaded_ts_flow: false,
            selected_ts_mnf: '1',
            server_host : 'https://kr.neverlosewater.com/node/api/',
           // server_host : 'https://dev.neverlosewater.com/node/api/',
            mnf_date: '2020-11-01',
            columns: [       
                {label: 'ID', field: 'id'},        
                {label: 'Name', field: 'name'},         
                {label: 'customer_no', field: 'customer_no'},   
                {label: 'Info', field: 'info'},         
                {label: 'Is_new', field: 'iconNew'},             
                {label: 'Sound Cnt.', field: 'sound_count'},             
                {label: 'Sound Lasttime', field: 'sound_lasttime', width: '7%'},
                {label: 'Leak_Logger Cnt.', field: 'leak_logger_count'},
                {label: 'Leak_Logger Lasttime', field: 'leak_logger_lasttime'},
                {label: 'Leak_moni. Cnt.', field: 'leak_monitoring_cnt'},
                {label: 'Leak_moni. event', field: 'leak_monitoring_event'},
                {label: 'Pressure Cnt.', field: 'pressure_count'},
                {label: 'Pressure Lasttime', field: 'pressure_lasttime'},
                {label: 'Pressure_moni. Cnt', field: 'pressure_monitoring_cnt'},
                {label: 'Pressure_moni. event', field: 'pressure_monitoring_event'},
                {label: 'Flow Count', field: 'flow_count'},
                {label: 'Flow Lasttime', field: 'flow_lasttime'},
                {label: 'Flow Cnt', field: 'flow_monitoring_cnt'},
                {label: 'Flow_moni. event', field: 'flow_monitoring_event'},
                
            ],    
            columns1: [
                {label: 'Name', field: 'name'},             
                {label: 'Code', field: 'customer_no'},
                {label: 'License', field: 'sw_license_type'},
                {label: 'R_Date', field: 'register_datetime'},
                {label: 'S_Date', field: 'service_start_date'},
                {label: 'E_Date', field: 'service_end_date'},
            ],            
             f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            device_option: [
                { value: 'SM1', text: 'SM1' },
                { value: 'SM2', text: 'SM2' },
                { value: 'ILOG', text: 'ILogger' },
            ],            
            table_customer: [],
            device_type:'',
            s_customer_no:'',
            table_event: [],
            ts_mnf: [],
            activeCheckIndex: null,           
            device_type_name : [{value:'', text :"ALL"},
                                {value:'SM1', text :"SM1"},
                                {value:'SM2', text :"SM2"},
                                {value:'ILOG', text :"I-LOGER"},
                               ],                                
            customer_name : [{value:'', text :"ALL"},
                            ], 
            server_name : [],                            
            i_customer_name : [],      
            i_customer_id : [],                                                                    
            server_id : 1,
            clicked_l_idx: -1,
            adv_search: [
            {
              field: 'leak_result',
              control: 'select',
              value: null,
              //'Leak','Leak Inside','Usage','Other'
              options: [
                  { value: null, text: 'Leak Result' },
                  { value: 'RT_LEAK', text: 'Leak' },
                  { value: 'NO_LEAK', text: 'No Leak' },
                
              ]
          },
            {field: 'measure_datetime', control: 'date', value: null, value2: null, placeholder: 'From measure_datetime', placeholder2: 'To measure_datetime'},
      ],
      totalRecords:0,
        }
    },
    methods: {
      getToday_7before(){
          var date = new Date();
          date.setDate(date.getDate()-7)
          var year = date.getFullYear();
          var month = ("0" + (1 + date.getMonth())).slice(-2);
          var day = ("0" + (date.getDate())).slice(-2);

          return year + "-" + month + "-" + day;
      },
      goRouter(path) {
        this.$router.push({path: path, query: { tag: this.tag }})
      },
      getSelectedItem(){
          //this.i_device_type = this.properties['device_type'] 
          if(this.properties['device_type'] ==='ILOG'  ){
            this.i_device_type = 'SM2';
            this.device_sn1 = 'PL4';
          }else if(this.properties['device_type'] ==='SM1'){           
            this.i_device_type = 'SM1';
            this.device_sn1 = 'V3A';
          }else{
            this.i_device_type = 'SM2';
            this.device_sn1 = 'V3A';
          }
      },
      on_info_click(row) {
        this.$refs.dlg_customer_info.show(row,this.server_host);
      },
      onRowClick(params) {
        //선택된 색상 보여지게
         this.new_show =true;
        if(this.clicked_b_row != null){
          this.clicked_b_row.bgColor = '';
        //  this.clicked_b_row.style.backgroundColor = '';
        }
      
        if(params.event.target.nodeName==='TD'){
            params.event.target.parentElement.bgColor  = '0e5340';          
            this.clicked_b_row = params.event.target.parentElement ;
        }else  if(params.event.target.nodeName==='IMG'){
            params.event.target.parentElement.parentElement.parentElement.bgColor = '0e5340';      
            this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
        }else{
            params.event.target.parentElement.parentElement.bgColor = '0e5340';      
            this.clicked_b_row = params.event.target.parentElement.parentElement ;
        }
  
        this.properties = params.row ;
        this.properties_bak = Object.assign({}, this.properties) ;
        
       
    },
    new_device(){
      //새로운 장비 등록
      this.new_show =false;
      this.properties = [] ;
      this.properties['customer_no'] = '082-042-000';
      this.properties['device_type'] = 'SM2' ;
      this.i_device_type = 'SM2' ;
      this.device_sn1 = 'V3A';
      this.device_sn2 = '';
      this.properties['device_status'] = 'A' ;
    },
    apply(){
      let params = {} ; 
      var data = {} ;
      let keys = Object.keys(this.properties_bak)
      if(this.properties['customer_no'] === undefined || this.device_sn1.length < 1 || this.device_sn2.length < 1 || this.properties['device_type'] === undefined) {
        alert("고객사선택, 유일한 serial no가 필수 입력 사항입니다.")
        return
      }
      if(!this.new_show){ //신규등록일시
        for(var i = 0 ; i < this.i_customer_name.length ; ++i ){
          var c_no =this.i_customer_name[i].value ;
          if(c_no === this.properties['customer_no']){
            this.properties['customer_id'] = this.i_customer_name[i].id;
            params['customer_id'] = this.i_customer_name[i].id;
          }
        }
        this.properties['device_sn'] = this.i_device_type+"-"+ this.device_sn1 +"-"+ this.device_sn2
        keys = Object.keys(this.properties)
        for(let i=0; i<keys.length; i++) {
         // if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
          if(keys[i] === 'customer_no' || keys[i] === 'customer_name') continue
          params[keys[i]] = this.properties[keys[i]]
        }       
        data = {type: 'add', params:params} ;
      }else{     
        for(let j=0; j<keys.length; j++) {
          if(this.properties_bak[keys[j]]===this.properties[keys[j]]) continue
          if(keys[j] === 'customer_no' ){
            for(var ii = 0 ; ii < this.i_customer_name.length ; ++ii ){
              var c_no1 =this.i_customer_name[ii].value ;
              if(c_no1 === this.properties['customer_no']){
                this.properties['customer_id'] = this.i_customer_name[ii].id;
                params['customer_id'] = this.i_customer_name[ii].id;                
              }
            }
            continue         
          } 
          if( keys[j] === 'customer_name') continue 
          params[keys[j]] = this.properties[keys[j]]
        }           
        data = {type: 'modify',id:this.properties["id"], params:params} ;
      }
      if(Object.keys(params).length<=0) return
       this.$http.get(this.URL+'api/add_modify_device?json='+encodeURIComponent(JSON.stringify(data))).then((res) => {
          console.log(res.data);
          if(res.data.check ===1){
            alert("fail : "+ res.data.msg) ;
          }else{
            this.go_search();
          }
      })
    },
    
    go_search() {
       //if(value===undefined) return;
                //left메뉴 데이터를 뿌려준다.
       //},
        this.new_show =true;
        this.table_event = []; 
        //var pai_url = this.server_host
       // this.$refs.table_event.setUrl(pai_url+"get_all_customer_list", (rows1) => {
        this.$http.get(this.server_host+'get_all_customer_list').then((res) => {   
             console.log(res)
          var table_list = []
          var table_list1 = []
          //const con_arr={}
          if((!res)||(res.length<=0)) return;
          this.totalRecords = res.data.list.length
          table_list = res.data.list          
        // this.clicked_l_idx = rows1.id
        //  this.properties =  rows1[0];
        //  this.properties_bak = Object.assign({}, this.properties)
           this.$http.get(this.server_host+'event/table').then((res1) => {
              if((!res1)||(res1.data.result<=0)) {
                  this.table_rows = table_list
              }else{
                table_list1 = res1.data.result    
                for(let i=0; i<table_list.length; i++) {
                  for(let j=0; j<table_list1.length; j++) {
                    if(table_list[i].id == table_list1[j].customer_id  ){
                       const con_arr = Object.assign(table_list[i],table_list1[j])
                       table_list[i] = con_arr
                       break;
                    }
                  }  
                  
                }
                this.table_rows = table_list
              }
            });
        })
       
      },
    },
    mounted () {
       this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175
      
       this.customer_name = [];    
       this.i_customer_name = [];     
       this.i_customer_id = [];     
       this.server_name = []         
       this.base_date = this.getToday_7before() ;
      // alert( this.base_date)
        this.$http.get(this.server_host+'get_server_list').then((rows) => {
          if((!rows)||(rows.data.result<=0)) return;
          for(let i=0; i<rows.data.result.length; i++) {
            this.server_name.push({value:rows.data.result[i].server_host, text :rows.data.result[i].server_name}) ;
          }          
        })
        this.$http.get(this.server_host+'get_all_customer_list').then((res) => {   
       // this.$refs.table_event.setUrl(this.server_host+"get_all_customer_list", (rows1) => {
          console.log(res)
          var table_list = []
          var table_list1 = []
          //const con_arr={}
          if((!res)||(res.length<=0)) return;
          this.totalRecords = res.data.list.length
          table_list = res.data.list          
        // this.clicked_l_idx = rows1.id
        //  this.properties =  rows1[0];
        //  this.properties_bak = Object.assign({}, this.properties)
           this.$http.get(this.server_host+'event/table').then((res1) => {
              if((!res1)||(res1.data.result<=0)) {
                  this.table_rows = table_list
              }else{
                table_list1 = res1.data.result    
                for(let i=0; i<table_list.length; i++) {
                  for(let j=0; j<table_list1.length; j++) {
                    if(table_list[i].id == table_list1[j].customer_id  ){
                       const con_arr = Object.assign(table_list[i],table_list1[j])
                       table_list[i] = con_arr
                       break;
                    }
                  }  
                  
                }
                this.table_rows = table_list
              }
            });
        });
       //left메뉴 데이터를 뿌려준다.
       this.box = [] ;
       var select_id= 0 ;

       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
               
               if(select_id>-1){
                  //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
               //   this.updateCheckIndex(select_id)
               }
           }
         }
       }, 500)
   
    },
    beforeDestroy () {
    },
    components: {  
    //  ServerSideTable: ServerSideTable,
      DlgCustomerInfo:DlgCustomerInfo,
    }
}
</script>

<style lang="scss">

.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // background: #fff;
  margin-bottom: 10px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}

//.specification_btn .btn-group-toggle .btn-group-toggle {
  //margin-left: 5px;
//}
.specification_btn .btn-secondary{
  float: left;
  width: 42px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  background: rgba(35,41,47,0.4);
  font-size: 14px;
  margin-right: .2em;
  cursor: pointer;
  border: 0;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background: rgba(35,41,47,0.8);
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}


.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 735px;
  overflow-y: scroll;
}
table.vgt-table.condensed td {
      padding: .188rem .15rem;
}
.btn_pre{
 position: absolute;
    top: 0;
    right: 110px;
   // background: #4C5055;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
 }
 .map-button.icon_btn.btn.btn-sm{
   padding-top: 4px;
 }
 .search_box.device {
    position: absolute;
    top: 58px;
    left: 30px;
    width:30%;
}
</style>

<template>
  <div>
   <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                    
    <scadaChart refclass="hchart" ref="chart"
      v-bind:chartData_1="chartData_1"  v-bind:chartData_2="chartData_2" v-bind:chartData_3="chartData_3"  v-bind:chartData_4="chartData_4" 
      v-bind:chartData1_1="chartData1_1"  v-bind:chartData1_2="chartData1_2" v-bind:chartData1_3="chartData1_3"  v-bind:chartData1_4="chartData1_4" 
      v-bind:y_min1 ="y_min1"  v-bind:y_max1 ="y_max1" v-bind:y_min2 ="y_min2"  v-bind:y_max2 ="y_max2"  v-if="loaded"    v-bind:chart_type ="chart_type"   v-bind:vchart ="vchart" 
      v-bind:y_label_1 = "y_label_1" v-bind:y_label_2 = "y_label_2" v-bind:y_label_3 = "y_label_3" v-bind:y_label_4 = "y_label_4"
      v-bind:y_label1_1 = "y_label1_1" v-bind:y_label1_2 = "y_label1_2" v-bind:y_label1_3 = "y_label1_3" v-bind:y_label1_4 = "y_label1_4"
      v-bind:y_title = "y_title" v-bind:y_title1 = "y_title1" v-bind:x_title = "x_title" />
  </div>
</template>

<script>
import scadaChart from '@/components/chart/scadaChart' ;
import * as d3 from 'd3'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'ChartData1',
    props: [
        'height',
        'url'
    ],
  watch: {
        chartData_1: function() { //series 1,

              //   deep: true,
                 //  this.redraw();
                // handler(){this.renderChart(this.chartData, this.options); }
        },
        call_csv_index(){
          if(this.call_csv_index === 0) return
          if(this.call_csv_index >0 && this.call_csv_index < this.yseries1_id.length)
            this.call_csv_file(this.call_csv_index,1);  
          else
            this.call_csv_file(0,2);  
        },
        call_csv1_index(){   
          if(this.call_csv1_index === 0) return       
          if(this.call_csv1_index >0 && this.call_csv1_index <this.yseries2_id.length )
           this.call_csv_file(this.call_csv1_index,2);       
          else  this.redraw();              

        },

        url() {
          if(this.url==='') return
          this.loaded=false;
          this.loaded_ts_flow=false;
          this.y_label_1 = null
          this.y_label_2 = null
          this.y_label_3 = null
          this.y_label_4 = null
          this.y_label1_1 = null
          this.y_label1_2 = null
          this.y_label1_3 = null
          this.y_label1_4 = null

          if(this.yseries1 !== null && this.yseries1 !== undefined ){
              var series_name =this.yseries1.split('/')
              //
              var size = series_name.length-1
              for(var i=0 ; i < size ; ++i){
                if(i=== 0)       this.y_label_1 = series_name[i]
                else if(i === 1) this.y_label_2 = series_name[i]
                else if(i === 2) this.y_label_3 = series_name[i]
                else if(i === 3) this.y_label_4 = series_name[i]
              }
          }

          if(this.yseries2 !== null && this.yseries2 !== undefined ){
            var series_name1 =this.yseries2.split('/')
            //
            var size1 = series_name1.length-1
            for(i=0 ; i < size1 ; ++i){
              if(i=== 0)       this.y_label1_1 = series_name1[i]
              else if(i === 1) this.y_label1_2 = series_name1[i]
              else if(i === 2) this.y_label1_3 = series_name1[i]
              else if(i === 3) this.y_label1_4 = series_name1[i]
            }
          }
          

         this.chartData_1 = [] ;//주측 series1
         this.chartData_2= [] ;//주측 series2
         this.chartData_3= [] ;//주측 series3
         this.chartData_4 = [] ; //주측 series4
         this.chartData1_1  = [] ; //보조측 series1
         this.chartData1_2= [] ; //보조측 series1
         this.chartData1_3= [] ; //보조측 series1
         this.chartData1_4= [] ; //보조측 series1
         this.call_csv_index = 0
         this.call_csv1_index = 0
         if(this.yseries1_id.length == 1 && this.yseries2_id.length == 1 && this.chart_type === "scatter"){
            this.call_scatter_csv_file();
         }else{
            this.call_csv_file(0,1);
         }

        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        call_csv_index : 0,
        call_csv1_index : 0,
        loaded_ts_flow: true,
        chartData:  [],
        interval : 100,
        sdate: Date.UTC(2020, 0, 1,0,0,0,0),
        loaded: false,
        bar_idx:-1,
        ytitle: 'pressure',
        fixed : 0 , //0 : false, 1: fixed
        min : null,
        param:{},
        yseries1: null, //주측 이름
        yseries2: null, //보조측 이름
        yseries1_id : [],
        yseries2_id : [],
        y_title : null,
        y_title1 : null,
        x_title : null,
        vchart : 1, //1:비율, 0:고정
        chart_type : "", //column, scatter,""
        chartData_1: [],//주측 series1
        chartData_2: [],//주측 series2
        chartData_3: [],//주측 series3
        chartData_4: [], //주측 series4
        chartData1_1: [], //보조측 series1
        chartData1_2: [], //보조측 series1
        chartData1_3: [], //보조측 series1
        chartData1_4: [], //보조측 series1
        x_min1 : null,
        x_max1 : null,
        y_min1 : null,
        y_max1 : null,
        y_min2 : null,
        y_max2 : null,
        y_label_1: null, //주측 series 1 name
        y_label_2: null, //주측 series 2
        y_label_3: null, //주측 series 3
        y_label_4: null, //주측 series 4
        y_label1_1: null, //보조측 series1 1
        y_label1_2: null, //보조측 series1 2
        y_label1_3: null, //보조측 series1 3
        y_label1_4: null, //보조측 series1 4

      }
    },
    methods: {
        call_scatter_csv_file(){ //a :index, y :1 주측, y:2 보조측 
         this.x_title = "";
         var csv_url = this.URL+'api/scada/get_csv_file_chart?id='+this.yseries1_id[0]
         var csv_url1 = this.URL+'api/scada/get_csv_file_chart?id='+this.yseries2_id[0]
 
         
         this.$http.get(csv_url).then((res) => {
            //데이터 셋팅
              var b_data = res.data;           
             if( b_data.check !==1 && b_data != null) {
                this.$http.get(csv_url1).then((res1) => {
                   var b_data1 = res1.data; 
                  if( b_data1.check !==1 && b_data1 != null) {
                    
                     this.chartData_1  = this.make_data1(b_data,b_data1)
                     console.log(this.chartData_1)
                     this.redraw();    
                  }else{                
                     this.chartData_1 =  this.make_data(b_data)           
                     this.loaded_ts_flow=true;
                  }
               
                });
                 
             }else{                
                 this.chartData_1 = [] ;            
                 this.loaded_ts_flow=true;
             }
          
          })
          .catch((error) => {
             this.chartData_1 = [] ;            
             this.loaded_ts_flow=true;
             console.log(error)
          });
       },
       call_csv_file(a, y){ //a :index, y :1 주측, y:2 보조측 
         
         var csv_url = this.URL+'api/scada/get_csv_file_chart?id='+this.yseries1_id[a]
         
         if(y === 2){
            if(this.yseries2_id.length > 0 && a < this.yseries2_id.length){
              
              csv_url = this.URL+'api/scada/get_csv_file_chart?id='+this.yseries2_id[a]
            }else{
              this.redraw();
              return
            }            
         }
         this.$http.get(csv_url).then((res) => {
            //데이터 셋팅
              var b_data = res.data;           
             if( b_data.check !==1 && b_data != null) {
               if(a === 0){
                  if(y === 1) this.chartData_1  = this.make_data(b_data)    
                  else this.chartData1_1  = this.make_data(b_data)    
               }else if(a === 1){
                 if(y === 1) this.chartData_2  = this.make_data(b_data)    
                  else this.chartData1_2  = this.make_data(b_data)    
               }else if(a === 2){
                 if(y === 1) this.chartData_3  = this.make_data(b_data)    
                  else this.chartData1_3  = this.make_data(b_data)    
               }else if(a === 3){
                 if(y === 1) this.chartData_4  = this.make_data(b_data)    
                  else this.chartData1_4  = this.make_data(b_data)    
               }
                 
             }else{                
                 this.chartData_1 = [] ;            
                 this.loaded_ts_flow=true;
             }
             if(y === 1)  ++ this.call_csv_index
             else  ++ this.call_csv1_index
          })
          .catch((error) => {
             this.chartData_1 = [] ;            
             this.loaded_ts_flow=true;
             console.log(error)
          });
       },
       make_data(b_data){
            var parseTime = d3.timeParse("%Y-%m-%d %H:%M");
            var size = b_data.length 
            var ccdata = []
              var ssdate1 = null
              var sdate1 = null
              var value = null 
              //var avg = null
              for(let i=0; i < size ; ++i){
              
                    ssdate1 = parseTime(b_data[i][0]);
                    sdate1 = Date.UTC(ssdate1.getFullYear(), ssdate1.getMonth(),  ssdate1.getDate(),ssdate1.getHours(),ssdate1.getMinutes(),ssdate1.getSeconds());
                    
                    value = Number(b_data[i][1])
                     if(this.vchart === "0" && this.x_min1 > '2000-00-00' && this.x_max1 > '2000-00-00'  ){
                         if(this.x_min1<b_data[i][0] && this.x_max1>b_data[i][0] )
                            ccdata.push([sdate1,value]);  
                     }else{
                        ccdata.push([sdate1,value]);      
                     }

              }            
                              
            return ccdata
       },
       make_data1(b_data, b_data1){
            var parseTime = d3.timeParse("%Y-%m-%d %H:%M");
            var size = b_data.length 
            var size1 = b_data1.length 
            var ccdata = []
              var ssdate1 = null
            //  var sdate1 = null
              var value = null 

              var ssdate1_1 = null
             // var sdate1_1 = null
              var value1 = null
              //var avg = null
              var next = 0
              for(let i=0; i < size ; ++i){
              
                    ssdate1 = parseTime(b_data[i][0]);
                //    sdate1 = Date.UTC(ssdate1.getFullYear(), ssdate1.getMonth(),  ssdate1.getDate(),ssdate1.getHours(),ssdate1.getMinutes(),ssdate1.getSeconds());
                    
                    value = Number(b_data[i][1])
                    
                    for(let j=next; j < size1 ; ++j){

                        ssdate1_1 = parseTime(b_data1[j][0]);
                        if(ssdate1.getTime()  === ssdate1_1.getTime() ){
                                                    
                           value1 = Number(b_data1[j][1])
                           if(this.vchart === "0"  && this.x_min1 !== this.x_max1 ){
                             if(this.x_min1<value1 && this.x_max1>value1 )
                                ccdata.push([value1,value]);  
                           }else{
                               ccdata.push([value1,value]);  
                           }
                           next = j ;
                           break;
                        }
                    }    
              }            
                              
            return ccdata
       },
       redraw () {

              this.loaded = true;
              this.loaded_ts_flow = true      
              this.call_csv_index = 0
              this.call_csv1_index = 0 
           // this.update();
           // this.$data._chart.update()
           // this.chartData =  cData ;
           //   this.$refs.chart.update();

      },
    
  
  },
  mounted () {
        window.addEventListener('resize', this.resize);

       // if(this.url==='') return

         //this.$http.get(this.url).then((res) => {
            //데이터 셋팅
         //    if( res.data !=="" && res.data != null) {
         //       this.chartData = res.data.data;
                /*
                for(let i=0; i < b_data.length ; ++i){
                  this.xdata.push(b_data[i].w_title);
                  //this.chartData1.push(b_data[i].leak_count) ;
                  //this.chartData2.push(b_data[i].leak_inside) ;
                  this.chartData1.push(10) ;
                  this.chartData2.push(20) ;
                  this.chartData3.push(b_data[i].other_count) ;

                }
                */
      //       }else{
      //            this.xdata = [];
                 
      //       }
      //    });


    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    },

    components: {
        PulseLoader: PulseLoader,
        scadaChart:scadaChart,
    }
}
</script>
<style  scoped>

.wavchart .highcharts-container .highcharts-root {
    width: 98%;    
    margin: 0 auto;
}


</style>
<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
<!--                        <li class="breadcrumb-title">Map Frequency Comparison</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/leak-master')" href="#">{{$t('water_leak.page_title')}}</a></li>                   
                        <li class="breadcrumb-item active">{{$t('leak_analysis.page_title')}}</li>
                    </ol>
                </li>
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div>
             <b-row style="margin: 0;">
                <b-col :cols="msize" style="padding: 0 10px 0 0;">
                    <div class="search_div">

                        <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="value"  @keyup.enter ="Enter_Check"/></span>
                        <span ><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="go_search" style="margin-right:5px;">{{$t('button.search')}}</button></span>
                        <b-button class="mr-2 map-button icon_btn" size="sm" @click="show_validation"  v-b-tooltip.hover :title="$t('map.btn_check')">                            
                          <i class="mdi mdi-check"/>                          
                        </b-button>
                         <b-button class="mr-2 map-button icon_btn" size="sm" @click="go_validation_check(2)" v-b-tooltip.hover :title="$t('map.btn_update_check')">                            
                          <i class="mdi mdi-check-all"/>                          
                        </b-button>
                        <b-button v-show = "dlg_show" class="mr-2 map-button icon_btn" size="sm" @click="leak_work_check(dlg_show)" v-b-tooltip.hover title="Guide">                            
                          <i class="mdi mdi-pin"/>                          
                        </b-button>
                        <b-button v-show = "!dlg_show" class="mr-2 map-button icon_btn" size="sm" @click="leak_work_check(dlg_show)" v-b-tooltip.hover title="Guide off">                            
                          <i class="mdi mdi-pin-off"/>                          
                        </b-button>                        
                        <b-button v-show = "work_block_i" class="mr-2 map-button icon_btn" size="sm" @click="work_block_show(1)" v-b-tooltip.hover title="Work_block">                            
                          
                          <i class="mdi mdi-selection"/>                     
                        </b-button>
                        <b-button v-show = "!work_block_i" class="mr-2 map-button icon_btn" size="sm" @click="work_block_show(2)" v-b-tooltip.hover title="Work_block off">                            
                          <i class="mdi mdi-selection-off"/>                          
                        </b-button>                        

                        <div class="set_validation" v-show="validation_setting_visible"  >
                            <span class="spn1" >
                            <span  ><label style="padding: 0 10px;"> Minimum value : </label>
                                 <input style="width:50px;text-align:center;color: #000;" type="text" placeholder="1.5" v-model="min_value"                                 
                                v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                            </span>
                            <span ><label style="padding: 0 10px;">distance(m) : </label>
                                 <input style="width:50px;text-align:center;color: #000;" type="text" placeholder="20" v-model="distance"                                 
                                onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                            </span>
            
                            <span style="float:right; margin-left: 10px;">
                                <b-button class="mr-2 map-button icon_btn" size="sm" @click="go_validation_check(1)"  v-b-tooltip.hover :title="$t('map.btn_check')">                            
                                    <i class="mdi mdi-magnify"/>                          
                                </b-button>
                            </span>
                            </span>
                        </div>
                    </div>
                    <div style=" margin : 0px; padding-right: -5px; " >
                    <div style="overflow-y: scroll;" :style="{height: contents_height+'px'}">

                     <Map
                         ref="map"
                         :widx="$route.query.widx"
                         w_type="100"
                         :visible_gisload_button="true"
                         :visible_size_side_view_button="true"
                         :leak_l ="true"
                        @get_gisinfo="get_gisinfo" 
                        @set_map_gisload="set_map_gisload"
                        @set_info="set_info"
                        @toggle_visible_all_layer = "toggle_visible_all_layer"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @toggle_visible_leak_type = "toggle_visible_leak_type"
                        @size_view="size_view"
                        @onLeakMapClick="onLeakMapClick()"
                        @gis_reload = "gis_reload"   
                     />
                      </div>
                     </div>
                 </b-col>
                 <b-col  v-if="v_chart" :cols="tsize" style=" padding: 0px 2px 0 0; ">
                   <b-row style=" padding-left: 0px;background-color: #172a46; margin: 0px 10px 0 -5px; ">
                       <b-col style="padding-top: 5px; padding-bottom: 5px;">

                           <div  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                           <div style=" position:absolute; top : 0px; left:0px;z-index:102; "><!--div style="font-size:10pt;"> {{lidx1}} </div-->
                              <div><img src="@/assets/images/new/ic_list_info.svg" alt="map"  style="width:70%; " @click="on_leakage_info(lidx1)" /></div>
                              <div><img src="@/assets/images/new/ic_table_map.svg" alt="map"  style="width: 60%; " @click="on_goto_map_click(lidx1,w_idx1)" /></div>
                           </div>
         
                            <div style=" position: relative;left:32%;width:53%;text-align:center;">
                                <div style="float:left;">
                                
                                    <span style="position:absolute;left:10px;margin-top: -10px;">{{band_hz1}}  </span>
                                    <span style="float: right;padding-top:15px;">
                                    <!--b-input v-model="szoom1"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                    type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                    v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" /-->
                                      <b-select  class="facility__input" style="margin-top:-5px;" size="sm" v-model="bandfilter1" :options="bandfilter_opt"  ></b-select>
                                    </span> 
                                </div>
                                <span  style="float:left;margin-left: -40px;" >                            
                                    <audio id="slice_audio_id1" controls preload="auto" :src="slice_url1" style="transform:scale(0.7);height: 50px;">
                                    </audio>
                                </span>
                          </div>     
           
                        <div class="status leak" style="right:-20px;">             
                                        
                            <input type="radio" v-model="vchart1" value="0" id="fixed_01"> <label for="fixed_01">Fixed</label>
                            <input type="radio" v-model="vchart1" value="1" id="proportional_01"><label for="proportional_01">Proportional</label>
                        </div>
                        <div style='height:50px'>
                            <div id="hidden-container1" >
                              <div id="waveform_1" ref="waveform_1" style="float : left; margin-left: 35px; width:95%;"/>
                              <div id="wave-timeline1" ref="wave-timeline1" style="float : left; margin-left: 35px; width:95%;"/>
                            </div>
                        </div>
                         <div :style="{height: fftchart_height+ 'px'}" style=';margin-left:-40px;'>
                            <pressChartData_fft :url="fft_url1" ref="fftchart1" :height="fftchart_height-30" style="width:100%; overflow: auto;"
                               @onZoomClickEvent="onZoomClickEvent(1)"  />
                         </div>   
                        </div> 
                       </b-col>
                   </b-row>

                   <b-row style=" padding-left: 0px; background-color:#172a46; margin: 10px 10px 0 -5px;" >
                       <b-col style="padding-top: 10px; padding-bottom: 5px;">
 
                        <div  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                           <div style=" position:absolute;  top : 0px; left:0px;z-index:102; "> <!--div style="font-size:10pt;">{{lidx2}} </div-->
                                <!--button variant="outline-danger" @click="on_leakage_info(lidx2)" style="border:0;"><img src="../assets/images/Common/table/info_icon.png" alt="map" class="infoimg" /></button-->
                             <div><img src="@/assets/images/new/ic_list_info.svg" alt="map"  style="width:70%; " @click="on_leakage_info(lidx2)" /></div>
                              <div><img src="@/assets/images/new/ic_table_map.svg" alt="map"  style="width: 60%; " @click="on_goto_map_click(lidx2,w_idx2)" /></div>
                           </div>
                        
                           <div style=" position: relative;left:32%;width:53%;text-align:center;">
                            <div style="float:left;">
                            
                                <span style="position:absolute;left:10px;margin-top: -10px;">{{band_hz2}}  </span>
                                <span style="float: right;padding-top:15px;">
                                <!--b-input v-model="szoom2"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" /-->
                                  <b-select  class="facility__input" style="margin-top:-5px;" size="sm" v-model="bandfilter2" :options="bandfilter_opt"  ></b-select>
                                </span> 
                            </div>
                            <span  style="float:left;margin-left: -40px;" >                            
                                <audio id="slice_audio_id2" controls preload="auto" :src="slice_url2" style="transform:scale(0.7);height: 50px;">
                                </audio>
                            </span>
                          </div>     
            
                        <div class="status leak" style="right:-20px;">             
                                        
                            <input type="radio" v-model="vchart2" value="0" id="fixed_02"> <label for="fixed_02">Fixed</label>
                            <input type="radio" v-model="vchart2" value="1" id="proportional_02"><label for="proportional_02">Proportional</label>
                        </div>
                        <div style='height:50px'>
                            <div id="hidden-container2" >
                            <div id="waveform_2" ref="waveform_2" style="float : left; margin-left: 35px; width:95%;"/>
                            <div id="wave-timeline2" ref="wave-timeline2" style="float : left; margin-left: 35px; width:95%;"/>
                        </div>
                        </div>
                          <div :style="{height: fftchart_height+ 'px'}" style=';margin-left:-40px;'>
                            <pressChartData_fft :url="fft_url2" ref="fftchart2" :height="fftchart_height-30" style="width:100%; overflow: auto; 
                              " @onZoomClickEvent="onZoomClickEvent(2)"  />
                         </div>   
                       </div> 
                   </b-col>
                   </b-row>
                   <b-row style="  padding-left: 0px;background-color:#172a46; margin: 10px 10px 0 -5px;">
                       <b-col style="padding-top: 10px; padding-bottom: 5px;">
  
                           <div  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                           <div style=" position:absolute;  top : 0px; left:0px; z-index:102; "><!--div style="font-size:10pt;">{{lidx3}} </div-->
                                <!--button  @click="on_leakage_info(lidx3)" style="border:0;"><img src="../assets/images/Common/table/info_icon.png" alt="map" class="infoimg"  @click="on_leakage_info(lidx3)" /></button--> 
                                 <div><img src="@/assets/images/new/ic_list_info.svg" alt="map"  style="width:70%; " @click="on_leakage_info(lidx3)" /></div>
                              <div><img src="@/assets/images/new/ic_table_map.svg" alt="map"  style="width: 60%; " @click="on_goto_map_click(lidx3,w_idx3)" /></div>
                           </div>
                           <!--
                             <audio controls preload="auto" :src="wav3" style="width:60%;transform:scale(0.7);"/>
                              <div style="position:relative; left:30px;width: 95%;" id="waveform_3" ref="waveform_3"/>
                             <FFTChart1_c :url="fft_url3" ref="fftchart3" :height="fftchart_height"/>
                             -->
                           <div style=" position: relative;left:32%;width:53%;text-align:center;">
                            <div style="float:left;">
                            
                                <span style="position:absolute;left:10px;margin-top: -10px;">{{band_hz3}}  </span>
                                <span style="float: right;padding-top:15px;">
                                <!--b-input v-model="szoom3"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" /-->
                                <b-select  class="facility__input" style="margin-top:-5px;" size="sm" v-model="bandfilter3" :options="bandfilter_opt"  ></b-select>
                                </span> 
                            </div>
                            <span  style="float:left;margin-left: -40px;" >                            
                                <audio id="slice_audio_id3" controls preload="auto" :src="slice_url3" style="transform:scale(0.7);height: 50px;">
                                </audio>
                            </span>
                          </div>     
                        
                        <div class="status leak" style="right:-20px;">             
                                        
                            <input type="radio" v-model="vchart3" value="0" id="fixed_03"> <label for="fixed_03">Fixed</label>
                            <input type="radio" v-model="vchart3" value="1" id="proportional_03"><label for="proportional_03">Proportional</label>
                        </div>
                        <div style='height:50px'>
                            <div id="hidden-container3" >
                            <div id="waveform_3" ref="waveform_3" style="float : left; margin-left: 35px; width:95%;"/>
                            <div id="wave-timeline3" ref="wave-timeline3" style="float : left; margin-left: 35px; width:95%;"/>
                        </div>
                        </div>
                          <div :style="{height: fftchart_height+ 'px'}" style='margin-left:-40px;'>
                            <pressChartData_fft :url="fft_url3" ref="fftchart3" :height="fftchart_height-30" style="width:100%; overflow: auto;" @onZoomClickEvent="onZoomClickEvent(3)"  />
                         </div>   
                           </div>  

                       </b-col>
                   </b-row>
                 </b-col>
            </b-row>
         </div>
    <DlgWorkList ref="dlg_work_list" @show_leak_map="show_leak_map" />
    </div>
</template>

<script>
import pressChartData_fft from '@/components/chart/pressChartData_fft';
import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';
import WaveSurfer from 'wavesurfer.js';
import DlgWorkList from '@/components/popup/DlgWorkList';
//import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions';

export default {
    name: 'LeakAnalysis1_c',
    props: {
    },
    data() {
        return {
            validation_setting_visible : false,
            min_value : 1.5,
            distance:20,
            v_chart : true,
            layer_leak_visible:false,
            contents_height: 600,
            waveform_height: 42,
            fftchart_height: 140,
            wavesurfer: null,
            layer_all_visible: true,
            chart_i : 0 ,
            fft_url1: '',
            fft_url2: '',
            fft_url3: '',
            lidx1 :'',
            lidx2 :'',
            lidx3 :'',
            w_idx1 :'',
            w_idx2 :'',
            w_idx3 :'',            
            wav1 : '',
            wav2 :'',
            wav3 : '' ,
            options: {
            },
            file: ' ' ,
            msize:'7',
            tsize:'5',
            value:'',
            sdata:0,
            slice_url1:'',
            szoom1:200,
            vchart1:1,
            slice_url2:'',
            szoom2:200,
            vchart2:1,
            slice_url3:'',
            szoom3:200,
            vchart3:1,
            roof:0,
            bandfilter1: 0,
            bandfilter2: 0,
            bandfilter3: 0,
            bandfilter_opt: [
                { value: 0, text : 'None' },
                { value: 1, text : 'Low' },
                { value: 2, text: 'High'},    
            ],
            band_hz1 :'',
            band_hz2 :'',
            band_hz3 :'',
            dlg_show: true,
            work_block_i : true,
            work_block_layer : null,
        }
    },
    methods: {
        show_validation(){
            this.validation_setting_visible = !this.validation_setting_visible ;
        },
        toggle_visible_all_layer(a) {

           this.$refs.map.edit_cancel();
           if(a=== 1){
              // alert(a)
                this.$refs.map.leak_cal = 1;
           }else{
              //  alert(a)
                this.$refs.map.leak_cal = 0;
           }
           this.$refs.map.init_reload_leak_layer() ;          
        },
        toggle_visible_leak_type() {
            // 처음엔 leak type all 데이터를 가져와 지도에 보여준다.
            // All or LEAK 값을 가져와 지도에 보여준다.
            this.$refs.map.edit_cancel();
            this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
            if(this.$refs.map.layer_leak_visible)
                this.$refs.map.leak_type = 'LEAK';
            else
                this.$refs.map.leak_type = null;
            
            //누수 레이어를 다시 그린다.
            this.$refs.map.init_reload_leak_layer() ;      
        },
                //작업방에서 누수 작업을 선택하게 한다.
        work_block_show(type){
           //1 : block을 보여준다
           //2 : block 를 숨긴다.
            if(type === 1){ 
               this.work_block_i = false
              //this.view_work_block(this.$route.query.widx)
              this.view_work_block(1);
               this.$refs.map.init_reload_leak_layer() ;   
            }else{
                this.work_block_i = true  
                this.view_work_block(-1)
            }
            
            

        },
        view_work_block(i_work_id) {
            /*
            if(a === 1)
                this.layer_leak_visible =false
            else if(a === 2)  
                this.layer_leak_visible =true
            */
            let work_id = i_work_id
            if(work_id === undefined || work_id === null)
              work_id = -1
            let map = this.$refs.map.map;
            map.layer_all_visible = true ;
            this.layer_all_visible = true;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
          //  var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
           // let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

          //  if(this.$refs.map.map.getView().getZoom() < cz){
          //      alert(this.$t('home.alert_zoom_in')) ;
          //  }else{
           
            var l_layer = this.work_block_layer ;   
            if(l_layer !== null) {
                var source_h1 = l_layer.getSource();
                if(source_h1 !== null){
                    var hfeatures1 = source_h1.getFeatures();
                    for(var kk=0; kk< hfeatures1.length ; kk++) {
                        source_h1.removeFeature(hfeatures1[kk]);
                    }
                    map.removeLayer(l_layer) ;
                }
                l_layer = null;
             }

             if(work_id > 0) {
                let layer_style = this.$refs.map.facility_el_style
                //if(a > 1) return;
                //누수음 작업방에 대한 work_block
                let surl = this.URL+'api/get_geojson?layer=facility&f_type=work_block&customer_id='+sessionStorage.select_customer_id+"&w_idx=-1,"+ this.$route.query.widx; 
                let param =   {name:'work_block', code: '1007', f_type: '', opacity: 0.6, style: layer_style,surl:surl,epsg : epsg}     
     
                 var layer = CreateVectorLayer_f(param,extent) ;
                 this.work_block_layer = layer
                    //var vis = params.visible ;
                 layer.setVisible(true);                    
                 map.addLayer(layer);
             }   
              //  } //for문 끝
           
         //   } // zoom크기에 대한 if


        },
        //작업방에서 누수 작업을 선택하게 한다.
        leak_work_check(i_dlg_show){
            if(i_dlg_show){
                this.dlg_show = false
                this.$refs.dlg_work_list.show(100,null,this.$route.query.widx,"2");
            }else{
                this.dlg_show = true
                this.show_leak_map(null,"0")
            }

        },
        //지도에 누수음을 그려준다.
        show_leak_map(list,type){
            if(type !== "0"){
                this.$refs.map.get_extent('w_type=100&w_idx='+list).then((data)=>{
                    this.$refs.map.set_fit_extent(data)     
                    this.$refs.map.init_guide_leak_layer(list, type)
                })
            }else{
                this.$refs.map.init_guide_leak_layer(list, type)
            }
          
        },
        //validation check
        go_validation_check(a){
            var w_id = this.$route.query.widx
            var extent1 = this.$refs.map.map.getView().calculateExtent(this.$refs.map.map.getSize());  
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            //get_facility_check_list_ex   
            if(a == 1){ //    
                this.$http.get(this.URL+"api/set_valodation_leak_check?w_idx="+w_id+"&bbox="+extent1+"&srid="+epsg+"&min="+this.min_value+"&distance="+this.distance).then((res) => {
                    if(res.data.length<=0){                
                        return
                    }
                    //
                    for(let i=0; i<res.data.rows.length; i++) {
                        this.$refs.map.select_facility_feature('100','',res.data.rows[i].item_id) 
                    }
                });
            }else{ 
               this.$http.get(this.URL+"api/set_valodation_leak_check?w_idx="+w_id+"&bbox="+extent1+"&srid="+epsg+"&min="+this.min_value+"&distance="+this.distance+"&action=update").then((res) => {
                    if(res.data.length<=0){                
                        return
                    }
                    alert(res.data.msg ) ; 
                });             
            }
        },
        go_search(){
            this.$refs.map.go_search(this.value);
        },
        fn_press_han(event)
        {
            var objTarget = event.srcElement || event.target;
        // if(objTarget.type == 'text') {
            
            var value = objTarget.value;
            //alert(value);
            if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');
            // alert('Please enter only numbers');
            // event.preventDefault();          
            } 
            var _pattern0 = /^\d*[.]\d*$/; // 현재 value값에 소수점(.) 이 있으면 . 입력불가
            if (_pattern0.test(value)) {
                if (event.keyCode == 46) {
                    return false;
                }
            }
            // 소수점 둘째자리까지만 입력가능
            var _pattern2 = /^\d*[.]\d{2}$/; // 현재 value값이 소수점 둘째짜리 숫자이면 더이상 입력 불가
            if (_pattern2.test(value)) {
                alert(this.$t('pressure.alt_elev2'));
                return false;
            }             
        },  
        //20210427 
        onZoomClickEvent(a){
        var s_min = ""; //09-03 이렇게 들어온다
        var s_max = ""; //09-03 이렇게 들어온다
        if(a===1){
            s_min = this.$refs.fftchart1.select_min; //09-03 이렇게 들어온다
            s_max = this.$refs.fftchart1.select_max; //09-03 이렇게 들어온다
            this.slice_url1 = this.URL+'api/get_wave_slice?idx='+this.lidx1+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom1 ;
        }else if(a===2){
            s_min = this.$refs.fftchart2.select_min; //09-03 이렇게 들어온다
            s_max = this.$refs.fftchart2.select_max; //09-03 이렇게 들어온다
            this.slice_url2 = this.URL+'api/get_wave_slice?idx='+this.lidx2+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom2 ;
        }else if(a===3){
            s_min = this.$refs.fftchart3.select_min; //09-03 이렇게 들어온다
            s_max = this.$refs.fftchart3.select_max; //09-03 이렇게 들어온다
            this.slice_url3 = this.URL+'api/get_wave_slice?idx='+this.lidx3+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom3;
        }
        //  this.min_value = s_min;
        //  this.max_value = s_max;
        
        // this.slice_url = 'http://dev.neverlosewater.com/node/api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
        },  
        Enter_Check(){
         //  if(event.keyCode == 13){
          this.go_search();  // 실행할 이벤트
        },
        on_leakage_info(idx) {
          this.$refs.map.show_leakage_info_pop(idx)
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        set_center(){
            var s_zoom = this.$route.query.zoom
            var c_x = Number(this.$route.query.c_x)
            var c_y = Number(this.$route.query.c_y)
            this.$refs.map.map.getView().setZoom(s_zoom);
            this.$refs.map.map.getView().setCenter([c_x,c_y]);
        },
        on_goto_map_click(idx,w_idx) {
            //this.$refs.map.get_extent('w_type=100&l_idx='+idx).then((data)=>{
            this.$refs.map.get_extent('w_type=100&l_idx='+idx+"&w_idx="+w_idx).then((data)=>{    
                this.$refs.map.set_fit_extent(data)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
              this.$refs.map.select_facility_feature('100','',idx) 
        },
        gis_reload(){
            this.set_info();
        // this.$refs.map.init_draw();
            if(this.$store.setting.map_name==='Daum')
            this.$refs.map.map.getView().setZoom(9);
            else
            this.$refs.map.map.getView().setZoom(15);   
            this.set_map_gisload() ;    
        },        
        get_gisinfo(){
            this.$refs.map.block_info = 1 ;
           // this.set_toggle_visible_all_layer();
            // this.$refs.map.init_draw();
            this.$refs.map.edit_change('add', 'Block')
            // this.$refs.map.edit_start('add', 'Block') ;
        },  
        set_toggle_visible_all_layer() {    
          
            this.layer_all_visible = !this.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue
                layer.setVisible(this.layer_all_visible)
            }
        },      
        set_map_gisload() {
            this.$refs.map.edit_cancel();
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                     if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    this.$refs.map.layer_list.push(newlayer) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);

                } //for문 끝
                this.$refs.map.init_draw_modify();
                //누수 레이어를 다시 그린다.
                this.$refs.map.init_reload_leak_layer() ;      
           } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.edit_cancel();
            this.$refs.map.select_info = true;
        },

        onLeakMapClick(){
            let leak_idx = this.$refs.map.leak_idx;
            let work_idx = this.$refs.map.leak_recov_idx;
            //leak_info를 쿼리해서 그 데이터를 wav,fft에 앉힌다.
            this.chart_i = this.chart_i+1 ;
            if(this.chart_i%3 === 1){
                this.lidx1 = leak_idx;   
                this.w_idx1 = work_idx ; 
                document.getElementById("hidden-container1").style.display = 'none';
                this.wavesurfer_1.load(this.URL+'api/get_wav/'+leak_idx);
                this.fft_url1 = this.URL+"api/get_fft/"+leak_idx+'#'+this.vchart1;
                this.slice_url1 = ""
                this.bandfilter1 = 0
                var audio1 = document.getElementById('slice_audio_id1')
                audio1.load(); 
             
            }else if(this.chart_i%3 === 2){
                this.lidx2 = leak_idx;
                this.w_idx2 = work_idx ;
                document.getElementById("hidden-container2").style.display = 'none';
                this.wavesurfer_2.load(this.URL+'api/get_wav/'+leak_idx);
                this.fft_url2 = this.URL+"api/get_fft/"+leak_idx+'#'+this.vchart2;
                this.slice_url2 = ""
                this.bandfilter2 = 0
                var audio2 = document.getElementById('slice_audio_id2')
                audio2.load(); 
      
             
            }else{
                this.lidx3 = leak_idx;       
                this.w_idx3 = work_idx ;        
                document.getElementById("hidden-container3").style.display = 'none';
                this.wavesurfer_3.load(this.URL+'api/get_wav/'+this.lidx3);
                this.fft_url3 = this.URL+"api/get_fft/"+this.lidx3+'#'+this.vchart3;
                this.slice_url3 = ""
                this.bandfilter3 = 0
                var audio3 = document.getElementById('slice_audio_id3') //초기화
                audio3.load(); 
             }

        },
        size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
        },
        create_wavesurfer(){
            this.wavesurfer_1 = WaveSurfer.create({
                container: '#waveform_1',
                waveColor: 'blue',
                progressColor: 'black',
                mediaControls: true,
            // autoCenter: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [
                ]
            });
            this.wavesurfer_1.on('finish', () => {
                this.is_playing1 = false
            });
            
            this.wavesurfer_1.on("loading", function () {
                document.getElementById("hidden-container1").style.display = '';
                var mediaElt = document.getElementById("waveform_1");
                mediaElt = mediaElt.querySelector('audio');
                mediaElt.style =  "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-20px;border:0; outline:0;" ;
            //  this.wavesurfer.params.mediaControls = true;
            });
            
            //
            this.wavesurfer_2 = WaveSurfer.create({
                container: '#waveform_2',
                waveColor: 'blue',
               // progressColor: 'black',
               // autoCenter: true,
                mediaControls: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [      
                //    TimelinePlugin.create({
                //        container: '#wave-timeline2',    
                 //   }),
                Regions.create({
                        regionsMinLength: 1,
                        regions: [
                            {
                                start: this.region_start,
                                end: this.region_end,
                                loop: false,
                                color: 'hsla(400, 100%, 30%, 0.5)',
                                drag: false,
                                resize: false
                            }, 
                        ],
                    })                
                ]
            });


             this.wavesurfer_2.on("loading", function () {
                document.getElementById("hidden-container2").style.display = 'block';
                var mediaElt1 = document.getElementById('waveform_2');
                var mediaElt = mediaElt1.querySelector('audio');
                 mediaElt.style = "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-20px;border:0; outline:0;" ;
            });

           //this.wavesurfer_2.load(this.URL+'api/get_wav/-1');
            //
            this.wavesurfer_3 = WaveSurfer.create({
                container: '#waveform_3',
                waveColor: 'blue',
                progressColor: 'black',
        //     autoCenter: true,
                mediaControls: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [
                ]
            });
      
            this.wavesurfer_3.on("loading", function () {
                document.getElementById("hidden-container3").style.display = '';
                var mediaElt = document.getElementById("waveform_3");
                mediaElt = mediaElt.querySelector('audio');
                mediaElt.style =  "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-20px;border:0; outline:0;" ;

            });

        }

    },
    mounted () {
        this.contents_height = window.innerHeight - 120;
        this.fftchart_height =  this.contents_height/3 - 70;
        setTimeout(()=>{
            this.toggle_visible_all_layer(0);              
        }, 500)  
        setTimeout(()=>{
            this.set_center()
        }, 1000)  
        document.getElementById("hidden-container1").style.display = 'none';
        document.getElementById("hidden-container2").style.display = 'none';
        document.getElementById("hidden-container3").style.display = 'none';
        this.create_wavesurfer();       
        this.fft_url1 = this.URL+"api/get_fft/-1";
        this.fft_url2 = this.URL+"api/get_fft/-1";
        this.fft_url3 = this.URL+"api/get_fft/-1";
       
    },
    watch: {
      v_chart: function() {
          if(this.v_chart){
  
               setTimeout(()=>{
                   this.create_wavesurfer() ;
                   this.wavesurfer_1.load(this.URL+'api/get_wav/'+this.lidx1);
                   this.fft_url1 = this.URL+"api/get_fft/"+this.lidx1+"&roof="+(++this.roof)+'#'+this.vchart1;
                   this.wavesurfer_2.load(this.URL+'api/get_wav/'+this.lidx2);
                   this.fft_url2 = this.URL+"api/get_fft/"+this.lidx2+"&roof="+(++this.roof)+'#'+this.vchart2;
                   this.wavesurfer_3.load(this.URL+'api/get_wav/'+this.lidx3);
                   this.fft_url3 = this.URL+"api/get_fft/"+this.lidx3+"&roof="+(++this.roof)+'#'+this.vchart3;
                    var audio1 = document.getElementById('slice_audio_id1')
                    audio1.load(); 
                    var audio2 = document.getElementById('slice_audio_id2')
                    audio2.load(); 
                    var audio3 = document.getElementById('slice_audio_id3')
                    audio3.load();                 
            }, 500)

          }
      },
      vchart1() {
        var c_url = this.fft_url1.split('#') ;
        this.fft_url1 = c_url[0]+'#'+this.vchart1 ;
      },
       vchart2() {
        var c_url = this.fft_url2.split('#') ;
        this.fft_url2 = c_url[0]+'#'+this.vchart2 ;
      },
      vchart3() {
        var c_url = this.fft_url3.split('#') ;
        this.fft_url3 = c_url[0]+'#'+this.vchart3 ;
      },
      bandfilter1() {
        if(this.bandfilter1!==0) {      
        var s_min = 0 ;
        var s_max = 0 ;
            if(this.bandfilter1 === 1){
                this.band_hz1 = this.$refs.fftchart1.low_max_hz +'Hz'
                s_min = this.$refs.fftchart1.low_max_hz - 200 < 0 ? 1 : this.$refs.fftchart1.low_max_hz - 200 ;
                s_max = this.$refs.fftchart1.low_max_hz + 200 ;
            }else{
                this.band_hz1 = this.$refs.fftchart1.high_max_hz+'Hz' 
                s_min = this.$refs.fftchart1.high_max_hz - 200  ;
                s_max = this.$refs.fftchart1.high_max_hz + 200 ;
            }
            this.slice_url1 = this.URL+'api/get_wave_slice?idx='+ this.lidx1+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom1 ;
        }else{
            this.band_hz1 = '-'
        }
      },
      bandfilter2() {
        if(this.bandfilter2!==0) {      
        var s_min = 0 ;
        var s_max = 0 ;
            if(this.bandfilter2 === 1){
                this.band_hz2 = this.$refs.fftchart2.low_max_hz +'Hz'
                s_min = this.$refs.fftchart2.low_max_hz - 200 < 0 ? 1 : this.$refs.fftchart2.low_max_hz - 200 ;
                s_max = this.$refs.fftchart2.low_max_hz + 200 ;
            }else{
                this.band_hz2 = this.$refs.fftchart2.high_max_hz+'Hz' 
                s_min = this.$refs.fftchart2.high_max_hz - 200  ;
                s_max = this.$refs.fftchart2.high_max_hz + 200 ;
            }
            this.slice_url2 = this.URL+'api/get_wave_slice?idx='+ this.lidx2+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom2 ;
        }else{
            this.band_hz2 = '-'
        }
      },
      bandfilter3() {
        if(this.bandfilter3!==0) {      
        var s_min = 0 ;
        var s_max = 0 ;
            if(this.bandfilter3 === 1){
                this.band_hz3 = this.$refs.fftchart3.low_max_hz +'Hz'
                s_min = this.$refs.fftchart3.low_max_hz - 200 < 0 ? 1 : this.$refs.fftchart3.low_max_hz - 200 ;
                s_max = this.$refs.fftchart3.low_max_hz + 200 ;
            }else{
                this.band_hz3 = this.$refs.fftchart3.high_max_hz+'Hz' 
                s_min = this.$refs.fftchart3.high_max_hz - 200  ;
                s_max = this.$refs.fftchart3.high_max_hz + 200 ;
            }
            this.slice_url3 = this.URL+'api/get_wave_slice?idx='+ this.lidx2+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom3 ;
        }else{
            this.band_hz3 = '-'
        }
      }            
    },
    components: {
        Map: Map,
    //    FFTChart1_c: FFTChart1_c,
        pressChartData_fft: pressChartData_fft,
        DlgWorkList:DlgWorkList,
    }
}
</script>

<style  lang="scss" >

.fftchart1 .highcharts-container .highcharts-root {
    width: 100%;
    height: 19vh;
}
.infoimg{
    width: 15px;
}
.search_div{
    position: absolute;
    left:460px;
    top: 5px;
    z-index: 110;
}
.set_validation{
    float: right;  
    margin: 5px 20px 0px 0;
    width: 380px;
    padding: 5px;
    background-color: dimgray;
}
.spn1 {
      float: left;
}

</style>

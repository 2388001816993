<template>
    <div>
        <b-modal
            ref="modal"
            title="My Page" 
        >
            <div >
              
            <b-tabs v-if="properties!==null" align="center">
                <b-tab title="Properties" >
                   <b-row>
                        <b-col sm="4">
                            <label>{{$t('mypage_info.id')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>
                   
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('mypage_info.email')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['email']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('mypage_info.name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['name']" ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('mypage_info.department')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['department']" disabled></b-input>
                        </b-col>
                    </b-row>
                  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('mypage_info.mobile_phone_number')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['mobile_phone_number']" ></b-input>
                        </b-col>
                    </b-row>
               
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('mypage_info.type')}}</label>
                        </b-col>
                        <b-col sm="8">                            
                            <b-select class="facility__input" size="sm" v-model="properties['type']" :options="type_option" disabled />
                        </b-col>
                </b-row>
                <b-row><b-col sm="12">&nbsp;&nbsp; </b-col></b-row>
                <b-row>

                        <b-col sm="4">
                            <label>{{$t('mypage_info.authority_type')}}</label>
                        </b-col> 
                        <b-col sm="8">
                         <b-select class="facility__input" size="sm" v-model="properties['authority_type']" :options="auth_type_option" disabled />
                         
                        </b-col>
                </b-row>   
               
                <b-row>

                        <b-col sm="4">
                            <label>{{$t('mypage_info.register_datetime')}}</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['register_datetime']" disabled  ></b-input>
                        </b-col>
                </b-row>                     
                <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('mypage_info.status')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" ></b-select>
                  </b-col>
              </b-row>              
                </b-tab>
                <b-tab title="PW Change" >    
                        <b-row >
                            <b-col sm="6">
                                <label class="facility__label">ID</label>
                            </b-col>
                            <b-col sm="6">
                                <b-input class="facility__input" v-model="m_id" size="sm"  disabled />
                            </b-col>
                        </b-row>
 
                         <!--b-row class="mb-2">
                            <b-col sm="6">
                                <label class="facility__label" >OLD PASSWORD</label>
                            </b-col>
                            <b-col sm="6">
                               <b-input class="facility__input" type="password" v-model="o_pw" placeholder="old Password"  size="sm" />
                            </b-col>
                        
                        </b-row-->
                               <b-row class="mb-2">
                            <b-col sm="6">
                                <label class="facility__label">NEW PASSWORD</label>
                            </b-col>
                            <b-col sm="6">
                                <b-input class="facility__input" type="password" v-model="n_pw" placeholder="new Password"  size="sm" />
                            </b-col>
                        
                        </b-row>
                        <b-row class="mb-2">
                            <b-col sm="6">
                                <label class="facility__label">RENEW PASSWORD</label>
                            </b-col>
                            <b-col sm="6">
                                <b-input class="facility__input" type="password" v-model="r_pw" placeholder="new Password Check"  size="sm" />
                            </b-col>
                        
                        </b-row>  
                </b-tab>
                 <b-tab title="SMS_YN Check" :disabled="sms_check"  v-if="!sms_check">    
                        <b-row >
                            <b-col sm="5">
                                <label class="facility__label">No</label>
                            </b-col>
                            <b-col sm="7">
                                <b-input class="facility__input" v-model="properties['id']" size="sm"  disabled />
                            </b-col>
                        </b-row>
 
                        <b-row >
                            <b-col sm="5">
                                <label class="facility__label">ID</label>
                            </b-col>
                            <b-col sm="7">
                                <b-input class="facility__input" v-model="m_id" size="sm"  disabled />
                            </b-col>
                        </b-row>
                        <b-row >
                            <b-col sm="5">
                                <label class="facility__label">Customer_id</label>
                            </b-col>
                            <b-col sm="7">
                                <b-input class="facility__input" v-model="properties['c_name']" size="sm"  disabled />
                            </b-col>
                        </b-row> 
                        <b-row>
                            <b-col sm="5">
                                <label class="facility__label">{{$t('mypage_info.status')}}</label>
                            </b-col>
                            <b-col sm="7">
                                <b-select class="facility__input" size="sm" v-model="sms_yn" :options="yn_option" ></b-select>
                            </b-col>
                        </b-row>  
                </b-tab>                
            </b-tabs>        
            </div>

            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="handleOk()">OK</b-button>
                <b-button @click="$refs.modal.hide()">Cancel</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: 'DlgSettings',
    props: {
    },
    data() {
        return {
         properties : null,
         properties_bak : null,
         m_id : sessionStorage.m_id ,
         c_id: sessionStorage.customer_id, 
         m_idx : sessionStorage.m_idx ,
       //  o_pw : "12345678!",
         n_pw : null,
         r_pw : null,
         sms_check : false,
         sms_yn : "N",
         sms_yn_back : "N" ,
         f_status_option: [
            { value: 'A', text: 'Active' },
            { value: 'I', text: 'Inactive' },
            { value: 'U', text: 'Unable' },
         ],
         yn_option: [
         //    { value: null, text: 'Please select yes/no' },
             { value: 'Y', text: 'Yes' },
             { value: 'N', text: 'No' },
         ],
         type_option: [
             { value: 0, text: 'Wiplat' },
             { value: 1, text: 'Client' },
             { value: 2, text: 'Outsourcing' },
         ],         
         auth_type_option: [
             { value: 0, text: 'Administrator' },
             { value: 1, text: 'Manager' },
             { value: 2, text: 'User' },
             { value: 3, text: 'Guest' },
         ],         

        }
    },
    computed: {
  
    },
    created() {
    },
    mounted () {
    },
    methods: {
    
        handleOk() {
            if(this.n_pw !== "" && this.n_pw !== null){
                this.pw_change()
            }
            if(this.sms_yn !== this.sms_yn_back){
                this.sms_update();
            }
            var params = {};
            var param = {};
            //회원정보수정
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
               if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
               param[keys[i]] = this.properties[keys[i]]
            }
            
            if(Object.keys(param).length>0  ) {
                params["id"] = this.properties["id"];
                params["fields"] = encodeURIComponent(JSON.stringify(param))
                this.$http.post(this.URL+'api/users/update_member',params).then((res) => {

                    console.log(res.data);
                
                })
            }
            
            this.$refs.modal.hide();
        },
        sms_update(){
          
           var param = {} 
           param['sms_active'] = this.sms_yn
           param['customer_id'] = this.c_id
           param['customer_member_id'] = this.m_idx          
           param['mobile'] = this.properties['mobile_phone_number']
           param['user_name'] = this.properties['name']

            this.$http.post(this.URL+'api/users/set_sms_setting',param).then(({data}) => {
                if(data.check===0) {
                  //  localStorage.m_id = m_id
                 //   localStorage.m_idx = data.m_idx
                 //   localStorage.accessToken = data.accessToken
                 
                    alert("You have changed your sms_setting.")
                  //  this.$router.push('/')
                }else{
                    alert(data.msg) ;
                }
            }) 
        },
        pw_change(){    
            if(!this.fnCheckPassword(this.m_id,this.n_pw))              
              return;

            if(this.n_pw.length < 4){
                 alert("Please enter at least 8 digits of the new password.")
               return;
            }
            if(this.n_pw !== this.r_pw){
               alert("The new password and renew password are not the same.")
               return;
            }

            //this.$refs.modal.hide();
            //let is_reload = (this.setting.map_name!==this.$store.setting.map_name)||(this.setting.map_type!==this.$store.setting.map_type)
            var param = {}
           // const encript_m_id = this.$CryptoJS.AES.encrypt(this.m_id, "wiplat!123!123").toString()
          //  const encript_m_pw = this.$CryptoJS.AES.encrypt(this.n_pw, "wiplat!123!123").toString()
           // params['m_id'] = encript_m_id;
           // params['m_pw'] = encript_m_id;
            param["id"] = this.m_idx ;
            param["pw"] = this.n_pw ;
          //  this.$http.get(this.URL+'api/set_pw_setting?m_id='+localStorage.m_id+'&json='+encodeURIComponent(JSON.stringify(this.setting)))
            this.$http.post(this.URL+'api/reset_pw_member',param).then(({data}) => {
                if(data.check===0) {
                  //  localStorage.m_id = m_id
                 //   localStorage.m_idx = data.m_idx
                 //   localStorage.accessToken = data.accessToken
                    alert("You have changed your new password.")
                   // this.$router.push('/')
                }else{
                    alert(data.msg) ;
                }
               
            })
          
        },
        admin_show(id){
             this.m_id = id ;
             this.properties = null
             this.properties_bak = null
             this.n_pw =  null
             this.r_pw = null
             this.$http.get(this.URL+'api/get_member?m_id='+id).then((res) => {
                if(res.data.check===0) {
                    this.properties = res.data.info                    
                    this.c_id = res.data.info.c_id
                    this.m_idx =  res.data.info.id
                    this.properties_bak = Object.assign({}, this.properties)
                    this.n_pw = null ;
                    this.r_pw =  null ;
                    this.sms_yn = res.data.info.sms_yn ; 
                    this.sms_yn_back = res.data.info.sms_yn;
                    if(sessionStorage.level < 2)
                       this.sms_check = false;
                    else
                        this.sms_check = true;
                   
                }

            })
            this.$refs.modal.show();
        },
        show() {
          //  this.setting = Object.assign({}, this.$store.setting)
             this.properties = null
             this.properties_bak = null
             this.n_pw =  null
             this.r_pw = null
           this.$http.get(this.URL+'api/get_member?m_id='+sessionStorage.m_id).then((res) => {
                if(res.data.check===0) {
                    this.properties = res.data.info                    
                    this.properties_bak = Object.assign({}, this.properties)
                    this.c_id = res.data.info.c_id
                    this.m_idx =  res.data.info.id
                    this.n_pw = null ;
                    this.r_pw =  null ;
                    this.sms_yn = res.data.info.sms_yn ; 
                    this.sms_yn_back = res.data.info.sms_yn;
                    if(sessionStorage.level < 2)
                       this.sms_check = false;
                    else
                        this.sms_check = true;
                   
                }

            })
            this.$refs.modal.show();
        },
        /* 비밀번호 확인
* 사용법 : */
     fnCheckPassword(uid, upw) {
      /*   
       if(!/^[a-zA-Z0-9]{8,20}$/.test(upw)){ 
            alert('The password must be 8 to 12 digits in combination with numbers and letters.'); 
            return false;
       }
     var chk_num = upw.search(/[0-9]/g);
     var chk_eng = upw.search(/[a-z]/ig);

     if(chk_num < 0 || chk_eng < 0)    {
        alert('Passwords must be mixed with numbers and alphabets.');
        return false;
     }  
     */  
     if(/(\w)\1\1\1/.test(upw)) {
        alert('You cannot use the same character more than 4 times in your password.'); 
        return false;
     }
 //   if(upw.search(uid)>-1) {
 //       alert('ID가 포함된 비밀번호는 사용하실 수 없습니다.'); 
//        return false;
//    }
    return true;
}




     
    },
    components: {
    }
}
</script>

<style scoped>
</style>

<template>
    <div>
        <!-- top-bar -->
       <div ref="navbar" class="navbar-custom">
           <h4 class="content-title">{{$t('water_leak.page_title')}} Dataset</h4>
            <li class="setting-btn" style="float:right; margin: -40px 55px 0 0;">
                                <!--b-button size="sm" class="float-right" @click="show_setting">
                                    <i class="fas fa-cog"></i>
                                </b-button-->          
              <!--b-button  class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="new_model">NEW AI_MODEL</b-button-->
              <b-button  class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="new_dataset">Add Dataset</b-button>
          </li>
           <ul class="list-unstyled topnav-menu topnav-menu-left">
<!--                <li>-->
<!--                    <ol class="breadcrumb">-->
<!--&lt;!&ndash;                        <li class="breadcrumb-title">Water Leak</li>&ndash;&gt;-->
<!--&lt;!&ndash;                        <li class="breadcrumb-bar">|</li>&ndash;&gt;-->
<!--                        <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>-->
<!--                        <li class="breadcrumb-item active">{{$t('water_leak.page_title')}}</li>-->
<!--                    </ol>-->
<!--                </li>-->
           </ul>
      
       </div>
        <!-- tom-bar-end -->

      <search-left-type1 ref="left" :w_type=300 :activeCheckIndex="activeCheckIndex"  @go_detail="go_detail"  @go_delete="go_delete"  @updateCheckIndex="updateCheckIndex"  
       is-bottom-index  @work_edit="work_edit" @new_model="new_model"
      />
      
      <!--div class="right_area chart_box">
        <div class="tab_box on">
          <div class="info_box">
            <ul class="list">
               <li>{{$t('water_leak.work_title')}} : {{t_title}} / {{$t('water_leak.worker')}} : {{t_worker}}</li>
                <li>{{$t('water_leak.start_time')}} : {{t_start_time}} / {{$t('water_leak.close_time')}} : {{t_close_time}}</li>
            </ul>
          </div>
          <div class="img_area">
            <div class="icon_box">
              <div class="icon_01 color_01"><span>{{$t('water_leak.bar_chart.leak')}}</span></div>
              <div class="icon_02 color_02"><span>{{$t('water_leak.bar_chart.inner_leak')}}</span></div>
              <div class="icon_03 color_03"><span>{{$t('water_leak.bar_chart.none')}}</span></div>
            </div>
            <leak-master1/>
          </div>
        </div>
      </div-->

<!--        <b-row>-->
<!--            <b-col class="table-box table-box-resize ml-4 mr-4">-->
<!--                &lt;!&ndash; <h5 class="card-box-title">Leak data table</h5>-->
<!--                <label for="textarea-small" class="table-box-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label> &ndash;&gt;-->
<!--                <ServerSideTable ref="table_workinfo"-->
<!--                    :columns="columns"-->
<!--                    :search="true"-->
<!--                    :sort="true"-->
<!--                    :select="true"-->
<!--                    :adv_search="adv_search"-->
<!--                    :max_height="table_height+'px'"-->
<!--                />-->
<!--            </b-col>-->
<!--        </b-row>-->
    <DlgWorkInfo ref="work_info" @worklist_refresh="worklist_refresh"/>
    <DlgAIModelInfo ref="ai_model_info" />
    </div>
</template>

<script>

import {CreateVectorLayer_f} from '@/gis.js';
import SearchLeftType1 from '@/components/left/SearchLeftType_leak_ds';
//import LeakMaster1 from '@/views/LeakMaster1';
import DlgWorkInfo from '@/components/popup/DlgWorkInfo'
import DlgAIModelInfo from '@/components/popup/DlgAIModelInfo'
export default {
    name: 'LeakDataSet',
    components: {
     // LeakMaster1,
       SearchLeftType1,
       DlgWorkInfo: DlgWorkInfo,
       DlgAIModelInfo : DlgAIModelInfo
    },
    props: {
    },
   watch: {


    },
    data() {
        return {
           t_title:'',
           t_worker:'',
           t_start_time:'',
           t_close_time:'',
            tabs: [
              { 'name': 'chart' },
              { 'name': 'map' },
              { 'name': 'setting' }
            ],
            activeCheckIndex: null,
            box: [
              {
                'title': '장흥 대덕 블록 누수음 수집',
                'date': '2020. 8. 28',
                'time': '13:24',
                'number': 186
              },
              {
                'title': '장흥 대덕 블록 누수음 수집',
                'date': '2020. 8. 28',
                'time': '13:24',
                'number': 186
              },
              {
                'title': '장흥 대덕 블록 누수음 수집',
                'date': '2020. 8. 28',
                'time': '13:24',
                'number': 186
              }
            ],
            contents_height: 600,
            map_height: 300,
            table_height: 300,
            layer_all_visible: false,
            fft_url: '',
            bar_url : '',
            barchart_height : 450,
            columns: [
                {label: 'No', field: 'id'},
                {label: 'Title', field: 'title'},
                {label: 'Contents', field: 'contents'},
                {label: 'Worker', field: 'worker'},
                {label: 'Start Time', field: 'w_start_time'},
                {label: 'End Time', field: 'w_end_time'},
                {label: 'Count', field: 'leak_count'}
            ],
            adv_search: [
                {
                    field: 'lf_type',
                    control: 'select',
                    value: null,
                    options: [
                        { value: null, text: 'LF type' },
                        { value: '0', text: 'Pipe' },
                        { value: '1', text: 'Valve' },
                        { value: '2', text: 'Water Meter' },
                        { value: '4', text: 'Hydrant'},
                        { value: '13', text: 'Other'},
                    ]
                },
                {field: 'start_time', control: 'date', value: null, value2: null, placeholder: 'From date', placeholder2: 'To date'},
                {field: 'o_max_frequency', control: 'range', value: null, value2: null, placeholder: 'From frequency', placeholder2: 'To frequency'},
                {field: 'f_block_idx', control: 'select', value: null, options: [{ value: null, text: 'Block name' }]},
            ]
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.window_resiz);
    },
    mounted () {
        window.addEventListener('resize', this.window_resiz)
        this.window_resiz()
      // this.$refs.table_workinfo.setUrl(this.URL+"api/get_work_list_ex?w_type=100")
      //테이블 데이터 가져오듯
      /*
        this.box = [] ;
       // this.$refs.table_workinfo.setUrl(this.URL+"api/get_work_list_ex?w_type=100", (rows) => {
          this.$http.get(this.URL+'api/get_work_list_ex?w_type=100&search_term=&per_page=100&page=1').then((rows) => {
          if((!rows)||(rows.data.rows.length<=0)) return;
          for(let i=0; i<rows.data.rows.length; i++) {
              if(i==0){
              this.t_title =  rows.data.rows[i].w_title;
              this.t_worker = rows.data.rows[i].worker;
              this.t_start_time = rows.data.rows[i].w_start_time;
              this.t_close_time = rows.data.rows[i].w_end_time;
            } 
            var abox = {'title': rows.data.rows[i].w_title, 'idx':  rows.data.rows[i].idx, 'time':  rows.data.rows[i].w_start_time, 'number':  rows.data.rows[i].leak_count, 
                       'close_time': rows.data.rows[i].w_end_time, 'worker': rows.data.rows[i].worker,'w_title':rows.data.rows[i].w_title} ; 
            this.box.push(abox) ;
 
          }
        })
      */ 

    },
    methods: {
      worklist_refresh () {
        this.$ref.left.search()
      },
      new_dataset(){
        this.$refs.work_info.insert_show(300)
      },
      new_model(idx,cnt){
         let list = idx.join()
        this.$refs.ai_model_info.insert_show(301,list,cnt)
      },
      work_edit(idx) {
        this.$refs.work_info.show(idx, 300)
      },
      updateCheckIndex(payload,work) {
        this.activeCheckIndex = payload
         this.t_title =  work.w_title;
        this.t_worker = work.worker;
        this.t_start_time = work.time;
        this.t_close_time = work.close_time;
      },
        window_resiz() {
            this.contents_height = window.innerHeight - 210
            //this.table_height = this.contents_height/2
            //this.map_height = this.table_height

            this.map_height = this.contents_height/1.3
            this.table_height = this.contents_height/3.5

          if (this.activeIndex === 1) {
            this.$refs.map.update_size()
          }
        },
        toggle_visible_all_layer() {

                // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
           this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
           if(this.$refs.map.layer_all_visible)
             this.$refs.map.leak_cal = 1;
           else
               this.$refs.map.leak_cal = 0;
           this.$refs.map.init_reload_leak_layer() ;

          /*
            this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')===100)
                    layer.setVisible(this.$refs.map.layer_all_visible)
            }
            */
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },


        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*2, size1[1]*2] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length-1; i++) {
                    var layer = this.$refs.map.layer_list[i];
                      if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length-1; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                        this.$refs.map.layer_list.push(newlayer) ;
                         var vis = params.visible ;
                         newlayer.setVisible(vis);
                         this.$refs.map.layer_list.push(newlayer) ;
                         map.addLayer(newlayer);


                    } //for문 끝

                } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },
        go_detail(idx) {

            if(idx.length<1){
               alert(this.$t('water_leak.left_menu.alert_select_list'))
               return;
            }

            //let list = [this.box[idx].idx]
            let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
            this.$router.push({path: '/dataset-detail', query: { widx: list }})           
        },

        go_delete(idx) {
            if(sessionStorage === null || typeof sessionStorage === undefined){
               alert(this.$t('water_leak.left_menu.alert_no_delete'))
               return
         //   }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 2){
               alert(this.$t('water_leak.left_menu.alert_no_permission'))
               return;
            }

            if(idx<0) return;

            //let list = [this.box[idx].idx]
            var con_test = confirm(this.$t('water_leak.left_menu.confirm_msg_delete'));
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test){
              this.$http.get(this.URL+'api/delete_ex?wr_idx_ex='+idx).then((res) => {  
                console.log(res.data);
                this.$refs.left.search();
              });

           }
        },
        /*
        go_search(value) {

            if(value===undefined) return;

          this.box = [] ;
       // this.$refs.table_workinfo.setUrl(this.URL+"api/get_work_list_ex?w_type=100", (rows) => {
          this.$http.get(this.URL+'api/get_work_list_ex?w_type=100&search_term='+value+'&per_page=100&page=1').then((rows) => {
          if((!rows)||(rows.data.rows.length<=0)) return;
          for(let i=0; i<rows.data.rows.length; i++) {
              if(i==0){
              this.t_title =  rows.data.rows[i].w_title;
              this.t_worker = rows.data.rows[i].worker;
              this.t_start_time = rows.data.rows[i].w_start_time;
              this.t_close_time = rows.data.rows[i].w_end_time;
            } 
            var abox = {'title': rows.data.rows[i].w_title, 'idx':  rows.data.rows[i].idx, 'time':  rows.data.rows[i].w_start_time, 'number':  rows.data.rows[i].leak_count, 
                       'close_time': rows.data.rows[i].w_end_time, 'worker': rows.data.rows[i].worker,'w_title':rows.data.rows[i].w_title} ; 
            this.box.push(abox) ;
 
          }
        })
        },
        */
        size_view(e) {
            if(e==='up') {
                this.table_height += 100
                this.map_height -= 100
            }
            else if(e==='down') {
                this.table_height -= 100
                this.map_height += 100
            }
          if (this.activeIndex === 1) {
            this.$refs.map.update_size()
          }
        },

      onBarClick(){
       // var bar_idx = this.$refs.barchart.bar_idx;
       // this.fft_url = this.URL+"api/get_leak_bubble?w_idx="+bar_idx[0] ;
      }

    }
}
</script>

<style scoped>
</style>

<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                   <li>
                     <ol class="breadcrumb with--map">
                          <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                           <li class="breadcrumb-item"><router-link to="/water-tank">Water Tank</router-link></li>
                        <li class="breadcrumb-item active">Specification</li>
                     </ol>
                   </li>

                 </ul>
         </div>
     <!-- tom-bar-end -->
        <search-left-type32 :box="box" :activeCheckIndex="activeCheckIndex" @updateCheckIndex="updateCheckIndex" @go_search="go_search"/>
        <div class="right_area right_area_01 right_area_block-detail">
          <b-row>
              <b-col >
                  <b-row style="margin: 0;">
                      <b-col style="padding: 0;">
                        <div class="specification_area" style=" background-color:#0A192F; padding: 20px 30px 10px 10px;">
                          <div class="specification_box" ref="button_group_mnf">
                            <div class="info_box">
                              <ul class="list">
                                <li>Water Tank Name : {{tag_name}}  </li>
                              </ul>                              
                            </div>
                             <div style="position:absolute;top:5px;right:260px;z-index:102;">
                                <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_mnf(-1)"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                               <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_mnf(1)"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                              </div>
                            <b-form inline class="btn_list_wrap"  style="top:5px">
                              <b-input size="sm" v-if="isShowDate1" v-model="mnf_date" type="date" @change="change_mnf_date($event)" class="m-0"/>
                              <b-button-group class="mr-2 btn_list" >
                                <div class="lookup_btn search" @click="showDate1">Search Date</div>
                                <b-radio :button="true"  size="sm" v-model="selected_ts_mnf" value='30' @change="change_selected_ts_mnf($event)">
                                  <span>1M</span>
                                </b-radio>
                                <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='90' @change="change_selected_ts_mnf($event)">
                                  <span>3M</span>
                                </b-radio>
                                <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='180' @change="change_selected_ts_mnf($event)">
                                  <span>6M</span>
                                </b-radio>
                                <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='365' @change="change_selected_ts_mnf($event)">
                                  <span>12M</span>
                                </b-radio>
                              </b-button-group>
        <!--                      <b-input size="sm" v-model="mnf_date" type="date" @change="change_mnf_date($event)"/>-->
        <!--                      <span>MNF Monitoring</span>-->
                            </b-form>
                            <div>
                             <pumpFlowDayChart :url="bar_url" ref="barchart" id="barchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:20px;"  @onBarClick="onBarClick"   />  
                            </div>  
                          </div>

                          </div>
                      </b-col>
                  </b-row>
                  <b-row style="margin-left: 0; margin-right: 0; margin-top: 10px;">
                      <b-col style="padding: 0;">
                           <!-- <div class="specification_area" style=" background-color:white; padding: 20px 20px 10px 20px ; height:400px;"> -->
                           <div class="specification_area" style=" padding: 20px 20px 10px 20px ; height:400px;">
                               <div style="position:absolute;top:15px;right:280px;z-index:102;">
                                <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(-1)"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                               <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(1)"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                              </div>
                          <div class="specification_box" ref="button_group_realtime">
                           <b-form inline class="btn_list_wrap"  style="top:-5px">
                              <b-input size="sm" v-if="isShowDate" v-model="flow_date" type="date" @change="change_flow_date($event)" class="m-0"/>
                              <b-button-group class="mr-2 btn_list">
                                <div class="lookup_btn search" @click="showDate">Search Date</div>
                                <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='1440' @change="change_selected_ts_flow($event)">
                                  <span>1D</span>
                                </b-radio>
                                <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='4320' @change="change_selected_ts_flow($event)">
                                  <span>3D</span>
                                </b-radio>
                                <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='10080' @change="change_selected_ts_flow($event)">
                                  <span>7D</span>
                                </b-radio>
                                <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='43200' @change="change_selected_ts_flow($event)">
                                  <span>30D</span>
                                </b-radio>
                              </b-button-group>
       
                            </b-form>
                           <div>
                               <div>
                                  <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                              </div>
                               <pumpRealTime :url="real_url" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:25px;"   />  
                            </div> 
                          </div>
                    
                          </div>

                      </b-col>
                  </b-row>
              </b-col>
         
          </b-row>
         
        </div>
    </div>
</template>

<script>
import SearchLeftType32 from '@/components/left/SearchLeftType32';
import pumpFlowDayChart from '@/components/chart/pumpFlowDayChart' ;
import pumpRealTime from '@/components/chart/pumpRealTime' ;

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    name: 'BlockMonitoringDetail',
    props: [
    ],
    watch: {
    },
    data() {
        return {
            bar_url : '',
            real_url : '',
            isShowDate: false,
            isShowDate1: false,
            contents_height: 600,
            mnfchart_height: 300,
            realtimechart_height: 300,
            tag : this.$route.query.tag,
            tag_name : "",

            selected_ts_flow: '1440',
            flow_date: this.$route.query.last_date,
            loaded_ts_flow: false,

            selected_ts_mnf: '30',
            mnf_date: this.$route.query.last_date,

            columns: [
                {label: 'Date', field: 'date_str'},
                {label: 'Supply(㎥/day)', field: 'flow'},
                {label: 'MNF', field: 'mnf'},
                {label: '%', field: 'rate'},
            ],
           // table_mnf: [],
            ts_mnf: [],
            activeCheckIndex: null,
            box: [
              {
                'name': '대덕 소블록',
                'out_flow_day': 23,
                'status': 'red'
              },
              {
               'name': '대덕 소블록',
                'out_flow_day': 23,
                'status': 'red'
              },
              {
              'name': '대덕 소블록',
                'out_flow_day': 23,
                'status': 'red'
              }
            ],
           
        }
    },
    methods: {
         goRouter(path) {
            this.$router.push({path: path, query: { tag: this.tag }})
        },
        change_date_mnf(a){
          this.isShowDate1 = true;
          let default_date = new Date(this.mnf_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.mnf_date = this.date_to_str(default_date)      
          this.get_time_series_mnf()
        },
        change_date_flow(a){
          this.isShowDate = true;
          let default_date = new Date(this.flow_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
         
          this.get_time_series_flow()
        },
        date_to_str(format){
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 
     
          return year + "-" + month + "-" + date ;
      },
        onBarClick(){
          var bar_idx = this.$refs.barchart.bar_idx; //09-03 이렇게 들어온다
          let default_date = this.flow_date.split('-') ;
          let sta =  default_date[0]+'-'+bar_idx
          this.flow_date = sta
           //   this.fft_url = this.URL+"api/get_leak_bubble?w_idx="+bar_idx[0] ;    
              this.get_time_series_flow()
         },
        showDate() {
          this.isShowDate = !this.isShowDate
        },
          showDate1() {
          this.isShowDate1 = !this.isShowDate1
        },
        go_search(value) {

            if(value===undefined) return;

                //left메뉴 데이터를 뿌려준다.
            this.box = [] ;
          //  this.$http.get(this.URL+'api/get_watertank_list_left?search='+value).then((rows) => {
            this.$http.get(this.URL+'api/API21/watertank/list?customer_id='+sessionStorage.select_customer_id+'&search='+this.value).then((res) => {         
                for(let i=0; i<res.data.results[0].length; i++) {
                  if(this.tag === res.data.results[0][i].water_tank_code ){
                    this.t_title =  res.data.results[0][i].water_tank_name;
                  }           
                  var abox = {};
                  abox = {id: res.data.results[0][i].water_tank_code, 'name': res.data.results[0][i].water_tank_name,
                   'out_flow_day': Math.round(Number(res.data.results[0][i].out_flow_rate)) , 'last_date':  res.data.results[0][i].last_date ,  'status': 'blue' } ; 
                  this.box.push(abox) ; 
                }
            })

     
        },
        updateCheckIndex(payload) {
          this.activeCheckIndex = payload
          this.tag=this.box[payload].id
       //   if(this.activeIndex === 0 ){
        //     this.get_time_series_mnf() 
        //   this.bar_url = this.URL+'api/get_watertank_series?type=day&item=1&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30';
       //   }else if(this.activeIndex === 1){\
            this.bar_url = this.URL+'api/API21/watertank_day/'+this.tag+'&to='+this.mnf_date+'&limit='+this.selected_ts_mnf;
            this.get_time_series_flow()
       //   }else{
   
        //  }          
        },
        change_flow_date(e) {
            this.flow_date = e
            this.get_time_series_flow()
        },
        change_selected_ts_flow(e) {
            this.selected_ts_flow = e
            this.get_time_series_flow()
        },
        get_time_series_flow() {
            this.loaded_ts_flow = false
          //  this.real_url = this.URL+'api/get_watertank_series?type=min&tag='+encodeURI(this.tag)+'&start_date='+this.flow_date+'&limit='+this.selected_ts_flow;
            this.real_url = this.URL+'api/API21/watertank_minute/'+this.tag+'&to='+this.flow_date+' 23:59&limit='+this.selected_ts_flow;
            /*
            this.$http.get(this.URL+'api/get_time_series?type=flow&tag='+encodeURI(this.tag)+'&start_date='+this.flow_date+'&limit='+this.selected_ts_flow).then((res) => {
                let ts_flow = res.data.rows
               // var parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S.000Z");
                var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
                for(let i=0; i<ts_flow.length; i++) {
                    ts_flow[i]['date_time'] = parseTime(ts_flow[i]['date_time'])
                }
                this.loaded_ts_flow = true
              //  this.draw_flow_chart(ts_flow)
            });
            */
            this.loaded_ts_flow = true
        },
        change_mnf_date(e) {
            this.mnf_date = e
            this.get_time_series_mnf()
        },
        change_selected_ts_mnf(e) {
            this.selected_ts_mnf = e
            this.get_time_series_mnf()
        },
        get_time_series_mnf() {
       
              //this.bar_url = this.URL+'api/get_watertank_series?type=day&item=1&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit='+this.selected_ts_mnf;
              this.bar_url = this.URL+'api/API21/watertank_day/'+this.tag+'&to='+this.mnf_date+'&limit='+this.selected_ts_mnf;
/*
            this.$http.get(this.URL+'api/get_pump_series?type=day&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30').then((res) => {
                this.ts_mnf = res.data.rows
                var parseTime = d3.timeParse("%Y-%m-%d");
                this.table_mnf = []
                for(let i=0; i<this.ts_mnf.length; i++) {
                    this.ts_mnf[i]['date_str'] = this.ts_mnf[i]['date']
                    this.ts_mnf[i]['date'] = parseTime(this.ts_mnf[i]['date'])
                    this.ts_mnf[i]['rate'] = this.ts_mnf[i]['mnf'] / (this.ts_mnf[i]['flow']/24) * 100

                    this.table_mnf.push({
                        date_str: this.ts_mnf[i]['date_str'],
                        flow: this.ts_mnf[i]['flow'].toFixed(0),
                        mnf: this.ts_mnf[i]['mnf'].toFixed(0),
                        rate: this.ts_mnf[i]['rate'].toFixed(0)
                    })



                }
                this.draw_mnf_chart(this.ts_mnf)
            });
            */
        },


    },
    mounted () {
 
        this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 180
        let left_height = this.contents_height - (this.$refs.button_group_mnf.clientHeight*2)
        this.mnfchart_height = left_height / 2
        this.realtimechart_height = this.mnfchart_height
        
      
   //left메뉴 데이터를 뿌려준다.
       this.box = [] ;
       //this.$http.get(this.URL+'api/get_watertank_list_left').then((rows) => {
       this.$http.get(this.URL+'api/API21/watertank/list?customer_id='+sessionStorage.select_customer_id).then((res) => {         
                for(let i=0; i<res.data.results[0].length; i++) {
                  if(this.tag === res.data.results[0][i].water_tank_code ){
                    this.t_title =  res.data.results[0][i].water_tank_name;
                  }           
                  var abox = {};
                  abox = {id: res.data.results[0][i].water_tank_code, 'name': res.data.results[0][i].water_tank_name,
                   'out_flow_day': Math.round(Number(res.data.results[0][i].out_flow_rate)) , 'last_date':  res.data.results[0][i].last_date ,  'status': 'blue' } ; 
                  this.box.push(abox) ; 
                }
        })
        //this.bar_url = this.URL+'api/get_watertank_series?type=day&item=1&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30';
        this.bar_url = this.URL+'api/API21/watertank_day/'+this.tag+'&to='+this.mnf_date+'&limit=30';
        this.get_time_series_flow()   
    },
    beforeDestroy () {
    },
    components: {
        PulseLoader: PulseLoader,
        SearchLeftType32:SearchLeftType32,
        pumpFlowDayChart:pumpFlowDayChart,
        pumpRealTime:pumpRealTime,
  
    }
}
</script>

<style scope lang="scss">
.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // background: #fff;
  margin-bottom: 16px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}

.specification_btn .btn-group-toggle + .btn-group-toggle {
  //margin-left: 5px;
}
.specification_btn .btn-secondary{
  float: left;
  width: 42px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  background: rgba(35,41,47,0.4);
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
  border: 0;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background: rgba(35,41,47,0.8);
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}


.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 735px;
  overflow-y: scroll;
}
.btn_list_wrap .btn_list .lookup_btn.search{
    margin-right: 96px;
}

</style>

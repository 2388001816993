<template>
    <div>
       <b-modal
            ref="modal"
            modal-class="modal-work"
            title="Wokr List"
            >
   
               <b-row>
            <b-col class="table-box table-box-resize ml-4 mr-4 gis-master-table-box">
          <!-- <h5 class="card-box-title">GIS Data table</h5>
            <label for="textarea-small" class="table-box-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label> -->
             <div v-if="dlg_type === '1'" style="width:500px; height:500px;margin:20px 10px;">
              <ServerSideTable ref="work_table" 
                                   :columns="columns"
                                   :sort="true"
                                   :select="false"
                                   :search="true"     
                                   :adv_search ="false"   
                                   :add_custom_column="true"    
                                   @on_move_click="on_move_click"                
                                    max_height="400px"
                                 
                  />              
             </div>     
             <div v-if="dlg_type !== '1'" style="width:500px; height:500px;margin:20px 10px;">
              <ServerSideTable ref="work_table" 
                                   :columns="columns1"
                                   :sort="true"
                                   :select="true"
                                   :search="true"     
                                   :adv_search ="false"   
                                   :add_custom_column="true"                                                     
                                    max_height="400px"
                                 
                  />              
             </div>     
        </b-col>
      </b-row>
             <template v-slot:modal-footer="{ ok, cancel, hide }">

              <b-row v-if="dlg_type !== '1'" class="modal-footer-row m-0 leak_foot">
                <b-col sm="6">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">Close</b-button> 
               </b-col>
               <b-col sm="6">
                 
                 <b-button v-if="dlg_type !== '1'" @click="guide_line_work" block class="modal-footer__button modal-footer__button-ok">Show</b-button>
               </b-col>
             
             </b-row>
              <b-row v-if="dlg_type === '1'" class="modal-footer-row m-0 leak_foot">
                <b-col sm="6">
                 
               </b-col>
               <b-col sm="6">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">Close</b-button>                  
               </b-col>
             
             </b-row>
            </template>
      </b-modal>
<!--
         
-->
  
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;
import ServerSideTable from '@/components/table/ServerSideTable';

export default {
    name: 'DlgWorkList', //작업방 옮기기 위한 리스트
    props: {
    },
    watch:{
     

    },
    data() {
        return {
            columns: [
                {label: 'Move', field: 'move', sortable: false},
                {label: 'ID', field: 'id'},
                {label: 'Title', field: 'title', 
                        type: "string",      
                },
                {label: 'Contents', field: 'contents'},
                {label: 'Count', field: 'leak_count'},
                {label: 'Customer', field: 'worker'}
            ],
            columns1: [             
                {label: 'ID', field: 'id'},
                {label: 'Title', field: 'title', type: "string", },
                {label: 'Contents', field: 'contents'},
                {label: 'Count', field: 'leak_count'},
                {label: 'leak_Count', field: 'leak'},
                {label: 'Customer', field: 'worker'}
            ],            
            move_list: [],
            work_type:100,
            work_id : '',
            work_list:[],
            dlg_type : 1,
            adv_search: [
              {
              
              },
              
          ],
        }
    },

    mounted () {
    },
    methods: {
      guide_line_work(){
        let rows = this.$refs.work_table.getSelectRows()
        if(rows.length<1) {
          alert(this.$t('leak_detail.alt_select'));
          return;
        }

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        
        this.$emit('show_leak_map',list,"1") ;
        this.$refs.modal.hide();
      } ,
      show(w_type,movelist,work_id,dlg_type ) { 
   
       //   this.$refs.work_table.setUrl(this.URL+'api/get_work_list_ex?w_type='+w_type+'&customer_id='+sessionStorage.select_customer_id, (rows) => {      
       
         // });
          this.dlg_type = dlg_type
          this.work_type = w_type ;
          this.work_id = work_id ;
         
          this.move_list = movelist;  
          this.$refs.modal.show();   
           /* 
            this.$http.get(this.URL+'api/get_work_list_ex?w_type='+w_type+'&customer_id='+sessionStorage.select_customer_id+"&search_term=&per_page=100&page=1").then((rows) => {
                if(rows.data.rows.length <= 0) return;
                this.work_list = rows.data.rows
              
            })
           */
           setTimeout(()=>{  
             var table =this.$refs.work_table
             if(table !==undefined){
                table.setUrl(this.URL+'api/get_work_list_ex?w_type='+w_type+'&customer_id='+sessionStorage.select_customer_id, (rows) => {
                  if((!rows)||(rows.length<=0)) return;
                
                  //this.work_list =rows;
                });
             }
           }, 500)
      //   this.$refs.modal.show();    
    
        },
      on_move_click(row){
         
         var con_test1 = confirm("Are you sure you want to Move?");
         //let list = idx
         // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
         if(con_test1){
            
            this.$http.get(this.URL+'api/move_location_leakage?w_type='+ this.work_type+'&w_id='+row.id+'&lidx='+this.move_list).then((res) => {  
                console.log(res.data);
                if(res.data.check === 0){
                  this.$http.get(this.URL+'api/move_work_leakage?w_type='+ this.work_type+'&w_id='+row.id+'&lidx='+this.move_list).then((res1) => {  
                     if(res1.data.check === 0){  
                       if(this.work_type === "100")
                         this.$emit('goRouter',"",row.id) ;
                       else  
                         this.$emit('leaktable_refresh') ;
                       this.$refs.modal.hide();
                     }else{
                        alert("The move failed..")
                     }

                  }); 
                }else{
                  alert("The move failed.")
                }
            });
          }
        },
    
    },
    components: {
         //   FFTChart1:FFTChart1
        //  PressBigChartData:PressBigChartData,
        ServerSideTable: ServerSideTable,
      
    }
}
</script>

<style  lang="scss">

.modal.show.modal-work .modal-dialog {
      max-width: 575px;
      background: #F2F2F2;
   }

.modal-work {
  
  .modal-dialog { 
    background: #172A46;
    width:575px;
    /*height: calc(100% - 70px);*/
    .modal-header {
      padding-bottom: 22px;
      background: #172A46;
      .modal-title {
        font-size: 18px;
        // margin-top: 20px;
      }
    }
    
    .modal-info-content {
      border: 0;
      border-radius: 0;
 
      &.reversal {
        background: #172A46;
        .nav-tabs {
          background: #172A46;
        }
        .tab-content {
          background: #172A46;
          &.is--scroll {
            height: 73vh;
            overflow-y: auto;
          }
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
          // 2022 new
          // background: #fff;
          border-color: 1px solid #707070;
        }
        .facility__label {
          color: #fff;
          font-size: 12px;
          display: inline-block;
          letter-spacing: 0.5px;
          //width: 35%;
        }
        .facility__input {
          //width: 60%;
          height: 32px;
          font-size: 12px;
          border: 1px solid #707070;
          box-sizing: border-box;
          border-radius: 0;
          margin-top: 0;
          margin-bottom: 14px;
          background: #0A192F;
          &.height--auto {
            height: auto;
          }
        }
        .modal-footer {
          background: #172A46;
          padding-bottom: 18px;
          padding-top: 3px;
        }
        .modal-footer-row {
          width: 100%;
          margin: 0;
          padding-bottom: 0;
        }
        .btn.modal-footer__button {
          height: 52px;
          font-size: 14px;
          border: 0;
          &.modal-footer__button-ok {
          // 2022 new
            // background: #28B7CB;
            background: #28AF81;
          
          }
          &.modal-footer__button-cancel {
            background: #9D9D9D;
          }
        }
        .photo-card {
          border-radius: 0;
          border: 0;
          background: none;
          .img-thumbnail {
            margin-bottom: 10px;
          }
          .card-header {
            height: 150px;
            background: #172A46;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .card-header__text {
              font-size: 16px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
    <div  :style="{height: height+'px'}">
        <line-chart ref="chart" v-bind:chartData="chartData"  v-bind:yopt="yopt" v-bind:xdata="xdata" v-bind:xmax="xmax" v-bind:ymax="ymax" v-if="loaded" v-bind:class="size"></line-chart>
    </div>
  
</template>

<script>
//import * as d3 from 'd3'
import LineChart from '@/components/chart/LineChart'
//import {Line} from 'vue-chartjs'

export default {
    name: 'FFTChart2',
 //   extends: Line,
    components: {LineChart },    
    props: {
      height: Number,
      vopt : Number,
      url:String,
      cData: { type: Array  },
      xData: { type: Array  },
    },
    watch: {
        chartData: function() {
             this.loaded = false;
         //   this.chartData = this.cData;
              //   deep: true, 
            this.redraw();
                // handler(){this.renderChart(this.chartData, this.options); }
        },
        url() {
            if(this.url==='') return
            
       //     this.chartData= [];
      //      this.xdata = [];
       //     this.datacollection= null;
            this.loaded=false;
            var url_a =this.url.split('?');
            if(url_a.length >1)
             this.vchart = url_a[1] ;
     //       this.chartData= this.cData;
        //    this.xdata = this.xData ;
            this.redraw();
             /*
            this.$http.get(url_a[0]).then((res) => {
               if( res.data !=="")
                  this.chartData = res.data.slice(0, this.$store.setting.wave.frequency.x); 
               else    
                  this.chartData =[] ;
            });
            */
        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        datacollection: null,
        chartData: [],
        size: 'firstClass',
        xdata : [],
        ymax : 1,
        xmax : 10,
        loaded: false,
        yopt : null,
        vchart : 1,
       // cData : [],
        //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
       //dataset: [65, 59, 80, 81, 56, 55, 40]
      // dataset :  this.fft_data ,
      }
    },
    methods: {
  
       redraw () {
         /*
            this.renderChart(
            {
              //  labels: ["January","February","March","April","May","June","July" ],
                datasets: [
                 {
                    label: "Data One",
                    backgroundColor: "#f87979",
                    data: this.chartData
                  }
                ]
            },
            { responsive: true, maintainAspectRatio: false }
            );  
            */ 
        //   if(this.chartData === null) this.chartData =[];
           
             this.ymax = parseInt(this.$store.setting.wave.frequency.y) ;
             if(this.vchart === '0'){
                this.ymax = parseInt(this.$store.setting.wave.frequency.y) ;
                 this.yopt =  {
                      beginAtZero: true,       
                      max: this.ymax,
                  //    max: 10,
                      min: 0,     
                      stepSize  : this.ymax/5
                    } ;
             }else{
                this.yopt = {  beginAtZero: true  } ;
             }
            

           //  this.xmax = parseInt(this.$store.setting.wave.frequency.x) ;

           //  this.xdata=[];  
           //    for (var i = 0;  i < this.chartData.length ; ++i) {
           //      this.xdata.push(i);
            //   }
              this.loaded = true;
            //  this.update();
           //   this.$data._chart.update()
           // this.chartData =  cData ;
            //this.$refs.chart.update();

      }
      

    
    },
  mounted () {
        window.addEventListener('resize', this.resize);
        if(this.url==='' || this.cData===null) return
        var url_a =this.url.split('?');
        this.vchart = url_a[1] ;
        this.xdata = this.xData ;
        this.chartData = this.cData;

        /*      
        this.$http.get(url_a[0]).then((res) => {
           if( res.data !=="")
             this.chartData = res.data
               this.chartData = res.data.slice(0, this.$store.setting.wave.frequency.x);
            else
               this.chartData =[] ;
        });
        */
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    }
    
}
</script>

<style scoped>
.firstClass{
   height: 200px;
 
    padding-top: 10px;
 

 /*  
    width: 700px;
    */
  }

</style>

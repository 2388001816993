<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                   <li>
                     <ol class="breadcrumb with--map">
                       <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>                      
                       <li class="breadcrumb-item active">Service Log</li>
                     </ol>
                   </li>
                 
           
              </ul>
         </div>
     <!-- tom-bar-end -->
    

        <div class="right_area_01 right_area_block-detail" >
          <b-row>
  
              <!-- <b-col  style="background-color:white;margin: 0 5px 5px 15px;" > -->
              <b-col  style="margin: 0 5px 5px 15px;" >
                <div class="log_box" style="padding: 20px 5px 20px 10px; "  >
                  <span class="title_value" style="font-size:15px;">NELOW Service Log List </span>
                </div>
                <div style="background-color:#0A192F; padding: 20px 10px;"  :style="{height: contents_height+50+'px'}">
                  <!--<vue-good-table ref="table"
                      :columns="columns"
                      :rows="table_log"
                      :line-numbers="true"
                      :max-height="contents_height+ 70 +'px'"
                      :fixed-header="true"
                        styleClass="vgt-table condensed"
                  /> -->
    
                    <div class="search_date" style="background-color:#ebeff2;padding: 10px 10px 0 30px ;margin-top: 15px;">
                       
                        <span style="float:left;padding: 5px 20px;"  >CUSTOMER : <b-select  size="sm" v-model="s_customer_no" :options="customer_name" style="width:200px;padding: 0 10px;" ></b-select>
                        </span>

                        <span style="float:left;padding: 5px 40px;"  >Log Type : <b-select  size="sm" v-model="s_log_type" :options="log_type_option" style="width:200px;padding: 0 10px;" ></b-select>
                        </span>


                        <span class="spn1"><b-row>
                            <b-col cols="4">
                                <b-input v-model="sdate" type="date" />
                            </b-col>
                            <b-col cols="1">
                                <span>~</span>
                            </b-col>
                            <b-col cols="4">
                                <b-input v-model="edate" type="date" />
                            </b-col>
                            <b-col cols="3" style="margin-top:3px;">
                              <button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="go_search">search</button>
                            </b-col>
                        </b-row></span>
                      
                    </div>
                    <div style="margin-top:35px;">
                    <ServerSideTable ref="table_log" 
                                  :line-numbers="true"
                                   :fixed-header="true"
                                   :columns="columns2"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"                                   
                                   :select="true"  
                                   :search="false"    
                                   :leak_total_cnt = "false"     
                                   :perpage_cnt = 20                                
                                   :max_height="contents_height-50+'px' "                                
                              
                  />
                    </div>
                 </div>
              </b-col>
          </b-row>
        </div>
    </div>
</template>

<script>

import * as d3 from 'd3'
import d3tip from "d3-tip";
import ServerSideTable from '@/components/table/ServerSideTable2';
//import PressMinChart from '@/components/PressMinChart' ;

 d3.tip = d3tip;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


export default {
    name: 'ServiceLog',
    props: [
    ],
    watch: {
       s_customer_no() {
         this.go_search();             
       },
       s_log_type() {
         this.go_search();             
       },
    },
    data() {
        return {
            bar_url : '',
            real_url : '',
            contents_height: 600,
            mnfchart_height: 300,
            realtimechart_height: 300,
            tag : this.$route.query.tag,
            tag_name : '',            
            selected_ts_flow: '1',
            sdate: '2020-11-01',
            loaded_ts_flow: false,
            selected_ts_mnf: '1',
            s_customer_no:'',
            s_log_type : '',
            customer_name : [{value:'', text :"ALL"},
                            ],                 
            edate: '2020-11-01',
            columns: [
                {label: 'Name', field: 'name'},             
                {label: 'Code', field: 'customer_no'},
                {label: 'Country', field: 'country_no'},
                {label: 'Region', field: 'region_no'},
                {label: 'License', field: 'sw_license_type'},
                {label: 'R_Date', field: 'register_datetime'},
                {label: 'S_Date', field: 'service_start_date'},
                {label: 'E_Date', field: 'service_end_date'},
            ],    
            columns1: [
                {label: this.$t('service_log.table.date'), field: 'name'},             
                {label: this.$t('service_log.table.customer'), field: 'email'},
                {label: this.$t('service_log.table.name'), field: 'department'},
                {label:  this.$t('service_log.table.log_type'), field: 'recent_work_date'},
                {label:  this.$t('service_log.table.result'), field: 'recent_work_date'},
                {label:  this.$t('service_log.table.contents'), field: 'recent_work_date'},                
            ],            
            columns2: [             
                {label: this.$t('service_log.table.date'), field: 'log_datetime'},             
                {label: this.$t('service_log.table.customer'), field: 'customer_name'},
                {label: this.$t('service_log.table.name'), field: 'member_name'},
                {label:  this.$t('service_log.table.log_type'), field: 'type'},
                {label:  this.$t('service_log.table.result'), field: 'result'},
                {label:  this.$t('service_log.table.contents'), field: 'message'},
            ],      
            table_customer: [],           
            table_member: [],
            table_log: [],
            ts_mnf: [],
            activeCheckIndex: null,
            server_name:[{value:1, text :"KOREA SERVER"},
                         {value:2, text :"INDONESIA SERVER"},
                         {value:3, text :"BATNAM SERVER"},
                         {value:4, text :"TANZANIA SERVER"},
                        ],
            server_id : 1,
            clicked_l_idx: -1,
            clicked_b_row:null,
            searchTerm: '',
            adv_search: [
            {
              field: 'leak_result',
              control: 'select',
              value: null,
              //'Leak','Leak Inside','Usage','Other'
              options: [
                  { value: null, text: 'Leak Result' },
                  { value: 'RT_LEAK', text: 'Leak' },
                  { value: 'NO_LEAK', text: 'No Leak' },
                
              ]
          },
            {field: 'measure_datetime', control: 'date', value: null, value2: null, placeholder: 'From measure_datetime', placeholder2: 'To measure_datetime'},
       ],
        log_type_option : [
           {value:'', text :"ALL"},
           {value:1, text :"로그인"},
           {value:2, text :"누수음 작업목록 생성"},
           {value:3, text :"압력 작업목록 생성"},
           {value:4, text :"m2-지점등록"},
           {value:5, text :"회원가입"},
           {value:6, text :"계정삭제"},
           {value:7, text :"회원상태변경"}
        ]
      }
    },
    methods: {
         goRouter(path) {
            this.$router.push({path: path, query: { tag: this.tag }})
        },
     onRowClick(params) {
      //선택된 색상 보여지게
   //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
      if(this.clicked_b_row != null){
        this.clicked_b_row.bgColor = '';
    //  //  this.clicked_b_row.style.backgroundColor = '';
     }
    
      if(params.event.target.nodeName==='TD'){
          params.event.target.parentElement.bgColor  = 'ebeff2';          
          this.clicked_b_row = params.event.target.parentElement ;
      }else  if(params.event.target.nodeName==='IMG'){
           params.event.target.parentElement.parentElement.parentElement.bgColor = 'ebeff2';      
          this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
      }else{
          params.event.target.parentElement.parentElement.bgColor = 'ebeff2';      
          this.clicked_b_row = params.event.target.parentElement.parentElement ;
      }
      
/*
     if(this.clicked_b_row != null){
       this.rowStyleClassFn(params.row, 1);
     }
      this.rowStyleClassFn(params.row, 0);
 */    
    //  this.clicked_b_row = params.row
  
      this.clicked_l_idx = params.row.id ;
      this.tag_name = params.row.name ;
      this.table_log = []; 
      var datequery = '' ;
      if(params.row.recent_work_date.length >9 ){
          this.sdate = params.row.recent_work_date.substr(0,7)+'-01' ;
          this.edate = params.row.recent_work_date.substr(0,10) ;
          datequery = '&startDate='+this.sdate+'&endDate='+this.edate ;
      }
      this.$refs.table_log.setUrl(this.URL+'api/work_count?member_id='+this.clicked_l_idx+datequery, (rows1) => {
          if((!rows1)||(rows1.length<=0)) return; 
      })
    },


    date_search(){
      this.table_log = []; 
      var datequery = '&startDate='+this.sdate+'&endDate='+this.edate ;
      this.$refs.table_log.setUrl(this.URL+'api/work_count?member_id='+this.clicked_l_idx+datequery, (rows1) => {
         if((!rows1)||(rows1.length<=0)) return;
      })
    },

     go_search() {
        //if(value===undefined) return;
              //left메뉴 데이터를 뿌려준다.
          this.table_log = [];       
        let search_query = "startDate="+this.sdate+"&endDate="+ this.edate+"&customer_id="+this.s_customer_no+"&type="+this.s_log_type; 
        this.$refs.table_log.setUrl(this.URL+'api/service_log_list?'+search_query, (rows1) => {
           if((!rows1)||(rows1.length<=0)) return;
        })
      },   
   
    },
    mounted () {
       this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175
       
       this.customer_name = []         
         //  this.server_name = []         
        /*
        this.$http.get(this.URL+'api/get_server_list').then((rows) => {
          if((!rows)||(rows.data.list<=0)) return;
          for(let i=0; i<rows.data.list.length; i++) {
            this.server_name.push({value:rows.data.list[i].idx, text :rows.data.list[i].server_name}) ;
          }          
        })
        */
        this.$http.get(this.URL+'api/get_customer_list').then((rows) => {
          if((!rows)||(rows.data.list<=0)) return;
          for(let i=0; i<rows.data.list.length; i++) {
            this.table_customer = rows.data.list;
            if(i=== 0){
              this.customer_name.push({value:'', text :'ALL'}) ; 
            }
            this.customer_name.push({value:rows.data.list[i].id, text :rows.data.list[i].name}) ; 
          }
          
        })

        let today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth()+1
        let day = today.getDate() < 10 ? '0'+today.getDate() : today.getDate(); 
        this.sdate = year +'-'+ month + '-01'
        this.edate = year +'-'+ month + '-'+day

        this.table_log = [];       
        let search_query = "startDate="+this.sdate+"&endDate="+ this.edate+"&customer_id=&type="; 
        this.$refs.table_log.setUrl(this.URL+'api/service_log_list?'+search_query, (rows1) => {
           if((!rows1)||(rows1.length<=0)) return;
        })
         
   
       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
               
               //if(select_id>-1){
                  //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
               //   this.updateCheckIndex(select_id)
               //}
           }
         }
       }, 500)
   
    },
    beforeDestroy () {
    },
    components: {  
      ServerSideTable: ServerSideTable,
    }
}
</script>

<style lang="scss">
.presschart_m2_d .wavchart  {
  height: 350px;
  width: 98%;
}
.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // background: #fff;
  margin-bottom: 10px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}

.specification_btn .btn-group-toggle + .btn-group-toggle {
  //margin-left: 5px;
}
.specification_btn .btn-secondary{
  float: left;
  width: 42px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  background: rgba(35,41,47,0.4);
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
  border: 0;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background: rgba(35,41,47,0.8);
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}



.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 735px;
  overflow-y: scroll;
}
table.vgt-table.condensed td {
      padding: .188rem .15rem;
}
.btn_pre{
 position: absolute;
    top: 0;
    right: 110px;
   // background: #4C5055;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
 }
 .map-button.icon_btn.btn.btn-sm{
   padding-top: 4px;
 }
 .search_date {
    position: absolute;
    left: 30px;
    top: 45px;
    z-index: 110;
}
</style>

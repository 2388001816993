<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                   <li>
                     <ol class="breadcrumb with--map">
                       <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>                      
                       <li class="breadcrumb-item active">IoT Device</li>
                     </ol>
                   </li>
                 
           
              </ul>
         </div>
     <!-- tom-bar-end -->
    

        <div class="right_area_01 right_area_block-detail">
          <b-row style="margin-left: 10px;">
            <!--
              <b-col >
                  <b-row style="margin: 0;">
                      <b-col style="padding: 0;">
                        <div class="specification_area" style=" background-color:white; padding: 20px 30px 10px 10px;height:810px;">
                              <div class="log_box" >
                                <span class="title_value">CUSTOMER</span>
                              </div>
                          
                                
                              <div style="background-color:white; padding: 20px 5px;">
                                <vue-good-table ref="table_customer"
                                    :columns="columns1"
                                    :rows="table_customer"
                                    :line-numbers="true"
                                     :max-height="contents_height+ 50 +'px'"   
                                    :fixed-header="true"
                                    styleClass="vgt-table condensed"
                                    @on-row-click="onRowClick"
                                      
                                 />
                              </div>
                                                  
                          </div>
                      </b-col>
                  </b-row>

              </b-col>
             --> 
              <b-col cols="7" style="margin: 0 10px 10px 0;background-color: #172A46;height:calc(90vh - 20px);" >
                <div style="position:absolute; top: -50px; right: 0px; width:350px;padding:10px;">
                    <!--span  >SERVER NAME : <b-select  size="sm" v-model="server_id" :options="server_name" style="width:200px;"></b-select>
                    </span-->
                </div>
                <div class="log_box" style="padding: 15px 5px 10px 20px; ">
                  <h5 style="color:#fff;" >IOT DEVICE</h5>
                </div>
                <div style="position:absolute; top: 20px; right: -30px; width:550px;padding:10px;background:#f2f2f;">
                    <span style="padding: 10px;"  >CUSTOMER : <b-select  size="sm" v-model="s_customer_no" :options="customer_name" style="width:150px;" ></b-select>
                    </span>

                    <span style="padding: 10px;"  >DEVICE TYPE : <b-select  size="sm" v-model="device_type" :options="device_type_name" style="width:120px;"></b-select>
                    </span>
                 
                </div>
                <!-- <div style="background-color:white; padding: 20px 10px;"> -->
                <div style="padding: 20px 10px;" class="device_table">
                
                    <ServerSideTable ref="table_device" 
                                  :line_num ="true"
                                   :fixed-header="true"                                   
                                   :columns="columns"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"      
                                   :adv_search="adv_search"                             
                                   :select="true"  
                                   :search="true"                                
                                   :max_height="contents_height-60 +'px'"  
                                    @on-row-click="onRowClick"                              
                              
                  />
                 </div>
              </b-col>
            
              <!-- <b-col style=" margin: 0 0 10px;background-color:white;"> -->
              <b-col style=" margin: 0 0 10px;background-color:#172a46;">
                <div style="padding: 0px 30px 0 40px; margin:0 10px ; background-color:#172a46;" >
                <div class="log_box" style="padding: 40px 5px 10px 0px;"  >
                   <span style="float: right;padding: 10px; margin-right: -20px;margin-top:-20px;">
                      <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" style="background:#28af81;" @click="upload_device">Upload</button></span>
                      <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" style="background:#28af81;" @click="new_device">New Device</button></span>
                      
                  </span>
                  <h5 style="color:#fff;">DEVICE INFORMATION</h5>
                  
                 
                  <hr/>
                </div>
                    <b-row >
                        <b-col sm="4">
                            <label>ID</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>Device Type</label>
                        </b-col>
                        <b-col sm="8">                      
                            <b-select class="facility__input" style="margin-bottom:8px;" size="sm" v-model="properties['device_type']" :options="device_option" :disabled="new_show" 
                             v-on:change="getSelectedItem"  ></b-select>
                        </b-col>
                    </b-row>                    
                    <b-row>
                        <b-col sm="4">
                            <label>Serial No</label>
                        </b-col>
                        <b-col sm="2"> <b-input  v-model="i_device_type" disabled /></b-col> 
                        <b-col sm="2"> <b-input  v-model="device_sn1"  maxlength="4" disabled /></b-col> 
                        <b-col sm="2">  
                            <b-input size="sm" v-model="device_sn2" maxlength="4" :disabled="new_show" 
                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                             ></b-input>  
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>Customer Name</label>
                        </b-col>
                        <b-col sm="8">                           
                            <b-select  size="sm" v-model="properties['customer_no']" :options="i_customer_name" style="width:300px;margin-bottom:8px;" :disabled="change_customer" />
                            
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12">
                            <label></label>
                           
                            <span style="float:right;color:#a7e1ec;">(* 장비가 지점에 등록되어 있는 경우, 앱에서 먼저 해제 후 장비 고객 변경 가능) </span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>Customer No</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['customer_no']" disabled></b-input>
                        </b-col>
                    </b-row>
       

                  
                    <b-row>
                        <b-col sm="4">
                            <label>M2 Point ID</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['m2_point_id']" disabled></b-input>
                        </b-col>
                    </b-row>

                  
                    <b-row>
                        <b-col sm="4">
                            <label>Firmware Version</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['firmware_ver']"  ></b-input>
                        </b-col>
                    </b-row> 
                     <b-row>
                        <b-col sm="4">
                            <label>Hardwre Version</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['board_ver']"  ></b-input>
                        </b-col>
                    </b-row>   
                                                                                                                                                    
                    <b-row>
                        <b-col sm="4">
                            <label>Register DateTime</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['register_date']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>Update DateTime</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['update_date']" disabled ></b-input>
                        </b-col>
                    </b-row>     
                                           
                  <b-row>
                        <b-col sm="4">
                            <label>Description</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['device_desc']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>Status</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select class="facility__input" size="sm" v-model="properties['device_status']" :options="f_status_option" ></b-select>
                        </b-col>
                    </b-row>    
                    <br/>                                                                      
                    <hr/>
                      <b-row class="facility-info-footer-row m-0 mb-3" style="padding : 30px 0px 30px 0px;">
                        <b-col sm="9">
                          <!--<b-button @click="$refs.modal.hide()">Close</b-button> -->
                          <!--b-button @click="new_device()" block class="facility-info-footer__button facility-info-footer__button-cancel">New Device</b-button-->
                        </b-col>
                        <b-col sm="3">
                          <b-button @click="apply()" block class="facility-info-footer__button facility-info-footer__button-ok">Save</b-button>
                        </b-col>
                      </b-row>
                </div>  
              </b-col>  
          </b-row>
        </div>
        <UploadDeviceCsv ref="upload_csv"/>
    </div>
</template>

<script>


import ServerSideTable from '@/components/table/ServerSideTable_device';
import UploadDeviceCsv from '@/components/popup/UploadDeviceCsv' ;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


export default {
    name: 'LogHistory',
    props: [
    ],
    watch: {
        s_customer_no() {      
            this.go_search();             
        },
        device_type(){
          this.go_search();
        }
    },
    data() {
        return {
            properties: [],
            change_customer : false,
            properties_bak: [],
            new_show : true,
            searchTerm: '',
            line_number : true,
            i_device_type :"",
            device_sn1:"",
            device_sn2:"",
            contents_height: 600,
            mnfchart_height: 300,
            realtimechart_height: 300,
            tag : this.$route.query.tag,
            tag_name : '',
            selected_ts_flow: '1',
            flow_date: '2020-11-01',
            loaded_ts_flow: false,
            selected_ts_mnf: '1',
            mnf_date: '2020-11-01',
            columns: [
                {label: 'device_sn', field: 'device_sn'},
                {label: 'customer_name', field: 'customer_name'},         
                {label: 'm2_point_id', field: 'm2_point_id'},             
                {label: 'Type', field: 'device_type'},
                {label: 'firmware_ver', field: 'firmware_ver'},
                {label: 'hardware_ver', field: 'board_ver'},
                {label: 'Register_Date', field: 'register_date'},
                {label: 'Update_Date', field: 'update_date'},
                {label: 'Status', field: 'device_status'},
                {label: 'Description', field: 'device_desc'},
                
            ],    
            columns1: [
                {label: 'Name', field: 'name'},             
                {label: 'Code', field: 'customer_no'},
                {label: 'License', field: 'sw_license_type'},
                {label: 'R_Date', field: 'register_datetime'},
                {label: 'S_Date', field: 'service_start_date'},
                {label: 'E_Date', field: 'service_end_date'},
            ],            
             f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            device_option: [
                { value: 'SM1', text: 'SM1' },
                { value: 'SM2', text: 'SM2' },
                { value: 'ILOG', text: 'ILogger' },
            ],            
            table_customer: [],
            device_type:'',
            s_customer_no:'',
            table_device: [],
            ts_mnf: [],
            activeCheckIndex: null,
            server_name:[{value:1, text :"KOREA SERVER"},
                         {value:2, text :"INDONESIA SERVER"},
                         {value:3, text :"BATNAM SERVER"},
                         {value:4, text :"TANZANIA SERVER"},
                        ],
            device_type_name : [{value:'', text :"ALL"},
                                {value:'SM1', text :"SM1"},
                                {value:'SM2', text :"SM2"},
                                {value:'ILOG', text :"I-LOGER"},
                               ],                                
            customer_name : [{value:'', text :"ALL"},
                            ], 
            i_customer_name : [],      
            i_customer_id : [],                                                                    
            server_id : 1,
            clicked_l_idx: -1,
            adv_search: [
            {
              field: 'leak_result',
              control: 'select',
              value: null,
              //'Leak','Leak Inside','Usage','Other'
              options: [
                  { value: null, text: 'Leak Result' },
                  { value: 'RT_LEAK', text: 'Leak' },
                  { value: 'NO_LEAK', text: 'No Leak' },
                
              ]
          },
            {field: 'measure_datetime', control: 'date', value: null, value2: null, placeholder: 'From measure_datetime', placeholder2: 'To measure_datetime'},
      ],
        }
    },
    methods: {
      goRouter(path) {
        this.$router.push({path: path, query: { tag: this.tag }})
      },
      getSelectedItem(){
          //this.i_device_type = this.properties['device_type'] 
          if(this.properties['device_type'] ==='ILOG'  ){
            this.i_device_type = 'SM2';
            this.device_sn1 = 'PL4';
          }else if(this.properties['device_type'] ==='SM1'){           
            this.i_device_type = 'SM1';
            this.device_sn1 = 'V3A';
          }else{
            this.i_device_type = 'SM2';
            this.device_sn1 = 'V3A';
          }
      },
      onRowClick(params) {
        //선택된 색상 보여지게
         this.new_show =true;
        if(this.clicked_b_row != null){
          this.clicked_b_row.bgColor = '';
        //  this.clicked_b_row.style.backgroundColor = '';
        }
      
        if(params.event.target.nodeName==='TD'){
            params.event.target.parentElement.bgColor  = '0e5340';          
            this.clicked_b_row = params.event.target.parentElement ;
        }else  if(params.event.target.nodeName==='IMG'){
            params.event.target.parentElement.parentElement.parentElement.bgColor = '0e5340';      
            this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
        }else{
            params.event.target.parentElement.parentElement.bgColor = '0e5340';      
            this.clicked_b_row = params.event.target.parentElement.parentElement ;
        }
  
        this.properties = params.row ;
        this.properties_bak = Object.assign({}, this.properties) ;
        var d_sn = this.properties['device_sn'].split("-")
        if(d_sn.length >2){
          this.i_device_type = d_sn[0]
          this.device_sn1 = d_sn[1];
          this.device_sn2 = d_sn[2];
        }      
        if(this.properties['m2_point_id'] === null){
          this.change_customer = false
        }else{
          this.change_customer = true
        }  
        this.clicked_l_idx = params.row.customer_no
       
    },
    upload_device(){
      this.$refs.upload_csv.show(this.i_customer_name,this.s_customer_no);
    },
    new_device(){
      //새로운 장비 등록
      this.new_show =false;
      this.properties = [] ;
      this.properties['customer_no'] = '082-042-000';
      this.properties['device_type'] = 'SM2' ;
      this.i_device_type = 'SM2' ;
      this.device_sn1 = 'V3A';
      this.device_sn2 = '';
      this.properties['device_status'] = 'A' ;
    },
    apply(){
      let params = {} ; 
      var data = {} ;
      let keys = Object.keys(this.properties_bak)
      if(this.properties['customer_no'] === undefined || this.device_sn1.length < 1 || this.device_sn2.length < 1 || this.properties['device_type'] === undefined) {
        alert("고객사선택, 유일한 serial no가 필수 입력 사항입니다.")
        return
      }
      if(!this.new_show){ //신규등록일시
        for(var i = 0 ; i < this.i_customer_name.length ; ++i ){
          var c_no =this.i_customer_name[i].value ;
          if(c_no === this.properties['customer_no']){
            this.properties['customer_id'] = this.i_customer_name[i].id;
            params['customer_id'] = this.i_customer_name[i].id;
          }
        }
        this.properties['device_sn'] = this.i_device_type+"-"+ this.device_sn1 +"-"+ this.device_sn2
        keys = Object.keys(this.properties)
        for(let i=0; i<keys.length; i++) {
         // if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
          if(keys[i] === 'customer_no' || keys[i] === 'customer_name') continue
          params[keys[i]] = this.properties[keys[i]]
        }       
        data = {type: 'add', params:params} ;
      }else{     
        for(let j=0; j<keys.length; j++) {
          if(this.properties_bak[keys[j]]===this.properties[keys[j]]) continue
          if(keys[j] === 'customer_no' ){
            for(var ii = 0 ; ii < this.i_customer_name.length ; ++ii ){
              var c_no1 =this.i_customer_name[ii].value ;
              if(c_no1 === this.properties['customer_no']){
                this.properties['customer_id'] = this.i_customer_name[ii].id;
                params['customer_id'] = this.i_customer_name[ii].id;                
              }
            }
            continue         
          } 
          if( keys[j] === 'customer_name') continue 
          params[keys[j]] = this.properties[keys[j]]
        }           
        data = {type: 'modify',id:this.properties["id"], params:params} ;
      }
      if(Object.keys(params).length<=0) return
       this.$http.get(this.URL+'api/add_modify_device?json='+encodeURIComponent(JSON.stringify(data))).then((res) => {
          console.log(res.data);
          if(res.data.check ===1){
            alert("fail : "+ res.data.msg) ;
          }else{
            this.go_search();
          }
      })
    },
    
    go_search() {
       //if(value===undefined) return;
                //left메뉴 데이터를 뿌려준다.
       //},
        this.new_show =true;
        this.table_device = []; 

        this.$refs.table_device.setUrl(this.URL+"api/get_device_list_ex?customer_no="+this.s_customer_no+"&type="+this.device_type, (rows1) => {
            if((!rows1)||(rows1.length<=0)) return;
            this.clicked_l_idx = rows1[0].id
            this.properties =  rows1[0];
            this.properties_bak = Object.assign({}, this.properties)
        })
      },
    },
    mounted () {
       this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175
      
       this.customer_name = [];    
       this.i_customer_name = [];     
       this.i_customer_id = [];     
         //  this.server_name = []         
        /*
        this.$http.get(this.URL+'api/get_server_list').then((rows) => {
          if((!rows)||(rows.data.list<=0)) return;
          for(let i=0; i<rows.data.list.length; i++) {
            this.server_name.push({value:rows.data.list[i].idx, text :rows.data.list[i].server_name}) ;
          }          
        })
        */
        this.$http.get(this.URL+'api/get_customer_list').then((rows) => {
          if((!rows)||(rows.data.list<=0)) return;
          this.table_customer = rows.data.list;
          for(let i=0; i<rows.data.list.length; i++) {
            
            if(i=== 0){
              //  this.$http.get(this.URL+'api/get_device_list_ex?customer_no='+rows.data.list[i].customer_no).then((rows1) => {
              //     this.table_device = rows1.data.list;
              //  })
                this.customer_name.push({value:'', text :'ALL'}) ; 
        
            }
            this.customer_name.push({value:rows.data.list[i].customer_no, text :rows.data.list[i].name}) ; 
            this.i_customer_name.push({value:rows.data.list[i].customer_no, text :rows.data.list[i].name,id:rows.data.list[i].id}) ; 
            
          }
         
        })
        this.$refs.table_device.setUrl(this.URL+"api/get_device_list_ex", (rows1) => {
          console.log(rows1)
          if((!rows1)||(rows1.length<=0)) return;
          this.clicked_l_idx = rows1[0].id
          this.properties =  rows1[0];
          this.properties_bak = Object.assign({}, this.properties)
          var d_sn = this.properties['device_sn'].split("-")
           if(d_sn.length >2){
            this.i_device_type = d_sn[0]
            this.device_sn1 = d_sn[1];
            this.device_sn2 = d_sn[2];
          }
          if(this.properties['m2_point_id'] === null){
            this.change_customer = false
          }else{
            this.change_customer = true
          }  
        })
       //left메뉴 데이터를 뿌려준다.
       this.box = [] ;
       var select_id= 0 ;

       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
               
               if(select_id>-1){
                  //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
               //   this.updateCheckIndex(select_id)
               }
           }
         }
       }, 500)
   
    },
    beforeDestroy () {
    },
    components: {  
      ServerSideTable: ServerSideTable,
      UploadDeviceCsv : UploadDeviceCsv,
    }
}
</script>

<style lang="scss">
.device_table .vgt-inner-wrap{
  overflow-y: hidden;
  height: 100%;
}
.presschart_m2_d .wavchart  {
  height: 350px;
  width: 98%;
}
.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // background: #fff;
  margin-bottom: 10px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}

//.specification_btn .btn-group-toggle .btn-group-toggle {
  //margin-left: 5px;
//}
.specification_btn .btn-secondary{
  float: left;
  width: 42px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  background: rgba(35,41,47,0.4);
  font-size: 14px;
  margin-right: .2em;
  cursor: pointer;
  border: 0;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background: rgba(35,41,47,0.8);
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}


.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 735px;
  overflow-y: scroll;
}
table.vgt-table.condensed td {
      padding: .188rem .15rem;
}
.btn_pre{
 position: absolute;
    top: 0;
    right: 110px;
   // background: #4C5055;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
 }
 .map-button.icon_btn.btn.btn-sm{
   padding-top: 4px;
 }
 .search_box.device {
    position: absolute;
    top: 58px;
    left: 30px;
    width:30%;
}
</style>

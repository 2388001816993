<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
<!--                        <li class="breadcrumb-title">Map Frequency Comparison</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/leak-recovery')" href="#">{{$t('leak_recovery.page_title')}}</a></li>
                        <!--li class="breadcrumb-item"><a @click="$router.push('/leak-recovery')" href="#">{{$t('leak_recovery.sub_page')}}</a></li-->
                        <li class="breadcrumb-item active">{{$t('leak_recovery.map_page')}}</li>
                    </ol>
                </li>
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div>
             <b-row style="margin: 0;">
                <b-col :cols="msize" style="padding: 0 10px 0 0;">
                    <!--div class="search_div">

                        <span class="spn1"><b-input size="sm" type="text" placeholder="Please enter your address" v-model="value"  @keyup.enter ="Enter_Check"/></span>
                        <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="go_search">search</button></span>
                    </div-->
                    <div style=" margin : 0px; padding-right: -5px;"  >
                    <div :style="{height: contents_height+'px'}">

                     <Map
                         ref="map"
                         :widx="$route.query.widx"
                         w_type="101"
                         :visible_gisload_button="true"
                         :visible_size_side_view_button="true"
                         :leak_l ="false"
                         :recovery_icon = "true" 
                        @set_map_gisload="set_map_gisload"
                        @set_info="set_info"
                        @toggle_visible_all_layer = "toggle_visible_all_layer"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @size_view="size_view"
                        @onLeakMapClick="onLeakMapClick()"
                     />
                      </div>
                     </div>
                 </b-col>
                 <b-col  v-if="v_chart" :cols="tsize" style=" padding: 0px 2px 0 0; " class="map_left">
                   <b-row style=" padding-left: 0px;background-color:#172a46; margin: 0px 10px 0 -5px; "  :style="{height: fftchart_height + 30 + 'px'}">
                       <b-col style="padding-top: 15px; padding-bottom: 5px;">

                           <!--
                           <button type="button" @click="play(1)" ><i class="mdi" :class="{'mdi-play': !is_playing1, 'mdi-pause':is_playing1}"></i></button>
                           <button type="button" @click="stop(1)"><i class="mdi mdi-stop"></i></button>
                           <button variant="outline-danger" @click="on_goto_map_click(1)"><i class="fas fa-arrow-left"></i></button>
                          <div id="hidden-container1" style="display: block;">
                              <div id="waveform_1" ref="waveform_1"/>
                          </div>-->
                          <!--

                           <audio controls preload="auto" :src="wav1" id="track1" style="width:60%;transform:scale(0.7);"/>
                            <div style="position:relative; left:30px; width: 95%;" id="waveform_1" ref="waveform_1"/>

                           <FFTChart1_c :url="fft_url1" ref="fftchart1" :height="fftchart_height"/>
                           -->
                           <div style=" position:absolute; top : 22px; left:10px;z-index:102; ">
                              <button variant="outline-danger" @click="on_leakage_info(lidx1)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg" /></button> 
                              <div style="font-size:10pt;"> {{lidx1}} </div>  
                           </div>
                            <div style=" position: relative;left:32%;width:53%;text-align:center;">    
                                <div style="float:left;">
                                    
                                    <span style="float:left;">  </span>
                                    <span style="float: right;padding-top:15px;padding-left:5px;">
                                    <b-input v-model="szoom"    style="width:50px;height:20px;margin:0 0 0 15px;padding:10px;text-align:right;" 
                                    type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                    v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                                    
                                    </span> 
                                </div>
                                <span  style="float:left;margin-left: -30px;" >                            
                                    <audio id="slice_audio_id" controls preload="auto" :src="slice_url" style="transform:scale(0.7);height: 50px;">
                                    </audio>
                                </span>
                            </div>        
                            <div class="status leak" style="">
                                <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                                <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                                    <span class="inner-bg">Y Axis</span> -->
                                            
                                <input type="radio" v-model="vchart" value="0" id="fixed"> <label for="fixed">{{$t('leakage_info.fixed')}}</label>
                                <input type="radio" v-model="vchart" value="1" id="proportional"><label for="proportional">{{$t('leakage_info.proportional')}}</label>
                            </div>
                            <div style='height:95px'>
                                <div id="hidden-container">
                                <div id="waveform" ref="waveform" style="float : left; margin-left: 35px; width:95%;"/>
                                <div id="wave-timeline" ref="wave-timeline" style="float : left; margin-left: 35px; width:95%;"/>
                                </div>
                            </div>
                                                        <!--FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:98%; overflow: auto;"  /-->
                            <div  :style="{height: fftchart_height -100 + 'px'}">
                            <pressChartData_fft :url="fft_url1" ref="fftchart" :height="fftchart_height-100" style="width:100%; overflow: auto;" @onZoomClickEvent="onZoomClickEvent"  />
                            </div>   
                       
                       </b-col>
                   </b-row>
               
                   <b-row style=" padding-left: 0px; background-color:#172a46; margin: 10px 10px 0 -5px;" :style="{height: fftchart_height- 30 + 'px'}">
                       <b-col style="padding-top: 20px; padding-bottom: 20px;">
 
                        <div class="facility-info1" >
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.columns.date')}}</label>
                                </b-col>
                                <b-col sm="3">                                  

                                    <b-input  class="facility__input" size="sm" v-model="properties['register_datetime']" disabled></b-input>

                                </b-col>
                        
                                <b-col sm="2">
                                    <label class="facility__label">{{$t('leak_recovery.columns.recovery_date')}}</label>
                                </b-col>
                                <b-col sm="4">

                                    <b-input  class="facility__input" size="sm" v-model="properties['recovery_datetime']" disabled ></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                 <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.columns.title')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['ma_title']" disabled ></b-input>
                                </b-col>
                                <b-col sm="2">
                                    <label class="facility__label">{{$t('leak_recovery.columns.contents')}}</label>
                                </b-col>
                                <b-col sm="4">

                                    <b-input  class="facility__input" size="sm" v-model="properties['ma_contents']" disabled ></b-input>

                                </b-col>                           
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.assumed')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['leak_amount_value']" disabled ></b-input>
                                </b-col>
                            
                                <b-col sm="2">
                                    <label class="facility__label">{{$t('leak_recovery.f_name')}}</label>
                                </b-col>
                                <b-col sm="4">

                                    <b-input  class="facility__input" size="sm" v-model="properties['layer_type']" disabled ></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.f_no')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['facility_id']" disabled ></b-input>
                                </b-col>
                                <b-col sm="2">
                                    <label class="facility__label">{{$t('leak_recovery.columns.worker')}}</label>
                                </b-col>
                                <b-col sm="4">

                                    <b-input  class="facility__input" size="sm" v-model="properties['worker']" disabled ></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('facility_info.material')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['material']" disabled ></b-input>

                                </b-col>                                
                                <b-col sm="2">
                                    <label class="facility__label">{{$t('facility_info.capacity')}}</label>
                                </b-col>
                                <b-col sm="4">

                                    <b-input  class="facility__input" size="sm" v-model="properties['capacity']" disabled ></b-input>

                                </b-col>
                            </b-row>
      
                          </div>  
 
                       </b-col>
                   </b-row>
                   <b-row style="  padding-left: 0px;background-color:#172a46; margin: 10px 0px 0 -5px;" :style="{height: fftchart_height+ 'px'}">
                       <b-col style="padding: 0px 6px 0 0px;overflow:auto;">
                    
                     <!--div style="overflow:auto; height:200px;width:680px;white-space: nowrap; text-align : margin : 0 auto ; " >
                       <p v-for="(item,i) in img_list" :key="i"  style="float:left;">
                          <b-img thumbnail fluid :src="item" alt="image" bottom  style=" width:220px; "></b-img>
                       </p>     
                    </div-->
                          <p v-for="(item,i) in img_list" :key="i" style="float:left;width:33.3%;height:100%;margin:0px;">
                                    <b-card
                                        no-body
                                        img-top
                                        class="photo-card"
                                    >
                                        <b-img thumbnail fluid :src="item" alt="image" bottom :style="{height: fftchart_height + 'px'}" ></b-img>
                                        <!--<b-img thumbnail fluid src="http://localhost:3000/api/get_img/leakage/6/1" alt="Image" bottom></b-img>-->
                                    </b-card>      
                          </p>          
                       </b-col>
                   </b-row>
                 </b-col>
            </b-row>
         </div>

    </div>
</template>

<script>

import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';
import WaveSurfer from 'wavesurfer.js';
//import FFTChart1_c from '@/components/FFTChart1_c';
import pressChartData_fft from '@/components/chart/pressChartData_fft';

export default {
    name: 'LeakRecoveryMap',
    props: {
    },
    data() {
        return {
            properties: [],
            properties_bak: [],
            v_chart : true,
            image_yn:true,
            contents_height: 600,
            waveform_height: 42,
            fftchart_height: 180,
            wavesurfer: null,
            layer_all_visible: true,   
            fft_url1: '',
            lidx1 :'',
            wav1 : '',
            options: {
            },
            file: ' ' ,
            msize:'7',
            tsize:'5',
            layer_type_option: [
                        {value: 0, text: 'Pipe'},
                        {value: 1, text: 'Valve'},
                        {value: 2, text: 'Water Meter'},
                        {value: 3, text: 'Pump'},
                        {value: 4, text: 'Hydrant'},
                        {value: 7, text: 'Block'},
                        {value: 8, text: 'Water Treatment Plant'},
                        {value: 9, text: 'Pumping Station'},
                        {value: 10, text: 'Water Basin'},
                        {value: 11, text: 'Chamber'},
                        {value: 12, text: 'Gauge'},
                        {value: 13, text: 'Other'},
                        ],
            f_type_option: [],
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            f_satus_level : true,
            material_option: [],   
            slice_url:'',
            szoom:50,
            img_list: [require('@/assets/noimage1.png'),require('@/assets/noimage1.png'),require('@/assets/noimage1.png')],            
            vchart: 0,          
        }
    },
    methods: {
        toggle_visible_all_layer() {
          this.$refs.map.init_reload_leak_layer() ;
        },

        fn_press_han(event)
        {
                var objTarget = event.srcElement || event.target;
            // if(objTarget.type == 'text') {
            
            var value = objTarget.value;
            //alert(value);
            if(isNaN(value)){
                objTarget.value =value.replace(/[^0-9]/g,'');
                // alert('Please enter only numbers');
                // event.preventDefault();          
            }        
        },                  
        search(aurl){
            this.$http.get(aurl, {
                  headers: {
                       'Authorization': 'KakaoAK 47347cfe6ea2ca698e3266af393511a1'
                    }
            }).then(response => {
                console.log('response', response)
                if(response.data.meta.total_count < 1 ){
                    if(aurl.indexOf("https://dapi.kakao.com/v2/local/search/keyword.json") !== -1)
                    alert("There is no data you are looking for.")
                    this.s_data = 0;
                     return
                }
                var posx = Number(response.data.documents[0].x);
                var posy = Number(response.data.documents[0].y);
                let data = [posx ,posy, posx, posy]

                this.$refs.map.set_fit_extent(data)     
                if(this.$store.setting.map_name==='Daum')
                this.$refs.map.map.getView().setZoom(13);
                else
                this.$refs.map.map.getView().setZoom(18);          
                this.s_data = 1;

            })

        },
 
        on_leakage_info(idx) {
          this.$refs.map.show_leakage_info_pop(idx)
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        on_goto_map_click(idx) {
            this.$refs.map.get_extent('w_type=100&l_idx='+idx).then((data)=>{
                this.$refs.map.set_fit_extent(data)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
        },
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                     if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    this.$refs.map.layer_list.push(newlayer) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);

                } //for문 끝

           } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },

        onLeakMapClick(){
            let leak_idx = this.$refs.map.leak_idx;
            let idx = this.$refs.map.leak_recov_idx;
            //leak_info를 쿼리해서 그 데이터를 wav,fft에 앉힌다.
            this.lidx1 = leak_idx;
            document.getElementById("hidden-container").style.display = 'none';
            this.wav1 = this.URL+'api/get_wav/'+leak_idx ;
            this.wavesurfer_1.load(this.URL+'api/get_wav/'+leak_idx);
            this.fft_url1 = this.URL+"api/get_fft/"+leak_idx+'#'+this.vchart;
            //이미지 가져오기
            //facility info정보가져오기
             this.properties =[] ;
             this.img_list =[];
 
             this.$http.get(this.URL+'api/get_leak_reco_info?idx='+idx).then((res) => {
                if(res.data.length<=0) return


                //this.properties = res.data.list[0];
                let reco_info = res.data.list[0];
              //  let f_idx = reco_info.facility_id;           
                  
                 if(reco_info.image_list === null || reco_info.image_list  === undefined){

                    this.img_list.push(require('@/assets/noimage1.png')) 
                    //this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
                    this.img_list.push(require('@/assets/noimage1.png'));
                    this.img_list.push(require('@/assets/noimage1.png'));
                  
                }else{
                  var img = reco_info.image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
                }

                this.properties = res.data.list[0];
        

                

             });
        },
        size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
        },
        create_wavesurfer(){
            this.wavesurfer_1 = WaveSurfer.create({
                container: '#waveform',
                waveColor: 'blue',
                progressColor: 'black',
            // autoCenter: true,
                mediaControls: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [
                ]
            });
            this.wavesurfer_1.on('finish', () => {
                this.is_playing1 = false
            });
           
            this.wavesurfer_1.on("loading", function () {
                document.getElementById("hidden-container").style.display = '';
                var mediaElt1 = document.getElementById("waveform");
                var mediaElt = mediaElt1.querySelector('audio');
                mediaElt.style = "width:40%;height:50px;transform:scale(0.7);position: absolute;left:0px; top:15px;border:0; outline:0;" ;
            //  this.wavesurfer.params.mediaControls = true;
            });
           
            //
         

        },
         onZoomClickEvent(){
            var s_min = this.$refs.fftchart.select_min; //09-03 이렇게 들어온다
            var s_max = this.$refs.fftchart.select_max; //09-03 이렇게 들어온다
            //  this.min_value = s_min;
            //  this.max_value = s_max;
            this.slice_url = this.URL+'api/get_wave_slice?idx='+this.lidx1+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
            // this.slice_url = 'http://dev.neverlosewater.com/node/api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
         },

    },
    mounted () {
        this.contents_height = window.innerHeight - 120;
        this.fftchart_height =  this.contents_height/3 - 10 ;
        setTimeout(()=>{
            this.toggle_visible_all_layer();   
       }, 500)   
       document.getElementById("hidden-container").style.display = 'none';
        this.create_wavesurfer();
        this.fft_url1 = this.URL+"api/get_fft/-1";
    
    },
    watch: {
      v_chart: function() {
          if(this.v_chart){
               // document.getElementById("hidden-container1").style.display = 'block';
              //  document.getElementById("hidden-container2").style.display = 'block';
              //  document.getElementById("hidden-container3").style.display = 'block';
               setTimeout(()=>{
                   this.create_wavesurfer() ;
                   this.wavesurfer_1.load(this.wav1);
            }, 500)

          }
      },
      vchart() {
     
         var c_url = this.fft_url1.split('#') ;
         this.fft_url1 = c_url[0]+'#'+this.vchart ;
    }
    },
    components: {
        Map: Map,
      //  FFTChart1_c: FFTChart1_c,
        pressChartData_fft: pressChartData_fft,
    }
}
</script>

<style lang="scss">
.infoimg{
    width: 24px;
}
.img{
    width:33%;
}
.search_div{
    position: absolute;
    left:360px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}
.map_left{  
  height:calc(100vh - 75px - 27px - 8px - 0px);
  /*overflow-y: scroll;*/
}
//.facility-info1{
//    height: 310px;
  //  overflow-y: auto; 
 //   overflow-x: hidden;

//}
</style>

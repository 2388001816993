<template>
  <div class="hello">
    <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733"  />
    <!--button @click="generateXLSX">Gerar XLSX</button-->
  </div>
</template>

<script>
import ExcelJS from "exceljs";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: "HelloWorld",
  props: {
    msg: String, 
   
  },
  data() {
    return {
      workbook : null,
      loaded_ts_flow: true,
      water_leak :{
             "id": 181310,
            "water_work_id": 2699,
            "w_title": "소양",
            "f_idx": 2379451,
            "midx": 388,
            "worker": "cwlim@wiplat.com",
            "m_id": "emmanuel@wiplat.com",
            "device_sn": "SGL-V1F-0081",
            "layer_type": "Water Meter",
            "measure_start_datetime": "24-07-26 12:03:52",
            "measure_end_datetime": "2024-07-26 12:03:55",
            "period": 3,
            "file_name": "20240726_12_03_52_127.23877877906521_35.847316051105345.wav",
            "file_path": "API21/FILE/67db2de2-9ca6-4911-918b-5699fa7737cd",
            "wave_avg": 0.15,
            "wave_max": 3.1441635887521304,
            "wave_max_frequency": 160,
            "wave_energy": 1.23,
            "wave_energy_cal": 1.23,
            "deviation": 0.16,
            "last_item_id": 181314,
            "remarks": null,
            "pressure_reading": null,
            "cut_position": 0.8,
            "shape": {
                "x": 127.23877877906521,
                "y": 35.847316051105345
            },
            "X": 127.23877877906521,
            "Y": 35.847316051105345,
            "leak_result_": "",
            "ground": "",
            "dataset": "",
            "probability": "",
            "ai_leak_result": "N",
            "image": 3,
            "image_list": "API21/FILE/572369a5-df56-47cc-bf1a-bc9392b9a286,API21/FILE/95ac8043-3051-4189-ba0b-9acb2e422b83,API21/FILE/d0b95817-773f-4fd2-b6a4-a439b1434f73",
            "fft_file_path": "API21/FILE/ccf3fb98-fa99-4e76-be99-14556e514912?outType=fftToJson"       
      },
    }
  },
  methods: {
    async download_report(rows){
      this.loaded_ts_flow = false
      const options = {
        filename: "./streamed-workbook.xlsx",
        useStyles: true,
        useSharedStrings: true,
      };     

      const wb = new ExcelJS.Workbook(options);
      this.workbook = wb
      await this.addSheet(rows)
      var final = rows.length -1
      const fileName = "report_leak_"+rows[0].id+"~"+rows[final].id+".xlsx";
      let promise = [];
      try {
         Promise.all(promise).then(() => {
          this.workbook.xlsx.writeBuffer().then((b) => {
          // let a = new Blob([b], { type: 'text/plain;charset=utf8' });
          let a = new Blob([b],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let url = window.URL.createObjectURL(a);

            let elem = document.createElement('a');
            elem.href = url;
            elem.download = fileName;
            elem.click();
            elem.remove();
            this.loaded_ts_flow = true
          });
        });
      } catch (error) {
         this.loaded_ts_flow = true
      }     
     
    },
    async addSheet(rows) {
      const ws = this.workbook.addWorksheet('Sheet1', {
          pageSetup: {
            paperSize: 9,
            orientation: 'portrait',
            horizontalCentered: true,
          },
        });
      ws.pageSetup.margins = {
          left: 0.7,
          right: 0.7,
          top: 1.25,
          bottom: 0.75,
          header: 0.3,
          footer: 0.3,
      };

      for(var i = 0 ; i < rows.length ; ++i){
        //페이지를 추가한다.
        await this.addPage(ws,i*9, rows[i])
      }

    },
    async addPage(ws,row_start, item){ //1,10,19

      //row_start
      this.water_leak = item

      //이미지 리스트에 담기
      var imaglist = [null,null,null,null]

      if(this.water_leak.image_list !== null)
          imaglist = this.water_leak.image_list.split(',')   



      for(var i = imaglist.length ; i < 4 ; ++i){
         imaglist.push(null)
      }
      //var s_url = 'https://kr.neverlosewater.com/node/api/'
      var s_url = this.URL+'api/'
      const myBase64Image1 = imaglist[0] !== null ? await this.toDataURL(s_url+imaglist[0]) : null;
      const myBase64Image2 = imaglist[1] !== null ? await this.toDataURL(s_url+imaglist[1]) : null;
      const myBase64Image3 = imaglist[2] !== null ?await this.toDataURL(s_url+imaglist[2]) : null;
      const myBase64Image4 = imaglist[3] !== null ?await this.toDataURL(s_url+imaglist[3]) : null;

      var date = this.water_leak.measure_start_datetime
      var date_t = date.split(" ");

      if(date_t.length > 1){
        date = date_t[0]
      }
      let excelData = [];
      excelData.push(
        {
          data: ['수도미터 및 수용가 대장'],
        },
      {
      data: [ ],
      },
        {
          data: ['수도미터\n현   황','업종','가정용/일반','구경(15mm)','주소',this.water_leak.w_title]
        },
        {
          data: ['','점검날짜',date,'공급방식','광역 상수도']	
        },
        {
          data: ['','수도지침(㎥)',this.water_leak.pressure_reading,'지반고(m)' ,'']		
        },
        {
          data: [ '기타 및 특이사항','', '강도 값 : ' + this.water_leak.wave_energy+'  (누수ID:' + this.water_leak.id+'/시설물ID:' + this.water_leak.f_idx+')' ]		
        }
      );

      excelData.forEach((item, index) => {
        ws.getRow(row_start+ 1 + index ).values = [...item.data];
      });
      //전체적인 스타일을 준다.
      /*
      const headerStyle = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'cce6ff' },
      };
      */
      const headerBorderStyle = {
        top: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      } ;

      //모든 컬럼 선 긋기
      for(let a = 3 ; a <= 8 ; a++ ) {
        for (let i = 1; i <= 6 ; i++) {
          const cssCell = ws.getCell(`${String.fromCharCode(i + 64)}`+(row_start+a));
        //  cssCell.fill = headerStyle;
          cssCell.border = headerBorderStyle;
          cssCell.alignment = { horizontal: 'center', vertical: 'middle' };
        }
      }
      // 셀 병합
      ws.mergeCells('A'+(row_start+1)+':F'+(row_start+2)); //제목
      ws.mergeCells('A'+(row_start+3)+':A'+(row_start+5));
    //  ws.mergeCells('A'+(3+row_start)+':A'+(5+row_start)); // 수도미터현황
      ws.mergeCells('E'+(4+row_start)+':F'+(4+row_start)); // 수도미터현황
      ws.mergeCells('E'+(5+row_start)+':F'+(5+row_start)); // 수도미터현황
      ws.mergeCells('A'+(6+row_start)+':B'+(6+row_start)); // 기타 및 특이사항
      ws.mergeCells('C'+(6+row_start)+':F'+(6+row_start)); // 기타 및 특이사항
      ws.mergeCells('A'+(7+row_start)+':C'+(7+row_start)); //사진1
    
      ws.mergeCells('D'+(7+row_start)+':F'+(7+row_start));//사진2
      ws.mergeCells('A'+(8+row_start)+':C'+(8+row_start));//사진3
      ws.mergeCells('D'+(8+row_start)+':F'+(8+row_start));//사진4  

         // 폰트 사이즈, 굵기, 색상 변경
      ws.getCell('A'+(1+row_start)).font = { size: 18, bold: true, color: { argb: '000000' } };

      // 정렬
      ws.getCell('A'+(1+row_start)).alignment = { horizontal: 'center', vertical: 'middle',wrapText: true };
      ws.getCell('A'+(3+row_start)).alignment = {  vertical: 'middle',wrapText: true, horizontal: 'center', };
      /*
      ws.mergeCells('A1:F2'); //제목
      ws.mergeCells('A3:A5'); // 수도미터현황
      ws.mergeCells('E4:F4'); // 수도미터현황
      ws.mergeCells('E5:F5'); // 수도미터현황
      ws.mergeCells('A6:B6'); // 기타 및 특이사항
      ws.mergeCells('C6:F6'); // 기타 및 특이사항
      ws.mergeCells('A7:C7'); //사진1
    
      ws.mergeCells('D7:F7');//사진2
      ws.mergeCells('A8:C8');//사진3
      ws.mergeCells('D8:F8');//사진4
      
      // 폰트 사이즈, 굵기, 색상 변경
      ws.getCell('A1').font = { size: 18, bold: true, color: { argb: '000000' } };

      // 정렬
      ws.getCell('A1').alignment = { horizontal: 'center', vertical: 'middle',wrapText: true };
      ws.getCell('A3').alignment = {  vertical: 'middle',wrapText: true, horizontal: 'center', };
     
      // 색 칠하기
 
      // 테두리
      ws.getCell('A1').border = {
        top: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
       */
      // 가로 길이
      const col = [];


      for (let i = 0; i < excelData.length; i++) {
        col.push({
          key: 'data',
          width: 12.5,
        });
      }
      ws.columns = col;

      ws.getColumn(5).width=6
      ws.getColumn(6).width=20
      // 세로 길이
      for (let i = 1; i <= excelData.length; i++) {
        let row = ws.getRow(i+row_start);
        row.height = 30.75;
      }

      ws.getRow(7+row_start).height = 240 ;
      ws.getRow(8+row_start).height = 240 ;
      //이미지 로딩
      // add image to workbook by base64
      //var myBase64Image = "data:image/png;base64,iVBORw0KG...";

      //alert(myBase64Image)
      if(myBase64Image1 !== null ){
        const imageId1 = this.workbook.addImage({
          buffer: myBase64Image1,
          extension: 'jpeg',
        });
        ws.addImage(imageId1, {
          tl: { col: 0.3, row: 6.04+row_start },
          ext: { width: 290, height: 300 },
          editAs: 'oneCell', 
        });
      }
      if(myBase64Image2 !== null ){
        const imageId2 = this.workbook.addImage({
          buffer: myBase64Image2,
          extension: 'jpeg',
        });

        ws.addImage(imageId2, {
          tl: { col: 3.5, row: 6.04 +row_start },
          ext: { width: 290, height: 300 },
          editAs: 'oneCell', 
        }); 
      }
      if(myBase64Image3 !== null ){
        const imageId3 = this.workbook.addImage({
          buffer: myBase64Image3,
          extension: 'jpeg',
        });
        ws.addImage(imageId3, {
          tl: { col: 0.3, row: 7.04 +row_start},
          ext: { width: 290, height: 300 },
          editAs: 'oneCell', 
        });
      }
      if(myBase64Image4 !== null ){
        const imageId4 = this.workbook.addImage({
          buffer: myBase64Image4,
          extension: 'jpeg',
        });
        ws.addImage(imageId4, {
          tl: { col: 3.5, row: 7.04 +row_start },
          ext: { width: 290, height: 300 },
          editAs: 'oneCell', 
        });
      }
    },

    async generateXLSX() {
      // var  myBase64Image = null
      // var s_url = 'https://kr.neverlosewater.com/node/api/API21/FILE/517e4d62-e519-40fa-ab6d-01a76a93ea7d'
   
      const myBase64Image1 = await this.toDataURL('https://kr.neverlosewater.com/node/api/API21/FILE/517e4d62-e519-40fa-ab6d-01a76a93ea7d')
      const myBase64Image2 = await this.toDataURL('https://kr.neverlosewater.com/node/api/API21/FILE/517e4d62-e519-40fa-ab6d-01a76a93ea7d')
      const myBase64Image3 = await this.toDataURL('https://kr.neverlosewater.com/node/api/API21/FILE/517e4d62-e519-40fa-ab6d-01a76a93ea7d')
      const myBase64Image4 = await this.toDataURL('https://kr.neverlosewater.com/node/api/API21/FILE/517e4d62-e519-40fa-ab6d-01a76a93ea7d')
      const options = {
        filename: "./streamed-workbook.xlsx",
        useStyles: true,
        useSharedStrings: true,
      };
     
      //const workbook = new ExcelJS.Workbook(options);
      

      // Force workbook calculation on load
      //workbook.calcProperties.fullCalcOnLoad = true;

      //worksheet.autoFilter = 'A1:C1';

       const wb = new ExcelJS.Workbook(options);
      //const wb = new ExcelJS.Workbook();
      /*
      wb.creator = "LAB";
      wb.lastModifiedBy = "LAB";
      wb.created = new Date(2024, 7, 26);
      wb.modified = new Date();
      wb.lastPrinted = new Date(2024, 7, 26);      
      */
      const ws = wb.addWorksheet('Sheet1', {
        pageSetup: {
          paperSize: 9,
          orientation: 'portrait',
          horizontalCentered: true,
        },
      });
      ws.pageSetup.margins = {
        left: 0.7,
        right: 0.7,
        top: 1.25,
        bottom: 0.75,
        header: 0.3,
        footer: 0.3,
      };

      //ws.pageSetup.printArea = 'A1:F8';

      var date = this.water_leak.measure_start_datetime
      var date_t = date.split(" ");

      if(date_t.length > 1){
        date = date_t[0]
      }
      let excelData = [];
      excelData.push(
        {
          data: ['수도미터 및 수용가 대장'],
        },
      {
      data: [ ],
      },
        {
          data: ['수도미터\n현   황','업종','가정용','구경(15mm)','주소',this.water_leak.w_title]
        },
        {
          data: ['','점검날짜',date,'공급방식','지방상수도']	
        },
        {
          data: ['','수도지침(㎥)',this.water_leak.pressure_reading,'지반고(m)' ,'']		
        },
      {
          data: [ '기타 및 특이사항','', '강도 값 : ' + this.water_leak.wave_energy ]		
        }
      );

      excelData.forEach((item, index) => {
        ws.getRow(index + 1).values = [...item.data];
      });
      //전체적인 스타일을 준다.
      /*
      const headerStyle = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'cce6ff' },
      };
      */
      const headerBorderStyle = {
        top: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      } ;

      //모든 컬럼 선 긋기
      for(let a = 3 ; a <= 8 ; a++ ) {
        for (let i = 1; i <= 6 ; i++) {
          const cssCell = ws.getCell(`${String.fromCharCode(i + 64)}`+a);
        //  cssCell.fill = headerStyle;
          cssCell.border = headerBorderStyle;
          cssCell.alignment = { horizontal: 'center', vertical: 'middle' };
        }
      }
      // 셀 병합
      ws.mergeCells('A1:F2'); //제목
      ws.mergeCells('A3:A5'); // 수도미터현황
      ws.mergeCells('E4:F4'); // 수도미터현황
      ws.mergeCells('E5:F5'); // 수도미터현황
      ws.mergeCells('A6:B6'); // 기타 및 특이사항
      ws.mergeCells('C6:F6'); // 기타 및 특이사항
      ws.mergeCells('A7:C7'); //사진1
    
      ws.mergeCells('D7:F7');//사진2
      ws.mergeCells('A8:C8');//사진3
      ws.mergeCells('D8:F8');//사진4

      // 폰트 사이즈, 굵기, 색상 변경
      ws.getCell('A1').font = { size: 18, bold: true, color: { argb: '000000' } };

      // 정렬
      ws.getCell('A1').alignment = { horizontal: 'center', vertical: 'middle',wrapText: true };
      ws.getCell('A3').alignment = {  vertical: 'middle',wrapText: true, horizontal: 'center', };

      // 색 칠하기
      /*
      ws.getCell('A4').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'e6f0fe' },
      };
     */
      // 테두리
      ws.getCell('A1').border = {
        top: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };

      // 가로 길이
      const col = [];


      for (let i = 0; i < excelData.length; i++) {
        col.push({
          key: 'data',
          width: 12.5,
        });
      
      }
      ws.columns = col;
      ws.getColumn(5).width=6
      ws.getColumn(6).width=20
      // 세로 길이
      for (let i = 1; i <= excelData.length; i++) {
        let row = ws.getRow(i);
        row.height = 30.75;
      }

      ws.getRow(7).height = 240 ;
      ws.getRow(8).height = 240 ;
      //이미지 로딩
      // add image to workbook by base64
      //var myBase64Image = "data:image/png;base64,iVBORw0KG...";

      //alert(myBase64Image)
      const imageId1 = wb.addImage({
        buffer: myBase64Image1,
        extension: 'jpeg',
      });
      ws.addImage(imageId1, {
        tl: { col: 0.3, row: 6.04 },
        ext: { width: 290, height: 300 },
        editAs: 'oneCell', 
      });
      const imageId2 = wb.addImage({
        buffer: myBase64Image2,
        extension: 'jpeg',
      });
      ws.addImage(imageId2, {
        tl: { col: 3.5, row: 6.04 },
        ext: { width: 290, height: 300 },
        editAs: 'oneCell', 
      }); 
      const imageId3 = wb.addImage({
        buffer: myBase64Image3,
        extension: 'jpeg',
      });
      ws.addImage(imageId3, {
        tl: { col: 0.3, row: 7.04 },
        ext: { width: 290, height: 300 },
        editAs: 'oneCell', 
      });
      const imageId4 = wb.addImage({
        buffer: myBase64Image4,
        extension: 'jpeg',
      });
      ws.addImage(imageId4, {
        tl: { col: 3.5, row: 7.04 },
        ext: { width: 290, height: 300 },
        editAs: 'oneCell', 
      });
      const fileName = "sample.xlsx";
      //await workbook.xlsx.write(fileName);
      //workbook.xlsx.writeFile(fileName).then(() => {
      //  console.log("WRITE");
      //});
       let promise = [];
       Promise.all(promise).then(() => {
          wb.xlsx.writeBuffer().then((b) => {
          // let a = new Blob([b], { type: 'text/plain;charset=utf8' });
          let a = new Blob([b],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let url = window.URL.createObjectURL(a);

            let elem = document.createElement('a');
            elem.href = url;
            elem.download = fileName;
            elem.click();
            elem.remove();
          });
        });

    },

    async toDataURL2(s_url) {
      const image = 'data:image/jpeg;base64,/9j/4AAQSk...'; // Replace with your actual base64 image data
      try {
        var response = await this.$http.post(s_url, { image })
        return response.data
       } catch (error) {
        console.error('에러 발생:', error);
        return null
      }  
    
    },
    async toDataURL(s_url) {
   
      try {
        var response = await this.$http.get(s_url, { responseType: 'arraybuffer' })
        return Buffer.from(response.data, 'binary');
       } catch (error) {
        console.error('에러 발생:', error);
        return null
      }  
    
    },
    async toDataURL1(s_url) {
      alert(s_url)
      //s_url ='https://kr.neverlosewater.com/node/api/API21/FILE/517e4d62-e519-40fa-ab6d-01a76a93ea7d'
      try {
        const response = await this.$http.get(s_url, { responseType: 'blob' }); // 이미지 URL 호출
        if (!response.ok) {
          throw new Error('이미지를 불러오는데 문제가 발생했습니다.');
        }
        //const blob = await response.blob(); // 이미지 데이터를 Blob으로 변환
        //const blob = response.blob(); // 이미지 데이터를 Blob으로 변환
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Image = reader.result; // 이미지를 Base64로 변환
          console.log('Base64 이미지:', base64Image);
          return base64Image
          // 여기서 필요한 작업을 수행하세요 (예: 화면에 표시, 파일에 저장 등)
        };
        reader.readAsDataURL(response);
      } catch (error) {
        console.error('에러 발생:', error);
        return null
      }
    },
  
    //데이터 셋팅 값
    set_reportData(item){
      this.water_leak = item
    },
  },
   components: {
        PulseLoader: PulseLoader,
     
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

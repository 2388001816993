<template>
    <div>
          <!-- top-bar -->
        <div class="navbar-custom">

                <ul class="list-unstyled topnav-menu topnav-menu-left">
                    <li>
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                          <li class="breadcrumb-item active">Pumping Station</li>
                        </ol>
                    </li>
                    <li class="setting-btn">
                                <b-button size="sm" class="float-right" @click="show_setting">
                                    <i class="fas fa-cog"></i>
                                </b-button>
                            </li>
                </ul>
        </div>
    <!-- tom-bar-end -->
      <div class="monitoring_box">
        <div class="monitoring_wrap">
          <div class="input_search_box">
            <input type="text" class="input_search"  v-model="value" placeholder="Search..."  @keyup.enter ="Enter_Check">
            <input type="submit" class="input_submit" @click="go_search" > 
          </div>
          <div class="info_box">
            <ul class="list">
              <!--<li>전체 가압장수 : 3개  /  최대 이용률 : 80% (000 배수지)  /  최소 이용률 : 30% (000 배수지)  /  기준일자 : 2020.09.01 -->
                <li>전체 가압장수 : {{total}}개  /  기준일자 : {{last_date}}
              </li>
            </ul>
          </div>
        </div>
        <div class="table_wrap block-monitoring" :key="listKey">
          <fragment v-for="item in ts_monitoring_list" :key="item.pump_id">
            <pumpCard :dma_name="item.pump_name" :tag="item.pump_id">
            </pumpCard>
          </fragment>
        </div>
        </div>

    </div>
</template>

<script>
import pumpCard from '@/components/card/pumpCard';
import { Fragment } from 'vue-fragment'


export default {
    name: 'PumpingMonitoring',
    props: [
    ],
    watch: {
    },
    data() {
        return {
            columns: [
                {label: 'Tag', field: 'pump_id'},
                {label: 'Name', field: 'pump_name'}
            ],
            ts_monitoring_list: [],
            ts_flow_tag_list: [],
            temp_ts_monitoring_list: [],
            value:'',
            listKey: 0,
            total : 0,
            last_date:'0001-01-01'

        }
    },
    methods: {
        show_setting() {
            this.temp_ts_monitoring_list = [...this.ts_monitoring_list]
            this.$refs.modal.show();
        },
        updateKey: function () {
          this.listKey+= 1
       },
        handleOk() {
            this.ts_monitoring_list = [...this.temp_ts_monitoring_list]
            let param = []
            for(let i=0; i<this.ts_monitoring_list.length; i++) param.push(this.ts_monitoring_list[i].tag)
            this.$http.get(this.URL+'api/set_monitoring_list?list='+param)
        },
        add_monitoring() {
            let rows = this.$refs.flow_tag_table.selectedRows
            if(rows.length<=0) return;
            for(let i=0; i<rows.length; i++) {
                this.temp_ts_monitoring_list.push({tag: rows[i].tag, name: rows[i].name})
            }
            this.del_dup_list(this.temp_ts_monitoring_list, this.ts_flow_tag_list)
        },
        del_monitoring() {
            let rows = this.$refs.ts_monitoring_table.selectedRows
            if(rows.length<=0) return;
            for(let i=0; i<rows.length; i++) {
                this.ts_flow_tag_list.push({tag: rows[i].tag, name: rows[i].name})
            }
            this.del_dup_list(this.ts_flow_tag_list, this.temp_ts_monitoring_list)
        },
        del_dup_list(a, b) {
            for(let i=0; i<a.length; i++) {
                let idx = b.findIndex(obj => obj.tag==a[i].tag);
                if(idx<=-1) continue
                b.splice(idx, 1)
            }
        },
         go_search() {

          if(this.value===undefined) return;
          this.updateKey();
          this.ts_monitoring_list=[];
         
       //  this.$http.get(this.URL+'api/get_pump_list_left?search='+this.value).then((res) => {
       this.$http.get(this.URL+'api/API21/pump/list?customer_id='+sessionStorage.select_customer_id+'&search='+this.value).then((res) => {
            this.ts_monitoring_list = res.data.results[0];         
        });
       
     },
      Enter_Check(){
       //  if(event.keyCode == 13){
         this.go_search();  // 실행할 이벤트
    }
    },
    mounted () {
        //this.$http.get(this.URL+'api/get_pump_list_left').then((res) => {
        this.$http.get(this.URL+'api/API21/pump/list?customer_id='+sessionStorage.select_customer_id).then((res) => {
            this.ts_monitoring_list = res.data.results[0];
            this.total = res.data.results[0].length ;
            if(this.total > 0){
              this.last_date = res.data.results[0][0].date_time.split(' ')[0] 
            }
           // this.$http.get(this.URL+'api/get_tag_list?type=flow').then((res) => {
          //      this.ts_flow_tag_list = res.data.rows
              //  this.del_dup_list(this.ts_monitoring_list, this.ts_flow_tag_list)
          //  });
        });
    },
    beforeDestroy () {
    },
    components: {
        pumpCard,
        Fragment
    }
}
</script>

<style lang="scss">

</style>

import { render, staticRenderFns } from "./SearchLeftType3_pressL.vue?vue&type=template&id=126c5c4a&scoped=true"
import script from "./SearchLeftType3_pressL.vue?vue&type=script&lang=js"
export * from "./SearchLeftType3_pressL.vue?vue&type=script&lang=js"
import style0 from "./SearchLeftType3_pressL.vue?vue&type=style&index=0&id=126c5c4a&prod&lang=css"
import style1 from "./SearchLeftType3_pressL.vue?vue&type=style&index=1&id=126c5c4a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "126c5c4a",
  null
  
)

export default component.exports
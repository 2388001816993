<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                   <li>
                     <ol class="breadcrumb with--map">
                       <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>                      
                       <li class="breadcrumb-item active">Worker Log</li>
                     </ol>
                   </li>
                 
           
              </ul>
         </div>
     <!-- tom-bar-end -->
    

        <div class="right_area_01 right_area_block-detail" >
          <b-row>
              <b-col  >
                  <!--b-row style="margin: 0;">
                      <b-col style="padding: 0;">
                        <div class="specification_area" style=" background-color:white; padding: 20px 30px 10px 10px;height:420px;">
                              <div class="log_box" >
                                <span class="title_value">CUSTOMER</span>
                              </div>
                    
                                
                              <div style="background-color:white; padding: 20px 5px;">
                                <vue-good-table ref="table_customer"
                                    :columns="columns"
                                    :rows="table_customer"
                                    :line-numbers="true"
                                    :max-height="contents_height/2-30 +'px'"
                                    :fixed-header="true"
                                    styleClass="vgt-table condensed"
                                    @on-row-click="onRowClick"
                                      
                                 />
                              </div>
                                                  
                          </div>
                      </b-col>
                  </b-row-->
                  <b-row style="margin: 0px 0 5px 0;"   >
                      <b-col style="padding: 0;background-color:#172a46;">
                          <div class="specification_area1" style="  padding: 20px 20px 10px 20px ;">
                          <div class="log_box" >
                                 <span class="title_value" style="font-size:15px;">Worker Last work time</span>
                          </div>
                            <div style="position:absolute; top: -50px; right: 0px; width:350px;padding:10px;">
                                  <!--span  >SERVER NAME : <b-select  size="sm" v-model="server_id" :options="server_name" style="width:200px;"></b-select>
                                  </span-->
                            </div>
                            <div style="position:absolute; top: 10px; right: 0px; width:380px;padding:10px;background:#f2f2f;">
                                <span style="padding: 10px 20px;"  >CUSTOMER  <b-select  size="sm" v-model="s_customer_no" :options="customer_name" style="margin: 0 15px;width:200px;padding: 0 10px; background: #aebfcf;" ></b-select>
                                </span>
                            </div>    
                              <!-- <div style="background-color:white; padding: 20px 5px;" :style="{height: contents_height+50+'px'}"> -->
                              <div style="padding: 20px 5px;" :style="{height: contents_height+50+'px'}">
                                <vue-good-table ref="table_member"
                                    :columns="columns1"
                                    :rows="table_member"
                                    :line-numbers="true"
                                    :max-height="contents_height-10+'px'"
                                    :fixed-header="true"
                                    @on-row-click="onRowClick"
                                    styleClass="vgt-table without--head-right-line type01 admin">
                                    <template slot="table-row" slot-scope="props" >                                      
                                      <span v-if="props.column.field == 'info'" class="table_icon" @click="on_info_click(props.row)"><img src="@/assets/images/new/ic_table_info.svg" alt="info" ></span>
                <!--                        <b-button size="ss" v-if="props.column.field == 'go_map'" @click="$emit('on_goto_map_click', props.row)" class="table_icon">-->
                <!--                          <img src="../assets/images/Common/table/map_icon.png" alt="map"></b-button>-->
                <!--                        <b-button size="ss" variant="outline-info" v-else-if="props.column.field == 'info'" @click="$emit('on_info_click', props.row.idx)" ><i class="fas fa-info"></i></b-button>-->
                                    </template>
                                </vue-good-table>
                              </div>
                                                  
                          </div>

                      </b-col>
                  </b-row>
              </b-col>
              <!-- <b-col cols="6" style="background-color:white;margin-bottom:5px;" > -->
              <b-col cols="6" style="margin-bottom:5px; background-color:#172a46;" >
                <div class="log_box" style="padding: 20px 5px 0 10px; "  >
                  <span class="title_value" style="font-size:16px;margin-bottom:15px;">Worker Log - Name : {{tag_name}}</span>
                      <div class="search_date" style="padding: 10px 10px 0 10px ;">
                        <span class="spn1"><b-row>
                           <b-col cols="2"  style="margin-top:5px;">
                             검색 기간 
                           </b-col> 
                            <b-col cols="4">
                                <b-input v-model="sdate" type="date" />
                            </b-col>
                            <b-col cols="1">
                                <span>~</span>
                            </b-col>
                            <b-col cols="4">
                                <b-input v-model="edate" type="date" />
                            </b-col>
                            <b-col cols="2" style="margin-top:3px;">
                              <button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" style="background:#0e5340;"
                                @click="date_search">search</button>
                            </b-col>
                        </b-row></span>
                      </div>
                </div>
                <!-- <div style="background-color:white; padding: 20px 10px;"  :style="{height: contents_height+50+'px'}"> -->
                <div style=" padding: 40px 10px;"  :style="{height: contents_height+'px'}">
                  <!--<vue-good-table ref="table"
                      :columns="columns"
                      :rows="table_log"
                      :line-numbers="true"
                      :max-height="contents_height+ 70 +'px'"
                      :fixed-header="true"
                        styleClass="vgt-table condensed"
                  /> -->
                
                    
                    <ServerSideTable ref="table_log" 
                                  :line-numbers="true"
                                   :fixed-header="true"
                                   :columns="columns2"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"                                   
                                   :select="true"  
                                   :search="false"   
                                   :add_custom_column="true" 
                                   :leak_total_cnt = "true" 
                                   :perpage_cnt = 20
                                   :max_height="contents_height-50+'px' "      
                                   styleClass="vgt-table without--head-right-line type01 admin"                          
                               
                  />
                 </div>
              </b-col>
          </b-row>
        </div>
        <DlgPwSettings ref="dlg_pw_setting1" />
    </div>
    
</template>

<script>
import DlgPwSettings from "@/components/popup/DlgPwSettings";
import * as d3 from 'd3'
import d3tip from "d3-tip";
import ServerSideTable from '@/components/table/ServerSideTable2';
//import PressMinChart from '@/components/PressMinChart' ;

 d3.tip = d3tip;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


export default {
    name: 'LogHistory',
    props: [
    ],
    watch: {
       s_customer_no() {
         this.go_search();             
       },
    },
    data() {
        return {
            bar_url : '',
            real_url : '',
            contents_height: 600,
            mnfchart_height: 300,
            realtimechart_height: 300,
            tag : this.$route.query.tag,
            tag_name : '',            
            selected_ts_flow: '1',
            sdate: '2020-11-01',
            loaded_ts_flow: false,
            selected_ts_mnf: '1',
            s_customer_no:'',
            customer_name : [{value:'', text :"ALL"},
                            ],                 
            edate: '2020-11-01',
            columns: [
                          
                {label: 'Name', field: 'name'},             
                {label: 'Code', field: 'customer_no'},
                {label: 'Country', field: 'country_no'},
                {label: 'Region', field: 'region_no'},
                {label: 'License', field: 'sw_license_type'},
                {label: 'R_Date', field: 'register_datetime'},
                {label: 'S_Date', field: 'service_start_date'},
                {label: 'E_Date', field: 'service_end_date'},
            ],    
            columns1: [
                {label: 'Info', field: 'info'},   
                {label: 'Name', field: 'name'},             
                {label: 'Worker ID', field: 'email'},
                {label: 'Customer', field: 'department'},
                {label: 'Last Work Time', field: 'recent_work_date'},
              
            ],            
            columns2: [
                {label: 'Time', field: 'register_date'},
                {label: 'Customer', field: 'department'},
                {label: 'Work Title', field: 'title'},
                {label: 'Leak Count', field: 'count'},
               
            ],      
            table_customer: [],           
            table_member: [],
            table_log: [],
            ts_mnf: [],
            activeCheckIndex: null,
            server_name:[{value:1, text :"KOREA SERVER"},
                         {value:2, text :"INDONESIA SERVER"},
                         {value:3, text :"BATNAM SERVER"},
                         {value:4, text :"TANZANIA SERVER"},
                        ],
            server_id : 1,
            clicked_l_idx: -1,
            clicked_b_row:null,
            searchTerm: '',
            adv_search: [
            {
              field: 'leak_result',
              control: 'select',
              value: null,
              //'Leak','Leak Inside','Usage','Other'
              options: [
                  { value: null, text: 'Leak Result' },
                  { value: 'RT_LEAK', text: 'Leak' },
                  { value: 'NO_LEAK', text: 'No Leak' },
                
              ]
          },
            {field: 'measure_datetime', control: 'date', value: null, value2: null, placeholder: 'From measure_datetime', placeholder2: 'To measure_datetime'},
       ],
        }
    },
    methods: {
      goRouter(path) {
        this.$router.push({path: path, query: { tag: this.tag }})
      },
      onRowClick(params) {
      //선택된 색상 보여지게
      //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
        if(this.clicked_b_row != null){
          this.clicked_b_row.bgColor = '';
      //  //  this.clicked_b_row.style.backgroundColor = '';
        }
    
      if(params.event.target.nodeName==='TD'){
          params.event.target.parentElement.bgColor  = '0e5340';          
          this.clicked_b_row = params.event.target.parentElement ;
      }else  if(params.event.target.nodeName==='IMG'){
           params.event.target.parentElement.parentElement.parentElement.bgColor = '0e5340';      
          this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
      }else{
          params.event.target.parentElement.parentElement.bgColor = '0e5340';      
          this.clicked_b_row = params.event.target.parentElement.parentElement ;
      }
      
/*
     if(this.clicked_b_row != null){
       this.rowStyleClassFn(params.row, 1);
     }
      this.rowStyleClassFn(params.row, 0);
 */    
    //  this.clicked_b_row = params.row
  
      this.clicked_l_idx = params.row.id ;
      this.tag_name = params.row.name ;
      this.table_log = []; 
      var datequery = '' ;
      if(params.row.recent_work_date.length >9 ){
          this.sdate = params.row.recent_work_date.substr(0,7)+'-01' ;
          this.edate = params.row.recent_work_date.substr(0,10) ;
          datequery = '&startDate='+this.sdate+'&endDate='+this.edate ;
      }
      this.$refs.table_log.setUrl(this.URL+'api/work_count?member_id='+this.clicked_l_idx+datequery, (rows1) => {
          if((!rows1)||(rows1.length<=0)) return; 
      })
    },


    date_search(){
      this.table_log = []; 
      var datequery = '&startDate='+this.sdate+'&endDate='+this.edate ;
      this.$refs.table_log.setUrl(this.URL+'api/work_count?member_id='+this.clicked_l_idx+datequery, (rows1) => {
         if((!rows1)||(rows1.length<=0)) return;
      })
    },

    go_search() {
        //if(value===undefined) return;
              //left메뉴 데이터를 뿌려준다.
        this.table_member = [] ;
        this.$http.get(this.URL+'api/worker_status?customer_id='+this.s_customer_no).then((rows) => {
            if((!rows)||(rows.data.result.length<=0)) return;
          // for(let i=0; i<rows.data.result.length; i++) {
              this.table_member = rows.data.result;
              if((!rows)||(rows.data.result.length<=0)) return;
              var datequery = '' ;
            // for(let i=0; i<rows.data.result.length; i++) {
                if(rows.data.result[0].recent_work_date.length >9 ){
                  this.sdate = rows.data.result[0].recent_work_date.substr(0,7)+'-01' ;
                  this.edate = rows.data.result[0].recent_work_date.substr(0,10) ;
                  datequery = '&startDate='+this.sdate+'&endDate='+this.edate ;
                }
                this.table_member = rows.data.result;
                this.clicked_l_idx = rows.data.result[0].id
                this.tag_name = rows.data.result[0].name
                this.table_log = []; 
                this.$refs.table_log.setUrl(this.URL+'api/work_count?member_id='+rows.data.result[0].id+datequery, (rows1) => {
                    if((!rows1)||(rows1.length<=0)) return;
                  
                    
                })
            
          })     
        },   
        on_info_click(row) {
          //alert("id==>"+row.id)
          this.$refs.dlg_pw_setting1.admin_show(row.email);
         // this.$refs.map.show_leakage_info(row.id)
        },
    },
    mounted () {
       this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175
       
       this.customer_name = []         
         //  this.server_name = []         
        /*
        this.$http.get(this.URL+'api/get_server_list').then((rows) => {
          if((!rows)||(rows.data.list<=0)) return;
          for(let i=0; i<rows.data.list.length; i++) {
            this.server_name.push({value:rows.data.list[i].idx, text :rows.data.list[i].server_name}) ;
          }          
        })
        */
        
        this.$http.get(this.URL+'api/get_customer_list').then((rows) => {
          if((!rows)||(rows.data.list<=0)) return;
          for(let i=0; i<rows.data.list.length; i++) {
            this.table_customer = rows.data.list;
            if(i=== 0){
              this.customer_name.push({value:'', text :'ALL'}) ; 
            }
            this.customer_name.push({value:rows.data.list[i].id, text :rows.data.list[i].name}) ; 
          }
          
        })
        this.$http.get(this.URL+'api/worker_status').then((rows) => {
          if((!rows)||(rows.data.result.length<=0)) return;
          var datequery = '' ;
         // for(let i=0; i<rows.data.result.length; i++) {
            if(rows.data.result[0].recent_work_date.length >9 ){
               this.sdate = rows.data.result[0].recent_work_date.substr(0,7)+'-01' ;
               this.edate = rows.data.result[0].recent_work_date.substr(0,10) ;
               datequery = '&startDate='+this.sdate+'&endDate='+this.edate ;
            }
            this.table_member = rows.data.result;
            this.clicked_l_idx = rows.data.result[0].id;
            this.tag_name = rows.data.result[0].name;
             //   this.$http.get(this.URL+'api/work_count?worker_id='+rows.data.result[0].id).then((rows1) => {
             //      this.table_log = rows1.result;
             //   })
            this.table_log = []; 

            this.$refs.table_log.setUrl(this.URL+'api/work_count?member_id='+rows.data.result[0].id+datequery, (rows1) => {
                if((!rows1)||(rows1.length<=0)) return;
               
                
            })
           
          // }
          
        })
         
   
       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
               
               //if(select_id>-1){
                  //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
               //   this.updateCheckIndex(select_id)
               //}
           }
         }
       }, 500)
   
    },
    beforeDestroy () {
    },
    components: {  
      ServerSideTable: ServerSideTable,
      DlgPwSettings: DlgPwSettings,
    }
}
</script>

<style lang="scss" >
.presschart_m2_d .wavchart  {
  height: 350px;
  width: 98%;
}
table.type01.admin th ,
table.type01.admin td {
  font-size: 14px;
}
.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // background: #fff;
  margin-bottom: 10px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}

.specification_btn .btn-group-toggle + .btn-group-toggle {
  //margin-left: 5px;
}
.specification_btn .btn-secondary{
  float: left;
  width: 42px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  background: rgba(35,41,47,0.4);
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
  border: 0;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background: rgba(35,41,47,0.8);
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}



.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 735px;
  overflow-y: scroll;
}
table.vgt-table.condensed td {
      padding: .188rem .15rem;
}
.btn_pre{
 position: absolute;
    top: 0;
    right: 110px;
   // background: #4C5055;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
 }
 .map-button.icon_btn.btn.btn-sm{
   padding-top: 4px;
 }
 .search_date {
    position: absolute;
    left: 30px;
    top: 55px;
    margin-top:20px;
    z-index: 110;
}
</style>

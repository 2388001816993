<template>
<!--  <div>-->
 

    <div class="items">
    <div class="item-head">
      <div class="item-head--left">
        <h3 style="letter-spacing: -0.12rem;font-size:23px;">{{ name }}</h3>
        <p class="date">{{ last_mea_date }}</p>
      </div>
      <div class="item-head--right" style="display: flex;">
        <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="go_m2_info"
             style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
        <img src="@/assets/images/new/ic_list_map.svg" alt="" @click="goRouter('/leak-monitoring-detail-map?w_type=102')"
             style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
        <img src="@/assets/images/new/ic_menu_chevron.svg" alt="" @click="goRouter('/leak-monitoring-detail')"
             style="width: 24px; height: 24px; cursor: pointer">
      </div>
    </div>
    <div class="item-content">
      <ul class="item-content--left">
        <li>
          <span class="unit m2_press">Strength</span>
          <p class="num">{{ now_flow }}</p>
          
        </li>
      </ul>

      <div class="item-content--right">
                      <!-- <p style="float:right;"> -->
                      <p>
                        {{dma_name}} /{{battery}}% /   <img :src="item" style="width:15px;padding-bottom:5px;" alt="">
                      </p>  
                      <div class="status_circle" style=" right:0px; top:100px;">
                        
      

                        <!-- <div class="circle color_01">#1</div>
                        <div class="circle color_01">#2</div>
                        <div class="circle color_01">#3</div>
                        <div class="circle color_02">#4</div>
                        <div class="circle color_03">#5</div> -->

                        <!-- <div :class=color1><span>0</span></div>
                        <div :class=color2><span>1</span></div>
                        <div :class=color3><span>2</span></div>
                        <div :class=color4><span>3</span></div>
                        <div :class=color5><span>4</span></div> -->
                        <ul>
                          <li><a :class=color1 href="#">0</a></li>
                          <li><a :class=color2 href="#">1</a></li>
                          <li><a :class=color3 href="#">2</a></li>
                          <li><a :class=color4 href="#">3</a></li>
                          <li><a :class=color5 href="#">4</a></li>
                        </ul>
                      </div>
                      <div class="btn_next" @click="goRouter('/leak-monitoring-detail')"></div>
      </div>
      </div>
      <!-- <div class="item-content--right">
        <div style="float:right;" >
          {{dma_name}} /{{battery}}% /   <img :src="item" style="width:15px;padding-bottom:5px;" alt="">
       </div>
        <div class="status_circle" style=" right:0px; top:100px;">

          <div class="circle color_01">#1</div>
          <div class="circle color_01">#2</div>
          <div class="circle color_01">#3</div>
          <div class="circle color_02">#4</div>
          <div class="circle color_03">#5</div>

          <div :class=color1>0</div>
          <div :class=color2>1</div>
          <div :class=color3>2</div>
          <div :class=color4>3</div>
          <div :class=color5>4</div>
        </div>
        <div class="btn_next" @click="goRouter('/leak-monitoring-detail')"></div>
      </div>
    </div>
    <DlgM2MoniSetting ref="m2_setting"/>
  </div> -->


 
<!--  <div class="table_block"  >-->
<!--      <div class="title_box">-->
<!--      <h3>{{ name }}</h3> -->
<!--        <div style="float: right; margin-top: -49px; margin-right: -25px;"  @click="go_m2_info"  >-->
        <!-- 2022 new -->
             <!-- <img src="@/assets/images/Common/icon/setting.png" alt=""> -->
<!--              <img src="@/assets/images/new/ic_list_info.svg" alt="">-->
<!--         </div>-->
<!--          <div style="float: right; margin-top: -49px; margin-right: -65px;"  @click="goRouter('/leak-monitoring-detail-map?w_type=102')"> -->
            <!-- 2022 new -->
           <!-- <img src="@/assets/images/Common/icon/map_1.png" alt=""> -->
<!--            <img src="@/assets/images/new/ic_list_map.svg" alt="">-->
<!--          </div>-->
<!--         <div class="btn_next" @click="goRouter('/leak-monitoring-detail')" ></div>-->
<!--      </div>-->
<!--      -->
<!--     -->
<!--      <div>  -->
<!--        <div class="title_con">-->
<!---->
<!--          <div class="title_text_wrap">-->
<!--            <div class="title_text">-->
<!--              <p class="num" style="height: 34px;width: 50%;">{{ now_flow }}</p>-->
<!--              <span class="unit m2_press">Strength</span>-->
<!--            </div>-->
<!--            <p class="date">{{last_mea_date}}</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      -->
<!--    <div class="con_box">-->
<!--        <div style="float:right;" >-->
<!--          {{dma_name}} /{{battery}}% /   <img :src="item" style="width:15px;padding-bottom:5px;" alt="">-->
<!--        </div>-->
      <!-- 2022 new -->
          <!-- <div class="status_circle" style=" left:190px;top: 90px;"> -->
<!--          <div class="status_circle" style=" right:0px; top:100px;">-->
                       <!---->
<!--                        <div class="circle color_01">#1</div>-->
<!--                        <div class="circle color_01">#2</div>-->
<!--                        <div class="circle color_01">#3</div>-->
<!--                        <div class="circle color_02">#4</div>-->
<!--                        <div class="circle color_03">#5</div>-->
<!--                        -->
<!--                        <div :class=color1>0</div>-->
<!--                        <div :class=color2>1</div>-->
<!--                        <div :class=color3>2</div>-->
<!--                        <div :class=color4>3</div>-->
<!--                        <div :class=color5>4</div>-->
<!--         </div>-->
<!--      </div>-->
<!--     -->
<!--    </div>-->
<!---->
<!--    </div>-->



</div>
    
</template>

<script>

export default {
    name: 'MonitoringCard',
    props: [
        'dma_name',
        'tag'
    ],
    data() {
        return {
            now_flow: '-',
            last_mea_date: null,
            name :'',
            battery:'-',
            now_mnf: null,
            flow: ['0','0','0','0'],
            mnf: ['0','0','0','0'],
            rate:['0','0','0','0'],
            title_color: 'red',
            color1: '' ,color2: '' ,color3: '' ,color4: '' ,color5: '' ,
            item : require('@/assets/images/new/ic_battery.svg')
        }
    },
    methods: {
        goRouter(path) {
            this.last_mea_date = this.last_mea_date.substring(0,16);
            this.$router.push({path: path, query: { id: this.tag,mea_date:this.last_mea_date }})
        },
        go_m2_info(){
          this.$emit('go_m2_info',  this.tag)
        }
    },
    mounted () {
        this.title_color = 'white'
     //   this.$http.get(this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&limit=5').then((res) => {
        this.$http.get(this.URL+'api/API21/m2_leak/list/'+this.tag+'?customer_id='+sessionStorage.select_customer_id).then((res) => {
          if(res.data.results[0][0].last_measure_datetime !== null){
            this.now_flow = res.data.results[0][0].wave_energy === null ? '-' :  res.data.results[0][0].wave_energy;
            this.last_mea_date = res.data.results[0][0].last_measure_datetime ;
            this.name = res.data.results[0][0].point_name;
           
            if(res.data.results[0][0].comm_sense != null){
              this.battery = res.data.results[0][0].battery ;
              this.item =  require('@/assets/images/sub/m2/signal'+res.data.results[0][0].comm_sense+'.png')            
            }
          }
        });
        //this.$http.get(this.URL+'api/API21/m2_leak/day/'+encodeURI(this.tag)+'?limit=1').then((res) => {
         this.$http.get(this.URL+'api/API21/m2_leak/day/'+encodeURI(this.tag)).then((res) => {   
            this.flow = [' ',' ',' ',' ']
            this.mnf = []
            this.rate = []
            //for(let i=0; i<res.data.rows.length; i++) {
             if(res.data.results[0].length < 1) return; 

             if(res.data.results[0][0].day0 === 1 )
                 this.color1 = 'circle color_01';
             else if(res.data.results[0][0].day0 === 0 )  
                 this.color1 = 'circle color_02';
             if(res.data.results[0][0].day1 === 1 )
                 this.color2 = 'circle color_01';
             else if(res.data.results[0][0].day1 === 0 )  
                 this.color2 = 'circle color_02';
             if(res.data.results[0][0].day2 === 1 )
                 this.color3 = 'circle color_01';
             else if(res.data.results[0][0].day2 === 0 )  
                 this.color3 = 'circle color_02';
             if(res.data.results[0][0].day3 === 1 )
                 this.color4 = 'circle color_01';
             else if(res.data.results[0][0].day3 === 0 )  
                 this.color4 = 'circle color_02';                    
             if(res.data.results[0][0].day4 === 1 )
                 this.color5 = 'circle color_01';
             else if(res.data.results[0][0].day4 === 0 )  
                 this.color5 = 'circle color_02';                    

             /*
                this.flow = []
                var roof = res.data.results[0].length > 5 ? 5 :res.data.results[0].length;
                for(var i = 0 ; i < roof ;++i ){
                  var  a = roof - i -1;
                  if(i === 0){
                    if(res.data.results[0][a].ai_leak_result === "LEAK" || res.data.results[0][a].ai_leak_result > 50)
                      this.color1 = 'circle color_01';
                    else  
                      this.color1 = 'circle color_02';
                  }
   
                if(i === 1){
                    if(res.data.results[0][a].ai_leak_result === "LEAK" || res.data.results[0][a].ai_leak_result > 50)
                      this.color2 = 'circle color_01';
                    else  
                      this.color2 = 'circle color_02';
                }
                
                if(i === 2){
                    if(res.data.results[0][a].ai_leak_result === "LEAK" || res.data.results[0][a].ai_leak_result > 50)
                      this.color3 = 'circle color_01';
                    else  
                      this.color3 = 'circle color_02';
                }
                
                if(i === 3){
                    if(res.data.results[0][a].ai_leak_result === "LEAK" || res.data.results[0][a].ai_leak_result > 50)
                      this.color4 = 'circle color_01';
                    else  
                      this.color4 = 'circle color_02';
                  
                }
                if(i === 4){
                    if(res.data.results[0][a].ai_leak_result === "LEAK" || res.data.results[0][a].ai_leak_result > 50)
                      this.color5 = 'circle color_01';
                    else  
                      this.color5 = 'circle color_02';
                }
            }
            */

        });

    },
    beforeDestroy () {
    },
    components: {
    }
}


</script>

<style scoped lang="scss">
.table_block {
  &:nth-child(3n) {
    margin-right: 0;
  }
 // background-color:#dde9f3 ;
  background-color:#94999d ;
}
.title_box h3{
  color: #fff;
}
.items {
  padding: 16px;
  // height: 280px;
  box-sizing: border-box;
  border: 1px solid #707070;

  .item-head {
    display: flex;
    justify-content: space-between;

    &--left {
      h3 {
        font-size: 24px;
        color: white;
        line-height: 1;
        margin: 0 0 6px 0;

      }

      p.date {
        margin: 0;
        font-size: 14px;
        color: white;
        opacity: .6;
      }
    }
  }

  .item-content {
    display: flex;
    // padding-top: 16px;

    &--left {
      width: 212px;
      padding-top: 32px;

      li {
        font-family: 'Roboto', sans-serif;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      span.unit {
        font-size: 15px;
        line-height: 1;
        color: white;
      }
      p.num {
        font-size: 44px;
        font-weight: bold;
        color: #01AF81FF;
        line-height: 1;
      }
    }

    &--right {
      // width: 100%;
      // position: relative;
      position: absolute;
      width: 200px;
      right: 16px;
      bottom: 16px;

      .btn_next {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
      }
      p {
          // margin-top: 0;
          // margin-bottom: 0.5rem;
          position: absolute;
          bottom: 30px;
          right: 9px;
          margin-bottom:0;
      }

    }
  }
}
      .status_circle > div {
    // float: left;
    width: 11.5%;
    font-size: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    width: 24px; 
    
}
// .status_circle > li a, 
.status_circle > div::after {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #707070;
    display: block;
    position: absolute;
    left: 50%;
    top: 20px;
    -webkit-transform: translate(-45%,0);
    transform: translate(-45%,0);
}
.status_circle > div {
    // float: left;
    width: 11.5%;
    font-size: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    width: 24px; 
    
}
// .status_circle > li a, 
.status_circle > div::after {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #707070;
    display: block;
    position: absolute;
    left: 50%;
    top: 20px;
    -webkit-transform: translate(-45%,0);
    transform: translate(-45%,0);
}
.status_circle ul{
  display: flex;
    padding-left: 0;
    list-style: none;
    position: absolute;
    bottom: 0;
    right: 0;
}
.status_circle ul li{
  display: list-item;
    text-align: -webkit-match-parent;
    margin-right: 6px;
    list-style: none;
}
.status_circle ul li a{
  display: block; 
    line-height: 0.6 !important;
    position: relative;
    display: block;
    padding: 9px;
    font-size: 12px;
    z-index: 3;
    color: #fff;
    border-radius: 20px;
    background-color: #707070;
    // border-color: #28AF81;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    line-height: 0.6 !important;
    line-height: 0.6 !important;
}
.status_circle .color_01{
                    background: #EB5B5B;
                }
.status_circle .color_02{
                    background: #638CFB;
                }

.items{
  background-color: #172A46;
}

// .status_circle > li, 



// .status_circle .color_01::after{
//                     background: #EB5B5B;
//                 }
// .status_circle .color_02::after{
//                     background: #638CFB;
//                 }


//  .status_circle > li{
//      display: list-item;
//     text-align: -webkit-match-parent;
//     margin-right: 6px;
//     display: flex;
//     padding-left: 0;
//     list-style: none;
//     float: left;
//   }

</style>

<template>
    <div>
        <b-modal
            ref="modal"
            :title="$t('gis.dlg_search.title')"
            centered
            >
            <div ref="content" v-if="typeof(adv_search)!=='undefined'" class="modal-content-content">
                <div v-for="(item, index) in adv_search" :key="index">
                    <div v-if="item.control==='select'">
                        <b-select v-model="item.value" :options='item.options' @change="change($event, index)"/>
                    </div>
                    <div v-else-if="item.control==='range'">
                        <b-row>
                            <b-col cols="5">
                                <b-input v-model="item.value" :placeholder="item.placeholder" class="modal__input"/>
                            </b-col>
                            <b-col cols="2" class="text-center pt-1">
                                <span>~</span>
                            </b-col>
                            <b-col cols="5">
                                <b-input v-model="item.value2" :placeholder="item.placeholder2"  class="modal__input"/>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-else-if="item.control==='date'">
                        <b-row>
                            <b-col cols="5">
                                <b-input v-model="item.value" type="date" :placeholder="item.placeholder"/>
                            </b-col>
                            <b-col cols="2">
                                <span>~</span>
                            </b-col>
                            <b-col cols="5">
                                <b-input v-model="item.value2" type="date" :placeholder="item.placeholder2"/>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
              <b-row class="modal-footer-row m-0">
                <b-col sm="6">
                  <b-button block @click="$refs.modal.hide()" class="modal-footer__button modal-footer__button-cancel">{{$t('button.cancel')}}</b-button>
                </b-col>
                <b-col sm="6">
                  <b-button block @click="handleOk()" class="modal-footer__button modal-footer__button-ok">{{$t('button.ok')}}</b-button>
                </b-col>
              </b-row>
            </template>
      </b-modal>
    </div>
</template>

<script>


export default {
    name: 'DlgSearch',
    props: [
        'adv_search'
    ],
    data() {
        return {
        }
    },
    methods: {
        show() {
            this.$refs.modal.show();
        },
        change(e, index) {
            this.$emit('change', e, index)
        },
        handleOk() {
            this.$emit('handleOk')
            this.$refs.modal.hide()
        }
    },
    mounted () {
    },
    components: {
    }
}
</script>

<style lang="scss">
.modal-dialog {
  .modal-footer-row {
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
  }
}
.modal-content-content {
    // 2022 new
//   background: #f2f2f2;
  background: #172A46;;
  padding: 36px 28px 16px 28px;
  .custom-select {
    margin-bottom: 10px;
    color:#fff;
    background: #0A192F;;
    border: 1px solid #707070;
  }
}

</style>

<template>
  <highcharts id= "wavchart" :options="chartOptions" :class="size" ></highcharts>
</template>

<script>
export default {
  name: 'BlockLineChart',
   props:{
      xdata: {
        type: Array,
      },
      chartData: {
        type: Array
      },
      interval : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
       sdate : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
      size : { type:String},
      y_title : { type:String},
      chart_type : { type:String}
  },
  data () {
    return {
  
       chartOptions: {
     
         colorAxis:[{lineColor:"#434348"}],
         colors : ["#5580b4"],
         credits: {
          text: '',
          href: ''
        },

        series: [{
          name: 'Water Flow (㎥/hr)',
          showInLegend: false,
          followPointer: true,
         // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
          data: this.chartData,
          type:this.chart_type,
          lineWidth: 1.0,
       
         // pointStart: Date.UTC(2018, 1, 1,0,0,0,0),
         // pointStart: this.sdate,
        //  pointInterval: 100,
        //  pointInterval: this.interval, //
         //  pointInterval: 4000/20480, //
          
        }],
         exporting: {
              buttons: { contextButton: { enabled: false} }
         },    
          tooltip: {
            valueDecimals: 2,
            shared: true,
            crosshairs: true
          },
           boost: { enabled: true },
          //dateTimeLabelFormats:{millisecond : {main:"%S.%L"}},
            plotOptions: {
                  area: {
                      fillColor: {
                        linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1
                        },
                        stops: [
                          [0,'#d6e8f9'],
                          [1,'#b5d6f5']
                        ]
                      },
                 },
                /*
                column: {
                        pointPadding: 0.4,
                        borderWidth: 1.0
                  }
                */
        
        
          },
       // }],      
        title: {        text: '', },
         chart: {   zoomType: 'xy' ,
                
                
                },
         /*
         tooltip: {
                formatter: function() {
                        return '<b>'+ this.series.name +'</b><br/>'+
                        this.dateFormat('%Y-%m-%d %H:%M:%S', this.x) +'<br/>'+
                        this.numberFormat(this.y, 2);
                }
            },
            */
        yAxis : {title:{text:'Water Flow (㎥/hr)',margin:30, style : {fontSize: '1.0em', color: '#c6c6c6' }},
                  gridLineColor: '#2a4369',
                  labels: {
                      align: 'left',
                      x: -20,
                      y: 4,
                      format: '{value:.,0f}',                    
                    },
               //   tickInterval : 10  
                min:0,  
                },   
        xAxis: { type: 'datetime',
        title : {text:'Datetime', style : {fontSize: '1.0em', color: '#c6c6c6' }}, 
            /*
                labels: {
                formatter: function() {
                  return Highcharts.dateFormat('Year:%y <br/> Month:%e<br /> %H:%M:%S:%L', this.value);

               
                },
              }  
              */
              }
    },

    
       
    }
  }
}
</script>
<style lang="scss" scoped>

.wavchart.firstClass .highcharts-container {
   width:100%;
}
.wavchart {
  min-width: 450px;
  max-width: 1600px;
  width:100%;
  margin: 0 auto;
}

.wavchart.firstClass .highcharts-container .highcharts-root {
    width: 98%;    
    margin: 0 auto;
}
.firstClass{   height: calc(40vh - 20px) ; margin: 0 auto; padding-top: 20px;  }
.firstClass2{   height: calc(30vh - 40px) ; margin: 0 auto;  padding-top: 10px;  }
.firstClass1{   height: calc(70vh - 40px) ; margin: 0 auto;max-width: 1800px;  padding-top: 10px;  }

</style>
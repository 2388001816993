<template>
    <div>
       <b-modal
            ref="modal"
            modal-class="modal-group"
            title="Groups Management"
            >
   
            <b-row style="margin: 0 10px;">
            <!--
              <b-col >
                  <b-row style="margin: 0;">
                      <b-col style="padding: 0;">
                        <div class="specification_area" style=" background-color:white; padding: 20px 30px 10px 10px;height:810px;">
                              <div class="log_box" >
                                <span class="title_value">CUSTOMER</span>
                              </div>
                          
                                
                              <div style="background-color:white; padding: 20px 5px;">
                                <vue-good-table ref="table_customer"
                                    :columns="columns1"
                                    :rows="table_customer"
                                    :line-numbers="true"
                                     :max-height="contents_height+ 50 +'px'"   
                                    :fixed-header="true"
                                    styleClass="vgt-table condensed"
                                    @on-row-click="onRowClick"
                                      
                                 />
                              </div>
                                                  
                          </div>
                      </b-col>
                  </b-row>

              </b-col>
             --> 
              <b-col cols="7" style="margin: 0 ; padding: 0 5px; background-color:#3b4d7c33;">
    
         
                <div style=" margin: 0 5px; padding: 20px 20px;">
                
                    <!--ServerSideTable ref="group_table" 
                                  :line-numbers="false"
                                   :fixed-header="true"                                   
                                   :columns="columns"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"      
                                   :adv_search="adv_search"                             
                                   :select="true"  
                                   :search="true"                                
                                   :max_height="contents_height-40 +'px'"  
                                    @on-row-click="onRowClick"                              
                              
                  /-->
                  <h5 style="color:#fff;">Group List</h5>
                   <vue-good-table ref="group_table"
                        :columns="columns"
                        :rows="table_group"
                        :line-numbers="true"
                        max-height='500px'
                        :fixed-header="true"  
                        @on-row-click="onRowClick"                    
                        styleClass="vgt-table without--head-right-line type01"
                   />
                 </div>
              </b-col>
            
              <b-col  cols="5" style=" padding: 0 5px; ">
               <b-tabs content-class="press_detail group"     style="margin-top: 0px;background-color:#3b4d7c33;">
                 <b-tab :title="$t('facility_info.properties')" style=" padding:0;" active> 
   
               
                <div style="padding: 0px 30px 0 40px;  " >
                  <div class="log_box" style="padding: 40px 5px 10px 0px; " >
                   <span style="float: right;padding: 10px; margin-right: -20px;margin-top:-20px;">
                      <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue" @click="add_group">Add Group</button></span>
                      
                  </span>
                  <h5 style="color:#fff;">Group Properties</h5>                 
                 
                   <hr/>
                 </div>
                    <b-row >
                        <b-col sm="5">
                            <label>ID</label>
                        </b-col>
                        <b-col sm="7">
                            <b-input size="sm" v-model="properties['groupId']" disabled></b-input>
                        </b-col>
                    </b-row>
                   
                    <b-row>
                        <b-col sm="5">
                            <label>Customer Name</label>
                        </b-col>
                        <b-col sm="7">                           
                            <b-input size="sm" v-model="properties['customerId']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="5">
                            <label>Group Name</label>
                        </b-col>
                        <b-col sm="7">
                            <b-input size="sm" v-model="properties['groupName']" ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="5">
                            <label>waterPressure Count</label>
                        </b-col>
                        <b-col sm="7">                           
                            <b-input size="sm" v-model="properties['waterPressureCount']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="5">
                            <label>Register Datetime</label>
                        </b-col>
                        <b-col sm="7">
                            <b-input size="sm" v-model="properties['register_datetime']" disabled></b-input>
                        </b-col>
                    </b-row>             
                 
                    <!--b-row>
                        <b-col sm="4">
                            <label>Status</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select class="facility__input" size="sm" v-model="properties['device_status']" :options="f_status_option" ></b-select>
                        </b-col>
                    </b-row-->    
                    <br/>                                                                      
                    <hr/>
                      <b-row class="modal-footer-row m-0 leak_foot" style="padding : 30px 0px 30px 0px;">
                        <b-col sm="1">
                        </b-col>
                        <b-col sm="5">
                          <b-button @click="Delete()" block class="modal-footer__button modal-footer__button-del">Delete</b-button>
                        </b-col>

                        <b-col sm="5">
                          <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">Apply</b-button>
                        </b-col>
                        <b-col sm="1"></b-col>
                      </b-row>
                   </div>  
                  </b-tab>
                  <b-tab :title="$t('menu.pressure')"  style=" padding: 5px;">
                    <div style="height:500px;padding:20px;">
                      <h5 style="color:#fff;" >Logger List</h5>
                     <vue-good-table ref="group_table1"
                        :columns="columns1"
                        :rows="table_group1"
                        :line-numbers="true"
                         max-height='430px'
                        :fixed-header="true"                       
                        styleClass="vgt-table without--head-right-line type01"
                   />
                    </div>
                  </b-tab>
                 </b-tabs>   
              </b-col>  
          </b-row>
             <template v-slot:modal-footer="{ ok, cancel, hide }">

              <b-row class="modal-footer-row m-0 leak_foot">
                <b-col sm="10">
               
               </b-col>
               <b-col sm="2">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">Close</b-button>
               </b-col>
               <!--b-col sm="2">
                 <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">Apply</b-button>
               </b-col-->
             
             </b-row>
            </template>
      </b-modal>
<!--
         
-->
  
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;

export default {
    name: 'DlgGroupList',
    props: {
    },
    watch:{
     

    },
    data() {
        return {
            columns: [
                {label: 'ID', field: 'groupId'},
                {label: 'Customer', field: 'customerId'},
                {label: 'Group Name', field: 'groupName'},
                {label: 'waterPressureCount', field: 'waterPressureCount'},
                {label: 'Register_Date', field: 'register_datetime'},         
            ],
            columns1: [
                {label: 'ID', field: 'water_pressure_id'},
                {label: 'Name', field: 'point_name'},
                {label: 'min', field: 'min'},
                {label: 'avg', field: 'avg'},
                {label: 'max', field: 'max'},
                {label: 'elevation', field: 'elevation'},
               
            ],
            properties: [],
            move_list: [],
            work_type:100,
            work_id : '',
            table_group:[],
            table_group1:[],
            adv_search: [
              {
              
              },
            
          ],
          customer_name : '',
        }
    },

    mounted () {
    },
    methods: {
      Delete(){
            if(sessionStorage === null || typeof sessionStorage === undefined){
              alert(this.$t('leak_detail.alt_no_update'))
              return
        //   }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 1  && sessionStorage.m_id !== this.properties.worker){
              alert(this.$t('water_leak.alert_no_permission'))
              return;
            }

           var con_test1 = confirm(this.$t('water_leak.left_menu.confirm_msg_delete'));
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           
           if(con_test1){
             
              this.$http.delete(this.URL+'api/waterpressure/groups/'+this.properties['groupId']+'?customer_id='+sessionStorage.select_customer_id).then((res) => {  
                 console.log(res.data);                
                 this.go_search();
              });
            
           }  
      },
      apply(){
        let params = {} ; 
       // var data = {} ;
        let keys = Object.keys(this.properties_bak)
        if(this.properties['groupName'] === null || this.properties['groupName'].length < 1 ) {
          alert("group name을 입력해 주세요.")
          return
        }
        //신규등록일시
        if(this.properties['groupId'] === undefined ){ 
          params["customerId"] = sessionStorage.select_customer_id
          params["groupName"] = this.properties["groupName"]     
        //  data = {type: 'add', params:params} ;
          this.$http.post(this.URL+'api/waterpressure/groups',params).then((res) => {
            console.log(res.data);
            if(res.data.check ===1){
              alert("fail : "+ res.data.msg) ;
            }else{
              this.go_search();
            }
          })          
        }else{     
          for(let j=0; j<keys.length; j++) {
            if(this.properties_bak[keys[j]]===this.properties[keys[j]]) continue
   
            //if( keys[j] === 'groupName') continue 
            params["customerId"] = this.properties["customerId"]
            params["groupName"] = this.properties["groupName"]
          }           
          //data = {type: 'modify',id:this.properties["id"], params:params} ;
          this.$http.patch(this.URL+'api/waterpressure/groups/'+this.properties['groupId'],params).then((res) => {
            console.log(res.data);
            if(res.data.check ===1){
              alert("fail : "+ res.data.msg) ;
            }
            this.go_search();
            
         })
        }//else
      }, 
      go_search(){
        this.table_group = [];
        this.table_group1 = [];
         this.$http.get(this.URL+'api/waterpressure/groups?customer_id='+sessionStorage.select_customer_id+"&search_term=").then((rows) => {
            if(rows.data.groups.length <= 0) return;
            this.table_group = rows.data.groups
            this.properties = rows.data.groups[0]
            this.$http.get(this.URL+'api/waterpressure/groups/'+rows.data.groups[0].groupId+'/items?customer_id='+sessionStorage.select_customer_id).then((rows1) => {
              this.table_group1= rows1.data.results
            })   
         })
      },    
      show() { 
   
       //   this.$refs.work_table.setUrl(this.URL+'api/get_work_list_ex?w_type='+w_type+'&customer_id='+sessionStorage.select_customer_id, (rows) => {      
       
         // });
          
          this.$refs.modal.show();   
          
            this.$http.get(this.URL+'api/waterpressure/groups?customer_id='+sessionStorage.select_customer_id+"&search_term=").then((rows) => {
                if(rows.data.groups.length <= 0) return;
                this.table_group = rows.data.groups
                this.properties = rows.data.groups[0]
                this.$http.get(this.URL+'api/waterpressure/groups/'+rows.data.groups[0].groupId+'/items?customer_id='+sessionStorage.select_customer_id).then((rows1) => {
                  this.table_group1= rows1.data.results
                })   
            })
          
         /*
           setTimeout(()=>{  
             var table =this.$refs.group_table             
             if(table !==undefined){
                table.setUrl(this.URL+'api/waterpressure/groups?&customer_id='+sessionStorage.select_customer_id, (rows) => {
                  if((!rows)||(rows.length<=0)) return;
                
                  //this.work_list =rows;
                });
             }
           }, 500)
          */
         //this.$refs.modal.show();    
    
        },
      on_move_click(row){
         
         var con_test1 = confirm("Are you sure you want to Move?");
         //let list = idx
         // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
         if(con_test1){
            
            this.$http.get(this.URL+'api/move_location_leakage?w_type='+ this.work_type+'&w_id='+row.id+'&lidx='+this.move_list).then((res) => {  
                console.log(res.data);
                if(res.data.check === 0){
                  this.$http.get(this.URL+'api/move_work_leakage?w_type='+ this.work_type+'&w_id='+row.id+'&lidx='+this.move_list).then((res1) => {  
                     if(res1.data.check === 0){  
                       if(this.work_type === "100")
                         this.$emit('goRouter',"",row.id) ;
                       else  
                         this.$emit('leaktable_refresh') ;
                       this.$refs.modal.hide();
                     }else{
                        alert("The move failed..")
                     }

                  }); 
                }else{
                  alert("The move failed.")
                }
            });
          }
        },
        onRowClick(params) {
            //선택된 색상 보여지게
            this.new_show =true;
            if(this.clicked_b_row != null){
              this.clicked_b_row.bgColor = '';
            //  this.clicked_b_row.style.backgroundColor = '';
            }
          
            if(params.event.target.nodeName==='TD'){
                params.event.target.parentElement.bgColor  = '125744';          
                this.clicked_b_row = params.event.target.parentElement ;
            }else  if(params.event.target.nodeName==='IMG'){
                params.event.target.parentElement.parentElement.parentElement.bgColor = 'ebeff2';      
                this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
            }else{
                params.event.target.parentElement.parentElement.bgColor = '125744';      
                this.clicked_b_row = params.event.target.parentElement.parentElement ;
            }
            this.table_group1 = [];
            this.properties = params.row ;
            this.properties_bak = Object.assign({}, this.properties) ;   
            this.$http.get(this.URL+'api/waterpressure/groups/'+ params.row.groupId+'/items?customer_id='+sessionStorage.select_customer_id).then((rows1) => {
                this.table_group1= rows1.data.results
            })           
            //this.clicked_l_idx = params.row.customer_no
          
        },
        add_group(){
          this.$router.push({ path: '/Work-block', query: { tag: this.work_id , block_type:110 } })
          /*
          this.properties = {} ;
          this.properties_bak = {} ;
          */
        }        

    },
    components: {
         //   FFTChart1:FFTChart1
        //  PressBigChartData:PressBigChartData,
        //ServerSideTable: ServerSideTable,
      
    }
}
</script>

<style  lang="scss" >
@import "@/assets/scss/base/variables";

.modal.show.modal-group .modal-dialog {
      max-width: 1000px;
      // background: #F2F2F2;
      background: $color-content-bg;
   }

.modal-group {
  
  .modal-dialog { 
    // background: #F2F2F2;
    background: $color-content-bg;;
    width:1000px;
    /*height: calc(100% - 70px);*/
    .modal-header {
      padding-bottom: 22px;
      // background: #F2F2F2;
      background: $color-content-bg;
      .modal-title {
        font-size: 18px;
        // margin-top: 20px;
      }
    }
    
    .modal-info-content {
      border: 0;
      border-radius: 0;
 
      &.reversal {
        // background: #F2F2F2;
        background: $color-content-bg;
        .nav-tabs {
          // background: #F2F2F2;
          background: $color-content-bg;
        }
        .tab-content {
          // background: #fff;
          background: $color-content-bg;
          &.is--scroll {
            height: 73vh;
            overflow-y: auto;
          }
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
          // background: #fff;
          background: $color-content-bg;
          border: 1px solid #707070;
        }
        .facility__label {
          // color: #616161;
          color: $color-white;
          font-size: 12px;
          display: inline-block;
          letter-spacing: 0.5px;
          //width: 35%;
        }
        .facility__input {
          //width: 60%;
          height: 32px;
          font-size: 12px;
          border: 1px solid #D9D9D9;
          box-sizing: border-box;
          border-radius: 0;
          margin-top: 0;
          margin-bottom: 14px;
          &.height--auto {
            height: auto;
          }
        }
        .modal-footer {
          // background: #fff;
          background: $color-content-bg;
          padding-bottom: 18px;
          padding-top: 3px;
        }
        .modal-footer-row {
          width: 100%;
          margin: 0;
          padding-bottom: 0;
        }
        .btn.modal-footer__button {
          height: 52px;
          font-size: 14px;
          border: 0;
          &.modal-footer__button-ok {
            // 2022 new
            // background: #28B7CB;
            background: #28AF81;
          }
          &.modal-footer__button-cancel {
            background: #9D9D9D;
          }
        }
        .photo-card {
          border-radius: 0;
          border: 0;
          background: none;
          .img-thumbnail {
            margin-bottom: 10px;
          }
          .card-header {
            height: 150px;
            background: #F2F2F2;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .card-header__text {
              font-size: 16px;
              color: rgba(3,3,3,0.4);
            }
          }
        }
      }
    }
  }
}
.modal-group .modal-dialog .modal-body  {
    // background: #ebeff2; 
    //background: #172A46; 
    padding: 0;
    // padding: 0;
   
}
.custom-checkbox .custom-control-label::before {
    border-radius: 0rem;
    color: #fff;
}
.layers-table .layers-table__item--color input[type="color"].form-control {
    margin: 10px;
    height: 25px;
}
.map-button.icon_btn.icon_btn_text.blue {
  background-color: #28AF81;
}

.modal-dialog .modal-body .tab-content.press_detail.group{
   background-color: transparent ;
}
</style>
<template>
  <div>
    <!-- top-bar -->
    <div ref="navbar" class="navbar-custom">
      <!-- <h4 class="content-title">{{$t('leak_monitoring.c_title')}}</h4> -->
      <ul class="list-unstyled topnav-menu topnav-menu-left">
        <li>
          <ol class="breadcrumb"> 
            <!-- <li class="breadcrumb-title">Leak Detail</li>
             <li class="breadcrumb-bar">|</li> -->
            <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
            <li class="breadcrumb-item"><router-link to="/water-leak-logger">{{$t('menu.leak_menu')}} {{$t('menu.leak_logger')}}</router-link></li>
            <li class="breadcrumb-item active">{{$t('leak_monitoring.c_title')}}</li>
          </ol>
        </li> 

      </ul>
    </div>
    <!-- tom-bar-end -->
        <search-left-type3 ref="left" :box="box" :activeCheckIndex="activeCheckIndex" :visible_search_box="true" w_type = "104"
          @updateCheckIndex="updateCheckIndex" @go_search="go_search_leftlist" @on_goto_map_click = "on_goto_map_click" @on_pupup_info = "on_pupup_info"/>
    <div class="content-area row justify-content-around M2" style=" padding:0;" >
      <div class="left leak-detail M2"  style=" padding: 0px 5px;">
        <!--
        <div class="check_list">
          <input type="checkbox" id="chk_01">
          <label for="chk_01">Water Leak</label>
          <input type="checkbox" id="chk_02">
          <label for="chk_02">Inner Water Leak</label>
          <input type="checkbox" id="chk_03">
          <label for="chk_03">None</label>
          <input type="checkbox" id="chk_04">
          <label for="chk_04">Apply to Map</label>
        </div>
        -->
        <!--
        <div class="lk-dt-tab table-button-group">
          <b-button variant="lk-middle lk-btn-left" @click="show_frequency_chart()"><i class="mdi mdi-pipe-leak mr-2"></i>LEAKAGE STRENGTH</b-button>
          <b-button variant="lk-middle border-left-line" @click="goRouter('/leak-analysis')"><i class="mdi mdi-leak mr-2"></i>FREQUENCY COMPARISON</b-button>
          <b-button variant="lk-middle border-left-line" @click="downloads()"><i class="mdi mdi-download mr-2"></i>WAVFILE DOWNLOAD</b-button>
          <b-button variant="lk-middle lk-btn-right border-left-line"  @click="go_download" >
                 <download-excel  class   = "btn-default"                     
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        :name    = "filename">
                    <i class="mdi mdi-download mr-2"> EXCEL DOWNLOAD</i> 
                  </download-excel> 
           </b-button>                    
        </div>
        -->
        <div class="list_box list_box2 Leak_logger">
          <div class="page_list">
            <b-row style=" margin-bottom: 20">
              <div class="input_search_box" style="margin : 10px 30px;">
          <!--<input type="text" class="input_search" v-model="value" placeholder="Search..."  @keyup.enter ="Enter_Check" >
          <input type="submit" class="input_submit" @click="go_search" >
          
            <button  class="btn btn-map-view ml-2 map-button icon_btn icon_btn_text" style="float:right;width: 170px;height:35x;font-size:14px;" type="button" @click="goRouter('/leak-monitoring-detail-map1')" v-b-tooltip.hover title='Frequency Comparison'>{{$t('leak_monitoring.btn_frequency')}}</button>
            -->
            <!-- <button  class="btn btn-primary btn-map-view ml-2 map-button icon_btn icon_btn_text" style="margin: 1.5em 0.5em 0 1em;/*2023 float:right;*/padding: 4px;font-size: .75em;white-space: nowrap; width: 100px;height:35x;font-size:12px;" type="button" @click="downloads" v-b-tooltip.hover title='Wavfile Download'>{{$t('leak_detail.btn_wav_down')}}</button> -->
           
           
            <download-excel  class   = "btn-default"                     
                  :data   = "json_data"
                  :fields = "json_fields"
                  worksheet = "My Worksheet"
                  :name    = "filename">
                <!-- <button  class="btn btn-primary btn-map-view ml-2 map-button icon_btn icon_btn_text" style="margin: 1.5em 0px 0px 0px;/*2023float:right;*/padding: 4px;font-size: .75em;white-space: nowrap;width: 100px;height:35x;font-size:12px;" type="button" @click="go_download" v-b-tooltip.hover title='Excel Download'>{{$t('leak_detail.btn_excel_down')}}</button> -->
                <button  class="btn btn-primary btn-g" style="margin: 1em 0.5em 0 0;/*2023 float:right;*/" type="button" @click="downloads" v-b-tooltip.hover title='Wavfile Download'>{{$t('leak_detail.btn_wav_down')}}</button>
                <button  class="btn btn-primary btn-g" style="margin: 1em 0.5em 0px 0px;/*2023float:right;*/" type="button" @click="go_download" v-b-tooltip.hover title='Excel Download'>{{$t('leak_detail.btn_excel_down')}}</button>
                <button v-if="levelA" class="btn btn-primary btn-g" style="margin: 1em 0.5em 0px 0px;" type="button" @click="update_leak_result(1)" v-b-tooltip.hover title='Excel Download'>{{$t('leak_detail.btn_leak')}}</button>
                <button v-if="levelA" class="btn btn-primary btn-g" style="margin: 1em 0px 0px 0px;" type="button" @click="update_leak_result(0)" v-b-tooltip.hover title='Excel Download'>{{$t('leak_detail.btn_no_leak')}}</button>
            </download-excel> 
            
            </div>
            </b-row>
            <b-row class="list_box list_box2" >
              <b-col class="page_list">
               <!-- <h3>Water Leak Data Table</h3> -->
                <div class="table-box" >
                 <!-- <div style="position:absolute; top: 0; right:0;  ">
                      <download-excel
                        class   = "btn btn-default"
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        name    = "filename.xls">
                      <button  @click="go_download" class="btn btn-third ml-2"  > <i class="mdi mdi-download" v-b-tooltip.hover title='Excel Download'></i></button>
                    </download-excel> </div>-->
                  <!--<label for="textarea-small" class="table-box-text mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label>-->
                  <ServerSideTable ref="table_leakage_info" 
                                   :columns="columns"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"
                                   :search="false"
                                   :adv_search="adv_search"
                                   :select="true"
                                   :perpage_cnt = 20
                                   :add_custom_column="true"
                                   :max_height="table_height+'px'"
                                   @on-row-click="onRowClick"                                
                                   @on_info_click="on_leakage_info"
                               
                              
                  />

                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="right M2" >
        <!-- 2023 -->
        <!-- <b-row style=" padding: 10px 10px 0px 10px ;margin-bottom:5px; "> -->
        <b-row >
          <b-col class="leak-detail-content" style="margin-bottom: 10px;padding:0;">
            <b-row  >
             <b-col style="padding: 0px; " >
                   <div style="margin-top:10px;  position: relative;left:32%;width:100%;text-align:center;">
                      <div style="float:left;">
                          
                          <span style="float:left;">  </span>
                       
                      </div>
                       <span  style="float:left;margin-left: 0px; " >          
                          <span style="float: left;">
                           <b-input v-model="szoom"    style="width:45px;height:20px;margin:15px 0 0 10px;padding:10px;text-align:right;" 
                           type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                           v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                           
                          </span>                   
                        <audio id="slice_audio_id" controls preload="auto" :src="slice_url" style="transform:scale(0.7);height: 50px; margin-left: -20px;width:200px;">
                        </audio>
                       </span>
                      
                        <div class="status leak" style="position: absolute;margin-top:9px; margin-left: 250px;" >
                  <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                     <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                       <span class="inner-bg">Y Axis</span> -->
                  <input type="radio" v-model="vchart" value="0" id="fixed"> <label for="fixed">{{$t('leakage_info.fixed')}}</label>
                  <input type="radio" v-model="vchart" value="1" id="proportional"><label for="proportional">{{$t('leakage_info.proportional')}}</label>
                </div>
             
                   </div> 
               
                <div style='min-height: 180px; height: calc(20vh - 10px);'> 
                <div id="hidden-container" >
                  <div id="waveform" ref="waveform" style="float : left; margin-left: 20px; width:93%;"/>
                  <div id="wave-timeline" ref="wave-timeline" style="float : left; margin-left: 20px; width:93%;"/>
                </div>
                </div>
                <!-- <audio controls preload="auto" :src="aurl" style = "width:50%;">
                 </audio> -->
                <!--<av-waveform :audio-src="aurl" :caps-height="2" :canv-width="800" :canv-height="120" ref="avwaveform"></av-waveform> -->
              </b-col>
            </b-row>
            <b-row >
              <b-col style=" padding-left: 0px;padding-right: 0px;">
                <!--FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:98%; overflow: auto;"  /-->
                <div  :style="{height: fftchart_height + 'px'}">
                   <pressChartData_fft :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:100%; overflow: auto;" @onZoomClickEvent="onZoomClickEvent"  />
                </div>  
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row  style=" padding: 0px ; margin: 0; background: #172A46;">
          <b-col class="leak-detail-content-map"  style='padding: 0;'>
              <div class="search_div_flow">
                <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
                <!--span style="float:left; margin-left:10px;"> <b-select size="sm" v-model="value_type" :options="value_opt" change></b-select> </span-->
              </div>
            <div :style="{height: map_height+'px'}" >
              <Map
                  ref="map"
                  :widx="$route.query.widx"
                  :visible_full_extent="true"
                  :visible_gisload_button="true"                  
                  :press_icon ="false"
                  :press_icon1 ="true"
                  w_type="104"
                  @go_home="go_home()"
                  @onLeakMapClick="onLeakMapClick()"
                  @set_map_gisload="set_map_gisload"
                  @toggle_visible_all_layer = "toggle_visible_all_layer"
                  @facility_invisible_layer = "facility_invisible_layer"
                  @set_info="set_info"
                  @leaktable_refresh="leaktable_refresh"
                  @get_gisinfo="get_gisinfo"                               
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    
    <DlgLeakLoggerInfo ref="logger_info" />
    <DlgLeakLoggerDetailInfo ref="logger_detail_info"  @refresh_table="refresh_table" />
  </div>
</template>

<script>
import DlgLeakLoggerInfo from '@/components/popup/DlgLeakLoggerInfo' 
import pressChartData_fft from '@/components/chart/pressChartData_fft';
//import FFTChart1 from '@/components/FFTChart1';
import ServerSideTable from '@/components/table/ServerSideTable';
import Map from '@/components/Map';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
//import DlgFrequencyChart from '@/components/DlgFrequencyChart';
import {CreateVectorLayer_f} from '@/gis.js';
import WaveSurfer from 'wavesurfer.js';
import SearchLeftType3 from '@/components/left/SearchLeftType_leak_lo';
import DlgLeakLoggerDetailInfo from '@/components/popup/DlgLeakLoggerDetailInfo_pop' 

//import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
/*
' CASE '+
' WHEN layer_type=0 then "Pipe" '+
' WHEN layer_type=1 then "Valve" '+
' WHEN layer_type=2 then "Water Meter" '+
' WHEN layer_type=3 then "Pump" '+
' WHEN layer_type=4 then "Hydrant" '+
' WHEN layer_type=7 then "Block" '+
' WHEN layer_type=8 then "Water Treatment Plant" '+
' WHEN layer_type=9 then "Pumping Station" '+
' WHEN layer_type=10 then "Water Basin" '+
' WHEN layer_type=11 then "Chamber" '+
' WHEN layer_type=12 then "Gauge" '+
' WHEN layer_type=13 then "Other" '+
' END AS type_, '+
*/

export default {
  name: 'LeakDetail',
  props: {
    // url
  },
  watch: {
    vchart() {
      //     if(this.vchart==='0') {
      //        alert("radio button : " + this.vchart) ;
      //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
      //     }
      var c_url = this.fft_url.split('#') ;
      this.fft_url = c_url[0]+'#'+this.vchart ;
    },
    download_idx(){
       if(this.download_idx > this.download_arr.length -1 ) return
       var idx = this.download_arr[this.download_idx].id ;
       let fst = {'Leak':'L','Leak inside':'I','Usage':'U','Other':'O'}
       var fs ="" ;
       if(this.download_arr[this.download_idx].leak_result_ !== null){
           fs =  fst[this.download_arr[this.download_idx].leak_result_];
        }       
       let fname = idx+"_"+this.download_arr[this.download_idx].file_name.split('.')[0]+'_'+fs+".wav"       
       this.download(idx, fname)       
    },  
    value_type(){
       this.chang_mapvalue_type() ;
    }    
  },
  data() {
    return {
      layer_all_visible: false,
      levelA : false,
      contents_height: 800,
      waveform_height: 80,
      fftchart_height: 200,
      map_height: 500,
      table_height: 640,
      vchart : 1,
      work_id : this.$route.query.widx,
      tag_id : this.$route.query.id,
      value_type : '',              
      value_opt: ['avg' , 'min', 'max' ],
      is_playing: false,
      value:'',
      slice_url : '',
      szoom:50,
      line_num : true,
      adv_search: [
          {
              field: 'leak_result',
              control: 'select',
              value: null,
              //'Leak','Leak Inside','Usage','Other'
              options: [
                  { value: null, text: 'Leak Result' },
                  { value: 'L', text: 'Leak' },
                  { value: 'N', text: 'No Leak' },
                  { value: 'M', text: 'Meter is Running' },
                
              ]
          },
            {field: 'measure_datetime', control: 'date', value: null, value2: null, placeholder: 'From measure_datetime', placeholder2: 'To measure_datetime'},
      ],
      columns: [
      
     //   {label: 'Map', field: 'go_map',sortable: false},
        {label: 'Info', field: 'info', sortable: false},
        {label: this.$t('leak_monitoring.table_column.name'), field: 'point_name',sortable: false,width: '30%',},
        {label:  this.$t('leak_monitoring.table_column.time'), field: 'measure_datetime',width: '20%',},
        {label: this.$t('leak_monitoring.table_column.strength'), field: 'wave_energy',width: '20%',},  
        {label:  this.$t('leak_monitoring.table_column.max_fre'), field: 'wave_max_frequency',width: '15%',},       
        {label: this.$t('leak_monitoring.table_column.leak'),  field: 'leak_result_', width: '13%',   },
      
      // {label:  this.$t('leak_monitoring.table_column.ai'), field: 'ai_leak_result',width: '15%',},  
       //{label: this.$t('leak_detail.probability') , field: 'probability',width: '13%',},  
      //  {label:  this.$t('leak_monitoring.table_column.battery'), field: 'battery'},
      //  {label:  this.$t('leak_monitoring.table_column.temp'), field: 'device_temperature'},
      
      ],
  
      json_data :[],
      json_fields:{
        'Name': 'point_name',
        'Time': 'measure_datetime',      
        'Strength' : 'wave_energy',
        'Max Frequency(Hz)': 'wave_max_frequency',
    //   'Strength_cal': 'o_strength_cal',
        'Leak Result': 'leak_result_',
        'AI': 'ai_leak_result',
     //   'Battery': 'Battery',
     //   'Temp.(℃)': 'device_temperature'
      },
      filename : 'filename.xls',
      wavesurfer: null,
      fft_url: '',
      download_idx: -1,
      aurl:'',
      clicked_l_idx: -1,
      clicked_b_row:null,
      searchTerm: '',
      mea_last_date: '', 
      addr_value : '',
      activeCheckIndex: null,
      box: [
        {
          'name': '대덕 소블록',
          'device_sn': '',
          'comm_sense': '5'
        },
        {
          'name': '대덕 소블록',
          'device_sn': '',
          'comm_sense': '5'
        },
        {
          'name': '대덕 소블록',
          'mnf': '',
          'comm_sense': '5'
        }
      ],
     
    }
  },
  methods: {
    //leak_result 결과 update
    update_leak_result(type) {
      //   this.filename = 
        var leak_query = '' ;
        if(type === 1){ //누수
          leak_query = 'L' ; //RT_LEAK          
        }else{
          leak_query = '' ;
        }
    
        let rows = this.$refs.table_leakage_info.getSelectRows()
        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        var con_test = confirm(this.$t('leak_detail.confirm_set_leak'));
       
        if(con_test){
          this.$http.get(this.URL+'api/update_leak_logger?type=0&leak_result='+leak_query+'&lidx='+list).then((res) => {  
             console.log(res.data);
             //this.leaktable_refresh();
             this.refresh_table();
          });

        }
    },
    refresh_table(){
      this.updateCheckIndex(this.activeCheckIndex)
    },
    chang_mapvalue_type() {
          // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          
        this.$refs.map.leak_cal = this.value_type;         
         //누수 레이어를 다시 그린다.
        this.$refs.map.init_reload_pressure_layer() ;
        

     },  
    addr_search(){
      this.$refs.map.go_search(this.addr_value);
    },
    Enter_Check1(){
      this.addr_search();
    },   
    go_search_leftlist(value,type,strength) {

        if(value===undefined) return;

            //left메뉴 데이터를 뿌려준다.
        this.box = [] ;
      
        // this.$http.get(this.URL+'api/get_monitoring_list_left?search='+value).then((rows) => {
          this.$http.get(this.URL+'api/API21/water_leak_logger/list?type='+this.work_id+'&search='+value+'&sort='+strength).then((res) => {
            if(res.data.results[0].length <1) return
        
            
            for(let i=0; i<res.data.results[0].length; i++) {                
              var abox = {};
              // abox = {'name': res.data.results[0][i].jijum_name, 'mnf': Math.round(Number(res.data.results[0][i].jijum_id)) , 'flow':  Math.round(Number(res.data.results[0][i].tag_value)) ,  'status': 'blue' } ; 

              abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id ,  'device_sn':res.data.results[0][i].device_sn ,
                  'start_datetime':res.data.results[0][i].measure_start_datetime.substring(0,13)+"H",'end_datetime':res.data.results[0][i].measure_end_datetime.substring(0,13)+"H", 
                  'strength': res.data.results[0][i].max   } ; 
           
               this.box.push(abox) ; 
            }
              this.updateCheckIndex(0)
        })

  
    },

    updateCheckIndex(payload) {
      this.activeCheckIndex = payload
      this.tag=this.box[payload].id
     // this.left_status_5(this.tag)
      
      this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_logger_detail_list_ex?logger_id="+this.tag, (rows) => {
          console.log(rows)

          if((!rows)||(rows.length<=0)) return;
          this.mea_last_date = rows[0].measure_datetime.substring(0,16);
          this.clicked_l_idx = rows[0].id
          this.filename = "widx-"+this.tag_id+"-lidx-"+this.clicked_l_idx+"~.xls"
          this.aurl = this.URL+'api/get_wav/water_leak_logger_detail/'+rows[0].id
          //  this.avwaveform.load(aurl)
          this.wavesurfer.load(this.aurl);
          this.fft_url = this.URL+'api/get_fft/water_leak_logger_detail/'+rows[0].id+'#'+this.vchart;
      });   
    },    
    toggle_visible_all_layer() {
      // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
      // strength_cal값을 가져와 지도에 보여준다.
      this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
      if(this.$refs.map.layer_all_visible)
        this.$refs.map.leak_cal = 1;
      else
        this.$refs.map.leak_cal = 0;
      
      //누수 레이어를 다시 그린다.
      this.$refs.map.init_reload_leak_layer() ;

      /*
        this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
        let map = this.$refs.map.map
        for(let i=1; i<map.getLayers().getLength()-1; i++) {
            let layer = map.getLayers().item(i)
            if(layer.get('code')===100)
                layer.setVisible(this.$refs.map.layer_all_visible)
        }
        */
    },
    facility_invisible_layer() {

      this.layer_all_visible = false;
      let map = this.$refs.map.map
      for(let i=1; i<map.getLayers().getLength()-1; i++) {
        let layer = map.getLayers().item(i)
        if(layer.get('code')>50) continue;
        layer.setVisible(this.layer_all_visible)
      }
    },
    go_download() {
  //   this.filename = 
      this.filename = this.filename.replace(",","_")
      let rows = this.$refs.table_leakage_info.rows ;
      if(rows.length<=0) {
        alert(this.$t('leak_detail.alt_no_data'));
        return;
      }
      this.json_data = rows ;
      // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
      // this.$router.push({path: '/leak-detail', query: { widx: list }})
    },
    go_home() {
      var w_idx = this.$route.query.widx ;
      this.$router.push({path: '/water-leak-logger-map', query: { mea_date: this.mea_last_date, w_type: '104',widx: w_idx}})
    },
    download(idx, name) {
      this.$http({
        url: this.URL+'api/download/wav/'+idx+"?type=water_leak_logger_detail",
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);
        fileLink.click();
          //'Leak','Leak Inside','Usage','Other'
      
      
        setTimeout(()=>{
          ++this.download_idx; 
        }, 200)
      
      })

    },
    downloads() {
      let rows = this.$refs.table_leakage_info.getSelectRows()
      this.download_arr = rows ; 
      if(rows.length<=0){
        alert(this.$t('leak_detail.alt_select'));   
        this.download_arr = [] ;
        return;  
      } else{
          this.download_idx = 0;
      }  
    },
    show_frequency_chart() {
      let rows = this.$refs.table_leakage_info.getSelectRows()
      if(rows.length<=0) {
        alert(this.$t('leak_detail.alt_select'));   
        return;
      }
      let lidx = []
      for(let i=0; i<rows.length; i++) lidx.push(rows[i].idx)
      this.$refs.dlg_frequency_chart.show(lidx);
    },
    goRouter(path) {
      let query = {}
      //if(path==='/') query['customer_id'] = this.$route.query.id
      /*
      if('/leak-analysis'===path) {
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<=0) return;
        let lidx = []
        for(let i=0; i<rows.length; i++) lidx.push(rows[i].idx)
        query['lidx'] = lidx
        query['widx'] = this.$route.query.widx
      }
      */
      this.$router.push({path: path, query: query,})
    },
    on_pupup_info(idx){
        this.$refs.logger_info.show(idx)
    },
    on_leakage_info(row) {          
      this.$refs.logger_detail_info.show(row.id)
    },
    onLeakMapClick(){

      let leak_idx = this.$refs.map.leak_idx;
      this.on_pupup_info(leak_idx)
      /*
      this.clicked_l_idx = leak_idx;
      this.aurl = this.URL+'api/get_wav/m2_water_leak/'+leak_idx ;

      this.wavesurfer.load(this.aurl);
      this.fft_url = this.URL+"api/get_fft/m2_water_leak/"+leak_idx+'?'+this.vchart;
      */
    },
    leaktable_refresh(){
      this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_logger_detail_list_ex?logger_id="+this.tag_id, (rows) => {
        if((!rows)||(rows.length<=0)) return;
        this.clicked_l_idx = rows[0].id
        this.filename = "widx-"+this.$route.query.widx+"-lidx-"+this.clicked_l_idx+"~.xls"
        this.aurl =this.URL+'api/get_wav/water_leak_logger_detail/'+rows[0].id;
        this.wavesurfer.load(this.aurl);

        this.fft_url = this.URL+'api/get_fft/water_leak_logger_detail/'+rows[0].id+'#'+this.vchart;
      })
    },
    onRowClick(params) {
      //선택된 색상 보여지게
   //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
      if(this.clicked_b_row != null){
        this.clicked_b_row.bgColor = '';
    //  //  this.clicked_b_row.style.backgroundColor = '';
     }
    
      if(params.event.target.nodeName==='TD'){
          params.event.target.parentElement.bgColor  = '#125744';          
          this.clicked_b_row = params.event.target.parentElement ;
      }else  if(params.event.target.nodeName==='IMG'){
           params.event.target.parentElement.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
      }else{
          params.event.target.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement ;
      }
      
/*
     if(this.clicked_b_row != null){
       this.rowStyleClassFn(params.row, 1);
     }
      this.rowStyleClassFn(params.row, 0);
 */    
    //  this.clicked_b_row = params.row 
     this.slice_url = null;
      var audio = document.getElementById('slice_audio_id')
      audio.load(); 
     
      this.clicked_l_idx = params.row.id
      document.getElementById("hidden-container").style.display = 'none';
      this.aurl = this.URL+'api/get_wav/water_leak_logger_detail/'+params.row.id;
      this.wavesurfer.load( this.aurl);
      this.fft_url = this.URL+"api/get_fft/water_leak_logger_detail/"+params.row.id+'#'+this.vchart;

    },

    on_goto_map_click(row) {
      this.$refs.map.get_extent('w_type=104&p_idx='+row.id).then((data)=>{
        this.$refs.map.set_fit_extent(data)     
        if(this.$store.setting.map_name==='Daum')
          this.$refs.map.map.getView().setZoom(13);
        else
          this.$refs.map.map.getView().setZoom(18);   
      })
      this.$refs.map.select_facility_feature('104','',row.id) 
    },
    play() {
      if(!this.is_playing) {
        this.wavesurfer.play();
        this.is_playing = true
      }
      else {
        this.is_playing = false
        this.wavesurfer.pause();
      }

    },
    stop() {
      this.is_playing = false
      this.wavesurfer.stop();
    },
    window_resiz() {
      this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 50

      this.map_height = this.contents_height/2 - 10 ;
      this.fftchart_height = this.map_height /2
      this.waveform_height = this.fftchart_height/2 

      this.$refs.map.update_size()
    },
    set_map_gisload() {
      this.set_info();
      let map = this.$refs.map.map;
      var size1 = map.getSize();
      var size = [size1[0]*1, size1[1]*1] ;
      var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
      let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
      var extent = map.getView().calculateExtent(size);

      if(this.$refs.map.map.getView().getZoom() < cz){
        alert(this.$t('home.alert_zoom_in')) ;
      }else{
        // 추가 되었던 소스 가져와서 삭제하기
        // heat layer 삭제

        var h_layer = this.$refs.map.heat_layer ;
        if(h_layer != null) {
          var source_h = h_layer.getSource();
          var hfeatures = source_h.getFeatures();
          for(var k=0; k< hfeatures.length ; k++) {
            source_h.removeFeature(hfeatures[k]);
          }
          map.removeLayer(h_layer) ;
          h_layer = null;
        }
        // remove_layer(map);
        for(let i=0; i<this.$refs.map.layer_list.length; i++) {
          var layer = this.$refs.map.layer_list[i];
          if(layer=== null || layer.values_ === null) continue
          var source1 = layer.getSource();
          var features = source1.getFeatures();
          for(var j=0; j< features.length ; j++) {
            source1.removeFeature(features[j]);
          }
          map.removeLayer(layer) ;
        }
        this.$refs.map.layer_list = [] ;
        for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

          var params = this.$refs.map.layer_setting_stlye_list[i];
          // var nstyle = params.style;
          var _url=params.surl;
          // if(params.code >50 || layer.get('code')=== undefined ) continue

          var lftaye = params.f_type;
          _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

          var newlayer = CreateVectorLayer_f(params,extent) ;
          var vis = params.visible ;
          newlayer.setVisible(vis);
          this.$refs.map.layer_list.push(newlayer) ;
          map.addLayer(newlayer);


        } //for문 끝
        this.$refs.map.init_draw_modify();
      } // zoom크기에 대한 if


    },
    gis_reload(){
        this.set_info();
       // this.$refs.map.init_draw();
        if(this.$store.setting.map_name==='Daum')
          this.$refs.map.map.getView().setZoom(9);
        else
          this.$refs.map.map.getView().setZoom(15);   
        this.set_map_gisload() ;    
    },
    get_gisinfo(){
        this.$refs.map.block_info = 1 ;
        this.set_toggle_visible_all_layer();
        // this.$refs.map.init_draw();
        this.$refs.map.edit_change('add', 'Block')
        // this.$refs.map.edit_start('add', 'Block') ;
    },
    set_toggle_visible_all_layer() {
          
            this.layer_all_visible = !this.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue
                layer.setVisible(this.layer_all_visible)
            }
        },
    set_info(){
      this.$refs.map.edit_cancel();
      this.$refs.map.select_info = true;
    },
    /*
    on_leakage_info(idx) {
      this.$refs.map.show_leakage_info(idx)
    },
    */  
     go_search() {

        if(this.value===undefined) return;

        this.box = [] ;
       // this.$refs.table_workinfo.setUrl(this.URL+"api/get_work_list_ex?w_type=100", (rows) => {
      //    this.$http.get(this.URL+'api/get_work_list_ex?w_type=100&search_term='+value+'&per_page=100&page=1').then((rows) => {
        this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_logger_list_ex?m2_point_id="+this.tag_id+'&search='+this.value, (rows) => {
          console.log(rows)
          if((!rows)||(rows.length<=0)) return;
          this.clicked_l_idx = rows[0].id
          this.aurl = this.URL+'api/get_wav/water_leak_logger_detail/'+rows[0].id
          //  this.avwaveform.load(aurl)
          this.wavesurfer.load(this.aurl);
          this.fft_url = this.URL+'api/get_fft/water_leak_logger_detail/'+rows[0].id+'#'+this.vchart;
        })
     },
      Enter_Check(){
       //  if(event.keyCode == 13){
         this.go_search();  // 실행할 이벤트
    },
   onZoomClickEvent(){
      var s_min = this.$refs.fftchart.select_min; //09-03 이렇게 들어온다
      var s_max = this.$refs.fftchart.select_max; //09-03 이렇게 들어온다
    //  this.min_value = s_min;
    //  this.max_value = s_max;
      this.slice_url = this.URL+'api/get_wave_slice?w_type=104&idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
      // this.slice_url = 'http://dev.neverlosewater.com/node/api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
    },    
     fn_press_han(event)
    {
        var objTarget = event.srcElement || event.target;
    // if(objTarget.type == 'text') {
      
      var value = objTarget.value;
      //alert(value);
      if(isNaN(value)){
        objTarget.value =value.replace(/[^0-9]/g,'');
        // alert('Please enter only numbers');
        // event.preventDefault();          
      }        
    },      
  },
  created () {
    this.$EventBus.$on('sidebar_resize', () => {
      setTimeout(()=>{
        this.$refs.fftchart.redraw()
        if(this.clicked_l_idx>-1){
          this.aurl = this.URL+'api/get_wav/water_leak_logger_detail/'+this.clicked_l_idx ;
          this.wavesurfer.load(this.aurl);

        }
      }, 500)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.window_resiz)
  },
  mounted () {
    //this.map_height = window.innerHeight/2 - 30;
    window.addEventListener('resize', this.window_resiz);
    this.window_resiz(); 
    //this.window_resiz()
    if(sessionStorage !== null && typeof (sessionStorage) !== undefined && sessionStorage.level < 2){
       this.levelA = true       
    }else{
       this.levelA = false
    }
    setTimeout(()=>{
          let setting = this.$store.setting
          if(setting) {
              //this.pressure_value_type = this.$store.setting.pressure.legend.value
              this.value_type = 'max'
          }
        }, 1000)    
      //left메뉴 데이터를 뿌려준다.
       this.box = [] ;
      // var sel_id = Number(this.tag_id) ;
      
      this.$http.get(this.URL+'api/API21/water_leak_logger/list?type='+this.$route.query.widx).then((res) => {
          if(res.data.results[0].length <1) return
        
          for(let i=0; i<res.data.results[0].length; i++) {
            
            var abox = {};
          //  abox = {'name': rows.data.rows[i].name, 'mnf': Math.round(Number(rows.data.rows[i].mnf)) , 'flow':  Math.round(Number(rows.data.rows[i].flow)) ,  'status': 'blue' } ; 
            var check =  res.data.results[0][i]._5days
            if(check !== null && check !== undefined)
               check =  check.split(",") ;
            else
               check = null              
            
            abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id ,  'device_sn':res.data.results[0][i].device_sn ,
                   "day_0" : check !== null && check.length>0 ?  "circle color_0"+check[0] : "circle color_03",
                   "day_1" : check !== null && check.length>1 ?  "circle color_0"+check[1] : "circle color_03",
                   "day_2" : check !== null && check.length>2 ?  "circle color_0"+check[2] : "circle color_03",
                   "day_3" : check !== null && check.length>3 ?  "circle color_0"+check[3] : "circle color_03",
                   "day_4" : check !== null && check.length>4 ?  "circle color_0"+check[4] :"circle color_03", 

              'start_datetime':res.data.results[0][i].measure_start_datetime.substring(0,13)+"H",'end_datetime':res.data.results[0][i].measure_end_datetime.substring(0,13)+"H", 
             'strength': res.data.results[0][i].max   } ; 
             this.box.push(abox) ;
             /* 
             if(sel_id === res.data.results[0][i].id){                  
               setTimeout(()=>{
                 this.updateCheckIndex(i)
               }, 500)
             }
             */
            
           
          }
      
          this.updateCheckIndex(0) ;
       }) ;
      document.getElementById("hidden-container").style.display = 'none';
      this.wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#4488ee',
        progressColor: 'blue',
        mediaControls: true,
        backend: 'MediaElement',
        normalize : true,

        //waveColor: '#D2EDD4',
        //progressColor: '#46B54D',
        //   barWidth: 2,
        //   barHeight: 0.5, // the height of the wave
        //   autoCenter: true,
        height: this.waveform_height,
        //   width: 600,
        plugins: [
          TimelinePlugin.create({
              container: '#wave-timeline',    
            }),
        ]
      });
    this.wavesurfer.on('finish', () => {
      this.is_playing = false
    });

    this.wavesurfer.on("loading", function () {
      document.getElementById("hidden-container").style.display = '';
      var mediaElt1 = document.getElementById('waveform');
      var mediaElt = mediaElt1.querySelector('audio');
      mediaElt.style = "width:40%;height:50px; transform:scale(0.7); position: absolute; top:10px;left:-20px;border:0; outline:0;" ;
      //  this.wavesurfer.params.mediaControls = true;
    });
  
    setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
               
               if(this.clicked_l_idx>-1){
                  //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
                  this.wavesurfer.empty()
                  this.wavesurfer.load(this.aurl);
                  this.$refs.fftchart.redraw()
               }
           }
         }
     }, 500)

  },
  components: {
  //  FFTChart1: FFTChart1,
    pressChartData_fft: pressChartData_fft,
    ServerSideTable: ServerSideTable,
    Map: Map,
    SearchLeftType3,  
    DlgLeakLoggerInfo:DlgLeakLoggerInfo,
    DlgLeakLoggerDetailInfo:DlgLeakLoggerDetailInfo,
  }
}

</script>

<style lang="scss" >
/*
    #waveform{
          background: rgba(0,0,0,0.8);
    }
*/
#waveform .audio {
  all: none;
  width: 300px;
  position :absolute;
  z-index:1;
}

.inner-bg {
  float : left;
  /* 공통 속성값 지정 */

  display: inline-block;
  /* 인라인요소를 인라인블록 요소로 */
  height: 30px;
  /* 이미지 공간을 확보하기 위해 높이값 지정 */

  background: #c0c0c0;
  padding-left:10px;
  padding-right: 10px;

}

.content-area {
  padding: 0 30px;
}
.leak-detail-content {
  padding: 10px 30px 0;
   // 2022 new
  // background: #fff;
  background: #172A46;
  border: 1px solid #172A46;
  color: #fff;
}
.leak-detail-content-map {
  padding: 0;
}
.left .list_box.list_box2.Leak_logger {
  min-height: 768px;
  height: calc(100vh - 50px - 70px  - 50px - 37px - 19px - 61px);
}
.Leak_logger .list_box.list_box2 {
  height: calc(100vh - 200px);
}
.table-button-group {
  margin-bottom: 15px;
}
.table-button-group .btn-lk-middle {
  width: 100%;
}
.left.leak-detail {
  height: auto;
}
table.type01 th {
  padding: 12px 0;
  background-color: aqua;
}

.btn-default {
  color: #fff;
}
.btn-default :hover{
  color: #000;
}
i {
    font-style: normal;
}
/*
table.vgt-table.vgt-fixed-header {
    position: relative !important;
}

table.vgt-table.vgt-fixed-header th:last-child {
    /* Offset for the scrollbar, you may have to adjust this */
  /*  width: calc(100% + 17px) !important;
}

table.vgt-table:not(.vgt-fixed-header) > thead {
    /* Collapse the first row which is the un-fixed table header */
   // visibility: collapse !important;
//}

.vgt-table{ 
  overflow-y: auto; height: 100px; 
}
.vgt-table th { 
  position: sticky; top: 0; 
}
.green {
  background-color: green;
}

.red {
  background-color: red;
}
// 2023
// .left.leak-detail.M2{
//   width: 50%;
//   margin-top:-20px;
// }
.right.M2{
  width: 50%;
  padding-right: 0;
}
.right.M2 .row{
  margin : 0 ;
}
.content-area.row.justify-content-around.M2{
  padding: 0 16px;
}
.search_div_flow{
    position: absolute;
    left:215px;
    top: 5px;
    z-index: 111;
}
.row>* {
    padding-right: 0px;
}

</style>

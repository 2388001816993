
<script>
//import { Line ,mixins} from 'vue-chartjs'
import { Line } from 'vue-chartjs'
//const {reactiveProp} = mixins;

  export default {
    extends: Line,
  //  mixins: [reactiveProp],
   props:{
      chartData: {
        type: Array
      },
      chartData1: {
        type: Array
      },
     chartData2: {
        type: Array
      },
      chartData3: {
        type: Array
      },
     chartData4: {
        type: Array
      },  
      ymin :  {} ,  
      xdata: {
        type: Array,
      },
      yopt:{
        
      },
  },

    watch: {
      chartData1: function() {
        this.$data._chart.destroy()
        this.datacollection.labels = this.xdata;
        this.datacollection.datasets[0].data= this.chartData1;
        this.datacollection.datasets[1].data= this.chartData2;
        this.datacollection.datasets[2].data= this.chartData3;
        this.datacollection.datasets[3].data= this.chartData4; 
        this.options.scales.yAxes[0].ticks.beginAtZero = this.ymin !== null ? false : true;      
        this.options.scales.yAxes[0].ticks.min = Number(this.ymin);      
        this.renderChart(this.datacollection, this.options) ;
        this.$data._chart.update()
      },
    
      bar_idx : function() {
        this.onBarClickEvent(this.bar_idx) ;

      }
      

    },
    data () {
      return {

        datacollection: {

          //  labels: ["2014", "2013", "2012", "2011", "2010"],
            labels: this.xdata,
            bar_idx: -1,           
            datasets: [{
             // data: [727, 589, 537, 543, 574],
              label: 'Max',
              type: 'line',
              data: this.chartData1,
              hidden :true,
              yAxisID: "y-axis-1",
              borderColor	: '#F4BD48',
           //   pointBackgroundColor: 'white',
              borderWidth: 1,
              pointRadius : 2,                   
              backgroundColor: "#F4BD4815",
              //hoverBackgroundColor: "#e5a419",
              //fill:false
           },
          
           {
            //  data: [238, 553, 746, 884, 903],
              label: 'Avg',
              data: this.chartData2,
              type: 'line',
              hidden :true,
              backgroundColor: "#00cc6615",
              yAxisID: "y-axis-1",
              //hoverBackgroundColor: "#051e41",
               borderColor	: '#00cc66',
           //   pointBackgroundColor: 'white',
              borderWidth: 1,
              pointRadius : 2, 
          
            },
          
            {
            //  data: [1238, 553, 746, 884, 903],
              label:'Min' ,
              data: this.chartData3, 
              type: 'line',
              hidden :true,
              yAxisID: "y-axis-1",
              backgroundColor: "#a7141415",
              // hoverBackgroundColor: "#14a8bf",
              borderColor	: '#a71414',
           //   pointBackgroundColor: 'white',
              borderWidth: 1,
              pointRadius : 2,    

            },
                    {
            //  data: [1238, 553, 746, 884, 903],
              label:'Mnp' ,
              data: this.chartData4, 
              type: 'bar',
              yAxisID: "y-axis-1",
               backgroundColor: "#2BB7CD",
              // hoverBackgroundColor: "#14a8bf",
               borderColor	: '#2BB7CD',
           //   pointBackgroundColor: 'white',
              borderWidth: 1,
              pointRadius : 2,    

            }]


        },
        options: {
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero:true,
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:11
              },
              scaleLabel:{
                display:true
              },
              gridLines: {
                 display:false,
              },
              stacked: false
              }],
              /*
              yAxes: [{
                gridLines: {
                  display:true,
               //   color: "#fff",
              //    zeroLineColor: "#fff",
               //   zeroLineWidth: 0
                },
                ticks: {
                  fontFamily: "'Open Sans Bold', sans-serif",
                  fontSize:11
                },
                stacked: false
              }]
*/
              yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                          display: true,
                          labelString: 'Pressusre (bar)'
                        },  
                        ticks:this.yopt
                        /*
                          ticks:           
                                {
                                 // beginAtZero: false,       
                                 // max: this.ymax,
                                 // max: this.ymin+2,
                                  min: this.ymin,     
                                  //stepSize  : this.ymax/5
                                }
                          */      
                         }
          
                    /*
                     {
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        scaleLabel: {
                          display: true,
                          labelString: 'MNP (㎥/day)'
                        },      
                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        }, 
                        
                       } */
                    ],
                 
          },
          /*
           legend: {
                    display: true,
                    labels: {
                        filter: (legendItem, data) => {
                           if(legendItem.text !== 'Mnp'){
                              console.log(data)
                             // data.datasets[0].hidden
                              return true;  
                           }else{
                            return false; // this should hide all legends
                           }
                        }
                    }
                },
          */
       
          legend: {
              display: true,
              labels: {
                    fontColor: "white",
                    fontSize : 16, 
              }
            },
       
          title:{
            display : false,
            text : 'Latest 30 Water leak sound collection works',
            fontSize : 18
          },
          
          responsive: true,
          maintainAspectRatio: false,
          onClick:this.handle
        }
      }
    },

    methods: {
        onBarClickEvent(idx){
            this.bar_idx = idx ;
           this.$emit('onBarClick') ;
        },
        handle (point, event) {
          const item = event[0] ;
          if( item !== undefined ) {
          var index = item._index;
         // var datasetLabel = item.data.labels[index];
          var xLabel =  item._chart.data.labels[index];

          var idx = xLabel.split(':') ;
          this.bar_idx = idx[0];
          this.onBarClickEvent(idx) ;
          }
          /*
           this.$emit('on-receive', {
            index: item._index,
            backgroundColor: item._view.backgroundColor,
            value: this.values[item._index]
          })
          */
    }
    },
    mounted () {
  //    this.height = 150;
      this.renderChart(this.datacollection, this.options)
      
     
    }
  }
</script>

<template>
    <div>
        <b-modal
            ref="modal"
            :title="$t('m2_point_setting.title')"
            >
            <b-tabs  v-if="properties!==null" align="center" v-model="tabIndex">
                <b-tab :title="$t('m2_point_setting.tab_title1')" active>  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>
       
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.customer')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['customer_name']" disabled></b-input>
                        </b-col>
                    </b-row>
                  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.worker')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['worker']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.f_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['facility_id']" disabled></b-input>
                        </b-col>
                    </b-row>
                  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.p_name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_name']" ></b-input>
                        </b-col>
                    </b-row> 
                     <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.p_addr')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_address']" ></b-input>
                        </b-col>
                    </b-row>   

                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.p_type')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_type']" disabled ></b-input>
                        </b-col>
                    </b-row>  
                    <!--
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.p_pos')}}Point Location</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_location']" disabled></b-input>
                        </b-col>
                    </b-row>
                    -->
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.desc')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_desc']" ></b-input>
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.elevation')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_elevation']"   oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" ></b-input>
                        </b-col>
                    </b-row> 
                     <b-row v-if="properties['point_type'] === 'Water Pressure'">
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.mnp_boundary')}}</label>
                        </b-col>
                        <!--b-col sm="4">
                             <b-select size="sm" v-model="properties['mnp_date_setting']" :options="mnp_date_setting_option" ></b-select>
                        </b-col>
                        <b-col sm="4">
                            <b-input size="sm" v-model="properties['mnp_boundary']"   oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" 
                            :disabled = "properties['mnp_date_setting'] !== 0" 
                            ></b-input>
                        </b-col-->
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['mnp_boundary']"   oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" 
                            ></b-input>
                        </b-col>

                    </b-row>                                                                                                                                                                                
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.c_date')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['register_date']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label >{{$t('facility_info.status')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select  size="sm" v-model="properties['point_status']" :options="f_status_option" disabled></b-select>
                        </b-col>
                    </b-row>          
                </b-tab>

                <b-tab :title="$t('m2_point_setting.tab_title2')" >
                  <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.device_id')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['cd_id']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.customer')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['customer_name']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.serial_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['device_sn']" disabled></b-input>
                        </b-col>
                    </b-row>                                     
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.device_type')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['device_type']" disabled></b-input>
                        </b-col>
                    </b-row>
                  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.device_desc')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['device_desc']" disabled></b-input>
                        </b-col>
                    </b-row>
               
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.firm_ver')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['firmware_ver']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.board_ver')}}</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['board_ver']" disabled  ></b-input>
                        </b-col>
                    </b-row>           
                  <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.r_date')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['cd_register_date']" disabled ></b-input>
                        </b-col>
                    </b-row>
                </b-tab>
         
            <b-tab :title="$t('m2_point_setting.tab_title3')" >
                  <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.measure_mode')}}</label>
                        </b-col>
                        <b-col sm="6" style="padding: 5px 15px;" >                           
                            <b-select  size="sm" v-model="properties['measure_mode']" :options="measure_mode_option"  disabled ></b-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.press_mea_intr')}}</label>
                        </b-col>
                        <b-col sm="6" style="padding: 5px 15px;">
                            <!--b-select  size="sm" v-model="properties['press_measure_interval']" :options="pressure_mea_itr_option"   :disabled=set2 ></b-select-->
                           <b-select  size="sm" v-model="press_measure_interval" :options="pressure_mea_itr_option"   :disabled=set2 ></b-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.press_send_interval')}}</label>
                        </b-col>
                        <b-col sm="6" style="padding: 5px 15px;">
                            <b-select  size="sm" v-model="properties['press_measure_count']" :options="pressure_send_intr_option"   :disabled=set2 ></b-select>
                         
                        </b-col>
                    </b-row>   
                    <!--
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.press_mea_cnt')}}<br/>(1~288)</label>
                        </b-col>
                        <b-col sm="6">
                            <b-input size="sm" v-model="properties['press_measure_count']" :disabled=set2  
                            v-on:keyup="checkValue(1,$event)"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"   ></b-input>
                        </b-col>
                    </b-row>
                    -->
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.leak_mea_time')}}</label>
                        </b-col>
                        <b-col sm="6"  style="padding: 0px 15px 5px;">
                                <b-select  size="sm" v-model="properties['leak_sound_measure_time']" :options="leak_mea_time_option"   :disabled=set1 ></b-select>                            
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.leak_mea_intr')}}<br/>(0~23)</label>
                        </b-col>
                        <b-col sm="6">
                            <b-input size="sm" v-model="properties['leak_sound_measure_interval']"  :disabled=set1
                            v-on:keyup="checkValue(2,$event)"
                             oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" >
                             </b-input>
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.fft_mode')}}<br/></label>
                        </b-col>
                        <b-col sm="6"  style="padding: 5px 15px;">
                               <b-select  size="sm" v-model="properties['leak_sound_fft_mode']" :options="fft_mode_option"   :disabled=set1 ></b-select> 
                        </b-col>
                    </b-row>                    
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.flow_mea_intr')}}</label>
                        </b-col> 
                        <b-col sm="6">
                            <!--b-input size="sm" v-model="properties['water_flow_measure_interval']" :disabled=set3  
                            v-on:keyup="checkValue(3,$event)"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" ></b-input-->
                            <b-select  size="sm" v-model="flow_measure_interval" :options="flow_mea_itr_option"   :disabled=set3 ></b-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="6">
                            <!--label>{{$t('m2_point_setting.press_send_interval')}}</label-->
                            <label>Flow Send Interval</label>
                        </b-col>
                        <b-col sm="6" style="padding: 5px 15px;">
                            <b-select  size="sm" v-model="properties['water_flow_measure_count']" :options="flow_send_intr_option"   :disabled=set3 ></b-select>
                         
                        </b-col>
                    </b-row>  
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.s_r_date')}}</label>
                        </b-col> 
                        <b-col sm="6">
                            <b-input size="sm" v-model="properties['ms_register_date']" disabled  ></b-input>
                        </b-col>
                    </b-row>                                  
                    <b-row>
                       <b-col sm="6">
                          <label>{{$t('m2_point_setting.u_date')}}</label>
                       </b-col> 
                       <b-col sm="6">
                          <b-input size="sm" v-model="properties['ms_update_datetime']" disabled  ></b-input>
                        </b-col>
                    </b-row>      
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.battery')}}</label>
                        </b-col> 
                        <b-col sm="6">
                            <b-input size="sm" v-model="properties['battery']" disabled  ></b-input>
                        </b-col>
                    </b-row>      
                     <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.comm_sense')}}</label>
                        </b-col> 
                        <b-col sm="6">
                            <b-input size="sm" v-model="properties['comm_sense']" disabled  ></b-input>
                        </b-col>
                    </b-row>                                       
                    <b-row>
                        <b-col sm="6">
                            <label>{{$t('m2_point_setting.setting_time')}}</label>
                        </b-col> 
                        <b-col sm="6">
                            <b-input size="sm" v-model="properties['device_time']" disabled  ></b-input>
                        </b-col>
                    </b-row>                      
            </b-tab>
            <b-tab title="Staus">
                <b-row>
                        <b-col sm="5">
                          
                        </b-col> 
                                                 
                        <b-col sm="4" style="padding-right:0px;">
                                <b-input size="sm" v-model="last_date" type="date" @change="change_last_date($event)" class="input_date  m-0 btn-gray-date"/> 
                        </b-col>
                          <b-col sm="3">
                              <button  class="btn-primary" type="button" @click="search_status" v-b-tooltip.hover>Search</button>
                        </b-col> 
                    </b-row>                      
               
               <vue-good-table ref="table"
                   :columns="columns"
                   :rows="table"
               />
            </b-tab>            
            <b-tab :title="$t('m2_point_setting.tab_title4')">
              
                    <p v-for="(item,i) in img_list" :key="i">
                                    <b-card
                                        no-body
                                        img-top
                                        class="photo-card"
                                    >
                                        <b-img thumbnail fluid :src="item" alt="image" bottom ></b-img>
                                        <!--<b-img thumbnail fluid src="http://localhost:3000/api/get_img/leakage/6/1" alt="Image" bottom></b-img>-->
                                    </b-card>      
                    </p>                    
            </b-tab>
          
            <b-tab v-if="is_admin" title="Health Check" >
                <b-row>
                        <b-col sm="1">
                        </b-col> 
                        <b-col sm="3" style="padding:0px;">
                            <b-input size="sm" v-model="s_date" type="date" class="input_date  m-0 btn-gray-date"/> 
                        </b-col>                         
                         <b-col sm="1">
                          ~
                        </b-col> 
                        <b-col sm="3" style="padding:0px;">
                            <b-input size="sm" v-model="e_date" type="date"  class="input_date  m-0 btn-gray-date"/> 
                        </b-col>
                          <b-col sm="3" style="text-wrap: nowrap;">
                              <button  class="btn-primary" style="margin-right:10px;" type="button" @click="search_status1" v-b-tooltip.hover>Search</button>
                              <b-button variant="lk-middle lk-btn-right border-left-line"  @click="go_download"   >
                                <download-excel  class   = "btn-default"                     
                                        :data   = "json_data"
                                        :fields = "json_fields"
                                        worksheet = "My Worksheet"
                                        :name    = "filename">
                                    <i class="mdi mdi-download mr-2"></i> 
                                </download-excel> 
                              </b-button>  
                        </b-col> 
                    </b-row>                      
               <div class="m2_scroll" >
               <vue-good-table ref="table"
                   :fixed-header="true"   
                   :columns="columns1"
                   :rows="table1">
                   <template slot="table-row" slot-scope="props" >
                       <span v-if="props.column.field == 'com_status' && props.row.com_status !== '정상' ">
                            <div style="color: #ff8282;">{{props.row.com_status}}</div> 
                        </span>
 
                        <span v-else>
                        {{props.formattedRow[props.column.field]}}
                        </span>
                   
                   </template>
               </vue-good-table>
               </div>
            </b-tab>  
            <b-tab v-if="is_admin" title="Data Check">
                <b-row>
                        
                        <b-col sm="4" style="padding-left:20px;">
                            <b-input size="sm" v-model="s_date1" type="date" class="input_date  m-0 btn-gray-date"/> 
                        </b-col>                         
                        <b-col sm="1" >~</b-col>
                        <b-col sm="3" style="padding:0px;">
                            <b-input size="sm" v-model="e_date1" type="date"  class="input_date  m-0 btn-gray-date"/> 
                        </b-col>
                          <b-col sm="4" style="text-wrap: nowrap;">
                              <button  class="btn-primary" type="button" style="margin-right:10px;" @click="search_status2" v-b-tooltip.hover> Search</button>
                              <b-button variant="lk-middle lk-btn-right border-left-line" @click="go_download1"   >
                                <download-excel                     
                                            :data   = "json_data1"
                                            :fields = "json_fields1"
                                            worksheet = "My Worksheet"
                                            :name    = "filename1">
                                        <i class="mdi mdi-download mr-2"></i> 
                                    </download-excel> 
                              </b-button>  
                        </b-col> 
                    </b-row>                      
                <div class="m2_scroll" >
               <vue-good-table ref="table2"
                   :columns="columns2"
                   :rows="table2"
                   :fixed-header="true"   
               >
                 <template slot="table-row" slot-scope="props" >
                       <span v-if="props.column.field == 'com_status' && props.row.com_status !== '정상' ">
                            <div style="color: #ff8282;">{{props.row.com_status}}</div> 
                        </span>
                        <span v-else-if = "props.column.field == 'water_press' ">
                            <div >{{to_bar(props.row.water_press)}}</div> 
                        </span>
                        <span v-else>
                        {{props.formattedRow[props.column.field]}}
                        </span>
                   
                    </template>
               </vue-good-table>
                </div>
            </b-tab>                              
        </b-tabs>
<!--
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="apply()">Apply</b-button>
                
                <b-button @click="cancel1()">Close</b-button>
            </template>
-->
           <template v-slot:modal-footer="{  }">

              <b-row class="modal-footer-row m-0 leak_foot">
               <b-col sm="6">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">Close</b-button>
               </b-col>
               <b-col sm="6">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">Apply</b-button>
               </b-col>              

             </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;

export default {
    name: 'DlgM2MonitoringSetting',
    props: {
    },
    watch:{
     tabIndex(){
        if (this.tabIndex === 5) {
           this.search_status1()
        }else if (this.tabIndex === 6) {
           this.search_status2()
        }   
     },
     press_measure_interval(){
         if(this.press_measure_interval === 1){             
             this.pressure_send_intr_option = this.pressure_send_intr_option_1m
             this.properties['press_measure_count'] = this.properties['press_measure_count'] * 10
         }else{              
             this.pressure_send_intr_option = this.pressure_send_intr_option_10m  
             this.properties['press_measure_count'] = this.properties['press_measure_count'] / 10
         }
     },
     flow_measure_interval(){
         if(this.flow_measure_interval === 1){             
             this.flow_send_intr_option = this.pressure_send_intr_option_1m
             this.properties['water_flow_measure_count'] = this.properties['water_flow_measure_count'] * 10
         }else{              
             this.flow_send_intr_option = this.pressure_send_intr_option_10m  
             this.properties['water_flow_measure_count'] = this.properties['water_flow_measure_count'] / 10
         }
     }

    },
    data() {
        return {
            properties: null,
            is_admin :false,
            properties_bak: null,
            tag_id: -1,
            tabIndex:0,
            set1: true,
            set2: true,
            set3: true,
            last_date : null,
            e_date : null,
            s_date : null,
            e_date1 : null,
            s_date1 : null,
            p_mode : '',
            recount: 0,
            myWorkItem : false,
            ele_data : 0,
            measure_mode:1,
            filename: 'filename.xls',
            filename1: 'filename1.xls',
            measure_mode_option: [
                { value: 1, text: ' Water Leak' },
                { value: 2, text: ' Water Pressure' },
                { value: 3, text: ' Water Flow' }, 
           
            ],
            flow_measure_interval : 10,
            flow_send_intr_option : [],
            flow_mea_itr_option : [
                { value: 1, text: '1min' },
                { value: 10, text: '10min' },
            ],
            press_measure_interval : 10,
            pressure_send_intr_option : [],
            pressure_mea_itr_option: [
                { value: 1, text: '1min' },
                { value: 10, text: '10min' },
                /*
                { value: 20, text: ' 20' },
                { value: 30, text: ' 30' }, { value: 40, text: ' 40' }, 
                { value: 50, text: ' 50' }, { value: 60, text: ' 60' },                            
                */
            ],            
            pressure_send_intr_option_1m: [
                { value: 60, text: '1Hour ' },
                { value: 240, text: '4Hour' },
                { value: 360, text: '6Hour' },
                { value: 720, text: '12Hour' },
                { value: 1440, text: '24Hour' },
            ],

            pressure_send_intr_option_10m: [
                { value: 6, text: '1Hour ' },
                { value: 24, text: '4Hour' },
                { value: 36, text: '6Hour' },
                { value: 72, text: '12Hour' },
                { value: 144, text: '24Hour' },
            ],
            leak_mea_time_option : [
                { value: 1, text: ' 1' }, { value: 2, text: ' 2' },
                { value: 3, text: ' 3' }, { value: 4, text: ' 4' }, 
                { value: 5, text: ' 5' }
            ],
            mnp_date_setting_option : [
                { value: 0, text: 'Direct input' }, { value: 1, text: '30day_avg' },
                { value: 2, text: '60day_avg' }, { value: 3, text: '90day_avg' }, 
                
            ],
            fft_mode_option : [
                { value: 0, text: ' OFF' }, { value: 1, text: 'ON' },
            ],            
            columns: [
                {label: this.$t('m2_point_setting.table.id'), field: 'm2_device_id',sortable: false},
                {label: this.$t('m2_point_setting.table.reg_datetime'), field: 'registration_datetime',sortable: false},
                {label: this.$t('m2_point_setting.table.device_time'), field: 'device_time',sortable: false},
                {label: this.$t('m2_point_setting.table.battery'), field: 'battery_level'},
                {label: this.$t('m2_point_setting.table.com_sense'), field: 'modem_signal_sensitivity'},
                {label: this.$t('m2_point_setting.table.temp'), field: 'device_temperature'},
            ],
            table: [],
            table1:[],
            columns1: [
                {label: this.$t('m2_point_setting.table.id'), field: 'devie_sn',sortable: false},
                {label: this.$t('m2_point_setting.table.reg_datetime'), field: 'reg_dt',sortable: false},
                {label: this.$t('m2_point_setting.table.device_time'), field: 'dev_dt',sortable: false},
                {label: 'status', field: 'com_status'},
             
            ],
            table2:[],
            columns2: [
                {label: this.$t('m2_point_setting.table.id'), field: 'devie_sn',sortable: false},
                {label: "measure_dt", field: 'measure_dt'},
                {label: "water_press", field: 'water_press',sortable: false},
                {label: "dev_temp", field: 'dev_temp',sortable: false},
                {label: 'status', field: 'com_status'},
             
            ],
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            json_data :[],
            json_fields:{
                'device_sn' : 'devie_sn',
                'reg_dt': 'reg_dt',              
                'dev_dt': 'dev_dt',            
                'status': 'com_status',
            },
            json_data1 :[],
            json_fields1:{
                'device_sn' : 'devie_sn',
                'measure_dt': 'measure_dt',   
                'water_press': 'water_press',                 
                'dev_temp': 'dev_temp',            
                'status': 'com_status',
            },            
        }
    },

    mounted () {
    },
    methods: {
      to_bar(v){
          if(v === null){
              return '-'
          }else{
              return  Math.round(v/14.504*100)/100
          }
      },  
      linkClass(idx) {
        if (this.tabIndex === idx && idx === 5) {
           this.search_status1()
        }else if (this.tabIndex === idx && idx === 6) {
           this.search_status2()
        }
      },
       checkValue(type,event) {
          var objTarget = event.srcElement || event.target;
          var value = objTarget.value;
          if(type === 1 && (value < 1 || value > 288)) {             
             alert(this.$t('m2_point_setting.alt_1_288'));
             this.properties['press_measure_count'] = this.properties_bak['press_measure_count'] ;
          }else if(type === 2 && (value < 0 || value > 24)) {
             alert(this.$t('m2_point_setting.alt_0_24'));
             this.properties['leak_sound_measure_interval'] = this.properties_bak['leak_sound_measure_interval'];
          }else if(type === 3 && (value < 60 || value > 1440)) {
             alert(this.$t('m2_point_setting.alt_60_1440'));
             this.properties['water_flow_measure_interval'] = this.properties_bak['water_flow_measure_interval'];
          }
        },
        current_date(today){
            var year = today.getFullYear();
            var month = ('0' + (today.getMonth() + 1)).slice(-2);
            var day = ('0' + today.getDate()).slice(-2);
           // var hours = ('0' + today.getHours()).slice(-2);
           // this.current_hour =  today.getHours()    

            var dateString = year + '-' + month  + '-' + day ;


            return dateString
        },        
        go_download() {
            let rows = this.$refs.table.rows ;
            if(rows.length<=0) {
                alert(this.$t('leak_detail.alt_no_data'));
                return;
            }
            this.json_data = rows ;
        },
        go_download1() {
            let rows = this.$refs.table2.rows ;
            if(rows.length<=0) {
                alert(this.$t('leak_detail.alt_no_data'));
                return;
            }
            this.json_data1 = rows ;
        },        
        search_status1(){
             this.table1 = []     
             let serial_id = this.properties.device_sn   
            
             let e_date = (this.e_date === null ? '':this.e_date ).replaceAll('-','')
             let s_date =  (this.s_date === null ? '':this.s_date ).replaceAll('-','')
             this.filename = serial_id+"_"+s_date+"_he.xls"
             this.$http.get(this.URL+'api/monitoring/status_list?type=1&device_sn='+serial_id+'&start_dt='+s_date+'&end_dt='+e_date).then((res) => {
                if(res.data.list[0].length <1) return
                this.table1 = res.data.list[0]            
             });
        },
        search_status2(){
             this.table2 = []     
             let serial_id = this.properties.device_sn   
             let e_date = (this.e_date1 === null ? '':this.e_date1 ).replaceAll('-','')
             let s_date =  (this.s_date1 === null ? '':this.s_date1 ).replaceAll('-','')
             this.filename1 = serial_id+"_"+s_date+"_da.xls"
             this.$http.get(this.URL+'api/monitoring/status_list?type=2&device_sn='+serial_id+'&start_dt='+s_date+'&end_dt='+e_date).then((res1) => {
                if(res1.data.list[0].length <1) return
                this.table2 = res1.data.list[0]            
             });
        },
        search_status(){
            this.table = []  
            var l_date = this.last_date + " 23:59:59"   
             this.$http.get(this.URL+'api/API21/m2_device_status/day/'+this.tag_id+'?to='+ l_date+"&limit=2").then((res1) => {
                if(res1.data.results[0].length <1) return
                this.table = res1.data.results[0]            
             });
        },
         change_last_date(e) {
           this.last_date = e

           //this.get_time_series_mnf()
         },
         show(id) {
            this.tag_id  = id;
            this.properties = [] ;
           // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
           if(sessionStorage.level < 2){
               this.is_admin = true
           }
            this.img_list =[];
            // this.$http.get(this.URL+'api/get_press_info?idx='+idx).then((res) => {
            this.$http.get(this.URL+'api/get_m2_monitoring_setting?id='+id).then((res) => {
                 
            if(res.data.length<=0) return           
            this.table1 = []
            this.table2 = []
            this.properties = res.data.list[0]; 
             
              //  if(sessionStorage.m_id === this.properties.worker )
              //       this.myWorkItem = true
              
                
             //    let p_type = this.properties['point_type'] ;
                 // 
             //    let ps_type = ['ALL',' Water Leak',' Water Pressure' ,' Water Flow'  ]
              //   this.properties['point_type']  = ps_type[p_type] 
                
                 if(this.properties.image_list == null || this.properties.image_list  == undefined){
                 
                  this.img_list.push(require('@/assets/noimage1.png')) 
                  //this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
                  this.img_list.push(require('@/assets/noimage1.png'));
                  this.img_list.push(require('@/assets/noimage1.png'));
                  
                }else{
                    
                  var img = this.properties.image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
                }       
                this.press_measure_interval = this.properties['press_measure_interval']
                this.pressure_send_intr_option = this.properties['press_measure_interval'] === 1 ? this.pressure_send_intr_option_1m : this.pressure_send_intr_option_10m
                this.flow_measure_interval = this.properties['water_flow_measure_interval']
                this.flow_send_intr_option = this.properties['water_flow_measure_interval'] === 1 ? this.pressure_send_intr_option_1m : this.pressure_send_intr_option_10m
                 if(this.properties.measure_mode === 1){
                     this.set1 = false
                     this.set2 = true
                     this.set3 = true
                     this.is_admin = false
                 }else if(this.properties.measure_mode === 2){
                     this.set2 = false
                     this.set1 = true
                     this.set3 = true
                     this.is_admin = true
                 }else {
                    this.set1 = true
                     this.set2 = true 
                    this.set3 = false
                    this.is_admin = false
                 }
            
                this.properties_bak = Object.assign({}, this.properties)
          

             })
             this.table = []     
             this.$http.get(this.URL+'api/API21/m2_device_status/day/'+id).then((res1) => {
                if(res1.data.results[0].length <1) return
                this.table = res1.data.results[0]
                var sdate = res1.data.results[0][0].device_time 
               if(sdate !== null) {  
                  this.last_date = sdate.substring(0,10)                      
               }
            })
            const today = new Date();
            this.e_date = this.current_date(today)
            this.e_date1 = this.current_date(today)
            const ss_date = new Date(today.setDate(today.getDate() - 7))
            this.s_date =this.current_date(ss_date);
            this.s_date1 =this.current_date(ss_date);
            this.$refs.modal.show();          
    

           },
        del(){
           var con_test1 = confirm("Are you sure you want to delete?");
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test1){
              this.$http.get(this.URL+'api/delete_ex?p_idx_ex='+this.properties['id']).then((res) => {  
                 console.log(res.data);
                 this.$emit('leaktable_refresh') ;
                 this.$refs.modal.hide();
              });

           }
        },
        apply() {
          //  if(localStorage === null || typeof localStorage === undefined){
              if(sessionStorage === null || typeof sessionStorage === undefined){
              //  alert("Can not be modified.")
                return
         //   }else if(localStorage.level === 'C'){
              }else if(sessionStorage.level > 1){
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
            //
            this.properties['press_measure_interval'] = this.press_measure_interval 
            this.properties['water_flow_measure_interval'] = this.flow_measure_interval 

            let params = {} ;
            let params1 = {} ;
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
            if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                if(keys[i] === 'measure_mode' || keys[i] === 'press_measure_interval' || keys[i] === 'press_measure_count' || params[keys[i]] ==='leak_sound_measure_time'
                   || keys[i]  === 'leak_sound_measure_interval' || keys[i]  === 'water_flow_measure_interval' || keys[i]  === 'water_flow_measure_count'){
                  params1[keys[i]] = this.properties[keys[i]]
                  //if(keys[i] === 'measure_mode')
                   // params['point_type'] = this.properties[keys[i]]
                }else{
                  params[keys[i]] = this.properties[keys[i]]
                }
            }
            if(Object.keys(params).length<=0 && Object.keys(params1).length<=0) return
               if(Object.keys(params).length>0){
                  this.$http.get(this.URL+'api/update_customer_m2_point?id='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                   console.log(res.data);
                  })   
               }
               if(Object.keys(params1).length>0) {
                   this.$http.get(this.URL+'api/update_m2_point_device_setting?id='+this.properties['id']+'&cd_id='+this.properties['cd_id']+'&fields='+encodeURIComponent(JSON.stringify(params1))).then((res) => {
                    console.log(res.data);
                  })                   
               }
          
           // this.$emit('worklist_refresh') ;
            this.$refs.modal.hide();     
        }       
    },
    components: {
         //   FFTChart1:FFTChart1
        //  PressBigChartData:PressBigChartData,
      
    }
}
</script>

<style  lang="scss">

.pressure_chart  .highcharts-container {
  width:440px;
}
.pressure_chart svg.highcharts-root {
    max-width: 440px;
}
.facility__label-radio {
  font-size: 12px;
  color: #616161;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}
.tab-content {
    max-height: 720px;    
    overflow-y: auto;    
    overflow-x: hidden; 
}
.m2_scroll .vgt-inner-wrap .vgt-responsive {
  height: 700px;    
  overflow-y: auto;   
}
.modal-info {
  .modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    margin: 70px auto 0;
    background: #F2F2F2;
    width: 350px;
    height: calc(100% - 70px);
    .modal-header {
      padding-bottom: 22px;
      .modal-title {
        font-size: 18px;
        // margin-top: 12px;
      }
    }
    .modal-info-content {
      border: 0;
      border-radius: 0;
 
      &.reversal {
        background: #172A46;
        .nav-tabs {
          background: #172A46;
          white-space: nowrap;
        }
        .tab-content {
          background: #172A46;
          &.is--scroll {
            height: 73vh;
            overflow-y: auto;
          }
        }
        /*
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            // 2022 new
        //   background: #fff;
        }
        */
        .facility__label {
          color: #fff;
          font-size: 12px;
          display: inline-block;
          letter-spacing: 0.5px;
          //width: 35%;
        }
        .facility__input {
          //width: 60%;
          height: 32px;
          font-size: 12px;
          border: 1px solid #707070;
          box-sizing: border-box;
          border-radius: 0;
          margin-top: 0;
          margin-bottom: 14px;
          &.height--auto {
            height: auto;
          }
        }
        .modal-footer {
          background: #172A46;
          padding-bottom: 18px;
          padding-top: 3px;
        }
        .modal-footer-row {
          width: 100%;
          margin: 0;
          padding-bottom: 0;
        }
        .btn.modal-footer__button {
          height: 52px;
          font-size: 14px;
          border: 0;
          &.modal-footer__button-ok {
            // 2022 new
            // background: #28B7CB;
            background: #28AF81;
          }
          &.modal-footer__button-cancel {
            background: #9D9D9D;
          }
        }
        .photo-card {
          border-radius: 0;
          border: 0;
          background: none;
          .img-thumbnail {
            margin-bottom: 10px;
          }
          .card-header {
            height: 150px;
            background: #172A46;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .card-header__text {
              font-size: 16px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
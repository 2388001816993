<template>
  <highcharts id="LeakCountChart" class="LeakCountChart" :options="chartOptions"></highcharts>
</template>

<script>
export default {
  name: 'barHchart',
  props: {

    chartData: {
      type: Number
    },
    chartData1: {
      type: Number
    },
     chartData2: {
      type: Number
    },
     chartData3: {
      type: Number
    },
    series_name: {},
    /*
    interval : {
      // 100 : 0.1초, 1000: 1초
      type : Number,
    },
     sdate : {
      // 100 : 0.1초, 1000: 1초
      type : Number,
    },
    */
    //y_title : { type:String},
    //chart_type : { type:String}
  },
  data () {
    return {

      chartOptions: {
        // 2022 new
        colorAxis: [{ lineColor: '#fff000' }],
        colors: ['#ff9834',"#e9e95f",'#28AF81', '#EB5B5B',],
        credits: {
          text: '',
          href: ''
        },
        series: [
          {
          name: 'Leak suspicion',
          data: [this.chartData2]
        }, {
          name: 'Unidentified',
          data: [this.chartData3]
        },
          {
          name: 'No Leak',
          data: [this.chartData1]
        }, {
          name: this.series_name,
          data: [this.chartData]
        }, ],

        title: { text: '', },
        chart: {  // zoomType: 'x' ,
          type: 'bar',
          backgroundColor: '#0A192F',
          lineColor: '#fff000'
        },

        xAxis: {
          categories: [''],
          lineWidth: 0,
          labels: {
            enabled: false
          }
        },
        yAxis: {
          min: 0,
          max: this.series_name === 'Leak' ? null : Math.ceil(this.chartData, 0),
          title: {
            text: ''
          },
          lineWidth: 0,
          labels: {
            enabled: false
          },
          lineColor: '#fff000',
        
        },
         exporting: {
              buttons: { contextButton: { enabled: false} }
         },    
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            stacking: 'percent',
            dataLabels: {
              enabled: true
            }
          },
          column: {
            pointWidth: 70
          }
        },
      },

    }
  }
}
</script>

<style scoped>
.LeakCountChart .highcharts-container .highcharts-root {
  /* width: 300px; */
  height: 40px;
}

.LeakCountChart {
  min-width: 100px;
  max-width: 300px;
  height: 50px;
  width: 100%;
  /* 2022 new */
  /* margin: -20px 10px; */

}

.LeakCountChart:active {
  /* background-color: #0e5340; */
}

.highcharts-grid-line {
  stroke-width: 0;
  /* 2022 new */
  /* stroke: #ffffff; */
  /* background-color: red; */
}


</style>

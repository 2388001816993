<template>
    <div>
        <b-modal
            ref="modal"
            :title="$t('water_flow.title_info')"
            >
            <b-tabs  v-if="properties!==null" align="center">
                <b-tab :title="$t('pressure.tab_title')" active>
                   <b-row >
                  <!--b-col style="top:-35px; " >
                    <div class="status" >
                      <input type="radio" id="radio_01" name="radio01"  v-model="vchart1" value="kgf">
                      <label for="radio_01">kgf</label>
                      <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart1" value="bar">
                      <label for="radio_02">bar</label>
                      <input type="radio" id="radio_03" name="radio01"   v-model="vchart1" value="psi" >
                      <label for="radio_03">psi</label>
                    </div>
                  </b-col-->
                </b-row>
            
                    <b-row style="height:380px;">
                        <b-col>
                            <!--<FFTChart :url="fft_url" ref="fftchart1" :height="fftchart_height1"/>
                             <FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height1"/>-->
                          <div class="pressure_chart" :style="{height: map_height+'px'}">
                            <!--svg/-->
                             <!--PressBigChartData :url="bar_url"  style="position:absolute; margin-top:-30px;"   /-->
                                 <BlockMonRealTime :url="bar_url" style="position:absolute; margin-top:-30px;"    />
                           </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>
                   
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.table_column.title')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['w_title']"  disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.contents')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['w_contents']" disabled></b-input>
                        </b-col>
                    </b-row>
                  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.worker')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['worker']" disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.point_name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_name']" ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.point_addr')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['address']" ></b-input>
                        </b-col>
                    </b-row>
                    <!--
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.group_name')}}</label>
                        </b-col>
                        <b-col sm="8">                            
                            <b-select  size="sm" v-model="properties['group_id']" :options="group_name" style="margin-bottom:5px;" ></b-select>
                        </b-col>
                    </b-row> 
                    -->                                                                 
                     <b-row>
                        <b-col sm="4">
                            <label>{{$t('leakage_info.f_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['f_idx']" disabled ></b-input>
                        </b-col>
                    </b-row> 
                      <b-row>
                        <b-col sm="4">
                            <label>{{$t('facility_info.f_name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['layer_type']" disabled ></b-input>
                        </b-col>
                    </b-row>      
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.device_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['device_sn']" disabled ></b-input>
                        </b-col>
                    </b-row>                                                                          
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.start_time')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['start_time']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col sm="4">
                            <label>{{$t('pressure.end_time')}}</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['log_time']" disabled  ></b-input>
                        </b-col>
                    </b-row>                   
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('water_flow.max_u')}}</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['max']"  disabled ></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label>{{$t('water_flow.min_u')}}</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['min']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                                      
                      <b-row>
                        <b-col sm="4">
                            <label>{{$t('water_flow.avg_u')}}</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['avg']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                       <!--b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.elevation')}}</label>
                        </b-col>                       
                        <b-col sm="6">
                            <b-input size="sm" v-model="properties['elevation']" v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" >

                            </b-input>
                        </b-col>
                        <b-col sm="2">
                            <b-button v-if="!loading" class="mr-2"  size="sm" @click="update_elevation">
                                <i class="fas fa-sync-alt"></i>
                            </b-button>
                            <span v-if="loading"><img src="@/assets/images/loading.gif" style="width:25px;"/></span>
                        </b-col>
                   </b-row-->                        
                   <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.remarks')}}</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['remarks']"   ></b-input>
                        </b-col>
                    </b-row>      
                </b-tab>

                <b-tab  :title="$t('pressure.photo')">
              
                    <p v-for="(item,i) in img_list" :key="i">
                                    <b-card
                                        no-body
                                        img-top
                                        class="photo-card"
                                    >
                                        <b-img thumbnail fluid :src="item" alt="image" bottom ></b-img>
                                        <!--<b-img thumbnail fluid src="http://localhost:3000/api/get_img/leakage/6/1" alt="Image" bottom></b-img>-->
                                    </b-card>      
                    </p>                    
                </b-tab>
            </b-tabs>
<!--
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="apply()">Apply</b-button>
                
                <b-button @click="cancel1()">Close</b-button>
            </template>
-->
          <template v-slot:modal-footer="{ ok, cancel, hide }">
              <b-row class="modal-footer-row m-0 leak_foot">
                    <b-col sm="4">
                      <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">{{$t('button.close')}}</b-button>
                    </b-col>
                    <b-col sm="4">
                      <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('button.apply')}}</b-button>
                    </b-col>
                    <b-col  sm="4">
                      <b-button  @click="del()" block class="modal-footer__button modal-footer__button-del">{{$t('button.delete')}}</b-button>
                    </b-col>

              </b-row>
          </template>
        </b-modal>
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;
import BlockMonRealTime from '@/components/chart/BlockMonRealTime' ;
export default {
    name: 'DlgPressureInfo',
    props: {
    },
    watch:{
         vchart1() {
      
          var url =  this.bar_url 
          var surl =  url.split('&retType=')
          this.bar_url  = surl[0]+'&retType='+this.vchart1+"#3"        
      },

    },
    data() {
        return {
            properties: null,
            properties_bak: null,
            fft_url: '',      
             map_height: 380,
             img1:'',
             img2:'',
             img3:'',
             img_list: [],
             vchart1 : 'bar',
             bar_url : '',
             recount: 0,
             loading : false,
             ele_data : 0,
             group_name : [{value:'', text :""}], 
        }
    },

    mounted () {

    },
    methods: {
        fn_press_han(event)
        {
           var objTarget = event.srcElement || event.target;
       // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');
           // alert('Please enter only numbers');
           // event.preventDefault();          
          }    
        var _pattern0 = /^\d*[.]\d*$/; // 현재 value값에 소수점(.) 이 있으면 . 입력불가
        if (_pattern0.test(value)) {
            if (event.keyCode == 46) {
                return false;
            }
        }


        // 소수점 둘째자리까지만 입력가능

        var _pattern2 = /^\d*[.]\d{2}$/; // 현재 value값이 소수점 둘째짜리 숫자이면 더이상 입력 불가

        if (_pattern2.test(value)) {

            alert(this.$t('pressure.alt_elev2'));

            return false;

        }      
      },
      isNumberKey(evt) {

        var charCode =  evt.keyCode;

        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))

            return false;

        // Textbox value    

        var _value = evt.srcElement.value;    

        var _pattern0 = /^\d*[.]\d*$/; // 현재 value값에 소수점(.) 이 있으면 . 입력불가
        if (_pattern0.test(_value)) {
            if (charCode == 46) {
                return false;
            }
        }
        // 1000 이하의 숫자만 입력가능
        var _pattern1 = /^\d{3}$/; // 현재 value값이 3자리 숫자이면 . 만 입력가능
        if (_pattern1.test(_value)) {
            if (charCode != 46) {
                alert(this.$t('pressure.alt_elev1'));
                return false;
            }
        }

        // 소수점 둘째자리까지만 입력가능

        var _pattern2 = /^\d*[.]\d{2}$/; // 현재 value값이 소수점 둘째짜리 숫자이면 더이상 입력 불가

        if (_pattern2.test(_value)) {

            alert(this.$t('pressure.alt_elev2'));

            return false;

        }  



        return true;

    },
         update_elevation(){
            this.loading = true     
            this.$http.get(this.URL+'api/set_elevation?idx='+this.properties['id']).then((res) => {
                 if(res.data.check ===1){ 
                    this.loading = false
                    return;
                 }
                 this.ele_data = res.data.msg ;
                 this.properties['elevation'] = this.ele_data
                 this.loading = false 
            });
            setTimeout(()=>{
                this.loading = false      
             }, 3000)    
        },
        show(idx) {
          this.properties = {} ;
          //group_name 
          /*
          this.group_name = [];   
          this.$http.get(this.URL+'api/waterpressure/groups?customer_id='+sessionStorage.select_customer_id).then((rows) => {
            if((!rows)||(rows.data.groups<=0)) return;
            
            for(let i=0; i<rows.data.groups.length; i++) {
              if(i=== 0){
                  this.group_name.push({value:'', text :''}) ; 
              }
              this.group_name.push({value:rows.data.groups[i].groupId, text :rows.data.groups[i].groupName}) ; 
              
              
            }
         
          });     
          */        
            // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
              this.img_list =[];
            // this.$http.get(this.URL+'api/get_press_info?idx='+idx).then((res) => {
              this.$http.get(this.URL+'api/API21/flow/LOGGER_DETAIL/'+idx).then((res) => {
                 
                if(res.data.length<=0) return           

                this.properties = res.data.results[0][0]; 
               // this.ele_data = this.properties['elevation'] 
                if(sessionStorage.m_id === this.properties.worker )
                     this.myWorkItem = true
                if(res.data.results[0][0].image_list === null || res.data.results[0][0].image_list === undefined ){

                  this.img_list.push(require('@/assets/noimage1.png')) 
                 // this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
                  this.img_list.push(require('@/assets/noimage1.png'));
                  this.img_list.push(require('@/assets/noimage1.png'));
                  
                }else{
                  var img = res.data.results[0][0].image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
              
               
                }

                //
               // for(let i=0; i<res.data.list[0].data.length; i++) {
               //   res.data.list[0].data[i].date = parseTime(res.data.list[0].data[i].date)
              //  }
               // this.draw_pressure_chart(res.data.list[0].data) ;
                this.properties_bak = Object.assign({}, this.properties)
                //watch bar_url 을 위하여 recount 추가
                this.bar_url = this.URL+'api/'+res.data.results[0][0].flw_file_path+'&sensor='+ res.data.results[0][0].sensor+'&re='+(++this.recount)+'&retType='+this.vchart1+"#3"     

             })
            this.$refs.modal.show();
    
           // this.fft_url = this.URL+"api/get_fft/"+idx;
           },
         del(){
            if(sessionStorage === null || typeof sessionStorage === undefined){
              alert(this.$t('leak_detail.alt_no_update'))
              return
        //   }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 1  && sessionStorage.m_id !== this.properties.worker){
              alert(this.$t('water_leak.alert_no_permission'))
              return;
            }

           var con_test1 = confirm(this.$t('water_leak.left_menu.confirm_msg_delete'));
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           
           if(con_test1){
              this.$http.get(this.URL+'api/delete_ex?f_idx_ex='+this.properties['id']).then((res) => {  
                 console.log(res.data);
                 this.$emit('leaktable_refresh') ;
                 this.$refs.modal.hide();
              });

           }
        },
        apply() {
        //  if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === undefined){
              alert(this.$t('leak_detail.alt_no_update'))
              return
        //   }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 2 && sessionStorage.m_id !== this.properties.worker){
              alert(this.$t('water_leak.alert_no_permission'))
              return;
            }
            let params = {} ;
            let params1 = {} ;
            let formData = new FormData();
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
              if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue

              if(keys[i]==="elevation")
                params1[keys[i]] = this.properties[keys[i]]
              else{ 
                if(keys[i]==="group_id" && this.properties[keys[i]] ===''){
                  params[keys[i]] = null
                }else{
                  params[keys[i]] = this.properties[keys[i]] 
                  formData.append(keys[i],  this.properties[keys[i]]  );
                 
                }
              }
            }
            if(Object.keys(params).length < 1 && Object.keys(params1).length < 1) return;
            /*
            if(Object.keys(params).length>0){
              this.$http.get(this.URL+'api/update_press_info?idx='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                  console.log(res.data);
              })   
            }
            if(Object.keys(params1).length>0){
              this.$http.get(this.URL+'api/update_press_elevation?idx='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params1))).then((res) => {
                  console.log(res.data);
              }) 
            }
            */
            
            /*
            for( var i = 0; i < this.files.length; i++ ){
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }
            */
          //  this.$http.post(this.URL+'api/upload_shape?layer='+this.selected_layer+'&encoding='+this.encoding+'&tag_name='+this.tag_name, formData, {
              formData.append('idx',  this.properties['id']  );
             this.$http.post(this.URL+'api/API21/set_flow_logger', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                console.log('SUCCESS!!');
                this.$refs.modal.hide();
            })
            .catch(() => {
                console.log('FAILURE!!');
                this.$refs.modal.hide();
            });

            //this.$emit('leaktable_refresh') ;
            this.$refs.modal.hide();     
        }       
    },
    components: {
         BlockMonRealTime:BlockMonRealTime,
          //pressChartData1:pressChartData1,
    }
}
</script>

<style  lang="scss">

.pressure_chart  .highcharts-container {
  width:440px;
}
.pressure_chart svg.highcharts-root {
    max-width: 440px;
}
.facility__label-radio {
  font-size: 12px;
  color: #616161;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}
.tab-content {
    max-height: 720px;    
    overflow-y: auto;    
    overflow-x: hidden; 
}
.modal-info {
  .modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    margin: 70px auto 0;
    background: #F2F2F2;
    width: 350px;
    height: calc(100% - 70px);
    .modal-header {
      padding-bottom: 22px;
      .modal-title {
        font-size: 18px;
        // margin-top: 12px;
      }
    }
    .modal-info-content {
      border: 0;
      border-radius: 0;
 
      &.reversal {
        background: #F2F2F2;
        .nav-tabs {
          background: #F2F2F2;
        }
        .tab-content {
          background: #fff;
          &.is--scroll {
            height: 73vh;
            overflow-y: auto;
          }
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
          // 2022 new
          // background: #fff;
          border-color: 1px solid #707070;
        }
        .facility__label {
          color: #fff;
          font-size: 12px;
          display: inline-block;
          letter-spacing: 0.5px;
          //width: 35%;
        }
        .facility__input {
          //width: 60%;
          height: 32px;
          font-size: 12px;
          border: 1px solid #707070;
          background: #0A192F;
          box-sizing: border-box;
          border-radius: 0;
          margin-top: 0;
          margin-bottom: 14px;
          &.height--auto {
            height: auto;
          }
        }
        .modal-footer {
          background: #fff;
          padding-bottom: 18px;
          padding-top: 3px;
        }
        .modal-footer-row {
          width: 100%;
          margin: 0;
          padding-bottom: 0;
        }
        .btn.modal-footer__button {
          height: 52px;
          font-size: 14px;
          border: 0;
          &.modal-footer__button-ok {
            // 2022 new
            // background: #28B7CB;
            background: #28AF81;
          }
          &.modal-footer__button-cancel {
            background: #9D9D9D;
          }
        }
        .photo-card {
          border-radius: 0;
          border: 0;
          background: none;
          .img-thumbnail {
            margin-bottom: 10px;
          }
          .card-header {
            height: 150px;
            background: #F2F2F2;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .card-header__text {
              font-size: 16px;
              color: rgba(3,3,3,0.4);
            }
          }
        }
      }
    }
  }
}
</style>
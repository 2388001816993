<template>
  <div>
    <!-- top-bar -->
   
    <!-- tom-bar-end -->
    
    <div class="content-area row justify-content-around" :key="listKey1">
      <div class="left leak-detail dashboard" >
        <!--
        <div class="check_list">
          <input type="checkbox" id="chk_01">
          <label for="chk_01">Water Leak</label>
          <input type="checkbox" id="chk_02">
          <label for="chk_02">Inner Water Leak</label>
          <input type="checkbox" id="chk_03">
          <label for="chk_03">None</label>
          <input type="checkbox" id="chk_04">
          <label for="chk_04">Apply to Map</label>
        </div>
        -->
    
   
        <div class="list_box list_box2 dashboard" >

         <b-row >  
          <b-col sm="6" >
            <b-row style="padding: 0 0 0 30px;"><span class="dashboard title">{{gis_points}}</span></b-row>            
             <PieHChart  v-bind:chartData="gis_point_data"  v-bind:series_name="gis_points"  v-bind:size = "gis_pie_class"></PieHChart></b-col>
          <b-col sm="6" >
             <b-row><span class="dashboard title">{{pipe_length}}</span></b-row>           
             <PieHChart  v-bind:chartData="gis_pipe_data"  v-bind:series_name="gis_points"  v-bind:size = "gis_pie_class"></PieHChart></b-col>
        </b-row>
         <b-row><span class="dashboard title">Length of Pipe Material (km)</span></b-row>
         <b-row>  
              <BarChart_gis  v-bind:chartData="gis_pipe_material"  v-bind:series_name="gis_material"  v-bind:size = "gis_bar_class"></BarChart_gis>            
         </b-row>          
         <!--b-row>
           <span class="dashboard title">Number of water meter by DMA</span>
           <DmaChart  v-bind:chartData="dma_data"  v-bind:series_name="gis_points"></DmaChart>   
        </b-row-->     
        </div>
      </div>
      <div class="right dashboard">
        <b-row style=" padding: 10px 20px 0px 20px ; " >
            
          <b-col sm="6">
            <b-row>
              <span class="dashboard title">Leak Sound Data</span>
            </b-row> 
            <b-row>
             
              <b-col sm="6">
             <Pie2DHChart  v-bind:chartData="leak_data"  v-bind:series_name="gis_points" v-bind:size = "leak_pie_class"></Pie2DHChart>
             </b-col>
             <b-col sm="6"> 
             <Pie2DHChart  v-bind:chartData="leak_gis_layer_data"  v-bind:series_name="gis_points"  v-bind:size = "leak_pie_class"></Pie2DHChart>
             </b-col>
            </b-row>
          </b-col>
          
          <b-col sm="6">
               <b-row><span class="dashboard title">Leak Sound % by Frequency</span></b-row>
              <b-row><BarChart_gis  v-bind:chartData="leak_frequency"  v-bind:series_name="leak_frequency_x"
               v-bind:y_title="leak_y_title" ></BarChart_gis>   
              </b-row>
          </b-col>

        </b-row>
        <b-row  style=" padding: 10px 20px 0px 20px ; ">
             <b-col sm="6">
              <b-row> <span class="dashboard title">Monthly count of leak sound</span></b-row>
              <b-row>
              <BubbleHChart  v-bind:chartData="leak_month_data"   ></BubbleHChart>   
              </b-row>
            </b-col>
            <b-col sm="6">
              <b-row> <span class="dashboard title"> % by Pressure</span></b-row>
              <b-row>
              <BarChart_gis  v-bind:chartData="press_value"  v-bind:series_name="press_value_x" v-bind:y_title="press_y_title"></BarChart_gis>   
              </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  
  </div>
</template>

<script>

//import FFTChart1 from '@/components/FFTChart1';

import PieHChart from '@/components/chart/PieHChart'
import Pie2DHChart from '@/components/chart/Pie2DHChart'
import BarChart_gis from '@/components/chart/BarChart_gis'
//import DmaChart from '@/components/DmaChart'
import BubbleHChart from '@/components/chart/BubbleHChart'
//import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
/*
' CASE '+
' WHEN layer_type=0 then "Pipe" '+
' WHEN layer_type=1 then "Valve" '+
' WHEN layer_type=2 then "Water Meter" '+
' WHEN layer_type=3 then "Pump" '+
' WHEN layer_type=4 then "Hydrant" '+
' WHEN layer_type=7 then "Block" '+
' WHEN layer_type=8 then "Water Treatment Plant" '+
' WHEN layer_type=9 then "Pumping Station" '+
' WHEN layer_type=10 then "Water Basin" '+
' WHEN layer_type=11 then "Chamber" '+
' WHEN layer_type=12 then "Gauge" '+
' WHEN layer_type=13 then "Other" '+
' END AS type_, '+
*/

export default {
  name: 'Dashboard',
  
  props: {
    // url
  },
  watch: {
  
  },
  data() {
    return {
      gis_pie_class : "gisPieChart",
      leak_pie_class : "leakPieChart",
      gis_bar_class : "gisBarChart",
      gis_type : true,
      leak_type : false,
      listKey: 0,
      listKey1 : 0,
      contents_height: 800,  
      table_height: 640,
      gis_points : "Nember of Water meter & valve",
      pipe_length : "Length(km) of pipes",   
      value:'',
      leak_data : [],
      leak_gis_layer_data:[],
      leak_month_data : [],
      leak_frequency : [],
      leak_frequency_x : [],
      press_value : [],
      press_value_x : [],
      press_y_title : 'Pressure(bar)',
      leak_y_title : 'Strength',
      gis_pipe_material : [],
      gis_pipe_data : [],
      gis_material : [],
      dma_data : [],
      gis_point_data: [
        {
          'name': '',
          'device_sn': '',
          'comm_sense': '5'
        },
     
      ],
      
    }
  },
  methods: {
  
    go_home() {

      this.$router.push({path: '/leak-monitoring-detail-map', query: { mea_date: this.mea_last_date, w_type: '102' }})
    },
  
    goRouter(path) {
      let query = {}
      //if(path==='/') query['customer_id'] = this.$route.query.id
      /*
      if('/leak-analysis'===path) {
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<=0) return;
        let lidx = []
        for(let i=0; i<rows.length; i++) lidx.push(rows[i].idx)
        query['lidx'] = lidx
        query['widx'] = this.$route.query.widx
      }
      */
      this.$router.push({path: path, query: query,})
    },
    gis_chart(customer_id){
       this.gis_point_data = []
       this.gis_pipe_data = []
       this.gis_pipe_material = []
       this.gis_material = []
       this.dma_data = []
       this.$http.get(this.URL+'api/API21/dashboard/gis_points1/1?customer_id='+customer_id).then((res) => {
          if(res.data.results[0].length <1) {
            this.$http.get(this.URL+'api/API21/dashboard/GIS_INSERT/1?customer_id='+customer_id).then((res1) => {
              if(res1.data.results[0].length <1) return;
              this.gis_point_data =[];
              this.gis_pipe_data =[];
              for(let i=0; i<res1.data.results[0].length; i++) {
                        //this.gis_point_data.push(['Valve', res.data.results[0][i].v_cnt]) ;
                        //this.gis_point_data.push(['Water Meter', res.data.results[0][i].w_cnt]) ;
                        this.gis_point_data.push({name:'Valve', y: res1.data.results[0][i].valve}) ;
                        this.gis_point_data.push({name:'Water Meter',y: res1.data.results[0][i].water_meter}) ;   
                        this.gis_pipe_data.push({name: 'Supply', y: res1.data.results[0][i].supply_pipe_length}) ;             
                        this.gis_pipe_data.push({name: 'Service', y: res1.data.results[0][i].service_pipe_length}) ;             
              } 
                
            });
          }else{
        
            for(let i=0; i<res.data.results[0].length; i++) {
              //this.gis_point_data.push(['Valve', res.data.results[0][i].v_cnt]) ;
              //this.gis_point_data.push(['Water Meter', res.data.results[0][i].w_cnt]) ;
              this.gis_point_data.push({name:'Valve', y: res.data.results[0][i].valve}) ;
              this.gis_point_data.push({name:'Water Meter',y: res.data.results[0][i].water_meter}) ;   
              this.gis_pipe_data.push({name: 'Supply', y: res.data.results[0][i].supply_pipe_length}) ;             
              this.gis_pipe_data.push({name: 'Service', y: res.data.results[0][i].service_pipe_length}) ;             
            }  
              ++this.listKey;
          }   
       }) ;
       
       this.$http.get(this.URL+'api/API21/dashboard/gis_points/1?customer_id='+customer_id).then((res) => {
          if(res.data.results[0].length <1) return
          this.gis_point_data =[];
          for(let i=0; i<res.data.results[0].length; i++) {
             this.gis_point_data.push(['Valve', res.data.results[0][i].v_cnt]) ;
             this.gis_point_data.push(['Water Meter', res.data.results[0][i].w_cnt]) ;
          }     
       }) ;

       //this.$http.get(this.URL+'api/API21/dashboard/pipe_type/25?customer_id='+customer_id).then((res) => {
        this.$http.get(this.URL+'api/API21/dashboard/pipe_type/1?customer_id='+customer_id).then((res) => { 
          if(res.data.results[0].length <1) return
          this.gis_pipe_data = [];
          for(let i=0; i<res.data.results[0].length; i++) {
             
             this.gis_pipe_data.push({name: res.data.results[0][i].type, y: res.data.results[0][i].length}) ;           
          }
      
           ++this.listKey1;
       }) ;
      this.$http.get(this.URL+'api/API21/dashboard/pipe_material/1?customer_id='+customer_id).then((res) => {
          if(res.data.results[0].length <1) return
        
          for(let i=0; i<res.data.results[0].length; i++) {
             //this.gis_point_data.push(['Valve', res.data.results[0][i].v_cnt]) ;
             //this.gis_point_data.push(['Water Meter', res.data.results[0][i].w_cnt]) ;
             //this.gis_pipe_material.push({name: res.data.results[0][i].material, data: [res.data.results[0][i].length]}) ;
             this.gis_pipe_material.push(res.data.results[0][i].length) ;
             this.gis_material.push( res.data.results[0][i].material)
          }
           ++this.listKey1;
     
       }) ;
       this.$http.get(this.URL+'api/API21/dashboard/dma_m/1?customer_id='+customer_id).then((res1) => {
          if(res1.data.results[0].length <1) return
          //this.dma_data.push({name: "Other", id:'A', })
          this.dma_data.push({name: "Other", id:'A', })
          for(let j=0; j<res1.data.results[0].length; j++) {
            this.dma_data.push({id:res1.data.results[0][j].mb_id+"" ,
                         name: res1.data.results[0][j].f_name === null ? "중블럭"+res1.data.results[0][j].mb_id :res1.data.results[0][j].f_name+"" })
          }
          this.$http.get(this.URL+'api/API21/dashboard/dma/1?customer_id='+customer_id).then((res) => {
              if(res.data.results[0].length <1) return
              //this.dma_data.push({name: "Other", id:'A', })
              for(let i=0; i<res.data.results[0].length; i++) {
                //this.gis_point_data.push(['Valve', res.data.results[0][i].v_cnt]) ;
                //this.gis_point_data.push(['Water Meter', res.data.results[0][i].w_cnt]) ;
                //this.gis_pipe_material.push({name: res.data.results[0][i].material, data: [res.data.results[0][i].length]}) ;
                if(res.data.results[0][i].m_block_id === null){
                  this.dma_data.push({name: res.data.results[0][i].name, parent:'A', value: res.data.results[0][i].water_meter})
                }else{
                  this.dma_data.push({name: res.data.results[0][i].name, parent:res.data.results[0][i].m_block_id+"", value: res.data.results[0][i].water_meter})
                }
              }
              //++this.listKey;
           }) ;
       
       }) ;
    },
    leak_chart(customer_id){
       this.leak_data = []
       this.leak_gis_layer_data = []
       this.leak_month_data = []
       this.leak_frequency = []
       this.leak_frequency_x = []
       this.press_value = []
       this.press_value_x = []
       this.$http.get(this.URL+'api/API21/dashboard/leak/1?customer_id='+customer_id).then((res) => {
          if(res.data.results[0].length <1) return
        
          for(let i=0; i<res.data.results[0].length; i++) {
             //this.gis_point_data.push(['Valve', res.data.results[0][i].v_cnt]) ;
             //this.gis_point_data.push(['Water Meter', res.data.results[0][i].w_cnt]) ;
             this.leak_data.push({name:res.data.results[0][i].leak, y: res.data.results[0][i].cnt}) ;
            // this.leak_data.push({name:'Water Meter',y: res.data.results[0][i].w_cnt}) ;             
          }
      
     
       }) ;
       //this.$http.get(this.URL+'api/API21/dashboard/pipe_type/25?customer_id='+customer_id).then((res) => {
        this.$http.get(this.URL+'api/API21/dashboard/leak_gis_layer/1?customer_id='+customer_id).then((res) => { 
          if(res.data.results[0].length <1) return        
          for(let i=0; i<res.data.results[0].length; i++) {             
             this.leak_gis_layer_data.push({name: res.data.results[0][i].gis_layer_id, y: res.data.results[0][i].cnt }) ;           
          }     
       }) ;
      this.$http.get(this.URL+'api/API21/dashboard/leak_frequency/1?customer_id='+customer_id).then((res) => {
          if(res.data.results[0].length <1) return
        
          for(let i=0; i<res.data.results[0].length; i++) {
             this.leak_frequency.push(res.data.results[0][i].strength) ;   
             this.leak_frequency_x.push(res.data.results[0][i].frequency) ;        
          }         
     
       }) ;
       this.$http.get(this.URL+'api/API21/dashboard/leak_month/1?customer_id='+customer_id).then((res) => {
          if(res.data.results[0].length <1) return
          //this.dma_data.push({name: "Other", id:'A', })
         
          for(let i=0; i<res.data.results[0].length; i++) {
            this.leak_month_data.push({x:Number(res.data.results[0][i].year_m), y:1, z:res.data.results[0][i].cnt, name: res.data.results[0][i].year_m}) ; 
            //this.leak_month_data.push({x:i, y:1, z:res.data.results[0][i].cnt, name: res.data.results[0][i].year_m}) ;      
          }
       }) ;
       this.$http.get(this.URL+'api/API21/dashboard/pressure/1?customer_id='+customer_id).then((res) => {
          if(res.data.results[0].length <1) return
          //this.dma_data.push({name: "Other", id:'A', })
         
          for(let i=0; i<res.data.results[0].length; i++) {
            this.press_value.push(res.data.results[0][i].cnt) ;  
            this.press_value_x.push(res.data.results[0][i].press_data)   
          }
       }) ;       
    }
  },
  created () {
  
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.window_resiz)
  },
  mounted () {
    window.addEventListener('resize', this.window_resiz)    
 //left메뉴 데이터를 뿌려준다.
       this.box = [] ;
      
       var customer_id = sessionStorage.select_customer_id ; 
       if(sessionStorage.select_customer_id === "27"){
          customer_id = 6
       }
   
    this.gis_chart(customer_id)  
    this.leak_chart(customer_id)  
  
    setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
              document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
           }
         }
     }, 500)

  },
  components: {
  //  FFTChart1: FFTChart1,
   
    PieHChart : PieHChart,
    Pie2DHChart : Pie2DHChart,
    BarChart_gis : BarChart_gis,
  //  DmaChart : DmaChart,
    BubbleHChart : BubbleHChart,
  }
}

</script>

<style lang="scss" >
.dashboard.title{
  font-size: 13pt;
  width:100%;
  text-align: center;
}
/*
    #waveform{
          background: rgba(0,0,0,0.8);
    }
*/
#waveform .audio {
  all: none;
  width: 300px;
  position :absolute;
  z-index:1;
}

.inner-bg {
  float : left;
  /* 공통 속성값 지정 */

  display: inline-block;
  /* 인라인요소를 인라인블록 요소로 */
  height: 30px;
  /* 이미지 공간을 확보하기 위해 높이값 지정 */

  background: #c0c0c0;
  padding-left:10px;
  padding-right: 10px;

}

.content-area {
  padding: 0 30px;
}
.leak-detail-content {
  /*background: #fff;*/
  padding: 10px 30px 0;
}
.leak-detail-content-map {
  padding: 0;
}
.left .list_box.list_box2.dashboard {
  min-height: 785px;
  padding-top:15px ;
  background: transparent;
  height: calc(100vh - 50px - 40px  );
}
.table-button-group {
  margin-bottom: 15px;
}
.table-button-group .btn-lk-middle {
  width: 100%;
}
.left.leak-detail {
  height: auto;
}
table.type01 th {
  padding: 12px 0;
  background-color: aqua;
}
table.type01 td span {
  padding: 2px 0;
}
.btn-default {
  color: #fff;
}
.btn-default :hover{
  color: #000;
}
i {
    font-style: normal;
}
/*
table.vgt-table.vgt-fixed-header {
    position: relative !important;
}

table.vgt-table.vgt-fixed-header th:last-child {
    /* Offset for the scrollbar, you may have to adjust this */
  /*  width: calc(100% + 17px) !important;
}

table.vgt-table:not(.vgt-fixed-header) > thead {
    /* Collapse the first row which is the un-fixed table header */
   // visibility: collapse !important;
//}

.vgt-table{ 
  overflow-y: auto; height: 100px; 
}
.vgt-table th { 
  position: sticky; top: 0; 
}
.green {
  background-color: green;
}

.red {
  background-color: red;
}
.left.leak-detail.dashboard{
  width: 33%;
  margin-top:2px;
 /* background: #fff;*/
}
.right.dashboard{
   width: 66%;
   /*background: #fff;*/
   margin-top: 2px;
}
.content-area.row.justify-content-around{
  padding: 0 10px;
}
</style>

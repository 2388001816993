<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <!--h4 class="content-title">{{$t('leak_monitoring.map_title')}}</h4-->
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                
                    <ol class="breadcrumb with--map"> 
<!--                        <li class="breadcrumb-title">Map Frequency Comparison</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/water-leak-logger')" href="#">{{$t('menu.leak_logger')}}</a></li>                       
                        <li class="breadcrumb-item active">{{$t('leak_monitoring.map_title')}}</li>
                    </ol>
                
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div>
             <b-row style="margin: 0;">
                <b-col :cols="msize" style="padding: 0 10px 0 0;">
                    <div class="corr"  >
                      <label for="radio1_01" style="font-size:12pt;">Correlation</label>
                      <label for="radio1_01">1Sound :</label> <input type="text" id="corr_sound1" name="radio1_01"  v-model="corr_sound1" disabled>
                      <label for="radio1_01">2Sound :</label> <input type="text" id="corr_sound2" name="radio1_01"  v-model="corr_sound2" disabled>
                      <span style="postition:relative;"> <button variant="outline-danger" @click="draw_line" style="border:0;margin-left:10px;background: #cccccc;">
                        <!-- 2022 new -->
                        <!-- <img src="../assets/images/Common/table/map_icon.png" alt="map" class="infoimg" /> -->
                        <img src="@/assets/images/new/ic_table_map.svg" alt="map" class="infoimg_logeer" />
                        Draw Pipe</button>
                      <button variant="outline-danger" @click="draw_modify" style="border:0;margin-left:10px;background: #cccccc;">
                        <!-- 2022 new -->
                        <!-- <img src="../assets/images/Common/table/map_icon.png" alt="map" class="infoimg" /> -->
                        <img src="@/assets/images/new/ic_table_map.svg" alt="map" class="infoimg_logeer" />
                        Modify</button>
                      <button variant="outline-danger" @click="correction_call" style="border:1px;margin: 0 10px 0 10px; background: #cccccc;">
                        <img src="@/assets/images/Common/icon/icon_plus.png" alt="map" class="infoimg_logeer" /> 
                        Calculation</button>
                      <button variant="outline-danger" @click="correction_save" style="border:0;background: #cccccc;"><img src="@/assets/images/Common/icon/icon_download.png" alt="map" class="infoimg" /> Save</button>
                      </span>
                   </div>
                    <div class="search_div m2_map">

                        <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="value"  @keyup.enter ="Enter_Check"/></span>
                        <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="go_search">{{$t('button.search')}}</button></span>
                       <span style="float:right;width:250px;padding: 0 10px 0 10px;">
                           <span >
                                <b-input size="sm" type="date" placeholder="datetime" v-model="flow_date_min1"  style="width: 120px;" @change="change_flow_date_min1($event)"  />
                                <span style="position:absolute;top:0px;right:-130px;">
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-30*48*10)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-30*48)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(30*48)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(30*48*10)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>
                                    <button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="map_draw_correl">Correlations</button>
                                </span>
                           </span>  
                           
                            <span > 
                                <b-input size="sm" type="time" placeholder="time" v-model="flow_date_min2" @change="change_flow_date_min2($event)" style="margin-top:2px;width: 120px;" />
                                <span style="position:absolute;top:34px;right:-50px;">
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-10*6)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-10)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(10)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(10*6)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>
                                </span>
                            </span>
                       </span>
                    
                    </div>
                    <div style=" margin : 0px; padding-right: -5px; " >
                    <div style="overflow-y: scroll;" :style="{height: contents_height+'px'}">

                     <Map
                         ref="map"
                         :widx="$route.query.widx"
                         w_type="104"
                         :visible_gisload_button="true"
                         :visible_size_side_view_button="true"
                         :leak_l ="false"
                         :press_icon1 ="true"
                        @set_map_gisload="set_map_gisload"
                        @set_info="set_info"
                        @toggle_visible_all_layer = "toggle_visible_all_layer"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @toggle_visible_leak_type = "toggle_visible_leak_type"
                        @size_view="size_view"
                        @onLeakMapClick="onLeakMapClick()"
                        @on_correlation_info_map="on_correlation_info_map"
                     />
                      </div>
                     </div>
                 </b-col>
                 <b-col  v-if="v_chart" :cols="tsize" style=" padding: 0px 2px 0 0; ">
                 <b-tabs content-class="logger_detail"     style="margin-top: -50px;">
                 <b-tab title="Water Leak Sound" style="padding:0;max-height: calc(95vh + 20px);background:#000;" active> 
                 
                   <b-row style=" padding-left: 0px; margin: 0px 10px 0 -5px;background: #172a46; ">
                       <b-col style="padding-top: 5px; padding-bottom: 5px;">

                           <div  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                           <div style=" position:absolute; top : 5px; left:5px;z-index:102; ">
                              <!--button variant="outline-danger" @click="on_leakage_info(lidx1)" style="border:0;"><img src="../assets/images/Common/table/info_icon.png" alt="map" class="infoimg" /></button--> 
                               <img src="@/assets/images/new/ic_list_info.svg" alt="map"  style="width: 80%; margin: 10px 0;" @click="on_leakage_info(lidx1)" />
                              <div> <img src="@/assets/images/new/ic_table_map.svg" alt="map"  style="width: 80%; margin: 10px 0;" @click="on_goto_map_click1(p_idx1)" /> </div>  
                           </div>
         
                            <div style=" position: relative;left:32%;width:53%;text-align:center;">
                                <div style="float:left;">
                                
                                    <span style="float:left;">  </span>
                                    <span style="float: right;padding-top:15px;">
                                    <b-input v-model="szoom1"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                    type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                    v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                                    
                                    </span> 
                                </div>
                                <span  style="float:left;margin-left: -40px;" >                            
                                    <audio id="slice_audio_id1" controls preload="auto" :src="slice_url1" style="transform:scale(0.7);height: 50px;">
                                    </audio>
                                </span>
                          </div>     
           
                        <div class="status leak" style="right:-20px;">             
                                        
                            <input type="radio" v-model="vchart1" value="0" id="fixed1"> <label for="fixed1">{{$t('leakage_info.fixed')}}</label>
                            <input type="radio" v-model="vchart1" value="1" id="proportional1"><label for="proportional1">{{$t('leakage_info.proportional')}}</label>
                        </div>
                        <div style='height:50px'>
                            <div id="hidden-container1" >
                              <div id="waveform_1" ref="waveform_1" style="float : left; margin-left: 35px; width:95%;"/>
                              <div id="wave-timeline1" ref="wave-timeline1" style="float : left; margin-left: 35px; width:95%;"/>
                            </div>
                        </div>
                         <div :style="{height: fftchart_height+ 'px'}" style='margin-left:-20px;'>
                            <pressChartData_fft :url="fft_url1" ref="fftchart1" :height="fftchart_height-30" style="width:100%; overflow: auto;" @onZoomClickEvent="onZoomClickEvent(1)"  />
                         </div>   
                        </div> 
                       </b-col>
                   </b-row>

                   <b-row style=" padding-left: 0px; margin: 5px 10px 0 -5px;background: #172a46;" >
                       <b-col style="padding-top: 10px; padding-bottom: 5px;">
 
                        <div  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                           <div style=" position:absolute; top : 5px; left:5px;z-index:102; "> 
                                <!--button variant="outline-danger" @click="on_leakage_info(lidx2)" style="border:0;"><img src="../assets/images/Common/table/info_icon.png" alt="map" class="infoimg" /></button-->
                                    <img src="@/assets/images/new/ic_list_info.svg" alt="map"  style="width: 80%; margin: 10px 0;" @click="on_leakage_info(lidx2)" />
                                    <div> <img src="@/assets/images/new/ic_table_map.svg" alt="map"  style="width: 80%; margin: 10px 0;" @click="on_goto_map_click1(p_idx2)" /> </div>  
                           </div>
                        
                           <div style=" position: relative;left:32%;width:53%;text-align:center;">
                            <div style="float:left;">
                            
                                <span style="float:left;">  </span>
                                <span style="float: right;padding-top:15px;">
                                <b-input v-model="szoom2"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                                
                                </span> 
                            </div>
                            <span  style="float:left;margin-left: -40px;" >                            
                                <audio id="slice_audio_id2" controls preload="auto" :src="slice_url2" style="transform:scale(0.7);height: 50px;">
                                </audio>
                            </span>
                          </div>     
            
                        <div class="status leak" style="right:-20px;">             
                                        
                            <input type="radio" v-model="vchart2" value="0" id="fixed2"> <label for="fixed2">{{$t('leakage_info.fixed')}}</label>
                            <input type="radio" v-model="vchart2" value="1" id="proportional2"><label for="proportional2">{{$t('leakage_info.proportional')}}</label>
                        </div>
                        <div style='height:50px'>
                            <div id="hidden-container2" >
                            <div id="waveform_2" ref="waveform_2" style="float : left; margin-left: 35px; width:95%;"/>
                            <div id="wave-timeline2" ref="wave-timeline2" style="float : left; margin-left: 35px; width:95%;"/>
                        </div>
                        </div>
                         <div :style="{height: fftchart_height+ 'px'}" style='margin-left:-20px;'>
                            <pressChartData_fft :url="fft_url2" ref="fftchart2" :height="fftchart_height-30" style="width:100%; overflow: auto; 
                              " @onZoomClickEvent="onZoomClickEvent(2)"  />
                         </div>   
                       </div> 
                   </b-col>
                   </b-row>
                   <b-row style="  padding-left: 0px; margin: 5px 10px 0 -5px;background: #172a46;">
                       <b-col style="padding-top: 10px; padding-bottom: 5px;">
  
                           <div  style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                           <div style=" position:absolute; top : 5px; left: 5px;z-index:102; ">
                                <!--button  @click="on_leakage_info(lidx3)" style="border:0;"><img src="../assets/images/Common/table/info_icon.png" alt="map" class="infoimg"  @click="on_leakage_info(lidx3)" /></button--> 
                                    <img src="@/assets/images/new/ic_list_info.svg" alt="map"  style="width: 80%; margin: 10px 0;" @click="on_leakage_info(lidx1)" />
                                    <div> <img src="@/assets/images/new/ic_table_map.svg" alt="map"  style="width: 80%; margin: 10px 0;" @click="on_goto_map_click1(p_idx3)" /> </div>  
                           </div>
                           <!--
                             <audio controls preload="auto" :src="wav3" style="width:60%;transform:scale(0.7);"/>
                              <div style="position:relative; left:30px;width: 95%;" id="waveform_3" ref="waveform_3"/>
                             <FFTChart1_c :url="fft_url3" ref="fftchart3" :height="fftchart_height"/>
                             -->
                           <div style=" position: relative;left:32%;width:53%;text-align:center;">
                            <div style="float:left;">
                            
                                <span style="float:left;">  </span>
                                <span style="float: right;padding-top:15px;">
                                <b-input v-model="szoom3"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                                type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                                v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                                
                                </span> 
                            </div>
                            <span  style="float:left;margin-left: -40px;" >                            
                                <audio id="slice_audio_id3" controls preload="auto" :src="slice_url3" style="transform:scale(0.7);height: 50px;">
                                </audio>
                            </span>
                          </div>     
                        
                        <div class="status leak" style="right:-20px;">             
                                        
                            <input type="radio" v-model="vchart3" value="0" id="fixed3"> <label for="fixed3">{{$t('leakage_info.fixed')}}</label>
                            <input type="radio" v-model="vchart3" value="1" id="proportional3"><label for="proportional3">{{$t('leakage_info.proportional')}}</label>
                        </div>
                        <div style='height:50px'>
                            <div id="hidden-container3" >
                            <div id="waveform_3" ref="waveform_3" style="float : left; margin-left: 35px; width:95%;"/>
                            <div id="wave-timeline3" ref="wave-timeline3" style="float : left; margin-left: 35px; width:95%;"/>
                        </div>
                        </div>
                         <div :style="{height: fftchart_height+ 'px'}" style='margin-left:-20px;'>
                            <pressChartData_fft :url="fft_url3" ref="fftchart3" :height="fftchart_height-30" style="width:100%; overflow: auto; 
                              " @onZoomClickEvent="onZoomClickEvent(3)"  />
                         </div>   
                           </div>  

                       </b-col>
                   </b-row>
                 </b-tab>
                  <b-tab title="Correlations" style="max-height: calc(95vh + 20px);" >
                      <div class="list_box list_box2 correl">
                    <h5 class="card-box-title gis_check">Correlations</h5>
                        <div class="table-box" >   
                        <ServerSideTable ref="correlations_list"
                                        :columns="columns"
                                        :search="false"
                                        :sort="true"
                                        :select="false"
                                        :add_custom_column="true"
                                        :adv_search="adv_search"
                                        :max_height="table_height+'px'"
                                        @search_item_change="search_item_change"
                                        @on_info_click="on_correlation_info"
                                        @on_goto_map_click="on_goto_map_click"
                        />
                        </div>
                    </div>
                  </b-tab>
                  </b-tabs>
                 </b-col>
            </b-row>
         </div>
     <DlgLeakLoggerDetailInfo ref="logger_detail_info" />
     <DlgLeakCorrelationInfo ref="correlation_info" />
    </div>
</template>

<script>
import pressChartData_fft from '@/components/chart/pressChartData_fft';
import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';
import WaveSurfer from 'wavesurfer.js';
//import FFTChart1_c from '@/components/FFTChart1_c';
//import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import DlgLeakLoggerDetailInfo from '@/components/popup/DlgLeakLoggerDetailInfo_pop' 
import DlgLeakCorrelationInfo from '@/components/popup/DlgLeakCorrelationInfo' 
import ServerSideTable from '@/components/table/ServerSideTable';
export default {
    name: 'LeakLoggerMap',
    props: {
    },
    data() {
        return {
            v_chart : true,
            contents_height: 600,
            waveform_height: 42,
            fftchart_height: 160,
            table_height : 720,
            wavesurfer: null,
            layer_all_visible: true,
            chart_i : 0 ,
            fft_url1: '',
            fft_url2: '',
            fft_url3: '',
            lidx1 :'', //누수음 id
            lidx2 :'',
            lidx3 :'',
            p_idx1 :'', //지점 id
            p_idx2 :'',
            p_idx3 :'',
            corr_sound1 : '', //누수음1
            corr_sound2 : '', //누수음2
            strength1 : 0,
            strength2 : 0,
            wav1 : '',
            wav2 :'',
            wav3 : '' ,
            options: {
            },
            file: ' ' ,
            msize:'7',
            tsize:'5',
            value:'',
            sdata:0,
            slice_url1:'',
            szoom1:50,
            vchart1:1,
            slice_url2:'',
            szoom2:50,
            vchart2:1,
            slice_url3:'',
            szoom3:50,
            vchart3:1,
            roof:0,
            flow_date : '0001-01-01 00:00',
            flow_date_min1 : '0001-01-01'  ,
            flow_date_min2 :  "03:00",
            start_idx:0,
            max_idx:2, //(0,1,2 까지 볼수 있다.)
            coord_1 : [],
            coord_2 : [],
            coord_3 : [],
            cal_length : 0,
            columns: [
                {label: 'Map', field: 'go_map', sortable: false},
                {label: 'Info', field: 'info', sortable: false},
                {label: 'No', field: 'id'},
                {label: 'Work ID', field: 'water_work_id'},               
                {label: 'Mesasure Time', field: 'measure_datetime'},
                {label: 'Logger1', field: 'logger_id1',width: '5%',sortable: false},
             //   {label: 'Logger1', field: 'point_name1',width: '5%',sortable: false},
                {label: 'Sound1', field: 'logger_detail_id1'},
                //{label: 'Logger2', field: 'point_name2',width: '5%',sortable: false},
                {label: 'Logger2', field: 'logger_id2',width: '5%',sortable: false},
                {label: 'Sound2', field: 'logger_detail_id2'},
                {label: 'Pipe Length', field: 'line_length',sortable: false},
                {label: 'Leak length', field: 'leak_length',sortable: false},
                {label: 'date', field: 'update_datetime',},                            
               // {label: 'Status', field: 'f_status_', sortable: false}
            ],
            adv_search: [
                {
                field: 'leak_result',
                control: 'select',
                value: null,
                //'Leak','Leak Inside','Usage','Other'
                options: [
                    { value: null, text: 'Leak Result' },
                    { value: 'RT_LEAK', text: 'Leak' },
                    { value: 'NO_LEAK', text: 'No Leak' },
                    
                ]
                }, 
               {field: 'measure_datetime', control: 'date', value: null, value2: null, placeholder: 'From measure_datetime', placeholder2: 'To measure_datetime'},
            ],
          
        }
    },
    methods: {
        change_date_flow_min(a){
       
          let default_date = new Date(this.flow_date.toLocaleString());
          default_date.setMinutes(default_date.getMinutes()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date,1)
          this.flow_date_min1 = this.date_to_str(default_date,0)
          this.flow_date_min2 = this.date_to_str(default_date,2)
          this.chang_press_mapvalue_type();
          //this.get_time_series_flow()
        },
        change_flow_date_min1(e) {
            this.flow_date_min1 = e
            this.flow_date = this.flow_date_min1 + " "+ this.flow_date_min2
            this.chang_press_mapvalue_type();
        },
        change_flow_date_min2(e) {
            this.flow_date_min2 = e
            this.flow_date = this.flow_date_min1 + " "+ this.flow_date_min2
            this.chang_press_mapvalue_type();
        },
        map_draw_correl(){
            //this.$refs.map.leak_layer 에 로거값 추가함.
            // this.$refs.map.leak_value_layer 라인과 누수추정계산된 값 표시
           this.$refs.map.correlation_layer(); 
        },
        on_facility_info(row) {
            this.$refs.map.show_facility_info(row.id)
         },
        
         search_item_change() {

         },
         toggle_visible_all_layer(a) {

           if(a=== 1){
              // alert(a)
                this.$refs.map.leak_cal = 1;
           }else{
              //  alert(a)
                this.$refs.map.leak_cal = 0;
           }
           this.$refs.map.init_reload_leak_layer() ;

    
        },
        change_min_flow(a){
          let default_date = new Date(this.flow_date.toLocaleString());
          default_date.setMinutes(default_date.getMinutes()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
          this.chang_press_mapvalue_type();
          //this.get_time_series_flow()
        },
        change_date_flow(a){
          let default_date = new Date(this.flow_date.toLocaleString());
          default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
          this.chang_press_mapvalue_type();
          //this.get_time_series_flow()
        },
        chang_press_mapvalue_type() {

          this.$refs.map.time_30m = this.flow_date; //시간
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;
          //this.$refs.map.leak_layer 에 로거값 추가함.

        },  
        date_to_str1(format){
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 
          var hour = format.getHours();
           if(hour<10) hour = '0' + hour; 
          var min = format.getMinutes();
          if(min<10) min = '0' + min; 
     
          return year + "-" + month + "-" + date +" "+hour+":"+min;
        },        
        date_to_str(format,a){
          //a = 0 ,day, a=1 ㅡㅑㅜ
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 

          if(a > 0){
            var hour = format.getHours();
            if(hour<10) hour = '0' + hour; 
            var min = format.getMinutes();
            if(min<10) min = '0' + min; 
            if(a === 1)
                return year + "-" + month + "-" + date +" "+hour+":"+min;
            else
                return hour+":"+min;    
          }else{
            return year + "-" + month + "-" + date ;
          }
        },        
        toggle_visible_leak_type() {
            // 처음엔 leak type all 데이터를 가져와 지도에 보여준다.
            // All or LEAK 값을 가져와 지도에 보여준다.
            this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
            if(this.$refs.map.layer_leak_visible)
                this.$refs.map.leak_type = 'LEAK';
            else
                this.$refs.map.leak_type = null;
            
            //누수 레이어를 다시 그린다.
            this.$refs.map.init_reload_leak_layer() ;      
        },
        go_search(){
            this.$refs.map.go_search(this.value);
        },
        fn_press_han(event)
        {
            var objTarget = event.srcElement || event.target;
        // if(objTarget.type == 'text') {
            
            var value = objTarget.value;
            //alert(value);
            if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');
            // alert('Please enter only numbers');
            // event.preventDefault();          
            }        
        },  
        //20210427 
        onZoomClickEvent(a){
        var s_min = ""; //09-03 이렇게 들어온다
        var s_max = ""; //09-03 이렇게 들어온다
        if(a===1){
            s_min = this.$refs.fftchart1.select_min; //09-03 이렇게 들어온다
            s_max = this.$refs.fftchart1.select_max; //09-03 이렇게 들어온다
            this.slice_url1 = this.URL+'api/get_wave_slice?w_type=104&idx='+this.lidx1+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom1 ;
        }else if(a===2){
            s_min = this.$refs.fftchart2.select_min; //09-03 이렇게 들어온다
            s_max = this.$refs.fftchart2.select_max; //09-03 이렇게 들어온다
            this.slice_url2 = this.URL+'api/get_wave_slice?w_type=104&idx='+this.lidx2+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom2 ;
        }else if(a===3){
            s_min = this.$refs.fftchart3.select_min; //09-03 이렇게 들어온다
            s_max = this.$refs.fftchart3.select_max; //09-03 이렇게 들어온다
            this.slice_url3 = this.URL+'api/get_wave_slice?w_type=104&idx='+this.lidx3+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom3;
        }
        //  this.min_value = s_min;
        //  this.max_value = s_max;
        
        // this.slice_url = 'http://dev.neverlosewater.com/node/api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
        },                

        Enter_Check(){
         //  if(event.keyCode == 13){
          this.go_search();  // 실행할 이벤트
        },
        on_correlation_info_map() {                
          this.$refs.correlation_info.show(this.$refs.map.leak_idx)
        }, 
        on_correlation_info(row) {        
          this.$refs.correlation_info.show(row.id)
        },        
        on_leakage_info(idx) {          
          this.$refs.logger_detail_info.show(idx)
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        on_goto_map_click(row) {
            this.$refs.map.get_extent('w_type=1004&p_idx='+row.id).then((data)=>{
                this.$refs.map.set_fit_extent(data);
                 this.$refs.map.select_facility_feature(1004, '', row.id)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
        },
         on_goto_map_click1(id) {
            this.$refs.map.get_extent('w_type=104&p_idx='+id).then((data)=>{
                this.$refs.map.set_fit_extent(data);
                 this.$refs.map.select_facility_feature(104, '', id)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
            this.$refs.map.select_facility_feature('104','',id) 
        },
        set_map_gisload() {
            this.$refs.map.edit_end();
            let map = this.$refs.map.map;            
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
               alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                     if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                    var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    this.$refs.map.layer_list.push(newlayer) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);

                } //for문 끝

           } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },
        draw_line_clear(){
            this.$refs.map.draw_linestring( null, null);  
        },
        draw_line(){
           if(this.corr_sound1 === '' || this.corr_sound2 === '' ) {
               alert('No data is selected.') ;
               return;
           }
           if(this.coord_1 !== this.coord_2 ){ 
               if(this.corr_sound1 > this.corr_sound2){
                   this.$refs.map.draw_linestring( this.coord_2 , this.coord_1);                 
               }else{
                   this.$refs.map.draw_linestring( this.coord_1 , this.coord_2);
               }
           }else{
               alert("Please select another location.") 
           }
        },
        draw_modify(){
          
          if(this.$refs.map.corre_layer!= null){
            this.draw_line();  
            this.$refs.map.init_draw_modify();
            this.$refs.map.edit_change('modify', '')
          }else{
             alert("There is no data to modify.") 
          }
        },
        correction_call(){
            this.$refs.map.edit_end();
            //두개의 강도를 구해서 호출한다.
           if(this.corr_sound1 === '' || this.corr_sound2 === '' ) {
               alert("There is no data to calculate.") 
               return;
           }
           if(this.coord_1 !== this.coord_2 ){ 
         // this.$refs.map.leak_value_layer
            //추정된 누수위치를 화면에 뿌려준다.
          var l_v_layer =  this.$refs.map.corre_layer ;
            // //102 m2 모니터링누수음 시연용고객사 : 거제
           //alert(icoord1,icoord2)
            if(l_v_layer != null) {
              var source_h = l_v_layer.getSource();
              var fs = source_h.getFeatures();              
             // let param = {} ;              
              var len =  fs[0].getGeometry().getLength();
             // param["length"] = len ;
              //A거리 = (B강도-A강도+지점간거리)/2              
              this.cal_length = (this.strength2-this.strength1+len)/2 ; //20
              if(this.corr_sound1 > this.corr_sound2){
                  this.cal_length = (this.strength1-this.strength2+len)/2 ; //20
              }
              this.$refs.map.draw_point( fs[0].getGeometry(), this.cal_length) ;         
            } 
           }else{
               alert("Please select another location.") ;
           }
        },
        correction_save(){
            this.$refs.map.edit_end();
          //db에 work_id, customer_id, member_id, leak1, leak2, line_geometry,length, leak_geometry,leak_length
             var l_v_layer =  this.$refs.map.corre_layer ;
            // //102 m2 모니터링누수음 시연용고객사 : 거제
           //alert(icoord1,icoord2)
            var param = {};
            var len = 0;
            var line_fe = null;
            var leak_fe = null ;
            if(l_v_layer != null) {
              var source_h = l_v_layer.getSource();
              var fs = source_h.getFeatures();  
               if(fs.length <2 ){
                 alert("No calculated data.") 
                 return;
               }            
              //for(var k=0; k< fs.length ; k++) {
               //  source_h.removeFeature(fs[k]);
                  len =  fs[0].getGeometry().getLength();
                  line_fe =  fs[0]
                  leak_fe =  fs[1]
              //}

              // len =  fs[0].getGeometry().getLength();
             
            }
            if(line_fe === null ||leak_fe === null ){
                   alert("No calculated data.") 
                return;
            }
            // this.$refs.map.draw_point( fs[0].getGeometry(), cal_length) ;
            //4326 으로 line에 대한 geometry정보를 변환한다.                
            var line_data= {type: 'add', feature:line_fe, code: 0} ; //라인을 4326으로 변환
            var line_fs_4326 = this.$refs.map.change_xy(line_data);
            var point_data  = {type: 'add', feature: leak_fe, code: 1} ; //포인트값을 4326으로 변환
            var leak_fs_4326 = this.$refs.map.change_xy(point_data)
                //라인을 4326으로 변환
            param["customer_id"] = sessionStorage.select_customer_id ;
            param["water_work_id"] = this.$route.query.widx ;
            param["customer_member_id"] = sessionStorage.m_idx ;
            param["logger_detail_id1"] = this.corr_sound1 ;       
            param["logger_detail_id2"] = this.corr_sound2 ;
            if(this.corr_sound1 > this.corr_sound2){
                param["logger_detail_id1"] = this.corr_sound2 ;       
                param["logger_detail_id2"] = this.corr_sound1 ;
            }
            param["measure_datetime"] =this.flow_date;
            param["line_length"] = len ;
            param["leak_length"] = this.cal_length ;       
            var data = {type: 'add', line_geometry: line_fs_4326.feature.geometry,
            leak_geometry: leak_fs_4326.feature.geometry, params:param} ;
            //db에 work_id, customer_id, member_id, leak1, leak2, line_geometry,length, leak_geometry,leak_length
            this.$http.post(this.URL+'api/set_leak_logger_correlation',data).then((res) => {
                    console.log(res.data);
                  if(res.data.check ===1){ 
                    alert("Fail save."+res.data.msg)   
                  }else{
                    alert("Done save.");
                    this.draw_line_clear();
                    this.map_draw_correl() ;
                    this.$refs.correlations_list.setUrl(this.URL+"api/get_leak_logger_correlation_list_ex?customer_id="+sessionStorage.select_customer_id);  
                  }
            });
        },

        onLeakMapClick(){
            var l_v_layer = this.$refs.map.corre_layer ;
            // //102 m2 모니터링누수음 시연용고객사 : 거제
           //alert(icoord1,icoord2)
            if(l_v_layer != null) {
                var source_h = l_v_layer.getSource();
                var hfeatures = source_h.getFeatures();
                for(var k=0; k< hfeatures.length ; k++) {
                    source_h.removeFeature(hfeatures[k]);
                }
                this.$refs.map.map.removeLayer(l_v_layer) ;
                l_v_layer = null;
                this.$refs.map.corre_layer = null;
            } 
            let leak_idx = this.$refs.map.leak_recov_idx;
            let p_idx = this.$refs.map.leak_idx;
            let leak_coord = this.$refs.map.coord_map;
            let strength = this.$refs.map.move_idx
            //leak_info를 쿼리해서 그 데이터를 wav,fft에 앉힌다.
            this.chart_i = this.chart_i+1 ;
            if(this.chart_i%2 === 0){
                this.coord_2 = leak_coord;
                this.corr_sound2 = leak_idx;    
                this.strength2 = strength

            }else{
                this.coord_1 = leak_coord;
                this.corr_sound1 = leak_idx;    
                this.strength1 = strength ;
            }
            if(this.chart_i%3 === 1){
                this.lidx1 = leak_idx;    
                this.p_idx1 = p_idx
                document.getElementById("hidden-container1").style.display = 'none';
                this.wavesurfer_1.load(this.URL+'api/get_wav/water_leak_logger_detail/'+leak_idx+"?date="+this.flow_date);
                this.fft_url1 = this.URL+"api/get_fft/water_leak_logger_detail/"+leak_idx+"?date="+this.flow_date+'#'+this.vchart1;
                var audio1 = document.getElementById('slice_audio_id1')
                audio1.load(); 
                
             
            }else if(this.chart_i%3 === 2){
                this.lidx2 = leak_idx;
                this.p_idx2 = p_idx
                document.getElementById("hidden-container2").style.display = 'none';
                this.wavesurfer_2.load(this.URL+'api/get_wav/water_leak_logger_detail/'+leak_idx+"?date="+this.flow_date);
                this.fft_url2 = this.URL+"api/get_fft/water_leak_logger_detail/"+leak_idx+"?date="+this.flow_date+'#'+this.vchart2;
                var audio2 = document.getElementById('slice_audio_id2')
                audio2.load(); 
                
             
            }else{
                this.lidx3 = leak_idx; 
                this.p_idx3 = p_idx              
                document.getElementById("hidden-container3").style.display = 'none';
                this.wavesurfer_3.load(this.URL+'api/get_wav/water_leak_logger_detail/'+this.lidx3+"?date="+this.flow_date);
                this.fft_url3 = this.URL+"api/get_fft/water_leak_logger_detail/"+this.lidx3+"?date="+this.flow_date+'#'+this.vchart3;
                var audio3 = document.getElementById('slice_audio_id3') //초기화
                audio3.load(); 
               // this.coord_3 = leak_coord;
             }

        },
        size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
        },
        create_wavesurfer(){
            this.wavesurfer_1 = WaveSurfer.create({
                container: '#waveform_1',
                waveColor: 'blue',
                progressColor: 'black',
                mediaControls: true,
            // autoCenter: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [
                ]
            });
            this.wavesurfer_1.on('finish', () => {
                this.is_playing1 = false
            });
            
            this.wavesurfer_1.on("loading", function () {
                document.getElementById("hidden-container1").style.display = '';
                var mediaElt = document.getElementById("waveform_1");
                mediaElt = mediaElt.querySelector('audio');
                mediaElt.style =  "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-10px;border:0; outline:0;" ;
            //  this.wavesurfer.params.mediaControls = true;
            });
            
            //
            this.wavesurfer_2 = WaveSurfer.create({
                container: '#waveform_2',
                waveColor: 'blue',
               // progressColor: 'black',
               // autoCenter: true,
                mediaControls: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [      
                //    TimelinePlugin.create({
                //        container: '#wave-timeline2',    
                 //   }),
                Regions.create({
                        regionsMinLength: 1,
                        regions: [
                            {
                                start: this.region_start,
                                end: this.region_end,
                                loop: false,
                                color: 'hsla(400, 100%, 30%, 0.5)',
                                drag: false,
                                resize: false
                            }, 
                        ],
                    })                
                ]
            });


             this.wavesurfer_2.on("loading", function () {
                document.getElementById("hidden-container2").style.display = 'block';
                var mediaElt1 = document.getElementById('waveform_2');
                var mediaElt = mediaElt1.querySelector('audio');
                 mediaElt.style = "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-10px;border:0; outline:0;" ;
            });

           //this.wavesurfer_2.load(this.URL+'api/get_wav/-1');
            //
            this.wavesurfer_3 = WaveSurfer.create({
                container: '#waveform_3',
                waveColor: 'blue',
                progressColor: 'black',
        //     autoCenter: true,
                mediaControls: true,
                backend: 'MediaElement',
                normalize : true,
                height: this.waveform_height,
                plugins: [
                ]
            });
      
            this.wavesurfer_3.on("loading", function () {
                document.getElementById("hidden-container3").style.display = '';
                var mediaElt = document.getElementById("waveform_3");
                mediaElt = mediaElt.querySelector('audio');
                mediaElt.style =  "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-10px;border:0; outline:0;" ;

            });

        }

    },
    mounted () {
        this.contents_height = window.innerHeight - 120;
        this.fftchart_height =  this.contents_height/3 - 60;
        setTimeout(()=>{
           var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
            if( elem !== undefined ){
                if(elem.length > 0){
                    document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();            
                }
            }
        }, 500)  
        var date = this.$route.query.mea_date

        if(!(date === undefined || date === null)&& date !== '0001-01-01 00:00'){
          this.flow_date = date ;
          this.flow_date_min1 =  date.substr(0,10);
          this.flow_date_min2 =  date.substr(11);
          setTimeout(()=>{         
            this.chang_press_mapvalue_type();
          }, 1000)
        }
        document.getElementById("hidden-container1").style.display = 'none';
        document.getElementById("hidden-container2").style.display = 'none';
        document.getElementById("hidden-container3").style.display = 'none';
        this.create_wavesurfer();       
        this.fft_url1 = this.URL+"api/get_fft/-1";
        this.fft_url2 = this.URL+"api/get_fft/-1";
        this.fft_url3 = this.URL+"api/get_fft/-1";
        this.$refs.correlations_list.setUrl(this.URL+"api/get_leak_logger_correlation_list_ex?customer_id="+sessionStorage.select_customer_id);  
       // this.$refs.correlations_list.setUrl(this.URL+"api/get_leak_logger_correlation_list_ex?work_id="+this.$route.query.widx+"&customer_id="+sessionStorage.customer_id);  
    },
    watch: {
        
      v_chart: function() {
          if(this.v_chart){
  
               setTimeout(()=>{
                   this.create_wavesurfer() ;
                   this.wavesurfer_1.load(this.URL+'api/get_wav/m2_water_leak/'+this.lidx1);
                   this.fft_url1 = this.URL+"api/get_fft/m2_water_leak/"+this.lidx1+"&roof="+(++this.roof)+'#'+this.vchart1;
                   this.wavesurfer_2.load(this.URL+'api/get_wav/m2_water_leak/'+this.lidx2);
                   this.fft_url2 = this.URL+"api/get_fft/m2_water_leak/"+this.lidx2+"&roof="+(++this.roof)+'#'+this.vchart2;
                   this.wavesurfer_3.load(this.URL+'api/get_wav/m2_water_leak/'+this.lidx3);
                   this.fft_url3 = this.URL+"api/get_fft/m2_water_leak/"+this.lidx3+"&roof="+(++this.roof)+'#'+this.vchart3;
                    var audio1 = document.getElementById('slice_audio_id1')
                    audio1.load(); 
                    var audio2 = document.getElementById('slice_audio_id2')
                    audio2.load(); 
                    var audio3 = document.getElementById('slice_audio_id3')
                    audio3.load();                 
            }, 500)

          }
      },
      vchart1() {
        var c_url = this.fft_url1.split('#') ;
        this.fft_url1 = c_url[0]+'#'+this.vchart1 ;
      },
       vchart2() {
        var c_url = this.fft_url2.split('#') ;
        this.fft_url2 = c_url[0]+'#'+this.vchart2 ;
      },
      vchart3() {
        var c_url = this.fft_url3.split('#') ;
        this.fft_url3 = c_url[0]+'#'+this.vchart3 ;
      },

    },
    components: {
        Map: Map,
    //    FFTChart1_c: FFTChart1_c,
        pressChartData_fft: pressChartData_fft,
        DlgLeakLoggerDetailInfo:DlgLeakLoggerDetailInfo,
        ServerSideTable: ServerSideTable,
        DlgLeakCorrelationInfo : DlgLeakCorrelationInfo,
    }
}
</script>

<style lang="scss" >
.infoimg_logeer {
    width: 15px;
}
.infoimg{
    width: 15px;
}
.search_div.m2_map{
    position: absolute;
    left:210px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}
.corr{
    position: absolute;
    top: -50px;
    right:10px;    
    padding:0 10px;
    margin-bottom: 5px;
  
}
.corr input {
 width: 50px;
}
.corr label {
    padding: 10px;
    margin-bottom: 0;
}
.tab-content.logger_detail {
  overflow-x: hidden;
  max-height: calc(95vh + 20px);
  padding:0px;   
  
}
.list_box.list_box2.correl{
  background: #172a46; ;  
  padding: 10px;
  height:calc(95vh - 70px);
}
.card-box-title.gis_check{
  padding-left: 20px;
  color : #fff;
}
</style>

<template>
    <div>
        <b-modal
            ref="modal"
            title="Customer Info"
            >
            <b-tabs  v-if="properties!==null" align="center">
              <b-tab  :title="$t('work_info.proper')" >  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('work_info.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <!-- <b-input size="sm" v-model="properties['id']" disabled></b-input> -->
                            <b-input size="sm" v-model="properties['id']" disabled ></b-input>
                        </b-col>
                    </b-row>
                   
                    <b-row>
                        <b-col sm="4">
                            <label>customer_no</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['customer_no']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>area_id</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['area_id']" ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>name</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['name']" disabled></b-input>
                        </b-col>
                    </b-row>
                  
                    <b-row>
                        <b-col sm="4">
                            <label>sw_license_type</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['sw_license_type']" disabled></b-input>
                        </b-col>
                    </b-row>
               
                    <b-row>
                        <b-col sm="4">
                            <label>sw_license_count</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['sw_license_count']"  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col sm="4">
                            <label>register_datetime</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['register_datetime']" disabled  ></b-input>
                        </b-col>
                    </b-row>  
                    <b-row>

                        <b-col sm="4">
                            <label>service_start_date</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['service_start_date']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                     <b-row>

                        <b-col sm="4">
                            <label>service_end_date</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['service_end_date']" disabled  ></b-input>
                        </b-col>
                    </b-row>   
                  <!--b-row>

                        <b-col sm="4">
                            <label>update_datetime</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['update_datetime']" disabled  ></b-input>
                        </b-col>
                    </b-row>    
                    <b-row>
                        <b-col sm="4">
                            <label>Image</label>
                        </b-col> 
                        <b-col sm="8">
                              <b-img thumbnail fluid :src="properties['image_file_id']" alt="image" bottom ></b-img>
                        </b-col>
                    </b-row>                                                                                    

                    <b-row>
                        <b-col sm="4">
                            <label>gis_location</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['gis_location']" disabled  ></b-input>
                        </b-col>
                    </b-row-->                                                                                    
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('facility_info.status')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" ></b-select>
                        </b-col>
                </b-row>              
              </b-tab>
                <b-tab :title="$t('facility_info.specification')" class="event_info" active >
                  <b-row>
                        <b-col sm="4">
                            <label>Sound Total Count  </label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['sound_count']" disabled></b-input>
                            
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>Sound Lasttime</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['sound_lasttime']" disabled></b-input>
                            <!--b-input size="sm" v-model="sound_work_info" disabled></b-input-->
                            <label>{{sound_work_info}}(work_info)</label>
                        </b-col>
                    </b-row>
                     <!--b-row>
                        <b-col sm="4">
                            <label>Sound Work Info</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="sound_work_info" disabled></b-input>
                        </b-col>
                    </b-row-->
                    <b-row>
                        <b-col sm="4">
                            <label>Leak_Logger Count</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['leak_logger_count']" disabled></b-input>
                           
                        </b-col>
                    </b-row>
               
                    <b-row>
                        <b-col sm="4">
                            <label>Leak_Logger Lasttime</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['leak_logger_lasttime']" disabled ></b-input>
                            <!--b-input size="sm" v-model="leak_logger_work_info" disabled></b-input-->
                            <label>{{leak_logger_work_info}}(work_info)</label>                         
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col sm="4">
                            <label>Leak_monitering Cnt</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['leak_monitoring_cnt']" disabled  ></b-input>
                        </b-col>
                    </b-row>           
                    <b-row>
                        <b-col sm="4">
                            <label>Event detail</label>
                        </b-col> 
                        <b-col sm="8" >
                          <div class="event_list">  
                          <li v-for="(leak_event, index) in leak_event_list"  :key="index" >
                              {{index+1}} :  {{ leak_event.content }}
                          </li>
                          </div>
                        </b-col>
                    </b-row>           
                    <b-row>
                        <b-col sm="4">
                            <label>Pressure Count</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['pressure_count']" disabled  ></b-input>
                        </b-col>
                    </b-row>           
                     <b-row>
                        <b-col sm="4">
                            <label>Pressure Lasttime</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['pressure_lasttime']" disabled  ></b-input>
                            <!--b-input size="sm" v-model="pressure_work_info" disabled></b-input-->
                            <label>{{pressure_work_info}}(work_info)</label>
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col sm="4">
                            <label>Pressure_moni. Cnt</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['pressure_monitoring_cnt']" disabled  ></b-input>
                        </b-col>
                    </b-row>           
                     <b-row>
                        <b-col sm="4">
                            <label>Pressure_moni. event</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['pressure_monitoring_event']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>Event detail</label>
                        </b-col> 
                        <b-col sm="8" >
                            <div class="event_list">    
                          <li v-for="(press_event, index) in press_event_list"  :key="index" >
                             index : {{ press_event.content }}
                          </li>
                            </div>
                        </b-col>
                    </b-row>    
                    <b-row>
                        <b-col sm="4">
                            <label>Flow Count</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['flow_count']" disabled  ></b-input>
                        </b-col>
                    </b-row>           
                    <b-row>
                        <b-col sm="4">
                            <label>Flow Lasttime</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['flow_lasttime']" disabled  ></b-input>
                            <!--b-input size="sm" v-model="flow_logger_work_info" disabled></b-input-->
                             <label>{{flow_logger_work_info}}(work_info)</label>    
                        </b-col>
                    </b-row>                                                                      
                    <b-row>
                        <b-col sm="4">
                            <label>Flow Point Cnt</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['flow_monitoring_cnt']" disabled  ></b-input>
                        </b-col>
                    </b-row>                                                                      
                    <b-row>
                        <b-col sm="4">
                            <label>Flow_moni. event</label>
                        </b-col> 
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties1['flow_monitoring_event']" disabled  ></b-input>
                        </b-col>
                    </b-row>   
                    <b-row>
                        <b-col sm="4">
                            <label>Flow Event detail</label>
                        </b-col> 
                        <b-col sm="8" >
                          <div class="event_list">  
                             <li v-for="(flow_event, index) in flow_event_list"  :key="index" >
                              {{ flow_event.content }}
                             </li>
                          </div>
                        </b-col>
                    </b-row>                                                                       

                </b-tab>              
             

            </b-tabs>
<!--
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="apply()">Apply</b-button>
                
                <b-button @click="cancel1()">Close</b-button>
            </template>
-->
           <template v-slot:modal-footer="{ ok, cancel, hide }">

              <b-row class="modal-footer-row m-0 leak_foot">
               <b-col sm="6">

               </b-col>

               <b-col sm="6">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">{{$t('work_info.close')}}</b-button>
               </b-col>
               <!--b-col sm="6">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('work_info.apply')}}</b-button>
               </b-col-->              

             </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>
//import FFTChart1 from '@/components/FFTChart1';
//import * as d3 from 'd3'
//import PressBigChartData from '@/components/PressBigChartData' ;

export default {
    name: 'DlgWorkInfo',
    props: {
    },
    watch:{
     

    },
    data() {
        return {
            properties: null,
            properties1: null,
            properties_bak: null,
            s_customer_id : 1,
            work_id : 0,
             p_mode : '',
             recount: 0,
             myWorkItem : false,
             ele_data : 0,
             work_type : 100, 
             isActiveSetting : true, //로거인 경우엔 false
             isWorkBlock:false,
             f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
             ],
             columns: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'Remarks', field: 'remarks' }
             ],
             columns1: [
                { label: 'ID', field: 'id' },
                { label: 'Name', field: 'name' },
                { label: 'color', field: 'rgb' }
             ],
            event_all_list: [], //선택블럭
            leak_event_list: [], //선택추가할 블럭 
            press_event_list: [], // 변경값
            flow_event_list : [],
            server_host : '',
            sound_work_info :null,
            leak_logger_work_info :null,
            pressure_work_info :null,
            flow_logger_work_info :null,
            
        }
    },

    mounted () {
    },
    methods: {

           goRouter (path) {
             this.$router.push({ path: path, query: { tag: this.s_customer_id  } })
           },
       

            show(row,host) {
              this.server_host = host  
              this.properties = {} ;
              this.properties1 = row ;
            // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
              this.img_list =[];
              //this.work_type = w_type ;
              this.s_customer_id = row.id;
              this.s_customer_name = row.name
              this.$http.get(this.URL+'api/get_customer_list?name='+this.s_customer_name).then((res) => {
                   if((!res)||(res.data.list<=0)) return;
                   this.properties = res.data.list[0]
                   this.properties_bak = Object.assign({}, this.properties)
              });    
            // this.$http.get(this.URL+'api/get_press_info?idx='+idx).then((res) => {
              this.$http.get(this.server_host+'event/detail?customer_id='+this.s_customer_id).then((res) => {
                 
                if(res.data.length<=0) return           
                var work_list = res.data.result
                var event_list = res.data.log

                for(var i=0; i < work_list.length ; ++i){
                    if(work_list[i].type_code === 100)
                        this.sound_work_info = work_list[i].id +" : "+ work_list[i].title 
                    else if(work_list[i].type_code === 110)    
                      this.pressure_work_info = work_list[i].id +" : "+ work_list[i].title 
                    else if(work_list[i].type_code === 104)    
                      this.leak_logger_work_info = work_list[i].id +" : "+ work_list[i].title   
                    else if(work_list[i].type_code === 160)    
                      this.flow_logger_work_info = work_list[i].id +" : "+ work_list[i].title                       

                }
                this.leak_event_list =  []  //선택추가할 블럭 
                this.press_event_list =  [] // 변경값
                this.flow_event_list =  []
                for(i=0; i < event_list.length ; ++i){
                  if(event_list[i].work_type === '102'){ //leak monitoring
                    this.leak_event_list.push(event_list[i])
                  }else if(event_list[i].work_type === '111'){
                    this.press_event_list.push(event_list[i])
                  }else if(event_list[i].work_type === '161'){
                     this.flow_event_list.push(event_list[i])  
                  }

                }
             })

            this.$refs.modal.show();          
    

           },
         del(){
           var con_test1 = confirm("Are you sure you want to delete?");
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test1){
              this.$http.get(this.URL+'api/delete_ex?p_idx_ex='+this.properties['id']).then((res) => {  
                 console.log(res.data);
                 this.$emit('leaktable_refresh') ;
                 this.$refs.modal.hide();
              });

           }
        },
  
      
        apply() {
          //  if(localStorage === null || typeof localStorage === undefined){
              if(sessionStorage === null || typeof sessionStorage === undefined){
                alert("Can not be modified.")
                return
         //   }else if(localStorage.level === 'C'){
              }else if(sessionStorage.level > 1){
                alert("You do not have permission to edit.")
                return;
            }
            if(this.change_work > 0) {
                this.work_block_setting()
            }
            let params = {} ;
            let keys = Object.keys(this.properties_bak)
            for(let i=0; i<keys.length; i++) {
            if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                params[keys[i]] = this.properties[keys[i]]
                if('status' === keys[i] && this.properties[keys[i]] === 'U'){
                  var del_url = this.URL+'api/delete_ex?w_idx_ex='+this.properties['id']
                  if(this.work_type === 110){
                    del_url = this.URL+'api/delete_ex?p_idx_ex='+this.properties['id']
                  } 
                  this.$http.get(del_url).then((res) => {
                        console.log(res.data);
                    })  
                }
            }
            if(Object.keys(params).length<=0) return
            this.$http.get(this.URL+'api/update_work_info?id='+this.properties['id']+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                console.log(res.data);
            })   
       
            this.$emit('worklist_refresh') ;
            this.$refs.modal.hide();     
        }       
    },
    components: {
         //   FFTChart1:FFTChart1
        //  PressBigChartData:PressBigChartData,
      
    }
}
</script>

<style  lang="scss">

.pressure_chart  .highcharts-container {
  width:440px;
}
.pressure_chart svg.highcharts-root {
    max-width: 440px;
}
.facility__label-radio {
  font-size: 12px;
  color: #616161;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}
.tab-content {
    max-height: 720px;    
    overflow-y: auto;    
    overflow-x: hidden; 
}
 .event_info .form-control:disabled {
     color : #fff;
 }
 .event_info .event_list {
     max-height : 80px;
     min-height: 30px;;
     overflow-y: auto;
     border: #707070 1px solid;
 }
// .modal-info {
//   .modal-dialog {
//     position: absolute;
//     right: 0;
//     top: 0;
//     margin: 70px auto 0;
//     // 2022 new
//     // background: #F2F2F2;
//     background: #172A46;
//     width: 350px;
//     // width: 350px;
//     height: calc(100% - 70px);
//     .modal-header {
//       padding-bottom: 22px;
//       .modal-title {
//         font-size: 18px;
//         // margin-top: 12px;
//       }
//     }
//     .modal-info-content {
//       border: 0;
//       border-radius: 0;
 
//       &.reversal {
//         background: #172A46;
//         .nav-tabs {
//           background: #172A46;
//         }
//         .tab-content {
//           background: #172A46;
//           &.is--scroll {
//             height: 73vh;
//             overflow-y: auto;
//           }
//         }
//         .nav-tabs .nav-item.show .nav-link,
//         .nav-tabs .nav-link.active {
//           // 2022 new
//           // background: #fff;
//           border-color: 1px solid #707070;
//         }
//         .facility__label {
//           color: #fff;
//           font-size: 12px;
//           display: inline-block;
//           letter-spacing: 0.5px;
//           //width: 35%;
//         }
//         .facility__input {
//           //width: 60%;
//           height: 32px;
//           font-size: 12px;
//           border: 1px solid #707070;
//           box-sizing: border-box;
//           border-radius: 0;
//           margin-top: 0;
//           margin-bottom: 14px;
//           background: #0A192F;
//           &.height--auto {
//             height: auto;
//           }
//         }
//         .modal-footer {
//           background: #172A46;
//           padding-bottom: 18px;
//           padding-top: 3px;
//         }
//         .modal-footer-row {
//           width: 100%;
//           margin: 0;
//           padding-bottom: 0;
//         }
//         .btn.modal-footer__button {
//           height: 52px;
//           font-size: 14px;
//           border: 0;
//           &.modal-footer__button-ok {
//             // 2022 new
//             // background: #28B7CB;
//             background: #28AF81;
//           }
//           &.modal-footer__button-cancel {
//             background: #9D9D9D;
//           }
//         }
//         .photo-card {
//           border-radius: 0;
//           border: 0;
//           background: none;
//           .img-thumbnail {
//             margin-bottom: 10px;
//           }
//           .card-header {
//             height: 150px;
//             background: #172A46;
//             text-align: center;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             .card-header__text {
//               font-size: 16px;
//               color: #fff;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
import { render, staticRenderFns } from "./pressChartData_fft.vue?vue&type=template&id=065e9fee&scoped=true"
import script from "./pressChartData_fft.vue?vue&type=script&lang=js"
export * from "./pressChartData_fft.vue?vue&type=script&lang=js"
import style0 from "./pressChartData_fft.vue?vue&type=style&index=0&id=065e9fee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "065e9fee",
  null
  
)

export default component.exports
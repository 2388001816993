<template>
<!--  <div>-->
 

    <div class="items">
    <div class="item-head">
      <div class="item-head--left">
        <h3>{{ properties['name'] }}</h3>       
      </div>
      <div class="item-head--right">
        <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="on_inp_file_info"
             style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
        <!--img src="@/assets/images/new/ic_list_map.svg" alt="" @click="goRouter('/leak-monitoring-detail-map?w_type=102')"
             style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer"-->
        <img src="@/assets/images/new/ic_menu_chevron.svg" alt="" @click="goRouter('/simulation-detail')"
             style="width: 8px; cursor: pointer">
      </div>
    </div>
    <div class="item-content">
      <ul class="item-content--left" >
        <li>
          <span class="unit m2_press"> {{$t('inp_file_info.register')}} : {{ properties['m_id'] }}</span>
         
          
        </li>
         <li>
          <span class="unit m2_press">{{$t('inp_file_info.register_time')}} : {{ properties['insert_datetime'] }}</span>          
          
        </li>
         <li>
          <span class="unit m2_press">{{$t('inp_file_info.save_time')}} : {{ properties['update_datetime'] }}</span>          
          
        </li>
        <li>
          <span class="unit m2_press">{{$t('inp_file_info.f_name')}} : {{ properties['file_name'] }}</span>
          
        </li>
      </ul>
      <div class="item-content--right">
         <li>
          <span class="unit m2_press">{{$t('inp_file_info.node')}} : {{ properties1['Junctions'] }}</span>
          </li>
         <li>
          <span class="unit m2_press">{{$t('inp_file_info.reservior')}} : {{ properties1['Reservoirs'] }}</span>          
            
        </li>
         <!--li>
          <span class="unit m2_press">{{$t('inp_file_info.tank')}} : {{ properties1['Tanks'] }}</span>          
          
        </li-->
        <li>
        
          <span class="unit m2_press" >{{$t('inp_file_info.tank')}} : {{ properties1['Tanks'] }}</span> 
          <span class="unit m2_press" style="padding-left:15px;">{{$t('inp_file_info.link')}} : {{ properties2['Pipes'] }}</span>
        </li>
         <li>
          <span class="unit m2_press">{{$t('inp_file_info.link_length')}} : {{ properties2['Pipes_length'] }}</span>
          
        </li>
         <li>
          <span class="unit m2_press">{{$t('inp_file_info.pump')}} : {{ properties2['Pumps'] }}</span>
          <span class="unit m2_press" style="padding-left:15px;">{{$t('inp_file_info.valve')}} : {{ properties2['Valves'] }}</span>
        </li>
        <!--li>
          <span class="unit m2_press">{{$t('inp_file_info.valve')}} : {{ properties2['Valves'] }}</span>
          
        </li-->
        
      </div>  

      </div>
   



</div>
    
</template>

<script>

export default {
    name: 'ModelingCard',
    props: [
        'dma_name',
        'tag'
    ],
    data() {
        return {
            properties : {},
            properties1 : {},
            properties2 : {},
            now_flow: '-',
            last_mea_date: null,
            name :'',
            battery:'-',
            now_mnf: null,
            flow: ['0','0','0','0'],
            mnf: ['0','0','0','0'],
            rate:['0','0','0','0'],
            title_color: 'red',
            color1: '' ,color2: '' ,color3: '' ,color4: '' ,color5: '' ,
            item : require('@/assets/images/new/ic_battery.svg')
        }
    },
    methods: {
        goRouter(path) {
           
            this.$router.push({path: path, query: { id: this.tag }})
        },
        on_inp_file_info(){
          this.$emit('on_inp_file_info',  this.tag)
        }
    },
    mounted () {
        this.title_color = 'white'
     //   this.$http.get(this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&limit=5').then((res) => {
        this.$http.get(this.URL+'api/get_model_info?id='+this.tag).then((res) => {
          if(res.data.length<=0) return 
          this.properties = res.data.list[0];
          this.$http.get(this.URL+'api/get_model_info_setting?id='+this.tag).then((res1) => {
             if(res1.data.node_tot !== null &&  res1.data.node_tot !== undefined)
               this.properties1 = res1.data.node_tot ;
             if(res1.data.link_tot !== null &&  res1.data.link_tot !== undefined)
              this.properties2 = res1.data.link_tot ;
          });

        });
    

    },
    beforeDestroy () {
    },
    components: {
    }
}


</script>

<style scoped lang="scss">
.table_block {
  &:nth-child(3n) {
    margin-right: 0;
  }
 // background-color:#dde9f3 ;
  background-color:#94999d ;
}
.title_box h3{
  color: #fff;
}
.items {
  padding: 16px;
  // height: 280px;
  box-sizing: border-box;
  border: 1px solid #707070;
  background: url('../../assets/images/sub/epanet/img_ai.png') -35% 0px no-repeat ;
  opacity:0.7;
  .item-head {
    display: flex;
    justify-content: space-between;

    &--left {
      h3 {
        font-size: 24px;
        color: white;
        line-height: 1;
        margin: 0 0 6px 0;
       text-overflow: ellipsis;
        width: 350px;
        display:inline-block;
        white-space: nowrap;
        overflow: hidden; 


      }

      p.date {
        margin: 0;
        font-size: 14px;
        color: white;
        opacity: .6;
      }
    }
  }

  .item-content {
    display: flex;
    // padding-top: 16px;

    &--left {
      width: 100%;
      margin-left:27%;
      padding-top: 15px;
      
     /* border-right: solid #fff 1px; */
      li {
        
        font-family: 'Roboto', sans-serif;
        width: 95%;
        display: inline-block;   
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
        
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }

      span.unit {
        font-size: 17px;
        line-height: 1;
        color: white;
      }
      p.num {
        font-size: 44px;
        font-weight: bold;
        color: #01AF81FF;
        line-height: 1;
      }
    }

    &--right {
      // width: 100%;
      // position: relative;
      position: absolute;
      width: 0%;
      right: 16px;
      bottom: 16px;
      height: 70%;
       li {
        font-family: 'Roboto', sans-serif;
        width: 95%;
        display: inline-block;   
       //  white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
        
        &:not(:last-child) {
          margin-bottom: 0px;
        }
        
      }
      .btn_next {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
      }
      p {
          // margin-top: 0;
          // margin-bottom: 0.5rem;
          position: absolute;
          bottom: 30px;
          right: 9px;
          margin-bottom:0;
      }

    }
  }
}
      .status_circle > div {
    // float: left;
    width: 11.5%;
    font-size: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    width: 24px; 
    
}
// .status_circle > li a, 
.status_circle > div::after {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #707070;
    display: block;
    position: absolute;
    left: 50%;
    top: 20px;
    -webkit-transform: translate(-45%,0);
    transform: translate(-45%,0);
}
.status_circle > div {
    // float: left;
    width: 11.5%;
    font-size: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    width: 24px; 
    
}
// .status_circle > li a, 
.status_circle > div::after {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #707070;
    display: block;
    position: absolute;
    left: 50%;
    top: 20px;
    -webkit-transform: translate(-45%,0);
    transform: translate(-45%,0);
}
.status_circle ul{
  display: flex;
    padding-left: 0;
    list-style: none;
    position: absolute;
    bottom: 0;
    right: 0;
}
.status_circle ul li{
  display: list-item;
    text-align: -webkit-match-parent;
    margin-right: 6px;
    list-style: none;
}
.status_circle ul li a{
  display: block; 
    line-height: 0.6 !important;
    position: relative;
    display: block;
    padding: 9px;
    font-size: 12px;
    z-index: 3;
    color: #fff;
    border-radius: 20px;
    background-color: #707070;
    // border-color: #28AF81;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    line-height: 0.6 !important;
    line-height: 0.6 !important;
}
.status_circle .color_01{
                    background: #EB5B5B;
                }
.status_circle .color_02{
                    background: #638CFB;
                }

.items{
  background-color: #172A46;
}
.items:hover {
     border: 2px solid #28AF81;                    
  /*   background-color: #0E5340; */
     cursor: pointer;
  }

// .status_circle > li, 



// .status_circle .color_01::after{
//                     background: #EB5B5B;
//                 }
// .status_circle .color_02::after{
//                     background: #638CFB;
//                 }


//  .status_circle > li{
//      display: list-item;
//     text-align: -webkit-match-parent;
//     margin-right: 6px;
//     display: flex;
//     padding-left: 0;
//     list-style: none;
//     float: left;
//   }


</style>

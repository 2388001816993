
<script>
  import { Bar } from 'vue-chartjs'



  export default {
    extends: Bar,
   // mixins: [reactiveProp],
   props:{

      chartData1: {
        type: Array
      },
     chartData2: {
        type: Array
      },
      chartData3: {
        type: Array
      },
      xdata: {
        type: Array,
      },
      item:{
        // 0 : pump, 1: watertank
        type : Number,
      }
  },

    watch: {
      chartData3: function() {
        this.$data._chart.destroy()
        this.datacollection.labels = this.xdata;
        this.datacollection.datasets[0].data= this.chartData1;
        this.datacollection.datasets[1].data= this.chartData2;
        this.datacollection.datasets[2].data= this.chartData3;
        this.renderChart(this.datacollection, this.options) ;
        this.$data._chart.update()
      },
      /*
      bar_idx : function() {
        this.onBarClickEvent(this.bar_idx) ;

      }
      */

    },
    data () {
      return {
        
        datacollection: {

          //  labels: ["2014", "2013", "2012", "2011", "2010"],
            labels: this.xdata,
            bar_idx: -1,
            datasets: [{
             // data: [727, 589, 537, 543, 574],
              label: 'Out Flow Day',
              data: this.chartData1,
               yAxisID: "y-axis-1",
              backgroundColor: "#2BB7CD",
              
              hoverBackgroundColor: "#14a8bf"
           },{
            //  data: [238, 553, 746, 884, 903],
              label: this.item === 0 ? 'max total head' : 'highest water level'   ,
              type: 'line',
              data: this.chartData2,
               yAxisID: "y-axis-2",
              borderColor: "#0E2D58",
              fill:false,
              hoverBackgroundColor: "#051e41"
            },{
            //  data: [1238, 553, 746, 884, 903],
              type: 'line',
              label:this.item === 0 ? 'min total head' : 'low water level',          
              data: this.chartData3, 
               yAxisID: "y-axis-2",
               borderColor: "#F4BD48",
               fill:false,
               hoverBackgroundColor: "#e5a419"
            }]


        },
        options: {
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero:true,
                fontFamily: "'Open Sans Bold', sans-serif",
                fontSize:11
              },
              scaleLabel:{
                display:true
              },
              gridLines: {
                 display:false,
              },
              stacked: false
              }],
             /*
              yAxes: [{
                gridLines: {
                  display:true,
               //   color: "#fff",
              //    zeroLineColor: "#fff",
               //   zeroLineWidth: 1
                },
                ticks: {
                  fontFamily: "'Open Sans Bold', sans-serif",
                  fontSize:11
                },
                stacked: false
              }]
          */
 
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        id: "y-axis-1",
                        scaleLabel: {
                          display: true,
                          labelString: 'Water Flow (㎥/day)'
                        },       
                    }, {
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "right",
                        id: "y-axis-2",
                        scaleLabel: {
                          display: true,
                          labelString: 'Total Head (m)'
                        },       
                        // grid line settings
                        gridLines: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                   
                    }],
            
          },
          /*
          onClick: function(event, element) {
                  this.bar_idx = -1;
                  var activeElement = element[0];
                  var data = activeElement._chart.data;
                  var barIndex = activeElement._index;
                  var datasetIndex = activeElement._datasetIndex;

                  var datasetLabel = data.datasets[datasetIndex].label;
                  var xLabel = data.labels[barIndex];
                  var yLabel = data.datasets[datasetIndex].data[barIndex];
                  var idx = xLabel.split(':') ;
                  this.bar_idx = idx[0];
                //  activeElement._chart.onBarClickEvent(idx)

                  console.log(datasetLabel, xLabel, yLabel);
          },
          */
          legend: {
            display: false,
            labels: {
              boxWidth: 10,
              padding: 5,
              fontSize: 12,
              fontStyle: 'bold'
              // fontFamily: "'Ubuntu', AppleSDGothicNeo, -apple-system, BlinkMacSystemFont"
            }
          }, /*
          tooltips: {
              callbacks: {
                 label: function(tooltipItem, data) {
                        var rLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].r;
                        rLabel = rLabel/10;
                        var datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                        return datasetLabel+' : '  + tooltipItem.xLabel+','+ tooltipItem.yLabel+' : ' + rLabel;
                        //return datasetLabel + ' : ' + rLabel + '% har pris på ca. ' + tooltipItem.yLabel + ' kr.';

                  }
              }
          },
          */
          title:{
            display : false,
            text : 'Latest 30 Water leak sound collection works',
            fontSize : 18
          },
          responsive: true,
          maintainAspectRatio: false,
          onClick:this.handle
        }
      }
    },

    methods: {
        onBarClickEvent(idx){
            this.bar_idx = idx ;
           this.$emit('onBarClick') ;
        },
        handle (point, event) {
          const item = event[0] ;
          var index = item._index;
         // var datasetLabel = item.data.labels[index];
          var xLabel =  item._chart.data.labels[index];

          var idx = xLabel.split(':') ;
          this.bar_idx = idx[0];
          this.onBarClickEvent(idx) ;
          /*
           this.$emit('on-receive', {
            index: item._index,
            backgroundColor: item._view.backgroundColor,
            value: this.values[item._index]
          })
          */
    }
    },
    mounted () {
  //    this.height = 150;
      this.renderChart(this.datacollection, this.options)
    }
  }
</script>

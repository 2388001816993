<template>
    <div>
        <b-modal
            ref="modal"
            title="Frequency Chart"
            ok-only
            >
            <div ref="chart" style="margin: 5px 5px 5px 5px;  height: 300px;">
                <svg/>
            </div>
      </b-modal>
    </div>
</template>

<script>
import * as d3 from 'd3'
import d3tip from "d3-tip";
d3.tip = d3tip;

export default {
    name: 'DlgFrequencyChart',
    data() {
        return {
            fdata: []
        }
    },
    methods: {
        show(lidx) {
            this.$refs.modal.show();
            this.$http.get(this.URL+'api/get_frequency?l_idx='+lidx).then((res) => {
                for(let i=0; i<res.data.rows.length; i++) {
                    if(res.data.rows[i].wave_energy===null) res.data.rows[i].wave_energy = 0
                    if(res.data.rows[i].wave_max_frequency===null) res.data.rows[i].wave_max_frequency = 0
                }
                this.fdata = res.data.rows
                let arr = this.fdata.map((itm) => { return itm.wave_energy });
                let sorted = arr.slice().sort(function(a,b){return b-a})
                let ranks = arr.slice().map(function(v){ return sorted.indexOf(v)+1 });
                for(let i=0; i<arr.length; i++)
                    this.fdata[i].rank = ranks[i];
                this.drawChart()
            });
        },
        drawChart() {
            d3.select(this.$refs.chart).select("svg").remove();
            var svg = d3.select(this.$refs.chart).append("svg");
            var chartDiv = this.$refs.chart;
            var margin = {top: 20, right: 20, bottom: 20, left: 23};
            var width = chartDiv.clientWidth - margin.left - margin.right;
            var height = chartDiv.clientHeight - margin.top - margin.bottom;
            svg.attr("width", chartDiv.clientWidth).attr("height", chartDiv.clientHeight);

            // Create Tooltips

            var tip = d3.tip().attr('class', 'd3-tip').direction('e').offset([-100,-100])
                .html(function(d, idx) {
                    var content = "<span style='margin-left: 2.5px;'><b>" + 'No:'+ (idx+1) + "</b></span><br>";
                    content +=`
                        <table style="margin-top: 2.5px;">
                                <tr><td>Leakage strength: </td><td style="text-align: right">` + d.wave_energy.toFixed(2) + `</td></tr>
                                <tr><td>Max. frequency: </td><td style="text-align: right">` + d.wave_max_frequency.toFixed(0)  + `Hz</td></tr>
                                <tr><td>Leakage Strength Rank: </td><td style="text-align: right">` + d.rank  + `</td></tr>
                        </table>
                        `;
                    return content;
                });

            svg.call(tip);

            var x = d3.scaleBand().rangeRound([0, width]).padding(0.1),
                y = d3.scaleLinear().rangeRound([height, 0]);

            var g = svg.append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            let data = this.fdata.map((itm) => { return itm.wave_energy });

            x.domain(this.fdata.map((itm, idx) => { return idx+1 }));
            y.domain([0, Math.max.apply(null,  data)]);

            g.append("g")
                .attr("class", "axis axis--x")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x));

            g.append("g")
                .call(d3.axisLeft(y).ticks(10))
                .append("text")
                .attr("fill", "#000")
                .attr("transform", "rotate(-90)")
                .attr("y", 6)
                .attr("dy", "0.71em")
                .attr("text-anchor", "end")
                .text("Frequency");

            g.selectAll(".bar")
                .data(this.fdata)
                .enter().append("rect")
                .attr("class", "bar")
                .attr("x", function(d, i) { return x(i+1); })
                .attr("y", function(d) {
                    return y(d.wave_energy);
                })
                .attr("width", x.bandwidth())
                .attr("height", function(d) {
                    return height - y(d.wave_energy);
                })
                .on('mouseover', tip.show)
                .on('mouseout', tip.hide);

        }
    },
    mounted () {
    },
    components: {
    }
}
</script>

<style>
.d3-tip {
        line-height: 1;
        font-weight: bold;
        padding: 12px;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        border-radius: 2px;
        z-index: 10000;
}

.bar {
    fill: steelblue;
}

.bar:hover {
    fill: brown;
}
</style>

<template>
  <div>
    <!-- top-bar -->
    <div  ref="navbar" class="navbar-custom">
<!-- 2022 new -->
      <h4 class="content-title">{{$t('menu.gis_master')}}</h4>    
      <ul class="list-unstyled topnav-menu-gm topnav-menu-left">
        <!-- 추가변경 0419 -->
        <!-- <li class="toggle-menu">
           <a href="#"><i class="mdi mdi-menu toggle-menu-btn"></i></a>
         </li> -->
        <!-- 추가변경 0419 -->
        <!-- <li>
          <ol class="breadcrumb with--map"> -->
                                   <!-- <li class="breadcrumb-title">GIS Master</li> -->
                                   <!-- <li class="breadcrumb-bar">|</li> -->
            <!-- <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">{{$t('menu.home')}}</router-link></li>
            <li class="breadcrumb-item active">{{$t('menu.gis_master')}}</li>
          </ol>
        </li> -->
      </ul>
    </div>
    <!-- tom-bar-end -->

    <div>
      <b-row style="margin: 0;">
         <b-col :cols="msize" style="padding: 0 15px 0 0;">
          <div :style="{height: map_height+'px'}">
            <div class="search_div">
                <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('home.input_search_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
            </div>
            <Map ref="map"
                 w_type="-1000"
                 visible_full_extent="true"
                 visible_active_button="true"
                 visible_gischeck_button = "true"
                 :visible_gisload_button="false"
                 :visible_size_side_view_button="true"      
                 layer_hide_leakage="true"
                 @go_home="go_home"
                 @go_gischeck = "go_gischeck"
                 @set_status="set_status"
                 @size_view="size_view"
                 @on-row-click="onRowClick"
                 @facility_refresh="facility_refresh"
                 @gis_reload = "gis_reload"
                 @change_map_mode="change_map_mode"
            />

            <div style="position: absolute; left: 5px; top: 5px; z-index: 10;">
              <b-button-toolbar aria-label="Toolbar with button groups">
                <b-button-group class="mr-2 icon-button-group">
                  <b-radio :button='true' size="sm" v-model="map_mode" value='select' v-b-tooltip.hover :title="$t('home.icon_select_title')">
                    <span class="fas fa-mouse-pointer"></span>
                  </b-radio>
                  <b-radio :button='true' size="sm" v-model="map_mode" value='info' v-b-tooltip.hover :title="$t('home.icon_info_title')" >
                    <i class="fas fa-info"></i>
                  </b-radio>
                  <b-radio :button='true' size="sm" v-model="map_mode" value='edit' v-b-tooltip.hover :title="$t('button.modify')">
                    <span class="fas fa-pencil-alt"></span>
                  </b-radio>
                </b-button-group>
                <b-button class="map-button icon_btn"  size="sm" @click="zoom('+')" v-b-tooltip.hover title='zoom(+)'>
                  <span class="fas fa-search-plus"></span>
                </b-button>
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="zoom('-')" v-b-tooltip.hover title='zoom(-)'>
                  <span class="fas fa-search-minus"></span>
                </b-button>

                <b-button class="mr-2 map-button icon_btn" size="sm" @click="delete_feature()" v-b-tooltip.hover :title="$t('button.delete')">
                  <span class="fas fa-window-close"></span>
                </b-button>
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="zoom_fit()" v-b-tooltip.hover title='zoom_fit'>
                  <span class="fas fa-expand"></span>
                </b-button>
              
                <b-button class="mr-2" size="sm"  @click="$refs.upload_shape.show();" v-b-tooltip.hover title='upload_shape'>
                    <i class="fas fa-cloud-upload-alt"></i>
                </b-button>
               
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="toggle_visible_all_layer()"  v-b-tooltip.hover :title="layer_all_visible ? $t('home.icon_vis_title') :$t('home.icon_invis_title') " >
                  <span v-show="layer_all_visible"><i class="fas fa-toggle-on"/> </span>
                <!--/b-button>
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="toggle_visible_all_layer()" v-show="!layer_all_visible" v-b-tooltip.hover :title="$t('home.icon_invis_title')"-->
                 <span v-show="!layer_all_visible"> <i class="fas fa-toggle-off"/></span>
                </b-button>

                <button  class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text" type="button" @click="set_map_gisload()"  v-b-tooltip.hover title='GIS Load' id="gis_btn_mas" >{{$t('button.gisload')}}</button>
              </b-button-toolbar>
              <p id="c_zoom">zoom=</p>

              <div style="width: 300px; position: absolute; left: 0; top: 35px; ">
                <b-card id="edit" v-if="$refs.map && visible_edit">
                  <template v-slot:header>
                    <b-button-close @click="edit_cancel()"/>
                    <h6 class="mb-0">{{$t('home.edit')}}</h6>
                  </template>
                  <b-row>
                    <b-col>
                      <b-radio v-model="edit_mode" value="add" >{{$t('button.add')}}</b-radio>
                    </b-col>
                    <b-col>
                      <b-radio v-model="edit_mode" value="modify" >{{$t('button.modify')}}</b-radio>
                    </b-col>
                  </b-row>
                  <div v-if="edit_mode==='add'">
                    <b-row v-for="(item, index) in get_edit_layers()" :key="index">
                      <b-col>
                        <b-radio size="sm" v-model="add_layer_name" :value="item.get('name')"> {{item.get('name')}} </b-radio>
                      </b-col>
                    </b-row>
                  </div>
                  <template v-slot:footer>
                    <!-- <b-button @click="edit_ok()" class="mr-2">OK</b-button>
                     <b-button @click="edit_cancel()" >Cancel</b-button>-->
                  </template>

                </b-card>
              </div>

            </div>



          </div>
        </b-col>
   
        <b-col  v-if="v_chart" :cols="tsize" style="padding: 0 10px 5px 0;margin-left:-5px; /*background: #fff;*/ ">
          <!-- <h5 class="card-box-title">GIS Data table</h5>
            <label for="textarea-small" class="table-box-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label> -->
          <div class="info_box gis_master">
            <div class="status m1-map d-flex" style="position: relative; left: 0px;top:0px;;">
              <div>
                <input type="radio" id="radio_01" name="radio01" checked v-model="gis_type" value="-1">
                <label for="radio_01">{{$t('map.all')}}</label>
              </div>
              <div>
                <input type="radio" id="radio_02" name="radio01"   v-model="gis_type" value="7">
                <label for="radio_02">Block</label>
              </div>
              <div>
                <input type="radio" id="radio_03" name="radio01"  v-model="gis_type" value="1">
                <label for="radio_03">Valve</label>
              </div>
              <div>
                <input type="radio" id="radio_04" name="radio01"   v-model="gis_type" value="2">
                <label for="radio_04">Water Meter</label>
              </div>
              <div>
                <input type="radio" id="radio_05" name="radio01"   v-model="gis_type" value="0">
                <label for="radio_05">Pipe</label>
              </div>
                
            </div>   
          </div>
          <div class="list_box list_box2 gis_master">  
          <ServerSideTable1 ref="facility_list"
                           :columns="columns"
                           :search="true"
                           :sort="true"
                           :select="false"
                           :add_custom_column="true"
                           :adv_search="adv_search"
                           :max_height="table_height+'px'"
                             @on-row-click="onRowClick"
                           @search_item_change="search_item_change"
                           @on_info_click="on_facility_info"
                           @on_goto_map_click="on_goto_map_click"
          />
          </div>
        </b-col>
      </b-row>
    </div>
    <UploadShape ref="upload_shape"/>
  </div>
</template>

<script>

import ServerSideTable1 from '@/components/table/ServerSideTable1';
import Map from '@/components/Map';
import proj4 from 'proj4';
import GeoJSON from 'ol/format/GeoJSON';
import {CreateVectorLayer_f} from '@/gis.js';
//import {getLength} from 'ol/sphere';
//import {LineString} from 'ol/geom';
export default {
  name: 'GISMaster',
  props: {
  },
  data() {
    return {
      map_mode: 'select',
      addr_value :'',
      layer_all_visible: false,
      visible_edit: false,
      edit_mode: 'add',
      add_layer_name: 'Service Pipe',
      add_fe: [],
      contents_height: 600,
      map_height: 300,
      table_height: 300,
      //    new_add_fa : 0 ,
      gis_type : '-1',
      msize:'7',
      tsize:'5',
       clicked_b_row:null,
      v_chart : true,
      columns: [
        {label: this.$t('gis.table_column.map'), field: 'go_map', width: '5%', sortable: false},
        {label: this.$t('gis.table_column.info'), field: 'info', width: '5%', sortable: false},
        {label: this.$t('gis.table_column.facility_no'), field: 'id', width: '10%',},
        {label: this.$t('gis.table_column.facility_name'), field: 'name', width: '20%',},
     //   {label: 'layer_type', field: 'layer_type', sortable: false, hidden: true, },
        {label: this.$t('gis.table_column.layer_type'), field: 'type_', sortable: false,  width: '8%',},
        {label: this.$t('gis.table_column.f_type'), field: 'type', sortable: false, width: '10%',},
        {label: this.$t('gis.table_column.material'), field: 'material', width: '10%',},
        {label: this.$t('gis.table_column.block'), field: 'block_id', width: '4%',},
        {label: this.$t('gis.table_column.size'), field: 'size', width: '8%',},
     //   {label: this.$t('gis.table_column.unit'), field: 'unit_', width: '10%',},
    //    {label: this.$t('gis.table_column.capacity'), field: 'capacity', width: '5%',},
    //    {label: this.$t('gis.table_column.worker'), field: 'member_id', width: '10%',},
    //    {label: this.$t('gis.table_column.date_install'),field: 'installation_date',  width: '15%',},
        {label: this.$t('leak_detail.table_column.image'), field: 'image',width: '4%',},        
        {label: 'update_time', field: 'update_datetime',  width: '10%',},
        {label: this.$t('gis.table_column.status'), field: 'f_status_', sortable: false, width: '6%',},
      ],
      adv_search: [
        {
          field: 'layer_type',
          control: 'select',
          value: null,       
          options: [
            { value: null, text: 'Type' },
            { value: '0', text: 'Pipe' },
            { value: '1', text: 'Valve' },
            { value: '2', text: 'Water Meter' },
            { value: '3', text: 'Pump'},
            { value: '4', text: 'Hydrant'},
            { value: '7', text: 'Block'},
            { value: '8', text: 'Water Treatment Plant'},
            { value: '9', text: 'Pumping station'},
            { value: '10', text: 'Water_basin'},
            { value: '11', text: 'Chamber'},
            { value: '12', text: 'Gauge'},
            { value: '13', text: 'Other'},
          ]
        },
        {field: 'type', control: 'select', value: null, options: [{ value: null, text: 'Facility type' }]},
        {field: 'size', control: 'range', value: null, value2: null, placeholder: 'From size', placeholder2: 'To size'},
        {field: 'status', control: 'select', value: null,
          options: [
            { value: null, text: 'Status Type' },
            { value: 'A', text: 'Active' },
            { value: 'I', text: 'Inactive' },
            { value: 'U', text: 'Unable' },
          ]
        },
        {field: 'block_id', control: 'select', value: null, options: [{ value: null, text: 'Block name' }]},
         {field: 'update_datetime', control: 'date', value: null, value2: null, placeholder: 'From update_time', placeholder2: 'To update_time'},
      ]
    }
  },
  watch: {
    v_chart: function() {
        if(this.v_chart){
          setTimeout(()=>{
           // this.get_gis_check_list();
           this.facility_refresh();      
          }, 200)

        }
    }, 
    gis_type(){
      this.facility_refresh();
    },
    add_layer_name() {
      this.$refs.map.edit_change(this.edit_mode, this.add_layer_name)
    },
    edit_mode() {
      this.$refs.map.edit_change(this.edit_mode, this.add_layer_name)
    },
    map_mode() {
      if(this.map_mode==='edit') {
        if(this.layer_all_visible){
          this.visible_edit = true
          this.$refs.map.edit_start(this.edit_mode, this.add_layer_name)
        }else{
          alert(this.$t('home.alert_text_no_gisload')) ;
          this.$refs.map.select_info = this.map_mode==='info'
        }
      }
      else {

        this.$refs.map.select_info = this.map_mode==='info'
        this.visible_edit = false
        this.$bvToast.hide('edit');
        this.$refs.map.edit_cancel();
      }
    }
  },
  methods: {
    addr_search(){
      this.$refs.map.go_search(this.addr_value);
    },
    size_view(e) {
        if(e==='right') {
            this.v_chart= false ;
            this.msize = '12';
            this.tsize='0';

        }
        else if(e==='left') {
            this.v_chart= true ;
            this.msize = '7';
            this.tsize='5';

        }
        this.$refs.map.update_size()
    },  
     onRowClick(params) {
      //선택된 색상 보여지게
   //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
      if(this.clicked_b_row != null){
        this.clicked_b_row.bgColor = '';
    //  //  this.clicked_b_row.style.backgroundColor = '';
      }
    
      if(params.event.target.nodeName==='TD'){
          params.event.target.parentElement.bgColor  = '#125744';          
          this.clicked_b_row = params.event.target.parentElement ;
      }else  if(params.event.target.nodeName==='IMG'){
           params.event.target.parentElement.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
      }else{
          params.event.target.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement ;
      }  
     },   
    Enter_Check1(){
      this.addr_search();
    },
    toggle_visible_all_layer() {

      this.layer_all_visible = !this.layer_all_visible
      let map = this.$refs.map.map
      for(let i=1; i<map.getLayers().getLength(); i++) {
        let layer = map.getLayers().item(i)
        if(layer.get('code')>50) continue
        layer.setVisible(this.layer_all_visible)
      }
    },
    change_map_mode(){
      this.map_mode = 'select'
      this.visible_edit = false
    },
    set_map_gisload() {

      let map = this.$refs.map.map;
      map.layer_all_visible = true ;
      this.layer_all_visible = true;
      var size1 = map.getSize();
     //var size = [size1[0]*2, size1[1]*2] ;
      var size = [size1[0], size1[1]] ;
      //   if( (this.new_add_fa%2) === 1 ){
      //     size =  [size1[0]*3, size1[1]*3] ;
      //   }
      //    map.updateSize();
      var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
      let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
      var extent = map.getView().calculateExtent(size);

      if(this.$refs.map.map.getView().getZoom() < cz){
        alert(this.$t('home.alert_zoom_in')) ;
      }else{
        // 추가 되었던 소스 가져와서 삭제하기
        // heat layer 삭제

        var h_layer = this.$refs.map.heat_layer ;
        if(h_layer != null) {
          var source_h = h_layer.getSource();
          var hfeatures = source_h.getFeatures();
          for(var k=0; k< hfeatures.length ; k++) {
            source_h.removeFeature(hfeatures[k]);
          }
          map.removeLayer(h_layer) ;
          h_layer = null;
        }
        // remove_layer(map);
        for(let i=0; i<this.$refs.map.layer_list.length; i++) {
          var layer = this.$refs.map.layer_list[i];
          if(layer=== null || layer.values_ === null) continue
          var source1 = layer.getSource();
          var features = source1.getFeatures();
          for(var j=0; j< features.length ; j++) {
            source1.removeFeature(features[j]);
          }
          map.removeLayer(layer) ;
        }
        this.$refs.map.layer_list = [] ;
        for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

          var params = this.$refs.map.layer_setting_stlye_list[i];
          // var nstyle = params.style;
          var _url=params.surl;
          // if(params.code >50 || layer.get('code')=== undefined ) continue

          var lftaye = params.f_type;

          _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

          var newlayer = CreateVectorLayer_f(params,extent) ;
          var vis = params.visible ;
          newlayer.setVisible(vis);
          this.$refs.map.layer_list.push(newlayer) ;
          map.addLayer(newlayer);


        } //for문 끝
        this.$refs.map.init_draw_modify();
      } // zoom크기에 대한 if


    },
    edit_ok() {
      let writer = new GeoJSON();
      //좌표값 변환
      for(let i=0; i<this.$refs.map.add_modify_features.length; i++) {
        let item = this.$refs.map.add_modify_features[i]
        item.feature = JSON.parse(writer.writeFeature(item.feature))

        if(item.code===1 || item.code ===2 ||  item.code ===3 ){ //point일 경우
          var cod_point = item.feature.geometry.coordinates ;
          item.feature.geometry.coordinates = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', cod_point)

        }else if(item.code===7){
          var cod_block = item.feature.geometry.coordinates ;

          for(let k=0; k<cod_block.length; k++) {//블럭갯수
            var chang_block_d=cod_block[k] ;
            for(let l=0; l<chang_block_d.length; l++) { //블럭안에 point갯수
              chang_block_d[l] = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', chang_block_d[l])
            }
            cod_block[k] = chang_block_d ;
          }
        }else{
          for(let j=0; j<item.feature.geometry.coordinates.length; j++) {
            var cod = item.feature.geometry.coordinates[j] ;
            item.feature.geometry.coordinates[j] = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', cod)
          }
        }

      }
      //db에 저장
      if(this.$refs.map.add_modify_features.length>0) {
        this.$http.get(this.URL+'api/add_modify_features?json='+encodeURIComponent(JSON.stringify(this.$refs.map.add_modify_features))).then(() => {
          let lyr = this.get_edit_layers()
          for(let i=0; i<lyr.length; i++) {
            lyr[i].getSource().clear()
            lyr[i].getSource().refresh()
          }
        })
      }

      this.map_mode = 'select'
      this.$bvToast.hide('edit')
      this.visible_edit = false
      this.$refs.map.edit_end()
    },
    edit_cancel() {
      this.map_mode = 'select'
      this.visible_edit = false
      this.$refs.map.edit_end();
      /*
      let lyr = this.get_edit_layers()
      for(let i=0; i<lyr.length; i++) {
          lyr[i].getSource().clear()
          lyr[i].getSource().refresh()
      }
      */
    },

    zoom(e) {
      var z = this.$refs.map.map.getView().getZoom() ;
      if(e==='+'){
        this.$refs.map.map.getView().setZoom(z+1);
      }else{
        this.$refs.map.map.getView().setZoom(z-1);
      }

    },
    zoom_fit() {
      this.$refs.map.set_fit_extent(this.$store.setting.extent)
    },
    delete_feature() {
      this.$refs.map.delete_feature()
    },
    get_edit_layers() {
      let lyrs = this.$refs.map.get_layers()
      let ll = []
      for(let i=1; i<lyrs.length; i++) {
        if(parseInt(lyrs[i].get('code'))>=13) continue
        ll.push(lyrs[i])
      }
      return ll
    },
    go_home() {
      this.$router.push({path: '/', query: { extent: this.$refs.map.get_view_extent() }})
    },
    go_gischeck() {
      //this.$router.push({path: '/gis-topologycheck', query: { extent: this.$refs.map.get_view_extent() }})
        if(this.$refs.facility_list.rows.length < 1){
          alert(this.$t('gis.gis_check_alert'))
          return;
        } 
      this.$router.push({path: '/gis-topologycheck', query: { f_idx: this.$refs.facility_list.rows[0].id }})
    },
    on_goto_map_click(row) {
    
      this.$refs.map.get_extent('f_idx='+row.id).then((data)=>{
        this.$refs.map.set_fit_extent(data, ()=>{
          this.$refs.map.select_facility_feature(row.gis_layer_id, row.type, row.id)
        })
      })

    },
    on_facility_info(row) {

      this.$refs.map.show_facility_info(row.id)
    },
    set_status(e) {
      let rows = this.$refs.facility_list.getSelectRows()
      if(rows.length<=0) return;
      let list = []
      for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
      this.$http.get(this.URL+'api/set_facility_status?f_status='+e+'&f_idx='+list).then(() => {
        this.$refs.facility_list.loadItems()
      })
    },
    facility_refresh(){
      // this.$bvToast.hide('edit') ;
      //this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id="+sessionStorage.customer_id);
        if(sessionStorage.customer_id === "1"){
        if(sessionStorage.select_customer_id === "27"){
          this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id=1&layer_type="+this.gis_type);
        }else{
          this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id="+sessionStorage.select_customer_id+"&layer_type="+this.gis_type);
        }
      }      
      else   
        this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id="+sessionStorage.customer_id+"&layer_type="+this.gis_type);


      this.init_mode();
      /*
      if(a===0){
        ++this.new_add_fa;
        this.set_map_gisload()
      }
      */
      //  this.gis_reload();
    },
    gis_reload(){
      this.edit_cancel() ;
      this.$bvToast.hide('edit') ;
      this.set_map_gisload() ;
    },
    init_mode(){
      this.edit_cancel() ;
      this.$bvToast.hide('edit') ;
    },
    /*
    size_view(e) {
      if(e==='up') {
        this.table_height += 100
        this.map_height -= 100
      }
      else if(e==='down') {
        this.table_height -= 100
        this.map_height += 100
      }
      setTimeout(() => this.$refs.map.map.updateSize(), 10)
    },
    */
    search_item_change(e, index) {
      if(index==0) {
        if(this.adv_search[0].value===null) {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [{ value: null, text: 'Facility Type' }];
        }
        else if(this.adv_search[0].value==='0') {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [
            { value: null, text: 'Pipe Type' },
            { value: 'Service', text: 'Service' },
            { value: 'Supply', text: 'Supply' },
            { value: 'Drain', text: 'Drain' },
            { value: 'Other', text: 'Other' }
          ]
        }
        else if(this.adv_search[0].value==='1') {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [
            { value: null, text: 'Valve Type' },
            { value: 'PRV', text: 'PRV' },
            { value: 'Control', text: 'Control' },
            { value: 'Drain', text: 'Drain' },
            { value: 'Boundary', text: 'Boundary' },
            { value: 'Relief', text: 'Relief' },
            { value: 'Other', text: 'Other' }
          ]
        }
        else if(this.adv_search[0].value==='2') {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [
            { value: null, text: 'Water Meter Type' },
            { value: 'Mechanical', text: 'Mechanical' },
            { value: 'Digital', text: 'Digital' },
            { value: 'Other', text: 'Other' }
          ]
        }
        else if(this.adv_search[0].value==='7') {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [
            { value: null, text: 'Block Type' },
            { value: 'Big', text: 'Big' },
            { value: 'Middle', text: 'Middle' },
            { value: 'Small', text: 'Small' },
            { value: 'Sub', text: 'Sub' }
          ]
        }
        else {
          this.adv_search[1].value = null;
          this.adv_search[1].options = [ { value: null, text: '' } ]
        }
      }
    }
  },
  created () {
  },
  mounted () {
    this.contents_height = window.innerHeight - 120
    this.map_height = this.contents_height
    this.table_height = Number(this.contents_height-210)
    setTimeout(()=>{
    var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
    if( elem !== undefined ){
      if(elem.length > 0){
          document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
      }
    }
    }, 1000)
    if(sessionStorage.customer_id === "1"){
      if(sessionStorage.select_customer_id === "27"){
        this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id=1");
      }else{
        this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id="+sessionStorage.select_customer_id);
      }
    }
    else   
      this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex?customer_id="+sessionStorage.customer_id);
    this.$refs.map.update_size()
  },
  beforeDestroy () {
    //console.log('GITSMaster destroyed!!')
    //console.log(this.$refs.map);
  },
  components: {
    ServerSideTable1: ServerSideTable1,
    Map: Map,
    UploadShape: () => import('@/components/popup/UploadShape'),
  }
}


</script>
<style scoped lang="scss">
// 2022 new 추가
@import '../../assets/scss/base/variables';
// 2022 new 추가
.status.m1-map.d-flex > div:not(:last-child) {
  // 2023
  // margin-right: 10px;
}

.map-button.icon_btn {
  font-size: 14px;
  line-height: 1;
  margin-right: 6px;
}
.map-button.icon_btn.icon_btn_text {
  font-size: 12px;
}
.icon-button-group ::v-deep .btn-secondary {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  float: left;
  margin-right: 6px;
  font-size: 14px;
  line-height: 23px;
  background: rgba(35, 41, 47, 0.8) !important;
  border-color: transparent !important;
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.icon_btn.btn-secondary {
  background: rgba(35, 41, 47, 0.8);
  border-color: transparent;
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.btn-map-view {
  background: rgba(35, 41, 47, 0.8);
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.map-zoom {
  font-size: 14px;
  float: left;
  margin-right: 10px;
  margin-bottom: 0;
  margin-top: 5px;
}
.btn-toolbar {
  margin-right: 0;
}
.gis-master-table-box {
  background: #f2f2f2;
  padding-bottom: 0;
}
.search_div{
    position: absolute;
    left:460px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}
.list_box.list_box2.gis_master{
  padding: 1em 1em 1em 1em;
  height:calc(100vh - 70px - 1rem - 70px - 40px );
  // background: #fff;
  background: $color-content-bg;
}
.info_box.gis_master {
  width: 100%;
  height: 70px;
    padding: 1em 1em 0 1em;
    // background-color: #fff;
    background-color: $color-content-bg;
    // margin-bottom: 7px;
    // font-size:15pt;X
    font-size:16pt;
}
// 2022 new
.info_box.gis_master {
  .status {
    // 2023
    float: left;
  display: inline-block;
  margin-top: 5px;
  border-radius: 10px;
  overflow: hidden;    

  input[type=radio] {
    position: absolute;
    visibility: hidden;
    display: none;  
    &:checked + label {
      color: $color-white;
      background: $color-primary;
    }        
  }
}
label {
    color: $color-white;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 20px;
    border: 1px solid $color-primary;
    border-radius: 22px;
  } 
}
.mb-0 {
  color:#fff;
}
</style>

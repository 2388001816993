<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
<!--                        <li class="breadcrumb-title">Map Frequency Comparison</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/leak-recovery')" href="#">{{$t('leak_recovery.page_title')}}</a></li>
                        <!--li class="breadcrumb-item"><a @click="$router.push('/leak-recovery')" href="#">{{$t('leak_recovery.sub_page')}}</a></li-->
                        <li class="breadcrumb-item active">{{$t('leak_recovery.map_page')}}</li>
                    </ol>
                </li>
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div>
             <b-row style="margin: 0;">
                <b-col :cols="msize" style="padding: 0 10px 0 0;">
                    <!--div class="search_div">

                        <span class="spn1"><b-input size="sm" type="text" placeholder="Please enter your address" v-model="value"  @keyup.enter ="Enter_Check"/></span>
                        <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="go_search">search</button></span>
                    </div-->
                    <div style=" margin : 0px; padding-right: -5px;"  >
                    <div :style="{height: contents_height+'px'}">

                     <Map
                         ref="map"
                         :widx="$route.query.widx"
                         w_type="140"
                         :visible_gisload_button="true"
                         :visible_size_side_view_button="true"
                         :leak_l ="false"
                         :recovery_icon = "true" 
                        @set_map_gisload="set_map_gisload"
                        @set_info="set_info"
                        @toggle_visible_all_layer = "toggle_visible_all_layer"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @size_view="size_view"
                        @onLeakMapClick="onLeakMapClick()"
                     />
                      </div>
                     </div>
                 </b-col>
                 <b-col  v-if="v_chart" :cols="tsize" style=" padding: 0px 2px 0 0; " >
              
               
                   <b-row style=" padding-left: 0px; background-color:#172a46; margin: 0px 10px 0 0px;" >
                       <b-col style="padding: 30px; ">
 
                        <div class="facility-info1" >
                            <b-row>
                              <b-col sm="3">
                                <label>{{$t('work_info.no')}}</label>
                             </b-col>
                             <b-col sm="3">
                                 <b-input size="sm" v-model="properties['id']" disabled></b-input>
                             </b-col>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.columns.date')}}</label>
                                </b-col>
                                <b-col sm="3">                                  

                                    <b-input  class="facility__input" size="sm" v-model="properties['register_datetime']" disabled></b-input>

                                </b-col>

                            </b-row>
                            <b-row>
                                 <b-col sm="3">
                                    <label class="facility__label">Work {{$t('work_info.title')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['w_title']" disabled ></b-input>
                                </b-col>
                                <b-col sm="3">
                                    <label class="facility__label">Work {{$t('work_info.contents')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['w_contents']" disabled ></b-input>

                                </b-col>                           
                            </b-row>                            
                            <b-row>
                                 <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.columns.title')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['title']" disabled ></b-input>
                                </b-col>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.columns.contents')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['contents']" disabled ></b-input>

                                </b-col>                           
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('facility_info.type')}}</label>
                                </b-col>
                                <b-col sm="3">
                                   <b-select class="facility__input detail" size="sm" 
                                    v-model="properties['type']" :options="f_type_option" ></b-select>

                                </b-col>

                            
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.f_name')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['layer_type']" disabled ></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.f_no')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['f_idx']" disabled ></b-input>
                                </b-col>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('leak_recovery.columns.worker')}}</label>
                                </b-col>
                                <b-col sm="3">

                                    <b-input  class="facility__input" size="sm" v-model="properties['worker']" disabled ></b-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('facility_info.remarks')}}</label>
                                </b-col>
                                <b-col sm="9">

                                    <b-input  class="facility__input" size="sm" v-model="properties['remarks']" disabled ></b-input>

                                </b-col>                                
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label class="facility__label">{{$t('facility_info.status')}}</label>
                                </b-col>
                                <b-col sm="3">

                                     <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" ></b-select>

                                </b-col>                                
                            </b-row>
                        </div>  
 
 
                       </b-col>
                   </b-row>
                   <b-row style="  padding-left: 0px;background-color:#172a46; margin: 10px 0px 0 0px;">
                       <b-col style="padding: 0px 6px 0 0px;overflow-y:auto;height:calc(60vh - 65px);">
                    
                     <!--div style="overflow:auto; height:200px;width:680px;white-space: nowrap; text-align : margin : 0 auto ; " >
                       <p v-for="(item,i) in img_list" :key="i"  style="float:left;">
                          <b-img thumbnail fluid :src="item" alt="image" bottom  style=" width:220px; "></b-img>
                       </p>     
                    </div-->
                          <p v-for="(item,i) in img_list" :key="i" style="float:left;width:33.3%;height:100%;margin:0px;">
                                    <b-card
                                        no-body
                                        img-top
                                        class="photo-card"
                                    >
                                        <b-img thumbnail fluid :src="item" alt="image" bottom style="height: 270px;" ></b-img>
                                        <!--<b-img thumbnail fluid src="http://localhost:3000/api/get_img/leakage/6/1" alt="Image" bottom></b-img>-->
                                    </b-card>      
                          </p>          
                       </b-col>
                   </b-row>
                 </b-col>
            </b-row>
         </div>

    </div>
</template>

<script>

import Map from '@/components/Map_model';
import {CreateVectorLayer_f} from '@/gis.js';
//import WaveSurfer from 'wavesurfer.js';
//import FFTChart1_c from '@/components/FFTChart1_c';
//import pressChartData_fft from '@/components/pressChartData_fft';

export default {
    name: 'MaintenanceMap',
    props: {
    },
    data() {
        return {
            properties: [],
            properties_bak: [],
            v_chart : true,
            image_yn:true,
            contents_height: 600,
            waveform_height: 42,
            fftchart_height: 180,
            wavesurfer: null,
            layer_all_visible: true,   
            fft_url1: '',
            lidx1 :'',
            wav1 : '',
            options: {
            },
            file: ' ' ,
            msize:'7',
            tsize:'5',
    
            f_type_option: [
                { value: 0, text: 'Other' },
                { value: 1, text: 'Water Quality' },
                { value: 2, text: 'Pressure' },
                { value: 3, text: 'Stop' },//단수
                { value: 4, text: 'Price' },//단수
                { value: 5, text: 'Leak' },//단수
            ],
            layer_type_option: [
                        {value: 0, text: 'Pipe'},
                        {value: 1, text: 'Valve'},
                        {value: 2, text: 'Water Meter'},
                        {value: 3, text: 'Pump'},
                        {value: 4, text: 'Hydrant'},
                        {value: 7, text: 'Block'},
                        {value: 8, text: 'Water Treatment Plant'},
                        {value: 9, text: 'Pumping Station'},
                        {value: 10, text: 'Water Basin'},
                        {value: 11, text: 'Chamber'},
                        {value: 12, text: 'Gauge'},
                        {value: 13, text: 'Other'},
                        ],
         
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            f_satus_level : true,
            material_option: [],   
            slice_url:'',
            szoom:50,
            img_list: [require('@/assets/noimage1.png'),require('@/assets/noimage1.png'),require('@/assets/noimage1.png')],            
            vchart: 0,          
        }
    },
    methods: {
        toggle_visible_all_layer() {
          this.$refs.map.init_reload_layer() ;
        },

        fn_press_han(event)
        {
                var objTarget = event.srcElement || event.target;
            // if(objTarget.type == 'text') {
            
            var value = objTarget.value;
            //alert(value);
            if(isNaN(value)){
                objTarget.value =value.replace(/[^0-9]/g,'');
                // alert('Please enter only numbers');
                // event.preventDefault();          
            }        
        },                  
        search(aurl){
            this.$http.get(aurl, {
                  headers: {
                       'Authorization': 'KakaoAK 47347cfe6ea2ca698e3266af393511a1'
                    }
            }).then(response => {
                console.log('response', response)
                if(response.data.meta.total_count < 1 ){
                    if(aurl.indexOf("https://dapi.kakao.com/v2/local/search/keyword.json") !== -1)
                    alert("There is no data you are looking for.")
                    this.s_data = 0;
                     return
                }
                var posx = Number(response.data.documents[0].x);
                var posy = Number(response.data.documents[0].y);
                let data = [posx ,posy, posx, posy]

                this.$refs.map.set_fit_extent(data)     
                if(this.$store.setting.map_name==='Daum')
                this.$refs.map.map.getView().setZoom(13);
                else
                this.$refs.map.map.getView().setZoom(18);          
                this.s_data = 1;

            })

        },
 
        on_leakage_info(idx) {
          this.$refs.map.show_leakage_info_pop(idx)
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        on_goto_map_click(idx) {
            this.$refs.map.get_extent('w_type=100&l_idx='+idx).then((data)=>{
                this.$refs.map.set_fit_extent(data)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
        },
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                     if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    this.$refs.map.layer_list.push(newlayer) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);

                } //for문 끝

           } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },

        onLeakMapClick(){
            let leak_idx = this.$refs.map.leak_idx;
         
            //leak_info를 쿼리해서 그 데이터를 wav,fft에 앉힌다.
            this.lidx1 = leak_idx;
            //이미지 가져오기
            //facility info정보가져오기
            this.row_detail(leak_idx)
        },
        size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
        },
        row_detail(id){
            this.properties = {} ;
            // var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
            this.img_list =[];
            
            // this.$http.get(this.URL+'api/get_press_info?idx='+idx).then((res) => {
            this.$http.get(this.URL+'api/get_maintenance_info?idx='+id).then((res) => {
                
                if(res.data.length<=0) return           

                this.properties = res.data.list[0]; 
            
            if(res.data.list[0].image_list === null || res.data.list[0].image_list === undefined ){

                this.img_list.push(require('@/assets/noimage1.png')) 
                // this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
                this.img_list.push(require('@/assets/noimage1.png'));
                this.img_list.push(require('@/assets/noimage1.png'));
                
                }else{
                var img = res.data.list[0].image_list.split(',')
                for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                }
            
                
                }
                this.properties_bak = Object.assign({}, this.properties)

            })
        },

        window_resiz() {
            this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 70

            this.map_height = this.contents_height/2
            //this.fftchart_height = this.map_height /2
            //this.waveform_height = this.fftchart_height - 100

            this.$refs.map.update_size()
        },

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.window_resiz)
    },
    mounted () {
        window.addEventListener('resize', this.window_resiz)    
       this.window_resiz()
       setTimeout(()=>{
            this.toggle_visible_all_layer();   
       }, 1000)  
  
    
    },
    watch: {
      v_chart: function() {
          if(this.v_chart){
               // document.getElementById("hidden-container1").style.display = 'block';
              //  document.getElementById("hidden-container2").style.display = 'block';
              //  document.getElementById("hidden-container3").style.display = 'block';
           

          }
      },
      vchart() {
     
         var c_url = this.fft_url1.split('#') ;
         this.fft_url1 = c_url[0]+'#'+this.vchart ;
      }
    },
    components: {
        Map: Map,
      //  FFTChart1_c: FFTChart1_c,
      //  pressChartData_fft: pressChartData_fft,
    }
}
</script>

<style lang="scss">
.infoimg{
    width: 24px;
}
.img{
    width:33%;
}
.search_div{
    position: absolute;
    left:360px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}
.map_left{  
  height:calc(100vh - 75px - 27px - 8px - 0px);
  /*overflow-y: scroll;*/
}
//.facility-info1{
//    height: 310px;
  //  overflow-y: auto; 
 //   overflow-x: hidden;

//}
.facility__input.custom-select{
  background: #0A192F;
  color:  #707070;
  border: 1px solid #707070;
}
</style>

<template>
    <div>
        <b-modal
            ref="modal"
            title="Hydraulic Info"
            >
            <b-tabs  v-if="properties!==null" align="center">
            <b-tab :title="$t('m2_point_setting.tab_title1')" active>  
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>
       
                    <b-row v-show="false">
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.customer')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['customer_name']" disabled></b-input>
                        </b-col>
                    </b-row>
                  <b-row>
                        <b-col sm="4">
                            <label>Hydraulic Point Name</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_name']" ></b-input>
                        </b-col>
                    </b-row> 
                
                   <b-row>
                        <b-col sm="4">
                            <label>{{$t('pressure.group_name')}}</label>
                        </b-col>
                        <b-col sm="8">                            
                            <b-select  size="sm" v-model="properties['group_id']" :options="group_name" style="margin-bottom:5px;" ></b-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>root_id</label>
                        </b-col>
                        <b-col sm="7">
                            <b-input size="sm" v-model="properties['root_id']" disabled></b-input>
                        </b-col>
                        
                   
                       
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>branch_id</label>
                        </b-col>
                        <b-col sm="7">
                            <b-input size="sm" v-model="properties['branch_id']" disabled></b-input>
                        </b-col>
                         <b-col sm="1">
                        <span  style="position:absolute;right:0px;top:-20px;" v-if ="info_u_YN">
                           <b-button class="mr-2 map-button icon_btn" size="sm" @click="change_root_id" >
                            <i class='fas fa-arrows-alt-v' style='font-size:24px'></i>
                           </b-button> 
                         </span> 
                         </b-col> 
                    </b-row>
              
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.p_addr')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_address']" ></b-input>
                        </b-col>
                    </b-row>   

         
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.p_pos')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_location']" disabled></b-input>
                        </b-col>
                    </b-row>                              
                   
                    <b-row>
                        <b-col sm="4">
                            <label>Distance(m)</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['branch_length']" ></b-input>
                        </b-col>
                    </b-row>                     
                  
        
         
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.desc')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['point_desc']" ></b-input>
                        </b-col>
                    </b-row>  
             
                     <b-row>
                        <b-col sm="4">
                            <label>Boundary Condition</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['mnp_boundary']"   oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" ></b-input>
                        </b-col>
                    </b-row>                                                                                                                                                                                
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.c_date')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['register_date']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label>{{$t('m2_point_setting.worker')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input size="sm" v-model="properties['worker']" disabled></b-input>
                        </b-col>
                    </b-row>                    
                    <b-row>
                        <b-col sm="4">
                            <label >{{$t('facility_info.status')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select  size="sm" v-model="properties['point_status']" :options="f_status_option" ></b-select>
                        </b-col>
                    </b-row> 
                                        
                </b-tab> 
                <!--
                <b-tab :title="$t('m2_point_setting.tab_title4')">
              
                    <p v-for="(item,i) in img_list" :key="i">
                                    <b-card
                                        no-body
                                        img-top
                                        class="photo-card"
                                    >
                                        <b-img thumbnail fluid :src="item" alt="image" bottom ></b-img>
                                     
                                    </b-card>      
                    </p>                    
                </b-tab>              
                -->
            </b-tabs>

            <template v-slot:modal-footer="{ ok, cancel, hide }">
              
               <b-row class="modal-footer-row m-0 leak_foot">
                    <b-col sm="6">
                      <b-button @click="cancel1" block class="modal-footer__button modal-footer__button-cancel">{{$t('button.close')}}</b-button>
                    </b-col>
                    <b-col sm="6">
                      <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('button.apply')}}</b-button>
                    </b-col>                 

              </b-row>
            </template>

        </b-modal>
    </div>
</template>

<script>
//import {getLength} from 'ol/sphere';
//import {Sphere} from 'ol/sphere';
import {transform} from 'ol/proj';
import {LineString } from 'ol/geom';
export default {
    name: 'DlgBlockInfo',
    props: {
    },
    watch:{
       

    },
    data() {
        return {
            properties: null,
            properties_bak: null,
            info_u_YN : true,
            geo_u_YN : false,
            ditem :{},
            columns: [
                {label: 'No', field: 'idx'},
                {label: 'Layer', field: 'layer'},
                {label: 'Title', field: 'w_title'},
                {label: 'Start Time', field: 'w_start_time'},
            ],
            //block안에 레이어 갯수 계산
            columns1: [
                {label: 'Layer', field: 'layer'},
                {label: 'Count', field: 'count'},
                {label: 'length', field: 'length'},
            ],        
            table1: [],    
            table: [],
            layer_type_option: [
                {value: 0, text: 'Pipe'},
                {value: 1, text: 'Valve'},
                {value: 2, text: 'Water Meter'},
                {value: 3, text: 'Pump'},
                {value: 4, text: 'Hydrant'},
                {value: 7, text: 'Block'},
                {value: 8, text: 'Water Treatment Plant'},
                {value: 9, text: 'Pumping Station'},
                {value: 10, text: 'Water Basin'},
                {value: 11, text: 'Chamber'},
                {value: 12, text: 'Gauge'},
                {value: 13, text: 'Other'},
            ],
            f_type_option: [],
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            material_option: [],
            img1:'',
            img2:'',
            img3:'',
            img_list: [],
            group_name : [{value:'', text :""}], 
        }
    },

    mounted () {
    },
    methods: {
        change_root_id(){

            this.properties['root_id'] =  this.properties_bak['branch_id']
            this.properties['branch_id'] =  this.properties_bak['root_id']
        },
        group_call(){
          this.group_name = [];   
          this.$http.get(this.URL+'api/waterpressure/groups?customer_id='+sessionStorage.select_customer_id).then((rows) => {
                if((!rows)||(rows.data.groups<=0)) return;
                
                for(let i=0; i<rows.data.groups.length; i++) {
                    if(i=== 0){
                        this.group_name.push({value:'', text :''}) ; 
                    }
                    this.group_name.push({value:rows.data.groups[i].groupId, text :rows.data.groups[i].groupName}) ; 
                    
                    
                }
            
            })    
        },
        show(idx) {
            //동수두 정보를 가져온다.
            this.group_call();
            this.info_u_YN = true;
             this.img_list =[];
            // this.$http.get(this.URL+'api/get_press_info?idx='+idx).then((res) => {
              this.$http.get(this.URL+'api/API21/hydraulic/'+idx).then((res) => {
                 
                if(res.data.results[0].length<=0) return           

                this.properties = res.data.results[0][0]; 
               // this.ele_data = this.properties['elevation'] 
              //  if(sessionStorage.m_id === this.properties.worker )
              //       this.myWorkItem = true
              /*
                if(res.data.results[0][0].image_list === null || res.data.results[0][0].image_list === undefined ){

                  this.img_list.push(require('@/assets/noimage1.png')) 
                 // this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
                  this.img_list.push(require('@/assets/noimage1.png'));
                  this.img_list.push(require('@/assets/noimage1.png'));
                  
                }else{
                  var img = res.data.results[0][0].image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
              
               
                }
               */ 
                //
               // for(let i=0; i<res.data.list[0].data.length; i++) {
               //   res.data.list[0].data[i].date = parseTime(res.data.list[0].data[i].date)
              //  }
               // this.draw_pressure_chart(res.data.list[0].data) ;
                this.properties_bak = Object.assign({}, this.properties)
                //watch bar_url 을 위하여 recount 추가
                //this.bar_url = this.URL+'api/'+res.data.list[0].psr_file_path+'&sensor='+ res.data.list[0].sensor+'&re='+(++this.recount)+'&retType='+this.vchart1     

             })
            this.$refs.modal.show();
    
        },
        distanceBetweenPoints(latlng1, latlng2){
            //var line = new LineString([latlng1, latlng2]);
            var line = new LineString([transform(latlng1, "EPSG:900913",'EPSG:4326'), transform(latlng2, "EPSG:900913",'EPSG:4326')]);
            return Math.round(line.getLength() * 100) / 100;
        },
        //동수두 위치를 저장한다.
        insert_show(item) {
            this.group_call();
            this.properties = {} ;
            this.img_list.push(require('@/assets/noimage1.png')) 
                 // this.img_list.push( this.URL+'api/get_img/leakage/2467/1')
            this.img_list.push(require('@/assets/noimage1.png'));
            this.img_list.push(require('@/assets/noimage1.png'));
          //  let ltype = item.code; 
            this.geo_u_YN = true;
            this.ditem = null;
            if(item.features=== null) return
         
            let geo = item.geometry.coordinates          
            alert(geo);
           
            if(item.type==="add"){ //추가
                this.info_u_YN = false ;
                this.properties = item.params;
                this.properties['customer_id'] = sessionStorage.select_customer_id ;
                this.properties['customer_member_id'] = sessionStorage.m_idx ;
               
              
                //let date = new Date();
                //let yy = date.getFullYear() ;
                //this.properties['f_register_date'] = yy +"-"+("0"+(date.getMonth()+1)).slice(-2)+"-"+("0"+date.getDate()).slice(-2) ;
                           //시설정보 테이블에 데이터    
                this.properties['point_status'] = 'A' ;
                                                
            }


            //데이터 변환한다.
            //this.change_xy(item);
            this.ditem = item;
            this.$refs.modal.show();           
        },        
        apply() {
           
          
            let params = {} ;

            if(this.info_u_YN){ //upate
                let keys = Object.keys(this.properties_bak)
                for(let i=0; i<keys.length; i++) {
                    if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue                    
                    if(keys[i] === "root_id" || keys[i] === "branch_id"){
                        var r_b_id = this.properties[keys[i]].split("-")
                        params[keys[i]] = r_b_id[0]
                    }else{
                        params[keys[i]] = this.properties[keys[i]]
                    }
                }

               
                this.ditem['idx'] = this.properties['id'];
                this.ditem['type'] = 'modify';
                this.ditem['params'] = params ;
                if(this.geo_u_YN){//지도수정          
                    this.$http.get(this.URL+'api/add_hydraulic_feature?m_id='+sessionStorage.m_id+'&json='+encodeURIComponent(JSON.stringify(this.ditem))).then((res) => {
                        console.log(res.data);
                   })  
                   //this.$emit('facility_refresh',0) ;                  
                }else{
                     if(Object.keys(params).length<=0) return
                     this.$http.get(this.URL+'api/add_hydraulic_feature?m_id='+sessionStorage.m_id+'&json='+encodeURIComponent(JSON.stringify(this.ditem))).then((res) => {
                        console.log(res.data);
                     })   
                   // this.$emit('facility_refresh',1) ;
                }

            }else{ //신규추가사항
                let keys = Object.keys(this.properties);
                for(let k=0 ; k < keys.length ; ++k) {
                    if(  this.properties[keys[k]]  !== null && this.properties[keys[k]].value !== '')  params[keys[k]] = this.properties[keys[k]] ;
                } 
                this.ditem['params'] = params ;
                 this.$http.get(this.URL+'api/add_hydraulic_feature?m_id='+sessionStorage.m_id+'&json='+encodeURIComponent(JSON.stringify(this.ditem))).then((res) => {
                    console.log(res.data);
                })
               // this.$emit('facility_refresh',0) ;
            }
/*
            let cur_page = ""+document.location.href ;         
            if(cur_page.indexOf("gis-master") !== -1) { 
                this.$emit('facility_refresh',1) ;
            }
*/            
            this.$refs.modal.hide(); 
       },
       cancel1(){
        //home =>gis_reload만
            if(this.geo_u_YN) { 
               // this.$emit('facility_refresh',2) ; //수정분 날려보낸다. 
               this.$emit('init_draw');               
            }
            this.$refs.modal.hide(); 
           
       }
    },
    components: {
    }
}
</script>

<style scoped>
</style>

<template>
  <div>
    <!-- top-bar -->
    <div ref="navbar" class="navbar-custom">
      <ul class="list-unstyled topnav-menu topnav-menu-left">
        <li>
          <ol class="breadcrumb">
            <!--  <li class="breadcrumb-title">Leak Detail</li>-->
            <!--  <li class="breadcrumb-bar">|</li>-->
            <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
            <li class="breadcrumb-item"><router-link to="/leak-master">Scada</router-link></li>
            <li class="breadcrumb-item active">{{$t('leak_detail.page_title')}}</li>
          </ol>
        </li>
      </ul>
    </div>
    <!-- tom-bar-end -->
    <div class="content-area row justify-content-around">
      <div class="left leak-detail" style="width:45%;" >
      <div class="ns-search-box" style=" margin-left: 20px;">
        <input type="text" class="ns-search-input" v-model="value" :placeholder="$t('water_leak.left_menu.search')"
               @keyup.enter="Enter_Check">
        <button type="button" class="ns-search-button" @click="go_search"/>
      </div>  

        <div class="lk-dt-tab table-button-group" style=" margin: -5% 0px 20px 66%;">
        <!--   <b-button variant="lk-middle lk-btn-left" @click="show_frequency_chart()"><i class="mdi mdi-pipe-leak mr-2"></i>LEAKAGE STRENGTH</b-button>
          <b-button variant="lk-middle border-left-line" @click="goRouter('/leak-analysis',-1)"><i class="mdi mdi-leak mr-2"></i>FREQUENCY COMPARISON</b-button>
         <b-button variant="lk-middle border-left-line" @click="$refs.leak_legend.show('set_leak_legend', $store.state.leak_legend);"><i class="mdi mdi-book-open mr-2"></i>LEGEND SETTING</b-button>-->
          <b-button class=" btn-grayLine"  @click="new_cs_upload()">파일등록</b-button>
          <b-button class=" btn-grayLine"  @click="add_yseries(0)">주측<i class="mdi mdi-arrow-right mr-2"></i></b-button>
          <b-button class=" btn-grayLine"  @click="add_yseries(1)">보조측<i class="mdi mdi-arrow-right mr-2"></i></b-button>
          
          <!--b-button v-if="levelA" variant="lk-middle border-left-line" @click="makeExcelFile4()"><i class="mdi mdi-delete mr-2"></i>make_excel</b-button-->
          
        </div>
       

        <div class="list_box list_box2">
          <div class="page_list">
            <b-row class="list_box list_box2 scada" >
              <b-col class="page_list">
              

                <h3>{{$t('csv_file_info.table_title')}}</h3> 
                <div class="table-box" id='table' >
                 <!-- <div style="position:absolute; top: 0; right:0;  ">
                      <download-excel
                        class   = "btn btn-default"
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        name    = "filename.xls">
                      <button  @click="go_download" class="btn btn-third ml-2"  > <i class="mdi mdi-download" v-b-tooltip.hover title='Excel Download'></i></button>
                    </download-excel> </div>-->
                  <!--<label for="textarea-small" class="table-box-text mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label>-->
                  <ServerSideTable ref="table_scada_info" 
                                   :columns="columns"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"                                   
                                   :select="true"
                                   :add_custom_column="true"
                                   :adv_search="adv_search"
                                   :search="admin_yn"
                                   :max_height="table_height+'px'"
                                   @on-row-click="onRowClick"                                  
                                   @on_info_click="on_leakage_info"
                  />

                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="right leak-detail" style="width:55%;" >
       
        <b-row style=" padding: 30px 0px 0px 20px ; "  >
          <b-col class="leak-detail-content" style="height: 270px;">
            <b-row >
              <b-col style="padding-top: 20px; " >
                <b-row>
                  <b-col sm=6>
                    <div class="ns-search-box" style="width: 100%;">
                     <input type="text" class="ns-search-input" v-model="yseries1" placeholder="주측 파일id"
                         >
                    </div>
                  </b-col>
                  <b-col style="    margin: 10px 0 0 -60px;">                   
                    <span @click="yseries_clear(1)" ><i class='fas fa-minus-circle fa-lg' style='color:#27b6ba' alt="Clear"></i></span>
                  </b-col> 
                  <b-col sm=6>
                    <div class="ns-search-box" style="width: 100%;">
                    <input type="text" class="ns-search-input" v-model="yseries2" placeholder="보조측 파일id"
                         >
                    </div>
                  </b-col>
                   <b-col style="    margin: 10px 0 0 -60px;">  
                    <span @click="yseries_clear(2)"  ><i class='fas fa-minus-circle fa-lg' style='color:#27b6ba;'></i></span>
                   
                  </b-col> 
                </b-row>
                <b-row>
                  <b-col sm=3> 
                    <div class="status leak" style="float:left;padding:0px ;">                 
                      <input type="radio" v-model="vtype" value="column" id="Bar"> <label for="Bar">Bar</label>
                      <input type="radio" v-model="vtype" value="scatter" id="Scatter"><label for="Scatter">Scatter</label>
                      <input type="radio" v-model="vtype" value="" id="Line"><label for="Line">Line</label>
                    </div>
                  </b-col>
                   <b-col sm=3> 
                  <div class="ns-search-box" style=" width:95%; margin: 10px;">
                        <input type="text" class="ns-search-input" v-model="x_series1_name" placeholder="X측 이름"
                         >
                    
                   </div>  
                   </b-col>
                   <b-col sm=3> 
                    <div class="ns-search-box" style=" width:98%; margin : 10px 0;">
                      
                     <input type="text" class="ns-search-input" v-model="y_series1_name" placeholder="Y측 이름"
                         >
                    </div> 
                   </b-col>
                   <b-col sm=3>  
                    <div class="ns-search-box" style="width:95%; margin: 10px ;">
                      
                     <input type="text" class="ns-search-input" v-model="y_series2_name" placeholder="보조측 이름"
                         >
                    </div> 
                   </b-col>
                </b-row>
                <b-row>
                  <b-col style="padding-right:0;" >
                    <div class="status leak" style="float:left;margin: 2px 0 0 5px;">                 
                      <input type="radio" v-model="vchart" value="0" id="Fixed"> <label for="Fixed" style="padding: 5px 10px;">Fixed</label>
                      <input type="radio" v-model="vchart" value="1" id="Propotional"><label for="Propotional" style="padding: 5px 10px;">Propotional</label>                   
                    </div>                   
                  </b-col>    
                  <b-col sm=3>
                    <b-row>
                      <b-col sm=3 style="padding:10px;"> Y측 : </b-col>
                      <b-col sm=3 class="ns-search-box" style="width:100px;height:40px;padding:10px;">
                         <input type="text" class="ns-search-input"  v-model="y_min1" placeholder="최소값">
                      </b-col>
                      <b-col sm=2 style="padding:10px 20px;"> ~ </b-col>
                      <b-col sm=3 class="ns-search-box" style="width:100px;height:40px;padding:10px;">     
                        <input type="text" class="ns-search-input" v-model="y_max1" placeholder="최대값">
                      </b-col>
                    </b-row>
                  </b-col> 
                  <b-col sm=3>
                    <b-row>
                      <b-col sm=3 style="padding:10px 0;"> 보조측 : </b-col>
                      <b-col sm=3 class="ns-search-box" style="width:100px;height:40px;padding:10px;">
                         <input type="text" class="ns-search-input" style="" v-model="y_min2" placeholder="최소값">
                      </b-col>
                      <b-col sm=2 style="padding:10px 20px;"> ~ </b-col>
                      <b-col sm=3 class="ns-search-box" style="width:100px;height:40px;padding:10px;">     
                          <input type="text" class="ns-search-input" v-model="y_max2" placeholder="최대값">
                       </b-col>
                    </b-row>
                  </b-col> 
                  
                  <b-col sm=4>
                    <b-row>
                      <b-col sm=2 style="padding:10px;"> x측 : </b-col>
                      <b-col sm=3 class="ns-search-box" style="width:100px;height:40px;padding:10px;">
                         <input type="text" class="ns-search-input"  v-model="x_min1" placeholder="최소값">
                      </b-col>
                      <b-col sm=2 style="padding:10px 20px;"> ~ </b-col>
                      <b-col sm=3 class="ns-search-box" style="width:100px;height:40px;padding:10px;">     
                      
                            <input type="text" class="ns-search-input" v-model="x_max1" placeholder="최대값">
                        
                      </b-col>
                    </b-row>
                  </b-col>
                  
                </b-row>
                <b-row style="float:right;margin-right: 35px;">
                   
                    <b-button class="btn-grayLine" style="" @click="draw_chart()">차트그리기</b-button>
                   
                   
                </b-row>  
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row  style=" padding: 5px 0px 0px 20px ; "  >
          <b-col class="leak-detail-content-map"  style='padding: 0;bottom: 0px;background: #172A46;'>
             <div :style="{height: map_height+'px'}" >
               <div  style='height:100%;margin-top:30px;'>
                   <scadaChartData :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:100%; overflow: auto;"  />
               </div>         
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    
    <UploadCsv ref="upload_csv"  @goRouter="goRouter" />
  </div>
</template>
   
<script>


import scadaChartData from '@/components/chart/scadaChartData';
import ServerSideTable from '@/components/table/ServerSideTable_ds';
import UploadCsv from '@/components/popup/UploadCsv';



export default {
  name: 'ScadaFileData',
  props: {
    // url
  },
  watch: {
    download_idx(){
       if(this.download_idx > this.download_arr.length -1 ) return
       var idx = this.download_arr[this.download_idx].id ;
      let fst = { "L": "L", "N": "N", "M": "M" };
      var fs = "N";
      if (this.download_arr[this.download_idx].ai_leak_result !== null) {
        fs = fst[this.download_arr[this.download_idx].ai_leak_result];
      }       
       let fname = idx+"_"+this.download_arr[this.download_idx].file_name.split('.')[0]+'_'+fs+".wav"       
       this.download(idx, fname)       
    },
    vchart() {
      //     if(this.vchart==='0') {
      //        alert("radio button : " + this.vchart) ;
      //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
      //     }
      this.$refs.fftchart.y_title = this.y_series1_name
       this.$refs.fftchart.y_title1 = this.y_series2_name
       this.$refs.fftchart.x_title = this.x_series1_name
       this.$refs.fftchart.vchart = this.vchart
       this.$refs.fftchart.chart_type = this.vtype
       //데이터의 최소값, 최대값 셋팅
       this.$refs.fftchart.y_min1 = this.y_min1
       this.$refs.fftchart.y_max1 = this.y_max1
       this.$refs.fftchart.y_min2 = this.y_min2
       this.$refs.fftchart.y_max2 = this.y_max2
      var c_url = this.fft_url.split('#') ;
      this.fft_url = c_url[0]+'#'+this.vchart ;
      // this.bandfilter = 0
     // this.low_max_hz = this.$refs.fftchart.low_max_hz;
     // this.high_max_hz = this.$refs.fftchart.high_max_hz;
    },
 
  },
  data() {
    return {
      layer_all_visible: false,
      leak_btn_str : this.$t('leak_detail.btn_leak'),
      dataset_btn_str : this.$t('leak_detail.btn_dataset'),      
      checkedNames: [],
      unchecked : true,
      a : 0, 
      s_min : 0, 
      s_max : 0, 
      band_hz : '-',
      admin_yn : sessionStorage.level < 2 ? true :false,
      contents_height: 800,
      waveform_height: 50,
      fftchart_height: 195,
      low_max_hz : 0,
      y_min1 : 0,
      y_max1 : 1000,
      x_min1 : 0,
      x_max1 : 0,
      y_min2 : 0,
      y_max2 : 1000,
      high_max_hz : 800,
      map_height: 400,
      table_height: 530,
      vchart : "1", //비율, 0 : 고정
      vtype : "", //column, scatter,""
      region_start:0,
      region_end:0,      
      szoom:50,
      y_series1 : '',
      y_series2 : '',
      x_series1_name: '',
      y_series1_name:'',
      y_series2_name:'',
      is_playing: false,
      value:'',
      addr_value :'',
      levelA : false,
      columns: [  
         {label: this.$t('leak_detail.table_column.info'), field: 'info',width: '5%', sortable: false},   
         {label: this.$t('csv_file_info.id'), field: 'id',width: '10%', sortable: false},
         {label: this.$t('csv_file_info.name'), field: 'name',width: '20%', sortable: false},
         {label: this.$t('csv_file_info.f_name'), field: 'file_name', width: '20%',sortable: false},
         {label: this.$t('csv_file_info.type'), field: 'time_type',width: '10%',}, 
         {label: this.$t('csv_file_info.data_count'), field: 'data_count',width: '15%',},
         {label: this.$t('csv_file_info.start_time'), field: 'start_time',width: '20%',},

      ],
     selected_ts_mnf: '',
      json_data :[],
      json_fields:{
        'Start Time': 'measure_start_datetime',
        'Work No': 'water_work_id',
        //'Customer_name' : 
        'Worker': 'm_id',
        //'Facility No': 'f_idx',
        'Facility Name': 'layer_type',
        //'X' : 'X',
        //'Y' : 'Y',               
        'Period(Sec)':'period',
        'Device No' : 'device_sn',
        'Image' : 'image',
        'Strength' : 'wave_energy',
        'Max Frequency(Hz)': 'wave_max_frequency',
        'file_name' : 'file_name',
        'Strength_cal': 'wave_energy_cal',
        'Leak Result': 'leak_result_',
        'AI Leak Result'    : 'ai_leak_result' ,
        'probability' : "probability",
        'Remarks': 'remarks'
      },

         adv_search: [
                 {
          field: "start_time",
          control: "date",
          value: null,
          value2: null,
          placeholder: "From start_time",
          placeholder2: "To start_time",
        },
   
        {
          field: "data_count",
          control: "range",
          value: null,
          value2: null,
          placeholder: "From Data Count",
          placeholder2: "To Data Count",
        },
     
        {
          field: "time_type",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "time_type" },
            { value: "D", text: "Day" },
            { value: "H", text: "Hour" },
            { value: "M", text: "Menutes" },
            { value: "S", text: "Second" },
          ],
        },
             
      ],
      filename : 'filename.xls',
      wavesurfer: null,
      fft_url: '',
      aurl:'',
      clicked_l_idx: -1,
      clicked_b_row:null,
      search : '',
      searchTerm: '',
      slice_url:'',
      download_idx: -1,
      download_arr: [],
      t_start_time : '',
      t_worker : '',
      t_device_no : '',
      t_ai_result: '  ',
      t_leak_result: '  ',
      t_strength: '',
      t_strength_cal: '',
      t_f_type: '',
      t_remark: '',
      yseries1 : [],
      yseries1_id : [],
      yseries2 : [],
      yseries2_id : [],
    }
  },
  methods: {
    yseries_clear(a){
      if(a===1){
         this.yseries1_id = []
         this.yseries1 = ""
         this.$refs.fftchart.yseries1_id =  []
          this.$refs.fftchart.yseries1 = this.yseries1
      }else{
         this.yseries2_id = []
         this.yseries2 = ""     
         this.$refs.fftchart.yseries2_id = []
         this.$refs.fftchart.yseries2 = this.yseries2
      }
    },
    add_yseries(a){
      let rows = this.$refs.table_scada_info.getSelectRows()
    
      if(rows.length<=0){
        alert(this.$t('leak_detail.alt_select'));   
      //  this.yseries1 = [] ;
        return;  
      } 

      //a == 0 : 주측, a==1 : 보조측
   
      var selected_id = [];
      var selected_name = [];
      
      for(let i=0; i<rows.length; i++) {                        
          selected_id.push(rows[i].id);
          selected_name += rows[i].name +"/"
          if( i === 4){ 
            alert(this.$t('water_leak.left_menu.alt_more_20')) ;
            break;
          }
          //체크되었던 row를 해제한다.
          
              //   });
      }
      if(a === 0){
         this.yseries1_id = selected_id
         this.yseries1 = selected_name
         this.$refs.fftchart.yseries1_id = selected_id
         this.$refs.fftchart.yseries1 = this.yseries1
      }else{
         this.yseries2_id = selected_id
         this.yseries2 = selected_name     
         this.$refs.fftchart.yseries2_id = selected_id
         this.$refs.fftchart.yseries2 = this.yseries2
      }
     this.$refs.table_scada_info.selec
    },
    toggle_visible_all_layer() {
      // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
      // strength_cal값을 가져와 지도에 보여준다.
      this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
      if(this.$refs.map.layer_all_visible)
        this.$refs.map.leak_cal = 1;
      else
        this.$refs.map.leak_cal = 0;
      
      //누수 레이어를 다시 그린다.
      this.$refs.map.init_reload_leak_layer() ;

      /*
        this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
        let map = this.$refs.map.map
        for(let i=1; i<map.getLayers().getLength()-1; i++) {
            let layer = map.getLayers().item(i)
            if(layer.get('code')===100)
                layer.setVisible(this.$refs.map.layer_all_visible)
        }
        */
    },
   toggle_visible_leak_type() {
      // 처음엔 leak type all 데이터를 가져와 지도에 보여준다.
      // All or LEAK 값을 가져와 지도에 보여준다.
      this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
      if(this.$refs.map.layer_leak_visible)
        this.$refs.map.leak_type = 'LEAK';
      else
        this.$refs.map.leak_type = null;
      
      //누수 레이어를 다시 그린다.
      this.$refs.map.init_reload_leak_layer() ;      
    },
   fn_press_han(event)
   {
      var objTarget = event.srcElement || event.target;
  // if(objTarget.type == 'text') {
    
    var value = objTarget.value;
    //alert(value);
    if(isNaN(value)){
      objTarget.value =value.replace(/[^0-9]/g,'');
      // alert('Please enter only numbers');
      // event.preventDefault();          
    }        
   },          
    addr_search(){
      this.$refs.map.go_search(this.addr_value);
    },
    Enter_Check1(){
      this.addr_search();
     },
     uncheckedAll (a) {
      
       // this.isChecked = true;        
             if (this.unchecked && a === "1") {
                this.checkedNames=[]
             }else{
               if(this.checkedNames.length > 0)
                  this.unchecked = false ;
                else
                  this.unchecked = true ;   
             }                    
    },
     change_selected_ts_mnf(e) {
          this.selected_ts_mnf = e
          this.go_search();
           /*
          this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_list_ex?w_idx="+this.$route.query.widx+'&search='+this.value+'&leak_result='+this.selected_ts_mnf, (rows) => {
            console.log(rows)
            if((!rows)||(rows.length<=0)) return;
            this.clicked_l_idx = rows[0].idx
            this.aurl = this.URL+'api/get_wav/'+rows[0].idx
            //  this.avwaveform.load(aurl)
            this.wavesurfer.load(this.aurl);
            this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
        })
        */
        //    this.get_time_series_mnf()
    },
    facility_invisible_layer() {

      this.layer_all_visible = false;
      let map = this.$refs.map.map
      for(let i=1; i<map.getLayers().getLength(); i++) {
        let layer = map.getLayers().item(i)
        if(layer.get('code')>50) continue;
        layer.setVisible(this.layer_all_visible)
      }
    },
    new_cs_upload() {
  
        this.$refs.upload_csv.insert_show();
     
    },
    update_leak_result() {
        // this.filename = 
        var leak_query = 0 ;
        if(this.leak_btn_str === this.$t('leak_detail.btn_leak')){
          leak_query = 1 ; //RT_LEAK
          this.leak_btn_str = this.$t('leak_detail.btn_no_leak')
        }else{
          this.leak_btn_str =this.$t('leak_detail.btn_leak')
        }
        this.permission_check();
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<1) {
           alert(this.$t('leak_detail.alt_select'));
           return;
        }

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        var con_test = confirm(this.$t('leak_detail.confirm_set_leak'));
       
        if(con_test){
          this.$http.get(this.URL+'api/update_work_leak?leak_result='+leak_query+'&lidx='+list).then((res) => {  
             console.log(res.data);
             this.leaktable_refresh();
          });

        }
    },
     update_dataset() {
        var dataset_query = 0 ;
        if(this.dataset_btn_str === this.$t('leak_detail.btn_dataset')){
          dataset_query = 1 ; //RT_LEAK
          this.dataset_btn_str = this.$t('leak_detail.btn_no_dataset')
        }else{
          this.dataset_btn_str = this.$t('leak_detail.btn_dataset')
        }
        this.permission_check();
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<1){
           alert(this.$t('leak_detail.alt_select'));
           return;
        }

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
       var con_test = confirm(this.$t('leak_detail.confirm_set_dataset'));
       
        if(con_test){
          this.$http.get(this.URL+'api/update_work_leak?dataset='+dataset_query+'&lidx='+list).then((res) => {  
            console.log(res.data);
              this.leaktable_refresh();
            });

        }
    },
    go_download() {
  //   this.filename = 
      this.filename = this.filename.replace(",","_")
      let rows = this.$refs.table_leakage_info.rows ;
      if(rows.length<=0) {
        alert(this.$t('leak_detail.alt_no_data'));
        return;
      }
      this.json_data = rows ;
      // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
      // this.$router.push({path: '/leak-detail', query: { widx: list }})
    },
    makeExcelFile4 () {
        let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">'
        tab_text += '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">'
        tab_text += '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
        tab_text += '<x:Name>Test Sheet</x:Name>'
        tab_text += '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>'
        tab_text += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>'
        tab_text += "<table>"
        var div1 = document.getElementById('table');
        var div1Paras = div1.getElementsByTagName('table');

        const temp = div1Paras[1].innerHTML
        // console.log(temp)
        tab_text += temp           
        tab_text += '</table></body></html>'
        console.log(tab_text)
        const fileName = 'exampleTable.xls'
        const a_tag = document.createElement('a')
        const blob = new Blob([tab_text], { type: 'application/vnd.ms-excel;charset=utf-8;' })
        a_tag.href = window.URL.createObjectURL(blob)
        a_tag.download = fileName
        a_tag.click()      
    },
    permission_check(){
       if(sessionStorage === null || typeof sessionStorage === undefined){
            alert(this.$t('leak_detail.alt_no_update'))
            return
   
        }else if(sessionStorage.level > 1){
            alert(this.$t('water_leak.alert_no_permission'))
            return;
        }
    },
    go_delete() {
        this.permission_check();
       
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<=0) {
          alert(this.$t('leak_detail.alt_no_data'));
          return;
        }
        
        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        var con_test = confirm(this.$t('water_leak.left_menu.confirm_msg_delete'));
        //let list = idx
        // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
        if(con_test){
          this.$http.get(this.URL+'api/delete_ex?l_idx_ex='+list).then((res) => {  
            console.log(res.data);
              this.leaktable_refresh();
            });

        }
     },
    go_home() {
      this.$router.push({path: '/leak-analysis1', query: { widx: this.$route.query.widx, w_type: '100' }})
    },
    download(idx, name) {
      this.$http({
        url: this.URL+'api/download/wav/'+idx,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);
        fileLink.click();
          //'Leak','Leak Inside','Usage','Other'
      
      
        setTimeout(()=>{
          ++this.download_idx; 
        }, 200)
      
      })

    },
    downloads() {
      let rows = this.$refs.table_leakage_info.getSelectRows()
      this.download_arr = rows ; 
      if(rows.length<=0){
        alert(this.$t('leak_detail.alt_select'));   
        this.download_arr = [] ;
        return;  
      } else{
          this.download_idx = 0;
      }  
    
    },
 
    goRouter(path, w_id) {
      
      let query = {}
      if(Number(w_id) === -1){
        if(path==='/') query['widx'] = this.$route.query.widx
        else if('/leak-analysis'===path) {
          let rows = this.$refs.table_leakage_info.getSelectRows()
          if(rows.length<=0) return;
          let lidx = []
          for(let i=0; i<rows.length; i++) lidx.push(rows[i].id)
          query['lidx'] = lidx
          query['widx'] = this.$route.query.widx
          this.$router.push({path: path, query: query})
        }
      }else{
        query['widx'] = w_id
        this.$router.push({path: '/leak-detail', query: query})
        this.$router.go();

      }
     
    },
    
    onLeakMapClick(){
      let leak_idx = this.$refs.map.leak_idx;
      this.clicked_l_idx = leak_idx;

      //LEAKAGE INFO값을 가져와서 FFT, WAV값을 뿌려준다.
      this.aurl = this.URL+'api/get_wav/'+leak_idx ;

      this.wavesurfer.load(this.aurl);
      this.fft_url = this.URL+"api/get_fft/"+leak_idx+'#'+this.vchart;
      this.bandfilter = 0

    },
    leaktable_refresh(){
      this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_list_ex?w_idx="+this.$route.query.widx, (rows) => {
        if((!rows)||(rows.length<=0)) return;
        this.clicked_l_idx = rows[0].id ;
        this.onTitleChange(rows[0]) ;
        this.filename = "widx-"+this.$route.query.widx+"-lidx-"+this.clicked_l_idx+"~.xls"
        //this.aurl =this.URL+'api/get_wav/'+rows[0].idx;
        this.aurl = this.URL+'api/'+rows[0].file_path ;
        this.wavesurfer.load(this.aurl);

        
      //  this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
        if(rows[0].fft_file_path !== null )
          this.fft_url = this.URL+'api/'+rows[0].fft_file_path+'#'+this.vchart;
        else 
          this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'#'+this.vchart;
          this.bandfilter = 0
      })
    },
    chart_download(){

    },
    draw_chart(){
      //x,y,y1의 라벨붙이기
       this.$refs.fftchart.y_title = this.y_series1_name
       this.$refs.fftchart.y_title1 = this.y_series2_name
       this.$refs.fftchart.x_title = this.x_series1_name
       this.$refs.fftchart.vchart = this.vchart
       this.$refs.fftchart.chart_type = this.vtype
       //데이터의 최소값, 최대값 셋팅
       this.$refs.fftchart.y_min1 = this.y_min1
       this.$refs.fftchart.y_max1 = this.y_max1
       this.$refs.fftchart.y_min2 = this.y_min2
       this.$refs.fftchart.y_max2 = this.y_max2
       this.$refs.fftchart.x_min1 = this.x_min1
       this.$refs.fftchart.x_max1 = this.x_max1
       //주측 데이터가 하나이상이어야 한다.
       if(this.yseries1_id.length > 0){
         ++this.a;
         this.fft_url = this.URL+'api/scada/get_csv_file_chart?id='+this.yseries1_id[0]+'#'+this.a;
       }

       
    },
    onRowClick(params) {
      //선택된 색상 보여지게
   //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
      if(this.clicked_b_row != null){
        this.clicked_b_row.bgColor = '';
     //  //  this.clicked_b_row.style.backgroundColor = '';
      }
    
      if(params.event.target.nodeName==='TD'){
          params.event.target.parentElement.bgColor  = '#125744';          
          this.clicked_b_row = params.event.target.parentElement ;
      }else  if(params.event.target.nodeName==='IMG'){
           params.event.target.parentElement.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
      }else{
          params.event.target.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement ;
      }
      


     

    },

 
   
    window_resiz() {
      this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 300

      this.map_height = this.contents_height*0.9 
      this.fftchart_height = this.map_height -50
      //this.waveform_height = this.fftchart_height - 100

      
    },



    on_leakage_info(id) {
      this.$refs.upload_csv.show(id);
      //this.$refs.map.show_leakage_info(row.id)
    },

    go_search() {
        if(this.value===undefined) return;

        this.box = [] ;       
       // if(this.checkedNames.length === 0 || this.checkedNames.length === 3) {
         if(this.checkedNames.length === 0 ){ 
          this.checkedNames=[]       
        }
      //    this.$http.get(this.URL+'api/get_work_list_ex?w_type=100&search_term='+value+'&per_page=100&page=1').then((rows) => {
        this.$refs.table_scada_info.setUrl(this.URL+"api/scada?customer_id="+this.$route.query.widx+'&search='+this.value, (rows) => {
          console.log(rows)
          if((!rows)||(rows.length<=0)) return;
          this.clicked_l_idx = rows[0].id
         
        
        })
     },
      Enter_Check(){
       //  if(event.keyCode == 13){
         this.go_search();  // 실행할 이벤트
     },


  },
  created () {
    this.$EventBus.$on('sidebar_resize', () => {
      setTimeout(()=>{
       // this.$refs.fftchart.redraw()
        if(this.clicked_l_idx>-1){
          //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
       

        }
      }, 500)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.window_resiz)
  },
  mounted () {
    window.addEventListener('resize', this.window_resiz)
    this.window_resiz()
    
//삭제버튼 활성화 여부
   // if(localStorage !== null && typeof (localStorage) !== undefined && localStorage.level === 'A'){
     if(sessionStorage !== null && typeof (sessionStorage) !== undefined && sessionStorage.level < 2){
       this.levelA = true       
     }else{
       this.levelA = false
    }
 
    

      this.$refs.table_scada_info.setUrl(this.URL+"api/scada?customer_id="+this.$route.query.widx+'&search='+this.value, (rows) => { 
        console.log(rows)

        if((!rows)||(rows.length<=0)) return;
        this.clicked_l_idx = rows[0].id
      
    })
    
    setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
               
               
           }
         }
     }, 1000)
    
  },
  components: {
  
    scadaChartData: scadaChartData,
    ServerSideTable: ServerSideTable,       
    UploadCsv:UploadCsv,
  }
}

</script>

<style lang="scss" scoped >
/*
    #waveform{
          background: rgba(0,0,0,0.8);
    }
*/
#waveform .audio {
  all: none;
  width: 300px;
  position :absolute;
  z-index:1;
}

.inner-bg {
  float : left;
  /* 공통 속성값 지정 */

  display: inline-block;
  /* 인라인요소를 인라인블록 요소로 */
  height: 30px;
  /* 이미지 공간을 확보하기 위해 높이값 지정 */

  background: #c0c0c0;
  padding-left:10px;
  padding-right: 10px;

}

.content-area {
  padding: 0 30px;
}
.leak-detail-content {
  padding: 10px 30px 0;
   // 2022 new
  // background: #fff;
  background: #172A46;
  border: 1px solid #172A46;
  color: #fff;
}
.leak-detail-content-map {
  padding: 0;
  bottom: 0;
}
.left .list_box.list_box2 .list_box.list_box2.scada {    
    min-height: 700px;
    height: calc(100vh - 50px - 70px - 20px - 37px);
}
.left .list_box.list_box2 {

  // 2022 new
  background: #172A46;
  color: #fff;
}
.table-button-group {  
    margin: 10px 0 10px 0;

}
.table-button-group .btn-lk-middle {
  width: 100%;
}
.left.leak-detail {
  height: auto;
}
table.type01 th {
  padding: 12px 0;
  background-color: aqua;
}
table.type01 td span {
  letter-spacing : -1px;
}
.btn-default {
  color: #fff;
}
.btn-default :hover{
  color: #000;
}
i {
    font-style: normal;
}
.input_search_box.leak{
  margin-top:0px;
}
.input_search_box .input_submit .search{
    top: -25px;
    right: 50px;
}
.custom-radio.b-custom-control-sm{
     margin-left: 20px;
}
.search_div_leak_detail{
    position: absolute;
    left:420px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}

table.type01 td .text-right  {
  font-size: 11px;
}
.fftchart .highcharts-container .highcharts-root {
    width: 98%;
}
.btn-grayLine {
  background-color:#28AF81;
}

</style>


<template>
    <div  :style="{height: height+'px'}">
        <PressLineChart ref="chart" v-bind:chartData1="chartData1"  v-bind:chartData2="chartData2"  v-bind:chartData3="chartData3"    v-bind:xdata="xdata" v-if="loaded"
         v-bind:chartData4="chartData4"  v-bind:class="size" @onBarClick="onBarClick" v-bind:yopt="yopt" v-bind:ymin="min"></PressLineChart>
    </div>

</template>

<script>
//import * as d3 from 'd3'
import PressLineChart from '@/components/chart/PressLineChart'
//import {Line} from 'vue-chartjs'

export default {
    name: 'PressMonitoringDayChart',
 //   extends: Line,
    components: {PressLineChart :PressLineChart},
    props: [
        'height',
        'url'
    ],
    watch: {
        bidx: function() {
           this.redraw();          
        },
        url() {
            if(this.url==='') return
           // datacollection: null,
           // this.chartData= [];
          this.datacollection= null;
          this.chartData1 = [];
          this.chartData2 = [];
          this.chartData3 = [];
          this.chartData4 = [];
          this.xdata = [];
          var s_url = this.url.split("#")
          this.itype = Number(s_url[1]) ;

          this.size = this.itype === 0 ? 'firstClass' : 'firstClass1';
          this.loaded=false;
      //    this.url = "http://localhost:3000/api/get_leak_bubble?w_idx=768" ;

       

      //   this.$http.get(this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30').then((res) => {
            this.$http.get(s_url[0]).then((res) => {
              if( res.data !=="" && res.data != null) {
                var ts_mnf = res.data.results[0]                
                for(let i=0; i<ts_mnf.length; i++) { 
                   /*
                    this.ts_mnf[i]['date_str'] = this.ts_mnf[i]['date']
                    this.ts_mnf[i]['date'] = parseTime(this.ts_mnf[i]['date'])
                    this.ts_mnf[i]['rate'] = this.ts_mnf[i]['mnf'] / (this.ts_mnf[i]['flow']/24) * 100

                    this.table_mnf.push({
                        date_str: this.ts_mnf[i]['date_str'],
                        flow: this.ts_mnf[i]['flow'].toFixed(0),
                        mnf: this.ts_mnf[i]['mnf'].toFixed(0),
                        rate: this.ts_mnf[i]['rate'].toFixed(0)
                    })
                  */
                   this.xdata.push(ts_mnf[i]['measure_datetime']);                  
                   this.chartData1.push(ts_mnf[i]['max_water_pressure']);
                   this.chartData2.push( ts_mnf[i]['avg_water_pressure']);
                   this.chartData3.push(ts_mnf[i]['min_water_pressure']);
                   this.chartData4.push(ts_mnf[i]['maximum_night_pressure']);

                }   
              }else{

                  this.xdata = [];
                  this.chartData1 = [] ;
                  this.chartData2 = [] ;
                  this.chartData3 = [] ;
                  this.chartData4 = [] ;
              }
                
            });
           if( Number(this.fixed) !== 1){         
              this.yopt = {beginAtZero: true, };
              this.min = null
           }else{
              this.yopt =  {
                      beginAtZero: false,       
                      min: Number(this.min),
                  //    max: 10,                  
               } ;
           }
           ++this.bidx;


        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        datacollection: null,
        chartData1: [],
        chartData2: [],
        chartData3: [],
        chartData4: [],
        bidx : 0 ,
        size: 'firstClass',
        loaded: false,
        bar_idx:-1,
        itype : 0,
        fixed : 1 , //0 : false, 1: fixed
        min : 3,
        yopt : null,
      }
    },
    methods: {

       redraw () {

        this.loaded = true;
        // this.update();
        // this.$data._chart.update()
        // this.chartData =  cData ;
        if( this.$refs.chart !== undefined)
          this.$refs.chart.renderChart();

      },      
      onBarClick(){
         this.bar_idx = this.$refs.chart.bar_idx;
         this.$emit('onBarClick') ;
      }



    },
  mounted () {
        window.addEventListener('resize', this.resize);

        if(this.url==='') return
/*
         this.$http.get(this.url).then((res) => {
            //데이터 셋팅
             if( res.data !=="" && res.data != null) {
                var b_data = res.data.rows;
                for(let i=0; i < b_data.length ; ++i){
                  this.xdata.push(b_data[i].w_title);
                  //this.chartData1.push(b_data[i].leak_count) ;
                  //this.chartData2.push(b_data[i].leak_inside) ;
                  this.chartData1.push(10) ;
                  this.chartData2.push(20) ;
                  this.chartData3.push(b_data[i].other_count) ;

                }
             }else{
                  this.xdata = [];
                  this.chartData1 = [] ;
                  this.chartData2 = [] ;
                  this.chartData3 = [] ;
             }
          });
*/

    },
    beforeDestroy: function () {
      window.removeEventListener('resize', this.resize)
    }

}
</script>

<style scoped>
.firstClass{  height: calc(45vh - 100px );     padding-top: 10px;  }
.firstClass1{ height: calc(75vh - 100px );   padding-top: 10px;  }

</style>

<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
<!--                        <li class="breadcrumb-title">Map Frequency Comparison</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/hydraulic-monitoring')" href="#">Hydraulic Monitoring </a></li>
                      
                        <li class="breadcrumb-item active">Comparison in Map</li>
                    </ol>
                </li>
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div >
             <b-row style="margin: 0;">
                 <b-col :cols="msize" style="padding: 0 10px 0 0;">
                    <div class="search_div press_m2_map">
                        <span>    
                        <span class="spn1"> <b-select size="sm" v-model="pressure_value_type" :options="pressure_value_opt" ></b-select></span>                       
                        
                        <!--span class="status m1-map">
                            <input type="checkbox" id="check_01" name="check_01"  v-model="elevation_yn" />
                            <label style="margin-right: 20px;" >Elevation</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="value_type" value="bar">
                            <label style="margin-right: 20px;" for="radio_02">bar</label>
                            <input type="radio" id="radio_01" name="radio01"  v-model="value_type" value="kgf">
                            <label style="margin-right: 20px;" for="radio_01">kgf</label>                            
                            <input type="radio" id="radio_03" name="radio01"   v-model="value_type" value="psi" >
                            <label style="margin-right: 20px;" for="radio_03">psi</label>
                        </span-->
                        </span>
                        <span style="float:left;width:150px;padding: 0 20px;" v-if="show_day">
                            <!--b-input size="sm" type="text" placeholder="datetime" v-model="flow_date"  disabled /-->
                            <b-input size="sm" v-model="flow_date" type="date" @change="change_date_flow(0,1)" class="input_date m-0" style="padding: .25rem .25rem;"/>
                            <span style="position:absolute;top:0px;left:250px;">
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-10,1)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-1,1)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(1,1)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(10,1)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>
                             </span>
                       </span>
                       <span style="float:left;width:170px;padding: 0 20px;" v-if="!show_day">
                            <b-input size="sm" type="text" placeholder="datetime" v-model="flow_date_min"  @change="change_date_flow_min(0)"/>
                             <span style="position:absolute;top:0px;left:250px;">
                             <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-30*48)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-10)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(10)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(30*48)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>
                             </span>
                       </span>
                       <span style="float:right;">
                       <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" style="width:100px;" @click="unit_hydraulic"
                        v-b-tooltip.hover title="동수두/dis.">
                           <!--i v-if="!unit_value " class='fas fa-minus-circle fa-lg' style='color:#ffffff'></i>
                           <i v-if="unit_value " class='fas fa-plus-circle fa-lg' style='color:#ffffff'></i-->
                             {{unit_value ? '단위동수두(+)' : '단위동수두(-)'}}
                         </button></span>
                       <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="value_view"  v-b-tooltip.hover title="M2 Data Show">
                         <!--i v-if="point_value_view " class='fas fa-minus-circle fa-lg' style='color:#ffffff'></i>
                         <i v-if="!point_value_view " class='fas fa-plus-circle fa-lg' style='color:#ffffff'></i-->
                           {{point_value_view ? '압력값(-)' : '압력값(+)'}}</button></span>
                       </span>                       
                    </div>
                    
          
            <div style="overflow-y: scroll;" :style="{height: contents_height+'px'}">

                     <Map
                         ref="map"
                        :visible_gisload_button="true"
                        :visible_size_side_view_button="true"
                        :widx="$route.query.widx" 
                        :press_icon1=true                     
                        w_type="112"                     
                        @set_map_gisload="set_map_gisload"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @set_info="set_info"
                        @go_home="go_home()"
                        @size_view="size_view"
                        @onLeakMapClick1="onLeakMapClick1()" 
                        @onLeakMapClick="onLeakMapClick2()"
                     />
                  
            </div>   

                
            </b-col>
             <b-col  v-if="v_chart" :cols="tsize" style=" padding: 0px 2px 0 0; ">
                   <b-row style=" padding-left: 0px;background-color:#172A46; margin: 0px 10px 0 -5px; ">
                       <b-col style="padding-top: 5px; padding-bottom: 5px;" :style="{height: (mnfchart_height+15)+ 'px'}">
                          <div class="specification_area" style="  padding: 15px 10px 10px 10px ;margin-top: -10px;height:50px">
                              <b-form inline>
                                    <b-button-group  v-if="!show_day1" class="mr-2 specification_btn">
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='1' @change="change_selected_ts_flow($event)">
                                          <span>1D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='3' @change="change_selected_ts_flow($event)">
                                          <span>3D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='7' @change="change_selected_ts_flow($event)">
                                          <span>7D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='30' @change="change_selected_ts_flow($event)">
                                          <span>30D</span>
                                      </b-radio>
                                  </b-button-group>
                                   <b-button-group  v-if="show_day1" class="mr-2 specification_btn">
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='1' @change="change_selected_ts_flow($event)">
                                          <span>1M</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='2' @change="change_selected_ts_flow($event)">
                                          <span>2M</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='3' @change="change_selected_ts_flow($event)">
                                          <span>3M</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='6' @change="change_selected_ts_flow($event)">
                                          <span>6M</span>
                                      </b-radio>
                                  </b-button-group>                        
                                  <b-input size="sm" v-model="flow_date1" type="date" @change="change_flow_date($event)" class="input_date m-0"/>
                            
                              </b-form>
                                 <span class="btn_pre_m2">
                                   <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-1,2)"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                                   <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(1,2)"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                                  </span>
                            <div class="status m2press" >
                                <input type="radio" id="radio_01" name="radio01"  checked v-model="day_chart" value="raw">
                                <label for="radio_01">Raw</label>                             
                                <input type="radio" id="radio_02" name="radio02"  v-model="day_chart" value="Mnp">
                                <label for="radio_02">Mnp</label>
                            </div>
                            
                            <div :key="listKey" > 
                                 <button  @click="on_goto_map_click(1,tag1)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_table_map.svg" alt="map" style="width:15px;"  /></button>   
                                <button variant="outline-danger" @click="show_press_info(1,tag1)" style="border:0;  background: #172A46;"><img src="@/assets/images/new/ic_list_info.svg"  style="width:20px; height: 20px;" alt="map" class="infoimg" /></button> No : {{tag_name1}}
                            </div>   
                                    <!--PressMinChart :url="real_url1" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:5px;"   /-->  
                          
                            <pressChartData1 :url="real_url1" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height-10" style="position:relative;width:100%;top:0px;left:0px;"  />  
                          
                          </div>
                
                       </b-col>
                   </b-row>

                   <b-row style=" padding-left: 0px; background-color:#172A46; margin: 10px 10px 0 -5px;"  >
                       <b-col style="padding-top: 10px; padding-bottom: 5px;" :style="{height: mnfchart_height+ 'px'}">
                        <!-- <div class="status press" >
                            <input type="radio" id="radio_01" name="radio01"  v-model="vchart" value="kgf">
                            <label for="radio_01">kgf</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart" value="bar">
                            <label for="radio_02">bar</label>
                            <input type="radio" id="radio_03" name="radio01"   v-model="vchart" value="psi" >
                            <label for="radio_03">psi</label>
                          </div> -->
                          <div class="specification_area" style="  padding: 0px 10px 10px 10px ;height:50px" >
                               <button  @click="on_goto_map_click(2,tag2)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_table_map.svg" alt="map" style="width:15px;"  /></button>   
                               <button variant="outline-danger" @click="show_press_info(2,tag2)" style="border:0;  background: #172A46;"><img src="@/assets/images/new/ic_list_info.svg"  style="width:20px; height: 20px;" alt="map" class="infoimg" /></button> No : {{tag_name2}} 
                              
                               <pressChartData1 :url="real_url2" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height" style="position:relative;width:100%;top:0px;left:0px;"   />  
                              
                           </div>
                             
                       </b-col>
                   </b-row>
                   <b-row style="  padding-left: 0px;background-color:#172A46; margin: 10px 10px 0 -5px;">
                       
                       <b-col style="padding-top: 0px; padding-bottom: 5px;" :style="{height: mnfchart_height+ 'px'}">
                          <!--<div class="status press" >
                            <input type="radio" id="radio_01" name="radio01"  v-model="vchart" value="kgf">
                            <label for="radio_01">kgf</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart" value="bar">
                            <label for="radio_02">bar</label>
                            <input type="radio" id="radio_03" name="radio01"   v-model="vchart" value="psi" >
                            <label for="radio_03">psi</label>
                          </div> -->
                          <div class="specification_area" style="  padding: 10px 10px 10px 10px ;height:50px" >
                               <div>
                                   <button  @click="on_goto_map_click(3,tag3)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_table_map.svg" alt="map" style="width:15px;"  /></button>   
                                   <button variant="outline-danger" @click="show_press_info(3,tag3)" style="border:0;  background: #172A46;"><img src="@/assets/images/new/ic_list_info.svg"  style="width:20px; height: 20px;" alt="map" class="infoimg" /></button>   No : {{tag_name3}} </div>
                             
                                <pressChartData1 :url="real_url3" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height-10" style="position:relative;width:100%;top:0px;left:0px;"   /> 
                             
                           </div>
                         
                       </b-col>
                   </b-row>
                 </b-col>

           </b-row>
           
         </div>
     <DlgM2MoniSetting ref="m2_setting" />
     <DlgHydraulicPointInfo ref="hydraulic_info"  />
    </div>
</template>

<script>
//동수두 팝업창 등록
import DlgHydraulicPointInfo from '@/components/popup/DlgHydraulicPointInfo'
import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';
import pressChartData1 from '@/components/chart/pressChartData1' ;
import DlgM2MoniSetting from '@/components/popup/DlgM2MoniSetting' 
//import PressMinChart from '@/components/PressMinChart' ;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import * as d3 from 'd3'
import d3tip from "d3-tip";
 d3.tip = d3tip;

export default {
    name: 'HydraulicMonitoringMap',
    props: [
        'w_type'
    ],
    data() {
        return {
            selected_ts_flow: '1',    
            show_day : true,  
            show_day1 : false,  
            unit_value: true,
            point_value_view: true,
            value:'',
            loaded_ts_flow : true,
            v_chart : true,
            vchart : 'hydraulic',
            day_chart : 'raw',
            contents_height: 600,
            waveform_height: 42,
            mnfchart_height: 160,
            wavesurfer: null,
            layer_all_visible: true,
            chart_i : 0 ,
            real_url1: '',
            real_url2: '',
            real_url3: '',
            tag1 :'',
            tag2 :'',
            tag3 :'',
            psr_path1 : '',
            psr_path2 :'',
            psr_path3 : '' ,
            tag_name1 : '',
            tag_name2 :'',
            tag_name3 : '' ,    
            msize:'7',
            tsize:'5',        
            options: {
            },
            file: ' ' ,
            flow_date_min : this.$route.query.mea_date +" 03:00",
            flow_date : this.$route.query.mea_date,
            flow_date1 : this.$route.query.mea_date,
            elevation_yn : null,
            value_type : 'bar',
           // pressure_value_type :  this.$store.setting.pressure.legend.value,
            pressure_value_type : 'max',
            pressure_value_opt: ['10min','avg(3H)','avg(3.5H)','avg(4H)','avg' , 'min', 'max','mnp', ],   
            measuredate: null,  
            start_idx:0,
            max_idx:2, //(0,1,2 까지 볼수 있다.)
            listKey:0,
            hydraulic_chart : 0,
        }
    },
    methods: {
         unit_hydraulic(){

           if(this.$refs.map.leak_val !== 'unit' ) {
                this.unit_value = false
               this.$refs.map.leak_val =  'unit';     //bar,psi,kgf
           }else{
               this.unit_value = true
              this.$refs.map.leak_val =  '';  
           } 
          //누수 레이어를 다시 그린다.
         // this.$refs.map.init_reload_pressure_layer() ;
          this.$refs.map.init_reload_hydraulic_layer1();
          this.$refs.map.init_reload_hydrolic_layer() ;
          
         },
         value_view(){
            if(this.$refs.map.p_value === 0 ) { //값숨기기
                this.point_value_view = false
                this.$refs.map.p_value = 1;     //값보이기            
            }else{
                this.point_value_view = true
                this.$refs.map.p_value = 0;
            }
             //누수 레이어를 다시 그린다.
            // this.$refs.map.init_reload_pressure_layer() ;
            this.$refs.map.init_reload_hydraulic_layer1();
            this.$refs.map.init_reload_hydrolic_layer() ;
           
         },
         size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
         },
   
        show_press_info(i,idx) {
           // alert(idx)
           // this.$refs.m2_setting.show(idx)
            var brench_url =''
            if(i === 1){
                brench_url = this.real_url1
            }else if(i === 2){
                brench_url = this.real_url2
            }else{
                brench_url = this.real_url3
            }
            
            if(brench_url.indexOf('api/API21/hydraulic/') !== -1){
                this.$refs.hydraulic_info.show(idx);
           }else{
                this.$refs.m2_setting.show(idx)
           }
          //this.$refs.map.show_press_info(idx)
        },         
         toggle_visible_all_layer(a) {

                // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          /*
           this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
           if(this.$refs.map.layer_all_visible)
             this.$refs.map.leak_cal = 1;
           else
               this.$refs.map.leak_cal = 0;
            */
           if(a=== 1){
              // alert(a)
                this.$refs.map.leak_cal = 1;
           }else{
              //  alert(a)
                this.$refs.map.leak_cal = 0;
           }
          // this.$refs.map.init_reload_leak_layer() ;
            this.$refs.map.init_reload_hydrolic_layer() ;
          /*
            this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')===100)
                    layer.setVisible(this.$refs.map.layer_all_visible)
            }
            */
        },
        chang_press_mapvalue_type() {          
          // 화면에 시간을 searching할 수 있도록 화면을 셋팅한다.
          if(this.pressure_value_type === "10min"){
              this.show_day =false
              this.$refs.map.time_30m = this.flow_date_min; //지도에서 분데이터를 가져간다.
             
          }else{
            this.show_day = true
            this.$refs.map.time_30m = this.flow_date; //지도에서 일데이터 (최종시간 그전날 데이터)
          }
          this.$refs.map.leak_cal = this.pressure_value_type;    //avg,min/max
          this.$refs.map.leak_val =  this.value_type;     //bar,psi,kgf
       
          //누수 레이어를 다시 그린다.
         // this.$refs.map.init_reload_pressure_layer() ;
          this.$refs.map.init_reload_hydraulic_layer1();
          this.$refs.map.init_reload_hydrolic_layer() ;
         

        },  
  
        addr_search(){
          this.$refs.map.go_search(this.value);
        },
        Enter_Check1(){
            this.addr_search();
        },
       change_date_flow(a,type){ // 1:지도 2.chart
          if( type === 1) {
            let default_date = new Date(this.flow_date.toLocaleString());
            default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
            this.flow_date = this.date_to_str(default_date) ;
             this.chang_press_mapvalue_type();
             
          }else{
            let default_date1 = new Date(this.flow_date1.toLocaleString());
            default_date1.setDate(default_date1.getDate()+Number(a)).toLocaleString();
            this.flow_date1 = this.date_to_str(default_date1);  
            this.get_time_series_flow()      
          }         
      },
      date_to_str(format,a){
          //a = 0 ,day, a=1 ㅡㅑㅜ
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 

          if(a === 1){
            var hour = format.getHours();
            if(hour<10) hour = '0' + hour; 
            var min = format.getMinutes();
            if(min<10) min = '0' + min; 
            return year + "-" + month + "-" + date +" "+hour+":"+min;
          }else{
            return year + "-" + month + "-" + date ;
          }
      },
       change_date_flow_min(a){
       
          let default_date = new Date(this.flow_date_min.toLocaleString());
           default_date.setMinutes(default_date.getMinutes()+Number(a)).toLocaleString() ;
          this.flow_date_min = this.date_to_str(default_date,1)
          this.chang_press_mapvalue_type();
          //this.get_time_series_flow()
        },
        reutrn_url(s_url){
            var ss_url = s_url.split("?")
            if(ss_url.length >0)
              return ss_url[0]
            else
             return s_url;   
        },    
        change_selected_ts_flow(e) {
            this.selected_ts_flow = e
            this.get_time_series_flow()
        },
        get_time_series_flow() {
            this.loaded_ts_flow =false 
            /*
            this.real_url1 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            this.real_url2 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            this.real_url3 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            */
            this.real_url1 = this.reutrn_url(this.real_url1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart; 
            this.real_url2 = this.reutrn_url(this.real_url2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            this.real_url3 = this.reutrn_url(this.real_url3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            this.loaded_ts_flow = true
        }, 
        Enter_Check(){
         //  if(event.keyCode == 13){
          this.go_search();  // 실행할 이벤트
        },
        on_leakage_info(idx) {
          this.$refs.map.show_leakage_info_pop(idx)
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        on_goto_map_click(i, idx) {
            var brench_url =''
            if(i === 1){
                brench_url = this.real_url1
            }else if(i === 2){
                brench_url = this.real_url2
            }else{
                brench_url = this.real_url3
            }
            var w_type= '111'
            if(brench_url.indexOf('api/API21/hydraulic/') !== -1)
              w_type='112'

            this.$refs.map.get_extent('w_type='+w_type+'&p_idx='+idx).then((data)=>{
                this.$refs.map.set_fit_extent(data)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
            this.$refs.map.select_facility_feature(w_type,'',idx) 

        },
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                  alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                     if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    this.$refs.map.layer_list.push(newlayer) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);

                } //for문 끝

           } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },
       onLeakMapClick1(){//m2압력
            this.hydraulic_chart = 1
            this.onLeakMapClick();
        },
        onLeakMapClick2(){ //m2 hydraulic
            this.hydraulic_chart = 0
            this.onLeakMapClick();
        },        
        onLeakMapClick(){
            let tag_id = this.$refs.map.leak_idx;
            let tag_name = this.$refs.map.leak_recov_idx
            this.chart_i = this.chart_i+1 ;
            var d_day = '/minute/' ;
            if(this.day_chart !== "raw"){
                 d_day = '/day/' ;
            }
            var f_s_url =this.URL+'api/API21/hydraulic'+d_day ; 
            if(this.hydraulic_chart === 1){
                f_s_url =this.URL+'api/API21/m2_press'+d_day ; 
            }
            if(this.chart_i%3 === 1){
                this.tag_name1 = tag_name ;    
                this.tag1 = tag_id ;
                this.flow_date1 = this.flow_date ;
               // this.real_url1 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
                this.real_url1 = f_s_url+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            }else if(this.chart_i%3 === 2){
               this.tag_name2 = tag_name ;    
               this.tag2 = tag_id ;
             //  this.real_url2 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
               this.real_url2 = f_s_url+encodeURI(this.tag2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            }else{
                this.tag_name3 = tag_name ;    
                this.tag3 = tag_id ;
              //  this.real_url3 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
                this.real_url3 = f_s_url+encodeURI(this.tag3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
             }


        },
        updateKey: function () {
          this.listKey+= 1
       },
  
    },
    mounted () {
        this.contents_height = window.innerHeight - 140;
        this.mnfchart_height =  this.contents_height/3 - 10 ;
         setTimeout(()=>{
          let setting = this.$store.setting
          if(setting) {
             
             // this.pressure_value_type = this.$store.setting.pressure.legend.value
              this.pressure_value_type = "10min"
             // this.change_date_flow(-1,1);
          }
        }, 1000);
      setTimeout(()=>{
            var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
            if( elem !== undefined ){
            if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();         
            }
         }
       }, 200)
    },
    watch: {

     
      pressure_value_type(){//min,max,avg,
        this.chang_press_mapvalue_type() ;
      },
      value_type(){ //bar,psi,kgf
          this.chang_press_mapvalue_type() ;
      },
      day_chart(){
            setTimeout(()=>{
               this.updateKey();
               if(this.day_chart === 'raw'){
                   this.show_day1 = false
                   this.real_url1 =this.real_url1.replace("day","minute")
                   this.real_url2 =this.real_url2.replace("day","minute")
                   this.real_url3 =this.real_url3.replace("day","minute")
               }else{
                 this.show_day1 = true
                 this.real_url1 =  this.real_url1.replace("minute","day")
                 this.real_url2 =  this.real_url2.replace("minute","day")
                 this.real_url3 =  this.real_url3.replace("minute","day")
               }
               /*
               this.real_url1 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url2 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url3 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               */
               this.real_url1 = this.reutrn_url(this.real_url1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url2 = this.reutrn_url(this.real_url2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url3 = this.reutrn_url(this.real_url3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
            }, 500)
      },  
     v_chart: function() {
        if(this.v_chart){
             setTimeout(()=>{
               this.updateKey();
               /*
               this.real_url1 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url2 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url3 = this.URL+'api/API21/hydraulic/minute/'+encodeURI(this.tag3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               */
               this.real_url1 = this.reutrn_url(this.real_url1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url2 = this.reutrn_url(this.real_url2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url3 = this.reutrn_url(this.real_url3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
             }, 500)
        }
      },    

    },
    components: {
      Map: Map,
      pressChartData1:pressChartData1,
      DlgM2MoniSetting : DlgM2MoniSetting,
      DlgHydraulicPointInfo:DlgHydraulicPointInfo,
       // PulseLoader: PulseLoader,
    }
}
</script>

<style scoped>
.infoimg{
    width: 15px;
}
.press_m2_map{
    position: absolute;
    left:210px; 
    width: 590px;   
    top: 5px;
    
    z-index: 110;
     
}
.spn1 {
    float: left;
    padding: 2px;
}
.spn2 {
    float: right;
    padding: 2px;
}
.m1-map{
    position: relative;
    top : 7px;
    margin-left: 15px;
    background: #ececec;
    padding: 6px 0px 6px 7px;
    border:solid 0.1em #ccc;
    border-radius: 5px;
}
.real_chart .wavchart .highcharts-container .highcharts-root {
    width: 100%;
}
.btn_pre_m2 {
    position: absolute;
    top: 10px;
    left:420px;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}
.status.m2press {
    position: absolute;
    top: 10px;
    right: 25px;
}
</style>

<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                      <li class="breadcrumb-item active">Water Tank</li>
                    </ol>
                </li>
            </ul>
        </div>
        <!-- tom-bar-end -->

        <b-row>
            <b-col>
              <div class="monitoring_box">
                <div class="monitoring_wrap">
                  <div class="input_search_box">
                    <input type="text" class="input_search"  v-model="value" placeholder="Search..."  @keyup.enter ="Enter_Check">
                    <input type="submit" class="input_submit" @click="go_search" > 
                  </div>
                  <div class="info_box">
                    <ul class="list">
                      <!--li>전체 배수지수 : 3개  /  최대 이용률 : 80% (000 배수지)  /  최소 이용률 : 30% (000 배수지)  /  기준일자 : 2020.08.29
                      </li-->
                        <li>전체 배수지수 : {{total}}개  / 기준일자 : {{last_date}}
                      </li>
                    </ul>
                  </div>
                </div>
              <div class="table_wrap pump" :key="listKey">
                  <fragment v-for="item in ts_monitoring_list" :key="item.water_tank_code">
                    <watertankCard :dma_name="item.water_tank_name" :tag="item.water_tank_code">
                    </watertankCard>
                   </fragment>
               </div>
         <!--       <div class="table_wrap pump">
                  <div class="table_block table_block_100p" @click="goRouter('/water-tank-detail','남문배수지')">
                    <div class="title_box">
                      <h3>남문 배수지   :    V (3,000 ㎥), H (4m),Count(10)</h3>
                      <span class="date">2020. 9. 1 13:10</span>
                      <div class="water_div">
                        <div class="water_li bb0">
                          <div class="title">Water flow in</div>
                          <div class="con">
                            <span class="num">76.5</span>
                            <span class="unit">㎥/hr</span>
                          </div>
                          <div class="title">Water level #1</div>
                          <div class="con">
                            <span class="num">3.4</span>
                            <span class="unit">m</span>
                          </div>
                            <div class="title">Water level #2</div>
                          <div class="con">
                            <span class="num">3.4</span>
                            <span class="unit">m</span>
                          </div>
                        </div>
                        <div class="water_li">
                          <div class="title">Water flow out</div>
                          <div class="con">
                            <span class="num">76.5</span>
                            <span class="unit">㎥/hr</span>
                          </div>
                          <div class="title">Water level #3</div>
                          <div class="con">
                            <span class="num">3.4</span>
                            <span class="unit">m</span>
                          </div>
                            <div class="title">Water level #4</div>
                          <div class="con">
                            <span class="num">3.4</span>
                            <span class="unit">m</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="con_box">
                      <table>
                        <colgroup>
                          <col style="width:20%">
                          <col style="width:11.4%">
                        </colgroup>
                        <thead>
                        <tr>
                          <th></th>
                          <th class="bg">1</th>
                          <th class="bg">2</th>
                          <th class="bg">3</th>
                          <th class="bg">4</th>
                          <th class="bg">5</th>
                          <th class="bg">6</th>
                          <th class="bg">7</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td class="bg"><p class="unit"><span>out_flow_day (㎥/day)</span></p></td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                        </tr>
                        <tr>
                          <td class="bg"><p class="unit"><span>highest_water_level (m)</span></p></td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                        </tr>
                        <tr>
                          <td class="bg"><p class="unit"><span>low_water_level (m)</span></p></td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                        </tr>
                        <tr>
                          <td class="bg"><p class="unit"><span>Utilization (%)</span></p></td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                          <td>128</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="btn_next"></div>
                  </div>
                </div>
  -->
              </div>
            </b-col>
       
       
        </b-row>
    </div>
</template>

<script>

import watertankCard from '@/components/card/watertankCard';
import { Fragment } from 'vue-fragment'

export default {
    name: 'WaterTank',
    props: {
    },
    
    data() {
        return {
                columns: [
                {label: 'Tag', field: 'water_tank_code'},
                {label: 'Name', field: 'water_tank_name'}
            ],
            ts_monitoring_list: [],
            ts_flow_tag_list: [],
            temp_ts_monitoring_list: [],
            value:'',
            listKey: 0,
            total : 0,
            last_date : '0001-01-01'
        }
    },
    methods: {
      goRouter(path,name) {
        this.$router.push({path: path, query: { tag: name }})
      },
      
      updateKey: function () {
          this.listKey+= 1
       },
      go_search() {

          if(this.value===undefined) return;
        this.updateKey();
         this.ts_monitoring_list=[];
         
       //  this.$http.get(this.URL+'api/get_watertank_list_left?search='+this.value).then((res) => {
           this.$http.get(this.URL+'api/API21/watertank/list?customer_id='+sessionStorage.select_customer_id+'&search='+this.value).then((res) => {     
            this.ts_monitoring_list = res.data.results[0]   
            this.total = res.data.results[0].length ;
            if(this.total > 0){
              this.last_date = res.data.results[0][0].date_time.split(' ')[0] 
            }       
        });
       
     },
      Enter_Check(){
       //  if(event.keyCode == 13){
         this.go_search();  // 실행할 이벤트
    }
    },
    created () {
    },
    beforeDestroy() {
    },
    mounted () {
        //  this.$http.get(this.URL+'api/get_watertank_list_left').then((res) => {
          this.$http.get(this.URL+'api/API21/watertank/list?customer_id='+sessionStorage.select_customer_id).then((res) => {  
            this.ts_monitoring_list = res.data.results[0]
            this.total = res.data.results[0].length ;
            if(this.total > 0){
              this.last_date = res.data.results[0][0].date_time.split(' ')[0] 
            }       
           // this.$http.get(this.URL+'api/get_tag_list?type=flow').then((res) => {
           //     this.ts_flow_tag_list = res.data.rows
              //  this.del_dup_list(this.ts_monitoring_list, this.ts_flow_tag_list)
          //  });
        });
    },
       components: {
        watertankCard,
        Fragment
    }
}

</script>
<style scoped>
</style>

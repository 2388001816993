<template>
    <div>
        <div v-if="search==true" class="table-option">
            <b-input-group class="mt-1 search_box">
                <!--b-input type="text" v-model="serverParams.searchTerm" class="form-control gis-master__input" placeholder="Search..."></b-input-->
                <!-- <span style="width:67%;"></span> -->
                <!-- 2023 -->
                <!-- <span style="width:86%;">
                    <div class="input_search_box" style="margin : 0;">                        
                     <button  class="btn btn-primary btn-g" type="button" @click="downloads" v-b-tooltip.hover title='WavFile Download'>{{$t('leak_detail.btn_wav_down')}}</button>        
                    </div>
                </span> -->
                <!-- 2023 -->
                <!-- <div class="search_btn_area" style="margin-right:-2%;" > -->
                <div class="search_btn_area" style="position: absolute;right: 10px;top: -45px;" >
                  <a href="#none" @click="$refs.modal.show();" class="search_icon icon_option"></a>
                  <a href="#none" @click="clear()" class="search_icon icon_clear"></a>
                </div>
             </b-input-group>
        </div>
        <div v-if="leak_total_cnt==true" class="table-option">
            <b-input-group class="mt-1 search_box">
                <!--b-input type="text" v-model="serverParams.searchTerm" class="form-control gis-master__input" placeholder="Search..."></b-input-->
                <span style="width:83%;right:0px;"></span>
                <span style="width:17%;right:0px;" >Leak TotalCount : {{leak_t_cnt}} </span>
               
             </b-input-group>
        </div>
                <vue-good-table ref="table"
                    mode="remote"
                    @on-row-click="onRowClick"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    styleClass="vgt-table without--head-right-line type01 admin"
                    :globalSearch="true"
                    :line-numbers="false"
                    :isLoading.sync="isLoading"
                    :select-options="{ enabled: select, selectOnCheckboxOnly: true, disableSelectInfo: false }"
                    :sort-options="{enabled: sort}"
                    :search-options="{enabled: true, externalQuery: serverParams.searchTerm}"
                    :pagination-options="{enabled: true, position: 'top', perPageDropdown: drop_page_opt, dropdownAllowAll: false,}"
                    :totalRows="totalRecords"
                    :rows="rows"
                    :row-style-class="rowStyleClassFn"
                    :columns="columns"
                    :adv_search="adv_search"
                    :fixed-header="true"
                    :max-height="max_height"
                
                    >
                    <template slot="table-row" slot-scope="props" v-if="add_custom_column">
                      <span v-if="props.column.field == 'go_map'" class="table_icon" @click="$emit('on_goto_map_click', props.row)">
                        <!-- 2022 new -->
                        <!-- <img src="@/assets/images/Common/table/map_icon.png" alt="map"> -->
                        <img src="@/assets/images/new/ic_table_map.svg" alt="map">
                    </span>
                      <span v-else-if="props.column.field == 'info'" class="table_icon" @click="$emit('on_info_click', props.row.idx)">
                        <!-- 2022 new -->
                        <!-- <img src="@/assets/images/Common/table/info_icon.png" alt="map"> -->
                        <img src="@/assets/images/new/ic_list_info.svg" style="width:70%"  alt="map">
                    </span>
<!--                        <b-button size="ss" v-if="props.column.field == 'go_map'" @click="$emit('on_goto_map_click', props.row)" class="table_icon">-->
<!--                          <img src="@/assets/images/Common/table/map_icon.png" alt="map"></b-button>-->
<!--                        <b-button size="ss" variant="outline-info" v-else-if="props.column.field == 'info'" @click="$emit('on_info_click', props.row.idx)" ><i class="fas fa-info"></i></b-button>-->
                       <span v-if="props.column.field == 'leak_result_' && props.row.leak_result_ === 'Leak' ">
                            <div style=" background-color: #ff8282;">{{props.row.leak_result_}}</div> 
                        </span>
                        <span v-else>
                        {{props.formattedRow[props.column.field]}}
                        </span>
                   
                    </template>
                </vue-good-table>
          <DlgSearch ref="modal" :adv_search="adv_search" @change="change" @handleOk="handleOk"/>
        </div>
</template>

<script>
import DlgSearch from '@/components/popup/DlgSearch';

import _ from 'lodash'

export default {
    name: 'ServerSideTable',
    props: [
        'columns',
        'select',
        'leak_total_cnt',
        'perpage_cnt',
        'search',
        'sort',
        'adv_search',
        'add_custom_column',
        'max_height',
        'line_num'
    ],
    watch: {
   
    },
    data() {
        return {
            isLoading: false,
            rows: [],
            totalRecords:0,
            serverParams: {
                searchTerm: '',
                columnFilters: {
                },
                sort: {
                    field: '',
                    type: '',
                },
                page: 1,
                perPage: this.perpage_cnt,              
            },
            drop_page_opt: this.perpage_cnt === 20 ?  [20, 50, 100, 200,500,1000] :  [15, 30, 50, 100, 200,500,1000] ,
            url: '',
            is_search_dlg_ok: false,
            
            leak_t_cnt:0,
            listkey : 0,
        }
    },
    methods: {
        onRowClick(params) {
            this.$emit('on-row-click', params);
          //  this.rowStyleClassFn(this.getSelectRows())
        },
        rowStyleClassFn(row) {
            return row.period > 2 ? "green1" : "red1";
        },
         tdClassFunc(row) {
            if (row.ai_leak_result < 50) {
            return 'green';
            }
            return 'red';
        },
        getSelectRows() {
            return this.$refs.table.selectedRows;
        },
        onSearch: _.debounce(function (params) {
            this.updateParams(params);
            this.serverParams.page = 1;
            this.loadItems();
            return false;
        }, 500),
        updateParams(newProps) {
              this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.loadItems();
        },
        onPerPageChange(params) {
           this.updateParams({perPage: params.currentPerPage});
           this.loadItems();
           /*
           if(params.currentPerPage > 400) {
                alert('Searching data is limited to under 1,000')

               this.updateParams({perPage: 10, page: 1}); 
              //  this.loadItems();
               // this.onSearch();
               return false;
            }else{
                this.updateParams({perPage: params.currentPerPage});            
                this.loadItems();
            }
            */
        },
        onSortChange(params) {
            this.updateParams({
                sort: {
                 //  type: params.sortType,
                 //   field: this.columns[params.columnIndex].field,
                   type: params[0].type,
                   field: params[0].field,
                },
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        loadItems(cb) {
            let s = (this.url.indexOf("?")>0)?'&':'?';
            
            let url = this.url+s+'page='+this.serverParams.page+'&per_page='+this.serverParams.perPage+'&search_term='+this.serverParams.searchTerm+'&sort_field='+this.serverParams.sort.field+'&sort_type='+this.serverParams.sort.type


            var field= this.serverParams.sort.field === "" ? 'measure_datetime' : this.serverParams.sort.field ;
            var type  = this.serverParams.sort.type=== "" ? 'desc':this.serverParams.sort.type ;
            let cur_page = ""+document.location.href ;
            if(cur_page.indexOf("leak-monitoring-detail") !== -1 ){
                url = this.url+s+'page='+this.serverParams.page+'&per_page='+this.serverParams.perPage+'&search_term='+this.serverParams.searchTerm+'&sort_field='+field+'&sort_type='+type
            }

            if(this.is_search_dlg_ok) {
                for(let i=0; i<this.adv_search.length; i++) {
                    if(this.adv_search[i].control==='range') {
                        if((this.adv_search[i].value!==null)&&(this.adv_search[i].value2!==null))
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'=,'+this.adv_search[i].value2
                    }else  if(this.adv_search[i].control==='date') {
                        if((this.adv_search[i].value!==null)&&(this.adv_search[i].value2!==null))
                            if(this.adv_search[i].value > this.adv_search[i].value2){
                               this.adv_search[i].value = null
                               this.adv_search[i].value2 = null 
                               alert("Invalid date input.")     
                               return
                            }else{                              
                                url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                            }
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'=,'+this.adv_search[i].value2

                    }
                    else {
                        if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value
                    }
                }
            }
            this.$http.get(url).then((res) => {
                 if(this.leak_total_cnt){
                   this.leak_t_cnt = res.data.totalLeakCount ;                   
                  
                   if(this.leak_t_cnt === 0 )
                    console.log("leak_cnt:"+this.leak_t_cnt,",res.data.cnt :" +res.data.cnt, "row.cnt:"+res.data.rows.length)
                }
                this.rows = res.data.rows;
                this.totalRecords = res.data.cnt;                
                cb && cb(this.rows);
			});
        },
        setUrl(_url, cb) {
            this.url = _url;
            this.loadItems(cb)
        },
        change(e, index) {
            this.$emit('search_item_change', e, index)
        },
        handleOk() {
            this.is_search_dlg_ok = true
            this.loadItems()
        },
        clear() {
            for(let i=0; i<this.adv_search.length; i++) {
                this.adv_search[i].value = null
                if(this.adv_search[i].control==='range') this.adv_search[i].value2 = null;
            }
            this.serverParams.searchTerm = ''
            this.is_search_dlg_ok = false;
            this.loadItems()
        },
        get_option_highe() {
            return this.$refs.optionHeight.clientWidth
        }
    },
    mounted () {
    },
    beforeDestroy () {
    },
    components: {
        DlgSearch: DlgSearch
    }
}
</script>

<style lang="scss" scoped>
.table-option {
  margin-top: 0;
  position:relative;
}

td {
  color: white !important;
}


.btn-size-view {
  background: #f2f2f2;
}

.input-group > {
  .form-control {
    &.gis-master__input {
      width: calc(100% - 200px);
      height: 30px;
      text-indent: 40px;
      border: none;
      background: url('../../assets/images/Common/table/btn_search.png') 3px center no-repeat;
      background-size: 28px;
      color: #171725;
      font-size: 18px;
    }
  }
}
.green {
  background-color: green;
}

.red {
  background-color: red;
}

td {
  color: white !important;
}
</style>

import { render, staticRenderFns } from "./SearchLeftType32.vue?vue&type=template&id=451a8b0c&scoped=true"
import script from "./SearchLeftType32.vue?vue&type=script&lang=js"
export * from "./SearchLeftType32.vue?vue&type=script&lang=js"
import style0 from "./SearchLeftType32.vue?vue&type=style&index=0&id=451a8b0c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451a8b0c",
  null
  
)

export default component.exports
<template>
    <div>
        <div v-if="search==true" class="table-option">
            <b-input-group class="mt-1 search_box">
                <b-input type="text" v-model="serverParams.searchTerm" class="form-control gis-master__input" :placeholder="$t('gis.dlg_search.search')"></b-input>              
                <div class="search_btn_area">
                  <a href="#none" @click="$refs.modal.show();" class="search_icon icon_option"></a>
                  <!-- 2022 new -->
                  <a href="#none" @click="clear()" class="search_icon icon_clear"></a>
                </div>
             </b-input-group>
        </div>

                <vue-good-table ref="table"
                    mode="remote"
                    @on-row-click="onRowClick"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"
                    styleClass="vgt-table without--head-right-line type01"
                    :globalSearch="true"
                    :line-numbers="false"
                    :isLoading.sync="isLoading"
                    :select-options="{ enabled: false, selectOnCheckboxOnly: true, disableSelectInfo: true }"
                    :sort-options="{enabled: sort}"
                    :search-options="{enabled: true, externalQuery: serverParams.searchTerm}"
                    :pagination-options="{enabled: true, position: 'top', perPageDropdown: [15, 30, 50, 100, 200,500, 1000],dropdownAllowAll: false,}"
                    :totalRows="totalRecords"
                    :rows="rows"
                    :columns="columns"
                    :adv_search="adv_search"
                    :fixed-header="true"
                    :max-height="max_height"
                    >
                    <template slot="table-row" slot-scope="props" v-if="add_custom_column">
                      <span v-if="props.column.field == 'go_map'" class="table_icon" @click="$emit('on_goto_map_click', props.row)"><img src="@/assets/images/new/ic_table_map.svg" alt="map"></span>
                      <span v-else-if="props.column.field == 'info'" class="table_icon" @click="$emit('on_info_click', props.row)"><img src="@/assets/images/new/ic_table_info.svg" alt="info" ></span>
<!--                        <b-button size="ss" v-if="props.column.field == 'go_map'" @click="$emit('on_goto_map_click', props.row)" class="table_icon">-->
<!--                          <img src="@/assets/images/Common/table/map_icon.png" alt="map"></b-button>-->
<!--                        <b-button size="ss" variant="outline-info" v-else-if="props.column.field == 'info'" @click="$emit('on_info_click', props.row.idx)" ><i class="fas fa-info"></i></b-button>-->
                    </template>
                </vue-good-table>
          <DlgSearch ref="modal" :adv_search="adv_search" @change="change" @handleOk="handleOk"/>
        </div>
</template>

<script>
//gis_master참조,
import DlgSearch from '@/components/popup/DlgSearch'; 

import _ from 'lodash'

export default {
    name: 'ServerSideTable',
    props: [
        'columns',
        'select',
        'search',
        'sort',
        'adv_search',
        'add_custom_column',
        'max_height'
    ],
    watch: {
    },
    data() {
        return {
            isLoading: false,
            rows: [],
            totalRecords:0,
            serverParams: {
                searchTerm: '',
                columnFilters: {
                },
                sort: {
                    field: '',
                    type: '',
                },
                page: 1,
                perPage: 15
            },
            url: '',
            is_search_dlg_ok: false
        }
    },
    methods: {
        onRowClick(params) {
            this.$emit('on-row-click', params);
        },
        getSelectRows() {
            return this.$refs.table.selectedRows;
        },
        onSearch: _.debounce(function (params) {
            this.updateParams(params);
            this.serverParams.page = 1;
            this.loadItems();
            return false;
        }, 500),
        updateParams(newProps) {
              this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.loadItems();
        },
        onSortChange(params) {

            this.updateParams({
                page: 1,     
                sort: {
                 //  type: params.sortType,
                 //   field: this.columns[params.columnIndex].field,
                   type: params[0].type,
                   field: params[0].field,
                },
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        loadItems(cb) {
            let s = (this.url.indexOf("?")>0)?'&':'?';
            let url = this.url+s+'page='+this.serverParams.page+'&per_page='+this.serverParams.perPage+'&search_term='+this.serverParams.searchTerm+'&sort_field='+this.serverParams.sort.field+'&sort_type='+this.serverParams.sort.type


            if(this.is_search_dlg_ok) {
                for(let i=0; i<this.adv_search.length; i++) {
                    if(this.adv_search[i].control==='range'|| this.adv_search[i].control==='date') {
                        if((this.adv_search[i].value!==null)&&(this.adv_search[i].value2!==null)){
                            if(this.adv_search[i].value > this.adv_search[i].value2){
                               this.adv_search[i].value = null
                               this.adv_search[i].value2 = null 
                               var msg = this.adv_search[i].control==='date' ? "Invalid date input." : "Incorrect size input."
                               alert(msg)     
                               return
                            }    
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                        }else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'=,'+this.adv_search[i].value2
                       
                    }
                    else {
                        if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value
                    }
                }
            }
            this.$http.get(url).then((res) => {
                this.rows = res.data.rows;
                this.totalRecords = res.data.cnt;
                cb && cb(this.rows);
			});
        },
        setUrl(_url, cb) {
            this.url = _url;
            this.loadItems(cb)
        },
        change(e, index) {
            this.$emit('search_item_change', e, index)
        },
        handleOk() {
            this.is_search_dlg_ok = true
            this.loadItems()
        },
        clear() {
            for(let i=0; i<this.adv_search.length; i++) {
                this.adv_search[i].value = null
                if(this.adv_search[i].control==='range') this.adv_search[i].value2 = null;
            }
            this.serverParams.searchTerm = ''
            this.is_search_dlg_ok = false;
            this.loadItems()
        },
        get_option_highe() {
            return this.$refs.optionHeight.clientWidth
        }
    },
    mounted () {
    },
    beforeDestroy () {
    },
    components: {
        DlgSearch: DlgSearch
    }
}
</script>

<style lang="scss" scoped>
.btn-size-view {
  background: #f2f2f2;
}
// 2022 new start
// .input-group > {
//   .form-control {
//     &.gis-master__input {
//       width: calc(100% - 200px);
//       height: 30px;
//       text-indent: 40px;
//       border: none;
//       background: url('@/assets/images/Common/table/btn_search.png') 3px center no-repeat;
//       background-size: 28px;
//       color: #171725;
//       font-size: 18px;
//       background-color: #fafafa;
//     }
//   }
// }
.input-group > {
  .form-control {
    &.gis-master__input {
    //   width: calc(100% - 100px);
      //   height: 30px;
      height: 44px;
      text-indent: 10px;
      border: 1px solid #28AF81;
    //   background: url('@/assets/images/Common/table/btn_search.png') 3px center no-repeat;
      background: url('../../assets/images/new/ic_search_primary.svg') right center no-repeat;
      background-size: 28px;
      background-color: #0A192F;
    //   color: #171725;
      color: #fff;
      font-size: 18px;

    }
  }
}
// 2022 new end
.table-option {
    margin-top: 0;
    margin-bottom: 0;
    float: none;
}
.input-group > .form-control.gis-master__input[data-v-920b80a6]{
 // background: #fcfcfc;
}
.input-group.mt-1.search_box{
  margin: 0px auto 2px auto;
}
</style>

<template>
  <!-- 2023 start -->
        
        <div class="items" style="padding: 16px;-webkit-box-sizing: border-box;box-sizing: border-box;background-color: #172A46;border: 1px solid #707070;">
          <div  class="item-head">
            <div class="item-head--left">
              <h3 >{{ name }}<img :src="(alarm_icon == 'bg_card_color_red' ? require('@/assets/images/sub/m2/alarm_red.png') :'')" style="width:25px;padding: 0 0 5px 5px ;" alt=""></h3>
              <p  class="date">{{ pump_date }}</p>
            </div>
            <div class="item-head--right" style="display: flex;">
              <!--span v-if="is_admin" class="mdi mdi-check-circle-outline" style="font-size:24pt; margin-right: 8px; cursor: pointer;color:#dbd4d4;"></span-->
            
              <img src="@/assets/images/new/ic_list_info.svg" alt="" style="width: 24px; height: 24px;margin-right: 8px; cursor: pointer;" @click="go_m2_info" >
              <img src="@/assets/images/new/ic_list_map.svg" alt="" style="width: 24px; height: 24px; margin-right: 8px; cursor: pointer;" @click="goRouter('/pressure-monitoring-map?w_type=111')">
              <img src="@/assets/images/new/ic_menu_chevron.svg" alt="" style="width: 24px; height: 24px; margin-right: 8px; cursor: pointer;" @click="goRouter('/pressure-monitoring-detail')">
            </div>
          </div>
          <div class="item-content">
            <ul class="item-content--left">
              <li style="margin:0;">
                
                <!--span class="unit m2_press" style="margin-top: 15px;">Pressure</span>                
                <p class="num">{{now_flow}}<span class="unit">bar</span></p-->
               <b-row style="margin:0 0 0 -20px;width:102%;padding:0px;">
                <b-col sm='5'>
                <span class="unit m2_press" style="margin-top: 15px;">Pressure</span>
                </b-col>
                <b-col sm='7' style="padding:0px;">
                <p  class="num">{{now_flow}}<span class="unit"   >bar</span></p>
                </b-col>
                </b-row>
              </li>
              <li>
                <b-row style="margin:0 0 0 -20px;width:102%;padding:0px;">
                <b-col sm='5'>
                <span class="unit m2_press" style="margin-top: 15px;">MNP</span>
                </b-col>
                <b-col sm='7' style="padding:0px;">
                <p  class="num">{{now_mnf}}<span class="unit"   >bar</span></p>
                </b-col>
                </b-row>
              </li>
            </ul>
            <div class="item-content--right" style="position: absolute;width: 200px;right: 16px;bottom: 16px;">
              <p style="bottom: 70px;">
                {{ last_mea_date }} 
              </p>
              <p style="bottom: 40px;">  
                {{dma_name}} / 
                <img src="/img/ic_battery.6cf79b3d.svg" alt="" style="width: 15px; padding-bottom: 5px;">
              </p>
              <div class="status_circle" style="right: 0px; top: 100px;">
                <ul >
                  <li >
                    <a  href="#" :class = "color[0]" >0</a>
                  </li>
                  <li >
                    <a  href="#" :class="color[1]">1</a>
                  </li>
                  <li>
                    <a  href="#" :class="color[2]">2</a>
                  </li>
                  <li >
                    <a  href="#" :class="color[3]">3</a>
                  </li>
                  <li >
                    <a href="#" :class="color[4]">4</a>
                  </li>
                </ul>
              </div>
              <div class="btn_next">

              </div>
            </div>
          </div>
        </div>
     
    <!-- 2023 end -->
    
    <!-- 이전 -->
    <!-- <div class="table_block"  style='width:100%;' :class=bg_color>
      
 
                     <div class="title_box ps">
                      <div class="water_div">
                        <div class="ps bb0">
                          <div class="title1">
                              <div class="title_img" style="margin: 20px 0 0 0px;float:left;" >
                              </div>
                              <div class="title_text_wrap ps2">
                            
                                <div class="title_text">
                                  <h3>{{ name }}</h3>              
                                </div>
                                <div class="title_text">
                                  <h3>{{point_desc}} </h3>              
                                </div>
                                
                              
                              </div>
                            
                           
                          </div>
           
         
                          <div class="con1">   
                            <p>
                            <span class="num">{{now_flow}}</span>
                            <span class="unit">bar</span>
                            </p>
                           <p>
                              {{dma_name}} / {{battery}}% /   <img :src="item" style="width:15px;padding-bottom:5px;" alt="">
                           </p>
                           <p class="date" style=" margin:0px 30px 0 0px;">{{ last_mea_date }} </p>
                         
                          </div>
                          
                        </div>

                      </div>
                    </div>
      <div class="con_box" :key="listKey">
        <table>
          <colgroup>
            <col style="width:10%">
          </colgroup>
          <thead>
          <tr>
            <th class="bg" style="width:9%">Date</th>
            <th class="bg" style="width:9%">{{date_str[0]}}</th>
            <th class="bg" style="width:9%">{{date_str[1]}}</th>
            <th class="bg" style="width:9%">{{date_str[2]}}</th>
            <th class="bg" style="width:9%">{{date_str[3]}}</th>
            <th class="bg" style="width:9%">{{date_str[4]}}</th>
            <th class="bg" style="width:9%">{{date_str[5]}}</th>           
            <th class="bg" style="width:9%">{{date_str[6]}}</th>
            <th class="bg" style="width:9%">{{date_str[7]}}</th>
            <th class="bg" style="width:9%">{{date_str[8]}}</th>
            <th class="bg" style="width:9%">{{date_str[9]}}</th>            
          </tr>
          </thead>
          <tbody>
         
          <tr>
            <td class="bg"><p class="unit" style="margin-left:0;"><span>MNP(Bar)</span></p></td>
            <td :class=color[0]>{{mnf[0]}}</td>
            <td :class=color[1]>{{mnf[1]}}</td>
            <td :class=color[2]>{{mnf[2]}}</td>
            <td :class=color[3]>{{mnf[3]}}</td>
            <td :class=color[4]>{{mnf[4]}}</td>
            <td :class=color[5]>{{mnf[5]}}</td>
            <td :class=color[6]>{{mnf[6]}}</td>  
            <td :class=color[7]>{{mnf[7]}}</td>
            <td :class=color[8]>{{mnf[8]}}</td>
            <td :class=color[9]>{{mnf[9]}}</td>          
          </tr>
         
   
        
          </tbody>
        </table>
      </div>
        <div style="float: right; margin-top: 30px; margin-right: 50px;"  @click="go_m2_info"  >
         <div> 
             
         </div>
         <div class="title_img m2_press"> 
            <img src="@/assets/images/new/ic_list_info.svg" alt="">
             <img src="@/assets/images/new/ic_list_map.svg" alt="" @click="goRouter('/pressure-monitoring-map?w_type=111')"> 

            </div>
         <div class="btn_next" @click="goRouter('/pressure-monitoring-detail')" >
         </div>
        </div>
    </div> -->

 
</template>

<script>

export default {
    name: 'pumpCard',
    props: [
        'dma_name',
        'tag',
        'box'
    ],
    data() {
        return {
            now_flow: null,
            now_mnf: null,
            is_admin :false,
            pump_date:'',
            battery : '',
            name : '',
            alarm_icon : '',
            bg_color : '',
            point_desc:'',
            listKey: 0,
            date_str: [],
            mnf: [],
            rate:[],
            title_color: 'red',    
            last_mea_date : "",
            // 2022 new
            // item : require('@/assets/images/sub/m2/signal5.png'),     
            item : require('@/assets/images/new/ic_battery.svg'),     
            capacity: '' ,
            avg_guarantee_efficiency:'',
            efficiency:'',
            efficiency_loss_rate:'',
            water_energy_unit:'',
            color : ["","","","","","","blue","blue","blue","blue"],
            color1: '' ,color2: '' ,color3: '' ,color4: '' ,color5: '' ,
            title_m : [
             {'name': '고남 가압장', 'flow_in': 17.0, 'total_head': 8.0,'press_in': 5.8 ,'press_out': 6.6 , 
               'color1': 'circle color_01' , 'color2': 'circle color_02' , 'color3': 'circle color_03' , 'color4': 'circle color_03', 'color5': 'circle color_03'},
             {'name': '소원 가압장(근흥 라인)', 'flow_in': 187.2, 'total_head': 50.0,'press_in': 5.7 ,'press_out': 10.7 , 
               'color1': 'circle color_01' , 'color2': 'circle color_01' , 'color3': 'circle color_01' , 'color4': 'circle color_02', 'color5': 'circle color_03'},
             {'name': '소원 가압장(소원라인)', 'flow_in': 180.5, 'total_head': 56.0,'press_in':4.0 ,'press_out': 9.6 , 
             'color1': 'circle color_01' , 'color2': 'circle color_02' , 'color3': 'circle color_01' , 'color4': 'circle color_02', 'color5': 'circle color_03'},
           ]            
        }
    },
    methods: {
        goRouter(path) {
            var last_str = this.last_mea_date.substr(0,10)
            this.$router.push({path: path, query: { id: this.tag, mea_date:last_str  }})
        },
        go_m2_info(){
          this.$emit('go_m2_info',  this.tag)
        }
    },
    mounted () {
      // this.chartData2.push( ts_mnf[i]['max_total_head'].toFixed(0));
      // this.chartData3.push(ts_mnf[i]['min_total_head'].toFixed(0));
        this.title_color = 'white'
        //var to_date = ''
        if(sessionStorage.level < 2){
              this.is_admin = true
          }
        //this.$http.get(this.URL+'api/API21/m2_press/list/'+this.tag+'?customer_id='+sessionStorage.select_customer_id).then((res) => {
          /*
          if(res.data.results[0][0].water_pressure !== null)
            this.now_flow = res.data.results[0][0].water_pressure.toFixed(2);
          if(res.data.results[0][0].last_measure_datetime !== null){  
            this.last_mea_date = res.data.results[0][0].last_measure_datetime ;
            to_date = this.last_mea_date.substr(0,10)
          }
          if(res.data.results[0][0].point_status === 'I')
            this.bg_color = "bg_card_color";
          this.name = res.data.results[0][0].point_name;
          this.point_desc =  res.data.results[0][0].point_desc;
          this.battery = res.data.results[0][0].battery ;
          this.now_mnf=res.data.results[0][0].mnp
          var mnp_boundary = res.data.results[0][0].mnp_boundary
          if( res.data.results[0][0].comm_sense !== null)
            var com_str= res.data.results[0][0].comm_sense  > 5 || res.data.results[0][0].comm_sense === 'undefined'  ? 5 : res.data.results[0][0].comm_sense
            // 2022 new
            this.item =  require('@/assets/images/sub/m2/signal'+com_str+'.png')
            // this.item =  require('@/assets/images/new/ic_battery'+com_str+'.svg')
          */
          if(this.box.water_pressure !== null)
            this.now_flow = this.box.water_pressure.toFixed(2);
          else
            this.now_flow = ' - '  
          if(this.box.last_measure_datetime !== null){  
            this.last_mea_date = this.box.last_measure_datetime ;
           // to_date = this.last_mea_date.substr(0,10)
          }
          if(this.box.point_status === 'I')
            this.bg_color = "bg_card_color";
          this.name = this.box.point_name;
          this.point_desc =  this.box.point_desc;
          this.battery = this.box.battery ;
          if(this.box.mnp !== null)
            this.now_mnf = this.box.mnp.toFixed(2);
          else
            this.now_mnf = ' - '  

         // this.now_mnf=this.box.mnp
          if( this.box.comm_sense !== null)
            var com_str= this.box.comm_sense  > 5 || this.box.comm_sense === 'undefined'  ? 5 : this.box.comm_sense
            this.item =  require('@/assets/images/sub/m2/signal'+com_str+'.png')
         // var mnp_boundary = this.box.mnp_boundary
          var e_content = this.box.content
          if(e_content !== null && e_content !== undefined && e_content !== 0){ 
              this.alarm_icon = "bg_card_color_red";
          }else{
              this.alarm_icon = "";
          }
          var day_color = ["circle color_03","circle color_03","circle color_03","circle color_03","circle color_03","circle color_03"] 
          
          if(this.box._5days !== null || this.box._5days === undefined) {
            var str_color = this.box._5days.split(",")
            for(let i=0; i < str_color.length; i++) {
              if(str_color[i] === "1"  ){
                  day_color[i] = "circle color_01" ; //붉은색
                }else{
                  day_color[i] = "circle color_02" ; //청색
                }
            }
          }

          this.color = day_color;
          // 
          /*
          this.$http.get(this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+to_date+'&limit=1').then((res1) => {
            //this.date_str = ['-','-','-','-','-','-','-','-','-','-']
           // this.mnf = []
           // this.rate = []
            //this.flow = []
            if(res1.data.results[0].length < 1) return
            var a = res1.data.results[0].length-1 ;
            var day_color = ["","","","","",""] 
            for(let i=0; i < 5; i++) {
                
               // var date =''
               if(a > i){
                 if(res1.data.results[0][a-i].measure_datetime.length > 6){
                   this.date_str.push( res1.data.results[0][a-i].measure_datetime.substr(5));
                 }else{ 
                    this.date_str.push( res1.data.results[0][a-i].measure_datetime)
                 }
                 if(mnp_boundary !== null  && res1.data.results[0][a-i].maximum_night_pressure !== null){
                    if( mnp_boundary > res1.data.results[0][a-i].maximum_night_pressure ){
                      day_color[i] = "circle color_01" ; //붉은색
                    }else{
                      day_color[i] = "circle color_02" ; //청색
                    }
                 }else{                       
                    day_color[i] = "circle color_03" ; //회색
                 }
                 if(mnp_boundary === null && res1.data.results[0][a-i].maximum_night_pressure !== null)
                       day_color[i] = "circle color_02" ; //청색

                    //this.color[i] = "red" ;
                 this.mnf.push( res1.data.results[0][a-i].maximum_night_pressure )   
               }
            }
            this.color = day_color;
            
          });
          */
       // });

    },
    beforeDestroy () {
    },
    components: {
    }
}


</script>

<style scoped lang="scss">
// 2022 new 추가
@import '@/assets/scss/base/variables';

.table_block {
  &:nth-child(even) {
    margin-right: 0;
  }
 // background-color:#dde9f3 ;
  
}
.table_wrap .table_block{
      padding: 1% 2.5%;
      // 2022 new 추가
      word-wrap: break-word;
      background-color: #172A46;
      border: 1px solid #172A46;
}
.table_wrap .table_block .con_box {
    margin-top: 1%;
    width: 50%;
    float: left;
    margin-bottom: 1%;
}
.table_wrap .table_block .con_box table td {
    padding: 12px 0;
    // 2022 new 추가
    color: $color-white;
    border-bottom: none;
}
.table_wrap .table_block .title_box {
  width: 40%;
  // 2022 new
  color: $color-white;
  padding-right:0;
  margin-right: 0;
}
.table_wrap .table_block .con_box .red {
 /* background-color: red; */
//  color : red;
//  2022 new 추가
color: $color-red;
background-color:$color-content-bg;

}
.table_wrap .table_block .title_box h3{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    // 2022 new
    color: $color-white;
}

.table_wrap .table_block .title_box .title_con .title_img{
    width: 42px;   
    float :  left;
    display: inline-block;
    
}
.table_wrap .table_block .title_img.m2_press {
  margin: 15px -10px 0 10px;
}
.table_wrap .table_block .title_img.m2_press img{
 width: 28px;
 margin: 3px;
  
}
.table_wrap .table_block .title_box .ps{
    overflow: hidden;
   
    padding: 8px 0;
  /*  width: 100%; 
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;*/
}
.table_wrap .table_block .title_box .ps.bb0{
    border-bottom: none;
}
.table_wrap .table_block .title_box .ps > div{
    float: left;
}
.table_wrap .table_block .title_box .ps .title{
    width: 15%;
    font-size: 12px;
    color: #575757;
    line-height: 32px;
}
.table_wrap .table_block .title_box .ps .con {
    width: 18%;  
    // 2022 new 
    // text-align: center;
}
/* title 간격 2칸 */
.table_wrap .table_block .title_box .ps .title1{
    width: 45%;    
    font-size: 12px;
    color: #575757;
    line-height: 32px;
}
.table_wrap .table_block .title_box .ps .con1{
    width: 50%;
    // 2022 new 
    // text-align: center;
    margin-top: 1.5%;
}
.table_wrap .table_block .title_box .ps p {
  margin : 0;
}
.table_wrap .table_block .title_box .ps .con2{
    width: 15%;
    // 2022 new 
    // text-align: center;
    margin-top: 2%;
}

.table_wrap .table_block .title_box .ps .con1 .num{
    font-size: 23px;
    // 2022 new
    // color: #575757;
    color: $color-primary;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
}
.table_wrap .table_block .title_box .ps .con2 .num{
    font-size: 20px;
     // 2022 new
    // color: #575757;
    color: $color-primary;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
}
.unit{
    font-size: 14px;
    color: #BCBCBC;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
}                
.table_wrap .table_block .title_box .ps .con1 .unit{
    font-size: 14px;
    // 2022 new
    // color: #BCBCBC;
    color: $color-white;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
}                
.table_wrap .table_block .title_box .ps .con .num{
    font-size: 23px;
    color: #575757;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
}
.table_wrap .table_block .title_box .ps .con .unit{
    font-size: 14px;
    color: #BCBCBC;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
}
.table_wrap .title_text_wrap.ps2{
     float: right;
     margin-top: 10px;
     width: 80%;
}
/*
.table_wrap .table_block .title_box.ps{
 margin-top:1.5%;
}
*/
.table_wrap .table_block .title_box .ps .con1 h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0px;
}
.table_wrap .table_block.bg_card_color {
  // 2022 new
  // background-color: #dfdfdf;
}
// 2022 new 추가
.table_wrap .table_block .title_box .ps .con1 .date{
    color: $color-primary;
}
.table_wrap .table_block .con_box table th.bg{
  color: #999fad;
  background-color: #314062;
}
.table_wrap .table_block .con_box table tr{
  background-color: $color-content-bg;
  border-bottom: 1px solid $color-secondary;
}
// 2023
// 컴포넌트 개별적용.
.items {
  padding: 16px;
  height: 210px;
  box-sizing: border-box;
  border: 1px solid #707070;

  .item-head {
    display: flex;
    justify-content: space-between;

    &--left {
      h3 {
        font-size: 24px;
        color: white;
        line-height: 1;
        margin: 0 0 6px 0;

      }

      p.date {
        margin: 0;
        font-size: 14px;
        color: white;
        opacity: .6;
      }
    }
  }

//   .item-content {
//     display: flex;
//     padding-top: 16px;

//     &--left {
//       width: 212px;
//       // 2023
//       // padding-top: 32px;

//       li {
//         font-family: 'Roboto', sans-serif;

//         &:not(:last-child) {
//           margin-bottom: 16px;
//         }
//       }

//       span.unit {
//         font-size: 15px;
//         line-height: 1;
//         color: white;
//       }

//       p.num {
//         font-size: 44px;
//         font-weight: bold;
//         color: #01AF81FF;
//         line-height: 1;
//       }
//     }

//     &--right {
//       width: 100%;
//       position: relative;

//       .btn_next {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         z-index: 1;
//         cursor: pointer;
//       }
//     }
//   }
// }
.item-content {
    display: flex;
    // padding-top: 16px;

    &--left {
      width: 252px;
      padding-top: 32px;

      li {
        font-family: 'Roboto', sans-serif;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      span.unit {
        font-size: 15px;
        line-height: 1;
        color: white;
      }
      p.num {
        font-size: 40px;
        font-weight: bold;
        color: #01AF81FF;
        line-height: 1;
        float:right;
      }
    }

    &--right {
      // width: 100%;
      // position: relative;
      position: absolute;
      width: 200px;
      right: 16px;
      bottom: 16px;

      .btn_next {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
      }
      p {
          // margin-top: 0;
          // margin-bottom: 0.5rem;
          position: absolute;
          bottom: 30px;
          right: 9px;
          margin-bottom:0;
      }

    }
  }
}
.status_circle > div {
    // float: left;
    width: 11.5%;
    font-size: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    width: 24px; 
    
}
// .status_circle > li a, 
.status_circle > div::after {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #707070;
    display: block;
    position: absolute;
    left: 50%;
    top: 20px;
    -webkit-transform: translate(-45%,0);
    transform: translate(-45%,0);
}
.status_circle > div {
    // float: left;
    width: 11.5%;
    font-size: 12px;
    text-align: center;
    position: relative;
    display: inline-block;
    width: 24px; 
    
}
// .status_circle > li a, 
.status_circle > div::after {
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #707070;
    display: block;
    position: absolute;
    left: 50%;
    top: 20px;
    -webkit-transform: translate(-45%,0);
    transform: translate(-45%,0);
}
.status_circle ul{
  display: flex;
    padding-left: 0;
    list-style: none;
    position: absolute;
    bottom: 0;
    right: 0;
}
.status_circle ul li{
  display: list-item;
    text-align: -webkit-match-parent;
    margin-right: 6px;
    list-style: none;
}
.status_circle ul li a{
  display: block; 
    line-height: 0.6 !important;
    position: relative;
    display: block;
    padding: 9px;
    font-size: 12px;
    z-index: 3;
    color: #fff;
    border-radius: 20px;
    background-color: #707070;
    // border-color: #28AF81;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    line-height: 0.6 !important;
    line-height: 0.6 !important;
}
.status_circle .color_01{
                    background: #EB5B5B;
                }
.status_circle .color_02{
                    background: #638CFB;
                }

.items{
  background-color: #172A46;
}
.row{
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;
}
</style>

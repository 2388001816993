<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
<!--                        <li class="breadcrumb-title">Map Frequency Comparison</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/pressure-monitoring')" href="#">Pressure Monitoring </a></li>
                      
                        <li class="breadcrumb-item active">Map Water Pressure</li>
                    </ol>
                </li>
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div >
             <b-row style="margin: 0;">
                 <b-col :cols="msize" style="padding: 0 10px 0 0;">
                    <div class="search_div press_m2_map">
                        <span>    
                        <span class="spn1"> <b-select size="sm" v-model="pressure_value_type" :options="pressure_value_opt" ></b-select></span>                       
                        
                        <span class="status " style="background-color:#172A46;padding:12px 5px ;position:relative;">
                            <input type="radio" id="check_01" name="radio01" v-model="value_type" value='hydraulic' />
                            <label  for="check_01" >Elevation</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="value_type" value="bar">
                            <label  for="radio_02">bar</label>
                            <input type="radio" id="radio_01" name="radio01"  v-model="value_type" value="kgf">
                            <label  for="radio_01">kgf</label>                            
                            <input type="radio" id="radio_03" name="radio01"   v-model="value_type" value="psi" >
                            <label  for="radio_03">psi</label>
                        </span>
                        </span>
                        <span style="float:right;width:170px;padding-right: 20px;" v-if="show_day">
                            <!--b-input size="sm" type="text" placeholder="datetime" v-model="flow_date"  disabled /-->
                            <b-input size="sm" v-model="flow_date" type="date" @change="change_flow_date($event)" class="input_date m-0" style="padding: .25rem .25rem;"/>
                            <span style="position:absolute;top:0px;right:-240px;">
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-10,1)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-1,1)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(1,1)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(10,1)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>
                           
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="add_marker()" ><i class="mdi mdi-map-marker "></i></button>
                             <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="edit_marker()"><i class='mdi mdi-pencil' ></i></button>
                             </span>
                       </span>
                       <span style="float:right;width:170px;padding-right: 40px;" v-if="!show_day">
                            <b-input size="sm" type="text" placeholder="datetime" v-model="flow_date_min"  disabled />
                             <span style="position:absolute;top:0px;right:-210px;">
                             <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-30*48)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(-10)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(10)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow_min(30*48)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>

                             <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="add_marker()" ><i class="mdi mdi-map-marker "></i></button>
                             <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="edit_marker()"><i class='mdi mdi-pencil' ></i></button>
                             </span>
                       </span>
                       

                        
                    </div>
                    
          
            <div style="overflow-y: scroll;" :style="{height: contents_height+'px'}">

                     <Map
                         ref="map"
                        :visible_gisload_button="true"
                        :visible_size_side_view_button="true"
                        :widx="$route.query.widx" 
                        :press_icon1=true                     
                        w_type="111"
                        @toggle_visible_Hydraulic_type = "toggle_visible_Hydraulic_type"
                        @set_map_gisload="set_map_gisload"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @set_info="set_info"
                        @go_home="go_home()"
                        @size_view="size_view"
                        @onLeakMapClick="onLeakMapClick()"
                        @hydraulic_info_popup = "hydraulic_info_popup"
                     />
                  
            </div>   

                
            </b-col>
             <b-col  v-if="v_chart" :cols="tsize" style=" padding: 0px 2px 0 0; ">
                    <div class="corr" style="width: 99%;"  >
                      <label for="radio1_01" style="font-size:12pt;">Hydraulic Point : </label>
                      <label for="radio1_01">Root Point</label> <input type="text" id="corr_sound2" name="radio1_01"  v-model="corr_sound2" disabled>
                      <label for="radio1_01">Brench Point</label> <input type="text" id="corr_sound1" name="radio1_01"  v-model="corr_sound1" disabled>
                      <label for="radio1_01">Distance</label> <input type="text" id="corr_text3" name="radio1_01"  v-model="corr_text3" maxlength="10" style="width: 100px;">                    
                      <button variant="outline-danger" @click="hydraulic_reg" class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" style=" margin: 5px 5px; background-color: #28AF81; ">
                          ADD</button>
                   </div>
                   <b-row style=" padding-left: 0px;background-color:#172A46; margin: 0px 10px 0 -5px; ">
                       <b-col style="padding-top: 5px; padding-bottom: 5px;">
                          <div class="specification_area" style=" background-color:#172A46; padding: 15px 10px 10px 10px ;height:285px;">
                              <b-form inline>
                                  <b-button-group class="mr-2 specification_btn">
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='1' @change="change_selected_ts_flow($event)">
                                          <span>1D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='3' @change="change_selected_ts_flow($event)">
                                          <span>3D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='7' @change="change_selected_ts_flow($event)">
                                          <span>7D</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='30' @change="change_selected_ts_flow($event)">
                                          <span>30D</span>
                                      </b-radio>
                                  </b-button-group>
                                  <b-input size="sm" v-model="flow_date1" type="date" @change="change_flow_date($event)" class="input_date m-0"/>
                            
                              </b-form>
                                 <span class="btn_pre_m2">
                                   <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-1,2)"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                                   <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(1,2)"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                                  </span>
                         <div class="status m2press" >
                            <input type="radio" id="c_radio_02" name="radio02"  checked v-model="vchart" value="bar">
                            <label for="c_radio_02">bar</label>                             
                            <input type="radio" id="c_radio_01" name="radio02"  v-model="vchart" value="kgf">
                            <label for="c_radio_01">kgf</label>
                            <input type="radio" id="c_radio_03" name="radio02"   v-model="vchart" value="psi" >
                            <label for="c_radio_03">psi</label>
                          </div>
                           
                          <div :key="listKey" > <button variant="outline-danger" @click="show_press_info(tag1)" style="border:0;   background: #172A46;"><img src="@/assets/images/new/ic_list_info.svg"  style="width:25px; height: 25px;" alt="map" class="infoimg" /></button>No : {{tag_name1}} </div>   
                                <!--PressMinChart :url="real_url1" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:5px;"   /-->  
                               <pressChartData1 :url="real_url1" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:0px;left:-10px;"  />  
                          </div>
                
                       </b-col>
                   </b-row>

                   <b-row style=" padding-left: 0px; background-color:#172A46; margin: 10px 10px 0 -5px;"  >
                       <b-col style="padding-top: 10px; padding-bottom: 5px;">
                        <!-- <div class="status press" >
                            <input type="radio" id="radio_01" name="radio01"  v-model="vchart" value="kgf">
                            <label for="radio_01">kgf</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart" value="bar">
                            <label for="radio_02">bar</label>
                            <input type="radio" id="radio_03" name="radio01"   v-model="vchart" value="psi" >
                            <label for="radio_03">psi</label>
                          </div> -->
                          <div class="specification_area" style=" background-color:#172A46; padding: 0px 10px 10px 10px ;height:260px;" >
                              <div>  <button variant="outline-danger" @click="show_press_info(tag2)" style="border:0;   background: #172A46;"><img src="@/assets/images/new/ic_list_info.svg"  style="width:25px; height: 25px;" alt="map" class="infoimg" /></button>No : {{tag_name2}} </div>
                               <pressChartData1 :url="real_url2" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:0px;left:-10px;"   />  
                                   
                           </div>
                             
                       </b-col>
                   </b-row>
                   <b-row style="  padding-left: 0px;background-color:#172A46; margin: 10px 10px 0 -5px;">
                       
                       <b-col style="padding-top: 0px; padding-bottom: 5px;">
                          <!--<div class="status press" >
                            <input type="radio" id="radio_01" name="radio01"  v-model="vchart" value="kgf">
                            <label for="radio_01">kgf</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart" value="bar">
                            <label for="radio_02">bar</label>
                            <input type="radio" id="radio_03" name="radio01"   v-model="vchart" value="psi" >
                            <label for="radio_03">psi</label>
                          </div> -->
                          <div class="specification_area" style=" background-color:#172A46; padding: 10px 10px 10px 10px ;height:260px;" >
                               <div><button variant="outline-danger" @click="show_press_info(tag3)" style="border:0;   background: #172A46;"><img src="@/assets/images/new/ic_list_info.svg"  style="width:25px; height: 25px;" alt="map" class="infoimg" /></button>  No : {{tag_name3}} </div>
                                
                                <pressChartData1 :url="real_url3" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:0px;left:-10px;"   /> 
                           </div>
                         
                       </b-col>
                   </b-row>
                 </b-col>

           </b-row>
           
         </div>
     <DlgM2MoniSetting ref="m2_setting" />
     <DlgHydraulicPointInfo ref="hydraulic_info"   @init_draw="init_draw()" />
    </div>
</template>

<script>
//동수두 팝업창 등록
import DlgHydraulicPointInfo from '@/components/popup/DlgHydraulicPointInfo'
import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';
import pressChartData1 from '@/components/chart/pressChartData2_temp' ;
import DlgM2MoniSetting from '@/components/popup/DlgM2MoniSetting' 
//import PressMinChart from '@/components/PressMinChart' ;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import * as d3 from 'd3'
import d3tip from "d3-tip";
 d3.tip = d3tip;

export default {
    name: 'HydraulicPointRegMap',
    props: [
        'w_type'
    ],
    data() {
        return {
            selected_ts_flow: '1',    
            show_day : true,    
            value:'',
            loaded_ts_flow : true,
            v_chart : true,
            vchart : 'bar',
            contents_height: 600,
            waveform_height: 42,
            mnfchart_height: 160,
            wavesurfer: null,
            layer_all_visible: true,
            chart_i : 0 ,
            real_url1: '',
            real_url2: '',
            real_url3: '',
            tag1 :'',
            tag2 :'',
            tag3 :'',
            psr_path1 : '',
            psr_path2 :'',
            psr_path3 : '' ,
            tag_name1 : '',
            tag_name2 :'',
            tag_name3 : '' ,    
            msize:'7',
            tsize:'5',        
            options: {
            },
            file: ' ' ,
            flow_date_min : this.$route.query.mea_date +" 03:00",
            flow_date : this.$route.query.mea_date,
            flow_date1 : this.$route.query.mea_date,
            elevation_yn : null,
            value_type : 'bar',
           // pressure_value_type :  this.$store.setting.pressure.legend.value,
            pressure_value_type : 'max',
            pressure_value_opt: ['10min','avg(3H)','avg(3.5H)','avg(4H)','avg' , 'min', 'max','mnp', ],   
            measuredate: null,  
            start_idx:0,
            max_idx:2, //(0,1,2 까지 볼수 있다.)
            listKey:0,
           coord_1:'',
           coord_2:'',
           corr_sound2 :'',
           corr_sound1 :'',
           corr_text3:'',
        }
    },
    methods: {
        hydraulic_reg(){
            this.draw_line();
            var l_v_layer =  this.$refs.map.corre_layer ;
            if(l_v_layer != null) {
              var source_h = l_v_layer.getSource();
              var fs = source_h.getFeatures();              
             // let param = {} ;              
              var len =  fs[0].getGeometry().getLength();
              this.corr_text3 = len;
             // param["length"] = len ;
              //A거리 = (B강도-A강도+지점간거리)/2              
              this.cal_length = len/2 ; //20
             
              this.$refs.map.draw_point( fs[0].getGeometry(), this.cal_length) ;         
              this.correction_save();
            } 
        },
        draw_line(){
           if(this.corr_sound1 === '' || this.corr_sound2 === '' ) {
               alert('No data is selected.') ;
               return;
           }
           if(this.coord_1 !== this.coord_2 ){             
               this.$refs.map.draw_linestring( this.coord_1 , this.coord_2);            
           }else{
               alert("Please select another location.") 
           }
        },
        correction_save(){
            this.$refs.map.edit_end();
          //db에 work_id, customer_id, member_id, leak1, leak2, line_geometry,length, leak_geometry,leak_length
             var l_v_layer =  this.$refs.map.corre_layer ;
            // //102 m2 모니터링누수음 시연용고객사 : 거제
           //alert(icoord1,icoord2)
            var param = {};
            var len = 0;
            var line_fe = null;
            var leak_fe = null ;
            if(l_v_layer != null) {
              var source_h = l_v_layer.getSource();
              var fs = source_h.getFeatures();  
               if(fs.length <2 ){
                 alert("No calculated data.") 
                 return;
               }            
              //for(var k=0; k< fs.length ; k++) {
               //  source_h.removeFeature(fs[k]);
                  len =  fs[0].getGeometry().getLength();
                  line_fe =  fs[0]
                  leak_fe =  fs[1]
              //}

              // len =  fs[0].getGeometry().getLength();
             
            }
            if(line_fe === null ||leak_fe === null ){
                   alert("No calculated data.") 
                return;
            }
            // this.$refs.map.draw_point( fs[0].getGeometry(), cal_length) ;
            //4326 으로 line에 대한 geometry정보를 변환한다.                
            var line_data= {type: 'add', feature:line_fe, code: 0} ; //라인을 4326으로 변환
            var line_fs_4326 = this.$refs.map.change_xy(line_data);
            var point_data  = {type: 'add', feature: leak_fe, code: 1} ; //포인트값을 4326으로 변환
            var leak_fs_4326 = this.$refs.map.change_xy(point_data)
                //라인을 4326으로 변환
        
            param["root_id"] = this.corr_sound2 ;       
            param["branch_id"] = this.corr_sound1 ;
            
          //  param["measure_datetime"] =this.flow_date;
           // param["branch_length"] = this.corr_text3 ;            
            param["branch_length"] = len ; 
            param["point_location"] = leak_fs_4326.feature.geometry.coordinates ;  
           // param["line_location"] = line_fs_4326.feature.geometry.coordinates ;  
                 
            var data = {type: 'add', geometry: leak_fs_4326.feature.geometry,line_geometry: line_fs_4326.feature.geometry,
                        params:param} ;

            this.$refs.hydraulic_info.insert_show(data);            
            //db에 work_id, customer_id, member_id, leak1, leak2, line_geometry,length, leak_geometry,leak_length
            /*
            this.$http.post(this.URL+'api/set_leak_logger_correlation',data).then((res) => {
                    console.log(res.data);
                  if(res.data.check ===1){ 
                    alert("Fail save."+res.data.msg)   
                  }else{
                    alert("Done save.");
                    this.draw_line_clear();
                    this.map_draw_correl() ;
                    this.$refs.correlations_list.setUrl(this.URL+"api/get_leak_logger_correlation_list_ex?customer_id="+sessionStorage.customer_id);  
                  }
            });
            */
        },    
        init_draw(){
            this.corr_sound1 = '';
            this.corr_sound2 = '';
            this.corr_text3 = "";
            this.$refs.map.draw_linestring(null,null);
            this.add_marker();
        }, 
        hydraulic_info_popup(id){
            this.$refs.hydraulic_info.show(id);
        },
         //동수두 변곡점을 추가한다.
         add_marker(){            
            this.$refs.map.init_reload_hydrolic_layer() ;
         },
         change_map_mode(){
           this.$refs.map.map_mode = 'select'  
           this.$refs.map.edit_end() ;
         },
         edit_marker(){
            this.$refs.map.block_info = 2 ;
            this.$refs.map.init_draw_modify();
            this.$refs.map.edit_change('modify', 'Junction')
         },
         size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
         },
        show_press_info(idx) {
           // alert(idx)
            this.$refs.m2_setting.show(idx)
          //this.$refs.map.show_press_info(idx)
        },         
         toggle_visible_all_layer(a) {

                // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          /*
           this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
           if(this.$refs.map.layer_all_visible)
             this.$refs.map.leak_cal = 1;
           else
               this.$refs.map.leak_cal = 0;
            */
           if(a=== 1){
              // alert(a)
                this.$refs.map.leak_cal = 1;
           }else{
              //  alert(a)
                this.$refs.map.leak_cal = 0;
           }
           this.$refs.map.init_reload_leak_layer() ;

          /*
            this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')===100)
                    layer.setVisible(this.$refs.map.layer_all_visible)
            }
            */
        },
        chang_press_mapvalue_type() {          
          // 화면에 시간을 searching할 수 있도록 화면을 셋팅한다.
          if(this.pressure_value_type === "10min"){
              this.show_day =false
              this.$refs.map.time_30m = this.flow_date_min; //지도에서 분데이터를 가져간다.
             
          }else{
            this.show_day = true
            this.$refs.map.time_30m = this.flow_date; //지도에서 일데이터 (최종시간 그전날 데이터)
          }
          this.$refs.map.leak_cal = this.pressure_value_type;    //avg,min/max
          this.$refs.map.leak_val =  this.value_type;     //bar,psi,kgf
       
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;

        },  
       toggle_visible_Hydraulic_type() {
          // 처음엔 동수도 적용안한다. Hydraulic_type 데이터를 가져와 지도에 보여준다. 동수도 적용안한다.
          // All or Hydraulic 적용값 가져와 지도에 보여준다.
          this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
          if(this.$refs.map.layer_leak_visible)
            this.$refs.map.leak_type = 'HYDRAULIC';
          else
            this.$refs.map.leak_type = null;            
          
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;      
        },               
        addr_search(){
          this.$refs.map.go_search(this.value);
        },
        Enter_Check1(){
            this.addr_search();
        },
       change_date_flow(a,type){ // 1:지도 2.chart
          if( type === 1) {
            let default_date = new Date(this.flow_date.toLocaleString());
            default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
            this.flow_date = this.date_to_str(default_date) ;
             this.chang_press_mapvalue_type();
             
          }else{
            let default_date1 = new Date(this.flow_date1.toLocaleString());
            default_date1.setDate(default_date1.getDate()+Number(a)).toLocaleString();
            this.flow_date1 = this.date_to_str(default_date1);  
            this.get_time_series_flow()      
          }         
      },
      date_to_str(format,a){
          //a = 0 ,day, a=1 ㅡㅑㅜ
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 

          if(a === 1){
            var hour = format.getHours();
            if(hour<10) hour = '0' + hour; 
            var min = format.getMinutes();
            if(min<10) min = '0' + min; 
            return year + "-" + month + "-" + date +" "+hour+":"+min;
          }else{
            return year + "-" + month + "-" + date ;
          }
      },
       change_date_flow_min(a){
       
          let default_date = new Date(this.flow_date_min.toLocaleString());
           default_date.setMinutes(default_date.getMinutes()+Number(a)).toLocaleString() ;
          this.flow_date_min = this.date_to_str(default_date,1)
          this.chang_press_mapvalue_type();
          //this.get_time_series_flow()
        },
    
        change_selected_ts_flow(e) {
            this.selected_ts_flow = e
            this.get_time_series_flow()
        },
          get_time_series_flow() {
            this.loaded_ts_flow =false 
            this.real_url1 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            this.real_url2 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            this.real_url3 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
          
            this.loaded_ts_flow = true
        }, 
        Enter_Check(){
         //  if(event.keyCode == 13){
          this.go_search();  // 실행할 이벤트
        },
        on_leakage_info(idx) {
          this.$refs.map.show_leakage_info_pop(idx)
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        on_goto_map_click(idx) {
            this.$refs.map.get_extent('w_type=110&l_idx='+idx).then((data)=>{
                this.$refs.map.set_fit_extent(data)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
        },
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                  alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                     if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    this.$refs.map.layer_list.push(newlayer) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);

                } //for문 끝

           } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },

        onLeakMapClick(){
            let tag_id = this.$refs.map.leak_idx;
            let tag_name = this.$refs.map.leak_recov_idx
            let leak_coord = this.$refs.map.coord_map;
            this.chart_i = this.chart_i+1 ;
            if(this.chart_i%2 === 0){
                this.coord_1 = leak_coord; //location
                this.corr_sound1 = tag_id;//id    
             //   this.strength2 = strength

            }else{
                this.coord_2 = leak_coord; //location
                this.corr_sound2 = tag_id; //id    
             //   this.strength1 = strength ;
            }
            if(this.chart_i%3 === 1){
                this.tag_name1 = tag_name ;    
                this.tag1 = tag_id ;
                this.flow_date1 = this.flow_date ;
                this.real_url1 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            }else if(this.chart_i%3 === 2){
               this.tag_name2 = tag_name ;    
               this.tag2 = tag_id ;
               this.real_url2 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            }else{
                this.tag_name3 = tag_name ;    
                this.tag3 = tag_id ;
                this.real_url3 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
             }


        },
        updateKey: function () {
          this.listKey+= 1
       },
  
    },
    mounted () {
        this.contents_height = window.innerHeight - 120;
         setTimeout(()=>{
          let setting = this.$store.setting
          if(setting) {
             
             // this.pressure_value_type = this.$store.setting.pressure.legend.value
              this.pressure_value_type = "10min"
             // this.change_date_flow(-1,1);
          }
        }, 1000);
      setTimeout(()=>{
            var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
            if( elem !== undefined ){
            if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();         
            }
         }
       }, 200)
    },
    watch: {
      elevation_yn: function() {
         this.toggle_visible_Hydraulic_type();
      },
    
      pressure_value_type(){//min,max,avg,
        this.chang_press_mapvalue_type() ;
      },
      value_type(){ //bar,psi,kgf
         if(this.value_type === 'hydraulic'){
            this.toggle_visible_Hydraulic_type();
         }else{
            this.$refs.map.leak_type = null;     
            this.chang_press_mapvalue_type() ;
         }
      },
     v_chart: function() {
        if(this.v_chart){
             setTimeout(()=>{
               this.updateKey();
               this.real_url1 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url2 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
               this.real_url3 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+'&count='+this.listKey+"#"+this.vchart;
             }, 500)
        }
      },
      vchart() {
            //setTimeout(()=>{
                  // this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart;
                  // this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart;
                  // this.real_url3 = this.URL+this.psr_path3+'&retType='+this.vchart;
             //  }, 500)
            this.loaded_ts_flow =false 
            this.real_url1 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            this.real_url2 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag2)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            this.real_url3 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag3)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
          
            this.loaded_ts_flow = true
          
      },

    },
    components: {
      Map: Map,
      pressChartData1:pressChartData1,
      DlgM2MoniSetting : DlgM2MoniSetting,
      DlgHydraulicPointInfo:DlgHydraulicPointInfo,
       // PressMinChart:PressMinChart,
       // PulseLoader: PulseLoader,
    }
}
</script>

<style  scoped>
.infoimg{
    width: 15px;
}
.press_m2_map{
    position: absolute;
    left:210px; 
    width: 580px;   
    top: 5px;
    
    z-index: 110;
     
}
.spn1 {
    float: left;
    padding: 2px;
}
.spn2 {
    float: right;
    padding: 2px;
}
.m1-map{
    position: relative;
    top : 7px;
    margin-left: 15px;
    background: #ececec;
    padding: 6px 0px 6px 7px;
    border:solid 0.1em #ccc;
    border-radius: 5px;
}
.real_chart .wavchart .highcharts-container .highcharts-root {
    width: 100%;
}
.btn_pre_m2 {
    position: absolute;
    top: 20px;
    left:420px;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}
.status.m2press {
    position: absolute;
    top: 20px;
    right: 25px;
}
</style>

<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                   <li>
                     <ol class="breadcrumb with--map">
                       <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>                      
                       <li class="breadcrumb-item active">Log History</li>
                     </ol>
                   </li>
                 
           
              </ul>
         </div>
     <!-- tom-bar-end -->
    

        <div class="right_area_01 right_area_block-detail">
          <b-row>
              <b-col cols="8">
                  <b-row style="margin: 0;">
                      <b-col style="padding: 0;">
                        <div class="specification_area" style=" background-color:#0A192F; padding: 20px 30px 10px 10px;height:420px;">
                              <div class="log_box" >
                                <span class="title_value">CUSTOMER</span>
                              </div>
                              <div style="position:absolute; top: -50px; right: 0px; width:350px;padding:10px;">
                                  <!--span  >SERVER NAME : <b-select  size="sm" v-model="server_id" :options="server_name" style="width:200px;"></b-select>
                                  </span-->
                              </div>
                                
                              <!-- <div style="background-color:white; padding: 20px 5px;"> -->
                              <div style="padding: 20px 5px;">
                                <vue-good-table ref="table_customer"
                                    :columns="columns1"
                                    :rows="table_customer"
                                    :line-numbers="true"
                                    :max-height="contents_height/2-30 +'px'"
                                    :fixed-header="true"
                                    styleClass="vgt-table condensed"
                                    @on-row-click="onRowClick"
                                      
                                 />
                              </div>
                                                  
                          </div>
                      </b-col>
                  </b-row>
                  <b-row style="margin: 10px 0 5px 0;">
                      <b-col style="padding: 0;">
                           <!-- <div class="specification_area" style=" background-color:white; padding: 20px 20px 10px 20px ;height:420px;"> -->
                           <div class="specification_area" style=" padding: 20px 20px 10px 20px ;height:420px;">
                          <div class="log_box" >
                                 <span class="title_value">MEMBER</span>
                              </div>
    
                                
                              <!-- <div style="background-color:white; padding: 20px 5px;"> -->
                              <div style="padding: 20px 5px;">
                                <vue-good-table ref="table_member"
                                    :columns="columns2"
                                    :rows="table_member"
                                    :line-numbers="true"
                                    :max-height="contents_height/2-30+'px'"
                                    :fixed-header="true"
                                      styleClass="vgt-table condensed"
                                 />
                              </div>
                                                  
                          </div>

                      </b-col>
                  </b-row>
              </b-col>
              <!-- <b-col cols="4" style="background-color:white;margin-bottom:5px;"> -->
              <b-col cols="4" style="margin-bottom:5px;">
                <div class="log_box" style="padding: 20px 5px 0 10px; ">
                  <span class="title_value">HISTORY</span>
                </div>
                <!-- <div style="background-color:white; padding: 20px 5px;"> -->
                <div style="padding: 20px 5px;">
                  <!--<vue-good-table ref="table"
                      :columns="columns"
                      :rows="table_log"
                      :line-numbers="true"
                      :max-height="contents_height+ 70 +'px'"
                      :fixed-header="true"
                        styleClass="vgt-table condensed"
                  /> -->
                    <ServerSideTable ref="table_log" 
                                  :line-numbers="true"
                                   :fixed-header="true"
                                   :columns="columns"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"                                   
                                   :select="true"  
                                   :search="true"                                 
                                   :adv_search="adv_search"
                                   :max-height="contents_height+ 70 +'px'"                                
                              
                  />
                 </div>
              </b-col>
          </b-row>
        </div>
    </div>
</template>

<script>

import * as d3 from 'd3'
import d3tip from "d3-tip";
import ServerSideTable from '@/components/table/ServerSideTable2';
//import PressMinChart from '@/components/PressMinChart' ;

 d3.tip = d3tip;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'


export default {
    name: 'LogHistory',
    props: [
    ],
    watch: {
    },
    data() {
        return {
            bar_url : '',
            real_url : '',
            contents_height: 600,
            mnfchart_height: 300,
            realtimechart_height: 300,
            tag : this.$route.query.tag,
            tag_name : '',
            selected_ts_flow: '1',
            flow_date: '2020-11-01',
            loaded_ts_flow: false,
            selected_ts_mnf: '1',
            mnf_date: '2020-11-01',
            columns: [
                {label: 'Date', field: 'date_str'},
                {label: 'Log', field: 'flow'},
                {label: 'Leak', field: 'mnf'},
                {label: 'Pressure', field: 'rate'},
            ],    
            columns1: [
                {label: 'Name', field: 'name'},             
                {label: 'Code', field: 'customer_no'},
                {label: 'Country', field: 'country_no'},
                {label: 'Region', field: 'region_no'},
                {label: 'License', field: 'sw_license_type'},
                {label: 'R_Date', field: 'register_datetime'},
                {label: 'S_Date', field: 'service_start_date'},
                {label: 'E_Date', field: 'service_end_date'},
            ],            
            columns2: [
                {label: 'Name', field: 'name'},
                {label: 'ID', field: 'email'},
                {label: 'Status', field: 'status'},
                {label: 'Customer', field: 'customer_name'},
                {label: 'R_Date', field: 'register_datetime'},
                {label: 'Count', field: 'count'},
                {label: 'Last Date', field: 'last_date'},
                {label: 'Leak', field: 'Leak'},
            ],      
            table_customer: [],
            table_member: [],
            table_log: [],
            ts_mnf: [],
            activeCheckIndex: null,
            server_name:[{value:1, text :"KOREA SERVER"},
                         {value:2, text :"INDONESIA SERVER"},
                         {value:3, text :"BATNAM SERVER"},
                         {value:4, text :"TANZANIA SERVER"},
                        ],
            server_id : 1,
            clicked_l_idx: -1,
            adv_search: [
            {
              field: 'leak_result',
              control: 'select',
              value: null,
              //'Leak','Leak Inside','Usage','Other'
              options: [
                  { value: null, text: 'Leak Result' },
                  { value: 'RT_LEAK', text: 'Leak' },
                  { value: 'NO_LEAK', text: 'No Leak' },
                
              ]
          },
            {field: 'measure_datetime', control: 'date', value: null, value2: null, placeholder: 'From measure_datetime', placeholder2: 'To measure_datetime'},
      ],
        }
    },
    methods: {
         goRouter(path) {
            this.$router.push({path: path, query: { tag: this.tag }})
        },
     onRowClick(params) {
      //선택된 색상 보여지게
   //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
      if(this.clicked_b_row != null){
        this.clicked_b_row.bgColor = '';
    //  //  this.clicked_b_row.style.backgroundColor = '';
     }
    
      if(params.event.target.nodeName==='TD'){
          params.event.target.parentElement.bgColor  = 'ebeff2';          
          this.clicked_b_row = params.event.target.parentElement ;
      }else  if(params.event.target.nodeName==='IMG'){
           params.event.target.parentElement.parentElement.parentElement.bgColor = 'ebeff2';      
          this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
      }else{
          params.event.target.parentElement.parentElement.bgColor = 'ebeff2';      
          this.clicked_b_row = params.event.target.parentElement.parentElement ;
      }
      
/*
     if(this.clicked_b_row != null){
       this.rowStyleClassFn(params.row, 1);
     }
      this.rowStyleClassFn(params.row, 0);
 */    
    //  this.clicked_b_row = params.row
  
      this.clicked_l_idx = params.row.customer_no
      this.table_member = []; 
      this.$http.get(this.URL+'api/get_member_list?customer_no='+this.clicked_l_idx).then((rows1) => {
           this.table_member = rows1.data.list;
      })
       
    },




        go_search(value) {

            if(value===undefined) return;

                //left메뉴 데이터를 뿌려준다.
   
     
        },
   
   
    },
    mounted () {
       this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175
       
       this.customer_name = []         
         //  this.server_name = []         
        /*
        this.$http.get(this.URL+'api/get_server_list').then((rows) => {
          if((!rows)||(rows.data.list<=0)) return;
          for(let i=0; i<rows.data.list.length; i++) {
            this.server_name.push({value:rows.data.list[i].idx, text :rows.data.list[i].server_name}) ;
          }          
        })
        */
        this.$http.get(this.URL+'api/get_customer_list').then((rows) => {
          if((!rows)||(rows.data.list<=0)) return;
          for(let i=0; i<rows.data.list.length; i++) {
            this.table_customer = rows.data.list;
            if(i=== 0){
                this.$http.get(this.URL+'api/get_member_list?customer_no='+rows.data.list[i].customer_no).then((rows1) => {
                   this.table_member = rows1.data.list;
                })
            } 
          }
          
        })
       //left메뉴 데이터를 뿌려준다.
       this.box = [] ;
       var select_id= 0 ;
       this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+sessionStorage.select_customer_id).then((res) => {
          if(res.data.results[0].length <1) return
        
          for(let i=0; i<res.data.results[0].length; i++) {
          
            var abox = {};
          //  abox = {'name': rows.data.rows[i].name, 'mnf': Math.round(Number(rows.data.rows[i].mnf)) , 'flow':  Math.round(Number(rows.data.rows[i].flow)) ,  'status': 'blue' } ; 
            var press_data = res.data.results[0][i].water_pressure === null ? "" :  res.data.results[0][i].water_pressure.toFixed(2)
            var last_date = res.data.results[0][i].last_measure_datetime === null ? "" :  res.data.results[0][i].last_measure_datetime
            abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id ,  'device_sn':res.data.results[0][i].device_sn ,
            'flow':  last_date+"/ p_data :  "+press_data ,  'date':  last_date } ; 
            this.box.push(abox) ; 
          
            select_id = 0;
            if(this.$route.query.tag === res.data.results[0][i].id){
               select_id = i;
            }
          }
      
        //  this.get_time_series_mnf()
        //  this.get_time_series_flow()
       })
       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
               
               if(select_id>-1){
                  //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
               //   this.updateCheckIndex(select_id)
               }
           }
         }
       }, 500)
   
    },
    beforeDestroy () {
    },
    components: {  
      ServerSideTable: ServerSideTable,
    }
}
</script>

<style lang="scss">
.presschart_m2_d .wavchart  {
  height: 350px;
  width: 98%;
}
.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // background: #fff;
  margin-bottom: 10px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}

.specification_btn .btn-group-toggle + .btn-group-toggle {
  //margin-left: 5px;
}
.specification_btn .btn-secondary{
  float: left;
  width: 42px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 2px;
  background: rgba(35,41,47,0.4);
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
  border: 0;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background: rgba(35,41,47,0.8);
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}


.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 735px;
  overflow-y: scroll;
}
table.vgt-table.condensed td {
      padding: .188rem .15rem;
}
.btn_pre{
 position: absolute;
    top: 0;
    right: 110px;
   // background: #4C5055;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
 }
 .map-button.icon_btn.btn.btn-sm{
   padding-top: 4px;
 }
 
</style>

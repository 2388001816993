<template>
    <div>
        <!-- top-bar -->
        <!-- 2022 new -->
         <div ref="navbar" class="navbar-custom">
          <!-- 2023 -->
                 <!-- <h4 class="content-title">Monitoring Detail</h4> -->
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                   <li>
                     <ol class="breadcrumb with--map"> 
                       <!--                              <li class="breadcrumb-title">Monitoring Detail</li>-->
                       <!--                              <li class="breadcrumb-bar">|</li>-->
                       <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                       <li class="breadcrumb-item"><router-link to="/block-monitoring">{{$t('menu.monitoring')}}</router-link></li>
                       <li class="breadcrumb-item active">{{$t('pressure.specification')}}</li>
                     </ol>
                   </li>

                 </ul>
         </div>
     <!-- tom-bar-end -->
        <search-left-type3 :box="box" :activeCheckIndex="activeCheckIndex" @updateCheckIndex="updateCheckIndex" @go_search="go_search"  @on_goto_map_click = "on_goto_map_click"/>
        <div class="right_area right_area_01 right_area_block-detail" style="margin-left:15px;width:78%;">
          <b-row>
              <b-col cols="8">
         <!-- 탭으로 화면 합치기 --> 
               <b-tabs content-class="press_detail"  v-model="tabIndex"    style="">
                <b-tab :title="$t('water_flow.tab_title1')" style="padding:0;" active>                 
                  <b-row style="margin: 0;">
                      <b-col style="padding: 0;">
                        <div class="specification_area" style=" padding: 20px 20px 10px 20px ;min-height: 430px;height: calc(50vh - 80px);">
                          <div class="specification_box" ref="button_group_mnf" style=" background: #172A46;">
                              <b-form inline>
                                  <b-button-group class="mr-2 specification_btn">
                                      <b-radio :button="true"  size="sm" v-model="selected_ts_mnf" value='30' @change="change_selected_ts_mnf($event)">
                                          <span>1M</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='60' @change="change_selected_ts_mnf($event)">
                                          <span>2M</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='90' @change="change_selected_ts_mnf($event)">
                                          <span>3M</span>
                                      </b-radio>
                                      <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='180' @change="change_selected_ts_mnf($event)">
                                          <span>6M</span>
                                      </b-radio>
                                  </b-button-group>
                                  <b-input size="sm" v-model="mnf_date" type="date" @change="change_mnf_date($event)" class="input_date btn-gray-date m-0 btn-gray-date" />
                                  <!--span class="title_value" style="margin: 5px 0 0 70px;">MNF Monitoring</span-->

                              </b-form>
                                  <!--span class="btn_pre" style="margin: 5px 0px 0px -60px;">
                                   <button class="" @click="change_date_mnf(-1)" style="width: 20px;height: 20px;line-height: 8px;margin: 0;text-indent: -4px;"><i class="mdi mdi-menu-left mdi-24px" ></i></button>
                                   <button class="" @click="change_date_mnf(1)" style="width: 20px;height: 20px;line-height: 8px;margin: 0 1em 0 .5em;text-indent: -4px;"><i class="mdi mdi-menu-right mdi-24px" ></i></button>
                                  </span-->
                                  <span style="float:left;margin: -25px 0px 0 380px;">
                                   <span style="float:left;"> 
                                   <button class="" @click="change_date_mnf(-1)" style="width: 20px;height: 20px;line-height: 6px;margin: 0;text-indent: -4px;    border: none;"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                                   <button class="" @click="change_date_mnf(1)" style="width: 20px;height: 20px;line-height: 6px;margin: 0 1em 0 .5em;text-indent: -4px;    border: none;"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                                   </span>
                                   <span class="title_value" style="margin: 0px;color:#cfcfcf;">MNF Monitoring</span>
                                  </span>
                            <div class="btn-primary btn-g-ch" style=" width: 60px; border-radius: 4px;margin: 5px 0 0;" @click="goRouter('/block-monitoring-detail-tab')">Chart</div>
                            <!-- <div class="chart_btn" @click="goRouter('/block-monitoring-detail-tab')">Chart</div> -->
                             <div class="status" style=" right: 200px;" >                                            
                              <span style="float: right;margin-right: 30px;margin-top: -21px;" @click="change_min">
                               <b-input v-model="min" style="width:60px;height:20px;margin:0 0 0 10px;padding:10px 0 10px 10px ;text-align:center;"
                                 type="number" step="10" class="input_spin" />
                             </span> 
                             </div>
                          </div>
 <!--                         
                          <div ref="mnf_chart" :style="{height: mnfchart_height+'px'}">
                              <svg/>
                          </div>
 -->
                            <div >
                              <BlockMonitoringBarMnfChart :url="bar_url" ref="barchart" id="barchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:20px;"  @onBarClick="onBarClick"   />  
                           </div>                          
                          </div>
                      </b-col>
                  </b-row>
                  </b-tab>
                  <b-tab :title="$t('water_flow.tab_title2')" >
                    <b-row style="margin-left: 0; margin-right: 0;">
                        <b-col style="padding: 0;">
                
                            <!-- <div class="specification_area" style=" background-color:white; padding: 20px 20px 10px 20px ;height:420px;"> -->
                            <div class="specification_area" style="padding: 20px 20px 10px 20px ;min-height: 430px;height: calc(50vh - 80px);">
                            <div class="specification_box" ref="button_group_realtime">
                                <b-form inline class="d-flex">
                                    <b-button-group class="mr-2 specification_btn float-end">
                                        <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='1440' @change="change_selected_ts_flow($event)">
                                            <span>1D</span>
                                        </b-radio>
                                        <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='4320' @change="change_selected_ts_flow($event)">
                                            <span>3D</span>
                                        </b-radio>
                                        <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='10080' @change="change_selected_ts_flow($event)">
                                            <span>7D</span>
                                        </b-radio>
                                        <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='43200' @change="change_selected_ts_flow($event)">
                                            <span>30D</span>
                                        </b-radio>
                                      </b-button-group>
                                      <b-input size="sm" v-model="flow_date" type="date" @change="change_flow_date($event)" class="btn-gray-date input_date m-0 d-flex"/>
                                        <!--span class="title_value d-flex" style=" margin: 0px 0 0 70px;">Realtime Monitoring</span-->
                                 </b-form>
                                      <!--span class="btn_pre" style="margin: 5px 0px 0px -60px;">
                                        <button class="" @click="change_date_flow(-1)" style="width: 20px;height: 20px;line-height: 8px;margin: 0;text-indent: -4px;"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                                        <button class="" @click="change_date_flow(1)" style="width: 20px;height: 20px;line-height: 8px;margin: 0 1em 0 .5em;text-indent: -4px;"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                                      
                                      </span-->
                                   <span style="float:left;margin: -25px 0px 0 380px;">    
                                      <span style="float:left;"> 
                                      <button class="" @click="change_date_flow(-1)" style="width: 20px;height: 20px;line-height: 6px;margin: 0;text-indent: -4px;    border: none;"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                                      <button class="" @click="change_date_flow(1)" style="width: 20px;height: 20px;line-height: 6px;margin: 0 1em 0 .5em;text-indent: -4px;    border: none;"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                                      </span>
                                      <span class="title_value" style="margin: 0px;color:#cfcfcf;">Realtime Monitoring</span>
                                   </span>
                                   <!-- <div class="chart_btn d-flex" @click="goRouter('/block-monitoring-detail-tab')">Chart</div> -->
                                   <div class="btn-primary btn-g" style="width: 60px; border-radius: 4px; margin: -5px 0px 0px;" @click="goRouter('/block-monitoring-detail-tab')">Chart</div>
                              <div class="status" style=" right: 200px;" >                                            
                                <span style="float: right;margin-right: 20px; margin-top: -31px;" @click="change_min">
                                <b-input v-model="m_min" style="width:60px;height:20px;margin:0 0 0 10px;padding:10px 0 10px ;text-align:center;"
                                  type="number" step="10" class="input_spin" />
                              </span> 
                              </div>           
                            </div>
  <!--
                            <div ref="flow_chart" :style="{height: realtimechart_height+'px'}">
                                <div>
                                    <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                                </div>
                                <svg/>
                            </div>
  -->
                              <!-- <div style="background-color:white; "> -->
                              <div >
                                <div>
                                  <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                              </div>
                                <BlockMonRealTime class="flow_detail" :url="real_url" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:5px;"   />  
                            </div> 
                            </div>

                        </b-col>
                    </b-row>
                  </b-tab>
               </b-tabs> 
               <b-row>
                 <b-col class="pressure-detail-content-map" style="padding-left: 0px; margin-left: 15px;margin-top:10px;">
                    <div class="search_div_press m2_flow ">
                        <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                        <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
                        <span style="float:left; margin-left:10px;"> <b-select size="sm" v-model="pressure_value_type" :options="pressure_value_opt" change></b-select> </span>
                     </div>
                    <div :style="{height: map_height+'px'}">
                     <Map
                           ref="map"
                          :visible_gisload_button="true"      
                          :visible_full_extent="true"                  
                          :widx="$route.query.widx"                                  
                          :press_icon1 ="true"           
                          w_type="161"
                          @toggle_visible_Hydraulic_type = "toggle_visible_Hydraulic_type"
                          @set_map_gisload="set_map_gisload"
                          @facility_invisible_layer = "facility_invisible_layer"
                          @set_info="set_info"
                          @go_home="go_home()"                      
                          @onLeakMapClick="onLeakMapClick()"
                       />
                    </div>
                  </b-col>
                </b-row>  
              </b-col>
              <!-- <b-col cols="4" style="background-color:white;"> -->
              <b-col cols="4" style="background-color:#172A46; padding: 1em 1em 0;margin: 3.3em 0 0 0em;">
                <!-- <div style="background-color:white; padding: 20px 5px;"> -->
                <div style="height:20px;" >
                  <span style=" position: absolute; top: 20px; right: 20px;">
                      {{$t('water_flow.data_table_title')}}
                    <!--b-button size="sm" @click="updateDayData"  v-b-tooltip.hover title="Update Data" style="margin: -5px 10px -5px;">
                        <i class="mdi mdi-refresh"/>                             
                     </b-button-->
                 </span>
                </div>
                <div style="padding: 20px 5px; ">
                  <vue-good-table ref="table"
                      :columns="columns"
                      :rows="table_mnf"
                      :line-numbers="true"
                      :max-height="contents_height+ 50 +'px'"
                      :fixed-header="true"
                        styleClass="vgt-table condensed"
                  />
                 </div>
              </b-col>
          </b-row>
        </div>
    </div>
</template>

<script>
import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';
import SearchLeftType3 from '@/components/left/SearchLeftType3';
import * as d3 from 'd3'
import d3tip from "d3-tip";
import BlockMonitoringBarMnfChart from '@/components/chart/BlockMonitoringBarMnfChart' ;
import BlockMonRealTime from '@/components/chart/BlockMonRealTime' ;
d3.tip = d3tip;
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    name: 'BlockMonitoringDetail',
    props: [
    ],
    watch: {
      pressure_value_type(){
        this.chang_press_mapvalue_type() ;
      }, 
      min() {
        if(this.vchart===0) {
          return;
        }
        
         this.chang_bar_url();
      },  
      tabIndex(){
         if(this.tabIndex === 0 ){
            this.get_time_series_mnf();
         }else{
            this.get_time_series_mnf();
            this.get_time_series_flow();
         }
      }   
    },
    data() {
        return {
            bar_url : '',
            bar_url1 : '',
            vchart : 1,
            min : 20,
            m_min : 20,                        
            real_url : '',
            contents_height: 600,
            mnfchart_height: 300,
            map_height: 250,
            realtimechart_height: 300,
            tag : this.$route.query.tag,
            pressure_value_type : '',
            pressure_value_opt: ['mnf' , 'flow'],   
            tabIndex : 0,
            selected_ts_flow: '1440',
            flow_date: this.$route.query.last_date,
            loaded_ts_flow: false,

            selected_ts_mnf: '30',
            mnf_date: this.$route.query.last_date,
            addr_value:'',
            columns: [
                {label: 'Date', field: 'date_str'},
                {label: 'Supply(㎥/day)', field: 'flow'},
                {label: 'MNF', field: 'mnf'},
                {label: '%', field: 'rate'},
            ],
            table_mnf: [],
            ts_mnf: [],
            activeCheckIndex: null,
            box: [
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'status': 'red'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'status': 'blue'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'status': 'red'
              }
            ],
           
        }
    },
    methods: {
        updateDayData(){
          //기준날짜에 한달 건 계산
          var tag = encodeURI(this.tag) ;
          var param = "m2_point_id="+tag+"&current_datetime="+this.mnf_date
          this.$http.get(this.URL+'api/refresh?type=161&'+param).then((res) => { 
          // this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id).then((res) => {
            if(res.data.check === 0) alert("Success..")
            else  alert("Fail.. ")
          });

        },
         goRouter(path) {
            this.$router.push({path: path, query: { tag: this.tag, last_date : this.$route.query.last_date }})
        },
         addr_search(){
           this.$refs.map.go_search(this.addr_value);
        },
        Enter_Check1(){
            this.addr_search();
        },
        chang_press_mapvalue_type() {
          // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          
          this.$refs.map.leak_cal = this.pressure_value_type;    //avg,min/max
          //this.$refs.map.leak_val =  this.value_type;     //bar,psi,kgf
          //this.$refs.map.time_30m = this.flow_date; //지도에서 일데이터 (최종시간 그전날 데이터)
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;

        },  
      on_goto_map_click(row) {
        
        this.$refs.map.get_extent('w_type=161&p_idx='+row.id).then((data)=>{
          this.$refs.map.set_fit_extent(data)     
          if(this.$store.setting.map_name==='Daum')
            this.$refs.map.map.getView().setZoom(13);
          else
            this.$refs.map.map.getView().setZoom(18);   
        })
        this.$refs.map.select_facility_feature('161','',row.id)  
      },
      go_home() {
         //  this.$router.push({path: '/', query: { widx: this.$route.query.widx, w_type: '110' }})
          var last_str = this.flow_date
          this.$router.push({path: '/block-monitoring-map?w_type=161', query: { tag: this.tag, mea_date:last_str  }})
        }, 
         set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            //var size = [size1[0]*2, size1[1]*2] ;
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length-1; i++) {
                    var layer = this.$refs.map.layer_list[i];
                    if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length-1; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);


                    } //for문 끝

                } // zoom크기에 대한 if


        },   
       toggle_visible_Hydraulic_type() {
          // 처음엔 동수도 적용안한다. Hydraulic_type 데이터를 가져와 지도에 보여준다. 동수도 적용안한다.
          // All or Hydraulic 적용값 가져와 지도에 보여준다.
          this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
          if(this.$refs.map.layer_leak_visible)
            this.$refs.map.leak_type = 'HYDRAULIC';
          else
            this.$refs.map.leak_type = null;            
          
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;      
        },                
       facility_invisible_layer() {

          this.layer_all_visible = false;
          let map = this.$refs.map.map
          for(let i=1; i<map.getLayers().getLength(); i++) {
            let layer = map.getLayers().item(i)
            if(layer.get('code')>50) continue;
            layer.setVisible(this.layer_all_visible)
          }
        },        
        onLeakMapClick(){
            let tag_id = this.$refs.map.leak_idx;
            this.tag_name = this.$refs.map.leak_recov_idx
           // this.tag_name1 = tag_name ;    
            this.tag = tag_id ;
            //this.flow_date1 = this.flow_date ;
            //this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date1+'&limit='+this.selected_ts_flow+"#"+this.vchart;
            if(this.tabIndex === 0 ){
              this.get_time_series_mnf();
            }else{
              this.get_time_series_mnf();
              this.get_time_series_flow()
            }

        },  
      set_info(){
            this.$refs.map.select_info = true;
        },                           
        change_date_mnf(a){
          let default_date = new Date(this.mnf_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.mnf_date = this.date_to_str(default_date)
          this.get_time_series_mnf()
        },
        change_date_flow(a){
          let default_date = new Date(this.flow_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
         
          this.get_time_series_flow()
        },
        date_to_str(format){
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 
     
          return year + "-" + month + "-" + date ;
      },




        go_search(value) {

            if(value===undefined) return;

                //left메뉴 데이터를 뿌려준다.
            this.box = [] ;
            //this.$http.get(this.URL+'api/get_monitoring_list_left?search='+value).then((rows) => {
            var customer_id = sessionStorage.select_customer_id ; 
            if(sessionStorage.select_customer_id === "27"){
              customer_id = 1
            }
              this.$http.get(this.URL+'api/API21/flow/list?customer_id='+customer_id+'&search='+value).then((res) => {
                
                for(let i=0; i<res.data.results[0].length; i++) {
                  if(this.tag === res.data.results[0][i].id ){
                    this.t_title =  res.data.results[0][i].point_name;
                  }           
                  var abox = {};
                  abox = {'id':res.data.results[0][i].id ,'name': res.data.results[0][i].point_name, 'mnf': Math.round(Number( res.data.results[0][i].night_min_flow)) , 
                       'flow':  res.data.results[0][i].last_measure_datetime ,  'status': 'blue' } ; 
                  this.box.push(abox) ; 
                }
            })

     
        },
        updateCheckIndex(payload) {
          this.activeCheckIndex = payload
          this.tag=this.box[payload].id
          this.vchart = 0;
          var s_date = this.box[payload].flow
          if(s_date !== null ) {  
            this.flow_date  = s_date.substring(0,10);
            this.mnf_date = s_date.substring(0,10);
          }
      
            if(this.tabIndex === 0 ){
              this.get_time_series_mnf();
            }else{
              this.get_time_series_flow();
              this.get_time_series_mnf();
            }
        },
        change_flow_date(e) {
            this.flow_date = e
            this.get_time_series_flow()
        },
        change_selected_ts_flow(e) {
            this.selected_ts_flow = e
            this.get_time_series_flow()
        },
        get_time_series_flow() {
            this.loaded_ts_flow = false
            
          //   this.real_url = this.URL+'api/get_time_series?type=flow&tag='+encodeURI(this.tag)+'&start_date='+this.flow_date+'&limit='+this.selected_ts_flow+"#0";
            this.real_url = this.URL+'api/API21/flow/minute/'+this.tag+'?to='+this.flow_date+' 23:59&limit='+this.selected_ts_flow+"#0";          
            /*
            this.$http.get(this.URL+'api/get_time_series?type=flow&tag='+encodeURI(this.tag)+'&start_date='+this.flow_date+'&limit='+this.selected_ts_flow).then((res) => {
                let ts_flow = res.data.rows
               // var parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S.000Z");
                var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
                for(let i=0; i<ts_flow.length; i++) {
                    ts_flow[i]['date_time'] = parseTime(ts_flow[i]['date_time'])
                }
                this.loaded_ts_flow = true
              //  this.draw_flow_chart(ts_flow)
            });
            */
           this.loaded_ts_flow = true
        },
        change_mnf_date(e) {
            this.mnf_date = e
            this.get_time_series_mnf()
        },
        change_selected_ts_mnf(e) {
            this.selected_ts_mnf = e
            this.get_time_series_mnf()
        },
        change_min(){
          if(this.vchart === 0){
             this.vchart =1 ;
             this.chang_bar_url();  
          }
        },
        chang_bar_url(){
             this.$refs.barchart.fixed = 1 ; // 1 :fixed
             this.$refs.barchart.min = this.min ;
             var c_url = this.bar_url1.split('#') ;
             this.bar_url = c_url[0]+"&fixed="+this.vchart+"&"+ this.min+'#0' ;
        },        
        get_time_series_mnf() {
       //     this.bar_url = this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit='+this.selected_ts_mnf+'#0';
            this.table_mnf = []
            this.bar_url1 = this.URL+'api/API21/flow/day/'+this.tag+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+'#0';
            var chart_avg = 0
       //     this.$http.get(this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit='+this.selected_ts_mnf).then((res) => {
            this.$http.get(this.URL+'api/API21/flow/day/'+this.tag+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf).then((res) => {
               if(res.data.results[0].length <1){      
                 this.bar_url = this.URL+'api/API21/flow/day/'+this.tag+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+'#0';         
                 return;
               } 
               this.ts_mnf = res.data.results[0]
               var parseTime = d3.timeParse("%Y-%m-%d");
                
               for(let i=0; i<this.ts_mnf.length; i++) {
                    this.ts_mnf[i]['date_str'] = this.ts_mnf[i]['measure_datetime']
                    this.ts_mnf[i]['date'] = parseTime(this.ts_mnf[i]['measure_datetime'])
                  //  this.ts_mnf[i]['rate'] = this.ts_mnf[i]['mnf'] / (this.ts_mnf[i]['flow']/24) * 100
                    this.ts_mnf[i]['rate'] = this.ts_mnf[i]['night_min_rate'] 

                    this.table_mnf.push({
                        date_str: this.ts_mnf[i]['date_str'],
                        flow: this.ts_mnf[i]['water_flow'],
                        mnf: this.ts_mnf[i]['night_min_flow'],
                        rate: this.ts_mnf[i]['rate']
                    })
                     chart_avg += this.ts_mnf[i]['night_min_flow']


                }
                chart_avg = chart_avg/this.ts_mnf.length
                this.min = Math.round((chart_avg -chart_avg/10)/10, 0)*10
             //   this.draw_mnf_chart(this.ts_mnf)
                this.chang_bar_url();
            });
        },
        onBarClick(){
          var bar_idx = this.$refs.barchart.bar_idx; //09-03 이렇게 들어온다
          let default_date = this.flow_date.split('-') ;
          let sta =  default_date[0]+'-'+bar_idx
          this.flow_date = sta
           //   this.fft_url = this.URL+"api/get_leak_bubble?w_idx="+bar_idx[0] ;    
              this.get_time_series_flow()
         }
   
    },
    mounted () {
       this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 175
       let left_height = this.contents_height - (this.$refs.button_group_mnf.clientHeight*2)
       this.mnfchart_height = left_height / 2
       this.realtimechart_height = this.mnfchart_height
       this.map_height = (this.contents_height+90)/2
       setTimeout(()=>{
          let setting = this.$store.setting
          if(setting) {
                this.pressure_value_type = 'mnf'
          }
        }, 1000)
   //left메뉴 데이터를 뿌려준다.
       this.box = [] ;
       var select_id= Number(this.$route.query.tag) ;
       var customer_id = sessionStorage.select_customer_id ; 
        if(sessionStorage.select_customer_id === "27"){
          customer_id = 1
        }
      var sel_id = 0  
    //   this.$http.get(this.URL+'api/get_monitoring_list_left').then((rows) => {
      this.$http.get(this.URL+'api/API21/flow/list?customer_id='+customer_id).then((res) => {
                
        for(let i=0; i<res.data.results[0].length; i++) {
          if(select_id === res.data.results[0][i].id ){
            this.t_title =  res.data.results[0][i].point_name;
            sel_id = i
          }           
          var abox = {};
          abox = {'id':res.data.results[0][i].id ,'name': res.data.results[0][i].point_name, 'mnf': Math.round(Number( res.data.results[0][i].night_min_flow)) , 
            'flow':  res.data.results[0][i].last_measure_datetime ,  'status': 'blue' } ; 
          this.box.push(abox) ; 
        }
         this.updateCheckIndex(sel_id)
      })       
  
     //   this.bar_url = this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30#0';
      //  this.get_time_series_mnf()
      //  this.get_time_series_flow()
        setTimeout(()=>{
            var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
            if( elem !== undefined ){
            if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();         
            }
         }
       }, 200)
    },
    beforeDestroy () {
    },
    components: {
        Map: Map, 
        PulseLoader: PulseLoader,
        SearchLeftType3,
        BlockMonitoringBarMnfChart:BlockMonitoringBarMnfChart,
        BlockMonRealTime:BlockMonRealTime,
    }
}
</script>

<style lang="scss" scoped>
.search_div_press.m2_flow {
  left: 215px;
} 
.specification_area .input_date {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.specification_area .specification_box{
  // 2022 new
  // background: #fff;
  background: #172A46;
  margin-bottom: 10px;
  position: relative;
  padding: 0 5%;
}

.specification_area .specification_box::after{
  content: "";
  display: block;
  clear: both;
}

.btn_box_wrap{
  position: absolute;
  top: 28px;
  left: 5%;
}

.btn_box_wrap > div{
  float: left;
}

.specification_btn .btn-group-toggle + .btn-group-toggle {
  //margin-left: 5px;
}
.specification_btn .btn-secondary{
  // float: left;
  // width: 42px;
  // height: 32px;
  // line-height: 32px;
  // text-align: center;
  // border-radius: 2px;
  // background: rgba(35,41,47,0.4);
  // font-size: 14px;
  // margin-right: 16px;
  // cursor: pointer;
  // border: 0;
   display: inline-block;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #707070;
    text-align: center;
    vertical-align: middle;
}
.specification_btn .btn-secondary span {
  color: #fff;
}
.specification_btn .btn-secondary:hover{
  background-color: #28AF81;;
}

.specification_btn .btn-secondary.on{
  background: rgba(35,41,47,0.8);
}

.btn_box_wrap .date_btn{
  margin-top: 5px;
  margin-left: 14px;
}

.specification_box .chart_btn{
  position: absolute;
  top: 0;
  right: 22px;
  // background: #4C5055;
  width: 80px;
  height: 32px;
  line-height: 32px;
  // border-radius: 2px;
  text-align: center;
  // color: #fff;
  font-size: 14px;
  cursor: pointer;
}



.specification_box .chart_img{
  padding-top: 105px;
  height: 270px;
}

.specification_box .chart_img img{
  height: 100%;
}

.specification_table{
  width: 28%;
  float: left;
  padding: 1%;
  // background: #fff;
}

.specification_table table{
  width: 100%;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  box-sizing: border-box;
  height: 800px;
}

.specification_table table th,
.specification_table table td{
  font-size: 9px;
  text-align: center;
  height: 28px;
  line-height: 28px;
}

.specification_table table th{
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table th.last{
  border-right: 1px solid #E0E0E0;
}

.specification_table table td{
  border-right: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  box-sizing: border-box;
}

.specification_table table td.bg{
  background: rgba(175,175,175,0.1);
}

.specification_table .table_wrap{
  height: 745px;
  overflow-y: scroll;
}
table.vgt-table.condensed td {
      padding: .188rem .15rem;
}
.btn_pre{
 position: absolute;
    top: 0;
    right: 110px;
   // background: #4C5055;
    width: 80px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
 }
//  2022 new

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    /* background-color: #fff; */
    /* border-color: #dee2e6 #dee2e6 #fff; */
    border: 1px solid #28AF81;
    background-color: #28AF81;
}
.color-content-bg{
       background: #172A46;
}
.btn-primary{
  position: absolute;
    right: 0;
    top: 0;
}

#wavchart .highcharts-container {
  /*min-height: 350px;*/
 
  width: 100%;
}
</style>

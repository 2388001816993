<template>
<!--  <div>-->
    <div class="modelding-area">
        <b-modal
            ref="modal"
            title="Modeling data"
            centered
           size="lg" scrollable 
           :header-bg-variant="headerBgVariant"
        >
    
    <div style="padding:20px; ">
     <table id="table" border=0 cellpadding=0 cellspacing=0 width=750 style='border-collapse:
 collapse;table-layout:fixed;width:486pt'>
 <col width=100 span=9 style='width:54pt'>
 <tr height=22 style='height:16.5pt'>
  <td height=22 width=100 style='height:16.5pt;width:54pt'>[TITLE]</td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
 </tr>
 <tr height=44 style='height:33.0pt;mso-xlrowspan:2'>
  <td height=44 colspan=9 style='height:33.0pt;mso-ignore:colspan'></td>
 </tr>
 <tr class=xl67 height=22 style='height:16.5pt'>
  <td height=22 class=xl67 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[JUNCTIONS]</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
 <tr class=xl67 height=22 style='height:16.5pt'>
  <td height=22 class=xl67 style='height:16.5pt'>;ID</td>
  <td class=xl67>Elev</td>
  <td class=xl67>Demand</td>
  <td class=xl67 colspan=2 style='mso-ignore:colspan'>Pattern</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
 <!-- junctions -->
 <tr class=xl67 height=22 style='height:16.5pt' v-for="junction in junctions" :key="junction.id+'junction'" >
  <td height=22 class=xl67 align=right style='height:16.5pt'>{{junction.id}}</td>
  <td class=xl67 align=right>{{junction.elev}}</td>
  <td class=xl67 align=right>{{junction.demand}}</td>
  <td class=xl67></td>
  <td class=xl67>{{junction.name}}</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
  <tr class=xl67 height=22 style='height:16.5pt' v-for="junction1 in junctions1" :key="junction1.id+'junction1'" >
  <td height=22 class=xl67 align=right style='height:16.5pt'>{{junction1.id}}</td>
  <td class=xl67 align=right>{{junction1.elev}}</td>
  <td class=xl67 align=right>{{junction1.demand}}</td>
  <td class=xl67></td>
  <td class=xl67>{{junction1.name}}</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
 <!-- junctions END  -->
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[RESERVOIRS]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;ID</td>
  <td>Head</td>
  <td colspan=2 style='mso-ignore:colspan'>Pattern</td>
  <td colspan=5 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 align=right style='height:16.5pt'>6</td>
  <td align=right>50</td>
  <td></td>
  <td colspan=2 style='mso-ignore:colspan'>;watertank</td>
  <td colspan=4 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[TANKS]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;ID</td>
  <td>Elevation </td>
  <td>InitLevel</td>
  <td>MinLevel</td>
  <td>MaxLevel</td>
  <td>Diameter</td>
  <td>MinVol</td>
  <td>VolCurve</td>
  <td>Overflow</td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 align=right style='height:16.5pt'></td>
  <td align=right></td>
  <td align=right></td>
  <td align=right></td>
  <td align=right></td>
  <td align=right></td>
  <td align=right></td>
  <td></td>
  <td>;</td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr class=xl67 height=22 style='height:16.5pt'>
  <td height=22 class=xl67 style='height:16.5pt'>[PIPES]</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
 <tr class=xl67 height=22 style='height:16.5pt'>
  <td height=22 class=xl67 style='height:16.5pt'>;ID</td>
  <td class=xl67>Node1</td>
  <td class=xl67>Node2</td>
  <td class=xl67>Length</td>
  <td class=xl67>Diameter</td>
  <td class=xl67>Roughnes</td>
  <td class=xl67>MinorLoss</td>
  <td class=xl67>Status</td>
  <td class=xl67></td>
 </tr>

  <!-- PIPES -->
 <tr class=xl67 height=22 style='height:16.5pt' v-for="pipe in pipes" :key="pipe.id+'pipe'" >
 
  <td height=22 class=xl67 align=right style='height:16.5pt'>{{pipe.id}}</td>
  <td class=xl67 align=right>{{pipe.node1_id}}</td>
  <td class=xl67 align=right>{{pipe.node2_id}}</td>
  <td class=xl67 align=right>{{pipe.length}}</td>
  <td class=xl67 align=right></td>
  <td class=xl67 align=right></td>
  <td class=xl67 align=right></td>
  <td class=xl67>Open</td>
  <td class=xl67>{{pipe.name}}</td>
 </tr>
 
   <!-- PIPES END -->
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[PUMPS]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;ID</td>
  <td>Node1</td>
  <td>Node2</td>
  <td colspan=2 style='mso-ignore:colspan'>Parameters</td>
  <td colspan=4 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 align=right style='height:16.5pt'></td>
  <td align=right></td>
  <td align=right></td>
  <td></td>
  <td>;</td>
  <td colspan=4 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[VALVES]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;ID</td>
  <td>Node1</td>
  <td>Node2</td>
  <td>Diameter</td>
  <td>Type</td>
  <td>Setting</td>
  <td colspan=2 style='mso-ignore:colspan'>MinorLoss</td>
  <td></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 align=right style='height:16.5pt'></td>
  <td align=right></td>
  <td align=right></td>
  <td align=right></td>
  <td></td>
  <td align=right></td>
  <td align=right></td>
  <td>;</td>
  <td></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[TAGS]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <!--
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>NODE</td>
  <td align=right>2</td>
  <td>j2</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>NODE</td>
  <td align=right>3</td>
  <td>j3</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>NODE</td>
  <td align=right>4</td>
  <td>j5</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>NODE</td>
  <td align=right>6</td>
  <td>wt1</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>LINK</td>
  <td align=right>3</td>
  <td>p4</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>LINK</td>
  <td align=right>5</td>
  <td>p1</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[DEMANDS]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;Junction        </td>
  <td>Demand</td>
  <td>Pattern         </td>
  <td>Category</td>
  <td colspan=5 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 -->
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[STATUS]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;ID</td>
  <td colspan=2 style='mso-ignore:colspan'>Status/Setting</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[PATTERNS]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;ID</td>
  <td colspan=2 style='mso-ignore:colspan'>Multipliers</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[CURVES]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;ID</td>
  <td>X-Value</td>
  <td>Y-Value</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>;PUMP: pump1</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 align=right style='height:16.5pt'>1</td>
  <td align=right>30</td>
  <td align=right>30</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[CONTROLS]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[RULES]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[ENERGY]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Global Ef</td>
  <td align=right>75</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Global Pr</td>
  <td align=right>0</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Demand</td>
  <td align=right>0</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[EMITTERS]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;Junction</td>
  <td colspan=2 style='mso-ignore:colspan'>Coefficient</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[QUALITY]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;Node</td>
  <td>InitQual</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[SOURCES]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;Node</td>
  <td>Type</td>
  <td>Quality</td>
  <td>Pattern</td>
  <td colspan=5 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[REACTIONS]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;Type</td>
  <td>Pipe/Tank</td>
  <td colspan=2 style='mso-ignore:colspan'>Coefficient</td>
  <td colspan=5 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=44 style='height:33.0pt;mso-xlrowspan:2'>
  <td height=44 colspan=9 style='height:33.0pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[REACTIONS]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Order Bul</td>
  <td align=right>1</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Order Tank</td>
  <td align=right>1</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Order Wall</td>
  <td align=right>1</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Global Bulk</td>
  <td align=right>0</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Global Wall</td>
  <td align=right>0</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Limiting Potential</td>
  <td align=right>0</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Roughness Correlation</td>
  <td align=right>0</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[MIXING]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>;Tank</td>
  <td>Model</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[TIMES]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Duration</td>
  <td align=right>0</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Hydraulic</td>
  <td class=xl65 align=right>1:00</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Quality Timestep</td>
  <td class=xl65 align=right>0:05</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Pattern Timestep</td>
  <td class=xl65 align=right>1:00</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Pattern Start</td>
  <td class=xl65 align=right>0:00</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Report Timestep</td>
  <td class=xl65 align=right>1:00</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Report Start</td>
  <td class=xl65 align=right>0:00</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Start ClockTime</td>
  <td class=xl66 align=right>12:00 AM</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Statistic</td>
  <td>None</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[REPORT]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Status</td>
  <td>No</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Summary</td>
  <td>No</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Page         </td>
  <td align=right>0</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[OPTIONS]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Units        </td>
  <td>CMH</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Headloss           </td>
  <td>H-W</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Specific Gravity   </td>
  <td align=right>1</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Viscosity </td>
  <td align=right>1</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Trials</td>
  <td align=right>40</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Accuracy</td>
  <td align=right>0.001</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>CHECKFREQ</td>
  <td align=right>2</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>MAXCHECK</td>
  <td align=right>10</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>DAMPLIMIT          </td>
  <td align=right>0</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Unbalanced         </td>
  <td colspan=2 style='mso-ignore:colspan'>Continue 10</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Pattern            </td>
  <td align=right>1</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Demand Multiplier  </td>
  <td align=right>1</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Emitter Exponent   </td>
  <td align=right>0.5</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Quality            </td>
  <td colspan=2 style='mso-ignore:colspan'>None mg/L</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Diffusivity        </td>
  <td align=right>1</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>Tolerance          </td>
  <td align=right>0.01</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr class=xl67 height=22 style='height:16.5pt'>
  <td height=22 class=xl67 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[COORDINATES]</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
 <!-- node COORDINATES -->
 <tr class=xl67 height=22 style='height:16.5pt'  >
 
 <tr class=xl67 height=22 style='height:16.5pt'>
  <td height=22 class=xl67 style='height:16.5pt'>;Node      </td>
  <td colspan= 2 class=xl67>X-Coord           </td>
  <td colspan= 2 class=xl67>Y-Coord</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
  <!-- node COORDINATES end -->
 <tr class=xl67 height=22 style='height:16.5pt' v-for="junction in junctions" :key="junction.id"> 
  <td height=22 class=xl67 style='height:16.5pt'>{{junction.id}}</td>
  <td colspan= 2 class=xl67>{{junction.x}}</td>
  <td colspan= 2 class=xl67>{{junction.y}}</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
 <tr class=xl67 height=22 style='height:16.5pt' v-for="junction1 in junctions1" :key="junction1.id"> 
  <td height=22 class=xl67 style='height:16.5pt'>{{junction1.id}}</td>
  <td colspan= 2 class=xl67>{{junction1.x}}</td>
  <td colspan= 2 class=xl67>{{junction1.y}}</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
 <tr class=xl67 height=22 style='height:16.5pt'>
  <td height=22 class=xl67 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[VERTICES]</td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
 <tr class=xl67 height=22 style='height:16.5pt'>
  <td height=22 class=xl67 style='height:16.5pt'>;Link</td>
  <td colspan= 2 class=xl67>X-Coord</td>
  <td colspan= 2 class=xl67>Y-Coord</td>
 
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>

 <!-- VERTICES COORDINATES -->
 <tr class=xl67 height=22 style='height:16.5pt' v-for="vertice in vertices" :key="vertice.id" >

  <td height=22 class=xl67 align=right style='height:16.5pt'>{{vertice.model_pipe_id}}</td>
  <td colspan= 2 class=xl67 align=right>{{vertice.x}}</td>
  <td colspan= 2 class=xl67 align=right>{{vertice.y}}</td>

  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
  <td class=xl67></td>
 </tr>
  <!-- VERTICES COORDINATES end -->
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[LABELS]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=6 style='height:16.5pt;mso-ignore:colspan'>;X-Coord            
  Y-Coord            
  Label &amp; Anchor Node</td>
  <td colspan=3 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>[BACKDROP]</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'> 
  DIMENSIONS  </td>
  <td align=right>0</td>
  <td align=right>0</td>
  <td align=right>10000</td>
  <td align=right>10000</td>
  <td colspan=4 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>UNITS          </td>
  <td>None</td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=2 style='height:16.5pt;mso-ignore:colspan'>FILE           </td>
  <td colspan=7 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>OFFSET         </td>
  <td align=right>0</td>
  <td align=right>0</td>
  <td colspan=6 style='mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 colspan=9 style='height:16.5pt;mso-ignore:colspan'></td>
 </tr>
 <tr height=22 style='height:16.5pt'>
  <td height=22 style='height:16.5pt'>[END]</td>
  <td colspan=8 style='mso-ignore:colspan'></td>
 </tr>

 <tr height=0 style='display:none'>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
  <td width=100 style='width:54pt'></td>
 </tr>

</table>
  
    </div>
  
   
        <template v-slot:modal-footer="{ ok, cancel, hide }">
              <b-row class="modal-footer-row m-0">
                <b-col sm="4">
                  <b-button block @click="$refs.modal.hide()"  class="modal-footer__button modal-footer__button-cancel">Cancel</b-button>
                </b-col>
                <b-col sm="4">
                  <b-button block @click="makeExcelFile4()" class="modal-footer__button modal-footer__button-ok">EXCEL DOWNLOAD</b-button>
                </b-col>
                <b-col sm="4">
                  <b-button block @click="exportTableToTxt()" class="modal-footer__button modal-footer__button-ok">.inp File DOWNLOAD</b-button>
                </b-col>
              </b-row>
            </template>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: 'DlgModeling',
  
    data() {
        return {
          headerBgVariant : '',
            now_flow: null,
            now_mnf: null,
            last_date: null,
            flow: ['0','0','0','0'],
            mnf: ['0','0','0','0'],
            rate:['0','0','0','0'],
            title_color: 'red',
            nodes:null,
            vertices:null,
            junctions:null,
            junctions1:null,
            pipes:null, 
            f_name : null,             
        }
    },
    methods: {
        goRouter(path) {
            this.$router.push({path: path, query: { tag: this.tag, last_date : this.last_date }})
        },
        makeExcelFile4 () {
            let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">'
            tab_text += '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">'
            tab_text += '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
            tab_text += '<x:Name>Test Sheet</x:Name>'
            tab_text += '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>'
            tab_text += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>'
            tab_text += "<table>"
            const temp = document.getElementById('table').innerHTML
            // console.log(temp)
            tab_text += temp           
            tab_text += '</table></body></html>'
            console.log(tab_text)
            const fileName = this.f_name + '.xls'
            const a_tag = document.createElement('a')
            const blob = new Blob([tab_text], { type: 'application/vnd.ms-excel;charset=utf-8;' })
            a_tag.href = window.URL.createObjectURL(blob)
            a_tag.download = fileName
            a_tag.click()

            this.$refs.modal.hide(); 
       },
       exportTableToTxt(tableId, filename) {
            if (filename == null || typeof filename == undefined)
                filename = this.f_name;
            filename += ".inp";

            //var BOM = "\uFEFF";

            var table = document.getElementById('table');
            var csvString = '';
            for (var rowCnt = 0; rowCnt < table.rows.length; rowCnt++) {
                var rowData = table.rows[rowCnt].cells;
                for (var colCnt = 0; colCnt < rowData.length; colCnt++) {
                    var columnData = rowData[colCnt].innerHTML;
                  //  if (columnData == null || columnData.length == 0) {
                  //      columnData = "".replace(/"/g, '""');
                  //  }
                  //  else {
                  //      columnData = columnData.toString().replace(/"/g, '""'); // escape double quotes
                  //  }
                    //csvString = csvString + '"' + columnData + '",';
                    csvString = csvString + columnData + ',';
                }
                csvString = csvString.substring(0, csvString.length - 1);
                csvString = csvString + "\r\n";
            }
            csvString = csvString.substring(0, csvString.length - 1);
            var blob = null;
            var csvUrl = null;
            var a = null;
            // IE 10, 11, Edge Run
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {

                blob = new Blob([decodeURIComponent(csvString)], {
                    type: 'text/plain;charset=utf8'
                });

                window.navigator.msSaveOrOpenBlob(blob, filename);

            } else if (window.Blob && window.URL) {
                // HTML5 Blob
                blob = new Blob([csvString], { type: 'text/plain;charset=utf8' });
                csvUrl = URL.createObjectURL(blob);
                 a = document.createElement('a');
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', csvUrl);
                a.setAttribute('download', filename);
                document.body.appendChild(a);

                a.click()
                a.remove();
            } else {
                // Data URI
                var csvData = 'data:application/octet-stream;charset=utf-8,' + encodeURIComponent(csvString);
                 blob = new Blob([csvString], { type: 'text/plain;charset=utf8' });
                 csvUrl = URL.createObjectURL(blob);
                 a = document.createElement('a');
                a.setAttribute('style', 'display:none');
                a.setAttribute('target', '_blank');
                a.setAttribute('href', csvData);
                a.setAttribute('download', filename);
                document.body.appendChild(a);
                a.click()
                a.remove();
            }
        },
         show(idx,ditem) {
           // if(item.features === null) return
           let geo = '';
           if(ditem !== null ){
              if(ditem.type==="add"){
                  geo = ditem.feature.geometry.coordinates[0]
              }
           }
            
            this.f_name = idx > -1 ? idx+'_model_data': '_model_data' ;
            this.$http.get(this.URL+'api/get_pipe_model_data?f_idx='+idx+'&type=1&geo='+geo).then((res) => {
                this.junctions = res.data.rows
            }) 
             this.$http.get(this.URL+'api/get_pipe_model_data?f_idx='+idx+'&type=4&geo='+geo).then((res) => {
                this.junctions1 = res.data.rows
            }) 
            this.$http.get(this.URL+'api/get_pipe_model_data?f_idx='+idx+'&type=2&geo='+geo).then((res) => {
                this.vertices = res.data.rows 
            })             
            this.$http.get(this.URL+'api/get_pipe_model_data?f_idx='+idx+'&type=3&geo='+geo).then((res) => {
                this.pipes = res.data.rows
            })             

               /*
                    this.$http.get(this.URL+'api/get_geometry?layer=0&f_type=Service&geo='+geo).then((res) => {
                        let ct = res.data.features.length ;     
                        if(ct<=0) return
                        //var wgs84Sphere = new Sphere(6371008.8);
                        
                        for(let i=0; i<ct; i++){
                            // const length = getLength(new LineString([transform(res.data.features[i].geometry.coordinates[0], "EPSG:900913",'EPSG:4326'),transform(res.data.features[i].geometry.coordinates[1], "EPSG:900913",'EPSG:4326')]));
                            const length = getLength(new LineString([res.data.features[i].geometry.coordinates[0],res.data.features[i].geometry.coordinates[1]])); 
                            // var distance = wgs84Sphere.haversineDistance(transform(res.data.features[i].geometry.coordinates[0], "EPSG:900913",'EPSG:4326'),ol.proj.transform(res.data.features[i].geometry.coordinates[1], "EPSG:900913",'EPSG:4326'))
                            // var length = wgs84Sphere.haversineDistance(res.data.features[i].geometry.coordinates[0],res.data.features[i].geometry.coordinates[1])
                            this.vlength += length    
                        }
                      
                         this.vlength = ct 
                        
                    })
                  */

              
                this.$refs.modal.show();

        },
    },
    mounted () {
        this.title_color = 'white'
      //  this.makeExcelFile4();
        
    },
    beforeDestroy () {
    },
    components: {
    }
}


</script>

<style>

.modelding-area .modal-dialog .modal-content {
    border-radius: 0;
    height: 850px;
    background: #f2f2f2;
}


tr
	{mso-height-source:auto;
	mso-ruby-visibility:none;}
col
	{mso-width-source:auto;
	mso-ruby-visibility:none;}
br
	{mso-data-placement:same-cell;}
ruby
	{ruby-align:left;}
.style0
	{mso-number-format:General;
	text-align:general;
	vertical-align:middle;
	white-space:nowrap;
	mso-rotate:0;
	mso-background-source:auto;
	mso-pattern:auto;
	color:white;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:"맑은 고딕", monospace;
	mso-font-charset:129;
	border:none;
	mso-protection:locked visible;
	mso-style-name:표준;
	mso-style-id:0;}
td
	{mso-style-parent:style0;
	padding-top:1px;
	padding-right:1px;
	padding-left:1px;
	mso-ignore:padding;
	color:white;
	font-size:11.0pt;
	font-weight:400;
	font-style:normal;
	text-decoration:none;
	font-family:"맑은 고딕", monospace;
	mso-font-charset:129;
	mso-number-format:General;
	text-align:general;
	vertical-align:middle;
	border:none;
	mso-background-source:auto;
	mso-pattern:auto;
	mso-protection:locked visible;
	white-space:nowrap;
	mso-rotate:0;}
.xl65
	{mso-style-parent:style0;
	mso-number-format:"Short Time";}
.xl66
	{mso-style-parent:style0;
	mso-number-format:"Medium Time";}
.xl67
	{mso-style-parent:style0;
	color:red;}

</style>

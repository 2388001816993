<template>
    <div>
        <div v-if="search==true" class="table-option">
            <b-input-group class="mt-1 search_box">
                <b-input type="text" v-model="serverParams.searchTerm" class="form-control gis-master__input" placeholder="Search..."></b-input>
                <!--<div class="search_btn_area">
                  <a href="#none" @click="$refs.modal.show();" class="search_icon icon_option">Option</a>
                  <a href="#none" @click="clear()" class="search_icon icon_clear">Clear</a>
                </div>
                -->
             </b-input-group>
        </div>
           <div style="scroll_box">
                <vue-good-table ref="table"
                   
                    @on-row-click="onRowClick"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    @on-search="onSearch"                   
                    styleClass="vgt-table without--head-right-line type01"
                    :globalSearch="true"
                    :line-numbers="false"
                    :isLoading.sync="isLoading"
                    :select-options="{ enabled: select, selectOnCheckboxOnly: true, disableSelectInfo: true }"
                    :sort-options="{enabled: sort}"
                    :search-options="{enabled: true, externalQuery: serverParams.searchTerm}"
                    :pagination-options="{enabled: true, position: 'top', perPageDropdown: [15, 30, 50, 100, 200,500,1000], dropdownAllowAll: false,}"
                    :totalRows="totalRecords"
                    :rows="rows"
                    :columns="columns"
                    :adv_search="adv_search"
                    :fixed-header="true"
                    :max-height="max_height"                
                    >
                    <template slot="table-row" slot-scope="props" v-if="add_custom_column">
                      <span v-if="props.column.field == 'go_map'" class="table_icon" @click="$emit('on_goto_map_click', props.row)"><img src="@/assets/images/Common/table/map_icon.png" alt="map" style="width:26px;"></span>
                      <span v-else-if="props.column.field == 'info'" class="table_icon" @click="$emit('on_info_click', props.row)"><img src="@/assets/images/Common/table/info_icon.png" alt="map" style="width:26px;"></span>
                      <span v-else-if="props.column.field == 'control_yn'" class="checkbox_span" >
                      <b-form-checkbox v-model="props.row[props.column.field]"
                       @change="$emit('changeCell', props.row,props.column.field)"  style="margin-top: -20px;"  ></b-form-checkbox></span>
                      <span v-else-if="props.column.field == 'value_yn'">
                      <b-form-checkbox v-model="props.row[props.column.field]"
                       @change="$emit('changeCell', props.row,props.column.field)"   style="margin-top: -20px;" ></b-form-checkbox></span>
                       <span v-else-if="props.column.field == 'set1'"> <b-form-input v-model="props.row[props.column.field]"
                      type="number"  @change="changeCell(props.row[props.column.field], props.column.field, props.row)"    >
                      </b-form-input></span>
                       <span v-else-if="props.column.field == 'set2'"> <b-form-input v-model="props.row[props.column.field]"
                      type="number"  @change="changeCell(props.row[props.column.field], props.column.field, props.row)"    ></b-form-input></span>
                      <span v-else-if="props.column.field == 'move'" class="table_icon" @click="$emit('on_move_click', props.row)"><img src="@/assets/images/Common/table/folder_b.png" alt="map"></span>
<!--                        <b-button size="ss" v-if="props.column.field == 'go_map'" @click="$emit('on_goto_map_click', props.row)" class="table_icon">-->
<!--                          <img src="@/assets/images/Common/table/map_icon.png" alt="map"></b-button>-->
<!--                        <b-button size="ss" variant="outline-info" v-else-if="props.column.field == 'info'" @click="$emit('on_info_click', props.row.idx)" ><i class="fas fa-info"></i></b-button>-->
                    </template>
                </vue-good-table>
           </div>
          <DlgSearch ref="modal" :adv_search="adv_search" @change="change" @handleOk="handleOk"/>
        </div>
</template>

<script>
import DlgSearch from '@/components/popup/DlgSearch';

import _ from 'lodash'

export default {
    name: 'ServerSideTable',
    props: [
        'columns',
        'totalRecords',
        'select',
        'search',
        'sort',
        'adv_search',
        'add_custom_column',
        'max_height'
    ],
    watch: {
    },
    data() {
        return {
            isLoading: false,
            rows: [],
          //  totalRecords:0,
            serverParams: {
                searchTerm: '',
                columnFilters: {
                },
                sort: {
                    field: '',
                    type: '',
                },
                page: 1,
                perPage: 15
            },
            url: '',
            is_search_dlg_ok: false,
       
        }
    },
    methods: {
        onRowClick(params) {
            this.$emit('on-row-click', params);
          //  this.rowStyleClassFn(this.getSelectRows())
        },
     
        getSelectRows() {
            return this.$refs.table.selectedRows;
        },
        onSearch: _.debounce(function (params) {
            this.updateParams(params);
            this.serverParams.page = 1;
           // this.loadItems();
            return false;
        }, 500),
        updateParams(newProps) {
              this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
          //  this.loadItems();
        },
        onPerPageChange(params) {
           this.updateParams({perPage: params.currentPerPage});
           //this.$emit.getPagedata(params.currentPerPage);
         //  this.loadItems();
           /*
           if(params.currentPerPage > 400) {
                alert('Searching data is limited to under 1,000')

               this.updateParams({perPage: 10, page: 1}); 
              //  this.loadItems();
               // this.onSearch();
               return false;
            }else{
                this.updateParams({perPage: params.currentPerPage});            
                this.loadItems();
            }
            */
        },
        onSortChange(params) {
            this.updateParams({  
                page: 1,              
                sort: {
                 //  type: params.sortType,
                 //   field: this.columns[params.columnIndex].field,
                   type: params[0].type,
                   field: params[0].field,
                },
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        changeCell(changedData, column, row) {
           // alert(changedData+":"+ column+":"+ row)
            this.rows[row.originalIndex][column] = changedData
            this.$emit('change_set', changedData, column, row) 
        },
        loadItems(cb) {
            /*
            let s = (this.url.indexOf("?")>0)?'&':'?';
            let url = this.url+s+'page='+this.serverParams.page+'&per_page='+this.serverParams.perPage+'&search_term='+this.serverParams.searchTerm+'&sort_field='+this.serverParams.sort.field+'&sort_type='+this.serverParams.sort.type


            if(this.is_search_dlg_ok) {
                for(let i=0; i<this.adv_search.length; i++) {
                    if(this.adv_search[i].control==='range') {
                        if((this.adv_search[i].value!==null)&&(this.adv_search[i].value2!==null))
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','+this.adv_search[i].value2
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value+','
                        else if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'=,'+this.adv_search[i].value2
                    }
                    else {
                        if(this.adv_search[i].value!==null)
                            url += '&'+this.adv_search[i].field+'='+this.adv_search[i].value
                    }
                }
            }

            this.isLoading = true;
            this.$http.get(url).then((res) => {
                */
                this.rows =  this.url;
                //this.totalRecords =  this.url.length;
                this.isLoading = false;
                cb && cb(this.rows);
		//	});
        },
        setUrl(_url, cb) {
            this.url = _url;
            this.loadItems(cb)
        },
        change(e, index) {
            this.$emit('search_item_change', e, index)
        },
        handleOk() {
            this.is_search_dlg_ok = true
            this.loadItems()
        },
        clear() {
            for(let i=0; i<this.adv_search.length; i++) {
                this.adv_search[i].value = null
                if(this.adv_search[i].control==='range') this.adv_search[i].value2 = null;
            }
            this.serverParams.searchTerm = ''
            this.is_search_dlg_ok = false;
            this.loadItems()
        },
        get_option_highe() {
            return this.$refs.optionHeight.clientWidth
        }
    },
    mounted () {
    },
    beforeDestroy () {
    },
    components: {
        DlgSearch: DlgSearch
    }
}
</script>

<style lang="scss" scoped>
.table-option {
  margin-top: 0;
}
.custom-checkbox .custom-control label.custom-control-label{
  vertical-align: top;
}
td {
  color: white !important;
}


.btn-size-view {
  background: #f2f2f2;
}

.input-group > {
  .form-control {
    &.gis-master__input {
      width: calc(100% - 200px);
      height: 30px;
      text-indent: 40px;
      border: none;
      background: url('../../assets/images/Common/table/btn_search.png') 3px center no-repeat;
      background-size: 28px;
      color: #171725;
      font-size: 18px;
    }
  }
}

</style>

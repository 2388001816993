<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
<!--                        <li class="breadcrumb-title">Map Frequency Comparison</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/pressure-monitoring')" href="#">{{$t('pressure.title')}}</a></li>
                      
                        <li class="breadcrumb-item active">{{$t('pressure.map_page')}}</li>
                    </ol>
                </li>
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div>
             <b-row style="margin: 0;">
                <b-col :cols="msize" style="padding: 0 10px 0 0;">
                    <div class="press_m1_map">

                        <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="value"  @keyup.enter ="Enter_Check1"/></span>
                        <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
                        <span style="float:left; margin-left:10px;"> <b-select size="sm" v-model="pressure_value_type" :options="pressure_value_opt" change></b-select> </span>
                    </div>
                    <div style=" margin : 0px; padding-right: -5px; " >
                    <div style="overflow-y: scroll;" :style="{height: contents_height+'px'}">

                     <Map
                         ref="map"
                         :widx="$route.query.widx"
                         w_type="110"                        
                         :visible_gisload_button="true"
                         :visible_size_side_view_button="true"                        
                         :press_icon ="true"
                         :press_icon1 ="true"
                        @set_map_gisload="set_map_gisload"
                        @set_info="set_info"
                        @toggle_visible_all_layer = "toggle_visible_all_layer"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @toggle_visible_Hydraulic_type = "toggle_visible_Hydraulic_type"
                        @size_view="size_view"
                        @onLeakMapClick="onLeakMapClick()"
                     />
                      </div>
                     </div>
                 </b-col>
                 <b-col  v-if="v_chart" :cols="tsize" style=" padding: 0px 2px 0 0; overflow-y: scroll;" :style="{height: contents_height+'px'}">
                   <b-row class="map-chart-row press">
                       <b-col style="padding-top: 5px; padding-bottom: 5px;">
                          <div class="specification_area" style="  padding: 5px 20px 10px 20px ;height:255px;">
  
                         <div class="status leak hammer" >
                            <input type="radio" id="radio_01" name="radio01"  v-model="vchart" value="kgf">
                            <label for="radio_01">kgf</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart" value="bar">
                            <label for="radio_02">bar</label>
                            <input type="radio" id="radio_03" name="radio01"   v-model="vchart" value="psi" >
                            <label for="radio_03">psi</label>
                          </div>
     
                              
                      
                            <div>
                                 <button  @click="on_goto_map_click(lidx1)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_table_map.svg" alt="map" style="width:20px;"  /></button>
                                 <button variant="outline-danger" @click="show_press_info(lidx1)" style="border:0;  ;background:#172A46;"><img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg" /></button>
                             No : {{tag_name1}} </div>   
                            

                                <!--PressMinChart :url="real_url1" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:5px;"   /-->  
                                <pressChartData1 :url="real_url1" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:0px;"   />  
                          </div>
                
                       </b-col>
                   </b-row>

                   <b-row class="map-chart-row press" >
                       <b-col style="padding-top: 5px; padding-bottom: 5px;">
                        <!-- <div class="status press" >
                            <input type="radio" id="radio_01" name="radio01"  v-model="vchart" value="kgf">
                            <label for="radio_01">kgf</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart" value="bar">
                            <label for="radio_02">bar</label>
                            <input type="radio" id="radio_03" name="radio01"   v-model="vchart" value="psi" >
                            <label for="radio_03">psi</label>
                          </div> -->
                          <div class="specification_area" style="  padding: 0px 20px 10px 20px ;height:250px;">
                              <div> 
                                <button  @click="on_goto_map_click(lidx2)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_table_map.svg" alt="map" style="width:20px;"  /></button>  
                                <button variant="outline-danger" @click="show_press_info(lidx2)" style="border:0; background:#172A46;"><img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg" /></button>    No : {{tag_name2}} </div>
                               <pressChartData1 :url="real_url2" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:0px;"   />  
                                   
                           </div>
                             
                       </b-col>
                   </b-row>
                   <b-row class="map-chart-row press" >
                       
                       <b-col style="padding-top: 0px; padding-bottom: 5px;">
                          <!--<div class="status press" >
                            <input type="radio" id="radio_01" name="radio01"  v-model="vchart" value="kgf">
                            <label for="radio_01">kgf</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart" value="bar">
                            <label for="radio_02">bar</label>
                            <input type="radio" id="radio_03" name="radio01"   v-model="vchart" value="psi" >
                            <label for="radio_03">psi</label>
                          </div> -->
                          <div class="specification_area" style=" padding: 10px 20px 10px 20px ;height:250px;">
                               <div>
                                      <button  @click="on_goto_map_click(lidx3)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_table_map.svg" alt="map" style="width:20px;"  /></button>
                                   <button  @click="show_press_info(lidx3)" style="border:0;;background:#172A46;"><img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg"   /></button>  No : {{tag_name3}} </div>
                                
                                <pressChartData1 :url="real_url3" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:0px;"   /> 
                           </div>
                         
                       </b-col>
                   </b-row>
                 </b-col>
            </b-row>
         </div>
    <DlgPressureInfo ref="pressure_info"  @leaktable_refresh="leaktable_refresh" />
    </div>
</template>

<script>

import Map from '@/components/Map';
import DlgPressureInfo from '@/components/popup/DlgPressureInfo'
import {CreateVectorLayer_f} from '@/gis.js';
import pressChartData1 from '@/components/chart/pressChartData1' ;
//import PressMinChart from '@/components/PressMinChart' ;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import * as d3 from 'd3'
import d3tip from "d3-tip";
 d3.tip = d3tip;

export default {
    name: 'LeakAnalysis1_c',
    props: [
        'w_type'
    ],
    data() {
        return {
            selected_ts_flow: '1',        
            value:'',
            loaded_ts_flow : true,
            v_chart : true,
            vchart : 'bar',
            contents_height: 600,
            waveform_height: 42,
            mnfchart_height: 220,
            wavesurfer: null,
            layer_all_visible: true,
            chart_i : 0 ,
            real_url1: '',
            real_url2: '',
            real_url3: '',
            lidx1 :'',
            lidx2 :'',
            lidx3 :'',
            psr_path1 : '',
            psr_path2 :'',
            psr_path3 : '' ,
            tag_name1 : '',
            tag_name2 :'',
            tag_name3 : '' ,       
            pressure_value_type : null,            
            pressure_value_opt: ['avg' , 'min', 'max' ],     
            options: {
            },
            file: ' ' ,
            msize:'7',
            tsize:'5',
            flow_date : '2020-11-01',
            roof: 0 ,
        }
    },
    methods: {
         toggle_visible_all_layer(a) {
           if(a=== 1){
              // alert(a)
                this.$refs.map.leak_cal = 1;
           }else{
              //  alert(a)
                this.$refs.map.leak_cal = 0;
           }
           this.$refs.map.init_reload_leak_layer() ;
        
        },
        
        addr_search(){
          this.$refs.map.go_search(this.value);
        },
        Enter_Check1(){
            this.addr_search();
        },
  
    
        Enter_Check(){
         //  if(event.keyCode == 13){
          this.go_search();  // 실행할 이벤트
        },
        show_press_info(idx) {
          //this.$refs.map.show_press_info(idx)
           this.$refs.pressure_info.show(idx)
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        on_goto_map_click(idx) {
            this.$refs.map.get_extent('w_type=110&p_idx='+idx).then((data)=>{
                this.$refs.map.set_fit_extent(data)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
             this.$refs.map.select_facility_feature('110','',idx)  
        },
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                     if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    this.$refs.map.layer_list.push(newlayer) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);

                } //for문 끝

           } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },

        onLeakMapClick(){
            let tag_id = this.$refs.map.leak_idx;
       
            this.chart_i = this.chart_i+1 ;
            if(this.chart_i%3 === 1){
                this.tag_name1 = tag_id ;    
                this.psr_path1 = this.$refs.map.leak_recov_idx ; 
                this.lidx1 = tag_id ;  
                this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart;
            }else if(this.chart_i%3 === 2){
               this.tag_name2 = tag_id ;    
                this.lidx2 = tag_id ;  
               this.psr_path2 = this.$refs.map.leak_recov_idx ; 
               this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart;
            }else{
                this.tag_name3 = tag_id ;    
                 this.lidx3 = tag_id ;  
                this.psr_path3 = this.$refs.map.leak_recov_idx ; 
                this.real_url3 =this.URL+this.psr_path3+'&retType='+this.vchart;
             }

        },
        size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
        },
        chang_press_mapvalue_type() {
          // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          
          this.$refs.map.leak_cal = this.pressure_value_type;         
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;

        },
        toggle_visible_Hydraulic_type() {
          // 처음엔 동수도 적용안한다. Hydraulic_type 데이터를 가져와 지도에 보여준다. 동수도 적용안한다.
          // All or Hydraulic 적용값 가져와 지도에 보여준다.
          this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
          if(this.$refs.map.layer_leak_visible)
            this.$refs.map.leak_type = 'HYDRAULIC';
          else
            this.$refs.map.leak_type = null;
          
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;      
        },            
    },
    mounted () {
        this.contents_height = window.innerHeight - 120;
        setTimeout(()=>{
          let setting = this.$store.setting
          if(setting) {
                this.pressure_value_type = this.$store.setting.pressure.legend.value
          }
        }, 1000)
   
    },
    watch: {
      v_chart: function() {
          if(this.v_chart){
          
               setTimeout(()=>{
                   this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart+"&roof="+(++this.roof);
                   this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart+"&roof="+(++this.roof);
                   this.real_url3 = this.URL+this.psr_path3+'&retType='+this.vchart+"&roof="+(++this.roof);
               }, 500)

          }
      },
      vchart() {
            //setTimeout(()=>{
                   this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart;
                   this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart;
                   this.real_url3 = this.URL+this.psr_path3+'&retType='+this.vchart;
             //  }, 500)
          //var c_url = this.fft_url.split('#') ;
          //this.fft_url = c_url[0]+'#'+this.vchart ;
         // this.real_url1 = this.URL+'api/'+ params.row.psr_file_path+'&sensor='+ params.row.sensor+'&retType='+this.vchart 
          //this.real_url1 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag1)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#3#"+this.vchart;
          //this.real_url2 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag2)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#3#"+this.vchart;
          //this.real_url3 = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag3)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#3#"+this.vchart;          
      },
      pressure_value_type(){
        this.chang_press_mapvalue_type() ;
      }
    },
    components: {
        Map: Map,
      //  PressMinChart:PressMinChart,
        pressChartData1:pressChartData1,
       // PulseLoader: PulseLoader,
        DlgPressureInfo : DlgPressureInfo,
    }
}
</script>

<style   lang="scss" scoped>
.map-chart-row.press{
  min-height: 255px;  
}
.infoimg{
    width: 20px;
}
 .press_m1_map{
    position: absolute;
    left:255px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}
.press {
    top:20px;
}
.real_chart .wavchart {
  height: 220px;
}
.real_chart .wavchart .highcharts-container .highcharts-root {
    width: 100%;
}
.status.leak.hammer {
    margin-top:0;
}
</style>


<template>
    <div  :style="{height: height+'px'}">
        <bar-chart ref="chart" v-bind:chartData1="chartData1"  v-bind:chartData2="chartData2"  v-bind:chartData3="chartData3" v-bind:xdata="xdata" v-if="loaded" v-bind:item="item"
         v-bind:class="size" @onBarClick="onBarClick"></bar-chart>
    </div>

</template>

<script>
//import * as d3 from 'd3'
import BarChart from '@/components/chart/BarChart_pump'
//import {Line} from 'vue-chartjs'

export default {
    name: 'pumpFlowDayChart',
 //   extends: Line,
    components: {BarChart },
    props: [
        'height',
        'url'
    ],
    watch: {
        bidx: function() {
           this.redraw();          
        },
        url() {
            if(this.url==='') return
           // datacollection: null,
           // this.chartData= [];
          this.datacollection= null;
          this.chartData1 = [];
          this.chartData2 = [];
          this.chartData3 = [];
          this.xdata = [];
          this.item = Number(this.$route.query.item) ;
          var s_url = this.url.split("#")
          this.item = Number(s_url[1]) ;
          this.loaded=false;
      //    this.url = "http://localhost:3000/api/get_leak_bubble?w_idx=768" ;

      //   this.$http.get(this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30').then((res) => {
            this.$http.get(s_url[0]).then((res) => {
              if( res.data !=="" && res.data != null) {
                var ts_mnf = res.data.results[0]             
                for(let i=0; i<ts_mnf.length; i++) {
                   /*
                    this.ts_mnf[i]['date_str'] = this.ts_mnf[i]['date']
                    this.ts_mnf[i]['date'] = parseTime(this.ts_mnf[i]['date'])
                    this.ts_mnf[i]['rate'] = this.ts_mnf[i]['mnf'] / (this.ts_mnf[i]['flow']/24) * 100

                    this.table_mnf.push({
                        date_str: this.ts_mnf[i]['date_str'],
                        flow: this.ts_mnf[i]['flow'].toFixed(0),
                        mnf: this.ts_mnf[i]['mnf'].toFixed(0),
                        rate: this.ts_mnf[i]['rate'].toFixed(0)
                    })
                  */
                  
                   if(this.item === 0){ 
                       this.xdata.push(ts_mnf[i]['pump_date'].substring(5,10));                          
                       this.chartData1.push(ts_mnf[i]['flow_rate'].toFixed(0));               
                      this.chartData2.push( ts_mnf[i]['max_total_head'].toFixed(0));
                      this.chartData3.push(ts_mnf[i]['min_total_head'].toFixed(0));
                   }else{
                      this.xdata.push(ts_mnf[i]['water_date'].substring(5,10)); 
                      this.chartData1.push(ts_mnf[i]['out_flow'].toFixed(0));
                      this.chartData3.push( ts_mnf[i]['lowest_water_level'].toFixed(0));
                      this.chartData2.push(ts_mnf[i]['highest_water_level'].toFixed(0));                
                   }
                }   

              }else{

                  this.xdata = [];
                  this.chartData1 = [] ;
                  this.chartData2 = [] ;
                  this.chartData3 = [] ;
              }
                
            });
           ++this.bidx;


        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        datacollection: null,
        chartData1: [],
        chartData2: [],
        chartData3: [],
        bidx : 0 ,
        size: 'firstClass',
        loaded: false,
        bar_idx:-1,
        item : 0 ,
      }
    },
    methods: {

       redraw () {

              this.loaded = true;
            //  this.update();
           //   this.$data._chart.update()
           // this.chartData =  cData ;
            //this.$refs.chart.update();

      },
      onBarClick(){
         this.bar_idx = this.$refs.chart.bar_idx;
         this.$emit('onBarClick') ;
      }



    },
  mounted () {
        window.addEventListener('resize', this.resize);

        if(this.url==='') return
/*
         this.$http.get(this.url).then((res) => {
            //데이터 셋팅
             if( res.data !=="" && res.data != null) {
                var b_data = res.data.rows;
                for(let i=0; i < b_data.length ; ++i){
                  this.xdata.push(b_data[i].w_title);
                  //this.chartData1.push(b_data[i].leak_count) ;
                  //this.chartData2.push(b_data[i].leak_inside) ;
                  this.chartData1.push(10) ;
                  this.chartData2.push(20) ;
                  this.chartData3.push(b_data[i].other_count) ;

                }
             }else{
                  this.xdata = [];
                  this.chartData1 = [] ;
                  this.chartData2 = [] ;
                  this.chartData3 = [] ;
             }
          });
*/

    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    }

}
</script>

<style scoped>
.firstClass{
   height: 370px;

 /*
    padding-top: 10px;
    width: 700px;
    */
  }

</style>

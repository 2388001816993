<template>
  <highcharts id= "wavchart"  class="wavchart" :options="chartOptions"></highcharts>
</template>

<script>
export default {
  name: 'pressChart11',
   props:{

      chartData: {
        type: Array
      },
      interval : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
       sdate : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
      ytitle : {type:String },
      chart_type : { type:String,default:"line"}
  },
  data () {
    return {
  
       chartOptions: {
     
         colorAxis:[{lineColor:"#434348"}],
         colors : ["#ffffff","#5580b4","#9cd1ff" ],
         credits: {      
            text: '',
            href: ''
         },

        series: [{
          name: '',
          showInLegend: false,
          followPointer: true,
         // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
           data: this.chartData,
           type:this.chart_type,
           lineWidth: 1.0,
     
          
        }],
         exporting: {
              buttons: { contextButton: { enabled: false} }
         },    
          tooltip: {
            valueDecimals: 2,
            shared: true,
            crosshairs: true
          },
          boost: { enabled: true },
          //dateTimeLabelFormats:{millisecond : {main:"%S.%L"}},
          plotOptions: {
                area: {
                    fillColor: {
                      linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                      },
                      stops: [
                        [0,'#d6e8f9'],
                        [1,'#b5d6f5']
                      ]
                    },
                },
            
        
        
          },
       // }],      
        title: {        text:'' , },
         chart: {   zoomType: 'xy' ,
                
                
                },
         /*
         tooltip: {
                formatter: function() {
                        return '<b>'+ this.series.name +'</b><br/>'+
                        this.dateFormat('%Y-%m-%d %H:%M:%S', this.x) +'<br/>'+
                        this.numberFormat(this.y, 2);
                }
            },
            */
        yAxis : {title:{text:this.ytitle,margin:30, style : {fontSize: '1.0em', color: '#c6c6c6' }},
                  gridLineColor: '#2a4369',
                  gridLineWidth:1,   
                  className: 'highcharts-color-0', 
                  labels: {
                      align: 'left',
                      x: -20,
                      y: 4,
                      format: '{value:.,.1f}',
                   
                    },                 
                 },   
        xAxis: { type: 'datetime',
                 title : {text:'Datetime', style : {fontSize: '1.0em', color: '#c6c6c6' }}, 
                labels: { 
               // formatter: function() {
              //    return Highcharts.dateFormat('Year:%y <br/> Month:%e<br /> %H:%M:%S:%L', this.value);
             //     x: 20,
              //    y: 10,
               
             //   },
              }  
              
              }
    },

    
       
    }
  }
}
</script>
<style lang="scss" >
//@import url("https://code.highcharts.com/css/highcharts.css");
.wavchart {
  min-width: 450px;
  max-width: 1600px;
  width:100%;
  margin: 0 auto;
}
.highcharts-axis.highcharts-color-0 text {
    fill: #7cb5ec;
}

.highcharts-grid.highcharts-yaxis-grid.highcharts-color-0 .highcharts-grid-line {
    stroke-width: 0.5px;
    stroke: #cccccc;
}

</style>
<template>
  <!--  <div>-->
  <div class="items">
    <div class="item-head">
      <div class="item-head--left">
        <h3>{{ dma_name }}</h3>
        <p class="date">{{ last_date_time }}</p>
      </div>
      <div class="item-head--right">
        <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="go_m2_info(tag)"
             style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
        <img src="@/assets/images/new/ic_list_map.svg" alt="" @click="goRouter('/block-monitoring-map',0)"
             style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
        <img src="@/assets/images/new/ic_menu_chevron.svg" alt="" @click="goRouter('/block-monitoring-detail',1)"
             style="width: 8px; cursor: pointer">
      </div>
    </div>
    <div class="item-content">
      <ul class="item-content--left">
        <li>
          <span class="unit">㎥/hr(Current Time)</span>
          <p class="num">{{ now_flow }}</p>
        </li>
        <li>
          <span class="unit">㎥/hr(MNF)</span>
          <p class="num">{{ now_mnf }}</p>
        </li>
      </ul>
      <div class="item-content--right">
        <table class="ns-table">
          <!--          <colgroup>-->
          <!--            <col style="width:16.666%">-->
          <!--          </colgroup>-->
          <thead>
          <tr>
            <th></th>
            <th>{{ date_str[0] }}</th>
            <th>{{ date_str[1] }}</th>
            <th>{{ date_str[2] }}</th>
            <th>{{ date_str[3] }}</th>
            <th>{{ date_str[4] }}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td width="25%"><span >Supplied(㎥/day)</span>
             </td>
            <td>{{ flow[0] }}</td>
            <td>{{ flow[1] }}</td>
            <td>{{ flow[2] }}</td>
            <td>{{ flow[3] }}</td>
            <td>{{ flow[4] }}</td>
          </tr>
          <tr>
            <td><span>MNF(㎥/hr)</span>
             </td>
            <td>{{ mnf[0] }}</td>
            <td>{{ mnf[1] }}</td>
            <td>{{ mnf[2] }}</td>
            <td>{{ mnf[3] }}</td>
            <td>{{ mnf[4] }}</td>
          </tr>
          <tr>
            <td><span>Rate(%)</span>
            </td>
            <td>{{ rate[0] }}%</td>
            <td>{{ rate[1] }}%</td>
            <td>{{ rate[2] }}%</td>
            <td>{{ rate[3] }}%</td>
            <td>{{ rate[4] }}%</td>
          </tr>
          </tbody>
        </table>
        <div class="btn_next" @click="goRouter('/block-monitoring-detail',1)"></div>
      </div>
    </div>
    <DlgM2MoniSetting ref="m2_setting"/>
  </div>
  <!-- Start Content-->
  <!--    <div class="monitoring-Card">-->
  <!--      <div class="monitoring-card-set">-->
  <!--        <div class="mtcard">-->
  <!--          <div class="panel panel-default card-view">-->
  <!--            <div class="mtcard-heading" :style="{backgroundColor: title_color}">-->
  <!--              <div class="mtcard-heading-title">-->
  <!--                <span class="mtcard-geading-title-child-none"></span>-->
  <!--                <span class="mtcard-heading-title-child">{{dma_name}}</span>-->
  <!--                <span class="mtcard-geading-title-point">-->
  <!--                          <i class="mdi mdi-brightness-1 mdi-light-green"></i>-->
  <!--                          </span>-->
  <!--              </div>-->


  <!--            </div>-->
  <!--            <div class="mtcard-panel">-->
  <!--              <div class="mtcart-panel-body">-->

  <!--                <div class="monitoring-table">-->
  <!--                  <div class="monitoring-table-body">-->
  <!--                    <div class="monitoring-table-row" id="ms-public-methods">-->
  <!--                      <div class="monitoring-table">-->
  <!--                        <table class="table table-bordered text-center flex-table">-->
  <!--                          <thead class="thead-light">-->
  <!--                          <tr>-->
  <!--                            <th>4</th>-->
  <!--                            <th>3</th>-->
  <!--                            <th>2</th>-->
  <!--                            <th>1</th>-->
  <!--                          </tr>-->
  <!--                          </thead>-->
  <!--                          <tbody>-->
  <!--                          <tr>-->
  <!--                            <td>{{flow[3]}}</td>-->
  <!--                            <td>{{flow[2]}}</td>-->
  <!--                            <td>{{flow[1]}}</td>-->
  <!--                            <td>{{flow[0]}}</td>-->
  <!--                          </tr>-->
  <!--                          <tr>-->
  <!--                            <td>{{mnf[3]}}</td>-->
  <!--                            <td>{{mnf[2]}}</td>-->
  <!--                            <td>{{mnf[1]}}</td>-->
  <!--                            <td>{{mnf[0]}}</td>-->
  <!--                          </tr>-->
  <!--                          <tr>-->
  <!--                            <td>{{rate[3]}}%</td>-->
  <!--                            <td>{{rate[2]}}%</td>-->
  <!--                            <td>{{rate[1]}}%</td>-->
  <!--                            <td>{{rate[0]}}%</td>-->
  <!--                          </tr>-->

  <!--                          </tbody>-->
  <!--                        </table>-->
  <!--                      </div>-->
  <!--                      &lt;!&ndash; 0320수정 &ndash;&gt;-->
  <!--                      <div class="ms-selection">-->
  <!--                        <div class="mtcard-right-btn">-->
  <!--                          &lt;!&ndash; 0320수정 클래스명변경 &ndash;&gt;-->
  <!--                          <span class="btn-v3 mt-btn-blue btn-width-sm"-->
  <!--                                href="#">{{now_flow}}</span>-->
  <!--                          <span class="btn-v3 mt-btn-green btn-width-sm mt-2"-->
  <!--                                href="#">{{now_mnf}}</span>-->
  <!--                          &lt;!&ndash; 0320수정 클래스명변경 &ndash;&gt;-->
  <!--                        </div>-->
  <!--                      </div>-->
  <!--                    </div>-->
  <!--                    <div class="button-box">-->
  <!--                      <a class="btn-v3 mt-bt-lbt btn-outline mr-10 pbtm" @click="goRouter('/block-monitoring-detail')" href="#">Detail</a>-->
  <!--                      <a class="btn-v3 mt-bt-rbt btn-outline mr-10 pbtm" @click="$router.push({path: '/'})" href="#">Map</a>-->
  <!--                    </div>-->
  <!--                  </div>-->

  <!--                </div>-->
  <!--              </div>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->

  <!--      </div>-->
  <!--      &lt;!&ndash; end row &ndash;&gt;-->
  <!--    </div> &lt;!&ndash; container-fluid &ndash;&gt;-->
  <!--  </div>-->

</template>

<script>
import DlgM2MoniSetting from '@/components/popup/DlgM2MoniSetting'

export default {
  name: 'MonitoringCard',
  props: [
    'dma_name',
    'now_flow',
    'tag',
    'last_date_time',
  ],
  data () {
    return {
      //now_flow: null,
      now_mnf: null,
      last_date: null,
      date_str: [],
      flow: ['0', '0', '0', '0'],
      mnf: ['0', '0', '0', '0'],
      rate: ['0', '0', '0', '0'],
      title_color: 'red'
    }
  },
  methods: {
    goRouter (path, a) {
      if (a === 1) {
        this.$router.push({ path: path, query: { tag: this.tag, last_date: this.last_date } })
      } else {
        this.$router.push({ path: path, query: { w_type: '160', tag: this.tag, mea_date: this.last_date } })
      }
    },
    go_m2_info (m2_point_id) {
      this.$refs.m2_setting.show(m2_point_id)
    },
  },
  mounted () {
    this.title_color = 'white'
    ////sp_block ==> sp_flow 변경
    //this.$http.get(this.URL+'api/API21/block_day/'+encodeURI(this.tag)+'?limit=5').then((res) => {
    this.$http.get(this.URL + 'api/API21/flow/day/' + encodeURI(this.tag) + '?limit=5').then((res) => {
      this.flow = []
      this.mnf = []
      this.rate = []
      this.date_str = ['-', '-', '-', '-', '-']
      for (let i = 0; i < res.data.results[0].length; i++) {
        if (i === 0) {
          this.last_date = res.data.results[0][i].measure_datetime
          this.now_mnf = res.data.results[0][i].night_min_flow

        }
        let d_str = res.data.results[0][i].measure_datetime
        if (d_str !== undefined && d_str.length > 5) {
          d_str = d_str.substr(5)
        }
        this.date_str[i] = d_str
        //this.flow.push( res.data.results[0][i].water_flow.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") )
        this.flow.push(res.data.results[0][i].water_flow)
        this.mnf.push(res.data.results[0][i].night_min_flow)
        this.rate.push(res.data.results[0][i].night_min_rate)
      }
    })

  },
  beforeDestroy () {
  },
  components: {
    DlgM2MoniSetting: DlgM2MoniSetting,
  }
}


</script>

<style scoped lang="scss">
// 컴포넌트 개별적용.
.items {
  padding: 16px;
  height: 280px;
  box-sizing: border-box;
  border: 1px solid #707070;

  .item-head {
    display: flex;
    justify-content: space-between;

    &--left {
      h3 {
        font-size: 24px;
        color: white;
        line-height: 1;
        margin: 0 0 6px 0;

      }

      p.date {
        margin: 0;
        font-size: 14px;
        color: white;
        opacity: .6;
      }
    }
  }

  .item-content {
    display: flex;
    padding-top: 16px;

    &--left {
      width: 212px;
      // 2023
      // padding-top: 32px;

      li {
        font-family: 'Roboto', sans-serif;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      span.unit {
        font-size: 15px;
        line-height: 1;
        color: white;
      }

      p.num {
        font-size: 44px;
        font-weight: bold;
        color: #01AF81FF;
        line-height: 1;
      }
    }

    &--right {
      width: 100%;
      position: relative;

      .btn_next {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
}
</style>

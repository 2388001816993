<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                     <li>
                         <ol class="breadcrumb">
<!--                              <li class="breadcrumb-title">Monitoring Detail</li>-->
<!--                              <li class="breadcrumb-bar">|</li>-->
                           <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                           <li class="breadcrumb-item"><router-link to="/pressure-monitoring">{{$t('pressure_monitoring.mpage_title')}}</router-link></li>
                             <li class="breadcrumb-item active">{{$t('pressure_monitoring.dpage_title')}}</li>
                         </ol>
                     </li>

                 </ul>
         </div>
     <!-- tom-bar-end -->
<!--      <div class="content-area">-->
        <search-left-type3 :box="box" :activeCheckIndex="activeCheckIndex" @updateCheckIndex="updateCheckIndex" @go_search="go_search"
        :visible_sort_box_press="true" @on_goto_map_click = "on_goto_map_click"/>
    
        <div class="right_area chart_box right_area_01 tab-chart-box" style="width: 77%;">
          <div class="tab_list_02 is--square" ref="tabList">
            <div :class="[`tab_btn tab_btn0${index+1}`, { 'on' : activeIndex === index }]" v-for="(item, index) in tabs" :key="index" @click="tabItem(index)">{{ item.name }}</div>
          </div>
          <div class="tab_box_area" ref="tabBox">
        
            <div id="tab_box01" :class="['tab_box',{ 'on' : activeIndex === 0 }]">
              <div class="info_box">
                <ul class="list">
                  <li> {{$t('pressure_monitoring.name')}} : {{tag_name}}  </li>
                </ul>
          
               
                
              </div>
              
              <div class="img_area">
                <!-- <div  style=" background-color:white;"> -->
                <div  style=" ">
                    <div style="position:absolute; top:6%; right:365px;  z-index: 102;">
                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(-1)"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(1)"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                    </div>
                  <div ref="button_group_realtime">
                    <b-form inline class="btn_list_wrap" style=" top: 6%; right: 130px;">
                      <b-input size="sm" v-if="isShowDate" v-model="flow_date" type="date" @change="change_flow_date($event)" class="input_date m-0 btn-gray-date "/>
                      <b-button-group class="mr-2 btn_list">
                        <div class="lookup_btn search"   style="margin-right: 100px;" @click="showDate">Search Date</div>                 
                        <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='30' @change="change_selected_ts_flow($event)">
                          <span>30D</span>
                        </b-radio>
                        <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='7' @change="change_selected_ts_flow($event)">
                          <span>7D</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='3' @change="change_selected_ts_flow($event)">
                          <span>3D</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_flow" value='1' @change="change_selected_ts_flow($event)">
                          <span>1D</span>
                        </b-radio>
                      </b-button-group>
<!--                  <span>Realtime Monitoring</span>-->
                    </b-form>
                   
                  </div>
                   <div style="position:absolute; top:6%; right:20px;">
                      <span><button class="btn btn-primary btn-g" style="width:120px;" @click="chart_exceldownload(2)" >{{$t('pressure_monitoring.excel_down')}}</button></span>
                    </div>
                    <!--div class="status" style="top:6.5%;    right: 750px;" >                                            
                       
                          <span style="float: right;">
                            <b-input v-model="m_min" style="width:60px;height:20px;margin:0 0 0 10px;padding:10px 0;text-align:center;" 
                            type="number" step="0.25" class="input_spin" />
                        
                          </span> 
                    </div-->  
                     <div class="status leak" style="margin-top:-30px;right:40px;" >                                            
                          <input type="radio" v-model="vchart1" value="0" id="proportional"> <label for="proportional">{{$t('leakage_info.proportional')}}</label>   
                          <span style="float: right;">
                            <!--b-input v-model="m_min" style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                            type="text" maxlength=4  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" /-->
                            <b-input v-model="m_min" style="width:60px;height:20px;margin:5px 0 0 10px;padding:10px 0;text-align:center;" 
                            type="number" step="0.25" class="input_spin" />
                          </span> 
                          <input type="radio" v-model="vchart1" value="1" id="fixed"><label for="fixed" style="margin-right:10px;">Min Fixed : </label>
                    </div>  
                  <div><!--
                      <div>
                        <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                    </div>
                      <PressMinChart :url="real_url" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:25px;"   />  -->
                       <p>
                         <pressChartData1 class="presschart_m2_c1" :url="real_url"  ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;    margin-top: 30px;"   />
                       </p> 
                  </div> 
                </div>
              </div>
            </div>
    <div id="tab_box02" :class="['tab_box',{ 'on' : activeIndex === 1 }]">
              <div class="info_box">
                <ul class="list">
                  <li>{{$t('pressure_monitoring.name')}} : {{tag_name}}</li>
                </ul>
              </div>
              <div class="img_area">

                <div  class="large-chart-bg">
                  <div ref="button_group_mnf">
                    <div style="position:absolute;top:6%; right:510px; z-index: 103;">
                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_mnf(-1)"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                    <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_mnf(1)"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                    </div>
                    <b-form inline class="btn_list_wrap" style=" top: 6%; right: 150px;">
                      <b-input v-if="isShowDate1" size="sm"  v-model="mnf_date" type="date" @change="change_mnf_date($event)" class="m-0 btn-gray-date "/>
                   

                      <b-button-group class="mr-2 btn_list">
                         <div   class="lookup_btn search" @click="showDate1"  style="  margin-right: 96px;">{{$t('pressure_monitoring.search_date')}}</div>                  
                           
                        <b-radio :button="true"  size="sm" v-model="selected_ts_mnf" value='1' @change="change_selected_ts_mnf($event)">
                          <span>1M</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='2' @change="change_selected_ts_mnf($event)">
                          <span>2M</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='3' @change="change_selected_ts_mnf($event)">
                          <span>3M</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='6' @change="change_selected_ts_mnf($event)">
                          <span>6M</span>
                        </b-radio>
                      </b-button-group>
                      <div class="status leak" style=""  @click="change_min">                                            
                          <!--input type="radio" v-model="vchart" value="0" id="proportional"> <label for="proportional">{{$t('leakage_info.proportional')}}</label>   
                          <span style="float: right;">
                            <b-input v-model="min" style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                            type="text" maxlength=4  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                        
                          </span> 
                          <input type="radio" v-model="vchart" value="1" id="fixed"><label for="fixed" style="margin-right:10px;">Min Fixed : </label-->
                            <b-input v-model="min" style="width:60px;height:20px;margin:-10px 40px 0 10px;padding:10px 0 10px ;text-align:center;" @click="change_min"
                                 type="number" step="0.25" class="input_spin" />
                   
                    </div>  
                    </b-form>
                  </div>
                   
          
                     <div style="position:absolute;  top:6%; right:20px;">
                      <span><button class="btn btn-primary btn-g"  @click="chart_exceldownload(1)" >{{$t('pressure_monitoring.excel_down')}}</button></span>
                    </div>        
                  <!--
                  <div ref="mnf_chart" :style="{height: mnfchart_height+'px'}">
                    <svg/>
                  </div>
                
                -->
                <div>
                  <PressMonitoringDayChart :url="bar_url" ref="barchart" id="barchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:20px;"   />  
               </div> 
               </div> 
              </div>
            </div>            
            <div id="tab_box03" :class="['tab_box ',{ 'on' : activeIndex === 2 }]">
           
             
           
             <div class="table_box_wrap scroll_box" style="margin-top:10px;">
              <!-- 2023 -->
              <!-- <table class="with--bg">
                <colgroup>
                  <col style="width:110px">
                  <col style="width:55px">
                </colgroup>
                <thead>
                  <tr>
                    <th>yyyy-MM</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18</th>
                    <th>19</th>
                    <th>20</th>
                    <th>21</th>
                    <th>22</th>
                    <th>23</th>
                    <th>24</th>
                    <th>25</th>
                    <th>26</th>
                    <th>27</th>
                    <th>28</th>
                    <th>29</th>
                    <th>30</th>
                    <th>31</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                  
                    <td >{{item.ym}}</td>
                    <td v-for="(tb_v, index) in item.tb_v" :key="index">{{tb_v.max}}<br>{{tb_v.avg}}<br>{{tb_v.min}}<br>{{tb_v.mnp}}</td>
                  
                  </tr>
                
                </tbody>
              </table> -->
              <p><span style="padding: 35px; top: 10px; position: relative;"> data : max/avg/min/mnp</span> </p>
              <table class="with--bg">
                <colgroup>
                  <col style="width:110px">
                  <col style="width:55px">
                </colgroup>
                <thead>
                  <tr>
                    <th>yyyy-MM</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18</th>
                    <th>19</th>
                    <th>20</th>
                    <th>21</th>
                    <th>22</th>
                    <th>23</th>
                    <th>24</th>
                    <th>25</th>
                    <th>26</th>
                    <th>27</th>
                    <th>28</th>
                    <th>29</th>
                    <th>30</th>
                    <th>31</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                  
                    <td >{{item.ym}}</td>
                    <td v-for="(tb_v, index) in item.tb_v" :key="index">{{tb_v.max}}<br>{{tb_v.avg}}<br>{{tb_v.min}}<br>{{tb_v.mnp}}</td>
                  
                  </tr>
                
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>

      </div>

</template>

<script>
import SearchLeftType3 from '@/components/left/SearchLeftType3_p';
import * as d3 from 'd3'
//import BlockMonitoringBarMnfChart from '@/components/BlockMonitoringBarMnfChart' ;
//import BlockMonRealTime from '@/components/BlockMonRealTime' ;
import d3tip from "d3-tip";
d3.tip = d3tip;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import PressMonitoringDayChart from '@/components/chart/PressMonitoringDayChart' ;
//import PressMinChart from '@/components/PressMinChart' ;
import pressChartData1 from '@/components/chart/pressChartData2_temp' ;

export default {
    name: 'PressMonitoringDetailTab',
    props: [
    ],
    watch: {
      min() {
        if(this.vchart==='0') {
          return;
        }
         this.chang_bar_url();
      },
    
      vchart1() {
 
        this.$refs.realchart.fixed = this.vchart1 ;
        this.$refs.realchart.min = this.m_min ;
        var c_url = this.real_url.split('#') ;
       // this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#1";
 
        this.real_url = c_url[0]+"&fixed="+this.vchart1+"&"+this.m_min+'#1' ;
      } ,      
      m_min() {
        if(this.vchart1 === 1 ){
          this.$refs.realchart.fixed = 1 ;
          this.$refs.realchart.min = this.m_min ;
        //  var c_url = this.real_url.split('#') ;
        var c_url = this.real_url.split('&fixed=') ;
         // this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#1";
 
          this.real_url = c_url[0]+"&fixed=1&"+this.m_min+'#1' ;
        }else{

           if(this.init_min !== 0){
             this.vchart1 =  1 ;
          }else{
            ++this.init_min;
          }
        }
    
      }        
    },
    data() {
        return {
            bar_url : '',
            bar_url1:'',
            real_url : '',
            vchart : 0,
            min : 3,
            vchart1 : 0,
            m_min : 2,
            init_min : 1,
            tabs: [
              { 'name': this.$t('pressure_monitoring.tab_title2') },
              { 'name': this.$t('pressure_monitoring.tab_title1') },
              { 'name': this.$t('pressure_monitoring.tab_title3') }
            ],
            activeIndex: 0,
            activeCheckIndex: null,
            box: [
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'comm_sense': '5'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'comm_sense': '5'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'comm_sense': '5'
              }
            ],
            isShowDate: true,
            isShowDate1: true,
            contents_height: 600,
            mnfchart_height: 550,
            realtimechart_height: 550,
            isInit : true,
            isInit1 : true,

            selected_ts_flow: '30',
            flow_date: '2020-11-01',
            loaded_ts_flow: false,

            selected_ts_mnf: '1',
            mnf_date: '2020-11-01',
            mnp_boundary: null,
            columns: [
                {label: 'Date', field: 'date_str'},
                {label: 'Supply(㎥/day)', field: 'flow'},
                {label: 'MNF', field: 'mnf'},
                {label: '%', field: 'rate'},
            ],
            table_mnf: [], //테이블 데이터 존재
            items: [
                   //   { id: 1, name: 'Item 1', sub: [{ title: 'SubItem Item 1-1'}, { title: 'SubItem Item 1-2'}]},
                   //   { id: 2, name: 'Item 2', sub: [{ title: 'SubItem Item 2-1'}, { title: 'SubItem Item 2-2'}]},
             ],
            ts_mnf: [],
            tag :'',  
            tag_name:'',
        }
    },
    methods: {
      change_min(){
          if(this.vchart === 0){
             this.vchart =1 ;
             this.chang_bar_url();  
          }
        },
        chang_bar_url(){
             this.$refs.barchart.fixed = 1 ; // 1 :fixed
             this.$refs.barchart.min = this.min ;
             var c_url = this.bar_url1.split('#') ;
             this.bar_url = c_url[0]+"&fixed="+this.vchart+"&"+ this.min+'#1' ;
        },
      chart_exceldownload(type) {
          // this.$http.get(this.URL+'api/'+idx).then((response) => {
          // 1:day chart, 2:minutes chart 
          var surl = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow
          //var name = 'point_'+this.tag+'_'+ this.flow_date+'_'+this.selected_ts_flow+'_min';
          var name = this.tag_name+'_'+ this.flow_date+'_'+this.selected_ts_flow+'_min';
          
          if(type === 1 ){
              surl = this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf
              //name = 'point_'+this.tag+'_'+ this.mnf_date+'_'+this.selected_ts_mnf+'_day';
              name = this.tag_name+'_'+ this.mnf_date+'_'+this.selected_ts_mnf+'_day';
          }
          this.$http({
            // url: this.URL+'api/download/psr/'+idx,
            ///api/API21/FILE/575c5303-b633-40c2-858b-b9e54ec865be?outType=psrToJson
            url:  surl,
            method: 'GET',
            //responseType: 'blob',
            //data: JSON.stringify(data),
            responseType: 'json',
            contentType: 'application/json; charset=utf-8',
          }).then((response) => {
            // let data = JSON.parse(decodeURIComponent(response.data))
          //  let json_data = JSON.stringify(response.data) ;
              let data = this.jsonToCSV(response.data)
            var fileURL = window.URL.createObjectURL(new Blob([data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', name+".csv");
            document.body.appendChild(fileLink);
            fileLink.click();
            setTimeout(()=>{
              ++this.psr_download_idx
            }, 200);
      
          })

        },
        jsonToCSV(json_data) { // 1-1. json 데이터 취득
          const json_array = json_data.results[0]; // 1-2. json데이터를 문자열(string)로 넣은 경우, JSON 배열 객체로 만들기 위해 아래 코드 사용 
          
          let csv_string = ''; // 3. 제목 추출: json_array의 첫번째 요소(객체)에서 제목(머릿글)으로 사용할 키값을 추출
          const titles = Object.keys(json_array[0]); // 4. CSV문자열에 제목 삽입: 각 제목은 컴마로 구분, 마지막 제목은 줄바꿈 추가 
            titles.forEach((title, index)=>{ csv_string += (index !== titles.length-1 ? `${title},` : `${title}\r\n`); }); 
        
            json_array.forEach((content, index)=>{ 
              let row = '';
              // 각 인덱스에 해당하는 '내용'을 담을 행
              for(let title in content){ // for in 문은 객체의 키값만 추출하여 순회함.
              // 행에 '내용' 할당: 각 내용 앞에 컴마를 삽입하여 구분, 첫번째 내용은 앞에 컴마X
                  row += (row === '' ? `${content[title]}` : `,${content[title]}`); } 
                  // CSV 문자열에 '내용' 행 삽입: 뒤에 줄바꿈(\r\n) 추가, 마지막 행은 줄바꿈X 
                  csv_string += (index !== json_array.length-1 ? `${row}\r\n`: `${row}`); }) 
              // 6. CSV 문자열 반환: 최종 결과물(string) 
          return csv_string;
        },        
        updateCheckIndex(payload) {
          this.activeCheckIndex = payload
          this.tag=this.box[payload].id
          var sdate = this.box[payload].date === null ? '2022-01-01':this.box[payload].date
          this.tag_name = this.box[payload].name
          this.flow_date = sdate.substring(0,10)
          this.mnf_date = sdate.substring(0,10)
          this.mnp_boundary = this.box[payload].mnp_boundary
          if(this.activeIndex === 1 || this.activeIndex === 2  ){
             this.get_time_series_mnf() 
           //  this.bar_url = this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30#1';
          }else if(this.activeIndex === 0  ){
            if(this.vchart1 === 1){
              this.vchart1 = 0;
              this.init_min = 0;
              this.m_min = 2 ;
            }else{
               this.get_time_series_flow()
            }
          }else{
            if(this.vchart1 === 1){
              this.vchart1 = 0;
            }else{
               this.get_time_series_flow()
            }
          }          
        },
        change_date_mnf(a){
          this.isShowDate1 = true;
          let default_date = new Date(this.mnf_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.mnf_date = this.date_to_str(default_date)      
          this.get_time_series_mnf()
        },
        change_date_flow(a){
          this.isShowDate1 = true;
          let default_date = new Date(this.flow_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
         
          this.get_time_series_flow()
        },
        date_to_str(format){
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 
     
          return year + "-" + month + "-" + date ;
      },

      on_goto_map_click(row) {
        var last_str = row.date.substr(0,10)       
        this.$router.push({path: '/pressure-monitoring-map?w_type=111', query: { id: row.id, mea_date:last_str  }})
        /*
        this.$refs.map.get_extent('w_type=111&p_idx='+row.id).then((data)=>{
          this.$refs.map.set_fit_extent(data)     
          if(this.$store.setting.map_name==='Daum')
            this.$refs.map.map.getView().setZoom(13);
          else
            this.$refs.map.map.getView().setZoom(18);   
        })
        */
        //  var last_str = row.flow.substr(0,10)
        //  this.$router.push({path: '/pressure-monitoring-map?w_type=111', query: { tag: this.tag, mea_date:last_str  }})
        
      },
       go_search(value,sort,status_type) {

            if(value===undefined) return;

          //left메뉴 데이터를 뿌려준다.
          this.box = [] ;
          var customer_id = sessionStorage.select_customer_id ; 
          if(sessionStorage.select_customer_id === "27"){
            customer_id = 17 //파주
          }       
          this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id+'&search='+value+"&sort="+sort+"&type="+status_type).then((res) => {
            if(res.data.results[0].length <1) return
            for(let i=0; i<res.data.results[0].length; i++) {                
                var abox = {};
                // abox = {'name': res.data.results[0][i].jijum_name, 'mnf': Math.round(Number(res.data.results[0][i].jijum_id)) , 'flow':  Math.round(Number(res.data.results[0][i].tag_value)) ,  'status': 'blue' } ; 
             //   var comm_sense = res.data.results[0][i].modem_signal_sensitivity === null || res.data.results[0][i].modem_signal_sensitivity > 5 || res.data.results[0][i].modem_signal_sensitivity < 0  ? 0 :  res.data.results[0][i].modem_signal_sensitivity
                 var check =  res.data.results[0][i]._5days
                 if(check !== null && check !== undefined)
                  check =  check.split(",") ;
                else
                  check = null   

                 abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id , 'device_sn':res.data.results[0][i].device_sn ,
               //    'battery':res.data.results[0][i].battery_level,'comm_sense':comm_sense, 'point_status' :res.data.results[0][i].point_status,              
                   "day_0" : check !== null && check.length>0 ?  "circle color_0"+check[0] : "circle color_03",
                   "day_1" : check !== null && check.length>1 ?  "circle color_0"+check[1] : "circle color_03",
                   "day_2" : check !== null && check.length>2 ?  "circle color_0"+check[2] : "circle color_03",
                   "day_3" : check !== null && check.length>3 ?  "circle color_0"+check[3] : "circle color_03",
                   "day_4" : check !== null && check.length>4 ?  "circle color_0"+check[4] :"circle color_03",
                   'flow':  res.data.results[0][i].last_measure_datetime+"/ p_data :  "+res.data.results[0][i].water_pressure ,  'date':  res.data.results[0][i].last_measure_datetime } ; 
                  this.box.push(abox) ; 

              }
              this.updateCheckIndex(0)
          })

     
        },
        showDate() {
          this.isShowDate = !this.isShowDate
        },
          showDate1() {
          this.isShowDate1 = !this.isShowDate1
        },
        tabItem(index){
          this.activeIndex = index
          if(index === 1 && this.isInit) { 
            this.get_time_series_mnf()
            this.isInit = false
          }else if(index === 2 && this.isInit1) {
            this.isInit1 = false  
          }
        },
        change_flow_date(e) {
            this.flow_date = e
            this.get_time_series_flow()
        },
        change_selected_ts_flow(e) {
            this.selected_ts_flow = e
            this.get_time_series_flow()
        },
        get_time_series_flow() {
            this.loaded_ts_flow = false    
            //this.$refs.realchart.fixed = 0 ;
            this.real_url = this.URL+'api/API21/m2_press/minute/'+encodeURI(this.tag)+'?to='+this.flow_date+'&limit='+this.selected_ts_flow+"#1";
            this.loaded_ts_flow = true
        },
        change_mnf_date(e) {
            this.mnf_date = e
            this.get_time_series_mnf()
        },
        change_selected_ts_mnf(e) {
            this.selected_ts_mnf = e
            this.get_time_series_mnf()
        },
        get_time_series_mnf() {
          this.bar_url1 = this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+"#1";
          this.get_time_table_mnf();
        },
        get_time_table_mnf(){
            var chart_avg = 0 ;
            var real_chart_min = 0;
            this.table_mnf = [];
            this.ts_mnf = [];
            this.items =[] ;
            this.$http.get(this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+'#1').then((res) => {
                if(res.data.results[0].length < 1){     
                  this.bar_url =  this.URL+'api/API21/m2_press/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+"#1";            
                  return;
                } 
                this.ts_mnf = res.data.results[0]
                var parseTime = d3.timeParse("%Y-%m-%d");
              

                //신규 가로날짜에 데이터 앉히기
                var old_ym = '';
                var s_day = 1 ;
                var tb_v = [] ;
              
                for(let i=0; i<this.ts_mnf.length; i++) {
                    chart_avg += this.ts_mnf[i]['maximum_night_pressure'] ; 
                    real_chart_min += this.ts_mnf[i]['min_water_pressure'] 
                    this.ts_mnf[i]['date_str'] = this.ts_mnf[i]['measure_datetime']
                    this.ts_mnf[i]['date'] = parseTime(this.ts_mnf[i]['measure_datetime'])
                    //this.ts_mnf[i]['rate'] = this.ts_mnf[i]['mnf'] / (this.ts_mnf[i]['flow']/24) * 100
                   // if(i === 0 || this.ts_mnf[i]['date_str'].substring(0,7) !== this.ts_mnf[i]['date_str'].substring(0,7) ){
                    if(i=== 0 ){
                      old_ym =  this.ts_mnf[i]['date_str'].substring(0,7)  //2020-09   
                      s_day = this.ts_mnf[i]['date_str'].substring(8,10)   //01     
                       for(var m=1 ; m < s_day ; ++m  ){ //월이 바뀔시 시작시 앞에 날수에 데이터가 없을 시 데이터 앉히기
                         tb_v.push({date_str: '', max : '',avg : '',min : '',mnp : '' })
                       }             
                    }
                    if(old_ym !==  this.ts_mnf[i]['date_str'].substring(0,7) ){ //달이 바뀌었다면 그 이전달의 데이터 없는 날의 이후 날수에 맞게 데이터를 앉힌다.
                      var abox = {}; //초기화하고
                      var bk = 31 - tb_v.length ;
                      for(var j = 0 ; j < bk ;++j){
                          tb_v.push({date_str: '', max : '',avg : '',min : '',mnp : '' })
                        }
                      abox = {'ym': old_ym, "tb_v" : tb_v } ; //뿌려질 월별 요일 데이터를 앉힌다.
                      this.items.push(abox) ; 
                       tb_v = []        
                       old_ym =  this.ts_mnf[i]['date_str'].substring(0,7)  
                       s_day = this.ts_mnf[i]['date_str'].substring(8,10)        
                       for(var mi=1 ; mi < s_day ; ++mi  ){ //월이 바뀔시 시작시 앞에 날수에 데이터가 없을 시 데이터 앉히기
                           tb_v.push({date_str: '',max : '',avg : '',min : '',mnp : ''})
                       }
                    }
                    this.table_mnf.push({date_str: this.ts_mnf[i]['date_str'], max: this.ts_mnf[i]['max_water_pressure'],
                        avg: this.ts_mnf[i]['avg_water_pressure'], min: this.ts_mnf[i]['min_water_pressure'],
                        mnp: this.ts_mnf[i]['maximum_night_pressure']    })
                   // 만약 15일부터 데이터가 앉혀진다.
                  
    // 만약 15일부터 데이터가 앉혀진다.
                   s_day = this.ts_mnf[i]['date_str'].substring(8,10) 
                   if(Number(s_day) > tb_v.length+1){
                     for(var ia =tb_v.length+1 ; ia < Number(s_day) ; ++ ia ){
                        tb_v.push({date_str: '',max : '',avg : '',min : '',mnp : '' })
                     }
                        tb_v.push({date_str: this.ts_mnf[i]['date_str'], max: this.ts_mnf[i]['max_water_pressure'],
                        avg: this.ts_mnf[i]['avg_water_pressure'], min: this.ts_mnf[i]['min_water_pressure'],
                        mnp: this.ts_mnf[i]['maximum_night_pressure']      })
                   }else{
                       tb_v.push({date_str: this.ts_mnf[i]['date_str'], max: this.ts_mnf[i]['max_water_pressure'],
                        avg: this.ts_mnf[i]['avg_water_pressure'], min: this.ts_mnf[i]['min_water_pressure'],
                        mnp: this.ts_mnf[i]['maximum_night_pressure']      })
                   }

                 


                }//for문 끝
                var bk1 = 31 - tb_v.length ;//달이 안바뀐 상태에서 다시 데이터가 24일까지 밖에 없으면 나머지 31일까지 빈공간으로 데이터를 채워준다.
                for(var k = 0 ; k< bk1 ;++k){
                  tb_v.push({date_str: '',max : '',avg : '',min : '',mnp : '' })
                }
               // var abox1 = {};
                // abox1 = {'ym': old_ym, "tb_v" : tb_v } ; 
                this.items.push({'ym': old_ym, "tb_v" : tb_v }) ; 
             //   this.draw_mnf_chart(this.ts_mnf)
                chart_avg = chart_avg/ this.ts_mnf.length
                real_chart_min = real_chart_min /this.ts_mnf.length 
               
                this.min = Math.round((chart_avg -0.5)*10)/10
                if(this.min < 0 && this.min >= -0.6){
                    this.min = 0 ;
                }
                //20230120 추가
                if(this.mnp_boundary> -1 && this.mnp_boundary !== null ){
                  if(this.min > this.mnp_boundary -0.5)
                    chart_avg =  Math.round((this.mnp_boundary -0.5)*10)/10
                    this.min = Math.round((this.mnp_boundary -0.5)*10)/10
                
                }
              //  if(this.min === Math.round(chart_avg,0))
                  this.chang_bar_url();
                //this.min = Math.round(chart_avg,0);
                
                this.m_min =  Math.round(real_chart_min,0);
            });
        },
        window_resiz() {
          this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight 
          let left_height = this.contents_height - (this.$refs.button_group_mnf.clientHeight*2)        
          
          this.mnfchart_height = left_height  -50
          this.realtimechart_height = this.mnfchart_height
         // this.$refs.map.update_size();
        },
         
    },
    mounted () {
       window.addEventListener("resize", this.window_resiz);
       this.window_resiz()

       // let left_height = this.contents_height - (this.$refs.button_group_mnf.clientHeight*2)
      //  this.mnfchart_height = left_height 
      //  this.realtimechart_height = this.mnfchart_height
      // this.bar_url = this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30#1';
        //left메뉴 데이터를 뿌려준다.
        this.tag= Number(this.$route.query.tag) ;
        this.box = [] ;
        var customer_id = sessionStorage.select_customer_id ; 
        if(sessionStorage.select_customer_id === "27"){
          customer_id = 17 //파주
        }
         this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id+"&type=A").then((res) => {   
       //this.$http.get(this.URL+'api/API21/m2_press/list?customer_id='+customer_id).then((res) => {
          if(res.data.results[0].length <1) return
          var sel_id = 0
          for(let i=0; i<res.data.results[0].length; i++) {
            if(this.tag === res.data.results[0][i].id ){
              sel_id = i
              this.tag_name =  res.data.results[0][i].point_name;
            }           
            var abox = {};
            var check =  res.data.results[0][i]._5days
            if(check !== null && check !== undefined)
               check =  check.split(",") ;
            else
               check = null   

           // var comm_sense = res.data.results[0][i].modem_signal_sensitivity === null ? "5" :  res.data.results[0][i].modem_signal_sensitivity ;            
           // abox = {'name': rows.data.rows[i].name, 'mnf': Math.round(Number(rows.data.rows[i].mnf)) , 'flow':  Math.round(Number(rows.data.rows[i].flow)) ,  'status': 'blue' } ; 
             var status = (res.data.results[0][i].content == null || res.data.results[0][i].content== undefined || res.data.results[0][i].content== 0) ? "" : "red"
            abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id , 'device_sn':res.data.results[0][i].device_sn ,
           //        'battery':res.data.results[0][i].battery_level,'comm_sense':comm_sense, 'point_status' :res.data.results[0][i].point_status,"mnp_boundary" : res.data.results[0][i].mnp_boundary,             
                  "day_0" : check !== null && check.length>0 ?  "circle color_0"+check[0] : "circle color_03",
                  "day_1" : check !== null && check.length>1 ?  "circle color_0"+check[1] : "circle color_03",
                  "day_2" : check !== null && check.length>2 ?  "circle color_0"+check[2] : "circle color_03",
                  "day_3" : check !== null && check.length>3 ?  "circle color_0"+check[3] : "circle color_03",
                  "day_4" : check !== null && check.length>4 ?  "circle color_0"+check[4] :"circle color_03",
                  'flow':  res.data.results[0][i].last_measure_datetime+"/ p_data :  "+res.data.results[0][i].water_pressure ,  'date':  res.data.results[0][i].last_measure_datetime,'status': status } ; 
                  this.box.push(abox) ; 
          }
          this.updateCheckIndex(sel_id)
         
       })
       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();         
           }
         }
       }, 200)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.window_resiz)
    },
    components: {
        // PulseLoader: PulseLoader,
        SearchLeftType3,
        PressMonitoringDayChart:PressMonitoringDayChart,
      //  PressMinChart:PressMinChart,
        pressChartData1:pressChartData1,
    }
}
</script>

<style  lang="scss">
.presschart_m2_c1 .wavchart  {
  /*min-height: 650px;*/
  height : calc(70vh - 50px);
}
.presschart_m2_c1 .highcharts-container {
  width: 100%;
  margin: 0 auto;
}
.presschart_m2_c1 .highcharts-container .highcharts-root {
  width: 100%;
  margin: 0 auto;
}
.map-button.icon_btn.btn {
    padding-top: 0;
}
.d3-tip td {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}
.btn_list_wrap .btn_list .lookup_btn.search{
    margin-right: 96px;
}
.map-button.icon_btn.btn.btn-sm1{
  padding: 0;
}
.scroll_box::-webkit-scrollbar {
    width:10px;
    height: 10px; 
    //background: gray;
}
/*
.scroll_box::-webkit-scrollbar-button{
  position: absolute;
  width: 10px;
  height: 10px;
  content: '';
  border-bottom: 20px solid transparent;
  border-right: 42px solid #7081f391;
  border-left: 20px solid transparent;
  border-top: 20px solid transparent;
}
*/
.scroll_box::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #7082f324 ;
}
/*
::-webkit-scrollbar-button {
  position: absolute;
  content: '';
  border-right: 20px solid transparent;
  border-bottom: 62px solid #7082f3;
  border-left: 20px solid transparent;
  border-top: 20px solid transparent;
}
*/
/*
Horiontal Scrollbar button (BOTTOM-RIGHT aKa END)
*/
</style>


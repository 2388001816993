<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb with--map">
<!--                        <li class="breadcrumb-title">Map Frequency Comparison</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</a></li>
                        <li class="breadcrumb-item"><a @click="$router.push('/water-pressure')" href="#">{{$t('pressure.title')}}</a></li>
                      
                        <li class="breadcrumb-item active">{{$t('pressure.map_page')}}</li>
                    </ol>
                </li>
            </ul>
        </div>
         <!-- tom-bar-end -->

         <div >
             <b-row style="margin: 0;">
                 <b-col :cols="msize" style="padding: 0 10px 0 0;">
                    <div class="search_div press_m1_map">

                        <span class="spn1"> <b-select size="sm" v-model="pressure_value_type" :options="pressure_value_opt" style="width:60px;" disabled="true" v-if="false"></b-select></span>                       
                        
                        <span class="status m1-map" style="margin-top:0px;float:left;padding:0px;">
                            <input type="radio" id="check_01" name="check_01"  v-model="value_type" value="hydraulic" />
                            <label for="check_01" >Elevation</label>
                            <input type="radio" id="radio_01" name="radio01"  v-model="value_type" value="kgf">
                            <label for="radio_01">kgf</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="value_type" value="bar">
                            <label for="radio_02">bar</label>
                            <input type="radio" id="radio_03" name="radio01"   v-model="value_type" value="psi" >
                            <label for="radio_03">psi</label>
                        </span>
                        <span style="float:right;width:140px;margin-right: 100px;padding-right:0;">
                            <b-input size="sm" type="text" placeholder="datetime" v-model="flow_date"  disabled />
                             <span style="position:absolute;top:0px;right:-220px;">
                             <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-30*48)"><i class="mdi mdi-chevron-double-left mdi-24px"></i></button>                                 
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(-30)"><i class="mdi mdi-chevron-left mdi-24px"></i></button>
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(30)"><i class="mdi mdi-chevron-right mdi-24px"></i></button>                             
                            <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm" @click="change_date_flow(30*48)"><i class="mdi mdi-chevron-double-right mdi-24px"></i></button>
                                <span  style="float:right;width:150px;"> 
                                <span v-if="levelA">    
                                <b-select size="sm" style="float:left;width:110px;" v-model="group_value" :options="group_value_opt" ></b-select>
                                <b-button size="sm" style="float:right;" @click="show_group">
                                    <i class="fas fa-cog"></i>
                                </b-button>
                                <span class="group_layer" v-if='visible_group_max'>
                                    <span   >                                                                     
                                        <b-row>
                                            <b-col sm="6">
                                                <label class="modal__input-label"> Max : </label>
                                            </b-col>
                                            <b-col sm="6">
                                                <label class="modal__input-label"> {{group_max}} </label>
                                            </b-col>                               
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6">
                                                <label class="modal__input-label"> Min : </label>
                                            </b-col>
                                            <b-col sm="6">
                                              <label class="modal__input-label"> {{group_min}} </label>
                                            </b-col>                               
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6">
                                                <label class="modal__input-label"> Max-Min : </label>
                                            </b-col>
                                            <b-col sm="6">
                                                <label class="modal__input-label"> {{group_char}}  </label>
                                            </b-col>                               
                                        </b-row>
                                    </span>
                                </span>
                                </span>
                                <span></span>
                                </span>  
                            </span>
                           
                       </span>
                       
                        
                    </div>
                    
          
            <div style="overflow-y: scroll;" :style="{height: contents_height+'px'}" >

                     <Map
                         ref="map"
                        :visible_gisload_button="true"
                        :visible_size_side_view_button="true"
                        :widx="$route.query.widx"  
                        :press_icon1 = "true"                    
                        w_type="110"
                        @toggle_visible_Hydraulic_type = "toggle_visible_Hydraulic_type"
                        @set_map_gisload="set_map_gisload"
                        @facility_invisible_layer = "facility_invisible_layer"
                        @set_info="set_info"
                        @go_home="go_home()"
                        @size_view="size_view"
                        @onLeakMapClick="onLeakMapClick()"
                     />
                  
            </div>   

                
            </b-col>
             <b-col  v-if="v_chart" :cols="tsize" style=" padding: 0px 2px 0 0; ">
                   <div class="corr"  >
                      <label for="radio1_01" style="font-size:12pt;">Correlation cofficient</label>
                      <label for="radio1_01">1-2</label> <input type="text" id="corr_text1" name="radio1_01"  v-model="corr_text1" disabled>
                      <label for="radio1_01">1-3</label> <input type="text" id="corr_text2" name="radio1_01"  v-model="corr_text2" disabled>
                      <label for="radio1_01">2-3</label> <input type="text" id="corr_text3" name="radio1_01"  v-model="corr_text3" disabled>
                      <span class="status leak">
                       <b-select size="sm" v-model="pressure_value_type1" :options="pressure_value_opt1" style="width:130px;margin-right:10px;"></b-select></span>
                   </div>
                   <b-row class="map-chart-row press">
                       <b-col style="padding-top: 5px; padding-bottom: 5px;">
                          <div class="specification_area" style="  padding: 5px 20px 10px 20px ;height:270px;">
                         
                         <div class="status press" >
                            <span v-if="levelA" style="float:left;margin-right: 0px;width:73%;" >    
                             <b-row> 
                              <b-col  sm="3"><button  @click="on_goto_map_click(lidx1)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_table_map.svg" alt="map" style="width:20px;"  /></button> 
                               <button variant="outline-danger" @click="show_press_info(lidx1)" style="border:0;  ;background:#172A46;"><img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg" /></button>
                               <span style="margin-left:0px;"> {{tag_name1}} </span></b-col>   
                               <b-col  sm="4" style="padding:0;">
                              <div  >
                                <input type="radio" id="radio_0001" name="radio_0002"  v-model="vchart_min" value="min">
                                <label for="radio_0001">Raw</label>
                                <input type="radio" id="radio_0002" name="radio_0002"  checked v-model="vchart_min" value="30min">
                                <label for="radio_0002">30min</label>                            
                              </div>
                           
                             </b-col>                                
                             <b-col sm="5"> <b-select  size="sm" v-model="group_id1" :options="group_name" style="margin-bottom:5px; width: 120px;" ></b-select>
                             <b-button class="mr-2" style="margin:-5px 0 0 10px;" size="sm" @click="update_group(lidx1,'1')">
                                <i class="fas fa-sync-alt"></i>
                              </b-button></b-col>
                             </b-row>
                            </span>
                            <span style="float:right;width:30%;margin-right:-40px;">
                            <input type="radio" id="radio1_01" name="radio1_01"  v-model="vchart" value="kgf">
                            <label for="radio1_01">kgf</label>
                            <input type="radio" id="radio1_02" name="radio1_01"  checked v-model="vchart" value="bar">
                            <label for="radio1_02">bar</label>
                            <input type="radio" id="radio1_03" name="radio1_01"   v-model="vchart" value="psi" >
                            <label for="radio1_03">psi</label>
                            </span>
                          </div>
              
                                <!--PressMinChart :url="real_url1" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:5px;"   /-->  
                                <pressChartData1 :url="real_url1" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height-30" style="width:100%;top:0px;" @set_param = "set_param1"  />  
                          </div>
                
                       </b-col>
                   </b-row>

                   <b-row class="map-chart-row press" >
                       <b-col style="padding-top: 10px; padding-bottom: 5px;">
                          <span v-if="levelA" style="float:right;margin-right: 250px;width:170px;" >    
                             <b-row>                           
                             <b-col sm="10"> <b-select  size="sm" v-model="group_id2" :options="group_name" style="margin-bottom:5px; width: 120px;" ></b-select></b-col>
                              <b-col sm="2"><b-button class="mr-2" style="float:right;" size="sm" @click="update_group(lidx2,'2')">
                                <i class="fas fa-sync-alt"></i>
                              </b-button></b-col>
                             </b-row>
                          </span>
                          <div class="specification_area" style="  padding: 0px 20px 10px 10px ;;height:100%;">
                              <div> <button  @click="on_goto_map_click(lidx2)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_table_map.svg" alt="map" style="width:20px;"  /></button>  <button variant="outline-danger" @click="show_press_info(lidx2)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg" /></button>
                              <span style="margin-left:0px;">    {{tag_name2}} </span> </div>
                               <pressChartData1 :url="real_url2" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height" style="position:relative;width:100%;height:90%;top:0px;"  @set_param = "set_param2"  />  
                                   
                           </div>
                             
                       </b-col>
                   </b-row>
                   <b-row class="map-chart-row press">
                       
                       <b-col style="padding-top: 0px; padding-bottom: 5px;">
                          <!--<div class="status press" >
                            <input type="radio" id="radio_01" name="radio01"  v-model="vchart" value="kgf">
                            <label for="radio_01">kgf</label>
                            <input type="radio" id="radio_02" name="radio01"  checked v-model="vchart" value="bar">
                            <label for="radio_02">bar</label>
                            <input type="radio" id="radio_03" name="radio01"   v-model="vchart" value="psi" >
                            <label for="radio_03">psi</label>
                          </div> -->
                           <span v-if="levelA" style="float:right;margin-right: 250px;margin-top:10px;width:170px;" >    
                             <b-row>                           
                             <b-col sm="10"> <b-select  size="sm" v-model="group_id3" :options="group_name" style="margin-bottom:5px; width: 120px;" ></b-select></b-col>
                              <b-col sm="2"><b-button class="mr-2" style="float:right;" size="sm" @click="update_group(lidx3,'3')">
                                <i class="fas fa-sync-alt"></i>
                              </b-button></b-col>
                             </b-row>
                          </span>
                          <div class="specification_area" style="  padding: 10px 20px 10px 0px ;height:100%;"  >
                               <div><button  @click="on_goto_map_click(lidx3)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_table_map.svg" alt="map" style="width:20px;"  /></button> 
                               <button  @click="show_press_info(lidx3)" style="border:0;background:#172A46;"><img src="@/assets/images/new/ic_list_info.svg" alt="map" class="infoimg"   /></button> 
                               <span style="margin-left:0px;"> {{tag_name3}} </span></div>
                                
                                <pressChartData1 :url="real_url3" ref="realchart" id="realchart" class="real_chart" :height="mnfchart_height" style="position:relative;width:100%;height:90%;top:0px;"  @set_param = "set_param3" /> 
                           </div>
                         
                       </b-col>
                   </b-row>
                 </b-col>

           </b-row>
           
         </div>
     <DlgGroupList ref="dlg_group_list"   />
     <DlgPressureInfo ref="pressure_info"  @leaktable_refresh="leaktable_refresh" />
    </div>
</template>

<script>
import DlgPressureInfo from '@/components/popup/DlgPressureInfo'
import Map from '@/components/Map';
import {CreateVectorLayer_f} from '@/gis.js';
import pressChartData1 from '@/components/chart/pressChartData1' ;
import DlgGroupList from '@/components/popup/DlgGroupList';
//import PressMinChart from '@/components/PressMinChart' ;
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import * as d3 from 'd3'
import d3tip from "d3-tip";
 d3.tip = d3tip;

export default {
    name: 'PressureDetailMap',
    props: [
        'w_type'
    ],
    data() {
        return {
            selected_ts_flow: '1',        
            value:'',
            group_name : [], 
            loaded_ts_flow : true,
            v_chart : true,
            vchart : "bar",
            vchart_min : "min",
            contents_height: 600,
            waveform_height: 42,
            mnfchart_height: 180,
            wavesurfer: null,
            layer_all_visible: true,
            chart_i : 0 ,
            real_url1: '',
            real_url2: '',
            real_url3: '',
            lidx1 :'',
            lidx2 :'',
            lidx3 :'',
            
            levelA : true,
            group_id1 : '',
            group_id2 : '',
            group_id3 : '',            
            psr_path1 : '',
            psr_path2 :'',
            psr_path3 : '' ,
            tag_name1 : '',
            tag_name2 :'',
            tag_name3 : '' ,    
            msize:'7',
            tsize:'5',        
            options: {
            },
            file: ' ' ,
 
            flow_date : '0001-01-01 00:00',
            elevation_yn : null,
            value_type : 'bar',
            pressure_value_type : null,
            pressure_value_opt: ['avg' , 'avg_c' ],   
            group_value : '',
            group_value_opt : [],
            measuredate: null,  
            start_idx:0,
            max_idx:2, //(0,1,2 까지 볼수 있다.)
           params1 : {} , //key => 시간날짜 1번차트
           params2 : {} , 
           params3 : {} , 
           corr_text1:'',
           corr_text2:'',
           corr_text3:'',
           properties_bak : { '1' : '', '2' : '', '3' : ''},
           group_max : '',
           group_min : '',
           group_char : '',
           visible_group_max : false,
           pressure_value_type1 : 'Measured Time',
           pressure_value_opt1: ['Measured Time','Setting Time',], 
           chart_set1:'',      
           chart_set2:'',
           chart_set3:'',

           t_time_interval : '',
        }
    },
    methods: {
       chang_press_mapvalue_type1() {          
          // 화면에 시간을 searching할 수 있도록 화면을 셋팅한다.
         if(this.pressure_value_type1 == "Measured Time"){
            this.chart_set1 = ''
            this.chart_set2 = ''
            this.chart_set3 = ''  
            this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart+this.chart_set1;
            this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart+this.chart_set2;
            this.real_url3 = this.URL+this.psr_path3+'&retType='+this.vchart+this.chart_set3; 

          }else{
            this.$http.get(this.URL+'api/API21/m2_press/logger_info/'+this.tag_name1).then((res) => {
                if(res.data.length<=0) return
                this.chart_set1 = '#'+res.data.results[0][0].data_cnt+"_"+res.data.results[0][0].time_interval
                this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart+this.chart_set1;
            });
            this.$http.get(this.URL+'api/API21/m2_press/logger_info/'+this.tag_name2).then((res) => {
                if(res.data.length<=0) return
                this.chart_set2 = '#'+res.data.results[0][0].data_cnt+"_"+res.data.results[0][0].time_interval
                this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart+this.chart_set2;
            });
            this.$http.get(this.URL+'api/API21/m2_press/logger_info/'+this.tag_name3).then((res) => {
                if(res.data.length<=0) return
                this.chart_set3 = '#'+res.data.results[0][0].data_cnt+"_"+res.data.results[0][0].time_interval
                this.real_url3 = this.URL+this.psr_path3+'&retType='+this.vchart+this.chart_set3;
            });
          }
          //누수 레이어를 다시 그린다.
         // this.$refs.map.init_reload_pressure_layer() ;

      },              
    
         show_group(){
            this.$refs.dlg_group_list.show();
         }, 
         size_view(e) {
            if(e==='right') {
                this.v_chart= false ;
                this.msize = '12';
                this.tsize='0';

            }
            else if(e==='left') {
                this.v_chart= true ;
                this.msize = '7';
                this.tsize='5';

            }
            this.$refs.map.update_size()
         },
         update_group(p_idx, a){
              //  if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === undefined){
              alert(this.$t('leak_detail.alt_no_update'))
              return
        //   }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 2 && sessionStorage.m_id !== this.properties.worker){
              alert(this.$t('water_leak.alert_no_permission'))
              return;
            }
            let params = {} ;
            if(p_idx === null || typeof p_idx === undefined) return
            if(a === "1"){
                if(this.properties_bak[a]=== this.group_id1) return
                params["group_id"] =  this.group_id1
            } else if(a === "2"){
                if(this.properties_bak[a]=== this.group_id2) return
                params["group_id"] =  this.group_id2
            }else{
                if(this.properties_bak[a]=== this.group_id3) return
                params["group_id"] =  this.group_id3
            } 
            if(Object.keys(params).length<=0) return
            if( params["group_id"] === ''){               
                params["group_id"] = null;
            }
            this.$http.get(this.URL+'api/update_press_info?idx='+p_idx+'&fields='+encodeURIComponent(JSON.stringify(params))).then((res) => {
                console.log(res.data);
                if( params["group_id"] === null)
                this.properties_bak[a] =   '' ;
            })   

         },
         set_param1(param){
           // alert("param1");
            //console.log(param)
            this.params1 = param;
            this.get_correlation();
         },
         set_param2(param){
          //  alert("param2");
            console.log(param)
            this.params2 = param;
            this.get_correlation();
         },
         set_param3(param){
         //   alert("param3");
            console.log(param)
            this.params3 = param;
            this.get_correlation();
         },
        cal_correlation(a){ //1이면 1=>2번관계 도출, 2:2=>3, 3: 1 =>3 계산
           
           var date = this.flow_date.substr(0,10)
           //sumx, sumy를 구한다.          
           var sumx = 0;
           var sumx1 = 0;
           var sumy=0;
           var sumy1 = 0 ;
           //제곱의 합을 구한다.
           var sumxsq = 0;
           var sumxsq1 = 0;
           var sumysq = 0;
           var sumysq1 = 0;
           //곱을 합친다.
           var psum = 0;
           var psum1 = 0 ;
           //총갯수
           var length = 0;
           var length1 = 0;
           if(a === 1){ //1=>2, 1=>3
                if(this.params1 === null ){
                    return;
                }
                let keys = Object.keys(this.params1) 
                if(this.params1 === null ){
                    return;
                }
                if( Object.keys(this.params3).length ===0 ){
                     this.corr_text2='';//1-3
                     this.corr_text3='';//2-3  
                }
                if( Object.keys(this.params2).length ===0 ){
                     this.corr_text1='';//1-3
                     this.corr_text2='';//2-3  
                }

                for(let i=0; i<keys.length; i++) {
                    if(keys[i].indexOf(date)=== -1 ) continue ////첫번째 데이터 날짜에 대한 값을 가져온다.
                    if(this.params2[keys[i]] != undefined){
                        ++length;
                        sumx += this.params1[keys[i]] 
                        sumxsq += Math.pow(this.params1[keys[i]],2) 
                        sumy += this.params2[keys[i]] 
                        sumysq += Math.pow(this.params2[keys[i]],2) 
                        psum += (this.params1[keys[i]] * this.params2[keys[i]] )
                      //  console.log("chart1=>2 :"+ keys[i] + " , " +this.params1[keys[i]] +","+this.params2[keys[i]] )
                    }
                    if(this.params3[keys[i]] !== undefined ){
                       ++length1;
                       sumx1 += this.params1[keys[i]] 
                       sumxsq1 += Math.pow(this.params1[keys[i]],2) 
                       sumy1 += this.params3[keys[i]] 
                       sumysq1 += Math.pow(this.params3[keys[i]],2) 
                       psum1 += (this.params1[keys[i]] * this.params3[keys[i]] )
                       //console.log("chart1=>3 :"+ keys[i] + " , " +this.params1[keys[i]]+","+ this.params3[keys[i]])
                    }
                    
                    
                }
                if(length === 0){ //값표출
                    this.corr_text1=''; //1-2
                    this.corr_text2=''; //1-3  
                    return;
                }
                var num = psum - (sumx*sumy/length)
                var den = Math.sqrt((sumxsq-Math.pow(sumx,2)/length)*(sumysq-Math.pow(sumy,2)/length))
                
                this.corr_text1 =  den===0 ? '' : (num/den).toFixed(5); //1-2 corr

                if(length1 === 0){ //값표출            
                    this.corr_text2=''; //1-3  
                    
                }else{
                    var num1 = psum1 - (sumx1*sumy1/length1)
                    var den1 = Math.sqrt((sumxsq1-Math.pow(sumx1,2)/length1)*(sumysq1-Math.pow(sumy1,2)/length1))
                    this.corr_text2 =  den1===0 ? '' : (num1/den1).toFixed(5) ; //1-3 corr
                }
                 
           }else{
                let keys = Object.keys(this.params2) 
                if(keys.length === 0){
                    this.corr_text3 ='';
                }
                for(let i=0; i<keys.length; i++) {
                    if(keys[i].indexOf(date)=== -1 || this.params3[keys[i]] === undefined ) continue ////첫번째 데이터 날짜에 대한 값을 가져온다.
                    ++length;
                    sumx += this.params2[keys[i]] 
                    sumy += this.params3[keys[i]]                     
                    sumxsq += Math.pow(this.params2[keys[i]],2) 
                    sumysq += Math.pow(this.params3[keys[i]],2)                     
                    psum += (this.params2[keys[i]] * this.params3[keys[i]] )
                   // console.log("chart2=>3 :"+ keys[i] + " , " +this.params2[keys[i]] +","+this.params3[keys[i]] )
                }
                if(length === 0){ //값표출
                     this.corr_text3 = '';
                     return;
                }
                var num2 = psum - (sumx*sumy/length)
                var den2 = Math.sqrt((sumxsq-Math.pow(sumx,2)/length)*(sumysq-Math.pow(sumy,2)/length))
                this.corr_text3 =  den2===0 ? 0 : (num2/den2).toFixed(5) ;
           }

        }, 
        get_correlation(){
            if(this.chart_i>1)
                this.cal_correlation(1) ; //1=>2, 1 =>3
            if(this.chart_i>2)
                this.cal_correlation(2) ; //2=>3
        },
        show_press_info(idx) {
          //this.$refs.map.show_press_info(idx)
          this.$refs.pressure_info.show(idx)
        },         
         toggle_visible_all_layer(a) {

                // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          /*
           this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
           if(this.$refs.map.layer_all_visible)
             this.$refs.map.leak_cal = 1;
           else
               this.$refs.map.leak_cal = 0;
            */
           if(a=== 1){
              // alert(a)
                this.$refs.map.leak_cal = 1;
           }else{
              //  alert(a)
                this.$refs.map.leak_cal = 0;
           }
           this.$refs.map.init_reload_leak_layer() ;

          /*
            this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')===100)
                    layer.setVisible(this.$refs.map.layer_all_visible)
            }
            */
        },
        chang_group_id_max() {
          // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          var p='&w_idx='+this.$route.query.widx;
          var press_type= this.$refs.map.leak_type ;
          //누수 레이어를 다시 그린다.
          if(this.group_value === '' ||  (this.group_value) === undefined ) {
              this.visible_group_max = false;
              this.group_max = '' ;
              this.group_min = '' ;
              this.group_char = '' ;   
              return
          }
          let surl =this.URL+'api/get_group_max?layer=press_info'+p+'&leak_cal='+this.pressure_value_type+'&leak_type='+press_type+'&press_value='+ this.value_type+'&press_date='+this.flow_date+"&group_id="+this.group_value;
          this.$http.get(surl).then((res) => {
            if(res.data.length<=0){ 
             //30분 데이터 search 부분 비활성화
              this.group_max = '' ;
              this.group_min = '' ;
              this.group_char = '' ;   
              return
            }
            //

            this.group_max = res.data.rows[0].group_max ;
            this.group_min = res.data.rows[0].group_min ;
            this.group_char = res.data.rows[0].group_char ;           
            this.visible_group_max = true;
          });
        },  

        chang_press_mapvalue_type() {
          // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
          // strength_cal값을 가져와 지도에 보여준다.
          
          this.$refs.map.leak_cal = this.pressure_value_type;    //avg,min/max
          this.$refs.map.leak_val =  this.value_type;     //bar,psi,kgf
          this.$refs.map.time_30m = this.flow_date; //시간
          //group_id
          this.$refs.map.init_fidx =  this.group_value;  
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;
          this.chang_group_id_max();

        },  
       toggle_visible_Hydraulic_type() {
          // 처음엔 동수도 적용안한다. Hydraulic_type 데이터를 가져와 지도에 보여준다. 동수도 적용안한다.
          // All or Hydraulic 적용값 가져와 지도에 보여준다.
        //  this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
        //  if(this.$refs.map.layer_leak_visible)
            this.$refs.map.leak_type = 'HYDRAULIC';
        //  else
        //    this.$refs.map.leak_type = null;            
          
          //누수 레이어를 다시 그린다.
          this.$refs.map.init_reload_pressure_layer() ;    
          this.chang_group_id_max();  
        },               
        addr_search(){
          this.$refs.map.go_search(this.value);
        },
        Enter_Check1(){
            this.addr_search();
        },
       change_date_flow(a){
          if( this.flow_date === '0001-01-01 00:00') {
              alert("There is no data every 30 minutes. ")
              return;
          }
          /*
          if(a<0){
              if(this.start_idx<1) return
              --this.start_idx
              
          }else{
              if(this.max_idx < this.start_idx) return;
              ++this.start_idx
          } 
          */
          let default_date = new Date(this.flow_date.toLocaleString());
           default_date.setMinutes(default_date.getMinutes()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
          this.chang_press_mapvalue_type();
          //this.get_time_series_flow()
        },
        date_to_str(format){
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 
          var hour = format.getHours();
           if(hour<10) hour = '0' + hour; 
          var min = format.getMinutes();
          if(min<10) min = '0' + min; 
     
          return year + "-" + month + "-" + date +" "+hour+":"+min;
      },
        change_selected_ts_flow(e) {
            this.selected_ts_flow = e
            this.get_time_series_flow()
        },
  
        Enter_Check(){
         //  if(event.keyCode == 13){
          this.go_search();  // 실행할 이벤트
        },
        on_leakage_info(idx) {
          this.$refs.map.show_leakage_info_pop(idx)
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        on_goto_map_click(idx) {
            this.$refs.map.get_extent('w_type=110&p_idx='+idx).then((data)=>{
                this.$refs.map.set_fit_extent(data)
                if(this.$store.setting.map_name==='Daum')
                    this.$refs.map.map.getView().setZoom(13);
                else
                    this.$refs.map.map.getView().setZoom(18);   
            })
             this.$refs.map.select_facility_feature('110','',idx)  
        },
        set_map_gisload() {
            let map = this.$refs.map.map;
            var size1 = map.getSize();
            var size = [size1[0]*1, size1[1]*1] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                     if(layer=== null || layer.values_ === null) continue
                        var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

                    var params = this.$refs.map.layer_setting_stlye_list[i];
                    // var nstyle = params.style;
                    var _url=params.surl;
                    // if(params.code >50 || layer.get('code')=== undefined ) continue

                        var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

                    var newlayer = CreateVectorLayer_f(params,extent) ;
                    this.$refs.map.layer_list.push(newlayer) ;
                    var vis = params.visible ;
                    newlayer.setVisible(vis);
                    this.$refs.map.layer_list.push(newlayer) ;
                    map.addLayer(newlayer);

                } //for문 끝

           } // zoom크기에 대한 if


        },
        set_info(){
            this.$refs.map.select_info = true;
        },

       onLeakMapClick(){
          let tag_id = this.$refs.map.leak_idx;  
           //세개의 로거의 데이터수_측정시간 
          let chart_setting_data = ''
          if(this.pressure_value_type1 !=='Measured Time'){
            this.$http.get(this.URL+'api/API21/m2_press/logger_info/'+tag_id).then((res) => {
                if(res.data.length<=0) return
                chart_setting_data = '#'+res.data.results[0][0].data_cnt+"_"+res.data.results[0][0].time_interval
                this.onLeakMapClick1(chart_setting_data)
            });
          }else{
              this.onLeakMapClick1(chart_setting_data) 
          }
        },
        onLeakMapClick1(chart_setting_data){
              let tag_id = this.$refs.map.leak_idx;
       
            this.chart_i = this.chart_i+1 ;
            if(this.chart_i%3 === 1){
                this.tag_name1 = tag_id ;    
                this.psr_path1 = this.$refs.map.leak_recov_idx ; 
                this.group_id1 = this.$refs.map.move_idx ;
                this.properties_bak["1"] = this.$refs.map.move_idx ;
                this.chart_set1 = chart_setting_data
                this.lidx1 = tag_id ;                 
                if(this.vchart_min === "min"){
                    this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart+this.chart_set1 ;
                    //this.real_url1 = this.URL+"api/get_psr_data?idx="+this.lidx1+'&retType='+this.vchart;            
                }else if(this.vchart_min === "30min"){
                    this.real_url1 = this.URL+'api/API21/m2_press/30minute/'+this.lidx1+'?retType=#'+this.vchart;
                }
            }else if(this.chart_i%3 === 2){
               this.tag_name2 = tag_id ;    
                this.lidx2 = tag_id ;  
               this.psr_path2 = this.$refs.map.leak_recov_idx ; 
               this.group_id2 = this.$refs.map.move_idx ;
               this.properties_bak["2"] = this.$refs.map.move_idx ;
               this.chart_set2 = chart_setting_data
             //  this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart+this.chart_set2 ;
               if(this.vchart_min === "min"){
                   this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart+this.chart_set2 ;
                   //this.real_url2 = this.URL+"api/get_psr_data?idx="+this.lidx2+'&retType='+this.vchart;           
                }else if(this.vchart_min === "30min"){
                   this.real_url2 = this.URL+'api/API21/m2_press/30minute/'+this.lidx2+'?retType=#'+this.vchart;
                }
              
            }else{
                this.tag_name3 = tag_id ;    
                 this.lidx3 = tag_id ;  
                this.psr_path3 = this.$refs.map.leak_recov_idx ; 
                this.group_id3 = this.$refs.map.move_idx ;
                this.properties_bak["3"] = this.$refs.map.move_idx ;
                 this.chart_set3 = chart_setting_data
                if(this.vchart_min === "min"){
                    this.real_url3 = this.URL+this.psr_path3+'&retType='+this.vchart+this.chart_set3
                  //  this.real_url3 = this.URL+"api/get_psr_data?idx="+this.lidx3+'&retType='+this.vchart;
                }else if(this.vchart_min === "30min"){
                    this.real_url3 = this.URL+'api/API21/m2_press/30minute/'+this.lidx3+'?retType=#'+this.vchart;
                }
             }

        },
      
  
    },
    mounted () {
        this.contents_height = window.innerHeight - 120;
        this.mnfchart_height =  this.mnfchart_height/3 - 60;
         setTimeout(()=>{
          let setting = this.$store.setting
          if(setting) {
               // this.pressure_value_type = this.$store.setting.pressure.legend.value
                this.pressure_value_type = 'avg'
          }
        }, 1000);
      
        this.$http.get(this.URL+'api/get_press_30_setting?w_idx='+this.$route.query.widx).then((res) => {
            if(res.data.length<=0){ 
             //30분 데이터 search 부분 비활성화
                return
            }
            //
            for(let i=0; i<res.data.rows.length; i++) {
               if(res.data.rows[i].measure_mode === 1){ 
                  this.flow_date = res.data.rows[i].start_datetime ;
                  //var last_date = res.data.list[i].end_datetime ;
                  this.max_idx = Number(res.data.rows[i].measure_time)/2;

                  break;
               }
            }
        
            
        });
        
        this.$http.get(this.URL+'api/waterpressure/groups?&customer_id='+sessionStorage.select_customer_id+"&search_term=").then((rows) => {
            if(rows.data.groups.length <= 0) return;           
            for(let i=0; i<rows.data.groups.length; i++) {
                
                if(i=== 0){
                    this.group_value_opt.push({value:'', text :'ALL'}) ;                 
                    this.group_name.push({value:'', text :''}) ;   
                }
                this.group_value_opt.push({value:rows.data.groups[i].groupId, text :rows.data.groups[i].groupName}) ; 
                this.group_name.push({value:rows.data.groups[i].groupId, text :rows.data.groups[i].groupName}) ;
            }
        })
    },
    watch: {
      elevation_yn: function() {
         this.toggle_visible_Hydraulic_type();
      },
      pressure_value_type1(){//min,max,avg,
         this.chang_press_mapvalue_type1() ;
      },
      pressure_value_type(){
        this.chang_press_mapvalue_type() ;
      },
      group_value(){
         this.chang_press_mapvalue_type() ;
      },
      value_type(){
          if(this.value_type === 'hydraulic'){
              this.toggle_visible_Hydraulic_type();
          }else {  
            this.$refs.map.leak_type = null;    
            this.chang_press_mapvalue_type() ;
          }
      },
      v_chart: function() {
          if(this.v_chart){ //사이즈 조절
          
             if(this.vchart_min === "min"){
                   this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart+"&roof="+(++this.roof);
                   this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart+"&roof="+(++this.roof);
                   this.real_url3 = this.URL+this.psr_path3+'&retType='+this.vchart+"&roof="+(++this.roof);
                  // this.real_url1 = this.URL+"api/get_psr_data?idx="+this.lidx1+'&retType='+this.vchart+"&roof="+(++this.roof);
                  // this.real_url2 = this.URL+"api/get_psr_data?idx="+this.lidx2+'&retType='+this.vchart+"&roof="+(++this.roof);
                  // this.real_url3 = this.URL+"api/get_psr_data?idx="+this.lidx3+'&retType='+this.vchart+"&roof="+(++this.roof);

                  }else{
                    this.real_url1 = this.URL+'api/API21/m2_press/30minute/'+this.lidx1+'?retType=#'+this.vchart;
                    this.real_url2 = this.URL+'api/API21/m2_press/30minute/'+this.lidx2+'?retType=#'+this.vchart;  
                    this.real_url3 = this.URL+'api/API21/m2_press/30minute/'+this.lidx3+'?retType=#'+this.vchart;
                  }
          }
      },
      vchart() { //단위값
            //setTimeout(()=>{
               if(this.vchart_min === "min"){
                   this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart+this.chart_set1;
                   this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart+this.chart_set2;
                   this.real_url3 = this.URL+this.psr_path3+'&retType='+this.vchart+this.chart_set3;
                //   this.real_url1 = this.URL+"api/get_psr_data?idx="+this.lidx1+'&retType='+this.vchart;
                //   this.real_url2 = this.URL+"api/get_psr_data?idx="+this.lidx2+'&retType='+this.vchart;
                //   this.real_url3 = this.URL+"api/get_psr_data?idx="+this.lidx3+'&retType='+this.vchart;

                }else{
                   this.real_url1 = this.URL+'api/API21/m2_press/30minute/'+this.lidx1+'?retType=#'+this.vchart;
                   this.real_url2 = this.URL+'api/API21/m2_press/30minute/'+this.lidx2+'?retType=#'+this.vchart; 
                   this.real_url3 = this.URL+'api/API21/m2_press/30minute/'+this.lidx3+'?retType=#'+this.vchart;     
                }
             //  }, 500)
          
      },
       vchart_min() {
        if(this.vchart_min === "min"){
                   this.real_url1 = this.URL+this.psr_path1+'&retType='+this.vchart+this.chart_set1;
                   this.real_url2 = this.URL+this.psr_path2+'&retType='+this.vchart+this.chart_set2;
                   this.real_url3 = this.URL+this.psr_path3+'&retType='+this.vchart+this.chart_set3;
                //   this.real_url1 = this.URL+"api/get_psr_data?idx="+this.lidx1+'&retType='+this.vchart;
                //   this.real_url2 = this.URL+"api/get_psr_data?idx="+this.lidx2+'&retType='+this.vchart;
                //   this.real_url3 = this.URL+"api/get_psr_data?idx="+this.lidx3+'&retType='+this.vchart;

                }else{
                   this.real_url1 = this.URL+'api/API21/m2_press/30minute/'+this.lidx1+'?retType=#'+this.vchart;
                   this.real_url2 = this.URL+'api/API21/m2_press/30minute/'+this.lidx2+'?retType=#'+this.vchart; 
                   this.real_url3 = this.URL+'api/API21/m2_press/30minute/'+this.lidx3+'?retType=#'+this.vchart;     
                }
          
      },  

    },
    components: {
      Map: Map,
      pressChartData1:pressChartData1,
      DlgGroupList:DlgGroupList,
      DlgPressureInfo : DlgPressureInfo,
       // PressMinChart:PressMinChart,
       // PulseLoader: PulseLoader,
    }
}
</script>

<style scoped>

.infoimg{
    width: 24px;
}
.press_m1_map{
    position: absolute;
    left:210px; 
    width: 550px;   
    top: 5px;
    
    z-index: 110;
     
}
.spn1 {
    float: left;
    padding: 2px;
}
.spn2 {
    float: right;
    padding: 2px;
}
.m1-map{
    position: relative;
    top : 0px;
    margin-left: 15px;
    background: #172a46;
    padding: 10px 7px 10px 7px;
    border:solid 0.1em #ccc;
    border-radius: 5px;
}
.real_chart .wavchart .highcharts-container .highcharts-root {
    width: 100%;
}
.corr{
    position: absolute;
    top: -50px;
    right:10px;
    left: -5px;
    padding:0 10px;
    margin-bottom: 5px;
   
}
.corr input {
 width: 80px;
}
.corr label {
    padding: 10px;
    margin-bottom: 0;
}
.group_layer {
  position: absolute;
  right: 0px;
  top:40px;  
  width: 180px;
  height: 120px;
  padding: 20px 20px;
  background: linear-gradient(rgba(130, 149, 164, 0.8), rgba(130, 149, 164,0.8)) ;
  border-radius: 10px;
}
.group_layer .row {
    margin-bottom: 0;  
}
.status.press{
    right:10px;
    top:12px;
}
</style>

<template>
<div >
       <div  ref="navbar" class="navbar-custom">
<!-- 2023 new -->
        <!-- <h4 class="content-title">{{$t('menu.dma')}}</h4> -->
      <ul class="list-unstyled topnav-menu-gm topnav-menu-left">
    
        <li>
          <ol class="breadcrumb with--map">
            <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">{{$t('menu.home')}}</router-link></li>
            <li class="breadcrumb-item ">{{$t('menu.gis_master')}}</li>
            <li class="breadcrumb-item active">{{$t('menu.dma')}}</li>
          </ol>
        </li>
      </ul>
    </div>
    <div>
      <b-row style="margin: 0;">
       <b-col cols="3" style="margin: 0 0 0 -10px;height: 100% ;">
       <search-left-type3 :box="box" :activeCheckIndex="activeCheckIndex" :visible_search_box="true" w_type = "102"
          @updateCheckIndex="updateCheckIndex" @on_facility_info="on_facility_info" @go_search="go_search_leftlist" @on_goto_map_click = "on_goto_map_click"/>
       </b-col>
        <b-col cols="9" style="padding: 0 0px 0 0;">
          <div style="width: 100%;height: 100% ;">        

        <!--div style="width: 75%; height: calc(100% - 130px); position:fixed; top: 130px;right:0px;"-->
            <div class="search_div">
                <span class="spn1"><b-input size="sm" type="text"  :placeholder="$t('home.input_search_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
           </div>
            <Map ref="map" :widx="$route.query.widx" :w_type="$route.query.w_type"
                 @facility_refresh="facility_refresh"
                 @gis_reload = "gis_reload"
                 @change_map_mode="change_map_mode"
            />
            <!-- 2022 new -->
            <!-- <div style="position: absolute; left: 12px; top: 12px; z-index: 10;"> -->
            <div style="position: absolute; left: 42px; top: 11px; z-index: 10;">
             
              <b-button-toolbar aria-label="Toolbar with button groups">
                  <!--b-button-group class="mr-2 icon-button-group">
                      <b-radio :button='true' size="sm" v-model="map_mode" value='select'  @click="click_mode_map" v-b-tooltip.hover title='select'>
                          <span class="fas fa-mouse-pointer"></span>
                      </b-radio>
                      <b-radio :button='true' size="sm" v-model="map_mode" value='info'  @click="click_mode_map" v-b-tooltip.hover title='info'>
                          <i class="fas fa-info"></i>
                      </b-radio>
                      <b-radio :button='true' size="sm" v-model="map_mode" value='edit'  @click="click_mode_map" v-b-tooltip.hover title='edit'>
                          <span class="fas fa-pencil-alt"></span>
                      </b-radio>
                  </b-button-group-->
                    <b-button class="mr-2 map-button icon_btn" size="sm"   @click="click_mode_map('select')" v-b-tooltip.hover :title="$t('home.icon_select_title')">
                        <span class="fas fa-mouse-pointer"></span>
                    </b-button>
                    <b-button class="mr-2 map-button icon_btn" size="sm"   @click="click_mode_map('info')" v-b-tooltip.hover :title="$t('home.icon_info_title')">
                        <i class="fas fa-info"></i>
                    </b-button>
                    <b-button class="mr-2 map-button icon_btn" size="sm"   @click="click_mode_map('edit')" v-b-tooltip.hover :title="$t('button.modify')">
                        <span class="fas fa-pencil-alt"></span>
                    </b-button>
                  <b-button class="map-button icon_btn" size="sm" @click="zoom('+')"  v-b-tooltip.hover title='zoom(+)'>
                      <span class="fas fa-search-plus"></span>
                  </b-button>
                  <b-button class="mr-2 map-button icon_btn" size="sm" @click="zoom('-')"  v-b-tooltip.hover title='zoom(-)'>
                      <span class="fas fa-search-minus"></span>
                  </b-button>

                  <b-button class="mr-2 map-button icon_btn" size="sm" @click="delete_feature()"  v-b-tooltip.hover :title="$t('button.delete')">
                      <span class="fas fa-window-close"></span>
                  </b-button>
                  <b-button class="mr-2 map-button icon_btn" size="sm" @click="zoom_fit()"  v-b-tooltip.hover title='zoom_fit'>
                      <span class="fas fa-expand"></span>
                  </b-button>
                 
                  <b-button class="mr-2  map-button icon_btn" size="sm"  @click="$refs.upload_shape.show();"  v-b-tooltip.hover title='upload_shape'>
                      <i class="fas fa-cloud-upload-alt"></i>
                  </b-button>
                                  
                  <b-button class="mr-2 map-button icon_btn" size="sm" @click="toggle_visible_all_layer()"  v-b-tooltip.hover :title="layer_all_visible ? $t('home.icon_vis_title') :$t('home.icon_invis_title') " >
                  <span v-show="layer_all_visible"><i class="fas fa-toggle-on"/> </span>
                <!--/b-button>
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="toggle_visible_all_layer()" v-show="!layer_all_visible" v-b-tooltip.hover :title="$t('home.icon_invis_title')"-->
                 <span v-show="!layer_all_visible"> <i class="fas fa-toggle-off"/></span>
                </b-button>
                  <b-button class="mr-2 map-button icon_btn" size="sm"    v-b-tooltip.hover :title='layer_leak_visible ? "Elevation" :"No Elevation" '>
                    <span v-show="layer_leak_visible" @click="view_map_elevation(1)"><i class="mdi mdi-elevation-rise"></i></span>
                <!--/b-button>
                <b-button class="mr-2 map-button icon_btn" size="sm" @click="view_map_elevation(2)" v-show="!layer_leak_visible" v-b-tooltip.hover title='No Elevation'-->
                    <span v-show="!layer_leak_visible" @click="view_map_elevation(2)"><i class="mdi mdi-dots-horizontal"></i></span>
                </b-button>
               
                <button  class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text" type="button" @click="set_map_gisload(0)" v-b-tooltip.hover title='GIS Load'>{{$t('button.gisload')}}</button>
               <button  class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text" type="button" @click="get_gisinfo" v-b-tooltip.hover title='GIS Info'>{{$t('map.gis_info')}}</button>
              </b-button-toolbar>
              <p id="c_zoom" class="map-zoom">zoom=</p>
         

                <div style="width: 300px;">
                    <b-card id="edit" v-if="$refs.map && visible_edit">
                        <template v-slot:header>
                            <b-button-close @click="edit_cancel()"/>
                            <h6 class="mb-0">{{$t('home.edit')}}</h6>
                        </template>
                        <b-row>
                            <b-col>
                                <b-radio v-model="edit_mode" value="add" >{{$t('button.add')}}</b-radio>
                            </b-col>
                            <b-col>
                                <b-radio v-model="edit_mode" value="modify" >{{$t('button.modify')}}</b-radio>
                            </b-col>
                        </b-row>
                        <div v-if="edit_mode==='add'">
                            <b-row v-for="(item, index) in get_edit_layers()" :key="index">
                                <b-col>
                                    <b-radio size="sm" v-model="add_layer_name" :value="item.get('name')"> {{item.get('name')}} </b-radio>
                                </b-col>
                            </b-row>
                        </div>
                        <template v-slot:footer>
                       <!--
                                <b-button @click="edit_ok()" class="mr-2">OK</b-button>
                            <b-button @click="edit_cancel()">Cancel</b-button>
                            -->
                        </template>

                    </b-card>
                </div>
                <!-- -->




            </div>
         
        </div>
                   <div class="dma_layer">
                      <div style="padding:20px 20px;" :key="listKey" >  
                            <b-row>
                            <b-col sm="8">
                            <label class="form-title">{{properties['name']}}</label> 
                           
                            </b-col>
                            <b-col sm="2">
                              <b-button v-if="!loading" class="mr-2"  size="sm" @click="get_dma_result">
                                <i class="fas fa-sync-alt"></i>
                              </b-button>
                               <span v-if="loading"><img src="@/assets/images/loading.gif" style="width:25px;"/></span>
                            </b-col>
                           <b-col sm="2">
                              <b-button class="mr-2"  size="sm" @click="download_dma_shpfile">
                                <i class='fas fa-file-download fa-lg'></i>
                              </b-button>                               
                            </b-col>                            
                            </b-row>                                        
                            <b-col class="tab-btm-line"></b-col>
                            <b-row>
                                <b-col sm="12">
                            
                             <label class="form-title">
                               DMA Properties </label>
                             </b-col>
                            </b-row> 
                            <b-row>
                                <b-col sm="6">
                                    <label class="modal__input-label"> Water Meter : </label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="properties['w_count']" type="text"                              ></b-input>
                                </b-col>                               
                            </b-row>

                            <b-row>
                                <b-col sm="6">
                                    <label class="modal__input-label">Valve : </label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="properties['v_count']" type="text"  disabled></b-input>
                                </b-col>
                            </b-row>

                             <b-row>   
                                <b-col sm="6">
                                    <label class="modal__input-label">Supply Pipe(m)</label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="properties['sp_length']" type="text"  disabled   ></b-input>
                                </b-col>
                               
                            </b-row>
                            <b-row>   
                                <b-col sm="6">
                                    <label class="modal__input-label">Service Pipe(m)</label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="properties['sv_length']" type="text"  disabled   ></b-input>
                                </b-col>
                               
                            </b-row>
                            <b-row>   
                                <b-col sm="6">
                                    <label class="modal__input-label">Area(㎢)</label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="properties['area']" type="text"  disabled   ></b-input>
                                </b-col>
                               
                            </b-row>
                            <b-row>   
                                <b-col sm="6">
                                    <label class="modal__input-label">Elevation(H)(m)</label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="properties['elevation_h']" type="text"  disabled   ></b-input>
                                </b-col>
                               
                            </b-row>
                            <b-row>   
                                <b-col sm="6">
                                    <label class="modal__input-label">Elevation(A)(m)</label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="properties['elevation_a']" type="text"  disabled   ></b-input>
                                </b-col>
                               
                            </b-row>
                            <b-row>   
                                <b-col sm="6">
                                    <label class="modal__input-label">Elevation(L)(m)</label>
                                </b-col>
                                <b-col sm="6">
                                    <b-input class="modal__input" size="sm" v-model="properties['elevation_l']" type="text"  disabled   ></b-input>
                                </b-col>
                               
                            </b-row>                  
    
                          </div>
                        </div>
        </b-col>
      </b-row>  
        <UploadShape ref="upload_shape"/>
    </div>
</div> 
</template>

<script>
import proj4 from 'proj4';
import Map from '@/components/Map';
import GeoJSON from 'ol/format/GeoJSON';
//20200708추가
import {CreateVectorLayer_ff,CreateVectorLayer_f} from '@/gis.js';
import SearchLeftType3 from '@/components/left/SearchLeftType_dma';
import shpwrite from 'shp-write-chinese';
import b64toBlob from 'b64-to-blob' ;

export default {
    name: 'Home',
    props: [
        'w_type'
    ],
    data() {
        return {
            properties: [],
            loading:false,
            map_mode: 'select',
            addr_value :'',
            visible_edit: false,
            edit_mode: 'add',
            add_layer_name: 'Service Pipe',
            layer_leak_visible: true, //고도값
            router_query: null,
            activeCheckIndex: null,
            interactions_modify: null,
            layer_all_visible: false,
            elevation_layer:null,
            box: [],
            setting: null,
            listKey: 0,
            tag : 0, 
        }
    },
    watch: { //Service Pipe 만 수로 수정 가능함
     
        add_layer_name() {
            this.$refs.map.edit_change(this.edit_mode, this.add_layer_name)
        },
        edit_mode() {
            this.$refs.map.edit_change(this.edit_mode, this.add_layer_name)
        },
        map_mode1() {
            if(this.map_mode==='edit') {
               if(this.layer_all_visible){
                this.visible_edit = true
                this.$refs.map.edit_start(this.edit_mode, this.add_layer_name)
               }else{
                   alert(this.$t('home.alert_text_no_gisload')) ;
                   this.map_mode='info'
                   this.$refs.map.select_info ='info'                   
               }
            }
            else {
                this.visible_edit = false
                this.$refs.map.select_info ='info'
                this.map_mode='info'
                this.$bvToast.hide('edit')
                this.$refs.map.edit_cancel();
            }
        }
    },
    methods: {
      download_dma_shpfile(){
        var zipname = (this.properties['name'] === undefined ? "test" : this.tag+"_"+this.properties['name']+".zip") ; 
        this.$http.get(this.URL+'api/get_shape?call_type=1&layer=4&customer_id='+ sessionStorage.select_customer_id +'&dma_id='+this.tag).then((res) => {

         //   console.log(res)
           
            var options = {
                folder: 'myshapes',
                //names: ['test','test1'] 
                
                types: {
                    point: 'mypoints',
                    polygon: 'mypolygons',
                    line: 'mylines'
                }
                
            }
           var json_date = res.data
           console.log(json_date)
         
           var content = shpwrite.zip(json_date, options);         
           
            const blob = b64toBlob(content, 'application/zip');            
            const blobUrl = URL.createObjectURL(blob);
            var fileLink = document.createElement('a');
             fileLink.href = blobUrl;
            // data:application/zip;base64,' + content;
             fileLink.setAttribute('download', zipname);
             document.body.appendChild(fileLink);
             fileLink.click();
           
            
        });
      },  
      get_dma_result(){
            this.loading = true     
            this.$http.get(this.URL+'api/API21/dma/insert/'+this.tag).then((res) => {
                 if(res.data.check ===1){ 
                    this.loading = false
                    return;
                 }
                 this.updateCheckIndex(this.activeCheckIndex,this.tag)
                 this.loading = false 
            });
            setTimeout(()=>{
                this.loading = false      
             }, 2000)    
      },
      go_search_leftlist(value,type,strength) {

        if(value===undefined) return;

            //left메뉴 데이터를 뿌려준다.
        this.box = [] ;
          var customer_id = sessionStorage.select_customer_id ; 
        if(sessionStorage.select_customer_id === "27"){
            customer_id = 6
        }
        // this.$http.get(this.URL+'api/get_monitoring_list_left?search='+value).then((rows) => {
          this.$http.get(this.URL+'api/API21/m2_leak/list?customer_id='+customer_id+'&search='+value+'&type='+type+'&sort='+strength).then((res) => {
            if(res.data.results[0].length <1) return
        
            
            for(let i=0; i<res.data.results[0].length; i++) {                
              var abox = {};
              // abox = {'name': res.data.results[0][i].jijum_name, 'mnf': Math.round(Number(res.data.results[0][i].jijum_id)) , 'flow':  Math.round(Number(res.data.results[0][i].tag_value)) ,  'status': 'blue' } ; 
              var strength = res.data.results[0][i].wave_energy === null ? "" :  res.data.results[0][i].wave_energy.toFixed(2)
              var last_date = res.data.results[0][i].last_measure_datetime === null ? "" :  res.data.results[0][i].last_measure_datetime
              var comm_sense = res.data.results[0][i].modem_signal_sensitivity === null ? "5" :  res.data.results[0][i].modem_signal_sensitivity
              abox = {'name': res.data.results[0][i].point_name, 'id': res.data.results[0][i].id ,  'device_sn':res.data.results[0][i].device_sn ,
                'battery':res.data.results[0][i].battery_level,'comm_sense':comm_sense, 
               'flow':  last_date+"/ strength :  "+strength ,  'date':  last_date } ; 
               this.box.push(abox) ; 
            }
            this.updateCheckIndex(0)
        })

  
    },
    updateKey: function () {
      this.listKey+= 1
    },

    updateCheckIndex(index,i_tag) {
      this.activeCheckIndex = index
      this.properties = [];
      this.tag=i_tag;
      //this.$refs.table_leakage_info.setUrl(this.URL+"api/get_m2_leak_list_ex?m2_point_id="+this.tag, (rows) => {
      this.$http.get(this.URL+'api/API21/dma/'+this.tag+'?customer_id='+sessionStorage.select_customer_id ).then((res) => {
           if(res.data.results[0].length > 0){
                this.properties['name']  = res.data.results[0][0].name; 
                this.properties['w_count']  = res.data.results[0][0].water_meter; 
                this.properties['v_count'] = res.data.results[0][0].valve;         
                this.properties['sp_length']  = res.data.results[0][0].supply_pipe_meter;     
                this.properties['sv_length']  = res.data.results[0][0].service_pipe_meter;    
                this.properties['area']  = res.data.results[0][0].area; 
                this.properties['elevation_h']  = res.data.results[0][0].elevation_high;   
                this.properties['elevation_a']  = res.data.results[0][0].elevation_avg;   
                this.properties['elevation_l']  = res.data.results[0][0].elevation_low;                                                     
                
           }
           this.updateKey(); 
           
       }) 
       this.on_goto_map_click(i_tag)
       this.set_map_gisload(0);
       setTimeout(()=>{
            this.on_goto_map_click(i_tag)
       }, 2000)       
      
    },   
      on_goto_map_click(i_tag) {
        this.$refs.map.get_extent('f_idx='+i_tag).then((data)=>{
          this.$refs.map.set_fit_extent(data, ()=>{
            this.$refs.map.select_facility_feature(7, '', i_tag)
          })
        })
      },
        click_mode_map(a) {
            var mmode = a
            if(mmode==='edit') {
               if(this.layer_all_visible){
                this.visible_edit = true
                this.$refs.map.edit_start(this.edit_mode, this.add_layer_name)
               }else{
                   alert(this.$t('home.alert_text_no_gisload')) ;
                  // this.map_mode='info'
                   this.$refs.map.select_info ='info'                   
               }
            }
            else {
                this.visible_edit = false
                this.$refs.map.select_info ='info'
                //this.map_mode='info'
                this.$bvToast.hide('edit')
                this.$refs.map.edit_cancel();
            }
        },
        addr_search(){
          this.$refs.map.go_search(this.addr_value);
        },
        Enter_Check1(){
          this.addr_search();
        },
        toggle_visible_all_layer() {
            this.layer_all_visible = !this.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength(); i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue
                layer.setVisible(this.layer_all_visible)
            }
        },
        get_gisinfo(){
                this.$refs.map.block_info = 1 ;
               // this.set_toggle_visible_all_layer();
                // this.$refs.map.init_draw();
                this.$refs.map.edit_change('add', 'Block')
                // this.$refs.map.edit_start('add', 'Block') ;
        },
        on_facility_info(id) {
            this.$refs.map.show_facility_info(id)
        },        
        change_map_mode(){
            //this.map_mode = 'select'
          //  this.visible_edit = false
          //  this.$refs.map.select_info ='info'
         
          //  this.$bvToast.hide('edit')
          //  this.$refs.map.edit_cancel();            
          
        },
       // 20200708 추가
       set_map_gisload(a) {
            if(a === 1)
                this.layer_leak_visible =false
            else if(a === 2)  
                this.layer_leak_visible =true
            else
                this.layer_leak_visible =true
            let map = this.$refs.map.map;
            map.layer_all_visible = true ;
            this.layer_all_visible = true;
            var size1 = map.getSize();
            var size = [size1[0]*2, size1[1]*2] ;
         //   var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.tag === undefined || Number(this.tag) < 1){
                alert(this.$t('home.alert_dma_id')) ;
            }else{
                // 추가 되었던 소스 가져와서 삭제하기
                // heat layer 삭제

                var h_layer = this.$refs.map.heat_layer ;
                if(h_layer != null) {
                    var source_h = h_layer.getSource();
                    var hfeatures = source_h.getFeatures();
                    for(var k=0; k< hfeatures.length ; k++) {
                        source_h.removeFeature(hfeatures[k]);
                    }
                    map.removeLayer(h_layer) ;
                    h_layer = null;
                }
                // remove_layer(map);
                for(let i=0; i<this.$refs.map.layer_list.length; i++) {
                    var layer = this.$refs.map.layer_list[i];
                    if(layer=== null || layer.values_ === null) continue
                    var source1 = layer.getSource();
                    var features = source1.getFeatures();
                    for(var j=0; j< features.length ; j++) {
                        source1.removeFeature(features[j]);
                    }
                    map.removeLayer(layer) ;
                }
                this.$refs.map.layer_list = [] ;
                if(a > 1) return;

                let layer_style = []
                if(a === 0){
                    layer_style = this.$refs.map.layer_setting_stlye_list
                }else{
                    layer_style = this.$refs.map.layer_setting_stlye_el_list
                }
                for(let i=0; i<layer_style.length; i++) {

                    var params = layer_style[i];
                    //수도미터 내림
                    //if(params.code === 2  ) continue;
                    // var nstyle = params.style;
                       var _url=params.surl;
                    
                        var lftaye = params.f_type;
                        //_url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye+'&dma_id='+this.block_id;
                        _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;
                    
                        var newlayer = CreateVectorLayer_ff(params,extent,this.tag ) ;
                        var vis = params.visible ;
                      //  if(params.code !== 2  ) {
                          newlayer.setVisible(vis);
                     //   }else{
                     //      newlayer.setVisible(false);                           
                     //   }
                        this.$refs.map.layer_list.push(newlayer) ;
                        map.addLayer(newlayer);
                 //   }

                } //for문 끝
                if(a === 0)          
                    this.$refs.map.init_draw_modify();
            } // zoom크기에 대한 if


        },
      // 20200708 추가
       view_map_elevation(a) {
            if(a === 1)
                this.layer_leak_visible =false
            else if(a === 2)  
                this.layer_leak_visible =true

            let map = this.$refs.map.map;
            map.layer_all_visible = true ;
            this.layer_all_visible = true;
            var size1 = map.getSize();
            var size = [size1[0]*2, size1[1]*2] ;
            var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
            let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
            var extent = map.getView().calculateExtent(size);

            if(this.$refs.map.map.getView().getZoom() < cz){
                alert(this.$t('home.alert_zoom_in')) ;
            }else{
                if(this.elevation_layer !== null){
                    map.removeLayer(this.elevation_layer) ;
                }
                let layer_style = null     
                if(a > 1) return;
                      
                layer_style = this.$refs.map.layer_setting_stlye_el_list
          
                for(let i=0; i<layer_style.length; i++) {

                    var params = layer_style[i];                 
                 
                    var _url=params.surl;
                  
                    var lftaye = params.f_type;
                    _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;
                   // params.surl = _url 
                    this.elevation_layer = CreateVectorLayer_f(params,extent) ;

                    var vis = params.visible ;
                    this.elevation_layer.setVisible(vis);                    
                    map.addLayer(this.elevation_layer);

                } //for문 끝
           
            } // zoom크기에 대한 if


        },

        facility_refresh(){
           // this.$refs.facility_list.setUrl(this.URL+"api/get_facility_list_ex");
            this.init_mode() ;
        },
        gis_reload(){
          //  this.edit_cancel() ;
          //  this.$bvToast.hide('edit') ;
            this.set_map_gisload(0) ;
            if(this.visible_edit ){              
            this.$refs.map.edit_start(this.edit_mode, this.add_layer_name)
            }
        },
        init_mode(){
           // this.edit_cancel() ;
           // this.$bvToast.hide('edit') ;
        //   if(this.visible_edit ){              
         //       this.$refs.map.edit_start(this.edit_mode, this.add_layer_name)
         //  }
        },
        zoom(e) {
            var z = this.$refs.map.map.getView().getZoom() ;
            if(e==='+'){
                this.$refs.map.map.getView().setZoom(z+1);
            }else{
                this.$refs.map.map.getView().setZoom(z-1);
            }

        },
        zoom_fit() {
            this.$refs.map.set_fit_extent(this.$store.setting.extent)
        },
        delete_feature() {
            this.$refs.map.delete_feature()
        },
        edit_ok() {
            let writer = new GeoJSON();
            //기존소스
            /*
            for(let i=0; i<this.$refs.map.add_modify_features.length; i++) {
                let item = this.$refs.map.add_modify_features[i]
                item.feature = JSON.parse(writer.writeFeature(item.feature))
                for(let j=0; j<item.feature.geometry.coordinates.length; j++) {
                    item.feature.geometry.coordinates[j] = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', item.feature.geometry.coordinates[j])
                }
            }
            */
           //point, block 수정 및 입력 안되는 부분 수정

            for(let i=0; i<this.$refs.map.add_modify_features.length; i++) {
                let item = this.$refs.map.add_modify_features[i]
                item.feature = JSON.parse(writer.writeFeature(item.feature))

                if(item.code===1 || item.code ===2 ||  item.code ===3 ){ //point일 경우
                    var cod_point = item.feature.geometry.coordinates ;
                    item.feature.geometry.coordinates = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', cod_point)

                }else if(item.code===7){
                     var cod_block = item.feature.geometry.coordinates ;

                     for(let k=0; k<cod_block.length; k++) {//블럭갯수
                        var chang_block_d=cod_block[k] ;
                        for(let l=0; l<chang_block_d.length; l++) { //블럭안에 point갯수
                             chang_block_d[l] = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', chang_block_d[l])
                        }
                        cod_block[k] = chang_block_d ;
                     }
                }else{
                    for(let j=0; j<item.feature.geometry.coordinates.length; j++) {
                        var cod = item.feature.geometry.coordinates[j] ;
                        item.feature.geometry.coordinates[j] = proj4(this.$refs.map.map.getView().getProjection().getCode(), 'EPSG:4326', cod)
                    }
                }

            }

            if(this.$refs.map.add_modify_features.length>0) {
                this.$http.get(this.URL+'api/add_modify_features?json='+encodeURIComponent(JSON.stringify(this.$refs.map.add_modify_features))).then(() => {
                    let lyr = this.get_edit_layers()
                    for(let i=0; i<lyr.length; i++) {
                        lyr[i].getSource().clear()
                        lyr[i].getSource().refresh()
                    }
                })
            }
            this.map_mode = 'select'
            this.$bvToast.hide('edit')
            this.visible_edit = false
            this.$refs.map.edit_end()
        },
        edit_cancel() {
            this.map_mode = 'select'
            this.visible_edit = false
            this.$refs.map.edit_end()
            /*
            let lyr = this.get_edit_layers()
            for(let i=0; i<lyr.length; i++) {
                lyr[i].getSource().clear()
                lyr[i].getSource().refresh()
            }
            */
        },
        get_edit_layers() {
            let lyrs = this.$refs.map.get_layers()
            let ll = []
            for(let i=1; i<lyrs.length; i++) {
                if(parseInt(lyrs[i].get('code'))>=100) continue
                ll.push(lyrs[i])
            }
            return ll
        },
        get_layers() {
            return this.$refs.map.get_layers()
        },
        layer_visible(e, name) {
            for(let i=0; i<this.get_layers().length; i++) {
                if(this.get_layers()[i].get('name')!==name) continue;
                this.get_layers()[i].setVisible(e)
            }
        },
        fn_press_han(event)
        {
           var objTarget = event.srcElement || event.target;
       // if(objTarget.type == 'text') {
          
          var value = objTarget.value;
          //alert(value);
          if(isNaN(value)){
            objTarget.value =value.replace(/[^0-9]/g,'');
           // alert('Please enter only numbers');
           // event.preventDefault();          
          }        
        },
    },
    mounted () {

      this.box = [] ;
     
      //api/get_facility_list_ex?customer_id=1&layer_type=7&page=1&per_page=15&search_term=&sort_field=&sort_type=
      

       setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
           
           }
         }
        }, 1000)
    },
    beforeUpdate () {
    },
    beforeDestroy (){
        //this.$refs.map = null;
    },
    components: {
        UploadShape: () => import('@/components/popup/UploadShape'),
        Map: Map,
        SearchLeftType3,
    }
}

</script>

<style scoped lang="scss">
@import '@/assets/scss/base/variables';
 /* 2022 new */
 .modal_input{
  line-height: 20px;
  background: #172A46;
  border: 1px solid #707070;
  border-radius: 0;
}
.map-button.icon_btn {
  font-size: 14px;
  line-height: 1;
  margin-right: 6px;
}
.map-button.icon_btn.icon_btn_text {
  font-size: 12px;
}
.icon-button-group ::v-deep .btn-secondary {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  float: left;
  margin-right: 6px;
  font-size: 14px;
  line-height: 23px;
  background: rgba(35, 41, 47, 0.8) !important;
  border-color: transparent !important;
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.icon_btn.btn-secondary {
  background: rgba(35, 41, 47, 0.8);
  border-color: transparent;
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.btn-map-view {
  background: rgba(35, 41, 47, 0.8);
  &:hover {
    background: rgba(35, 41, 47, 1);
    border: none;
  }
}
.map-zoom {
  font-size: 14px;
  float: left;
  margin-right: 10px;
  margin-bottom: 0;
  margin-top: 5px;
}
.btn-toolbar {
  margin-right: 0;
}
.search_div{
    position: absolute;
    // 2022 new
    // left:580px;
    left:650px;
    top: 10px;
    z-index: 110;
}
.spn1 {
      float: left;
}
.dma_layer {
  position: absolute;
  right: 30px;
  top:30px;  
  width: 350px;
  height: 500px;

//   2022 new
//   background: linear-gradient(rgba(138, 137, 137, 0.7), rgba(138, 137, 137,0.7)) ;
  background-color: rgba(23,42,70,0.85);
//   border-radius: 10px;
  border-radius: 0px;
  color: #fff;
}

// 2022 new
input[type=radio] {
    position: absolute;
    visibility: hidden;
    display: none;  
    &:checked + label {
      color: $color-white;
      background: $color-primary;
    }  
}
</style>

<template>
  <highcharts id= "PieCountChart"   :options="chartOptions"  :class="size"></highcharts>
</template>

<script>
export default {
  name: 'PieHchart',
   props:{
      chartData: {
      
      },
       chartData1: {
      
      },
   
      series_name : {},
      size : { type:String},
  },
  data () {
    return {
  
       chartOptions: {
         
         colorAxis:[{lineColor:"#ffffff"}],
        // colors : ["#2e75b6","#ff0000",],
         credits: {
              text: '',
              href: ''
          },
        series: [ {
            //name: this.series_name,
            data: this.chartData
        }],
    
         title: {        text:  "", },
         chart: {  // zoomType: 'x' ,
                  type: 'pie', 
                //  plotBackgroundColor: "#cccccc",
                  options3d: {
                             enabled: true,
                             alpha: 45
                             }
                },
        tooltip: {
            pointFormat: "<b>{point.y}</b>"
        },
         exporting: {
              buttons: { contextButton: { enabled: false} }
         },    
         legend: {
            enabled: true,
            verticalAlign: "top",
            itemStyle: {color : '#28b7cb'},
            //verticalAlign: "middle"
        },
        plotOptions: {
            pie: {
               // innerSize: 0,              
                depth: 25,
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b><br>{point.percentage:.1f} %, {point.y}',
                  distance: -40,
                  filter: {
                    property: 'percentage',
                    operator: '>',
                    value: 4
                  }
                },
                showInLegend: true,
            }
        },
      },

    
       
    }
  }
}
</script>
<style lang="scss" >
.gisPieChart .highcharts-container .highcharts-root {
  width: 90%; 
  /*height: 250px;  */
  height : calc(45vh - 20px);
}
.gisPieChart {
  min-width:250px;
  max-width: 1600px;
  height : calc(45vh - 20px);
  width:90%; 
  margin: 0 auto;
  
}
.leakPieChart .highcharts-container .highcharts-root {
  width: 90%; 
  height: 350px;  
}
.leakBarChart {
  min-width:300px;
  max-width: 1600px;
  height: 350px;
  width:90%; 
   margin: 0 auto;
  
}

 .highcharts-grid-line {
    stroke-width: 0px;
    stroke: #ffffff;
}
</style>
<template>
  <div>
    <!-- top-bar -->
    <div ref="navbar" class="navbar-custom">
      <ul class="list-unstyled topnav-menu topnav-menu-left">
        <li>
          <ol class="breadcrumb">
            <!--  <li class="breadcrumb-title">Leak Detail</li>-->
            <!--  <li class="breadcrumb-bar">|</li>-->
            <li class="breadcrumb-item">
              <router-link to="/"
                ><img
                  src="@/assets/images/Common/icon/back_arrow.png"
                  alt=""
                  class="back_arrow"
                />Home</router-link
              >
            </li>
            <li class="breadcrumb-item">
              <router-link to="/dataset">{{$t("water_leak.page_title")}} Dataset</router-link>
            </li>
            <li class="breadcrumb-item active">
              {{ $t("leak_detail.page_title") }}
            </li>
          </ol>
        </li>
      </ul>
    </div>
    <!-- tom-bar-end -->
    <div class="content-area row justify-content-around">
      <div class="left leak-detail dataset">
        <!--div class="check_list">
          <input
            type="checkbox"
            id="chk_01"
            value="RT_LEAK"
            v-model="checkedNames"
          />
          <label for="chk_01">{{ $t("leak_detail.search_leak") }}</label>
          <input
            type="checkbox"
            id="chk_02"
            value="NO_LEAK"
            v-model="checkedNames"
          />
          <label for="chk_02">{{ $t("leak_detail.search_no_leak") }}</label-->

          <!--
          <input type="checkbox" id="chk_01"  value='RT_LEAK' v-model="checkedNames"  @change="uncheckedAll('2')">
          <label for="chk_01">Water Leak</label>
          <input type="checkbox" id="chk_02" value='RT_LEAK_INSIDE' v-model="checkedNames" @change="uncheckedAll('2')">
          <label for="chk_02">Inner Water Leak</label>
          <input type="checkbox" id="chk_03"  value='RT_USAGE'  v-model="checkedNames"  @change="uncheckedAll('2')">
          <label for="chk_03">Usage</label>          
          <input type="checkbox" id="chk_04"  v-model="checkedNames"   value='RT_OTHER' @change="uncheckedAll('2')">
          <label for="chk_04">Other</label>
          <input type="checkbox" id="chk_05" @change="uncheckedAll('1')" v-model="unchecked">
          <label for="chk_05">All</label>
          -->
          <!--button class="workbtn" @click="go_search">
            <i class="fas fa-search"></i>
          </button>
        </div-->


        <div class="info_box leak" style="padding-top:15px;">
                  <ul class="list1">
                    <li class="ns-items">
                      <label>
                        <input class="ns-checkbox" type="checkbox" @click="SelectAllBox" v-model="allSelected">
                        <span  class="title" style="padding : 0 10px;"> {{$t('water_leak.left_menu.select_all')}}</span>
                      </label>
                      <b-button style="float:right;font-size:10pt;margin-right:5px;"  @click="go_search">
                        Search
                      </b-button>  
                       <!--b-button style="float:right;font-size:10pt;margin-right:5px;"  @click="set_strength_cals">
                        Strengh_cal
                      </b-button-->  
                    </li>
                    <li style="position:relative;height:80px;overflow-y:auto;" >
                      
                      <div style="float:left;padding:5px;" v-for="(item, index) in customer_name" :key="index">
                        <label><input class="ns-checkbox" type="checkbox" :id="item.id" :value="item.id"
                          v-model="customer_check">{{item.name}}</label>
                      </div>
                    </li>  

                  </ul>
        </div> 
       
        <div class="lk-dt-tab table-button-group">
          <!--   <b-button variant="lk-middle lk-btn-left" @click="show_frequency_chart()"><i class="mdi mdi-pipe-leak mr-2"></i>LEAKAGE STRENGTH</b-button>
          <b-button variant="lk-middle border-left-line" @click="goRouter('/leak-analysis',-1)"><i class="mdi mdi-leak mr-2"></i>FREQUENCY COMPARISON</b-button>
         <b-button variant="lk-middle border-left-line" @click="$refs.leak_legend.show('set_leak_legend', $store.state.leak_legend);"><i class="mdi mdi-book-open mr-2"></i>LEGEND SETTING</b-button>-->
          <!--b-button variant="lk-middle lk-btn-left" @click="go_work_move()"
            ><i class="mdi mdi-arrow-right mr-2"></i
            >{{ $t("leak_detail.btn_work_move") }}</b-button-->
        <b-button variant="lk-middle lk-btn-left" @click="downloads()"
            ><i class="mdi mdi-download mr-2"></i
            >{{ $t("leak_detail.btn_wav_down") }}</b-button
          >
          <!--b-button
            v-if="levelA"
            variant="lk-middle border-left-line"
            @click="go_delete()"
            ><i class="mdi mdi-delete mr-2"></i
            >{{ $t("button.delete") }}</b-button
          -->
          <!--b-button v-if="levelA" variant="lk-middle border-left-line" @click="makeExcelFile4()"><i class="mdi mdi-delete mr-2"></i>make_excel</b-button-->
          <b-button
            variant="lk-middle border-left-line"
            @click="go_download"
          >
            <download-excel
              class="btn-default"
              :data="json_data"
              :fields="json_fields"
              worksheet="My Worksheet"
              :name="filename"
            >
              <i class="mdi mdi-download mr-2">
                {{ $t("leak_detail.btn_excel_down") }}</i
              >
            </download-excel>   
          </b-button>           

          <b-button v-if="levelA"
            variant="lk-middle border-left-line"
            @click="update_dataset(1)"
            ><i class="mdi mdi-check mr-2"></i>Check_ON</b-button
          >
          <b-button v-if="levelA"
            variant="lk-middle border-left-line"
            @click="update_dataset(0)"
            ><i class="mdi mdi-minus mr-2"></i>Check_OFF</b-button
          >
          <b-button variant="lk-middle lk-btn-left" @click="update_leak_result(1)"
            ><i class="mdi mdi-pipe-leak mr-2"></i>Leak</b-button
          >
          <b-button v-if="levelA"
            variant="lk-middle border-left-line"
            @click="update_leak_result(0)"
            ><i class="mdi mdi-water-off mr-2"></i>No Leak</b-button
          >
          <b-button v-if="levelA"
            variant="lk-middle border-left-line"
            @click="update_leak_result(2)"
            ><i class="mdi mdi-gauge mr-2"></i>Meter is running</b-button
          >
          <b-button v-if="levelA"
            variant="lk-middle border-left-line"
            @click="update_leak_result(3)"
            ><i class="mdi mdi-flash"></i>Electric</b-button
          >
          <b-button v-if="levelA"
            variant="lk-middle lk-btn-right border-left-line"
            @click="update_leak_result(3)"
            ><i class="mdi mdi-help mr-2"></i>Unindentified</b-button
          >
  
       
        </div>
        <div class="list_box list_box2 dataset">
          <div class="page_list">
            <b-row class="list_box list_box2 dataset">
              <b-col class="page_list">
                <h3>{{ $t("leak_detail.table_title") }}</h3>
                <div class="table-box" id="table" style="margin-top: -20px">
                  <!-- <div style="position:absolute; top: 0; right:0;  ">
                      <download-excel
                        class   = "btn btn-default"
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        name    = "filename.xls">
                      <button  @click="go_download" class="btn btn-third ml-2"  > <i class="mdi mdi-download" v-b-tooltip.hover title='Excel Download'></i></button>
                    </download-excel> </div>-->
                  <!--<label for="textarea-small" class="table-box-text mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label>-->
                  <ServerSideTable
                    ref="table_leakage_info"
                    :columns="columns"
                    :searchOptions="{
                      enabled: true,
                      trigger: 'enter',
                      placeholder: 'Search this table',
                      externalQuery: searchTerm,
                    }"
                    :sort="true"
                    :select="true"
                    :add_custom_column="true"
                    :search="true"
                    :adv_search="adv_search"
                    :max_height="table_height + 'px'"
                    @on-row-click="onRowClick"
                    @on_goto_map_click="on_goto_map_click"
                    @on_info_click="on_leakage_info"
                    @get_ai_result="get_ai_result"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="right leak-detail dataset">
        <b-row style="padding: 10px 0px 0px 0px;  background: #172a46;">
          <b-col sm=9 class="leak-detail-content" style="height: calc(45vh - 20px );">
            <b-row>
              <b-col style="padding-top: 0px">
                <div
                  style="
                    position: relative;
                    left: 30%;
                    width: 53%;
                    text-align: center;
                  "
                >
                  <div style="float: left">
                     <span style="position:absolute;left:10px;margin-top: -10px;">{{band_hz}}  </span>
                    <span style="float: right; padding-top: 15px">
                     
                      <!--b-input
                        v-model="szoom"
                        style="
                          width: 60px;
                          height: 20px;
                          margin: 0 8px;
                          padding: 10px;
                          text-align: right;
                        "
                        type="text"
                        maxlength="4"
                        onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                        v-on:keydown="fn_press_han"
                        v-on:keyup="fn_press_han"
                      /-->
                      <b-select  class="facility__input" style="margin-top:-5px;" size="sm" v-model="bandfilter" :options="bandfilter_opt"  ></b-select>
                    </span>
                  </div>
                  <span style="float: left; margin-left: -45px">
                    <audio
                      id="slice_audio_id"
                      controls
                      preload="auto"
                      :src="slice_url"
                      style="transform: scale(0.7); height: 50px;"       
                    ></audio>
                     <b-button v-if="!loading" size="sm" @click="elec_remove"  v-b-tooltip.hover title="Remove noise" style="margin: -40px 0 0 -37px;">
                       <i class="mdi mdi-flash-off"/>                             
                     </b-button>
                     <span v-if="loading"><img src="@/assets/images/loading.gif" style="width:25px;margin-top: -40px;"/></span>
                  </span>
                </div>
                <div class="status leak" style="float: right;">
                  <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                     <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                       <span class="inner-bg">Y Axis</span> -->

                  <input type="radio" v-model="vchart" value="0" id="fixed" />
                  <label for="fixed">{{ $t("leakage_info.fixed") }}</label>
                  <input
                    type="radio"
                    v-model="vchart"
                    value="1"
                    id="proportional"
                  /><label for="proportional">{{
                    $t("leakage_info.proportional")
                  }}</label>
                </div>
                <div style="min-height: 180px; height: calc(20vh - 10px);">
                  <div id="hidden-container" style="display: none">
                    <div
                      id="waveform"
                      ref="waveform"
                      style="float: left; margin-left: 35px; width: 95%"
                    />
                    <div
                      id="wave-timeline"
                      ref="wave-timeline"
                      style="float: left; margin-left: 35px; width: 95%"
                    />
                  </div>
                </div>
                <!-- <audio controls preload="auto" :src="aurl" style = "width:50%;">
                 </audio> -->
                <!--<av-waveform :audio-src="aurl" :caps-height="2" :canv-width="800" :canv-height="120" ref="avwaveform"></av-waveform> -->
              </b-col>
            </b-row>
            <b-row>
              <b-col style="padding-left: 0px; padding-right: 0px">
                <!--FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:98%; overflow: auto;"  /-->
                <div style="min-height: 200px; height: calc(20vh - 10px);">
                  <pressChartData_fft
                    :url="fft_url"
                    ref="fftchart"
                    :height="fftchart_height"
                    style="width: 100%; overflow: auto"
                    @onZoomClickEvent="onZoomClickEvent"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col style="align-self: center; ">
            <img :src='spectrum_img' style="margin-top: 50px;width:216px; "/>
            <div style="right: 5px; position: absolute; bottom: -80px;">
              
              <img src="@/assets/images/new/ic_list_map.svg" alt="" @click="map_tab(0)"
             style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
               <b-button size="sm" @click="map_tab(1)"  v-b-tooltip.hover title="Chart" style="margin: 20px ;">
                  <i class="mdi mdi-chart-line"/>                             
               </b-button>
              
              </div>
          </b-col>  
        </b-row>
        <b-row style="padding: 10px 0px 0px 0px">
          <!--b-col sm=3 style="align-self: center;">
            <img :src='spectrum_img' style="width:216px; "/>
          </b-col-->

          <div v-if="map_loading" style="width: 100%;padding-right: 0;">
            <b-col 
              class="leak-detail-content-map"
              style="padding: 0; bottom: 0px"    >
              <div class="search_div_leak_dataset">
                <span class="spn1"
                  ><b-input
                    size="sm"
                    type="text"
                    :placeholder="$t('map.alt_addr')"
                    v-model="addr_value"
                    @keyup.enter="Enter_Check1"
                /></span>
                <span
                  ><button
                    class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text"
                    @click="addr_search"
                  >
                    {{ $t("button.search") }}
                  </button></span
                >
              </div>
              <div :style="{ height: map_height + 'px' }">
                <Map
                  ref="map"
                  :widx="widx"
                  :visible_full_extent="true"
                  :visible_gisload_button="true"
                  :leak_P="true"
                  :w_type="clicked_w_type"
                  @go_home="go_home()"
                  @onLeakMapClick="onLeakMapClick"
                  @set_map_gisload="set_map_gisload"
                  @toggle_visible_all_layer="toggle_visible_all_layer"
                  @toggle_visible_leak_type="toggle_visible_leak_type"
                  @facility_invisible_layer="facility_invisible_layer"
                  @set_info="set_info"
                  @leaktable_refresh="leaktable_refresh"
                  @get_gisinfo="get_gisinfo"
                  @gis_reload="gis_reload"
                />
              </div>
            </b-col>
          </div>
          <div v-if="!map_loading" style="width: 100%;padding: 0 0 0 15px;">
            <!-- chart row-->  
            <b-row style="padding: 10px 0px 0px 0px;  background: #172a46;">
            <b-col sm=9 class="leak-detail-content" style="height: calc(45vh - 20px );">
              <b-row>
                <b-col style="padding-top: 0px">
                  <div
                    style="
                      position: relative;
                      left: 30%;
                      width: 53%;
                      text-align: center;
                    "
                  >
                    <div style="float: left">
                      <span style="position:absolute;left:10px;margin-top: -10px;"> </span>
                      <span style="float: right; padding-top: 15px">
                        <b-input
                          v-model="szoom"
                          style="
                            width: 60px;
                            height: 20px;
                            margin: 0 8px;
                            padding: 10px;
                            text-align: right;
                          "
                          type="text"
                          maxlength="4"
                          onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                          v-on:keydown="fn_press_han"
                          v-on:keyup="fn_press_han"
                        />
                        <!--b-select  style="margin-top:-5px;" size="sm" v-model="bandfilter" :options="bandfilter_opt"  ></b-select-->
                      </span>
                    </div>
                    <span style="float: left; margin-left: 0px">
                      <audio
                        id="slice_audio_id1"
                        controls
                        preload="auto"
                        :src="slice_url1"
                        style="transform: scale(0.7); height: 50px;"       
                      ></audio>
                 
                    </span>
                  </div>
                  <div class="status leak" style="float: right;">
                    <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                      <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                        <span class="inner-bg">Y Axis</span> -->

                    <input type="radio" v-model="vchart" value="0" id="fixed" />
                    <label for="fixed">{{ $t("leakage_info.fixed") }}</label>
                    <input
                      type="radio"
                      v-model="vchart"
                      value="1"
                      id="proportional"
                    /><label for="proportional">{{
                      $t("leakage_info.proportional")
                    }}</label>
                  </div>
                  <div style="min-height: 180px; height: calc(20vh - 10px);">
                    <div id="hidden-container" style="display: none">
                      <div
                        id="waveform"
                        ref="waveform"
                        style="float: left; margin-left: 35px; width: 95%"
                      />
                      <div
                        id="wave-timeline"
                        ref="wave-timeline"
                        style="float: left; margin-left: 35px; width: 95%"
                      />
                    </div>
                  </div>
                  <!-- <audio controls preload="auto" :src="aurl" style = "width:50%;">
                  </audio> -->
                  <!--<av-waveform :audio-src="aurl" :caps-height="2" :canv-width="800" :canv-height="120" ref="avwaveform"></av-waveform> -->
                </b-col>
              </b-row>
              <b-row>
                <b-col style="padding-left: 0px; padding-right: 0px">
                  <!--FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:98%; overflow: auto;"  /-->
                  <div style="min-height: 200px; height: calc(20vh - 10px);">
                    <pressChartData_fft
                      :url="fft_url1"
                      ref="fftchart1"
                      :height="fftchart_height"
                      style="width: 100%; overflow: auto"
                      @onZoomClickEvent="onZoomClickEvent1"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col style="align-self: center; ">
              <img :src='spectrum_img' style="width:216px; "/>
        
            </b-col>  
            </b-row>
          </div>
        </b-row>
      </div>
    </div>
    <DlgLeakDataset ref="dlg_dataset" />
    <DlgWavSliceChart ref="dlg_wave_slice_chart" />
    <DlgWorkList ref="dlg_work_list" @goRouter="goRouter" />
  </div>
</template>

<script>
import pressChartData_fft from "@/components/chart/pressChartData_fft";
import ServerSideTable from "@/components/table/ServerSideTable_ds";
import Map from "@/components/Map_model";

import DlgLeakDataset from "@/components/popup/DlgLeakDataset";
import { CreateVectorLayer_f } from "@/gis.js";
import WaveSurfer from "wavesurfer.js";
import DlgWavSliceChart from "@/components/popup/DlgWavSliceChart";
import DlgWorkList from "@/components/popup/DlgWorkList";

import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js";
import Regions from "wavesurfer.js/dist/plugin/wavesurfer.regions";
/*
' CASE '+
' WHEN layer_type=0 then "Pipe" '+
' WHEN layer_type=1 then "Valve" '+
' WHEN layer_type=2 then "Water Meter" '+
' WHEN layer_type=3 then "Pump" '+
' WHEN layer_type=4 then "Hydrant" '+
' WHEN layer_type=7 then "Block" '+
' WHEN layer_type=8 then "Water Treatment Plant" '+
' WHEN layer_type=9 then "Pumping Station" '+
' WHEN layer_type=10 then "Water Basin" '+
' WHEN layer_type=11 then "Chamber" '+
' WHEN layer_type=12 then "Gauge" '+
' WHEN layer_type=13 then "Other" '+
' END AS type_, '+
*/

export default {
  name: "LeakDetail",
  props: {
    // url
  },
  watch: {
    download_idx() {
      if (this.download_idx > this.download_arr.length - 1) return;
      //leak_id를 얻어온다.
      var idx = this.download_arr[this.download_idx].leak_id;
      //wav_file_id를 가져온다.
      var wav_file_id = this.download_arr[this.download_idx].file_path;
      var fs = "";
      if (this.download_arr[this.download_idx].leak_result !== null) {
        fs = this.download_arr[this.download_idx].leak_result;
      }
      let fname =
        fs +"_" + idx +
        "_" +this.download_arr[this.download_idx].work_type +"_"+
        this.download_arr[this.download_idx].f_name +"_"+this.download_arr[this.download_idx].layer_type +
        "_G" + this.download_arr[this.download_idx].is_ground +
        ".wav";
      //this.download1(idx, fname,this.download_arr[this.download_idx].work_type);
      this.download(wav_file_id, fname);
    },
    vchart() {
      //     if(this.vchart==='0') {
      //        alert("radio button : " + this.vchart) ;
      //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
      //     }
      var c_url = this.fft_url.split("#");
      this.fft_url = c_url[0] + "#" + this.vchart;
       this.bandfilter = 0
    },
    bandfilter() {
      if(this.bandfilter!==0) {      
        this.s_min = 0 ;
        this.s_max = 0 ;
        if(this.bandfilter === 1){
          this.band_hz = this.$refs.fftchart.low_max_hz +'Hz'
          this.s_min = this.$refs.fftchart.low_max_hz - 200 < 0 ? 1 : this.$refs.fftchart.low_max_hz - 200 ;
          this.s_max = this.$refs.fftchart.low_max_hz + 200 ;
        }else{
          this.band_hz = this.$refs.fftchart.high_max_hz+'Hz' 
          this.s_min = this.$refs.fftchart.high_max_hz - 200  ;
          this.s_max = this.$refs.fftchart.high_max_hz + 200 ;
        }
        var file_path = null
        if(this.aurl.indexOf('api') !== -1){
          file_path = this.aurl.split('/API21/FILE/')
        }else{
          return
        }
        this.slice_url = this.URL + "api/get_wave_slice_new?idx=" + file_path[1] +"&w_type="+this.clicked_w_type+"&start_pos="+this.s_min+"&end_pos="+this.s_max+"&y_zoom="+this.szoom ;
      //  this.slice_url = this.URL+'api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+this.s_min+"&end_pos="+this.s_max+"&y_zoom="+this.szoom ;
      }else{
         this.band_hz = '-'
      }
    },
    //2023-06-15 누수음 강도 재계산
    strength_cal_idx(){
      if( this.strength_cal_idx == -1) return
       if (this.strength_cal_idx > this.strength_cal_arr.length - 1){
          //this.ref
          return;
       }else{   
          this.set_strength_cal(this.strength_cal_idx)
       }
    }
  
  },
  data() {
    return {
      customer_check: [],
      ds_id :this.$route.query.widx, 
      widx : 300,
      layer_all_visible: false,
      leak_btn_str: this.$t("leak_detail.btn_leak"),
      dataset_btn_str: 'Check_ON',
      checkedNames: [],
      map_loading : true,
      loading : false,
      unchecked: true,
      contents_height: 800,
      waveform_height: 50,
      fftchart_height: 195,
      map_height: 400,
      table_height: 530,
      vchart: 0,
      region_start: 0,
      region_end: 0,
      szoom: 200,
      spectrum_img : require('@/assets/mfcc.png'),
      customer_name : [],
      is_playing: false,
      value: "",
      addr_value: "",
      levelA: false,
      columns: [
        {
          //label: this.$t("leak_detail.table_column.map"),
          label: '지도',
          field: "go_map",
          width: "4%",
          sortable: false,
        },
        {
          //label: this.$t("leak_detail.table_column.info"),
          label: '정보',
          field: "info",
          width: "4%",
          sortable: false,
        },
        {
          label: "고객사",
          field: "customer_name",
          width: "7%",
          filterable: true,
        //  type: "date",
        /*
          filterOptions: {
            enabled: true,
            placeholder: "Filter This Thing",
            filterValue: 'Jane', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          }
          */
        },        
        {
        //  label: this.$t("leak_detail.table_column.time"),
          label: '시간',
          field: "measure_start_datetime",
          width: "13%",
          /* filterable: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "yyyy-MM-dd HH:mm:ss",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Received",
         //   filterFn: this.dateRangeFilter
          }*/
        },
        //  {label: 'Work No', field: 'w_idx'},
        {
         // label: this.$t("leak_detail.table_column.worker"),
          label: '작업자',
          field: "worker",
          width: "7%",
        },
        //  {label: 'Facility No', field: 'f_idx'},
        {
          //label: this.$t("leak_detail.table_column.facility"),
          label: '시설물',
          field: "layer_type",
          width: "7%",
        },
        //{label: 'material ', field: 'material'},   
        {label: '장비번호', field: 'device_sn'},        
        // {label: 'Period(Sec)', field: 'period'},
        {
          //label: this.$t("leak_detail.table_column.strength"),
          label: '강도',
          field: "wave_energy",
          width: "5%",
        },
     
        {label: 'Max_Hz', field: 'wave_max_frequency', width: "5%",},
        /*
        {
          label: this.$t("leak_detail.table_column.s_cal"),
          field: "wave_energy_cal",
          width: "7%",
        },
        */
        {
          //label: this.$t("leak_detail.table_column.leak"),
          label: '분류',
          field: "leak_result",
          width: "3%",
          /*filterable: true,
          filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: 'Filter This Thing', // placeholder for filter input
                  filterValue: '', // initial populated value for this filter
                  filterDropdownItems: ['Leak','Leak Inside','Usage','Other'], // dropdown (with selected values) instead of text input
                //  filterFn: this.columnFilterFn, //custom filter function that
                  trigger: 'enter', //only trigger on enter not on keyup 
                },
          */
        },
        {
        //  label: this.$t("leak_detail.table_column.ai"),
          label: this.$route.query.widx === "5" ? '강도(R)':'기존AI',
          field: this.$route.query.widx === "5" ? 'wave_energy_cal':"ai_1",
          width: "6%",
        },
        {
        //  label: this.$t("leak_detail.table_column.ai"),
          label: '신규AI',
          field: "ai_leak_result",
          width: "6%",
        },
         {
          label: 'AI_R',
          field: "ai_btn",
          width: "4%",
        },
        {
        //  label: this.$t("leak_detail.table_column.grd"),
          label: 'G',
          field: "is_ground",
          width: "4%",
          /*
         filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: ""
        }
        */
        },
        {
          label: '체크',
          field: "is_dataset",
          width: "4%",
          /*
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: ""
          }
          */
        },
        {
          //label: this.$t("leak_detail.table_column.remarks"),
          label: '비고',
          field: "remarks",
          width: "10%",
        },
      ],
      selected_ts_mnf: "",
      json_data: [],
      json_fields: {  "Start Time": "measure_start_datetime", 
        "Worker": "worker",
        "work_type" : "work_type",
        "water_work_id":"water_work_id",
        'Customer_name' : "customer_name",
        'Facility No': 'f_idx',
        "layer_type": "layer_type",       
        "Material" : "material",
        "Device No": "device_sn",        
        "Strength": "wave_energy",
        "Max Frequency(Hz)": "wave_max_frequency",
        "Leak Result": "leak_result",
        "AI Leak Result": "ai_leak_result",
        "wave_file_path": "file_path",
        "fft_file_path": "fft_file_path",
        "is_ground" : "is_ground",
        "check" : "is_dateset",
        "Remarks": "remarks",
      },

      adv_search: [
        {
          field: "measure_datetime",
          control: "date",
          value: null,
          value2: null,
          placeholder: "From measure_date",
          placeholder2: "To measure_datet",
        },
       
        {
          field: "layer_type",
          control: "select",
          value: null,
          options: [
            { value: null, text: "layer_type" },
            { value: "0", text: "Pipe" },
            { value: "1", text: "Valve" },
            { value: "2", text: "Water Meter" },
            { value: "3", text: "Pump" },
            { value: "4", text: "Hydrant" },
            { value: "1000", text: "Empty" },
          ],
        },
        {
          field: "device_sn",
          control: "select",
          value: null,
          options: [
            { value: null, text: "device_sn" },
            { value: "SM1-V3A", text: "SM1" },
            { value: "SM1-V3G", text: "Ground Sensor" },            
            { value: "SM2-SL", text: "Logger" },
            { value: "M2", text: "M2" },

          ],
        },        
        {
          field: "wave_energy",
          control: "range",
          value: null,
          value2: null,
          placeholder: "From Strength",
          placeholder2: "To Strength",
        },
        {
          field: "wave_max_frequency",
          control: "range",
          value: null,
          value2: null,
          placeholder: "From max_frequency",
          placeholder2: "To max_frequency",
        },
        {
          field: "leak_result",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "Leak Result" },
            { value: "L", text: "Leak" },
            { value: "N", text: "No LEAK" },
            { value: "M", text: "Meter is Running" },
            { value: "U", text: "Unidentified" },
          ],
        },
        {
          field: "ai_leak_result",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "new_ai_result" },
            { value: "L", text: "Leak" },
            { value: "N", text: "No LEAK" },
            { value: "M", text: "Meter is Running" },
            
            { value: "U", text: "Unidentified" },
          ],
        },
        {
          field: "current_ai_result",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "ai_leak_result" },
            { value: "Leak", text: "Leak" },
            { value: "No LEAK", text: "No LEAK" },
          ],
        },
        {
          field: "is_ground",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "Ground Y/N" },
            { value: "0", text: "No Ground" },
            { value: "1", text: "Ground" },
          ],
        },
        {
          field: "is_dataset",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "Check ON/OFF" },
            { value: "0", text: "Check OFF" },
            { value: "1", text: "Check ON" },
          ],
        },
      ],
      filename: "filename.xls",
      wavesurfer: null,
      fft_url: "",
      aurl: "",
      clicked_l_idx: -1,
      clicked_w_type : 100,
      clicked_b_row: null,
      searchTerm: "",
      slice_url: "",
      slice_url1: "",
      download_idx: -1,
      download_arr: [],
      strength_cal_idx : -1,
      strength_cal_arr :[],
      
      t_start_time: "",
      t_worker: "",
      t_device_no: "",
      t_ai_result: "  ",
      t_leak_result: "  ",
      t_strength: "",
      t_strength_cal: "",
      t_f_type: "",
      t_remark: "",
      reload_set : -1 ,
      allSelected: false,
      bandfilter: 0,
      bandfilter_opt: [
          { value: 0, text : 'None' },
          { value: 1, text : 'Low' },
          { value: 2, text: 'High'},    
      ],
      low_max_hz : 0,
      high_max_hz : 800,
      band_hz : '-',
    };
  },
  methods: {
    map_tab(a){
        //a = 0 , 지도보기
        if(a === 0 ) this.map_loading = true
        else this.map_loading = false

        //a=1 chart 보기
    },
    elec_remove(){
      this.loading = true;
      this.slice_url = null ;        
      if(this.aurl.indexOf('api') !== -1){
        var api_path = this.aurl.split('api')
  
        this.slice_url = this.URL +"ai/electric/remove?api_path="+api_path[1] ;

        var self = this;
        /*
        fetch( this.URL +"ai/electric/remove?api_path="+api_path[1], {mode: "no-cors",
           //'content-type': 'audio/wav',
           //     'accept': 'audio/wav'
           }).then(function(response) {
          alert(response.status);
           //const urlCreator = window.URL || window.webkitURL
          // self.slice_url = urlCreator.createObjectURL(response.data) 
          self.slice_url = response.data
        });
        */
        
        var a = 0
       
        var interval = setInterval(function() {
          //alert('http://webisfree.com');
          var x = document.getElementById("slice_audio_id").duration;
          if(!isNaN(x)){
            self.loading = false;
            clearInterval(interval)  
           
          }
          ++a;
          if(a === 15){
            self.loading = false;
            clearInterval(interval)  
            
          }
        }, 1000);
       
      }
    },
    go_detail () {
      //var leak_arry = this.customer_check
      this.go_search()
      /*
      if (leak_arry.length > 0) {
        this.go_search()
      } else {
        alert(this.$t('water_leak.left_menu.alert_select_list'))
      }
      */
    },    
     SelectAllBox () {

      // this.isChecked = true;
      var selected = []

      if (!this.allSelected) {
        //   this.box.forEach(function (item) {
        for (let i = 0; i < this.customer_name.length; i++) {
          selected.push(this.customer_name[i].id)
          //   });
        }
      }
      this.customer_check = selected

    },
    toggle_visible_all_layer() {
      // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
      // strength_cal값을 가져와 지도에 보여준다.
      if(this.$refs.table_leakage_info.rows[0] !== undefined && this.$refs.table_leakage_info.rows[0] !== null )
        this.on_goto_map_click(this.$refs.table_leakage_info.rows[0]) 
      ++this.reload_set
      this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
      if (this.$refs.map.layer_all_visible) this.$refs.map.leak_cal = 1;
      else this.$refs.map.leak_cal = 0;

      //누수 레이어를 다시 그린다.
      this.$refs.map.init_reload_layer();

      /*
        this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
        let map = this.$refs.map.map
        for(let i=1; i<map.getLayers().getLength()-1; i++) {
            let layer = map.getLayers().item(i)
            if(layer.get('code')===100)
                layer.setVisible(this.$refs.map.layer_all_visible)
        }
        */
    },
    toggle_visible_leak_type() {
      // 처음엔 leak type all 데이터를 가져와 지도에 보여준다.
      // All or LEAK 값을 가져와 지도에 보여준다.
      ++this.reload_set
      this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
      if (this.$refs.map.layer_leak_visible) this.$refs.map.leak_type = "LEAK";
      else this.$refs.map.leak_type = null;
       var cz = this.$store.setting.map_name === "Daum" ? 9 : 15;
    //  let epsg =
    //    this.$store.setting.map_name === "Daum" ? "EPSG:5181" : "EPSG:3857";
      //var extent = map.getView().calculateExtent(size);

      if (this.$refs.map.map.getView().getZoom() < cz) {
        alert(this.$t("home.alert_zoom_in"));
      } else {
        //누수 레이어를 다시 그린다.
        this.$refs.map.init_reload_layer();
      }
    },
    fn_press_han(event) {
      var objTarget = event.srcElement || event.target;
      // if(objTarget.type == 'text') {

      var value = objTarget.value;
      //alert(value);
      if (isNaN(value)) {
        objTarget.value = value.replace(/[^0-9]/g, "");
        // alert('Please enter only numbers');
        // event.preventDefault();
      }
    },
 
    addr_search() {
      this.$refs.map.go_search(this.addr_value);
    },
    Enter_Check1() {
      this.addr_search();
    },
    uncheckedAll(a) {
      // this.isChecked = true;
      if (this.unchecked && a === "1") {
        this.checkedNames = [];
      } else {
        if (this.checkedNames.length > 0) this.unchecked = false;
        else this.unchecked = true;
      }
    },
    change_selected_ts_mnf(e) {
      this.selected_ts_mnf = e;
      this.go_search();
      /*
          this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_list_ex?w_idx="+this.$route.query.widx+'&search='+this.value+'&leak_result='+this.selected_ts_mnf, (rows) => {
            console.log(rows)
            if((!rows)||(rows.length<=0)) return;
            this.clicked_l_idx = rows[0].idx
            this.aurl = this.URL+'api/get_wav/'+rows[0].idx
            //  this.avwaveform.load(aurl)
            this.wavesurfer.load(this.aurl);
            this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
        })
        */
      //    this.get_time_series_mnf()
    },
    facility_invisible_layer() {
      this.layer_all_visible = false;
      let map = this.$refs.map.map;
      for (let i = 1; i < map.getLayers().getLength(); i++) {
        let layer = map.getLayers().item(i);
        if (layer.get("code") > 50) continue;
        layer.setVisible(this.layer_all_visible);
      }
    },
    go_work_move() {
      //   this.filename =
      this.permission_check();
      let rows = this.$refs.table_leakage_info.getSelectRows();
      if (rows.length < 1) {
        alert(this.$t("leak_detail.alt_select"));
        return;
      }

      let list = [];
      for (let i = 0; i < rows.length; i++) {
        list.push(rows[i].id);
      }
      this.$refs.dlg_work_list.show(100, list, this.$route.query.widx);
      /*
        var con_test = confirm("Are you sure you want to move?");
        //let list = idx
        // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
        if(con_test){
          this.$http.get(this.URL+'api/delete_ex?l_idx_ex='+list).then((res) => {  
            console.log(res.data);
              this.leaktable_refresh();
            });

        }
        */
    },
    update_leak_result(leak_query) {
      // leak_query : 1 => leak, 0 : no_leak, 2:meater is running
      /*
      var leak_query = 0;
      if (this.leak_btn_str === this.$t("leak_detail.btn_leak")) {
        leak_query = 1; //RT_LEAK
        this.leak_btn_str = this.$t("leak_detail.btn_no_leak");
      } else {
        this.leak_btn_str = this.$t("leak_detail.btn_leak");
      }
      */
      this.permission_check();
      let rows = this.$refs.table_leakage_info.getSelectRows();
      if (rows.length < 1) {
        alert(this.$t("leak_detail.alt_select"));
        return;
      }

      let list = [];
      for (let i = 0; i < rows.length; i++) {
        list.push(rows[i].idx);
      }
      var con_test = confirm(this.$t("leak_detail.confirm_set_leak"));

      if (con_test) {
        this.$http
          .get(
            this.URL +
              "api/dataset/update?leak_result=" +
              leak_query +
              "&lidx=" +
              list+"&worker=" + sessionStorage.m_id
          )
          .then((res) => {
            console.log(res.data);
            this.leaktable_refresh();
          });
      }
    },
    update_dataset(dataset_query) {
      var dataset_btn_str1 ="";
      
      if (dataset_query === 1) {     
        dataset_btn_str1 ='Check_ON';
      } else {
        dataset_btn_str1 = 'Check_OFF';
      }
      
      this.permission_check();
      let rows = this.$refs.table_leakage_info.getSelectRows();
      if (rows.length < 1) {
        alert(this.$t("leak_detail.alt_select"));
        return;
      }

      let list = [];
      for (let i = 0; i < rows.length; i++) {
        list.push(rows[i].idx);
      }
      var con_test = confirm(dataset_btn_str1+" 로 변경하시겠습니까?");

      if (con_test) {
        this.$http
          .get(
            this.URL +
              "api/dataset/update?check_in=" +
              dataset_query +
              "&lidx=" +
              list
          )
          .then((res) => {
            console.log(res.data);
            this.leaktable_refresh();
          });
      }
    },
    go_download() {
      //   this.filename =
      this.filename = this.filename.replace(",", "_");
      let rows = this.$refs.table_leakage_info.rows;
      if (rows.length <= 0) {
        alert(this.$t("leak_detail.alt_no_data"));
        return;
      }
      this.json_data = rows;
      // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
      // this.$router.push({path: '/leak-detail', query: { widx: list }})
    },
    makeExcelFile4() {
      let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
      tab_text +=
        '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
      tab_text += "<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>";
      tab_text += "<x:Name>Test Sheet</x:Name>";
      tab_text +=
        "<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>";
      tab_text += "</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>";
      tab_text += "<table>";
      var div1 = document.getElementById("table");
      var div1Paras = div1.getElementsByTagName("table");

      const temp = div1Paras[1].innerHTML;
      // console.log(temp)
      tab_text += temp;
      tab_text += "</table></body></html>";
      console.log(tab_text);
      const fileName = "exampleTable.xls";
      const a_tag = document.createElement("a");
      const blob = new Blob([tab_text], {
        type: "application/vnd.ms-excel;charset=utf-8;",
      });
      a_tag.href = window.URL.createObjectURL(blob);
      a_tag.download = fileName;
      a_tag.click();
    },
    permission_check() {
      if (sessionStorage === null || typeof sessionStorage === undefined) {
        alert(this.$t("leak_detail.alt_no_update"));
        return;
      } else if (sessionStorage.level > 2) {
        alert(this.$t("water_leak.alert_no_permission"));
        return;
      }
    },
    go_delete() {
      this.permission_check();

      let rows = this.$refs.table_leakage_info.getSelectRows();
      if (rows.length <= 0) {
        alert(this.$t("leak_detail.alt_no_data"));
        return;
      }

      let list = [];
      for (let i = 0; i < rows.length; i++) {
        list.push(rows[i].id);
      }
      var con_test = confirm(
        this.$t("water_leak.left_menu.confirm_msg_delete")
      );
      //let list = idx
      // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
      if (con_test) {
        this.$http
          .get(this.URL + "api/delete_ex?l_idx_ex=" + list)
          .then((res) => {
            console.log(res.data);
            this.leaktable_refresh();
          });
      }
    },
    go_home() {
      /*
      this.$router.push({
        path: "/leak-analysis1",
        query: { widx: this.$route.query.widx, w_type: "100" },
      });
      */
    },
    download(wav_url, name) {
      //work_type = 100
      var surl = this.URL +'api/'+ wav_url
      /*
      if(work_type === '102')
        surl = this.URL + "api/download/wav/" + idx +"?type=m2_water_leak"
      else if(work_type === '104')  
        surl = this.URL+'api/download/wav/'+idx+"?type=water_leak_logger_detail"
      else
        surl = this.URL + "api/download/wav/" + idx
      */
      this.$http({
        url: surl,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);
        fileLink.click();
        //'Leak','Leak Inside','Usage','Other'

        setTimeout(() => {
          ++this.download_idx;
        }, 200);
      });
    },    
    download1(idx, name,work_type) {
      //work_type = 100
      var surl = this.URL + "api/download/wav/" + idx
      if(work_type === '102')
        surl = this.URL + "api/download/wav/" + idx +"?type=m2_water_leak"
      else if(work_type === '104')  
        surl = this.URL+'api/download/wav/'+idx+"?type=water_leak_logger_detail"
      else
        surl = this.URL + "api/download/wav/" + idx

      this.$http({
        url: surl,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);
        fileLink.click();
        //'Leak','Leak Inside','Usage','Other'

        setTimeout(() => {
          ++this.download_idx;
        }, 200);
      });
    },
    downloads() {
      let rows = this.$refs.table_leakage_info.getSelectRows();
      this.download_arr = rows;
      if (rows.length <= 0) {
        alert(this.$t("leak_detail.alt_select"));
        this.download_arr = [];
        return;
      } else {
        this.download_idx = 0;
      }
    },
    //2023-6-15 강도재계산
    set_strength_cals(){
      this.strength_cal_idx = -1;
      let rows = this.$refs.table_leakage_info.getSelectRows();
      this.strength_cal_arr = rows;
      if (rows.length <= 0) {
        alert(this.$t("leak_detail.alt_select"));
        this.strength_cal_arr = [];
        return;
      } else {
        this.strength_cal_idx = 0;
      }
    },
    set_strength_cal(idx) {
        
        var id = this.strength_cal_arr[idx].idx
       // alert(file_id)
        
        this.$http.get(this.URL+'api/dataset/set_strength_cal?idx='+id).then((res) => {  
          console.log(res.data);
          //this.leaktable_refresh();
          setTimeout(() => {
            ++this.strength_cal_idx;
          }, 100);
        });
       
      //  this.slice_url = this.URL+'api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+this.s_min+"&end_pos="+this.s_max+"&y_zoom="+this.szoom ;
    },
    show_frequency_chart() {
      let rows = this.$refs.table_leakage_info.getSelectRows();
      if (rows.length <= 0) {
        alert(this.$t("leak_detail.alt_select"));
        return;
      }
      let lidx = [];
      for (let i = 0; i < rows.length; i++) lidx.push(rows[i].id);
      this.$refs.dlg_frequency_chart.show(lidx);
    },
    goRouter(path, w_id) {
      let query = {};
      if (Number(w_id) === -1) {
        if (path === "/") query["widx"] = this.$route.query.widx;
        else if ("/leak-analysis" === path) {
          let rows = this.$refs.table_leakage_info.getSelectRows();
          if (rows.length <= 0) return;
          let lidx = [];
          for (let i = 0; i < rows.length; i++) lidx.push(rows[i].id);
          query["lidx"] = lidx;
          query["widx"] = this.$route.query.widx;
          this.$router.push({ path: path, query: query });
        }
      } else {
        query["widx"] = w_id;
        this.$router.push({ path: "/leak-detail", query: query });
        this.$router.go();
      }
    },

    onLeakMapClick(f) {
      //let leak_idx = this.$refs.map.leak_idx;
      this.clicked_l_idx =  f.get('leak_id');
      this.widx =   f.get('w_idx');
      this.clicked_w_type =  f.get('type');
       this.$refs.dlg_dataset.show( this.clicked_l_idx,  this.clicked_w_type )
      //LEAKAGE INFO값을 가져와서 FFT, WAV값을 뿌려준다.
      /*
      this.aurl = this.URL + "api/get_wav/" + leak_idx;

      this.wavesurfer.load(this.aurl);
      this.fft_url = this.URL + "api/get_fft/" + leak_idx + "#" + this.vchart;
      */
    },
    leaktable_refresh() {
      this.go_search() ;
      /*
      this.$refs.table_leakage_info.setUrl(
        this.URL + "api/get_leak_list_ex?w_idx=" + this.$route.query.widx,
        (rows) => {
          if (!rows || rows.length <= 0) return;
          this.clicked_l_idx = rows[0].leak_id;
          this.clicked_w_type = rows[0].work_type;
          this.onTitleChange(rows[0]);
          this.filename =
            "widx-" +
            this.$route.query.widx +
            "-lidx-" +
            this.clicked_l_idx +
            "~.xls";
          //this.aurl =this.URL+'api/get_wav/'+rows[0].idx;
          this.aurl = this.URL + "api/" + rows[0].file_path;
          this.wavesurfer.load(this.aurl);

          //  this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
          if (rows[0].fft_file_path !== null)
            this.fft_url =
              this.URL + "api/" + rows[0].fft_file_path + "#" + this.vchart;
          else
            this.fft_url =
              this.URL + "api/get_fft/" + rows[0].idx + "#" + this.vchart;
        }
      );
      */
    },
    onTitleChange(row) {
      this.t_start_time = row.measure_start_datetime;
      this.t_worker = row.m_id;
      this.t_device_no = row.device_sn;
      this.t_ai_result = row.ai_leak_result;
      this.t_leak_result = row.leak_result_;
      this.t_strength = row.wave_energy;
      this.t_strength_cal = row.wave_energy_cal;
      this.t_f_type = row.layer_type;
      this.t_remark = row.remarks;
    },
    onRowClick(params) {
      //선택된 색상 보여지게
      //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
      if (this.clicked_b_row != null) {
        this.clicked_b_row.bgColor = "";
        //  //  this.clicked_b_row.style.backgroundColor = '';
      }

      if (params.event.target.nodeName === "TD") {
        params.event.target.parentElement.bgColor = "#125744";
        this.clicked_b_row = params.event.target.parentElement;
      } else if (params.event.target.nodeName === "IMG") {
        params.event.target.parentElement.parentElement.parentElement.bgColor =
          "#125744";
        this.clicked_b_row =
          params.event.target.parentElement.parentElement.parentElement;
      } else {
        params.event.target.parentElement.parentElement.bgColor = "#125744";
        this.clicked_b_row = params.event.target.parentElement.parentElement;
      }
      this.spectrum_img = this.URL+'ai/image?file_id='+params.row.wave_file_id
      /*
     if(this.clicked_b_row != null){
       this.rowStyleClassFn(params.row, 1);
     }
      this.rowStyleClassFn(params.row, 0);
 */
      //  this.clicked_b_row = params.row
      this.slice_url1 = null;
      var audio = document.getElementById("slice_audio_id");
      audio.load();
      this.onTitleChange(params.row);
      this.clicked_l_idx = params.row.leak_id;
      this.widx =  params.row.water_work_id;
      this.clicked_w_type = params.row.work_type;
      document.getElementById("hidden-container").style.display = "none";
      this.aurl = this.URL + "api/" + params.row.file_path;
      this.wavesurfer.load(this.aurl);
      this.bandfilter = 0
      //this.fft_url = this.URL+"api/"+params.row.fft_path+'?'+this.vchart;
      if (params.row.fft_file_path !== null)
        this.fft_url =
          this.URL + "api/" + params.row.fft_file_path + "?outType=fftToJson#" + this.vchart;
      else
        this.fft_url =
          this.URL + "api/get_fft/" + params.row.id + "#" + this.vchart;
      this.wavesurfer.clearRegions();
      if (params.row.cut_position !== null) {
        this.wavesurfer.addRegion({
          start: params.row.cut_position, // time in seconds
          end: Number(params.row.cut_position) + 1, // time in seconds
          color: "hsla(200, 100%, 30%, 0.1)",
          drag: false,
          resize: false,
        });
      }
    },
    //초기화면 호출 
    init_extent_set(){
      this.$refs.map.get_extent("w_type=300&&w_idx=" + this.$route.query.widx).then((data) => {
          this.$refs.map.set_fit_extent(data);
          this.$refs.map.init_reload_leak_layer();
       });    
    },
    on_goto_map_click(row) {
      if(this.reload_set > 0){
        this.reload_set = 0 ;
        this.init_extent_set();
      }
       let s_url = "w_type=300&p_idx=" + row.idx + "&w_idx=" + this.$route.query.widx 
       //https://dev.neverlosewater.com/api/get_extent?w_type=104&p_idx=322

      // if(row.work_type === 104)
     //     s_url = "w_type="+row.work_type+"&p_idx=" + row.water_work_id ;         
      // else if(row.work_type === 102)       
      //    s_url = "w_type="+row.work_type+"&p_idx=" + row.water_work_id ;   

        this.$refs.map.get_extent(s_url).then((data) => {
          this.$refs.map.set_fit_extent(data);
          if (this.$store.setting.map_name === "Daum")
            this.$refs.map.map.getView().setZoom(13);
          else this.$refs.map.map.getView().setZoom(18);
        });
        this.$refs.map.select_facility_feature(300, "", row.idx);
    
    },
    play() {
      if (!this.is_playing) {
        this.wavesurfer.play();
        this.is_playing = true;
      } else {
        this.is_playing = false;
        this.wavesurfer.pause();
      }
    },
    stop() {
      this.is_playing = false;
      this.wavesurfer.stop();
    },
    window_resiz() {
      this.contents_height =
        window.innerHeight - this.$refs.navbar.clientHeight - 50;
      this.table_height = this.contents_height - 320
      this.map_height = this.contents_height / 2;
      this.fftchart_height = this.map_height / 2;
      this.waveform_height = this.fftchart_height - 100;
      
      this.$refs.map.update_size();
    },
    set_map_gisload() {
      this.set_info();
      let map = this.$refs.map.map;
      var size1 = map.getSize();
      //var size = [size1[0] * 2, size1[1] * 2];
      var size = [size1[0] , size1[1] ];
      var cz = this.$store.setting.map_name === "Daum" ? 9 : 15;
    //  let epsg =
    //    this.$store.setting.map_name === "Daum" ? "EPSG:5181" : "EPSG:3857";
      var extent = map.getView().calculateExtent(size);

      if (this.$refs.map.map.getView().getZoom() < cz) {
        alert(this.$t("home.alert_zoom_in"));
      } else {
        // 추가 되었던 소스 가져와서 삭제하기
        // heat layer 삭제

        var h_layer = this.$refs.map.heat_layer;
        if (h_layer != null) {
          var source_h = h_layer.getSource();
          var hfeatures = source_h.getFeatures();
          for (var k = 0; k < hfeatures.length; k++) {
            source_h.removeFeature(hfeatures[k]);
          }
          map.removeLayer(h_layer);
          h_layer = null;
        }
        // remove_layer(map);
        for (let i = 0; i < this.$refs.map.layer_list.length; i++) {
          var layer = this.$refs.map.layer_list[i];
          if (layer === null || layer.values_ === null) continue;
          var source1 = layer.getSource();
          var features = source1.getFeatures();
          for (var j = 0; j < features.length; j++) {
            source1.removeFeature(features[j]);
          }
          map.removeLayer(layer);
        }
        this.$refs.map.layer_list = [];
        for (let i = 0;i < this.$refs.map.layer_setting_stlye_list.length; i++ ) {
          var params = this.$refs.map.layer_setting_stlye_list[i];
          // var nstyle = params.style;
          var _url = params.surl;
          // if(params.code >50 || layer.get('code')=== undefined ) continue

          //var lftaye = params.f_type;
          var s_url = _url.split('?')
          _url =
            s_url[0] +"?call_type=1&layer="+params.code ;
          //  "bbox=" +
          //  extent.join(",") +
          //  "&srid=" +
          //  epsg +
          //  "&f_type=" +
          //  lftaye;
          params.surl = _url ;
          var newlayer = CreateVectorLayer_f(params, extent);
          var vis = params.visible;
          newlayer.setVisible(vis);
          this.$refs.map.layer_list.push(newlayer);
          map.addLayer(newlayer);
        } //for문 끝
        this.$refs.map.init_draw_modify();
      } // zoom크기에 대한 if
    },
    gis_reload() {
      this.set_info();
      // this.$refs.map.init_draw();
      if (this.$store.setting.map_name === "Daum")
        this.$refs.map.map.getView().setZoom(9);
      else this.$refs.map.map.getView().setZoom(15);
      this.set_map_gisload();
    },
    get_gisinfo() {
      this.$refs.map.block_info = 1;
      this.set_toggle_visible_all_layer();
      // this.$refs.map.init_draw();
      this.$refs.map.edit_change("add", "Block");
      // this.$refs.map.edit_start('add', 'Block') ;
    },
    set_toggle_visible_all_layer() {
      this.layer_all_visible = !this.layer_all_visible;
      let map = this.$refs.map.map;
      for (let i = 1; i < map.getLayers().getLength() - 1; i++) {
        let layer = map.getLayers().item(i);
        if (layer.get("code") > 50) continue;
        layer.setVisible(this.layer_all_visible);
      }
    },
    set_info() {
      this.$refs.map.edit_cancel();
      this.$refs.map.select_info = true;
    },
    on_leakage_info(row) {
       this.$refs.dlg_dataset.show(row.leak_id,row.work_type)
      //if(this.clicked_w_type === 100 || this.clicked_w_type === '100')
      //this.$refs.map.show_leakage_info(row.leak_id);
       
    },
    get_ai_result(row){
        // this.loading = true     
        this.$http.get(this.URL+'api/set_leak_AI_data_new?idx='+row.idx).then((res) => {
          if(res.data.check ===1){ 
            // this.loading = false
            return;
          }
          alert("분류결과 : "+ res.data.ai_result+", 확률 : " + res.data.probability ) ;
          this.leaktable_refresh();
           // this.ai_result = res.data.msg ;
           // this.loading = false 
        });
           
    },
    go_search() {
      // if (this.value === undefined) return;
      // this.customer_check
      this.box = [];
      if (this.checkedNames.length === 0 || this.checkedNames.length === 2) {
        this.checkedNames = [];
      }
  
      this.$refs.table_leakage_info.setUrl(
        this.URL +
          "api/dataset?widx="+this.ds_id+"&customer_id=" +this.customer_check 
          /*
          +"&search=" 
          +this.value 
          + "&leak_result=" +
          this.checkedNames,
          */
          ,(rows) => {
          console.log(rows);
          if (!rows || rows.length <= 0) return;
          this.clicked_l_idx = rows[0].id;
          this.clicked_w_type = rows[0].work_type;
          this.onTitleChange(rows[0]);
          this.aurl = this.URL + "api/" + rows[0].file_path;
          //  this.avwaveform.load(aurl)
          this.wavesurfer.load(this.aurl);
           this.bandfilter = 0
          // this.fft_url = this.URL+'api/'+rows[0].fft_file_path+'?'+this.vchart;
          if (rows[0].fft_file_path !== null)
            this.fft_url =
              this.URL + "api/" + rows[0].fft_file_path + "?outType=fftToJson#" + this.vchart;
          else
            this.fft_url =
              this.URL + "api/get_fft/" + rows[0].idx + "#" + this.vchart;
        }
      );
    },
    Enter_Check() {
      //  if(event.keyCode == 13){
      this.go_search(); // 실행할 이벤트
    },

    wav_getTime(prog) {
      //alert("audio prog :"+ prog)
      var w_lenght = Math.round(this.wavesurfer.getDuration());
      var intval = 2048 * Math.pow(2, w_lenght);

      let starttime = prog * this.wavesurfer.getDuration() - 0.5;
      let endtime = prog * this.wavesurfer.getDuration() + 0.5;
      this.s_time = starttime;

      var st = Math.round((intval * starttime) / this.wavesurfer.getDuration());
      var et = Math.round((intval * endtime) / this.wavesurfer.getDuration());
      this.delay = 1000;
      var dataJSON = this.wavesurfer.backend.getPeaks(intval / 2); //start,end를 지정할 수 있다. 24000
      // var sp = dataJSON.splice(st,et-st)
      var sp = dataJSON.splice(st, 2048);
      // sp = JSON.stringify(sp);
      intval = sp.length / (et - st);
      // var duration = endtime-starttime
      //dev엔 적용
      //var buf  = null
      // create a new buffer to hold the new clip
      //  var buffer = this.createBuffer(this.wavesurfer.backend.buffer, duration)
      // copy
      //   var buf = this.copyBuffer(this.wavesurfer.backend.buffer, starttime, endtime, buffer, 0)

      //this.$refs.dlg_wave_slice_chart.show(this.clicked_l_idx,sp,buf,intval,starttime,endtime);
    },
    //20210427
    onZoomClickEvent() {
      var s_min = this.$refs.fftchart.select_min; //09-03 이렇게 들어온다
      var s_max = this.$refs.fftchart.select_max; //09-03 이렇게 들어온다
      //  this.min_value = s_min;
      //  this.max_value = s_max;
      //this.aurl = this.URL + "api/" + rows[0].file_path;
      //this.clicked_l_id
     // setTimeout(() => {
        var file_path = null
        if(this.aurl.indexOf('api') !== -1){
          file_path = this.aurl.split('/API21/FILE/')
        }else{
          return
        }

        this.slice_url = this.URL + "api/get_wave_slice_new?idx=" + file_path[1] +"&w_type="+this.clicked_w_type+
          "&start_pos=" +
          s_min +
          "&end_pos=" +
          s_max +
          "&y_zoom=" +
          this.szoom;
    //  }, 200);
      // this.slice_url = 'http://dev.neverlosewater.com/node/api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
    },
    onZoomClickEvent1() {
        var s_min = this.$refs.fftchart.select_min; //09-03 이렇게 들어온다
      var s_max = this.$refs.fftchart.select_max; //09-03 이렇게 들어온다
      //  this.min_value = s_min;
      //  this.max_value = s_max;
      //this.aurl = this.URL + "api/" + rows[0].file_path;
      //this.clicked_l_id
      //var file_path = this.aurl.split('/api/API21/FILE/')

      this.slice_url = this.URL + "api/get_wave_slice?idx="+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
    //  var s_min = this.$refs.fftchart1.select_min; //09-03 이렇게 들어온다
     // var s_max = this.$refs.fftchart1.select_max; //09-03 이렇게 들어온다
      //  this.min_value = s_min;
      //  this.max_value = s_max;
      /*
      this.slice_url =
        this.URL +
        "api/get_wave_slice?idx=" +
        this.clicked_l_idx +"&w_type="+this.clicked_w_type+
        "&start_pos=" +
        s_min +
        "&end_pos=" +
        s_max +
        "&y_zoom=" +
        this.szoom;
        */
      // this.slice_url = 'http://dev.neverlosewater.com/node/api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
    },
  },
  
  created() {
    this.$EventBus.$on("sidebar_resize", () => {
      setTimeout(() => {
        this.$refs.fftchart.redraw();
        if (this.clicked_l_idx > -1) {
          //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
          this.wavesurfer.empty();
          this.wavesurfer.load(this.aurl);
        }
      }, 500);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.window_resiz);
  },
  mounted() {
    window.addEventListener("resize", this.window_resiz);
    this.window_resiz();
    setTimeout(() => {
      this.toggle_visible_all_layer();
    }, 1000);
    //삭제버튼 활성화 여부
    // if(localStorage !== null && typeof (localStorage) !== undefined && localStorage.level === 'A'){
    if (
      sessionStorage !== null &&
      typeof sessionStorage !== undefined &&
      sessionStorage.level < 2
    ) {
      this.levelA = true;
    } else {
      this.levelA = false;
    }
    this.wavesurfer = WaveSurfer.create({
      container: "#waveform",
      waveColor: "#638CFB",
      progressColor: "blue",
      mediaControls: true,
      backend: "MediaElement",
      normalize: true,
      scrollParent: true,
      //waveColor: '#D2EDD4',
      //progressColor: '#46B54D',
      //   barWidth: 0.5,
      //   barHeight: 4, // the height of the wave
      //minPxPerSec : 300,  //오디오 파일의 1초당 렌더링 될 픽셀 수의 최소값
      //   autoCenter: true,
      height: this.waveform_height,
      //   width: 600,
      plugins: [
        TimelinePlugin.create({
          container: "#wave-timeline",
        }),
        Regions.create({
          regionsMinLength: 1,
          regions: [
            {
              start: this.region_start,
              end: this.region_end,
              loop: false,
              // 2022 new
              // color: 'hsla(400, 100%, 30%, 0.5)',
              color: "#7e84a3",
              drag: false,
              resize: false,
            },
          ],
        }),
      ],
    });

    this.wavesurfer.on("finish", () => {
      this.is_playing = false;
    });

    this.wavesurfer.on("loading", function () {
      document.getElementById("hidden-container").style.display = "";
      //document.querySelector('audio');
      var mediaElt1 = document.getElementById("waveform");
      var mediaElt = mediaElt1.querySelector("audio");

      // mediaElt = document.getElementById("waveform");
      mediaElt.style =
        "width:39%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-21px;border:0; outline:0;";
    });

    this.wavesurfer.drawer.on("click", (event, progress) => {
      // this.wavesurfer.backend.seekTo(progress);
      this.wav_getTime(progress);
    });
    //customer_list를 가져온다
   this.$http.get(this.URL+'api/dataset/get_customer_list?widx='+this.ds_id).then((res) => { 
         if((!res)||(res.length<=0)) return;
         this.customer_name = res.data.list           
        
   });
    //this.$refs.table_leakage_info.setUrl( this.URL + "api/get_leak_list_ex?w_idx=" + this.$route.query.widx,(rows) => {
      this.$refs.table_leakage_info.setUrl( this.URL + "api/dataset?widx="+this.ds_id,(rows) => {
        console.log(rows);

        if (!rows || rows.length <= 0) return;
        this.clicked_l_idx = rows[0].leak_id;
        this.onTitleChange(rows[0]);
        this.filename =
          "widx-" +
          this.$route.query.widx +
          "-lidx-" +
          this.clicked_l_idx +
          "~.xls";
        // this.aurl = this.URL+'api/get_wav/'+rows[0].idx
        this.aurl = this.URL + "api/" + rows[0].file_path;
        //  this.avwaveform.load(aurl)
        this.wavesurfer.load(this.aurl);
        //  this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
        if (rows[0].fft_file_path !== null)
          this.fft_url =
            this.URL + "api/" + rows[0].fft_file_path + "?outType=fftToJson#" + this.vchart;
        else
          this.fft_url =
            this.URL + "api/get_fft/" + rows[0].id + "#" + this.vchart;
        this.bandfilter = 0
        this.wavesurfer.clearRegions();
        if (rows[0].cut_position !== null) {
          this.wavesurfer.addRegion({
            start: rows[0].cut_position, // time in seconds
            end: Number(rows[0].cut_position) + 1, // time in seconds
            color: "hsla(200, 100%, 30%, 0.1)",
            drag: false,
            resize: false,
          });
        }
      }
    );

    setTimeout(() => {
      var elem = document.getElementsByClassName(
        "page-wrapper chiller-theme toggled"
      );
      if (elem !== undefined) {
        if (elem.length > 0) {
          document
            .getElementsByClassName("un-toggle-menu-btn-tri-off")[0]
            .click();

          if (this.clicked_l_idx > -1) {
            //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
            this.wavesurfer.empty();
            this.wavesurfer.load(this.aurl);
            this.$refs.fftchart.redraw();
          }
        }
      }
    }, 1000);
  },
  components: {
    pressChartData_fft: pressChartData_fft,
    ServerSideTable: ServerSideTable,
    Map: Map,
    DlgLeakDataset: DlgLeakDataset,
    DlgWavSliceChart: DlgWavSliceChart,
    DlgWorkList: DlgWorkList,
  },
};
</script>

<style lang="scss" scoped>
/*
    #waveform{
          background: rgba(0,0,0,0.8);
    }
*/
#waveform .audio {
  all: none;
  width: 300px;
  position: absolute;
  z-index: 1;
}

table.type01 td span { display:inline-block; }
.inner-bg {
  float: left;
  /* 공통 속성값 지정 */

  display: inline-block;
  /* 인라인요소를 인라인블록 요소로 */
  height: 30px;
  /* 이미지 공간을 확보하기 위해 높이값 지정 */

  background: #c0c0c0;
  padding-left: 10px;
  padding-right: 10px;
}

.content-area {
  padding: 0 30px;
}
.leak-detail-content {
  // 2022 new
  // background: #fff;
  background: #172a46;
  border: 1px solid #172a46;
  padding: 10px 30px 0;
  color: #fff;
}
.leak-detail-content-map {
  padding: 0;
  bottom: 0;
}

.left .list_box.list_box2.dataset {
  min-height: 655px;
  height: calc(95vh - 50px - 50px - 120px - 30px);
  // 2022 new
  background: #172a46;
  color: #fff;
}
.table-button-group {
  margin: 10px 0 10px 0;
}
.table-button-group .btn-lk-middle {
  width: 100%;
}
.left.leak-detail {
  height: auto;
}
table.type01 th {
  padding: 12px 0;
  background-color: aqua;
}
table.vgt-table td,th span {
  padding: 2px 0;
  letter-spacing: -1px;
  display: inline-block;

}
.btn-default {
  color: #fff;
}
.btn-default :hover {
  color: #000;
}
i {
  font-style: normal;
}
.input_search_box.leak {
  margin-top: 0px;
}
.input_search_box .input_submit .search {
  top: -25px;
  right: 50px;
}
.custom-radio.b-custom-control-sm {
  margin-left: 20px;
}
.search_div_leak_dataset {
  position: absolute;
  left: 220px;
  top: 5px;
  z-index: 110;
}
.spn1 {
  float: left;
}

table.type01 td .text-right {
  font-size: 11px;
}
.fftchart .highcharts-container .highcharts-root {
  width: 98%;
}
.left.leak-detail.dataset {
  width: 45%;
}
// 2023
 .right.leak-detail.dataset {
   width: 54%;
 }

</style>

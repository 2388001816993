<template>
  <div class="left_area">
    <div class="left_list_box">
      <div class="input_search_box" >
        <input type="text" class="input_search" v-model="value"   placeholder="Search..."  @keyup.enter ="Enter_Check">
        <input type="submit" class="input_submit" @click="$emit('go_search',  value)" >
      </div>
      <div class="list_box_wrap" style="transform: translateZ(0);-webkit-transform: translateZ(0);">
        <div :class="['box_list box_list02', { 'is--active': activeCheckIndex === index }]" v-for="(item, index) in box" :key="index" @click="selectBox(index)">
          <div class="con_box">
            <p class="title">{{ item.name }}</p>
            <span class="unit">water flow rate : {{ item.out_flow_day }} ㎥/hr</span><br/>
            <span class="unit">{{ item.last_date }} </span>
            <div :class="['status_box', statusColor(item)]"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchLeftType32',
  data() {
    return {
      value: ''
    }
  },
  props: {
    activeCheckIndex: {
      type: null
    },
    box: {
      type: Array
    }
  },
  methods: {
    statusColor(item) {
      return item.status === 'red' ? 'status_02': 'status_01'
    },
    selectBox (index) {
      this.$emit('updateCheckIndex', index)

    },
    Enter_Check(){
       if(event.keyCode == 13){
          this.$emit('go_search',  this.value) ;  // 실행할 이벤트
       }
    },
  }
};
</script>

<style lang="scss" scoped>
.detail__work-title {
  font-size: 13px;
  margin-top: 1%;
  margin-bottom: 2%;
  &:before {
    content: '●';
    font-size: 10px;
    padding-right: 5px;
  }
}
</style>

<template>
  <div>
    <!-- top-bar -->
    <div class="navbar-custom">
          <h4 class="content-title">{{$t('leak_monitoring.m_title')}}</h4>
           <ul class="list-unstyled topnav-menu topnav-menu-left">
    <!--            <li>-->
    <!--                <ol class="breadcrumb">-->
    <!--                  <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>-->
    <!--                  <li class="breadcrumb-item active">{{$t('leak_monitoring.m_title')}}</li>-->
    <!--                </ol>-->
    <!--            </li>-->

    <!--            <li class="setting-btn">-->
    <!--                        <b-button size="sm" class="float-right" @click="show_setting">
                                  <i class="fas fa-cog"></i>-->
    <!--                        </b-button>-->
    <!--            </li>-->
    <!--            -->
           </ul>
    </div>
    <!-- tom-bar-end -->
    <div class="monitoring_box">
      <!-- 2022 new -->
      <!--      Headers start-->
      <div class="content-header">
        <div class="d-flex align-items-center">
          <div class="item-total">
            <span>{{ $t("leak_monitoring.tot_m2_leak") }}</span>
            <strong>{{ tot_block_length }}</strong>
          </div>
          <div
            class="status d-flex align-items-center ml-4"
            style="position: relative; top: 0px"
          >
            <div class="mr-1">
              <input
                type="radio"
                id="radio_01"
                name="radio01"
                v-model="leak_type"
                value="L"
              />
              <label for="radio_01">
                {{ $t("leak_monitoring.search_leak") }}
              </label>
            </div>
            <div class="mr-1">
              <input
                type="radio"
                id="radio_02"
                name="radio01"
                checked
                v-model="leak_type"
                value=""
              />
              <label for="radio_02">
                {{ $t("leak_monitoring.search_all") }}
              </label>
            </div>
            <!--
            <div>
              <input
                type="checkbox"
                style="display: none"
                id="check_01"
                name="check_01"
                v-model="sort_strength"
              />
              <label class="btn-grayLine" style="border: 1px solid #707070">
                {{ $t("leak_monitoring.sort_streng") }}
              </label>
            </div>
            -->
            <div style="border-radius: 10px; margin-left: 5px;">
              <input type="checkbox" id="check_01" name="check_01"  v-model="sort_strength" value="sork_key" style=""/>
              <label for="check_01" class="btn-grayLine" style="border: 1px solid #707070;padding:5px 10px;"> {{ $t("leak_monitoring.sort_streng") }}</label></div>    
          </div>
          <div>
              <b-select
                    size="sm"
                    v-model="status"
                    :options="f_status_option"
                    style="width: 100px; padding: 0 10px"
                  ></b-select>
            </div>
        </div>

        <div class="item-right">
          <div class="ns-search-box">
            <input
              type="text"
              class="ns-search-input"
              v-model="value"
              placeholder="Search..."
              @keyup.enter="Enter_Check"
            />
            <button type="button" class="ns-search-button" @click="go_search" />
          </div>
        </div>
      </div>
      <!-- Headers end -->
      <!--      content-->

      <!--<div class="monitoring_wrap">-->
      <!--  <div class="input_search_box">-->
      <!--    <input type="text" class="input_search"  v-model="value" placeholder="Search..."  @keyup.enter ="Enter_Check">-->
      <!--    <input type="submit" class="input_submit" @click="go_search" > -->
      <!--  </div>-->
      <!--  <div class="info_box1"  style="float: right;padding: 25px 30px 0 0 ;">              -->
      <!--  <ul >-->
      <!--      <li style="background:none;"> -->
      <!--          <span > <input type="checkbox" id="chk_01" > Leak </span>-->
      <!--          <span > <input type="checkbox" id="chk_02" > ALL </span-->
      <!--        <span class="status m1-map" style="position: relative; right: 100%;top:0px;">-->
      <!--                 -->
      <!--                    <input type="radio" id="radio_01" name="radio01"  v-model="leak_type" value="leak">-->
      <!--                    <label for="radio_01">{{$t('leak_monitoring.search_leak')}}</label>-->
      <!--                    <input type="radio" id="radio_02" name="radio01"  checked v-model="leak_type" value="">-->
      <!--                    <label for="radio_02">{{$t('leak_monitoring.search_all')}}</label>      -->
      <!--                    <input type="checkbox" id="check_01" name="check_01"  v-model="sort_strength" />-->
      <!--                    <label >{{$t('leak_monitoring.sort_streng')}}</label>           -->
      <!--        </span>   -->
      <!--         <span style="float: right;right: 20px;">{{$t('leak_monitoring.tot_m2_leak')}} : {{tot_block_length}}</span>-->
      <!--      </li>-->
      <!--    </ul>         -->
      <!--   -->
      <!--  </div>-->
      <!--</div>-->

      <!-- <div class="table_wrap leak-monitoring" :key="listKey">
          <fragment v-for="item in ts_monitoring_list" :key="item.id">
            <MonitoringCard :dma_name="item.device_sn" :tag="item.id" @go_m2_info="go_m2_info"  >
            </MonitoringCard>
          </fragment>
        </div> -->
      <!--      content-->

      <!-- 2023 start-------------------- -->
      <div class="monitoring-content mt-3" :key="listKey">
        <ul class="monitoring-list row row-cols-3">
          <!--          <li class="col" v-for="item in ts_monitoring_list" :key="item.block_id">-->
          <!--            <MonitoringCard :dma_name="item.point_name"-->
          <!--                            :last_date_time="item.last_measure_datetime" :tag="item.id"-->
          <!--                            :now_flow="item.water_flow">-->
          <!--            </MonitoringCard>-->
          <!--          </li>-->
          <fragment v-for="item in ts_monitoring_list" :key="item.id">
            <li class="col">
              <MonitoringCard
                :dma_name="item.device_sn"
                :tag="item.id"
                @go_m2_info="go_m2_info"
              >
              </MonitoringCard>
            </li>
          </fragment>
        </ul>
      </div>
    <!-- 2023 end------------------- -->    
    </div>
    <b-modal ref="modal" title="Settings" @ok="handleOk">
      <vue-good-table
        ref="flow_tag_table"
        :columns="columns"
        :rows="ts_flow_tag_list"
        max-height="200px"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          disableSelectInfo: true,
        }"
      />
      <b-button @click="add_monitoring">Add</b-button>
      <vue-good-table
        ref="ts_monitoring_table"
        :columns="columns"
        :rows="temp_ts_monitoring_list"
        max-height="200px"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          disableSelectInfo: true,
        }"
      />
      <b-button @click="del_monitoring">Delete</b-button>
    </b-modal>
    <DlgM2MoniSetting ref="m2_setting" />
  </div>
</template>

<script>
import MonitoringCard from "@/components/card/MonitoringLeakCard";
import { Fragment } from "vue-fragment";
import DlgM2MoniSetting from "@/components/popup/DlgM2MoniSetting";

export default {
  name: "LeakMonitoring",
  props: [],
  watch: {
    leak_type() {
      this.go_search(); // 실행할 이벤트
    },
    sort_strength() {
      if (this.sort_strength) this.strength_sort_yn = "strength";
      else this.strength_sort_yn = "";
      this.go_search(); // 실행할 이벤트
    },
    type() {
      this.go_search();
    },
    status() {
      this.go_search();
    },
  },
  data() {
    return {
      columns: [
        { label: "Tag", field: "tag" },
        { label: "Name", field: "name" },
      ],
      ts_monitoring_list: [],
      ts_flow_tag_list: [],
      temp_ts_monitoring_list: [],
      value: "",
      listKey: 0,
      tot_block_length: 0,
      s_block_length: 0,
      leak_type: "",
      sort_strength: "",
      strength_sort_yn: "",
      status: "A",
      f_status_option: [
        { value: "", text: "All" },
        { value: "A", text: "Active" },
        { value: "I", text: "Inactive" },
      ],
    };
  },
  methods: {
    show_setting() {
      this.temp_ts_monitoring_list = [...this.ts_monitoring_list];
      this.$refs.modal.show();
    },
    updateKey: function () {
      this.listKey += 1;
    },
    go_m2_info(m2_point_id) {
      this.$refs.m2_setting.show(m2_point_id);
    },
    handleOk() {
      this.ts_monitoring_list = [...this.temp_ts_monitoring_list];
      let param = [];
      for (let i = 0; i < this.ts_monitoring_list.length; i++)
        param.push(this.ts_monitoring_list[i].tag);
      this.$http.get(this.URL + "api/set_monitoring_list?list=" + param);
    },
    add_monitoring() {
      let rows = this.$refs.flow_tag_table.selectedRows;
      if (rows.length <= 0) return;
      for (let i = 0; i < rows.length; i++) {
        this.temp_ts_monitoring_list.push({
          tag: rows[i].tag,
          name: rows[i].name,
        });
      }
      this.del_dup_list(this.temp_ts_monitoring_list, this.ts_flow_tag_list);
    },
    del_monitoring() {
      let rows = this.$refs.ts_monitoring_table.selectedRows;
      if (rows.length <= 0) return;
      for (let i = 0; i < rows.length; i++) {
        this.ts_flow_tag_list.push({ tag: rows[i].tag, name: rows[i].name });
      }
      this.del_dup_list(this.ts_flow_tag_list, this.temp_ts_monitoring_list);
    },
    del_dup_list(a, b) {
      for (let i = 0; i < a.length; i++) {
        let idx = b.findIndex((obj) => obj.tag == a[i].tag);
        if (idx <= -1) continue;
        b.splice(idx, 1);
      }
    },
    go_search() {
      if (this.value === undefined) return;
      this.updateKey();
      this.ts_monitoring_list = [];
      var customer_id = sessionStorage.select_customer_id;
      if (sessionStorage.select_customer_id === "27") {
        customer_id = 6;
      }
      //this.$http.get(this.URL+'api/API21/m2_leak/list?customer_id='+sessionStorage.select_customer_id+'&search='+this.value+'&type='+this.leak_type+'&sort='+this.strength_sort_yn).then((res) => {
      this.$http
        .get(
          this.URL +
            "api/API21/m2_leak/list?customer_id=" +
            customer_id +
            "&search=" +
            this.value +
            "&type=" +
            this.leak_type +
            "&sort=" +
            this.strength_sort_yn +"&to="+this.status
        )
        .then((res) => {
          this.ts_monitoring_list = res.data.results[0];
          this.tot_block_length = res.data.results[0].length;
        });
    },
    Enter_Check() {
      //  if(event.keyCode == 13){
      this.go_search(); // 실행할 이벤트
    },
  },
  mounted() {
    //  this.$http.get(this.URL+'api/get_monitoring_list').then((res) => {
    var customer_id = sessionStorage.select_customer_id;
    if (sessionStorage.select_customer_id === "27") {
      customer_id = 6;
    }

    // this.$http.get(this.URL+'api/API21/m2_leak/list?customer_id='+sessionStorage.select_customer_id).then((res) => {
    this.$http
      .get(this.URL + "api/API21/m2_leak/list?to=A&customer_id=" + customer_id)
      .then((res) => {
        this.ts_monitoring_list = res.data.results[0];
        this.tot_block_length = this.ts_monitoring_list.length;
        this.s_block_length = this.ts_monitoring_list.length;
      });
  },
  beforeDestroy() {},
  components: {
    MonitoringCard,
    Fragment,
    DlgM2MoniSetting: DlgM2MoniSetting,
  },
};
</script>

<style scoped lang="scss">
// 2022 new 추가
.monitoring-content {
  background-color: none;
  padding: 0 0 30px;
  margin-top: 0px;
}
// .item-total{
//    height: 4px;
//     width: 200px;
//     position: relative;
//     margin: 10px;
// }

.row {
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.col {
  padding-right: 8px;
  padding-left: 8px;
}
</style>

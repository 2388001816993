<template>
  <highcharts id= "wavchart"  class="wavchart" :options="chartOptions"></highcharts>
</template>

<script >
//import 'highcharts/css/highcharts.css';
export default {
  name: 'pressChart1',
   props:{

      chartData: {
        type: Array
      },
       chartData1: {
        type: Array
      },
      interval : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
       sdate : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
       ymin : {
    
      },
      y_title : { type:String},
      chart_type : { type:String}
  },
  data () {
    return {
  
       chartOptions: {
         background : "#172a46",
         colorAxis:[{lineColor:"#434348"}],
         colors : ["#5580b4","#c85b6d","#f3ae1a","#FF0000"],
         credits: {
          text: '',
          href: ''
        },

        series: [{
          name: 'Pressure',
          showInLegend: true,
          followPointer: true,
         // data: [10, 20, 10, 23, 65, 121, 44, 66, 98, 30, 32, 56, 25, 12, 53],
          data: this.chartData,
           type:this.chart_type,
           lineWidth: 1.0,
            yAxis: 0,
         // pointStart: Date.UTC(2018, 1, 1,0,0,0,0),
         // pointStart: this.sdate,
        //  pointInterval: 100,
        //  pointInterval: this.interval, //
         //  pointInterval: 4000/20480, //
          
        },
        {
           name: 'Temp.(℃)',
           showInLegend: true,
           data: this.chartData1,
         //  type:this.chart_type,
           lineWidth: 1.0,
           yAxis: 1,
        }],
          tooltip: {
            valueDecimals: 2,
            shared: true,
            crosshairs: true
          },
           boost: { enabled: true },
          //dateTimeLabelFormats:{millisecond : {main:"%S.%L"}},
            plotOptions: {
                  area: {
                      fillColor: {
                        linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1
                        },
                        stops: [
                          [0,'#d6e8f9'],
                          [1,'#b5d6f5']
                        ]
                      },
                 },
            
        
        
          },
       // }],      
         title: {        text: '', },
         chart: {   zoomType: 'xy' ,
              //   styledMode: true,
                
                },
         /*
         tooltip: {
                formatter: function() {
                        return '<b>'+ this.series.name +'</b><br/>'+
                        this.dateFormat('%Y-%m-%d %H:%M:%S', this.x) +'<br/>'+
                        this.numberFormat(this.y, 2);
                }
            },
            */
        //yAxis : {title:{text:''}},   
        
          yAxis: [{ // left y axis
              min : this.ymin,
              gridLineColor: '#fff',
              gridLineWidth:  1,  
               gridZIndex: 3,            
              title: {
                 // text: this.y_title     
                text:'Pressure',  
                margin : 30,
                style : {fontSize: '1.0em',color: '#c6c6c6'}
              },
              className: 'highcharts-color-0',
              labels: {
                  align: 'left',
                   x: -20,
                   y: 4,
                  format: '{value:.,0f}'
              },
              showFirstLabel: false
          }, { // right y axis
            //  linkedTo: 1,
              gridLineWidth: 0,
             // max: 35,
             // min:10,              
              //tickInterval: 5,
              alignTicks: false,
              opposite: true,
              title: {
                  text: ''
              },
              labels: {
                align: 'right',
                x: 10,
                y: 0,
                format: '{value:.,0f}'+ '°C',
                style: { color: '#FF0000' }  
              },
              showFirstLabel: false
          }],
        xAxis: { type: 'datetime',
            /*
                labels: {
                formatter: function() {
                  return Highcharts.dateFormat('Year:%y <br/> Month:%e<br /> %H:%M:%S:%L', this.value);

               
                },
              }  
              */
              },
       exporting: {
          buttons: { contextButton: { enabled: false} }
       },    
       legend: {
                align: 'left',
                verticalAlign: 'top',
                borderWidth: 0,
                itemStyle : {"color" : '#c0c0c0'},
                itemHoverStyle: {"color" : '#fff'},
            },       
    },

    
       
    }
  }
}
</script>


<style scoped >

.wavchart {
  min-width: 450px;
  max-width: 1600px;
  width:100%;
 
  margin: 0 auto;
}

</style>
<template>
    <div class="leak-chart">
        <!-- top-bar -->
       <div ref="navbar" class="navbar-custom">
            <h4 class="content-title">WATER LEAK</h4>
           <ul class="list-unstyled topnav-menu topnav-menu-left">
<!--                <li>-->
<!--                    <ol class="breadcrumb">-->
<!--                        <li class="breadcrumb-title">Water Leak</li>-->
<!--                        <li class="breadcrumb-bar">|</li>-->
<!--                        <li class="breadcrumb-item"><a @click="$router.push('/')" href="#">HOME</a></li>-->
<!--                        <li class="breadcrumb-item active">WATER LEAK</li>-->
<!--                    </ol>-->
<!--                </li>-->
           </ul>
       </div>
        <!-- tom-bar-end -->

        <b-row class="leak-chart-content--full">
            <b-col>
                <div style="height: 615px;background-color:#fff; ">

                      <LeakTypeBarChart :url="bar_url" ref="barchart" id="barchart" :height="barchart_height" style="position:absolute;width:98%;"   />

                </div>

            </b-col>
        </b-row>


    </div>
</template>

<script>


import LeakTypeBarChart from '@/components/chart/LeakTypeBarChart' ;


export default {
    name: 'LeakMaster1',
    props: {
    },
   watch: {


    },
    data() {
        return {
            contents_height: 900,
            map_height: 450,
            fftchart_height : 420,
            barchart_height : 450,
            table_height: 500,
            fft_url: '',
            clicked_l_idx : -1,
            bar_url : '',
            json_data :[],
            columns: [
                {label: 'No', field: 'idx'},
                {label: 'Title', field: 'w_title'},
                {label: 'Contents', field: 'w_contents'},
                {label: 'Worker', field: 'worker'},
                {label: 'Start Time', field: 'w_start_time'},
                {label: 'End Time', field: 'w_end_time'},
                {label: 'Count', field: 'leak_count'}
            ],
            json_fields:{
                'No': 'idx',
                'Title': 'w_title',
                'Contents': 'w_contents',
                'Worker': 'worker',
                'Start Time': 'w_start_time',
                'End Time': 'w_end_time',
                'Count': 'leak_count'
            },
            adv_search: [
                {
                    field: 'lf_type',
                    control: 'select',
                    value: null,
                    options: [
                        { value: null, text: 'LF type' },
                        { value: '0', text: 'Pipe' },
                        { value: '1', text: 'Valve' },
                        { value: '2', text: 'Water Meter' },
                        { value: '4', text: 'Hydrant'},
                        { value: '13', text: 'Other'},
                    ]
                },
                {field: 'start_time', control: 'date', value: null, value2: null, placeholder: 'From date', placeholder2: 'To date'},
                {field: 'o_max_frequency', control: 'range', value: null, value2: null, placeholder: 'From frequency', placeholder2: 'To frequency'},
                {field: 'f_block_idx', control: 'select', value: null, options: [{ value: null, text: 'Block name' }]},
            ]
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.window_resiz)
    },
    mounted () {
        window.addEventListener('resize', this.window_resiz)
        this.window_resiz()
        this.bar_url = this.URL+'api/get_work_bar_chart?w_type=100&customer_id='+sessionStorage.select_customer_id ;


    },
    methods: {
        window_resiz() {
            this.contents_height = window.innerHeight - 210
            //this.table_height = this.contents_height/2
            //this.map_height = this.table_height

            this.map_height = this.contents_height/2
            this.table_height = this.contents_height/1.8


        },
        /*
        callLink() {
            var activePoint = this.$refs.barchart.getElementAtEvent(event);

            // make sure click was on an actual point
            if (activePoint.length > 0) {
                var clickedDatasetIndex = activePoint[0]._datasetIndex;
                var clickedElementindex = activePoint[0]._index;
                var label = this.$refs.barchart.data.labels[clickedElementindex];
                var value = this.$refs.barchart.data.datasets[clickedDatasetIndex].data[clickedElementindex];
                alert("Clicked: " + label + " - " + value);
            }
        },
        */
        go_detail() {
            let rows = this.$refs.table_workinfo.getSelectRows()
            if(rows.length<=0) return;
            let list = []
            for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
            this.$router.push({path: '/leak-detail', query: { widx: list }})
        },
        go_download() {
            let rows = this.$refs.table_workinfo.rows ;
            if(rows.length<=0) {
                alert("No Data!!");
                return;
            }
           this.json_data = rows ;
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           // this.$router.push({path: '/leak-detail', query: { widx: list }})
        },


    },


    components: {

        LeakTypeBarChart:LeakTypeBarChart,
    }
}
</script>

<style scoped>
.leak-chart,
.leak-chart-content--full {
  width: 100%;
}

</style>

<template>
  <div>
    <!-- top-bar -->
    <div ref="navbar" class="navbar-custom">
      <ul class="list-unstyled topnav-menu topnav-menu-left">
        <li>
          <ol class="breadcrumb">
            <!--  <li class="breadcrumb-title">Leak Detail</li>-->
            <!--  <li class="breadcrumb-bar">|</li>-->
            <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
            <li class="breadcrumb-item"><router-link to="/leak-master">{{$t('water_leak.page_title')}}</router-link></li>
            <li class="breadcrumb-item active">{{$t('leak_detail.page_title')}}</li>
          </ol>
        </li>
      </ul>
    </div>
    <!-- tom-bar-end -->
    <div class="content-area row justify-content-around">
      <div class="left leak-detail" >
        <div class="check_list" style="  padding: 0 0 0 20px;">
          <input type="checkbox" id="chk_01"  value='L' v-model="checkedNames"  >
          <label for="chk_01">{{$t('leak_detail.search_leak')}}</label>
          <input type="checkbox" id="chk_02" value='N' v-model="checkedNames" >
          <label for="chk_02">{{$t('leak_detail.search_no_leak')}}</label>         
          <input
            type="checkbox"
            id="chk_03"
            value="M"
            v-model="checkedNames"
          />
          <label for="chk_03">Meter is Running</label>
          <input
            type="checkbox"
            id="chk_04"
            value="LS"
            v-model="checkedNames"
          />
          <label for="chk_03">Leak suspicion</label>
          <!--
          <input type="checkbox" id="chk_01"  value='RT_LEAK' v-model="checkedNames"  @change="uncheckedAll('2')">
          <label for="chk_01">Water Leak</label>
          <input type="checkbox" id="chk_02" value='RT_LEAK_INSIDE' v-model="checkedNames" @change="uncheckedAll('2')">
          <label for="chk_02">Inner Water Leak</label>
          <input type="checkbox" id="chk_03"  value='RT_USAGE'  v-model="checkedNames"  @change="uncheckedAll('2')">
          <label for="chk_03">Usage</label>          
          <input type="checkbox" id="chk_04"  v-model="checkedNames"   value='RT_OTHER' @change="uncheckedAll('2')">
          <label for="chk_04">Other</label>
          <input type="checkbox" id="chk_05" @change="uncheckedAll('1')" v-model="unchecked">
          <label for="chk_05">All</label>
          -->
         <button class="workbtn" @click="go_search" ><i class="fas fa-search"></i>  </button>
        </div>  

        <div class="lk-dt-tab table-button-group">
        <!--   <b-button variant="lk-middle lk-btn-left" @click="show_frequency_chart()"><i class="mdi mdi-pipe-leak mr-2"></i>LEAKAGE STRENGTH</b-button>
          <b-button variant="lk-middle border-left-line" @click="goRouter('/leak-analysis',-1)"><i class="mdi mdi-leak mr-2"></i>FREQUENCY COMPARISON</b-button>
         <b-button variant="lk-middle border-left-line" @click="$refs.leak_legend.show('set_leak_legend', $store.state.leak_legend);"><i class="mdi mdi-book-open mr-2"></i>LEGEND SETTING</b-button>-->
          <b-button variant="lk-middle lk-btn-left" @click="go_work_move()"><i class="mdi mdi-arrow-right mr-2"></i>{{$t('leak_detail.btn_work_move')}}</b-button>
          <b-button v-if="levelA" variant="lk-middle border-left-line" @click="update_leak_result()"><i class="mdi mdi-pipe-leak mr-2"></i>{{leak_btn_str}}</b-button>
          <b-button v-if="levelA" variant="lk-middle border-left-line" @click="update_dataset()"><i class="mdi mdi-leak mr-2"></i>{{dataset_btn_str}}</b-button>
          <b-button variant="lk-middle border-left-line" @click="downloads()"><i class="mdi mdi-download mr-2"></i>{{$t('leak_detail.btn_wav_down')}}</b-button>
          <b-button v-if="levelA" variant="lk-middle border-left-line" @click="go_delete()"><i class="mdi mdi-delete mr-2"></i>{{$t('button.delete')}}</b-button>
          <b-button v-if="levelA" variant="lk-middle border-left-line" @click="report_download"><i class="mdi mdi-download mr-2"></i>Report</b-button>
          <b-button variant="lk-middle lk-btn-right border-left-line"  @click="go_download"   >
                 <download-excel  class   = "btn-default"                     
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        :name    = "filename">
                    <i class="mdi mdi-download mr-2"> {{$t('leak_detail.btn_excel_down')}}</i> 
                  </download-excel> 
           </b-button> 
        </div>
                <div class="info_box leak">
                  <ul class="list">                      
                      <li>Work_ID: {{w_title}} / leak_id : {{clicked_l_idx}} / {{$t('leak_detail.start_time')}} : {{t_start_time}} / {{$t('leak_detail.table_column.worker')}} : {{t_worker}}  </li>
                      <li>{{$t('leak_detail.f_type')}}  : {{t_f_type}} / {{$t('leak_detail.device_no')}} : {{t_device_no}}  / {{$t('leak_detail.ai_result')}} : {{t_ai_result}} / {{$t('leak_detail.leak_result')}}  : {{t_leak_result}} / {{$t('leak_detail.table_column.strength')}} : {{t_strength}} / Remark : {{t_remark}}</li>                      
                  </ul>
                </div>

        <div class="list_box list_box2">
          <div class="page_list">
            <b-row class="list_box list_box2 leak">
              <b-col class="page_list">
                <h3>{{ $t("leak_detail.table_title") }}</h3>
                <div class="table-box" id="table" style="margin-top: -20px">
                  <!-- <div style="position:absolute; top: 0; right:0;  ">
                      <download-excel
                        class   = "btn btn-default"
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        name    = "filename.xls">
                      <button  @click="go_download" class="btn btn-third ml-2"  > <i class="mdi mdi-download" v-b-tooltip.hover title='Excel Download'></i></button>
                    </download-excel> </div>-->
                  <!--<label for="textarea-small" class="table-box-text mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label>-->
                  <ServerSideTable ref="table_leakage_info" 
                                   :columns="admin_yn ? columns: columns1"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"            
                                   :select="true"
                                   :add_custom_column="true"
                                   :adv_search="adv_search"
                                   :search="admin_yn"
                                   :max_height="table_height+'px'"
                                   @on-row-click="onRowClick"
                                   @on_goto_map_click="on_goto_map_click"
                                   @on_info_click="on_leakage_info"
                              
                  />

                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="right leak-detail">
        <b-row style="padding: 10px 0px 0px 10px;">
          <b-col class="leak-detail-content" style="height: calc(45vh - 20px );">
            <b-row >
              <b-col style="padding-top: 0px; " >
                  <div style=" position:absolute;  top : 10px; left:10px;z-index:102; "> <!--div style="font-size:10pt;">{{lidx2}} </div-->                  
                    <div><img src="@/assets/images/new/ic_list_info.svg" alt="map"  style="width:70%; " @click="on_leakage_info(clicked_l_idx)" /></div>
                  </div>                         
               
                   <div style=" position: relative;left:34%;width:53%;text-align:center;">
                      <div style="float:left;">
                          
                          <span style="position:absolute;left:10px;margin-top: -10px;">{{band_hz}}  </span>
                          <span style="float: right;padding-top:15px;">
                          <!--b-input v-model="szoom"    style="width:60px;height:20px;margin:0 0 0 10px;padding:10px;text-align:right;" 
                           type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                           v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" /-->
                           <b-select  class="facility__input" style="margin-top:-5px;" size="sm" v-model="bandfilter" :options="bandfilter_opt"  ></b-select>
                          </span> 
                      </div>
                       <span  style="float:left;margin-left: -40px;" >                            
                        <audio id="slice_audio_id" controls preload="auto" :src="slice_url" style="transform:scale(0.7);height: 50px;">
                        </audio>
                       </span>
                   </div>      
                <div class="status leak" >
                  <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                     <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                       <span class="inner-bg">Y Axis</span> -->
                                
                  <input type="radio" v-model="vchart" value="0" id="fixed"> <label for="fixed">{{$t('leakage_info.fixed')}}</label>
                  <input type="radio" v-model="vchart" value="1" id="proportional"><label for="proportional">{{$t('leakage_info.proportional')}}</label>
                </div>
                <div style='height:180px'>
                <div id="hidden-container" >
                  <div id="waveform" ref="waveform" style="float : left; margin-left: 35px; width:95%;"/>
                  <div id="wave-timeline" ref="wave-timeline" style="float : left; margin-left: 35px; width:95%;"/>
                </div>
                </div>
                <!-- <audio controls preload="auto" :src="aurl" style = "width:50%;">
                 </audio> -->
                <!--<av-waveform :audio-src="aurl" :caps-height="2" :canv-width="800" :canv-height="120" ref="avwaveform"></av-waveform> -->
              </b-col>
            </b-row>
            <b-row   >
              <b-col style=" padding-left: 0px;padding-right: 0px;" >
                <!--FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:98%; overflow: auto;"  /-->
                <div  style='height:200px'>
                   <pressChartData_fft :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:100%; overflow: auto;" @onZoomClickEvent="onZoomClickEvent"  />
                </div>   
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row  style=" padding: 5px 0px 0px 10px ; "  >
          <b-col class="leak-detail-content-map"  style='padding: 0;bottom: 0px;'>

                <div class="search_div_leak_detail">
                        <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
                        <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
                      
                </div>
            <div :style="{height: map_height+'px'}" >
              <Map
                  ref="map"
                  :widx="$route.query.widx"
                  :visible_full_extent="true"
                  :visible_gisload_button="true"
                  :leak_P ="true"
                  w_type="100"
                  @go_home="go_home()"
                  @onLeakMapClick="onLeakMapClick()"
                  @set_map_gisload="set_map_gisload"
                  @toggle_visible_all_layer = "toggle_visible_all_layer"
                  @toggle_visible_leak_type = "toggle_visible_leak_type"
                  @facility_invisible_layer = "facility_invisible_layer"
                  @set_info="set_info"
                  @leaktable_refresh="leaktable_refresh"
                  @get_gisinfo="get_gisinfo"   
                  @gis_reload = "gis_reload"                   
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <DlgFrequencyChart ref="dlg_frequency_chart"/>
     <DlgReport ref="dlg_report" />
    <!--DlgReport ref="dlg_report" :download_arr = '$refs.table_leakage_info.getSelectRows()' :table_list ='$refs.table_leakage_info.rows' /-->
    <DlgWorkList ref="dlg_work_list"  @goRouter="goRouter" />
  </div>
</template>
   
<script>


import pressChartData_fft from '@/components/chart/pressChartData_fft';
import ServerSideTable from '@/components/table/ServerSideTable_ds';
import Map from '@/components/Map';

import DlgFrequencyChart from '@/components/popup/DlgFrequencyChart';
import {CreateVectorLayer_f} from '@/gis.js';
import WaveSurfer from 'wavesurfer.js';
import DlgReport from '@/components/popup/DlgReport';
import DlgWorkList from '@/components/popup/DlgWorkList';

import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
/*
' CASE '+
' WHEN layer_type=0 then "Pipe" '+
' WHEN layer_type=1 then "Valve" '+
' WHEN layer_type=2 then "Water Meter" '+
' WHEN layer_type=3 then "Pump" '+
' WHEN layer_type=4 then "Hydrant" '+
' WHEN layer_type=7 then "Block" '+
' WHEN layer_type=8 then "Water Treatment Plant" '+
' WHEN layer_type=9 then "Pumping Station" '+
' WHEN layer_type=10 then "Water Basin" '+
' WHEN layer_type=11 then "Chamber" '+
' WHEN layer_type=12 then "Gauge" '+
' WHEN layer_type=13 then "Other" '+
' END AS type_, '+
*/

export default {
  name: 'LeakDetail',
  props: {
    // url
  },
  watch: {
    download_idx(){
       if(this.download_idx > this.download_arr.length -1 ) return
       var idx = this.download_arr[this.download_idx].id ;
      let fst = { "L": "L", "N": "N", "M": "M" };
      var fs = "N";
      if (this.download_arr[this.download_idx].ai_leak_result !== null) {
        fs = fst[this.download_arr[this.download_idx].ai_leak_result];
      }       
       let fname = idx+"_"+this.download_arr[this.download_idx].file_name.split('.')[0]+'_'+fs+".wav"       
       this.download(idx, fname)       
    },
    vchart() {
      //     if(this.vchart==='0') {
      //        alert("radio button : " + this.vchart) ;
      //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
      //     }
      var c_url = this.fft_url.split('#') ;
      this.fft_url = c_url[0]+'#'+this.vchart ;
      this.bandfilter = 0
     // this.low_max_hz = this.$refs.fftchart.low_max_hz;
     // this.high_max_hz = this.$refs.fftchart.high_max_hz;
    },
     bandfilter() {
      if(this.bandfilter!==0) {      
        this.s_min = 0 ;
        this.s_max = 0 ;
        if(this.bandfilter === 1){
          this.band_hz = this.$refs.fftchart.low_max_hz +'Hz'
          this.s_min = this.$refs.fftchart.low_max_hz - 200 < 0 ? 1 : this.$refs.fftchart.low_max_hz - 200 ;
          this.s_max = this.$refs.fftchart.low_max_hz + 200 ;
        }else{
          this.band_hz = this.$refs.fftchart.high_max_hz+'Hz' 
          this.s_min = this.$refs.fftchart.high_max_hz - 200  ;
          this.s_max = this.$refs.fftchart.high_max_hz + 200 ;
        }
        this.slice_url = this.URL+'api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+this.s_min+"&end_pos="+this.s_max+"&y_zoom="+this.szoom ;
      }else{
         this.band_hz = '-'
      }
    }
  },
  data() {
    return {
      layer_all_visible: false,
      leak_btn_str : this.$t('leak_detail.btn_leak'),
      dataset_btn_str : this.$t('leak_detail.btn_dataset'),      
      checkedNames: [],
      unchecked : true,
      s_min : 0, 
      s_max : 0, 
      band_hz : '-',
      admin_yn : sessionStorage.level < 2 ? true :false,
     // admin_yn : false,
      contents_height: 800,
      waveform_height: 50,
      fftchart_height: 195,
      low_max_hz : 0,
      high_max_hz : 800,
      map_height: 400,
      table_height: 530,
      vchart : 1,
      region_start:0,
      region_end:0,      
      szoom:200,
      w_title:'',
      is_playing: false,
      value:'',
      addr_value :'',
      levelA : false,
      columns: [
        {label: this.$t('leak_detail.table_column.map'), field: 'go_map', width: '4%',sortable: false},
        {label: this.$t('leak_detail.table_column.info'), field: 'info',width: '4%', sortable: false},
        {label: 'No', field: 'id',width: '5%'},
        {label: this.$t('leak_detail.table_column.time'), field: 'measure_start_datetime',width: '10%',},
        //  {label: 'Work No', field: 'w_idx'},
        {label: this.$t('leak_detail.table_column.worker'), field: 'm_id',width: '10%',},
        //  {label: 'Facility No', field: 'f_idx'},
        {label: this.$t('leak_detail.table_column.facility'), field: 'layer_type',width: '7%',},
        {label: 'Device', field: 'device_sn' ,width: '5%'},
    

        // {label: 'Period(Sec)', field: 'period'},
        {label:  this.$t('leak_detail.table_column.strength'), field: 'wave_energy',width: '7%',},
        {label: 'Max Frequency', field: 'wave_max_frequency' ,width: '5%'},
        
      //  {label:this.$t('leak_detail.table_column.s_cal') , field: 'wave_energy_cal',width: '7%',},
        {label: this.$t('leak_detail.table_column.leak'),
          field: 'leak_result_',  width: '7%',
          /*
          filterOptions: {
                  enabled: true, // enable filter for this column
                  placeholder: 'Filter This Thing', // placeholder for filter input
                  filterValue: '', // initial populated value for this filter
                  filterDropdownItems: ['Leak','Leak Inside','Usage','Other'], // dropdown (with selected values) instead of text input
                  filterFn: this.columnFilterFn, //custom filter function that
                  trigger: 'enter', //only trigger on enter not on keyup 
                },
          */
        },
        {label: this.$t('leak_detail.table_column.ai'), field: 'ai_leak_result',width: '7%',}, 
    //    { label:this.$t('leak_detail.probability') ,   field: "probability",     width: "7%",   }, 
        {label: this.$t('leak_detail.table_column.image'), field: 'image',width: '4%',},
   //     {label: this.$t('leak_detail.table_column.grd'), field: 'ground',width: '4%',},
        {label: this.$t('leak_detail.table_column.ds') , field: 'dataset',width: '4%',},
        {label: this.$t('leak_detail.table_column.remarks') , field: 'remarks',}
      ],
      columns1: [
        {label: this.$t('leak_detail.table_column.map'), field: 'go_map', width: '4%',sortable: false},
        {label: this.$t('leak_detail.table_column.info'), field: 'info',width: '4%', sortable: false},
        {label: this.$t('leak_detail.table_column.time'), field: 'measure_start_datetime',width: '20%',},
        
        {label: this.$t('leak_detail.table_column.worker'), field: 'm_id',width: '15%',},
        //  {label: 'Facility No', field: 'f_idx'},
        {label: this.$t('leak_detail.table_column.facility'), field: 'layer_type',width: '7%',},
        {label: 'Device', field: 'device_sn'},
        {label:  this.$t('leak_detail.table_column.strength'), field: 'wave_energy',width: '7%',},
        {label: 'Max Frequency', field: 'wave_max_frequency'},        
        {label: this.$t('leak_detail.table_column.leak'),         
          field: 'leak_result_',  width: '7%',          
        },
    //    {label: this.$t('leak_detail.table_column.ai'), field: 'ai_leak_result',width: '7%',}, 
    //    { label:this.$t('leak_detail.probability') ,   field: "probability",     width: "7%",   }, 
        {label: this.$t('leak_detail.table_column.image'), field: 'image',width: '4%',},
   //     {label: this.$t('leak_detail.table_column.grd'), field: 'ground',width: '4%',},
   //     {label: this.$t('leak_detail.table_column.ds') , field: 'dataset',width: '4%',},
        {label: this.$t('leak_detail.table_column.remarks') , field: 'remarks',}
      ],      
     selected_ts_mnf: '',
      json_data :[],
      json_fields:{
        'Start Time': 'measure_start_datetime',
        'Work No': 'water_work_id',
        //'Customer_name' : 
        'Worker': 'm_id',
        //'Facility No': 'f_idx',
        'Facility Name': 'layer_type',
        'X' : 'X',
        'Y' : 'Y',               
        'Period(Sec)':'period',
        'Device No' : 'device_sn',
        'Image' : 'image',
        'Strength' : 'wave_energy',
        'Max Frequency(Hz)': 'wave_max_frequency',
        'file_name' : 'file_name',
        'Strength_cal': 'wave_energy_cal',
        'Leak Result': 'leak_result_',
        'AI Leak Result'    : 'ai_leak_result' ,
        'probability' : "probability",
        'Remarks': 'remarks'
      },

         adv_search: [
                 {
          field: "measure_datetime",
          control: "date",
          value: null,
          value2: null,
          placeholder: "From measure_date",
          placeholder2: "To measure_datet",
        },
        {
          field: "layer_type",
          control: "select",
          value: null,
          options: [
            { value: null, text: "layer_type" },
            { value: "0", text: "Pipe" },
            { value: "1", text: "Valve" },
            { value: "2", text: "Water Meter" },
            { value: "3", text: "Pump" },
            { value: "4", text: "Hydrant" },
          ],
        },
        {
          field: "wave_energy",
          control: "range",
          value: null,
          value2: null,
          placeholder: "From Strength",
          placeholder2: "To Strength",
        },
        {
          field: "leak_result",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "Leak Result" },
            { value: "L", text: "Leak" },
            { value: "N", text: "No LEAK" },
            { value: "M", text: "Meter is Running" },
            { value: "LS", text: "Leak Suspicion" },
          ],
        },
        {
          field: "ai_leak_result",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "ai_leak_result" },
            { value: "L", text: "Leak" },
            { value: "N", text: "No LEAK" },
            { value: "M", text: "Meter is Running" },
          ],
        },
        {
          field: "is_ground",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "Ground Y/N" },
            { value: "0", text: "No Ground" },
            { value: "1", text: "Ground" },
          ],
        },
        {
          field: "is_dataset",
          control: "select",
          value: null,
          //'Leak','Leak Inside','Usage','Other'
          options: [
            { value: null, text: "Dataset Y/N" },
            { value: "0", text: "No Dataset" },
            { value: "1", text: "Dataset" },
          ],
        },
               
            ],
      filename : 'filename.xls',
      wavesurfer: null,
      fft_url: '',
      aurl:'',
      clicked_l_idx: -1,
      clicked_b_row:null,
      searchTerm: '',
      slice_url:'',
      download_idx: -1,
      download_arr: [],
      t_start_time : '',
      t_worker : '',
      t_device_no : '',
      t_ai_result: '  ',
      t_leak_result: '  ',
      t_strength: '',
      t_strength_cal: '',
      t_f_type: '',
      t_remark: '',
      bandfilter: 0,
      bandfilter_opt: [
          { value: 0, text : 'None' },
          { value: 1, text : 'Low' },
          { value: 2, text: 'High'},    
      ],
    }
  },
  methods: {
    toggle_visible_all_layer() {
      // 처음엔 strngth 데이터를 가져와 지도에 보여준다.
      // strength_cal값을 가져와 지도에 보여준다.
      this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible;
      if(this.$refs.map.layer_all_visible)
        this.$refs.map.leak_cal = 1;
      else
        this.$refs.map.leak_cal = 0;
      
      //누수 레이어를 다시 그린다.
      this.$refs.map.init_reload_leak_layer() ;

      /*
        this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
        let map = this.$refs.map.map
        for(let i=1; i<map.getLayers().getLength()-1; i++) {
            let layer = map.getLayers().item(i)
            if(layer.get('code')===100)
                layer.setVisible(this.$refs.map.layer_all_visible)
        }
        */
    },
    toggle_visible_leak_type() {
      // 처음엔 leak type all 데이터를 가져와 지도에 보여준다.
      // All or LEAK 값을 가져와 지도에 보여준다.
      this.$refs.map.layer_leak_visible = !this.$refs.map.layer_leak_visible;
      if(this.$refs.map.layer_leak_visible)
        this.$refs.map.leak_type = 'LEAK';
      else
        this.$refs.map.leak_type = null;
      
      //누수 레이어를 다시 그린다.
      this.$refs.map.init_reload_leak_layer() ;      
    },
    fn_press_han(event)
    {
        var objTarget = event.srcElement || event.target;
    // if(objTarget.type == 'text') {
      
      var value = objTarget.value;
      //alert(value);
      if(isNaN(value)){
        objTarget.value =value.replace(/[^0-9]/g,'');
        // alert('Please enter only numbers');
        // event.preventDefault();          
      }        
    },          
    addr_search(){
      this.$refs.map.go_search(this.addr_value);
    },
    Enter_Check1(){
      this.addr_search();
     },
     uncheckedAll (a) {
      
       // this.isChecked = true;        
             if (this.unchecked && a === "1") {
                this.checkedNames=[]
             }else{
               if(this.checkedNames.length > 0)
                  this.unchecked = false ;
                else
                  this.unchecked = true ;   
             }                    
    },
    change_selected_ts_mnf(e) {
          this.selected_ts_mnf = e
          this.go_search();
           /*
          this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_list_ex?w_idx="+this.$route.query.widx+'&search='+this.value+'&leak_result='+this.selected_ts_mnf, (rows) => {
            console.log(rows)
            if((!rows)||(rows.length<=0)) return;
            this.clicked_l_idx = rows[0].idx
            this.aurl = this.URL+'api/get_wav/'+rows[0].idx
            //  this.avwaveform.load(aurl)
            this.wavesurfer.load(this.aurl);
            this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
        })
        */
        //    this.get_time_series_mnf()
    },
    facility_invisible_layer() {

      this.layer_all_visible = false;
      let map = this.$refs.map.map
      for(let i=1; i<map.getLayers().getLength(); i++) {
        let layer = map.getLayers().item(i)
        if(layer.get('code')>50) continue;
        layer.setVisible(this.layer_all_visible)
      }
    },
    go_work_move() {
       //   this.filename = 
        this.permission_check();
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<1) {
          alert(this.$t('leak_detail.alt_select'));
          return;
        }

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        this.$refs.dlg_work_list.show(100,list,this.$route.query.widx,"1");
        /*
        var con_test = confirm("Are you sure you want to move?");
        //let list = idx
        // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
        if(con_test){
          this.$http.get(this.URL+'api/delete_ex?l_idx_ex='+list).then((res) => {  
            console.log(res.data);
            this.leaktable_refresh();
          });

        }
        */
    },
     update_leak_result() {
       //   this.filename = 
        var leak_query = 0 ;
        if(this.leak_btn_str === this.$t('leak_detail.btn_leak')){
          leak_query = 1 ; //RT_LEAK
          this.leak_btn_str = this.$t('leak_detail.btn_no_leak')
        }else{
          this.leak_btn_str =this.$t('leak_detail.btn_leak')
        }
        this.permission_check();
        let rows = this.$refs.table_leakage_info.getSelectRows()
        /*
        if(rows.length<1) {
           alert(this.$t('leak_detail.alt_select'));
           return;
        }
        */
        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        var con_test = confirm(this.$t('leak_detail.confirm_set_leak'));
       
        if(con_test){
          this.$http.get(this.URL+'api/update_work_leak?leak_result='+leak_query+'&lidx='+list).then((res) => {  
             console.log(res.data);
             this.leaktable_refresh();
          });

        }
    },
    update_dataset() {
        var dataset_query = 0 ;
        if(this.dataset_btn_str === this.$t('leak_detail.btn_dataset')){
          dataset_query = 1 ; //RT_LEAK
          this.dataset_btn_str = this.$t('leak_detail.btn_no_dataset')
        }else{
          this.dataset_btn_str = this.$t('leak_detail.btn_dataset')
        }
        this.permission_check();
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<1){
           alert(this.$t('leak_detail.alt_select'));
           return;
        }

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
       var con_test = confirm(this.$t('leak_detail.confirm_set_dataset'));
       
        if(con_test){
          this.$http.get(this.URL+'api/update_work_leak?dataset='+dataset_query+'&lidx='+list).then((res) => {  
            console.log(res.data);
              this.leaktable_refresh();
            });

        }
    },
    go_download() {
  //   this.filename = 
      this.filename = this.filename.replace(",","_")
      let rows = this.$refs.table_leakage_info.rows ;
      if(rows.length<=0) {
        alert(this.$t('leak_detail.alt_no_data'));
        return;
      }
      this.json_data = rows ;
      // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
      // this.$router.push({path: '/leak-detail', query: { widx: list }})
    },
    makeExcelFile4 () {
        let tab_text = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">'
        tab_text += '<head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">'
        tab_text += '<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
        tab_text += '<x:Name>Test Sheet</x:Name>'
        tab_text += '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>'
        tab_text += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>'
        tab_text += "<table>"
        var div1 = document.getElementById('table');
        var div1Paras = div1.getElementsByTagName('table');

        const temp = div1Paras[1].innerHTML
        // console.log(temp)
        tab_text += temp           
        tab_text += '</table></body></html>'
        console.log(tab_text)
        const fileName = 'exampleTable.xls'
        const a_tag = document.createElement('a')
        const blob = new Blob([tab_text], { type: 'application/vnd.ms-excel;charset=utf-8;' })
        a_tag.href = window.URL.createObjectURL(blob)
        a_tag.download = fileName
        a_tag.click()      
    },
    permission_check(){
       if(sessionStorage === null || typeof sessionStorage === undefined){
            alert(this.$t('leak_detail.alt_no_update'))
            return
   
        }else if(sessionStorage.level > 1){
            alert(this.$t('water_leak.alert_no_permission'))
            return;
        }
    },
    go_delete() {
        this.permission_check();
       
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<=0) {
          alert(this.$t('leak_detail.alt_no_data'));
          return;
        }
        
        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        var con_test = confirm(this.$t('water_leak.left_menu.confirm_msg_delete'));
        //let list = idx
        // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
        if(con_test){
          this.$http.get(this.URL+'api/delete_ex?l_idx_ex='+list).then((res) => {  
            console.log(res.data);
              this.leaktable_refresh();
            });

        }
     },
    go_home() {
      var view = this.$refs.map.map.getView()
      var c_zoom = view.getZoom()            
      var center = view.getCenter();
      this.$router.push({path: '/leak-analysis1', query: { widx: this.$route.query.widx, w_type: '100',zoom:c_zoom, c_x:center[0],c_y:center[1] }})
    },
    download(idx, name) {
      this.$http({
        url: this.URL+'api/download/wav/'+idx,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);
        fileLink.click();
          //'Leak','Leak Inside','Usage','Other'
      
      
        setTimeout(()=>{
          ++this.download_idx; 
        }, 200)
      
      })

    },
    report_download(){
      let rows = this.$refs.table_leakage_info.getSelectRows()
      this.download_arr = rows ; 
      this.$refs.dlg_report.download_report(rows)
    },
    downloads() {
      let rows = this.$refs.table_leakage_info.getSelectRows()
      this.download_arr = rows ; 
      if(rows.length<=0){
        alert(this.$t('leak_detail.alt_select'));   
        this.download_arr = [] ;
        return;  
      } else{
          this.download_idx = 0;
      }  
    
    },
    show_frequency_chart() {
      let rows = this.$refs.table_leakage_info.getSelectRows()
      if(rows.length<=0) {
        alert(this.$t('leak_detail.alt_select'));
        return;
      }
      let lidx = []
      for(let i=0; i<rows.length; i++) lidx.push(rows[i].id)
      this.$refs.dlg_frequency_chart.show(lidx);
    },
    goRouter(path, w_id) {
      
      let query = {}
      if(Number(w_id) === -1){
        if(path==='/') query['widx'] = this.$route.query.widx
        else if('/leak-analysis'===path) {
          let rows = this.$refs.table_leakage_info.getSelectRows()
          if(rows.length<=0) return;
          let lidx = []
          for(let i=0; i<rows.length; i++) lidx.push(rows[i].id)
          query['lidx'] = lidx
          query['widx'] = this.$route.query.widx
          this.$router.push({path: path, query: query})
        }
      }else{
        query['widx'] = w_id
        this.$router.push({path: '/leak-detail', query: query})
        this.$router.go();

      }
     
    },
    
    onLeakMapClick(){
      let leak_idx = this.$refs.map.leak_idx;
      this.clicked_l_idx = leak_idx;

      this.$http.get(this.URL+'api/get_leak_info?idx='+leak_idx).then((res) => {
        if(res.data.length<=0) return
        this.onTitleChange(res.data.list[0]);
      });
      //LEAKAGE INFO값을 가져와서 FFT, WAV값을 뿌려준다.
      this.aurl = this.URL+'api/get_wav/'+leak_idx ;

      this.wavesurfer.load(this.aurl);
      this.fft_url = this.URL+"api/get_fft/"+leak_idx+'#'+this.vchart;
      this.bandfilter = 0

    },
    leaktable_refresh(){
      this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_list_ex?w_idx="+this.$route.query.widx, (rows) => {
        if((!rows)||(rows.length<=0)) return;
        this.clicked_l_idx = rows[0].id
        this.onTitleChange(rows[0]) ;
        this.filename = "widx-"+this.$route.query.widx+"-lidx-"+this.clicked_l_idx+"~.xls"
        //this.aurl =this.URL+'api/get_wav/'+rows[0].idx;
        this.aurl = this.URL+'api/'+rows[0].file_path ;
        this.wavesurfer.load(this.aurl);

        
      //  this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
        if(rows[0].fft_file_path !== null )
          this.fft_url = this.URL+'api/'+rows[0].fft_file_path+'#'+this.vchart;
        else 
          this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'#'+this.vchart;
          this.bandfilter = 0
      })
    },
    onTitleChange(row){
        var c_title = row.w_title === undefined ? "-" : row.w_title
        this.w_title=row.water_work_id+"("+ c_title +")" ;
        this.t_start_time = row.measure_start_datetime ;
        this.t_worker = row.m_id ;
        this.t_device_no = row.device_sn ;
        this.t_ai_result= row.ai_leak_result ;
        this.t_leak_result= row.leak_result_ ;
        this.t_strength= row.wave_energy ;
        this.t_strength_cal= row.wave_energy_cal ;
        this.t_f_type= row.layer_type ;
        this.t_remark= row.remarks ;
    },
    onRowClick(params) {
      //선택된 색상 보여지게
     //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
      if(this.clicked_b_row != null){
        this.clicked_b_row.bgColor = '';
      //  this.clicked_b_row.style.backgroundColor = '';
      }
    
      if(params.event.target.nodeName==='TD'){
          params.event.target.parentElement.bgColor  = '#125744';          
          this.clicked_b_row = params.event.target.parentElement ;
      }else  if(params.event.target.nodeName==='IMG'){
           params.event.target.parentElement.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
      }else{
          params.event.target.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement ;
      }
      
/*
     if(this.clicked_b_row != null){
       this.rowStyleClassFn(params.row, 1);
     }
      this.rowStyleClassFn(params.row, 0);
 */    
    //  this.clicked_b_row = params.row
      this.slice_url = null;
      var audio = document.getElementById('slice_audio_id')
      audio.load(); 
      this.onTitleChange(params.row) ;
      this.clicked_l_idx = params.row.id
      document.getElementById("hidden-container").style.display = 'none';
      this.aurl = this.URL+'api/'+params.row.file_path ;
      this.wavesurfer.load( this.aurl);

     //this.fft_url = this.URL+"api/"+params.row.fft_path+'?'+this.vchart;
      if(params.row.fft_file_path !== null )
         this.fft_url = this.URL+'api/'+params.row.fft_file_path+'#'+this.vchart;
      else 
         this.fft_url = this.URL+'api/get_fft/'+params.row.id+'#'+this.vchart;
      
      this.bandfilter = 0

      this.wavesurfer.clearRegions()
        if( params.row.cut_position !== null){
        this.wavesurfer.addRegion({
            start: params.row.cut_position, // time in seconds
            end: Number(params.row.cut_position)+1, // time in seconds
            color: 'hsla(200, 100%, 30%, 0.1)',
            drag: false,
            resize: false
          }); 
        }  
    },

    on_goto_map_click(row) {
      this.$refs.map.get_extent('w_type=100&l_idx='+row.id+"&w_idx="+row.water_work_id).then((data)=>{
        this.$refs.map.set_fit_extent(data)     
        if(this.$store.setting.map_name==='Daum')
          this.$refs.map.map.getView().setZoom(13);
        else
          this.$refs.map.map.getView().setZoom(18);   
      })
      this.$refs.map.select_facility_feature('100','',row.id) 
    },
    play() {
      if(!this.is_playing) {
        this.wavesurfer.play();
        this.is_playing = true
      }
      else {
        this.is_playing = false
        this.wavesurfer.pause();
      }

    },
    stop() {
      this.is_playing = false
      this.wavesurfer.stop();
    },
    window_resiz() {
      this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 50

      this.map_height = this.contents_height/2
      this.fftchart_height = this.map_height /2
      this.waveform_height = this.fftchart_height - 100

      this.$refs.map.update_size()
    },
    set_map_gisload() {
      this.set_info();
      let map = this.$refs.map.map;
      var size1 = map.getSize();
      //var size = [size1[0]*2, size1[1]*2] ;
      var size = [size1[0], size1[1]] ;
      var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
      let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
      var extent = map.getView().calculateExtent(size);

      if(this.$refs.map.map.getView().getZoom() < cz){
        alert(this.$t('home.alert_zoom_in')) ;
      }else{
        // 추가 되었던 소스 가져와서 삭제하기
        // heat layer 삭제

        var h_layer = this.$refs.map.heat_layer ;
        if(h_layer != null) {
          var source_h = h_layer.getSource();
          var hfeatures = source_h.getFeatures();
          for(var k=0; k< hfeatures.length ; k++) {
            source_h.removeFeature(hfeatures[k]);
          }
          map.removeLayer(h_layer) ;
          h_layer = null;
        }
        // remove_layer(map);
        for(let i=0; i<this.$refs.map.layer_list.length; i++) {
          var layer = this.$refs.map.layer_list[i];
          if(layer=== null || layer.values_ === null) continue
          var source1 = layer.getSource();
          var features = source1.getFeatures();
          for(var j=0; j< features.length ; j++) {
            source1.removeFeature(features[j]);
          }
          map.removeLayer(layer) ;
        }
        this.$refs.map.layer_list = [] ;
        for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

          var params = this.$refs.map.layer_setting_stlye_list[i];
          // var nstyle = params.style;
          var _url=params.surl;
          // if(params.code >50 || layer.get('code')=== undefined ) continue

          var lftaye = params.f_type;
          _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

          var newlayer = CreateVectorLayer_f(params,extent) ;
          var vis = params.visible ;
          newlayer.setVisible(vis);
          this.$refs.map.layer_list.push(newlayer) ;
          map.addLayer(newlayer);


        } //for문 끝
        this.$refs.map.init_draw_modify();
        this.$refs.map.init_reload_leak_layer() ;    
      } // zoom크기에 대한 if


    },
    gis_reload(){
        this.set_info();
       // this.$refs.map.init_draw();
        if(this.$store.setting.map_name==='Daum')
          this.$refs.map.map.getView().setZoom(9);
        else
          this.$refs.map.map.getView().setZoom(15);   
        this.set_map_gisload() ;    
    },
    get_gisinfo(){
        this.$refs.map.block_info = 1 ;
        this.set_toggle_visible_all_layer();
        // this.$refs.map.init_draw();
        this.$refs.map.edit_change('add', 'Block')
        // this.$refs.map.edit_start('add', 'Block') ;
    },
    set_toggle_visible_all_layer() {          
            this.layer_all_visible = !this.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue
                layer.setVisible(this.layer_all_visible)
            }
    },
    set_info(){
      this.$refs.map.edit_cancel();
      this.$refs.map.select_info = true;
    },
    on_leakage_info(id) {

      this.$refs.map.show_leakage_info(id)
    },

    go_search() {

            if(this.value===undefined) return;

        this.box = [] ;       
       // if(this.checkedNames.length === 0 || this.checkedNames.length === 3) {
         if(this.checkedNames.length === 0 ){ 
          this.checkedNames=[]       
        }
      //    this.$http.get(this.URL+'api/get_work_list_ex?w_type=100&search_term='+value+'&per_page=100&page=1').then((rows) => {
        this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_list_ex?w_idx="+this.$route.query.widx+'&search='+this.value+'&leak_result_='+this.checkedNames, (rows) => {
          console.log(rows)
          if((!rows)||(rows.length<=0)) return;
          this.clicked_l_idx = rows[0].id
          this.onTitleChange(rows[0]) ;
          this.aurl = this.URL+'api/'+rows[0].file_path
          //  this.avwaveform.load(aurl)
          this.wavesurfer.load(this.aurl);
         // this.fft_url = this.URL+'api/'+rows[0].fft_file_path+'?'+this.vchart;
          if(rows[0].fft_file_path !== null )
             this.fft_url = this.URL+'api/'+rows[0].fft_file_path+'#'+this.vchart;
         else 
              this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'#'+this.vchart;
         
         this.bandfilter = 0
        })
     },
    Enter_Check(){
       //  if(event.keyCode == 13){
         this.go_search();  // 실행할 이벤트
    },
  
    wav_getTime(prog){
      //alert("audio prog :"+ prog)
      var w_lenght = Math.round(this.wavesurfer.getDuration()) ;
      var intval = 2048 *  Math.pow(2, w_lenght)
 
      let starttime = prog*this.wavesurfer.getDuration()-0.5
      let endtime = prog*this.wavesurfer.getDuration()+0.5
      this.s_time = starttime;
     
      var st = Math.round( intval*starttime/this.wavesurfer.getDuration())
      var et = Math.round(intval*endtime/this.wavesurfer.getDuration())
      this.delay = 1000;
      var dataJSON = this.wavesurfer.backend.getPeaks(intval/2); //start,end를 지정할 수 있다. 24000
     // var sp = dataJSON.splice(st,et-st)
      var sp = dataJSON.splice(st,2048)
     // sp = JSON.stringify(sp);
      intval = sp.length/(et-st)
      // var duration = endtime-starttime
      //dev엔 적용
      //var buf  = null 
      // create a new buffer to hold the new clip
          //  var buffer = this.createBuffer(this.wavesurfer.backend.buffer, duration)
      // copy
        //   var buf = this.copyBuffer(this.wavesurfer.backend.buffer, starttime, endtime, buffer, 0)

      //this.$refs.dlg_wave_slice_chart.show(this.clicked_l_idx,sp,buf,intval,starttime,endtime);

    },
        //20210427 
    onZoomClickEvent(){
      var s_min = this.$refs.fftchart.select_min; //09-03 이렇게 들어온다
      var s_max = this.$refs.fftchart.select_max; //09-03 이렇게 들어온다
    //  this.min_value = s_min;
    //  this.max_value = s_max;
      this.slice_url = this.URL+'api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
      // this.slice_url = 'http://dev.neverlosewater.com/node/api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
    },
  },
  created () {
    this.$EventBus.$on('sidebar_resize', () => {
      setTimeout(()=>{
        this.$refs.fftchart.redraw()
        if(this.clicked_l_idx>-1){
          //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
          this.wavesurfer.empty()
          this.wavesurfer.load(this.aurl);

        }
      }, 500)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.window_resiz)
  },
  mounted () {
    window.addEventListener('resize', this.window_resiz)
    this.window_resiz()
       setTimeout(()=>{
            this.toggle_visible_all_layer();   
       }, 1000)  
   //삭제버튼 활성화 여부
   // if(localStorage !== null && typeof (localStorage) !== undefined && localStorage.level === 'A'){
     if(sessionStorage !== null && typeof (sessionStorage) !== undefined && sessionStorage.level < 2){
       this.levelA = true       
     }else{
       this.levelA = false
     }
    document.getElementById("hidden-container").style.display = 'none';
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#4488ee',
      progressColor: 'blue',
      mediaControls: true,
      backend: 'MediaElement',
      normalize : true,
      scrollParent : true,
      //waveColor: '#D2EDD4',
      //progressColor: '#46B54D',
      //   barWidth: 0.5,
      //   barHeight: 4, // the height of the wave
      //minPxPerSec : 300,  //오디오 파일의 1초당 렌더링 될 픽셀 수의 최소값
      //   autoCenter: true,
      height: this.waveform_height,
      //   width: 600,
      plugins: [      
          TimelinePlugin.create({
            container: '#wave-timeline',    
          }),
       Regions.create({
            regionsMinLength: 1,
            regions: [
                {
                    start: this.region_start,
                    end: this.region_end,
                    loop: false,
                    color: 'hsla(400, 100%, 30%, 0.5)',
                    drag: false,
                    resize: false
                }, 
             ],
          })
        
    
      ]
    });
    
    this.wavesurfer.on('finish', () => {
      this.is_playing = false
    });

      this.wavesurfer.on("loading", function () {
        document.getElementById("hidden-container").style.display = '';
         //document.querySelector('audio');
        var mediaElt1 = document.getElementById('waveform');
        var mediaElt = mediaElt1.querySelector('audio');
       
        // mediaElt = document.getElementById("waveform");
        mediaElt.style = "width:40%;height:50px; transform:scale(0.7); position: absolute; top:0px;left:-15px;border:0; outline:0;" ;


      });

      this.wavesurfer.drawer.on('click', (event, progress)=>{
           // this.wavesurfer.backend.seekTo(progress);
            this.wav_getTime(progress)
        });
      
      this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_list_ex?w_idx="+this.$route.query.widx, (rows) => {
        console.log(rows)

        if((!rows)||(rows.length<=0)) return;
        this.clicked_l_idx = rows[0].id
        this.onTitleChange(rows[0]) ;
        this.filename = "widx-"+this.$route.query.widx+"-lidx-"+this.clicked_l_idx+"~.xls"
       // this.aurl = this.URL+'api/get_wav/'+rows[0].idx
        this.aurl = this.URL+'api/'+rows[0].file_path
        //  this.avwaveform.load(aurl)
        this.wavesurfer.load(this.aurl);
      //  this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
        if(rows[0].fft_file_path !== null )
          this.fft_url = this.URL+'api/'+rows[0].fft_file_path+'#'+this.vchart;
        else 
          this.fft_url = this.URL+'api/get_fft/'+rows[0].id+'#'+this.vchart;
        
        this.bandfilter = 0

        this.wavesurfer.clearRegions()
        if( rows[0].cut_position !== null){
        this.wavesurfer.addRegion({
            start: rows[0].cut_position, // time in seconds
            end: Number(rows[0].cut_position)+1, // time in seconds
            color: 'hsla(200, 100%, 30%, 0.1)',
            drag: false,
            resize: false            
          }); 
        }
    })
    
    setTimeout(()=>{
         var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
         if( elem !== undefined ){
           if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();
               
               if(this.clicked_l_idx>-1){
                  //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
                  this.wavesurfer.empty()
                  this.wavesurfer.load(this.aurl);
                  this.$refs.fftchart.redraw()
               }
           }
         }
     }, 1000)
    
  },
  components: {
  
    pressChartData_fft: pressChartData_fft,
    ServerSideTable: ServerSideTable,
    Map: Map,
    DlgFrequencyChart: DlgFrequencyChart,
    DlgReport:DlgReport,
    DlgWorkList:DlgWorkList,
  }
}

</script>

<style lang="scss" >
/*
    #waveform{
          background: rgba(0,0,0,0.8);
    }
*/
#waveform .audio {
  all: none;
  width: 300px;
  position :absolute;
  z-index:1;
}

.inner-bg {
  float : left;
  /* 공통 속성값 지정 */

  display: inline-block;
  /* 인라인요소를 인라인블록 요소로 */
  height: 30px;
  /* 이미지 공간을 확보하기 위해 높이값 지정 */

  background: #c0c0c0;
  padding-left:10px;
  padding-right: 10px;

}

.content-area {
  padding: 0 30px;
}
.leak-detail-content {
  padding: 10px 30px 0;
   // 2022 new
  // background: #fff;
  background: #172A46;
  border: 1px solid #172A46;
  color: #fff;
}
.leak-detail-content-map {
  padding: 0;
  bottom: 0;
}
.left .list_box.list_box2 .list_box.list_box2.leak {    
    min-height: 700px;
}
.left .list_box.list_box2 {
  min-height: 630px;
  height: calc(95vh - 50px - 70px - 20px - 50px - 37px);
  // 2022 new
  background: #172A46;
  color: #fff;
}
.table-button-group {  
    margin: 10px 0 10px 0;

}
.table-button-group .btn-lk-middle {
  width: 100%;
}
.left.leak-detail {
  height: auto;
}
table.type01 th {
  padding: 12px 0;
  background-color: aqua;
}
table.type01 td span {
  padding: 2px 0;
  letter-spacing : -1px;
}
.btn-default {
  color: #fff;
}
.btn-default :hover{
  color: #000;
}
i {
    font-style: normal;
}
.input_search_box.leak{
  margin-top:0px;
}
.input_search_box .input_submit .search{
    top: -25px;
    right: 50px;
}
.custom-radio.b-custom-control-sm{
     margin-left: 20px;
}
.search_div_leak_detail{
    position: absolute;
    left:420px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}

table.type01 td .text-right  {
  font-size: 11px;
}
.fftchart .highcharts-container .highcharts-root {
    width: 98%;
}
.left.leak-detail{
  width: 56%;
}
// 2023
// .right.leak-detail{
//   width: 44%;
// }
</style>

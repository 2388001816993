<template>
    <div>
        <b-modal
              ref="modal"
              title="Upload CSVFile"
              
            >

            <div style ="padding:40px;">
                <!--b-row> <b-col sm="4"><label>Encoding</label>  </b-col>
                        <b-col sm="8"><b-input size="sm"  type="text" v-model="encoding"/> </b-col>
                
                </b-row-->
                 <b-row> <b-col sm="4"><label>ID</label>  </b-col>
                        <b-col sm="8"> <b-input size="sm"  type="text" v-model="properties['id']" disabled/> </b-col>
                </b-row>
                 <b-row> <b-col sm="4"><label>Tag ID(*)</label>  </b-col>
                        <b-col sm="8"> <b-input size="sm"  type="text" v-model="properties['tag_id']"/> </b-col>
                </b-row>
                <b-row> <b-col sm="4"><label>Name(*)</label>  </b-col>
                        <b-col sm="8"> <b-input size="sm"  type="text" v-model="properties['name']"/> </b-col>
                
                </b-row>
                 <b-row> <b-col sm="4"><label>contents</label>  </b-col>
                  <b-col sm="8">   <b-input size="sm"  type="text" v-model="properties['contents']"/> </b-col>
                
                </b-row>                 
                <b-row> <b-col sm="4"><label>device_sn</label>  </b-col>
                  <b-col sm="8">   <b-input size="sm"  type="text" v-model="properties['device_sn']"/> </b-col>
                </b-row>
                <b-row> <b-col sm="4"><label>data_count</label>  </b-col>
                  <b-col sm="8">   <b-input size="sm"  type="text" v-model="properties['data_count']"/> </b-col>
                </b-row>         
                <b-row> <b-col sm="4"><label>start_time</label>  </b-col>
                  <b-col sm="8">   <b-input size="sm"  type="text" v-model="properties['start_time']"/> </b-col>
                </b-row>         
                <b-row> <b-col sm="4"><label>time_type</label>  </b-col>
                  <b-col sm="8">   <b-select class="facility__input" size="sm" v-model="properties['time_type']" :options="time_type_option" ></b-select> </b-col>
                </b-row> 
                  <b-row>
                  <b-col sm="4">
                      <label class="facility__label">{{$t('facility_info.status')}}</label>
                  </b-col>
                  <b-col sm="8">
                      <b-select class="facility__input" size="sm" v-model="properties['status']" :options="f_status_option" ></b-select>
                  </b-col>
              </b-row>  
                <div>
                    
                    <label>Files
                         <input size="sm"  type="file" id="files" ref="files" v-on:change="handleFilesUpload()"/>
                    </label>
                </div>
                <div>
                    <ul>
                        <li v-for="(file, index) in files" :key="index">{{ file.name }} </li>
                    </ul>
                </div>
                <br>
                <div>
                    <b-button v-on:click="addFiles()">Add Files</b-button>
                </div>
                <br>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-button @click="submitFiles()">OK</b-button>
                <b-button @click="$refs.modal.hide()">Cancel</b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>

export default {
    data() {
        return {
            files: [],           
            encoding: 'euc-kr',
            tag_name: null,
            selected_layer: 0,
            properties : {},
            properties_bak : null,
            f_status_option: [
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
                { value: 'U', text: 'Unable' },
            ],
            time_type_option: [
                { value: 'D', text: 'Day' },
                { value: 'H', text: 'Hour' },
                { value: 'M', text: 'Minute' },
                { value: 'S', text: 'Second' },
            ],
        }
    },


    methods: {
        insert_show() {
            this.properties = {} ;
            this.properties['time_type'] = 'H'
            this.properties['status'] = 'A'
            this.files = []
            this.$refs.modal.show();
        },
        show(id){
            this.files = []
            this.properties = {} ;   
            this.$http.get(this.URL+"api/scada/get_scada_info?id="+id).then((res) => {                   
                
                if(res.data.length<=0) return

                this.properties = res.data.list[0]
                this.properties_bak = Object.assign({}, this.properties)
                this.file_name = res.data.list[0].csv_file_id
                this.$refs.modal.show();
            })     
          
        },
        addFiles() {
            this.$refs.files.click();
        },
        submitFiles(){
            let formData = new FormData();
            let a = 0 ;
            
            if(this.files.length > 0 ){
                for( var i = 0; i < this.files.length; i++ ){
                    let file = this.files[i];
                    let fileName = file.name;
                    let fileExtension = fileName.split('.').pop();
                    if(this.properties["name"]=== null || this.properties["name"]=== undefined  ){
                        this.properties["name"]=fileName
                    }
                    if( fileExtension.toLowerCase() ==="csv" ){
                        ++a;
                    }

                   formData.append('files[' + i + ']', file);
                }
                 formData.append("customer_no", sessionStorage.select_customer_no) ;
                 formData.append("m_idx",  sessionStorage.m_idx) ; 
              
            }
            if( this.properties['id'] === undefined || this.properties['id'] === null){
                let keys_1 = Object.keys(this.properties)
                for(let i=0; i<keys_1.length; i++) {
                  if(this.properties["name"]=== null || this.properties["name"]=== undefined  ){
                      alert("Name은 필수 입력값입니다.")                      
                  }else if(this.properties["tag_id"]=== null || this.properties["tag_id"]=== undefined  ){
                      alert("Tag ID 은 필수 입력값입니다.")                      
                  }   
                  if(this.properties[keys_1[i]]=== null || this.properties[keys_1[i]]=== undefined) continue
                  formData.append(keys_1[i],this.properties[keys_1[i]])
                }
                if(a !== 1  ){
                    alert("csv파일을 업로드 하여야 합니다.")
                    return
                }
                //formData = this.properties
            }else{
                let keys = Object.keys(this.properties_bak)
                for(let i=0; i<keys.length; i++) {
                  if(this.properties_bak[keys[i]]===this.properties[keys[i]]) continue
                  formData.append(keys[i], this.properties[keys[i]])
                  ++a;
                }
                if(a < 1 ){
                    alert("저장할 내용이 없습니다.") 
                    return;
                }
                formData.append("idx", this.properties['id']) ;
   
            }
           // let form_keys = Object.keys(formData)
           
            formData.append("customer_id", sessionStorage.select_customer_id) ;
           
            // this.$http.post(this.URL+'api/upload_shape?layer='+this.selected_layer+'&encoding='+this.encoding+'&tag_name='+this.tag_name, formData, {
            this.$http.post(this.URL+'api/API21/set_scada_file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  //'Content-Type': undefined 
                }
            }).then((res) => {
                console.log('SUCCESS!!');
               
                if(res.data.check ===1){
                   console.log("")
                    alert("fail : "+ res.data.msg) ;
                }
                this.$refs.modal.hide();
            })
            .catch(() => {
                 console.log('FAILURE!!');
                this.$refs.modal.hide();
            });
        },
        handleFilesUpload(){
            this.files = [];
            let uploadedFiles = this.$refs.files.files;
            for( var i = 0; i < uploadedFiles.length; i++ ){
                this.files.push( uploadedFiles[i] );
            }
        },
        removeFile( key ){
            this.files.splice( key, 1 );
        }
    }
}
</script>

<style  lang="scss">
  input[type="file"]{
    position: absolute;
    top: -500px;
  }
  div.file-listing{
    width: 200px;
  }
  span.remove-file{
    color: red;
    cursor: pointer;
    float: right;
  }
</style>

<template>
    <div>
        <!-- top-bar -->
         <div ref="navbar" class="navbar-custom">
            <!--h4 class="content-title">Block Monitoring</h4-->
                 <ul class="list-unstyled topnav-menu topnav-menu-left">
                     <li>
                         <ol class="breadcrumb"> 
<!--                              <li class="breadcrumb-title">Monitoring Detail</li>-->
<!--                              <li class="breadcrumb-bar">|</li>-->
                            <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                           <li class="breadcrumb-item"><router-link to="/block-monitoring">{{$t('menu.monitoring')}}</router-link></li>
                             <li class="breadcrumb-item active">{{$t('pressure.specification')}}</li>
                         </ol>
                     </li> 

                 </ul>
         </div>
     <!-- tom-bar-end -->
<!--      <div class="content-area">-->
        <search-left-type3 :box="box" :activeCheckIndex="activeCheckIndex" @updateCheckIndex="updateCheckIndex" @go_search="go_search"
         @on_goto_map_click = "on_goto_map_click" />
    
        <div class="right_area chart_box right_area_01 tab-chart-box" style=" width: 78%;">
          <div class="tab_list_02 is--square" ref="tabList">
            <div :class="[`tab_btn tab_btn0${index+1}`, { 'on' : activeIndex === index }]" v-for="(item, index) in tabs" :key="index" @click="tabItem(index)">{{ item.name }}</div>
          </div>
          <div class="tab_box_area" ref="tabBox">
            <div id="tab_box01" :class="['tab_box',{ 'on' : activeIndex === 0 }]">
              <div class="info_box">
                <ul class="list">
                  <li>Block Name : {{tag_name}} </li>
                </ul>
              </div>
              <div class="img_area">
<!--                <div class="icon_box">-->
<!--                  <div class="icon_01 color_01"><span>Inner Leak</span></div>-->
<!--                  <div class="icon_02 color_06"><span>Inner leak</span></div>-->
<!--                </div>-->
                <div  class="large-chart-bg">
                  <div ref="button_group_mnf">
                    <div style="position: absolute;top: 6%;right: 608px;z-index: 100;">
                      <!-- 2022 new -->
                        <!-- <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_mnf(-1)"><i class="mdi mdi-menu-left mdi-24px"></i></button> -->
                        <!-- <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_mnf(1)"><i class="mdi mdi-menu-right mdi-24px"></i></button> -->
                        <button class="btn-primaryBlack " @click="change_date_mnf(-1)"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                        <button class="btn-primaryBlack " @click="change_date_mnf(1)"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                    </div>
                    <b-form inline class="btn_list_wrap" style=" top: 6%; right: 100px;">
                      <b-input v-if="isShowDate1"  size="sm"  v-model="mnf_date" type="date" @change="change_mnf_date($event)" class=" m-0"/>
                       <b-button-group class="mr-2 btn_list">
                         <div class="btn-primary lookup_btn search" @click="showDate1" style=" margin-right: 370px; width: 130px;">Search Date</div>                  

                        <b-radio :button="true"  size="sm" v-model="selected_ts_mnf" value='30' @change="change_selected_ts_mnf($event)">
                          <span>1M</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='60' @change="change_selected_ts_mnf($event)">
                          <span>2M</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='90' @change="change_selected_ts_mnf($event)">
                          <span>3M</span>
                        </b-radio>
                        <b-radio :button="true" size="sm" v-model="selected_ts_mnf" value='180' @change="change_selected_ts_mnf($event)">
                          <span>6M</span>
                        </b-radio>
                      </b-button-group>

                    </b-form>
                  </div>
                    <div style="position:absolute; top:6%; right:30px;">
                      <span>
                        <!-- <button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text"  @click="chart_exceldownload(1)" >{{$t('pressure_monitoring.excel_down')}} -->
                        <button class="btn-primary btn btn-map-view ml-1  map-button icon_btn icon_btn_text"  @click="chart_exceldownload(1)" >{{$t('pressure_monitoring.excel_down')}}
                        </button>
                      </span>
                    </div>
                  <!--
                  <div ref="mnf_chart" :style="{height: mnfchart_height+'px'}">
                    <svg/>
                  </div>
                
                -->
                <div>
                  <BlockMonitoringBarMnfChart :url="bar_url" ref="barchart" id="barchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:20px;"   />  
               </div> 
               </div> 
              </div>
            </div>
            <div id="tab_box02" :class="['tab_box',{ 'on' : activeIndex === 1 }]">
              <div class="info_box">
                <ul class="list">
                  <li>Block Name : {{tag_name}}  </li>
                </ul>
              </div>
              <div class="img_area">
                <div  class="large-chart-bg">
                 
                  <div ref="button_group_realtime">
                     <div style="position: absolute;top: 6%;right: 608px;z-index: 100;">
                      <!-- 2022 new -->
                        <!-- <button class="btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(-1)"><i class="mdi mdi-menu-left mdi-24px"></i></button> -->
                        <!-- <button class="btn-primaryBlack btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(1)"><i class="mdi mdi-menu-right mdi-24px"></i></button> -->
                        <button class="btn-primaryBlack btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(-1)"><i class="mdi mdi-menu-left mdi-24px"></i></button>
                        <button class="btn-primaryBlack btn mr-2 map-button icon_btn btn-secondary btn-sm1" @click="change_date_flow(1)"><i class="mdi mdi-menu-right mdi-24px"></i></button>
                    </div>
                    <b-form inline class="btn_list_wrap" style=" top: 6%; right: 100px;">
                      <b-input size="sm" v-if="isShowDate" v-model="flow_date" type="date" @change="change_flow_date($event)" class="input_date btn-gray-date m-0"/>
                      <b-button-group class="mr-2 btn_list">
                        <div class="btn-primary lookup_btn search" @click="showDate" style="  margin-right: 370px; width: 130px;">Search Date</div>  
  
                        <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='0' @change="change_selected_ts_flow($event)">
                          <span>1D</span>
                        </b-radio>
                        <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='-3' @change="change_selected_ts_flow($event)">
                          <span>3D</span>
                        </b-radio>
                        <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='-7' @change="change_selected_ts_flow($event)">
                          <span>7D</span>
                        </b-radio>
                         <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='-14' @change="change_selected_ts_flow($event)">
                          <span>14D</span>
                        </b-radio>                       
                        <b-radio class="min_btn" :button="true" size="sm" v-model="selected_ts_flow" value='-30' @change="change_selected_ts_flow($event)">
                          <span>30D</span>
                        </b-radio>
                      </b-button-group>
<!--                      <span>Realtime Monitoring</span>-->
                    </b-form>
                  </div>
                    <div  style="position:absolute; top:6%; right:30px;">
                      <span><button class="btn-primary btn btn-map-view ml-1  map-button icon_btn icon_btn_text"  @click="chart_exceldownload(2)" >{{$t('pressure_monitoring.excel_down')}}</button></span>
                    </div>
                  <div>
                      <div>
                        <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                    </div>
                      <BlockMonRealTime :url="real_url" ref="realchart" id="realchart" :height="mnfchart_height" style="position:relative;width:100%;height:100%;top:25px;"   />  
                  </div> 
                </div>
              </div>
            </div>
            <div id="tab_box03" :class="['tab_box ',{ 'on' : activeIndex === 2 }]">
             <div class="table_box_wrap scroll_box m2_flow">
               <span style="padding: 10px;top:-10px;float:left;"> water_flow / mnf</span>               
              <table class="with--bg">
                <colgroup>
                  <col style="width:110px">
                  <col style="width:55px">
                </colgroup>
                <thead>
                  <tr>
                    <th>yyyy-MM</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18</th>
                    <th>19</th>
                    <th>20</th>
                    <th>21</th>
                    <th>22</th>
                    <th>23</th>
                    <th>24</th>
                    <th>25</th>
                    <th>26</th>
                    <th>27</th>
                    <th>28</th>
                    <th>29</th>
                    <th>30</th>
                    <th>31</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                  
                    <td >{{item.ym}}</td>
                    <td v-for="(tb_v, index) in item.tb_v" :key="index">{{tb_v.flow}}<br>{{tb_v.mnf}} </td>
                  
                  </tr>
                
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>

      </div>

</template>

<script>
import SearchLeftType3 from '@/components/left/SearchLeftType3';
import * as d3 from 'd3'
import BlockMonitoringBarMnfChart from '@/components/chart/BlockMonitoringBarMnfChart' ;
import BlockMonRealTime from '@/components/chart/BlockMonRealTime' ;
import d3tip from "d3-tip";
d3.tip = d3tip;
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    name: 'BlockMonitoringDetail_r',
    props: [
    ],
    watch: {
    },
    data() {
        return {
            bar_url : '',
            real_url : '',
            tabs: [
              { 'name': 'Supplied Water / MNF' },
              { 'name': 'Supplied Water by Time' },
              { 'name': 'Table' }
            ],
            activeIndex: 0,
            activeCheckIndex: null,
            box: [
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'status': 'red'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'status': 'blue'
              },
              {
                'name': '대덕 소블록',
                'mnf': 23,
                'status': 'red'
              }
            ],
            isShowDate: false,
            isShowDate1: false,
            contents_height: 600,
            mnfchart_height: 550,
            realtimechart_height: 550,
            isInit : true,
            isInit1 : true,

            selected_ts_flow: '0',
            flow_date: this.$route.query.last_date,
            loaded_ts_flow: false,
            from_date : '',
            selected_ts_mnf: '30',
            mnf_date: this.$route.query.last_date,

            columns: [
                {label: 'Date', field: 'date_str'},
                {label: 'Supply(㎥/day)', field: 'flow'},
                {label: 'MNF', field: 'mnf'},
                {label: '%', field: 'rate'},
            ],
            table_mnf: [], //테이블 데이터 존재
            items: [
                   //   { id: 1, name: 'Item 1', sub: [{ title: 'SubItem Item 1-1'}, { title: 'SubItem Item 1-2'}]},
                   //   { id: 2, name: 'Item 2', sub: [{ title: 'SubItem Item 2-1'}, { title: 'SubItem Item 2-2'}]},
             ],
            ts_mnf: [],
            tag :'',  
            tag_name:'',
        }
    },
    methods: {
      on_goto_map_click(row) {
        var last_str = row.flow.substr(0,10)       
        this.$router.push({path: '/block-monitoring-map?w_type=160', query: { id: row.id, mea_date:last_str  }})     
        
      },
            chart_exceldownload(type) {
             // this.$http.get(this.URL+'api/'+idx).then((response) => {
              // 1:day chart, 2:minutes chart 
              var f_name= this.tag_name.replace("DMA ","")
              var surl = this.URL+'api/API21/flow/minute/'+this.tag+'?to='+this.flow_date+' 23:59&from='+this.from_date
              var name =f_name+'_'+ this.flow_date+'_'+this.selected_ts_flow+'_min';
              if(type === 1 ){
                 surl = this.URL+'api/API21/flow/day/'+encodeURI(this.tag)+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf
                 name = f_name+'_'+ this.mnf_date+'_'+this.selected_ts_mnf+'_day';
              }
              this.$http({
               // url: this.URL+'api/download/psr/'+idx,
               ///api/API21/FILE/575c5303-b633-40c2-858b-b9e54ec865be?outType=psrToJson
                url:  surl,
                method: 'GET',
                //responseType: 'blob',
                //data: JSON.stringify(data),
                responseType: 'json',
                contentType: 'application/json; charset=utf-8',
              }).then((response) => {
                // let data = JSON.parse(decodeURIComponent(response.data))
              //  let json_data = JSON.stringify(response.data) ;
                 let data = this.jsonToCSV(response.data)
                var fileURL = window.URL.createObjectURL(new Blob([data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', name+".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
                setTimeout(()=>{
                  ++this.psr_download_idx
                }, 200);
          
              })

        },
        jsonToCSV(json_data) { // 1-1. json 데이터 취득
          const json_array = json_data.results[0]; // 1-2. json데이터를 문자열(string)로 넣은 경우, JSON 배열 객체로 만들기 위해 아래 코드 사용 
          
          let csv_string = ''; // 3. 제목 추출: json_array의 첫번째 요소(객체)에서 제목(머릿글)으로 사용할 키값을 추출
          const titles = Object.keys(json_array[0]); // 4. CSV문자열에 제목 삽입: 각 제목은 컴마로 구분, 마지막 제목은 줄바꿈 추가 
            titles.forEach((title, index)=>{ csv_string += (index !== titles.length-1 ? `${title},` : `${title}\r\n`); }); 
        
            json_array.forEach((content, index)=>{ 
              let row = '';
              // 각 인덱스에 해당하는 '내용'을 담을 행
              for(let title in content){ // for in 문은 객체의 키값만 추출하여 순회함.
              // 행에 '내용' 할당: 각 내용 앞에 컴마를 삽입하여 구분, 첫번째 내용은 앞에 컴마X
                  row += (row === '' ? `${content[title]}` : `,${content[title]}`); } 
                  // CSV 문자열에 '내용' 행 삽입: 뒤에 줄바꿈(\r\n) 추가, 마지막 행은 줄바꿈X 
                  csv_string += (index !== json_array.length-1 ? `${row}\r\n`: `${row}`); }) 
              // 6. CSV 문자열 반환: 최종 결과물(string) 
          return csv_string;
        },  
        updateCheckIndex(payload) {
          this.activeCheckIndex = payload
          this.tag=this.box[payload].id
          this.tag_name=this.box[payload].name
          
          var s_date = this.box[payload].flow
          if(s_date !== null ) {  
            this.flow_date  = s_date.substring(0,10);
            this.mnf_date = s_date.substring(0,10);
          }
          if(this.activeIndex === 0 || this.activeIndex === 2  ){
             this.get_time_series_mnf() 
           //  this.bar_url = this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30#1';
          }else if(this.activeIndex === 1  ){
            this.get_time_series_flow()
          }else{
            this.get_time_series_flow()
          }          
        },
        change_date_mnf(a){
          this.isShowDate1 = true;
          let default_date = new Date(this.mnf_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.mnf_date = this.date_to_str(default_date)      
          this.get_time_series_mnf()
        },
        change_date_flow(a){
          this.isShowDate = true;
          let default_date = new Date(this.flow_date.toLocaleString());
           default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
          this.flow_date = this.date_to_str(default_date)
         
          this.get_time_series_flow()
        },
        date_to_str(format){
          var year = format.getFullYear();
          var month = format.getMonth() + 1;
          if(month<10) month = '0' + month;
          var date = format.getDate();
          if(date<10) date = '0' + date; 
     
          return year + "-" + month + "-" + date ;
      },

      go_search(value) {

       if(value===undefined) return;

          //left메뉴 데이터를 뿌려준다.
       this.box = [] ;
      // this.$http.get(this.URL+'api/get_monitoring_list_left?search='+value).then((rows) => {
        var customer_id = sessionStorage.select_customer_id ; 
        if(sessionStorage.select_customer_id === "27"){
          customer_id = 1
        }
          this.$http.get(this.URL+'api/API21/flow/list?customer_id='+customer_id+'&search='+value).then((res) => {          
              for(let i=0; i<res.data.results[0].length; i++) {
                if(this.tag === res.data.results[0][i].block_id ){
                  this.t_title =  res.data.results[0][i].block_name;
                  this.tag_name = res.data.results[0][i].block_name
                }           
                var abox = {};
                abox = {'id':res.data.results[0][i].id ,'name': res.data.results[0][i].point_name, 'mnf': Math.round(Number( res.data.results[0][i].night_min_flow)) , 
                'flow':  res.data.results[0][i].last_measure_datetime ,  'status': 'blue' } ; 
                this.box.push(abox) ; 
              }
          })

     
        },
        showDate() {
          this.isShowDate = !this.isShowDate
        },
          showDate1() {
          this.isShowDate1 = !this.isShowDate1
        },
        tabItem(index){
          this.activeIndex = index
          if(index === 1 && this.isInit) { 
            this.get_time_series_flow()
            this.isInit = false
          }else if(index === 2 && this.isInit1) {
            this.isInit1 = false  
          }
        },
        change_flow_date(e) {
            this.flow_date = e
            this.get_time_series_flow()
        },
        change_selected_ts_flow(e) {
            this.selected_ts_flow = e
            this.get_time_series_flow()
        },
        change_from_date_flow(a){
          if(a==="0"){
            this.from_date = this.flow_date
          }else{
            let default_date = new Date(this.flow_date.toLocaleString());
             default_date.setDate(default_date.getDate()+Number(a)).toLocaleString() ;
             this.from_date = this.date_to_str(default_date)
          }
         
       
        },
        get_time_series_flow() {
            this.loaded_ts_flow = false
            this.change_from_date_flow(this.selected_ts_flow)
         //   this.real_url = this.URL+'api/get_time_series?type=flow&tag='+encodeURI(this.tag)+'&start_date='+this.flow_date+'&limit='+this.selected_ts_flow+"#1";
            //this.real_url = this.URL+'api/API21/block_minute/'+this.tag+'?to='+this.flow_date+' 23:59&from='+this.selected_ts_flow+"#1";          
             this.real_url = this.URL+'api/API21/flow/minute/'+this.tag+'?to='+this.flow_date+' 23:59&from='+this.from_date+"#1";          
            /*
            this.$http.get(this.URL+'api/get_time_series?type=flow&tag='+encodeURI(this.$route.query.tag)+'&start_date='+this.flow_date+'&limit='+this.selected_ts_flow).then((res) => {
                let ts_flow = res.data.rows
               // var parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S.000Z");
                var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S");
                for(let i=0; i<ts_flow.length; i++) {
                    ts_flow[i]['date_time'] = parseTime(ts_flow[i]['date_time'])
                }
                this.loaded_ts_flow = true
                this.draw_flow_chart(ts_flow)
            });
            */
            this.loaded_ts_flow = true
        },
        change_mnf_date(e) {
            this.mnf_date = e
            this.get_time_series_mnf()
        },
        change_selected_ts_mnf(e) {
            this.selected_ts_mnf = e
            this.get_time_series_mnf()
        },
        get_time_series_mnf() {
       //   this.bar_url = this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30#1';
          this.bar_url = this.URL+'api/API21/flow/day/'+this.tag+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+'#1';
          this.get_time_table_mnf();
        },
        get_time_table_mnf(){
          //  this.$http.get(this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30#1').then((res) => {
            this.$http.get(this.URL+'api/API21/flow/day/'+this.tag+'?to='+this.mnf_date+'&limit='+this.selected_ts_mnf+'#1').then((res) => {                
                this.ts_mnf = res.data.results[0]
                var parseTime = d3.timeParse("%Y-%m-%d");
                this.table_mnf = []

                //신규 가로날짜에 데이터 앉히기
                var old_ym = '';
                var s_day = 1 ;
                var tb_v = [] ;
                this.items =[] ;
                for(let i=0; i<this.ts_mnf.length; i++) {
                    this.ts_mnf[i]['date_str'] = this.ts_mnf[i]['measure_datetime']
                    this.ts_mnf[i]['date'] = parseTime(this.ts_mnf[i]['measure_datetime'])
                   // this.ts_mnf[i]['rate'] = this.ts_mnf[i]['mnf'] / (this.ts_mnf[i]['flow']/24) * 100
                    this.ts_mnf[i]['rate'] = this.ts_mnf[i]['night_min_rate'] 
                   // if(i === 0 || this.ts_mnf[i]['date_str'].substring(0,7) !== this.ts_mnf[i]['date_str'].substring(0,7) ){
                    if(i=== 0 ){
                      old_ym =  this.ts_mnf[i]['date_str'].substring(0,7)  //2020-09   
                      s_day = this.ts_mnf[i]['date_str'].substring(8,10)   //01     
                       for(var m=1 ; m < s_day ; ++m  ){ //월이 바뀔시 시작시 앞에 날수에 데이터가 없을 시 데이터 앉히기
                         tb_v.push({date_str: '', flow : '',mnf : '',rate : '' })
                       }             
                    }
                    if(old_ym !==  this.ts_mnf[i]['date_str'].substring(0,7) ){ //달이 바뀌었다면 그 이전달의 데이터 없는 날의 이후 날수에 맞게 데이터를 앉힌다.
                      var abox = {}; //초기화하고
                      var bk = 31 - tb_v.length ;
                      for(var j = 0 ; j < bk ;++j){
                          tb_v.push({date_str: '', flow : '',mnf : '',rate : '' })
                        }
                      abox = {'ym': old_ym, "tb_v" : tb_v } ; //뿌려질 월별 요일 데이터를 앉힌다.
                      this.items.push(abox) ; 
                       tb_v = []        
                       old_ym =  this.ts_mnf[i]['date_str'].substring(0,7)  
                       s_day = this.ts_mnf[i]['date_str'].substring(8,10)        
                       for(var mi=1 ; mi < s_day ; ++mi  ){ //월이 바뀔시 시작시 앞에 날수에 데이터가 없을 시 데이터 앉히기
                           tb_v.push({date_str: '', flow : '',mnf : '',rate : '' })
                       }
                    }
                   // this.table_mnf.push({date_str: this.ts_mnf[i]['date_str'], flow: this.ts_mnf[i]['flow'].toFixed(0),
                   //     mnf: this.ts_mnf[i]['mnf'].toFixed(0), rate: this.ts_mnf[i]['rate'].toFixed(0)    })
                   
                     this.table_mnf.push({
                        date_str: this.ts_mnf[i]['date_str'],
                        flow: this.ts_mnf[i]['water_flow'],
                        mnf: this.ts_mnf[i]['night_min_flow'],
                        rate: this.ts_mnf[i]['rate']
                    })
                    // 만약 15일부터 데이터가 앉혀진다.

                   tb_v.push({date_str: this.ts_mnf[i]['date_str'], flow: this.ts_mnf[i]['water_flow']=== null ? '-': this.ts_mnf[i]['water_flow'],
                        mnf: this.ts_mnf[i]['night_min_flow'] === null ? '-': this.ts_mnf[i]['night_min_flow'], rate: this.ts_mnf[i]['rate']    })


                }//for문 끝
                var bk1 = 31 - tb_v.length ;//달이 안바뀐 상태에서 다시 데이터가 24일까지 밖에 없으면 나머지 31일까지 빈공간으로 데이터를 채워준다.
                for(var k = 0 ; k< bk1 ;++k){
                  tb_v.push({'date_str': '', 'flow' : '','mnf' : '','rate' : '' })
                }
               // var abox1 = {};
                // abox1 = {'ym': old_ym, "tb_v" : tb_v } ; 
                this.items.push({'ym': old_ym, "tb_v" : tb_v }) ; 
             //   this.draw_mnf_chart(this.ts_mnf)
            });
        },
  
    },
    mounted () {
        this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight
       // let left_height = this.contents_height - (this.$refs.button_group_mnf.clientHeight*2)
      //  this.mnfchart_height = left_height 
      //  this.realtimechart_height = this.mnfchart_height
      // this.bar_url = this.URL+'api/get_time_series?type=flow_mnf&tag='+encodeURI(this.tag)+'&start_date='+this.mnf_date+'&limit=30#1';
        //left메뉴 데이터를 뿌려준다.
        this.tag= Number(this.$route.query.tag) ;
       this.box = [] ;
   //    this.$http.get(this.URL+'api/get_monitoring_list_left').then((rows) => {
        var customer_id = sessionStorage.select_customer_id ; 
        if(sessionStorage.select_customer_id === "27"){
          customer_id = 1
        }     
        this.$http.get(this.URL+'api/API21/flow/list?customer_id='+customer_id).then((res) => {
          
          for(let i=0; i<res.data.results[0].length; i++) {
            if(this.tag === res.data.results[0][i].id ){
              this.t_title =  res.data.results[0][i].w_title;
              this.tag_name= res.data.results[0][i].point_name
             }           
            var abox = {};
            abox = {'id':res.data.results[0][i].id ,'name': res.data.results[0][i].point_name, 'mnf': Math.round(Number( res.data.results[0][i].night_min_flow)) , 
            'flow':  res.data.results[0][i].last_measure_datetime ,  'status': 'blue' } ; 
                  this.box.push(abox) ; 
          }
       })
        this.get_time_series_mnf()
        this.get_time_series_flow()
        setTimeout(()=>{
            var elem = document.getElementsByClassName("page-wrapper chiller-theme toggled") 
            if( elem !== undefined ){
            if(elem.length > 0){
               document.getElementsByClassName("un-toggle-menu-btn-tri-off")[0].click();         
            }
         }
       }, 200)
    },
    beforeDestroy () {
    },
    components: {
        PulseLoader: PulseLoader,
        SearchLeftType3,
        BlockMonitoringBarMnfChart:BlockMonitoringBarMnfChart,
        BlockMonRealTime:BlockMonRealTime,
    }
}
</script>

<style  lang="scss" >
.map-button.icon_btn.btn {
    padding-top: 0;
}
.d3-tip td {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}
.btn_list_wrap .btn_list .lookup_btn.search{
    margin-right: 0px;
}
.map-button.icon_btn.btn.btn-sm1{
  padding: 0;
}
.btn_list_wrap .btn_list .min_btn .btn.btn-secondary  {
      margin-right: 5px;
}
.table_box_wrap.scroll_box.m2_flow{
  width : 90%;
  height: 75vh;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
//import store from '@/store'

import Home from '@/views/Home';
import Login from '@/views/Login';
import Dashboard from '@/views/Dashboard';
import GISMaster from '@/views/GIS/GISMaster';
import GIS_DMA from '@/views/GIS/GIS_DMA';
import Work_Block from '@/views/Leak/Work_Block';
//import GIS_Modeling from '@/views/GIS_Modeling';
import GIS_Simulation from '@/views/GIS/GIS_Simulation';
import GIS_SimulationDetail from '@/views/GIS/GIS_SimulationDetail';

import GISTopologyCheck from '@/views/GIS/GISTopologyCheck';
import BlockMonitoring from '@/views/Flow/BlockMonitoring';
import WaterPressure from '@/views/Pressure/WaterPressure';
import PressureDetail from '@/views/Pressure/PressureDetail';
import PressureDetailMap from '@/views/Pressure/PressureDetailMap';
import PressureDetailMap1 from '@/views/Pressure/PressureDetailMap1';
import PressureDetailTab from '@/views/Pressure/PressureDetailTab';
import PressureMonitoring from '@/views/Pressure/PressureMonitoring';
import PressureMonitoringDetail from '@/views/Pressure/PressureMonitoringDetail';
import PressureMonitoringDetailTab from '@/views/Pressure/PressureMonitoringDetailTab';
import PressureMonitoringMap from '@/views/Pressure/PressureMonitoringMap';
import LeakMaster from '@/views/Leak/LeakMaster';
import LeakMaster1 from '@/views/Leak/LeakMaster1';
import LeakDetail from '@/views/Leak/LeakDetail';
import LeakMonitoring from '@/views/Leak/LeakMonitoring';
import LeakMonitoringDetail from '@/views/Leak/LeakMonitoringDetail';
import LeakMonitoringMap from '@/views/Leak/LeakMonitoringMap';
import LeakMonitoringMap1 from '@/views/Leak/LeakMonitoringMap1';
//import LeakDetail1 from '@/views/LeakDetail1';
import LeakRecovery from '@/views/Mgnt/LeakRecovery';
import LeakRecoveryDetail from '@/views/Mgnt/LeakRecoveryDetail';
import LeakRecoveryMap from '@/views/Mgnt/LeakRecoveryMap';
import Maintenance from '@/views/Mgnt/Maintenance';
import MaintenanceDetail from '@/views/Mgnt/MaintenanceDetail';
import MaintenanceMap from '@/views/Mgnt/MaintenanceMap';

import WaterAccounts from '@/views/Etc/WaterAccounts';
import AccountsDetail from '@/views/Etc/AccountsDetail';
import LeakAnalysis_c from '@/views/Leak/LeakAnalysis';
import LeakAnalysis1_c from '@/views/Leak/LeakAnalysis1';
import WaterMeterReading from '@/views/Etc/WaterMeterReading';
import WaterQuality from '@/views/Etc/WaterQuality';
import BlockMonitoringDetailTab from '@/views/Flow/BlockMonitoringDetailTab';
import BlockMonitoringDetail from '@/views/Flow/BlockMonitoringDetail';
import BlockMonitoringMap from '@/views/Flow/BlockMonitoringMap';
//m1 누수로거
import WaterLeakLogger from '@/views/Leak/WaterLeakLogger';
import WaterLeakLoggerDetail from '@/views/Leak/WaterLeakLoggerDetail';
import WaterLeakLoggerMap from '@/views/Leak/WaterLeakLoggerMap';
//m1 유량로거
import WaterFlowLogger from '@/views/Flow/WaterFlowLogger';
import WaterFlowLoggerDetail from '@/views/Flow/WaterFlowLoggerDetail';
import WaterFlowLoggerMap from '@/views/Flow/WaterFlowLoggerMap';
import WaterTank from '@/views/Etc/WaterTank';
import WaterTankDetail from '@/views/Etc/WaterTankDetail';
import Pumping from '@/views/Etc/Pumping';
import LogHistory from '@/views/Admin/LogHistory';
import WorkerLog from '@/views/Admin/WorkerLog';
import ServiceLog from '@/views/Admin/ServiceLog';
import IoTDevice from '@/views/Admin/IoTDevice';
import EventLog from '@/views/Admin/EventLog';
//import Pump from '@/views/Pump';
import PumpingStationDetail from '@/views/Etc/PumpingStationDetail';

import HydraulicMonitoring from '@/views/Pressure/HydraulicMonitoring';
import HydraulicMonitoringDetail from '@/views/Pressure/HydraulicMonitoringDetail';
import HydraulicMonitoringDetailTab from '@/views/Pressure/HydraulicMonitoringDetailTab';
import HydraulicMonitoringMap from '@/views/Pressure/HydraulicMonitoringMap';
import HydraulicPointRegMap from '@/views/Pressure/HydraulicPointRegMap';
//
import LeakDataSetDetail from '@/views/Dataset/LeakDataSetDetail';
//import LeakDataSetDetail1 from '@/views/LeakDataSetDetail1';
import LeakDataSet from '@/views/Dataset/LeakDataSet';
import ScadaDataChart from '@/views/Scada/ScadaDataChart';
import AI_modeling from '@/views/Dataset/AI_modeling';

Vue.use(VueRouter)

const requireAuth = () => (from, to, next) => {
    //if (store.state.accessToken) return next()
   // const {accessToken} = localStorage
    const {accessToken} = sessionStorage
    if (accessToken) return next()
    next('/login')
}

const routes = [
  {    path: '/',    name: 'home',    component: Home,    beforeEnter: requireAuth(),     // alias: ['/'+localStorage.level+'/']
  },
  { path: '/login',    name: 'login',    component: Login  },
  {    path: '/gis-master',    name: 'gis-master',    component: GISMaster,    beforeEnter: requireAuth(),    //  alias: ['/'+localStorage.level+'/gis-master'] 
  },
  { path: '/dashboard', name: 'dashboard',    component: Dashboard,    beforeEnter: requireAuth(),    },
  { path: '/gis-dma', name: 'gis-dma',    component: GIS_DMA,    beforeEnter: requireAuth(),    },
  { path: '/work-block', name: 'work-block',    component: Work_Block,    beforeEnter: requireAuth(),    },
  //{ path: '/gis-modeling', name: 'gis-modeling',    component: GIS_Modeling,    beforeEnter: requireAuth(),    },
  { path: '/gis-topologycheck',    name: 'gis-topologycheck',    component: GISTopologyCheck,    beforeEnter: requireAuth(),   },
  { path: '/simulation', name: 'simulation',    component: GIS_Simulation,    beforeEnter: requireAuth(),    },
  { path: '/simulation-detail', name: 'simulation-detail',    component: GIS_SimulationDetail,    beforeEnter: requireAuth(),    },
  //water-flow
  { path: '/block-monitoring',    name: 'block-monitoring',    component: BlockMonitoring,    beforeEnter: requireAuth()  },
  { path: '/block-monitoring-detail-tab',    name: 'block-monitoring-detail-tab',    component: BlockMonitoringDetailTab,   beforeEnter: requireAuth()},
  { path: '/block-monitoring-detail',  name: 'block-monitoring-detail',   component: BlockMonitoringDetail,   beforeEnter: requireAuth()},
  { path: '/block-monitoring-map',  name: 'block-monitoring-map',   component: BlockMonitoringMap,   beforeEnter: requireAuth()},

 //m1 누수음 로거
 {   path: '/water-leak-logger',   name: 'water-leak-logger',    component: WaterLeakLogger,    beforeEnter: requireAuth()},
 {   path: '/water-leak-logger-detail',  name: 'water-leak-logger-detail',   component: WaterLeakLoggerDetail,    beforeEnter: requireAuth()},
 {   path: '/water-leak-logger-map',    name: 'water-leak-logger-map',    component: WaterLeakLoggerMap,    beforeEnter: requireAuth()  },
  //m1 유량 로거
  {   path: '/water-flow-logger',   name: 'water-flow-logger',    component: WaterFlowLogger,    beforeEnter: requireAuth()},
  {   path: '/water-flow-logger-detail',  name: 'water-flow-logger-detail',   component: WaterFlowLoggerDetail,    beforeEnter: requireAuth()},
  {   path: '/water-flow-logger-map',    name: 'water-flow-logger-map',    component: WaterFlowLoggerMap,    beforeEnter: requireAuth()  },
  //m1 압력 로거
  {   path: '/water-pressure',   name: 'water-pressure',    component: WaterPressure,    beforeEnter: requireAuth()},
  {   path: '/pressure-detail',  name: 'pressure-detail',   component: PressureDetail,    beforeEnter: requireAuth()},
  {   path: '/pressure-detail-map',    name: 'pressure-detail-map',    component: PressureDetailMap,    beforeEnter: requireAuth()  },
  {   path: '/pressure-detail-map1',    name: 'pressure-detail-map1',    component: PressureDetailMap1,    beforeEnter: requireAuth()  },
  {   path: '/pressure-detailT', name: 'pressure-detailT', component: PressureDetailTab, beforeEnter: requireAuth() }, 
  //m2-monitoring 
  { path: '/pressure-monitoring', name: 'pressure-monitoring', component: PressureMonitoring, beforeEnter: requireAuth() },
  { path: '/pressure-monitoring-detail', name: 'pressure-monitoring-detail', component: PressureMonitoringDetail, beforeEnter: requireAuth() }, 
  { path: '/pressure-monitoring-detailT', name: 'pressure-monitoring-detailT', component: PressureMonitoringDetailTab, beforeEnter: requireAuth() }, 
  { path: '/pressure-monitoring-map', name: 'pressure-monitoring-map', component: PressureMonitoringMap, beforeEnter: requireAuth() },
 //m2-압력 동수두분석
 { path: '/hydraulic-monitoring', name: 'hydraulic-monitoring', component: HydraulicMonitoring, beforeEnter: requireAuth() },
 { path: '/hydraulic-monitoring-detail', name: 'hydraulic-monitoring-detail', component: HydraulicMonitoringDetail, beforeEnter: requireAuth() }, 
 { path: '/hydraulic-monitoring-detailT', name: 'hydraulic-monitoring-detailT', component: HydraulicMonitoringDetailTab, beforeEnter: requireAuth() }, 
 { path: '/hydraulic-monitoring-map', name: 'hydraulic-monitoring-map', component: HydraulicMonitoringMap, beforeEnter: requireAuth() },
 { path: '/hydraulic-point-reg-map', name: 'hydraulic-point-reg-map', component: HydraulicPointRegMap, beforeEnter: requireAuth() },
  //누수음
  { path: '/leak-master',    name: 'leak-master',    component: LeakMaster,    beforeEnter: requireAuth()  },
  { path: '/leak-master1',    name: 'leak-master1',    component: LeakMaster1,    beforeEnter: requireAuth() },
  { path: '/leak-detail', name: 'leak-detail', component: LeakDetail, beforeEnter: requireAuth() },
  //{ path: '/leak-detail1', name: 'leak-detail1', component: LeakDetail1, beforeEnter: requireAuth() },
  {    path: '/leak-analysis',    name: 'leak-analysis',    component: LeakAnalysis_c,    beforeEnter: requireAuth()  },
  {    path: '/leak-analysis1',    name: 'Map-leak-analysis',    component: LeakAnalysis1_c,    beforeEnter: requireAuth()  },
  //
  { path: '/dataset', name: 'dataset', component: LeakDataSet, beforeEnter: requireAuth() },
  { path: '/aI-modeling', name: 'aI-modeling', component: AI_modeling, beforeEnter: requireAuth() },
  { path: '/dataset-detail', name: 'datasetdetail', component: LeakDataSetDetail, beforeEnter: requireAuth() },
  
  { path: '/scadadata-chart', name: 'scadadata-chart', component: ScadaDataChart, beforeEnter: requireAuth() },
  
  //m2 누수음
  { path: '/leak-monitoring', name: 'leak-monitoring', component: LeakMonitoring, beforeEnter: requireAuth() },  
  { path: '/leak-monitoring-detail', name: 'leak-monitoring-detail', component: LeakMonitoringDetail, beforeEnter: requireAuth() },
  { path: '/leak-monitoring-detail-map', name: 'leak-monitoring-detail-map', component: LeakMonitoringMap, beforeEnter: requireAuth() },
  { path: '/leak-monitoring-detail-map1', name: 'leak-monitoring-detail-map1', component: LeakMonitoringMap1, beforeEnter: requireAuth() },
  //누수복구
  { path: '/leak-recovery', name: 'leak-recovery', component: LeakRecovery, beforeEnter: requireAuth() },
  { path: '/leak-recovery-detail', name: 'leak-recovery-detail', component: LeakRecoveryDetail, beforeEnter: requireAuth() },
  { path: '/leak-recovery-detail-map', name: 'leak-recovery-detail-map', component: LeakRecoveryMap, beforeEnter: requireAuth() },
  //누수복구 popup 새창으로 만들기
  { path: '/leakRecove_popup_info',  name: 'leakRecove_popup_info',    component: () => import( '../components/popup/DlgLeakRecoveryInfo_pop.vue')  },

  { path: '/water-accounts',    name: 'water-accounts',    component: WaterAccounts,    beforeEnter: requireAuth()  },
  { path: '/accounts-detail',   name: 'accounts-detail',    component: AccountsDetail,    beforeEnter: requireAuth()  },
  //유지보수
  { path: '/maintenance',    name: 'maintenance',    component: Maintenance,    beforeEnter: requireAuth()  },
  { path: '/maintenance-detail',    name: 'maintenance-detail',    component: MaintenanceDetail,    beforeEnter: requireAuth()  },
  { path: '/maintenance-detail-map',    name: 'maintenance-detail-map',    component: MaintenanceMap,    beforeEnter: requireAuth()  },
  { path: '/pumping',    name: 'pumping',    component: Pumping,    beforeEnter: requireAuth()  },
  { path: '/log-history',    name: 'LogHistory',    component: LogHistory,    beforeEnter: requireAuth()  },
  { path: '/worker-log',    name: 'WorkerLog',    component: WorkerLog,    beforeEnter: requireAuth()  },  
  {    path: '/iot-device',    name: 'IoTDevice',    component: IoTDevice,    beforeEnter: requireAuth()  },  
  {    path: '/event-log',    name: 'EventLog',    component: EventLog,    beforeEnter: requireAuth()  },  
  {    path: '/service-log',    name: 'ServiceLog',    component: ServiceLog,    beforeEnter: requireAuth()  },  
  {    path: '/pumping-station-detail',    name: 'pumpingStationDetail',    component: PumpingStationDetail,    beforeEnter: requireAuth()  },
  {    path: '/water-tank',    name: 'water-tank',    component: WaterTank,    beforeEnter: requireAuth()  },
  {    path: '/water-tank-detail',    name: 'water-tank-detail',    component: WaterTankDetail,    beforeEnter: requireAuth()  },
  {    path: '/water-quality',    name: 'water-quality',    component: WaterQuality,    beforeEnter: requireAuth()  },
  {    path: '/water-meter-reading',    name: 'water-meter-reading',    component: WaterMeterReading,    beforeEnter: requireAuth()  },
  {    path: '/customer-voice',    name: 'customer-voice',    component: () => import('../views/Etc/CustomerVoice'),    beforeEnter: requireAuth()  },
  //2024-08-30 추가
  {    path: '/customer',    name: 'customer',    component:  () => import('../views/Admin/Customer'),    beforeEnter: requireAuth()  },  

  {    path: '/member',    name: 'member',    component: () => import('../views/Member'),  beforeEnter: requireAuth()  },
  {    path: '/about',    name: 'about',    component: () => import( '../views/About.vue')  }  ,
  //popup 새창으로 만들기
  {    path: '/leak_popup_info',    name: 'leak_popup_info',    component: () => import( '../components/popup/DlgLeakageInfo_pop.vue')  },
  //popup 새창으로 만들기
  {    path: '/m_leak_popup_info',    name: 'm2_leak_popup_info',    component: () => import( '../components/popup/DlgM2LeakageInfo_pop.vue')  },
  {    path: '/web-accounts',    name: 'web-accounts',    component: () => import( '../views/WebAccount.vue')  },

  {    path: '/pressHammer-monitoring-detail',    name: 'pressHammer-monitoring-detail',    component: () => import('../views/Pressure/PressMoniHammer'),  beforeEnter: requireAuth()  },
  {    path: '/pressHammer-detail-chart',    name: 'pressHammer-detail-chart',    component: () => import('../views/Pressure/PressMoniHammerTab'),  beforeEnter: requireAuth()  },
]

 
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

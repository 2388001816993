<template>
 
    <div class="table_block table_block_100p" @click="goRouter('/water-tank-detail')" style='width:100%;'>

          <div class="title_box">
                      <h3 class="fln"> {{ dma_name }}  :    V (3,000 ㎥), H (4m),Count(10)</h3>
                      <span class="date">{{measure_date}}</span>
                      <div class="water_div">
                        <div class="water_li bb0">
                          <div class="title">Water flow (in)</div>
                          <div class="con">
                            <span class="num">{{flow_in}}</span>
                            <span class="unit">㎥/hr</span>
                          </div>
                          <div class="title">Water level #1</div>
                          <div class="con">
                            <span class="num">{{level_1}}</span>
                            <span class="unit">m</span>
                          </div>
                            <div class="title">Water level #2</div>
                          <div class="con">
                            <span class="num">{{level_2}}</span>
                            <span class="unit">m</span>
                          </div>
                        </div>
                        <div class="water_li">
                          <div class="title">Water flow (out)</div>
                          <div class="con">
                            <span class="num">{{flow_out}}</span>
                            <span class="unit">㎥/hr</span>
                          </div>
                          <div class="title">Water level #3</div>
                          <div class="con">
                            <span class="num">{{level_3}}</span>
                            <span class="unit">m</span>
                          </div>
                            <div class="title">Water level #4</div>
                          <div class="con">
                            <span class="num">{{level_4}}</span>
                            <span class="unit">m</span>
                          </div>
                        </div>
                      </div>
                    </div>
  



      <div class="con_box">
        <table>
          <colgroup>
            <col style="width:16.666%">
          </colgroup>
          <thead>
          <tr>
            <th></th>
            <th class="bg">1</th>
            <th class="bg">2</th>
            <th class="bg">3</th>
            <th class="bg">4</th>
            <th class="bg">5</th>
            <th class="bg">6</th>
            <th class="bg">7</th>            
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="bg"><p class="unit"><span>Water flow out(㎥/hr)</span></p></td>
            <td>{{flow[0]}}</td>
            <td>{{flow[1]}}</td>
            <td>{{flow[2]}}</td>
            <td>{{flow[3]}}</td>
            <td>{{flow[4]}}</td>
            <td>{{flow[5]}}</td>
            <td>{{flow[6]}}</td>            
          </tr>
          <tr>
            <td class="bg"><p class="unit"><span>Lowest water level (m)</span></p></td>
            <td>{{mnf[0]}}</td>
            <td>{{mnf[1]}}</td>
            <td>{{mnf[2]}}</td>
            <td>{{mnf[3]}}</td>
            <td>{{mnf[4]}}</td>
            <td>{{mnf[5]}}</td>
            <td>{{mnf[6]}}</td>            
          </tr>
          <tr>
            <td class="bg"><p class="unit"><span>Highest water level (m)</span></p></td>
            <td>{{rate[0]}}</td>
            <td>{{rate[1]}}</td>
            <td>{{rate[2]}}</td>
            <td>{{rate[3]}}</td>
            <td>{{rate[4]}}</td>
            <td>{{rate[5]}}</td>
            <td>{{rate[6]}}</td>            
          </tr>
           <tr>
            <td class="bg"><p class="unit"><span>Usage rate (%)</span></p></td>
            <td>{{rate1[0]}}%</td>
            <td>{{rate1[1]}}%</td>
            <td>{{rate1[2]}}%</td>
            <td>{{rate1[3]}}%</td>
            <td>{{rate1[4]}}%</td>
            <td>{{rate1[5]}}%</td>
            <td>{{rate1[6]}}%</td>            
          </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_next"></div>
    </div>

 
</template>

<script>

export default {
    name: 'waterCard',
    props: [
        'dma_name',
        'tag'
    ],
    data() {
        return {
            now_flow: null,
            now_mnf: null,
            last_date : null, 
            flow: ['0','0','0','0','0','0','0'],
            mnf: ['0','0','0','0','0','0','0'],
            rate:['0','0','0','0','0','0','0'],
            rate1:['0','0','0','0','0','0','0'],
            title_color: 'red',
            flow_in: '',
            flow_out: '' ,
            level_1: '' ,level_2: '' ,level_3: '' ,level_4: '' ,
            title_m : [
             {'name': '남문배수지', 'flow_in': 16.9,'flow_out': 130 , 'level_1': 3.1 , 'level_2': 0 , 'level_3': 3.62 , 'level_4': 2.96},
             {'name': '동문배수지', 'flow_in': 925,'flow_out': 69 , 'level_1': 3.18 , 'level_2': 3.18 , 'level_3': 3.18 , 'level_4': ''},
             {'name': '안면배수지', 'flow_in': 15.6,'flow_out': 162 , 'level_1': 3.01 , 'level_2': 2.99 , 'level_3': 2.8 , 'level_4': 2.77},
           ]
        }
    },
    methods: {
        goRouter(path) {
            this.$router.push({path: path, query: { tag: this.tag, last_date : this.last_date }})
        }
    },
    mounted () {
      // this.chartData2.push( ts_mnf[i]['max_total_head'].toFixed(0));
      // this.chartData3.push(ts_mnf[i]['min_total_head'].toFixed(0));
        this.title_color = 'white'
        /*
        for(var i= 0 ; i < this.title_m.length ;++i){
            if(this.dma_name ===this.title_m[i].name ){
              this.flow_in = this.title_m[i].flow_in; this.flow_out = this.title_m[i].flow_out;
              this.level_1 = this.title_m[i].level_1; this.level_2 = this.title_m[i].level_2;
              this.level_3 = this.title_m[i].level_3; this.level_4 = this.title_m[i].level_4;
              this.measure_date = '2020-09-30 13:10' ;
              break;
            }
        }
        */
              this.$http.get(this.URL+'api/API21/watertank/list/'+this.tag).then((res) => {
              if(res.data.results[0].length >0) {
                  this.measure_date = res.data.results[0][0].date_time
                  this.flow_in = res.data.results[0][0].in_flow_rate; 
                  this.flow_out =  res.data.results[0][0].out_flow_rate;
                  this.level_1 =  res.data.results[0][0].water_level_1; 
                  this.level_2 = res.data.results[0][0].water_level_2;   
                  this.level_3 =  res.data.results[0][0].water_level_3; 
                  this.level_4 = res.data.results[0][0].water_level_4;   
              }
             
        });
       // this.$http.get(this.URL+'api/get_watertank_series?type=day&item=0&tag='+encodeURI(this.tag)+'&start_date=2020-09-01&limit=7').then((res) => {
          this.$http.get(this.URL+'api/API21/watertank_day/'+this.tag+'?limit=7').then((res) => {
            this.flow = []
            this.mnf = []
            this.rate = []
             this.rate1 = []
            for(let i=0; i<res.data.results[0].length; i++) {
                if(i===0) {
                   this.last_date = res.data.results[0][i].water_date
                    this.now_flow = (res.data.results[0][i].out_flow/24).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  //  this.now_mnf = res.data.rows[i].mnf.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                

                }
                this.flow.push( res.data.results[0][i].out_flow.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") )
                this.mnf.push( res.data.results[0][i].lowest_water_level.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") )
                this.rate.push( res.data.results[0][i].highest_water_level.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") )
                 this.rate1.push( res.data.results[0][i].usage_rate.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") )
            }
        });

    },
    beforeDestroy () {
    },
    components: {
    }
}


</script>

<style scoped lang="scss">
.table_block {
  &:nth-child(even) {
    margin-right: 0;
  }
 // background-color:#dde9f3 ;
  background-color:#94999d ;
}
.table_wrap.pump .table_block .con_box table td {
    padding: 8px 0;
}

.table_wrap .table_block .title_box .water_li .title {

    text-align: right;
}
</style>

<template>
  <div class="left_area">
    <div class="left_list_box">
      <div class="input_search_box" >
        <input type="text" class="input_search" v-model="value"   placeholder="Search..."  @keyup.enter ="Enter_Check">
        <input type="submit" class="input_submit" @click="$emit('go_search',  value)" >
      </div>
      <div class="list_box_wrap" style="transform: translateZ(0);-webkit-transform: translateZ(0);">
        <div :class="['box_list box_list02', { 'is--active': activeCheckIndex === index }]" v-for="(item, index) in box" :key="index" @click="selectBox(index)">
          <div class="con_box">
            <p class="title">{{ item.name }}</p>
            <span class="unit">MNF : {{ item.mnf }} ㎥/hr</span>
            <span class="unit"> : {{ item.flow }}</span>
            <!--div :class="['status_box', statusColor(item)]"></div-->
              <!-- <div style="    position: absolute;    top: 30%;    right: 4%; z-index:1000;" > -->
              <div style="position: absolute;top: 23%;right: 5%;width: 28px; z-index: 1000;" >
                <!-- 2022 new -->
                <!-- <img src="@/assets/images/Common/icon/setting.png" alt="" @click="go_m2_info(item.id)" style="margin-bottom:10px;width:28px;margin-right:5px;"> -->
                <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="go_m2_info(item.id)" style="margin-bottom:10px;width:28px;margin-right:5px; width: 70%;">
                <!--img src="@/assets/images/Common/icon/map_1.png" alt="" @click="goRouter('/pressure-monitoring-map?w_type=111',item.id,item.flow )"-->
                <!-- 2022 new -->
                <!-- <img src="@/assets/images/Common/icon/map_1.png" alt="" @click="$emit('on_goto_map_click',  item)" style="float:right;width:28px;" > -->
                <img src="@/assets/images/new/ic_list_map.svg" alt="" @click="$emit('on_goto_map_click',  item)" style="width: 70%" >
              </div>
          </div>
        </div>
      </div>
    </div>
    <DlgM2MoniSetting ref="m2_setting" />
  </div>
</template>

<script>
import DlgM2MoniSetting from '@/components/popup/DlgM2MoniSetting' 
export default {
  name: 'SearchLeftType3',
  data() {
    return {
      value: ''
    }
  },
  props: {
    activeCheckIndex: {
      type: null
    },
    box: {
      type: Array
    }
  },
  methods: {
    statusColor(item) {
      return item.status === 'red' ? 'status_02': 'status_01'
    },
    go_m2_info(m2_point_id){
      this.$refs.m2_setting.show(m2_point_id)
    },    
    selectBox (index) {
      this.$emit('updateCheckIndex', index)

    },
    Enter_Check(){
       if(event.keyCode == 13){
          this.$emit('go_search',  this.value) ;  // 실행할 이벤트
       }
    },
  },
  components: {      
      DlgM2MoniSetting : DlgM2MoniSetting,
  }
};
</script>

<style lang="scss" scoped>
.detail__work-title {
  font-size: 13px;
  margin-top: 1%;
  margin-bottom: 2%;
  &:before {
    content: '●';
    font-size: 10px;
    padding-right: 5px;
  }
}
// 2022 new
 .left_area .list_box_wrap{
    height: calc(100vh - 85px - 37px - 28px - 26px - 60px);
 }
//  2023
.left_area .list_box_wrap .box_list.box_list02 {
  background: url(../../assets/images/new/ic_04_w.png) left 7% center no-repeat;
}
</style>

<template>
  <div>
   <pulse-loader class="loading" v-if="!loaded_ts_flow" loading="loading" color="#FF5733" />
                    
    <pressChart1 refclass="hchart" v-bind:chartData="chartData"  v-bind:chartData1="chartData1" v-bind:sdate="sdate" v-bind:ymin="min"
      v-bind:interval="interval"    v-if="loaded"    v-bind:chart_type ="chart_type" v-bind:y-title = "ytitle" ></pressChart1>
  </div>
</template>

<script>
import pressChart1 from '@/components/chart/pressChart2_temp' ;
import * as d3 from 'd3'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'pressChartData1', 
    props: [
        'height',
        'url'
    ],
  watch: {
        chartData: function() {

              //   deep: true,
                   this.redraw();
                // handler(){this.renderChart(this.chartData, this.options); }
        },
        url() {
            if(this.url==='') return
           // datacollection: null,
          //  this.chartData= [];
     
          //this.chartData =  [];
       
         
          this.loaded=false;
          this.loaded_ts_flow=false;
      //    this.url = "http://localhost:3000/api/get_leak_bubble?w_idx=768" ;

         var parseTime = d3.timeParse("%Y-%m-%d %H:%M:%S.%L");
         var parseTime1 = d3.timeParse("%Y-%m-%d %H:%M");
        
         if(this.url.indexOf('/day/')!==-1){
           parseTime1 = d3.timeParse("%Y-%m-%d");
           // this.chart_type = "area" ;
         }
         if( Number(this.fixed) !== 1){         
              this.yopt = {beginAtZero: true, };
              this.min = null
          }
         this.$http.get(this.url).then((res) => {
            //데이터 셋팅
              var b_data = res.data;
              this.chart_type = null;
             if(this.url.indexOf("api/API21/m2_press/") !== -1){
                b_data = res.data.results[0]
                this.chart_type = "area" ;
                if(this.url.indexOf('/day/')!==-1){
                  this.chart_type = "column" ;
                }
             }
             if( b_data !=="" && b_data != null) {
                //var b_data = res.data;
                //this.chartData = res.data.data    
                //this.chartData = res.data           
                
                 var ccdata = []; //압력
                 var ccdata1 = []; //온도
                 var size = b_data.length 

                 var ssdate1 = null
                 var sdate1 = null
                 var value = null 
                 //var avg = null
                  for(let i=0; i < size ; ++i){
                    // if(i === 0){
                    if(this.url.indexOf("api/API21/m2_press/minute/") !== -1){  
                       this.ytitle = 'Pressure';
                       ssdate1 = parseTime1(b_data[i].measure_datetime);
                       sdate1 = Date.UTC(ssdate1.getFullYear(), ssdate1.getMonth(),  ssdate1.getDate(),ssdate1.getHours(),ssdate1.getMinutes(),ssdate1.getSeconds());
                       value = null
                       if(this.url.indexOf("#kgf") !== -1){  
                         value = b_data[i].water_pressure_kgf; 
                       }else if(this.url.indexOf("#psi") !== -1){  
                         value =b_data[i].water_pressure_psi;
                       }else{
                        this.ytitle = "Pressure(bar)"
                        //ccdata.push([sdate1,b_data[i].water_pressure]);
                        value =b_data[i].water_pressure;
                       }
                       ccdata.push([sdate1,value]);                  
                       ccdata1.push([sdate1,b_data[i].device_temperature]);
                    }else if(this.url.indexOf("api/API21/m2_press/day/") !== -1){ 
                      this.ytitle = 'Pressure';
                       ssdate1 = parseTime1(b_data[i].measure_datetime);
                       sdate1 = Date.UTC(ssdate1.getFullYear(), ssdate1.getMonth(),  ssdate1.getDate(),ssdate1.getHours(),ssdate1.getMinutes(),ssdate1.getSeconds());
                       value = b_data[i].maximum_night_pressure;
                       if(this.url.indexOf("#kgf") !== -1){  
                         value =  b_data[i].maximum_night_pressure*14.504* 0.070307
                       }else if(this.url.indexOf("#psi") !== -1){  
                         value =b_data[i].maximum_night_pressure*14.504
                       }
                      // avg += value 
                       ccdata.push([sdate1,value]);   
                       ccdata1.push([sdate1,null]);
                    }else{
                       var ssdate = parseTime(b_data[i].date);
                       var sdate = Date.UTC(ssdate.getFullYear(), ssdate.getMonth(),  ssdate.getDate(),ssdate.getHours(),ssdate.getMinutes(),ssdate.getSeconds(),ssdate.getMilliseconds());
                       ccdata.push([sdate,b_data[i].press_data]);
                    }
                    //  console.log(ssdate )
                    //avg = avg / size 
                  }
                  this.chartData = ccdata
                  this.chartData1 = ccdata1
   
             }else{

                  this.xdata = [];
                 this.chartData = [] ;
                 this.chartData1 = [] ;
                 this.loaded_ts_flow=true;
             }
          })
          .catch((error) => {
           // this.loaded = true;
            this.xdata = [];
            this.chartData = [] ;
            this.chartData1 = [] ;           
            this.loaded_ts_flow = true
            console.log(error)
          });


        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        
        loaded_ts_flow: true,
        chartData:  [],
        interval : 100,
        sdate: Date.UTC(2020, 0, 1,0,0,0,0),
        loaded: false,
        bar_idx:-1,
        ytitle: 'pressure',
        fixed : 0 , //0 : false, 1: fixed
        min : null,
        param:{},
      }
    },
    methods: {

       redraw () {

              this.loaded = true;
              this.loaded_ts_flow = true        
           // this.update();
           // this.$data._chart.update()
           // this.chartData =  cData ;
           // this.$refs.chart.update();

      },
    
  
  },
  mounted () {
        window.addEventListener('resize', this.resize);

       // if(this.url==='') return

         //this.$http.get(this.url).then((res) => {
            //데이터 셋팅
         //    if( res.data !=="" && res.data != null) {
         //       this.chartData = res.data.data;
                /*
                for(let i=0; i < b_data.length ; ++i){
                  this.xdata.push(b_data[i].w_title);
                  //this.chartData1.push(b_data[i].leak_count) ;
                  //this.chartData2.push(b_data[i].leak_inside) ;
                  this.chartData1.push(10) ;
                  this.chartData2.push(20) ;
                  this.chartData3.push(b_data[i].other_count) ;

                }
                */
      //       }else{
      //            this.xdata = [];
                 
      //       }
      //    });


    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    },

    components: {
        PulseLoader: PulseLoader,
        pressChart1:pressChart1,
    }
}
</script>
<style lang="scss" scoped>

.wavchart .highcharts-container .highcharts-root {
    width: 98%;    
    margin: 0 auto;
}


</style>
<template>
  <div>
    <!-- top-bar -->
    <div ref="navbar" class="navbar-custom">
      <ul class="list-unstyled topnav-menu topnav-menu-left">
        <li>
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-title">Leak Detail</li>-->
            <!-- <li class="breadcrumb-bar">|</li>-->
            <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
            <li class="breadcrumb-item"><router-link to="/leak-recovery">{{$t('leak_recovery.page_title')}}</router-link></li>
            <li class="breadcrumb-item active">{{$t('leak_recovery.specification')}}</li>
          </ol>
        </li>

      </ul>
    </div>
    <!-- tom-bar-end -->
    <div class="content-area row justify-content-around" style="padding : 0 15px 0 15px;">
      <div class="left leak-recovery">
  
        <div class="list_box list_box2">
           
   
  
          <div class="page_list">
            <b-row class="list_box list_box2 recov" >
              <b-col class="page_list">
                <h3>{{$t('leak_recovery.table_title')}}</h3>
                <p></p>
                <div class="input_search_box leak" style="width:60%">
                   <input type="text" class="input_search" v-model="value" :placeholder="$t('water_leak.left_menu.search')"  @keyup.enter ="Enter_Check" >
                    <input type="submit" class="input_submit" @click="go_search" >
                </div>
                <div style="float:right;margin:0px 0px 0px">
                  <button class="btn-primary btn btn-map-view ml-1 map-button icon_btn icon_btn_text"  style="width: 100px; " @click="go_work_move" >{{$t('leak_detail.btn_work_move')}}</button>                                      
                </div>       
                <div class="table-box" >
                 <!-- <div style="position:absolute; top: 0; right:0;  ">
                      <download-excel
                        class   = "btn btn-default"
                        :data   = "json_data"
                        :fields = "json_fields"
                        worksheet = "My Worksheet"
                        name    = "filename.xls">
                      <button  @click="go_download" class="btn btn-third ml-2"  > <i class="mdi mdi-download" v-b-tooltip.hover title='Excel Download'></i></button>
                    </download-excel> </div>-->
                  <!--<label for="textarea-small" class="table-box-text mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </label>-->
                  <ServerSideTable ref="table_leakage_info" 
                                   :columns="columns"
                                   :searchOptions="{ enabled: true,    trigger: 'enter',  placeholder: 'Search this table',    externalQuery: searchTerm}"
                                   :sort="true"                                   
                                   :select="true"
                                   :add_custom_column="true"
                                   :adv_search="adv_search"
                                   :max_height="table_height+'px'"
                                   @on-row-click="onRowClick"
                                   @on_goto_map_click="on_goto_map_click"
                                   @on_info_click="on_leakage_info"
                  />

                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="right recovery">
        <b-row style=" padding: 0px 0px 0px 0px ; margin-bottom:7px;">
          <b-col class="leak-detail-content" :style="{height: map_height+'px'}">
            <b-row style="margin-bottom:5px;">
              <b-col style="padding-top: 0px; " >
              <!--  
                    <div>           
                          <input id="slider" type="range" min="1" max="1000" value="1" style="width: 100%" />                      
                    </div>  
                -->
                  <div style=" position: relative;left:32%;width:53%;text-align:center;">    
                     <div style="float:left;">
                          
                          <span style="float:left;">  </span>
                          <span style="float: right;padding-top:15px;">
                           <b-input v-model="szoom"    style="width:60px;height:20px;margin:0 0 0 25px;padding:10px;text-align:right;" 
                           type="text" maxlength=4  onkeyPress="if ((event.keyCode<48) || (event.keyCode>57)) event.preventDefault();"
                           v-on:keydown="fn_press_han" v-on:keyup="fn_press_han" />
                           
                          </span> 
                      </div>
                       <span  style="float:left;margin-left: -10px;" >                            
                        <audio id="slice_audio_id" controls preload="auto" :src="slice_url" style="transform:scale(0.7);height: 50px;">
                        </audio>
                       </span>
                  </div>        
                  <div class="status leak" >
                    <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                      <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                        <span class="inner-bg">Y Axis</span> -->
                                  
                    <input type="radio" v-model="vchart" value="0" id="fixed"> <label for="fixed">{{$t('leakage_info.fixed')}}</label>
                    <input type="radio" v-model="vchart" value="1" id="proportional"><label for="proportional">{{$t('leakage_info.proportional')}}</label>
                  </div>
                  <div style='min-height: 180px; height: calc(20vh - 10px);'>
                    <div id="hidden-container" >
                      <div id="waveform" ref="waveform" style="float : left; margin-left: 35px; width:95%;"/>
                      <div id="wave-timeline" ref="wave-timeline" style="float : left; margin-left: 35px; width:95%;"/>
                    </div>
                  </div>
                <!-- <audio controls preload="auto" :src="aurl" style = "width:50%;">
                 </audio> -->
                <!--<av-waveform :audio-src="aurl" :caps-height="2" :canv-width="800" :canv-height="120" ref="avwaveform"></av-waveform> -->
              </b-col>
            </b-row>
            <b-row style="margin-bottom:5px;">
              <b-col style=" padding-left: 0px;padding-right: 0px;">
                <!--FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:98%; overflow: auto;"  /-->
                <div  style='min-height: 200px; height: calc(20vh - 10px);'>
                   <pressChartData_fft :url="fft_url" ref="fftchart" :height="fftchart_height" style="width:100%; overflow: auto;" @onZoomClickEvent="onZoomClickEvent"  />
                </div>   
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row  style=" padding: 10px 0px 0px 0px ; ">
          <b-col class="leak-detail-content-map"  style='padding: 0;'>
            <div class="search_div_rec">
              <span class="spn1"><b-input size="sm" type="text" :placeholder="$t('map.alt_addr')" v-model="addr_value"  @keyup.enter ="Enter_Check1"/></span>
              <span><button class="btn btn-map-view ml-1  map-button icon_btn icon_btn_text" @click="addr_search">{{$t('button.search')}}</button></span>
           </div>
            <div :style="{height: map_height+'px'}" >
              <Map
                  ref="map"
                  :widx="$route.query.widx"
                  :visible_full_extent="true"
                  :visible_gisload_button="true"    
                  :recovery_icon = "true"               
                  w_type="101"
                  @go_home="go_home()"
                  @onLeakMapClick="onLeakMapClick()"
                  @set_map_gisload="set_map_gisload"
                  @toggle_visible_all_layer = "toggle_visible_all_layer"
                  @facility_invisible_layer = "facility_invisible_layer"
                  @set_info="set_info"
                  @leaktable_refresh="leaktable_refresh"
                  @get_gisinfo="get_gisinfo"   
                  @gis_reload = "gis_reload"                   
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
     <DlgWorkList ref="dlg_work_list"  @leaktable_refresh="leaktable_refresh" />
  </div>
</template>

<script>

//import FFTChart1 from '@/components/FFTChart1';
import pressChartData_fft from '@/components/chart/pressChartData_fft';
import ServerSideTable from '@/components/table/ServerSideTable';
import Map from '@/components/Map';
import DlgWorkList from '@/components/popup/DlgWorkList';
import {CreateVectorLayer_f} from '@/gis.js';
import WaveSurfer from 'wavesurfer.js';
//import DlgWavSliceChart from '@/components/DlgWavSliceChart';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';

export default {
  name: 'LeakRecoveryDetail',
  props: {
    // url
  },
  watch: {
    vchart() {
      //     if(this.vchart==='0') {
      //        alert("radio button : " + this.vchart) ;
      //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
      //     }
      var c_url = this.fft_url.split('#') ;
      this.fft_url = c_url[0]+'#'+this.vchart ;
    }
  },
  data() {
    return {
      layer_all_visible: false,
      addr_value:'',
      checkedNames: [],
      unchecked : true,
      contents_height: 800,
      waveform_height: 50,
      fftchart_height: 200,
      map_height: 380,
      table_height: 580,
      vchart : 0,
      is_playing: false,
      value:'',
      levelA : false,
      columns: [
        {label: this.$t('leak_recovery.columns.map'), field: 'go_map',sortable: false},
        {label: this.$t('leak_recovery.columns.info'), field: 'info', sortable: false},
        {label: this.$t('leak_recovery.columns.date'), field: 'register_datetime'},
        {label: this.$t('leak_recovery.columns.title'), field: 'ma_title'},
        {label: this.$t('leak_recovery.columns.worker'), field: 'worker'},
        {label: this.$t('leak_recovery.columns.facility'), field: 'layer_type'},     
        {label: this.$t('leak_recovery.columns.leak'), field: 'leak_amount_value'},
        {label: this.$t('leak_recovery.columns.strength'), field: 'wave_energy_cal'},    
        {label: this.$t('leak_recovery.columns.image'), field: 'image'},
        {label: this.$t('leak_recovery.columns.contents'), field: 'ma_contents'},
        //{label: 'Remarks', field: 'remarks'}
      ],
     selected_ts_mnf: '',
      json_data :[],
      json_fields:{
        'Date': 'register_datetime',
        'Sub Title': 'ma_title',
        'Worker': 'worker',
        'Facility': 'layer_type',       
        'X' : 'X',
        'Y' : 'Y',
        'Leak(m³/h)': 'leak_amount_value',       
        'Strength' : 'wave_energy_cal',    
        'Image': 'Image',
        'contents': 'ma_contents'
      },

         adv_search: [
                {
                    field: 'leak_result_',
                    control: 'select',
                    value: null,
                    //'Leak','Leak Inside','Usage','Other'
                    options: [
                        { value: null, text: 'Leak Result' },
                        { value: 'RT_LEAK', text: 'Leak' },
                        { value: 'RT_LEAK_INSIDE', text: 'Leak Inside' },
                        { value: 'RT_USAGE', text: 'Usage' },
                        { value: 'RT_OTHER', text: 'Other'},
                      
                    ]
                },
               
            ],
      filename : 'filename.xls',
      wavesurfer: null,
      fft_url: '',
      aurl:'',
      clicked_l_idx: -1,
      clicked_b_row:null,
      searchTerm: '',
      slice_url:'',
      szoom:50,
    }
  },
  methods: {
    addr_search(){
      this.$refs.map.go_search(this.addr_value);
    },
    Enter_Check1(){
        this.addr_search();
    },
    toggle_visible_all_layer() {
     
      //누수 레이어를 다시 그린다.
      this.$refs.map.init_reload_leak_layer() ;

      
    },
    fn_press_han(event)
    {
        var objTarget = event.srcElement || event.target;
    // if(objTarget.type == 'text') {
      
      var value = objTarget.value;
      //alert(value);
      if(isNaN(value)){
        objTarget.value =value.replace(/[^0-9]/g,'');
        // alert('Please enter only numbers');
        // event.preventDefault();          
      }        
    },    
    permission_check(){
       if(sessionStorage === null || typeof sessionStorage === undefined){
            alert(this.$t('leak_detail.alt_no_update'))
            return
   
        }else if(sessionStorage.level > 2){
            alert(this.$t('water_leak.alert_no_permission'))
            return;
        }
    },      
    go_work_move() {
        this.permission_check();
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<1) {
          alert(this.$t('leak_detail.alt_select'));
          return;
        }

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].id)
        }
        this.$refs.dlg_work_list.show(101,list,this.$route.query.widx);
    
    },    
     uncheckedAll (a) {
      
       // this.isChecked = true;        
             if (this.unchecked && a === "1") {
                this.checkedNames=[]
             }else{
               if(this.checkedNames.length > 0)
                  this.unchecked = false ;
                else
                  this.unchecked = true ;   
             }                    
    },
     change_selected_ts_mnf(e) {
          this.selected_ts_mnf = e
          this.go_search();
           /*
          this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_list_ex?w_idx="+this.$route.query.widx+'&search='+this.value+'&leak_result='+this.selected_ts_mnf, (rows) => {
            console.log(rows)
            if((!rows)||(rows.length<=0)) return;
            this.clicked_l_idx = rows[0].idx
            this.aurl = this.URL+'api/get_wav/'+rows[0].idx
            //  this.avwaveform.load(aurl)
            this.wavesurfer.load(this.aurl);
            this.fft_url = this.URL+'api/get_fft/'+rows[0].idx+'?'+this.vchart;
        })
        */
        //    this.get_time_series_mnf()
    },
    facility_invisible_layer() {

      this.layer_all_visible = false;
      let map = this.$refs.map.map
      for(let i=1; i<map.getLayers().getLength()-1; i++) {
        let layer = map.getLayers().item(i)
        if(layer.get('code')>50) continue;
        layer.setVisible(this.layer_all_visible)
      }
    },
    go_download() {
  //   this.filename = 
      this.filename = this.filename.replace(",","_")
      let rows = this.$refs.table_leakage_info.rows ;
      if(rows.length<=0) {
        alert(this.$t('leak_detail.alt_no_data'));
        return;
      }
      this.json_data = rows ;
      // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
      // this.$router.push({path: '/leak-detail', query: { widx: list }})
    },
       go_delete() {
            let rows = this.$refs.table_leakage_info.getSelectRows()
            if(rows.length<0) return;

        let list = []
        for(let i=0; i<rows.length; i++){        
          list.push(rows[i].idx)
       }
            var con_test = confirm("Are you sure you want to delete?");
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test){
              this.$http.get(this.URL+'api/delete?l_idx_ex='+list).then((res) => {  
                console.log(res.data);
                this.leaktable_refresh();
              });

           }
        },
    go_home() {
      this.$router.push({path: '/leak-recovery-detail-map', query: { widx: this.$route.query.widx, w_type: '101' }})
    },
    download(idx, name) {
      this.$http({
        url: this.URL+'api/download_wav/'+idx,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);
        fileLink.click();
      })

    },
    downloads() {
      let rows = this.$refs.table_leakage_info.getSelectRows()
      if(rows.length<=0){
        alert(this.$t('gis.gis_check_alert'));   
        return;  
      } 
      //'Leak','Leak Inside','Usage','Other'
      let fst = {'Leak':'L','Leak inside':'I','Usage':'U','Other':'O'}
      for(let i=0; i<rows.length; i++){
        let fs = '';
        if(rows[i].leak_result_ !== null){
           fs =  fst[rows[i].leak_result_];
        }
        let fname = rows[i].file_name.split('.')[0]+'_'+fs+".wav"
       // this.download(rows[i].idx, rows[i].file_name)
        setTimeout(()=>{
          this.download(rows[i].idx, fname)
        }, 100*i)
      }
    },
 
    goRouter(path) {
      let query = {}
      if(path==='/') query['widx'] = this.$route.query.widx
      else if('/leak-analysis'===path) {
        let rows = this.$refs.table_leakage_info.getSelectRows()
        if(rows.length<=0) return;
        let lidx = []
        for(let i=0; i<rows.length; i++) lidx.push(rows[i].idx)
        query['lidx'] = lidx
        query['widx'] = this.$route.query.widx
      }
      this.$router.push({path: path, query: query})
    },
    onLeakMapClick(){
      let leak_idx = this.$refs.map.leak_idx;
      this.clicked_l_idx = leak_idx;

      //LEAKAGE INFO값을 가져와서 FFT, WAV값을 뿌려준다.
      this.aurl = this.URL+'api/get_wav/'+leak_idx ;

      this.wavesurfer.load(this.aurl);
      this.fft_url = this.URL+"api/get_fft/"+leak_idx+'#'+this.vchart;

    },
    leaktable_refresh(){
      this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_recov_list_ex?w_idx="+this.$route.query.widx, (rows) => {
        if((!rows)||(rows.length<=0)) return;
        this.clicked_l_idx = rows[0].water_leak_id
        this.filename = "widx-"+this.$route.query.widx+"-lidx-"+rows[0].idx+"~.xls"
        this.aurl =this.URL+'api/get_wav/'+rows[0].water_leak_id;        
        this.wavesurfer.load(this.aurl);        
        this.fft_url = this.URL+'api/get_fft/'+rows[0].water_leak_id+'#'+this.vchart;

      })
    },
    onRowClick(params) {
      //선택된 색상 보여지게
   //  this.HighlightRow(params.event.target.parentElement.parentElement) ;
      if(this.clicked_b_row != null){
        this.clicked_b_row.bgColor = '';
    //  //  this.clicked_b_row.style.backgroundColor = '';
     }
    
      if(params.event.target.nodeName==='TD'){
          params.event.target.parentElement.bgColor  = '#125744';          
          this.clicked_b_row = params.event.target.parentElement ;
      }else  if(params.event.target.nodeName==='IMG'){
           params.event.target.parentElement.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement.parentElement ;
      }else{
          params.event.target.parentElement.parentElement.bgColor = '#125744';      
          this.clicked_b_row = params.event.target.parentElement.parentElement ;
      }
      
/*
     if(this.clicked_b_row != null){
       this.rowStyleClassFn(params.row, 1);
     }
      this.rowStyleClassFn(params.row, 0);
 */  

    //  this.clicked_b_row = params.row 
      this.slice_url = null;
      var audio = document.getElementById('slice_audio_id')
      audio.load();       

      this.clicked_l_idx = params.row.water_leak_id
      document.getElementById("hidden-container").style.display = 'none';
   
      this.aurl =this.URL+'api/get_wav/'+params.row.water_leak_id;        
      this.wavesurfer.load(this.aurl);        
      this.fft_url = this.URL+'api/get_fft/'+params.row.water_leak_id+'#'+this.vchart;  

    },

    on_goto_map_click(row) {
      this.$refs.map.get_extent('w_type=101&p_idx='+row.id).then((data)=>{
        this.$refs.map.set_fit_extent(data)     
        if(this.$store.setting.map_name==='Daum')
          this.$refs.map.map.getView().setZoom(13);
        else
          this.$refs.map.map.getView().setZoom(18);   
      })
      this.$refs.map.select_facility_feature(101,'',row.id) 
    },
    play() {
      if(!this.is_playing) {
        this.wavesurfer.play();
        this.is_playing = true
      }
      else {
        this.is_playing = false
        this.wavesurfer.pause();
      }

    },
    stop() {
      this.is_playing = false
      this.wavesurfer.stop();
    },
    window_resiz() {
      this.contents_height = window.innerHeight - this.$refs.navbar.clientHeight - 40

      this.map_height = this.contents_height/2 - 20
      this.fftchart_height = this.map_height /2 - 30
      this.waveform_height = this.fftchart_height/3
      this.$refs.map.update_size()
    },
    set_map_gisload() {
      this.set_info();
      let map = this.$refs.map.map;
      var size1 = map.getSize();
      var size = [size1[0]*1, size1[1]*1] ;
      var cz = (this.$store.setting.map_name === 'Daum')? 9 : 15 ;
      let epsg = (this.$store.setting.map_name === 'Daum') ? 'EPSG:5181' : 'EPSG:3857' ;
      var extent = map.getView().calculateExtent(size);

      if(this.$refs.map.map.getView().getZoom() < cz){
         alert(this.$t('home.alert_zoom_in')) ;
      }else{
        // 추가 되었던 소스 가져와서 삭제하기
        // heat layer 삭제

        var h_layer = this.$refs.map.heat_layer ;
        if(h_layer != null) {
          var source_h = h_layer.getSource();
          var hfeatures = source_h.getFeatures();
          for(var k=0; k< hfeatures.length ; k++) {
            source_h.removeFeature(hfeatures[k]);
          }
          map.removeLayer(h_layer) ;
          h_layer = null;
        }
        // remove_layer(map);
        for(let i=0; i<this.$refs.map.layer_list.length; i++) {
          var layer = this.$refs.map.layer_list[i];
          if(layer=== null || layer.values_ === null) continue
          var source1 = layer.getSource();
          var features = source1.getFeatures();
          for(var j=0; j< features.length ; j++) {
            source1.removeFeature(features[j]);
          }
          map.removeLayer(layer) ;
        }
        this.$refs.map.layer_list = [] ;
        for(let i=0; i<this.$refs.map.layer_setting_stlye_list.length; i++) {

          var params = this.$refs.map.layer_setting_stlye_list[i];
          // var nstyle = params.style;
          var _url=params.surl;
          // if(params.code >50 || layer.get('code')=== undefined ) continue

          var lftaye = params.f_type;
          _url=_url+(_url.indexOf('?')>-1?'&':'?')+'bbox='+extent.join(',')+'&srid='+epsg+'&f_type='+lftaye;

          var newlayer = CreateVectorLayer_f(params,extent) ;
          var vis = params.visible ;
          newlayer.setVisible(vis);
          this.$refs.map.layer_list.push(newlayer) ;
          map.addLayer(newlayer);


        } //for문 끝
        this.$refs.map.init_draw_modify();
      } // zoom크기에 대한 if


    },
    gis_reload(){
        this.set_info();
       // this.$refs.map.init_draw();
        if(this.$store.setting.map_name==='Daum')
          this.$refs.map.map.getView().setZoom(9);
        else
          this.$refs.map.map.getView().setZoom(15);   
        this.set_map_gisload() ;    
    },
    get_gisinfo(){
        this.$refs.map.block_info = 1 ;
        this.set_toggle_visible_all_layer();
        // this.$refs.map.init_draw();
        this.$refs.map.edit_change('add', 'Block')
        // this.$refs.map.edit_start('add', 'Block') ;
    },
    set_toggle_visible_all_layer() {
          
            this.layer_all_visible = !this.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue
                layer.setVisible(this.layer_all_visible)
            }
        },
    set_info(){
      this.$refs.map.edit_cancel();
      this.$refs.map.select_info = true;
    },
    on_leakage_info(row) {
      var purl = "/leakRecove_popup_info?idx="+row.id ;
      window.open(purl, "PopupWin", "width=580,height=750,top=10, left=50,status=no, menubar=no, toolbar=no,resizable=yes, location=no");
      //this.$refs.map.show_leakage_info(idx)
    },
     parent_funcion(){
       setTimeout(()=>{
          this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_recov_list_ex?w_idx="+this.$route.query.widx, (rows) => {
            console.log(rows)

            if((!rows)||(rows.length<=0)) return;
            this.clicked_l_idx = rows[0].water_leak_id
            this.filename = "widx-"+this.$route.query.widx+"-lidx-"+rows[0].id+"~.xls"
            this.aurl = this.URL+'api/get_wav/'+rows[0].water_leak_id     
            this.wavesurfer.load(this.aurl);
            this.fft_url = this.URL+'api/get_fft/'+rows[0].water_leak_id+'#'+this.vchart;
          
         })
      }, 1000)  
     },
     go_search() {
          if(this.value===undefined) return;

          this.box = [] ;       
      //    this.$http.get(this.URL+'api/get_work_list_ex?w_type=100&search_term='+value+'&per_page=100&page=1').then((rows) => {
          this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_recov_list_ex?w_idx="+this.$route.query.widx+'&search='+this.value+'&leak_result='+this.checkedNames, (rows) => {
          console.log(rows)
          if((!rows)||(rows.length<=0)) return;
          this.clicked_l_idx = +rows[0].water_leak_id 
          this.aurl = this.URL+'api/get_wav/'+rows[0].water_leak_id     
          this.wavesurfer.load(this.aurl);
         this.fft_url = this.URL+'api/get_fft/'+rows[0].water_leak_id+'#'+this.vchart;

        })
     },
      Enter_Check(){
       //  if(event.keyCode == 13){
         this.go_search();  // 실행할 이벤트
     },
  
     onZoomClickEvent(){
      var s_min = this.$refs.fftchart.select_min; //09-03 이렇게 들어온다
      var s_max = this.$refs.fftchart.select_max; //09-03 이렇게 들어온다
    //  this.min_value = s_min;
    //  this.max_value = s_max;
      this.slice_url = this.URL+'api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
      // this.slice_url = 'http://dev.neverlosewater.com/node/api/get_wave_slice?idx='+this.clicked_l_idx+"&start_pos="+s_min+"&end_pos="+s_max+"&y_zoom="+this.szoom ;
    },
  },
  created () {
    this.$EventBus.$on('sidebar_resize', () => {
      setTimeout(()=>{
        this.$refs.fftchart.redraw()
        if(this.clicked_l_idx>-1){
          //this.aurl = this.URL+'api/get_wav/'+this.clicked_l_idx ;
          this.wavesurfer.empty()
          this.wavesurfer.load(this.aurl);

        }
      }, 500)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.window_resiz)
  },
  mounted () {
    window.addEventListener('resize', this.window_resiz)
    this.window_resiz()
       setTimeout(()=>{
            this.toggle_visible_all_layer();   
       }, 500)  
//삭제버튼 활성화 여부
   // if(localStorage !== null && typeof (localStorage) !== undefined && localStorage.level === 'A'){
     if(sessionStorage !== null && typeof (sessionStorage) !== undefined && sessionStorage.level === 'A'){
       this.levelA = true       
     }else{
       this.levelA = false
    }
    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#4488ee',
      progressColor: 'blue',
      mediaControls: true,
      backend: 'MediaElement',
      normalize : true,
      scrollParent : true,
      //waveColor: '#D2EDD4',
      //progressColor: '#46B54D',
      //   barWidth: 0.5,
      //   barHeight: 4, // the height of the wave
      //minPxPerSec : 300,  //오디오 파일의 1초당 렌더링 될 픽셀 수의 최소값
      //   autoCenter: true,
      height: this.waveform_height,
      //   width: 600,
      plugins: [      
          TimelinePlugin.create({
            container: '#wave-timeline',    
          }),
      
    
      ]
    });
    
    this.wavesurfer.on('finish', () => {
      this.is_playing = false
    });
      document.getElementById("hidden-container").style.display = 'none';
      this.wavesurfer.on("loading", function () {
        document.getElementById("hidden-container").style.display = '';
       // var mediaElt = document.querySelector('audio');
        var mediaElt1 = document.getElementById('waveform');
        var mediaElt = mediaElt1.querySelector('audio');
        mediaElt.style = "width:40%;height:50px; transform:scale(0.7); position: absolute; left:-20px;top:0px;border:0; outline:0;" ;


      });
     /*
      this.wavesurfer.drawer.on('click', (event, progress)=>{
           // this.wavesurfer.backend.seekTo(progress);
            this.wav_getTime(progress)
        });
    */
      this.$refs.table_leakage_info.setUrl(this.URL+"api/get_leak_recov_list_ex?w_idx="+this.$route.query.widx, (rows) => {
        console.log(rows)

        if((!rows)||(rows.length<=0)) return;
        this.clicked_l_idx = rows[0].water_leak_id
        this.filename = "widx-"+this.$route.query.widx+"-lidx-"+rows[0].id+"~.xls"
        this.aurl = this.URL+'api/get_wav/'+rows[0].water_leak_id     
        this.wavesurfer.load(this.aurl);
        this.fft_url = this.URL+'api/get_fft/'+rows[0].water_leak_id+'#'+this.vchart;
      
    })

  },
  components: {
   // FFTChart1: FFTChart1,
    pressChartData_fft: pressChartData_fft,
    ServerSideTable: ServerSideTable,
    Map: Map,    
    DlgWorkList:DlgWorkList,
  //  DlgWavSliceChart:DlgWavSliceChart,

  }
}

</script>

<style lang="scss" >
.fftchart .highcharts-container .highcharts-root {
    width: 100%;
    height: 20vh;
}

/*
    #waveform{
          background: rgba(0,0,0,0.8);
    }
*/
#waveform .audio {
  all: none;
  width: 300px;
  position :absolute;
  z-index:1;
}

.inner-bg {
  float : left;
  /* 공통 속성값 지정 */

  display: inline-block;
  /* 인라인요소를 인라인블록 요소로 */
  height: 30px;
  /* 이미지 공간을 확보하기 위해 높이값 지정 */

  background: #c0c0c0;
  padding-left:10px;
  padding-right: 10px;

}

.content-area {
  padding: 0 30px;
}
.leak-detail-content {
  padding: 10px 30px 0;
   // 2022 new
  // background: #fff;
  background: #172A46;
  border: 1px solid #172A46;
  color: #fff;
}
.leak-detail-content-map {
  padding: 0;
}
.left .list_box.list_box2.recov {
  min-height: 780px;
  height: calc(100vh - 120px);
}
.table-button-group {
  margin-bottom: 15px;
}
.table-button-group .btn-lk-middle {
  width: 100%;
}
.left.leak-detail {
  height: auto;
}
table.type01 th {
  padding: 12px 0;
  background-color: aqua;
}

.btn-default {
  color: #fff;
}
.btn-default :hover{
  color: #000;
}
i {
    font-style: normal;
}
.input_search_box.leak{
  margin-top:0px;
}
.custom-radio.b-custom-control-sm{
     margin-left: 20px;
}

.search_div_rec{
    position: absolute;
    left:250px;
    top: 5px;
    z-index: 110;
}
.spn1 {
      float: left;
}
</style>

<template>
  <div class="content-body">
        <li class="setting-btn" style="float:right; margin: -50px 10px 0 0;">
                                <!--b-button size="sm" class="float-right" @click="show_setting">
                                    <i class="fas fa-cog"></i>
                                </b-button-->          
                 <b-button v-if="is_dev()" class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="add_dataset">DataSet</b-button>                                
                 <b-button  class="btn btn btn-map-view ml-1  map-button icon_btn icon_btn_text blue btn-secondary btn-sm"   @click="add_work">Add Work</b-button>
             </li>
    <div class="content-header">
      <div class="d-flex" style="line-height: 40px;">
        <label>
          <input class="ns-checkbox" type="checkbox" @click="SelectAllBox" v-model="allSelected">
          <!--          <span  class="title"> {{$t('water_leak.left_menu.select_all')}}</span>-->
        </label>
        <div class=" d-flex " >
          <div>
            <!-- 2022 new -->
            <!-- <b-button class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text btn-grayLine" @click="go_detail"> -->
            <b-button class=" btn-grayLine"  @click="go_detail">
              {{ $t('button.go_detail') }}
            </b-button>
          </div>
           <div>
            <input type="radio" id="radio_01" name="radio01" v-model="sort" value="create_datetime">
            <label for="radio_01" class="btn-grayLine"  >{{ $t('water_leak.work_sort') }}</label>
          </div>
          <div>
            <input type="radio" id="radio_02" name="radio01" checked v-model="sort" value="last_work_datetime">
            <label for="radio_02" class="btn-grayLine">{{ $t('water_leak.leak_sort') }}</label>
          </div>
          <div class="item-total">
            <span>  Total :</span>
            <strong> {{ tot_block_length }}</strong>  
          </div> 
        </div>
        <!-- <div class="d-flex">
          <div>
            <b-button class="btn btn-map-view ml-1 map-button icon_btn icon_btn_text btn-gray" @click="go_detail">
              {{ $t('button.go_detail') }}
            </b-button>
          </div>
          <div>
            <input type="radio" id="radio_01" name="radio01" v-model="sort" value="create_datetime">
            <label for="radio_01">{{ $t('water_leak.work_sort') }}</label>
          </div>
          <div>
            <input type="radio" id="radio_02" name="radio01" checked v-model="sort" value="last_work_datetime">
            <label for="radio_02">{{ $t('water_leak.leak_sort') }}</label>
          </div>
        </div> -->
      </div>
      <div class="ns-search-box">
        <input type="text" class="ns-search-input" v-model="value" :placeholder="$t('water_leak.left_menu.search')"
               @keyup.enter="Enter_Check">
        <button type="button" class="ns-search-button" @click="search"/>
      </div>
    </div>


<!----------------- 2023 start -------------->

<ul class="ns-list ns-list-head" >

  <li >
    <div class="ns-items" style="background: #3b4d7c; ">
      <div>
        <label><input class="ns-checkbox" type="checkbox" ></label>
      </div>
      <div class="title" style="width:18%;">
        <span>Title</span>
      </div>
      <div  class="" style="width:12%;text-align: center; ">
        <span>Work Creation Time</span>
      </div>
      <div  class="" style="width:12%;text-align: center;">
        <span>Last Sound Time</span>
      </div>
      <div style="width:12%;text-align: center;">
        <span>Total / Ground </span>
      </div>
      <div class="center" style="padding: 0 1.5em 0 0; text-align: center;width:12%;">
        <span>Valve / Water Meter / Pipe / Oter</span>
      </div>
      <!-- <div class="center" style="padding: 0 1.5em 0 0;">
        <span>Valve</span>
      </div>
      <div class="center" style="padding: 0 0;">
        <span>Water Meter</span>
      </div>
      <div class="center" style="padding: 0 1.5em;">
        <span>Pipe</span>
      </div>
      <div class="center" style="padding: 0 3em 0 0;">
        <span>Oter</span>
      </div> -->
      <div class="center" style="width :20%; ">
        <span>Leak / No Leak / Leak suspicion</span>
      </div>
    </div>

  </li>   
</ul>
    <ul class="ns-list" style="width:100%">

      <li :class="[{ 'is--active': activeCheckIndex === index }, { 'with--bottom-index' : isBottomIndex},, { 'press_icon' : isPressicon},]"
          v-for="(item, index) in box" :key="index" style="width:100%; position: relative;">
        <div class="ns-items" @click="selectBox(index)">
          <div>
            <label><input class="ns-checkbox" type="checkbox" :id="item.idx" :value="item.idx"
                          v-model=" leak_check"></label>
          </div>
          <div class="title" style="width:18%;">
            <span>
              <b-img :src="item.icon_img " alt="image" v-if="item.isMode"
                     style=" width: 15px;  position: absolute;  left: 55px; top: 25px; "></b-img>
            <span class="num bottom" v-if="isBottomIndex">{{ item.idx }}</span>
            </span>
            <p>
              <!-- work 별 이미지 추가 -->
              <span class="num" v-if="!isBottomIndex">{{ item.idx }}</span> {{ item.title }}
            </p>
          </div>
          <div  class="" style="width:12%;text-align: center;">
            <p>{{ item.time }}</p>
          </div>
          <div  class="" style="width:12%;text-align: center;">
            <p>{{ item.close_time }}</p>
          </div>
          <div style="width:12%;text-align: center;">
            <p style="font-size: 16pt;font-weight: 600;">{{ item.leak_count }} / {{ item.ground }}</p>
          </div>
          <div>
            
          </div>
          <div class="center" style="padding: 0 1.5em 0 0; text-align: center;width:12%;">
            <p>{{ item.valve }} /  {{ item.meter }} / {{ item.pipe }} / {{ item.other }}</p>
   
          </div>
          <!-- <div class="center" style="padding: 0 1.5em 0 0; text-align: center;">
            <p>{{ item.valve }} </p>
          </div>
          <div class="center" style="padding: 0 0;">
            <p>{{ item.meter }}</p>
          </div>
          <div class="center" style="padding: 0 1.5em;">
            <p>{{ item.pipe }}</p>
          </div>
          <div class="center" style="padding: 0 3em 0 0;">
            <p>{{ item.other }}</p>
          </div> -->
          <!-- 2023 -->
          <!-- <div class="center" style="width :310px; height: 40px;margin-left: 20px;" > -->
          <div class="center" style="width :20%; text-align: center;">
            <BarHChart v-bind:chartData="item.leak" v-bind:chartData1="item.no_leak"
             v-bind:chartData2="item.m_leak"  v-bind:series_name="series_name"
            ></BarHChart>
          </div>
          <div style="position: absolute;right:6em">
            <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="work_edit(item.idx)"
                 style="width: 24px;height: 24px; margin-right: 8px; cursor: pointer">
          </div>
          <div style="position: absolute;right:3em">
            <img src="@/assets/images/new/ic_menu_chevron.svg" alt="" @click="$emit('go_detail',  item.idx)"
                 style="width: 8px; cursor: pointer">
          </div>
        </div>

      </li>   
    </ul>




    <DlgWorkInfo ref="work_info" @worklist_refresh="worklist_refresh"/>
    <DlgDatasetSave ref="dataset_save"/>
    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" force-use-infinite-wrapper="true" >
      <div slot="no-more" style="color: rgb(102, 102, 102); font-size: 14px; padding: 10px 0px;">
        {{ $t('water_leak.left_menu.no_data') }}
      </div>
    </infinite-loading>
  </div>
</template>

<script>
//import { BIconCheckSquare } from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'
import DlgWorkInfo from '@/components/popup/DlgWorkInfo'
import DlgDatasetSave from '@/components/popup/DlgDatasetSave'
//import BarChart from '@/components/BarChart_st_line';
import BarHChart from '@/components/chart/BarHChart_leak'

export default {
  name: 'SearchLeftType1',
  watch: {
    sort () {
      this.search()

    },

  },
  data () {
    return {
      leak_check: [],
      value: '',
      allSelected: false,
      api_url: '',
      box: [],
      page: 1,
      infiniteId: +new Date(),
      levelA: false,
      icon_imag: '',
      isPressicon: false,
      leak_width: '20',
      other_width: '20',
      sort: 'create_datetime',
      series_name: 'Leak',
      tot_block_length : 0,
      //    w_type : 100,
    }
  },
  components: {
    //BIconCheckSquare
    DlgWorkInfo: DlgWorkInfo,
    InfiniteLoading,
    DlgDatasetSave,
    // BarChart,
    BarHChart
  },
  props: {
    activeCheckIndex: {
      type: null
    },
    w_type: {
      type: Number
    },
    //  box: {
    //    type: Array
    //  },
    isBottomIndex: {
      type: Boolean,
      default: false
    },

  },
  methods: {
    //dataset 버튼 숨기기 여부
    is_dev(){
      let cur_page = "" + document.location.href;
      if (cur_page.indexOf("dev.neverlosewater.com") !== -1 || cur_page.indexOf("samjin.neverlosewater.com") !== -1)  return true;
      //if (sessionStorage.customer_id === "1" || sessionStorage.customer_id === "32") return true;
      else return false;
    },
    add_work(){
      this.$refs.work_info.insert_show(this.w_type,this.measure_mode)
    },
    add_dataset(){
       var leak_arry = this.leak_check
       //권한여부 체크
       if(sessionStorage === null || typeof sessionStorage === undefined){
          alert(this.$t('leak_detail.alt_no_update'))
          return
       }else if(sessionStorage.level > 1){
          alert(this.$t('water_leak.alert_no_permission'))
          return;
       }

       if(leak_arry.length<1){
          alert(this.$t('water_leak.left_menu.alert_select_list'))
          return;
       }
      
 
       this.$refs.dataset_save.show(100,leak_arry);
    },
    selectBox (index) {
      this.$emit('updateCheckIndex', index, this.box[index])
    },
    go_detail () {
      var leak_arry = this.leak_check
      if (leak_arry.length > 0) {
        this.$emit('go_detail', leak_arry)
      } else {
        alert(this.$t('water_leak.left_menu.alert_select_list'))
      }
    },
    work_edit (idx) {
      this.$refs.work_info.show(idx, this.w_type)
    },
    worklist_refresh () {
      this.search()
    },
   infiniteHandler ($state) {
      //sort_field=this.sort&sort_type=desc
      var customer_id = sessionStorage.select_customer_id
      if (sessionStorage.select_customer_id === '27') {
        customer_id = 22
      }
      // this.$http.get(this.URL+'api/get_work_list_ex?w_type='+this.w_type+'&search_term='+this.value+'&per_page=10&page='+this.page+'&sort_field='+this.sort+'&sort_type=desc&customer_id='+sessionStorage.select_customer_id).then((rows) => {
      this.$http.get(this.URL + 'api/get_work_list_ex?w_type=' + this.w_type + '&search_term=' + this.value + '&per_page=10&page=' + this.page + '&sort_field=' + this.sort + '&sort_type=desc&customer_id=' + customer_id).then((rows) => {
        if (rows.data.rows.length > 0) {
        //   setTimeout(() => {
          this.page += 1
          //this.list.push(...data.hits);
          for (let i = 0; i < rows.data.rows.length; i++) {
            if (i == 0 || this.page === 2) {
              this.t_title = rows.data.rows[i].title
              this.t_worker = rows.data.rows[i].worker
              this.t_start_time = rows.data.rows[i].create_datetime
              this.t_close_time = rows.data.rows[i].last_work_datetime
            }
            var abox = {}
            if (this.w_type === 100 || this.w_type === 101) {
              abox = {
                'title': rows.data.rows[i].title,
                'idx': rows.data.rows[i].id,
                'time': rows.data.rows[i].create_datetime,
                'leak_count': rows.data.rows[i].leak_count,
                'close_time': rows.data.rows[i].last_work_datetime,
                'worker': rows.data.rows[i].worker,
                'w_title': rows.data.rows[i].title,
                'pipe': rows.data.rows[i].pipe,
                'valve': rows.data.rows[i].valve,
                'meter': rows.data.rows[i].meter,

                'other': rows.data.rows[i].other,
                'ground': rows.data.rows[i].ground,
                'leak': rows.data.rows[i].leak,
                'no_leak': rows.data.rows[i].no_leak,
                'm_leak' : rows.data.rows[i].meter_is_running,
              }

            }
            this.box.push(abox)
          }
          $state.loaded()
          // }, 500)
        } else {
          $state.complete()
          this.tot_block_length =  this.box.length
        }
      })
    },
    SelectDelete () {

    },
    SelectAllBox () {

      // this.isChecked = true;
      var selected = []

      if (!this.allSelected) {
        //   this.box.forEach(function (item) {
        for (let i = 0; i < this.box.length; i++) {
          selected.push(this.box[i].idx)
          if (i === 19) {
            alert(this.$t('water_leak.left_menu.alt_more_20'))
            break
          }
          //   });
        }
      }
      this.leak_check = selected

    },
    Enter_Check () {
      //  if(event.keyCode == 13){
      //  this.$emit('go_search',  this.value) ;  // 실행할 이벤트
      this.search()
    },
    search () {
      this.page = 1
      this.box = []
      this.infiniteId += 1
    }

  },
  mounted () {
    setTimeout(() => {
      if (this.box.length > 0)
        this.$emit('updateCheckIndex', 0, this.box[0])
    }, 500)

    //삭제버튼 활성화 여부
    // if(localStorage !== null && typeof (localStorage) !== undefined && localStorage.level === 'A'){
    if (sessionStorage !== null && typeof (sessionStorage) !== undefined && sessionStorage.level < 2) {
      this.levelA = true
    } else {
      this.levelA = false
    }

  }
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/base/variables';


.left_area .left_list_box .input_search_box.with--all-button .btn {
  left: 0px;
}

.left_area.w_leak .left_list_box .input_search_box .input_submit {
  left: 31%;
}

.left_area.w_leak .left_list_box .input_search_box .input_search {
  /* float: right; */
  width: 33%;
}

.left_area.w_leak {
  width: 100%;
  display: inline-block;
  margin-top: 10px;
  margin-left: 2.5%;
  float: left;
  // 2022 new
  background-color: $color-bg;
  // background-color: #0A192F;
}

.left_area.w_leak .left_list_box .input_search_box.with--all-button {
  width: 95%;
}
// 2023
// .workbtn {
//   background-color: #cccccc;
//   border: 1px solid #ccc;
//   color: #000;
//   padding: 4px 4px;
//   font-size: 12px;
//   cursor: pointer;
// }

// /* Darker background on mouse-over */
// .workbtn:hover {
//   background-color: rgb(102, 102, 102);
//   color: #fff;
// }

.left_area.w_leak .list_box_wrap .box_list.with--bottom-index .con_box.worklist {
  // 2022 new
  width: 10%;
  padding-left: 20px;

}

.left_area.w_leak .list_box_wrap .box_list.with--bottom-index .con_box.worklist2 {
  width: 23%;
  padding-top: 8px;
  padding-left: 1.5%;
  padding-right: 1.5%;
  text-align: center;
  // 2022 new
  // border-left: solid 1px #c5c5c5;
  // border-right: solid 1px #c5c5c5;
}

.left_area.w_leak .list_box_wrap .box_list .num_box.worklist {
  width: 23%;
  text-align: center;
  margin: 20px 20px 0px 20px;

}

.left_area.w_leak .list_box_wrap .box_list .num_box.worklist1 {
  width: 20%;
  text-align: center;
  // 2022 new
  // margin: 7px 20px 0px 20px;

}

.left_area.w_leak .list_box_wrap {
  margin-top: 0px;
}

.left_area.w_leak .list_box_wrap .box_list {
  // 2022 new
  // padding: 16px 0 16px 5%;
  padding: 2px 0 2px 5%;
}

.left_area.w_leak .list_box_wrap .box_list .num.bottom {

  left: 22px;
  bottom: 30px;
  width: 41px;
  text-align: center;
}

.left_area.w_leak .list_box_wrap {

  height: calc(100vh - 75px - 37px - 28px - 66px - 37px);
  overflow-y: scroll;
}

.left_area.w_leak .left_list_box .input_search_box.with--all-button .btn {
  position: relative;
  top: 0px;
  width: 100px;
  //  2022 new
  //  background-color:#2e75b6 ;
  background-color: $color-darkgray;
  margin-right: 30px;

}

.left_area.w_leak .list_box_wrap .box_list.with--bottom-index {
  // 2022 new
  // background: url('../assets/images/sub/web_4/box_list_icon.png') 30px 15px no-repeat #fff;

  border-radius: 0;
}

.left_area.w_leak .list_box_wrap .box_list .con_box .time {
  // 2022 new
  //  color: #1b1a1a;
  color: #fff;
  font-size: 14px;
}

.left_area.w_leak .list_box_wrap .box_list .con_box .title {
  font-size: 14px;
  margin-bottom: 8px;

}

.LeakCountChart .highcharts-container .highcharts-root { 
  // width: 300px;
  height: 100px;
}

/* 2022 new */

.left_area {
  background-color: $color-bg;
}
// 2023

</style>

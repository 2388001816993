<template>
  <highcharts id= "LeakBubbleChart" :options="chartOptions" :class="size"></highcharts>
</template>

<script>
export default {
  name: 'BubbleChart',
   props:{

      chartData: {
        
      },
       chartData1: {
        type: Number
      },
    //  series_name : {},
      y_title : {},
      size : { type:String},
      /*
      interval : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
       sdate : {
        // 100 : 0.1초, 1000: 1초
        type : Number,
      },
      */
      //y_title : { type:String},
      //chart_type : { type:String}
  },
  data () {
    return {
     //  x_label : this.size === "gisBarChart" ? "" : "%" ,
       chartOptions: {
         credits: {
              text: '',
              href: ''
          },
       
        series:[ 
            {            
             data : this.chartData
            }
         ]
        ,
          tooltip: {
            pointFormat: " <b>{point.x}Month : {point.z}</b>"
        },
         title: {        text: '', },
         chart: {  // zoomType: 'x' ,
                    type: 'bubble',  
                    plotBorderWidth: 1,                  
                },
      
        xAxis: {
         
            title: { text: '' },  
            
            labels: {
              // rotation: -45,
                enabled: true,
                
            }
        },
        yAxis: {
          
          //  max:this.series_name === "Leak" ? null : Math.ceil(this.chartData,0),
            title: {
                text: ''
            },
            maxPadding: 0.2,
          //  lineWidth:1,
            labels: {
                enabled: true,
              //  format: '{value}'
            }
        },
        legend: {
            enabled: false
        },
        
        plotOptions: {
            series: {               
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.z}</b>',
                }
            },
       
        },
      },

    
       
    }
  }
}
</script>
<style   lang="scss" >

#LeakBubbleChart .highcharts-container .highcharts-root {
  width: 100%; 
  height: 400px;  
}
#LeakBubbleChart {
  min-width:400px;
  max-width: 1600px;
  height: 400px;
  width:100%; 
   margin: 0 auto;
  
}
 

</style>

<script>
  import { Line } from 'vue-chartjs'



  export default {
    extends: Line,
   // mixins: [reactiveProp],
   props:{
      xdata: {
        type: Array,
      },
      chartData: {
        type: Array
      },
      yopt:{
        
      },
      ymax: Number,
      xmax:Number
  },
   watch: {
      chartData () {
        this.$data._chart.destroy()
        this.renderChart(this.datacollection, this.options) ;
        this.$data._chart.update()
      }
    },
    data () {
      return {        

        datacollection: {
          labels: this.xdata,
          datasets: [
            {
            //  label: 'Strength',
              backgroundColor: '#0064FF',
           //   pointBackgroundColor: 'white',
              borderWidth: 1,
              pointRadius : 0,        
              //data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100]
              data: this.chartData
            }
          ]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: this.yopt
              /*
              {
                  beginAtZero: true,       
                max: this.ymax,
            //    max: 10,
                min: 0,     
                stepSize  : this.ymax/5
              }
              */
              ,
              gridLines: {
                display: true
              },
                scaleLabel: {
                display: true,
                labelString: 'Amplitude'
              },             
            }],
            xAxes: [ {
              gridLines: {
                display: false,                
              },
              ticks: {   
                beginAtZero: true,
                max: this.xmax,
                min: 0,
                autoskip: false,        
                //stepSize  : this.xmax/10
                //stepSize  : 200,
               // unit :'Number',
               // unitStepSize: 100
                //scaleSteps : 200,                
                maxTicksLimit : 10
              },
              scaleLabel: {
                display: true,
                labelString: 'Frequency(Hz)'
              },             
            }]
          },
            legend: {
             display: false
          },
          tooltips: {
              callbacks: {
                label: function(tooltipItem) {
                        return tooltipItem.yLabel;
                }
              }
          },
     //     title:{
     //       display : true,
      //      text : 'Strength'
      //    },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted () {
  //    this.height = 150;
      this.renderChart(this.datacollection, this.options)
    }
  }
</script>

<template>
  <div class="left_area">
    <div class="left_list_box" >
      <div class="input_search_box" >
        <input type="text" class="input_search" v-model="value"   placeholder="Search..."  @keyup.enter ="Enter_Check">
        <input type="submit" class="input_submit" @click="go_search()" >
      </div>
      <div v-if="visible_search_box"  style="margin: 1em 0;">
        <!-- 2023 -->
            <!-- <span class="status m1-map d-flex" style="position: relative;top:0px;"> -->
            <span class="status m1-map" style="position: relative;top:0px;">
                         
                
                <input type="radio" id="radio_01" name="radio01"  v-model="leak_type" value="L">
                <label for="radio_01">Leak</label> 
                
                <input type="radio" id="radio_02" name="radio01"  checked v-model="leak_type" value="">
                <label for="radio_02">All</label> 
                <!--input type="checkbox" id="check_01" name="check_01"  v-model="sort_strength"  style="display:none"/>
                <label class="btn-gray" >Order by Strength</label-->  
                <span style="padding-left: 0px;border-radius: 10px;  ;margin-left: 5px;">
                  <input type="checkbox" id="check_01" name="check_01"  v-model="sort_strength"  style=""/>
                  <!-- <label for="check_01" class="btn-grayLine" style="border: 1px solid #707070;padding:padding:5px 10px;">Order by Strength</label></div> -->
                  <label for="check_01" class="btn-grayLine" style="border: none;padding: 4px 2px 0px;background: none;">Order by Strength</label>
                </span>
                <span style="float: right;   margin-top: 3px;"  >
                  <!--label for="radio_02"  style="font-size: 15px;margin-right:10px;">Status </label-->
                  <b-select  size="sm" v-model="status_type" :options="f_status_option" style="padding: 0 10px;" ></b-select>
                </span>      

            </span>   
      </div>
      <div v-if="visible_sort_box_press" style="margin: 1em 0;">
            <span class="status m1-map" style="position: relative;top:0px;">
                         
                <input type="radio" id="radio_01" name="radio01"  checked v-model="sort" value="">
                <label for="radio_01">Name</label>
                <input type="radio" id="radio_02" name="radio01"  v-model="sort" value="id">
                <label for="radio_02">Installation Data</label>      
                <span style="float: right;   margin-top: 3px;"  >
                  <!--label for="radio_02"  style="font-size: 15px;margin-right:10px;">Status </label-->
                  <b-select  size="sm" v-model="status_type" :options="f_status_option" style="padding: 0 10px;" ></b-select>
                </span>        
            </span>   
      </div>      
      <div class="list_box_wrap press" style="transform: translateZ(0);-webkit-transform: translateZ(0);">
        <div :class="['box_list box_list02', { 'is--active': activeCheckIndex === index },{'is--leak' : visible_search_box} , { 'is--status': 'I' === item.point_status }]" v-for="(item, index) in box" :key="index" @click="selectBox(index)">
          <div class="con_box press">
            <p class="title ">{{ item.name }}<img :src="('red' === item.status ? require('@/assets/images/sub/m2/alarm_red.png') :'')" style="width:25px;padding: 0 0 5px 5px ;" alt=""></p>
            <p class="title " v-if="!display_day" > {{ item.device_sn}} </p>
            <div v-if="display_day" class="title "> &nbsp;
              <div  class="status_circle" style="right: 0px; margin-top: 0px;">
                <ul >
                  <li >
                    <a  href="#" :class = "item.day_0" >0</a>
                  </li>
                  <li >
                    <a  href="#" :class="item.day_1">1</a>
                  </li>
                  <li>
                    <a  href="#" :class="item.day_2">2</a>
                  </li>
                  <li >
                    <a  href="#" :class="item.day_3">3</a>
                  </li>
                  <li >
                    <a href="#" :class="item.day_4">4</a>
                  </li>
                </ul>
              </div>
            </div>
            
            
            <span class="unit"> {{ item.flow}}</span>
              <div style="    position: absolute;    top: 23%;    right: 5%; width:28px; z-index:1000;" >
                <!-- 2022 new -->
                <!-- <img src="@/assets/images/Common/icon/setting.png" alt="" @click="go_m2_info(item.id)" style="margin-bottom:10px;"> -->
                <img src="@/assets/images/new/ic_list_info.svg" alt="" @click="go_m2_info(item.id)" style="margin-bottom:10px; width: 70%;">
                <!--img src="@/assets/images/Common/icon/map_1.png" alt="" @click="goRouter('/pressure-monitoring-map?w_type=111',item.id,item.flow )"-->
                <!-- 2022 new -->
                <!-- <img src="@/assets/images/Common/icon/map_1.png" alt="" @click="$emit('on_goto_map_click',  item)"> -->
                <img src="@/assets/images/new/ic_list_map.svg" alt="" @click="$emit('on_goto_map_click',  item)" style="margin-bottom:10px; width: 70%;">
              </div>
          </div>
        </div>
      </div>
    </div>
    <DlgM2MoniSetting ref="m2_setting" />
    <DlgHydraulicPointInfo ref="hydraulic_info" />
  </div>
</template>

<script>
//pressure-monitoring,leak-monitoring 호출
import DlgM2MoniSetting from '@/components/popup/DlgM2MoniSetting' 
import DlgHydraulicPointInfo from '@/components/popup/DlgHydraulicPointInfo' 
export default {
  name: 'SearchLeftType3',
  watch: {
    leak_type() {
       this.$emit('go_search', this.value,  this.leak_type,this.strength_sort_yn, this.status_type) ;  // 실행할 이벤트
      
    },
    sort_strength(){
       if(this.sort_strength)
          this.strength_sort_yn ="strength" 
       else 
          this.strength_sort_yn ="" 
        this.$emit('go_search', this.value,  this.leak_type,this.strength_sort_yn, this.status_type ) ;  // 실행할 이벤트
      
    },
    sort() {
      this.go_search();
    },
    status_type() {
      this.go_search();
    },
  },
  data() {
    return {
      value: '',
      status_type : 'A',
      leak_type: '',
      sort:'',
      sort_strength:'',
      strength_sort_yn : '',
      // 2022 new
      // img_com_sense : require('@/assets/images/sub/m2/signal5.png'),
      img_com_sense : require('@/assets/images/new/ic_battery.svg'),
      f_status_option: [
                { value: '', text: 'All' },
                { value: 'A', text: 'Active' },
                { value: 'I', text: 'Inactive' },
      ],  

    }
  },
  props: {
    activeCheckIndex: {
      type: null
    },
    box: {
      type: Array
    },
    w_type : { type: null},
    visible_search_box:{type:null},
    visible_sort_box_press:{type:null},
    display_day:{type:null,default:true},
  },
  methods: {
    statusColor(item) {
      return item.status === 'red' ? 'status_02': 'status_01'
    },
    go_m2_info(m2_point_id){
       let cur_page = ""+document.location.href ;
      if(cur_page.indexOf("hydraulic") !== -1 ) { 
        this.$refs.hydraulic_info.show(m2_point_id)
      }else{
        this.$refs.m2_setting.show(m2_point_id)
      }
    },
    selectBox (index) {
      this.$emit('updateCheckIndex', index)

    },
    Enter_Check(){
      
       if(event.keyCode == 13){
         if(this.visible_search_box)
           this.$emit('go_search',  this.value, this.leak_type,this.strength_sort_yn, this.status_type) ;  // 실행할 이벤트
          else
             this.$emit('go_search',  this.value, this.sort, this.status_type)
       }
    },
    go_search(){
        if(this.visible_search_box)
           this.$emit('go_search',  this.value, this.leak_type,this.strength_sort_yn, this.status_type) ;  // 실행할 이벤트
        else
            this.$emit('go_search',  this.value, this.sort, this.status_type)
    },
    goRouter(path,tag,last_date) {

      if(last_date.length < 10 ) return;
      var last_str = last_date.substr(0,10)
      if(this.w_type === '102' ){
        path= 'leak-monitoring-detail-map?w_type=102'
        last_str = last_date.substr(0,16)
      }
      this.$router.push({path: path, query: { tag: tag, mea_date : last_str  }})
    }
  },
  components: {      
      DlgM2MoniSetting : DlgM2MoniSetting,
      DlgHydraulicPointInfo: DlgHydraulicPointInfo,
  }
};
</script>

<style lang="scss" scoped>
.detail__work-title {
  font-size: 13px;
  margin-top: 1%;
  margin-bottom: 2%;
  &:before {
    content: '●';
    font-size: 10px;
    padding-right: 5px;
  }
}
.left_area .list_box_wrap .box_list .con_box.press{
      width: 70%;
}
.left_area .list_box_wrap.press{
  /*min-height: 710px;*/
  height: calc(100vh - 80px - 85px - 37px - 28px - 56px );
}
.left_area .list_box_wrap .box_list.box_list02 {
  border: 1px solid #707070;
}
.status {
    /* float: right; */
    // margin-top: 16px;
    float: none; 
}
.left_area .list_box_wrap .box_list.box_list02{
    background: url(../../assets/images/new/ic_03_w.png) left 7% center no-repeat ;
}

.left_area .list_box_wrap .box_list.box_list02.is--leak{
    background: url(../../assets/images/new/ic_05_w.png) left 7% center no-repeat ;
}
.status_circle ul{
    display: flex;
    padding-left: 0;
    list-style: none;
    position: absolute;
    top: 40%;
    left: 20%;
}
.status_circle ul li{
  display: list-item;
    text-align: -webkit-match-parent;
    margin-right: 6px;
    list-style: none;
}
.status_circle ul li a{
  display: block; 
    line-height: 0.6 !important;
    position: relative;
    display: block;
    padding: 9px;
    font-size: 12px;
    z-index: 3;
    color: #fff;
    border-radius: 20px;
    background-color: #707070;
    // border-color: #28AF81;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    line-height: 0.6 !important;
    line-height: 0.6 !important;
}
.status_circle .color_01{
                    background: #EB5B5B;
                }
.status_circle .color_00{
                    background: #638CFB;
                }
</style>

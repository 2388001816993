<template>
    <div>
        <!--<div id="waveform_m" ref="waveform_m" style="position: absolute; left: 20px; top: 5px; z-index: 20;"/> -->
        <b-modal 
            ref="modal" 
            title="Water Leak Dataset">


           <!-- <div class="mt-3" style="padding: 10px 30px;"   v-if="properties!==null" > -->
            <!--b-tabs  v-if="properties!==null && w_type === 300" align="center">
              <b-tab :title="$t('leakage_info.properties')" active>

                    <b-row >
                        <b-col style="height: 280px">
                        
                          <b-row >
                              <audio controls preload="auto" :src="aurl" ref="player" style = "margin: 0px 0 0 -35px;width:70%;transform:scale(0.7);"/>
                        
                               <div class="status leak" style="padding-right: 0px; margin-left: -20px;" >
                                  <input type="radio" v-model="vchart" value="0" id="dfixed" checked>
                                  <label for="dfixed" >{{$t('leakage_info.fixed')}}</label>
                                  <input type="radio" v-model="vchart" value="1" id="dproportional">
                                  <label for="dproportional" >{{$t('leakage_info.proportional')}}</label>
                              </div>
                          </b-row>
                              <FFTChart1 :url="fft_url"  ref="fftchart" :height="fftchart_height1"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['id']" disabled></b-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.title')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['w_title']"  disabled></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.contents')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['w_contents']" disabled></b-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.worker')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['worker']" disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.f_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['f_idx']" disabled ></b-input>
                        </b-col>
                    </b-row>
                      <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('facility_info.f_name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['layer_type']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.start_time')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['measure_start_datetime']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.save_time')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['period']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.strength')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['wave_energy']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                      <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.max_hz')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['wave_max_frequency']" disabled  ></b-input>
                        </b-col>
                    </b-row>


                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.file_name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-textarea class="facility__input height--auto" rows="2" v-model="properties['file_name']"  disabled ></b-textarea>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.device_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['device_sn']" disabled  ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.image')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['image']" disabled   ></b-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.remarks')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input  class="facility__input" size="sm" v-model="properties['remarks']" disabled ></b-input >
                        </b-col>
                    </b-row>  
                   
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.ai_result')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-textarea class="facility__input height--auto" size="sm" v-model="ai_result" disabled ></b-textarea >
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.probability')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['probability']"  disabled ></b-input>
                        </b-col>
                    </b-row>   
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.leak_result')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select class="facility__input"  style="margin-bottom:5px;" size="sm" v-model="properties['leak_result']" :options="leak_result_option"  ></b-select>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">check</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select class="facility__input" style="margin-bottom:5px;" size="sm" v-model="properties['is_dataset']" :options="is_dataset_option" disabled="" ></b-select>
                        </b-col>
                    </b-row>             
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.is_ground')}}</label>
                        </b-col>
                        <b-col sm="8">
                              <b-input  class="facility__input" size="sm" v-model="properties['ground']"  disabled></b-input >
                        </b-col>
                    </b-row>    
                     
                 </b-tab>
                <b-tab :title="$t('leakage_info.photo')">
                      <p v-for="(item,i) in img_list" :key="i">
                                    <b-card
                                        no-body
                                        img-top
                                        class="photo-card"
                                    >
                                        <b-img thumbnail fluid :src="item" alt="image" bottom ></b-img>
                                      
                                    </b-card>      
                    </p>                 
    
                </b-tab>
            </b-tabs-->
                       <!-- <div class="mt-3" style="padding: 10px 30px;"   v-if="properties!==null" > -->
            <b-tabs  v-if="properties!==null" align="center">
              <b-tab title="Water Leak Dataset" active>

                    <b-row >
                        <b-col style="height: 280px">
                            <!--<FFTChart :url="fft_url" ref="fftchart1" :height="fftchart_height1"/>-->
                             <!--<FFTChart1 :url="fft_url" ref="fftchart" :height="fftchart_height1"/> -->
                          <b-row >
                              <audio controls preload="auto" :src="aurl" ref="player" style = "margin: 0px 0 0 -35px;width:70%;transform:scale(0.7);"/>
                         <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                            <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                              <span class="inner-bg">Y Axis</span> -->
                               <div class="status leak" style="padding-right: 0px; margin-left: -20px;" >
                                  <input type="radio" v-model="vchart" value="0" id="dfixed" checked>
                                  <label for="dfixed" >{{$t('leakage_info.fixed')}}</label>
                                  <input type="radio" v-model="vchart" value="1" id="dproportional">
                                  <label for="dproportional" >{{$t('leakage_info.proportional')}}</label>
                              </div>
                          </b-row>
                              <FFTChart1 :url="fft_url"  ref="fftchart" :height="fftchart_height1"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['idx']" disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">work_type</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['work_type']" disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">water_work_id</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['water_work_id']" disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">leak_id</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['leak_id']" disabled></b-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.worker')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['worker']" disabled></b-input>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.f_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['f_idx']" disabled ></b-input>
                        </b-col>
                    </b-row>
                      <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('facility_info.f_name')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['layer_type']" disabled ></b-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.start_time')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['measure_start_datetime']" disabled ></b-input>
                        </b-col>
                    </b-row>
     
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.strength')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['wave_energy']" disabled  ></b-input>
                        </b-col>
                    </b-row>
   
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.device_no')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input class="facility__input" size="sm" v-model="properties['device_sn']" disabled  ></b-input>
                        </b-col>
                    </b-row>
              

                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.table_column.remarks')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-input  class="facility__input" size="sm" v-model="properties['remarks']" disabled ></b-input >
                        </b-col>
                    </b-row>  
                   
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.ai_result')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-textarea class="facility__input height--auto" size="sm" v-model="ai_result" disabled ></b-textarea >
                        </b-col>
                        <!--b-col sm="2">
                            <b-button v-if="!loading" class="mr-2"  size="sm" @click="get_ai_result">
                                <i class="fas fa-sync-alt"></i>
                            </b-button>
                            <span v-if="loading"><img src="@/assets/images/loading.gif" style="width:25px;"/></span>
                        </b-col-->
                    </b-row> 

                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leak_detail.leak_result')}}</label>
                        </b-col>
                        <b-col sm="8">
                            <b-select class="facility__input"  style="margin-bottom:5px;" size="sm" v-model="properties['leak_result']" :options="leak_result_option1"  ></b-select>
                        </b-col>
                    </b-row>
                     <b-row>
                        <b-col sm="4">
                            <label class="facility__label">check</label>
                        </b-col>
                        <b-col sm="8">
                             <b-input  class="facility__input" style="margin-bottom:5px;" size="sm" v-model="properties['is_dataset']" disabled />
                        </b-col>
                    </b-row>             
                    <b-row>
                        <b-col sm="4">
                            <label class="facility__label">{{$t('leakage_info.is_ground')}}</label>
                        </b-col>
                        <b-col sm="8">
                              <b-input  class="facility__input" size="sm" v-model="properties['ground']"  disabled></b-input >
                        </b-col>
                    </b-row>    
                     
                 </b-tab>
     
            </b-tabs>
            <!--</div> -->
           <template  v-slot:modal-footer="{ ok, cancel, hide }">
             <b-row  class="modal-footer-row m-0 leak_foot">
               <b-col sm="4">
                 <b-button @click="$refs.modal.hide()" block class="modal-footer__button modal-footer__button-cancel">{{$t('button.close')}}</b-button>
               </b-col>
               <b-col sm="4">
                <b-button @click="apply()" block class="modal-footer__button modal-footer__button-ok">{{$t('button.apply')}}</b-button>
               </b-col>
               <b-col  sm="4">
                <b-button  @click="del()" block class="modal-footer__button modal-footer__button-del">{{$t('button.delete')}}</b-button>
               </b-col>

             </b-row>
         
            </template>
        </b-modal>
    </div>
</template>

<script>
//import WaveSurfer from 'wavesurfer.js';
import FFTChart1 from '@/components/chart/FFTChart1';

export default {
    name: 'DlgLeakageInfo',
    props: {
    },
    data() {
        return {
            w_type : 300,
            properties: null,
       //     waveform_height1: 10,
            fftchart_height1: 180,
            is_playing: false,
       //     wavesurfer: null,
            loading:false,
            fft_url: '',
            aurl : '' ,
             img1:'',
             img2:'',
             img3:'',
             vchart : '0',
             is_dataset_option: [
                { value: '1', text : 'Dataset' },
                { value: '0', text: 'No Dataset' },
                //{ value: 'RT_LEAK_INSIDE', text: 'Leak inside' },
                //{ value: 'RT_USAGE', text: 'Usage' },
                //{ value: 'RT_OTHER', text: 'Other' },
            ],
            leak_result_option1: [
                { value: 'L', text: 'Leak' },
                //{ value: 'RT_LEAK_INSIDE', text: 'Leak inside' },
                //{ value: 'RT_USAGE', text: 'Usage' },
                //{ value: 'RT_OTHER', text: 'Other' },                
                { value: 'N', text : 'No Leak' },
                { value: 'M', text : 'Meter is Running' },
                { value: 'U', text : 'Unidentified' },
            ],
             leak_result_option: [
                { value: 'RT_LEAK', text: 'Leak' },
                //{ value: 'RT_LEAK_INSIDE', text: 'Leak inside' },
                //{ value: 'RT_USAGE', text: 'Usage' },
                //{ value: 'RT_OTHER', text: 'Other' },
                { value: '', text : 'No Leak' },
            ],
            img_list: [],
            ai_result:'',
        }
    },
    mounted () {



    },
     watch: {
        vchart() {
               //     if(this.vchart==='0') {
                //        alert("radio button : " + this.vchart) ;
                 //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
               //     }
               var c_url = this.fft_url.split('#') ;
               this.fft_url = c_url[0]+'#'+this.vchart ;
        }
    },
    methods: {
        handleOk() {

        },

        get_ai_result(){
            this.loading = true     
            this.$http.get(this.URL+'api/set_leak_AI_data?idx='+this.properties['id']).then((res) => {
                 if(res.data.check ===1){ 
                    this.loading = false
                    return;
                 }
                 this.ai_result = res.data.msg ;
                 this.loading = false 
            });
            setTimeout(()=>{
                this.loading = false      
             }, 12000)    
        },

        show(idx,w_type) {
             this.properties = {} ;
             this.ai_result ="";            
             this.img_list =[];
             var s_aurl = "";
             var s_ffturl = "";
             let call_url = "" ;
             this.w_type = w_type ;
             //if(w_type === 100){
             //  call_url = this.URL+'api/get_leak_info?idx='+idx
             //}else{
               call_url = this.URL+'api/dataset/get_dataset?leak_id='+idx +"&w_type=" +w_type
             //}
             this.$http.get(call_url).then((res) => {
                if(res.data.length<=0) return

              //  let leak_result = res.data.list[0]['leak_result'] ;

             //   if(leak_result === null)
             //      this.properties['leak_result'] =  "RT_OTHER" ;
              //  this.properties['idx'] =  i ;
               
                this.properties = res.data.list[0];
  
                 this.ai_result = this.properties['ai_leak_result'] ;
                 /*
                 if(this.properties.image_list == null || this.properties.image_list  == undefined){
                 
                  this.img_list.push(require('@/assets/noimage1.png'))                 
                  this.img_list.push(require('@/assets/noimage1.png'));
                  this.img_list.push(require('@/assets/noimage1.png'));
                  
                }else{
                    
                  var img = this.properties.image_list.split(',')
                  for(var i = 0 ; i < img.length ;++i) {   
                    this.img_list.push( this.URL+'api/'+img[i])
                  }
                }
                */
               
                s_aurl = res.data.list[0].file_path;
                s_ffturl = res.data.list[0].fft_file_path;

                if(s_ffturl === null || s_ffturl=== undefined)
                  s_ffturl = "get_fft/"+idx;
                
                setTimeout(()=>{
              //  this.aurl = this.URL+'api/get_wav/'+idx ;
                    this.fft_url = this.URL+"api/"+s_ffturl+'#'+this.vchart;
                    this.aurl =this.URL+'api/'+ s_aurl ;
                }, 200)    

                this.properties_bak = Object.assign({}, this.properties)

             })


            this.$refs.modal.show();
            /*
            this.wavesurfer = WaveSurfer.create({
                container: '#waveform_m',
                waveColor: 'blue',
                progressColor: 'black',
                autoCenter: true,
        //      height: this.waveform_height1,
                plugins: [
                ]
            });
            this.wavesurfer.on('finish', () => {
                this.is_playing = false
            });
                this.wavesurfer.load(this.URL+'api/get_wav/'+idx);
                 var c_url = this.fft_url.split('?') ;
               
            */
            
               // this.fft_url = this.URL+"api/get_fft/"+idx;
             
             
        },
        apply() {
         //   if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === "undefined"){
                return
           // }else if(localStorage.level === 'C'){
            }else if(Number(sessionStorage.level) > 1 && sessionStorage.m_id !== this.properties.worker){
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
  
            let lidx = this.properties['leak_id']
            let leak_result = 0
          // 
          //  if(this.w_type !== 100)
          //      lidx = this.properties['leak_id']
        
            if(this.properties_bak['leak_result']===this.properties['leak_result']) return

            if(this.properties['leak_result'] === 'L') leak_result = 1
            else if(this.properties['leak_result'] === 'N') leak_result = 0
            else if(this.properties['leak_result'] === 'M') leak_result = 2
            else if(this.properties['leak_result'] === 'U') leak_result = 3
  
            this.$http.get(  this.URL +"api/dataset/update?leak_result=" +leak_result +"&w_type="+this.w_type+
                     "&leak_id=" +lidx+"&worker=" + sessionStorage.m_id ).then((res) => {
                console.log(res.data);
            })

            this.$emit('leaktable_refresh') ;
            this.$refs.modal.hide();
        },
        del(){
             //   if(localStorage === null || typeof localStorage === undefined){
            if(sessionStorage === null || typeof sessionStorage === "undefined"){
                return
           // }else if(localStorage.level === 'C'){
            }else if(Number(sessionStorage.level)  > 2 && sessionStorage.m_id !== this.properties.worker){
                alert(this.$t('water_leak.left_menu.alert_no_permission'));
                return;
            }
           var con_test1 = confirm("Are you sure you want to delete?");
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test1){
              this.$http.get(this.URL+'api/delete_ex?l_idx_ex='+this.properties['id']).then((res) => {  
                 console.log(res.data);
                 this.$emit('leaktable_refresh') ;
                 this.$refs.modal.hide();
              });

           }
        }

    },
    components: {
        FFTChart1: FFTChart1
        //FFTChart1_c:FFTChart1_c
    }
}
</script>

<style scoped lang="scss">
// 2022 new
.leak_foot {
    text-align: center;
}
.facility__label-radio {
  font-size: 12px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 10px;
}
.tab-content {
    max-height: 720px;    
    overflow-y: auto;    
    overflow-x: hidden; 
}
.modal-info {
  .modal-dialog {
    position: absolute;
    right: 0;
    top: 0;
    margin: 70px auto 0;
    background: #172A46;
    width: 350px;
    height: calc(100% - 70px);
    .modal-header {
      padding-bottom: 22px;
      .modal-title {
        font-size: 18px;
        /* margin-top: 12px; */
      }
    }
    .modal-info-content {
      border: 0;
      border-radius: 0;
 
      &.reversal {
        background: #172A46;
        .nav-tabs {
          background: #172A46;
        }
        .tab-content {
          background: #172A46;
          &.is--scroll {
            height: 73vh;
            overflow-y: auto;
          }
        }
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            /* 2022 new */
          /* background: #fff; */
        }
        .facility__label {
          color: #fff;
          font-size: 12px;
          display: inline-block;
          letter-spacing: 0.5px;
          /* width: 35%; */
        }
        .facility__input {
          /* width: 60%; */
          height: 32px;
          font-size: 12px;
          border: 1px solid #707070;
          box-sizing: border-box;
          border-radius: 0;
          margin-top: 0;
          margin-bottom: 14px;
          &.height--auto {
            height: auto;
          }
        }
        .modal-footer {
          background: #172A46;
          padding-bottom: 18px;
          padding-top: 3px;
        }
        .modal-footer-row {
          width: 100%;
          margin: 0;
          padding-bottom: 0;
        }
        .btn.modal-footer__button {
          height: 52px;
          font-size: 14px;
          border: 0;
          &.modal-footer__button-ok {
            /* 2022 new */
            /* background: #28B7CB; */
            background: #28AF81;
          }
          &.modal-footer__button-cancel {
            background: #9D9D9D;
          }
        }
        .photo-card {
          border-radius: 0;
          border: 0;
          background: none;
          .img-thumbnail {
            margin-bottom: 10px;
          }
          .card-header {
            height: 150px;
            background: #172A46;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .card-header__text {
              font-size: 16px;
              color:3fff;
            }
          }
        }
      }
    }
  }
}
.leak_foot {
    text-align: center;
}
</style>

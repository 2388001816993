<template>
  
        <b-modal
            ref="modal"
            title="Wave Slice Chart"
            ok-only
            id="wav_pop"
            >
         <b-row style=" padding: 10px 20px 0px 20px ; ">
          <b-col class="content1">
            <b-row >
              <b-col style="padding-top: 50px;" >          
          
               
                <div class="status" >
                  <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
                     <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
                       <span class="inner-bg">Y Axis</span> -->
                 
                                     
                  <input type="radio" v-model="vchart" value="0" id="fixed"> <label for="fixed">Fixed</label>
                  <input type="radio" v-model="vchart" value="1" id="proportional"><label for="proportional">Proportional</label>
                
                </div>
                <div  >     
                <audio id="audio2" ref="audio2"  controls preload="auto" :src="aurl"  style = "position: absolute; top: 0px; left: -40px;width:50%;transform:scale(0.7);" />
               
                <!--<av-waveform :audio-src="aurl" :caps-height="2" :canv-width="800" :canv-height="120" ref="avwaveform"></av-waveform> -->
                </div>
                  <div id="waveform1" ref="waveform1" style="float : left; margin-left: 35px; width:95%;"/>
                 <wavChart v-bind:chartData="chartData" v-bind:interval="intv"     v-if="loaded"  style="width:100%;" ></wavChart>
              </b-col>
            </b-row>
            <b-row >
              <b-col style=" padding-left: 0px;padding-right: 0px;">
                <FFTChart2 :url="fft_url" :cData = "cData" :xData = "xData" ref="fftchart" :height="fftchart_height" style="width:98%; overflow: auto;"  />
                
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-modal>

</template>

<script>

import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import FFTChart2 from '@/components/chart/FFTChart2';
import wavChart from '@/components/chart/wavChart' ;
import { WaveFile } from 'wavefile';

//import WaveSurfer_cus from '@/components/WaveSurfer_cus';

export default {
    name: 'DlgWavSliceChart',
    data() {
        return {
            fft_url: '',
            vchart : 1,
            fftchart_height : 250,
            sliderVal : 50,
            sliderVal_y:150,
            sliderVal_p:0,
            chartData:  [0,1,2,3,4,5],
            aurl:null,
            loaded: false,
            Dlgwavesurfer: null,
            w_idx : '',
            intv : 1,
            s_time : 0,
            e_time : 1,
            delay : 1000,
            cData : [],
            xData : [],
            cData1 : [],
            xData1 : [],            
        }
    },
  watch: {
    vchart() {
      //     if(this.vchart==='0') {
      //        alert("radio button : " + this.vchart) ;
      //       this.$refs.fftchart.toggle_ymax(this.vchart) ;
      //     }
    //  this.xData = this.xData1
   //   this.cData = this.cData1
      
      var c_url = this.fft_url.split('?') ;
      this.fft_url = c_url[0]+'?'+this.vchart ;
    }
  },   
    methods: {
      on_zoom(){            
        this.$refs.wav1.on_zoom(this.sliderVal) 
     },
      on_zoom_y(){       
        this.$refs.wav1.on_setHeigth(this.sliderVal_y)
        //this.wavesurfer.zoom(this.sliderVal) 
     },
      on_zoom_p(){       
        this.$refs.wav1.on_zoom_p(this.sliderVal_p/100)
        //this.wavesurfer.zoom(this.sliderVal) 
     },
       // show(lidx,start,end) {
         //this.$refs.dlg_wave_slice_chart.show(this.clicked_l_idx,sp,starttime,endtime);
      show(lidx,sp,buffer,intval,start,end) {
          //  this.fft_url = this.URL+'api/get_fft/'+lidx+'?'+this.vchart;
          //  this.aurl = this.URL+'api/get_wav/'+lidx +'/1/3';
            this.fft_url = this.URL+'api/get_fft1/'+lidx+'?'+this.vchart;
           // sp  = sp.filter(function(item) {
          //    return item !== 0;
          //  });
            
            const fft = require('fft-js').fft,
               fftUtil = require('fft-js').util,
               signal = sp;
            let phasors =  fft(signal);
           // this.cData = fft_json
           // console.log("fft_json : "+fft_json) ;
            var frequencies = fftUtil.fftFreq(phasors, 8000), // Sample rate and coef is just used for length, and frequency step
                magnitudes = fftUtil.fftMag(phasors); 
 
           // var both = frequencies.map(function (f, ix) {
           //     return {frequency: f, magnitude: magnitudes[ix]};
         //   });

           // console.log(both);
           var int_feq = []
            for(var i = 0 ; i <frequencies.length ; ++i ){
              int_feq.push(Math.round(frequencies[i]));
            }
            this.xData1 = int_feq;
            this.cData1 = magnitudes;
            this.xData = int_feq;
            this.cData = magnitudes;
         
            this.w_idx = lidx;
            this.s_time = start;
            this.e_time = end;
            this.intv = intval/1000;
            this.chartData = sp
            this.loaded =true
             this.$refs.modal.show();
            var startTime = start;
            var endTime = end;
            var delaySec = endTime - startTime;
            var delayMillis = delaySec * 1000;
            this.delay = delayMillis;

          //  const fs = require("fs");
          //  const WaveFile = require("wavefile");

            let wav = new WaveFile();
            wav.fromScratch(1, 22050, "32f", sp); // the sampling rate (second parameter) could be different on your machine, but you can print it out in the code snippet above to find out
          //  fs.writeFileSync("test.wav", wav.toBuffer());
            /*
               setTimeout(()=>{
                     var myAudio=document.getElementById('audio2');
                      myAudio.addEventListener('play',() =>{
                        this.startplay();
                      });

                 // myAudio.onprogress = function() {
                   myAudio.addEventListener("timeupdate", function () {
                      if (myAudio.currentTime == 0) {
                      //  myAudio.currentTime = start;
                      }
                  });    
                 }, 500)
                 */
/*
           
             */
            /*
            var myAudio=document.getElementById('audio2');
            myAudio.addEventListener('canplaythrough', function() {
              if(this.currentTime < startTime){this.currentTime = startTime;}
              this.play();
              setTimeout(function(){
                  document.getElementById('audio2').pause();
                }, delayMillis); 
             });

*/
            // var blob = new Blob(wav.toBuffer(), { 'type' : 'audio/x-wav; codecs=0' });
            // var blob = new Blob([buffer], { 'type' : 'audio/x-wav' });
            // this.aurl =window.URL.createObjectURL(blob);
            // document.querySelector("audio").play(start,end);

             // document.querySelector("audio").src = window.URL.createObjectURL(blob);
             //document.querySelector("audio").src = source
             // var au = this.$ref.wavslice 
             //     var duration = end - start

            // create a new buffer to hold the new clip
            /*
              var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
              var source = audioCtx.createBufferSource();
            //  source.buffer = Object.values(sp);
              audioCtx.decodeAudioData(sp, function(buffer) {
                source.buffer = buffer;

                source.connect(audioCtx.destination);
                source.loop = true;
              });
*/
         //     function(e){ console.log("Error with decoding audio data" + e.err); });

  
         //  var au = document.querySelector("audio")
          //     au.src= source ;
               //document.querySelector("audio").src = source
             /*
             const fs = require("fs");
             const WaveFile = require("wavefile");
             let wav = new WaveFile();
             wav.fromScratch(1, 44100, "32f", sp); //
             this.aurl =  wav.toBuffer() 
             */

            /*
            this.$http.get(this.URL+'api/get_frequency?l_idx='+lidx).then((res) => {
                for(let i=0; i<res.data.rows.length; i++) {
                    if(res.data.rows[i].o_strength===null) res.data.rows[i].o_strength = 0
                    if(res.data.rows[i].o_max_frequency===null) res.data.rows[i].o_max_frequency = 0
                }
                this.fdata = res.data.rows
                let arr = this.fdata.map((itm) => { return itm.o_strength });
                let sorted = arr.slice().sort(function(a,b){return b-a})
                let ranks = arr.slice().map(function(v){ return sorted.indexOf(v)+1 });
                for(let i=0; i<arr.length; i++)
                    this.fdata[i].rank = ranks[i];
                this.drawChart()
            });
            */
           
          setTimeout(()=>{
            // this.aurl =  wav.toDataURI()
                this.aurl = this.URL+'api/get_wav1/'+lidx ;
              var myAudio=document.getElementById('audio2');
                myAudio.addEventListener('play',() =>{
                  this.startplay();
              });
                this.Dlgwavesurfer = WaveSurfer.create({
                container: '#waveform1',
                waveColor: '#4488ee',
                progressColor: 'blue',
                mediaControls: true,
                backend: 'MediaElement',
                normalize : true,
                scrollParent : true,
                //waveColor: '#D2EDD4',
                //progressColor: '#46B54D',
                //   barWidth: 0.5,
                //   barHeight: 4, // the height of the wave
                //minPxPerSec : 300,  //오디오 파일의 1초당 렌더링 될 픽셀 수의 최소값
                //   autoCenter: true,
                height: 200,
                //   width: 600,
                plugins: [      
                    TimelinePlugin.create({
                        container: '#wave-timeline',    
                    }),
                
    
                ]
               });
                
                this.Dlgwavesurfer.on('finish', () => {
                this.is_playing = false
                });

                this.Dlgwavesurfer.on("loading", function () {
                  //  document.getElementById("hidden-container").style.display = '';
                    var mediaElt = document.querySelector('audio');
                    // mediaElt = document.getElementById("waveform");
                    mediaElt.style = "width:40%;height:50px; transform:scale(0.8); position: absolute; top:0px;border:0; outline:0;" ;


                });
                   this.Dlgwavesurfer.load( wav.toDataURI() )
                  
                }, 1000)  

         //  setTimeout(()=>{
             //  this.Dlgwavesurfer.loadDecodedBuffer(buffer)
          //     this.Dlgwavesurfer.backend.loadElt(this.Dlgwavesurfer.backend,sp)
         //    }, 2000)  

        },
         startplay(){
           // document.getElementById('audio2').currentTime = this.s_time ;
           
            if (Math.abs(this.s_time - this.$refs.audio2.currentTime) > 0.5) {
               let d = this.delay
               this.$refs.audio2.currentTime =  this.s_time
               this.$refs.audio2.lastPlay = this.s_time
               if( this.$refs.audio2.currentTime === 0){
               //  this.$refs.audio2.muted = true;
                d = this.delay + this.s_time*1100;
 
               }
               this.$refs.audio2.play();
               setTimeout(()=>{
                   this.$refs.audio2.pause();
                }, d); 
            }
            /*
           var myAudio=document.getElementById('audio2');
            if(myAudio.currentTime <  this.s_time ){myAudio.currentTime =  this.s_time ;}
            myAudio.play();
        //    setTimeout(function(){
        //        document.getElementById('audio2').pause();
        //      }, this.delay); 
        //    });
             setTimeout(()=>{
                      document.getElementById('audio2').pause();
                }, this.delay); 
                */
        },
   
    },
    mounted () {
    
       

    },

    components: {
        FFTChart2: FFTChart2,
        wavChart:wavChart,
  //      WaveSurfer_cus : WaveSurfer_cus,
    }
}
</script>

<style lang="scss">
#wav_pop.modal.show .modal-dialog {
    max-width: 850px;
    max-height: 650px;
    margin: 1.75rem auto;
}
</style>

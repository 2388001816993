<template>
    <div>
        <!-- top-bar -->
        <div ref="navbar" class="navbar-custom">
            <ul class="list-unstyled topnav-menu topnav-menu-left">
                <li>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home</router-link></li>
                      <li class="breadcrumb-item active">{{$t('menu.maintenance')}}</li>
                    </ol>
                </li>
            </ul>
        </div>
        <!-- tom-bar-end -->
        <!--search-left-type1 :box="box" :activeCheckIndex="activeCheckIndex"   @go_detail="go_detail" @go_search="go_search"  @updateCheckIndex="updateCheckIndex" /-->
       <search-left-type1  ref="left" :w_type=140 :activeCheckIndex="activeCheckIndex"  @go_detail="go_detail"  @go_delete="go_delete"   @updateCheckIndex="updateCheckIndex"  is-bottom-index />

    </div>
</template>

<script>

import SearchLeftType1 from '@/components/left/SearchLeftType_recovery';
//import PressBarChart from '@/components/PressBarChart' ;
//import {CreateVectorLayer_f} from '@/gis.js';

export default {
    name: 'Maintenance',
    props: {
    },
    data() {
        return {
          bar_url : '',
           barchart_height : 450,
            contents_height: 600,
            map_height: 300,
            table_height: 300,
           t_title:'',
           t_worker:'',
           t_start_time:'',
           t_close_time:'',
            tabs: [
              { 'name': 'chart' },
              { 'name': 'map' },
              { 'name': 'setting' }
            ],
            activeCheckIndex: null,
            box: [
              {
                'title': '장흥 대덕 블록 압력 수집',
                'date': '2020. 8. 28',
                'time': '13:24',
                'number': 6
              },
              {
                'title': '장흥 대덕 블록 압력 수집',
                'date': '2020. 8. 28',
                'time': '13:24',
                'number': 186
              },
              {
                'title': '장흥 대덕 블록 압력 수집',
                'date': '2020. 8. 28',
                'time': '13:24',
                'number': 18
              }
            ],
  
        }
    },
    methods: {
       updateCheckIndex(payload,work) {
        this.activeCheckIndex = payload
         this.t_title =  work.w_title;
        this.t_worker = work.worker;
        this.t_start_time = work.time;
        this.t_close_time = work.close_time;
      },
        window_resiz() {
            this.contents_height = window.innerHeight - 210
            //this.table_height = this.contents_height/2
            //this.map_height = this.table_height

            this.map_height = this.contents_height/1.3
            this.table_height = this.contents_height/4
            if (this.activeIndex === 1) {
              this.$refs.map.update_size()
            }
        },
       toggle_visible_all_layer() {

            this.$refs.map.layer_all_visible = !this.$refs.map.layer_all_visible
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')===100)
                    layer.setVisible(this.$refs.map.layer_all_visible)
            }
        },
        facility_invisible_layer() {

            this.layer_all_visible = false;
            let map = this.$refs.map.map
            for(let i=1; i<map.getLayers().getLength()-1; i++) {
                let layer = map.getLayers().item(i)
                if(layer.get('code')>50) continue;
                    layer.setVisible(this.layer_all_visible)
            }
        },
        size_view(e) {
            if(e==='up') {
                this.table_height += 100
                this.map_height -= 100
            }
            else if(e==='down') {
                this.table_height -= 100
                this.map_height += 100
            }
          if (this.activeIndex === 1) {
            this.$refs.map.update_size()
          }
        },
        /*
        go_detail() {
            let rows = this.$refs.table_workinfo.getSelectRows()
            if(rows.length<=0) return;
            let list = []
            for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
            this.$router.push({path: '/pressure-detail', query: { widx: list }})
        },
        */
          go_delete(idx) {

            if(sessionStorage === null || typeof sessionStorage === undefined){
               alert(this.$t('water_leak.left_menu.alert_no_delete'))
               return
         //   }else if(localStorage.level === 'C'){
            }else if(sessionStorage.level > 2){
              alert(this.$t('water_leak.left_menu.alert_no_permission'))
               return;
            }
            
            if(idx<0) return;

            //let list = [this.box[idx].idx]
            var con_test = confirm(this.$t('water_leak.left_menu.confirm_msg_delete'));
            //let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
           if(con_test){
              this.$http.get(this.URL+'api/delete_ex?wf_idx_ex='+idx).then((res) => {  
                console.log(res.data);
                this.$refs.left.search();
              });

           }
        },
        go_detail(idx) {

           if(idx.length<1){
               alert(this.$t('water_leak.left_menu.alert_select_list'))
               return;
           }


            let list = idx
           // for(let i=0; i<rows.length; i++) list.push(rows[i].idx)
            this.$router.push({path: '/maintenance-detail', query: { widx: list }})
        },
        /*
        go_search(value) {

            if(value===undefined) return;

          this.box = [] ;
       // this.$refs.table_workinfo.setUrl(this.URL+"api/get_work_list_ex?w_type=100", (rows) => {
          this.$http.get(this.URL+'api/get_work_list_ex?w_type=110&search_term='+value+'&per_page=100&page=1&customer_id='+sessionStorage.select_customer_id).then((rows) => {
          if((!rows)||(rows.data.rows.length<=0)) return;
          for(let i=0; i<rows.data.rows.length; i++) {
            if(i==0){
                 this.t_title =  rows.data.rows[i].title;
                this.t_worker = rows.data.rows[i].worker;
                this.t_start_time = rows.data.rows[i].create_datetime;
                this.t_close_time = rows.data.rows[i].last_work_datetime;
             }      
            var abox = {'title': rows.data.rows[i].title, 'idx':  rows.data.rows[i].id, 'time':  rows.data.rows[i].create_datetime, 'number':  rows.data.rows[i].press_count,
                        'close_time': rows.data.rows[i].last_work_datetime, 'worker': rows.data.rows[i].worker,'w_title':rows.data.rows[i].title} ;
            this.box.push(abox) ;
          }

        })
        },
        */
   
    },
    created () {
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.window_resiz)
    },
    mounted () {
        window.addEventListener('resize', this.window_resiz)
        this.window_resiz()
       //this.bar_url = this.URL+'api/get_work_bar_chart?w_type=110&customer_id='+sessionStorage.select_customer_id ;
       // this.$refs.table_workinfo.setUrl(this.URL+"api/get_work_list_ex?w_type=110")
       /*
          this.box = [] ;
          this.$http.get(this.URL+'api/get_work_list_ex?w_type=110&search_term=&per_page=100&page=1').then((rows) => {
          if((!rows)||(rows.data.rows.length<=0)) return;
          for(let i=0; i<rows.data.rows.length; i++) {
              if(i==0){
              this.t_title =  rows.data.rows[i].w_title;
              this.t_worker = rows.data.rows[i].worker;
              this.t_start_time = rows.data.rows[i].w_start_time;
              this.t_close_time = rows.data.rows[i].w_end_time;
             }      
            var abox = {'title': rows.data.rows[i].w_title, 'idx':  rows.data.rows[i].idx, 'time':  rows.data.rows[i].w_start_time, 'number':  rows.data.rows[i].press_count,
                        'close_time': rows.data.rows[i].w_end_time, 'worker': rows.data.rows[i].worker,'w_title':rows.data.rows[i].w_title} ;
            this.box.push(abox) ;
          }
        })
        */
    },
    components: {
      // ServerSideTable,
      SearchLeftType1,
    //  PressBarChart:PressBarChart,
    }
}

</script>

<template>
  <div>
    <!-- top-bar -->
       <div class="navbar-custom">
        <h4 class="content-title">Water Flow Monitoring</h4>
         <ul class="list-unstyled topnav-menu topnav-menu-left">
    <!--        <li>-->
    <!--          <ol class="breadcrumb">-->
    <!--            <li class="breadcrumb-item">-->
    <!--              <router-link to="/"><img src="@/assets/images/Common/icon/back_arrow.png" alt="" class="back_arrow">Home-->
    <!--              </router-link>-->
    <!--            </li>-->
    <!--            <li class="breadcrumb-item active">Block Monitoring</li>-->
    <!--          </ol>-->
    <!--        </li>-->
    <!--        &lt;!&ndash;li class="setting-btn">-->
    <!--                    <b-button size="sm" class="float-right" @click="show_setting">-->
    <!--                        <i class="fas fa-cog"></i>-->
    <!--                    </b-button>-->
    <!--        </li&ndash;&gt;-->
         </ul>
       </div>
    <!-- tom-bar-end -->
    <div class="monitoring_box">
      <!--      Headers -->
      <div class="content-header">
        <div class="item-total">
          <span>Total Block</span>
          <strong>{{ tot_block_length }}</strong>
        </div>
        <div class="ns-search-box">
          <input type="text" class="ns-search-input" v-model="value" placeholder="Search..."
                 @keyup.enter="Enter_Check"/>
          <button type="button" class="ns-search-button" @click="go_search"/>
        </div>
      </div>
      <!--      content-->
      <div class="monitoring-content" :key="listKey">
        <ul class="monitoring-list row row-cols-2">
          <!--          <li class="col" v-for="item in ts_monitoring_list" :key="item.block_id">-->
          <!--            <MonitoringCard :dma_name="item.point_name"-->
          <!--                            :last_date_time="item.last_measure_datetime" :tag="item.id"-->
          <!--                            :now_flow="item.water_flow">-->
          <!--            </MonitoringCard>-->
          <!--          </li>-->
          <div v-for="item in ts_monitoring_list" :key="item.block_id" style="margin-bottom:30px;">
            
            <li class="col">
              <MonitoringCard :dma_name="item.point_name" :last_date_time="item.last_measure_datetime" :tag="item.id"
                              :now_flow="item.water_flow">
              </MonitoringCard>
            </li>
          </div>
        </ul>
      </div>
    </div>
    <b-modal
        ref="modal"
        title="Settings"
        @ok="handleOk"
    >
      <vue-good-table ref="flow_tag_table"
                      :columns="columns"
                      :rows="ts_flow_tag_list"
                      max-height="200px"
                      :select-options="{ enabled: true, selectOnCheckboxOnly: true, disableSelectInfo: true }"
      />
      <b-button @click="add_monitoring">Add</b-button>
      <vue-good-table ref="ts_monitoring_table"
                      :columns="columns"
                      :rows="temp_ts_monitoring_list"
                      max-height="200px"
                      :select-options="{ enabled: true, selectOnCheckboxOnly: true, disableSelectInfo: true }"
      />
      <b-button @click="del_monitoring">Delete</b-button>
    </b-modal>
  </div>
</template>

<script>
import MonitoringCard from '@/components/card/MonitoringCard'
//import { Fragment } from 'vue-fragment'

export default {
  name: 'BlockMonitoring',
  props: [],
  watch: {},
  data () {
    return {
      columns: [
        { label: 'Tag', field: 'block_id' },
        { label: 'Name', field: 'block_name' },
        { label: 'last_date_time', field: 'last_date' }
      ],
      ts_monitoring_list: [],
      ts_flow_tag_list: [],
      temp_ts_monitoring_list: [],
      value: '',
      listKey: 0,
      tot_block_length: 0,
    }
  },
  methods: {
    show_setting () {
      this.temp_ts_monitoring_list = [...this.ts_monitoring_list]
      this.$refs.modal.show()
    },
    updateKey: function () {
      this.listKey += 1
    },
    handleOk () {
      this.ts_monitoring_list = [...this.temp_ts_monitoring_list]
      let param = []
      for (let i = 0; i < this.ts_monitoring_list.length; i++) param.push(this.ts_monitoring_list[i].tag)
      this.$http.get(this.URL + 'api/set_monitoring_list?list=' + param)
    },
    add_monitoring () {
      let rows = this.$refs.flow_tag_table.selectedRows
      if (rows.length <= 0) return
      for (let i = 0; i < rows.length; i++) {
        this.temp_ts_monitoring_list.push({ tag: rows[i].tag, name: rows[i].name })
      }
      this.del_dup_list(this.temp_ts_monitoring_list, this.ts_flow_tag_list)
    },
    del_monitoring () {
      let rows = this.$refs.ts_monitoring_table.selectedRows
      if (rows.length <= 0) return
      for (let i = 0; i < rows.length; i++) {
        this.ts_flow_tag_list.push({ tag: rows[i].tag, name: rows[i].name })
      }
      this.del_dup_list(this.ts_flow_tag_list, this.temp_ts_monitoring_list)
    },
    del_dup_list (a, b) {
      for (let i = 0; i < a.length; i++) {
        let idx = b.findIndex(obj => obj.tag == a[i].tag)
        if (idx <= -1) continue
        b.splice(idx, 1)
      }
    },
    go_search () {

      if (this.value === undefined) return
      this.updateKey()
      this.ts_monitoring_list = []
      var customer_id = sessionStorage.select_customer_id
      if (sessionStorage.select_customer_id === '27') {
        customer_id = 1
      }
      //sp_block ==> sp_flow 변경
      //  this.$http.get(this.URL+'api/get_monitoring_list_left?search='+this.value).then((res) => {
      this.$http.get(this.URL + 'api/API21/flow/list?customer_id=' + customer_id + '&search=' + this.value).then((res) => {
        this.ts_monitoring_list = res.data.results[0]
        this.tot_block_length = this.ts_monitoring_list.length
      })

    },
    Enter_Check () {
      //  if(event.keyCode == 13){
      this.go_search()  // 실행할 이벤트
    }
  },
  mounted () {
    //  this.$http.get(this.URL+'api/get_monitoring_list').then((res) => {
    var customer_id = sessionStorage.select_customer_id
    if (sessionStorage.select_customer_id === '27') {
      customer_id = 1
    }
    //  this.$http.get(this.URL+'api/API21/block/list?customer_id='+customer_id).then((res) => {
    this.$http.get(this.URL + 'api/API21/flow/list?customer_id=' + customer_id).then((res) => {
      this.ts_monitoring_list = res.data.results[0]
      this.tot_block_length = this.ts_monitoring_list.length
      //   this.$http.get(this.URL+'api/get_tag_list?type=flow').then((res) => {
      //      this.ts_flow_tag_list = res.data.rows
      //      this.del_dup_list(this.ts_monitoring_list, this.ts_flow_tag_list)
      //  });
    })
  },
  beforeDestroy () {
  },
  components: {
    MonitoringCard,
   // Fragment
  }
}
</script>

<style scoped>
.row {
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.col {
  padding-right: 8px;
  padding-left: 8px;
}
</style>
